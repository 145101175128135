import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { MortgageFireHongKongProposal } from "../../sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { AbstractPremiumSummary } from "./abstract-premium-summary";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MortgageFireHongKongFieldValidationManager } from "../../sme/mortgage-fire/hong-kong/base/mf-hk-field-validation.manager";

export class DbsPremiumSummary implements AbstractPremiumSummary {
  public Can_toggle_manualUw(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    return true; //todo: code changed, for future
  }

  public getMinPremium(proposal: MortgageFireHongKongProposal): number {
    return MortgageFireHongKongFieldValidationManager.minPremium; //dummy
  }
}
