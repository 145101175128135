<form novalidate #myForm="ngForm">
  <kendo-grid
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [pageable]="true"
    [sortable]="true"
    (dataStateChange)="onStateChange($event)"
    (edit)="editHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (remove)="removeHandler($event)"
    (add)="addHandler($event, myForm)"
    [navigable]="true"
  >
    <ng-template [ngIf]="addButtonVisiiblity"
      ><!--need extra ng-template to control the visibility of the kendoGridToolbarTemplate-->
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand type="button">
          {{ labelTextInterestedParty }}
        </button>
      </ng-template>
    </ng-template>
    <kendo-grid-column field="Name" title="Name" [width]="350">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-textbox [(ngModel)]="dataItem.Name" name="Name" required>
        </kendo-textbox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="NatureOfInterest"
      title="Nature Of Interest"
      [width]="250"
    ></kendo-grid-column>
    <kendo-grid-command-column
      title="Action"
      [hidden]="hideInterestedPartiesActionCol"
      [width]="220"
    >
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand type="button" [primary]="true">
          Edit
        </button>
        <button kendoGridRemoveCommand type="button">Remove</button>
        <button
          kendoGridSaveCommand
          type="button"
          [disabled]="myForm.invalid! || myForm.pristine!"
        >
          {{ isNew ? "Add" : "Update" }}
        </button>
        <button kendoGridCancelCommand type="button">
          {{ isNew ? "Discard changes" : "Cancel" }}
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</form>
