<div class="col-md-12 chubbWhite_bg" style="display: flex">
  <i
    class="fa fa-2x fa-remove paddingTop3 tabNav marginLeftTop10px chubbRed cursorPointer"
    (click)="dialog.close()"
    title="Close"
  ></i>
  <div class="col-md-10">
    <span class="card-head-agency">{{ title }}</span>
  </div>
  <div class="col-md-2 chubbWhite_bg">
    <div class="">
      <i
        class="fa fa-eraser fa-2x floatLeft paddingTop3 marginRight25 chubbRed cursorPointer"
        style="margin: 11px 25px 10px 0"
        *ngIf="data.mode == 'edit'"
        (click)="resetEdit()"
        title="Reset"
      ></i>
    </div>
    <div class="">
      <i
        class="fa fa-save fa-2x floatLeft paddingTop3 marginRight25 chubbRed cursorPointer"
        style="margin: 11px 25px 10px 0"
        *ngIf="data.mode == 'edit'"
        (click)="saveEdit()"
        title="Save"
      ></i>
    </div>
    <div class="">
      <i
        class="fa fa-edit fa-2x floatLeft paddingTop3 chubbRed cursorPointer"
        style="margin: 11px 25px 10px 0"
        *ngIf="data.mode == 'view'"
        (click)="edit()"
        title="Edit"
      ></i>
    </div>
  </div>
</div>
<kendo-tabstrip (tabSelect)="onTabSelect($event)" id="admin">
  <kendo-tabstrip-tab title="About the User" [selected]="true">
    <ng-template kendoTabContent>
      <div class="content">
        <app-view-user-profile
          *ngIf="data && data.mode == 'view'"
          [data]="data"
          [accessControlList]="accessControlList"
          [teamsData]="teamsData"
        ></app-view-user-profile>
        <app-edit-user-profile
          *ngIf="data && data.mode == 'edit'"
          [data]="data"
          [accessControlList]="accessControlList"
          [teamsData]="teamsData"
        >
        </app-edit-user-profile>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Additional Information">
    <ng-template kendoTabContent>
      <div class="content">
        <app-view-personal-details
          *ngIf="data && data.mode == 'view'"
          [data]="data"
        ></app-view-personal-details>
        <app-edit-personal-details
          *ngIf="data && data.mode == 'edit'"
          [data]="data"
        ></app-edit-personal-details>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
