import { Component, Input, OnInit, ViewChild } from "@angular/core";
@Component({
  selector: "app-warning-zone",
  templateUrl: "./warning-zone.component.html",
  styleUrls: ["./warning-zone.component.scss"],
})
export class WarningZoneComponent implements OnInit {
  @Input() public opened = false;

  @Input() public text: string = "Please select a date range.";

  constructor() {}

  ngOnInit(): void {}
}

export interface warningZoneArgs {
  showWarning: boolean;
  warningText: string;
}
