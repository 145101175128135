import { Component, OnInit } from "@angular/core";
import { BaseConfigurablesListComponent } from "src/app/components/new-product/base/base-configurables-list/base-configurables-list.component";
import { MfHkConfigEditCriteria } from "src/app/components/shared/partials/configurable-form-edit/mf-hk-config-edit-criteria";
import { ConfigurableMode } from "src/app/framework/enum.shared";

@Component({
  selector: "mf-hk-extra-peril-list",
  templateUrl: "./extra-peril-list.component.html",
  styleUrls: ["./extra-peril-list.component.scss"],
})
export class ExtraPerilListComponent
  extends BaseConfigurablesListComponent
  implements OnInit
{
  public coverageTypeOptions: Array<{ text: string; value: string }> = [
    { text: "Fire", value: "84B90097-F4C7-4AAA-9B99-71FE8CD7355E" },
  ];

  override ngOnInit(): void {
    this.model.typeId = ConfigurableMode.ExtraPerils; //do before super.ngOnInit() for loading list

    super.ngOnInit();
  }

  addNewExtraPeril(val: any) {
    this.editModel = new MfHkConfigEditCriteria(); //reset

    this.isAdd = true;
  }
}
