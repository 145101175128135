export class UserProfile {
  UserId!: number;
  OrganisationId!: number;
  OrganisationName!: string;
  UserLogin!: string;
  UserName!: string;
  Email!: string;
  active!: boolean;
  lastLoginTime!: Date;
  ipAddress!: string;
  receiveEmail!: boolean;
  Preferences!: string;
  builderUser!: boolean;
  webServiceUser!: boolean;
  disabled!: boolean;
  meridianCode!: string;
  geniusCode!: string;
  personalId!: string;
  mobileNumber!: string;
  blackList!: boolean;
  organisationGroupId!: string;
  isDefault!: string;
}
export class UserProfileList {
  Items: UserProfile[];
  constructor() {
    this.Items = new Array<UserProfile>();
  }
}
export class UserDetailsModel {
  userId!: number;

  /// <summary>
  /// UserLogin
  /// </summary>
  userName!: string;

  password!: string;

  active!: boolean;

  email!: string;

  organisatoinId!: number;

  // for underwriter used only
  mappedOrganisationId!: number;

  productsByOrganisation!: SelectedProducts[];
  productIds!: Array<string>;
  userOrganisation!: number | undefined;
  meridianCode!: string;

  geniusCode!: string;

  receiveEmail!: boolean;

  blackListed!: boolean;

  underwriterGroup!: UnderwriterControlGroupModel;

  notifyUserCreation!: boolean;
  isNotifyCreation!: boolean;

  /// <summary>
  /// UserName
  /// </summary>
  name!: string;

  confirmPassword!: string;

  isSuperAgent!: boolean;

  disabled!: boolean;

  suspended!: boolean;

  isPrulia!: boolean;

  accessGroupId!: number;
  displayAccessGroup!: string;
  locked!: boolean;

  userCountry?: string;
  displayUserCountry!: string;

  //DBS Admin Screen Enable Changes
  roles!: SelectedRoles[];
  roleIds!: Array<number>;

  isPasswordExpired!: boolean;

  currentPassword!: string;
  //End

  //Added FP007222
  userTeamId!: number;
  teamId!: number;
  userTeam: any;
  //End FP007222

  authType!: string;

  mobileNumber!: string;
  userType: any;
}
export class SelectedRoles {
  text!: string;

  value!: string;
}
export class UnderwriterControlGroupModel {
  groupId!: number;
  groupName!: string;
  groupDetails!: UnderwriterControlGroupDetailsModel[];
}
export class UnderwriterControlGroupDetailsModel {
  groupDetailsId!: number;

  type!: number;

  productId!: string;

  productName!: string;

  defaultMinLimit!: number;

  defaultMaxLimit!: number;

  overwrittenMinLimit!: number;

  overwrittenMaxLimit!: number;

  coverageCode!: string;

  startEffectiveDate!: Date;

  endEffectiveDate!: Date;

  coverageProductMappingId!: string;

  coverageDisplayName!: string;

  parentProductId!: string;

  parentProductName!: string;
}
export class SelectedProducts {
  text!: string;
  value!: string;
}
export class UserProfileModel {
  userDetails!: UserDetailsModel;
  person!: PersonModel;
  subUser: any;
  userType!: string;
  underwriter?: UnderwriterModel;
  broker?: BrokerModel;
  marketer?: MarketerModel;
  systemAdmin?: SystemAdministratorModel;
}

//UnderwriterModelFactory
export class UnderwriterModel {
  organisationByProducts: any;
  meridianCode: any;
  geniusCode: any;
  mode: any;
}

//BrokerModelFactory
export class BrokerModel {
  organisationId!: number;
  organisationName: any;
  product: any;
}

//MarketerModelFactory
export class MarketerModel {
  marketerId: any;
  marketerCode: any;
  branchId: any;
  organisationByProducts: any;
  mode: any;
}

export class SystemAdministratorModel {
  organisationId!: number;
  organisationName: any;
  product: any;
  isSuperUser: any;
  userRole: any;
}

export class PersonModel {
  constructor() {
    this.addresses = new Array<AddressModel>();
    this.userDetails = new UserDetailsModel();
  }

  personId!: number;

  userDetails!: UserDetailsModel;

  displayName!: string;

  firstName!: string;

  lastName!: string;

  fullName!: string;

  maritalStatus!: number;
  displayMaritalStatus!: string;

  nameOfSpouse!: string;

  education!: string;

  passportNo!: string;

  dateOfBirth?: Date | string;

  gender!: number;
  displayGender!: string;

  salutation!: string;

  nationality!: string;
  displayNationality!: string;

  occupation!: string;

  race!: string;

  noOfChildren!: string;

  hobbies!: string;

  lastModifiedDate!: Date;

  status!: string;

  addresses!: AddressModel[];

  taboo!: string;

  mobileNo!: string;

  email!: string;

  polisyAsiaClientTypeMapping!: string;

  clientNumber!: string;

  categoryID?: number;
}
export class AddressModel {
  addressId!: number;

  addressCategoryId!: number;

  addressTypeId!: number;

  addressLine1!: string;

  addressLine2!: string;

  addressLine3!: string;

  addressLine4!: string;

  city!: string;

  expiryDate!: Date;

  postcode!: string;

  startDate!: Date;

  state!: string;

  country!: string;

  lastUpdatedDate!: Date;

  polisyAsiaAddressTypeId!: number;

  subDistrict!: string;
}
export class ApiModel {
  organisationName!: string;
  userTypeDesc!: string;
  userType!: string | number;
  userProfile!: UserProfileModel;
}
