import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { ApiService } from "src/app/services/dal/api.service";
import * as Enums from "./../../../../../framework/enum.shared";

export class PrintTypeModel {
  printTypeId!: number;
  printTypeValue!: string;
  description!: string;
  showEmail!: boolean;
}
@Injectable({
  providedIn: "root",
})
export class MortgageFireSingaporeProposalPrintService {
  constructor(private apiService: ApiService) {}

  postPrintOptions(
    proposalEntity: MortgageFireSingaporeProposal
  ): Observable<boolean> {
    if (
      proposalEntity.transType === Enums.TransType.CAN &&
      proposalEntity.endorsementType === 3
    ) {
      //Endorsement Type 3 = Edit Correspondance address
      return of(true);
    }
    var uri = "api/proposal/sendPrintOptions";
    const postData = {
      proposalId: proposalEntity.proposalId!.toString(),
      printType: proposalEntity.printType,
      messageId:
        proposalEntity.messageId == undefined ? 0 : proposalEntity.messageId,
      printCorrespondence: proposalEntity.printCorrespondence,
      isReprint: proposalEntity.isReprint,
      isSummary: proposalEntity.isSummary,
      isManual: proposalEntity.isManual,
      isPasswordProtected: proposalEntity.isPasswordProtected,
      fileIdsJsonString: null,
    };
    return this.apiService.search(uri, {}, postData).pipe(
      map(
        (response: any) => {
          return response!;
        },
        (error: any) => {
          return error;
        }
      )
    );
  }

  // getPrintTypeModel(): ng.IPromise<PrintTypeModel[]> {
  //     const deffer = this.$q.defer<PrintTypeModel[]>();
  //     var uri = 'api/proposal/getPrintTypeModels';
  //     this.api.getApiData<PrintTypeModel[]>(uri)
  //         .then((response) => {
  //             deffer.resolve(response);
  //         }, (error) => {
  //             deffer.reject(error);
  //         });
  //     return deffer.promise;
  // }
}
