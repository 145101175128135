import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-md-successful-file-upload",
  templateUrl: "./md-successful-file-upload.component.html",
  styleUrls: ["./md-successful-file-upload.component.scss"],
})
export class MdSuccessfulFileUploadComponent implements OnInit {
  @Input() public opened = false;
  @Input() public data: any;
  @Input() public message: any;
  public isSuccess: boolean = false;
  validationMessage: any = [];

  @Output() closeDialogEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    //has to be done here, not constructor() since UI/input not yet ready
    this.isSuccess = this.data != null ? this.data.status : false;
    if (this.data.validationMessage) {
      this.validationMessage = Object.keys(this.data.validationMessage).map(
        (key) => ({ value: this.data.validationMessage[key] })
      );
    }
  }

  public close(status: any) {
    this.opened = false;

    let savParamters: dlgModelArgs = {
      status: status,
      data: this.data,
    };
    this.closeDialogEvent.emit(savParamters);
  }

  public open() {
    this.opened = true;
  }
}

export interface dlgModelArgs {
  status: string;
  data: any; //ProposalEntity[]
}
