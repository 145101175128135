<kendo-dialog
  title="Customer Timeline"
  class="modal-title-light-orange"
  (close)="getMessageAndClose('cancel')"
  [width]="850"
  [height]="450"
>
  <div class="modal-body container-overflow-y">
    <div>
      <div class="">
        <form class="" role="form">
          <div class="form-group">
            <div class="fontBold">
              <label for="Upgrade" class="required">Upgrade to Multiyear</label>
            </div>
            <input
              type="radio"
              name="productRadio"
              value="true"
              [(ngModel)]="model.clientTimeline[0].timelineConsent"
              ng-change="model.onChange_ProductRadio()"
            />
            <label class="marginRight20" for="oiProductRadio">Yes</label>
            <input
              type="radio"
              name="productRadio"
              value="false"
              [(ngModel)]="model.clientTimeline[0].timelineConsent"
              ng-change="model.onChange_ProductRadio()"
            />
            <label class="marginRight20" for="oiProductRadio">No</label>
          </div>
          <div class="form-group">
            <div class="fontBold">
              <label for="content" class="required">Comments</label>
            </div>
            <textarea
              name="contentText"
              [(ngModel)]="model.clientTimeline[0].timelineComments"
              rows="5"
              cols="40"
              class="form-control"
              maxlength="500"
            ></textarea>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        (click)="submit()"
      >
        Add
      </button>
      <button
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel"
        kendoButton
        (click)="getMessageAndClose('no')"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
