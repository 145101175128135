import { Component, Input, OnInit } from "@angular/core";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";

@Component({
  selector: "currency-box-conditional-question",
  templateUrl: "./currency-box-conditional-question.component.html",
  styleUrls: ["./currency-box-conditional-question.component.scss"],
})
export class CurrencyBoxConditionalQuestionComponent implements OnInit {
  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;
  @Input() public question!: QuestionEntity;
  @Input() public condition!: boolean;

  constructor() {}

  ngOnInit(): void {}

  //https://stackoverflow.com/questions/40341648/angular-2-how-to-bind-input-to-model-property-with-getter-and-setter-methods
  // Getter method
  public get QuestionAnswerFormatted() {
    return Number(this.question.answer);
  }

  // Setter method
  public set QuestionAnswerFormatted(val: number) {
    // strip out all the currency information
    //const revenueVal = val.replace(/[^\d]/g, '');
    //this.AnnualRevenue = parseInt(revenueVal);
    this.question.answer = String(val);
  }
}
