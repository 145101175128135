<div class="row marginLeft15" *ngIf="isDataLoaded">
  <div class="k-i-loading"></div>
</div>
<div *ngIf="!isDataLoaded" class="col-md-12">
  <div class="card-head chubbWhite_bg chubbLightGray_border">
    <div class="tools">
      <div class="btn-group"></div>
    </div>
    <header class="chubbDarkBlue">Profile</header>
  </div>
  <div class="card-body chubbLightGray_border">
    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
      <div style="padding: 10px">
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label class="required" [innerHtml]="'User Login'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="userName"
                name="userName"
                placeholder=""
                type="text"
                [(ngModel)]="userDetails.userName"
                class="k-textbox form-control"
                (blur)="checkUser()"
                (keypress)="onKeypressEvent($event)"
                required
              />
              <kendo-formhint></kendo-formhint>
              <kendo-formerror
                ><span class="k-icon-custom k-i-warning"></span>Enter
                UserName</kendo-formerror
              >
            </kendo-formfield>
          </div>
          <div
            class="col-md-3"
            [ngClass]="isUserExists ? 'lblColorRed' : 'lblColorBlack'"
          >
            {{ isUserAvailable }}
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Team'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-combobox
                [data]="teamsData"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                placeholder="--Type to search"
                [valuePrimitive]="true"
                [(ngModel)]="userDetails.userTeam"
                name="userTeam"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row" style="padding: inherit">
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Email'"></label>
          </div>
          <div class="col-md-4">
            <kendo-formfield orientation="horizontal">
              <input
                id="email"
                name="email"
                placeholder=""
                type="text"
                [(ngModel)]="userDetails.email"
                class="k-textbox form-control"
                [disabled]="true"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Receive Email'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="receiveEmail"
              type="checkbox"
              value="{{ userDetails.receiveEmail }}"
              [(ngModel)]="userDetails.receiveEmail"
              id="receiveEmail"
            />
          </div>
        </div>
        <div
          *ngIf="userDetails.mobileNumber"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label [innerHtml]="'Mobile Number'"></label>
          </div>
          <div class="col-md-3">{{ userDetails.mobileNumber }}</div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label class="required" [innerHtml]="'User Type'"></label>
          </div>
          <div class="col-md-3">{{ model.userTypeDesc }}</div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Country'"></label>
          </div>
          <div class="col-md-3">{{ userDetails.displayUserCountry }}</div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Organisation'"></label>
          </div>
          <div class="col-md-3">{{ model.organisationName }}</div>
        </div>
        <div
          *ngIf="model.userType != 1"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label class="required" [innerHtml]="title"></label>
          </div>
          <div class="col-md-4" style="display: grid">
            <kendo-formfield orientation="horizontal">
              <kendo-multiselect
                id="product"
                placeholder="Select product..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="productData"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="userDetails.productIds"
                name="productIds"
                required
              >
              </kendo-multiselect>
              <kendo-formhint></kendo-formhint>
              <kendo-formerror
                ><span class="k-icon-custom k-i-warning"></span>You must select
                at least one product for this user.</kendo-formerror
              >
            </kendo-formfield>
          </div>
        </div>
        <div
          *ngIf="model.userType != 1"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label
              [ngClass]="model.userType != '3' ? 'required' : ''"
              [innerHtml]="'Roles'"
            ></label>
          </div>
          <div
            *ngIf="model.userType != '3'"
            class="col-md-4"
            style="display: grid"
          >
            <kendo-formfield orientation="horizontal">
              <kendo-multiselect
                id="roles"
                placeholder="Select roles..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="roleData"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="userDetails.roleIds"
                name="roleIds"
                required
              >
              </kendo-multiselect>
              <kendo-formerror
                ><span class="k-icon-custom k-i-warning"></span>You must select
                at least one role for this user.</kendo-formerror
              >
            </kendo-formfield>
          </div>
          <div
            *ngIf="model.userType == '3'"
            class="col-md-4"
            style="display: grid"
          >
            <kendo-multiselect
              id="roles"
              placeholder="Select roles..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="roleData"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="userDetails.roleIds"
              name="roleIds"
            >
            </kendo-multiselect>
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Active'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="active"
              type="checkbox"
              [value]="userDetails.active"
              [(ngModel)]="userDetails.active"
              (change)="toggleDisabled()"
              id="active"
            />
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Disabled'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="disabled"
              type="checkbox"
              [value]="userDetails.disabled"
              [(ngModel)]="userDetails.disabled"
              [disabled]="userDetails.active"
              id="disabled"
            />
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Suspended'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="suspended"
              type="checkbox"
              value="{{ userDetails.suspended }}"
              [(ngModel)]="userDetails.suspended"
              id="suspended"
            />
          </div>
        </div>
        <div
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
          [hidden]="model.userType != 1"
        >
          <div class="col-md-3">
            <label [innerHtml]="'Access Group'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-dropdownlist
              [data]="accessControlList"
              textField="text"
              valueField="value"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [valuePrimitive]="true"
              [(ngModel)]="userDetails.accessGroupId"
              name="accessGroupId"
            >
            </kendo-dropdownlist>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
