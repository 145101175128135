export const openItemDisplayStatus: { [key: number]: string } = {
  1: "Pending Auto Debit",
  2: "Pending Invoice To Bank",
  3: "Pending To Invoice To Individual",
  4: "Pending Refund To Bank",
  5: "Auto Debited",
  6: "Manual Paid",
  7: "Invoiced To Bank",
  8: "Invoiced To Individual",
  9: "Refunded",
  10: "Reconciled",
  11: "Pending Manual Pay By Cheque",
  12: "Manual Paid Non Auto Debit File Sent",
  13: "Pending Refund To Individual",
  14: "Unpaid Cheque To Bank",
  15: "Unpaid Cheque To Individual",
  16: "No Refund",
  17: "Refunded Cheque",
  18: "Meridian Auto Offset",
  19: "Cheque Payment Auto Updated",
  20: "Free Insurance Auto Updated",
  21: "Pending Refund To Individual In Review",
  22: "Pending Refund To Bank In Review",
  23: "Pending Payment", //new for hase
  24: "Sent for Collection", //new for hase
  //25: "Manually Paid", //6
  //26: "Reconciled", //10
  25: "Pending Refund", //new for hase
  //28: "Refunded", //9
};
