// 'use strict';

import { IIdentity } from "../framework/interface.shared";

// import * as Dialog from '../application/core/components/dialog/dialog';

// import { AppSettings } from '../appSettings';
// import { Application } from '../application';
import "../shared/ext/extensions";
// let angular: ng.IAngularStatic = Application.angular;

class Global {
  public static readonly DEFAULT_GUID: string =
    "00000000-0000-0000-0000-000000000000";

  //     /** Variable to stored dialog */
  //     private _dialog: Dialog.IDialog;

  //     /** Running number pool, for unique id generation purposes */
  //     private static _numPool: number = 0;

  //     detectBrowserMode(): void {
  //         return window["myapp"].detectBrowserMode();
  //     }

  //     static validateIE11() {
  //         return (!!navigator.userAgent.match(/Trident\/7\./));
  //     }

  //     /** Open Processing modal window to block user from interact with the UI */
  //     openProcessingModal(processingMsg?: string): void {
  //         if (!angular.isDefined(processingMsg))
  //             processingMsg = 'Processing...';

  //         let data = { msg: "" };
  //         data.msg = processingMsg;
  //         this._dialog = Dialog.DialogModal.Instance.modalMedium(
  //             'shared/uiControls/dialog/partials/customModal.html',
  //             'CustomModalController',
  //             data, undefined, undefined, undefined, undefined, false, 'standardModal');

  //     };

  //     public static session: { [key: string]: any; } = {};

  //     static isReleaseMode(): boolean {
  //         const key: string = "IsReleaseMode";
  //         if (Global.isDefined(this.session[key])) {
  //             return <boolean>this.session[key];
  //         }

  //         this.session[key] = AppSettings.appSettings.environment.toLowerCase() === 'release';
  //         return this.session[key];
  //     }

  //     static openProcessingOverlay(): void {
  //         $(".overlay").show();
  //         /*to disable the scrollbar*/
  //         $('html, body').css('overflow', 'hidden');
  //     }

  //     static closeProcessingOverlay(): void {
  //         /*to enable the scrollbar*/
  //         $('html, body').css('overflow', 'auto');
  //         $(".overlay").hide();
  //     }

  //     /** Close opened Processing modal window */
  //     closeProcessingModal(): void {

  //         if (angular.isDefined(this._dialog)) {
  //             this._dialog.close('processing done');
  //             this._dialog = undefined;
  //         }
  //     }

  //     static getQueryStringValue(key) {
  //         return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  //     }

  //     static formatDateToString(date): string {
  //         // 01, 02, 03, ... 29, 30, 31
  //         let dd = (new Date(date).getDate() < 10 ? '0' : '') + new Date(date).getDate();
  //         // 01, 02, 03, ... 10, 11, 12
  //         let MM = ((new Date(date).getMonth() + 1) < 10 ? '0' : '') + (new Date(date).getMonth() + 1);
  //         // 1970, 1971, ... 2015, 2016, ...
  //         let yyyy = new Date(date).getFullYear();

  //         // create the format you want
  //         return (yyyy + "-" + MM + "-" + dd);
  //     }

  //     static convertDateToDDMMYYYY(date): string {
  //         // 01, 02, 03, ... 29, 30, 31
  //         let dateSplit = date.split('-');
  //         return dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0];
  //     }

  //     static generateUniqueId(): number {
  //         this._numPool++;
  //         return this._numPool;
  //     }

  //     /** Method to deep clone an object */
  //     static objClone(objectToClone) {

  //         if (!this.isDefined(objectToClone)
  //             && !this.isObject(objectToClone)) {
  //             return objectToClone;
  //         }

  //         let clone = {};
  //         Application.angular.copy(objectToClone, clone);

  //         return clone;
  //     };

  //     /** Method to deep clone an object */
  //     static arrayClone(arrayToClone) {

  //         if (!this.isDefined(arrayToClone)
  //             && !Application.angular.isArray(arrayToClone)) {
  //             return arrayToClone;
  //         }

  //         let clone = [];
  //         Application.angular.copy(arrayToClone, clone);

  //         return clone;
  //     };

  //     /** Trim text */
  //     static trim(text): string {
  //         if (!angular.isDefined(text))
  //             return '';
  //         var trimText = text.toString();
  //         return trimText.replace(/^\s+|\s+$/g, '');
  //     };

  //     public trim(text): string {
  //         if (!angular.isDefined(text))
  //             return '';
  //         var trimText = text.toString();
  //         return trimText.replace(/^\s+|\s+$/g, '');
  //     };

  /** Check if is Date data type */
  static isDate(value: number | Object): boolean {
    return value instanceof Date && !isNaN(value.valueOf());
  }

  /** Check if is Object data type */
  static isObject(value: null): boolean {
    // http://jsperf.com/isobject4
    return value !== null && typeof value === "object";
  }

  //     /** Check if is string type */
  //     static isString(value): boolean {
  //         // http://jsperf.com/isobject4
  //         return value !== null && typeof value === 'string';
  //     };

  //     /** Convert object to "" if undefined/null, otherwise to normal string */
  //     static toNullString(value: any): string {
  //         if (this.isUndefined(value)) return "";
  //         return value.toString();
  //     };

  /** Map between string "1" and "0" TO true and false */
  static toBoolean(value: string): boolean {
    if (Global.isDefined(value)) return value.toBoolean();
    return false;
  }

  //     /** check if value is json string */
  //     static isJSON(str: any): boolean {
  //         try {
  //             JSON.parse(str);
  //             if (!isNaN(parseInt(JSON.parse(str)))) {
  //                 return false;
  //             }
  //         } catch (e) {
  //             return false;
  //         }
  //         return true;
  //     }

  /** Function to serialize object to string */
  static objectToJson(value: any): string {
    if (Global.isObject(value)) {
      return JSON.stringify(value);
    }

    return "";
  }

  //     /** Function to json string to object */
  //     static jsonToOBject<T>(value: any): T {
  //         if (Global.isUndefined(value)) {
  //             return value;
  //         }
  //         return <T>JSON.parse(value);
  //     }

  static convertToApiStandardValue(value: Object): string {
    let typeOf: string = typeof value;

    if (this.isDate(value)) {
      typeOf = "date";
    }

    switch (typeOf) {
      case "string":
        return value.toString();

      case "number":
        return (<number>value).toApiValue();

      case "boolean":
        return (<boolean>value).toApiValue();

      case "date":
        return (<Date>value).toApiValue();

      case "object":
        break;

      default:
        break;
    }
    return Global.objectToJson(value);
  }

  static isDefined(valueToCheck: any | null): boolean {
    if (typeof valueToCheck !== "undefined" && valueToCheck !== null)
      return true;
    else return false;
  }

  static isUndefined(valueToCheck: any | null): boolean {
    return !Global.isDefined(valueToCheck);
  }

  //     static isFunction(valueToCheck): boolean {
  //         if (angular.isFunction(valueToCheck))
  //             return true;
  //         else return false;
  //     }

  //     static groupBy(list: Array<any>, groupByProperty: any) {
  //         let groups = Array;
  //         list.forEach((o) => {

  //             let groupValue = o[groupByProperty];
  //             if (Global.isDefined(groupValue) && groupValue != "") {
  //                 groups[groupValue] = groups[groupValue] || [];
  //                 if (groups[groupValue].length >= 0) {
  //                     return;
  //                 } else {
  //                     groups[groupValue].push(o[groupByProperty]);
  //                 }
  //             }

  //         });
  //         return Object.keys(groups).map(function (group) {

  //             return groups[group];
  //         })
  //     }

  //     static arraySortAscending(value: number[]) {
  //         value.sort((n1, n2) => {
  //             if (n1 > n2) {
  //                 return 1;
  //             }

  //             if (n1 < n2) {
  //                 return -1;
  //             }

  //             return 0;
  //         });
  //     }

  //     /** Method to destroy on screen KendoUi component */
  //     static kendoUiDestroy() {
  //         let kendoDropDownList = $('[controlType="kendoDropDownList"]').data('kendoDropDownList');
  //         if (kendoDropDownList) {
  //             kendoDropDownList.destroy();
  //             kendo.destroy(kendoDropDownList.wrapper);
  //             kendoDropDownList.wrapper.remove();
  //         }

  //         let kendoNumericTextBox = $('[controlType="kendoNumericTextBox"]').data('kendoNumericTextBox');
  //         if (kendoNumericTextBox) {
  //             kendoNumericTextBox.destroy();
  //             kendo.destroy(kendoNumericTextBox.wrapper);
  //             kendoNumericTextBox.wrapper.remove();
  //         }
  //     }
}

export { Global };
