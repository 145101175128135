// Custom Messages
export const enCustomMessages = {
  teamEfficiency: "Team Efficiency",
  teamMembers: "Team Members",
  dashboard: "Dashboard",
  planning: "Planning",
  profile: "Profile",
  info: "Info",
  language: "Language",
  trend: "Trend",
  volume: "Volume",
  myTeam: "My Team",
  allTeams: "All Teams",
  teamCalendar: "Team Calendar",
  saveChanges: "Save Changes",
  cancel: "Cancel",
  warehouse: "app",
  startFreeTrial: "Start Free Trial",
  buyNow: "Buy Now",
  getSource: "Get the source code on GitHub",
  demoInfo:
    "This demo application is built using the following Kendo UI for Angular components.",
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  email: "Email Address",
  phoneNumber: "Phone Number",
  country: "Country",
  biography: "Short Biography",
  public: "Include in public directory",
  team: "Team",
  employee: "Employee",
  contactName: "Contact Name",
  jobTitle: "Job Title",
  status: "Status",
  performance: "Performance",
  rating: "Rating",
  engagement: "Engagement",
  budget: "Budget",
  contacts: "Contacts",
  address: "Address",
  phone: "Phone",
  gridSearch: "Search in all columns...",
  exportExcel: "Export to Excel",
  exportPdf: "Export to PDF",
};

// Kendo Angular Component Messages
export const enComponentMessages = {
  // Grid
  "kendo.grid.groupPanelEmpty":
    "Drag a column header and drop it here to group by that column",
  "kendo.grid.noRecords": "No records available.",
  "kendo.grid.pagerFirstPage": "Go to the first page",
  "kendo.grid.pagerPreviousPage": "Go to the previous page",
  "kendo.grid.pagerNextPage": "Go to the next page",
  "kendo.grid.pagerLastPage": "Go to the last page",
  "kendo.grid.pagerPage": "Page",
  "kendo.grid.pagerOf": "of",
  "kendo.grid.pagerItems": "items",
  "kendo.grid.pagerItemsPerPage": "items per page",
  "kendo.grid.pagerPageNumberInputTitle": "Page Number",
  "kendo.grid.filter": "Filter",
  "kendo.grid.filterEqOperator": "Is equal to",
  "kendo.grid.filterNotEqOperator": "Is not equal to",
  "kendo.grid.filterIsNullOperator": "Is null",
  "kendo.grid.filterIsNotNullOperator": "Is not null",
  "kendo.grid.filterIsEmptyOperator": "Is empty",
  "kendo.grid.filterIsNotEmptyOperator": "Is not empty",
  "kendo.grid.filterStartsWithOperator": "Starts with",
  "kendo.grid.filterContainsOperator": "Contains",
  "kendo.grid.filterNotContainsOperator": "Does not contain",
  "kendo.grid.filterEndsWithOperator": "Ends with",
  "kendo.grid.filterGteOperator": "Is greater than or equal to",
  "kendo.grid.filterGtOperator": "Is greater than",
  "kendo.grid.filterLteOperator": "Is less than or equal to",
  "kendo.grid.filterLtOperator": "Is less than",
  "kendo.grid.filterIsTrue": "is true",
  "kendo.grid.filterIsFalse": "is false",
  "kendo.grid.filterBooleanAll": "(All)",
  "kendo.grid.filterAfterOrEqualOperator": "Is after or equal to",
  "kendo.grid.filterAfterOperator": "Is after",
  "kendo.grid.filterBeforeOperator": "Is before",
  "kendo.grid.filterBeforeOrEqualOperator": "Is before or equal to",
  "kendo.grid.filterFilterButton": "Filter",
  "kendo.grid.filterClearButton": "Clear",
  "kendo.grid.filterAndLogic": "And",
  "kendo.grid.filterOrLogic": "Or",
  "kendo.grid.filterDateToggle": "Toggle calendar",
  "kendo.grid.filterDateToday": "Today",
  "kendo.grid.filterNumericDecrement": "Decrease value",
  "kendo.grid.filterNumericIncrement": "Increase value",
  "kendo.grid.loading": "Loading",
  "kendo.grid.sort": "Sort",
  "kendo.grid.columnMenu": "Column Menu",
  "kendo.grid.columns": "Columns",
  "kendo.grid.lock": "Lock",
  "kendo.grid.unlock": "Unlock",
  "kendo.grid.stick": "Stick",
  "kendo.grid.unstick": "Unstick",
  "kendo.grid.setColumnPosition": "Set Column Position",
  "kendo.grid.sortAscending": "Sort Ascending",
  "kendo.grid.sortDescending": "Sort Descending",
  "kendo.grid.columnsApply": "Apply",
  "kendo.grid.columnsReset": "Reset",
  "kendo.grid.sortable": "Sortable",
  "kendo.grid.sortedAscending": "Sorted ascending",
  "kendo.grid.sortedDescending": "Sorted descending",
  "kendo.grid.sortedDefault": "Not sorted",

  // DropDowns
  "kendo.autocomplete.noDataText": "No data found",
  "kendo.autocomplete.clearTitle": "Clear",
  "kendo.combobox.noDataText": "No data found",
  "kendo.combobox.clearTitle": "Clear",
  "kendo.dropdownlist.noDataText": "No data found",
  "kendo.dropdownlist.clearTitle": "Clear",

  // Calendar
  "kendo.calendar.today": "TODAY",

  // TextBox
  "kendo.textbox.clearTitle": "Clear",

  // DatePicker
  "kendo.datepicker.today": "TODAY",
  "kendo.datepicker.toggle": "Toggle calendar",

  // DateInput
  "kendo.dateinput.increment": "Increase value",
  "kendo.dateinput.decrement": "Decrease value",

  // NumericTextBox
  "kendo.numerictextbox.increment": "Increase value",
  "kendo.numerictextbox.decrement": "Decrease value",

  // DateTimePicker
  "kendo.datetimepicker.dateTab": "Date",
  "kendo.datetimepicker.dateTabLabel": "Date tab",
  "kendo.datetimepicker.timeTab": "Time",
  "kendo.datetimepicker.timeTabLabel": "Time tab",
  "kendo.datetimepicker.toggle": "Toggle popup",
  "kendo.datetimepicker.accept": "Set",
  "kendo.datetimepicker.acceptLabel": "Set",
  "kendo.datetimepicker.cancel": "Cancel",
  "kendo.datetimepicker.cancelLabel": "Cancel",
  "kendo.datetimepicker.now": "NOW",
  "kendo.datetimepicker.nowLabel": "Select now",
  "kendo.datetimepicker.today": "TODAY",
  "kendo.datetimepicker.prevButtonTitle": "Navigate to previous view",
  "kendo.datetimepicker.nextButtonTitle": "Navigate to next view",

  // TimePicker
  "kendo.timepicker.accept": "Set",
  "kendo.timepicker.acceptLabel": "Set",
  "kendo.timepicker.cancel": "Cancel",
  "kendo.timepicker.cancelLabel": "Cancel",
  "kendo.timepicker.now": "Now",
  "kendo.timepicker.nowLabel": "Now",
  "kendo.timepicker.toggle": "Toggle time list",

  // FileSelect
  "kendo.fileselect.cancel": "Cancel",
  "kendo.fileselect.clearSelectedFiles": "Clear",
  "kendo.fileselect.dropFilesHere": "Drop files here to upload",
  "kendo.fileselect.externalDropFilesHere":
    "Drag and drop files here to upload",
  "kendo.fileselect.filesBatchStatus": "files",
  "kendo.fileselect.filesBatchStatusFailed": "files failed to upload.",
  "kendo.fileselect.filesBatchStatusUploaded": "files successfully uploaded.",
  "kendo.fileselect.fileStatusFailed": "File failed to upload.",
  "kendo.fileselect.fileStatusUploaded": "File successfully uploaded.",
  "kendo.fileselect.headerStatusPaused": "Paused",
  "kendo.fileselect.headerStatusUploaded": "Done",
  "kendo.fileselect.headerStatusUploading": "Uploading...",
  "kendo.fileselect.invalidFileExtension": "File type not allowed.",
  "kendo.fileselect.invalidMaxFileSize": "File size too large.",
  "kendo.fileselect.invalidMinFileSize": "File size too small.",
  "kendo.fileselect.remove": "Remove",
  "kendo.fileselect.retry": "Retry",
  "kendo.fileselect.select": "Select files...",
  "kendo.fileselect.uploadSelectedFiles": "Upload files",

  // Scheduler
  "kendo.scheduler.agendaViewTitle": "Agenda",
  "kendo.scheduler.allDay": "all day",
  "kendo.scheduler.allEvents": "All events",
  "kendo.scheduler.calendarToday": "TODAY",
  "kendo.scheduler.cancel": "Cancel",
  "kendo.scheduler.save": "Save",
  "kendo.scheduler.editorEventTitle": "Title",
  "kendo.scheduler.editorEventStart": "Start",
  "kendo.scheduler.editorEventStartTimeZone": "Start Time Zone",
  "kendo.scheduler.editorEventEnd": "End",
  "kendo.scheduler.editorEventEndTimeZone": "End Time Zone",
  "kendo.scheduler.dateHeader": "Date",
  "kendo.scheduler.dayViewTitle": "Day",
  "kendo.scheduler.deleteConfirmation":
    "Are you sure you want to delete this event?",
  "kendo.scheduler.deleteDialogTitle": "Delete Event",
  "kendo.scheduler.deleteOccurrence": "Delete current occurrence",
  "kendo.scheduler.deleteRecurringConfirmation":
    "Do you want to delete only this event occurrence or the whole series?",
  "kendo.scheduler.deleteRecurringDialogTitle": "Delete Recurring Item",
  "kendo.scheduler.deleteSeries": "Delete the series",
  "kendo.scheduler.deleteTitle": "Delete",
  "kendo.scheduler.destroy": "Delete",
  "kendo.scheduler.editOccurrence": "Edit current occurrence",
  "kendo.scheduler.editorEventAllDay": "All Day Event",
  "kendo.scheduler.editorEventDescription": "Description",
  "kendo.scheduler.editorEventSeparateTimeZones": "End in different Time Zone",
  "kendo.scheduler.editorEventTimeZone": "Specify Time Zone",
  "kendo.scheduler.editorTitle": "Event",
  "kendo.scheduler.editRecurringConfirmation":
    "Do you want to edit only this event occurrence or the whole series?",
  "kendo.scheduler.editRecurringDialogTitle": "Edit Recurring Item",
  "kendo.scheduler.editSeries": "Edit the series",
  "kendo.scheduler.eventHeader": "Event",
  "kendo.scheduler.monthViewTitle": "Month",
  "kendo.scheduler.multiDayViewTitle": "Multi-Day",
  "kendo.scheduler.nextTitle": "Next",
  "kendo.scheduler.previousTitle": "Previous",
  "kendo.scheduler.recurrenceEditorDailyInterval": "day(s)",
  "kendo.scheduler.recurrenceEditorDailyRepeatEvery": "Repeat every",
  "kendo.scheduler.recurrenceEditorEndAfter": "After",
  "kendo.scheduler.recurrenceEditorEndLabel": "End",
  "kendo.scheduler.recurrenceEditorEndNever": "Never",
  "kendo.scheduler.recurrenceEditorEndOccurrence": "occurrence(s)",
  "kendo.scheduler.recurrenceEditorEndOn": "On",
  "kendo.scheduler.recurrenceEditorFrequenciesDaily": "Daily",
  "kendo.scheduler.recurrenceEditorFrequenciesMonthly": "Monthly",
  "kendo.scheduler.recurrenceEditorFrequenciesNever": "Never",
  "kendo.scheduler.recurrenceEditorFrequenciesWeekly": "Weekly",
  "kendo.scheduler.recurrenceEditorFrequenciesYearly": "Yearly",
  "kendo.scheduler.recurrenceEditorMonthlyDay": "Day",
  "kendo.scheduler.recurrenceEditorMonthlyInterval": "month(s)",
  "kendo.scheduler.recurrenceEditorMonthlyRepeatEvery": "Repeat every",
  "kendo.scheduler.recurrenceEditorMonthlyRepeatOn": "Repeat on",
  "kendo.scheduler.recurrenceEditorOffsetPositionsFirst": "First",
  "kendo.scheduler.recurrenceEditorOffsetPositionsFourth": "Fourth",
  "kendo.scheduler.recurrenceEditorOffsetPositionsLast": "Last",
  "kendo.scheduler.recurrenceEditorOffsetPositionsSecond": "Second",
  "kendo.scheduler.recurrenceEditorOffsetPositionsThird": "Third",
  "kendo.scheduler.recurrenceEditorRepeat": "Repeat",
  "kendo.scheduler.recurrenceEditorWeekdaysDay": "Day",
  "kendo.scheduler.recurrenceEditorWeekdaysWeekday": "Weekday",
  "kendo.scheduler.recurrenceEditorWeekdaysWeekendday": "Weekend Day",
  "kendo.scheduler.recurrenceEditorWeeklyInterval": "week(s)",
  "kendo.scheduler.recurrenceEditorWeeklyRepeatEvery": "Repeat every",
  "kendo.scheduler.recurrenceEditorWeeklyRepeatOn": "Repeat on",
  "kendo.scheduler.recurrenceEditorYearlyInterval": "year(s)",
  "kendo.scheduler.recurrenceEditorYearlyOf": "of",
  "kendo.scheduler.recurrenceEditorYearlyRepeatEvery": "Repeat every",
  "kendo.scheduler.recurrenceEditorYearlyRepeatOn": "Repeat on",
  "kendo.scheduler.showFullDay": "Show full day",
  "kendo.scheduler.showWorkDay": "Show business hours",
  "kendo.scheduler.timeHeader": "Time",
  "kendo.scheduler.timelineMonthViewTitle": "Timeline Month",
  "kendo.scheduler.timelineViewTitle": "Timeline",
  "kendo.scheduler.timelineWeekViewTitle": "Timeline Week",
  "kendo.scheduler.today": "Today",
  "kendo.scheduler.weekViewTitle": "Week",
  "kendo.scheduler.workWeekViewTitle": "Work Week",

  // RecurrenceEditor
  "kendo.recurrenceeditor.dailyInterval": "day(s)",
  "kendo.recurrenceeditor.dailyRepeatEvery": "Repeat every",
  "kendo.recurrenceeditor.endAfter": "After",
  "kendo.recurrenceeditor.endLabel": "End",
  "kendo.recurrenceeditor.endNever": "Never",
  "kendo.recurrenceeditor.endOccurrence": "occurrence(s)",
  "kendo.recurrenceeditor.endOn": "On",
  "kendo.recurrenceeditor.frequenciesDaily": "Daily",
  "kendo.recurrenceeditor.frequenciesMonthly": "Monthly",
  "kendo.recurrenceeditor.frequenciesNever": "Never",
  "kendo.recurrenceeditor.frequenciesWeekly": "Weekly",
  "kendo.recurrenceeditor.frequenciesYearly": "Yearly",
  "kendo.recurrenceeditor.monthlyDay": "Day",
  "kendo.recurrenceeditor.monthlyInterval": "month(s)",
  "kendo.recurrenceeditor.monthlyRepeatEvery": "Repeat every",
  "kendo.recurrenceeditor.monthlyRepeatOn": "Repeat on",
  "kendo.recurrenceeditor.offsetPositionsFourth": "Fourth",
  "kendo.recurrenceeditor.offsetPositionsLast": "Last",
  "kendo.recurrenceeditor.offsetPositionsSecond": "Second",
  "kendo.recurrenceeditor.offsetPositionsThird": "Third",
  "kendo.recurrenceeditor.repeat": "Repeat",
  "kendo.recurrenceeditor.weekdaysDay": "Day",
  "kendo.recurrenceeditor.weekdaysWeekday": "Weekday",
  "kendo.recurrenceeditor.weekdaysWeekendday": "Weekend Day",
  "kendo.recurrenceeditor.weeklyInterval": "week(s)",
  "kendo.recurrenceeditor.weeklyRepeatEvery": "Repeat every",
  "kendo.recurrenceeditor.weeklyRepeatOn": "Repeat on",
  "kendo.recurrenceeditor.yearlyInterval": "year(s)",
  "kendo.recurrenceeditor.yearlyOf": "of",
  "kendo.recurrenceeditor.yearlyRepeatEvery": "Repeat every",
  "kendo.recurrenceeditor.yearlyRepeatOn": "Repeat on",
};
