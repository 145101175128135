// import * as Enums from './../../../../../../framework/enum.shared';

import { Component, Input } from "@angular/core";
import { Proposal } from "../../../../../../../../models/new-product/base/proposal.model";
import * as Enum from "../../../../../../../../framework/enum.shared";
import { MortgageFireSingaporeProposal } from "../../../../../../../../../app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { KeyValue } from "../../../../../../../../framework/domain-entity/key-value";
import { MfSgBaseComponent } from "../../mf-sg-base.component";
import { common } from "../../../../../../../../../app/framework/utils/common";
import { MfSgProposalService } from "../../../../../../../../../app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_PROPOSAL } from "../../../../../../../../../app/app.module";

export class TabModel {
  constructor(
    public index: number,
    public displayName: string,
    public visible: boolean
  ) {}
}

@Component({
  selector: "mf-sg-pending-transaction-panel",
  templateUrl: "./mf-sg-pending-transaction-panel.component.html",
  styleUrls: ["./mf-sg-pending-transaction-panel.component.scss"],
})
export class MfSgPendingTransactionPanelComponent extends MfSgBaseComponent {
  proposal!: MortgageFireSingaporeProposal;
  // private $state: angular.ui.IStateService;
  proposalPendingStatus: string = "";
  productId!: String;

  constructor(
    public proposalService: MfSgProposalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.route.queryParams.subscribe((params) => {
      this.productId = params["pdid"];
    });
  }

  ngOnDestroy = () => {
    super.unsubscribeRxjs();
  };

  ngOnInit = () => {
    this.initialize();
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalSavedNotifier(this.proposalLoaded);
  };

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireSingaporeProposal;
    if (
      common.isDefined(this.proposal.pendingTransaction) &&
      this.proposal.pendingTransaction != null
    ) {
      switch (this.proposal.pendingTransaction.transType) {
        case Enum.TransType.END:
          this.proposalPendingStatus = "Endorsement";
          break;
        case Enum.TransType.CAN:
          this.proposalPendingStatus = "Cancellation";
          break;
        case Enum.TransType.REN:
          this.proposalPendingStatus = "Renewal";
          break;
        default:
      }
    }
  };

  initialize() {}

  viewPendingTransaction = () => {
    const proposalId = this.proposalService.proposal.pendingTransaction?.pid;

    this.router.navigate(["/" + ROUTE_PROPOSAL], {
      queryParams: {
        pdid: this.productId,
        pid: proposalId,
        mpid: proposalId,
        action: 1,
        type: 1,
      },
    });
  };
}
