import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CdkDialogService } from "../../../../../../../../services/dialog/cdk-dialog.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { MasterPolicyService } from "src/app/services/master-policy.service";
import { BaseTodoListComponent } from "../../../../../../base/base-todo-list/base-todo-list.component";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { MfHkSearchCriteria } from "../mf-hk-todo-list/mf-hk-search-criteria";
import { HaseTodoPolicyListingEntity } from "../mf-hk-todo-list/HaseTodoPolicyListingEntity";
import * as HomeConstant from "src/app/components/new-product/home/home-constant";
import { OpenItemStatus } from "src/app/finance/finance-enums";
import { proposalCommon } from "src/app/components/new-product/base/utils/proposal-common";
import { PolicyListingEntity } from "src/app/components/new-product/home/policy-list/PolicyListingEntity";
import { BaseTodoPolicyListingEntity } from "src/app/components/new-product/base/base-todo-list/BaseTodoPolicyListingEntity";
import { GridComponent } from "@progress/kendo-angular-grid";
import { MdInformationMessageComponent } from "src/app/components/shared/partials/md-information-message/md-information-message.component";

@Component({
  selector: "app-mf-hk-policy-list",
  templateUrl: "./mf-hk-policy-list.component.html",
  styleUrls: ["./mf-hk-policy-list.component.scss"],
})
//most of the filters shares with BaseTodoListComponent, only nested grid different, hence inherited from BaseTodoListComponent
export class MfHkPolicyListComponent
  extends BaseTodoListComponent
  implements OnInit
{
  override model = new MfHkSearchCriteria();
  override listing: HaseTodoPolicyListingEntity[] = [];
  override proposalStatusOptions: Array<{ text: string; value: number }> = [
    { text: "Draft", value: 6 },
    { text: "Accepted", value: 2 },
    { text: "Approved", value: 1 },
    { text: "Referred", value: 3 },
    { text: "Declined", value: 4 },
  ];

  migratedOptions: Array<{ text: string; value: string }> = [
    //{ text: "All", value: "" },
    { text: "Migrated", value: "1" },
    { text: "Non-Migrated", value: "0" },
  ];

  public defaultMigratedItem: { text: string; value: string } = {
    text: "All",
    value: "",
  };

  @ViewChild(GridComponent) grid!: GridComponent;

  @ViewChild("toDoInterfaceDateTo", { static: false })
  public toDoInterfaceDateTo!: DatePickerComponent;

  public toDoInterfaceDateTo_min!: Date;
  public toDoInterfaceDateTo_max!: Date;
  public httpRequesting: boolean = false;

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public override masterPolicyService: MasterPolicyService,
    public override masterDataService: MasterDataService,
    public override uploadService: UploadExcelService,
    public override cdkDialogService: CdkDialogService
  ) {
    super(
      router,
      broadcastService,
      identityRolesService,
      masterPolicyService,
      masterDataService,
      uploadService,
      cdkDialogService
    );
  }

  //DBSEP-7247: https://www.telerik.com/kendo-angular-ui/components/grid/master-detail/expanded-state/
  public expandedDetailKeys: number[] = [];

  public expandDetailsBy = (dataItem: BaseTodoPolicyListingEntity): string => {
    return dataItem.proposalId;
  };

  //#region
  protected override defaultSearchCriteria() {
    super.defaultSearchCriteria();

    // effectiveDateFrom (14 days earlier from today)
    const interfaceDateFrom: Date = new Date(new Date().getFullYear(), 0, 1);
    //this.model.interfaceDateFrom = interfaceDateFrom;

    // effectiveDateFrom ( 6 months after effectiveDateFrom)
    const interfaceDateTo: Date = new Date(
      interfaceDateFrom.getFullYear(),
      11,
      31
    );
   // this.model.interfaceDateTo = interfaceDateTo;
  }

  protected override fetchUserList() {
    if (this.identityRolesService.isUnderwriter) {
      this.masterDataService
        .getHaseUnderwriterUserList()
        .subscribe((response) => {
          // update list
          var keyValueUserList = response;
          keyValueUserList.forEach((mp) => {
            this.userList.push({ text: mp.value, value: mp.key });
          });
        });
    } else {
      this.masterDataService.getHaseUserList().subscribe((response) => {
        // update list
        var keyValueUserList = response;
        keyValueUserList.forEach((mp) => {
          this.userList.push({ text: mp.value, value: mp.key });
        });
      });
    }
  }

  public on_searchInterfaceDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
  }

  public on_searchInterfaceDateFrom_change(value: Date): void {
    if (this.model.interfaceDateFrom === null) {
      this.model.interfaceDateTo = undefined;
      this.toDoInterfaceDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoInterfaceDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.interfaceDateTo &&
      this.model.interfaceDateFrom &&
      this.model.interfaceDateTo < this.model.interfaceDateFrom
    ) {
      this.model.interfaceDateTo = undefined;
      this.toDoInterfaceDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoInterfaceDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    // open datepicker
    this.toDoInterfaceDateTo.toggle();
    this.on_searchInterfaceDateFrom_focus();
  }

  protected override modelToArray(): (
    | (string | Date | undefined)[]
    | (string | string[])[]
    | (string | number[])[]
    | (string | number)[]
  )[] {
    let array = [
      ["startDateFrom", "gte", this.model.startDateFrom],
      ["startDateTo", "gte", this.model.startDateTo],
      ["expiryDateFrom", "gte", this.model.expiryDateFrom],
      ["expiryDateTo", "gte", this.model.expiryDateTo],
      ["effectiveDateFrom", "gte", this.model.effectiveDateFrom],
      ["effectiveDateTo", "lte", this.model.effectiveDateTo],
      ["quoteNo", "eq", this.model.quoteNo],
      ["policyNo", "eq", this.model.policyNo],
      ["masterPolicyId", "eq", this.model.masterPolicyId],
      ["cin", "eq", this.model.cin],
      ["insuredName", "eq", this.model.insuredName],
      ["productId", "eq", this.model.productId],
      ["proposalStatus", "eq", this.model.proposalStatus],
      //["bankReferenceNo", "eq", this.model.bankReferenceNo],
      ["createdByUserId", "eq", this.model.createdByUserId],
      //["userTeam", "eq", this.model.userTeam],
      ["additionalInsuredName", "eq", this.model.additionalInsuredName],
      //["bcrn", "eq", this.model.bcrn],
      //["riskAddressPostalCode", "eq", this.model.riskAddressPostalCode],
      ["insuredPropertyAddress", "eq", this.model.insuredPropertyAddress],
      ["interfaceDateFrom", "gte", this.model.interfaceDateFrom],
      ["interfaceDateTo", "lte", this.model.interfaceDateTo],
      [
        "mortgageLoanAccountNumber1",
        "eq",
        this.model.mortgageLoanAccountNumber1,
      ],
      ["migrated", "eq", this.model.migrated],
    ];

    return array;
  }

  protected override checkFilter(): boolean {
    let array = this.modelToArray();
    for (let i = 0; i < array.length; i++) {
      if (array[i][2] != "" && array[i][2] != undefined) {
          return false;
      }
    }
    return true;
  }


protected override refresh(): void {
    this.clearSelection();
    this.loading = true;
    this.httpRequesting = true;
    const formData: FormData = this.setFormData();
    var result = this.checkFilter();
    if (result == true) {
        const data = {
            message: "Please provide at least one search filter to proceed.",
        };

        const v1DialogRef = this.cdkDialogService.open(
            MdInformationMessageComponent,
            {
                data: data,
            }
        );

        v1DialogRef.afterClosed().subscribe((result: any) => {
            // Subscription runs after the dialog closes
            if (result) {
                this.loading = false;
                this.httpRequesting = false;
            }
        });
    }
    else {
        //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
        //IT HAS TO BE IN SINGLE CHUNK OF STRING
        var querystring: string = "";
        for (var pair of formData.entries()) {
            querystring +=
                encodeURIComponent(pair[0]) +
                "=" +
                encodeURIComponent(pair[1] as string) +
                "&";
        }

        this.masterPolicyService.searchHasePolicyListing(querystring).subscribe(
            (result: any) => {
                if (
                    result != undefined &&
                    result.body != undefined &&
                    result.body != null
                ) {
                    this.listing = result.body.d?.results;
                    this.totalListing = result.body.d?.__count;
                    this.loadItems();
                    this.expandedDetailKeys = []; //DBSEP-7247: has to be called every search to collapse the grid-detail
                    this.httpRequesting = false;

                    this.loading = false;
                }
            },
            (err: any) => {
                console.log(err);
                this.httpRequesting = false;
            }
        );
    }
}
//#endregion

protected override setFormData(): FormData {
  //todo: future enhancement
  //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
  const formData: FormData = new FormData();
  formData.append("take", this.take.toString());
  formData.append("skip", this.skip.toString());
  formData.append("page", "1");
  formData.append("pageSize", this.pageSize.toString());
  formData.append("sort[0][field]", "policyInceptionDate"); //formData.append("sort[0][field]", "createdDate");//diff with todo-list
  formData.append("sort[0][dir]", "desc");

  let array = this.modelToArray();

  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array[i].length; j++) {
      if (j == 0) {
        formData.append(
          `filter[filters][${i}][field]`,
          JSON.parse(JSON.stringify(array[i][j]))
        );
      } else if (j == 1) {
        formData.append(
          `filter[filters][${i}][operator]`,
          JSON.parse(JSON.stringify(array[i][j]))
        );
      } else {
        formData.append(
          `filter[filters][${i}][value]`,
          JSON.parse(JSON.stringify(array[i][j] || ""))
        );
      }
    }
  }

  return formData;
}
  public getProposalTypeDisplayText(dataItem: any): string {
    return HomeConstant.transactionType[dataItem.proposalTransTypeId];
  }

  public hasTransactionHistories(dataItem: PolicyListingEntity): boolean {
    return dataItem.transactionHistories !== "[]";
  }

  public getOpenItemStatusDisplayText(dataItem: any): string {
    const openItemStatusEnum: OpenItemStatus = dataItem.openItemStatusId;

    return openItemStatusEnum === OpenItemStatus.Undefined
      ? "-"
      : proposalCommon.translateOpenItemStatus(openItemStatusEnum);
  }

  protected override fetchMasterPolicyList = async () => {
    this.masterPolicyOptions = new Array<{
      text: string;
      value: string;
    }>();

    this.masterPolicyService.getList(this.currentUser!).subscribe(
      (result: any) => {
        this.masterPolicyList = result;

        // update list
        this.masterPolicyList.forEach((mp) => {
          const displayText =
            mp.policyType +
            " - " +
            mp.description +
            " - " +
            mp.meridianMasterPolicyNumber;
          this.masterPolicyOptions.push({ text: displayText, value: mp.id });
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  };
}
