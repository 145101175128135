export class PrintDocumentSearchCriteria {
  constructor() {
    this.productRadio = "dbs";
    this.channelTypeID = 0;
    // this.boundDateFrom = null;
    // this.boundDateTo = null;
    // this.policyNo = null;
    // this.insuredName = null;
    this.productIds = [];
  }
  productRadio: string;
  channelTypeID: number;
  boundDateFrom?: Date;
  boundDateTo?: Date;
  policyNo?: string;
  insuredName?: string;
  productIds: Array<string>;
}
