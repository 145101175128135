import { Component, Inject, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/dal/api.service";
import { ClientService } from "src/app/services/customer/client.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { MortgageFireSGTabSchema } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/schemas/mf-sg-tab-schema";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { DialogRef } from "../../dialog/dialog-ref";
import { DIALOG_DATA } from "../../dialog/dialog-tokens";
import { clientViewDlgModelArgs } from "../../mdClient/client-view/client-view.component";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { filterBy } from "@progress/kendo-data-query";
import { clone } from "lodash";
import { BaseSubscriptionComponent } from "src/app/components/new-product/base/base-subscription/base-subscription.component";

@Component({
  selector: "app-occupation-search",
  templateUrl: "./occupation-search.component.html",
  styleUrls: ["./occupation-search.component.scss"],
})
export class OccupationSearchComponent
  extends BaseSubscriptionComponent
  implements OnInit
{
  occupationKeyword: string = "";

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];

  outputData!: any;
  occupationList: any;
  loading: boolean = false;
  public selectedRowIndexes: number[] = [];

  constructor(
    public identityRolesService: IdentityRolesService,
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: occupationSearchDlgInputArgs
  ) {
    super();
    this.tabId = MortgageFireSGTabSchema.premiumSummary.index.toString();

    if (this.inputData) {
      this.occupationList = clone(this.inputData.occupationList); //temp pass from outside
    }
  }

  //#region old ui code
  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroy() {
    this.unsubscribeAllRxjs();
  }

  private initialize() {
    super.subscribeTabLoadRenderer();
    this.loadItems();
  }

  unsubscribeAllRxjs() {
    super.unsubscribeRxjs();
  }

  public close(status: any) {
    let savParameters: clientViewDlgModelArgs = {
      status: status,
      data: this.outputData,
    };
    this.dialogRef?.close(savParameters);
  }
  //#endregion

  //#region generic Grid event
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.loadItems();
  }

  public gridSelectionChange(selection: SelectionEvent) {}

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    const di = dataItem;
    this.outputData = di;
    this.close("yes");
  }

  public search(event: any): void {
    this.loading = true;
    this.occupationList = filterBy(this.inputData.occupationList, {
      logic: "or",
      filters: [
        {
          field: "key",
          operator: "contains",
          value: this.occupationKeyword,
          ignoreCase: true,
        },
        {
          field: "value",
          operator: "contains",
          value: this.occupationKeyword,
          ignoreCase: true,
        },
      ],
    });
    this.loadItems();
  }

  private loadItems(): void {
    this.gridView = {
      data: this.occupationList.slice(this.skip, this.skip + this.pageSize),
      total: this.occupationList.length,
    };
    this.loading = false;
  }
  //#endregion
}

export interface occupationSearchDlgInputArgs {
  isEditable: boolean;
  occupationList: any;
}
