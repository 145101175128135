import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { BaseListingComponent } from "../base-listing/base-listing.component";
import { UntypedFormGroup } from "@angular/forms";
import {
  GridDataResult,
  PageSizeItem,
  PageChangeEvent,
  SelectionEvent,
  CellClickEvent,
} from "@progress/kendo-angular-grid";
import { IProduct } from "src/app/framework/interface.product";
import { IUser } from "src/app/framework/interface.shared";
import { ClausesDeductiblesService } from "src/app/services/clauses-deductibles.service";
import { MasterPolicyService } from "src/app/services/master-policy.service";
import { ConfigListingEntity } from "./config-listing-entity";
import { warningZoneArgs } from "../../home/warning-zone/warning-zone.component";
import { MfHkEndorsementListSearchCriteria } from "../../sme/mortgage-fire/hong-kong/components/endorsement/endorsement-list/mf-hk-end-list-search-criteria";
import { MfHkConfigEditCriteria } from "src/app/components/shared/partials/configurable-form-edit/mf-hk-config-edit-criteria";

@Component({
  selector: "app-base-configurables-list",
  templateUrl: "./base-configurables-list.component.html",
  styleUrls: ["./base-configurables-list.component.scss"],
})
export class BaseConfigurablesListComponent
  extends BaseListingComponent
  implements OnInit
{
  @ViewChild("heroForm", { static: false })
  private form!: UntypedFormGroup;

  public isAdd: boolean = false;
  public isEdit: boolean = false;
  editModel: MfHkConfigEditCriteria = new MfHkConfigEditCriteria();

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  protected listing: ConfigListingEntity[] = [];
  protected totalListing: number = 0;
  submitted = false;

  currentUser: IUser | undefined;
  model = new MfHkEndorsementListSearchCriteria();
  public userProductList!: IProduct[];
  //protected masterPolicyList!: MasterPolicyEntity[];
  //public masterPolicyOptions!: Array<{ text: string; value: string }>;

  public checkableRows: number[] = [];
  public selectedRowIndexes: number[] = [];
  public loading!: boolean;

  @Output() warningZoneEvent = new EventEmitter<any>();

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public masterPolicyService: MasterPolicyService,
    public clausesDeductiblesService: ClausesDeductiblesService
  ) {
    super(router, broadcastService, identityRolesService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.currentUser = this.currentIdentity.currentUser(); //init
    // this.fetchUserList();
    //this.fetchCmbOnlyMasterPolicyList();
    this.defaultSearchCriteria();
    // this.getUserOrganisation();
    this.refresh();
  }

  /** Default search criteria while page load */
  protected defaultSearchCriteria() {}

  public getDefaultDisplayText(dataItem: any): string {
    return dataItem.isDefault === "True" ? "Default" : "NA";
  }

  // protected fetchCmbOnlyMasterPolicyList = async () => {
  //   this.masterPolicyOptions = new Array<{
  //     text: string;
  //     value: string;
  //   }>();

  //   this.masterPolicyService.getList(this.currentUser!).subscribe(
  //     (result: any) => {
  //       this.masterPolicyList = result;

  //       // update list
  //       this.masterPolicyList.forEach((mp) => {
  //         if (
  //           mp.productId.toUpperCase() ===
  //           Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING
  //         ) {
  //           const displayText =
  //             mp.policyType +
  //             " - " +
  //             mp.description +
  //             " - " +
  //             mp.meridianMasterPolicyNumber;
  //           this.masterPolicyOptions.push({ text: displayText, value: mp.id });
  //         }
  //       });
  //     },
  //     (err: any) => {
  //       console.log(err);
  //     }
  //   );
  // };

  public returnListingHandler(ret: string): void {
    this.isAdd = false;
    this.isEdit = false;
    //reload the grid
    this.submitted = true;
    this.refresh();
  }

  //#region generic Grid event
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }

  protected clearSelection() {
    // Optionally, clear the selection when paging
    // this.checkableRows = [];
    // this.selectedRowIndexes = [];
  }

  public gridSelectionChange(selection: SelectionEvent) {
    // console.log("gridSelectionChanged");
    const selectedData = selection?.selectedRows;
    if (selectedData != undefined && selectedData.length > 0) {
      var entity = selectedData[0].dataItem;
    }

    const deselectedData = selection?.deselectedRows;
    if (deselectedData != undefined && deselectedData.length > 0) {
      var entity = deselectedData[0].dataItem;
      //auto, from two-way binding of [(selectedKeys)]
      // this.selectedRowIndexes.forEach((item, index) => {
      //   if (item === deselectedData[0].index)
      //     this.selectedRowIndexes.splice(index, 1);
      // });
    }
  }

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    //if click on "Action" column
    if (columnIndex === 3) {
      const configEntity = dataItem as ConfigListingEntity;
      this.editModel = new MfHkConfigEditCriteria();
      this.editModel.typeId = this.model.typeId; //use to differentiate in backend for sproc
      this.editModel.guid = configEntity.id;

      var myarray = configEntity.coverageTypeId
        .toString()
        .toUpperCase()
        .split(",");

      for (var i = 0; i < myarray.length; i++) {
        this.editModel.coverageTypes.push(myarray[i]); //case is important when UI selects it
      }

      this.editModel.default = this.stringBoolToString(configEntity.isDefault);
      this.editModel.code = configEntity.code;
      this.editModel.title = configEntity.title;
      this.editModel.editorValue = configEntity.description;

      this.isEdit = true;
    }
  }

  private stringBoolToString(boolToCompare: any) {
    if (
      boolToCompare == true ||
      String(boolToCompare).toLowerCase() === "true"
    ) {
      return true;
    }

    return false;
  }

  protected modelToArray(): (
    | (string | Date | undefined)[]
    | (string | string[])[]
    | (string | number[])[]
    | (string | number)[]
  )[] {
    let array = [
      ["coverageTypes", "eq", this.model.coverageTypes],
      ["code", "eq", this.model.code!],
      ["title", "eq", this.model.title],
      ["typeId", "eq", this.model.typeId],
    ];

    return array;
  }

  protected setFormData(): FormData {
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "code");
    formData.append("sort[0][dir]", "asc");

    let array = this.modelToArray();

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    return formData;
  }

  protected refresh(): void {
    this.clearSelection();
    this.loading = true;

    const formData: FormData = this.setFormData();

    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    //todo
    this.clausesDeductiblesService
      .searchClausesAndWarrantiesListing(querystring)
      .subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            this.listing = result.body.d?.results;
            this.totalListing = result.body.d?.__count;
            this.loadItems();
            this.loading = false;
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  protected loadItems(): void {
    this.listing.forEach((entity, index) => {});

    this.gridView = {
      data: this.listing, //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }

  private validateMandatoryFields(): boolean {
    let isValid: boolean = false;

    // dates
    const filters: boolean =
      this.model.coverageTypes !== null || this.model.title !== null;

    isValid = filters; // || etc...

    return isValid;
  }

  public onSubmit(): void {
    if (this.form.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }

    const isValid = this.validateMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      //$('#warningZone').collapse('hide');
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
      // $('#warningZone #message').text('Please select a date range.');
      // $('#warningZone').collapse('show');
      // httpNotifier.response_notifier$.next(new KeyValue('', e));
    }
  }

  getDataItemsFromGridWithSelectedIndexes(
    selectedIndexes: number[]
  ): ConfigListingEntity[] {
    var currentPageData = this.gridView.data as ConfigListingEntity[];
    var selectedDataItems: ConfigListingEntity[] = [];

    currentPageData.forEach((item, idx) => {
      //selectedIndexes is a running number for whole/all pages, while the currentPageData only for selected current page
      //thus need to onset
      let allIdx = idx + this.skip;
      if (selectedIndexes.indexOf(allIdx) >= 0) {
        selectedDataItems.push(item);
      }
    });
    return selectedDataItems;
  }
  //#endregion
}
