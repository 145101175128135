// header.service.ts
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Proposal } from "../models/new-product/base/proposal.model";

@Injectable({
  providedIn: "root",
})
export class BroadcastService {
  onAutheticated = new Subject<{}>();
  onRoleSet = new Subject<{}>();
  onProposalFailedNotifier = new Subject<{ kv: KeyValue<Proposal> }>();

  constructor() {}
}
