import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  ManualPaymentEntity,
  OpenItemEntity,
} from "src/app/components/accounting/open-item/models/open-item-list.model";
import {
  v1DlgInputArgs,
  MdConfirmationMessageV1Component,
  dlgModelArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { common } from "src/app/framework/utils/common";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";

@Component({
  selector: "mf-hk-do-manual-pay",
  templateUrl: "./mf-hk-do-manual-pay.component.html",
  styleUrls: ["./mf-hk-do-manual-pay.component.scss"],
})
export class MfHkDoManualPayComponent implements OnInit {
  public manualPayment = new ManualPaymentEntity();
  public openItemId!: string;
  public openItemEntity = new OpenItemEntity();
  public totalPremium: any;
  public active = false;
  //public payByChequeOnly: boolean = false;
  public isPaidByHase: boolean = false;
  public opened: boolean = false;
  //validation
  invalidRequiredField = false;
  invalidPayerName = false;
  invalidCIB = false;
  invalidCN = false;

  @Input() public set model(openItemEntity: any) {
    this.openItemEntity = openItemEntity;
    // toggle the Dialog visibility
    this.active = openItemEntity !== undefined;
    this.manualPayment.paymentType = 2;
    this.manualPayment.openItemId = openItemEntity.openItemId;
    //this.payByChequeOnly = this.openItemEntity?.statusId === 11;
  }
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() makePayment: EventEmitter<ManualPaymentEntity> = new EventEmitter();

  constructor(private cdkDialogService: CdkDialogService) {}

  ngOnInit(): void {}
  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }
  public onChangePaymentType(val: any): void {
    this.manualPayment.chequeIssuanceBank = "";
    this.manualPayment.chequeNo = "";
    this.invalidCIB = false;
    this.invalidCN = false;
    if (val === 1) {
      this.isPaidByHase = true;
    } else {
      this.isPaidByHase = false;
    }
  }

  ConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  public onSave(e: PointerEvent): void {
    this.invalidRequiredField = false;
    // do form validation
    const isValid: boolean = this.validateMandatoryFields();
    if (isValid) {
      this.ConfirmationPopup("Are you sure you want to make this payment?", "");
    } else {
      this.invalidRequiredField = true;
      e.preventDefault();
    }
  }
  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      this.makePayment.emit(this.manualPayment);
    }
  }
  private validateMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields
    if (common.isStringNullOrEmpty(this.manualPayment.payerName)) {
      hasError = true;
      this.invalidPayerName = true;
    } else {
      this.invalidPayerName = false;
    }

    return !hasError;
  }
}
