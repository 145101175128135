<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>

  <div *ngIf="!question.readOnly">
    <select
      kendo-drop-down-list
      id="{{ question.key }}_dropdown"
      name="{{ question.key }}"
      k-ng-model="question.answer"
      k-value-primitive="true"
      k-data-source="question.optionsValue"
      k-data-text-field="'key'"
      k-data-value-field="'value'"
      ng-disabled="question.isDisabled"
      class="form-control"
      controlType="kendoDropDownList"
      k-filters="'contains'"
    ></select>
  </div>

  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()">{{ answerDisplayText(question) }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
