export class SearchCriteria {
  constructor() {
    /**
     * Backend does not accept 'undefined'.
     * Hence initialize to empty string. */

    this.productRadio = "dbs"; // default dbs
    this.policyNo = "";
    this.invoiceNo = "";
    this.chequeNo = "";
    this.cin = "";
    this.segments = [];
    this.productIds = [];
    this.statusIds = [];
    this.autoDebitPeriodYear = "";
    this.autoDebitPeriodMonth = "";
    this.autoDebitPeriodDate = "";
  }

  pageNo!: number;
  pageSize!: number;

  productRadio: string;
  policyNo: string;
  invoiceNo: string;
  chequeNo: string;
  cin: string;
  segments: Array<string>;
  effectiveDateFrom?: Date;
  effectiveDateTo?: Date;
  autoDebitPeriod: any;
  autoDebitPeriodYear: string;
  autoDebitPeriodMonth: string;
  autoDebitPeriodDate: string;
  productIds: Array<string>;
  statusIds: Array<string>;
  policyeffectiveDateFrom?: Date;
  policyeffectiveDateTo?: Date;
}
export class OpenItemEntity {
  id!: string;
  statusId!: number;
  policyNo!: string;
  invoiceNo!: string;
  amount!: string;
  amountGst!: string;
  stampDuty!: string;
  payerTypeId!: number;
  autoDebitTransId!: number;
  invoiceId!: number;
  refundId!: number;
  manualPaymentId!: number;
  dateCreated!: string;
  createdByUserName!: string;
  lastUpdatedByUserName!: string;
  batchId!: number;
  policyCertificateNumber!: string;
  startEffectiveDate!: string;
  autoDebitMonth!: number;
  manualPaymentChequeNumber!: string;
  manualPaymentChequeIssuanceBank!: string;
  refundPaymentChequeNumber!: string;
  refundPaymentChequeIssuanceBank!: string;
  remarks!: string;
  payerName!: string;
  manualPaymentRemarks!: string;
  refundPaymentRemarks!: string;
  statusDisplayText!: string;
  autoDebitMonthDisplayText!: string;
  autoDebitDate!: string;
  chequeIssuanceBank!: string;
  chequeNumber!: string;
}

export class ManualPaymentEntity {
  openItemId?: string;
  payerName!: string;
  paymentType?: number;
  chequeIssuanceBank!: string;
  chequeNo!: string;
  // receiptDocumentId! : number;
  remarks!: string;
  invoiceNumber!: string;
}

export class RefundPaymentEntity {
  openItemId!: string;
  payerName!: string;
  chequeIssuanceBank!: string;
  chequeNo!: string;
  remarks!: string;
  refundPaymentType!: number;
}

export class RefundReviewEntity {
  openItemId!: string;
}
