import { AbstractGooglePlaces } from "../google-places/abstract-google-places";
import { HaseGooglePlaces } from "../google-places/hase-google-places";
import { AbstractDocType } from "../doc-type/abstract-doc-type";
import { AbstractProductFactory } from "./abstract-product-factory";
import { HaseDocType } from "../doc-type/hase-doc-type";
import { AbstractButtonPanel } from "../button-panel/abstract-button-panel";
import { HaseButtonPanel } from "../button-panel/hase-button-panel";
import { AbstractProposal } from "../proposal/abstract-proposal";
import { HaseProposal } from "../proposal/hase-proposal";
import { AbstractMaster } from "../master/abstract-master";
import { HaseMaster } from "../master/hase-master";
import { AbstractPremiumSummary } from "../premium-summary/abstract-premium-summary";
import { HasePremiumSummary } from "../premium-summary/hase-premium-summary";

export class HaseProductFactory implements AbstractProductFactory {
  docTypeList(): AbstractDocType {
    return new HaseDocType();
  }

  googlePlaces(): AbstractGooglePlaces {
    return new HaseGooglePlaces();
  }

  buttonPanel(): AbstractButtonPanel {
    return new HaseButtonPanel();
  }

  master(): AbstractMaster {
    return new HaseMaster();
  }

  premiumSummary(): AbstractPremiumSummary {
    return new HasePremiumSummary();
  }

  proposal(): AbstractProposal {
    return new HaseProposal();
  }
}
