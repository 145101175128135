import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import {
  MasterPolicyEntity,
  MasterPolicyService,
} from "src/app/services/master-policy.service";
import { ProposalActivityService } from "src/app/services/new-product/proposal-activity.service";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { ProductService } from "src/app/services/new-product/base/product.service";
import { BaseMainComponent } from "src/app/components/new-product/base/base-main/base-main.component";
import { componentHelper } from "src/app/models/new-product/utils/component.helper";
import { GUID } from "src/app/framework/domain-entity/guid";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import { common } from "src/app/framework/utils/common";
import { Echos } from "src/app/models/new-product/base/echos.model";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import * as Enum from "src/app/framework/enum.shared";

@Component({
  selector: "app-mf-sg-main",
  templateUrl: "./mf-sg-main.component.html",
  styleUrls: ["./mf-sg-main.component.scss"],
})
export class MfSgMainComponent extends BaseMainComponent implements OnInit {
  proposal!: MortgageFireSingaporeProposal;

  constructor(
    public override route: ActivatedRoute,
    public override proposalActivityService: ProposalActivityService,
    public override masterPolicyService: MasterPolicyService,
    public override identityRolesService: IdentityRolesService,
    public override masterDataService: MasterDataService,
    public override cdRef: ChangeDetectorRef,
    public override productService: ProductService,
    public proposalService: MfSgProposalService
  ) {
    super(
      route,
      proposalActivityService,
      masterPolicyService,
      identityRolesService,
      masterDataService,
      cdRef,
      productService
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    componentHelper.notifyPageUnload("mf-sg-main.component", this.proposal);
  }

  /** Method to create a proposal instance */
  override instantiateProposalObject() {
    const productId = new GUID(this.proposalActivityService.readProductId()!);
    const proposal = new MortgageFireSingaporeProposal(
      productId,
      this.identityRolesService,
      this.masterDataService
    );
    this.proposal = proposal;
    this.proposalService.initialize(proposal);

    let productBase = this.productService.getProductBase(
      this.proposal.productType
    );

    switch (productBase) {
      case EnumSG.ProductBase.Citi:
        this.isCitiProduct = true;
        break;
      case EnumSG.ProductBase.HASE:
        this.isHaseProduct = true;
        break;
      case EnumSG.ProductBase.DBS:
      default: {
        this.isDbsProduct = true;
        break;
      }
    }
  }

  override proposalLoaded = (event: KeyValue<Proposal>) => {
    var updatedProposal = event.value as MortgageFireSingaporeProposal;
    //20230202 GKH: update the proposal with newly updated
    this.proposal = updatedProposal;
    //do at $onInit event will not be the latest, thus do it here
    //search only will reach here
    this.getLastestProposalTransStatus(updatedProposal);
  };

  private getLastestProposalTransStatus(
    updatedProposal: MortgageFireSingaporeProposal
  ) {
    const premiumData = this.proposal.auxiliary["qt"];
    if (common.isDefined(premiumData) && premiumData.length > 0) {
      var pph = premiumData[0].premiumPeriodHistory;
      if (common.isDefined(pph) && pph.length > 0) {
        this.pphRecordCount = pph.length;
      }
    }
    this.isNewBusiness = updatedProposal.transType === Enum.TransType.NEW;
  }

  override loadProposal() {
    // get Master Policies
    this.masterPolicyService
      .getListByProduct(this.proposal.productId, this.submittedDate)
      .subscribe((masterPolicyList: MasterPolicyEntity[]) => {
        const options = new Array<KeyValue<string>>();
        if (common.isDefined(masterPolicyList)) {
          masterPolicyList.forEach((mp) => {
            options.push(new KeyValue<string>(mp.description, mp.id));
          });
        }
        this.proposal.qsMaster.qs_master_masterPolicyNumber!.options = options;
      });

    // Populate all requires master data for listing type questions before assigning the answer
    this.masterDataService
      .getSgIbgOccupancyRateList()
      .subscribe((sgIgbOccupancyRateListResponse) => {
        this.proposal.qsMaster.qs_master_occupancyRate!.options =
          sgIgbOccupancyRateListResponse;
        this.masterDataService
          .getSGIOccupationList()
          .subscribe((sgiOccupationListResponse) => {
            this.proposal.qsMaster.qs_master_insuredIndustry!.options =
              sgiOccupationListResponse;
            this.masterDataService
              .getSGIBGMachineryTypeList()
              .subscribe((sgiMachineryListResponse) => {
                this.proposal.qsMaster.qs_machinery_equipment!.options =
                  sgiMachineryListResponse;
                if (
                  this.proposal.productType ===
                  EnumSG.MortgageFireSgProduct.IBGMAC
                ) {
                  this.category = "BUPC";
                }
                this.masterDataService
                  .getFinancialInstitutionBranches(this.category)
                  .subscribe((codeResponse) => {
                    const options = new Array<KeyValue<string>>();
                    codeResponse.forEach(
                      (mp: { key: string; value: string }) => {
                        options.push(new KeyValue<string>(mp.key, mp.value));
                      }
                    );
                    if (this.category === "BUPC") {
                      this.proposal.qsMaster.qs_master_buPcCode!.options =
                        options;
                    } else {
                      if (
                        this.proposal.productType ===
                        EnumSG.MortgageFireSgProduct.IBGMF
                      ) {
                        this.proposal.qsMaster.qs_master_buPcCode!.options =
                          options;
                      } else {
                        this.proposal.qsMaster.qs_master_financialBranch!.options =
                          options;
                      }
                    }

                    //this is to init kendo's notifier
                    this.proposalService.setVCR(this.baseNotifierAppendTo);
                    // actual load of proposal from back end
                    this.proposalService.createOrEdit();
                  });
              });
          });
      });
  }

  override proposalSaved = (event: KeyValue<any>) => {
    const echos = event.value as Echos<QuestionEntity>[];
    this.resetMandatoryTabColor();
    this.setMandatoryTabColor(
      this.proposalService.fieldValidationManager.echos
    );
    var updatedProposal = event.value as MortgageFireSingaporeProposal;
    //20230202 GKH: update the proposal with newly updated
    this.proposal = updatedProposal;
    //user could have created a NB and directly enter END status, thus without go thru proposalLoaded() event
    this.getLastestProposalTransStatus(updatedProposal);
  };
}
