import * as lodash from "lodash";

import { ArgumentNullException } from "./../domain-entity/exceptions/argument-null-exception";
import { GUID } from "./../domain-entity/guid";

/** House of global common function */
class Common {
  private uniqueIdPool: number = 0;

  public readonly DEFAULT_GUID: string = "00000000-0000-0000-0000-000000000000";
  private defaultGuidObjectValue!: GUID;

  get DEFAULT_GUID_OBJ(): GUID {
    if (this.isUndefinedOrNull(this.defaultGuidObjectValue)) {
      this.defaultGuidObjectValue = new GUID(this.DEFAULT_GUID);
    }

    return this.defaultGuidObjectValue;
  }

  /** Method to generate an unique numeric ID */
  generateUniqueId(): number {
    this.uniqueIdPool++;
    return this.uniqueIdPool;
  }

  isUndefinedOrNull(value: any): boolean {
    return lodash.isUndefined(value) || lodash.isNull(value);
  }

  isDefined(value: any): boolean {
    return !this.isUndefinedOrNull(value);
  }

  isStringNullOrEmpty(value: string): boolean {
    if (this.isUndefinedOrNull(value)) {
      return true;
    }

    if (value.length === 0) {
      return true;
    }

    return false;
  }

  isEmptyObject(value: any): boolean {
    return lodash.isEmpty(value);
  }

  isString(value: any): boolean {
    return lodash.isString(value);
  }

  isNumber(value: any): boolean {
    return lodash.isNumber(value);
  }

  isObject(value: any): boolean {
    return lodash.isObject(value);
  }

  isArray(value: any): boolean {
    return lodash.isArray(value);
  }

  isDate(value: any): boolean {
    return lodash.isDate(value);
  }

  isBoolean(value: any): boolean {
    return lodash.isBoolean(value);
  }

  objectToJson(value: any): string {
    if (this.isObject(value)) {
      return JSON.stringify(value);
    }
    return "";
  }
}

export const common: Common = new Common();
