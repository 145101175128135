import { Injectable } from "@angular/core";
import { ApiService } from "../dal/api.service";
// import { AppSettings } from './../appSettings';
// import { Application } from './../application';
// import { ClientAddressEntity } from './base/client-address-entity.model';
import { ClientEntity } from "src/app/models/customer/base/client-entity.model";
import { common } from "src/app/framework/utils/common";
import { map } from "rxjs/operators";
import { of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
// import { Global } from '../shared/global';
// import { common } from './../framework/utils/common';
// import { notifier } from '../framework/utils/notifier';

@Injectable({
  providedIn: "root",
})
export class ClientService {
  clientDictionary: { [key: string]: ClientEntity };

  constructor(private apiService: ApiService, private httpClient: HttpClient) {
    this.clientDictionary = {};
  }

  //DBSEP-2063: Add new function to check existing policies of the client code
  FindExistingPoliciesForClient(clientId: number, clientCode: string) {
    const clientEntity: ClientEntity = new ClientEntity();
    clientEntity.clientId = clientId;
    clientEntity.clientCode = clientCode;
    const postData: string = JSON.stringify(clientEntity);

    // get from back end and cached

    const uri = "api/client/FindExistingPoliciesForClient";

    // generate post back model
    return this.apiService.add(uri, {}, postData).pipe(
      map(
        (response: any) => {
          // console.log("searchPersonCustomer response : " + response);
          return response;
        },
        (error: any) => {
          return error;
        }
      )
    );
  }

  create(client: any) {
    const postData: string = JSON.stringify(client);
    const uri = "api/client/save";
    // generate post back model
    return this.apiService.add(uri, {}, postData);
  }

  get(clientId: number, clientCode?: string) {
    const clientEntity: ClientEntity = new ClientEntity();
    clientEntity.clientId = clientId;
    clientEntity.clientCode = clientCode;
    const postData: string = JSON.stringify(clientEntity);

    if (
      common.isDefined(clientId) &&
      common.isDefined(this.clientDictionary[clientId.toString()])
    ) {
      return of(this.clientDictionary[clientId.toString()]);
    } else {
      // get from back end and cached
      const uri = "api/client/get";

      // generate post back model
      return this.apiService.add(uri, {}, postData).pipe(
        map(
          (response: any) => {
            let body = response?.body; //in new angular
            this.clientDictionary[body.clientId.toString()] = body;
            return this.clientDictionary[body.clientId.toString()];
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  }

  public search(querystring: string) {
    // get from back end and cached
    const uri = "api/client/search";

    // // generate post back model
    // return this.apiService.add(uri, {}, querystring).pipe(
    //   map(
    //     (response: any) => {
    //       let body = response?.body; //in new angular
    //       this.clientDictionary[body.clientId.toString()] = body;
    //       return this.clientDictionary[body.clientId.toString()];
    //     },
    //     (error: any) => {
    //       return error;
    //     }
    //   )
    // );
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase + uri,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }
}
