<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5">
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="selCoverageTypes"
              >Coverage Type</label
            >
          </div>
          <div class="col-md-6">
            <kendo-multiselect
              id="selCoverageTypes"
              placeholder="Select Coverage Type"
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="coverageTypeOptions"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="model.coverageTypes"
              name="coverageTypes"
            >
            </kendo-multiselect>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="txtTitle">Search</label>
          </div>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              id="txtTitle"
              placeholder="{{ searchPlaceholder }}"
              [(ngModel)]="model.title"
              name="title"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group row">
          <div class="col-md-12">
            <button
              type="submit"
              class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
            >
              <i class="fa fa-search ng-scope" aria-hidden="true"></i>
              Search
            </button>
            <!--HTML5 button element. Remember the reason is this button has a default behavior of submit, as stated in the W3 specification-->
            <button
              id="btnAdNew"
              type="button"
              class="btn btn-primary ld-ext-left chubbDarkBlue_bg chubbDarkBlue_border_btn"
              (click)="addNew()"
            >
              <span>New</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <input
        type="hidden"
        id="typeId"
        name="typeId"
        [(ngModel)]="model.typeId"
      />
    </div>
  </div>
</div>
