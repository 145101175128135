import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  AddEvent,
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  RemoveEvent,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { State } from "@progress/kendo-data-query";
import { CdkDialogService } from "../../../../../../../../../app/services/dialog/cdk-dialog.service";
import { IdentityRolesService } from "../../../../../../../../../app/services/identity-roles.service";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../../../../../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import {
  MdDetailsComponent,
  dlgDetailsModelArgs,
  v1DetailsDlgInputArgs,
} from "./md-details/md-details.component";
import { MfSgEquipmentListingEntity } from "./MfSgEquipmentListingEntity";
import { common } from "src/app/framework/utils/common";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { Subject } from "rxjs";
import { MdConfirmationMessageV2Component } from "src/app/components/shared/partials/md-confirmation-message-v2/md-confirmation-message-v2.component";
import { MfSgBaseComponent } from "../../mf-sg-base.component";
import { proposalServiceNotifier } from "src/app/models/new-product/notifier/proposal-service-notifier";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";

@Component({
  selector: "app-mf-sg-equipment-listing",
  templateUrl: "./mf-sg-equipment-listing.component.html",
  styleUrls: ["./mf-sg-equipment-listing.component.scss"],
})
export class MfSgEquipmentListingComponent
  extends MfSgBaseComponent
  implements OnInit
{
  isUnderwriter: boolean = false;
  isOperationUnderwriter: boolean = false;
  hideActions: boolean = true;

  public editEquipmentListingDataItem!: MfSgEquipmentListingEntity;
  public isNewEquipmentListing!: boolean;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  public listing: MfSgEquipmentListingEntity[] = []; //public, to be accessed by [kendoGridBinding]
  private totalListing: number = 0;

  public selectedRowIndexes: number[] = [];
  public loading!: boolean;
  @Input() equipmentData!: any;
  @Input() proposal!: MortgageFireSingaporeProposal;
  @Input() masterPolicyQuestion!: any;
  @Input() equipmentDropdownOptions!: KeyValue<any>[];
  @ViewChild("appendTo", { read: ViewContainerRef, static: false })
  public appendTo!: ViewContainerRef;
  @Input() clearEquipmentListing!: Subject<boolean>;

  @Output() equipmentDataOnUpdate: EventEmitter<MfSgEquipmentListingEntity[]> =
    new EventEmitter();
  @Output() equipmentDataOnCreate: EventEmitter<MfSgEquipmentListingEntity[]> =
    new EventEmitter();
  @Output() equipmentDataOnDestroy: EventEmitter<MfSgEquipmentListingEntity[]> =
    new EventEmitter();

  constructor(
    private identityRolesService: IdentityRolesService,
    private cdkDialogService: CdkDialogService
  ) {
    super();
    super.subscribeProposalLoadedNotifier((event) => {
      this.hideActions = this.equipmentData.readOnly ? true : false;
    });
    super.subscribeProposalSavedNotifier((event) => {
      this.hideActions = this.equipmentData.readOnly ? true : false;
    });
  }

  ngOnDestroy() {
    super.unsubscribeRxjs();
  }

  ngOnInit(): void {
    const component = this;
    this.isUnderwriter = this.identityRolesService.isUnderwriter;
    this.isOperationUnderwriter =
      this.identityRolesService.isOperationUnderwriter;

    this.hideActions = this.equipmentData.readOnly ? true : false;

    this.getEquipmentListingDetails();
    this.clearEquipmentListing.subscribe((v) => {
      this.refreshEquipmentListing();
    });
  }

  getEquipmentListingDetails() {
    // Equipment
    this.listing =
      this.equipmentData.answer === ""
        ? []
        : this.processTableDataEquipment(this.equipmentData.answer);
    this.loadItems();
  }

  processTableDataEquipment(answer: string) {
    let jsonParsed: any = [];
    if (common.isDefined(answer)) {
      jsonParsed = JSON.parse(answer);
      /*add id for table's use*/
      jsonParsed.forEach((item: any, index: any) => {
        item.rowID = index;
        //  addtional info is added later, so need to check on the value to prevent showing undefined
        if (!common.isDefined(item.additionalInfo)) {
          item.additionalInfo = "";
        }
      });
    }
    return jsonParsed;
  }

  public refreshEquipmentListing() {
    this.listing = [];
    this.loadItems();
  }

  private loadItems(): void {
    this.gridView = {
      data: this.listing.slice(this.skip, this.skip + this.pageSize), //static paging
      //data: this.listing, //.slice(this.skip, this.skip + this.pageSize),//dynamic paging
      total: this.totalListing,
    };
  }

  public onTabSelect(e: SelectEvent) {}

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }

  public gridSelectionChange(selection: SelectionEvent) {
    const selectedData = selection?.selectedRows;
    if (selectedData != undefined && selectedData.length > 0) {
      var entity = selectedData[0].dataItem;
    }

    const deselectedData = selection?.deselectedRows;
    if (deselectedData != undefined && deselectedData.length > 0) {
      var entity = deselectedData[0].dataItem;
      //auto, from two-way binding of [(selectedKeys)]
      // this.selectedRowIndexes.forEach((item, index) => {
      //   if (item === deselectedData[0].index)
      //     this.selectedRowIndexes.splice(index, 1);
      // });
    }
  }

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    // //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    // console.log("cell clicked");
    // //todo: this will re-route to proposal page
    // this.router.navigate(["/" + ROUTE_PROPOSAL], {
    //   queryParams: { id: dataItem.proposalId },
    // });
  }

  private refresh(): void {
    this.loadItems(); //no need to call api
  }

  public onEquipmentListingStateChange(state: State): void {}

  public addEquipmentListingHandler(): void {
    // master policy check
    if (this.masterPolicyQuestion.isAnswered()) {
      this.editEquipmentListingDataItem = new MfSgEquipmentListingEntity();
      this.isNewEquipmentListing = true;
    } else {
      this.policyNumberConfirmationPopup(
        "Please choose a master Policy to add Equipment."
      );
    }
  }

  policyNumberConfirmationPopup(msg: string) {
    let v2InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: "",
      message: msg,
    };

    const v2DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV2Component,
      {
        data: v2InputData,
      }
    );

    v2DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
    });
  }

  public editEquipmentListingHandler(args: AddEvent): void {
    this.editEquipmentListingDataItem = args.dataItem;
    this.isNewEquipmentListing = false;
  }

  public cancelEquipmentListingHandler(): void {
    this.editEquipmentListingDataItem = new MfSgEquipmentListingEntity();
  }

  //both new & edit
  public saveEquipmentListingHandler(
    equipmentListingEntity: MfSgEquipmentListingEntity
  ): void {
    if (
      common.isDefined(equipmentListingEntity.rowID) &&
      equipmentListingEntity.rowID !== ""
    ) {
      const updatedData: MfSgEquipmentListingEntity = equipmentListingEntity;
      this.listing.forEach((item: MfSgEquipmentListingEntity) => {
        if (item.rowID === updatedData.rowID) {
          item.equipmentType = updatedData.equipmentType;
          item.typeOfFinancing = updatedData.typeOfFinancing;
          item.equipmentDescription = updatedData.equipmentDescription;
          item.additionalInfo = updatedData.additionalInfo;
          item.sumInsured = updatedData.sumInsured;
        }
      });

      this.equipmentDataOnUpdate.emit(this.listing);
    } else {
      equipmentListingEntity.rowID = (this.listing.length + 1).toString();
      this.listing.push(equipmentListingEntity);
      this.equipmentDataOnCreate.emit(this.listing);
    }

    this.loadItems();
    proposalServiceNotifier.saved_notifier$.next(
      new KeyValue(this.proposal.proposalId!.toString(), this.proposal)
    );
    this.editEquipmentListingDataItem = new MfSgEquipmentListingEntity();
  }

  public removeEquipmentListingHandler(args: RemoveEvent): void {
    //todo: return as promise or observable
    this.deleteConfirmationPopup(
      "Are you sure you wish to delete the item?",
      args.dataItem
    );
  }

  deleteConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      if (args.data != null) {
        for (let i = 0; i < this.listing.length; i++) {
          const updatedData: any = args.data;
          if (this.listing[i].rowID === updatedData.rowID) {
            this.listing.splice(i, 1);
            break;
          }
        }
        this.loadItems();
        this.equipmentDataOnDestroy.emit(this.listing);
      }
    }
  }

  showDetails(data: any) {
    let v1InputData: v1DetailsDlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: "Equipment Details",
    };

    const v1DialogRef = this.cdkDialogService.open(MdDetailsComponent, {
      data: v1InputData,
    });

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForDetailsActions(result);
      }
    });
  }

  async confirmationModalCloseForDetailsActions(args: dlgDetailsModelArgs) {
    if (args.status === "yes") {
      if (args.data != null) {
      }
    }
  }
}
