import { Component, Input } from "@angular/core";
import { Proposal } from "../../../../../../../../models/new-product/base/proposal.model";
import * as Enums from "../../../../../../../../framework/enum.shared";
import { MortgageFireSingaporeProposal } from "../../../../../../../../../app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { KeyValue } from "../../../../../../../../framework/domain-entity/key-value";
import { MfSgBaseComponent } from "../../mf-sg-base.component";
import { common } from "../../../../../../../../../app/framework/utils/common";
import { MfSgProposalService } from "../../../../../../../../../app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { pageLifeCycleNotifier } from "../../../../../../../../../app/models/new-product/notifier/page-life-cycle-notifier";

@Component({
  selector: "mf-sg-referral-validation-echo-panel",
  templateUrl: "./mf-sg-echo-referral-validation-panel.component.html",
  styleUrls: ["./mf-sg-echo-referral-validation-panel.component.scss"],
})
export class MfSgEchoReferralValidationPanelComponent extends MfSgBaseComponent {
  pageLoaded: boolean = false;
  proposal!: MortgageFireSingaporeProposal;
  isIncomplete: boolean = false;
  isReferred: boolean = false;
  isPendingChecker: boolean = false;
  showBusinessRulesManager: boolean = false;

  constructor(public proposalService: MfSgProposalService) {
    super();
  }

  ngOnDestroy = () => {
    super.unsubscribeRxjs();
  };

  ngOnInit(): void {
    super.subscribeProposalLoadedNotifier((event) => {
      this.proposal = event.value as MortgageFireSingaporeProposal;
      this.showBusinessRulesManager = this.checkBusinessRulesManager();
      this.pageLoaded = true;
      this.isIncomplete =
        this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete
          ? true
          : false;
      this.isReferred =
        this.proposal.proposalStatus === Enums.ProposalStatus.Referred
          ? true
          : false;
      this.isPendingChecker =
        this.proposal.proposalStatus === Enums.ProposalStatus.PendingChecker
          ? true
          : false;
    });
    super.subscribeProposalSavedNotifier(this.proposalLoaded);
  }

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireSingaporeProposal;
    this.showBusinessRulesManager = this.checkBusinessRulesManager();
    this.isIncomplete =
      this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete
        ? true
        : false;
    this.isReferred =
      this.proposal.proposalStatus === Enums.ProposalStatus.Referred
        ? true
        : false;
    this.isPendingChecker =
      this.proposal.proposalStatus === Enums.ProposalStatus.PendingChecker
        ? true
        : false;
  };

  checkBusinessRulesManager() {
    let flag: boolean = false;
    if (
      common.isDefined(this.proposalService.businessRulesManager) &&
      common.isDefined(this.proposalService.businessRulesManager.echos)
    ) {
      return this.proposalService.businessRulesManager.echos.length > 0
        ? true
        : false;
    }
    return flag;
  }

  initialize() {}

  navigate(question: any) {
    pageLifeCycleNotifier.tab_request_notifier$.next(
      new KeyValue(question.tabId.toString(), question.key)
    );
  }
}
