import * as Enums from "./../../../framework/enum.shared";
import * as lodash from "lodash";

import { Echo } from "./../base/echo.model";
import { Echos } from "./../base/echos.model";
import { ExceptionManager } from "./../../../framework/utils/exception-manager";
import { GUID } from "./../../../framework/domain-entity/guid";
import { Proposal } from "./../base/proposal.model";
import { ProposalEntity } from "./../base/proposal-entity.model";
import { ProposalPackage } from "../base/proposal-package-model";
import { QuestionEntity } from "./../base/question-entity.model";
import { SectionTab } from "./../base/section-tab.model";
import { common } from "./../../../framework/utils/common";
import { proposalEntityFactory } from "./../factory/proposal-entity.factory";

class ProposalHelper {
  /** Method to extract and generate a new QuestionEntity array with only containing the question key and answer */
  formQuestionEntity(
    questions: QuestionEntity[],
    loadedProposalEntity: ProposalEntity
  ) {
    // console.log(loadedProposalEntity);
    const entities: QuestionEntity[] = [];

    if (common.isDefined(loadedProposalEntity.questionDictionary)) {
      questions.forEach((q) => {
        if (common.isDefined(loadedProposalEntity.getQuestion(q.key))) {
          const entity = new QuestionEntity(q.key, q.answer);
          entities.push(entity);
        } else {
          // console.log('[' + q.key + '] is not in loadedProposalEntity.questionDictionary');
        }
      });
    } else {
      ExceptionManager.error(
        "loadedProposalEntity.questionDictionary is undefined"
      );
    }

    return entities;
  }

  searchChildren<T extends Proposal>(proposals: T[], proposalId: GUID): T {
    const found = lodash.find(proposals, (child) => {
      return child.proposalId!.toId() === proposalId.toId();
    });

    return found as T;
  }
  emailValidation = (value: string): boolean => {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (reg.test(value)) return true;
    else return false;
  };

  phoneValidation = (value: string): boolean => {
    var reg = /^\+?[0-9]+$/;
    // const inputValue = parseInt(value.trim());
    if (
      value.length == 8 &&
      value !== "" &&
      reg.test(value) &&
      (value.toString()[0] == "9" || value.toString()[0] == "8")
    ) {
      return true;
    } else {
      return false;
    }
  };

  phoneHkValidation = (value: string): boolean => {
    var reg = /^\+?[0-9]+$/;
    // const inputValue = parseInt(value.trim());
    if (value !== "" && reg.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  convertToEchos<T>(echos: Echo<T>[]) {
    const result: Echos<T>[] = [];
    const groups = lodash.chain(echos).groupBy("info").value();

    for (const key in groups) {
      if (common.isDefined(key)) {
        const e = new Echos<T>("", groups[key] as Echo<T>[]);
        e.info = key;
        result.push(e);
      }
    }

    return result;
  }

  convertTitleToTabIntoEchos(
    echos: Echos<QuestionEntity>,
    tabSchemas: SectionTab[]
  ) {
    // Echos.title was assigned as question.tabId.toString();
    const tabId = parseInt(echos.info, 10);
    const foundTab = lodash.find(tabSchemas, (tab) => {
      return tab.index === tabId;
    });

    echos.title = foundTab!.displayText;
    return echos;
  }

  buildQuestionEntityEcho(
    message: string,
    question: QuestionEntity
  ): Echo<QuestionEntity> {
    const echo = new Echo<QuestionEntity>(message);
    echo.key = question.key;
    echo.entity = question;
    echo.title = "";
    echo.info = question.tabId;

    return echo;
  }

  constructProposalPackageEntityForServer<T extends Proposal>(
    proposal: ProposalPackage<T>,
    loadedProposalEntity: ProposalEntity,
    callback?: (proposalEntity: ProposalEntity) => void
  ): ProposalEntity {
    // extract policy level answers
    const returnProposalEntity = this.constructProposalEntityForServer(
      proposal,
      loadedProposalEntity,
      callback
    );

    // capture locations data
    if (common.isDefined(proposal.children) && proposal.children.length > 0) {
      returnProposalEntity.children = [];
      proposal.children.forEach((childProposal) => {
        const childProposalEntity = this.constructProposalEntityForServer(
          childProposal,
          loadedProposalEntity,
          callback
        );
        returnProposalEntity.children?.push(childProposalEntity);
      });
    }

    //HE-28
    if (common.isDefined(proposal.addtionalEndorsementType)) {
      returnProposalEntity.addtionalEndorsementType =
        proposal.addtionalEndorsementType;
    }

    if (common.isDefined(proposal.clausesDeductibles)) {
      returnProposalEntity.clausesDeductiblesTable =
        proposal.clausesDeductibles;
    }

    return returnProposalEntity;
  }

  constructProposalEntityForServer(
    proposal: Proposal,
    loadedProposalEntity: ProposalEntity,
    callback?: (proposalEntity: ProposalEntity) => void
  ): ProposalEntity {
    // extract policy level answers
    const questions = proposalHelper.formQuestionEntity(
      proposal.extractQuestionArray(),
      loadedProposalEntity
    );
    const returnProposalEntity = proposalEntityFactory.build(
      questions,
      proposal.productId,
      proposal.proposalId
    );

    if (common.isDefined(callback)) {
      callback!(returnProposalEntity);
    }

    return returnProposalEntity;
  }
}

export const proposalHelper: ProposalHelper = new ProposalHelper();
