import { AbstractProposal } from "./abstract-proposal";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import * as Enums from "src/app/framework/enum.shared";
import { ApiMapperNotifierModel } from "../../notifier/api-mapper-notifier.model";
import * as Const from "src/app/framework/const.shared";
import { ProposalEntity } from "../../base/proposal-entity.model";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";

export class DbsProposal implements AbstractProposal {
  public IsApprovedReadOnly(proposal: ProposalEntity): boolean {
    //DBSEP-2136
    if (
      proposal.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
      proposal.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
    ) {
      return true;
    }

    return false;
  }

  public IsIncompleteReadOnly(identityRolesService: IdentityRolesService) {
    // only Maker and Underwriter is editable in draft mode
    switch (identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
        // only Underwriter is editable in referred mode
        return false;

      case Enums.UserType.Broker:
        if (identityRolesService.getIdentity().role.isMaker) {
          return false;
        } else {
          return true;
        }

      default:
        return true;
    }
  }

  public isApiMapperNotifierModelAssignable(
    dataObject: ApiMapperNotifierModel
  ): boolean {
    return (
      common.isUndefinedOrNull(dataObject) || //if undefined when first init; has to let it pass-thru since unknown
      (common.isDefined(dataObject) &&
        common.isUndefinedOrNull(dataObject.proposalEntity)) || //if undefined when first init; has to let it pass-thru since unknown
      (common.isDefined(dataObject) &&
        common.isDefined(dataObject.proposalEntity) &&
        this.isDbsProposalEntity(dataObject.proposalEntity)) //if defined when init-ed
    );
  }

  public isDbsProposalEntity(proposal: ProposalEntity): boolean {
    return (
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MORTGAGEINTERESTPLUS ||
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSPTE ||
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSHDB ||
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MACH_IBG ||
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_CITI_HOUSEOWNERPLUSHDB ||
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_CITI_MORTGAGEFIREINSURANCEPTE ||
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_CITI_MORTGAGEFIREINSURANCECONDO
    );
  }

  public isKeyValueProposalEntityAssignable(
    dataObject: KeyValue<ProposalEntity>
  ) {
    return (
      common.isUndefinedOrNull(dataObject) || //if undefined when first init; has to let it pass-thru since unknown
      (common.isDefined(dataObject) &&
        common.isUndefinedOrNull(dataObject.value)) || //if undefined when first init; has to let it pass-thru since unknown
      (common.isDefined(dataObject) &&
        common.isDefined(dataObject.value) &&
        this.isDbsProposalEntity(dataObject.value)) //if defined when init-ed
    );
  }
}
