import * as MfEnum from "./../../singapore/base/mf-sg-enums";

import { ExceptionManager } from "./../../../../../../framework/utils/exception-manager";
import { GUID } from "./../../../../../../framework/domain-entity/guid";
import { mortgageFireSingaporeSettings } from "./../const/mf-sg-const";

class MortgageFireSingaporeHelper {
  readProductType(productId: GUID) {
    switch (productId.toString()) {
      case mortgageFireSingaporeSettings.hdbProductId.toString():
        return MfEnum.MortgageFireSgProduct.HDB;

      case mortgageFireSingaporeSettings.tpeProductId.toString():
        return MfEnum.MortgageFireSgProduct.PTE;

      case mortgageFireSingaporeSettings.mipProductId.toString():
        return MfEnum.MortgageFireSgProduct.MIP;

      case mortgageFireSingaporeSettings.ibgMFProductId.toString():
        return MfEnum.MortgageFireSgProduct.IBGMF;

      case mortgageFireSingaporeSettings.ibgMacProductId.toString():
        return MfEnum.MortgageFireSgProduct.IBGMAC;

      case mortgageFireSingaporeSettings.citiHdbProductId.toString():
        return MfEnum.MortgageFireSgProduct.CTHDB;
      case mortgageFireSingaporeSettings.citiMFPTEProductId.toString():
        return MfEnum.MortgageFireSgProduct.CTMFPTE;
      case mortgageFireSingaporeSettings.citiMFCondoProductID.toString():
        return MfEnum.MortgageFireSgProduct.CTMFCONDO;
      default:
        ExceptionManager.error("Unhandled");
        return null;
    }
  }
}

export const mortgageFireSingaporeHelper = new MortgageFireSingaporeHelper();
