import * as Enums from "src/app/framework/enum.shared";
import { Identity } from "src/app/shared/user/identity";
import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ROUTE_LOGIN } from "src/app/app.module";
import { common } from "src/app/framework/utils/common";
import { BroadcastService } from "src/app/services/broadcast.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { IdleService } from "src/app/services/idle/idle.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { MasterPolicyService } from "src/app/services/master-policy.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { environment } from "src/environments/environment";
import { BaseComponent } from "../shared/base/base.component";
import { IdleWarningStates } from "../shared/idle/enums/idle-warning.states.enum";
import { v1DlgInputArgs } from "../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { MdProductSelectionDialogComponent } from "../shared/partials/md-product-selection-dialog/md-product-selection-dialog.component";
import { filter } from "rxjs/operators";
import { ROUTE_USER_ROLE_SELECTION } from "src/app/app-routing.module";

@Component({
  selector: "[app-horiz-menu]", //render Selector as an Attribute, to prevent <app-home> nested
  templateUrl: "./horiz-menu.component.html",
  styleUrls: ["./horiz-menu.component.scss"],
})
export class HorizMenuComponent extends BaseComponent implements OnInit {
  public items: any[];

  idleTimer = true;
  allowedIdleTimeInSeconds = environment.idleAllowed;
  titleMessage = `You have been idle for ${
    this.allowedIdleTimeInSeconds / 60
  } minutes`;
  warningTimeInSeconds = environment.idleWarning;
  bodyMessage = `You are about to log off in ${this.warningTimeInSeconds} seconds`;
  public productListing: any;

  hideMenus: boolean = false;
  isSgProduct: boolean = false;
  isHkProduct: boolean = false;

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public idleService: IdleService,
    public masterPolicyService: MasterPolicyService,
    public masterDataService: MasterDataService,
    public uploadService: UploadExcelService,
    private cdkDialogService: CdkDialogService,
    public identity: Identity
  ) {
    super(router, broadcastService, identityRolesService);

    this.items = this.mapItems(router.config);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.showHideMenus();
    this.getCountry();
    this.timerSubscribe();
  }

  showHideMenus() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let url = event.url;
        //https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
        const paramsString = url.substring(url.indexOf("?") + 1, url.length);
        const searchParams = new URLSearchParams(paramsString);

        // Iterating the search parameters
        for (const p of searchParams) {
          //console.log(p);
        }
        if (
          searchParams.has("pid") ||
          paramsString === "/" + ROUTE_USER_ROLE_SELECTION
        ) {
          //hide menu if proposalpage
          this.hideMenus = true;
        } else {
          this.hideMenus = false;
        }
      });
  }

  getCountry(): void {
    //todo
    // this.sub = this.route.queryParams.subscribe((params) => {
    //   this.productId = params["pdid"];

    //   let country = this.productService.getCountryByProductId(this.productId!);
    //   switch (country) {
    //     case "SG":
    //       this.isSgProduct = true;
    //       this.isHkProduct = false;
    //       break;
    //     case "HK":
    this.isSgProduct = false;
    this.isHkProduct = true;
    //       break;
    //     default:
    //   }
    // });
  }

  //#region idle
  resubscribe(): void {
    this.idleTimer = true;
    this.timerSubscribe();
  }

  private timerSubscribe(): void {
    this.idleService.idleStateChanged().subscribe((val) => {
      if (val === IdleWarningStates.SecondaryTimerExpired) {
        this.idleService.stopTimer();
        this.idleTimer = false;
        //logout
        this.identityRolesService.logOut();
        this.router.navigate(["/" + ROUTE_LOGIN]);
      }
    });
  }
  //#endregion

  /* Method to invoke when product selection modal window open */
  btnNew_Onclick(event: any): void {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: null, //args.data,
      message: "", //this.confirmationMsg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdProductSelectionDialogComponent,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });

    event.preventDefault(); //do not goes further
  }

  //#region obsolete
  public onSelect({ item }: { item: any }): void {
    if (!item.items) {
      this.router.navigate([item.path]);
    }
  }

  // convert the routes to menu items.
  private mapItems(routes: any[], path?: string): any[] {
    return routes.map((item) => {
      const result: any = {
        text: item.text,
        path: (path ? `${path}/` : "") + item.path,
      };

      if (item.children) {
        result.items = this.mapItems(item.children, item.path);
      }

      return result;
    });
  }
  //#endregion
  //reload admin user profile
  public loadUserProfile(): void {
    //if same route, force reload
    //https://stackblitz.com/edit/angular-same-route-reload
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/" + "userProfileList"]));
  }
}
