import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  Inject,
  LOCALE_ID,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import * as Enum from "./../../../framework/enum.shared";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectAllCheckboxState,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { formatDate } from "@angular/common";
import { openItemDisplayStatus } from "../../../finance/finance-constant";
import {
  BatchInvoiceEntity,
  SearchCriteria,
} from "./models/batch-invoice-list.model";
import { common } from "../../../framework/utils/common";
import { warningZoneArgs } from "../../new-product/home/warning-zone/warning-zone.component";
import { Day } from "@progress/kendo-date-math";
import { BatchInvoiceListingEntity } from "./BatchInvoiceListingEntity";
import { Identity } from "src/app/shared/user/identity";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { BatchInvoiceListService } from "src/app/services/batch-invoice-list.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import {
  MdConfirmationMessageV2Component,
  v2DlgInputArgs,
} from "../../shared/partials/md-confirmation-message-v2/md-confirmation-message-v2.component";
import { NotifierService } from "src/app/services/notifier.service";

@Component({
  selector: "app-batch-invoice-list",
  templateUrl: "./batch-invoice-list.component.html",
  styleUrls: ["./batch-invoice-list.component.scss"],
})
export class BatchInvoiceListComponent implements OnInit {
  @ViewChild("heroForm", {
    static: false,
  })
  private form!: UntypedFormGroup;

  @ViewChild("oiEffectiveDateTo", {
    static: false,
  })
  public oiEffectiveDateTo!: DatePickerComponent;

  public openItemEntryDate_min!: Date;
  public openItemEntryDate_max!: Date;

  @ViewChild("oiPolicyEffectiveDateTo", {
    static: false,
  })
  public oiPolicyEffectiveDateTo!: DatePickerComponent;

  public policyeffectiveDate_min!: Date;
  public policyeffectiveDate_max!: Date;

  @ViewChild("autoDebitPeriod_dateInput", {
    static: false,
  })
  public autoDebitPeriod_dateInput!: DatePickerComponent;

  @ViewChild("appendTo", {
    read: ViewContainerRef,
    static: false,
  })
  public appendTo!: ViewContainerRef;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  public selectAllState: SelectAllCheckboxState = "unchecked";
  public listing: BatchInvoiceListingEntity[] = [];
  private totalListing: number = 0;
  submitted = false;

  searchCriteria = new SearchCriteria();
  public statusList!: Array<{
    text: string;
    value: number;
  }>;
  private isCitiProduct: boolean = false;
  autoDebitPeriod: any;
  public segmentList!: Array<{
    text: string;
    value: string;
  }>;
  public userProductList!: any;
  public checkableRows: number[] = [];
  public selectedRowIndexes: number[] = [];
  public loading!: boolean;
  public showDetails: boolean = false;
  public openItemId!: string;
  public redirectOpened: boolean = false;
  public redirectUpdateInvoiceOpened: boolean = false;
  public doManualPay: boolean = false;
  public doRefundPay: boolean = false;
  public doBatchAndExport: boolean = false;
  openItem!: BatchInvoiceEntity | undefined;

  msg_maxWarning!: string;
  public dialogOpened = false;
  public confirmationOpened = false;
  dialogCommand!: Enum.ProposalCommandAction;
  dialogData: any;
  confirmationMessage: any;
  confirmationMsg!: string;
  confirmationData: any;
  public warningDlgOpened = false;
  warningMessage!: string;
  warningData!: any;

  @Output() warningZoneEvent = new EventEmitter<any>();
  public disabledDates: Day[] = [
    Day.Saturday,
    Day.Sunday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
  ];
  public isSearch: boolean = false;
  public isSelectAll: boolean = true;
  public newInvoices: Array<{}> = [];
  public allInvoiceList: any;
  public mySelection: any = [];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private batchInvoiceListService: BatchInvoiceListService,
    private identity: Identity,
    private excelTemplate: ExcelTemplateService,
    private cdkDialogService: CdkDialogService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.defaultSearchCriteria();
    this.fetchUserList();
    this.fetchStatusList();
  }

  private defaultSearchCriteria() {
    // segment dropdownlist options
    const segmentList = [
      {
        text: "CBG",
        value: "CBG",
      },
      {
        text: "IBG",
        value: "IBG",
      },
      {
        text: "PB",
        value: "PB",
      },
      {
        text: "TPC",
        value: "TPC",
      },
    ];
    this.segmentList = segmentList;

    const policyEffectiveDateFrom: Date = new Date("1/1/2018");
    this.searchCriteria.policyeffectiveDateFrom = policyEffectiveDateFrom;

    // effectiveDateFrom ( 6 months after effectiveDateFrom)
    const policyeffectiveDateTo: Date = new Date(policyEffectiveDateFrom);
    policyeffectiveDateTo.setFullYear(
      policyEffectiveDateFrom.getFullYear() + 1
    );
    this.searchCriteria.policyeffectiveDateTo = policyeffectiveDateTo;
  }

  private fetchUserList() {
    // get user productsList
    this.userProductList = this.identity.currentUser().products;
  }

  private fetchStatusList() {
    // get statusList
    this.statusList = [];

    for (const key in openItemDisplayStatus) {
      if (openItemDisplayStatus.hasOwnProperty(key)) {
        const value = openItemDisplayStatus[key];
        this.statusList.push({
          text: value,
          value: Number(key),
        });
      }
    }
    // sort statusList by asc
    this.statusList.sort((x, y) => {
      return x.text > y.text ? 1 : x.text < y.text ? -1 : 0;
    });
  }
  public on_quoteNumber_keyup(event: any): void {
    var k;
    k = event.data?.charCodeAt(); //         k = event.keyCode;  (Both can be used)
    if (
      !(
        (k > 64 && k < 91) ||
        (k > 96 && k < 123) ||
        k == 8 ||
        k == 45 ||
        k == 32 ||
        (k >= 48 && k <= 57)
      )
    ) {
      event.preventDefault();
    }
  }

  selectAllPolicy = (select: boolean) => {
    if (select) {
      this.isSelectAll = false;
      this.checkableRows.forEach((idx) => {
        //selectedIndexes is a running number for whole/all pages, while the currentPageData only for selected current page
        //thus need to onset
        let allIdx = idx + this.skip;
        if (this.selectedRowIndexes.indexOf(allIdx) == -1) {
          this.selectedRowIndexes.push(allIdx);

          this.gridView.data[allIdx].isChecked = true;
        }
      });
      this.newInvoices = [];
      this.listing.forEach((invoice: BatchInvoiceListingEntity) => {
        invoice.isChecked = true;
        if (
          invoice.batchId === "" &&
          (invoice.statusId === 2 ||
            (invoice.statusId === 20 && invoice.pendInvBankBatchID === ""))
        ) {
          const openItemId = invoice.openItemId;
          this.newInvoices.push(openItemId);
        }
      });
      this.loadData();
    } else {
      this.isSelectAll = true;
      this.selectedRowIndexes = [];
      this.newInvoices = [];

      this.gridView.data.forEach((item) => {
        item.isChecked = false;
      });
    }
  };

  SetSelectedItem(dataItem: BatchInvoiceListingEntity, idx: number) {
    //revert
    dataItem.isChecked = !dataItem.isChecked;

    //selectedIndexes is a running number for whole/all pages, while the currentPageData only for selected current page
    //thus need to onset
    let allIdx = idx + this.skip;
    //add
    if (dataItem.isChecked) {
      if (this.selectedRowIndexes.indexOf(allIdx) == -1) {
        this.selectedRowIndexes.push(allIdx);
        this.newInvoices.push(dataItem.openItemId);
      }
    } else {
      //remove
      this.selectedRowIndexes.forEach((item, loopIndex) => {
        if (item === allIdx) {
          //item is the one that store the actual index; loopIndex is local index
          this.selectedRowIndexes.splice(loopIndex, 1);
          this.newInvoices.splice(loopIndex, 1);
        }
      });
    }
  }

  generateSelectedPolicies() {
    if (this.newInvoices.length === 0) {
      const msg = "Please choose at least 1 record to generate";
      // this.warningDlgOpened = true;
      // this.confirmationMsg = msg;
      this.WarningPopup(msg);
    } else {
      const msg =
        "You are about to Generate Batch Invoice for the selected Policies. <br><br> Action cannot be reverted back once done. <br><br> Please confirm.";
      this.confirmationMsg = msg;
      let v1InputData: v1DlgInputArgs = {
        opened: true,
        dialogWidth: 600,
        data: "",
        message: this.confirmationMsg,
      };

      const v1DialogRef = this.cdkDialogService.open(
        MdConfirmationMessageV1Component,
        {
          data: v1InputData,
        }
      );

      v1DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
          this.confirmationModalCloseForActions(result);
        }
      });
    }
  }

  WarningPopup(msg: string) {
    let v2InputData: v2DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: "",
      message: msg,
    };

    const v2DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV2Component,
      {
        data: v2InputData,
      }
    );

    v2DialogRef.afterClosed().subscribe((result: any) => {});
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }

  public onSubmit(): void {
    if (this.form.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }

    this.checkDatesRange();

    const isValid = this.validateMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }

  private checkDatesRange() {
    if (
      this.searchCriteria.effectiveDateFrom !== null &&
      this.searchCriteria.effectiveDateTo === null
    ) {
      this.searchCriteria.effectiveDateTo =
        this.searchCriteria.effectiveDateFrom;
    }
    if (
      this.searchCriteria.policyeffectiveDateFrom !== null &&
      this.searchCriteria.policyeffectiveDateTo === null
    ) {
      this.searchCriteria.policyeffectiveDateTo =
        this.searchCriteria.policyeffectiveDateFrom;
    }
  }

  private validateMandatoryFields(): boolean {
    let isValid: boolean = false;
    this.reformatPeriod();

    // dates
    const dates: boolean =
      this.searchCriteria.effectiveDateFrom !== null ||
      this.searchCriteria.effectiveDateTo !== null ||
      this.searchCriteria.policyeffectiveDateFrom !== null ||
      this.searchCriteria.policyeffectiveDateFrom !== null;

    isValid = dates; // || etc...

    return isValid;
  }
  reformatPeriod = () => {
    this.searchCriteria.autoDebitPeriod = "";
    if (!common.isUndefinedOrNull(this.autoDebitPeriod)) {
      if (this.isCitiProduct) {
        const period = formatDate(
          this.autoDebitPeriod,
          "dd/MM/yyyy",
          this.locale
        );
        this.searchCriteria.autoDebitPeriodYear = period.split("/")[2];
        this.searchCriteria.autoDebitPeriodMonth = period.split("/")[1];
        this.searchCriteria.autoDebitPeriodDate = period.split("/")[0];
        this.searchCriteria.autoDebitPeriod = this.autoDebitPeriod;
      } else {
        const period = formatDate(
          this.autoDebitPeriod,
          "MM/yyyy",
          this.locale
        ).toString();
        this.searchCriteria.autoDebitPeriodYear = !common.isUndefinedOrNull(
          period.split("/")[2]
        )
          ? period.split("/")[2]
          : period.split("/")[1];
        this.searchCriteria.autoDebitPeriodMonth = period.split("/")[0];
        this.searchCriteria.autoDebitPeriod = this.autoDebitPeriod;
      }
    } else {
      const validPeriod: boolean = this.autoDebitPeriod !== null;
      if (!validPeriod) {
        this.searchCriteria.autoDebitPeriodYear = "";
        this.searchCriteria.autoDebitPeriodMonth = "";
        this.searchCriteria.autoDebitPeriodDate = "";
        this.searchCriteria.autoDebitPeriod = "";
      }
    }
  };

  public sort: SortDescriptor[] = [
    {
      field: "policyNo",
      dir: "asc",
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }
  private loadData(): void {
    this.gridView = {
      data: orderBy(this.listing, this.sort),
      total: this.listing.length,
    };
  }
  private refresh(): void {
    this.clearSelection();
    this.loading = true;
    this.isSearch = true;
    this.batchInvoiceListService
      .searchBatchInvoiceListing(this.getQueryString())
      .subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            this.allInvoiceList = result;
            this.listing = result.body.d?.results;
            this.totalListing = result.body.d?.__count;
            this.loadItems();
            this.loading = false;
            this.notifier.success("Search successfully", this.appendTo);
          }
        },
        (err: any) => {
          this.notifier.error("Search failed", this.appendTo);
        }
      );
  }
  private getQueryString(): string {
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "policyNo");
    formData.append("sort[0][dir]", "desc");

    let array = [
      ["effectiveDateFrom", "gte", this.searchCriteria.effectiveDateFrom],
      ["effectiveDateTo", "lte", this.searchCriteria.effectiveDateTo],
      ["policyNo", "eq", this.searchCriteria.policyNo],
      ["batchId", "eq", this.searchCriteria.invoiceNo],
      ["segments", "eq", this.searchCriteria.segments],
      ["statusIds", "eq", this.searchCriteria.statusIds],
      ["productIds", "eq", this.searchCriteria.productIds],
      [
        "policyeffectiveDateFrom",
        "eq",
        this.searchCriteria.policyeffectiveDateFrom,
      ],
      [
        "policyeffectiveDateTo",
        "eq",
        this.searchCriteria.policyeffectiveDateTo,
      ],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    return querystring;
  }

  updateInvoiceNumber = () => {
    this.redirectUpdateInvoiceOpened = true;
  };

  downloadAllPolicies = () => {
    this.batchInvoiceListService
      .doDownloadAllPoliciesHandler(this.searchCriteria)
      .then((response: any) => {
        if (response)
          this.excelTemplate.downloadAllPolicies(
            allPoliciesDownloadColumns,
            "Policy",
            "Batch Invoice Policy",
            response
          );
      });
  };

  public buildCheckRows(dataItem: any, idx: number): boolean {
    if (!dataItem.batchId) {
      this.checkableRows.push(idx);
    }

    //isSelectAll is true by default, means canSelectAll
    if (this.isSelectAll) {
      return false;
    }
    return true;
  }

  private loadItems(): void {
    this.listing.forEach((entity, index) => {
      entity.isChecked = this.buildCheckRows(entity, index);
      entity.statusDisplayText = openItemDisplayStatus[entity.statusId];
    });

    this.gridView = {
      data: orderBy(this.listing, this.sort),
      total: this.totalListing,
    };
  }

  private clearSelection() {
    // Optionally, clear the selection when paging
    this.checkableRows = [];
    this.selectedRowIndexes = [];
  }
  public on_searchEffectiveDateFrom_change(value: Date): void {
    if (this.searchCriteria.effectiveDateFrom === null) {
      this.searchCriteria.effectiveDateTo = undefined;
      this.openItemEntryDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.openItemEntryDate_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.searchCriteria.effectiveDateTo &&
      this.searchCriteria.effectiveDateFrom &&
      this.searchCriteria.effectiveDateTo <
        this.searchCriteria.effectiveDateFrom
    ) {
      this.searchCriteria.effectiveDateTo = undefined;
      this.openItemEntryDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.openItemEntryDate_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.openItemEntryDate_min = value;
    this.openItemEntryDate_max = maxDate;

    // open datepicker
    // this.oiEffectiveDateTo.toggle();
    this.on_searchOIFrom_focus();
  }
  public on_searchPolicyEffectiveDateFrom_change(value: Date): void {
    if (this.searchCriteria.policyeffectiveDateFrom === null) {
      this.searchCriteria.policyeffectiveDateTo = undefined;
      this.policyeffectiveDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.policyeffectiveDate_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.searchCriteria.effectiveDateTo &&
      this.searchCriteria.effectiveDateFrom &&
      this.searchCriteria.effectiveDateTo <
        this.searchCriteria.effectiveDateFrom
    ) {
      this.searchCriteria.effectiveDateTo = undefined;
      this.policyeffectiveDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.policyeffectiveDate_min = new Date(2099, 12, 31, 0, 0, 0);
    }
    this.on_searchOIFrom_focus();
  }

  public on_searchOIFrom_focus(): void {
    if (this.oiEffectiveDateTo.isOpen) {
      this.oiEffectiveDateTo.toggle();
    }
    if (this.oiEffectiveDateTo.isOpen) {
      this.oiEffectiveDateTo.toggle();
    }
  }
  public on_searchPEFrom_focus(): void {
    if (this.oiPolicyEffectiveDateTo.isOpen) {
      this.oiPolicyEffectiveDateTo.toggle();
    }
    if (this.oiPolicyEffectiveDateTo.isOpen) {
      this.oiPolicyEffectiveDateTo.toggle();
    }
  }

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    this.openItemId = dataItem.openItemId;

    if (columnIndex === 0) {
      this.openItem = dataItem;
      switch (dataItem.actionName) {
        default:
          return;
      }
    } else {
      this.redirectOpened = true;
    }
  }

  public returnToBatchInvoiceListing(val: any) {
    this.redirectOpened = false;
    this.redirectUpdateInvoiceOpened = false;
  }
  async confirmationModalCloseForActions(args: dlgModelArgs) {
    this.confirmationOpened = false;
    if (args.status === "yes") {
      this.batchInvoiceListService
        .doBatchAndExportHandler(this.newInvoices)
        .subscribe((response: any) => {
          if (response)
            this.excelTemplate.downloadBatchInvoices(
              batchInvoiceColumns,
              "Batch id Generated",
              "Batched Policy",
              response.d?.results
            );
        }),
        (error: any) => {
          this.notifier.error("Download failed", this.appendTo);
        };
    }
  }
}
const batchInvoiceColumns: string[] = [
  "Batch Id",
  "Financial Institution Branch/BU PC Code",
  "Bank Reference Number",
  "Meridian Master Policy Number",
  "Policy Type",
  "Policy Number",
  "Main Insured Name",
  "Insured Correspondence Address",
  "Risk Address",
  "Original Inception Date",
  "Policy Inception/Start Date",
  "Policy Expiry Date",
  "Endorsement Effective Date",
  "Total Sum Insured",
  "Gross Premium excl. GST",
  "GST on Gross Premium",
  "Commission excl. GST",
  "GST on Commission",
  "Premium Net Off Comm.",
  "GST on Premium Net Off Comm.",
  "Transaction Code",
  "Total Premium",
];

const allPoliciesDownloadColumns: string[] = [
  "Policy Number",
  "Total Premium",
  "GST Amount",
  "Batch Id",
  "Status",
  "Last Updated By",
];
