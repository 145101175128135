import { Subject } from "rxjs";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { QuestionEntity } from "./../base/question-entity.model";

class QuestionNotifier {
  /** Notify after proposal loaded */
  answer_change_notifier$: Subject<KeyValue<QuestionEntity>> = new Subject<
    KeyValue<QuestionEntity>
  >();
}

export const questionNotifier = new QuestionNotifier();
