import { KeyValue } from "../../../framework/domain-entity/key-value";
import { Subject } from "rxjs";

class DialogActionNotifier {
  /** Notify on dialog Cancel() */
  cancel_notifier$: Subject<KeyValue<any>> = new Subject<KeyValue<any>>();

  /** Notify on dialog Ok() */
  ok_notifier$: Subject<KeyValue<any>> = new Subject<KeyValue<any>>();
}

export const dialogActionNotifier = new DialogActionNotifier();
