import { Component, Input, OnInit, LOCALE_ID, Inject } from "@angular/core";
import { Gender, MaritalStatus } from "src/app/framework/enum.shared";
import { ApiModel, PersonModel } from "../../user-profile.model";
import * as constants from "../../user-profile.constant";
import { common } from "src/app/framework/utils/common";
import { UserProfileService } from "src/app/services/user-profile.service";
import { DatePipe, formatDate } from "@angular/common";

@Component({
  selector: "app-view-personal-details",
  templateUrl: "./view-personal-details.component.html",
  styleUrls: ["./view-personal-details.component.scss"],
})
export class ViewPersonalDetailsComponent implements OnInit {
  isDataLoaded: boolean = false;
  personalDetails = new PersonModel();
  @Input() data!: any;
  model = new ApiModel();
  isDateTimeMin!: boolean;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private userProfileService: UserProfileService
  ) {}
  ngOnInit(): void {
    this.getUserProfile();
  }
  public getUserProfile(): void {
    if (common.isDefined(this.data?.userId) && this.data?.userId != "") {
      this.isDataLoaded = true;
      this.userProfileService
        .searchUserProfileByUserId(this.data.userId)
        .subscribe((response) => {
          if (response && response.body) {
            this.model = response.body;
            this.personalDetails = this.model.userProfile.person;
            this.personalDetails.displayGender =
              Gender[this.personalDetails.gender];
            this.personalDetails.displayMaritalStatus =
              MaritalStatus[this.personalDetails.maritalStatus];
            this.loadChild();
            this.loadNationality();
            this.isDateTimeMinValue(this.personalDetails.dateOfBirth);
            this.isDataLoaded = false;
          }
        });
    }
  }
  //Load country based on the selected usertype
  private loadNationality(): void {
    constants.nationality.forEach((item) => {
      if (this.personalDetails.nationality === item.value) {
        this.personalDetails.displayNationality = item.text;
      }
    });
  }
  private loadChild(): void {
    constants.personNoOfChildren.forEach((item) => {
      if (this.personalDetails.noOfChildren === item.value) {
        this.personalDetails.noOfChildren = item.text;
      }
    });
  }
  isDateTimeMinValue(value: any) {
    this.isDateTimeMin = formatDate(value, "yyyy", this.locale) === "0001";
  }
}
