import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CdkDialogService } from "../../../../../../../../services/dialog/cdk-dialog.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { MasterPolicyService } from "src/app/services/master-policy.service";
import { BaseTodoListComponent } from "../../../../../../base/base-todo-list/base-todo-list.component";
import { MfHkSearchCriteria } from "./mf-hk-search-criteria";
import { HaseTodoPolicyListingEntity } from "./HaseTodoPolicyListingEntity";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { MdInformationMessageComponent } from "src/app/components/shared/partials/md-information-message/md-information-message.component";

@Component({
  selector: "app-mf-hk-todo-list",
  templateUrl: "./mf-hk-todo-list.component.html",
  styleUrls: ["./mf-hk-todo-list.component.scss"],
})
export class MfHkTodoListComponent
  extends BaseTodoListComponent
  implements OnInit
{
  override model = new MfHkSearchCriteria();
  override listing: HaseTodoPolicyListingEntity[] = [];
  override proposalStatusOptions: Array<{ text: string; value: number }> = [
    { text: "Draft", value: 6 },
    { text: "Accepted", value: 2 },
    { text: "Approved", value: 1 },
    { text: "Referred", value: 3 },
    { text: "Declined", value: 4 },
  ];

  migratedOptions: Array<{ text: string; value: string }> = [
    //{ text: "All", value: "" },
    { text: "Migrated", value: "1" },
    { text: "Non-Migrated", value: "0" },
  ];

  public defaultMigratedItem: { text: string; value: string } = {
    text: "All",
    value: "",
  };

  @ViewChild("toDoInterfaceDateTo", { static: false })
  public toDoInterfaceDateTo!: DatePickerComponent;

  public toDoInterfaceDateTo_min!: Date;
  public toDoInterfaceDateTo_max!: Date;
  public httpRequesting: boolean = false;

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public override masterPolicyService: MasterPolicyService,
    public override masterDataService: MasterDataService,
    public override uploadService: UploadExcelService,
    public override cdkDialogService: CdkDialogService
  ) {
    super(
      router,
      broadcastService,
      identityRolesService,
      masterPolicyService,
      masterDataService,
      uploadService,
      cdkDialogService
    );
  }

  //#region
  protected override defaultSearchCriteria() {
    super.defaultSearchCriteria();

    // effectiveDateFrom (14 days earlier from today)
    const interfaceDateFrom: Date = new Date(new Date().getFullYear(), 0, 1);
    //this.model.interfaceDateFrom = interfaceDateFrom;

    // effectiveDateFrom ( 6 months after effectiveDateFrom)
    const interfaceDateTo: Date = new Date(
      interfaceDateFrom.getFullYear(),
      11,
      31
    );
    //this.model.interfaceDateTo = interfaceDateTo;
  }

  protected override fetchUserList() {
    if (this.identityRolesService.isUnderwriter) {
      this.masterDataService
        .getHaseUnderwriterUserList()
        .subscribe((response) => {
          // update list
          var keyValueUserList = response;
          keyValueUserList.forEach((mp) => {
            this.userList.push({ text: mp.value, value: mp.key });
          });
        });
    } else {
      this.masterDataService.getHaseUserList().subscribe((response) => {
        // update list
        var keyValueUserList = response;
        keyValueUserList.forEach((mp) => {
          this.userList.push({ text: mp.value, value: mp.key });
        });
      });
    }
  }

  public on_searchInterfaceDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
  }

  public on_searchInterfaceDateFrom_change(value: Date): void {
    if (this.model.interfaceDateFrom === null) {
      this.model.interfaceDateTo = undefined;
      this.toDoInterfaceDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoInterfaceDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.interfaceDateTo &&
      this.model.interfaceDateFrom &&
      this.model.interfaceDateTo < this.model.interfaceDateFrom
    ) {
      this.model.interfaceDateTo = undefined;
      this.toDoInterfaceDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoInterfaceDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    // open datepicker
    this.toDoInterfaceDateTo.toggle();
    this.on_searchInterfaceDateFrom_focus();
  }

  protected override modelToArray(): (
    | (string | Date | undefined)[]
    | (string | string[])[]
    | (string | number[])[]
    | (string | number)[]
  )[] {
    let array = [
      ["startDateFrom", "gte", this.model.startDateFrom],
      ["startDateTo", "gte", this.model.startDateTo],
      ["expiryDateFrom", "gte", this.model.expiryDateFrom],
      ["expiryDateTo", "gte", this.model.expiryDateTo],
      ["effectiveDateFrom", "gte", this.model.effectiveDateFrom],
      ["effectiveDateTo", "lte", this.model.effectiveDateTo],
      ["quoteNo", "eq", this.model.quoteNo],
      ["policyNo", "eq", this.model.policyNo],
      ["masterPolicyId", "eq", this.model.masterPolicyId],
      ["cin", "eq", this.model.cin],
      ["insuredName", "eq", this.model.insuredName],
      ["productId", "eq", this.model.productId],
      ["proposalStatus", "eq", this.model.proposalStatus],
      //["bankReferenceNo", "eq", this.model.bankReferenceNo],
      ["createdByUserId", "eq", this.model.createdByUserId],
      //["userTeam", "eq", this.model.userTeam],
      ["additionalInsuredName", "eq", this.model.additionalInsuredName],
      //["bcrn", "eq", this.model.bcrn],
      //["riskAddressPostalCode", "eq", this.model.riskAddressPostalCode],
      ["insuredPropertyAddress", "eq", this.model.insuredPropertyAddress],
      ["interfaceDateFrom", "gte", this.model.interfaceDateFrom],
      ["interfaceDateTo", "lte", this.model.interfaceDateTo],
      [
        "mortgageLoanAccountNumber1",
        "eq",
        this.model.mortgageLoanAccountNumber1,
      ],
      ["migrated", "eq", this.model.migrated],
    ];

    return array;
  }
  protected override refresh(): void {
    this.clearSelection();
    this.loading = true;
    this.httpRequesting = true;
    const formData: FormData = this.setFormData();
    var result = this.checkFilter();
      if (result == true) {
          const data = {
              message: "Please provide at least one search filter to proceed.",
          };
          
          const v1DialogRef = this.cdkDialogService.open(
              MdInformationMessageComponent,
              {
                  data: data,
              }
          );

          v1DialogRef.afterClosed().subscribe((result: any) => {
              // Subscription runs after the dialog closes
              if (result) {
                  this.loading = false;
                  this.httpRequesting = false;
              }
          });
      }
      else {
          //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
          //IT HAS TO BE IN SINGLE CHUNK OF STRING
          var querystring: string = "";
          for (var pair of formData.entries()) {
              querystring +=
                  encodeURIComponent(pair[0]) +
                  "=" +
                  encodeURIComponent(pair[1] as string) +
                  "&";
          }

          this.masterPolicyService.searchHaseTodoPolicyListing(querystring).subscribe(
              (result: any) => {
                  if (
                      result != undefined &&
                      result.body != undefined &&
                      result.body != null
                  ) {
                      this.listing = result.body.d?.results;
                      this.totalListing = result.body.d?.__count;
                      this.loadItems();
                      this.loading = false;
                      this.httpRequesting = false;
                  }
              },
              (err: any) => {
                  console.log(err);
                  this.httpRequesting = false;
              }
          );
      }
  }

  protected override fetchMasterPolicyList = async () => {
    this.masterPolicyOptions = new Array<{
      text: string;
      value: string;
    }>();

    this.masterPolicyService.getList(this.currentUser!).subscribe(
      (result: any) => {
        this.masterPolicyList = result;

        // update list
        this.masterPolicyList.forEach((mp) => {
          const displayText =
            mp.policyType +
            " - " +
            mp.description +
            " - " +
            mp.meridianMasterPolicyNumber;
          this.masterPolicyOptions.push({ text: displayText, value: mp.id });
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  };
  //#endregion
}
