import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as lodash from "lodash";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { KeyValue } from "../framework/domain-entity/key-value";
import { common } from "../framework/utils/common";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class MasterDataService extends ApiService {
  SERVER_URL_MAKERUSERLIST: string = "api/master/getMakerUserList";
  SERVER_URL_HASEUSERLIST: string = "api/master/getHaseUserList";
  SERVER_URL_UNDERWRITERMAKERUSERLIST: string =
    "api/master/getUnderwriterMakerUserList";
  SERVER_URL_HASE_UNDERWRITERUSERLIST: string =
    "api/master/getHaseUnderwriterUserList";
  SERVER_URL_COUNTRYLIST: string = "api/master/getCountryList";
  SERVER_URL_HKDISTRICTCODES: string = "api/master/getHkDistrictCodes";
  SERVER_URL_UNIQUENATIONALITYLIST: string =
    "api/master/getUniqueNationalityList";
  SERVER_URL_NATIONALITYLIST: string = "api/master/getNationalityList";
  SERVER_URL_SGIBGOCCUPANCYRATE: string = "api/master/getSgIbgOccupancyRate";
  SERVER_URL_SGIBGMACHINERYTYPELIST: string =
    "api/master/getSGIBGMachineryTypeList";
  SERVER_URL_FINANCIALINSTBRANCHES: string =
    "api/master/getFinancialInstitutionBranches";
  SERVER_URL_UNDERWRITERREPORTLIST: string =
    "api/master/getUnderwriterReports";

  country_list: KeyValue<string>[] = [];
  hkDistrict_codes: KeyValue<string>[] = [];
  nationality_list: KeyValue<string>[] = [];
  nationalityAll_list: KeyValue<string>[] = [];
  financialInstitutionBranchesDictionary!: {
    [key: string]: KeyValue<string>[];
  } | null;
  financialInstitutionBranchesList: KeyValue<string>[] = [];
  sgIBGOccupanyRate_list: KeyValue<string>[] = [];
  sgIBGMachineryType_list: KeyValue<string>[] = [];
  sgIOccupation_list: KeyValue<string>[] = [];
  makerUser_list: KeyValue<string>[] = [];
  haseUser_list: KeyValue<string>[] = [];
  makerCheckerUser_list: KeyValue<string>[] = [];
  underwriterUser_list!: KeyValue<string>[];
  haseUnderwriterUser_list!: KeyValue<string>[];
  financialInstitutionBranchesfullList: any[] = [];
  underwriterReport_list!: KeyValue<string>[];

  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  getCountryList = (): Observable<KeyValue<string>[]> => {
    if (common.isDefined(this.country_list) && this.country_list.length > 0) {
      return of(this.country_list);
    } else {
      //const url = 'http://localhost:19442/api/master/getCountryList?v=' + AppSettings.appSettings.jsonVersion;
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_COUNTRYLIST
      ).pipe(
        map(
          (response: any) => {
            this.country_list = [];
            response.d.forEach((i: { value: string; key: any }) => {
              this.country_list.push(new KeyValue(i.value, i.key));
            });

            this.country_list = lodash.orderBy(
              this.country_list,
              ["value"],
              ["asc"]
            );

            return this.country_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getHkDistrictCodes = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.hkDistrict_codes) &&
      this.hkDistrict_codes.length > 0
    ) {
      return of(this.hkDistrict_codes);
    } else {
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_HKDISTRICTCODES
      ).pipe(
        map(
          (response: any) => {
            this.hkDistrict_codes = [];
            response.d.forEach((i: { value: string; key: any }) => {
              this.hkDistrict_codes.push(new KeyValue(i.value, i.key));
            });

            this.hkDistrict_codes = lodash.orderBy(
              this.hkDistrict_codes,
              ["key"],
              ["asc"]
            );

            return this.hkDistrict_codes;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getNationalityList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.nationality_list) &&
      this.nationality_list.length > 0
    ) {
      return of(this.nationality_list);
    } else {
      //const url = 'http://localhost:19442/api/master/getUniqueNationalityList?v=' + AppSettings.appSettings.jsonVersion;
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_UNIQUENATIONALITYLIST
      ).pipe(
        map(
          (response: any) => {
            this.nationality_list = response.d;

            this.nationality_list = [];
            response.d.forEach((i: { value: string; key: any }) => {
              this.nationality_list.push(new KeyValue(i.value, i.key));
            });

            this.nationality_list = lodash.orderBy(
              this.nationality_list,
              ["value"],
              ["asc"]
            );

            return this.nationality_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getAllNationalityList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.nationalityAll_list) &&
      this.nationalityAll_list.length > 0
    ) {
      return of(this.nationalityAll_list);
    } else {
      //const url = 'http://localhost:19442/api/api/master/getNationalityList?v=' + AppSettings.appSettings.jsonVersion;
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_NATIONALITYLIST
      ).pipe(
        map(
          (response: any) => {
            this.nationalityAll_list = response.d;

            this.nationalityAll_list = [];
            response.d.forEach((i: { value: string; key: any }) => {
              this.nationalityAll_list.push(new KeyValue(i.value, i.key));
            });

            this.nationalityAll_list = lodash.orderBy(
              this.nationalityAll_list,
              ["value"],
              ["asc"]
            );
            return this.nationality_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  // /** Method to retrieved financial institution branches
  //  * CBG: [FIB]
  //  * IBG: [BUPC]
  //  * Both: ['']
  //  */

  clearFinancialInstitutionBranches() {
    this.financialInstitutionBranchesDictionary = null;
  }

  getFinancialInstitutionBranches = (category: string): Observable<any> => {
    this.financialInstitutionBranchesDictionary = {};
    this.financialInstitutionBranchesList = [];
    this.financialInstitutionBranchesfullList = [];

    return this.get(this.SERVER_URL_FINANCIALINSTBRANCHES, {}, {}).pipe(
      map(
        (response: any) => {
          response.d.forEach(
            (i: {
              isActive: string;
              category: string;
              value: string;
              key: any;
            }) => {
              if (i.isActive === "True") {
                const rCategory = i.category.trim().toUpperCase();
                if (
                  common.isUndefinedOrNull(
                    this.financialInstitutionBranchesDictionary![rCategory]
                  )
                ) {
                  this.financialInstitutionBranchesDictionary![rCategory] = [];
                }

                this.financialInstitutionBranchesList.push(
                  new KeyValue(i.value, i.key)
                );
                this.financialInstitutionBranchesDictionary![rCategory].push(
                  new KeyValue(i.value, i.key)
                );
              }
              this.financialInstitutionBranchesfullList.push({
                value: i.value,
                key: i.key,
                isActive: i.isActive,
              });
            }
          );

          if (common.isStringNullOrEmpty(category)) {
            return this.financialInstitutionBranchesList;
          } else {
            return this.financialInstitutionBranchesDictionary![
              category.trim().toUpperCase()
            ];
          }
        },
        (error: any) => {
          return error;
        }
      )
    );
  };

  getSgIbgOccupancyRateList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.sgIBGOccupanyRate_list) &&
      this.sgIBGOccupanyRate_list.length > 0
    ) {
      return of(this.sgIBGOccupanyRate_list);
    } else {
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_SGIBGOCCUPANCYRATE
      ).pipe(
        map(
          (response: any) => {
            this.sgIBGOccupanyRate_list = response.d;

            this.sgIBGOccupanyRate_list = [];
            response.d.forEach((i: { value: string; key: any }) => {
              this.sgIBGOccupanyRate_list.push(new KeyValue(i.value, i.key));
            });

            return this.sgIBGOccupanyRate_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getSGIBGMachineryTypeList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.sgIBGMachineryType_list) &&
      this.sgIBGMachineryType_list.length > 0
    ) {
      return of(this.sgIBGMachineryType_list);
    } else {
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_SGIBGMACHINERYTYPELIST
      ).pipe(
        map(
          (response: any) => {
            this.sgIBGMachineryType_list = response.d;

            this.sgIBGMachineryType_list = [];
            response.d.forEach((i: { value: string; key: any }) => {
              this.sgIBGMachineryType_list.push(new KeyValue(i.value, i.key));
            });

            return this.sgIBGMachineryType_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getMakerUserList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.makerUser_list) &&
      this.makerUser_list.length > 0
    ) {
      return of(this.makerUser_list);
    } else {
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_MAKERUSERLIST
      ).pipe(
        map(
          (response: any) => {
            this.makerUser_list = [];
            response.d.forEach((i: { key: string; value: any }) => {
              this.makerUser_list.push(new KeyValue(i.key, i.value));
            });

            return this.makerUser_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getHaseUserList = (): Observable<KeyValue<string>[]> => {
    if (common.isDefined(this.haseUser_list) && this.haseUser_list.length > 0) {
      return of(this.haseUser_list);
    } else {
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_HASEUSERLIST
      ).pipe(
        map(
          (response: any) => {
            this.haseUser_list = [];
            response.d.forEach((i: { key: string; value: any }) => {
              this.haseUser_list.push(new KeyValue(i.key, i.value));
            });

            return this.haseUser_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getUnderwriterMakerUserList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.underwriterUser_list) &&
      this.underwriterUser_list.length > 0
    ) {
      return of(this.underwriterUser_list);
    } else {
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_UNDERWRITERMAKERUSERLIST
      ).pipe(
        map(
          (response: any) => {
            this.underwriterUser_list = [];
            response.d.forEach((i: { key: string; value: any }) => {
              this.underwriterUser_list.push(new KeyValue(i.key, i.value));
            });

            return this.underwriterUser_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getHaseUnderwriterUserList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.haseUnderwriterUser_list) &&
      this.haseUnderwriterUser_list.length > 0
    ) {
      return of(this.haseUnderwriterUser_list);
    } else {
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_HASE_UNDERWRITERUSERLIST
      ).pipe(
        map(
          (response: any) => {
            this.haseUnderwriterUser_list = [];
            response.d.forEach((i: { key: string; value: any }) => {
              this.haseUnderwriterUser_list.push(new KeyValue(i.key, i.value));
            });

            return this.haseUnderwriterUser_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getSGIOccupationList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.sgIOccupation_list) &&
      this.sgIOccupation_list.length > 0
    ) {
      return of(this.sgIOccupation_list);
    } else {
      //const url = 'library/module/new-product/sme/data/getMasterData.json?v=' + AppSettings.appSettings.jsonVersion;
      //move to assets folder for security
      const url =
        "assets/new-product/sme/data/getMasterData.json?v=" +
        environment.jsonVersion;

      //todo: cache
      //this.get<KeyValue<string>[]>(url, {}, {}, false, true)
      return this.httpClient.get(url).pipe(
        map(
          (response: any) => {
            this.sgIOccupation_list = response;
            this.sgIOccupation_list = [];

            if (common.isDefined(response.ind)) {
              response.ind.forEach((industry: { ic: string; dn: any }) => {
                this.sgIOccupation_list.push(
                  new KeyValue(industry.ic, industry.dn)
                );
              });
            }

            return this.sgIOccupation_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };

  getUnderwriterReportList = (): Observable<KeyValue<string>[]> => {
    if (
      common.isDefined(this.underwriterReport_list) &&
      this.underwriterReport_list.length > 0
    ) {
      return of(this.underwriterReport_list);
    } else {
      return this.getApiData<KeyValue<string>[]>(
        this.SERVER_URL_UNDERWRITERREPORTLIST
      ).pipe(
        map(
          (response: any) => {
            this.underwriterReport_list = [];
            response.d.forEach((i: { key: string; value: any }) => {
              this.underwriterReport_list.push(new KeyValue(i.key, i.value));
            });

            return this.underwriterReport_list;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  };
}
