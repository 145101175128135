import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import { DIALOG_DATA } from "src/app/components/shared/partials/dialog/dialog-tokens";

@Component({
  selector: "app-mf-hk-address-help-form",
  templateUrl: "./mf-hk-address-help-form.component.html",
  styleUrls: ["./mf-hk-address-help-form.component.scss"],
})
export class MfHkAddressHelpFormComponent implements OnInit {
  showGeoCode = true;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any
  ) {
    if (this.inputData) {
      this.showGeoCode = this.inputData.showGeoCode;
    }
  }

  ngOnInit(): void {}

  public close(status: any) {
    this.dialogRef?.close(null);
  }
}
