import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { ClientAddressEntity } from "src/app/models/customer/base/client-address-entity.model";
import {
  ClientEntity,
  PreferredDeliveryEntity,
} from "src/app/models/customer/base/client-entity.model";
import { ClientService } from "src/app/services/customer/client.service";
import { MasterDataService } from "src/app/services/master-data.service";
import * as Enum from "src/app/framework/enum.shared";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import { Global } from "src/app/shared/global";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import { common } from "src/app/framework/utils/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { NotifierService } from "src/app/services/notifier.service";
import { MfHkBaseComponent } from "../mf-hk-base.component";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHkAddressHelpFormComponent } from "../mf-hk-address-help-form/mf-hk-address-help-form.component";

@Component({
  selector: "mf-hk-preferred-delivery",
  templateUrl: "./mf-hk-preferred-delivery.component.html",
  styleUrls: ["./mf-hk-preferred-delivery.component.scss"],
})
export class MfHkPreferredDeliveryComponent
  extends MfHkBaseComponent
  implements OnInit
{
  preferredDeliveryMethod: any;
  proposal!: MortgageFireHongKongProposal;
  //googleSearchAutoCompleteClientInformation: GoogleAddressAutoCompleteManager;
  ibgDisabled: boolean = true;
  isProcessing: boolean = false;
  //timeout = Application.angular_$timeout();
  showCorrespondencebtn: boolean = false;
  clientData!: PreferredDeliveryEntity;
  address!: ClientAddressEntity;
  showErrorMessage: boolean = false;
  isChangeOfCorrespondenceAddressType: boolean = false;
  hasMobileNoError: boolean = false;
  hasEmailError: boolean = false;
  emailPattern: string = "";
  readOnlyFields: any[] = [];
  addAddress1RO: boolean = true;
  addAddress2RO: boolean = true;
  addAddress3RO: boolean = true;
  addAddress4RO: boolean = true;
  addCityRO: boolean = true;
  addCountryRO: boolean = true;
  addPostcodeRO: boolean = true;
  addStateRO: boolean = true;
  nationalityList: any[] = [];
  countryList: any[] = [];
  firstRun: boolean = true;

  isStateVisible: boolean = false;
  countryExcludeState: any = ["SG", "HK"];

  errorMsgInHtml!: SafeHtml;

  @ViewChild("insuredAddress_lotunit", { static: false })
  public insuredAddress_lotunit: any;

  @ViewChild("insuredAddress_building", { static: false })
  public insuredAddress_building: any;

  @ViewChild("insuredAddress1", { static: false })
  public insuredAddress1: any;

  @ViewChild("insuredAddress2", { static: false })
  public insuredAddress2: any;

  @ViewChild("insuredAddress3", { static: false })
  public insuredAddress3: any;

  @ViewChild("insuredAddress4", { static: false })
  public insuredAddress4: any;

  @ViewChild("insuredCity", { static: false })
  public insuredCity: any;

  @ViewChild("insuredPostalCode", { static: false })
  public insuredPostalCode: any;

  @ViewChild("insuredState", { static: false })
  public insuredState: any;

  @ViewChild("clientInformation_autocomplete")
  clientInformation_autocomplete!: GooglePlaceDirective;

  @ViewChild("modal_body")
  modal_body!: ElementRef;

  options = {
    types: ["address"],
    componentRestrictions: { country: [] }, //no country restriction
  } as unknown as Options;

  constructor(
    public proposalService: MfHKProposalService,
    private clientService: ClientService,
    private masterDataService: MasterDataService,
    private cdkDialogService: CdkDialogService,
    private sanitizer: DomSanitizer,
    private notifier: NotifierService
  ) {
    super();
    // this.$state = Application.angular_$state();
    // // empty country value to search for all
    // this.googleSearchAutoCompleteClientInformation =
    //     new GoogleAddressAutoCompleteManager('clientInformation_autocomplete', '', this.updateAddressValues);
  }

  private dialogOkNotifierSubscription: Subscription = new Subscription();

  override ngOnInit(): void {
    this.initialize(); //2023-02-10: init var first

    if (this.proposalService.proposal.customerMappingId! > 0) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId!,
          this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        ?.subscribe((response: any) => {
          this.preferredDeliveryMethod = cloneDeep(
            this.proposalService.proposal.qsMaster
              .qs_master_preferredDeliveryMethod!.answer
          );
          let mobile = cloneDeep(
            this.proposalService.proposal.qsMaster
              .qs_master_preferredDeliveryMethod_MobileNo!.answer
          );
          this.clientData.mobileNumber = mobile;
          let email = cloneDeep(
            this.proposalService.proposal.qsMaster
              .qs_master_preferredDeliveryMethod_Email!.answer
          );
          this.clientData.emailAddress = email;
          if (
            this.proposalService.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.isAnswered()
          ) {
            var addressList =
              this.proposalService.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer.split(
                "|"
              );
            if (addressList.length < 2) {
              this.address = cloneDeep(response.address[0]);
            } else {
              if (addressList[9].includes("Hong Kong SAR")) {
                // this.address.addressLine1 = addressList[0];
                // this.address.addressLine2 = addressList[1];
                // this.address.unitNo = addressList[2];
                // this.address.buildingName = addressList[3];
                // this.address.city = "Singapore";
                // this.address.countryISO2 = addressList[4].trim();
                // this.address.postCode = addressList[5];
                this.address.unitNo = addressList[0];
                this.address.buildingName = addressList[1];
                this.address.addressLine1 = addressList[2];
                this.address.addressLine2 = addressList[3];
                this.address.addressLine3 = addressList[4];
                this.address.addressLine4 = addressList[5];
                this.address.countryISO2 = "HK";
              } else {
                this.address.unitNo = addressList[0];
                this.address.buildingName = addressList[1];
                this.address.addressLine1 = addressList[2];
                this.address.addressLine2 = addressList[3];
                this.address.addressLine3 = addressList[4];
                this.address.addressLine4 = addressList[5];
                //all offset 2 with the addition of addressLine3/4
                this.address.postCode = addressList[4 + 2];
                this.address.city = addressList[5 + 2];
                this.address.stateName = addressList[6 + 2];
                this.address.countryISO2 = addressList[7 + 2].trim();
              }
            }
          } else {
            this.address = cloneDeep(response.address[0]);
          }
        });
    }

    this.dialogOkNotifierSubscription =
      dialogActionNotifier.ok_notifier$.subscribe((event: any) => {
        this.updateDeliveryMethod();
      });

    //this.initialize();
  }

  ngOnDestroy() {
    super.unsubscribeRxjs();
    this.dialogOkNotifierSubscription.unsubscribe();
  }

  private initialize() {
    this.proposal = this.proposalService.proposal;
    this.nationalityList = new Array<any>();
    this.countryList = new Array<any>();
    super.subscribeTabLoadRenderer();
    super.subscribeTabLoadRenderer();

    this.initializeQuestionSchema();
    //todo
    //$(document).ready(() => {
    this.componentsInitialize();
    //});
    this.setcorrespondenceflag();
  }

  setcorrespondenceflag = (): void => {
    this.isChangeOfCorrespondenceAddressType =
      this.proposal.endorsementType ===
      Enum.EndorsementType.ChangeOfCorrespondenceAddress;
  };

  updateClientEmail() {
    if (this.proposalService.proposal.customerMappingId! > 0) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId!,
          this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response: ClientEntity) => {
          this.clientData.emailAddress = response["emailAddress"];
        });
    }
  }

  updateClientMobile() {
    if (this.proposalService.proposal.customerMappingId! > 0) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId!,
          this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response: ClientEntity) => {
          this.clientData.mobileNumber = cloneDeep(response["mobileNumber"]);
        });
    }
  }

  updateClientAddress() {
    if (this.proposalService.proposal.customerMappingId! > 0) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId!,
          this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response: ClientEntity) => {
          this.address = cloneDeep(response.address[0]);
        });
    }
  }

  updateAll() {
    if (this.proposalService.proposal.customerMappingId! > 0) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId!,
          this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response: ClientEntity) => {
          this.clientData.emailAddress = cloneDeep(response["emailAddress"]);
          this.clientData.mobileNumber = cloneDeep(response["mobileNumber"]);
          this.address = cloneDeep(response.address[0]);
        });
    }
  }

  emailValidation = (inputId: string): void => {
    const re = /\S+@\S+\.\S+/;
    if (!re.test(this.clientData.emailAddress)) {
      this.showErrorMessage = true;
      this.clientData.emailAddress = "";
      this.errorMsgInHtml =
        '<div class="col-md-12"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
        ' aria-hidden="true"></i> Please enter a valid <b> email address </b></div';
      this.hasEmailError = true;
      this.modal_body.nativeElement.scrollIntoView({ behavior: "smooth" });
    } else {
      this.showErrorMessage = false;
      this.errorMsgInHtml = "";
      // angular.element('#' + inputId).closest('.form-group').removeClass('has-error');//todo
      this.hasEmailError = false;
    }
  };

  addressCountryIfUnknown = (inputId: any) => {
    let displayText: string = "Unknown";
    if (inputId != undefined) {
      if (inputId.length > 0) {
        this.countryList.forEach((i) => {
          if (i.value === inputId.trim()) {
            displayText = i.key;
          }
        });
      }
    }

    return displayText;
  };

  addressDataIfUnknown = (inputId: any): string => {
    if (inputId != undefined) {
      if (inputId.length > 0) {
        return inputId;
      }
      if (Number(inputId)) {
        return inputId;
      }
    }
    return "Unknown";
  };

  phoneValidation = (inputId: string): void => {
    let errorText: string = "";
    const inputValue = this.clientData.mobileNumber.trim();
    var phoneno = /^\+?[0-9]+$/;

    if (!inputValue.match(phoneno)) {
      errorText = this.getPhoneValidationText(inputId);
      this.showErrorMessage = true;
      this.hasMobileNoError = true;
      this.errorMsgInHtml =
        '<div class="col-md-12"><i class="fa fa-exclamation-circle fa-2  chubbRed"' +
        ' aria-hidden="true"></i> Please enter a valid <b> ' +
        errorText +
        "</b></div>";

      this.modal_body.nativeElement.scrollIntoView({ behavior: "smooth" });
    } else {
      // this.checkIfPhoneEntered();
      this.showErrorMessage = false;
      this.hasMobileNoError = false;
      this.errorMsgInHtml = "";
      // angular.element('#' + inputId).closest('.form-group').removeClass('has-error');//todo
    }
  };

  getPhoneValidationText = (inputId: any): string => {
    let errorText: string = "";
    switch (inputId) {
      case "brokerMobileNo":
        errorText = "Mobile Number";
        this.clientData.mobileNumber = "";
        break;

      default:
        break;
    }
    return errorText;
  };

  initializeQuestionSchema = () => {
    this.clientData = new PreferredDeliveryEntity();
    this.clientData.address = [];
    this.address = new ClientAddressEntity();
    this.clientData.address.push(this.address);
  };

  componentsInitialize = () => {
    setTimeout(() => {
      this.masterDataService.getCountryList().subscribe((response) => {
        this.countryList = response;
        if (
          response.filter((x) => x.key == this.address.countryISO2.trim())
            .length > 0
        )
          this.address.countryISO2 = response
            .filter((x) => x.key == this.address.countryISO2)[0]
            .value.trim();
      });
      //todo
      // initializing the google auto completer search
      //this.googleSearchAutoCompleteClientInformation.InitializeAddressAutoCompleteElement();
    }, 500);
  };

  updateAddressValues = (
    place: { address_components: string | any[] },
    componentForm: { [x: string]: string | number },
    divId: any
  ) => {
    this.resetClientAddressForm();
    if (Global.isDefined(place.address_components)) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        //if (componentForm[addressType]) {
        //const val = place.address_components[i][componentForm[addressType]];
        switch (addressType) {
          case "street_number":
            let val1 = place.address_components[i].long_name;
            this.address.addressLine3 = val1;
            this.validateReadOnlyAddress(val1, "addAddress1RO");
            break;
          case "route":
            let val2 = place.address_components[i].long_name;
            this.address.addressLine3 += " " + val2;
            this.validateReadOnlyAddress(val2, "addAddress2RO");
            break;
          case "locality":
            let val3 = place.address_components[i].long_name;
            this.address.city = val3;
            this.validateReadOnlyAddress(val3, "addCityRO");
            break;
          case "postal_code":
            let val4 = place.address_components[i].long_name;
            this.address.postCode = val4;
            this.validateReadOnlyAddress(val4, "addPostcodeRO");
            break;
          case "administrative_area_level_1":
            let val5 = place.address_components[i].short_name;
            this.address.stateName = val5;
            this.validateReadOnlyAddress(val5, "addStateRO");
            break;
          case "country":
            let val6 = place.address_components[i].short_name;
            this.address.countryISO2 = val6.trim();
            this.validateReadOnlyAddress(val6, "addCountryRO");
            this.getIsStateVisible(val6);
            break;
          default:
            break;
        }
        //}
      }

      this.addAddress3RO = false;
      this.addAddress4RO = false;
      // clearing the search input
      //this.clientInformation_autocomplete.nativeElement.value = "";//todo
      this.inputReadOnlyAddress();
    }

    //this.$scope.$apply();//todo
  };

  getIsStateVisible = (value: any) => {
    const elementPos = this.countryExcludeState
      .map(function (x: any) {
        return x;
      })
      .indexOf(value);
    if (elementPos > -1) {
      this.isStateVisible = false;
    } else {
      this.isStateVisible = true;
    }
  };

  validateReadOnlyAddress = (value: string, inputId: any) => {
    if (value !== "") {
      const index = this.readOnlyFields.indexOf(inputId);
      switch (inputId) {
        case "addAddress1RO":
          this.addAddress1RO = false;
          break;
        case "addAddress2RO":
          this.addAddress2RO = false;
          break;
        case "addCityRO":
          this.addCityRO = false;
          break;
        case "addPostcodeRO":
          this.addPostcodeRO = false;
          break;
        case "addStateRO":
          this.addStateRO = false;
          break;
        case "addCountryRO":
          this.addCountryRO = false;
          break;
        default:
          break;
      }
      if (index !== -1) {
        this.readOnlyFields.splice(index, 1);
      }
    }
  };

  inputReadOnlyAddress = () => {
    for (let i = 0; i < this.readOnlyFields.length; i++) {
      const inputFieldId = this.readOnlyFields[i];
      switch (inputFieldId) {
        case "addAddress1RO":
          this.addAddress1RO = false;
          break;
        case "addAddress2RO":
          this.addAddress2RO = false;
          break;
        case "addCityRO":
          this.addCityRO = false;
          break;
        case "addStateRO":
          this.addStateRO = false;
          break;
        case "addPostcodeRO":
          this.addPostcodeRO = false;
          break;
        case "addCountryRO":
          this.addCountryRO = false;
          break;
        default:
          break;
      }
    }
  };

  resetClientAddressForm = () => {
    this.readOnlyFields = [
      "addAddress1RO",
      "addAddress2RO",
      "addCityRO",
      "addPostcodeRO",
      "addStateRO",
    ];
    this.address.buildingName = "";
    this.address.unitNo = "";
    this.address.addressLine1 = "";
    this.address.addressLine2 = "";
    this.address.addressLine3 = "";
    this.address.addressLine4 = "";
    this.address.city = "";
    this.address.postCode = "";
    this.address.stateName = "";

    this.updateNativeElement(this.insuredAddress_lotunit, "");
    this.updateNativeElement(this.insuredAddress_building, "");
    this.updateNativeElement(this.insuredAddress1, "");
    this.updateNativeElement(this.insuredAddress2, "");
    this.updateNativeElement(this.insuredAddress3, "");
    this.updateNativeElement(this.insuredAddress4, "");
    this.updateNativeElement(this.insuredCity, "");
    this.updateNativeElement(this.insuredPostalCode, "");
    this.updateNativeElement(this.insuredState, "");
    //this.clientInformation_autocomplete.nativeElement.value = "";//todo
  };

  updateNativeElement(ele: any, value: string) {
    if (ele !== undefined) {
      ele.nativeElement.value = value;
    }
  }

  noAdressIsFilled() {
    //HE-693: has to trim
    if (
      common.isDefined(this.address.addressLine1) &&
      this.address.addressLine1.trim() === "" &&
      common.isDefined(this.address.addressLine2) &&
      this.address.addressLine2.trim() === "" &&
      common.isDefined(this.address.addressLine3) &&
      this.address.addressLine3.trim() === "" &&
      common.isDefined(this.address.addressLine4) &&
      this.address.addressLine4.trim() === ""
    ) {
      return true;
    } else if (
      common.isUndefinedOrNull(this.address.addressLine1) &&
      common.isUndefinedOrNull(this.address.addressLine2) &&
      common.isUndefinedOrNull(this.address.addressLine3) &&
      common.isUndefinedOrNull(this.address.addressLine4)
    ) {
      return true;
    }
    return false;
  }

  updateDeliveryMethod = () => {
    this.showErrorMessage = false;
    //todo
    //     const deferred = this.$q.defer();
    let allMandatoryField: any[] = [];

    if (this.preferredDeliveryMethod === "5") {
      allMandatoryField = [
        {
          id: "brokerMobileNo",
          label: "Mobile Number",
          qid: this.clientData.mobileNumber,
        },
      ];
    }
    if (this.preferredDeliveryMethod === "2") {
      allMandatoryField = [
        {
          id: "brokerEmail",
          label: "Email",
          qid: this.clientData.emailAddress,
        },
      ];
    }
    if (this.preferredDeliveryMethod === "1" && this.noAdressIsFilled()) {
      allMandatoryField = [
        {
          id: "insuredAddress1",
          label: "Address Line 1",
          qid: this.address.addressLine1,
        },
        // {
        //   id: "clientCountry",
        //   label: "Country",
        //   qid: this.address.countryISO2.trim(),
        // },
      ];
    }

    let errorMessageIndicator: number = 0;
    const htmlErrorMessage: string =
      '<div class="col-md-12"><i class="fa fa-exclamation-circle fa-2 chubbRed" aria-hidden="true">' +
      "</i> <b> @i.label@ </b> is required. <br></div>";

    const clientAddressData: ClientAddressEntity = new ClientAddressEntity();
    clientAddressData.buildingName = this.address.buildingName;
    clientAddressData.unitNo = this.address.unitNo;
    clientAddressData.addressLine1 = this.address.addressLine1;
    clientAddressData.addressLine2 = this.address.addressLine2;
    clientAddressData.addressLine3 = this.address.addressLine3;
    clientAddressData.addressLine4 = this.address.addressLine4;
    clientAddressData.city = this.address.city;
    clientAddressData.postCode = this.address.postCode;
    clientAddressData.stateName = this.address.stateName;

    clientAddressData.countryISO2 = this.address.countryISO2?.trim();
    clientAddressData.contact1 = "";
    clientAddressData.contact2 = "";

    const clientData: PreferredDeliveryEntity = new PreferredDeliveryEntity();
    clientData.address = [];
    clientData.mobileNumber = this.clientData.mobileNumber;
    clientData.emailAddress = this.clientData.emailAddress;
    clientData.address.push(clientAddressData);

    if (allMandatoryField.length > 0) {
      this.errorMsgInHtml = "";
      //         angular.element('#createNewClient').removeClass('has-error');//todo
      for (const i of allMandatoryField) {
        //HE-693: has to trim
        if (common.isUndefinedOrNull(i.qid) || i.qid.trim().length === 0) {
          this.errorMsgInHtml = htmlErrorMessage.replace("@i.label@", i.label);
          //todo
          // angular.element('#' + i.id).closest('.form-group').addClass('has-error');
          errorMessageIndicator++;
          this.showErrorMessage = true;
        } else {
          //todo
          //angular.element('#' + i.id).closest('.form-group').removeClass('has-error');
        }
      }
    }
    if (clientData.mobileNumber.length == 0) {
      this.hasMobileNoError = false;
    }
    if (clientData.emailAddress.length == 0) {
      this.hasEmailError = false;
    }
    if (
      errorMessageIndicator < 1 &&
      clientData.mobileNumber != null &&
      clientData.mobileNumber != ""
    ) {
      this.phoneValidation("brokerMobileNo");
    }
    if (
      errorMessageIndicator < 1 &&
      clientData.emailAddress != null &&
      clientData.emailAddress != ""
    ) {
      this.emailValidation("brokerEmail");
    }
    if (this.preferredDeliveryMethod != "2" && this.hasEmailError) {
      this.hasEmailError = false;
      clientData.emailAddress = "";
    }
    if (this.preferredDeliveryMethod != "5" && this.hasMobileNoError) {
      this.hasMobileNoError = false;
      clientData.mobileNumber = "";
    }
    if (this.preferredDeliveryMethod != "1") {
      if (
        clientAddressData.addressLine1 == null ||
        clientAddressData.addressLine1 == "" ||
        clientAddressData.addressLine1 == " "
      ) {
        if (this.proposalService.proposal.customerMappingId! > 0) {
          this.clientService
            .get(
              this.proposalService.proposal.customerMappingId!,
              this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
            )
            .subscribe((response: ClientEntity) => {
              var address = cloneDeep(response.address[0]);
              clientAddressData.buildingName = address.buildingName;
              clientAddressData.unitNo = address.unitNo;
              clientAddressData.addressLine1 = address.addressLine1;
              clientAddressData.addressLine2 = address.addressLine2;
              clientAddressData.addressLine3 = address.addressLine3;
              clientAddressData.addressLine4 = address.addressLine4;
              clientAddressData.city = address.city;
              clientAddressData.postCode = address.postCode;
              clientAddressData.stateName = address.stateName;

              clientAddressData.countryISO2 = address.countryISO2.trim();
              clientAddressData.contact1 = "";
              clientAddressData.contact2 = "";
              clientData.address.push(clientAddressData);
            });
        }
      }
    }
    if (
      errorMessageIndicator === 0 &&
      !this.hasEmailError &&
      !this.hasMobileNoError
    ) {
      this.showErrorMessage = false;
      this.isProcessing = true;

      let responseClientData: PreferredDeliveryEntity;
      this.notifier.info(
        "Preferred Delivery is updated.",
        this.baseNotifierAppendTo
      );
      this.isProcessing = false;
      dialogActionNotifier.cancel_notifier$.next({
        key: "preferred-delivery-close",
        value: {
          status: "success",
          data: clientData,
          preferredDeliveryMethod: this.preferredDeliveryMethod,
        },
      });
    } else {
      this.modal_body.nativeElement.scrollIntoView({ behavior: "smooth" });
      dialogActionNotifier.cancel_notifier$.next({
        key: "preferred-delivery-close",
        value: { status: "failed" },
      });
    }

    //     return deferred.promise;
  };

  // showClientValidationMessage = (errorType: number) => {
  //     angular.element('#errorMessage').empty();
  //     angular.element('#createNewClient').removeClass('has-error');

  //     switch (errorType) {
  //         case EnumSG.ClientErrorType.MandatoryError:
  //             angular.element('#errorMessage').append('<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2  chubbRed"' +
  //                 ' aria-hidden="true"></i>Please enter all <b>Mandatory fields</b>.</div>');
  //             break;
  //         case EnumSG.ClientErrorType.EmailAddressError:
  //             angular.element('#errorMessage').append('<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
  //                 ' aria-hidden="true"></i> Please enter a valid <b> email address </b></div>');
  //             break;
  //         case EnumSG.ClientErrorType.PhoneNumberError:
  //             angular.element('#errorMessage').append('<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
  //                 ' aria-hidden="true"></i> <b>Phone Number</b> is invalid.</div>');
  //             break;
  //         default: break;
  //     }

  //     this.componentsInitialize();

  // }

  openAddressHelp(event: { preventDefault: () => void }): void {
    event.preventDefault();
    const data = {
      // 'proposalModel': this.proposal.proposalModel,
      showGeoCode: false, // determine to hide/show geocode
    };

    const clientSearchDialogRef = this.cdkDialogService.open(
      MfHkAddressHelpFormComponent,
      {
        data: data,
      }
    );

    clientSearchDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }

  // keyDown = function (evt) {
  //     /* to disbale the input for date input*/
  //     evt.preventDefault();
  // };

  formatDateToString = (date: any) => {
    // const dateSplit = date.split('-');
    // const newDate = dateSplit[1] + '-' + dateSplit[0] + '-' + dateSplit[2];
    const newDate = date;
    // 01, 02, 03, ... 29, 30, 31
    const dd =
      (new Date(newDate).getDate() < 10 ? "0" : "") +
      new Date(newDate).getDate();
    // 01, 02, 03, ... 10, 11, 12
    const MM =
      (new Date(newDate).getMonth() + 1 < 10 ? "0" : "") +
      (new Date(newDate).getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    const yyyy = new Date(newDate).getFullYear();

    // create the format you want
    return MM + "/" + dd + "/" + yyyy;
  };
}
