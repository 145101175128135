import * as MfEnum from "./../../hong-kong/base/mf-hk-enums";

import { ExceptionManager } from "./../../../../../../framework/utils/exception-manager";
import { GUID } from "./../../../../../../framework/domain-entity/guid";
import { Proposal } from "../../../../base/proposal.model";
import { ProposalEntity } from "./../../../../base/proposal-entity.model";
import { QuestionEntity } from "./../../../../base/question-entity.model";
import { IdentityRolesService } from "../../../../../../services/identity-roles.service";

/** Empty dummy class. No actual implementation
 * This class was created just to fullfil multi location structure,
 * but actually mortgage fire Singapore is single location
 */
export class MortgageFireHongKongProposalLocation extends Proposal {
  constructor(
    productId: GUID,
    public override identityRolesService: IdentityRolesService
  ) {
    super(productId, identityRolesService);

    this.initialize();
  }

  initialize() {}

  refreshQuestions(proposalEntity: ProposalEntity) {
    ExceptionManager.argumentNullGuard(proposalEntity, "proposalEntity");
    ExceptionManager.argumentNullGuard(
      proposalEntity.questions,
      "proposalEntity.questions"
    );
  }

  refreshPremiumSummary(proposalEntity: ProposalEntity) {
    ExceptionManager.argumentNullGuard(proposalEntity, "proposalEntity");
  }

  refreshQuestionsReadOnly(proposalEntity: ProposalEntity) {
    // do nothing
  }

  extractQuestionArray(): QuestionEntity[] {
    const questions: QuestionEntity[] = [];
    return questions;
  }
}
