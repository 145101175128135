<form (ngSubmit)="onSubmit()" #heroForm="ngForm">
  <div class="container-fluid">
    <!-- Search filters -->
    <div class="row">
      <div class="col-md-6">
        <!-- Left search section -->
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoQuoteNo">Quote No</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoQuoteNo"
              placeholder="Quote No"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.quoteNo"
              name="quoteNo"
              #name="ngModel"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoPolicyNo">Policy No</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoPolicyNo"
              placeholder="Policy No"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.policyNo"
              name="policyNo"
              #name="ngModel"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoCIN">Mortgagor ID</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoCIN"
              placeholder="Mortgagor ID"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.cin"
              name="cin"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- Right search section -->
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoStartDateFrom"
              >Advance On Date
            </label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoStartDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              (focus)="on_searchStartDateFrom_focus()"
              (valueChange)="on_searchStartDateFrom_change($event)"
              placeholder=""
              [(ngModel)]="model.startDateFrom"
              name="startDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoStartDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoStartDateTo
              id="toDoStartDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              [min]="toDoStartDateTo_min"
              [max]="toDoStartDateTo_max"
              [(ngModel)]="model.startDateTo"
              name="startDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoExpiryDateFrom"
              >INS expiry Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoExpiryDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              (focus)="on_searchExpiryDateFrom_focus()"
              (valueChange)="on_searchExpiryDateFrom_change($event)"
              placeholder=""
              [(ngModel)]="model.expiryDateFrom"
              name="expiryDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoExpiryDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoExpiryDateTo
              id="toDoExpiryDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              [min]="toDoExpiryDateTo_min"
              [max]="toDoExpiryDateTo_max"
              [(ngModel)]="model.expiryDateTo"
              name="expiryDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoInterfaceDateFrom"
              >Interface Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoInterfaceDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              (focus)="on_searchInterfaceDateFrom_focus()"
              (valueChange)="on_searchInterfaceDateFrom_change($event)"
              [(ngModel)]="model.interfaceDateFrom"
              name="interfaceDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoInterfaceDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoInterfaceDateTo
              id="toDoInterfaceDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="model.interfaceDateTo"
              name="interfaceDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoInsuredName"
              >Mortgagor Name</label
            >
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoInsuredName"
              placeholder="Mortgagor Name"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.insuredName"
              name="insuredName"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoMortgageLoanAccountNumber1"
              >Mortgage Loan Account Number</label
            >
          </div>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              id="toDoMortgageLoanAccountNumber1"
              placeholder="Mortgage Loan Account Number"
              ng-keypress="model.on_mortgageLoanAccountNumber1_keyup($event)"
              [(ngModel)]="model.mortgageLoanAccountNumber1"
              name="mortgageLoanAccountNumber1"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="todoAdvancedSearch" class="main-container collapse">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="marginTop5" for="toDoProduct">Product</label>
            </div>
            <div class="col-md-6">
              <kendo-multiselect
                id="toDoProduct"
                placeholder="Select product..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="userProductList"
                textField="pd"
                valueField="pdid"
                [valuePrimitive]="true"
                [(ngModel)]="model.productId"
                name="productId"
              >
              </kendo-multiselect>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="marginTop5" for="toDoEffectiveDateFrom"
                >Effective Date</label
              >
            </div>
            <div class="col-md-4">
              <kendo-datepicker
                id="toDoEffectiveDateFrom"
                class="maxWidth"
                format="dd/MM/yyyy"
                placeholder=""
                (focus)="on_searchEffectiveDateFrom_focus()"
                (valueChange)="on_searchEffectiveDateFrom_change($event)"
                [(ngModel)]="model.effectiveDateFrom"
                name="effectiveDateFrom"
                #name="ngModel"
              ></kendo-datepicker>
            </div>
            <div class="col-md-1 textAlignCenter">
              <label class="marginTop5" for="toDoEffectiveDateTo">to</label>
            </div>
            <div class="col-md-4">
              <kendo-datepicker
                #toDoEffectiveDateTo
                id="toDoEffectiveDateTo"
                class="maxWidth"
                format="dd/MM/yyyy"
                placeholder=""
                [(ngModel)]="model.effectiveDateTo"
                name="effectiveDateTo"
                #name="ngModel"
              ></kendo-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="marginTop5" for="toDoCreatedBy"
                >Last Updated By</label
              >
            </div>
            <div class="col-md-6">
              <kendo-dropdownlist
                id="toDoCreatedBy"
                [defaultItem]="defaultUserListItem"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="userList"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.createdByUserId"
                name="createdByUserId"
              ></kendo-dropdownlist>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="marginTop5" for="toDoInsuredPropertyAddress"
                >Insured Property Address</label
              >
            </div>
            <div class="col-md-9">
              <input
                type="text"
                class="form-control"
                id="toDoInsuredPropertyAddress"
                placeholder="Insured Property Address"
                ng-keypress="model.on_insuredPropertyAddress_keyup($event)"
                [(ngModel)]="model.insuredPropertyAddress"
                name="insuredPropertyAddress"
                #name="ngModel"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-md-2">
              <label class="marginTop5" for="toDoMasterPolicyNo"
                >Master Policy No</label
              >
            </div>
            <div class="col-md-10">
              <kendo-dropdownlist
                id="toDoMasterPolicyNo"
                [defaultItem]="defaultMasterPolicyOptionsItem"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="masterPolicyOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.masterPolicyId"
                name="masterPolicyId"
              ></kendo-dropdownlist>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="marginTop5" for="toDoStatus">Policy status</label>
            </div>
            <div class="col-md-6">
              <kendo-multiselect
                id="toDoStatus"
                placeholder="Select status..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="proposalStatusOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.proposalStatus"
                name="proposalStatus"
              >
              </kendo-multiselect>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="marginTop5" for="toDoMigrated"
                >Migrated/Non-Migrated</label
              >
            </div>
            <div class="col-md-9">
              <kendo-dropdownlist
                id="toDoMigrated"
                [defaultItem]="defaultMigratedItem"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="migratedOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.migrated"
                name="migratedId"
              ></kendo-dropdownlist>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--HTML5 button element. Remember the reason is this button has a default behavior of submit, as stated in the W3 specification-->
  <button
    id="todoAdvancedToggler"
    type="button"
    class="btn btn-primary ld-ext-left chubbDarkBlue_bg chubbDarkBlue_border_btn"
    data-toggle="collapse"
    data-target="#todoAdvancedSearch"
    (click)="toggleCaption()"
  >
    <span>{{ advancedSearchCaption }}</span>
    <i
      class="fa fa-caret-down paddingLeft5 verticalAlignSub"
      aria-hidden="true"
    ></i>
  </button>
  <button
    type="submit"
    class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    [disabled]="!heroForm.form.valid || httpRequesting"
  >
    <i class="fa fa-search ng-scope" aria-hidden="true"></i>
    Search
  </button>
</form>

<br />
<div class="grid-wrapper">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    [(selectedKeys)]="selectedRowIndexes"
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column field="checkboxChecked" title="" [width]="30">
      <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem>
        <input
          type="checkbox"
          [style.display]="dataItem.canApproveIBGForUW ? 'block' : 'none'"
          [checked]="dataItem.checkboxChecked"
          (click)="SetSelectedItem(dataItem, idx)"
        />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="createdDate"
      title="Last Updated Date"
      [width]="130"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="createdByUserName"
      title="Last Updated By"
      [width]="180"
    >
    </kendo-grid-column>
    <kendo-grid-column field="quoteNo" title="Quote No" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="policyNo" title="Policy No" [width]="170">
    </kendo-grid-column>
    <kendo-grid-column field="insuredName" title="Mortgagor Name" [width]="170">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="cin" title="Mortgagor ID" [width]="110">
    </kendo-grid-column> -->
    <kendo-grid-column field="productCode" title="Product" [width]="100">
    </kendo-grid-column>
    <kendo-grid-column
      field="proposalStatusDisplayText"
      title="Policy Status"
      [width]="110"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="proposalTypeDisplayText"
      title="Type"
      [width]="80"
    >
    </kendo-grid-column>
    <kendo-grid-column field="sumInsured" title="Sum Insured" [width]="120">
    </kendo-grid-column
    ><kendo-grid-column field="premium" title="Premium" [width]="110">
    </kendo-grid-column>
    <kendo-grid-column
      field="contactInfo"
      title="Insured Property Address"
      [width]="350"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="mortgageLoanAccountNumber1"
      title="Mortgage Loan Account Number"
      [width]="120"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="policyInceptionDate"
      title="Advance On Date"
      [width]="110"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="policyExpiryDate"
      title="INS expiry Date"
      [width]="120"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="interfaceDate"
      title="Interface Date"
      [width]="110"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="migrated"
      title="Migrated/Non-Migrated"
      [width]="150"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="insuranceCompany"
      title="Insurance Company"
      [width]="150"
    >
    </kendo-grid-column>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="10"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
