<div *ngIf="isreadonly">
  <div *ngIf="isAnswered">
    <div class="col-md-12">
      <div *ngFor="let item of resultList; let i = index">
        <div class="form-group row">
          <div class="col-md-5">
            <label for="item{{ i }}">{{ item.Title }}</label>
          </div>

          <div class="col-md-5">
            <input
              name="item{{ i }}"
              id="item{{ i }}"
              type="text"
              [(ngModel)]="item.AdditionalText"
              value="{{ item.AdditionalText }}"
              class="form-control"
              placeholder="Add more text"
              maxlength="200"
            />
          </div>
          <div class="col-md-2">
            <button
              (click)="removeItem(item)"
              class="k-icon k-font-icon k-i-minus-circle"
            ></button>
          </div>
        </div>

        <hr />
      </div>
    </div>
  </div>

  <div class="col-md-5">
    <div *ngIf="showAddMore">
      <button
        (click)="toggleAddMore()"
        class="k-icon k-font-icon k-i-plus-circle"
      ></button>
      <label>Add more from Master List</label>
    </div>
    <div *ngIf="addMoreExpanded">
      <kendo-combobox
        #comboBox
        [data]="nonDefaultList"
        [textField]="'Title'"
        [valueField]="'ClausesDeductiblesID'"
        placeholder="Type to search"
        (valueChange)="onDropdownchange($event)"
        [(ngModel)]="selectedDeductible"
        [kendoDropDownFilter]="{
          operator: 'contains'
        }"
      >
      </kendo-combobox>

      <button
        (click)="toggleAddMore()"
        class="k-icon k-font-icon k-i-plus-circle"
      ></button>
    </div>
  </div>
</div>

<div *ngIf="!isreadonly">
  <div *ngFor="let item of resultList">
    <div class="form-group row">
      <div class="col-md-5">
        <label>{{ item.Title }}</label>
      </div>
      <div class="col-md-5">
        <label>{{ item.AdditionalText }}</label>
      </div>
    </div>
  </div>
</div>
