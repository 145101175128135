import { Pipe, PipeTransform } from "@angular/core";
import { common } from "../framework/utils/common";

@Pipe({
  name: "newLines",
})
export class NewLinesPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (value != null) {
      if (common.isDefined(value) && value.length > 0)
        return value.replace(/\r\n|\r|\n/gi, "<br />");

      return value;
    }
    return value;
  }
}
