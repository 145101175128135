import { KeyValue } from "./../../../framework/domain-entity/key-value";
import { Proposal } from "./../base/proposal.model";
import { Subject } from "rxjs";

/** To notify proposal action trigger */
class ProposalServiceNotifier {
  /** Notify after proposal loaded */
  loaded_notifier$: Subject<KeyValue<Proposal>> = new Subject<
    KeyValue<Proposal>
  >();

  /** Notify after proposal saved */
  saved_notifier$: Subject<KeyValue<Proposal>> = new Subject<
    KeyValue<Proposal>
  >();

  /** Notify upon editing child proposal */
  edit_child_notifier$: Subject<KeyValue<Proposal>> = new Subject<
    KeyValue<Proposal>
  >();

  /** Notify upon save child proposal */
  save_child_notifier$: Subject<KeyValue<Proposal>> = new Subject<
    KeyValue<Proposal>
  >();

  /** Notify upon proposal command action failed */
  failed_notifier$: Subject<KeyValue<any>> = new Subject<KeyValue<any>>();
}

export const proposalServiceNotifier = new ProposalServiceNotifier();
