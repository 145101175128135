<div class="grid-wrapper">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [kendoGridBinding]="listing"
    (dataStateChange)="onEquipmentListingStateChange($event)"
    (edit)="editEquipmentListingHandler($event)"
    (remove)="removeEquipmentListingHandler($event)"
    (add)="addEquipmentListingHandler()"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    [(selectedKeys)]="selectedRowIndexes"
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <ng-template [ngIf]="!hideActions"
      ><!--need extra ng-template to control the visibility of the kendoGridToolbarTemplate-->
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand>ADD NEW EQUIPMENT</button>
      </ng-template>
    </ng-template>
    <kendo-grid-column
      field="equipmentType"
      title="Type of Equipment"
      [width]="250"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.equipmentType">
          {{ dataItem.equipmentType.key }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="typeOfFinancing"
      title="Serial Number"
      [width]="150"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="equipmentDescription"
      title="Description"
      [width]="250"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="sumInsured"
      title="Sum Insured"
      [width]="140"
      format="c0"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="additionalInfo"
      title="Additional Info"
      [width]="250"
    >
    </kendo-grid-column>
    <kendo-grid-command-column title="Action" [width]="300">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span kendoGridEditCommand [hidden]="hideActions" class="actionButtons">
          EDIT
        </span>
        <span
          kendoGridRemoveCommand
          [hidden]="hideActions"
          class="actionButtons"
        >
          DELETE
        </span>
        <span
          (click)="showDetails(dataItem)"
          class="actionButtons"
          style="vertical-align: middle"
          ><span>ADDT. INFO</span></span
        >
      </ng-template>
    </kendo-grid-command-column>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="10"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <!-- <kendo-pager-page-sizes
      [pageSizes]="pageSizes"
    ></kendo-pager-page-sizes> -->
    </ng-template>
  </kendo-grid>
  <app-mf-sg-equipment-listing-edit-form
    [model]="editEquipmentListingDataItem"
    [isNew]="isNewEquipmentListing"
    [equipmentDropdownOptions]="equipmentDropdownOptions"
    (save)="saveEquipmentListingHandler($event)"
    (cancel)="cancelEquipmentListingHandler()"
  >
  </app-mf-sg-equipment-listing-edit-form>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
