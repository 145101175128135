<div class="tab-content chubbWhite_bg">
  <div class="row" style="box-shadow: none" *ngIf="hasResult">
    <div class="col-md-12">
      <a (click)="clearContent()" title="Clear filtered result">
        <i
          class="fa fa-1x fa-remove fontSize15em chubbRed floatRight paddingTop3 marginRight20 cursorPointer"
        ></i>
      </a>
    </div>
  </div>
  <div>
    <div class="container">
      <div class="row clearfix">
        <div class="col-md-12 padding5">
          <div class="col-md-4 searchTitleWidth floatLeft zindex999">
            <div class="form-group marginBtm0 has-feedback">
              <div class="icon-addon addon-lg has-feedback marginTop3">
                <input
                  type="text"
                  id="userSearchText"
                  style="min-width: 330px"
                  [(ngModel)]="searchText"
                  class="form-control"
                  placeholder="Search..."
                />
                <i
                  class="form-control-feedback fa fa-search chubbGray"
                  style="left: 295px; padding-top: 15px"
                ></i>
              </div>
            </div>
          </div>
          <div class="col-md-8 marginLeft20">
            <button
              class="floatright btn chubbLightBlue chubbWhite_bg chubbLightBlue_border_btn"
              (click)="searchUser()"
            >
              Search
            </button>
          </div>
          <div
            class="col-md-12"
            style="padding: 10px"
            *ngIf="showSearchTextMsg"
          >
            Please Select either OrganisationName or SearchText to Search Users
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row marginLeft15" *ngIf="userListLoaded">
    <div style="margin-left: 20px" class="k-i-loading"></div>
  </div>
  <div class="row marginTop15" STYLE="box-shadow: none">
    <div class="col-md-12" style="padding: 0.5px">
      <div class="">
        <div class="row clearfix" *ngIf="organisationListLoaded">
          <div style="margin-left: 10px" [hidden]="contentLoaded"></div>
        </div>
        <div class="">
          <div
            class="userProfileSearchText1 padding8 cardBackgroundColor"
            *ngIf="showNoResultsMsg"
          >
            <span class="">No matching result for this search criteria</span>
          </div>
          <div
            class="userProfileSearchText1 padding10 chubbOrange_bg chubbWhite"
            *ngIf="userListModel.Items.length"
          >
            Filtered result
            <span class="userProfileSearchText2">{{
              userListModel.Items.length
            }}</span>
          </div>
          <a>
            <div
              class="col-sm-4 sss"
              *ngFor="let item of userListModel.Items"
              (click)="
                getUserProfileDetails(item.UserId, item.OrganisationId, 'view')
              "
            >
              <div
                class="card searchCard style-Agency-primary searchUserProfileCard"
                style="cursor: pointer !important"
              >
                <div
                  class="padding5 chubbGreen_bg searchUserCardHeader"
                  style="min-height: 31px !important"
                >
                  <!--<header class="text-primary-ACE ACEgreen"><i class="fa icon-Icons_01_edit" (click)="getUserProfileDetails(item.UserId,item.OrganisationId, 'edit' )"></i></header>-->
                  <div class="tools floatLeft">
                    <div class="btn-group">
                      <div
                        id="userNameText"
                        class="searchCardTextWrap chubbWhite buttonText marginLeft10 userLogin"
                      >
                        {{ item.UserName + " | " + item.UserLogin }}
                      </div>
                      <!--<span id="" class="tooltiptext"> {{item.UserName + ' | ' + item.UserLogin}}</span>-->
                    </div>
                  </div>
                </div>
                <div
                  class="searchUserCardContent"
                  *ngIf="userListModel.Items.length"
                >
                  <div>
                    <div id="orgName" class="floatLeft clearBoth fontBold">
                      Organisation Name
                    </div>
                    <div
                      id="orgNameText"
                      class="floatLeft clearBoth chubbGreen width90 searchCardTextWrap"
                    >
                      {{ item.OrganisationName }}
                    </div>
                    <!--<div id="NagvigateRight" class="floatRight clearBoth"
                                                           style="height:6px"><i
                                                              class="fa fa-2x icon-Icons_01_arrow_right floatRight"
                                                              style="  margin: -25px 15px;"></i></div>-->
                    <div id="userEmail" class="floatLeft clearBoth fontBold">
                      Email
                    </div>
                    <div
                      id="userEmailText"
                      class="floatLeft clearBoth chubbGreen width90 searchCardTextWrap"
                    >
                      {{ item.Email }}
                    </div>
                  </div>
                </div>
                <!--end .card-body -->
                <div style="height: 20px"></div>
              </div>
              <!--end .card -->
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
