<kendo-dialog
  [title]="h3LabelText"
  class="modal-title-light-orange"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="850"
  [height]="440"
>
  <div class="container-fluid-custom container-overflow-y">
    <div #modal_body row padding5 *ngIf="isProcessing">
      <div class="margin5 textAlignCenter">
        <span class="loadingBarText">Please Wait</span>&nbsp;
        <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
      </div>
    </div>
    <div class="container-fluid" *ngIf="showErrorMessage">
      <div class="row">
        <div class="col-md-12">
          <div id="myModal" class="marginBottom10" role="dialog">
            <div class="">
              <!-- Modal content-->
              <div class="modal-content" style="border-color: #afafaf">
                <div
                  class="modal-header"
                  style="
                    border-bottom-color: #afafaf;
                    padding-top: 5px;
                    padding-bottom: 5px;
                  "
                >
                  <h4 class="modal-title chubbRed">
                    Please complete the following :
                  </h4>
                </div>
                <div class="modal-body paddingTop5">
                  <div class="row">
                    <div
                      style="display: contents"
                      id="errorMessage"
                      [innerHtml]="errorMsgInHtml"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="createNewClient" class="masterPage" *ngIf="!isProcessing">
      <div class="container-fluid">
        <div class="row" *ngIf="isCreate">
          <div class="col-md-12">
            <form role="form">
              <label
                [textContent]="'Please select client type:'"
                class="fontSize12px"
              ></label>
              <div>
                <label>
                  <input
                    name="clientType"
                    type="radio"
                    value="1"
                    [(ngModel)]="changedVal"
                    (click)="selectClientType($event)"
                  />
                  <span class="fontSize12px" style="vertical-align: text-top"
                    >Individual</span
                  >
                </label>
                <label class="marginLeft10">
                  <input
                    name="clientType"
                    type="radio"
                    value="2"
                    [(ngModel)]="changedVal"
                    (click)="selectClientType($event)"
                  />
                  <span class="fontSize12px" style="vertical-align: text-top"
                    >Organization</span
                  >
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="container-fluid"
        id="clientPersonalInformation"
        *ngIf="isPersonal"
      >
        <div class="row">
          <div class="col-md-12 textDecoUnderline">
            <h4>Personal Information</h4>
          </div>
          <div class="col-md-6">
            <form role="form">
              <div class="form-group">
                <div
                  class="form-group"
                  [ngClass]="{ 'st-danger': isEmpty('Salutation') }"
                >
                  <label class="required" [textContent]="'Salutation'"></label>
                  <div>
                    <kendo-dropdownlist
                      [data]="salutationList"
                      textField="text"
                      valueField="value"
                      [kendoDropDownFilter]="{
                        operator: 'contains'
                      }"
                      [valuePrimitive]="true"
                      [(ngModel)]="clientData.salutation"
                      name="salutation"
                    >
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form">
              <form role="form ">
                <div
                  class="form-group"
                  [ngClass]="{ 'st-danger': isCreate && isEmpty('Surname') }"
                >
                  <label
                    [ngClass]="{ required: isCreate }"
                    [textContent]="'Surname'"
                  ></label>
                  <div>
                    <input
                      id="clientSurname"
                      type="text "
                      value=""
                      maxlength="60"
                      class="k-textbox form-control"
                      [(ngModel)]="clientData.lastName"
                      name="lastName"
                    />
                  </div>
                </div>
              </form>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div
                class="form-group"
                [ngClass]="{ 'st-danger': isCreate && isEmpty('Given Name') }"
              >
                <label
                  [ngClass]="{ required: isCreate }"
                  [textContent]="'Given Name'"
                ></label>
                <div>
                  <input
                    id="clientGivenName"
                    type="text "
                    value=""
                    maxlength="60"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.firstName"
                    name="firstName"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-3">
            <form role="form ">
              <div
                class="form-group"
                [ngClass]="{ 'st-danger': isEmpty('Date of Birth') }"
              >
                <label
                  class="required"
                  [textContent]="'Date of Birth (dd/MM/yyyy)'"
                ></label>
                <div>
                  <kendo-datepicker
                    class="maxWidth"
                    format="dd/MM/yyyy"
                    placeholder=""
                    [(ngModel)]="clientData.birthDate"
                    name="birthDate"
                    #name="ngModel"
                  ></kendo-datepicker>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div
                class="form-group"
                [ngClass]="{ 'st-danger': isEmpty('Document Type') }"
              >
                <label
                  class="required"
                  [textContent]="documentTypeLabel"
                ></label>
                <div>
                  <kendo-dropdownlist
                    [data]="docTypeList"
                    textField="text"
                    valueField="value"
                    [kendoDropDownFilter]="{
                      operator: 'contains'
                    }"
                    [valuePrimitive]="true"
                    [(ngModel)]="clientData.docType"
                    (valueChange)="documentTypeChange($event)"
                    name="docType"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <form
              role="form"
              [ngClass]="{
                'st-danger': clientData.docType != '4' && isEmpty('NRIC')
              }"
            >
              <div class="form-group" id="nric">
                <label
                  [ngClass]="{ required: clientData.docType != '4' }"
                  [textContent]="'NRIC'"
                ></label>
                <span class="unattended italic"
                  >(Enter either NRIC or Passport Number)</span
                >
                <div>
                  <input
                    id="clientNRICNo"
                    type="text "
                    value=""
                    maxlength="15"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.nric"
                    (blur)="nricPassportValidation($event, 'clientNRICNo')"
                    name="nric"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form">
              <div
                class="form-group"
                id="passportNo"
                [ngClass]="{
                  'st-danger':
                    clientData.docType == '4' && isEmpty('Passport Number')
                }"
              >
                <label
                  [ngClass]="{ required: clientData.docType == '4' }"
                  [textContent]="'Passport Number'"
                ></label>
                <div>
                  <input
                    id="clientPassportNo"
                    type="text "
                    value=""
                    maxlength="15"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.passportNo"
                    (blur)="nricPassportValidation($event, 'clientPassportNo')"
                    name="passportNo"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group" id="dbsCIN">
                <label class=" " [textContent]="CINText"></label>
                <div>
                  <input
                    type="text"
                    value=""
                    maxlength="15"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.cinNo"
                    name="cinNo"
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'Race'"></label>
                <div>
                  <kendo-dropdownlist
                    [data]="raceList"
                    textField="text"
                    valueField="value"
                    [kendoDropDownFilter]="{
                      operator: 'contains'
                    }"
                    [valuePrimitive]="true"
                    [(ngModel)]="clientData.race"
                    name="race"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-6">
            <form role="form ">
              <div
                class="form-group"
                [ngClass]="{ 'st-danger': isEmpty('Nationality') }"
              >
                <label class="required" [textContent]="'Nationality'"></label>
                <div>
                  <kendo-dropdownlist
                    [data]="nationalityList"
                    id="clientNationality"
                    #clientNationality
                    textField="key"
                    valueField="value"
                    [kendoDropDownFilter]="{
                      operator: 'contains'
                    }"
                    [valuePrimitive]="true"
                    [(ngModel)]="clientData.nationality"
                    name="nationality"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'Gender'"></label>
                <div>
                  <kendo-dropdownlist
                    [data]="genderList"
                    textField="text"
                    valueField="value"
                    [kendoDropDownFilter]="{
                      operator: 'contains'
                    }"
                    [valuePrimitive]="true"
                    [(ngModel)]="clientData.gender"
                    name="gender"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'Marital Status'"></label>
                <div>
                  <kendo-dropdownlist
                    [data]="maritalStatus"
                    textField="text"
                    valueField="value"
                    [kendoDropDownFilter]="{
                      operator: 'contains'
                    }"
                    [valuePrimitive]="true"
                    [(ngModel)]="clientData.maritalStatus"
                    name="maritalStatus"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'Occupation'"></label>
                <div>
                  <input
                    id="clientOccupation"
                    type="text"
                    class="form-control"
                    [(ngModel)]="clientData.occupation"
                    name="occupation"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="container-fluid"
        id="clientOrganizationInformation"
        *ngIf="!isPersonal"
      >
        <div class="row">
          <div class="col-md-12 textDecoUnderline">
            <h4>Organization Information</h4>
          </div>

          <div class="col-md-6">
            <form role="form ">
              <div
                class="form-group"
                [ngClass]="{ 'st-danger': isEmpty('Business Name') }"
              >
                <label class="required" [textContent]="'Business Name'"></label>
                <div>
                  <input
                    id="clientName"
                    maxlength="120"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.clientName"
                    name="clientName"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div
                class="form-group"
                [ngClass]="{
                  'st-danger': isEmpty('Business Registration Number')
                }"
              >
                <label
                  class="required"
                  [textContent]="'Business Registration Number '"
                ></label>
                <div>
                  <input
                    id="clientBRNo"
                    type="text "
                    value=""
                    maxlength="15"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.businessRegistrationNumber"
                    (blur)="businessRegistrationNumberToCIN($event)"
                    name="businessRegistrationNumber"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'Person in Charge'"></label>
                <div>
                  <input
                    id="clientFullName"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.personInCharge"
                    name="personInCharge"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-3">
            <form role="form ">
              <div class="form-group" id="dbsCIN">
                <label class=" " [textContent]="CINText"></label>
                <div>
                  <input
                    type="text"
                    value=""
                    maxlength="15"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.cinNo"
                    name="cinNo"
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-3" *ngIf="!ibgDisabled">
            <form role="form ">
              <div class="form-group">
                <label
                  class="required"
                  [textContent]="'Incorporated Date'"
                ></label>
                <div>
                  <input
                    id="clientIncorporatedDate"
                    class="form-control"
                    [(ngModel)]="clientData.incorporatedDate"
                    name="incorporatedDate"
                  />
                </div>
              </div>
            </form>
          </div>
          <!-- <div class="clearfix ">
        
        
                    </div> -->
        </div>
      </div>
      <div class="container-fluid" *ngIf="isOrganization || isPersonal">
        <hr />
        <div class="row">
          <div class="col-md-12 textDecoUnderline">
            <h4>Contact Information</h4>
          </div>
          <div class="col-md-12">
            <form role="form ">
              <div class="form-group">
                <label
                  class="floatLeft marginRight10"
                  [textContent]="
                    'Street Address Search – Please enter only the street address and suburb, do not include building name or suite/lot/unit/shop number or level                                      '
                  "
                ></label>
                <div
                  style="font-size: 12px"
                  class="cursorPointer floatLeft chubbWhite"
                >
                  <span
                    class="chubbGreen_bg"
                    style="padding: 0px 5px 1px 5px; vertical-align: super"
                    (click)="openAddressHelp($event)"
                    >Help</span
                  >
                </div>
                <div>
                  <div>
                    <input
                      id="clientInformation_autocomplete"
                      ngx-google-places-autocomplete
                      [options]="googlePlacesOptions"
                      #clientInformation_autocomplete="ngx-places"
                      (onAddressChange)="
                        updateAddressValues(
                          $event,
                          {},
                          'clientInformation_autocomplete'
                        )
                      "
                      placeholder="Enter your address "
                      type="text "
                      class="k-textbox maxWidth form-control"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label
                  class=" "
                  [textContent]="'Suite/Lot/Unit Number'"
                ></label>
                <div>
                  <input
                    id="insuredAddress_lotunit"
                    #insuredAddress_lotunit
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="address.unitNo"
                    name="unitNo"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'Building Name'"></label>
                <div>
                  <input
                    id="insuredAddress_building"
                    #insuredAddress_building
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="address.buildingName"
                    name="buildingName"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label
                  class="required"
                  [textContent]="'Address Line 1'"
                ></label>
                <div *ngIf="!addAddress1RO">
                  <input
                    id="insuredAddress1"
                    #insuredAddress1
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    maxlength="100"
                    [(ngModel)]="address.addressLine1"
                    name="addressLine1"
                  />
                </div>
                <div *ngIf="addAddress1RO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.addressLine1)"
                  ></span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label
                  class="required"
                  [textContent]="'Address Line 2'"
                ></label>

                <div *ngIf="!addAddress2RO">
                  <input
                    id="insuredAddress2"
                    #insuredAddress2
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    maxlength="100"
                    [(ngModel)]="address.addressLine2"
                    name="addressLine2"
                  />
                </div>
                <div *ngIf="addAddress2RO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.addressLine2)"
                  ></span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class="required" [textContent]="'Postcode'"></label>

                <div *ngIf="!addPostcodeRO">
                  <input
                    id="insuredPostalCode"
                    #insuredPostalCode
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    maxlength="6"
                    [(ngModel)]="address.postCode"
                    name="postCode"
                  />
                </div>
                <div *ngIf="addPostcodeRO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.postCode)"
                  ></span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class="required" [textContent]="'City/Suburb'"></label>
                <div *ngIf="!addCityRO">
                  <input
                    id="insuredCity"
                    #insuredCity
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="address.city"
                    name="city"
                  />
                </div>
                <div *ngIf="addCityRO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.city)"
                  ></span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'State'"></label>
                <div *ngIf="!addStateRO">
                  <input
                    id="insuredState"
                    #insuredState
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="address.stateName"
                    name="stateName"
                  />
                </div>
                <div *ngIf="addStateRO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.stateName)"
                  ></span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label
                  class="required"
                  [textContent]="'Country'"
                  ng-value=""
                ></label>
                <div *ngIf="!addCountryRO">
                  <!-- <select
                    kendo-drop-down-list
                    id="clientCountry"
                    class="maxWidth"
                    k-option-label="''"
                    k-options="{ autoClose: false }"
                    k-filter="'contains'"
                    k-value-primitive="true"
                    k-data-text-field="'key'"
                    k-data-value-field="'value'"
                    k-ng-model="address.countryISO2"
                    k-data-source="countryList"
                  ></select> -->
                  <kendo-dropdownlist
                    [data]="countryList"
                    id="clientCountry"
                    #clientCountry
                    textField="key"
                    valueField="value"
                    [kendoDropDownFilter]="{
                      operator: 'contains'
                    }"
                    [valuePrimitive]="true"
                    [(ngModel)]="address.countryISO2"
                    name="countryISO2"
                  >
                  </kendo-dropdownlist>
                </div>
                <div *ngIf="addCountryRO">
                  <span
                    class="unattended"
                    [textContent]="addressCountryIfUnknown(address.countryISO2)"
                  ></span>
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-12 floatLeft">
            <form role="form ">
              <div class="form-group">
                <div
                  class="btn-box chubbWhite chubbRed_bg"
                  (click)="resetClientAddressForm()"
                >
                  Reset
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form " id="mobilePhoneForm">
              <div class="form-group">
                <label
                  [ngClass]="{ required: CINText == 'DBS CIN' }"
                  [textContent]="'Mobile Number'"
                ></label>
                <div>
                  <input
                    id="brokerMobileNo"
                    name="brokerMobileNo"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    (blur)="phoneValidation('brokerMobileNo')"
                    [(ngModel)]="clientData.mobileNumber"
                    name="mobileNumber"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form " name="emailForm" novalidate>
              <div class="form-group">
                <label class=" " [textContent]="'Email Address'"></label>
                <div>
                  <input
                    id="brokerEmail"
                    type="text"
                    name="brokerEmail"
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.emailAddress"
                    (blur)="emailValidation($event, 'brokerEmail')"
                    name="emailAddress"
                  />
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-6">
            <form role="form " id="housePhoneForm">
              <div class="form-group">
                <label class=" " [textContent]="'Phone Number (Home)'"></label>
                <div>
                  <input
                    id="brokerPhoneNo"
                    name="brokerPhoneNo"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.phoneNoHome"
                    name="phoneNoHome"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form " id="officePhoneForm">
              <div class="form-group">
                <label
                  class=" "
                  [textContent]="'Phone Number (Office)'"
                ></label>
                <div>
                  <input
                    id="brokerOfficeNo"
                    name="brokerOfficeNo"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.phoneNoOffice"
                    name="phoneNoOffice"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'Fax Number'"></label>
                <div>
                  <input
                    id="brokerFaxNo"
                    name="brokerFaxNo"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.faxNumber"
                    name="faxNumber"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- <div>
                <button ng-click="createClient() ">Save</button>
            </div> -->
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        (click)="createClient()"
        themeColor="primary"
      >
        {{ labelText }}
      </button>
      <button
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel"
        kendoButton
        (click)="close('no')"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
<!-- <script>
    $(document).ready(function () {
        var bodyResize = function () {

            var windowWidth = $(window).width();

            if (windowWidth < 1022) {
                $('#content').css('padding-top', '16px');
                // console.log(windowWidth + '20');
            }
            if (windowWidth > 977) {
                $('#content').css('padding-top', '80px');
            }
        };

        bodyResize();

        $(window).resize(function () {
            bodyResize();
        });

        // Listen for orientation changes
        window.addEventListener("orientationchange ", function () {
            // Announce the new orientation number
            bodyResize();
        }, false);

    });

</script> -->
