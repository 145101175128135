<div
  id="myModalReferral"
  style="margin: 0 10px 10px 10px"
  role="dialog"
  *ngIf="
    pageLoaded && proposal.referralMessageRequires && showBusinessRulesManager
  "
>
  <div class="" *ngIf="proposalService.businessRulesManager.echos.length > 0">
    <!-- Modal content-->
    <div class="modal-content" style="border-color: #f1352b">
      <!--we won't have referral for both WPB and CMB -->
      <!--HE-28-->
      <div class="modal-header" style="border-bottom-color: #f1352b">
        <h4 class="modal-title chubbRed" *ngIf="isReferred">
          Submission is pending Chubb underwriting review due to the following
          reason(s):
        </h4>
        <h4 class="modal-title chubbRed" *ngIf="isIncomplete">
          Submission will be referring to Chubb underwriting review due to the
          following reason(s):
        </h4>
      </div>
      <div class="modal-body">
        <div *ngFor="let es of proposalService?.businessRulesManager?.echos">
          <div>
            <b>
              <u>{{ es.title }}</u>
            </b>

            <ul class="list-group">
              <li
                *ngFor="let e of es.echos"
                class="list-group-item"
                (click)="navigate(e.entity)"
                style="cursor: pointer; padding: 5px"
              >
                <i
                  class="fa fa-exclamation-circle fa-2 chubbRed"
                  aria-hidden="true"
                ></i>
                {{ e.message }}
                <i
                  class="fa fa-pencil-square fa-2 chubbRed marginLeft10"
                  aria-hidden="true"
                ></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
