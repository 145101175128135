import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
//import { environment } from "../environments/environment";
import { environment } from "../../../src/environments/environment"; //use the new one in src folder, old one mainly for guid.ts only
import { LstRateEntity } from "../components/product/lst-rate/lstRateEntity";
import { RmScreenListingEntity } from "../components/product/rm-screen/RmScreenListingEntity";
import { common } from "../framework/utils/common";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class LstRateService extends ApiService {
  private lstRateData!: LstRateEntity[];
  SERVER_URL_LST_RATE_DETAILS: string = "api/LstriCodeDetails/";
  SERVER_URL_LST_RATE_UPDATE: string =
    "api/LstriCodeDetails/BulkUpdateLstriCodeDetails/";
  allPoliciesDownloadEntityList: any;

  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  async getLstRates(): Promise<LstRateEntity[]> {
    this.lstRateData = [];
    const uri = environment.serviceBase + this.SERVER_URL_LST_RATE_DETAILS;

    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/json",
    });

    if (common.isDefined(this.lstRateData) && this.lstRateData.length > 0) {
      //todo
    } else {
      this.lstRateData = [];

      const response = await this.httpClient
        .get<any>(uri, { headers: headers })
        .toPromise();

      this.lstRateData = response;
    }

    return this.lstRateData;
  }

  async updateLstRates(lstRateEntity: LstRateEntity[]) {
    //has to be first item, if json array will not work
    let lstEntities: LstRateEntity[] = [];
    lstRateEntity.forEach((lstRateEntity, index) => {
      let lstEntity = {
        ID: lstRateEntity.ID,
        LSTRICode: lstRateEntity.LSTRICode.Value,
        ReferencePercentage: lstRateEntity.ReferencePercentage.Value,
        RICommissionPercentage: lstRateEntity.RICommissionPercentage.Value,
        EffectiveStartDate:
          lstRateEntity.EffectiveStartDate.Value.toLocaleString(),
        EffectiveEndDate: lstRateEntity.EffectiveEndDate.toLocaleString(),
        SortOrder: lstRateEntity.SortOrder,
        CreatedBY: lstRateEntity.CreatedBY,
        Remarks: lstRateEntity.Remarks,
        IsExternalParty: lstRateEntity.IsExternalParty,
      };
      lstEntities.push(lstEntity);
    });

    const postData = JSON.stringify(lstEntities);

    // return this.httpClient.put<any>(
    //   environment.serviceBase + this.SERVER_URL_LST_RATE_UPDATE,
    //   postData
    // );
    // const headers = new HttpHeaders({
    //   Accept: "application/octet-stream;",
    //   "Content-Type": "application/json",
    // });

    // const response = await this.httpClient
    //   .post<any>(
    //     environment.serviceBase + this.SERVER_URL_LST_RATE_UPDATE,
    //     postData,
    //     { headers: headers }
    //   )
    //   .toPromise();
    // if (response) this.allPoliciesDownloadEntityList = response.d.results;
    // return this.allPoliciesDownloadEntityList;

    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(this.SERVER_URL_LST_RATE_UPDATE),
      postData
    );
  }

  public mapToLstRateEntity(dao: any): LstRateEntity {
    const lstRateEntity = new LstRateEntity(
      dao.ID,
      dao.LSTRICode,
      dao.ReferencePercentage,
      dao.RICommissionPercentage,
      new Date(dao.EffectiveStartDate),
      new Date(dao.EffectiveEndDate),
      dao.SortOrder,
      dao.CreatedBY,
      dao.Remarks,
      dao.IsExternalParty
    );

    return lstRateEntity;
  }
}
