<kendo-dialog
  title="Confirmation"
  class="modal-title-red"
  (close)="close('cancel')"
  [width]="900"
  [minHeight]="340"
  ><div class="modal-body">
    <div>
      <div class="alert alert-warning" *ngIf="warning_message">
        <p style="margin-bottom: 0">Please provide {{ confirmLabel }}</p>
      </div>
    </div>
    <div [textContent]="inputData.message"></div>
    <div class="form-group" style="margin-top: 20px">
      <label class="required" [textContent]="confirmLabel"></label>
      <textarea
        id="confirmationReason"
        class="form-control"
        [(ngModel)]="confirmationReason"
        style="height: 120px"
      ></textarea>
    </div>
    <!-- <div
      dr-file-upload
      multiple
      ownerid="ownerid"
      description="description"
      type="fileType"
      documentids="documentids"
      opts="opts"
      module=""
    ></div> -->
    <div class="chubbGreen" [textContent]="validationMessage"></div>
    <kendo-upload
      name="documentIds"
      [autoUpload]="false"
      [multiple]="true"
      [(ngModel)]="myFiles"
      (complete)="complete()"
      (remove)="remove($event)"
      (success)="success($event)"
      (upload)="upload($event)"
    >
    </kendo-upload>
  </div>
  <div class="modal-footer">
    <kendo-dialog-actions>
      <button
        class="btn chubbLightBlue_bg_btn"
        kendoButton
        (click)="confirmation(1)"
        themeColor="primary"
      >
        OK
      </button>
      <button class="btn chubbRed_bg_btn" kendoButton (click)="close('no')">
        Cancel
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
<!-- 

<div class="modal-header-custom">
    <h3 class="modal-title-custom chubbRed_bg">Confirmation</h3>
</div>
<div class="modal-body">
    <div ng-bind-html="data.message"></div>
  

    <div style="margin-top:20px;">
        <label class="required" data-ng-bind="confirmLabel"></label>
        <textarea id="confirmationReason" class="form-control" ng-model="model.messageBoard.messageContent" style="height:120px;"></textarea>
    </div>
    <div dr-file-upload multiple ownerid="ownerid" description="description" type="fileType"
    documentids="documentids" opts="opts" module="">
</div>
</div>
<div dr-confirmation-button model="model">
</div> -->
