import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ausCurrency",
})
export class AusCurrencyPipe extends CurrencyPipe implements PipeTransform {
  override transform(value: any, decimal?: any, symbol?: any): any {
    /* https://angular.io/api/common/CurrencyPipe

      {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.

      minIntegerDigits: The minimum number of integer digits before the decimal point. Default is 1.
      minFractionDigits: The minimum number of digits after the decimal point. Default is 2.
      maxFractionDigits: The maximum number of digits after the decimal point. Default is 2. If not provided, the number will be formatted with the proper amount of digits, depending on what the ISO 4217 specifies. For example, the Canadian dollar has 2 digits, whereas the Chilean peso has none.
      Optional. Default is undefined.

    */
    let fractionSize = "1.0-2";
    if (decimal != undefined) {
      fractionSize = "1.2-" + decimal; //todo: check if works
    }
    let formatedByCurrencyPipe = super.transform(
      value !== "" ? value : 0,
      symbol,
      "symbol",
      fractionSize
    );
    return formatedByCurrencyPipe;
  }
}
