import { Injectable } from "@angular/core";
//import { promises, resolve } from 'dns';
import { reject } from "lodash";
import { Observable, Subject, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
//import { SharedLogoutMessageComponent } from "./dialog/components/shared-logout-message/shared-logout-message.component";

@Injectable({
  providedIn: "root",
})
export class UserListService {
  dictUserList = {};

  constructor(
    private apiService: ApiService,
    private dialog: DialogWrapperService
  ) {}

  subject = new Subject<any>();
  dictBrokerList = new Map<string, string>();

  fetchBrokerList(userID: number, productID: string): Observable<any> {
    //var deferred = $q.defer();

    /** BrokerList is cached as UserID_productID */
    //TODO:
    // if (this.dictBrokerList[userId+"_"+productId] ?? false  ) {
    //   this.dictBrokerList[userId+"_"+productId];
    // } else {
    var uri = "api/user/getUserMappedOrgnisations";
    //  Api.get(uri,undefined,'')
    //      .then(function (result){
    //          this.dictBrokerList[userId+"_"+productId] = result;
    //          deferred.resolve(result);
    //      },function (error){
    //          deferred.reject(error);
    //      });

    if (
      this.dictBrokerList != undefined &&
      this.dictBrokerList.has(userID + "_" + productID)
    ) {
      const result = this.dictBrokerList.get(userID + "_" + productID);
      this.subject.next(result);
    } else {
      this.apiService.getBrokerList<any>(uri, {}, {}).subscribe((result) => {
        this.dictBrokerList.set(userID + "_" + productID, result);
        this.subject.next(result);
      });
    }
    //TODO: sosola
    // .then(function (result){
    //   this.dictBrokerList[userId+"_"+productId] = result;
    // deferred.resolve(result);
    // },function (error){
    //  // deferred.reject(error);
    // });

    return this.subject;
    //   }
    //  return deferred.promise;
  }

  fetchUserList<T>(organisationID: any, productID: any) {
    var uri = `api/user/getProposalReassignableOrganisationUsers/${organisationID}/${productID}`;

    return this.apiService
      .get(uri, {}, {})
      .pipe(map((response: any) => response.d));
    // .subscribe((result) => {
    //sosola: TODO
    // this.dictUserList[organisationID] = result.d;
    //  deferred.resolve(result);
    // return result.d;
    //  }
  }
  showErrorMessagePopup(error: any) {
    this.dialog.modalLarge(
      "SharedLogoutMessage",
      "",
      error,
      undefined,
      undefined,
      "",
      "",
      undefined,
      "standardModal"
    );
  }

  getUser<IUser>(): Observable<IUser> {
    var uri = "api/user/get";

    return this.apiService.get(uri, {}, {}).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  //  return{
  //      fetchBrokerList : fetchBrokerList,
  //      fetchUserList : fetchUserList
  //  }
}
