// import * as Enums from './../../framework/enum.shared';

// import { Application } from './../../application';
// import { GUID } from './../../framework/domain-entity/guid';
import { MasterDataService } from "./../../../services/master-data.service";
import { ProposalEntity } from "./proposal-entity.model";
// import { Question } from './../../framework/entity.product';
import { QuestionEntity } from "./question-entity.model";
import { common } from "./../../../framework/utils/common";
import { environment } from "./../../../../environments/environment";

export abstract class QuestionSection {
  /** Array that contains references of all instances of QuestionEntity properties in the class,
   * useful for looping through all QuestionEntity to do certain task.
   */
  public questions: QuestionEntity[] = [];

  constructor(
    public tabId: number,
    public masterDataService: MasterDataService
  ) {
    this.initializeQuestionSchema();
    this.refreshQuestionsArray();
  }

  /** Setup Question schema
   * To be invoke in constructor to initializing instances of
   * [QuestionEntity] properties in the class and set up the basic behavior.
   */
  abstract initializeQuestionSchema(): any;

  /** Refresh this.questions[]
   * Method to push all QuestionEntity properties of the class to this.questions[] */
  abstract refreshQuestionsArray(): any;

  /** Method to refresh all instances of QuestionEntity properties in the class
   * by server returned ProposalEntity
   */
  refreshQuestions(proposalEntity: ProposalEntity) {
    this.questions.forEach((model) => {
      const serverEntity = proposalEntity.getQuestion(model.key);

      if (common.isDefined(serverEntity)) {
        this.readEntity(model, serverEntity!);
      }
    });
  }

  /** Method to clear all answers of QuestionEntity properties in the class */
  clearAnswers() {
    this.questions.forEach((question) => {
      question.clearAnswer();
    });
  }

  /** Method to reset all answers of QuestionEntity properties in the class */
  resetToDefaultAnswer() {
    this.questions.forEach((question) => {
      question.resetToDefaultAnswer();
    });
  }

  /** Method to sync value of certain properties with server returned data */
  readEntity(
    model: QuestionEntity,
    serverEntity: QuestionEntity,
    refreshDefaultAnswer: boolean = true
  ) {
    /** Sync server data
     * Do not sync everything from server,
     * probably sync only it's [answer] and [displayText].
     * Question schema should now defined by front end.
     * Data should always followed back-end,
     * but the behavior of the questions will be defined by front-end
     */
    // text to display
    model.displayText = serverEntity.displayText;

    if (
      common.isDefined(serverEntity.minValue) &&
      common.isNumber(serverEntity.minValue)
    ) {
      model.minValue = serverEntity.minValue;
    }

    if (
      common.isDefined(serverEntity.maxValue) &&
      common.isNumber(serverEntity.maxValue)
    ) {
      model.maxValue = serverEntity.maxValue;
    }

    if (
      common.isDefined(serverEntity.maxLength) &&
      common.isNumber(serverEntity.maxLength)
    ) {
      model.maxLength = serverEntity.maxLength;
    }

    if (
      common.isDefined(serverEntity.options) &&
      serverEntity.options.length > 0
    ) {
      // temporarily remove the empty option value
      serverEntity.options.splice(0, 1);
      model.options = serverEntity.options;
    }

    // answer value
    model.answer = serverEntity.answer;
    if (refreshDefaultAnswer) {
      model.defaultAnswer = serverEntity.defaultAnswer;
      model.answerOriginalVersion = serverEntity.answerOriginalVersion;
    }

    if (!environment.production) {
      // expected in sync with server entity checking
      if (model.questionPanelId !== serverEntity.questionPanelId) {
        const warn = `${model.key} questionPanelId not in sync with server:_
                 model[${model.questionPanelId}] server[${serverEntity.questionPanelId}]`;
        // console.log(warn);
      }
    }
  }
}
