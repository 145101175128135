<div class="wrapper" id="admin">
  <kendo-tabstrip
    (tabSelect)="onTabSelect($event)"
    #tabstrip
    style="padding-top: 30px"
  >
    <kendo-tabstrip-tab title="Search" [selected]="true">
      <ng-template kendoTabTitle>
        <i
          class="fa fa-1x fa-search paddingTop3 tabNav"
          style="margin-right: 7px"
        ></i>
        Search
      </ng-template>
      <ng-template kendoTabContent>
        <div class="content">
          <app-search-user></app-search-user>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Add User">
      <ng-template kendoTabTitle>
        <i
          class="fa fa-1x fa-plus paddingTop3 tabNav"
          style="margin-right: 7px"
        ></i>
        Add User
      </ng-template>
      <ng-template kendoTabContent>
        <div class="content">
          <app-create-user
            (changeTab)="selectFirstTab($event)"
          ></app-create-user>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
