import { Component, OnInit } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";

@Component({
  selector: "app-user-success",
  templateUrl: "./user-success.component.html",
  styleUrls: ["./user-success.component.scss"],
})
export class UserSuccessComponent implements OnInit {
  constructor(public dialog: DialogRef) {}
  ngOnInit(): void {}
}
