import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { MessageService } from "@progress/kendo-angular-l10n";
import {
  DrawerComponent,
  DrawerMode,
  DrawerSelectEvent,
} from "@progress/kendo-angular-layout";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
//import { Adb2cLoginComponent } from "./core/components/login/adb2c-login/adb2c-login.component";
import { SessionStorageService } from "./services/storages/session-storage.service";
import { ROUTE_HOME } from "./app.module";
import { PolicyCustomerLoginService } from "./services/policy-customer-login.service";
import { IdentityRolesService } from "./services/identity-roles.service";
import { Identity } from "./shared/user/identity";
import { filter } from "rxjs/operators";
import { ROUTE_USER_ROLE_SELECTION } from "./app-routing.module";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  public selected = "Team";
  public items: Array<any> = [];
  public mode: DrawerMode = "push";
  public mini = true;
  isCustomerLogin: boolean = false;
  isUserroleselection: boolean = false;

  constructor(
    private router: Router,
    public msgService: MessageService,
    private msalService: MsalService,
    private activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    public policyCustomerLoginService: PolicyCustomerLoginService,
    private identityRolesService: IdentityRolesService,
    private identity: Identity
  ) {}

  ngOnInit() {
    // // Update Drawer selected state when change router path
    // this.router.events.subscribe((route: any) => {
    //     if (route instanceof NavigationStart) {
    //         this.items = this.drawerItems().map((item) => {
    //             if (item.path && item.path === route.url) {
    //                 item.selected = true;
    //                 return item;
    //             } else {
    //                 item.selected = false;
    //                 return item;
    //             }
    //         });
    //     }
    // });

    // this.setDrawerConfig();

    // this.customMsgService.localeChange.subscribe(() => {
    //     this.items = this.drawerItems();
    // });

    // window.addEventListener('resize', () => {
    //     this.setDrawerConfig();
    // });
    this.showHideHeader();

    const path = window.location.pathname.toLocaleLowerCase();
    const lastSegment = path.slice(path.lastIndexOf("/"), path.length); //some env is sub-folder
    switch (lastSegment) {
      case "/customerlogin":
        // allowing public facing customer login module
        this.sessionStorageService.clear(); //clear sessionStorage
        this.identityRolesService.clearAuthData();
        this.router.navigate(["/customerlogin"]);
        this.policyCustomerLoginService.isCustomerLogin = true;
        this.policyCustomerLoginService.isCustomerOTP = false;
        this.policyCustomerLoginService.isCustomerOffer = false;
        break;
      case "/" + ROUTE_USER_ROLE_SELECTION:
        this.isUserroleselection = true;
        this.router.navigate(["/" + ROUTE_USER_ROLE_SELECTION]);
        break;
      default: //reset isUserroleselection, after re-login
        this.isUserroleselection = false;
        this.msalService.instance.handleRedirectPromise().then((res) => {
          if (res != null && res.account != null) {
            this.msalService.instance.setActiveAccount(res.account);
            this.router.navigate(["/adb2csso"]);
          } else {
            let authData = this.sessionStorageService.get("authorizationData");
            if (authData == null) {
              this.msalService.instance.loginRedirect();
            } else {
              this.router.navigate(["/" + ROUTE_HOME]);
            }
          }
        });
        break;
    }
  }

  ngOnDestroy() {
    //window.removeEventListener('resize', () => {});
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  showHeader(): boolean {
    return this.isLoggedIn() && !this.isUserroleselection;
  }

  showHorizMenu(): boolean {
    return this.isLoggedIn(); // &&
    //!this.isUserroleselection
  }

  showHideHeader() {
    //if there's any route change event by any sibling's children
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let url = event.url;
        if (url.toLocaleLowerCase() === "/" + ROUTE_USER_ROLE_SELECTION) {
          //hide menu if proposalpage
          this.isUserroleselection = true;
        } else {
          this.isUserroleselection = false;
        }
      });
  }
  // public setDrawerConfig() {
  //     const pageWidth = window.innerWidth;
  //     if (pageWidth <= 770) {
  //         this.mode = 'overlay';
  //         this.mini = false;
  //     } else {
  //         this.mode = 'push';
  //         this.mini = true;
  //     }
  // }

  // public drawerItems() {
  //     return [
  //         { text: this.customMsgService.translate('team'), icon: 'k-i-grid', path: '/', selected: true },
  //         { text: this.customMsgService.translate('dashboard'), icon: 'k-i-chart-line-markers', path: '/dashboard', selected: false },
  //         { text: this.customMsgService.translate('planning'), icon: 'k-i-calendar', path: '/planning', selected: false },
  //         { text: this.customMsgService.translate('profile'), icon: 'k-i-user', path: '/profile', selected: false },
  //         { separator: true },
  //         { text: this.customMsgService.translate('info'), icon: 'k-i-information', path: '/info', selected: false }
  //     ];
  // }

  // public toggleDrawer(drawer: DrawerComponent): void {
  //     drawer.toggle();
  // }

  // public onSelect(ev: DrawerSelectEvent): void {
  //     this.router.navigate([ev.item.path]);
  //     this.selected = ev.item.text;
  // }
}
