<kendo-dialog *ngIf="opened" [width]="900">
  <div class="modal-header-custom">
    <h3 class="modal-title-custom chubbLightOrange_bg">
      Update Invoice Number
    </h3>
  </div>
  <div class="container-fluid-custom">
    <div class="modal-body">
      <div class="ml-2">
        <h4 class="modal-title chubbRed" *ngIf="updateSuccess">
          Invoice No updated.
        </h4>
        <h4 class="modal-title chubbRed" *ngIf="isBatchIdError">
          Please enter a valid batch id.
        </h4>
        <h4 class="modal-title chubbRed" *ngIf="isInvoiceNoError">
          Please enter a valid invoice no.
        </h4>
      </div>
      <br />
      <div class="ml-2">
        <div class="row">
          <div class="col-md-6">
            <!-- Left search section -->
            <div class="form-group row">
              <div class="col-md-4">
                <label class="marginTop5" for="">Batch Id</label>
              </div>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  id="oiBatchId"
                  placeholder="Batch Id"
                  name="batchId"
                  #name="ngModel"
                  [(ngModel)]="batchId"
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Left search section -->
            <div class="row">
              <button
                (click)="onPolicySearch()"
                class="btn btn-primary ld-ext-left chubbLightBlue_bg_btn"
                event="1"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <br />
        <div class="row" *ngIf="enableUpdate">
          <div class="col-md-6">
            <!-- Left search section -->
            <div class="form-group row">
              <div class="col-md-4">
                <label class="marginTop5" for="">Invoice No</label>
              </div>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  id="oiInvoiceNo"
                  placeholder="Invoice No"
                  name="invoiceNo"
                  #name="ngModel"
                  [(ngModel)]="invoiceNo"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Left search section -->
            <div class="row">
              <button
                (click)="updateInvoiceNo()"
                class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
                event="1"
              >
                Update Invoice No
              </button>
            </div>
          </div>
        </div>
      </div>

      <kendo-grid
        style="cursor: pointer"
        [data]="gridView"
        [pageable]="false"
        [resizable]="true"
        mode="multiple"
        *ngIf="enableGrid"
      >
        <kendo-grid-column field="policyNo" title="Policy No" [width]="140">
        </kendo-grid-column>
        <kendo-grid-column field="batchId" title="Batch Id" [width]="90">
        </kendo-grid-column>
        <kendo-grid-column
          field="statusDisplayText"
          title="Status"
          [width]="180"
        >
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>

  <div class="modal-footer">
    <kendo-dialog-actions>
      <button
        kendoButton
        (click)="close('return')"
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel ng-binding"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
