<div class="wrapper">
  <kendo-tabstrip (tabSelect)="onTabSelect($event)" style="padding-top: 30px">
    <kendo-tabstrip-tab title="RM Details" [selected]="true">
      <ng-template kendoTabContent>
        <div class="grid-wrapper">
          <kendo-grid
            style="cursor: pointer"
            [data]="gridView"
            [pageSize]="pageSize"
            [skip]="skip"
            [pageable]="{
              info: true,
              pageSizes: true
            }"
            [kendoGridBinding]="listing"
            filterable="menu"
            (dataStateChange)="onRmStateChange($event)"
            (edit)="editRmHandler($event)"
            (remove)="removeRmHandler($event)"
            (add)="addRmHandler()"
            [height]="400"
            [resizable]="true"
            (pageChange)="pageChange($event)"
            [selectable]="true"
            mode="multiple"
            kendoGridSelectBy
            [(selectedKeys)]="selectedRowIndexes"
            (selectionChange)="gridSelectionChange($event)"
            (cellClick)="cellClickHandler($event)"
          >
            <ng-template [ngIf]="!hideActions"
              ><!--need extra ng-template to control the visibility of the kendoGridToolbarTemplate-->
              <ng-template kendoGridToolbarTemplate>
                <button kendoGridAddCommand>ADD</button>
              </ng-template>
            </ng-template>
            <kendo-grid-column field="rMName" title="RM Name" [width]="100">
              <ng-template
                kendoGridFilterMenuTemplate
                let-filter
                let-column="column"
                let-filterService="filterService"
              >
                <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false"
                >
                  <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="teamName" title="Team Name" [width]="150">
              <ng-template
                kendoGridFilterMenuTemplate
                let-filter
                let-column="column"
                let-filterService="filterService"
              >
                <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false"
                >
                  <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="pcCode" title="PC Code" [width]="140">
              <ng-template
                kendoGridFilterMenuTemplate
                let-filter
                let-column="column"
                let-filterService="filterService"
              >
                <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false"
                >
                  <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="officeNumber"
              title="Office Number"
              [width]="100"
            >
              <ng-template
                kendoGridFilterMenuTemplate
                let-filter
                let-column="column"
                let-filterService="filterService"
              >
                <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false"
                >
                  <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column
              title="Action"
              [hidden]="hideActions"
              [width]="300"
            >
              <ng-template kendoGridCellTemplate>
                <button kendoGridEditCommand>Edit</button>
                <button kendoGridRemoveCommand>Delete</button>
              </ng-template>
            </kendo-grid-command-column>
            <ng-template
              kendoPagerTemplate
              let-totalPages="totalPages"
              let-currentPage="currentPage"
            >
              <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
              <kendo-pager-numeric-buttons
                [buttonCount]="10"
              ></kendo-pager-numeric-buttons>
              <kendo-pager-info></kendo-pager-info>
              <kendo-pager-next-buttons></kendo-pager-next-buttons>
              <!-- <kendo-pager-page-sizes
              [pageSizes]="pageSizes"
            ></kendo-pager-page-sizes> -->
            </ng-template>
          </kendo-grid>
          <app-rm-grid-edit-form
            [model]="editRmDataItem"
            [isNew]="isNewRm"
            (save)="saveRmHandler($event)"
            (cancel)="cancelRmHandler()"
          >
          </app-rm-grid-edit-form>
          <div *ngIf="loading" class="k-i-loading"></div>
        </div>
        <div #appendTo class="append-container"></div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
