import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import { DIALOG_DATA } from "src/app/components/shared/partials/dialog/dialog-tokens";
import { clientViewDlgModelArgs } from "src/app/components/shared/partials/mdClient/client-view/client-view.component";
import {
  MessageBoard,
  MessageBoardModel,
} from "../message-forum/MessageBoardModel";
import * as Enum from "src/app/framework/enum.shared";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MessageBoardService } from "src/app/services/message-board.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { FileUploadService } from "src/app/services/file-upload.service";

@Component({
  selector: "app-underwriter-internal-comment",
  templateUrl: "./underwriter-internal-comment.component.html",
  styleUrls: ["./underwriter-internal-comment.component.scss"],
})
export class UnderwriterInternalCommentComponent implements OnInit {
  model: MessageBoardModel = new MessageBoardModel();
  errorMessage = "";

  underWriterList = [];

  ownerid: any;
  description = "Underwriter Internal Comment File";
  fileType = Enum.fileType.MessageBoard;

  documentids = [];
  folderTypes = [];

  module = "";
  data!: any;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any,
    public identityRolesService: IdentityRolesService,
    private messageBoardService: MessageBoardService,
    private cdkDialogService: CdkDialogService,
    private fileUploadService: FileUploadService
  ) {
    if (this.inputData) {
      this.ownerid = this.inputData.proposalId;

      this.model.messageBoard.push(new MessageBoard());
      this.model.messageBoard[0].messageTitle = "";
      this.model.messageBoard[0].messageContent = "";
      this.model.messageBoard[0].productId = this.inputData.productId;
      this.model.messageBoard[0].proposalId = this.inputData.proposalId;
      this.model.messageBoard[0].sendEmail = false;
      this.model.selectedUnderwriter = null;
      this.model.messageBoard[0].fileIds = this.inputData.documentids;
      this.model.statusText = "";
      this.model.messageBoard[0].folderTypeId = "";

      this.data = {
        proposalId: inputData.proposalId,
        fileIds: inputData.documentids,
      };

      //this api no longer exists, hence render whole module useless
      // this.messageBoardService.getMessageBoardCategory((resp) => {
      //   console.log(resp);
      //   //this.folderTypes = resp;
      // });
    }
  }

  ngOnInit(): void {}

  addMessageSendEmail() {
    // /*Caution: Changing this value to false will cause messages that are supposed
    //            to be viewed by Underwriters only, will be exposed to everyone.
    //  */
    // data.isUnderwriterInternalComment = true;
    // data.fileIds = $scope.documentids;
    // if($scope.model.messageBoard.messageTitle === ''
    //  || $scope.model.messageBoard.messageContent === ''
    //  || $scope.model.selectedUnderwriter === null)
    //      validateMandatoryFields();
    // else
    // {
    //     var serverModel = UiManager.buildServerModel($scope.model.messageBoard);
    //     serverModel.fileIds = $scope.documentids;
    //     serverModel.isUnderwriterInternalComment = data.isUnderwriterInternalComment;
    //     serverModel.underWriterUserId = $scope.model.selectedUnderwriter.value;
    //     MessageBoard.saveEmail(serverModel)
    //             .then(function (model) {
    //                 getMessage(data.proposalId);
    //             },
    //             function (result) {
    //                 $scope.errorMessage = result.statusText;
    //             }
    //         );
    //  }
  }

  submit() {
    // var serverModel = UiManager.buildServerModel($scope.model.messageBoard);
    // serverModel.fileIds = $scope.model.messageBoard.fileIds;
    // serverModel.isUnderwriterInternalComment = true;
    // if (angular.isUndefined($scope.model.messageBoard.messageTitle))
    //     $scope.model.messageBoard.messageTitle = "";
    // if (angular.isUndefined($scope.model.messageBoard.messageContent))
    //     $scope.model.messageBoard.messageContent = "";
    // if ($scope.model.messageBoard.messageTitle != "" && $scope.model.messageBoard.messageContent != "") {
    //     MessageBoard.saveMessage(serverModel)
    //         .then(function (model) {
    //             var messageId = model.data;
    //             getMessage(data.proposalId);
    //         },
    //         function (result) {
    //             $scope.hasFormError = true;
    //             $scope.formErrors = result.statusText;
    //         }
    //     );
    // }
    // else {
    //     validateMandatoryFields();
    // }
  }

  chckBoxOnChange() {
    // $scope.underWriterList = [];
    // $scope.model.messageBoard.selectedUnderwriter = [];
    // if($scope.model.messageBoard.sendEmail)
    // {
    //     var postBackData = {
    //         ProductID : $scope.model.messageBoard.productId
    //     };
    //     getUnderwriterList(postBackData).then(
    //     function(response){
    //         var list = [];
    //         list.push({text: "All underwriter" ,value: -1 });
    //         angular.forEach(response.data,function(data){
    //         list.push({text : data.UserLogin, value : data.UserId})
    //         });
    //         $scope.underWriterList = list;
    //     });
    // }
  }

  getMessage(proposalId: any) {
    this.messageBoardService
      .getInternalUnderwriterComment(proposalId)
      .subscribe((response: any) => {
        if (response) {
          setTimeout(() => {
            if (response.data != null && response.data.length > 0) {
              this.model.messageBoard = response.data;
              this.close("yes", this.model.messageBoard);
            } else {
              this.close("yes", this.model.messageBoard);
            }
          }, 100);
        }
      });
  }

  validateMandatoryFields = function () {
    // var validateData = [];
    //   var validationMessage = ' ';
    //   if ($scope.model.messageBoard.messageTitle == "" && $scope.model.messageBoard.messageContent == "")
    //       validationMessage = '<b>Please enter Title and Content</b>';
    //   else if ($scope.model.messageBoard.messageTitle == "")
    //       validationMessage = '<b>Please enter Title</b>';
    //   else if ($scope.model.messageBoard.messageContent == "")
    //       validationMessage = '<b>Please enter Content</b>';
    //   if($scope.model.messageBoard.sendEmail
    //   && $scope.model.selectedUnderwriter === null )
    //   {
    //       if(validationMessage === ' ')
    //           validationMessage = '<b>Please select an underwriter</b>';
    //       else
    //           validationMessage += ' <b>and select an underwriter<b>';
    //   }
    //   validateData.push({text: validationMessage});
    //   Dialog.modalLarge(
    //       'products/sme/masterPolicy/partial/mdValidationMesage/validateMessage.html',
    //       'ValidateMessageController',
    //   validateData)
  };

  getUnderwriterList(postBackData: any) {
    // var deferred = $q.defer();
    // var uri = 'api/proposal/getUnderwriterList';
    // Api.get(uri, {}, postBackData)
    //     .then(function (response) {
    //         deferred.resolve(response);
    //     }, function (rejection) {
    //         deferred.reject(rejection);
    //     });
    // return deferred.promise;
  }

  public close(status: any, messageBoard: any) {
    let savParamters: clientViewDlgModelArgs = {
      status: status,
      data: messageBoard,
    };
    this.dialogRef?.close(savParamters);
  }

  public getMessageAndClose(status: any) {
    if (status !== "yes") {
      this.getMessage(this.model.messageBoard[0].proposalId);
    }
  }
}
