import {
  DocumentType,
  EndorsementType,
  ManualPaymentType,
  ProposalStatus,
  RefundPaymentType,
  TransType,
} from "./../../../../framework/enum.shared";

import { OpenItemStatus } from "./../../../../finance/finance-enums";
import { common } from "src/app/framework/utils/common";

class ProposalCommon {
  translateProposalStatus(
    proposalStatus: ProposalStatus,
    transType: TransType
  ): string {
    switch (proposalStatus) {
      case ProposalStatus.None:
        return "Draft";
      case ProposalStatus.Approved:
        return "Approved By Underwriter";
      case ProposalStatus.Accepted:
        if (transType === TransType.CAN) {
          return "Cancelled";
        } else {
          return "In Force";
        }
      case ProposalStatus.Referred:
        return "Referred To Underwriter";
      case ProposalStatus.Declined:
        return "Declined By Underwriter";
      case ProposalStatus.Replaced:
        return "Deleted";
      case ProposalStatus.Incomplete:
        return "Draft";
      case ProposalStatus.QuickQuoteApprovedOnly:
        return "Quick Quote Approved Only";
      case ProposalStatus.ReferredToBroker:
        return "Referred To Broker";
      case ProposalStatus.Lapsed:
        return "Lapsed";
      case ProposalStatus.Endorsed:
        return "Endorsed";
      case ProposalStatus.Renewed:
        return "Renewed";
      case ProposalStatus.Cancelled:
        return "Cancelled";
      case ProposalStatus.Unsuccessful:
        return "Unsuccessful";
      case ProposalStatus.Submitted:
        return "Submitted";
      case ProposalStatus.PendingUnderwriter:
        return "Pending Underwriter";
      case ProposalStatus.PendingBroker:
        return "Pending Broker";
      case ProposalStatus.Complete:
        return "Complete";
      case ProposalStatus.PendingChecker:
        return "Pending Checker";
      default:
        throw Error("Not handled");
    }
  }
  translateDocumentType(docType: any): string {
    let type!: any;

    type = common.isObject(docType) ? parseInt(docType.key) : docType;
    switch (type) {
      case DocumentType.Undefined:
        return "Undefined";
      case DocumentType.DebitNote:
        return "Debit Note";
      case DocumentType.CreditNote:
        return "Credit Note";
      case DocumentType.CreditAdvice:
        return "Credit Advice";
      case DocumentType.DebitAdvice:
        return "Debit Advice";
      case DocumentType.TaxInvoice:
        return "Tax Invoice";
      case DocumentType.Quotation:
        return "Quotation";
      case DocumentType.PolicySchedule:
        return "Policy Schedule";
      case DocumentType.Endorsement:
        return "Endorsement";

      case DocumentType.Cancellation:
        return "Cancellation";
      case DocumentType.Renewal:
        return "Renewal";
      case DocumentType.RenewalNotice:
        return "Renewal Notice";
      case DocumentType.PolicyWording:
        return "Policy Wording";
      case DocumentType.CreditAdvice_AgentCommission:
        return "Credit Advice Agent Commission";
      case DocumentType.PaymentReceipt:
        return "Payment Receipt";
      case DocumentType.CoverNote_Draft:
        return "Cover Note Draft";
      case DocumentType.CoverNote:
        return "Cover Note";

      case DocumentType.CertificateOfCurrency:
        return "Certificate Of Currency";
      case DocumentType.PolicyScheduleAsAgreed:
        return "Policy Schedule As Agreed";
      case DocumentType.QuotationAsAgreed:
        return "Quotation As Agreed";
      case DocumentType.CancellationAsAgreed:
        return "Cancellation As Agreed";
      case DocumentType.CertificateOfCurrencyAsAgreed:
        return "Certificate Of Currency As Agreed";
      case DocumentType.ProposalForm:
        return "Proposal Form";
      case DocumentType.BinderLetter:
        return "Binder Letter";
      case DocumentType.Quote:
        return "Quote";

      case DocumentType.EndorsementAsAgreed:
        return "Endorsement As Agreed";
      case DocumentType.RenewalQuote:
        return "Renewal Quote";
      case DocumentType.QuickIndication:
        return "Quick Indication";
      case DocumentType.CancellationBinder:
        return "Cancellation Binder";
      case DocumentType.MultiQuote:
        return "Multi Quote";
      case DocumentType.RedemptionLetter:
        return "Redemption Letter";
      case DocumentType.ChangeInBuildingSumInsured:
        return "Endorsement - Change In Building Sum Insured";
      case DocumentType.ChangeInCorrespondanceAddress:
        return "Endorsement - Change In Correspondence Address";
      case DocumentType.ChangeOfAdditionalInsuredName:
        return "Endorsement - Change In Additional Insured name";
      case DocumentType.ChangeInPOI:
        return "Endorsement - Change in Policy Period";
      case DocumentType.ChangeOfClientInfo:
        return "Endorsement - Change In Client Information";
      case DocumentType.CancellationAdjustment:
        return "Cancellation Adjustment";
      case DocumentType.GstAdjustment:
        return "Gst Adjustment";
      case DocumentType.ChangeOfPlanType:
        return "Endorsement - Change of Plan Type";
      case DocumentType.RenewalMultiYear:
        return "Renewal";
      case DocumentType.LoanRedeemed:
        return "Endorsement - Loan Redemption";
      default:
        throw Error("Not handled");
    }
  }
  translateEndorsementType(endType: EndorsementType): string {
    switch (endType) {
      case EndorsementType.GeneralEndorsement:
        return "General Endorsement";
      case EndorsementType.LoanRedemption:
        return "Loan Redemption";
      case EndorsementType.ChangeOfPOI:
        return "Modify Policy Period";
      case EndorsementType.ChangeOfCorrespondenceAddress:
        return "Change of Correspondence Address";
      case EndorsementType.ChangeOfSumInsured:
        return "Modify Sum Insured";
      case EndorsementType.ChangeOfAdditionalInsured:
        return "Modify Additional Insured";
      case EndorsementType.ChangeOfClientInfo:
        return "Change Of Client Information";
      case EndorsementType.GstAdjustment:
        return "Gst Adjustment";
      case EndorsementType.ChangeOfPlanType:
        return "Change of Plan Type";
      case EndorsementType.Undefined:
        return " - ";
      default:
        throw Error("Not handled");
    }
  }

  translateTransType(transType: TransType, endType: EndorsementType): string {
    let transTypeText: string = "";
    switch (transType) {
      case TransType.NONE:
        transTypeText = "None";
        break;
      case TransType.NEW:
        transTypeText = "New Business";
        break;
      case TransType.END:
        transTypeText = "Endorsement";
        break;
      case TransType.CAN:
        transTypeText = "Cancellation";
        break;
      case TransType.REN:
        transTypeText = "Renewal";
        break;
      default:
        throw Error("Not handled");
    }
    if (transType === TransType.END) {
      if (endType !== null) {
        const endorsementText: string = this.translateEndorsementType(endType);
        if (endorsementText.length > 0) {
          return (transTypeText = transTypeText + " - " + endorsementText);
        }
      }
    }
    return transTypeText;
  }

  translateOpenItemStatus(openItemStatus: OpenItemStatus): string {
    let text: string = "";
    switch (openItemStatus) {
      case OpenItemStatus.Undefined:
        text = "N/A";
        break;
      case OpenItemStatus.PendingAutoDebit:
        text = "Pending Auto Debit";
        break;
      case OpenItemStatus.PendingInvoiceToBank:
        text = "Pending Invoice To Bank";
        break;
      case OpenItemStatus.PendingToInvoiceToIndividual:
        text = "Pending Invoice To Individual";
        break;
      case OpenItemStatus.PendingRefundToBank:
        text = "Pending Refund To Bank";
        break;
      case OpenItemStatus.PendingRefundToBankInReview:
        text = "Pending Refund To Bank In Review";
        break;
      case OpenItemStatus.AutoDebited:
        text = "Auto Debited";
        break;
      case OpenItemStatus.ManualPaid:
        text = "Manual Paid";
        break;
      case OpenItemStatus.InvoicedToBank:
        text = "Invoiced To Bank";
        break;
      case OpenItemStatus.InvoicedToIndividual:
        text = "Invoiced To Individual";
        break;
      case OpenItemStatus.Refunded:
        text = "Refunded";
        break;
      case OpenItemStatus.Reconciled:
        text = "Reconciled";
        break;
      case OpenItemStatus.PendingManualPayByCheque:
        text = "Pending Manual Pay By Cheque";
        break;
      case OpenItemStatus.ManualPaidNonAutoDebitFileSent:
        text = "Manual Paid Non Auto Debit File Sent";
        break;
      case OpenItemStatus.PendingRefundToIndividual:
        text = "Pending Refund To Individual";
        break;
      case OpenItemStatus.PendingRefundToIndividualInReview:
        text = "Pending Refund To Individual In Review";
        break;
      case OpenItemStatus.UnpaidChequeToBank:
        text = "Unpaid Cheque To Bank";
        break;
      case OpenItemStatus.UnpaidChequeToIndividual:
        text = "Unpaid Cheque To Individual";
        break;
      case OpenItemStatus.NoRefund:
        text = "No Refund";
        break;
      case OpenItemStatus.RefundedCheque:
        text = "Refunded Cheque";
        break;
      case OpenItemStatus.MeridianAutoOffset:
        text = "Meridian Auto Offset";
        break;
      case OpenItemStatus.ChequeAutoUpdated:
        text = "Cheque Payment Auto Updated";
        break;
      case OpenItemStatus.FreeInsuranceAutoUpdated:
        text = "Free Insurance Auto Updated";
        break;
      case OpenItemStatus.PendingPayment:
        text = "Pending Payment";
        break;
      case OpenItemStatus.SentForCollection:
        text = "Sent For Collection";
        break;
      case OpenItemStatus.PendingRefund:
        text = "Pending Refund";
        break;
      default:
        throw Error("Not handled");
    }
    return text;
  }

  translateManualPaymentType(manualPaymentType: ManualPaymentType): string {
    switch (manualPaymentType) {
      case ManualPaymentType.Cash:
        return "Cash";
      case ManualPaymentType.Cheque:
        return "Cheque";
      case ManualPaymentType.Undefined:
        return " - ";
      default:
        throw Error("Not handled");
    }
  }

  translateRefundPaymentType(refundPaymentType: RefundPaymentType): string {
    switch (refundPaymentType) {
      case RefundPaymentType.Cheque:
        return "Cheque";
      case RefundPaymentType.OnlineTransfer:
        return "Online Transfer";
      case RefundPaymentType.Undefined:
        return " - ";
      default:
        throw Error("Not handled");
    }
  }

  translateMonth(monthInteger: number): string {
    let text: string = "";
    switch (monthInteger) {
      case 1:
        text = "January";
        break;
      case 2:
        text = "February";
        break;
      case 3:
        text = "March";
        break;
      case 4:
        text = "April";
        break;
      case 5:
        text = "May";
        break;
      case 6:
        text = "June";
        break;
      case 7:
        text = "July";
        break;
      case 8:
        text = "August";
        break;
      case 9:
        text = "September";
        break;
      case 10:
        text = "October";
        break;
      case 11:
        text = "November";
        break;
      case 12:
        text = "December";
        break;
      default:
        throw Error("Not handled");
    }
    return text;
  }
}

export const proposalCommon = new ProposalCommon();
