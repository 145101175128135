<kendo-dialog
  title="{{ h3LabelText }}"
  class="modal-title-red"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="900"
  [height]="440"
>
  <div class="container-fluid-custom container-overflow-y">
    <div class="modal-body">
      <div>
        <mf-sg-preferred-delivery id="isCreate" *ngIf="isSgProduct">
        </mf-sg-preferred-delivery>
        <mf-hk-preferred-delivery id="isCreate" *ngIf="isHkProduct">
        </mf-hk-preferred-delivery>
      </div>
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbLightBlue_bg_btn"
        kendoButton
        (click)="create()"
        themeColor="primary"
      >
        {{ labelText }}
      </button>
      <button class="btn chubbRed_bg_btn" kendoButton (click)="close('no')">
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
