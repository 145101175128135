<div class="col-md-12 chubbWhite_bg" style="display: flex">
  <i
    class="fa fa-2x fa-remove paddingTop3 tabNav marginLeftTop10px chubbRed cursorPointer"
    (click)="dialog.close()"
    title="Close"
  ></i>
  <div class="col-md-10 chubbRed ng-binding">
    <span class="card-head-agency">User Profile Successfully Created.</span>
  </div>
</div>
<div class="col-md-12 chubbWhite_bg card-body cardBodyBorder">
  <span>UserProfile Successfully Created</span>
</div>
