<kendo-dialog
  title="Contact Us"
  class="modal-title-green"
  (close)="close('cancel')"
  [width]="300"
  [height]="150"
>
  <div class="row clearfix margin0">
    <div class="cardNoBorder">
      <div class="col-md-12 padding0">
        <!-- Modal content -->
        <div class="modal-content cardNoBorder">
          <div class="modal-body">
            <div id="feedback_form">
              <div class="clearfix">
                <div class="col-md-12">
                  <div class="clearfix">
                    <i
                      class="fa fa-user marginRight10 floatLeft paddingTop3"
                      style="padding-left: 2px"
                    ></i>
                    <span>Chubb</span>
                    <br />
                  </div>
                  <!-- <div class="clearfix">
                    <i
                      class="fa fa-phone marginRight10 floatLeft paddingTop3"
                      style="padding-left: 2px"
                    ></i>
                    <span>+65 6398 8797</span>
                    <br />
                  </div>
                  <div class="clearfix">
                    <i
                      class="fa fa-envelope marginRight10 floatLeft paddingTop3 cursorPointer"
                      (click)="bizPackEmailLink($event)"
                    ></i>
                    <a class="cursorPointer" (click)="bizPackEmailLink($event)"
                      >dbscs.sg@chubb.com</a
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>
