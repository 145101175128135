<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>
  <div *ngIf="!question.readOnly">
    <kendo-dateinput
      [(ngModel)]="question.answer"
      format="dd/MM/yyyy"
      class="form-control"
      style="width: 200px"
      (blur)="onChange()"
      [min]="checkIsDefined('min', question!.minValue)"
      [max]="checkIsDefined('max', question!.maxValue)"
    ></kendo-dateinput>
  </div>
  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()">{{ displayText() }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
