// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const loadConfiguration = (item: string) => {
  let data!: any;

  switch (window.location.origin) {
    case "http://localhost:4200":
      data = require("src/assets/config/configuration.json");
      break;
    case "https://apcdcbacsw192.aceins.com:8020":
      data = require("src/assets/config/configuration.sit.json");
      break;
    case "https://ep2-banca-uat.chubb.com":
    case "https://ep2ap-general.chubb.com":
      data = require("src/assets/config/configuration.uat.json");
      break;
    case "https://apcdcbacsw191.aceins.com:8030":
      data = require("src/assets/config/configuration.stage.json");
      break;
    case "https://ep2-banca.chubb.com":
      data = require("src/assets/config/configuration.production1.json");
      break;
    default:
  }

  if (data) {
    return data[item];
  } else {
    alert("incompatible server setup");
  }
};

export const environment = {
  browser: typeof window !== "undefined",

  production: loadConfiguration("production"),
  clientLibsVersion: loadConfiguration("clientLibsVersion"),
  clientVersion: loadConfiguration("clientVersion"),
  serverVersion: loadConfiguration("serverVersion"),
  jsonVersion: loadConfiguration("jsonVersion"),
  serviceBase: loadConfiguration("developement")
    ? "http://localhost:19442/" //dev purpose
    : window.location.origin + "/api/", //deployment purpose
  client: loadConfiguration("client"),
  siteTitle: loadConfiguration("siteTitle"),
  responseType: loadConfiguration("responseType"),
  environment: loadConfiguration("environment"),
  si_limit_referral: loadConfiguration("si_limit_referral"),
  lstMinLimit: loadConfiguration("lstMinLimit"),
  lstMaxLimit: loadConfiguration("lstMaxLimit"),
  cmbMaxSiLimit: loadConfiguration("cmbMaxSiLimit"),
  wpbMaxSiLimit: loadConfiguration("wpbMaxSiLimit"),
  ClausesAndDeductiblesReleaseDate: loadConfiguration(
    "ClausesAndDeductiblesReleaseDate"
  ),
  services: {
    epUser: loadConfiguration("services").epUser,
    epProposal: loadConfiguration("services").epProposal,
  },
  googleApiKey: loadConfiguration("googleApiKey"),
  gRecaptchaPublicKey: loadConfiguration("gRecaptchaPublicKey"),
  gRecaptchaMinScore: loadConfiguration("gRecaptchaMinScore"),
  isgRecaptchaRequire: loadConfiguration("isgRecaptchaRequire"),

  // second(s)
  idleAllowed: loadConfiguration("idleAllowed"), // durations of user allowed to be idle (eg, 15 * 60)
  idleWarning: loadConfiguration("idleWarning"), // durations of user to response to idle warning

  adb2cSettings: {
    clientId: loadConfiguration("adb2cSettings").clientId,
    redirectUri:
      window.location.origin + loadConfiguration("adb2cSettings").redirectUri, //adb2c-ssologin.component.html
    authority: loadConfiguration("adb2cSettings").authority,
    knownAuthorities: loadConfiguration("adb2cSettings").knownAuthorities,
    postLogoutRedirectUri:
      window.location.origin +
      loadConfiguration("adb2cSettings").postLogoutRedirectUri,
    passwordChangepolicy: {
      authority:
        loadConfiguration("adb2cSettings").passwordChangepolicy.authority,
      value: loadConfiguration("adb2cSettings").passwordChangepolicy.value,
      //deployment purpose
      redirectUri: loadConfiguration("developement")
        ? "https://ep2ap-sit.chubb.com:8024/adb2c/changepassword/change-password-confirmation.html" //dev purpose
        : loadConfiguration(
            "adb2cSettings"
          ).passwordChangepolicy.redirectUri.startsWith("https:")
        ? loadConfiguration("adb2cSettings").passwordChangepolicy.redirectUri
        : window.location.origin +
          loadConfiguration("adb2cSettings").passwordChangepolicy.redirectUri,
    },
    cache: {
      cacheLocation: loadConfiguration("adb2cSettings").cache.cacheLocation, // This configures where your cache will be stored
      storeAuthStateInCookie:
        loadConfiguration("adb2cSettings").cache.storeAuthStateInCookie, // Set this to "true" if you are having issues on IE11 or Edge
    },
    tokenRequest: {
      scopes: loadConfiguration("adb2cSettings").tokenRequest.scopes,
      forceRefresh:
        loadConfiguration("adb2cSettings").tokenRequest.forceRefresh, // Set this to "true" to skip a cached token and go to the server to get a new token
    },

    //deployment purpose
    userTypeListing: loadConfiguration("developement")
      ? "http://localhost:8080/#/adb2cusertypelisting" //dev purpose
      : window.location.origin +
        loadConfiguration("adb2cSettings").userTypeListing,
    // userTypeListing:
    //deployment purpose
    landingPageTemplate: loadConfiguration("developement")
      ? "https://ep2ap-sit.chubb.com:8024/adb2c" //dev purpose
      : window.location.origin +
        loadConfiguration("adb2cSettings").landingPageTemplate,
    // landingPageTemplate:

    tenant: loadConfiguration("adb2cSettings").tenant,
    signUpSignIn: loadConfiguration("adb2cSettings").signUpSignIn,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
