<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>E-Placement | Role Selection</title>
    <link rel="icon" type="image/x-icon" id="ProducerHubIcon" />
    <link rel="stylesheet" id="mainStyle" type="text/css" />
    <link rel="stylesheet" id="viewStyle" type="text/css" />
    <script>
      setTimeout(function () {
        var window_Reg_Mesg = {};

        document.activeElement.blur();
        var windowLocation = window.location.href;
        var splitValue = windowLocation.split("/");
        var domainValue = splitValue[3];
        var https = "https://";
        var cssSrc = _a.adb2cSettings.landingPageTemplate + "/style.css";
        var cssHomeSrc = _a.adb2cSettings.landingPageTemplate + "/home.css";
        var commonImgPath =
          _a.adb2cSettings.landingPageTemplate + "/assets/images/";

        var messageJsonUrl = "";
        var importantMessageJsonUrl = "";

        document.getElementById("mainStyle").setAttribute("href", cssSrc);
        document.getElementById("viewStyle").setAttribute("href", cssHomeSrc);

        // adding images path dymanmically
        var imgsrcLogo = commonImgPath + "chubb-logo-header.svg";
        document
          .querySelector(".header__brand--logo")
          .setAttribute("src", imgsrcLogo);
        var warningImg = commonImgPath + "warning.svg";
        if (document.getElementById("warningImg") != null) {
          document.getElementById("warningImg").setAttribute("src", warningImg);
        }

        var loading = document.getElementById("showLoader");
        loading.style.display = "none";
      }, 1000);
    </script>
    <style>
      .page-container {
        margin-top: 180px;
        /* margin-top: 0px; */
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        height: 80%;
      }
      body {
        background-image: none !important;
        background-color: #f4f4f4 !important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        padding-top: 1px;
        overflow-x: hidden;
        /* scrollbar-width: none; */
        -ms-overflow-style: none;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        /* opacity: 1 !important; */
        /* transition : '1.5s opacity';     */
      }

      .defaultusertext {
        font-family: Chubb Publico;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: center;
      }

      .top-section {
        background-color: white;
        max-width: 1150px;
        margin-left: -1px;
        min-height: 700px;
        padding: 0px 0px 0px 0px !important;
        min-height: 500px !important;
        margin-top: -100px !important;
      }

      .header {
        /* margin-top: 20px; */
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 60px;
      }
      .roletype {
        border-top: #afafaf thin;
        height: 70px;
        width: 402px;
        left: 483px;
        top: 337px;
        border-radius: 0px;
        padding: 24px;
        box-shadow: 0px 1px 4px 0px #00000040;
        background-color: white;
      }

      ul {
        list-style-type: none !important;
      }

      .content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 40px !important;
        padding-top: 20px;
      }

      .footer {
        background-color: #4b4e53;
        color: #efefef;
        width: 100%;
        grid-template-columns: minmax(50px, 2fr) minmax(0, 1200px) minmax(
            20px,
            1fr
          ) !important;
        display: grid;
        overflow: hidden;
        padding: 20px 0px;
      }

      .footer h1 {
        font-family: "Chubb Publico", Georgia, serif;
        font-size: 25px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 12px;

        background-color: #99cd00;
        font-family: "Publico", bold;
        color: #ffffff;
        height: 35px;
        font-size: 21px;
        vertical-align: middle;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
      }

      .footer__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 20px;
      }

      .footer__content--links {
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 15px;
        padding-top: 10px;
      }

      .footer__content--links span {
        display: inline-block;
        margin-right: 15px;
      }

      .footer__content--links span a {
        color: #ffffff;
        font-family: "Lato Regular";
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 15px;
        padding-top: 10px;
      }
    </style>
  </head>
  <body>
    <header
      class="header"
      style="background-color: #f4f4f4 !important; box-shadow: none"
    >
      <h1
        class="header__brand"
        style="
          background-color: transparent;
          font-family: 'Publico', bold;
          color: #ffffff;
          height: 35px;
          font-size: 21px;
          vertical-align: middle;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 20px;
          padding-right: 20px;
        "
      >
        <img
          src="assets/chubb-logo-header.svg"
          alt="CHUBB LOGO"
          class="header__brand--logo"
        />
      </h1>
    </header>
    <div id="page-containerId" class="page-container">
      <div
        class="top-section"
        style="max-width: 1670px; background-color: #f4f4f4 !important"
      >
        <div class="defaultusertext">
          Select your default <span style="color: blue"> user role</span>
        </div>
        <br />
        <p style="text-align: center">
          Please choose the default user role you will be logged into as
          everytime you log in.
        </p>
        <p style="text-align: center">
          This selection is not permanent, you can change this selection anytime
          afterwards on the account navigation.
        </p>
        <br />

        <ul style="z-index: 9999; margin-left: 33%">
          <div *ngFor="let option of userTypeOptions.Items">
            <li class="roletype cursorPointer">
              <a
                (click)="OpenUserRoleSelected(option)"
                ng-model="userloginselectedValue"
                ng-value="option.UserLogin"
                style="color: #337ab7; padding: 0px"
                ><i class="fa fa-user"></i> {{ option.OrganisationName }}</a
              >
            </li>
            <br />
          </div>
        </ul>
      </div>
      <footer class="footer">
        <div class="spacer"></div>
        <div class="footer__content">
          <h1 style="background-color: transparent">
            Chubb. Insured.<sup>&trade;</sup>
          </h1>
          <div class="footer__content--links">
            <span><a id="footerYear"></a></span>

            <span>
              <a
                href="https://www.chubb.com/us-en/terms-of-use.html"
                target="_blank"
              >
                Terms of Use
              </a>
            </span>

            <span>
              <a
                href="https://www.chubb.com/us-en/online-privacy-policy.html"
                target="_blank"
              >
                Privacy Statement
              </a>
            </span>
          </div>
        </div>
        <div class="spacer"></div>
      </footer>
    </div>
    <!-- <div id="insertJS"></div> -->
    <script>
      var windowLocation = window.location.href;
      var splitValue = windowLocation.split("/");
      var domainValue = splitValue[2];

      setTimeout(function () {
        var loading = document.getElementById("showLoader");
        if (loading) {
          loading.style.display = "none";
        }
        var pagecontainerId =
          window.document.getElementById("page-containerId");
        if (pagecontainerId) {
          pagecontainerId.style.display = "block";
        }
      }, 3000);

      setTimeout(function () {
        if (document.body) {
          document.body.style.opacity = "1";
          document.body.style.transition = "1.5s opacity";
        }
      }, 1000);

      var windowLocation = window.location.href;
      var splitValue = windowLocation.split("/");
      var domainValue = splitValue[2];

      var envVal = "";

      envVal = _a.adb2cSettings.landingPageTemplate;

      setTimeout(function () {
        var headTag = document.getElementsByTagName("head")[0];

        var linkTag = document.createElement("link");
        linkTag.setAttribute(
          "href",
          envVal + "/forgotpassword/forgot-password.css"
        );
        linkTag.setAttribute("type", "text/css");
        linkTag.setAttribute("rel", "stylesheet");

        var scriptTag = document.createElement("script");
        scriptTag.setAttribute(
          "src",
          envVal + "/forgotpassword/forgot-password-confirmation.js"
        );

        //document.getElementById("successIcon").src = envVal + "/forgotpassword/images/icon-success-blue.svg";

        document.head.appendChild(scriptTag);
        document.head.appendChild(linkTag);

        let commonJS = document.createElement("script");
        commonJS.setAttribute("src", envVal + "/adb2c/mfaverify/common.js");
        if (document.getElementById("insertJS") != null) {
          document.getElementById("insertJS").appendChild(linkTag);
          document.getElementById("insertJS").appendChild(scriptTag);
          document.getElementById("insertJS").appendChild(commonJS);
        }
      }, 2000);
    </script>

    <!-- <div id="showLoader"><span id="loading-text">Loading...</span></div> -->
  </body>
</html>
