import { Component, Inject, OnInit } from "@angular/core";
import { cloneDeep } from "lodash";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import { DIALOG_DATA } from "src/app/components/shared/partials/dialog/dialog-tokens";
import { clientViewDlgModelArgs } from "src/app/components/shared/partials/mdClient/client-view/client-view.component";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { Global } from "src/app/shared/global";
import { MdValidationMessageComponent } from "../../sme/masterPolicy/md-validation-message/md-validation-message.component";
import { ClientTimelineService } from "src/app/services/client-timeline.service";

@Component({
  selector: "app-client-timeline",
  templateUrl: "./client-timeline.component.html",
  styleUrls: ["./client-timeline.component.scss"],
})
export class ClientTimelineComponent implements OnInit {
  model: ClientTimelineModel = new ClientTimelineModel(); //ClientTimelineModelFactory.buildClientTimeline();
  data!: any;
  message = "";

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any,
    private cdkDialogService: CdkDialogService,
    private clientTimelineService: ClientTimelineService
  ) {
    if (this.inputData) {
      this.model.data = inputData;

      //this.model.consent = Enum.consent;

      this.model.clientTimeline.push(new ClientTimeline());
      this.model.clientTimeline[0].timelineComments = "";
      this.model.clientTimeline[0].timelineConsent = null;
      this.model.clientTimeline[0].policyNumber = "";

      this.data = {
        proposalId: inputData.proposalId,
        policyNumber: inputData.policyNumber,
        createdByUserId: inputData.createdByUserId,
      };
    }
  }

  ngOnInit(): void {}

  submit() {
    this.model.clientTimeline[0].proposalId = this.data.proposalId;
    this.model.clientTimeline[0].createdBy = this.data.createdByUserId;
    this.model.clientTimeline[0].timelineType = 1; //Type = 1 for Multiyear
    this.model.clientTimeline[0].policyNumber = this.data.policyNumber;

    var serverModel = cloneDeep(this.model.clientTimeline[0]); //UiManager.buildServerModel(this.model.clientTimeline);

    if (Global.isUndefined(this.model.clientTimeline[0].timelineComments))
      this.model.clientTimeline[0].timelineComments = "";

    if (
      this.model.clientTimeline[0].timelineComments != "" &&
      this.model.clientTimeline[0].timelineConsent != null
    ) {
      this.clientTimelineService
        .saveMultiyearOption(serverModel)
        .subscribe((model) => {
          setTimeout(() => {
            this.getMessage(this.model.clientTimeline[0].proposalId);
          }, 100);
        });
    } else {
      var validateData = [];
      var validationMessage = "";

      if (
        this.model.clientTimeline[0].timelineComments == "" &&
        this.model.clientTimeline[0].timelineConsent == null
      )
        validationMessage = "<b>Please enter Comments and Consent</b>";
      else if (this.model.clientTimeline[0].timelineComments == "")
        validationMessage = "<b>Please enter Comments</b>";
      else if (this.model.clientTimeline[0].timelineConsent == null)
        validationMessage = "<b>Please enter Consent</b>";

      validateData.push({
        text: validationMessage,
      });
      const v1DialogRef = this.cdkDialogService.open(
        MdValidationMessageComponent,
        {
          data: validateData,
        }
      );

      v1DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
        }
      });
    }
  }

  getMessage(proposalId: any) {
    this.clientTimelineService
      .getMessage(proposalId)
      .subscribe((response: any) => {
        if (response) {
          setTimeout(() => {
            if (response.data != null && response.data.length > 0) {
              this.model.clientTimeline = response.data;
              this.close("yes", this.model.clientTimeline);
            } else {
              this.close("yes", this.model.clientTimeline);
            }
          }, 100);
        }
      });
  }

  public close(status: any, messageBoard: any) {
    let savParamters: clientViewDlgModelArgs = {
      status: status,
      data: messageBoard,
    };
    this.dialogRef?.close(savParamters);
  }

  public getMessageAndClose(status: any) {
    if (status !== "yes") {
      this.getMessage(this.data.proposalId);
    }
  }
}

export class ClientTimelineModel {
  data: any;
  consent: any;
  clientTimeline: ClientTimeline[] = [];
}

export class ClientTimeline {
  timelineComments: any;
  timelineConsent: any;
  policyNumber: any;
  proposalId: any;
  createdBy: any;
  timelineType: any;
}
