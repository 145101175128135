<div class="row marginLeft15" *ngIf="isDataLoaded">
  <div class="k-i-loading"></div>
</div>
<div *ngIf="!isDataLoaded" class="col-md-12">
  <div class="card-head chubbWhite_bg chubbLightGray_border">
    <div class="tools">
      <div class="btn-group"></div>
    </div>
    <header class="chubbDarkBlue">Additional Information</header>
  </div>
  <div class="card-body chubbLightGray_border">
    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
      <div style="padding: 10px">
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Display Name'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="displayName"
                name="displayName"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.displayName"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Salutation'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="salutation"
                name="salutation"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.salutation"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'First Name'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="firstName"
                name="firstName"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.firstName"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Last Name'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="lastName"
                name="lastName"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.lastName"
                class="k-textbox form-control"
                required
              />
              <kendo-formerror style="width: 17vw"
                ><span class="k-icon-custom k-i-warning"></span> Please Enter
                LastName</kendo-formerror
              >
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Date of Birth'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-datepicker
                class="maxWidth"
                id="dateOfBirth"
                format="dd/MM/yyyy"
                placeholder=""
                [max]="dob_max"
                [(ngModel)]="dob"
                name="dateOfBirth"
              >
              </kendo-datepicker>
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Gender'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-dropdownlist
                [data]="genderList"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [valuePrimitive]="true"
                [(ngModel)]="personalDetails.gender"
                name="gender"
              >
              </kendo-dropdownlist>
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Nationality'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-dropdownlist
              [data]="nationality"
              textField="text"
              valueField="value"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [valuePrimitive]="true"
              [(ngModel)]="personalDetails.nationality"
              name="nationality"
            >
            </kendo-dropdownlist>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Race'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="race"
                name="race"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.race"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Passport No.'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="passportNo"
                name="passportNo"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.passportNo"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Marital Status'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-combobox
                [data]="statusList"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                placeholder="--Type to search"
                [valuePrimitive]="true"
                [(ngModel)]="personalDetails.maritalStatus"
                name="maritalStatus"
              >
              </kendo-combobox>
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div
          [hidden]="personalDetails.maritalStatus == 1"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label [innerHtml]="'Name of Spouse'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="nameOfSpouse"
                name="nameOfSpouse"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.nameOfSpouse"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'No of Children'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-dropdownlist
              [data]="noOfChilds"
              textField="text"
              valueField="value"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [valuePrimitive]="true"
              [(ngModel)]="personalDetails.noOfChildren"
              name="noOfChildren"
            >
            </kendo-dropdownlist>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Education'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="education"
                name="education"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.education"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Hobbies'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="hobbies"
                name="hobbies"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.hobbies"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
          *ngIf="serverErrorMsg"
        >
          <div class="col-md-6">
            <ul style="padding-left: 6px">
              <li class="lblColorRed">
                {{ serverErrorMsg }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
