import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { timer } from "rxjs";
import { finalize, takeWhile, tap } from "rxjs/operators";

import { IdleWarningStates } from "../enums/idle-warning.states.enum";
import { IdleService } from "src/app/services/idle/idle.service";

@Component({
  selector: "ng-idle-warning",
  templateUrl: "./idle-warning.component.html",
  styleUrls: ["./idle-warning.component.scss"],
})
export class IdleWarningComponent implements OnInit, OnDestroy {
  @Input() titleMessage!: string;
  @Input() bodyMessage!: string;

  constructor(private _idleService: IdleService) {}

  private _isComponentDestroyed = false;

  timeRemaining!: number;
  warningTime!: number;

  ngOnInit(): void {
    this.warningTime = this._idleService.idleWarningTime;
    this.timeRemaining = this._idleService.idleWarningTime;
    this.startTimer();
  }

  ngOnDestroy(): void {
    this._isComponentDestroyed = true;
  }

  private startTimer(): void {
    this.setState(true);
    timer(0, 1000)
      .pipe(
        takeWhile(() => this.continueTimer()),
        finalize(() => this.setState(false)),
        tap(() => this.timeRemaining--)
      )
      .subscribe();
  }

  private setState(isInitalState: boolean): void {
    if (isInitalState) {
      this._idleService.currentIdleWarningState =
        IdleWarningStates.SecondaryTimerStarted;
      return;
    }

    this._idleService.currentIdleWarningState =
      this.timeRemaining > 0
        ? IdleWarningStates.SecondaryTimerCancelled
        : IdleWarningStates.SecondaryTimerExpired;
  }

  private continueTimer(): boolean {
    if (!this._isComponentDestroyed && this.timeRemaining > 0) {
      return true;
    }
    return false;
  }
}
