export enum OpenItemStatus {
  Undefined = 0,
  PendingAutoDebit = 1,
  PendingInvoiceToBank = 2,
  PendingToInvoiceToIndividual = 3,
  PendingRefundToBank = 4,
  AutoDebited = 5,
  ManualPaid = 6, //shared with hase
  InvoicedToBank = 7,
  InvoicedToIndividual = 8,
  Refunded = 9, //shared with hase
  Reconciled = 10, //shared with hase
  PendingManualPayByCheque = 11,
  ManualPaidNonAutoDebitFileSent = 12,
  PendingRefundToIndividual = 13,
  UnpaidChequeToBank = 14,
  UnpaidChequeToIndividual = 15,
  NoRefund = 16,
  RefundedCheque = 17,
  MeridianAutoOffset = 18,
  ChequeAutoUpdated = 19,
  FreeInsuranceAutoUpdated = 20,
  PendingRefundToIndividualInReview = 21,
  PendingRefundToBankInReview = 22,
  PendingPayment = 23, //new for hase
  SentForCollection = 24, //new for hase
  PendingRefund = 25, //new for hase
}
