<kendo-dialog
  title="Client Details"
  class="modal-title-light-orange"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="dlgWidth"
  [height]="440"
>
  <div class="container-fluid-custom container-overflow-y">
    <div class="modal-body">
      <mf-sg-client-view id="isNewClient" *ngIf="isSgProduct">
      </mf-sg-client-view>
      <mf-hk-client-view id="isNewClient" *ngIf="isHkProduct">
      </mf-hk-client-view>
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        [hidden]="!isEditable"
        (click)="update(); close('yes')"
        themeColor="primary"
      >
        Edit
      </button>
      <button
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel"
        kendoButton
        [disabled]="isDisabled"
        (click)="close('no')"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
