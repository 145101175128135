import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
import {
  ROUTE_HOME,
  ROUTE_USER_ROLE_SELECTION,
} from "src/app/app-routing.module";
import * as Enums from "./../../../framework/enum.shared";
import { common } from "src/app/framework/utils/common";
import { AuthorizationService } from "src/app/services/authentication/authorization.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { UrlService } from "src/app/services/url.service";
import { Identity } from "src/app/shared/user/identity";
import { environment } from "src/environments/environment";
//import { AuthorizationService } from 'src/app/core/services/authentication/authorization.service';

@Component({
  selector: "app-adb2c-login-redirect",
  templateUrl: "./adb2c-login-redirect.component.html",
  styleUrls: ["./adb2c-login-redirect.component.scss"],
})
export class Adb2cLoginRedirectComponent implements OnInit {
  constructor(
    private msalService: MsalService,
    private authorizationService: AuthorizationService,
    private identity: Identity,
    private router: Router,
    private identityRolesService: IdentityRolesService,
    private urlService: UrlService
  ) {}

  ngOnInit(): void {
    var loginSilentRequest = {
      scopes: [environment.adb2cSettings.clientId],
    };

    this.msalService.instance
      .acquireTokenSilent(loginSilentRequest)
      .then((res) => {
        var ssoUserName = "adb2c_to_ams_sso"; // need to be configured in api as well
        var loginData = {
          userName: ssoUserName,
          password: res.accessToken,
        };

        this.authorizationService.login(loginData).subscribe(async (resp) => {
          this.identity.storeAuthData(resp);

          if (
            common.isDefined(resp.DefaultUserLoginCount) &&
            parseInt(resp.DefaultUserLoginCount) < 1
          ) {
            //some env is sub-folder
            window.location.href = this.urlService.GetFullUrlWithSubDirectory(
              ROUTE_USER_ROLE_SELECTION
            );
          } else {
            // console.log("adb2cssologin have default");
            // populating the current user
            //has to wait until fully finish to get latest this.identity.currentUser()
            //this.identity.populateCurrentUser(true);
            await this.identity.populateCurrentUser_Promise(true);
            this.redirectPage();
          }
        });
      });
  }

  redirectPage() {
    //check admin first in new angular, since some users could have multiple roles
    if (this.identity.currentUser().userType == Enums.UserType.Admin) {
      this.router.navigate(["/userProfileList"]);
    } else if (
      this.identityRolesService.hasProductReadWriteRoles ||
      this.identityRolesService.hasProductTransactRoles
    ) {
      this.router.navigate(["/" + ROUTE_HOME]);
    } else if (this.identityRolesService.isFinanceAccounting) {
      this.router.navigate(["/openItem"]);
    }
  }

  //   // Step 1: handle Access Token response
  //   handleAccessTokenResponse(response) {
  //   if (response !== null) {
  //       if (!response.accessToken || response.accessToken === "") {
  //         // throw Auth exception if access token is not returned from ADB2C
  //         //throw new msal.InteractionRequiredAuthError;

  //     } else {
  //       /* Access token is sent to API for verification and application token is generated
  //       Note: Do not change login-form ID in login drLogin.html & drADB2CSSOLogin.html
  //       */

  //     }
  //   }
  // }
}
