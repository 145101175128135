import { Component, Input, OnInit } from "@angular/core";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid";
import { proposalCommon } from "../../../base/utils/proposal-common";
import * as HomeConstant from "./../../../../new-product/home/home-constant";
import * as AppEnums from "src/app/framework/app.enums";
import { ROUTE_PROPOSAL } from "src/app/app.module";
import { Router } from "@angular/router";

@Component({
  selector: "app-policy-list-details",
  templateUrl: "./policy-list-details.component.html",
  styleUrls: ["./policy-list-details.component.scss"],
})
export class PolicyListDetailsComponent implements OnInit {
  ROW_HEADER_HEIGHT: number = 85;
  SINGLE_ROW_HEIGHT: number = 52; //bootstrap; default:45;
  public height = this.ROW_HEADER_HEIGHT;
  public pageSize = 5; //5 is just dummy initial
  public skip = 0;
  public take = 5;

  @Input()
  public transHistoriesJson!: string;

  public gridViewDetail!: GridDataResult; // Observable<GridDataResult>;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.on_DetailInit();
  }

  private on_DetailInit = () => {
    if (this.transHistoriesJson !== "[]") {
      var listing = JSON.parse(this.transHistoriesJson);

      if (listing != null) {
        //since parsing from json, nowhere to determine total and thus takes all, hence no paging
        this.pageSize = listing.length;

        this.height =
          this.ROW_HEADER_HEIGHT + this.SINGLE_ROW_HEIGHT * listing.length;
        this.gridViewDetail = {
          data: listing, //.slice(this.skip, this.skip + this.pageSize),
          total: listing.length,
        };
      }
      // $('<div/>').appendTo(e.detailCell).kendoGrid({
      //     dataSource: {
      //         data: JSON.parse(transHistoriesJson),
      //         requestEnd: this.on_ChildRequestEnd
      //     },
      //     columns: [
      //         { field: 'effectiveDate', title: 'Previous Trans. Effective Date' },
      //         { field: 'policyBoundDate', title: 'Previous Approved Date' },
      //         { field: 'proposalTypeDisplayText', title: 'Previous Trans. Type' },
      //         { field: 'endorsementTypeDisplayText', title: 'Previous Endorsement Type' },
      //         { field: 'premium', title: 'Premium' },
      //     ],
      //     selectable: 'single, row',
      //     scrollable: false,
      //     change: this.on_ChildChange
      // });
    }
  };

  public pageChange(event: PageChangeEvent): void {}

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    const productId = dataItem.productId;
    const proposalId = dataItem.proposalId;

    const route = AppEnums.productsRoute[productId + "|1"];
    const commandAction = AppEnums.proposalCommandAction["Open"];
    if (route !== undefined) {
      this.router.navigate(["/" + ROUTE_PROPOSAL], {
        queryParams: {
          pdid: productId,
          pid: proposalId,
          action: commandAction,
        },
      });
    }
  }

  public getProposalTypeDisplayText(dataItem: any): string {
    return HomeConstant.transactionType[dataItem.transTypeId];
  }

  public getEndorsementTypeDisplayText(dataItem: any): string {
    return proposalCommon.translateEndorsementType(dataItem.endorsementTypeId);
  }
}
