import { QuestionEntity } from "./../../../../../base/question-entity.model";
import { Rule } from "./../../../../../base/rule.model";
import { MortgageFireSingaporeProposal } from "./../mf-sg-proposal";

export class MortgageFireSingaporeBusinessRuleSchema {
  manualRefer: Rule<QuestionEntity | undefined>;
  ibgSI!: Rule<QuestionEntity | undefined>;
  backDatedPOI: Rule<QuestionEntity | undefined>;
  backDatedLoanRedemption: Rule<QuestionEntity | undefined>;
  // changeOfSIEndorsementType: Rule<QuestionEntity>;
  changeOfdAddressEndorsementType: Rule<QuestionEntity | undefined>;
  cancellationRefer: Rule<QuestionEntity | undefined>;
  makeRedundantRefer: Rule<QuestionEntity | undefined>;
  cancellationReferBackdated: Rule<QuestionEntity | undefined>;
  loanRedemptionReferBackdated: Rule<QuestionEntity | undefined>;
  manualUnderwritingRefer: Rule<QuestionEntity | undefined>;
  loanRedemptionReinstatementMessage: Rule<QuestionEntity | undefined>;
  renewalYearPendingCheckerMessage: Rule<QuestionEntity | undefined>;
  previousYearPendingCheckerMessage: Rule<QuestionEntity | undefined>;
  ibgMACSI: Rule<QuestionEntity | undefined>;

  constructor(private proposal: MortgageFireSingaporeProposal) {
    this.manualRefer = new Rule(
      "REF01",
      "Manual Refer",
      this.proposal.qsPremiumSummary.qs_master_manualReferral
    );
    this.ibgSI = new Rule(
      "REF02",
      "Sum Insured greater than $3M",
      this.proposal.qsMaster.qs_fire_replacement
    );
    this.backDatedPOI = new Rule(
      "REF03",
      "Backdating insurance start date",
      this.proposal.qsMaster.qs_master_poi_startDate
    );
    this.backDatedLoanRedemption = new Rule(
      "REF13",
      "Loan Redemption backdating effective date",
      this.proposal.qsMaster.qs_master_poi_startDate
    );
    // this.changeOfSIEndorsementType =
    //     new Rule('REF04', 'Modify Sum Insured Endorsement', this.proposal.qsMaster.qs_master_endorse_endorsementReason);
    this.changeOfdAddressEndorsementType = new Rule(
      "REF04",
      "Modify Correspondence Address Endorsement Requested",
      this.proposal.qsMaster.qs_master_endorse_endorsementReason
    );
    this.cancellationRefer = new Rule(
      "REF05",
      "Cancellation Adjustment Requested",
      this.proposal.qsMaster.qs_master_cancellation_adjustmentAmount
    );

    this.makeRedundantRefer = new Rule(
      "REF12",
      "Renewal Draft Redundant Requested",
      this.proposal.qsMaster.qs_master_endorse_endorsementReason
    );

    this.cancellationReferBackdated = new Rule(
      "REF06",
      "Cancellation backdating greater than 7 days",
      this.proposal.qsMaster.qs_master_effectiveDate
    );

    this.manualUnderwritingRefer = new Rule(
      "REF07",
      "Manual Underwriting is selected",
      this.proposal.qsMaster.qs_master_manualUnderwritting
    );

    this.loanRedemptionReferBackdated = new Rule(
      "REF08",
      "Loan redemption backdating greater than 14 days",
      this.proposal.qsMaster.qs_master_effectiveDate
    );

    this.loanRedemptionReinstatementMessage = new Rule(
      "REF09",
      "Previous year policy is currently being loan redeemed and is in Pending Checker status",
      this.proposal.qsMaster.qs_master_effectiveDate
    );

    this.renewalYearPendingCheckerMessage = new Rule(
      "REF10",
      "Previous year policy is currently is in Pending Checker status",
      this.proposal.qsMaster.qs_master_effectiveDate
    );

    this.previousYearPendingCheckerMessage = new Rule(
      "REF11",
      "Current year policy is currently is in Pending Checker status",
      this.proposal.qsMaster.qs_master_effectiveDate
    );
    //DBSEP-2136
    this.ibgMACSI = new Rule(
      "REF12",
      "Sum Insured greater than $3M",
      this.proposal.qsMaster.qs_machinery_equipment
    );
  }
}
