import { QuestionEntityValidator } from "./../../../../../base/validator/question-entity.validator";
import { Echos } from "../../../../../base/echos.model";
import { QuestionEntity } from "../../../../../base/question-entity.model";
//import lodash = require('lodash');
import { concat } from "lodash";
import { proposalHelper } from "../../../../../utils/proposal.helper";
import { Echo } from "../../../../../base/echo.model";
import { SectionTab } from "../../../../../base/section-tab.model";
import { QuestionTheme } from "../../../../../../../framework/enum.shared";

export class MortgageFireHongKongQuestionEntityValidator extends QuestionEntityValidator {
  constructor(
    private questionsSg: QuestionEntity[],
    private tabSchemasSg: SectionTab[]
  ) {
    super(questionsSg, tabSchemasSg);
  }

  override validate(callback?: (echosToAdd: Echos<QuestionEntity>) => void) {
    let pValidationEchos = super.validate(callback);

    if (pValidationEchos === null) {
      pValidationEchos = Array<Echos<QuestionEntity>>();
    }

    const postalCodeEchoArray: Array<Echo<QuestionEntity>> =
      Array<Echo<QuestionEntity>>();

    //no needed for Hase
    // const echoRiskPostalCodeValidation = this.validateRiskPostalCode();
    // if (echoRiskPostalCodeValidation) {
    //   postalCodeEchoArray.push(echoRiskPostalCodeValidation);
    // }

    const results = this.convertToEchos(postalCodeEchoArray);

    if (pValidationEchos.length > 0) {
      for (let index = 0; index < pValidationEchos.length; index++) {
        const value = pValidationEchos[index];
        results.forEach((nValue, nIndex) => {
          if (nValue.title === value.title) {
            pValidationEchos[index].echos = concat(
              pValidationEchos[index].echos,
              results[nIndex].echos
            );
          } else {
            pValidationEchos.push(nValue);
          }
        });
      }
    } else {
      pValidationEchos = results;
    }

    if (pValidationEchos.length > 0) {
      return pValidationEchos;
    }

    return Array<Echos<QuestionEntity>>();
  }

  validateRiskPostalCode = (): Echo<QuestionEntity> | null => {
    const insuredPostalCode = this.questionsSg.filter((value) => {
      return value.key === "qs_risk_insuredPostalCode";
    });

    if (insuredPostalCode.length > 0) {
      return this.validateProposalPostalCodes(insuredPostalCode[0]);
    }

    return null;
  };

  validateProposalPostalCodes = (
    question: QuestionEntity
  ): Echo<QuestionEntity> | null => {
    if (question.answer.length === 0) {
      return null;
    }

    const isValid = this.checkPostalCodeFormat(question.answer);
    if (!isValid) {
      question.theme = QuestionTheme.Danger;
      return proposalHelper.buildQuestionEntityEcho(
        "Invalid " + question.displayText + " Format",
        question
      );
    }

    return null;
  };

  checkPostalCodeFormat = (postalCodes: string): boolean => {
    const reg = /(^(\d{6}\/)*(\d{6})$)/;
    return reg.test(postalCodes);
  };
}
