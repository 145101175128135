<div row padding5 *ngIf="isProcessing">
  <div class="margin5 textAlignCenter">
    <span class="loadingBarText">Please Wait</span>&nbsp;
    <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
  </div>
</div>
<div class="container-fluid" id="clientPersonalInformation">
  <div class="row">
    <div class="col-md-12 textDecoUnderline">
      <h4>Personal Information</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Mortgagor Name'"></label>
          <div>
            <p class="wordBreakAll" *ngIf="clientViewData?.clientName != ''">
              {{ clientViewData?.clientName }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.clientName == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6"></div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form">
        <div class="form-group">
          <div class="form-group">
            <label
              class="fontSize14px"
              [textContent]="'Mortgagor ID Type'"
            ></label>
            <div>
              <!--documentType is for docx word document?-->
              <p *ngIf="clientViewData?.docType != ''">
                {{ clientViewData?.documentTypeText }}
              </p>
              <span class="unattended" *ngIf="clientViewData?.docType == ''">{{
                noAnswerDefaultText
              }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6 textBreakWord">
      <!--documentType is for docx word document?-->
      <form role="form " *ngIf="clientViewData.docType == 'I'">
        <div class="form-group" id="nric">
          <label class="fontSize14px" [textContent]="'Mortgagor ID'"></label>
          <div>
            <p *ngIf="clientViewData?.nric != ''">{{ clientViewData?.nric }}</p>
            <span class="unattended" *ngIf="clientViewData?.nric == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
      <!--documentType is for docx word document?-->
      <form
        role="form "
        *ngIf="
          clientViewData?.clientType == '1' && clientViewData.docType == 'P'
        "
      >
        <div class="form-group" id="passportNo">
          <label class="fontSize14px" [textContent]="'Mortgagor ID'"></label>
          <div>
            <p *ngIf="clientViewData?.passportNo != ''">
              {{ clientViewData?.passportNo }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.passportNo == ''"
              >{{ noAnswerDefaultText }}
            </span>
          </div>
        </div>
      </form>
      <form role="form " id="businessRegistrationNumber">
        <div class="form-group" *ngIf="clientViewData?.clientType == '2'">
          <label class="fontSize14px" [textContent]="'Mortgagor ID'"></label>
          <div>
            <p *ngIf="clientViewData?.businessRegistrationNumber != ''">
              {{ clientViewData?.businessRegistrationNumber }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.businessRegistrationNumber == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
      <form role="form " id="DBClientReferenceNumber">
        <!--documentType is for docx word document?-->
        <div
          class="form-group"
          *ngIf="
            clientViewData?.clientType == '3' && clientViewData.docType == 'X'
          "
        >
          <label class="fontSize14px" [textContent]="'Mortgagor ID'"></label>
          <div>
            <p *ngIf="clientViewData?.DBClientReferenceNumber != ''">
              {{ clientViewData?.DBClientReferenceNumber }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.DBClientReferenceNumber == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="container-fluid">
  <hr />
  <div class="row">
    <div class="col-md-12 textDecoUnderline">
      <h4>Contact Information</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Address Line 1'"></label>
          <div>
            <p *ngIf="clientViewData?.address[0].addressLine1 != ''">
              {{ clientViewData?.address[0].addressLine1 }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].addressLine1 == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Address Line 2'"></label>

          <div>
            <p *ngIf="clientViewData?.address[0].addressLine2 != ''">
              {{ clientViewData?.address[0].addressLine2 }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].addressLine2 == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Address Line 3'"></label>
          <div>
            <p *ngIf="clientViewData?.address[0].addressLine3 != ''">
              {{ clientViewData?.address[0].addressLine3 }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].addressLine3 == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Address Line 4'"></label>

          <div>
            <p *ngIf="clientViewData?.address[0].addressLine4 != ''">
              {{ clientViewData?.address[0].addressLine4 }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].addressLine4 == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Country'"></label>
          <div>
            <p *ngIf="clientViewData?.address[0].countryISO2 != ''">
              {{ clientViewData?.countryISO2Text }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].countryISO2 == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div> -->
  <div class="row">
    <div class="col-md-6">
      <form role="form " id="mobilePhoneForm">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Mobile Number'"></label>
          <div>
            <p *ngIf="clientViewData?.mobileNumber != ''">
              {{ clientViewData?.mobileNumber }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.mobileNumber == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form " name="emailForm" novalidate>
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Email Address'"></label>
          <div>
            <p *ngIf="clientViewData?.emailAddress != ''">
              {{ clientViewData?.emailAddress }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.emailAddress == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form " id="housePhoneForm">
        <div class="form-group">
          <label
            class="fontSize14px"
            [textContent]="'Phone Number (Home)'"
          ></label>
          <div>
            <p *ngIf="clientViewData?.phoneNoHome != ''">
              {{ clientViewData?.phoneNoHome }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.phoneNoHome == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form " id="officePhoneForm">
        <div class="form-group">
          <label
            class="fontSize14px"
            [textContent]="'Phone Number (Office)'"
          ></label>
          <div>
            <p *ngIf="clientViewData?.phoneNoOffice != ''">
              {{ clientViewData?.phoneNoOffice }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.phoneNoOffice == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Fax Number'"></label>
          <div>
            <p *ngIf="clientViewData?.faxNumber != ''">
              {{ clientViewData?.faxNumber }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.faxNumber == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
