import * as Enums from "./../../../framework/enum.shared";

import { IdentityRolesService } from "./../../../services/identity-roles.service";
import { EndorsementHistory } from "./endorsement-history.model";
import { ExceptionManager } from "./../../../framework/utils/exception-manager";
import { GUID } from "./../../../framework/domain-entity/guid";
import { IUser } from "./../../../framework/interface.shared";
import { ProductID } from "./../../../framework/const.shared";
import { ProposalEntity } from "./proposal-entity.model";
import { ProposalPendingTransaction } from "./proposal-pending-transaction.model";
import { QuestionEntity } from "./question-entity.model";
import { common } from "./../../../framework/utils/common";
import { ClausesandDeductibles } from "./Clauses-Deductibles.model";

/** Class that wrap proposal entity and act like a product specific proposal entity */
export abstract class Proposal {
  productId: GUID;
  proposalId?: GUID;
  currentUser: IUser;
  transType?: Enums.TransType = Enums.TransType.NEW;
  proposalStatus: Enums.ProposalStatus = Enums.ProposalStatus.Incomplete;
  makerUserId?: number = 0;
  checkerUserId?: number = 0;
  copyProcessFor?: Enums.copyProcessFor;
  quoteNumber?: string = "";
  policyNumber?: string = "";
  customerMappingId?: number = 0;
  customerMappingAddressID?: number = 0;
  //https://stackoverflow.com/questions/57086672/element-implicitly-has-an-any-type-because-expression-of-type-string-cant-b
  auxiliary: Record<string, any> = {};
  endorsementType?: number;
  addtionalEndorsementType?: number;
  loanRedeemed?: boolean = false;
  pendingTransaction: ProposalPendingTransaction | undefined;
  documents: any = [];
  endorsementHistory!: EndorsementHistory[];
  clausesDeductibles!: ClausesandDeductibles[];
  referralMessageRequires: boolean = false;
  originalInceptionDate?: any;
  minimumEffectiveDate?: any;
  lastSubmittedDate?: any;
  makerUserName: any;
  checkerUserName: any;
  openItems: any = [];
  validationMessage: any = [];

  constructor(
    productId: GUID,
    public identityRolesService: IdentityRolesService,
    proposalId?: GUID
  ) {
    ExceptionManager.argumentNullGuard(productId, "productId");

    if (common.isDefined(proposalId)) {
      this.proposalId = proposalId;
    } else {
      this.proposalId = new GUID();
    }

    this.productId = productId;
    this.currentUser = this.identityRolesService.getIdentity().currentUser();
  }

  get isUnderwriter(): boolean {
    return this.currentUser.userType === Enums.UserType.Underwriter;
  }

  get isBroker(): boolean {
    return this.currentUser.userType === Enums.UserType.Broker;
  }

  private resetHeader() {
    // reset flag
    this.referralMessageRequires = false;

    this.transType = Enums.TransType.NEW;
    this.proposalStatus = Enums.ProposalStatus.Incomplete;
    this.makerUserId = 0;
    this.checkerUserId = 0;
    this.quoteNumber = "";
    this.policyNumber = "";
    this.customerMappingId = 0;
    this.customerMappingAddressID = 0;
    this.auxiliary = {};
    this.endorsementType = undefined;
    this.loanRedeemed = false;
    this.pendingTransaction = undefined;
    this.documents = [];
    this.minimumEffectiveDate = undefined;
    this.lastSubmittedDate = undefined;
    this.originalInceptionDate = undefined;
    this.openItems = [];
    this.clausesDeductibles = [];
  }

  refreshHeader(proposalEntity: ProposalEntity) {
    this.resetHeader();
    this.transType = proposalEntity.transType;
    this.proposalStatus = proposalEntity.proposalStatus;
    this.makerUserId = proposalEntity.makerUserId;
    this.checkerUserId = proposalEntity.checkerUserId;
    this.quoteNumber = proposalEntity.quoteNumber;
    this.policyNumber = proposalEntity.policyNumber;
    this.customerMappingId = proposalEntity.customerMappingID;
    this.customerMappingAddressID = proposalEntity.customerMappingAddressID;
    this.auxiliary = proposalEntity.auxiliary;
    this.loanRedeemed = proposalEntity.loanRedeemed;
    this.documents = proposalEntity.downloadableDocuments;
    this.checkerUserName = proposalEntity.checkerUserName;
    this.makerUserName = proposalEntity.makerUserName;
    this.openItems = proposalEntity.openItems;
    this.clausesDeductibles = proposalEntity.clausesDeductiblesTable;
    this.copyProcessFor = proposalEntity.copyProcessFor;

    if (common.isDefined(proposalEntity.endorsementType)) {
      this.endorsementType = proposalEntity.endorsementType;
    }

    if (common.isDefined(proposalEntity.addtionalEndorsementType)) {
      this.addtionalEndorsementType = proposalEntity.addtionalEndorsementType;
    }

    if (common.isDefined(proposalEntity.pendingTransaction)) {
      this.pendingTransaction = proposalEntity.pendingTransaction;
    }

    if (common.isDefined(proposalEntity.minimumEffectiveDate)) {
      this.minimumEffectiveDate = proposalEntity.minimumEffectiveDate;
    }

    if (common.isDefined(proposalEntity.lastSubmittedDate)) {
      this.lastSubmittedDate = proposalEntity.lastSubmittedDate;
    }

    if (common.isDefined(proposalEntity.originalInceptionDate)) {
      this.originalInceptionDate = proposalEntity.originalInceptionDate;
    }
  }

  /** Not mandatory. Just a quick way to sync read only */
  abstract refreshQuestionsReadOnly(proposalEntity: ProposalEntity): any;

  /** * To refresh answers, certain properties in QuestionEntity
   * Normally only called once, which is the first time the proposal page is loaded. */
  abstract refreshQuestions(proposalEntity: ProposalEntity): any;

  /** To refresh calculated premiums returned from back end */
  abstract refreshPremiumSummary(proposalEntity: ProposalEntity): any;

  /** Extract all questions from all sections into an array */
  abstract extractQuestionArray(): QuestionEntity[];
}
