<div *ngIf="tabRequested" id="premiumPeriodHistoryTab">
  <div *ngIf="tabShow">
    <div class="row" id="premiumPeriodHistoryTabContainer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 card card-mod cardNoBorder ng-scope">
            <kendo-expansionpanel
              id="premium_summary_panel"
              title="Premium Period History"
              [expanded]="true"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      id="IBGPreSumm"
                      class="table-outter"
                      style="padding: 1px"
                    >
                      <!-- <div id="IBGPreSumm" class="table-outter" style="padding:1px; overflow:auto;"></div> -->
                      <table
                        class="table table-bordered table-hover nowrap marginTop10 cursorNormal borderNone"
                      >
                        <thead>
                          <tr>
                            <td
                              class="textAlignCenter chubbGray_bg chubbWhite [textContent]ing"
                              colspan="9"
                              [textContent]="poiText"
                            ></td>
                          </tr>
                          <tr class="chubbGray_bg chubbWhite">
                            <th class="textAlignLeft" style="width: 40%">
                              Effective Date
                            </th>
                            <th class="textAlignLeft">Expiry date</th>
                            <th class="textAlignCenter">No of Days</th>
                            <th class="textAlignCenter">Transaction</th>
                            <th class="textAlignCenter">Plan Type</th>
                            <th class="textAlignCenter">
                              Discount given by Bank
                            </th>
                            <th class="textAlignCenter">
                              Nett Premium billed to Customer
                            </th>
                            <th class="textAlignCenter">
                              Nett Comm Offset with Discount
                            </th>
                            <th class="textAlignCenter">
                              Nett Premium to Chubb
                            </th>
                          </tr>
                          <tr
                            *ngIf="
                              !(
                                proposalService.proposal.auxiliary['qt']
                                  .length > 0 &&
                                proposalService.proposal.auxiliary['qt'][0]
                                  .premiumPeriodHistory.length > 0
                              )
                            "
                          >
                            <td colspan="9">
                              <div style="height: 30px">
                                <div class="margin5 textAlignCenter">
                                  <span class="loadingBarText"
                                    >No data found.</span
                                  >
                                </div>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody
                          class="ng-scope"
                          *ngIf="
                            proposalService.proposal.auxiliary['qt'].length >
                              0 &&
                            proposalService.proposal.auxiliary['qt'][0]
                              .premiumPeriodHistory.length > 0
                          "
                        >
                          <tr
                            *ngFor="
                              let premiumPeriodHistory of proposalService
                                .proposal.auxiliary['qt'][0]
                                .premiumPeriodHistory
                            "
                          >
                            <td class="paddingLeft30">
                              <span
                                [textContent]="
                                  premiumPeriodHistory.proposalStartEffectiveDate
                                    | date : 'MM/dd/yyyy'
                                "
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="
                                  premiumPeriodHistory.policyExpiryDate
                                    | date : 'MM/dd/yyyy'
                                "
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="
                                  premiumPeriodHistory.totalPolicyInsuredDays
                                "
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="premiumPeriodHistory.transType"
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="premiumPeriodHistory.planType"
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="
                                  premiumPeriodHistory.premiumDiscount
                                    | ausCurrency : 2
                                "
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="
                                  premiumPeriodHistory.netBilledPremium
                                    | ausCurrency : 2
                                "
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="
                                  premiumPeriodHistory.netCommissionOffsetWithDiscount
                                    | ausCurrency : 2
                                "
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="
                                  premiumPeriodHistory.netPremiumToChubb
                                    | ausCurrency : 2
                                "
                              ></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row floatRight">
                  <div class="col-md-12 fontBold" style="font-size: 11px">
                    <em>all amounts in SGD unless otherwise specified.</em>
                  </div>
                </div>
              </div>
            </kendo-expansionpanel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
