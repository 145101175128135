import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "./dal/api.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ClausesDeductiblesService extends ApiService {
  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  public searchClausesAndWarrantiesListing(
    querystring: string,
    lastPath = "getHaseEndorsementList"
  ) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      environment.serviceBase + "api/product/haseClause/" + lastPath, //this.url,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public getAllHaseList(querystring: string, lastPath = "getHaseAllList") {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      environment.serviceBase + "api/product/haseClause/" + lastPath, //this.url,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public createHaseEndorsementClause(querystring: string) {
    return this.searchClausesAndWarrantiesListing(
      querystring,
      "createHaseEndorsementClause"
    );
  }

  public updateHaseEndorsementClause(querystring: string) {
    return this.searchClausesAndWarrantiesListing(
      querystring,
      "updateHaseEndorsementClause"
    );
  }

  public deleteHaseEndorsementClause(querystring: string) {
    return this.searchClausesAndWarrantiesListing(
      querystring,
      "deleteHaseEndorsementClause"
    );
  }
}
