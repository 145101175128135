import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
//import { promises, resolve } from 'dns';
import { reject } from "lodash";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { UserProfileModel } from "../components/admin/user-profile/user-profile.model";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class UserProfileService extends ApiService {
  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  //     var getOrganisationByGroupType = function (orgGroupType) {
  //         // pageType 1 - from userprofile, pageType 0 - from IntermediaryProfile
  //         // Considered this pageType as optional paramerter in server side code. So from intermediary profile page, no need to pass this as this is an optional field it will take zero.
  //         // From userprofile page, this should be passed with 1
  //         var uri = 'api/organisation/getOrganisationByGroupType/:type';
  //         var params = {
  //             type: orgGroupType,
  //             pageType: 1
  //         };
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     };

  //     var getOrganisationByGroupTypeRetrieveByCountry = function (orgGroupType, countryId) {
  //         // pageType 1 - from userprofile, pageType 0 - from IntermediaryProfile
  //         // Considered this pageType as optional paramerter in server side code. So from intermediary profile page, no need to pass this as this is an optional field it will take zero.
  //         // From userprofile page, this should be passed with 1
  //         var uri = 'api/organisation/getOrganisationByGroupTypeAndCountry/:type';
  //         var params = {
  //             type: orgGroupType,
  //             pageType: 1,
  //             countryId: countryId
  //         };
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     };

  //     var getOrganisationById = function (organisationId) {

  //         var uri = 'api/organisation/get/:organisationId';
  //         var params = {
  //             organisationId: organisationId
  //         };
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     };

  //     var getProductsByOrganisation = function (organisationId,countryId) {
  //         var uri = 'api/userProfile/getProductsByOrganisation/:organisationId';
  //         var params = {
  //             organisationId: organisationId
  //         };

  //         var data = {};
  //         return Api.get(uri, params, data);
  //     };

  //     //DBS Admin Screen Enable Changes
  //     //Products By Country And Organisation
  //     var getProductsByOrganisationAndCountry = function (organisationId,countryId) {

  //         var params = 'organisationId=' + organisationId + '&countryID=' + countryId;
  //         var uri = 'api/userProfile/getProductsByOrganisationAndCountry?'+params;
  //         params = {};
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     };

  //     //DBS Admin Screen Enable Changes
  //     //Roles API Call
  //     var getRoles = function (selectedProducts,selectedUserType) {
  //         var params = 'selectedProducts=' + selectedProducts +'&userType=' + selectedUserType;
  //         var uri = 'api/userProfile/getRoles?'+params;
  //         params = {};
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     };

  //     var populateOrganisation = function (orgGroupType, countryId) {
  //         var deferred = $q.defer();

  //         getOrganisationByGroupType(orgGroupType, countryId)
  //             .then(function (result) {
  //                 var data = [];
  //                 angular.forEach(result.items, function (item) {
  //                     if(item.i != '1')//FP007222 Additional Work - Restrict Super Admin Organisation Selection For System Maintenance User
  //                     {
  //                         data.push({
  //                             value: item.i,
  //                             text: item.o
  //                         })
  //                     }
  //                 });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });

  //         return deferred.promise;
  //     };

  //     var populateOrganisationRetrieveByCountry = function (orgGroupType, countryId) {
  //         var deferred = $q.defer();

  //         getOrganisationByGroupTypeRetrieveByCountry(orgGroupType, countryId)
  //             .then(function (result) {
  //                 var data = [];
  //                 angular.forEach(result.items, function (item) {
  //                     data.push({
  //                         value: item.i,
  //                         text: item.o
  //                     })
  //                 });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });

  //         return deferred.promise;
  //     };

  //     var populateOrganisationById = function (orgId) {

  //         var deferred = $q.defer();

  //         getOrganisationById(orgId)
  //             .then(function (orgType) {
  //                 var data = {
  //                     orgName: orgType.OrganisationDescription,
  //                     orgId: orgType.OrganisationID,
  //                     orgGroupId: orgType.OrganisationGroupID
  //                 }

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });

  //         return deferred.promise;
  //     };

  //     var populateProductsByOrganisation = function (orgId,countryId) {
  //         var deferred = $q.defer();
  //         getProductsByOrganisation(orgId,countryId)
  //             .then(function (products) {
  //                 var data = [];
  //                 angular.forEach(products.items, function (item) {
  //                     data.push({
  //                         value: item.i,
  //                         text: item.o
  //                     })
  //                 });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });
  //         return deferred.promise;
  //     };
  //     //DBS Admin Screen Enable Changes
  //     //Promise To Call Products By Country And Organisation API
  //     var populateProductsByOrganisationAndCountry = function (orgId,countryId) {
  //         var deferred = $q.defer();
  //         getProductsByOrganisationAndCountry(orgId,countryId)
  //             .then(function (products) {
  //                 var data = [];
  //                 angular.forEach(products.items, function (item) {
  //                     data.push({
  //                         value: item.i,
  //                         text: item.o
  //                     })
  //                 });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });
  //         return deferred.promise;
  //     };
  //     //DBS Admin Screen Enable Changes
  //     //Promise To Call Roles API
  //     var populateRoles = function (selectedProducts,selectedUserType) {
  //         var deferred = $q.defer();
  //         getRoles(selectedProducts,selectedUserType)
  //             .then(function (roles) {
  //                 var data = [];
  //                 angular.forEach(roles.items, function (item) {
  //                     data.push({
  //                         value: item.i,
  //                         text: item.o
  //                     })
  //                 });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });
  //         return deferred.promise;
  //     };

  //     //FP007222  start
  //     //TeamId Changes
  //     //Promise To Call UserTeams API
  //     var populateUserTeam = function () {
  //         var deferred = $q.defer();
  //         getUserTeam()
  //             .then(function (teams) {
  //                 var data = [];
  //                 angular.forEach(teams.items, function (item) {
  //                     data.push({
  //                         value: item.i,
  //                         text: item.o
  //                     })
  //                 });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });
  //         return deferred.promise;
  //     };

  //     //TeamId Changes
  //     //UserTeams API Call
  //     var getUserTeam = function () {
  //         var params = '';
  //         var uri = 'api/userProfile/getUserTeam';
  //         params = {};
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     };
  //     //FP007222  end

  //     var saveUser = function (userModel) {
  //         var deferred = $q.defer();
  //         saveUserDetails(userModel)
  //             .then(function (data) {
  //                 // var data = [];
  //                 //angular.forEach(products.items, function (item) {
  //                 //    data.push({
  //                 //        value: item.i,
  //                 //        text: item.o
  //                 //    })
  //                 // });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });
  //         return deferred.promise;
  //     };

  //     var saveUserDetails = function (model) {
  //         var uri = 'api/userProfile/add/';
  //         var params = {};
  //         var data = {};

  //         return Api.search(uri, params, model);
  //     }

  //     var getMarketerTeamDetails = function () {

  //         var uri = 'api/userProfile/getMarketerTeamList/';
  //         var params = {};
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     };

  //     var populateMarketerTeamDetails = function () {
  //         var deferred = $q.defer();

  //         getMarketerTeamDetails()
  //             .then(function (data) {
  //                 //var data = [];
  //                 //angular.forEach(data.MarketerTeamModel, function (item) {
  //                 //    data.push({
  //                 //        value: item.MarketerTeamId,
  //                 //        text: item.TeamDescriptions
  //                 //    })
  //                 //});

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });

  //         return deferred.promise;
  //     };

  //     var getAllProductList = function () {

  //         var uri = 'api/misc/getItems/:type';
  //         var params = {
  //             type: Enums.multiOptions.PRODUCTS_ALL_LIST
  //         };
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     };

  //     var populateAllProducts = function () {
  //         var deferred = $q.defer();

  //         getAllProductList()
  //             .then(function (model) {
  //                 var data = [];
  //                 angular.forEach(model.items, function (item) {
  //                     data.push({
  //                         value: item.ProductId,
  //                         text: item.ProductDescription
  //                     })
  //                 });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });

  //         return deferred.promise;
  //     };

  //     var getAllOrganisationsByProducts = function (productsByOrganisation) {

  //         //var uri = 'api/organisation/getAllOrganisationsByProducts/:products';
  //         var uri = 'api/organisation/getAllOrganisationsByProducts/';
  //         var params = {};
  //         var data = {};

  //         return Api.search(uri, params, productsByOrganisation);
  //     };

  //     var populateOrganisationByProducts = function (productList) {
  //         var deferred = $q.defer();

  //         var productsByOrganisation = [];
  //         angular.forEach(productList, function (item) {
  //             productsByOrganisation.push({
  //                 value: item.value,
  //                 text: item.text
  //             })
  //         });

  //         getAllOrganisationsByProducts(productsByOrganisation)
  //             .then(function (model) {
  //                 var data = [];
  //                 angular.forEach(model.items, function (item) {
  //                     data.push({
  //                         value: item.i,
  //                         text: item.o
  //                     })
  //                 });

  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });

  //         return deferred.promise;
  //     };

  //     var saveDetails = function (model) {
  //         var uri = 'api/userProfile/saveProfileDetails/';
  //         var params = {};
  //         var data = {};

  //         return Api.search(uri, params, model);
  //     }
  //     var saveProfileDetails = function (detailsModel) {
  //         var deferred = $q.defer();
  //         saveDetails(detailsModel)
  //             .then(function (data) {
  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });
  //         return deferred.promise;
  //     };

  //     var getDetails = function () {
  //         var uri = 'api/userProfile/getUserProfileDetails';
  //         var params = {};
  //         var data = {};

  //         return Api.get(uri, params, data);
  //     }
  //     var getProfileDetailsByUserId = function () {
  //         var deferred = $q.defer();
  //         getDetails()
  //             .then(function (data) {
  //                 deferred.resolve(data);

  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });
  //         return deferred.promise;
  //     };

  //     var getAllOrganisationByBranch = function (organisationByBranch) {

  //         //var uri = 'api/organisation/getAllOrganisationsByProducts/:products';
  //         var uri = 'api/organisation/getAllOrganisationsByBranch/';
  //         var params = {};
  //         var data = {};

  //         return Api.search(uri, params, organisationByBranch);
  //     };

  //     var resetPasswordApi = function (resetPasswordModel) {
  //         var uri = 'api/login/resetPassword/';
  //         var params = {};
  //         return Api.search(uri, params, resetPasswordModel);
  //     }
  //     var resetPassword = function (resetPasswordModel) {
  //         var deferred = $q.defer();

  //         resetPasswordApi(resetPasswordModel)
  //             .then(function (data) {
  //                 deferred.resolve(data);
  //             }, function (err) {
  //                 console.log(err);
  //                 deferred.reject(err);
  //             });
  //         return deferred.promise;
  //     };

  //     return {

  //         getOrganisationByGroupType: getOrganisationByGroupType,
  //         getOrganisationByGroupTypeRetrieveByCountry: getOrganisationByGroupTypeRetrieveByCountry,
  //         populateOrganisation: populateOrganisation,
  //         populateOrganisationById: populateOrganisationById,
  //         populateOrganisationRetrieveByCountry: populateOrganisationRetrieveByCountry,
  //         getOrganisationById: getOrganisationById,
  //         populateProductsByOrganisation: populateProductsByOrganisation,
  //         getProductsByOrganisation: getProductsByOrganisation,
  //         populateProductsByOrganisationAndCountry: populateProductsByOrganisationAndCountry,//DBS Admin Screen Enable Changes
  //         getProductsByOrganisationAndCountry: getProductsByOrganisationAndCountry,
  //         populateRoles: populateRoles,//END
  //         populateUserTeam:populateUserTeam,//FP007222 Changes
  //         saveUserDetails: saveUserDetails,
  //         saveUser: saveUser,
  //         populateMarketerTeamDetails: populateMarketerTeamDetails,
  //         getMarketerTeamDetails: getMarketerTeamDetails,
  //         getAllProductList: getAllProductList,
  //         populateAllProducts: populateAllProducts,
  //         getAllOrganisationsByProducts: getAllOrganisationsByProducts,
  //         populateOrganisationByProducts: populateOrganisationByProducts,
  //         saveProfileDetails: saveProfileDetails,
  //         getProfileDetailsByUserId: getProfileDetailsByUserId,
  //         getAllOrganisationByBranch: getAllOrganisationByBranch,
  //         resetPassword : resetPassword,

  //         checkUserLogin: function (userLogin) {

  //             var uri = 'api/userProfile/checkUserLogin/';
  //             var params = {};
  //             var data = {
  //                 userName: userLogin
  //             };

  //             return Api.search(uri, params, data);
  //         },
  //         getMarketerTeam: function () {

  //             var uri = 'api/userProfile/getMarketerTeamList/';
  //             var params = {};
  //             var data = {};

  //             return Api.get(uri, params, data);
  //         },

  //         saveMarketer: function (marketerModel) {
  //             var uri = 'api/marketer/saveMarketer/';
  //             var params = {};
  //             var data = {};

  //             return Api.add(uri, params, data);
  //         },

  //         searchUserList: function (searchText) {
  //             var uri = 'api/userProfile/searchUsers/';
  //             var params = {};
  //             var data = {
  //                 searchText: searchText
  //             };

  //             return Api.search(uri, params, data);
  //         },

  //         searchUserProfileByUserId: function (userId) {
  //             var uri = 'api/userProfile/searchUserProfileByUserId/';
  //             var params = {};
  //             var data = {
  //                 userId: userId
  //             };

  //             return Api.search(uri, params, data);
  //         },

  //         getAllCountryISO2s: function () {

  //             var uri = 'api/userProfile/getAllCountryISO2s';
  //             var params = {};
  //             var data = {};

  //             return Api.get(uri, params, data);
  //         },

  //         getAllMarketerTeamManagers: function () {
  //             var uri = 'api/userProfile/getAllMarketerTeamManagers';
  //             var params = {};
  //             var data = {};

  //             return Api.get(uri, params, data);
  //         },

  //         addNewMarketerTeam: function (model) {
  //             var uri = 'api/marketer/addNewMarketerTeam/';
  //             var params = {};
  //             var data = {};

  //             return Api.search(uri, params, model);
  //         },

  //         getAllUnderwriterControlGroups: function () {

  //             var uri = 'api/userProfile/getUnderwriterControlGroups';
  //             var params = {};
  //             var data = {};

  //             return Api.get(uri, params, data);
  //         },

  //         getAllUnderwriterControlGroupsByCountry: function (country) {

  //             var uri = 'api/userProfile/getUnderwriterControlGroupsByCountry/:country';
  //             var params = {
  //                 country: country
  //             };
  //             var data = {};

  //             return Api.get(uri, params, data);
  //         },

  //         getGroupDetails: function (groupId) {

  //             var uri = 'api/userProfile/getUnderwriterControlGroupDetails/:groupId';
  //             var params = {
  //                 groupId: groupId
  //             };
  //             var data = {}

  //             return Api.get(uri, params, data);
  //         },
  //         //DBS Admin Screen Enable Changes
  //         //Added This Code To Unlock The User
  //         unlockUser: function (userId) {

  //             var uri = 'api/userProfile/unlockUser/:userId';
  //             var params = { userId: userId };
  //             var data = {}

  //             return Api.get(uri, params, data);
  //         },
  //         getAccessControlGroups: function () {
  //             var uri = 'api/userProfile/getAccessControlGroups';
  //             var params = {};
  //             var data = {}

  //             return Api.get(uri, params, data);
  //         },

  //         overwriteUnderwriterGroupLimits: function (model) {
  //             var uri = 'api/userProfile/updateLimitDetails/';
  //             var params = {};
  //             var data = {};

  //             return Api.search(uri, params, model);
  //         },

  //         //ADB2C
  //         CheckUniqueEmailIDPhoneNumber: function (userEmailID,usemobileNumber,userOrganisationGroupID,userOrganisationID,isEmail,countryISO) {

  //             var uri = 'api/userProfile/CheckUniqueEmailIDPhoneNumber/';
  //             var params = {};
  //             var data = { userEmailID: userEmailID,userMobileNumber: usemobileNumber,userOrganisationGroupID:userOrganisationGroupID,userOrganisationID:userOrganisationID,isEmail: isEmail,countryISO:countryISO};

  //             return Api.search(uri, params, data);
  //         },

  //         CheckEmailIDInADB2C: function (userEmailID,B2CACCESS_TOKEN) {

  //             var uri = 'api/userProfile/CheckEmailIDInADB2C/';
  //             var params = {};
  //             var data = { userEmailID: userEmailID,b2cToken: B2CACCESS_TOKEN};

  //             return Api.search(uri, params, data);
  //         },

  GenerateUserIdToken(userEmailID: any) {
    var uri = "api/userProfile/GenerateUserGuid/";
    var params = {};
    var data = { userId: userEmailID };

    return this.search(uri, params, data);
  }

  GetUserRolesByEmail(userEmailID: any) {
    var uri = "api/userProfile/GetUserRolesByEmail/";
    var params = {};
    var data = { userEmailID: userEmailID };

    return this.search(uri, params, data);
  }

  SetDefaultUserRoles(userLogin: any) {
    var uri = "api/userProfile/SetDefaultUserRoles/";
    var params = {};
    var data = { userLogin: userLogin };

    return this.search(uri, params, data);
  }
  searchUserList(searchText: any) {
    var uri = "api/userProfile/searchUsers/";
    var params = {};
    var data = {
      searchText: searchText,
    };

    return this.search(uri, params, data);
  }
  searchUserProfileByUserId(userId: any) {
    var uri = "api/userProfile/searchUserProfileByUserId/";
    var params = {};
    var data = {
      userId: userId,
    };
    return this.search(uri, params, data);
  }
  //TeamId Changes
  //UserTeams API Call
  getUserTeam() {
    let uri = "api/userProfile/getUserTeam";
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(uri)
    );
  }
  getAccessControlGroups() {
    let uri = "api/userProfile/getAccessControlGroups";
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(uri)
    );
  }
  getProductsByOrganisationAndCountry(organisationId: any, countryId: any) {
    let uri = "api/userProfile/getProductsByOrganisationAndCountry";
    let params = new HttpParams();
    // Begin assigning parameters
    params = params.append("organisationId", organisationId);
    params = params.append("countryID", countryId);
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(uri),
      params
    );
  }
  getRoles(selectedProducts: any, selectedUserType: any) {
    let uri = "api/userProfile/getRoles";
    let params = new HttpParams();
    // Begin assigning parameters
    params = params.append("selectedProducts", selectedProducts);
    params = params.append("userType", selectedUserType);
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(uri),
      params
    );
  }
  saveUserDetails(model: UserProfileModel) {
    const postData = {
      userDetails: model.userDetails,
      person: model.person,
      subUser: model.subUser,
      userType: model.userType,
    };
    let uri = "api/userProfile/add";
    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(uri),
      postData
    );
  }
  checkUserLogin(userLogin: string) {
    var uri = "api/userProfile/checkUserLogin";
    const postData = {
      userName: userLogin,
    };
    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(uri),
      postData
    );
  }
  CheckEmailIDInADB2C(userEmailID: any, B2CACCESS_TOKEN: any) {
    const postData = {
      userEmailID: userEmailID,
      b2cToken: B2CACCESS_TOKEN,
    };
    let uri = "api/userProfile/CheckEmailIDInADB2C";
    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(uri),
      postData
    );
  }
  getOrganisationByGroupType(orgGroupType: any) {
    var uri = "api/organisation/getOrganisationByGroupType/" + orgGroupType;
    let params = new HttpParams();
    // Begin assigning parameters
    params = params.append("type", orgGroupType);
    params = params.append("pageType", 1);
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(uri),
      params
    );
  }

  //         //DBS Admin Screen Enable Changes
  //         //Different Implementation Tried For Roles API
  //         // getRoles: function () {
  //         //     var uri = 'api/userProfile/getRoles';
  //         //     var params = {};
  //         //     var data = {};

  //         //     return Api.get(uri, params, data);
  //         // },

  //     }
}
