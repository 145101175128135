import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { dlgModelArgs } from "../md-confirmation-message-v2/md-confirmation-message-v2.component";

@Component({
  selector: "app-custom-error",
  templateUrl: "./custom-error.component.html",
  styleUrls: ["./custom-error.component.scss"],
})
export class CustomErrorComponent implements OnInit {
  clientSideErrorTitle = "Client side error thrown";
  serverSideErrorTitle = "Server side error thrown";
  header = "";

  @Input() public opened = false;
  @Input() public error: any;
  @Output() closeDialogEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.opened = true;
    this.header =
      this.error && this.error.error
        ? this.error?.error?.ExceptionMessage
        : this.error;
  }

  public close(status: any) {
    this.opened = false;

    let savParamters: dlgModelArgs = {
      status: status,
      data: "",
    };
    this.closeDialogEvent.emit(savParamters);
  }
}
