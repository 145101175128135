<header class="plain-header">
  <img class="logo" src="assets\Chubb-turquoise.png" alt="chubb logo" />
</header>
<div class="centered-container">
  <form id="requestOtpForm" class="form" [formGroup]="customerLoginForm">
    <br />
    <p class="headline">Verification</p>
    <p class="description">
      To verify your identity, we need to send a One Time Password (OTP) to your
      email address / mobile number registered with Chubb
    </p>
    <p class="description">Please enter your email address/mobile number</p>
    <div class="">
      <kendo-formfield class="mt-0" orientation="horizontal">
        <kendo-label [for]="userEmailMobile"></kendo-label>
        <kendo-textbox
          class="k-text input-container textAlignCenter"
          formControlName="userEmailMobile"
          #userEmailMobile
          [maxlength]="30"
          required
        ></kendo-textbox>
        <kendo-formhint></kendo-formhint>
        <kendo-formerror class="k-text"
          >Email address/Mobile number is required.</kendo-formerror
        >
      </kendo-formfield>
    </div>
    <p class="description">
      Please enter your date of birth (DDMMYY Format, e.g: 241290)
    </p>
    <div class="">
      <kendo-formfield class="mt-0" orientation="horizontal">
        <kendo-label [for]="dob"></kendo-label>
        <kendo-textbox
          class="k-text input-container textAlignCenter"
          formControlName="dob"
          #dob
          [maxlength]="6"
          required
        ></kendo-textbox>
        <kendo-formhint></kendo-formhint>
        <kendo-formerror class="k-text"
          >Date of birth is required.</kendo-formerror
        >
      </kendo-formfield>
    </div>
    <p class="description">
      Please enter the last 4 characters of your NRIC/Passport Number
    </p>
    <div class="">
      <kendo-formfield class="mt-0" orientation="horizontal">
        <kendo-label [for]="last4UniqueId"></kendo-label>
        <kendo-textbox
          class="k-text input-container"
          formControlName="last4UniqueId"
          #last4UniqueId
          [maxlength]="4"
          required
        ></kendo-textbox>
        <kendo-formhint></kendo-formhint>
        <kendo-formerror class="k-text"
          >NRIC/Passport Number is required.</kendo-formerror
        >
      </kendo-formfield>
    </div>
    <div class="button-container">
      <button
        (click)="onSubmit()"
        event="1"
        [disabled]="IsDisabled"
        [class]="{ removePointer: IsDisabled }"
      >
        Request OTP
      </button>
    </div>
    <p id="error" class="error" *ngIf="hasError">{{ msg }}</p>
  </form>
  <!-- </div> -->
  <br />
</div>
<div id="footerdiv" class="" style="margin-left: 11px">
  <footer style="margin-top: 15px">
    <p>{{ footerTitle }}</p>
  </footer>
</div>
