import { Injectable, ViewContainerRef } from "@angular/core";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-enums";
import * as Enums from "./../../../../../framework/enum.shared";
import { MortgageFireHongKongAnswerChangeManager } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-answer-change.manager";
import { MortgageFireHongKongBusinessRulesManager } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-business-rules.manager";
import { MortgageFireHongKongFieldValidationManager } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-field-validation.manager";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHkClausesAndDeductiblesComponent } from "src/app/components/new-product/sme/mortgage-fire/hong-kong/components/mf-hk-clauses-and-deductibles/mf-hk-clauses-and-deductibles.component";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { ProposalActivityService } from "src/app/services/new-product/proposal-activity.service";
import { ProposalService } from "src/app/services/new-product/proposal.service";
import { buttonNotifier } from "src/app/components/shared/notifier/button-notifier";
import { MasterDataService } from "src/app/services/master-data.service";
import { GUID } from "src/app/framework/domain-entity/guid";
import { common } from "src/app/framework/utils/common";
import { ProposalEntity } from "src/app/models/new-product/base/proposal-entity.model";
import { cloner } from "src/app/framework/utils/cloner";
import { proposalServiceNotifier } from "src/app/models/new-product/notifier/proposal-service-notifier";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { apiMapperNotifier } from "src/app/models/new-product/notifier/api-mapper-notifier";
import { apiActionNotifier } from "src/app/models/new-product/notifier/api-action-notifier";
import { ApiMapperNotifierModel } from "src/app/models/new-product/notifier/api-mapper-notifier.model";
import { Router } from "@angular/router";
import { ROUTE_HOME } from "src/app/app.module";
import { proposalHelper } from "src/app/models/new-product/utils/proposal.helper";
import { MortgageFireHongKongProposalPrintService } from "./mf-hk-proposal-print.service";
import { Observable, of } from "rxjs";
import * as Const from "src/app/framework/const.shared";
import { questionNotifier } from "src/app/models/new-product/notifier/question-notifier";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { MdConfirmationMessageWithReasonAttachmentComponent } from "src/app/components/shared/partials/md-confirmation-message-with-reason-attachment/md-confirmation-message-with-reason-attachment.component";
import { HaseProposal } from "src/app/models/new-product/abstract-factory/proposal/hase-proposal";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { BroadcastService } from "src/app/services/broadcast.service";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { Echo } from "src/app/models/new-product/base/echo.model";
import { Echos } from "src/app/models/new-product/base/echos.model";

@Injectable({
  providedIn: "root",
})
export class MfHKProposalService {
  currentAction!: number;
  pageMode!: Enums.ProposalPageMode;
  proposal!: MortgageFireHongKongProposal;
  /** Version of [ProposalEntity] upon page loaded. It's a clone version */
  pageLoadedProposalEntity!: ProposalEntity;
  /** Version of current [ProposalEntity]. It's a clone version */
  proposalEntity!: ProposalEntity;
  /** Version of [ProposalEntity] before any proposal command action. It's a clone version */
  lastPostBackProposalEntity!: ProposalEntity;
  fieldValidationManager!: MortgageFireHongKongFieldValidationManager;
  clausesAndDeductibles!: MfHkClausesAndDeductiblesComponent;
  answerChangeManager!: MortgageFireHongKongAnswerChangeManager;
  businessRulesManager!: MortgageFireHongKongBusinessRulesManager;
  showFieldBlankValidationEchos!: Echos<QuestionEntity>[];
  constructor(
    public identityRolesService: IdentityRolesService,
    private proposalActivityService: ProposalActivityService,
    private proposalService: ProposalService,
    private masterDataService: MasterDataService,
    private router: Router,
    private proposalPrintService: MortgageFireHongKongProposalPrintService,
    private cdkDialogService: CdkDialogService,
    private broadcastService: BroadcastService
  ) {
    apiMapperNotifier.before_entity_to_api_map_notifier$.subscribe(
      this.beforeApiMap
    );
    apiActionNotifier.success_notifier$.subscribe(
      this.apiActionSuccessNotified
    );
    //     this.proposalPrintService = Application.angularInjectionObject<MortgageFireHongKongProposalPrintService>('MortgageFireHongKongProposalPrintService');
  }

  //#region migrate from old angularjs
  /** Method to initialize */
  initialize(proposal: MortgageFireHongKongProposal) {
    this.proposal = proposal;
    this.fieldValidationManager =
      new MortgageFireHongKongFieldValidationManager(this.proposal);
    this.businessRulesManager = new MortgageFireHongKongBusinessRulesManager(
      this.proposal,
      this.identityRolesService
    );
    this.answerChangeManager = new MortgageFireHongKongAnswerChangeManager(
      this.proposal,
      this.fieldValidationManager,
      this.businessRulesManager
    );
    this.showFieldBlankValidationEchos = [];
  }
  // /** Instantiate proposal instance */
  createOrEdit() {
    const proposalIdText = this.proposalActivityService.readProposalId();
    const productIdText = this.proposalActivityService.readProductId();
    const productId = new GUID(productIdText!);
    let proposalId: GUID;
    if (common.isStringNullOrEmpty(proposalIdText!)) {
      proposalId = new GUID();
    } else {
      proposalId = new GUID(proposalIdText!);
    }
    if (proposalId.toString() === common.DEFAULT_GUID) {
      this.pageMode = Enums.ProposalPageMode.Create;
      this.proposalService.create(productId).subscribe(this.loaded);
    } else {
      this.pageMode = Enums.ProposalPageMode.Edit;
      this.proposalService.edit(proposalId).subscribe(this.loaded);
    }
  }

  //DBSEP-2136
  ReferValidate(command: Enums.ProposalCommandAction, buttonId: number = 0) {
    var result = true;
    switch (command) {
      case Enums.ProposalCommandAction.Submit:
      case Enums.ProposalCommandAction.Approve:
      case Enums.ProposalCommandAction.Accept:
      case Enums.ProposalCommandAction.Endorse:
      case Enums.ProposalCommandAction.Cancel:
      case Enums.ProposalCommandAction.Renew:
        /** If the mandatory validation fails, raise a failure notification
                  else proceed to call action */
        if (
          command === Enums.ProposalCommandAction.Endorse ||
          command === Enums.ProposalCommandAction.Cancel
        ) {
          this.fieldValidationManager.echos = [];
        } else {
          const echos = this.fieldValidationManager.validate();
        }
        if (this.fieldValidationManager.echos.length <= 0) {
          this.notifyProposalSaved();
          result = true;
        } else {
          if (buttonId > 0) {
            // notify button end event
            buttonNotifier.end_notifier$.next(
              new KeyValue<string>(buttonId.toString(), "end")
            );
          }
          proposalServiceNotifier.failed_notifier$.next(
            new KeyValue(
              this.proposal.proposalId!.toString(),
              this.proposal as Proposal //this.fieldValidationManager.echos //HE-173 : is this wrong all this while
            )
          );

          //HE-173: somehow proposalServiceNotifier.failed_notifier$ is dead after save-with-client>Refer (maybe dispose? typecast error?),
          //hence use broadcastService.onFailedNotifier
          let kv = new KeyValue(
            this.proposal.proposalId!.toString(),
            this.proposal as Proposal
          );
          this.broadcastService.onProposalFailedNotifier.next({ kv });
          result = false;
        }
        break;
      default:
    }
    return result;
  }

  referAction(
    command: Enums.ProposalCommandAction,
    buttonId: number = 0,
    msg: string
  ) {
    if (command === Enums.ProposalCommandAction.Submit) {
      /** For saving the proposal-
       * [Client Name] is mandatory
       * If the mandatory validation fails, raise a failure notification
       * else proceed to call action */
      const validateSave = this.fieldValidationManager.validateSave();
      if (validateSave.length <= 0) {
        this.doReferAction(command, msg);
      } else {
        if (buttonId > 0) {
          // notify button end event
          buttonNotifier.end_notifier$.next(
            new KeyValue<string>(buttonId.toString(), "end")
          );
        }
        proposalServiceNotifier.failed_notifier$.next(
          new KeyValue(this.proposal.proposalId!.toString(), validateSave)
        );
      }
    }
  }

  action(command: Enums.ProposalCommandAction, buttonId: number = 0) {
    switch (command) {
      case Enums.ProposalCommandAction.Save:
      case Enums.ProposalCommandAction.Recalculate:
      case Enums.ProposalCommandAction.ReturnForRevise:
      case Enums.ProposalCommandAction.Decline:
      case Enums.ProposalCommandAction.MakeRedundant:
      case Enums.ProposalCommandAction.Copy:
      case Enums.ProposalCommandAction.CancellationAdjustment:
      case Enums.ProposalCommandAction.EditDeclinedProposal:
      case Enums.ProposalCommandAction.ChangeOfCorrespondenceAddress: 
      case Enums.ProposalCommandAction.EditQuotation:
        if (command === Enums.ProposalCommandAction.Save) {
          /** For saving the proposal-
           * [Client Name] is mandatory
           * If the mandatory validation fails, raise a failure notification
           * else proceed to call action */
          const validateSave = this.fieldValidationManager.validateSave();
          if (this.proposal.proposalStatus == Enums.ProposalStatus.Incomplete) {
            const echosDeliveryMethod =
              this.fieldValidationManager.validateDeliverMethod();
          }
          this.showFieldBlankValidationEchos = [];
          if(this.proposal.endorsementType == Enums.EndorsementType.LoanRedemption)
            this.validateLRMortgageLoanAcc(command);
          if (this.fieldValidationManager.echos.length <= 0 && this.showFieldBlankValidationEchos.length <= 0) {
            this.doAction(command);
          } else {
            const echoMessage = validateSave.length > 0 ? this.fieldValidationManager.echos : this.showFieldBlankValidationEchos;
            if (buttonId > 0) {
              // notify button end event
              buttonNotifier.end_notifier$.next(
                new KeyValue<string>(buttonId.toString(), "end")
              );
            }
            proposalServiceNotifier.failed_notifier$.next(
              new KeyValue(this.proposal.proposalId!.toString(), echoMessage)
            );
          }
        } //HE-2277: Fix alert message prompt when copy from LR policy
        else if(command === Enums.ProposalCommandAction.Copy){
            this.showFieldBlankValidationEchos = [];
            if(this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum2 != null && this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum3 != null){
              this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum2.theme = Enums.QuestionTheme.Normal;
              this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum3.theme = Enums.QuestionTheme.Normal;
            }            
            this.doAction(command);
        } else {            
          this.doAction(command);
        }
        break;
      case Enums.ProposalCommandAction.SendToChecker:
      case Enums.ProposalCommandAction.Submit:
      case Enums.ProposalCommandAction.Approve:
      case Enums.ProposalCommandAction.Accept:
      case Enums.ProposalCommandAction.Endorse:
      case Enums.ProposalCommandAction.Cancel:
      case Enums.ProposalCommandAction.Renew:
        /** If the mandatory validation fails, raise a failure notification
            else proceed to call action */
        if (
          command === Enums.ProposalCommandAction.Endorse ||
          command === Enums.ProposalCommandAction.Cancel
        ) {
          this.fieldValidationManager.echos = [];
        } else {
          const echos = this.fieldValidationManager.validate();
        }
        if (this.proposal.proposalStatus == Enums.ProposalStatus.Incomplete) {
          const echosDeliveryMethod =
            this.fieldValidationManager.validateDeliverMethod();
        }
        if (
          (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB ||
            this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB) &&
          (this.proposal.isUnderwriter || this.proposal.isBroker) &&
          this.proposal.auxiliary["isPremiumManualOverridden"] &&
          (this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete ||
            this.proposal.proposalStatus === Enums.ProposalStatus.Referred)
        ) {
          const premiumSummaryEchos =
            this.fieldValidationManager.validateCBGTotalChargeAgainstGrossAndGST();
        }
        ///*2022-08-30 dagoh0: APACDM-363 : validate change plan type endorsement selected plan type cannot be same as existing proposal's plan type*/
        //if ((this.proposal.productType === EnumSG.MortgageFireSgProduct.HDB ||
        //    this.proposal.productType === EnumSG.MortgageFireSgProduct.PTE ||
        //    this.proposal.productType === EnumSG.MortgageFireSgProduct.MIP) &&
        //    this.proposal.endorsementType === Enums.EndorsementType.ChangeOfPlanType) {
        //    const planTypeEchos = this.fieldValidationManager.validateCBGSelectedPlanType(this.pageLoadedProposalEntity.getQuestion('qs_master_planType').answer);
        //}
        this.showFieldBlankValidationEchos = [];
        if(this.proposal.endorsementType == Enums.EndorsementType.LoanRedemption && command == Enums.ProposalCommandAction.Submit)
          this.validateLRMortgageLoanAcc(command);
        if (this.fieldValidationManager.echos.length <= 0 && this.showFieldBlankValidationEchos.length <= 0) {
          this.doAction(command);
        } else {
          const echoMessage = this.fieldValidationManager.echos.length > 0 ? this.fieldValidationManager.echos : this.showFieldBlankValidationEchos;
          if (buttonId > 0) {
            // notify button end event
            buttonNotifier.end_notifier$.next(
              new KeyValue<string>(buttonId.toString(), "end")
            );
          }
          proposalServiceNotifier.failed_notifier$.next(
            new KeyValue(
              this.proposal.proposalId!.toString(),
              echoMessage
            )
          );
        }
        break;
      default:
    }
    // notify to update rules
    this.notifyProposalSaved();
  }

  validateLRMortgageLoanAcc(command: Enums.ProposalCommandAction){
    const questions = this.proposal
    .extractQuestionArray()
    .filter(
      (x) =>
        x.key === "qs_otherinfo_MortgLoanAcctNum2" ||
        x.key === "qs_otherinfo_MortgLoanAcctNum3" 
    );
    const echos: Echo<QuestionEntity>[] = [];
    
    var q2 = questions.filter(
      (x) => x.key === "qs_otherinfo_MortgLoanAcctNum2"
    )[0];
    var q3 = questions.filter(
      (x) => x.key === "qs_otherinfo_MortgLoanAcctNum3"
    )[0];
    
    q2.theme = common.isStringNullOrEmpty(this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum2?.answer) ? Enums.QuestionTheme.Normal : Enums.QuestionTheme.Danger;
    q3.theme = common.isStringNullOrEmpty(this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum3?.answer) ? Enums.QuestionTheme.Normal : Enums.QuestionTheme.Danger;

    if(!common.isStringNullOrEmpty(this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum2?.answer)){
      echos.push(
        proposalHelper.buildQuestionEntityEcho(
          "Please remove Mortgage Loan Account Numbers 2 and 3 to proceed with the Loan Redemption.",
          q2
        )
      );
    }else if(!common.isStringNullOrEmpty(this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum3?.answer)){
      echos.push(
        proposalHelper.buildQuestionEntityEcho(
          "Please remove Mortgage Loan Account Numbers 2 and 3 to proceed with the Loan Redemption.",
          q3
        )
      );
    }
    if(echos.length > 0)
      this.showFieldBlankValidationEchos.push(new Echos<QuestionEntity>("", echos));
  }

  doReferAction(command: Enums.ProposalCommandAction, msg: any) {
    const proposalEntity = this.constructProposalEntityForServer();
    //HE-28
    proposalEntity.addtionalEndorsementType = 2;
    //todo: check if this still needed
    //const deffer = this.$q.defer<boolean>();
    this.proposalService
      .action(Enums.ProposalCommandAction.Save, proposalEntity, false)
      .subscribe((returnEntity) => {
        this.proposal.proposalId = returnEntity.proposalId;
        //HE-28
        this.proposal.addtionalEndorsementType =
          returnEntity.addtionalEndorsementType;
        var formData = {
          proposalId: this.proposal.proposalId!.toString(),
          isUnderwriterInternalComment: false,
          productId: this.proposal.productId.toString(),
          printCorrespondence: "",
          message: msg,
          tabClicked: command,
          selectedPrintType: {
            label: "Mail",
            value: "1",
          },
          isPasswordProtected: false,
          preferredDeliveryMethod:
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer,
          emailId:
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!
              .answer,
          mobileNo:
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!
              .answer,
          contactInfo:
            this.proposal.qsMaster
              .qs_master_preferredDeliveryMethod_CorrAddress!.answer,
        };

        const v1DialogRef = this.cdkDialogService.open(
          MdConfirmationMessageWithReasonAttachmentComponent,
          {
            data: formData,
          }
        );

        v1DialogRef.afterClosed().subscribe((result: any) => {
          // Subscription runs after the dialog closes
          if (result) {
            this.confirmationModalCloseForActions(result);
          }
        });
      });
  }

  confirmationModalCloseForActions = (result: any): void => {
    if (result.status === "yes") {
      if (
        result.data.tabClicked == Enums.ProposalCommandAction.Submit ||
        result.data.tabClicked == Enums.ProposalCommandAction.Approve
      ) {
        this.proposal.printType = result.data.selectedPrintType;
        this.proposal.printCorrespondence = result.data.printCorrespondence;
        this.proposal.isPasswordProtected = result.data.isPasswordProtected;
        this.proposal.isReprint = false;
        this.proposal.isSummary = false;
        this.proposal.isManual = false;
        this.doAction(result.data.tabClicked);
      }
    }
  };

  doAction(command: Enums.ProposalCommandAction) {
    //HES-64-To revert the Disable the Button fix
    const proposalEntity = this.constructProposalEntityForServer();
    //todo: check if this still needed
    //const deffer = this.$q.defer<boolean>();
    this.proposalService
      .action(command, proposalEntity)
      .subscribe((returnEntity) => {
        this.proposal.proposalId = returnEntity.proposalId;
        if (
          (returnEntity.validationsMessages == undefined ||
            returnEntity.validationsMessages.length == 0) &&
          this.proposal.printType != undefined &&
          (returnEntity.proposalStatus == Enums.ProposalStatus.Accepted ||
            returnEntity.proposalStatus == Enums.ProposalStatus.Approved ||
            returnEntity.proposalStatus == Enums.ProposalStatus.Renewed)
        ) {
          this.sendPrintOptions(command).subscribe(
            (response: any) => {
              //deffer.resolve(response);
              this.saved(returnEntity);
            },
            function (error: any) {
              //deffer.reject(error);
            }
          );
        } else {
          this.saved(returnEntity);
        }
        if (command == Enums.ProposalCommandAction.Copy) {
          this.updateFinancialData(true);
        }
        this.resetPrintType();
        //return deffer.promise;
        //HE-1058 make clauses and deductibles flag as true on save
        this.proposal.qsMaster.qs_master_clausesflag!.answer = "true";
      });
  }

  sendPrintOptions(command: Enums.ProposalCommandAction): Observable<any> {
    if (
      command === Enums.ProposalCommandAction.Submit ||
      command === Enums.ProposalCommandAction.Approve ||
      command === Enums.ProposalCommandAction.Accept
    ) {
      return this.proposalPrintService.postPrintOptions(this.proposal);
    }
    return of(true);
  }

  exit() {
    this.router.navigate(["/" + ROUTE_HOME]);
  }

  constructProposalEntityForServer(): ProposalEntity {
    // extract policy level answers
    this.lastPostBackProposalEntity =
      proposalHelper.constructProposalPackageEntityForServer(
        this.proposal,
        this.proposalService.loadedProposalEntity,
        (postBackData: ProposalEntity) => {
          // no implementations yet
        }
      );
    return this.lastPostBackProposalEntity;
  }

  /** Method to refresh all necessary entities/items that returned from back end upon page load */
  private loaded = (response: ProposalEntity) => {
    // keep the original version of loaded proposalEntity
    this.pageLoadedProposalEntity = cloner.cloneDeep(response);
    // refresh answer that retrieved from back end
    this.proposal.refreshQuestions(response);
    this.saved(response, false);
    this.proposal.refreshQuestionsReadOnlyForcbsOps(response);
    this.fieldValidationManager =
      new MortgageFireHongKongFieldValidationManager(this.proposal);
    this.businessRulesManager = new MortgageFireHongKongBusinessRulesManager(
      this.proposal,
      this.identityRolesService
    );
    this.updateFinancialData(false);
    this.notifyProposalLoaded();
  };

  private updateFinancialData(isCopy: boolean) {
    let isAdded = false;
    let option: any = [];
    if (isCopy != true) {
      this.proposal.qsMaster.qs_master_financialBranch!.options.forEach(
        (x: any) => {
          if (
            x.value === this.proposal.qsMaster.qs_master_financialBranch!.answer
          ) {
            isAdded = true;
          }
        }
      );
    }
    else{
      this.proposal.checkDiscountEligibility('Copy');
    }
    if (!isAdded) {
      this.masterDataService.financialInstitutionBranchesfullList.forEach(
        (i) => {
          if (i.isActive === "True") {
            option.push(new KeyValue(i.value, i.key));
          }
          if (
            i.isActive != "True" &&
            i.key ===
              this.proposal.qsMaster.qs_master_financialBranch!.answer &&
            isCopy != true
          ) {
            option.push(new KeyValue(i.value, i.key));
          }
        }
      );
      this.proposal.qsMaster.qs_master_financialBranch!.options = option;   
    }
  }

  private saved = (response: ProposalEntity, notifySaved: boolean = true) => {
    // set current version of [ProposalEntity]
    this.proposalEntity = response;
    // refresh proposal header data
    this.proposal.refreshHeader(response);
    // refresh answer that might potentially changed by back-end
    this.proposal.refreshPotentiallyChangedQuestions(response);
    // refresh calculated premium summary
    this.proposal.refreshPremiumSummary(response);
    // initialize custom mandatory field
    this.fieldValidationManager.initializeMandatoryFields();
    // high level of setting read only to questions
    this.proposal.refreshQuestionsReadOnly(response);
    // custom readonly
    this.proposal.refreshCustomQuestionsReadOnly(response);
    // refresh validation message
    this.proposal.refreshValidationMessage(response);

    // TODO: custom readonly if available
    if (notifySaved) {
      this.notifyProposalSaved();
    }
  };

  private resetPrintType = (): void => {
    this.proposal.printType = undefined;
    this.proposal.printCorrespondence = undefined;
    this.proposal.isReprint = undefined;
    this.proposal.isSummary = undefined;
    this.proposal.isManual = undefined;
    this.proposal.isPasswordProtected = undefined;
  };

  private apiActionSuccessNotified = (response: KeyValue<ProposalEntity>) => {
    //has to filter by country's products now
    if (new HaseProposal().isKeyValueProposalEntityAssignable(response)) {
      const responseEntity = response.value;
      if (responseEntity.proposalStatus === Enums.ProposalStatus.Replaced) {
        this.router.navigate(["/" + ROUTE_HOME]);
      } else {
        this.update(response.value);
      }
    }
  };

  private notifyProposalLoaded() {
    proposalServiceNotifier.loaded_notifier$.next(
      new KeyValue(this.proposal.proposalId!.toString(), this.proposal)
    );
  }

  public notifyProposalSaved() {
    proposalServiceNotifier.saved_notifier$.next(
      new KeyValue(this.proposal.proposalId!.toString(), this.proposal)
    );
  }

  private update(proposalEntity: ProposalEntity) {
    this.proposal.refreshPremiumSummary(proposalEntity);
  }
  private beforeApiMap = (event: ApiMapperNotifierModel) => {
    //has to filter by country's products now
    if (new HaseProposal().isApiMapperNotifierModelAssignable(event)) {
      event.proposalEntity.customerMappingID = this.proposal.customerMappingId;
      event.proposalEntity.customerMappingAddressID =
        this.proposal.customerMappingAddressID;
      event.proposalEntity.endorsementType = this.proposal.endorsementType;
      event.proposalEntity.auxiliary = this.proposal.auxiliary;
      event.proposalEntity.originalInceptionDate =
        this.proposal.originalInceptionDate;
      event.proposalEntity.lastSubmittedDate = this.proposal.lastSubmittedDate;
      event.proposalEntity.clausesDeductiblesTable =
        this.proposal.clausesDeductibles;
    }
  };
  //#endregion

  isFinancialEndorsementOrNbOrCan() {
    return this.businessRulesManager.isFinancialEndorsementOrNbOrCan();
  }

  isOnlyFinancialEndorsement() {
    return this.businessRulesManager.isOnlyFinancialEndorsement();
  }

  compareQuestionAnswer(ques: QuestionEntity) {
    return this.businessRulesManager.compareQuestionAnswer(ques);
  }

  getVCR(): ViewContainerRef {
    return this.proposalService.getVCR();
  }

  setVCR(value: ViewContainerRef) {
    this.proposalService.setVCR(value);
  }
}
