import { Component, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";

@Component({
  selector: "app-adb2c-login",
  templateUrl: "./adb2c-login.component.html",
  styleUrls: ["./adb2c-login.component.scss"],
})
export class Adb2cLoginComponent implements OnInit {
  constructor(private msalService: MsalService) {}

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  login() {
    // handle auth redired/do all initial setup for msal
    this.msalService.instance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        const account = this.msalService.instance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          this.msalService.instance.loginRedirect();
        }
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
    // this.msalService.instance.loginRedirect();
    // this.msalService.loginPopup().subscribe((Response : AuthenticationResult) =>{
    //   this.msalService.instance.setActiveAccount(Response.account);
    // })
  }

  logout() {
    this.msalService.instance.logout();
  }

  ngOnInit(): void {
    this.login();

    // this.msalService.instance.handleRedirectPromise().then(
    //   res=> {
    //     if(res !=null && res.account !=null){
    //       this.msalService.instance.setActiveAccount(res.account);
    //     }
    //   }
    // )
  }
}
