import { Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild, 
} from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";

@Component({
    selector: "app-preset-report",
    templateUrl: "./preset-report.component.html",
    styleUrls: ["./preset-report.component.scss"],
})
export class PresetReportComponent extends BaseComponent implements OnInit{
@Output() warningZoneEvent = new EventEmitter<any>();
public reportOptions!: Array<{ text: string, value: number }>;
presetReportType!: number;
constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
){
    super(router, broadcastService, identityRolesService);
}
override ngOnInit():void{
    super.ngOnInit();
    this.initReportOptions();
    this.defaultSearchCriteria();

}
private initReportOptions() {

    // report dropdownlist options
    const reportOptions =
        [{ text: 'Daily NB Download', value: 1 }
            , { text: 'Daily EN Download', value: 2 }
            , { text: 'Daily CA Download', value: 3 }
            , { text: 'Bank Trans', value: 4 }
            , { text: 'Manual Paid Auto Debit', value: 5 }
            , { text: 'Renewal Draft Deletion', value: 6 }
            , { text: 'Renewal Summary Report', value: 7 }
            , { text: 'IBG Renewal Report', value: 8 }
            , { text: 'Summary of Invoice - Auto Debit', value: 9 }
            // , { text: 'Summary of Invoice - Free Insurance', value: 10 }
            , { text: 'Daily Transaction Document Print Report', value: 11 }
            , { text: 'Datapost Document Print Report', value: 12 }
            , { text: 'Auto Debit Text File', value: 13 }
            , { text: 'Auto Debit Extraction Report', value: 14 }
            , { text: 'Auto Update Extraction Report', value: 15 }
            , { text: 'IBG Reinsurance Report', value: 16 }
            , { text: 'Loan Redemption Report - Success', value: 17 }
            , { text: 'Loan Redemption Report - Failure', value: 18 }
            , { text: 'Citi Bank - Summary of weekly Invoice', value: 19 }
            , { text: 'Citi Bank - Monthly Report', value: 20 }
        ];

    /*2022-08-18 dagoh0: APACDM-1660 - Only allow Operation UnderWriter have the access */
    if (!this.identityRolesService.isOperationUnderwriter)
    {
        const indexOfObject = reportOptions.findIndex((object) => {
            return object.value ===  20;
          });

        if (indexOfObject > -1) {
            reportOptions.splice(indexOfObject, 1);
        }            
    }

    if(this.identityRolesService.isMaker == true || this.identityRolesService.isChecker == true)
    {
        const indexOfObject = reportOptions.findIndex((object) => {
            return object.value ===  19;
          });

        if (indexOfObject > -1) {
            reportOptions.splice(indexOfObject, 1);
        }            
    }

    // sort by text asc
    reportOptions.sort(function (x, y) {
        return x.text > y.text ? 1 :
            x.text < y.text ? -1 :
                0;
    });

    this.reportOptions = reportOptions;

    // const documentChannels =
    //     [{ text: 'All', value: 0 }
    //         , { text: 'DP link CBG', value: 1 }
    //         , { text: 'DP link IBG', value: 2 }
    //         , { text: 'DP link Reprint', value: 3 }
    //         , { text: 'Email', value: 4 }
    //         , { text: 'Both Email and Mail', value: 5 }
    //         , { text: 'No Print', value: 6 }
    //         , { text: 'Not Sent', value: 7 }
    //         , { text: 'SMS', value: 8 }]
    //     ;

    // this.documentChannels = documentChannels;

    // const transactionOption =
    //     [
    //         { text: 'New Business', value: 1 }
    //         , { text: 'Endorsement', value: 2 }
    //         , { text: 'Cancellation', value: 3 }
    //         , { text: 'Renewal', value: 4 }
    //         , { text: 'Loan Redemption', value: 5 }
    //     ];

    // this.transactionTypeOption = transactionOption;
}
private defaultSearchCriteria(): void {
    // default to first option
    this.presetReportType = this.reportOptions[0].value;
    //this.on_reportType_change();
}
public on_reportType_change(val:any): void {
    switch (this.presetReportType) {
        case 1:
        case 2:
        case 3:
            // target = '#cbgDailyDownloadFields';
            // this.searchCriteria = new CbgDailyDownloadSearchCriteria();
            // this.defaultCbgDailyDownloadSearchCriteria();

            break;
        case 4:
            // target = '#bankTransFields';
            // this.searchCriteria = new BankTransSearchCriteria();
            // this.defaultBankTransSearchCriteria();
            // var isMaker = instanceFactory.getIdentity().role.isMaker;
            // var isChecker = instanceFactory.getIdentity().role.isChecker;
            // if(isMaker == true || isChecker == true)
            // {
            //     this.productRadioControl = 'dbs';
            //     this.onChange_ProductRadio();
            // }
            // else
            // {
            //     this.showReportType = true;
            // }
            
            break;
        case 5:
            // target = '#manualPaidAutoDebitFields';
            // this.searchCriteria = new ManualPaidAutoDebitSearchCriteria();
            // this.defaultManualPaidAutoDebitSearchCriteria();
            break;
        case 6:
            // target = '#renewalDraftDeletionReportFields';
            // this.searchCriteria = new RenewalDraftDeletionSearchCriteria();
            // this.defaultRenewalDraftDeletionReportSearchCriteria();
            break;
        case 7:
            // target = '#renewalReportFields';
            // this.searchCriteria = new RenewalReportSearchCriteria();
            // this.defaultRenewalReportSearchCriteria();
            break;
        case 8:
            // target = '#IBGrenewalReportFields';
            // this.searchCriteria = new IBGRenewalReportSearchCriteria();
            // this.defaultIBGRenewalReportSearchCriteria();
            break;
        case 9:
            // target = '#summaryInvoiceAutoDebitFields';
            // this.searchCriteria = new SummaryInvoiceAutoDebitSearchCriteria();
            // this.defaultSummaryInvoiceAutoDebitSearchCriteria();
            break;
        case 10:
            // target = '#summaryInvoiceFreeInsuranceFields';
            // this.searchCriteria = new SummaryInvoiceFreeInsuranceSearchCriteria();
            // this.defaultSummaryInvoiceFreeInsuranceSearchCriteria();
            break;
        case 11:
            // target = '#documentPrintReportFields';
            // this.searchCriteria = new PrintDocumentSearchCriteria();
            // this.defaultPrintDocumentSearchCriteria();
            // var isMaker = instanceFactory.getIdentity().role.isMaker;
            // var isChecker = instanceFactory.getIdentity().role.isChecker;
            // if(isMaker == true || isChecker == true)
            // {
            //     this.productRadioControl = 'dbs';
            //     this.onChange_ProductRadio();
            // }
            // else
            // {
            //     this.showReportType = true;
            // }
            break;
        case 12:
            // target = '#dataPostPrintReportFields';
            // this.searchCriteria = new DataPostPrintSearchCriteria();
            // this.defaultDataPostPrintSearchCriteria();
            // var isMaker = instanceFactory.getIdentity().role.isMaker;
            // var isChecker = instanceFactory.getIdentity().role.isChecker;
            // if(isMaker == true || isChecker == true)
            // {
            //     this.productRadioControl = 'dbs';
            //     this.onChange_ProductRadio();
            // }
            // else
            // {
            //     this.showReportType = true;
            // }
            break;
        case 13:
            // target = '#autoDebitTextFileFields';
            // this.searchCriteria = new AutoDebitExtractionSearchCriteria();
            // this.defaultAutoDebitExtractionSearchCriteria();
            break;
        case 14:
            // target = '#autoDebitExtractionFields';
            // this.searchCriteria = new AutoDebitExtractionSearchCriteria();
            // this.defaultAutoDebitExtractionSearchCriteria();
            break;
        case 15:
            // target = '#autoUpdateExtractionFields';
            // this.searchCriteria = new AutoUpdateExtractionSearchCriteria();
            // this.defaultAutoUpdateExtractionSearchCriteria();
            break;
        case 16:
            // target = '#LSTReportFields';
            // this.searchCriteria = new LSTSearchCriteria();
            // this.defaultLSTReportSearchCriteria();
            break;
        case 17:
        case 18:
            // target = '#loanRedemptionReportFields';
            // this.searchCriteria = new LoanRedemptionSearchCriteria();
            // this.defaultLoanRedemptionSearchCriteria(this.presetReportType);
            // this.loadLoanRedemptionGrid();
            // var isMaker = instanceFactory.getIdentity().role.isMaker;
            // var isChecker = instanceFactory.getIdentity().role.isChecker;
            // if(isMaker == true || isChecker == true)
            // {
            //     this.productRadioControl = 'dbs';
            //     this.onChange_ProductRadio();
            // }
            // else
            // {
            //     this.showReportType = true;
            // }
            break;
        case 19:
            // target = '#citiWeeklysummaryInvoice';
            // this.searchCriteria = new CitiWeeklyInvoiceSearchCriteria();
            // this.defaultCitiWeeklyInvoiceSearchCriteria();
            break;
        case 20:
            // target = '#citiMthlyReport';
            // this.searchCriteria = new CitiMonthlyReportSearchCriteria();
            // this.defaultCitiMonthlyReportSearchCriteria();
            // this.productRadioControl = 'citi';
            // this.onChange_ProductRadio();
            //this.isCitiProduct = true;
            //this.fetchUserList();
            break;
    }



}
public passWarningZoneEvent(warningParamters:any):void{
    this.warningZoneEvent.emit(warningParamters);
}
}