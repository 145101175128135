import { MortgageFireHongKongProposal } from "../../sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { AbstractMaster } from "./abstract-master";
import * as Enum from "src/app/framework/enum.shared";
import * as Const from "./../../../../framework/const.shared";
import { IdentityRolesService } from "src/app/services/identity-roles.service";

export class HaseMaster implements AbstractMaster {
  public showClientSearch(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
        return this.showClientSearchByWpb(identityRolesService, proposal);
      // case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING:
      //   return this.showClientSearchByWpb(proposal);
      default:
        return this.showClientSearchByCmb(proposal);
    }
  }

  private showClientSearchByWpb(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    if (identityRolesService.isBroker) {
      if (
        proposal.transType === Enum.TransType.NEW &&
        proposal.proposalStatus === Enum.ProposalStatus.Incomplete
      ) {
        return true; //NB
      }
    } else if (identityRolesService.isUnderwriter) {
      if (
        proposal.transType === Enum.TransType.NEW &&
        (proposal.proposalStatus === Enum.ProposalStatus.Incomplete ||
          proposal.proposalStatus === Enum.ProposalStatus.Referred)
      ) {
        return true; //NB with referred
      }
    }
    return false;
  }

  private showClientSearchByCmb(
    proposal: MortgageFireHongKongProposal
  ): boolean {
    if (
      proposal.transType === Enum.TransType.NEW &&
      proposal.proposalStatus === Enum.ProposalStatus.Incomplete
    ) {
      return true; //NB
    }
    return false;
  }

  public showManualUnderwriting(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
        return this.showManualUnderwritingByWpb(identityRolesService, proposal);
      // case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING:
      //   return this.showManualUnderwritingByCmb(identityRolesService, proposal);
      default:
        return this.showManualUnderwritingByCmb(identityRolesService, proposal);
    }
  }

  private showManualUnderwritingByWpb(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    if (
      //identityRolesService.isBroker || //HE-28: only uw can perform manual-uw
      identityRolesService.isUnderwriter
    ) {
      return true;
    } else {
      return false;
    }
  }

  private showManualUnderwritingByCmb(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    if (identityRolesService.isBroker || identityRolesService.isUnderwriter) {
      return true;
    } else {
      return false;
    }
  }
}
