import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";

@Component({
  selector: "checkbox-question",
  templateUrl: "./checkbox-question.component.html",
  styleUrls: ["./checkbox-question.component.scss"],
})
export class CheckboxQuestionComponent implements OnInit {
  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;
  @Input() public question!: QuestionEntity;

  @ViewChild("qs_checkbox", { static: false })
  public qs_checkbox: any;

  constructor() {}

  ngOnInit(): void {}

  compareQuestionAnswer(ques: QuestionEntity) {
    return (
      ques!.answer == 1 || ques!.answer === "True" || ques!.answer === true
    );
  }

  public qs_onChange = (data: any): void => {
    if (data === "False") {
      this.updateNativeElement(this.qs_checkbox, true);
      this.question!.answer = "True";
    } else {
      this.updateNativeElement(this.qs_checkbox, false);
      this.question!.answer = "False";
    }
  };

  updateNativeElement(ele: any, value: boolean) {
    if (ele !== undefined) {
      ele.nativeElement.checked = value;
    }
  }
}
