import * as Enums from "./../../../framework/enum.shared";
import * as InterfaceProduct from "./../../../framework/interface.product";

import { BaseMapper } from "./../../product/baseMapper";
import { ExceptionManager } from "./../../../framework/utils/exception-manager";
import { GUID } from "./../../../framework/domain-entity/guid";
import { Global } from "./../../../shared/global";
import { KeyValue } from "./../../../framework/domain-entity/key-value";
import { QuestionEntity } from "./../base/question-entity.model";
import { common } from "./../../../framework/utils/common";

export class QuestionEntityMapper extends BaseMapper<
  QuestionEntity,
  InterfaceProduct.IUiControl
> {
  map(question: QuestionEntity): InterfaceProduct.IUiControl {
    const answer = question.answer;

    // 14: DateRangeQuestion
    if (question.controlType === Enums.UiControlType.DateRangeQuestion) {
      const dr = JSON.parse(question.answer.toString());

      const dateFrom = new Date(dr["df"]);
      const dateTo = new Date(dr["dt"]);

      dr["dft"] = dateFrom.toApiValue();
      dr["dtt"] = dateTo.toApiValue();

      question.answer = JSON.stringify(dr);
    }

    const control: InterfaceProduct.IUiControl = {
      /** v. Value to the answer */
      v: Global.convertToApiStandardValue(answer), // <== utilizing toApiValue() prototype
      /** k. Question name */
      k: question.key,
    };
    return control;
  }

  reverseMap(uiControl: InterfaceProduct.IUiControl): QuestionEntity {
    /**
         * ****************************************************************************
         * Control Type ID  Control Type	            Front end return data type
         * ****************************************************************************
            1	            LabelQuestion
            7	            ValueQuestion	                string
            19	            SingleCheckboxQuestion	        boolean
            9	            DateTimeQuestion	            date
            34	            NumericAndLabelQuestion	        numeric
            13	            DropDownListQuestion	        string
            6	            TrueFalseQuestion	            string
         */
    let answerDataType: Enums.AnswerDataType = Enums.AnswerDataType.String;
    let answerToAssigned: any = uiControl.v;

    switch (uiControl.c) {
      case Enums.UiControlType.LabelQuestion:
        break;

      case Enums.UiControlType.ValueQuestion:
      case Enums.UiControlType.DropDownListQuestion:
      case Enums.UiControlType.TrueFalseQuestion:
        answerDataType = Enums.AnswerDataType.String;
        break;

      case Enums.UiControlType.SingleCheckboxQuestion:
        answerDataType = Enums.AnswerDataType.Boolean;
        break;

      case Enums.UiControlType.DateTimeQuestion:
        answerDataType = Enums.AnswerDataType.Date;
        if (uiControl.v) {
          answerToAssigned = new Date(uiControl.v);
        }
        break;

      case Enums.UiControlType.CurrencyPremiumValueQuestion:
      case Enums.UiControlType.NumericAndLabelQuestion:
        answerDataType = Enums.AnswerDataType.Numeric;
        break;

      default:
      //todo
      //throw new ExceptionManager.incomplete("Control type not supported yet");
    }

    /** k. Question name */
    /** v. Value to the answer */
    const question = new QuestionEntity(
      uiControl.k!,
      answerToAssigned,
      answerDataType
    );
    /** dv. Default answer */
    question.defaultAnswer = uiControl.dv;
    /** ro. Read only flag */
    question.readOnly = Global.toBoolean(uiControl.ro!);
    /** t. Text to display */
    question.displayText = uiControl.t;
    /** c. Question Control type */
    question.controlType = uiControl.c;
    /** i. Question additional info. Usually contains multi select options */
    question.additionalInfo = uiControl.i;
    /** m. Value text maximum length */
    question.maxLength = uiControl.m;
    /** mv. Value maximum allowed amount */
    question.maxValue = uiControl.mv;
    /** mnv. Value minimum allowed amount */
    question.minValue = uiControl.mnv;
    /** r. Flag to determine if answer is mandatory */
    question.required = Global.toBoolean(uiControl.r!);
    /** nt. Question  */
    question.numberText = uiControl.nt;
    /** qpid. QuestionPanelId */
    question.questionPanelId = new GUID(uiControl.qpid);
    /** sid. Question section  */
    question.sectionId = uiControl.sid;
    question.isIgnore = uiControl.ig;

    if (Global.isDefined(question.additionalInfo)) {
      const info = JSON.parse(question.additionalInfo);
      if (common.isDefined(info.o)) {
        const options = eval(info.o);
        if (Global.isDefined(options)) {
          // temporay fix
          question.options = [
            {
              key: "",
              value: "",
            },
          ];
          options.forEach((option: { v: any; k: any }) => {
            if (common.isDefined(question.options)) {
              question.options.push({
                key: option.v,
                value: option.k,
              });
            }
          });
        }
      }
    }
    return question;
  }
}
