import * as Enums from "./../../enum.shared";

import { Exception } from "./exception";
import { common } from "./../../utils/common";

/** Common Exception object to be thrown for argument invalid exception */
export class ArgumentInvalidException extends Exception {
  constructor(argument: any, argumentName: string, message?: string) {
    super("");
    this.type = Enums.ErrorExceptionType.ArgumentException;

    if (common.isDefined(message) && message != undefined) {
      this.message = message;
    } else {
      this.message = `Invalid argument [${argumentName}] of type [${typeof argument}] of value [${argument}]`;
    }
  }
}
