import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { TodoPolicyListingEntity } from "src/app/components/new-product/home/todo-list/TodoPolicyListingEntity";
import { DialogRef } from "../dialog/dialog-ref";
import { DIALOG_DATA } from "../dialog/dialog-tokens";
import { ProposalEntity } from "./ProposalEntity";
import * as Enum from "./../../../../framework/enum.shared";

export type WindowSize = "sm" | "md" | "lg"; //"350" | "650" | "1250"

@Component({
  selector: "app-md-confirmation-message-v6",
  templateUrl: "./md-confirmation-message-v6.component.html",
  styleUrls: ["./md-confirmation-message-v6.component.scss"],
})
export class MdConfirmationMessageV6Component implements OnInit {
  model: ProposalEntity[] = [];

  @Input() public opened = false;
  public command: any;
  public gridData: TodoPolicyListingEntity[] = [];

  public postdataList: BulkActionModel[] = [];
  public printTypeOptions!: Array<{ text: string; value: number }>;
  public isValid = true;
  public warning_message = false; //existing bug; should be based on row
  public confirmLabel = "";

  public dlgWidth: WindowSize = "md";
  public titlebarTitle = "";
  public titlebarCssClass = "";

  @Output() closeDialogEvent = new EventEmitter<any>();

  selectedPrintType: any;
  showData!: boolean;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: v6DlgInputArgs
  ) {}

  ngOnInit(): void {
    const printTypeOptions = [
      { text: "Email", value: 2 },
      { text: "SMS", value: 5 },
      { text: "Mail", value: 1 },
      { text: "No Action Required", value: 4 },
    ];
    this.printTypeOptions = printTypeOptions;
    this.dlgWidth = this.inputData.dialogWidth;
    this.command = this.inputData.command;
    this.gridData = this.inputData.gridData;

    if (this.command === 6 || this.command === 9) {
      this.titlebarTitle = "Confirmation";
      this.titlebarCssClass = "modal-title-red";
    } else if (this.command === 7 || this.command === 3) {
      this.titlebarTitle = "Print Option Confirmation";
      this.titlebarCssClass = "modal-title-orange";
    }

    this.createFormModel();
  }

  public getWindowSize() {
    switch (this.dlgWidth) {
      case "sm":
        return 350;
      case "md":
        return 650;
      default:
        return 1250;
    }
  }

  createFormModel() {
    for (var i = 0; i < this.gridData?.length; i++) {
      var proposalEntity = new ProposalEntity();
      proposalEntity.contactInfo = this.gridData[i].contactInfo;
      proposalEntity.emailId = this.gridData[i].emailId;
      proposalEntity.policyNo = this.gridData[i].policyNo;
      proposalEntity.insuredName = this.gridData[i].insuredName;
      proposalEntity.mobileNo = this.gridData[i].mobileNo;
      proposalEntity.quoteNo = this.gridData[i].quoteNo;
      proposalEntity.proposalId = this.gridData[i].proposalId;
      proposalEntity.command = this.command;

      proposalEntity.printType = this.printTypeOptions.filter((item) => {
        return item.value == Number(this.gridData[i].preferredDeliveryMethod);
      })[0].value;

      if (proposalEntity.printType != null && proposalEntity.printType === 2) {
        proposalEntity.printCorrespondence = this.gridData[i].emailId;
      } else if (
        proposalEntity.printType != null &&
        proposalEntity.printType === 5
      ) {
        proposalEntity.printCorrespondence = this.gridData[i].mobileNo;
      } else if (
        proposalEntity.printType != null &&
        proposalEntity.printType === 1
      ) {
        proposalEntity.printCorrespondence = this.gridData[i].contactInfo;
      }
      if (proposalEntity.printType == null) {
        proposalEntity.printType = this.printTypeOptions[1].value;
      }

      proposalEntity.isPasswordProtected = false;
      proposalEntity.DeclineReason = "";
      proposalEntity.printEmailValidation = "";

      this.model.push(proposalEntity);
    }
  }

  validateDeclineReason(dataItem: ProposalEntity) {
    if (dataItem.command == 9) {
      if (dataItem.DeclineReason == "" || dataItem.DeclineReason == null) {
        this.warning_message = true;
        this.confirmLabel = "Please enter the decline reason.";

        return false;
      } else {
        this.warning_message = false;
      }
    }

    return true;
  }

  validateEmail(dataItem: ProposalEntity) {
    var email = dataItem.emailId;
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    var valid = false;
    if (email !== "" && reg.test(email)) {
      dataItem.isInvalidEmailAddress = false;
      valid = true;
      dataItem.printCorrespondence = email;
    } else {
      dataItem.isInvalidEmailAddress = true;
      dataItem.printEmailValidation = "Invalid Email Address!";
      valid = false;
      //todo
      //angular.element('.alertLogin.invalidEmail').fadeIn(500);
      //setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }

    return valid;
  }

  validateMobile(dataItem: ProposalEntity) {
    var mobile = dataItem.mobileNo;
    var reg = /^\+?[0-9]+$/;
    var valid = false;
    if (
      mobile.length == 8 &&
      mobile !== "" &&
      reg.test(mobile) &&
      (mobile.toString()[0] == "9" || mobile.toString()[0] == "8")
    ) {
      dataItem.isInvalidEmailAddress = false;
      valid = true;
      dataItem.printCorrespondence = mobile;
    } else {
      dataItem.isInvalidEmailAddress = true;
      valid = false;
      // angular.element('.alertLogin.invalidEmail').fadeIn(500);
      // setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  validateAddress(dataItem: ProposalEntity) {
    var address = dataItem.contactInfo;
    var valid = false;
    if (address !== "" && address !== null) {
      dataItem.isInvalidEmailAddress = false;
      dataItem.printCorrespondence = address;
      valid = true;
    } else {
      dataItem.isInvalidEmailAddress = true;
      valid = false;
      // angular.element('.alertLogin.invalidEmail').fadeIn(500);
      // setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  confirmation(flag: any): boolean {
    if (flag) {
      for (var i = 0; i < this.model.length; i++) {
        var proposalEntity = new BulkActionModel();
        if (
          this.model[i].printType == 2 &&
          !this.validateEmail(this.model[i])
        ) {
          this.model[i].printEmailValidation = "Invalid Email Address!";
          return false;
        } else if (
          this.model[i].printType == 5 &&
          !this.validateMobile(this.model[i])
        ) {
          this.model[i].printEmailValidation = "Invalid Mobile Number!";
          return false;
        } else if (
          this.model[i].printType == 1 &&
          !this.validateAddress(this.model[i])
        ) {
          this.model[i].printEmailValidation = "Invalid Mail Adress!";
          return false;
        } else {
          this.model[i].isInvalidEmailAddress = false;
          proposalEntity.printCorrespondence = "";
        }
        if (this.model[i].printCorrespondence != "") {
          proposalEntity.printCorrespondence =
            this.model[i].printCorrespondence;
        }

        proposalEntity.proposalId = this.model[i].proposalId;
        proposalEntity.printType = this.model[i].printType.toString();
        proposalEntity.messageId = 0;
        proposalEntity.isReprint = false;
        proposalEntity.isSummary = false;
        proposalEntity.isManual = false;
        proposalEntity.fileIdsJsonString = "";
        proposalEntity.isPasswordProtected = this.model[i].isPasswordProtected;
        proposalEntity.fileIdsJsonString = "";
        proposalEntity.DeclineReason = this.model[i].DeclineReason;
        proposalEntity.command = this.model[i].command;

        if (!this.validateDeclineReason(this.model[i])) {
          return false;
        }

        this.postdataList.push(proposalEntity);
      }
    } else {
      this.warning_message = false;
    }

    // $scope.data.BulkActionModel=postdataList;
    // $scope.data.confirmation = flag;
    // $scope.data.data=$scope.gridData;
    if (!this.warning_message) {
      // $modalInstance.dismiss($scope.data);
      return true;
    }

    return false;
  }

  public close(status: any) {
    if (status === "yes") {
      if (this.confirmation(true)) {
        this.opened = false; //double-confirmation on 'yes' only dismiss
        let savParamters: dlgModelArgs = {
          status: status,
          data: this.postdataList,
        };
        this.closeDialogEvent.emit(savParamters);
        this.dialogRef?.close(savParamters);
        return;
      } else {
        //let it float/modal
        //this.dialogRef?.close();
      }
    } else {
      let savParamters: dlgModelArgs = {
        status: status,
        data: null,
      };
      this.closeDialogEvent.emit(savParamters);
      this.dialogRef?.close(savParamters);
      return;
    }
  }

  public open() {
    this.opened = true;
  }
}

export interface v6DlgInputArgs {
  dialogWidth: WindowSize;
  command: Enum.ProposalCommandAction;
  gridData: TodoPolicyListingEntity[];
}

export interface dlgModelArgs {
  status: string;
  data: any; //ProposalEntity[]
}

export class BulkActionModel {
  proposalId!: string; //GUID;
  printCorrespondence!: string;
  printType!: string; //number;
  isReprint: boolean = false;
  isManual: boolean = false;
  isSummary: boolean = false;
  isPasswordProtected: boolean = false;
  messageId!: number;
  fileIdsJsonString!: string;
  DeclineReason!: string;
  command!: number;
}
