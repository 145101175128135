<div
  class="bs-callOut bs-callOut-info customCallOutAlignment"
  id="callout-type-dl-truncate"
  *ngIf="
    proposalService.proposal.pendingTransaction != null &&
    proposalService.proposal.proposalStatus != 6 &&
    proposalService.proposal.proposalStatus != 18
  "
>
  <h5>
    <b class="text-danger"
      >A Pending {{ proposalPendingStatus }} Transaction was found for the
      policy
      <u>{{ proposalService.proposal.policyNumber }}</u>
    </b>
  </h5>
  <div>
    <p>
      Please
      <b>
        <a style="cursor: pointer" (click)="viewPendingTransaction()"
          >click Here</a
        >
      </b>
      to proceed to Pending Transaction.
    </p>
  </div>
</div>
