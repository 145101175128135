import { ErrorHandler, Injectable } from "@angular/core";
import { DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { CustomErrorComponent } from "src/app/components/shared/partials/custom-error/custom-error.component";
import { common } from "src/app/framework/utils/common";
//import { common } from "src/app/core/extensions/framework/utils/common";
import { DialogWrapperService } from "../dialog/dialog-wrapper.service";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    protected dialog: DialogWrapperService, //Debugger, appSettings
    private dialogService: DialogService
  ) {}

  handleError(error: any): void {
    // const dialogRef: DialogRef = this.dialogService.open({
    //   title: "Error thrown",
    //   // Show component
    //   content: CustomErrorComponent,
    //   actions: [{ text: "Ok", themeColor: "primary" }],
    //   width: 650,
    //   height: 400,
    // });
    // const err = dialogRef.content.instance as CustomErrorComponent;
    // err.error = error;
  }

  handle(
    error: {
      toString?: any;
      status?: any;
      data?: any;
      fromErrorHandler?: any;
      config?: { headers: { Authorization: string } };
    },
    isShow: boolean,
    errorTitle: any
  ) {
    // if error message is error page
    if (
      common.isDefined(error) &&
      !common.isObject(error) &&
      error.toString().toLowerCase().indexOf("<!doctype html>") == 0
    ) {
      this.dialog.error(errorTitle, error);
    }
    // error
    else {
      this.removeAuthorizationHeader(error);
    }

    var showErrDialog = true;
    if (common.isDefined(isShow)) {
      showErrDialog = isShow;
    }

    // bad request
    if (error.status == 400) {
      if (common.isDefined(error.data)) error = error.data;
    }

    if (showErrDialog) {
      if (/* environment.releaseMode */ true)
        //todo
        this.dialog.error(
          errorTitle,
          "An error occurred while processing your request.Support staff have been notified."
        );
      else this.dialog.error(errorTitle, error);
    }

    error.fromErrorHandler = true;

    // throw error here for $exceptionHandler to capture
    throw error;
  }

  removeAuthorizationHeader(error: any) {
    if (
      common.isDefined(error) &&
      common.isObject(error) &&
      common.isDefined(error.config) &&
      common.isDefined(error.config.headers)
    ) {
      error.config.headers.Authorization = "";
    }

    return error;
  }
}
