import * as InterfaceProduct from "../../framework/interface.product";
import { Global } from "../../shared/global";
import { ProposalEntity } from "../new-product/base/proposal-entity.model";

/** Base class of all mapper */
export abstract class BaseMapper<TSource, TDestination>
  implements InterfaceProduct.IProposalModelMapper<TSource, TDestination>
{
  abstract map(TSource: TSource | ProposalEntity): TDestination;
  abstract reverseMap(
    TDestination: TDestination | InterfaceProduct.IProposalApiModel
  ): TSource;

  mapList(sourceList: Array<TSource>): Array<TDestination> {
    if (Global.isUndefined(sourceList)) return new Array<TDestination>(); //undefined;

    let returnList: Array<TDestination> = new Array<TDestination>();
    sourceList.forEach((source) => {
      returnList.push(this.map(source));
    });

    return returnList;
  }

  reverseMapList(destinationList: Array<TDestination>): Array<TSource> {
    if (Global.isUndefined(destinationList)) return new Array<TSource>(); //undefined;

    let returnList: Array<TSource> = new Array<TSource>();
    destinationList.forEach((destination) => {
      returnList.push(this.reverseMap(destination));
    });

    return returnList;
  }
}
