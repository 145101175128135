<div
  ng-class="{ running: running }"
  [attr.disabled]="httpRequesting"
  (click)="click()"
>
  <div class="ld" style="top: 40%">
    <i class="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>
  </div>
  <!-- <ng-transclude></ng-transclude> --><ng-content
  ></ng-content>
</div>
