import { AbstractProposal } from "./abstract-proposal";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import * as Enums from "src/app/framework/enum.shared";
import { ApiMapperNotifierModel } from "../../notifier/api-mapper-notifier.model";
import * as Const from "src/app/framework/const.shared";
import { ProposalEntity } from "../../base/proposal-entity.model";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";

export class HaseProposal implements AbstractProposal {
  public IsApprovedReadOnly(proposal: ProposalEntity): boolean {
    if (
      proposal.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING || //HE-28
      proposal.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING
    ) {
      return true;
    }

    return false;
  }

  public IsIncompleteReadOnly(identityRolesService: IdentityRolesService) {
    switch (identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
      case Enums.UserType.Broker:
        if (identityRolesService.getIdentity().role.IsHASEUser) {
          return false;
        }

        return true;
      default:
        return true;
    }
  }

  public isApiMapperNotifierModelAssignable(
    dataObject: ApiMapperNotifierModel
  ): boolean {
    return (
      common.isUndefinedOrNull(dataObject) || //if undefined when first init; has to let it pass-thru since unknown
      (common.isDefined(dataObject) &&
        common.isUndefinedOrNull(dataObject.proposalEntity)) || //if undefined when first init; has to let it pass-thru since unknown
      (common.isDefined(dataObject) &&
        common.isDefined(dataObject.proposalEntity) &&
        this.isHaseProposalEntity(dataObject.proposalEntity)) //if defined when init-ed
    );
  }

  public isHaseProposalEntity(proposal: ProposalEntity): boolean {
    return (
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING ||
      proposal!.productId!.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING
    );
  }

  public isKeyValueProposalEntityAssignable(
    dataObject: KeyValue<ProposalEntity>
  ) {
    return (
      common.isUndefinedOrNull(dataObject) || //if undefined when first init; has to let it pass-thru since unknown
      (common.isDefined(dataObject) &&
        common.isUndefinedOrNull(dataObject.value)) || //if undefined when first init; has to let it pass-thru since unknown
      (common.isDefined(dataObject) &&
        common.isDefined(dataObject.value) &&
        this.isHaseProposalEntity(dataObject.value)) //if defined when init-ed
    );
  }
}
