import { Identity } from "src/app/shared/user/identity";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ROUTE_HOME } from "src/app/app-routing.module";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import * as Enums from "./../../../framework/enum.shared";

@Component({
  selector: "app-adb2c-user-type-listing",
  templateUrl: "./adb2c-user-type-listing.component.html",
  styleUrls: ["./adb2c-user-type-listing.component.scss"],
})
export class Adb2cUserTypeListingComponent implements OnInit {
  public isProfileDetailsUpdated: boolean = false;
  public isExternalUser: boolean = false;

  constructor(
    public identityRolesService: IdentityRolesService,
    public identity: Identity,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (
      this.identityRolesService.hasProductReadWriteRoles ||
      this.identityRolesService.hasProductTransactRoles
    ) {
      this.router.navigate(["/" + ROUTE_HOME]);
    } else if (this.identityRolesService.isFinanceAccounting) {
      this.router.navigate(["/openItem"]);
    } else if (this.identity.currentUser().userType == Enums.UserType.Admin) {
      this.router.navigate(["/userProfileList"]);
    }
  }
}
