import { environment } from "src/environments/environment";
import { common } from "./../utils/common";
//import { environment } from "./../../environments/environment";

/** Class to generate GUID
 * https://stackoverflow.com/questions/26501688/a-typescript-guid-class
 */
export class GUID {
  private str: string;
  private id: number;

  private pattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gi;
  private regex: RegExp = new RegExp(this.pattern.source);

  constructor(str?: string) {
    if (str !== undefined && !common.isStringNullOrEmpty(str)) {
      const match = this.regex.test(str);
      if (!match) {
        throw Error(`[${str}] not match GUID pattern`);
      }
    }
    this.str = str || common.DEFAULT_GUID;
    this.str = this.str.trim().toUpperCase();
    this.id = common.generateUniqueId();
  }

  /** Generate a new unique GUID object */
  static newGuid(): GUID {
    // your favorite guid generation function could go here
    // ex: http://stackoverflow.com/a/8809472/188246
    let d = new Date().getTime();

    if (environment.browser) {
      if (
        window["performance"] &&
        typeof window["performance"].now === "function"
      ) {
        d += performance.now(); // use high-precision timer if available
      }
    }

    const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return new GUID(guid);
  }

  get isDefault(): boolean {
    return this.str === common.DEFAULT_GUID;
  }

  toString(): string {
    return this.str;
  }

  toId(): number {
    return this.id;
  }

  equal(object: GUID): boolean {
    return (
      this.str.trim().toUpperCase() === object.toString().trim().toUpperCase()
    );
  }
}
