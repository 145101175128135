import * as MfEnum from "./../../hong-kong/base/mf-hk-enums";

import { ExceptionManager } from "./../../../../../../framework/utils/exception-manager";
import { GUID } from "./../../../../../../framework/domain-entity/guid";
import { mortgageFireHongKongSettings } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/const/mf-hk-const";

class MortgageFireHongKongHelper {
  readProductType(productId: GUID) {
    switch (productId.toString()) {
      case mortgageFireHongKongSettings.wpbProductId.toString():
        return MfEnum.MortgageFireHKProduct.HSWPB;
      case mortgageFireHongKongSettings.cmbProductId.toString():
        return MfEnum.MortgageFireHKProduct.HSCMB;
      default:
        ExceptionManager.error("Unhandled");
        return null;
    }
  }
}

export const mortgageFireHongKongHelper = new MortgageFireHongKongHelper();
