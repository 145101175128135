import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ClientAddressEntity } from "src/app/models/customer/base/client-address-entity.model";
import * as Enum from "src/app/framework/enum.shared";
import { MasterDataService } from "src/app/services/master-data.service";
import { common } from "src/app/framework/utils/common";
import { Culture } from "src/app/shared/date-formatter";
import { ClientService } from "src/app/services/customer/client.service";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import { MfHkBaseComponent } from "../../mf-hk-base.component";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";

@Component({
  selector: "mf-hk-client-view",
  templateUrl: "./mf-hk-client-view.component.html",
  styleUrls: ["./mf-hk-client-view.component.scss"],
})
export class MfHkClientViewComponent
  extends MfHkBaseComponent
  implements OnInit
{
  id!: string;
  clientViewData: any;
  address!: ClientAddressEntity;
  noAnswerDefaultText: string = "Unknown";
  countryList!: any[];
  nationalityList!: any[];
  contactInformation: string = "";
  isStateVisible: boolean = false;
  countryExcludeState: any = ["SG", "HK"];
  clientSalutationDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Capt",
    2: "Dr.",
    3: "Madam",
    4: "Miss",
    5: "Mr",
    6: "Mrs",
    7: "Ms",
    8: "Prof",
    9: "Sir",
    10: "Tan Sir",
    13: "Others",
  };

  clientRaceDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Chinese",
    2: "Indian",
    3: "Malay",
    4: "Others",
  };

  clientGenderDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Male",
    2: "Female",
  };

  clientMaritalStatusDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Single",
    2: "Married",
    3: "Widowed",
    4: "Divorced",
    5: "Separated",
  };

  clientDocumentTypePrefixDictionary: { [key: string]: string } = {
    I: "Hk Id Card",
    P: "Passport",
    B: "Business Registration Document",
    C: "CI",
    X: "Others",
  };

  private dialogOkNotifierSubscription!: Subscription;
  isEditable: boolean = false;
  isProcessing = false;

  constructor(
    private proposalService: MfHKProposalService,
    private clientService: ClientService,
    private masterDataService: MasterDataService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  override ngOnInit(): void {
    if (
      this.proposalService.proposal.proposalStatus ===
        Enum.ProposalStatus.Incomplete &&
      this.proposalService.proposal.transType === Enum.TransType.NEW
    ) {
      this.isEditable = true;
    }

    super.subscribeTabLoadRenderer();
    this.initialize();
    this.dialogOkNotifierSubscription =
      dialogActionNotifier.ok_notifier$.subscribe((event) => {});
  }

  ngOnDestroy() {
    this.unsubscribeRxjs();
    this.dialogOkNotifierSubscription.unsubscribe();
  }

  verifyStateCountry(answer: string) {
    const elementPos = this.countryExcludeState
      .map(function (x: any) {
        return x;
      })
      .indexOf(answer);
    if (elementPos > -1) {
      this.isStateVisible = false;
    } else {
      this.isStateVisible = true;
    }
  }

  getAddressFormat = (input: any) => {
    if (!common.isUndefinedOrNull(input)) {
      return input + " ";
    } else {
      return "";
    }
  };

  getDisplayTextIfUnknown = (inputId: string, question: any) => {
    let displayText: string = "Unknown";
    let array: any = [];
    if (inputId.length > 0) {
      if (question === "country") {
        if (common.isDefined(this.countryList)) {
          array = this.countryList;
        }
      } else {
        if (common.isDefined(this.nationalityList)) {
          array = this.nationalityList;
        }
      }
      if (array.length > 0) {
        const elementPos = array
          .map(function (x: { value: any }) {
            return x.value;
          })
          .indexOf(inputId);
        const objectFound = array[elementPos];
        displayText = objectFound.key;
      }
    }

    return displayText;
  };

  getDateFormat = (date: any, culture: Culture) => {
    const mm = date.substring(5, 7);
    const dd = date.substring(8, 10);
    const yyyy = date.substring(0, 4);

    switch (culture) {
      case Culture.enSg:
        return dd + "/" + mm + "/" + yyyy;

      default:
        throw Error("date culture unhandled");
    }
  };

  private initialize() {
    this.masterDataService
      .getCountryList()
      .subscribe((responseCountry: any[]) => {
        this.countryList = responseCountry;
        this.masterDataService
          .getAllNationalityList()
          .subscribe((responseNationality: any[]) => {
            this.nationalityList = responseNationality;
            this.proposalLoaded();
          });
      });
  }

  //https://stackoverflow.com/questions/34547127/angular2-equivalent-of-document-ready
  ngAfterViewInit() {
    //todo: check if this works
    this.proposalLoaded();
    //https://stackoverflow.com/questions/53767289/angular-error-error-expressionchangedafterithasbeencheckederror-expression-ha
    this.cdRef.detectChanges();
  }

  private proposalLoaded = () => {
    //todo
    // $(document).ready(() => {
    setTimeout(() => {
      this.getClientDetailsLoaded();
    }, 60);
    // });
  };

  private getClientDetailsLoaded = () => {
    this.clientService
      .get(
        this.proposalService.proposal.customerMappingId!,
        this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
      )
      .subscribe((response: any) => {
        this.mapApiToUi(response);
      });
  };

  mapApiToUi(response: any) {
    this.clientViewData = response;
    //if (response.clientType === 1) {
    this.clientViewData.salutationText =
      this.clientSalutationDictionary[response.salutation];
    //dob changes begin
    let editDate: any;
    if (!common.isUndefinedOrNull(this.clientViewData.birthDate)) {
      if (this.clientViewData.birthDate.indexOf("T") > 0) {
        editDate = new Date(
          this.clientViewData.birthDate.replace(/-/g, "/").replace(/T.+/, "")
        );
      } else {
        editDate = new Date(
          this.formatDate(this.clientViewData.birthDate)
            .replace(/-/g, "/")
            .replace(/T.+/, "")
        );
      }

      const year = editDate.getFullYear();
      const month = editDate.getMonth() + 1;
      const day = editDate.getDate();
      var dateofbirth = `${day}/${month}/${year}`;
      this.clientViewData.birthDateFormat = dateofbirth;
    } else {
      this.clientViewData.birthDateFormat = "";
    }
    //dob changes end
    //this.clientViewData.birthDateFormat = Global.isUndefined(this.clientViewData.birthDate) ?
    //  '' : this.getDateFormat(this.clientViewData.birthDate, Culture.enSg);
    this.clientViewData.genderText =
      this.clientGenderDictionary[response.gender];
    this.clientViewData.maritalStatusText =
      this.clientMaritalStatusDictionary[response.maritalStatus];
    this.clientViewData.raceText = this.clientRaceDictionary[response.race];
    if (response.nationality) {
      this.clientViewData.nationalityText = this.getDisplayTextIfUnknown(
        [response.nationality][0],
        "nationality"
      );
    }

    //this.clientViewData.documentType = response.docType;
    this.clientViewData.docType = response.docType; //documentType is for docx word document?
    this.clientViewData.documentTypeText =
      this.clientDocumentTypePrefixDictionary[response.docType];
    //}

    if (response.docType === "B") {
      this.clientViewData.businessRegistrationNumber =
        response.businessRegistrationNumber;
    } else if (response.docType == "C") {
      this.clientViewData.businessRegistrationNumber =
        response.businessRegistrationNumber2;
    }
    this.clientViewData.DBClientReferenceNumber =
      response.dbsClientReferenceNumber;

    this.clientViewData.countryISO2Text = this.getDisplayTextIfUnknown(
      [response.address[0].countryISO2][0],
      "country"
    );
    this.verifyStateCountry([response.address[0].countryISO2][0]);
  }

  formatDate = (date: string) => {
    const dateSplit = date.split("/");
    const newDate = dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2];
    return newDate.replace(/-/g, "/").replace(/T.+/, "");
  };
}
