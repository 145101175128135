import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { LstRateService } from "src/app/services/lst-rate.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { NotifierService } from "src/app/services/notifier.service";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { LstRateEntity } from "./lstRateEntity";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";

@Component({
  selector: "app-last-rate",
  templateUrl: "./lst-rate.component.html",
  styleUrls: ["./lst-rate.component.scss"],
})
export class LstRateComponent implements OnInit {
  public lstRates: LstRateEntity[] = [];

  public confirmationOpened = false;
  confirmationMessage: any;
  confirmationMsg!: string;
  errorLogs!: { Title?: string; Logs?: string[] };
  isDirty: boolean = false;

  @ViewChild("appendTo", {
    read: ViewContainerRef,
    static: false,
  })
  public appendTo!: ViewContainerRef;

  constructor(
    private lstRateService: LstRateService,
    private notifier: NotifierService,
    private cdkDialogService: CdkDialogService
  ) {}

  ngOnInit(): void {
    this.errorLogs = {};
    this.getLstRates();
  }

  getLstRates() {
    this.lstRateService.getLstRates().then((result: any) => {
      if (result) {
        this.lstRates = [];
        result.forEach((oItem: any) => {
          var rItem = this.lstRateService.mapToLstRateEntity(oItem);
          this.lstRates.push(rItem);
        });
        //ascending
        this.lstRates = this.lstRates.sort((a, b) => a.SortOrder - b.SortOrder);
        this.validate();
      }
    });
  }

  public validate = () => {
    this.DirtyCheck();

    var isValid = true;
    this.errorLogs!.Logs = [];
    var sumOfReferencePercentage: number = 0.0;

    this.lstRates.forEach((lstRate, index) => {
      if (
        lstRate.LSTRICode.Value == undefined ||
        lstRate.LSTRICode.Value.toString().trim() == ""
      ) {
        this.errorLogs.Logs?.push(
          "Row: " + lstRate.SortOrder + " - LSTRI Code required."
        );
        isValid = false;
      }
      if (
        lstRate.ReferencePercentage.Value == undefined ||
        lstRate.ReferencePercentage.Value.toString().trim() == ""
      ) {
        this.errorLogs.Logs?.push(
          "Row: " + lstRate.SortOrder + " - Reference Percentage required."
        );
        isValid = false;
      }
      if (
        lstRate.RICommissionPercentage.Value == undefined ||
        lstRate.RICommissionPercentage.Value.toString().trim() == ""
      ) {
        this.errorLogs.Logs?.push(
          "Row: " + lstRate.SortOrder + " - Commission Percentage required."
        );
        isValid = false;
      }
      if (
        lstRate.EffectiveStartDate.Value == undefined ||
        lstRate.EffectiveStartDate.Value.toString().trim() == ""
      ) {
        this.errorLogs.Logs?.push(
          "Row: " + lstRate.SortOrder + " - Effective Start Date required."
        );
        isValid = false;
      }
      if (lstRate.IsExternalParty) {
        sumOfReferencePercentage =
          sumOfReferencePercentage + lstRate.ReferencePercentage.Value;
        sumOfReferencePercentage = Number(
          parseFloat(Number(sumOfReferencePercentage).toFixed(2))
        );
      }
      if (
        (lstRate.LSTRICode.IsDirty ||
          lstRate.ReferencePercentage.IsDirty ||
          lstRate.RICommissionPercentage.IsDirty) &&
        !lstRate.EffectiveStartDate.IsDirty
      ) {
        this.errorLogs.Logs?.push(
          "Row: " +
            lstRate.SortOrder +
            " - Effective Start Date can not be same."
        );
        isValid = false;
      }
    });

    if (sumOfReferencePercentage != 100) {
      this.errorLogs.Logs.push(
        "Sum of Reference percentage of external parties should be 100."
      );
      isValid = false;

      this.lstRates.forEach((lstRate, index) => {
        if (lstRate.IsExternalParty) {
        }
      });
    }
    return isValid;
  };

  public DirtyCheck = () => {
    this.isDirty = false;

    this.lstRates.forEach((lstRate, index) => {
      this.isDirty = lstRate.LSTRICode.CheckDirty() ? true : this.isDirty;
      this.isDirty = lstRate.ReferencePercentage.CheckDirty()
        ? true
        : this.isDirty;
      this.isDirty = lstRate.RICommissionPercentage.CheckDirty()
        ? true
        : this.isDirty;
      this.isDirty = lstRate.EffectiveStartDate.CheckDirty()
        ? true
        : this.isDirty;
    });
  };

  public errorLogCheck = () => {
    let isValid: boolean = false;
    if (this.errorLogs !== undefined && this.errorLogs?.Logs !== undefined) {
      if (this.errorLogs?.Logs?.length > 0) {
        isValid = true;
      }
    }
    return isValid;
  };

  public onConfirmUpdate = () => {
    this.validate();
    if (this.errorLogs?.Logs?.length == 0) {
      const msg =
        "Once you update you can't add any new changes on same date. Are you sure you want to update the LST rates?";
      this.confirmationMsg = msg;
      let v1InputData: v1DlgInputArgs = {
        opened: true,
        dialogWidth: 600,
        data: "",
        message: this.confirmationMsg,
      };

      const v1DialogRef = this.cdkDialogService.open(
        MdConfirmationMessageV1Component,
        {
          data: v1InputData,
        }
      );

      v1DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
          this.confirmationModalCloseForActions(result);
        }
      });
    }
  };

  public on_oiFrom_change = () => {
    this.validate();
  };

  public onCancel = () => {
    this.lstRates.forEach((lstRate, index) => {
      lstRate.LSTRICode.Value = lstRate.LSTRICode.OldValue;
      lstRate.ReferencePercentage.Value = lstRate.ReferencePercentage.OldValue;
      lstRate.RICommissionPercentage.Value =
        lstRate.RICommissionPercentage.OldValue;
      lstRate.EffectiveStartDate.Value = lstRate.EffectiveStartDate.OldValue;

      this.validate();
    });
  };

  public onTabSelect(e: SelectEvent) {}

  async confirmationModalCloseForActions(args: dlgModelArgs) {
    this.confirmationOpened = false;
    if (args.status === "yes") {
      (await this.lstRateService.updateLstRates(this.lstRates)).subscribe(
        (result: any) => {
          if (result.body.data == "Success") {
            this.notifier.success("Update successfully", this.appendTo);
            this.getLstRates();
          } else {
            this.AddGroupErrorMessageBySplitting(result.body.data);
            this.notifier.error("Update failed", this.appendTo);
          }
          this.validate();
        }
      );
    }
  }

  private AddGroupErrorMessageBySplitting(errorMessage: string) {
    this.errorLogs.Logs = [];
    var errorMessages = errorMessage.split(";").reverse();
    errorMessages.forEach((errorMessage) => {
      this.errorLogs?.Logs?.push(errorMessage);
    });
  }
}
