import * as Const from "./../../../../framework/const.shared";
import * as Enums from "./../../../../framework/enum.shared";

import { ExceptionManager } from "./../../../../framework/utils/exception-manager";
import { ProposalEntity } from "src/app/models/new-product/base/proposal-entity.model";
import { common } from "./../../../../framework/utils/common";
import { instanceFactory } from "./../../../../framework/utils/instance-factory";
import { IdentityRolesService } from "../../../identity-roles.service";
import { Injectable } from "@angular/core";
import { AbstractProductFactory } from "src/app/models/new-product/abstract-factory/factory/abstract-product-factory";
import { ProductService } from "../product.service";

@Injectable({
  providedIn: "root",
})
export class ProposalAvailableActionHelperService {
  constructor(
    private identityRolesService: IdentityRolesService,
    public productService: ProductService
  ) {}

  getAvailableActions(proposal: ProposalEntity): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();
    actions.push(Enums.ProposalCommandAction.Exit);
    actions.push(Enums.ProposalCommandAction.Copy);

    /** Current login user is transaction read only user
     * It could be [Claims] or whatever read only user type
     */
    if (
      this.identityRolesService.getIdentity().isProposalTransactionReadOnly ===
      true
    ) {
      // no other available action
    } else {
      switch (proposal.proposalStatus) {
        case Enums.ProposalStatus.Endorsed:
        case Enums.ProposalStatus.Cancelled:
          // do nothing
          break;
        case Enums.ProposalStatus.Renewed:
          const renewed = this.renewed(proposal);
          renewed.forEach((action) => {
            actions.push(action);
          });
          break;

        case Enums.ProposalStatus.Accepted:
          const accepted = this.accepted(proposal);
          accepted.forEach((action) => {
            actions.push(action);
          });
          break;

        case Enums.ProposalStatus.Referred:
          const referred = this.referred(proposal);
          referred.forEach((action) => {
            actions.push(action);
          });
          break;

        // draft
        case Enums.ProposalStatus.None:
        case Enums.ProposalStatus.Incomplete:
          const draft = this.incomplete(proposal);
          draft.forEach((action) => {
            actions.push(action);
          });
          break;

        case Enums.ProposalStatus.PendingChecker:
          const PendingChecker = this.pendingChecker(proposal);
          PendingChecker.forEach((action) => {
            actions.push(action);
          });
          break;

        // draft
        case Enums.ProposalStatus.Declined:
          const declined = this.declined(proposal);
          declined.forEach((action) => {
            actions.push(action);
          });
          break;

        case Enums.ProposalStatus.Approved:
          let productFactory!: AbstractProductFactory;
          productFactory = this.productService.getProductFactoryByProductId(
            proposal!.productId!
          );

          if (productFactory.buttonPanel().ProductAllowApproved(proposal)) {
            const approved = this.approved(proposal);
            approved.forEach((action) => {
              actions.push(action);
            });
          } else {
            ExceptionManager.error(
              "Not to be expected to in [Approved] status"
            );
          }
          break;

        case Enums.ProposalStatus.Replaced:
          const madeRedundant = this.madeRedundant(proposal);
          madeRedundant.forEach((action) => {
            actions.push(action);
          });
          break;

        case Enums.ProposalStatus.Lapsed:
          break;

        default:
          ExceptionManager.error("Unexpected ProposalStatus");
      }
    }
    return actions;
  }

  getAvailableActionsforCbsOps(
    proposal: ProposalEntity
  ): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();
    const isIBG =
      proposal.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
      proposal.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MACH_IBG;
    // This code is CBG specific - CbsOps has access only to the ChangeOfClientInfo & ChangeOfCorrespondenceAddress
    if (
      !isIBG &&
      proposal.proposalStatus === Enums.ProposalStatus.Incomplete &&
      (proposal.endorsementType === Enums.EndorsementType.ChangeOfClientInfo ||
        proposal.endorsementType ===
          Enums.EndorsementType.ChangeOfCorrespondenceAddress)
    ) {
      actions.push(Enums.ProposalCommandAction.Save);
      actions.push(Enums.ProposalCommandAction.Submit);
      actions.push(Enums.ProposalCommandAction.MakeRedundant);
    }

    // This code is IBG specific - CbsOps has access only to GeneralEndorsement
    if (
      proposal.proposalStatus === Enums.ProposalStatus.Incomplete &&
      proposal.endorsementType === Enums.EndorsementType.GeneralEndorsement &&
      isIBG
    ) {
      actions.push(Enums.ProposalCommandAction.Save);
      actions.push(Enums.ProposalCommandAction.Accept);
      actions.push(Enums.ProposalCommandAction.MakeRedundant);
    }

    return actions;
  }

  getAvailableActionforCSCBGSG(
    proposal: ProposalEntity
  ): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();
    const isCBG =
      proposal.productId?.toString().toUpperCase() !==
        Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
      proposal.productId?.toString().toUpperCase() !==
        Const.ProductID.PRODUCT_ID_DBS_MACH_IBG;

    if (
      isCBG &&
      proposal.proposalStatus === Enums.ProposalStatus.Incomplete &&
      proposal.endorsementType === Enums.EndorsementType.ChangeOfClientInfo
    ) {
      actions.push(Enums.ProposalCommandAction.Save);
      actions.push(Enums.ProposalCommandAction.Submit);
      actions.push(Enums.ProposalCommandAction.MakeRedundant);
    }

    return actions;
  }

  // a and b are javascript Date objects
  private dateBeforeDiffInDays(
    todaysDate: {
      getFullYear: () => number;
      getMonth: () => number;
      getDate: () => number | undefined;
    },
    policyExpiryDate: {
      getFullYear: () => number;
      getMonth: () => number;
      getDate: () => number | undefined;
    }
  ) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      todaysDate.getDate()
    );
    const utc2 = Date.UTC(
      policyExpiryDate.getFullYear(),
      policyExpiryDate.getMonth(),
      policyExpiryDate.getDate()
    );

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  // a and b are javascript Date objects
  private dateAfterDiffInDays(
    todaysDate: {
      getFullYear: () => number;
      getMonth: () => number;
      getDate: () => number | undefined;
    },
    policyExpiryDate: {
      getFullYear: () => number;
      getMonth: () => number;
      getDate: () => number | undefined;
    }
  ) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      todaysDate.getDate()
    );
    const utc2 = Date.UTC(
      policyExpiryDate.getFullYear(),
      policyExpiryDate.getMonth(),
      policyExpiryDate.getDate()
    );

    return Math.floor((utc1 - utc2) / _MS_PER_DAY);
  }

  private renewed(proposal: ProposalEntity): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();

    switch (this.identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
        if (
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null &&
          !proposal.auxiliary.IsPreviousYearTerminated &&
          proposal.auxiliary.ReInstateDisplayFlag
        ) {
          actions.push(Enums.ProposalCommandAction.Endorse);
          actions.push(Enums.ProposalCommandAction.Cancel);
        }
        // renewal
        if (
          (proposal.transType === Enums.TransType.NEW ||
            proposal.transType === Enums.TransType.END) &&
          proposal.proposalType === Enums.ProposalType.Policy &&
          proposal.proposalStatus === Enums.ProposalStatus.Accepted &&
          proposal.pendingTransaction == null &&
          !proposal.auxiliary.IsPreviousYearTerminated
        ) {
          // 30 days before & 30 days after for HDB & PTE - CBG
          // 19 days before & 30 days after for MIP - CBG
          // 88 days before & 30 days after for MAR & FIRE - IBG
          if (proposal.auxiliary.allowRenewal) {
            actions.push(Enums.ProposalCommandAction.Renew);
          }
        }
        // reinstate year2 cancellation
        if (
          proposal.auxiliary.IsPreviousYearTerminated &&
          proposal.proposalStatus === Enums.ProposalStatus.Accepted &&
          proposal.pendingTransaction == null &&
          proposal.transType !== Enums.TransType.CAN
        ) {
          actions.push(Enums.ProposalCommandAction.Cancel);
        }
        break;
      case Enums.UserType.Broker:
        if (
          this.identityRolesService.isMaker &&
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
        ) {
          actions.push(Enums.ProposalCommandAction.Endorse);
          actions.push(Enums.ProposalCommandAction.Cancel);
        }
        if (
          this.identityRolesService.IsCbsOps &&
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null
        ) {
          actions.push(Enums.ProposalCommandAction.Endorse);
        }

        if (
          this.identityRolesService.IsCSCBGSG &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MACH_IBG &&
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null
        ) {
          actions.push(Enums.ProposalCommandAction.Endorse);
        }
        break;
    }

    return actions;
  }

  private accepted(proposal: ProposalEntity): Enums.ProposalCommandAction[] {
    let productFactory!: AbstractProductFactory;
    productFactory = this.productService.getProductFactoryByProductId(
      proposal!.productId!
    );
    return productFactory
      .buttonPanel()
      .GetApprovedActions(this.identityRolesService, proposal);
  }

  private referred(proposal: ProposalEntity): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();
    switch (this.identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
        // DBSEP-2136: Check ProuctType IBG  with role Operation underwriter
        // Any UW for CBG
        if (
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
        ) {
          actions.push(Enums.ProposalCommandAction.Save);
          actions.push(Enums.ProposalCommandAction.Accept);
          actions.push(Enums.ProposalCommandAction.Decline);
        }
        // Non Operator UW for IBG
        if (
          !this.identityRolesService.getIdentity().role
            .isOperationUnderwriter &&
          (proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
            proposal.productId?.toString().toUpperCase() ===
              Const.ProductID.PRODUCT_ID_DBS_MACH_IBG)
        ) {
          actions.push(Enums.ProposalCommandAction.Save);
          actions.push(Enums.ProposalCommandAction.Accept);
          actions.push(Enums.ProposalCommandAction.Decline);
        }

        // Operator UW for IGB has no access to these buttons

        break;
    }

    return actions;
  }

  private approved(proposal: ProposalEntity): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();
    switch (this.identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Broker:
      case Enums.UserType.Underwriter:
        if (
          proposal.productId?.toString().toUpperCase() ===
          Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING
        ) {
          actions.push(Enums.ProposalCommandAction.EditQuotation);
        }
        actions.push(Enums.ProposalCommandAction.Submit);
        actions.push(Enums.ProposalCommandAction.MakeRedundant);
        break;
    }
    return actions;
  }
  private incomplete(proposal: ProposalEntity): Enums.ProposalCommandAction[] {
    let productFactory!: AbstractProductFactory;
    productFactory = this.productService.getProductFactoryByProductId(
      proposal!.productId!
    );
    return productFactory
      .buttonPanel()
      .GetIncompleteActions(this.identityRolesService, proposal);
  }

  private pendingChecker(
    proposal: ProposalEntity
  ): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();

    // if is [Checker] and it's not the same person with the proposal [MakerUserId]
    if (
      this.identityRolesService.isChecker &&
      this.identityRolesService.getIdentity().currentUser().id !==
        proposal.makerUserId &&
      proposal.productId?.toString().toUpperCase() !==
        Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
      proposal.productId?.toString().toUpperCase() !==
        Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
    ) {
      // Allow [Checked] and [Return For Revise]
      actions.push(Enums.ProposalCommandAction.Submit);
      actions.push(Enums.ProposalCommandAction.ReturnForRevise);
    }

    return actions;
  }

  private declined(proposal: ProposalEntity): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();
    switch (this.identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Broker:
        if (
          this.identityRolesService.isMaker &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
        ) {
          // Allow [Edit Declined]
          actions.push(Enums.ProposalCommandAction.EditDeclinedProposal);
        }
        break;
      // DBSEP-2136
      case Enums.UserType.Underwriter:
        if (
          this.identityRolesService.getIdentity().role.isOperationUnderwriter &&
          (proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
            proposal.productId?.toString().toUpperCase() ===
              Const.ProductID.PRODUCT_ID_DBS_MACH_IBG)
        ) {
          // Allow [Edit Declined]
          actions.push(Enums.ProposalCommandAction.EditDeclinedProposal);
        }
        break;
    }

    return actions;
  }

  private madeRedundant(
    proposal: ProposalEntity
  ): Enums.ProposalCommandAction[] {
    const actions = new Array<Enums.ProposalCommandAction>();
    return actions;
  }
}
