import * as Enums from "../../../../../../../framework/enum.shared";

import { Application } from "../../../../../../../application";
import { GUID } from "./../../../../../../../framework/domain-entity/guid";
import { MortgageFireSGSectionSchema } from "./../schemas/mf-sg-section-schema";
import { MortgageFireSGTabSchema } from "./../schemas/mf-sg-tab-schema";
import { ProposalEntity } from "./../../../../../base/proposal-entity.model";
import { QuestionEntity } from "./../../../../../base/question-entity.model";
import { QuestionSection } from "./../../../../../base/question-section";
import { common } from "./../../../../../../../framework/utils/common";
import { MasterDataService } from "../../../../../../../services/master-data.service";

export class MortgageFireSingaporeMasterQuestionSection extends QuestionSection {
  qs_master_brokerFirstName!: QuestionEntity | undefined;

  //Implementation Preferred Delivery Method
  qs_master_preferredDeliveryMethod!: QuestionEntity | undefined;
  qs_master_preferredDeliveryMethod_Email!: QuestionEntity | undefined;
  qs_master_preferredDeliveryMethod_MobileNo!: QuestionEntity | undefined;
  qs_master_preferredDeliveryMethod_CorrAddress!: QuestionEntity | undefined;

  qs_master_brokerLastName!: QuestionEntity | undefined;
  qs_master_brokerOrganizationName!: QuestionEntity | undefined;
  qs_master_borrowerCIN!: QuestionEntity | undefined;

  qs_master_buPcCode!: QuestionEntity | undefined;
  qs_master_uploadBuPcCode!: QuestionEntity | undefined;
  qs_master_rmName!: QuestionEntity | undefined;
  qs_master_rmContactNo!: QuestionEntity | undefined;
  qs_master_rmRemarks!: QuestionEntity | undefined;
  qs_master_insuredAddress!: QuestionEntity | undefined;

  qs_master_busReg2ndInsured!: QuestionEntity | undefined;
  qs_master_otherInsuredName!: QuestionEntity | undefined;
  qs_master_additionalInsured!: QuestionEntity | undefined;
  qs_master_insuredNo2!: QuestionEntity | undefined;
  qs_master_namesOtherInsured!: QuestionEntity | undefined;

  qs_master_insuredAddress_building!: QuestionEntity | undefined;
  qs_master_insuredAddress_lotunit!: QuestionEntity | undefined;
  qs_master_insuredAddress1!: QuestionEntity | undefined;
  qs_master_insuredAddress2!: QuestionEntity | undefined;
  qs_master_insuredPostalCode!: QuestionEntity | undefined;

  qs_master_insuredCity!: QuestionEntity | undefined;
  qs_master_insuredState!: QuestionEntity | undefined;
  qs_master_insuredCountry!: QuestionEntity | undefined;

  qs_master_insuredNo!: QuestionEntity | undefined;
  qs_master_insuredName!: QuestionEntity | undefined;
  qs_master_replacePolicyNo!: QuestionEntity | undefined;
  qs_master_masterPolicyNumber!: QuestionEntity | undefined;
  qs_master_planType!: QuestionEntity | undefined;
  qs_master_insuredNoNationality!: QuestionEntity | undefined;
  qs_master_insuredNo2Nationality!: QuestionEntity | undefined;
  qs_master_insuredNoDocType!: QuestionEntity | undefined;
  qs_master_insuredNo2DocType!: QuestionEntity | undefined;

  qs_master_poi_startDate!: QuestionEntity | undefined;
  qs_master_poi_endDate!: QuestionEntity | undefined;

  /// 12/10/2018 : Chirag Added Code For FreeInsurance Start/End Date
  qs_master_FreeIns_startDate!: QuestionEntity | undefined;
  qs_master_FreeIns_endDate!: QuestionEntity | undefined;

  qs_master_mobileNumber!: QuestionEntity | undefined;
  qs_master_emailID!: QuestionEntity | undefined;

  ////DBSEP-252 : Client Info Update
  qs_master_salutation!: QuestionEntity | undefined;
  qs_master_clientName!: QuestionEntity | undefined;
  qs_master_passport!: QuestionEntity | undefined;
  qs_master_nric!: QuestionEntity | undefined;
  qs_master_occupation!: QuestionEntity | undefined;
  qs_master_birthDate!: QuestionEntity | undefined;
  qs_master_race!: QuestionEntity | undefined;
  qs_master_nationality!: QuestionEntity | undefined;
  qs_master_gender!: QuestionEntity | undefined;
  qs_master_maritalStatus!: QuestionEntity | undefined;
  qs_master_phoneNoHome!: QuestionEntity | undefined;
  qs_master_phoneNoOffice!: QuestionEntity | undefined;
  qs_master_faxNumber!: QuestionEntity | undefined;
  qs_master_cin!: QuestionEntity | undefined;

  qs_master_financialBranch!: QuestionEntity | undefined;
  qs_master_bankReferNumber!: QuestionEntity | undefined;
  qs_master_assetClassCode!: QuestionEntity | undefined;
  qs_master_assetSerialNumber!: QuestionEntity | undefined;
  qs_master_effectiveDate!: QuestionEntity | undefined;
  qs_master_endorse_endorsementReason!: QuestionEntity | undefined;
  qs_master_cancellation_adjustmentAmount!: QuestionEntity | undefined;
  qs_master_cancellation_maximumAdjustmentAmount!: QuestionEntity | undefined;
  qs_master_refund!: QuestionEntity | undefined;
  qs_master_waiveMinimumPremium!: QuestionEntity | undefined;
  qs_master_cancellation_masterpiece!: QuestionEntity | undefined;
  qs_master_insuredIndustry!: QuestionEntity | undefined;
  qs_master_occupancyRate!: QuestionEntity | undefined;
  qs_master_miRate!: QuestionEntity | undefined;
  qs_master_insuredIndustry_desc!: QuestionEntity | undefined;
  qs_master_manualUnderwritting!: QuestionEntity | undefined;
  qs_location_occupation!: QuestionEntity | undefined;
  qs_location_riskAddress1!: QuestionEntity | undefined;
  qs_location_riskAddress2!: QuestionEntity | undefined;
  //questions: QuestionEntity[] = [];//no need anymore, since inherited from base

  qs_risk_floating!: QuestionEntity | undefined;
  qs_risk_sameAsAddress!: QuestionEntity | undefined;
  qs_risk_insuredAddress_building!: QuestionEntity | undefined;
  qs_risk_insuredAddress_lotunit!: QuestionEntity | undefined;
  qs_risk_insuredAddress1!: QuestionEntity | undefined;
  qs_risk_insuredAddress2!: QuestionEntity | undefined;
  qs_risk_insuredPostalCode!: QuestionEntity | undefined;
  qs_risk_insuredCity!: QuestionEntity | undefined;
  qs_risk_insuredState!: QuestionEntity | undefined;
  qs_risk_insuredCountry!: QuestionEntity | undefined;
  qs_risk_uploadRiskAddress!: QuestionEntity | undefined;

  // qs_fire_situation! : QuestionEntity | undefined;
  qs_fire_occupied!: QuestionEntity | undefined;
  qs_fire_notes!: QuestionEntity | undefined;
  qs_fire_excess!: QuestionEntity | undefined;
  qs_fire_mcst!: QuestionEntity | undefined;
  qs_fire_replacement!: QuestionEntity | undefined;
  qs_fire_generalPage!: QuestionEntity | undefined;
  qs_fire_businessDesc!: QuestionEntity | undefined;
  qs_fire_interestedParties!: QuestionEntity | undefined;
  qs_fire_discussionNotes!: QuestionEntity | undefined;

  qs_fire_natureBusiness!: QuestionEntity | undefined;
  qs_fire_usagePremises!: QuestionEntity | undefined;
  qs_fire_typeProperty!: QuestionEntity | undefined;
  qs_fire_freeInsuranceFirstYear!: QuestionEntity | undefined;
  qs_fire_nameSolicitors!: QuestionEntity | undefined;
  qs_fire_equipment_description!: QuestionEntity | undefined;
  qs_fire_equipment_type!: QuestionEntity | undefined;
  qs_fire_equipment_financing!: QuestionEntity | undefined;
  qs_fire_sumOfInsured!: QuestionEntity | undefined;
  qs_master_busReg1stInsured!: QuestionEntity | undefined;
  qs_fire_building!: QuestionEntity | undefined;
  qs_fire_householdContents!: QuestionEntity | undefined;
  qs_machinery_equipment!: QuestionEntity | undefined;
  qs_master_lst!: QuestionEntity | undefined;
  qs_master_lstDetails!: QuestionEntity | undefined;

  // IBG
  qs_master_dateOfBirthOrIncorporation!: QuestionEntity | undefined;
  qs_master_clientContactPerson!: QuestionEntity | undefined;
  qs_master_clientContactPersonContactNo!: QuestionEntity | undefined;
  qs_machinery_uploadSumInsured!: QuestionEntity | undefined;
  qs_machinery_uploadTypeOfEquipment!: QuestionEntity | undefined;
  qs_machinery_uploadEquipmentDescription!: QuestionEntity | undefined;
  qs_machinery_uploadTypeOfFinancing!: QuestionEntity | undefined;
  qs_fire_freeInsuranceNoYear!: QuestionEntity | undefined;
  qs_master_uploadAdditionalInsured!: QuestionEntity | undefined;

  //Citibank
  qs_master_premiumPayment!: QuestionEntity | undefined;
  qs_master_customerType!: QuestionEntity | undefined;
  qs_master_collateralNumber: QuestionEntity | undefined;
  qs_master_discountCode!: QuestionEntity | undefined;
  qs_master_discountType!: QuestionEntity | undefined;
  qs_fire_outstandingLoanValue!: QuestionEntity | undefined;
  qs_fire_coverageValueType!: QuestionEntity | undefined;
  qs_master_customDiscountCode!: QuestionEntity | undefined;

  nationalityList!: any[];

  constructor(public override masterDataService: MasterDataService) {
    super(MortgageFireSGTabSchema.master.index, masterDataService);
  }

  initializeQuestionSchema() {
    this.qs_master_brokerFirstName = new QuestionEntity(
      "qs_master_brokerFirstName",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerFirstName.displayText = "First Name";
    this.qs_master_brokerFirstName.maxLength = 100;
    this.qs_master_brokerFirstName.required = false;
    this.qs_master_brokerFirstName.tabId = this.tabId;
    this.qs_master_brokerFirstName.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_brokerLastName = new QuestionEntity(
      "qs_master_brokerLastName",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerLastName.displayText = "Last Name";
    this.qs_master_brokerLastName.maxLength = 100;
    this.qs_master_brokerLastName.required = true;
    this.qs_master_brokerLastName.tabId = this.tabId;
    this.qs_master_brokerLastName.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_brokerOrganizationName = new QuestionEntity(
      "qs_master_brokerOrganizationName",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerOrganizationName.displayText = "Insured Name";
    this.qs_master_brokerOrganizationName.maxLength = 100;
    this.qs_master_brokerOrganizationName.required = true;
    this.qs_master_brokerOrganizationName.tabId = this.tabId;
    this.qs_master_brokerOrganizationName.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    // New IBG broker requirement
    this.qs_master_insuredAddress = new QuestionEntity(
      "qs_master_insuredAddress",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress.displayText =
      "Uploaded correspondence address";
    this.qs_master_insuredAddress.readOnly = true;
    this.qs_master_insuredAddress.required = false;
    this.qs_master_insuredAddress.tabId = this.tabId;
    this.qs_master_insuredAddress.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_insuredAddress_building = new QuestionEntity(
      "qs_master_insuredAddress_building",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress_building.displayText = "Building Name";
    this.qs_master_insuredAddress_building.required = false;
    this.qs_master_insuredAddress_building.tabId = this.tabId;
    this.qs_master_insuredAddress_building.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredAddress_lotunit = new QuestionEntity(
      "qs_master_insuredAddress_lotunit",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress_lotunit.displayText = "Suite/Lot/Unit Number";
    this.qs_master_insuredAddress_lotunit.required = false;
    this.qs_master_insuredAddress_lotunit.tabId = this.tabId;
    this.qs_master_insuredAddress_lotunit.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredAddress1 = new QuestionEntity(
      "qs_master_insuredAddress1",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress1.displayText = "Address No 1";
    this.qs_master_insuredAddress1.readOnly = true;
    this.qs_master_insuredAddress1.required = false;
    this.qs_master_insuredAddress1.tabId = this.tabId;
    this.qs_master_insuredAddress1.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredAddress2 = new QuestionEntity(
      "qs_master_insuredAddress2",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress2.displayText = "Address No 2";
    this.qs_master_insuredAddress2.readOnly = true;
    this.qs_master_insuredAddress2.required = false;
    this.qs_master_insuredAddress2.tabId = this.tabId;
    this.qs_master_insuredAddress2.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredPostalCode = new QuestionEntity(
      "qs_master_insuredPostalCode",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredPostalCode.displayText = "PostCode";
    this.qs_master_insuredPostalCode.readOnly = true;
    this.qs_master_insuredPostalCode.required = false;
    this.qs_master_insuredPostalCode.tabId = this.tabId;
    this.qs_master_insuredPostalCode.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredCity = new QuestionEntity(
      "qs_master_insuredCity",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredCity.displayText = "City/Suburb";
    this.qs_master_insuredCity.readOnly = true;
    this.qs_master_insuredCity.required = false;
    this.qs_master_insuredCity.tabId = this.tabId;
    this.qs_master_insuredCity.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredState = new QuestionEntity(
      "qs_master_insuredState",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredState.displayText = "State";
    this.qs_master_insuredState.readOnly = true;
    this.qs_master_insuredState.required = false;
    this.qs_master_insuredState.tabId = this.tabId;
    this.qs_master_insuredState.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredCountry = new QuestionEntity(
      "qs_master_insuredCountry",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredCountry.displayText = "Country";
    this.qs_master_insuredCountry.readOnly = true;
    this.qs_master_insuredCountry.required = true;

    this.qs_master_insuredCountry.tabId = this.tabId;
    this.qs_master_insuredCountry.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.masterDataService.getCountryList().subscribe((response) => {
      this.qs_master_insuredCountry!.options = response;
      this.qs_master_insuredCountry!.answer = "SG";
    });

    this.qs_master_buPcCode = new QuestionEntity(
      "qs_master_buPcCode",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_buPcCode.displayText = "BU PC Code";
    this.qs_master_buPcCode.required = true;
    this.qs_master_buPcCode.tabId = this.tabId;
    this.qs_master_buPcCode.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_uploadBuPcCode = new QuestionEntity(
      "qs_master_uploadBuPcCode",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_uploadBuPcCode.displayText = "Uploaded BU PC Code";
    this.qs_master_uploadBuPcCode.required = false;
    this.qs_master_uploadBuPcCode.readOnly = true;
    this.qs_master_uploadBuPcCode.tabId = this.tabId;
    this.qs_master_uploadBuPcCode.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_rmName = new QuestionEntity(
      "qs_master_rmName",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_rmName.displayText = "Name";
    this.qs_master_rmName.maxLength = 100;
    this.qs_master_rmName.required = false;
    this.qs_master_rmName.tabId = this.tabId;
    this.qs_master_rmName.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_rmContactNo = new QuestionEntity(
      "qs_master_rmContactNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_rmContactNo.displayText = "Contact No";
    this.qs_master_rmContactNo.required = false;
    this.qs_master_rmContactNo.tabId = this.tabId;
    this.qs_master_rmContactNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_rmRemarks = new QuestionEntity(
      "qs_master_rmRemarks",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_rmRemarks.displayText = "Remarks";
    this.qs_master_rmRemarks.required = false;
    this.qs_master_rmRemarks.tabId = this.tabId;
    this.qs_master_rmRemarks.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
    // End new IBG

    // New IBG Client Information
    this.qs_master_busReg1stInsured = new QuestionEntity(
      "qs_master_busReg1stInsured",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_busReg1stInsured.displayText =
      "Business Registration No / NRIC No (1st Insured)";
    this.qs_master_busReg1stInsured.required = false;
    this.qs_master_busReg1stInsured.tabId = this.tabId;
    this.qs_master_busReg1stInsured.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_busReg2ndInsured = new QuestionEntity(
      "qs_master_busReg2ndInsured",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_busReg2ndInsured.displayText =
      "Business Registration No / NRIC No (2nd Insured)";
    this.qs_master_busReg2ndInsured.required = false;
    this.qs_master_busReg2ndInsured.tabId = this.tabId;
    this.qs_master_busReg2ndInsured.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_otherInsuredName = new QuestionEntity(
      "qs_master_otherInsuredName",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_otherInsuredName.displayText = "Other Insured Name";
    this.qs_master_otherInsuredName.required = false;
    this.qs_master_otherInsuredName.tabId = this.tabId;
    this.qs_master_otherInsuredName.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_additionalInsured = new QuestionEntity(
      "qs_master_additionalInsured",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_additionalInsured.displayText =
      "Names other than Insured(if any)";
    this.qs_master_additionalInsured.required = false;
    this.qs_master_additionalInsured.tabId = this.tabId;
    this.qs_master_additionalInsured.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
    // End IBG Client Information

    this.qs_master_insuredNo = new QuestionEntity(
      "qs_master_insuredNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredNo.displayText = "Client No";
    this.qs_master_insuredNo.required = true;
    this.qs_master_insuredNo.readOnly = true;
    this.qs_master_insuredNo.tabId = this.tabId;
    this.qs_master_insuredNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_insuredNoNationality = new QuestionEntity(
      "qs_master_insuredNoNationality",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredNoNationality.displayText = "Nationality";
    this.qs_master_insuredNoNationality.required = false;
    this.qs_master_insuredNoNationality.readOnly = false;
    this.qs_master_insuredNoNationality.tabId = this.tabId;
    this.qs_master_insuredNoNationality.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_insuredNoDocType = new QuestionEntity(
      "qs_master_insuredNoDocType",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredNoDocType.displayText = "Document Type";
    this.qs_master_insuredNoDocType.required = false;
    this.qs_master_insuredNoDocType.readOnly = false;
    this.qs_master_insuredNoDocType.tabId = this.tabId;
    this.qs_master_insuredNoDocType.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
    this.qs_master_insuredNoDocType.options = [
      { key: "S - Singapore NRIC", value: "1" },
      { key: "S - Singapore PR", value: "2" },
      { key: "M - Malaysia IC", value: "3" },
      { key: "P - Foreign Passport", value: "4" },
    ];

    this.qs_master_insuredName = new QuestionEntity(
      "qs_master_insuredName",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredName.displayText = "Client Name";
    this.qs_master_insuredName.readOnly = true;
    this.qs_master_insuredName.required = true;
    this.qs_master_insuredName.tabId = this.tabId;
    this.qs_master_insuredName.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_replacePolicyNo = new QuestionEntity(
      "qs_master_replacePolicyNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_replacePolicyNo.displayText = "Replacing Policy No";
    this.qs_master_replacePolicyNo.required = false;
    this.qs_master_replacePolicyNo.tabId = this.tabId;
    this.qs_master_replacePolicyNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_masterPolicyNumber = new QuestionEntity(
      "qs_master_masterPolicyNumber",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_masterPolicyNumber.displayText = "Master Policy Number";
    this.qs_master_masterPolicyNumber.required = false;
    this.qs_master_masterPolicyNumber.tabId = this.tabId;
    this.qs_master_masterPolicyNumber.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_planType = new QuestionEntity(
      "qs_master_planType",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_planType.displayText = "No Of Years";
    this.qs_master_planType.required = false;
    this.qs_master_planType.tabId = this.tabId;
    this.qs_master_planType.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_financialBranch = new QuestionEntity(
      "qs_master_financialBranch",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_financialBranch.displayText = "Financial Institution Branch";
    this.qs_master_financialBranch.required = true;
    this.qs_master_financialBranch.tabId = this.tabId;
    this.qs_master_financialBranch.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_bankReferNumber = new QuestionEntity(
      "qs_master_bankReferNumber",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_bankReferNumber.displayText = "Bank/Cross Reference Number";
    this.qs_master_bankReferNumber.required = true;
    this.qs_master_bankReferNumber.tabId = this.tabId;
    this.qs_master_bankReferNumber.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_assetClassCode = new QuestionEntity(
      "qs_master_assetClassCode",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_assetClassCode.displayText = "Asset Class Code";
    this.qs_master_assetClassCode.required = false;
    this.qs_master_assetClassCode.tabId = this.tabId;
    this.qs_master_assetClassCode.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_assetSerialNumber = new QuestionEntity(
      "qs_master_assetSerialNumber",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_assetSerialNumber.displayText = "Asset Serial Number";
    this.qs_master_assetSerialNumber.required = false;
    this.qs_master_assetSerialNumber.tabId = this.tabId;
    this.qs_master_assetSerialNumber.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_poi_startDate = new QuestionEntity(
      "qs_master_poi_startDate",
      undefined,
      Enums.AnswerDataType.Date
    );
    this.qs_master_poi_startDate.displayText = "Policy Inception Date";
    this.qs_master_poi_startDate.answer = new Date();
    // Set the minimum date to be selected to 30 days before the current Date
    // this.qs_master_poi_startDate.minValue =
    // new Date(new Date(this.qs_master_poi_startDate.answer).setDate(new Date(this.qs_master_poi_startDate.answer).getDate() - 30));
    this.qs_master_poi_startDate.required = true;
    this.qs_master_poi_startDate.tabId = this.tabId;
    this.qs_master_poi_startDate.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_effectiveDate = new QuestionEntity(
      "qs_master_effectiveDate",
      undefined,
      Enums.AnswerDataType.Date
    );
    this.qs_master_effectiveDate.displayText = "Effective Date";
    this.qs_master_effectiveDate.answer = new Date();
    this.qs_master_effectiveDate.tabId = this.tabId;
    this.qs_master_effectiveDate.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_poi_endDate = new QuestionEntity(
      "qs_master_poi_endDate",
      undefined,
      Enums.AnswerDataType.Date
    );
    this.qs_master_poi_endDate.displayText = "Policy Expiry Date";
    this.qs_master_poi_endDate.answer = new Date();
    this.qs_master_poi_endDate.readOnly = true;
    this.qs_master_poi_endDate.tabId = this.tabId;
    this.qs_master_poi_endDate.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    /// 12/10/2018 : Chirag Added Code For FreeInsurance Start/End Date
    this.qs_master_FreeIns_startDate = new QuestionEntity(
      "qs_master_FreeIns_startDate",
      undefined,
      Enums.AnswerDataType.Date
    );
    this.qs_master_FreeIns_startDate.displayText = "Free Insurance Start Date";
    this.qs_master_FreeIns_startDate.answer = new Date();
    this.qs_master_FreeIns_startDate.readOnly = true;
    this.qs_master_FreeIns_startDate.required = false;
    this.qs_master_FreeIns_startDate.tabId = this.tabId;
    this.qs_master_FreeIns_startDate.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_FreeIns_endDate = new QuestionEntity(
      "qs_master_FreeIns_endDate",
      undefined,
      Enums.AnswerDataType.Date
    );
    this.qs_master_FreeIns_endDate.displayText = "Free Insurance End Date";
    this.qs_master_FreeIns_endDate.answer = new Date();
    this.qs_master_FreeIns_endDate.required = false;
    this.qs_master_FreeIns_endDate.readOnly = true;
    this.qs_master_FreeIns_endDate.tabId = this.tabId;
    this.qs_master_FreeIns_endDate.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    //Implemetaion Preferred Delivery method
    this.qs_master_preferredDeliveryMethod = new QuestionEntity(
      "qs_master_preferredDeliveryMethod",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_preferredDeliveryMethod.displayText =
      "Preferred Delivery Method";
    this.qs_master_preferredDeliveryMethod.required = true;
    this.qs_master_preferredDeliveryMethod.tabId = this.tabId;
    this.qs_master_preferredDeliveryMethod.readOnly = false;
    this.qs_master_preferredDeliveryMethod.answer = 5;
    this.qs_master_preferredDeliveryMethod.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_preferredDeliveryMethod_Email = new QuestionEntity(
      "qs_master_preferredDeliveryMethod_Email",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_preferredDeliveryMethod_Email.displayText = "Email";
    this.qs_master_preferredDeliveryMethod_Email.required = false;
    this.qs_master_preferredDeliveryMethod_Email.tabId = this.tabId;
    this.qs_master_preferredDeliveryMethod_Email.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_preferredDeliveryMethod_MobileNo = new QuestionEntity(
      "qs_master_preferredDeliveryMethod_MobileNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_preferredDeliveryMethod_MobileNo.displayText =
      "Mobile Number";
    this.qs_master_preferredDeliveryMethod_MobileNo.required = false;
    this.qs_master_preferredDeliveryMethod_MobileNo.tabId = this.tabId;
    this.qs_master_preferredDeliveryMethod_MobileNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_preferredDeliveryMethod_CorrAddress = new QuestionEntity(
      "qs_master_preferredDeliveryMethod_CorrAddress",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_preferredDeliveryMethod_CorrAddress.displayText =
      "Corresponding Address";
    this.qs_master_preferredDeliveryMethod_CorrAddress.required = false;
    this.qs_master_preferredDeliveryMethod_CorrAddress.tabId = this.tabId;
    this.qs_master_preferredDeliveryMethod_CorrAddress.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    // End Of delivery Method Block

    /// 12/10/2018 : Chirag Added Code For Mobile/Email Address
    this.qs_master_mobileNumber = new QuestionEntity(
      "qs_master_mobileNumber",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_mobileNumber.displayText = "Mobile Number";
    this.qs_master_mobileNumber.required = false;
    this.qs_master_mobileNumber.tabId = this.tabId;
    this.qs_master_mobileNumber.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_emailID = new QuestionEntity(
      "qs_master_emailID",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_emailID.displayText = "Email";
    this.qs_master_emailID.required = false;
    this.qs_master_emailID.tabId = this.tabId;
    this.qs_master_emailID.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    ////DBSEP-252 : Client Info Update
    this.qs_master_salutation = new QuestionEntity(
      "qs_master_salutation",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_salutation.displayText = "Salutation";
    this.qs_master_salutation.required = false;
    this.qs_master_salutation.tabId = this.tabId;
    this.qs_master_salutation.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientName = new QuestionEntity(
      "qs_master_clientName",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientName.displayText = "ClientName";
    this.qs_master_clientName.required = false;
    this.qs_master_clientName.tabId = this.tabId;
    this.qs_master_clientName.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_passport = new QuestionEntity(
      "qs_master_passport",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_passport.displayText = "Passport";
    this.qs_master_passport.required = false;
    this.qs_master_passport.tabId = this.tabId;
    this.qs_master_passport.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_nric = new QuestionEntity(
      "qs_master_nric",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_nric.displayText = "NRIC";
    this.qs_master_nric.required = false;
    this.qs_master_nric.tabId = this.tabId;
    this.qs_master_nric.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_occupation = new QuestionEntity(
      "qs_master_occupation",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_occupation.displayText = "Occupation";
    this.qs_master_occupation.required = false;
    this.qs_master_occupation.tabId = this.tabId;
    this.qs_master_occupation.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_birthDate = new QuestionEntity(
      "qs_master_birthDate",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_birthDate.displayText = "Birth Date";
    this.qs_master_birthDate.required = false;
    this.qs_master_birthDate.tabId = this.tabId;
    this.qs_master_birthDate.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_race = new QuestionEntity(
      "qs_master_race",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_race.displayText = "Race";
    this.qs_master_race.required = false;
    this.qs_master_race.tabId = this.tabId;
    this.qs_master_race.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_nationality = new QuestionEntity(
      "qs_master_nationality",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_nationality.displayText = "Nationality";
    this.qs_master_nationality.required = false;
    this.qs_master_nationality.tabId = this.tabId;
    this.qs_master_nationality.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_gender = new QuestionEntity(
      "qs_master_gender",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_gender.displayText = "Gender";
    this.qs_master_gender.required = false;
    this.qs_master_gender.tabId = this.tabId;
    this.qs_master_gender.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_maritalStatus = new QuestionEntity(
      "qs_master_maritalStatus",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_maritalStatus.displayText = "Marital Status";
    this.qs_master_maritalStatus.required = false;
    this.qs_master_maritalStatus.tabId = this.tabId;
    this.qs_master_maritalStatus.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_phoneNoHome = new QuestionEntity(
      "qs_master_phoneNoHome",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_phoneNoHome.displayText = "PhoneNo Home";
    this.qs_master_phoneNoHome.required = false;
    this.qs_master_phoneNoHome.tabId = this.tabId;
    this.qs_master_phoneNoHome.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_phoneNoOffice = new QuestionEntity(
      "qs_master_phoneNoOffice",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_phoneNoOffice.displayText = "Office No";
    this.qs_master_phoneNoOffice.required = false;
    this.qs_master_phoneNoOffice.tabId = this.tabId;
    this.qs_master_phoneNoOffice.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_faxNumber = new QuestionEntity(
      "qs_master_faxNumber",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_faxNumber.displayText = "Fax Number";
    this.qs_master_faxNumber.required = false;
    this.qs_master_faxNumber.tabId = this.tabId;
    this.qs_master_faxNumber.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_cin = new QuestionEntity(
      "qs_master_cin",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_cin.displayText = "CIN";
    this.qs_master_cin.required = false;
    this.qs_master_cin.tabId = this.tabId;
    this.qs_master_cin.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_endorse_endorsementReason = new QuestionEntity(
      "qs_master_endorse_endorsementReason",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_endorse_endorsementReason.displayText = "Remarks";
    this.qs_master_endorse_endorsementReason.required = false;
    this.qs_master_endorse_endorsementReason.tabId = this.tabId;
    this.qs_master_endorse_endorsementReason.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_refund = new QuestionEntity(
      "qs_master_refund",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_refund.displayText = "Remarks";
    this.qs_master_refund.required = false;
    this.qs_master_refund.tabId = this.tabId;
    this.qs_master_refund.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_waiveMinimumPremium = new QuestionEntity(
      "qs_master_waiveMinimumPremium",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_waiveMinimumPremium.displayText = "WaiveMinimumPremium";
    this.qs_master_waiveMinimumPremium.defaultAnswer = "False";
    this.qs_master_waiveMinimumPremium.answer = "False";
    this.qs_master_waiveMinimumPremium.required = false;
    this.qs_master_waiveMinimumPremium.isVisible = false;
    this.qs_master_waiveMinimumPremium.tabId = this.tabId;
    this.qs_master_waiveMinimumPremium.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_cancellation_masterpiece = new QuestionEntity(
      "qs_master_cancellation_masterpiece",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_cancellation_masterpiece.displayText =
      "Masterpiece Issuance";
    this.qs_master_cancellation_masterpiece.defaultAnswer = "False";
    this.qs_master_cancellation_masterpiece.answer = "False";
    this.qs_master_cancellation_masterpiece.required = false;
    this.qs_master_cancellation_masterpiece.isVisible = false;
    this.qs_master_cancellation_masterpiece.tabId = this.tabId;
    this.qs_master_cancellation_masterpiece.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_cancellation_adjustmentAmount = new QuestionEntity(
      "qs_master_cancellation_adjustmentAmount",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_cancellation_adjustmentAmount.displayText =
      "Adjustment Amount";
    this.qs_master_cancellation_adjustmentAmount.isVisible = false;
    this.qs_master_cancellation_adjustmentAmount.required = false;
    this.qs_master_cancellation_adjustmentAmount.readOnly = true;
    this.qs_master_cancellation_adjustmentAmount.tabId = this.tabId;
    this.qs_master_cancellation_adjustmentAmount.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
    this.qs_master_cancellation_adjustmentAmount.answer = 0;
    this.qs_master_cancellation_adjustmentAmount.decimals = 2;

    this.qs_master_cancellation_maximumAdjustmentAmount = new QuestionEntity(
      "qs_master_cancellation_maximumAdjustmentAmount",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_cancellation_maximumAdjustmentAmount.displayText =
      "Maximum Adjustment Amount";
    this.qs_master_cancellation_maximumAdjustmentAmount.isVisible = false;
    this.qs_master_cancellation_maximumAdjustmentAmount.readOnly = true;
    this.qs_master_cancellation_maximumAdjustmentAmount.required = false;
    this.qs_master_cancellation_maximumAdjustmentAmount.tabId = this.tabId;
    this.qs_master_cancellation_maximumAdjustmentAmount.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_insuredIndustry = new QuestionEntity(
      "qs_master_insuredIndustry",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredIndustry.displayText = "NAICS";
    this.qs_master_insuredIndustry.required = true;
    this.qs_master_insuredIndustry.tabId = this.tabId;
    this.qs_master_insuredIndustry.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_occupancyRate = new QuestionEntity(
      "qs_master_occupancyRate",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_occupancyRate.displayText = "Occupancy Rates";
    this.qs_master_occupancyRate.required = true;
    this.qs_master_occupancyRate.tabId = this.tabId;
    this.qs_master_occupancyRate.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_miRate = new QuestionEntity(
      "qs_master_miRate",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_miRate.displayText = "Mi Rates";
    this.qs_master_miRate.required = true;
    this.qs_master_miRate.tabId = this.tabId;
    this.qs_master_miRate.readOnly = true;
    this.qs_master_miRate.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
    this.qs_master_miRate.options = [
      { key: "MI", value: "1" },
      { key: "Non-MI", value: "2" },
    ];

    this.qs_location_occupation = new QuestionEntity(
      "qs_location_occupation",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_location_occupation.displayText = "Location Occupation";
    this.qs_location_occupation.required = true;
    this.qs_location_occupation.tabId = this.tabId;
    this.qs_location_occupation.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredIndustry_desc = new QuestionEntity(
      "qs_master_insuredIndustry_desc",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredIndustry_desc.displayText = "Occupation Description";
    this.qs_master_insuredIndustry_desc.required = false;
    this.qs_master_insuredIndustry_desc.tabId = this.tabId;
    this.qs_master_insuredIndustry_desc.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_location_riskAddress1 = new QuestionEntity(
      "qs_location_riskAddress1",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_location_riskAddress1.displayText = "Location Address 1";
    this.qs_location_riskAddress1.required = true;
    this.qs_location_riskAddress1.tabId = this.tabId;
    this.qs_location_riskAddress1.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_location_riskAddress2 = new QuestionEntity(
      "qs_location_riskAddress2",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_location_riskAddress2.displayText = "Location Address 2";
    this.qs_location_riskAddress2.required = true;
    this.qs_location_riskAddress2.tabId = this.tabId;
    this.qs_location_riskAddress2.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_floating = new QuestionEntity(
      "qs_risk_floating",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_floating.displayText = "Floating";
    this.qs_risk_floating.options = [
      { key: "Yes", value: "1" },
      { key: "No", value: "2" },
    ];
    this.qs_risk_floating.required = false;
    this.qs_risk_floating.tabId = this.tabId;
    this.qs_risk_floating.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_sameAsAddress = new QuestionEntity(
      "qs_risk_sameAsAddress",
      undefined,
      Enums.AnswerDataType.Boolean
    );
    this.qs_risk_sameAsAddress.displayText =
      "Same as Client’s Contact Information";
    this.qs_risk_sameAsAddress.required = false;
    this.qs_risk_sameAsAddress.tabId = this.tabId;
    this.qs_risk_sameAsAddress.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_insuredAddress_building = new QuestionEntity(
      "qs_risk_insuredAddress_building",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_insuredAddress_building.displayText = "Building Name";
    this.qs_risk_insuredAddress_building.required = false;
    this.qs_risk_insuredAddress_building.readOnly = true;
    this.qs_risk_insuredAddress_building.tabId = this.tabId;
    this.qs_risk_insuredAddress_building.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_insuredAddress_lotunit = new QuestionEntity(
      "qs_risk_insuredAddress_lotunit",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_insuredAddress_lotunit.displayText = "Suite/Lot/Unit Number";
    this.qs_risk_insuredAddress_lotunit.required = false;
    this.qs_risk_insuredAddress_lotunit.tabId = this.tabId;
    this.qs_risk_insuredAddress_lotunit.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_insuredAddress1 = new QuestionEntity(
      "qs_risk_insuredAddress1",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_insuredAddress1.displayText = "Address No 1";
    this.qs_risk_insuredAddress1.readOnly = true;
    this.qs_risk_insuredAddress1.required = true;
    this.qs_risk_insuredAddress1.tabId = this.tabId;
    this.qs_risk_insuredAddress1.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_insuredAddress2 = new QuestionEntity(
      "qs_risk_insuredAddress2",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_insuredAddress2.displayText = "Address No 2";
    this.qs_risk_insuredAddress2.readOnly = true;
    this.qs_risk_insuredAddress2.required = false;
    this.qs_risk_insuredAddress2.tabId = this.tabId;
    this.qs_risk_insuredAddress2.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_insuredPostalCode = new QuestionEntity(
      "qs_risk_insuredPostalCode",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_insuredPostalCode.displayText = "PostCode";
    this.qs_risk_insuredPostalCode.readOnly = true;
    this.qs_risk_insuredPostalCode.required = true;
    this.qs_risk_insuredPostalCode.tabId = this.tabId;
    this.qs_risk_insuredPostalCode.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_insuredCity = new QuestionEntity(
      "qs_risk_insuredCity",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_insuredCity.displayText = "City/Suburb";
    this.qs_risk_insuredCity.readOnly = true;
    this.qs_risk_insuredCity.required = true;
    this.qs_risk_insuredCity.tabId = this.tabId;
    this.qs_risk_insuredCity.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_insuredState = new QuestionEntity(
      "qs_risk_insuredState",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_insuredState.displayText = "State";
    this.qs_risk_insuredState.readOnly = true;
    this.qs_risk_insuredState.required = false;
    this.qs_risk_insuredState.tabId = this.tabId;
    this.qs_risk_insuredState.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_insuredCountry = new QuestionEntity(
      "qs_risk_insuredCountry",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_insuredCountry.displayText = "Country";
    this.qs_risk_insuredCountry.readOnly = true;
    this.qs_risk_insuredCountry.required = true;
    this.qs_risk_insuredCountry.tabId = this.tabId;
    this.qs_risk_insuredCountry.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_risk_uploadRiskAddress = new QuestionEntity(
      "qs_risk_uploadRiskAddress",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_risk_uploadRiskAddress.displayText = "Uploaded risk address";
    this.qs_risk_uploadRiskAddress.readOnly = true;
    this.qs_risk_uploadRiskAddress.required = false;
    this.qs_risk_uploadRiskAddress.tabId = this.tabId;
    this.qs_risk_uploadRiskAddress.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.masterDataService.getCountryList().subscribe((response) => {
      this.qs_risk_insuredCountry!.options = response;
      this.qs_risk_insuredCountry!.answer = "SG";
    });

    this.qs_fire_occupied = new QuestionEntity(
      "qs_fire_occupied",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_occupied.displayText = "Occupied as";
    this.qs_fire_occupied.required = true;
    this.qs_fire_occupied.tabId = this.tabId;
    this.qs_fire_occupied.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;
    this.qs_master_manualUnderwritting = new QuestionEntity(
      "qs_master_manualUnderwritting",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_manualUnderwritting.displayText = "Manual Underwriting";
    this.qs_master_manualUnderwritting.required = true;
    this.qs_master_manualUnderwritting.tabId = this.tabId;
    this.qs_master_manualUnderwritting.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_notes = new QuestionEntity(
      "qs_fire_notes",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_notes.displayText = "Additional Notes on occupancy";
    this.qs_fire_notes.required = false;
    this.qs_fire_notes.tabId = this.tabId;
    this.qs_fire_notes.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_mcst = new QuestionEntity(
      "qs_fire_mcst",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_fire_mcst.displayText = "MCST Number";
    this.qs_fire_mcst.required = false;
    this.qs_fire_mcst.isVisible = false;
    this.qs_fire_mcst.tabId = this.tabId;
    this.qs_fire_mcst.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_replacement = new QuestionEntity(
      "qs_fire_replacement",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_fire_replacement.displayText =
      "Fire Replacement Value / Sum Insured";
    this.qs_fire_replacement.required = true;
    this.qs_fire_replacement.tabId = this.tabId;
    this.qs_fire_replacement.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_excess = new QuestionEntity(
      "qs_fire_excess",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_excess.displayText = "Excess";
    this.qs_fire_excess.required = false;
    this.qs_fire_excess.tabId = this.tabId;
    this.qs_fire_excess.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_generalPage = new QuestionEntity(
      "qs_fire_generalPage",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_generalPage.displayText = "General Page";
    this.qs_fire_generalPage.required = false;
    this.qs_fire_generalPage.tabId = this.tabId;
    this.qs_fire_generalPage.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_businessDesc = new QuestionEntity(
      "qs_fire_businessDesc",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_businessDesc.displayText = "Business Description";
    this.qs_fire_businessDesc.required = false;
    this.qs_fire_businessDesc.tabId = this.tabId;
    this.qs_fire_businessDesc.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_interestedParties = new QuestionEntity(
      "qs_fire_interestedParties",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_interestedParties.displayText = "Interested Parties";
    this.qs_fire_interestedParties.required = false;
    this.qs_fire_interestedParties.tabId = this.tabId;
    this.qs_fire_interestedParties.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_discussionNotes = new QuestionEntity(
      "qs_fire_discussionNotes",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_discussionNotes.displayText = "Discussion Note";
    this.qs_fire_discussionNotes.required = false;
    this.qs_fire_discussionNotes.tabId = this.tabId;
    this.qs_fire_discussionNotes.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    // New IBG risk details
    this.qs_fire_natureBusiness = new QuestionEntity(
      "qs_fire_natureBusiness",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_natureBusiness.displayText = "Nature of Business";
    this.qs_fire_natureBusiness.required = false;
    this.qs_fire_natureBusiness.tabId = this.tabId;
    this.qs_fire_natureBusiness.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_usagePremises = new QuestionEntity(
      "qs_fire_usagePremises",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_usagePremises.displayText = "Usage of Premises";
    this.qs_fire_usagePremises.required = false;
    this.qs_fire_usagePremises.tabId = this.tabId;
    this.qs_fire_usagePremises.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_typeProperty = new QuestionEntity(
      "qs_fire_typeProperty",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_typeProperty.displayText = "Type Of Property";
    this.qs_fire_typeProperty.required = true;
    this.qs_fire_typeProperty.tabId = this.tabId;
    this.qs_fire_typeProperty.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_freeInsuranceFirstYear = new QuestionEntity(
      "qs_fire_freeInsuranceFirstYear",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_freeInsuranceFirstYear.displayText =
      "Free Insurance for First Year";
    this.qs_fire_freeInsuranceFirstYear.required = false;
    this.qs_fire_freeInsuranceFirstYear.options = [
      { key: "Yes", value: "1" },
      { key: "No", value: "2" },
    ];
    this.qs_fire_freeInsuranceFirstYear.tabId = this.tabId;
    this.qs_fire_freeInsuranceFirstYear.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_nameSolicitors = new QuestionEntity(
      "qs_fire_nameSolicitors",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_nameSolicitors.displayText =
      "Name of Solicitors (For HDB Shops only)";
    this.qs_fire_nameSolicitors.required = false;
    this.qs_fire_nameSolicitors.tabId = this.tabId;
    this.qs_fire_nameSolicitors.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_machinery_equipment = new QuestionEntity(
      "qs_machinery_equipment",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_machinery_equipment.displayText = "Equipment";
    this.qs_machinery_equipment.required = true;
    this.qs_machinery_equipment.tabId = this.tabId;
    this.qs_machinery_equipment.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_lst = new QuestionEntity(
      "qs_master_lst",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_lst.displayText = "Local Surplus Treaty";
    this.qs_master_lst.required = false;
    this.qs_master_lst.readOnly = true;
    this.qs_master_lst.options = [
      { key: "Yes", value: "1" },
      { key: "No", value: "2" },
    ];
    this.qs_master_lst.tabId = this.tabId;
    this.qs_master_lst.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_lstDetails = new QuestionEntity(
      "qs_master_lstDetails",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_lstDetails.displayText = "LST Details";
    this.qs_master_lstDetails.required = false;
    this.qs_master_lstDetails.tabId = this.tabId;
    this.qs_master_lstDetails.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_fire_equipment_description = new QuestionEntity(
      "qs_fire_equipment_description",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_equipment_description.displayText = "Description of Equipment";
    this.qs_fire_equipment_description.required = false;
    this.qs_fire_equipment_description.tabId = this.tabId;
    this.qs_fire_equipment_description.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_equipment_type = new QuestionEntity(
      "qs_fire_equipment_type",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_equipment_type.displayText = "Type of Equipment";
    this.qs_fire_equipment_type.required = false;
    this.qs_fire_equipment_type.tabId = this.tabId;
    this.qs_fire_equipment_type.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_equipment_financing = new QuestionEntity(
      "qs_fire_equipment_financing",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_equipment_financing.displayText = "Type of Financing";
    this.qs_fire_equipment_financing.required = false;
    this.qs_fire_equipment_financing.tabId = this.tabId;
    this.qs_fire_equipment_financing.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_sumOfInsured = new QuestionEntity(
      "qs_fire_sumOfInsured",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_fire_sumOfInsured.displayText = "Sum Insured";
    this.qs_fire_sumOfInsured.required = false;
    this.qs_fire_sumOfInsured.tabId = this.tabId;
    this.qs_fire_sumOfInsured.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_building = new QuestionEntity(
      "qs_fire_building",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_fire_building.displayText = "Building";
    this.qs_fire_building.required = false;
    this.qs_fire_building.tabId = this.tabId;
    this.qs_fire_building.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_fire_householdContents = new QuestionEntity(
      "qs_fire_householdContents",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_fire_householdContents.displayText = "Household contents";
    this.qs_fire_householdContents.required = false;
    this.qs_fire_householdContents.readOnly = true;
    this.qs_fire_householdContents.tabId = this.tabId;
    this.qs_fire_householdContents.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_dateOfBirthOrIncorporation = new QuestionEntity(
      "qs_master_dateOfBirthOrIncorporation",
      undefined,
      Enums.AnswerDataType.Date
    );
    this.qs_master_dateOfBirthOrIncorporation.displayText = "Date Of Birth";
    this.qs_master_dateOfBirthOrIncorporation.required = true;
    this.qs_master_dateOfBirthOrIncorporation.readOnly = false;
    this.qs_master_dateOfBirthOrIncorporation.tabId = this.tabId;
    this.qs_master_dateOfBirthOrIncorporation.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
    this.qs_master_dateOfBirthOrIncorporation.maxValue = new Date();

    this.qs_master_clientContactPerson = new QuestionEntity(
      "qs_master_clientContactPerson",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientContactPerson.displayText = "Client Contact Person";
    this.qs_master_clientContactPerson.required = false;
    this.qs_master_clientContactPerson.readOnly = false;
    this.qs_master_clientContactPerson.tabId = this.tabId;
    this.qs_master_clientContactPerson.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientContactPersonContactNo = new QuestionEntity(
      "qs_master_clientContactPersonContactNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientContactPersonContactNo.displayText =
      "Client Contact Person Contact No";
    this.qs_master_clientContactPersonContactNo.required = false;
    this.qs_master_clientContactPersonContactNo.readOnly = false;
    this.qs_master_clientContactPersonContactNo.tabId = this.tabId;
    this.qs_master_clientContactPersonContactNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_machinery_uploadSumInsured = new QuestionEntity(
      "qs_machinery_uploadSumInsured",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_machinery_uploadSumInsured.displayText = "Uploaded Sum Insured";
    this.qs_machinery_uploadSumInsured.required = false;
    this.qs_machinery_uploadSumInsured.readOnly = true;
    this.qs_machinery_uploadSumInsured.tabId = this.tabId;
    this.qs_machinery_uploadSumInsured.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_machinery_uploadTypeOfEquipment = new QuestionEntity(
      "qs_machinery_uploadTypeOfEquipment",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_machinery_uploadTypeOfEquipment.displayText =
      "Uploaded Type Of Equipment";
    this.qs_machinery_uploadTypeOfEquipment.required = false;
    this.qs_machinery_uploadTypeOfEquipment.readOnly = true;
    this.qs_machinery_uploadTypeOfEquipment.tabId = this.tabId;
    this.qs_machinery_uploadTypeOfEquipment.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_machinery_uploadEquipmentDescription = new QuestionEntity(
      "qs_machinery_uploadEquipmentDescription",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_machinery_uploadEquipmentDescription.displayText =
      "Uploaded Equipment Description";
    this.qs_machinery_uploadEquipmentDescription.required = false;
    this.qs_machinery_uploadEquipmentDescription.readOnly = true;
    this.qs_machinery_uploadEquipmentDescription.tabId = this.tabId;
    this.qs_machinery_uploadEquipmentDescription.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_machinery_uploadTypeOfFinancing = new QuestionEntity(
      "qs_machinery_uploadTypeOfFinancing",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_machinery_uploadTypeOfFinancing.displayText =
      "Uploaded Type Of Financing";
    this.qs_machinery_uploadTypeOfFinancing.required = false;
    this.qs_machinery_uploadTypeOfFinancing.readOnly = true;
    this.qs_machinery_uploadTypeOfFinancing.tabId = this.tabId;
    this.qs_machinery_uploadTypeOfFinancing.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_uploadAdditionalInsured = new QuestionEntity(
      "qs_master_uploadAdditionalInsured",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_uploadAdditionalInsured.displayText =
      "Uploaded Additional Insured";
    this.qs_master_uploadAdditionalInsured.required = false;
    this.qs_master_uploadAdditionalInsured.readOnly = true;
    this.qs_master_uploadAdditionalInsured.tabId = this.tabId;
    this.qs_master_uploadAdditionalInsured.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_fire_freeInsuranceNoYear = new QuestionEntity(
      "qs_fire_freeInsuranceNoYear",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_fire_freeInsuranceNoYear.displayText =
      "No. of Years – Free Insurance";
    this.qs_fire_freeInsuranceNoYear.required = false;
    this.qs_fire_freeInsuranceNoYear.answer = 0;
    this.qs_fire_freeInsuranceNoYear.tabId = this.tabId;
    this.qs_fire_freeInsuranceNoYear.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_insuredNo2 = new QuestionEntity(
      "qs_master_insuredNo2",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredNo2.displayText =
      "Business Registeration No / NRIC - 2nd Insured";
    this.qs_master_insuredNo2.required = false;
    this.qs_master_insuredNo2.readOnly = true;
    this.qs_master_insuredNo2.tabId = this.tabId;
    this.qs_master_insuredNo2.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
    this.qs_master_insuredNo2.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_insuredNo2Nationality = new QuestionEntity(
      "qs_master_insuredNo2Nationality",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredNo2Nationality.displayText = "Nationality";
    this.qs_master_insuredNo2Nationality.required = false;
    this.qs_master_insuredNo2Nationality.readOnly = false;
    this.qs_master_insuredNo2Nationality.tabId = this.tabId;
    this.qs_master_insuredNo2Nationality.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_insuredNo2DocType = new QuestionEntity(
      "qs_master_insuredNo2DocType",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredNo2DocType.displayText = "Document Type";
    this.qs_master_insuredNo2DocType.required = false;
    this.qs_master_insuredNo2DocType.readOnly = false;
    this.qs_master_insuredNo2DocType.tabId = this.tabId;
    this.qs_master_insuredNo2DocType.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
    this.qs_master_insuredNo2DocType.options = [
      { key: "S - Singapore NRIC", value: "1" },
      { key: "S - Singapore PR", value: "2" },
      { key: "M - Malaysia IC", value: "3" },
      { key: "P - Foreign Passport", value: "4" },
    ];

    this.qs_master_namesOtherInsured = new QuestionEntity(
      "qs_master_namesOtherInsured",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_namesOtherInsured.displayText = "Names other than Insured";
    this.qs_master_namesOtherInsured.required = false;
    this.qs_master_namesOtherInsured.tabId = this.tabId;
    this.qs_master_namesOtherInsured.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_borrowerCIN = new QuestionEntity(
      "qs_master_borrowerCIN",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_borrowerCIN.displayText = "Borrower GCIN";
    this.qs_master_borrowerCIN.required = false;
    this.qs_master_borrowerCIN.tabId = this.tabId;
    this.qs_master_borrowerCIN.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.masterDataService.getNationalityList().subscribe((response) => {
      this.nationalityList = response;
      this.qs_master_insuredNoNationality!.options = this.nationalityList;
      this.qs_master_insuredNo2Nationality!.options = this.nationalityList;
    });

    this.qs_master_premiumPayment = new QuestionEntity(
      "qs_master_premiumPayment",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_premiumPayment.displayText = "Premium Payment";
    this.qs_master_premiumPayment.required = true;
    this.qs_master_premiumPayment.tabId = this.tabId;
    this.qs_master_premiumPayment.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_customerType = new QuestionEntity(
      "qs_master_customerType",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_customerType.displayText = "Customer Type";
    this.qs_master_customerType.required = true;
    this.qs_master_customerType.tabId = this.tabId;
    this.qs_master_customerType.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_collateralNumber = new QuestionEntity(
      "qs_master_collateralNumber",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_collateralNumber.displayText = "Collateral No";
    this.qs_master_collateralNumber.required = true;
    this.qs_master_collateralNumber.tabId = this.tabId;
    this.qs_master_collateralNumber.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_discountCode = new QuestionEntity(
      "qs_master_discountCode",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_discountCode.displayText = "Code";
    this.qs_master_discountCode.required = false;
    this.qs_master_discountCode.tabId = this.tabId;
    this.qs_master_discountCode.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_fire_outstandingLoanValue = new QuestionEntity(
      "qs_fire_outstandingLoanValue",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_outstandingLoanValue.displayText = "Outstanding Loan Value";
    this.qs_fire_outstandingLoanValue.required = false;
    this.qs_fire_outstandingLoanValue.tabId = this.tabId;
    this.qs_fire_outstandingLoanValue.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_fire_coverageValueType = new QuestionEntity(
      "qs_fire_coverageValueType",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_fire_coverageValueType.displayText = "Coverage Value Type";
    this.qs_fire_coverageValueType.required = true;
    this.qs_fire_coverageValueType.tabId = this.tabId;
    this.qs_fire_coverageValueType.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_customDiscountCode = new QuestionEntity(
      "qs_master_customDiscountCode",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_customDiscountCode.displayText = "Custom Code";
    this.qs_master_customDiscountCode.required = false;
    this.qs_master_customDiscountCode.tabId = this.tabId;
    this.qs_master_customDiscountCode.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
  }

  refreshQuestionsArray() {
    this.questions = [];
    this.questions.push(this.qs_master_brokerFirstName!);
    this.questions.push(this.qs_master_brokerLastName!);
    this.questions.push(this.qs_master_brokerOrganizationName!);

    this.questions.push(this.qs_master_insuredNo!);
    this.questions.push(this.qs_master_insuredNoNationality!);
    this.questions.push(this.qs_master_insuredNoDocType!);
    this.questions.push(this.qs_master_insuredName!);
    this.questions.push(this.qs_master_replacePolicyNo!);
    this.questions.push(this.qs_master_masterPolicyNumber!);
    this.questions.push(this.qs_master_planType!);
    this.questions.push(this.qs_master_poi_startDate!);
    this.questions.push(this.qs_master_poi_endDate!);

    //// 12/10/2018 : Chirag Added Code For FreeInsurance Start/End Date
    this.questions.push(this.qs_master_FreeIns_startDate!);
    this.questions.push(this.qs_master_FreeIns_endDate!);

    this.questions.push(this.qs_master_financialBranch!);
    this.questions.push(this.qs_master_bankReferNumber!);
    this.questions.push(this.qs_master_assetClassCode!);
    this.questions.push(this.qs_master_assetSerialNumber!);
    this.questions.push(this.qs_master_effectiveDate!);

    this.questions.push(this.qs_location_occupation!);
    this.questions.push(this.qs_location_riskAddress1!);
    this.questions.push(this.qs_location_riskAddress2!);

    this.questions.push(this.qs_risk_floating!);
    this.questions.push(this.qs_risk_sameAsAddress!);
    this.questions.push(this.qs_risk_insuredAddress_building!);
    this.questions.push(this.qs_risk_insuredAddress_lotunit!);
    this.questions.push(this.qs_risk_insuredAddress1!);
    this.questions.push(this.qs_risk_insuredAddress2!);
    this.questions.push(this.qs_risk_insuredPostalCode!);
    this.questions.push(this.qs_risk_insuredCity!);
    this.questions.push(this.qs_risk_insuredState!);
    this.questions.push(this.qs_risk_insuredCountry!);

    //// 24/01/2019 : Chirag Added Code For Mobile/Email
    ////DBSEP-252 : Client Info Update
    this.questions.push(this.qs_master_preferredDeliveryMethod_Email!);
    this.questions.push(this.qs_master_preferredDeliveryMethod_MobileNo!);
    this.questions.push(this.qs_master_preferredDeliveryMethod_CorrAddress!);
    this.questions.push(this.qs_master_mobileNumber!);
    this.questions.push(this.qs_master_emailID!);
    this.questions.push(this.qs_master_salutation!);
    this.questions.push(this.qs_master_clientName!);
    this.questions.push(this.qs_master_passport!);
    this.questions.push(this.qs_master_nric!);
    this.questions.push(this.qs_master_occupation!);
    this.questions.push(this.qs_master_birthDate!);
    this.questions.push(this.qs_master_race!);
    this.questions.push(this.qs_master_nationality!);
    this.questions.push(this.qs_master_gender!);
    this.questions.push(this.qs_master_maritalStatus!);
    this.questions.push(this.qs_master_phoneNoHome!);
    this.questions.push(this.qs_master_phoneNoOffice!);
    this.questions.push(this.qs_master_faxNumber!);
    this.questions.push(this.qs_master_cin!);
    this.questions.push(this.qs_master_preferredDeliveryMethod!);
    this.questions.push(this.qs_master_insuredAddress_building!);
    this.questions.push(this.qs_master_insuredAddress_lotunit!);
    this.questions.push(this.qs_master_insuredAddress1!);
    this.questions.push(this.qs_master_insuredAddress2!);
    this.questions.push(this.qs_master_insuredPostalCode!);
    this.questions.push(this.qs_master_insuredCity!);
    this.questions.push(this.qs_master_insuredState!);
    this.questions.push(this.qs_master_insuredCountry!);

    // this.questions.push(this.qs_fire_situation);
    this.questions.push(this.qs_fire_occupied!);
    this.questions.push(this.qs_master_miRate!);
    this.questions.push(this.qs_fire_notes!);
    this.questions.push(this.qs_fire_excess!);
    this.questions.push(this.qs_fire_mcst!);
    this.questions.push(this.qs_fire_replacement!);
    this.questions.push(this.qs_fire_generalPage!);
    this.questions.push(this.qs_fire_businessDesc!);
    this.questions.push(this.qs_fire_interestedParties!);
    this.questions.push(this.qs_fire_discussionNotes!);

    // New IBG fields
    this.questions.push(this.qs_master_buPcCode!);
    this.questions.push(this.qs_master_uploadBuPcCode!);
    this.questions.push(this.qs_master_busReg1stInsured!);
    this.questions.push(this.qs_master_busReg2ndInsured!);
    this.questions.push(this.qs_master_additionalInsured!);
    this.questions.push(this.qs_master_otherInsuredName!);
    this.questions.push(this.qs_master_rmName!);
    this.questions.push(this.qs_master_rmContactNo!);
    this.questions.push(this.qs_master_rmRemarks!);
    this.questions.push(this.qs_master_insuredAddress!);
    this.questions.push(this.qs_master_insuredNo2!);
    this.questions.push(this.qs_master_insuredNo2Nationality!);
    this.questions.push(this.qs_master_insuredNo2DocType!);
    this.questions.push(this.qs_master_namesOtherInsured!);
    this.questions.push(this.qs_master_borrowerCIN!);

    this.questions.push(this.qs_fire_natureBusiness!);
    this.questions.push(this.qs_fire_usagePremises!);
    this.questions.push(this.qs_fire_typeProperty!);
    this.questions.push(this.qs_fire_freeInsuranceFirstYear!);
    this.questions.push(this.qs_fire_nameSolicitors!);
    this.questions.push(this.qs_fire_equipment_description!);
    this.questions.push(this.qs_fire_equipment_type!);
    this.questions.push(this.qs_fire_equipment_financing!);
    this.questions.push(this.qs_fire_sumOfInsured!);
    this.questions.push(this.qs_fire_building!);
    this.questions.push(this.qs_fire_householdContents!);
    this.questions.push(this.qs_machinery_equipment!);
    this.questions.push(this.qs_risk_uploadRiskAddress!);
    this.questions.push(this.qs_master_endorse_endorsementReason!);
    this.questions.push(this.qs_master_refund!);
    this.questions.push(this.qs_master_waiveMinimumPremium!);
    this.questions.push(this.qs_master_cancellation_masterpiece!);
    this.questions.push(this.qs_master_cancellation_maximumAdjustmentAmount!);
    this.questions.push(this.qs_master_cancellation_adjustmentAmount!);
    this.questions.push(this.qs_master_insuredIndustry!);
    this.questions.push(this.qs_master_occupancyRate!);
    this.questions.push(this.qs_master_insuredIndustry_desc!);

    this.questions.push(this.qs_master_dateOfBirthOrIncorporation!);
    this.questions.push(this.qs_master_clientContactPerson!);
    this.questions.push(this.qs_master_clientContactPersonContactNo!);
    this.questions.push(this.qs_machinery_uploadSumInsured!);
    this.questions.push(this.qs_machinery_uploadTypeOfEquipment!);
    this.questions.push(this.qs_machinery_uploadEquipmentDescription!);
    this.questions.push(this.qs_machinery_uploadTypeOfFinancing!);
    this.questions.push(this.qs_fire_freeInsuranceNoYear!);
    this.questions.push(this.qs_master_uploadAdditionalInsured!);

    this.questions.push(this.qs_master_lst!);
    this.questions.push(this.qs_master_lstDetails!);

    this.questions.push(this.qs_master_manualUnderwritting!);

    //Citi
    this.questions.push(this.qs_master_premiumPayment!);
    this.questions.push(this.qs_master_customerType!);
    this.questions.push(this.qs_master_collateralNumber!);
    this.questions.push(this.qs_master_discountCode!);
    this.questions.push(this.qs_fire_outstandingLoanValue!);
    this.questions.push(this.qs_fire_coverageValueType!);
    this.questions.push(this.qs_master_customDiscountCode!);
  }
}
