import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { ProposalEntity } from "src/app/models/new-product/base/proposal-entity.model";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { MasterPolicyService } from "src/app/services/master-policy.service";
import { ProductService } from "src/app/services/new-product/base/product.service";
import { ProposalActivityService } from "src/app/services/new-product/proposal-activity.service";
import { Global } from "src/app/shared/global";
import { BaseSubscriptionComponent } from "../base-subscription/base-subscription.component";

@Component({
  selector: "app-base-main",
  templateUrl: "./base-main.component.html",
  styleUrls: ["./base-main.component.scss"],
})
export class BaseMainComponent
  extends BaseSubscriptionComponent
  implements OnInit
{
  sub: any;

  /** Flag to determine if document is completely rendered */
  documentReady: boolean = false;
  productListing: any;
  productName!: string;
  category: string = "";
  submittedDate!: string;
  isDbsProduct: boolean = false;
  isCitiProduct: boolean = false;
  isHaseProduct: boolean = false;
  isNewBusiness: boolean = false;
  pphRecordCount: number = 0;
  productId?: string;

  constructor(
    public route: ActivatedRoute,
    public proposalActivityService: ProposalActivityService,
    public masterPolicyService: MasterPolicyService,
    public identityRolesService: IdentityRolesService,
    public masterDataService: MasterDataService,
    public cdRef: ChangeDetectorRef,
    public productService: ProductService
  ) {
    super();
    super.subscribePageLoadedNotifier(this.pageLoaded);
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalFailedNotifier(this.onProposalFailed);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
  }

  ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe((params) => {
      this.productId = params["pdid"];
      //loadProposal() depends on this, hence must load before it in subscribe()
      this.instantiateProposalObject();

      // Instantiate DBS master data
      this.initProposalDateSubmitted(params).subscribe((date) => {
        this.loadProposal();
      });
    });
  }

  initProposalDateSubmitted(params: { [x: string]: any }): Observable<any> {
    const proposalId =
      params["pid"] === "00000000-0000-0000-0000-000000000000"
        ? null
        : params["pid"];
    let sDate: any;
    if (Global.isDefined(proposalId)) {
      return this.masterPolicyService.getProposalDateSubmitted(proposalId).pipe(
        map(
          (date: any) => {
            sDate = date;
            this.submittedDate = sDate;
            return this.submittedDate;
          },
          (error: any) => {
            return error;
          }
        )
      );
    } else {
      sDate = new Date();
      this.submittedDate = sDate;
      return of(this.submittedDate);
    }
  }

  public $postLink = () => {
    // do nothing
  };

  ngOnDestroy() {
    this.sub?.unsubscribe();
    super.unsubscribeRxjs();
  }

  //only show the <ct-sg-premium-period-history> control when it's not NB
  public showPremiumPeriodHistory() {
    return this.isCitiProduct && this.pphRecordCount > 0 && !this.isNewBusiness;
  }

  scrollToTop = () => {
    //$("html, body").animate({ scrollTop: 0 }, 500);
    window.scrollTo(0, 0);
  };

  /** Method to create a proposal instance */
  protected instantiateProposalObject() {}

  protected proposalLoaded = (event: KeyValue<Proposal>) => {};

  //https://stackoverflow.com/questions/34547127/angular2-equivalent-of-document-ready
  ngAfterViewInit() {
    //todo: check if this works
    this.pageLoaded();
    //https://stackoverflow.com/questions/53767289/angular-error-error-expressionchangedafterithasbeencheckederror-expression-ha
    this.cdRef.detectChanges();
  }

  private pageLoaded = (/*event: KeyValue<Proposal>*/) => {
    //todo
    //$(document).ready(() => {
    setTimeout(() => {
      this.productListing =
        this.identityRolesService.productListing !== undefined
          ? this.identityRolesService.productListing
          : "";

      this.productListing.forEach((product: { pdid: string; pd: string }) => {
        if (product.pdid.toLowerCase() === this.productId!.toLowerCase()) {
          this.productName = product.pd;
        }
      });

      this.documentReady = true;
    }, 60);
    //});
  };

  protected loadProposal() {}

  protected onProposalFailed = (event: KeyValue<any>) => {
    // const echos = event.value as Echos<QuestionEntity>[];
    // this.resetMandatoryTabColor();
    // this.setMandatoryTabColor(echos);
  };

  protected setMandatoryTabColor = (echos: any) => {
    // if (common.isDefined(echos) && echos.length > 0) {
    //     echos.forEach((echo, index) => {
    //         if (echo.echos.length > 0) {
    //             echo.echos.forEach(echoItem => {
    //                 switch (echoItem.info) {
    //                     case 0:
    //                         angular.element('li#master').addClass('k-state-mandatory');
    //                         break;
    //                     case 1:
    //                         angular.element('li#premiumSummary').addClass('k-state-mandatory');
    //                         break;
    //                 }
    //             });
    //         }
    //     });
    // }
  };

  protected resetMandatoryTabColor = () => {
    // // reset all the kendo tab for mandatory class
    // angular.element('li#master').removeClass('k-state-mandatory');
    // angular.element('li#premiumSummary').removeClass('k-state-mandatory');
  };

  protected proposalSaved = (event: KeyValue<any>) => {};

  private doTransition(returnEntity: ProposalEntity, action: any): void {
    // // refresh the URL only
    // const productId: string = returnEntity.productId.toString().toLocaleUpperCase();
    // let productRuote = '';
    // // let url = window.location.origin + window.location.pathname + '#/proposal';
    // switch (productId) {
    //     case Const.ProductID.PRODUCT_ID_DBS_MORTGAGEINTERESTPLUS:
    //         productRuote = 'proposal.dbssgpte';
    //         // url = url + '/MortgageeInterestPlusMIP/' + productId + '/' + returnEntity.proposalId + '/' + action;
    //         break;
    //     case Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSPTE:
    //         productRuote = 'proposal.dbssgpte';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSHDB:
    //         productRuote = 'proposal.dbssghdb';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_DBS_MF_IBG:
    //         productRuote = 'proposal.dbssgibgmf';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_DBS_MACH_IBG:
    //         productRuote = 'proposal.dbssgibgmac';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_CITI_HOUSEOWNERPLUSHDB:
    //         productRuote='proposal.citisghdb';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_CITI_MORTGAGEFIREINSURANCEPTE:
    //         productRuote= 'proposal.citisgmfpte';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_CITI_MORTGAGEFIREINSURANCECONDO:
    //         productRuote = 'proposal.citisgmfcondo';
    //         break;
    //     default:
    // }
    // // history.pushState(null, '', url);
    // this.$state.transitionTo(productRuote, {
    //     pdid: productId,
    //     mpid: returnEntity.proposalId,
    //     pid: returnEntity.proposalId,
    //     action: '',
    //     type: '1',
    // }, { reload: false });
  }
}
