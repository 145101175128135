<!-- #documentPrintReportFields -->
<form #heroForm="ngForm">
  <div id="documentPrintReportFields" class="container-fluid reportOption">
    <div *ngIf="showReportType" class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-2">
            <label class="mt-1">Product Type</label>
          </div>
          <div class="col-md-2">
            <input
              type="radio"
              name="productRadio"
              value="dbs"
              [(ngModel)]="productRadioControl"
              (change)="onChange_ProductRadio()"
            />
            <label class="mt-1 ml-1" for="oiProductRadio">DBS</label>
          </div>
          <div class="col-md-3">
            <input
              type="radio"
              name="productRadio"
              value="citi"
              [(ngModel)]="productRadioControl"
              (change)="onChange_ProductRadio()"
            />
            <label class="mt-1 ml-1" for="oiProductRadio">Citi</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-2">
            <label class="marginTop5" for="boundDateFrom"
              >Transaction Bound Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="boundDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.boundDateFrom"
              name="boundDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1">
            <label class="mt-1" for="boundDateTo">To</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="boundDateTo"
              #boundDateTo
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.boundDateTo"
              name="boundDateTo"
            ></kendo-datepicker>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-3">
            <label class="mt-1" for="PDInsuredName">Insured Name</label>
          </div>
          <div class="col-md-5">
            <input
              type="text"
              class="form-control"
              id="PDInsuredName"
              [(ngModel)]="searchCriteria.insuredName"
              name="insuredName"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-2">
            <label class="mt-1" for="reportProduct">Product</label>
          </div>
          <div class="col-md-9">
            <kendo-multiselect
              id="PDreportProduct"
              placeholder="Select product..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="userProductList"
              textField="pd"
              valueField="pdid"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.productIds"
              name="productIds"
            >
            </kendo-multiselect>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-3">
            <label class="mt-1" for="PolicyNumber">Policy Number</label>
          </div>
          <div class="col-md-5">
            <input
              type="text"
              class="form-control"
              id="PDPolicyNumber"
              [(ngModel)]="searchCriteria.policyNo"
              name="policyNo"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button
        (click)="search()"
        class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
      >
        <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
      </button>
      <button
        (click)="download()"
        class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
      >
        <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
      </button>
    </div>
  </div>
</form>
<br />
<div class="grid-wrapper ml-2">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column field="type" title="Type" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column
      field="nameOfFolder"
      title="Name Of Folder"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="link" title="Link" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="noOfPages" title="No.Of Pages" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="combinePDFName"
      title="Combine PDF Name"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column field="product" title="Product" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="policyNo" title="Policy Number" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="documentType" title="Document Type" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="transactionBoundDate"
      title="Transaction Bound Date"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="transactedByUserName"
      title="Transacted By User Name"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="savedByOrganisation"
      title="Saved By Organisation"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="channelOfDoc"
      title="Channel Of Doc"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="correspondenceAddress"
      title="Correspondence Address"
      [width]="160"
    >
    </kendo-grid-column>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
