<div *ngIf="tabRequested" id="clausesDeductiblesTab">
  <div *ngIf="tabShow">
    <div class="row" id="clausesDeductiblesTabContainer">
      <div class="container-fluid">
        <kendo-expansionpanel
          id="deductibles-panel"
          title="Deductibles"
          [expanded]="true"
        >
          <app-clauses-deductibles
            [resultList]="resultDeductible"
            [nonDefaultList]="nonDefaultDeductibles"
            [masterList]="masterListData"
            [isreadonly]="isreadonly()"
            [isAnswered]="isAnswered()"
            (resultListDataChange)="updateList($event)"
          ></app-clauses-deductibles>
        </kendo-expansionpanel>

        <div *ngIf="showExtraPerils()">
          <kendo-expansionpanel
            id="extraPerils-panel"
            title="Extra Perils"
            [expanded]="true"
          >
            <app-clauses-deductibles
              [resultList]="resultExtraPerils"
              [nonDefaultList]="nonDefaultExtraPerils"
              [masterList]="masterListData"
              [isreadonly]="isreadonly()"
              [isAnswered]="isAnswered()"
              (resultListDataChange)="updateList($event)"
            ></app-clauses-deductibles>
          </kendo-expansionpanel>
        </div>
        <kendo-expansionpanel
          id="clausesAndWarrenties-panel"
          title="Clauses And Warranties"
          [expanded]="true"
        >
          <app-clauses-deductibles
            [resultList]="resultClauses"
            [nonDefaultList]="nonDefaultClauses"
            [masterList]="masterListData"
            [isreadonly]="isreadonly()"
            [isAnswered]="isAnswered()"
            (resultListDataChange)="updateList($event)"
          ></app-clauses-deductibles>
        </kendo-expansionpanel>
        <kendo-expansionpanel
          id="additionalClausesAndExclusions-panel"
          title="Additional Clauses / Exclusions"
          [expanded]="true"
        >
          <app-clauses-deductibles
            [resultList]="resultAdditionalClauses"
            [nonDefaultList]="nonDefaultAdditionalClauses"
            [masterList]="masterListData"
            [isreadonly]="isreadonly()"
            [isAnswered]="isAnswered()"
            (resultListDataChange)="updateList($event)"
          ></app-clauses-deductibles>
        </kendo-expansionpanel>
      </div>
    </div>
  </div>
</div>
