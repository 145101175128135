import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Validators, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { RmScreenListingEntity } from "../RmScreenListingEntity";

@Component({
  selector: "app-rm-grid-edit-form",
  templateUrl: "./rm-grid-edit-form.component.html",
  styleUrls: ["./rm-grid-edit-form.component.scss"],
})
export class RmGridEditFormComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  public active = false;
  public editForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(""), //new FormControl("", Validators.required),//id is empty when new
    rMName: new UntypedFormControl("", Validators.required),
    teamName: new UntypedFormControl("", Validators.required),
    pcCode: new UntypedFormControl("", [Validators.required, Validators.maxLength(4)]),
    officeNumber: new UntypedFormControl(""),
  });

  @Input() public isNew = false;

  @Input() public set model(rmScreenEntity: RmScreenListingEntity | undefined) {
    this.editForm.reset(rmScreenEntity);

    // toggle the Dialog visibility
    this.active = rmScreenEntity !== undefined;
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<RmScreenListingEntity> = new EventEmitter();

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.save.emit(this.editForm.value);
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }
}
