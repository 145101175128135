<div class="col-md-12 chubbWhite_bg" style="display: flex">
  <i
    class="fa fa-2x fa-remove paddingTop3 tabNav marginLeftTop10px chubbRed cursorPointer"
    (click)="dialog.close()"
    title="Close"
  ></i>
  <div class="col-md-10">
    <span class="card-head-agency">Profile Details</span>
  </div>
</div>
<div class="col-md-12 card-body cardBodyBorder">
  <div style="margin: 10px">
    <div class="row" style="padding: inherit">
      <div class="col-md-3" align="left">Name</div>
      <div class="col-md-5" align="left">
        <label>{{ data.details.userName }}</label>
      </div>
      <div class="col-md-2" align="left"></div>
      <div class="col-md-2" align="left"></div>
    </div>
    <br />
    <div class="row" style="padding: inherit">
      <div class="col-md-3" align="left">User Login</div>
      <div class="col-md-5" align="left">
        <label>{{ data.details.emailAddress }}</label>
      </div>
      <div class="col-md-2" align="left"></div>
      <div class="col-md-2" align="left"></div>
    </div>
    <br />
    <div class="row" style="padding: inherit">
      <div class="col-md-6" align="left"></div>
    </div>
    <div class="row" style="padding: inherit" *ngIf="isExternalUser">
      <div class="col-md-12 textAlignLeft">
        <div
          class="btn chubbRed chubbRed_border_btn chubbWhite_bg"
          (click)="changePassword()"
        >
          Change Password
        </div>
      </div>
    </div>
    <div class="row" style="padding: inherit" *ngIf="isProfileDetailsUpdated">
      <span class="chubbRed fontSize14pt">Your details have been updated.</span>
    </div>
  </div>
</div>
