export class LSTSearchCriteria {
  constructor() {
    //this.effectiveDateFrom = null;
    //this.effectiveDateTo = null;
    //this.boundDateFrom = null;
    //this.boundDateTo = null;
    //this.startDateFrom = null;
    //this.startDateTo = null;
    //this.expiryDateFrom = null;
    //this.expiryDateTo = null;
    //this.policyNo = null;
    //this.insuredName = null;
    //this.transType = null;
    //this.productIds = null;
  }

  effectiveDateFrom?: Date;
  effectiveDateTo?: Date;
  boundDateFrom?: Date;
  boundDateTo?: Date;
  startDateFrom?: Date;
  startDateTo?: Date;
  expiryDateFrom?: Date;
  expiryDateTo?: Date;
  policyNo!: string;
  insuredName!: string;
  transType!: Array<string>;
  productIds!: Array<string>;
}
