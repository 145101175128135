import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "../dialog/dialog-ref";
import { DIALOG_DATA } from "../dialog/dialog-tokens";
import {
  dlgModelArgs,
  v1DlgInputArgs,
} from "../md-confirmation-message-v1/md-confirmation-message-v1.component";

@Component({
  selector: "app-md-confirmation-message-with-reason",
  templateUrl: "./md-confirmation-message-with-reason.component.html",
  styleUrls: ["./md-confirmation-message-with-reason.component.scss"],
})
export class MdConfirmationMessageWithReasonComponent implements OnInit {
  public data: any;
  isProcessing = false;
  model = {};
  confirmationReason = "";
  warning_message = false;
  confirmLabel = "Return To Revise Reason:";

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public inputData: confirmationMessageWithReasonInputArgs
  ) {}

  ngOnInit(): void {
    if (this.inputData) {
      this.data = this.inputData;

      if (this.inputData.tabClicked == 9) {
        this.confirmLabel = "Decline Reason:";
      } else if (this.inputData.tabClicked == 45) {
        this.confirmLabel = "Return To Revise Reason:";
      }
      this.data.tabClicked = this.inputData.tabClicked;
    }
  }

  confirmation(flag: any) {
    this.data.confirmation = flag;
    if (this.data.confirmation) {
      if (this.confirmationReason != "") {
        this.data.message = this.confirmationReason;
        this.close("yes");
      } else {
        this.warning_message = true;
      }
    } else {
      this.close("no");
    }
  }

  public close(status: any) {
    let savParamters: dlgModelArgs = {
      status: status,
      data: this.data,
    };

    this.dialogRef?.close(savParamters);
  }
}

export interface confirmationMessageWithReasonInputArgs {
  data: any;
  tabClicked: any;
}
