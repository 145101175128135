<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>

  <div *ngIf="!question.readOnly">
    <!--https://stackoverflow.com/questions/39760956/how-to-bind-maxlength-attribute-->
    <textarea
      id="{{ question.key }}_textBox"
      name="{{ question.key }}"
      type="text"
      ngModel
      maxlength="{{ question.maxLength }}"
      [(ngModel)]="question.answer"
      class="k-textbox form-control"
      ng-change="change()"
      [disabled]="question.isDisabled!"
    >
    </textarea>
  </div>

  <div *ngIf="question.readOnly">
    <p
      [textContent]="question.answer"
      *ngIf="question.isAnswered()"
      class="wordBreak"
    ></p>
    <span
      class="unattended"
      *ngIf="!question.isAnswered()"
      [textContent]="noAnswerDefaultText"
    ></span>
  </div>
</div>
