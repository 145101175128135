import * as Interface from "../../framework/interface.shared";
import { Injectable } from "@angular/core";
import { ApiService } from "../dal/api.service";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { ErrorHandlerService } from "../error-handler/error-handler.service";
import { DialogWrapperService } from "../dialog/dialog-wrapper.service";
import { saveAs } from "@progress/kendo-file-saver";

@Injectable({
  providedIn: "root",
})
export class DocumentService extends ApiService {
  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  /** Method to verify if current browser is supported for download */
  private checkBrowserSupport(): boolean {
    var userAgent = window.navigator.userAgent;

    // If Internet Explorer, return version number, otherwise returns -1 for other browsers
    var msie = userAgent.indexOf("MSIE ");

    if (msie > 0) {
      alert("Current browser does not support file download.");
      return false;
    }
    return true;
  }

  private generateAnchorAndTriggerDownload(
    fileData: Interface.IFileDownloadResponseModel
  ): void {
    // var anchor = angular.element('<a/>');
    var file = new Blob([fileData.data], { type: "application/octet-stream" });

    //implement for IE usage
    const nav = window.navigator as any;
    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(file, fileData.headerFileName);
    } else {
      let url = window.URL.createObjectURL(file);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = fileData.headerFileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  downloadProposalDocument(parameters: any) {
    this.download("api/document/getProposalDocument", parameters).subscribe(
      (response: HttpResponse<Blob>) => {
        var conDis = response.headers.get("content-disposition");
        if (conDis != null) {
          let fileName = conDis.split("filename=")[1].split(";")[0];
          if (fileName) this.downLoadFile(response.body, fileName);
        }
      }
    );
  }


  // downloadStaticDocument(parameters: any): ng.IPromise<any> {
  //     let deferred = this.$q.defer();

  //     Api.instance.download('api/document/getStaticDocument', parameters)
  //         .then((response: Interface.IFileDownloadResponseModel) => {

  //             this.generateAnchorAndTriggerDownload(response);

  //             deferred.resolve(true);
  //         });

  //     return deferred.promise;
  // }

  // downloadDocument(parameters: any): ng.IPromise<any> {

  //     // if(Global.isUnDefined(productId)){
  //     //     if (!this.checkBrowserSupport()) return;
  //     // }
  //     // else {
  //     //     if(productId.toLocaleLowerCase() !== Const.ProductID.PRODUCT_ID_AUS_BizPack.toLocaleLowerCase()){
  //     //         if (!this.checkBrowserSupport()) return;
  //     //     }
  //     // }

  //     let deferred = this.$q.defer();

  //     this.Api.download('api/document/get', parameters)
  //         .then((response: Interface.IFileDownloadResponseModel) => {

  //             this.generateAnchorAndTriggerDownload(response);

  //             deferred.resolve(true);
  //         });

  //     return deferred.promise;
  // }

  downloadDocument(parameters: any) {
    // this.download("api/document/get", parameters).subscribe(
    //   (response: Interface.IFileDownloadResponseModel) => {
    //     this.generateAnchorAndTriggerDownload(response);
    //   }
    // );
    this.download("api/document/get", parameters).subscribe(
      (response: HttpResponse<Blob>) => {
        var conDis = response.headers.get("content-disposition");
        if (conDis != null) {
          let fileName = conDis.split("filename=")[1].split(";")[0];
          if (fileName) this.downLoadFile(response.body, fileName);
        }
      }
    );
  }

  private downLoadFile(data: any, fileName: string) {
    let blob = new Blob([data]);
    let url = window.URL.createObjectURL(blob);
    saveAs(url, fileName);
  }
}
