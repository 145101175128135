export class DataPostPrintSearchCriteria {
  constructor() {
    this.productRadio = "dbs";
    //this.boundDateFrom = null;
    //this.boundDateTo = null;
    //this.policyNo = null;
    //this.insuredName = null;
    //this.productIds = [];
  }
  productRadio: string;
  boundDateFrom?: Date;
  boundDateTo?: Date;
  policyNo!: string;
  insuredName!: string;
  productIds!: Array<string>;
}
