
export class PolicyTypeEntity {
    productType!: string;
    policyType!: string;
}
export class PolicyWordingEntity {
    id: any;
    productType!: any;
    policyType!: Array<string>;
    policyTypeDesc!: string ;
    documentVersion!: string;
    documentTitle!: any;
    policyWordingDocumentSetupId!: any ;
    startEffectiveDate!: Date ;
    endEffectiveDate!: Date ;
    documentPath!: any ;
    documentName!: any ;
    updatedBy!: any;
    updatedDate!: Date ;
    files!: any;
    constructor() {
        this.id = null;
        this.productType = null;
        //this.policyType = null;
        //this.documentVersion = null;
        this.documentTitle = null;
        this.policyWordingDocumentSetupId = null;
        // this.startEffectiveDate = null;
        // this.endEffectiveDate = null;
        this.documentPath = null;
        this.documentName = null;
        // this.updatedBy = null;
        // this.updatedDate = null;
        // this.files = null;
    }

}






