import { Component, Input, OnInit } from "@angular/core";
import * as Enum from "./../../../../../../../../framework/enum.shared";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import { MfSgBaseComponent } from "../../mf-sg-base.component";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { Global } from "src/app/shared/global";
import { Culture, dateFormatter } from "src/app/shared/date-formatter";
import { MortgageFireSGTabSchema } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/schemas/mf-sg-tab-schema";
import { componentHelper } from "src/app/models/new-product/utils/component.helper";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { MdConfirmationMessageV1Component } from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
@Component({
  selector: "ct-sg-premium-summary",
  templateUrl: "./ct-sg-premium-summary.component.html",
})
export class CtSgPremiumSummaryComponent
  extends MfSgBaseComponent
  implements OnInit
{
  @Input() proposal!: MortgageFireSingaporeProposal; //temp using @Input()

  quote!: Array<any>;
  coverages!: Array<any>;
  premiumDetails!: any;
  premiumSummary!: any;
  fireGrossPremium!: number;
  fireGST!: number;
  fireTotalCharge!: number;
  poiText!: string;
  isCBGProduct!: boolean;
  containEquipment!: boolean;
  isAccepted!: boolean;
  isCancellation!: boolean;
  underwriterReadOnly: boolean = false;
  isIBGProduct!: boolean;
  isPremiumOverridingAllowed!: boolean;
  minPremium!: number;
  maxPremium!: number;
  minPremiumTax!: number;
  maxPremiumTax!: number;
  isIncomplete: boolean = false;
  isEndorse: boolean = false;
  isReferred!: boolean;
  isChecker: boolean = false; //instanceFactory.getIdentity().role.isChecker ? true : false;
  isMaker: boolean = false; //instanceFactory.getIdentity().role.isMaker ? true : false;
  isUnderwriter: boolean = false; //instanceFactory.getIdentity().role.isUnderwriter ? true : false;
  private msg_overidePremium_warning: string =
    "Are you sure you wish to Unlock? " +
    "Once unlocked, user is responsible to manually calculate all open fields. " +
    "There is no option to relock. Click OK to proceed, else please click Cancel.";
  resetIconIE: any;
  hasUpdated!: boolean;
  public editedDiv: any = [];
  constructor(
    public proposalService: MfSgProposalService,
    public identityRolesService: IdentityRolesService,
    private cdkDialogService: CdkDialogService
  ) {
    super();

    this.tabId = MortgageFireSGTabSchema.premiumSummary.index.toString();
    super.subscribeProposalLoadedNotifier(this.proposalSaved);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
    super.subscribeAnswerChangedNotifier(this.answerChanged);

    this.isChecker = this.identityRolesService.isChecker ? true : false;
    this.isMaker = this.identityRolesService.isMaker ? true : false;
    this.isUnderwriter = this.identityRolesService.isUnderwriter ? true : false;
  }

  ngOnInit(): void {
    this.initialize();
    componentHelper.notifyPageLoaded(
      "ct-sg-premium-summary.component",
      this.proposal
    );
  }
  private answerChanged = (kv: KeyValue<QuestionEntity>) => {
    const key = kv.key;
    const question = kv.value;
    switch (key) {
      case this.proposal.qsPremiumSummary.qs_master_manualReferral.key:
        this.qs_master_manualReferToUw_onChange();
        break;
      case this.proposal?.qsMaster.qs_machinery_equipment?.key:
        this.proposalSaved(new KeyValue<Proposal>("", this.proposal));
        break;
      default:
        break;
    }
  };

  private proposalSaved = (event: KeyValue<Proposal>) => {
    // reset the underwrite read only flag

    this.underwriterReadOnly = false;
    this.isAccepted =
      this.proposal.proposalStatus === Enum.ProposalStatus.Accepted
        ? true
        : false;
    this.isCancellation =
      this.proposal.proposalStatus === Enum.ProposalStatus.Cancelled
        ? true
        : false;
    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;
    this.isReferred =
      this.proposal.proposalStatus === Enum.ProposalStatus.Referred
        ? true
        : false;

    if (this.isAccepted || this.isCancellation) {
      this.underwriterReadOnly = true;
    }

    /** DBS CR
     * To set the Gross Premium and Premium Rate fields under ‘Premium Summary’
     * page of IBG products in ePlacement to be editable for the non-standard cases to be manually keyed in ePlacement.
     */
    this.isPremiumOverridingAllowed =
      this.proposal.isUnderwriter &&
      !this.proposal.refreshedProposalEntity.proposalReadOnly &&
      (this.proposal.endorsementType === Enum.EndorsementType.Undefined ||
        this.proposal.endorsementType ===
          Enum.EndorsementType.ChangeOfSumInsured ||
        this.proposal.endorsementType === Enum.EndorsementType.LoanRedemption);

    this.quote = this.proposal.refreshedProposalEntity.quotes!;

    if (Global.isDefined(this.quote) && this.quote.length > 0) {
      // CBG only contains fire risk
      this.coverages = this.quote[0].coverages;
      if (this.coverages.length > 0) {
        this.containEquipment = this.checkEquipmentCoverage();
        this.premiumDetails = this.coverages[0].premiumDetails;
        this.premiumSummary = this.quote[0].premiumSummary;
        this.proposal.qsPremiumSummary.qs_master_commission.answer =
          this.premiumSummary.commission; // commission
        this.proposal.qsPremiumSummary.qs_master_commissionGst.answer =
          this.premiumSummary.commissionTax; // commission on gst
        this.proposal.qsPremiumSummary.qs_master_netCommOffsetWithDiscount.answer =
          this.premiumDetails.netCommOffsetWithDiscount;
        this.proposal.qsPremiumSummary.qs_master_commissionPayable.answer =
          this.premiumDetails.commissionPayable;
        this.fireGrossPremium = this.premiumSummary.premium;
        this.fireGST = this.premiumSummary.premiumTax;
        this.fireTotalCharge = this.premiumSummary.totalPremium;
      }
    }

    this.getPOIText();

    switch (this.proposal.transType) {
      case Enum.TransType.NEW:
      case Enum.TransType.REN:
        this.minPremium = 0;
        this.maxPremium = 100000000;

        this.minPremiumTax = 0;
        this.maxPremiumTax = 100000000;
        break;

      case Enum.TransType.CAN:
        this.minPremium = -100000000;
        this.maxPremium = 0;

        this.minPremiumTax = -100000000;
        this.maxPremiumTax = 0;
        break;

      case Enum.TransType.END:
        this.minPremium = -100000000;
        this.maxPremium = 100000000;

        this.minPremiumTax = -100000000;
        this.maxPremiumTax = 100000000;

        this.isEndorse = true;
        break;
      default:
    }
  };

  getIndex = (index: number) => {
    return index < 1 ? 0 : index;
  };

  private checkEquipmentCoverage = () => {
    let isEquipmentCov: boolean = false;
    this.coverages.forEach((cov) => {
      if (
        cov.coverageProductMappingId !== "f2e78506-3774-4c7d-92d7-c628d50c31e9"
      ) {
        isEquipmentCov = true;
      }
    });

    return isEquipmentCov;
  };

  updatingCBGPremium(data: any, field: any) {
    data = Global.isDefined(data) ? data : 0;

    if (Global.isDefined(data)) {
      if (Global.isDefined(this.quote) && this.quote.length > 0) {
        if (Global.isDefined(this.quote[0])) {
          if (Global.isDefined(this.quote[0].premiumSummary)) {
            if (field === "grossPremium") {
              this.premiumSummary.grossPremium = data;
              this.fireGrossPremium = this.totalToDIsplay(
                data,
                "grossPremium",
                this.maxPremium,
                this.minPremium
              );
            }
            if (field === "premiumTax") {
              this.premiumSummary.premiumTax = data;
              this.fireGST = this.totalToDIsplay(
                data,
                "premiumTax",
                this.maxPremiumTax,
                this.minPremiumTax
              );
            }
          }
        }
      }
    }
  }
  private totalToDIsplay = (
    data: any,
    field: any,
    maxAmount: any,
    minAmount: any
  ): number => {
    data = Global.isDefined(data) ? data : 0;
    let amount: any = 0;
    if (Global.isDefined(data)) {
      if (data > minAmount) {
        if (data > maxAmount) {
          amount = maxAmount;
        } else {
          amount = data;
        }
      } else {
        amount = minAmount;
      }
    }
    return amount;
  };

  private qs_master_manualReferToUw_onChange() {
    this.proposalService.fieldValidationManager.setManualReferralReasonRequires();
    this.proposalService.businessRulesManager.validateManualReferral();

    // Clear manual refer reason details if select 'No'
    if (
      this.proposal.qsPremiumSummary.qs_master_manualReferral.readAnswerAsNumeric() ===
      Enum.TrueFalseQuestionAnswer.No
    ) {
      this.proposal.qsPremiumSummary.qs_master_manualReferralReason.clearAnswer();
    }
  }

  private initialize() {
    this.proposal = this.proposalService.proposal;
    super.subscribeTabLoadRenderer();
    this.premiumDetailsCommissionValue();

    this.isCBGProduct =
      this.proposal.productType === EnumSG.MortgageFireSgProduct.HDB ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.PTE ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.MIP;

    this.isIBGProduct =
      this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMF ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMAC
        ? true
        : false;

    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;
  }

  ngOnDestroy() {
    super.unsubscribeRxjs();
  }

  premiumDetailsCommissionValue = (): void => {
    if (this.proposal) {
      switch (this.proposal.productType) {
        case EnumSG.MortgageFireSgProduct.IBGMF:
        case EnumSG.MortgageFireSgProduct.IBGMAC:
          this.proposal.qsPremiumSummary.qs_master_brokerCommission.answer = 25;
          break;
        default:
          this.proposal.qsPremiumSummary.qs_master_brokerCommission.answer = 36.4;
      }
    }
  };

  getPOIText = () => {
    // const poiStartDate = this.proposal.qsMaster.qs_master_poi_startDate.readAnswerAsString().split(',')[0];
    // const poiEndDate = this.proposal.qsMaster.qs_master_poi_endDate.readAnswerAsString().split(',')[0];

    const poiStartDate = this.dateFormatting(
      this.proposal!.qsMaster.qs_master_poi_startDate!.answer
    );
    const poiEndDate = this.dateFormatting(
      this.proposal!.qsMaster.qs_master_poi_endDate!.answer
    );

    const poiText = "Period From " + poiStartDate + " To " + poiEndDate;

    this.poiText = poiText;
    // console.log(poiStartDate);
    // console.log(poiEndDate);
  };

  dateFormatting = (value: Date) => {
    return dateFormatter.format(value, Culture.enSg);
  };

  private allowPremiumToEdit = (): void => {
    const data = {
      message: this.msg_overidePremium_warning,
    };
    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: data,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForPremiumOveride(result);
      }
    });
  };

  private confirmationModalCloseForPremiumOveride = (result: any): void => {
    if (result.status == "yes") {
      this.proposal!.qsMaster.qs_master_manualUnderwritting!.isDisabled = true;
      this.proposalService.proposal.auxiliary["isPremiumManualOverridden"] =
        true;
      this.isEndorse = true;
      this.proposal!.qsMaster.qs_master_manualUnderwritting!.answer = true;
    }
  };

  updatingPremiumDetails = (
    coverageId: string,
    value: number,
    sectionType: any
  ): void => {
    const premiumData = this.proposal.refreshedProposalEntity.quotes!;
    value = Global.isDefined(value) ? value : 0;

    if (premiumData.length > 0 && Global.isDefined(value)) {
      premiumData[0].premiumSummary.rate = 0;
      premiumData[0].premiumSummary.sumInsured = 0;
      premiumData[0].premiumSummary.grossPremium = 0;
      premiumData[0].premiumSummary.premiumTax = 0;
      premiumData[0].premiumSummary.totalPremium = 0;
      premiumData[0].coverages?.forEach((coverage) => {
        premiumData[0].premiumSummary.rate =
          premiumData[0].premiumSummary.rate! + coverage!.premiumDetails!.rate!;
        premiumData[0].premiumSummary.sumInsured =
          premiumData[0].premiumSummary!.sumInsured! +
          coverage.premiumDetails!.sumInsured!;
        premiumData[0].premiumSummary.grossPremium =
          premiumData[0].premiumSummary!.grossPremium! +
          coverage.premiumDetails!.grossPremium!;
        premiumData[0].premiumSummary.premiumTax =
          premiumData[0].premiumSummary!.premiumTax! +
          coverage.premiumDetails!.premiumTax!;
        premiumData[0].premiumSummary.totalPremium =
          premiumData[0].premiumSummary.totalPremium +
          coverage.premiumDetails!.totalPremium;

        if (coverage.coverageProductMappingId === coverageId) {
          let id: string = sectionType + "_" + coverageId;
          switch (sectionType) {
            case "rate":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.rate = parseFloat(value.toFixed(4));
              break;
            case "sumInsured":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.sumInsured = parseFloat(
                value.toFixed(2)
              );
              break;
            case "grossPremium":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.grossPremium = parseFloat(
                value.toFixed(2)
              );
              coverage.premiumDetails!.premiumOverridden = parseFloat(
                value.toFixed(2)
              );
              break;
            case "premiumTax":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.premiumTax = parseFloat(
                value.toFixed(2)
              );
              break;
            case "totalPremium":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.totalPremium = parseFloat(
                value.toFixed(2)
              );
              break;
            default:
          }
        }
      });
    }
  };

  public resetPremiumDetails = (coverageId: string, sectionType: any): void => {
    const premiumData = this.proposal.refreshedProposalEntity.quotes!;
    if (premiumData.length > 0) {
      premiumData[0].premiumSummary.rate = 0;
      premiumData[0].premiumSummary.sumInsured = 0;
      premiumData[0].premiumSummary.grossPremium = 0;
      premiumData[0].premiumSummary.premiumTax = 0;
      premiumData[0].coverages?.forEach((coverage) => {
        premiumData[0].premiumSummary.sumInsured =
          premiumData[0].premiumSummary.sumInsured! +
          coverage.premiumDetails!.sumInsured!;
        premiumData[0].premiumSummary.grossPremium =
          premiumData[0].premiumSummary.grossPremium! +
          coverage.premiumDetails!.grossPremium!;
        premiumData[0].premiumSummary.premiumTax =
          premiumData[0].premiumSummary.premiumTax! +
          coverage.premiumDetails!.premiumTax!;
        premiumData[0].premiumSummary.rate =
          premiumData[0].premiumSummary.totalPremium +
          coverage.premiumDetails!.totalPremium;

        if (coverage.coverageProductMappingId === coverageId) {
          let id: string = sectionType + "_" + coverageId;
          switch (sectionType) {
            case "rate":
              premiumData[0].premiumSummary.rate =
                premiumData[0].premiumSummary.rate -
                coverage!.premiumDetails!.rate!;
              if (coverage!.premiumDetails!.rate !== 0) {
                this.editedDiv[id] = true;
              }
              coverage!.premiumDetails!.rate = 0;
              break;
            case "sumInsured":
              premiumData[0].premiumSummary.sumInsured =
                premiumData[0].premiumSummary.sumInsured -
                coverage.premiumDetails!.sumInsured!;
              if (coverage.premiumDetails!.sumInsured !== 0) {
                this.editedDiv[id] = true;
              }
              coverage.premiumDetails!.sumInsured = 0;
              //$('#sumInsured_' + coverageId).data('kendoNumericTextBox').value(0);
              break;
            case "grossPremium":
              premiumData[0].premiumSummary.grossPremium =
                premiumData[0].premiumSummary.grossPremium -
                coverage.premiumDetails!.grossPremium!;
              if (coverage.premiumDetails!.grossPremium !== 0) {
                this.editedDiv[id] = true;
              }
              coverage.premiumDetails!.grossPremium = 0;
              break;
            case "premiumTax":
              premiumData[0].premiumSummary.premiumTax =
                premiumData[0].premiumSummary.premiumTax -
                coverage.premiumDetails!.premiumTax!;
              if (coverage.premiumDetails!.premiumTax !== 0) {
                this.editedDiv[id] = true;
              }
              coverage.premiumDetails!.premiumTax = 0;
              break;
            default:
          }
        }
      });
    }
  };
}
