import { Component } from "@angular/core";
import { common } from "../../../../../../../../../app/framework/utils/common";
import { pageLifeCycleNotifier } from "../../../../../../../../../app/models/new-product/notifier/page-life-cycle-notifier";
import { KeyValue } from "../../../../../../../../framework/domain-entity/key-value";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { MfHkBaseComponent } from "../../mf-hk-base.component";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { BroadcastService } from "src/app/services/broadcast.service";
@Component({
  selector: "mf-hk-field-validation-echo-panel",
  templateUrl: "./mf-hk-field-validation-echo-panel.component.html",
  styleUrls: ["./mf-hk-field-validation-echo-panel.component.scss"],
})
export class MfHkFieldValidationEchoPanelComponent extends MfHkBaseComponent {
  pageLoaded: boolean = false;
  proposal!: MortgageFireHongKongProposal;
  showFieldValidation: boolean = false;
  showValidationMessage: boolean = false;
  showFieldBlankValidation: boolean = false;
  constructor(
    public proposalService: MfHKProposalService, // private Enums: any
    private broadcastService: BroadcastService
  ) {
    super();

    this.broadcastService.onProposalFailedNotifier.subscribe((msg: any) => {
      //HE-173: somehow proposalServiceNotifier.failed_notifier$ is dead after save-with-client>Refer (maybe dispose? typecast error?),
      //hence use broadcastService.onFailedNotifier
      this.proposalLoaded_function(msg.kv.value);
    });
  }

  ngOnDestroy = () => {
    super.unsubscribeRxjs();
  };

  override ngOnInit(): void {
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalSavedNotifier(this.proposalLoaded);
    super.subscribeProposalFailedNotifier(this.proposalLoaded);
  }

  public proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireHongKongProposal;
    this.proposalLoaded_function(this.proposal);
  };

  public proposalLoaded_function(proposal: MortgageFireHongKongProposal) {
    this.proposal = proposal;
    this.pageLoaded = true;
    this.showFieldValidation = this.checkFieldValidation();

    this.showValidationMessage =
      this.proposal.validationMessage.length > 0 ? true : false;

    this.showFieldBlankValidation =  this.proposalService.showFieldBlankValidationEchos.length > 0 ? true : false;
  }

  checkFieldValidation() {
    let flag: boolean = false;
    if (
      common.isDefined(this.proposalService.fieldValidationManager) &&
      common.isDefined(this.proposalService.fieldValidationManager.echos)
    ) {
      return this.proposalService.fieldValidationManager.echos.length > 0
        ? true
        : false;
    }
    return flag;
  }

  initialize() {}

  navigate(question: any) {
    pageLifeCycleNotifier.tab_request_notifier$.next(
      new KeyValue(question.tabId.toString(), question.key)
    );
  }
}
