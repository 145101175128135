import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import {
  AddEvent,
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  RemoveEvent,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { State } from "@progress/kendo-data-query";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { NotifierService } from "src/app/services/notifier.service";
import { RmScreenService } from "src/app/services/rm-screen.service";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { RmScreenListingEntity } from "./RmScreenListingEntity";

@Component({
  selector: "app-rm-screen",
  templateUrl: "./rm-screen.component.html",
  styleUrls: ["./rm-screen.component.scss"],
})
export class RmScreenComponent implements OnInit {
  isUnderwriter: boolean = false;
  isOperationUnderwriter: boolean = false;
  hideActions: boolean = true;

  public editRmDataItem!: RmScreenListingEntity | undefined;
  public isNewRm!: boolean;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  public listing: RmScreenListingEntity[] = []; //public, to be accessed by [kendoGridBinding]
  private totalListing: number = 0;

  public selectedRowIndexes: number[] = [];
  public loading!: boolean;

  @ViewChild("appendTo", { read: ViewContainerRef, static: false })
  public appendTo!: ViewContainerRef;

  constructor(
    private masterDataService: MasterDataService,
    private rmScreenService: RmScreenService,
    private notifier: NotifierService,
    private identityRolesService: IdentityRolesService,
    private cdkDialogService: CdkDialogService
  ) {}

  ngOnInit(): void {
    const component = this;
    this.isUnderwriter = this.identityRolesService.isUnderwriter;
    this.isOperationUnderwriter =
      this.identityRolesService.isOperationUnderwriter;

    if (this.isUnderwriter && !this.isOperationUnderwriter) {
      this.hideActions = false;
    }

    this.getRmdetails();
  }

  getRmdetails() {
    this.rmScreenService.getRmdetails().then((result) => {
      if (result) {
        this.listing = result;
        this.totalListing = this.listing.length;
        this.loadItems();
        this.loading = false;
      }
    });
  }

  private loadItems(): void {
    this.gridView = {
      data: this.listing.slice(this.skip, this.skip + this.pageSize), //static paging
      //data: this.listing, //.slice(this.skip, this.skip + this.pageSize),//dynamic paging
      total: this.totalListing,
    };
  }

  public onTabSelect(e: SelectEvent) {}

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }

  public gridSelectionChange(selection: SelectionEvent) {
    // console.log("gridSelectionChanged");
    const selectedData = selection?.selectedRows;
    if (selectedData != undefined && selectedData.length > 0) {
      var entity = selectedData[0].dataItem;
    }

    const deselectedData = selection?.deselectedRows;
    if (deselectedData != undefined && deselectedData.length > 0) {
      var entity = deselectedData[0].dataItem;
      //auto, from two-way binding of [(selectedKeys)]
      // this.selectedRowIndexes.forEach((item, index) => {
      //   if (item === deselectedData[0].index)
      //     this.selectedRowIndexes.splice(index, 1);
      // });
    }
  }

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {}

  private refresh(): void {
    this.loadItems(); //no need to call api
  }

  public onRmStateChange(state: State): void {
    // this.gridState = state;
    // this.editService.read();
  }

  public addRmHandler(): void {
    this.editRmDataItem = new RmScreenListingEntity();
    this.isNewRm = true;
  }

  public editRmHandler(args: AddEvent): void {
    this.editRmDataItem = args.dataItem;
    this.isNewRm = false;
  }

  public cancelRmHandler(): void {
    this.editRmDataItem = undefined;
  }

  //both new & edit
  public saveRmHandler(rmScreenEntity: RmScreenListingEntity): void {
    this.rmScreenService
      .save(new Array(rmScreenEntity), this.isNewRm)
      .subscribe(
        (result: any) => {
          if (result != undefined && result.body != undefined) {
            if (result.body.IsSuccess) {
              for (let i = 0; i < this.listing.length; i++) {
                const updatedData: any = rmScreenEntity;
                if (this.listing[i].id === updatedData.id) {
                  if (result.Id != "") {
                    this.listing[i].id = result.Id;
                  }
                  this.listing[i].officeNumber = updatedData.officeNumber;
                  this.listing[i].pcCode = updatedData.pcCode;
                  this.listing[i].rMName = updatedData.rMName;
                  this.listing[i].teamName = updatedData.teamName;
                  rmScreenEntity = this.listing[i];
                }
              }
              //options.success(options.data.models[0]);
              this.masterDataService.clearFinancialInstitutionBranches();
              if (this.isNewRm) {
                this.notifier.success("Data Added Succesfully", this.appendTo);
              } else {
                this.notifier.success(
                  "Data Updated Succesfully",
                  this.appendTo
                );
              }
              //this.hideDialogLoading();
              this.getRmdetails(); //reload live data
            } else {
              this.notifier.error("Error Occurred!", this.appendTo);
              //this.hideDialogLoading();
            }
          }
        },
        (err: any) => {
          console.log(err);
        }
      );

    this.editRmDataItem = undefined;
  }

  public removeRmHandler(args: RemoveEvent): void {
    this.ConfirmationPopup(
      "Are you sure you wish to delete the item?",
      args.dataItem
    );
  }

  ConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      if (args.data != null) {
        this.rmScreenService.deleteRM(args.data.id).subscribe(
          (result: any) => {
            if (result != undefined) {
              if (result.IsSuccess) {
                //options.success(options.data.models[0]);
                this.masterDataService.clearFinancialInstitutionBranches();
                this.notifier.success(
                  "Data Deleted Succesfully",
                  this.appendTo
                );
                //this.hideDialogLoading();
                this.getRmdetails(); //reload live data
              } else {
                this.notifier.error("Error Occurred!", this.appendTo);
                //this.hideDialogLoading();
              }
            }
          },
          (err: any) => {
            console.log(err);
          }
        );
      }
    }
  }
}
