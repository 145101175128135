import { Component } from "@angular/core";
import { MortgageFireSingaporeProposal } from "../../../../../../../../../app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { MfSgBaseComponent } from "../../mf-sg-base.component";
import { MfSgProposalService } from "../../../../../../../../../app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { common } from "../../../../../../../../../app/framework/utils/common";
import { pageLifeCycleNotifier } from "../../../../../../../../../app/models/new-product/notifier/page-life-cycle-notifier";
import { KeyValue } from "../../../../../../../../framework/domain-entity/key-value";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
@Component({
  selector: "mf-sg-echo-field-validation-panel",
  templateUrl: "./mf-sg-echo-field-validation-panel.component.html",
  styleUrls: ["./mf-sg-echo-field-validation-panel.component.scss"],
})
export class MfSgEchoFieldValidationPanelComponent extends MfSgBaseComponent {
  pageLoaded: boolean = false;
  proposal!: MortgageFireSingaporeProposal;
  showFieldValidation: boolean = false;
  showValidationMessage: boolean = false;
  constructor(
    public proposalService: MfSgProposalService // private Enums: any
  ) {
    super();
  }

  ngOnDestroy = () => {
    super.unsubscribeRxjs();
  };

  ngOnInit(): void {
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalSavedNotifier(this.proposalLoaded);
    super.subscribeProposalFailedNotifier(this.proposalLoaded);
  }

  public proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireSingaporeProposal;
    this.pageLoaded = true;
    this.showFieldValidation = this.checkFieldValidation();

    this.showValidationMessage =
      this.proposal.validationMessage.length > 0 ? true : false;
  };

  checkFieldValidation() {
    let flag: boolean = false;
    if (
      common.isDefined(this.proposalService.fieldValidationManager) &&
      common.isDefined(this.proposalService.fieldValidationManager.echos)
    ) {
      return this.proposalService.fieldValidationManager.echos.length > 0
        ? true
        : false;
    }
    return flag;
  }

  initialize() {}

  navigate(question: any) {
    pageLifeCycleNotifier.tab_request_notifier$.next(
      new KeyValue(question.tabId.toString(), question.key)
    );
  }
}
