import { AbstractButtonPanel } from "./abstract-button-panel";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import * as Enums from "src/app/framework/enum.shared";
import * as Const from "./../../../../framework/const.shared";
import { common } from "./../../../../framework/utils/common";
import { ProposalEntity } from "../../base/proposal-entity.model";
import { MortgageFireSingaporeProposal } from "../../sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { MortgageFireHongKongProposal } from "../../sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { ProposalStatus } from "src/app/framework/enum.shared";

export class HaseButtonPanel implements AbstractButtonPanel {
  GetIncompleteActions(
    identityRolesService: IdentityRolesService,
    proposal: ProposalEntity
  ): Array<Enums.ProposalCommandAction> {
    const actions = new Array<Enums.ProposalCommandAction>();
    switch (identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
      case Enums.UserType.Broker:
        if (
          identityRolesService.IsHASEUser &&
          common.isDefined(proposal.productId) &&
          (proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING ||
            proposal.productId?.toString().toUpperCase() ===
              Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING)
        ) {
          actions.push(Enums.ProposalCommandAction.Save);
          actions.push(Enums.ProposalCommandAction.Submit);
          actions.push(Enums.ProposalCommandAction.MakeRedundant);
          if (
            proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING
          ) {
            if (proposal.proposalStatus === Enums.ProposalStatus.Approved) {
              actions.push(Enums.ProposalCommandAction.EditQuotation);
            } else {
              actions.push(Enums.ProposalCommandAction.Approve);
            }
          }
        }

        break;
    }

    return actions;
  }

  public GetApprovedActions(
    identityRolesService: IdentityRolesService,
    proposal: ProposalEntity
  ): Array<Enums.ProposalCommandAction> {
    const actions = new Array<Enums.ProposalCommandAction>();

    switch (identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
      case Enums.UserType.Broker: //Hase's broker will resume what Dbs's UW's role
        if (
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null &&
          !proposal.auxiliary.IsPreviousYearTerminated
        ) {
          actions.push(Enums.ProposalCommandAction.Endorse);
          actions.push(Enums.ProposalCommandAction.Cancel);
        }
        // renewal
        if (
          (proposal.transType === Enums.TransType.NEW ||
            proposal.transType === Enums.TransType.END ||
            proposal.transType === Enums.TransType.REN) &&
          proposal.proposalType === Enums.ProposalType.Policy &&
          proposal.proposalStatus === Enums.ProposalStatus.Accepted &&
          proposal.pendingTransaction == null &&
          !proposal.auxiliary.IsPreviousYearTerminated
        ) {
          // 30 days before & 30 days after for HDB & PTE - CBG
          // 19 days before & 30 days after for MIP - CBG
          // 88 days before & 30 days after for MAR & FIRE - IBG
          if (proposal.auxiliary.allowRenewal) {
            actions.push(Enums.ProposalCommandAction.Renew);
          }
        }
        // reinstate year2 cancellation
        if (
          proposal.auxiliary.IsPreviousYearTerminated &&
          proposal.proposalStatus === Enums.ProposalStatus.Accepted &&
          proposal.pendingTransaction == null &&
          proposal.transType !== Enums.TransType.CAN
        ) {
          actions.push(Enums.ProposalCommandAction.Cancel);
        }
        // refund minimum premium
        if (
          proposal.transType === Enums.TransType.CAN &&
          proposal.pendingTransaction == null &&
          proposal.auxiliary.DisplayCancellationAdjustment
        ) {
          actions.push(Enums.ProposalCommandAction.CancellationAdjustment);
        }
        break;
    }

    return actions;
  }

  public ProductAllowApproved(proposalEntity: ProposalEntity): boolean {
    if (
      proposalEntity.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING || //HE-28
      proposalEntity.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING
    ) {
      return true;
    } else {
      return false;
    }
  }

  public CanSave(
    showSaveAction: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
        return this.CanSaveByWpb(
          showSaveAction,
          existingLogic,
          identityRolesService,
          proposal
        );
      // case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING:
      //   return showSaveAction && existingLogic;
      default:
        return showSaveAction && existingLogic;
    }
  }

  private CanSaveByWpb(
    showSaveAction: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    //showSaveAction && //api (AvailableActionHelper.cs) has to add or ui has set: this.actions.approve.visible, to true
    if (
      identityRolesService.getIdentity().currentUser().userType ==
        Enums.UserType.Underwriter &&
      proposal.proposalStatus == Enums.ProposalStatus.Referred
    ) {
      return false;
    }
    return showSaveAction && existingLogic;
  }

  public CanEditQuotation(
    showEditQuotation: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
        return this.CanEditQuotationByWpb(
          showEditQuotation,
          existingLogic,
          identityRolesService,
          proposal
        );
      // case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING:
      //   return showEditQuotation && existingLogic;
      default:
        return showEditQuotation && existingLogic;
    }
  }

  private CanEditQuotationByWpb(
    showEditQuotation: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    switch (identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Broker:
        if (
          proposal.proposalStatus == Enums.ProposalStatus.Approved //||
          //proposal.proposalStatus == Enums.ProposalStatus.Declined
        ) {
          return true;
        }
        break;
      case Enums.UserType.Underwriter:
        if (
          proposal.proposalStatus == Enums.ProposalStatus.Approved //||
          //proposal.proposalStatus == Enums.ProposalStatus.Declined
        ) {
          return true;
        }
        break;
      default:
        return false;
    }

    return showEditQuotation && existingLogic;
  }

  public CanApprove(
    showApproveAction: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
        return this.CanApproveByWpb(
          showApproveAction,
          identityRolesService,
          proposal
        );
      // case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING:
      //   return showApproveAction && existingLogic;
      default:
        return showApproveAction && existingLogic;
    }
  }

  private CanApproveByWpb(
    showApproveAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    return (
      //showApproveAction && //api (AvailableActionHelper.cs) has to add or ui has set: this.actions.approve.visible, to true
      identityRolesService.getIdentity().currentUser().userType ==
        Enums.UserType.Underwriter &&
      proposal.proposalStatus == Enums.ProposalStatus.Referred
    );
  }

  public CanEdit(
    showEditAction: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    return showEditAction && existingLogic; //todo: code changed, for future
  }

  public CanChecked(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireSingaporeProposal
  ) {
    return false;
  }

  public CanBindPolicy(
    identityRolesService: IdentityRolesService,
    hideActionsCommandsByIBG: boolean
  ): boolean {
    return identityRolesService.hasProductTransactRoles;
  }

  public CanReferToUnderwriter(
    showSubmitAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
        return this.showReferToUnderwriterByWpb(
          showSubmitAction,
          identityRolesService,
          proposal,
          proposalService
        );
      // case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING:
      //   return false;//showSubmitAction; //dummy/temporary, for future; submit can be 'bind' or 'Checked'
      default:
        return false; //showSubmitAction; //dummy/temporary, for future; submit can be 'bind' or 'Checked'
    }
  }

  private showReferToUnderwriterByWpb(
    showSubmitAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    //NB (transType) can have 2 draft proposal-statuses, one is Incomplete (after save), one is None (before save)
    //NB also can have referred proposal-status
    const isIncomplete =
      proposal.proposalStatus === ProposalStatus.None ||
      proposal.proposalStatus === Enums.ProposalStatus.Incomplete;

    if (
      identityRolesService.isBroker &&
      proposalService.isFinancialEndorsementOrNbOrCan() &&
      isIncomplete
    ) {
      return true;
    } else {
      return false;
    }
  }

  public CanSubmitForREN(
    showSubmitAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      default:
        const flag =
          proposal.transType === Enums.TransType.REN && showSubmitAction;
        return flag;
    }
  }

  public CanSubmit(
    showSubmitAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
        return this.showSubmitByWpb(
          showSubmitAction,
          identityRolesService,
          proposal,
          proposalService
        );
      // case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING:
      //   return showSubmitAction; //dummy/temporary, for future; submit can be 'bind' or 'Checked'
      default:
        return showSubmitAction; //dummy/temporary, for future; submit can be 'bind' or 'Checked'
    }
  }

  private showSubmitByWpb(
    showSubmitAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    switch (identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Broker:
        const propStatus = proposal.proposalStatus;
        //NB (transType) can have 2 draft Proposal-statuses, one is Incomplete (after save), one is None (before save)
        //NB also can have referred Proposal-status
        const isIncomplete =
          propStatus === ProposalStatus.None ||
          propStatus === ProposalStatus.Incomplete;
        const manual_uw = proposalService.compareQuestionAnswer(
          proposal!.qsMaster.qs_master_manualUnderwritting!
        );

        //HE-28
        if (
          proposalService.isFinancialEndorsementOrNbOrCan() &&
          isIncomplete &&
          manual_uw
        ) {
          return false;
        } else if (
          proposal.transType === Enums.TransType.NEW &&
          isIncomplete &&
          proposal.proposalId &&
          proposal.proposalId.toString() !==
            "00000000-0000-0000-0000-000000000000"
        ) {
          //NB but not manual UW
          return true;
        }
        return showSubmitAction;
      default:
        return showSubmitAction; //dummy, the rest handle by this.actions.submit.visible
    }
  }
}
