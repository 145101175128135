export enum MortgageFireSgProduct {
  HDB,
  PTE,
  MIP,
  IBGMF,
  IBGMAC,
  CTHDB,
  CTMFPTE,
  CTMFCONDO,
  HSWPB,
  HSCMB,
}

export enum ProductBase {
  DBS = "DBS",
  Citi = "Citi",
  HASE = "HASE",
}

export enum ClientType {
  Person = 1,
  Organization = 2,
  Others = 3,
}

export enum ClientErrorType {
  NRICError = 1,
  PassportNumberError = 2,
  BusinessRegistrationNumberError = 3,
  MandatoryError = 4,
  EmailAddressError = 5,
  PhoneNumberError = 6,
  NameAndNricError = 7,
}

export enum BackdatedRules {
  MaximumAllowedBackdate = 7,
  MaximumAllowedLoanRedemption = 14,
}
