import { Component, Input, OnInit } from "@angular/core";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import * as Enum from "src/app/framework/enum.shared";
import * as FinanceConstant from "src/app/finance/finance-constant";
import { proposalCommon } from "src/app/components/new-product/base/utils/proposal-common";
import { DocumentService } from "src/app/services/document/document.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { MfHkBaseComponent } from "../mf-hk-base.component";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import * as Const from "src/app/framework/const.shared";
@Component({
  selector: "mf-hk-header",
  templateUrl: "./mf-hk-header.component.html",
  styleUrls: ["./mf-hk-header.component.scss"],
})
export class MfHkHeaderComponent extends MfHkBaseComponent implements OnInit {
  @Input() proposal!: MortgageFireHongKongProposal; //temp using @Input()
  transTypeText!: string;
  proposalStatusText!: string;
  pid!: string;
  isDisplay: boolean = false;
  docTypeText!: string;
  displayChecker: boolean = false;
  displayMaker: boolean = false;
  displayOpenItem: boolean = false;
  displayOriginalPolicyNo: boolean = false;
  transOpenItemStatus!: string;

  public openItemId!: string;
  public openItemDetailsOpened: boolean = false;

  constructor(
    public identityRolesService: IdentityRolesService,
    private documentService: DocumentService
  ) {
    super();
  }

  hasQuoteNumber() {
    if (common.isDefined(this.proposal)) {
      return (
        this.proposal!.quoteNumber !== "" &&
        this.proposal!.quoteNumber !== undefined
      );
    }
    return false;
  }

  ngOnDestroy() {
    super.unsubscribeRxjs();
  }

  override ngOnInit(): void {
    this.initialize();
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalSavedNotifier(this.proposalLoaded);
  }

  initialize() {}

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireHongKongProposal;
    if (
      this.proposal.transType === Enum.TransType.END ||
      this.proposal.transType === Enum.TransType.CAN
    ) {
      this.isDisplay = true;
    }
    if (!common.isUndefinedOrNull(this.proposal.openItems)) {
      if (this.proposal.openItems.length > 0) {
        this.displayOpenItem = true;
        this.transOpenItemStatus =
          FinanceConstant.openItemDisplayStatus[
            this.proposal.openItems[0].Status
          ];
      }
    } else {
      this.displayOpenItem = false;
    }

    this.proposalStatusText = proposalCommon.translateProposalStatus(
      this.proposal.proposalStatus,
      this.proposal.transType!
    );

    if (this.checkExpiredPolicy()) {
      this.proposalStatusText = "Expired";
    }

    if (
      this.proposal.productId?.toString().toUpperCase() ==
      Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING
    ) {
      if (this.proposalStatusText.indexOf("Approved") != -1) {
        this.proposalStatusText = "Approved";
      }
    }
    this.transTypeText = proposalCommon.translateTransType(
      this.proposal!.transType!,
      this.proposal.endorsementType!
    );
    this.pid = this.proposal.proposalId!.toId.toString();
    this.displayChecker =
      common.isDefined(this.proposal.checkerUserName) && false;
    this.displayMaker = common.isDefined(this.proposal.makerUserName) && false;
    if (this.proposal.documents.length > 0) {
      const newDocuments: Array<KeyValue<string>> = [];
      this.proposal.documents.forEach((doc: Enum.DocumentType) => {
        const docText: string = proposalCommon.translateDocumentType(doc);
        newDocuments.push(new KeyValue(String(doc), docText));
      });
      this.proposal.documents = newDocuments;
    }

    /** The original Policy Number will be available only for migrated policies */
    const replacingPolicyNo =
      this.proposal.qsMaster.qs_master_replacePolicyNo!.readAnswerAsString();
    this.displayOriginalPolicyNo =
      common.isDefined(replacingPolicyNo) &&
      replacingPolicyNo !== "" &&
      replacingPolicyNo !== null
        ? true
        : false;
  };

  public downloadDocument = (docType: any, pid?: any): void => {
    const params = {
      proposalId: this.proposal.proposalId!.toString(),
      documentType: docType,
    };
    this.documentService.downloadProposalDocument(params);
  };

  public showDetails = (): void => {
    const postData = { openItemId: this.proposal.openItems[0].Id };
    this.openItemId = postData.openItemId;
    this.openItemDetailsOpened = true;
  };
  confirmationModalCloseForActions = (data: any): void => {
    this.openItemDetailsOpened = false;
  };

  public checkExpiredPolicy() {
    const expirydate = this.proposal.qsMaster.qs_master_poi_endDate?.answer;
    const currentDate = new Date().setHours(0, 0, 0, 0);

    if (
      currentDate > expirydate &&
      this.proposal.proposalStatus == Enum.ProposalStatus.Accepted
    )
      return true;
    else return false;
  }
}
