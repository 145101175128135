import { Injectable } from "@angular/core";
import {
  DialogAnimation,
  //DialogAnimation,//todo
  DialogCloseResult,
  DialogRef,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { Observable } from "rxjs";
import { common } from "src/app/framework/utils/common";
//import { common } from "src/app/core/extensions/framework/utils/common";
//import { ForceReferralComponent } from "./components/force-referral/force-referral.component";
// import { Application } from '../../../../application';
// import { Global } from '../../../../shared/global';

// /// <reference path="../../../../../../node_modules/@types/bootstrap/index.d.ts" />
// /// <reference path="../../../../../../node_modules/@types/jqueryui/index.d.ts" />

// let angular: ng.IAngularStatic = Application.angular;

enum E_ModalType {
  // template sliding modal
  sliding = 1,
  // bootstrap jquery stand modal
  standard = 2,
}

export interface DialogModalOptions {
  size?: string;
  windowClass?: string;
  modalType?: string;
  keyboard?: boolean;
  backdrop?: any;
}

export interface IDialog {
  confirm: (
    title: string,
    message: string,
    fnYes: Function,
    fnNo: Function
  ) => void;
  error: (header: string, message: string) => void;
  notify: (header: string, message: string) => void;
  modalLarge: (
    templateUrl: string,
    controllerName: string,
    data?: any,
    fnClose?: Function,
    fnError?: Function,
    size?: any,
    windowClass?: any,
    keyboard?: boolean,
    modalType?: string
  ) => any;
  modalMedium: (
    templateUrl: string,
    controllerName: string,
    data?: any,
    fnClose?: Function,
    fnError?: Function,
    size?: any,
    windowClass?: any,
    keyboard?: boolean,
    modalType?: string
  ) => any;
  modalSmall: (
    templateUrl: string,
    controllerName: string,
    data?: any,
    fnClose?: Function,
    fnError?: Function,
    size?: any,
    windowClass?: any,
    keyboard?: boolean,
    modalType?: string
  ) => any;
  close: (data?: any, modal_onClose?: (data?: any) => void) => void;
}

export interface IDialogViewModel {
  header: string;
  msg: string;
  stack?: string;
}

@Injectable({
  providedIn: "root",
})
export class DialogWrapperService {
  constructor(private dialogService: DialogService) {}
  // private static _instance: DialogModal;

  // static get Instance(): DialogModal {
  //   if (Global.isUndefined(this._instance)) {
  //     // try get instance from AngularJS injector
  //     this._instance = Application.angularInjectionObject<any>("Dialog");

  //     if (Global.isUndefined(this._instance)) {
  //       this._instance = new DialogModal(); // create own new instance
  //     }
  //   }

  //   return this._instance;
  // }

  // // angularjs module injector
  // private _dialogs: any;

  // private get dialogs() {
  //   return Application.angularInjectionObject<any>("dialogs");
  // }

  // right sliding
  private create(
    templateUrl: string,
    ComponentName: any,
    data?: any,
    fnClose?: Function,
    fnError?: Function,
    size?: any,
    windowClass?: any,
    keyboard?: boolean,
    modalType?: string
  ): Observable<any> {
    let windowAnimation: boolean | DialogAnimation =
      this.getWindowAnimation(modalType);

    switch (size) {
      //todo
      //{
      //statements;
      case "lg":
        let dialog: DialogRef;
        if (typeof templateUrl != "undefined" && templateUrl && ComponentName) {
          dialog = this.dialogService.open({
            content: ComponentName,
            htmlAttributes: {
              class: "flex-end-justified",
            },
            width: 1080,
            minWidth: 500,
            animation: windowAnimation,
          });
          const userInfo = dialog.content.instance as typeof ComponentName;
          userInfo.data = data;
        } else {
          dialog = this.openDefaultDialog(
            windowAnimation,
            1080,
            500,
            data,
            modalType
          );
        }

        return dialog.result;
        break;
      //}
      case "md": //{
      //statements;
      //break;
      //}

      case "sm":
      default: {
        let dialog: DialogRef;
        if (templateUrl === "forceReferral.html") {
          dialog = this.dialogService.open({
            //content: ForceReferralComponent,//todo
            htmlAttributes: {
              class: "force-referral-dlg",
            },
          });
          //todo
          // const userInfo = dialog.content.instance as ForceReferralComponent;
          // userInfo.data = data;
        } else if (
          typeof templateUrl != "undefined" &&
          templateUrl &&
          ComponentName
        ) {
          dialog = this.dialogService.open({
            content: ComponentName,
            htmlAttributes: {
              // class: "force-referral-dlg",
            },
            width: 500,
            minWidth: 500,
          });
          const userInfo = dialog.content.instance as typeof ComponentName;
          userInfo.data = data;
        } else {
          dialog = this.openDefaultDialog(
            windowAnimation,
            450,
            250,
            data,
            modalType
          );
        }

        return dialog.result;
      }
    }
  }

  private openDefaultDialog(
    windowAnimation: boolean | DialogAnimation,
    width: number,
    minWidth: number,
    data?: any,
    modalType?: string
  ) {
    let dialog: DialogRef;
    dialog = this.dialogService.open({
      title: "Confirmation",
      content: data.message,
      actions: [
        { text: "Yes", confirmation: 1, modelActionGoData: data },
        { text: "No", confirmation: 0, modelActionGoData: data },
      ],
      width: width,
      //height: 200,//update by css
      minWidth: minWidth,
      animation: windowAnimation,
      htmlAttributes: {
        class: modalType === "slidingModal" ? "flex-end-justified" : "centered",
      },
    });

    return dialog;
  }

  private getWindowAnimation(modalType?: string) {
    let windowAnimation: boolean | DialogAnimation = {};

    if (modalType === "slidingModal") {
      windowAnimation = {
        duration: 300,
        type: "slide",
        direction: "left",
      };
    } else {
      windowAnimation = {
        type: "fade",
      };
    }

    return windowAnimation;
  }
  // private create(templateUrl: string, controllerName: string, data?: any,
  //   fnClose?: Function, fnError?: Function,
  //   size?: any, windowClass?: any, keyboard?: boolean, modalType?: string) {

  //   let options: DialogModalOptions = {
  //     size: size,
  //     keyboard: (angular.isDefined(keyboard)) ? keyboard : true,
  //     backdrop: "static",
  //     windowClass: windowClass,
  //     modalType: modalType
  //   };

  //   let dlg = this.dialogs
  //     .create(templateUrl, controllerName, (angular.isDefined(data)) ? data : true, options);

  //   /*to disable the scrollbar*/
  //   $('html, body').css('overflow', 'hidden');
  //   dlg.result
  //     .then(function (result) {
  //       /*to enable the scrollbar*/
  //       $('html, body').css('overflow', 'auto');
  //       if (fnClose) {

  //         fnClose(result);
  //       }
  //     }, function () {
  //       /*to enable the scrollbar*/
  //       $('html, body').css('overflow', 'auto');
  //       if (fnError) {

  //         fnError();
  //       }
  //     });

  //   return dlg;
  // };

  // confirm(title, message, fnYes, fnNo) {
  //   let dlg = this._dialogs.confirm(title, message);
  //   dlg.result.then(fnYes, fnNo);
  // }

  error(header: any, message: any) {
    let errorMessage = "";
    let errorStack = "";
    if (common.isDefined(message)) {
      if (common.isObject(message)) {
        if (common.isDefined(message.message)) {
          errorMessage = message.message;
        } else {
          if (common.isDefined(message.data)) {
            if (common.isObject(message.data)) {
              errorMessage = JSON.stringify(message, null, 4);
            } else {
              // server side html error page pattern when message.data is not object
              errorMessage = message.data;
            }
          } else {
            errorMessage = JSON.stringify(message, null, 4);
          }
        }

        if (common.isDefined(message.stack)) {
          errorStack = message.stack;
        }
      } else {
        errorMessage = message;
      }
    }

    //todo
    // let vm: IDialogViewModel = {
    //   header: angular.copy(header),
    //   msg: angular.copy(errorMessage),
    //   stack: angular.copy(errorStack)
    // }

    // this.dialogs.create(
    //   "shared/uiControls/dialog/partials/customError.html",
    //   "ErrorDialogController",
    //   vm,
    //   {
    //     size: 'lg',
    //     keyboard: true,
    //     modalType: "standardModal"
    //   }
    // );
  }

  // notify(title, message) {
  //   this._dialogs.notify(title, message);
  // }

  modalLarge(
    templateUrl: string,
    controllerName: any,
    data: any,
    fnClose: Function | undefined,
    fnError: Function | undefined,
    size: any,
    windowClass: any,
    keyboard: boolean | undefined,
    modalType: string | undefined
  ) {
    return this.create(
      templateUrl,
      controllerName,
      data,
      fnClose,
      fnError,
      "lg",
      windowClass,
      keyboard,
      modalType
    );
  }

  modalMedium(
    templateUrl: string,
    controllerName: any,
    data: any,
    fnClose: Function | undefined,
    fnError: Function | undefined,
    size: any,
    windowClass: any,
    keyboard: boolean | undefined,
    modalType: string | undefined
  ) {
    return this.create(
      templateUrl,
      controllerName,
      data,
      fnClose,
      fnError,
      "md",
      windowClass,
      keyboard,
      modalType
    );
  }

  modalSmall(
    templateUrl: string,
    controllerName: any,
    data: any,
    fnClose: Function | undefined,
    fnError: Function | undefined,
    size: any,
    windowClass: null,
    keyboard: boolean | undefined,
    modalType: string | undefined
  ) {
    return this.create(
      templateUrl,
      controllerName,
      data,
      fnClose,
      fnError,
      "sm",
      windowClass,
      keyboard,
      modalType
    );
  }
}
