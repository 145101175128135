import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { pairwise, startWith, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CoverageTypeService {
  private valueSubject = new BehaviorSubject<string>("");

  value$: Observable<string> = this.valueSubject.asObservable().pipe(
    startWith(""),
    pairwise(),
    map(([previousValue, currentValue]) => currentValue)
  );

  //value$ = this.valueSubject.asObservable();

  updateValue(newValue: string) {
    this.valueSubject.next(newValue);
  }

  clearSelectedValue() {
    this.valueSubject.next("");
  }
}
