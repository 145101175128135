import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { ManualPaidAutoDebitSearchCriteria } from "./manual-paid-auto-debit.model";
import { proposalCommon } from "./../../../base/utils/proposal-common";

@Component({
  selector: "app-manual-paid-auto-debit",
  templateUrl: "./manual-paid-auto-debit.component.html",
})
export class ManualPaidAutoDebitComponent implements OnInit {
  @Output() warningZoneEvent = new EventEmitter<any>();
  public searchCriteria = new ManualPaidAutoDebitSearchCriteria();
  constructor(
    private presetExportService: PresetExportService,
    private excelTemplateService: ExcelTemplateService
  ) {}
  ngOnInit(): void {
    this.defaultManualPaidAutoDebitSearchCriteria();
  }

  public download(): void {
    if (this.validateManualPaidAutoDebitMandatoryFields()) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.downloadManualPaidAutoDebitReport();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a month.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private defaultManualPaidAutoDebitSearchCriteria(): void {
    // today
    this.searchCriteria.month = new Date();
  }
  private validateManualPaidAutoDebitMandatoryFields(): boolean {
    let isValid: boolean = false;
    // dates
    const dates: boolean = this.searchCriteria.month !== null;
    isValid = dates; // || etc...
    return isValid;
  }
  private formatDate(date: Date): string {
    return (
      proposalCommon.translateMonth(date.getMonth() + 1) +
      " " +
      date.getFullYear().toString()
    );
  }
  private downloadManualPaidAutoDebitReport() {
    const month: number = this.searchCriteria.month.getMonth() + 1;
    const year: number = this.searchCriteria.month.getFullYear();
    this.presetExportService
      .manualPaidAutoDebit(month, year)
      .subscribe((response: any) => {
        this.excelTemplateService.downloadManualPaidAutoDebitReport(
          mpautoDebitColumn,
          "Manual Paid Auto Debit",
          "Manual Paid Auto Debit in " +
            this.formatDate(this.searchCriteria.month),
          response?.d?.results
        );
      });
  }
}
const mpautoDebitColumn: string[] = [
  "S/N",
  "Policy Number",
  "Policy Certificate Number",
  "Cheque Issuance Bank",
  "Cheque No",
  "Payer Name",
  "Total Premium",
  "GST",
];
