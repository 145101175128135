import { Component, Inject, OnInit } from "@angular/core";
import { IProduct } from "src/app/framework/interface.product";
import { IUser } from "src/app/framework/interface.shared";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { DialogRef } from "../dialog/dialog-ref";
import { DIALOG_DATA } from "../dialog/dialog-tokens";
import * as Enums from "src/app/framework/enum.shared";
import * as AppEnums from "src/app/framework/app.enums";
import { DialogAction } from "@progress/kendo-angular-dialog";
import { Router } from "@angular/router";
import { ROUTE_PROPOSAL } from "src/app/app.module";

@Component({
  selector: "app-md-product-selection-dialog",
  templateUrl: "./md-product-selection-dialog.component.html",
  styleUrls: ["./md-product-selection-dialog.component.scss"],
})
export class MdProductSelectionDialogComponent implements OnInit {
  currentUser: IUser | undefined;
  public data: any;
  public isProcessing: boolean = false;
  public model = {};
  public params: any = null;
  public organisationID = "";
  public productListing!: IProduct[];
  public contentLoaded: boolean = true;
  public showProduct: boolean = false;
  public isBroker: boolean = false;
  public isUnderwriter: boolean = false;

  public dlgWidth: number = 600;

  public returnActions: DialogAction[] = [{ text: "Return to page" }];

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: v2DlgInputArgs,
    public identityRolesService: IdentityRolesService,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (this.inputData) {
      this.dlgWidth = this.inputData.dialogWidth;
      this.data = this.inputData.data;
    }

    this.currentUser = this.identityRolesService.getIdentity().currentUser();
    this.params = null; //ProposalManager.processParams($stateParams);//todo: when click frm productPage
    this.organisationID = "";
    this.productListing =
      this.currentUser.products != undefined ? this.currentUser.products : [];
    this.contentLoaded = true;
    this.showProduct = this.currentUser.userType == Enums.UserType.Underwriter;
    this.isBroker = this.currentUser.userType == Enums.UserType.Broker;
    this.isUnderwriter =
      this.currentUser.userType == Enums.UserType.Underwriter;
  }

  //https://stackoverflow.com/questions/59289095/use-of-enums-in-angular-8-html-template-for-ngif
  public get EnumsForHtml() {
    return Enums;
  }

  goTo(productId: any): void {
    if (
      productId.toLocaleLowerCase() == AppEnums.product.ManagementLiablity &&
      this.currentUser?.userType == Enums.UserType.Underwriter
    ) {
      //fectchBrokerList(productId.toLocaleLowerCase());
    } else {
      var defaultGUID = "00000000-0000-0000-0000-000000000000";
      var proposalId = defaultGUID;
      var masterProposalId = defaultGUID;
      var action = 1; // CreateOrEdit
      var section = 1; // default to 1 since it's New
      var pathToProposal =
        AppEnums.productsRoute[productId.toLocaleLowerCase() + "|" + section];
      var type = this.params?.type;
      //Special case for GCN
      if (productId == AppEnums.product.GeneralCoverNote) {
        pathToProposal = AppEnums.productsRoute[productId + "|" + 0];
      }
      this.goToProposal(
        pathToProposal,
        productId,
        proposalId,
        masterProposalId,
        action,
        section,
        type,
        null,
        null
      );
    }
  }

  goToProposal(
    pathToProposal: any,
    productId: any,
    proposalId: any,
    masterProposalId: any,
    action: any,
    section: any,
    type: any,
    buid: any,
    organisationID: any
  ): void {
    //this will re-route to proposal page
    let currentUrl = this.router.url;
    //if same route, force reload
    if (currentUrl.startsWith("/" + ROUTE_PROPOSAL)) {
      //https://stackblitz.com/edit/angular-same-route-reload
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
        this.router.navigate(["/" + ROUTE_PROPOSAL], {
          queryParams: {
            pdid: productId,
            pid: proposalId,
            mpid: masterProposalId,
            action: action, //Creaate
            section: section,
            type: type,
            buid: buid,
            organisationID: organisationID,
          },
        })
      );
    } else {
      this.router.navigate(["/" + ROUTE_PROPOSAL], {
        queryParams: {
          pdid: productId,
          pid: proposalId,
          mpid: masterProposalId,
          action: action, //Creaate
          section: section,
          type: type,
          buid: buid,
          organisationID: organisationID,
        },
      });
    }

    let savParamters: dlgModelArgs = {
      status: "yes", //action.text,
      data: this.data,
    };
    this.dialogRef?.close(savParamters);

    // $state.go(pathToProposal, {
    //     "pdid": productId,
    //     "pid": proposalId,
    //     "mpid": masterProposalId,
    //     "action": action, //Creaate
    //     "section": section,
    //     "type": type,
    //     "buid": buid,
    //     "organisationID": organisationID
    // }, {
    //     reload: true,
    //     inherit: false,
    //     notify: true
    // });
    // $scope.close();
  }

  /** Appending brokerid and broker user id is to cater for AML underwriter dual role. When logged in as
         * an underwriter user will be given an option to choose broker user id and this broker user id will be used
         during dosave() - kisiva
         */
  routeWithBrokerInfo(data: any): void {
    // if (angular.isUndefined(data)) return;
    // var defaultGUID = '00000000-0000-0000-0000-000000000000';
    // var proposalId = defaultGUID;
    // var masterProposalId = defaultGUID;
    // var action = 1; // CreateOrEdit
    // var section = 1; // default to 1 since it's New]
    // var productId = Enums.product.ManagementLiablity;
    // var pathToProposal = Enums.productsRoute[productId.toLocaleLowerCase() + '|' + section];
    // var type = $scope.params.type;
    // var buid = data.id;
    // var organisationID = data.organisationID;
    // goToProposal(pathToProposal,
    //     productId,
    //     proposalId,
    //     masterProposalId,
    //     action,
    //     section,
    //     type,
    //     buid,
    //     organisationID);
  }

  public onAction(action: DialogAction): void {
    let savParamters: dlgModelArgs = {
      status: "cancel", //action.text,
      data: this.data,
    };
    this.dialogRef?.close(savParamters);
  }
}

export interface v2DlgInputArgs {
  opened: boolean;
  dialogWidth: number;
  data: any;
  message: any;
}

export interface dlgModelArgs {
  status: string;
  data: any; //ProposalEntity[]
}
