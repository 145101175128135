import { Component, OnInit } from "@angular/core";
import { BaseSubscriptionComponent } from "src/app/components/new-product/base/base-subscription/base-subscription.component";

@Component({
  selector: "app-mf-hk-base",
  templateUrl: "./mf-hk-base.component.html",
  styleUrls: ["./mf-hk-base.component.scss"],
})
export class MfHkBaseComponent
  extends BaseSubscriptionComponent
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
