import { forEach } from "lodash";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
  UrlTree,
  UrlSegment,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { ROUTE_LOGIN } from "src/app/app.module";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import {
  ROUTE_HOME,
  ROUTE_USER_ROLE_SELECTION,
} from "src/app/app-routing.module";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private identityRolesService: IdentityRolesService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Get property name on security object to check
    let claimName: string = next.data["claimName"];

    if (
      this.identityRolesService.getIdentity() != null &&
      this.identityRolesService.isLoggedIn() != null
    ) {
      //check from roles
      const url: string = state.url;
      //any roles can goes to ROUTE_USER_ROLE_SELECTION, as long as autheticated
      if (url === "/" + ROUTE_USER_ROLE_SELECTION) {
        return true;
      }
      return this.checkUserRole(next, url);
      // return true;
    } else {
      this.router.navigate(
        ["/" + ROUTE_LOGIN] //, {
        //  queryParams: { returnUrl: state.url },
        //}
      );
      return false;
    }
  }

  // canActivateChild(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ):
  //   | Observable<boolean | UrlTree>
  //   | Promise<boolean | UrlTree>
  //   | boolean
  //   | UrlTree {
  //   return this.canActivate(next, state);
  // }
  // canDeactivate(
  //   component: unknown,
  //   currentRoute: ActivatedRouteSnapshot,
  //   currentState: RouterStateSnapshot,
  //   nextState?: RouterStateSnapshot
  // ):
  //   | Observable<boolean | UrlTree>
  //   | Promise<boolean | UrlTree>
  //   | boolean
  //   | UrlTree {
  //   return true;
  // }
  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]
  // ): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }

  checkUserRole(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.identityRolesService.isLoggedIn()) {
      const roles: string = route.data["role"];
      let valid: boolean = false;
      if (roles !== undefined) {
        const splitRoles: any[] = roles.split("|");
        splitRoles.forEach((role) => {
          valid =
            this.identityRolesService[role as keyof IdentityRolesService] !==
            undefined
              ? true
              : false;
          if (!valid) {
            return;
          }
        });
      }

      if (!valid) {
        this.router.navigate(["/" + ROUTE_HOME]);
      }

      return valid;
    }

    this.router.navigate(["/" + ROUTE_HOME]);
    return false;
  }
}
