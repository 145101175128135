import * as Enums from "./../../enum.shared";

import { Exception } from "./exception";
import { common } from "./../../utils/common";

/** Common Exception object to be thrown for argument null exception */
export class NotImplementedException extends Exception {
  constructor(message?: string) {
    if (message != undefined) {
      super(message);
      this.type = Enums.ErrorExceptionType.ApplicationError;

      if (common.isStringNullOrEmpty(message)) {
        this.message = `Incomplete implementations. TODO?`;
      }
    }
  }
}
