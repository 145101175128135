import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { cloneDeep } from "lodash";
import {
  ReconcilePoliciesDownloadEntity,
  SearchCriteria,
} from "src/app/components/accounting/reconcile/models/reconcile-list.model";
import { BaseReconcileListComponent } from "src/app/components/new-product/base/base-reconcile-list/base-reconcile-list.component";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { ReconcileListService } from "src/app/services/reconcile-list.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";

@Component({
  selector: "mf-hk-reconcile-list",
  templateUrl: "./mf-hk-reconcile-list.component.html",
  styleUrls: ["./mf-hk-reconcile-list.component.scss"],
})
export class MfHkReconcileListComponent
  extends BaseReconcileListComponent
  implements OnInit
{
  constructor(
    @Inject(LOCALE_ID) public override locale: string,
    protected override uploadService: UploadExcelService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected override excelTemplate: ExcelTemplateService,
    protected override reconcileService: ReconcileListService
  ) {
    super(
      locale,
      uploadService,
      router,
      route,
      excelTemplate,
      reconcileService
    );

    this.searchCriteria.productRadio = "wpb"; //default to hase
  }

  protected override validateMandatoryFields(): boolean {
    this.reformatPeriod();
    let isValid: boolean = false;

    const inoutField: boolean =
      this.searchCriteria.policyNumber !== "" ||
      this.searchCriteria.autoDebitPeriod !== "";

    isValid = inoutField; // || etc...

    return isValid;
  }

  findReconcileSelection(): boolean {
    var test = document.getElementsByName("reconcile");
    var sizes = test.length;
    for (let i = 0; i < sizes; i++) {
      if ((test[i] as HTMLInputElement).checked == true) {
        return true;
      }
    }

    return false;
  }

  protected override exportSearch() {
    let dummySearchCriteria = new SearchCriteria();
    if (this.findReconcileSelection()) {
      dummySearchCriteria = cloneDeep(this.searchCriteria);
    } else {
      dummySearchCriteria = cloneDeep(this.searchCriteria);
      //default to all; this one maybe missed during angular upgrade
      dummySearchCriteria.autoDebitPeriodMonth = "";
      dummySearchCriteria.autoDebitPeriodYear = "";
    }

    this.reconcileService
      .searchReconcilePolicies(dummySearchCriteria)     
      .then(
        (result: any) => {
          const base64String = result.fileBytes;
          const decodedByteArray = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
          this.uploadService.generateAnchorAndTriggerDownload(
            decodedByteArray,
            result.filename
          );
        },
        (error: any) => {
          let message: string = "";
          switch (error?.status) {
            case 401:
              message = "Unauthorized document download";
              break;
            default:
              message =
                "Document download response with unexpected status: " +
                error?.status?.toString();
              break;
          }
        }
      );
  }
}

const haseColumns: string[] = [
  "Serial Number",
  "Action",
  //"Meridian Sequence No",
  "Policy Certificate Number",
  "Total",
  "Levy",
  "Status",
  //"Invoice No",
  "Saved By",
  "Id(Do not change!)",
  "Status Id(Do not change!)",
  "Paid by",
  "Remarks"
];
