<!-- #citiMthlyReport -->
<div id="citiMthlyReport" class="reportOption container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="mt-1">Transaction Type</label>
        </div>
        <div class="col-md-9">
          <kendo-dropdownlist
            [data]="transactionTypeOption"
            textField="text"
            valueField="value"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.transactionType"
          >
          </kendo-dropdownlist>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-offset-1 col-md-3">
          <label class="mt-1" for="reportCitiMonthlyDateFrom"
            >Transaction Date</label
          >
        </div>
        <div class="col-md-4">
          <kendo-datepicker
            class="maxWidth"
            id="reportCitiMonthlyDateFrom"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="searchCriteria.dateFrom"
            (valueChange)="on_citiMonthlyReportDateFrom_change($event)"
            name="reportCitiMonthlyDateFrom"
            #name="ngModel"
          ></kendo-datepicker>
        </div>
        <div class="col-md-1 textAlignCenter">
          <label class="mt-1" for="reportCitiMonthlyDateTo">to</label>
        </div>
        <div class="col-md-4">
          <kendo-datepicker
            class="maxWidth"
            id="reportCitiMonthlyDateTo"
            #reportCitiMonthlyDateTo
            format="dd/MM/yyyy"
            [min]="reportCitiMonthlyDate_min"
            [max]="reportCitiMonthlyDate_max"
            placeholder=""
            [(ngModel)]="searchCriteria.dateTo"
            name="reportCitiMonthlyDateTo"
            #name="ngModel"
          ></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="mt-1" for="reportProduct">Product</label>
        </div>
        <div class="col-md-9">
          <kendo-multiselect
            id="reportProduct"
            placeholder="Select product..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="userProductList"
            textField="pd"
            valueField="pdid"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.productIds"
            name="productIds"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="mt-1">Customer Type</label>
        </div>
        <div class="col-md-9">
          <kendo-multiselect
            placeholder="Select Customer Type..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="customerTypes"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.customerType"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      (click)="download()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
    </button>
  </div>
</div>
