import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MortgageFireHongKongProposal } from "../../sme/mortgage-fire/hong-kong/base/mf-hk-proposal"; //dummy, will refer to sg in the future
import { AbstractMaster } from "./abstract-master";
import * as Enum from "src/app/framework/enum.shared";

export class DbsMaster implements AbstractMaster {
  public showClientSearch(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    if (
      proposal.transType === Enum.TransType.NEW &&
      proposal.proposalStatus === Enum.ProposalStatus.Incomplete
    ) {
      return true; //NB
    }
    return false;
  }

  public showManualUnderwriting(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal
  ): boolean {
    return true; //dummy for future
  }
}
