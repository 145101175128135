import { AbstractButtonPanel } from "./abstract-button-panel";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import * as Enums from "src/app/framework/enum.shared";
import { ProposalEntity } from "../../base/proposal-entity.model";
import * as Const from "./../../../../framework/const.shared";
import { common } from "./../../../../framework/utils/common";
import { MortgageFireSingaporeProposal } from "../../sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { MortgageFireHongKongProposal } from "../../sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";

export class DbsButtonPanel implements AbstractButtonPanel {
  GetIncompleteActions(
    identityRolesService: IdentityRolesService,
    proposal: ProposalEntity
  ): Array<Enums.ProposalCommandAction> {
    const actions = new Array<Enums.ProposalCommandAction>();
    switch (identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
        // Allow [Save] and [Bind Policy]
        actions.push(Enums.ProposalCommandAction.Save);
        actions.push(Enums.ProposalCommandAction.Submit);
        actions.push(Enums.ProposalCommandAction.MakeRedundant);
        break;

      case Enums.UserType.Broker:
        if (proposal.transType !== Enums.TransType.REN) {
          if (
            identityRolesService.isMaker &&
            common.isDefined(proposal.productId) &&
            proposal.productId?.toString().toUpperCase() !==
              Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
            proposal.productId?.toString().toUpperCase() !==
              Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
          ) {
            // Allow [Save], [Send To Checker] and [Delete]
            actions.push(Enums.ProposalCommandAction.Save);
            actions.push(Enums.ProposalCommandAction.SendToChecker);
            actions.push(Enums.ProposalCommandAction.MakeRedundant);
          } else if (
            identityRolesService.IsCSCBGSG &&
            common.isDefined(proposal.productId) &&
            proposal.productId?.toString().toUpperCase() !==
              Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
            proposal.productId?.toString().toUpperCase() !==
              Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
          ) {
            actions.push(Enums.ProposalCommandAction.Save);
            actions.push(Enums.ProposalCommandAction.Submit);
            actions.push(Enums.ProposalCommandAction.MakeRedundant);
          }
        } else if (identityRolesService.isChecker) {
          actions.push(Enums.ProposalCommandAction.MakeRedundant);
        }

        break;
    }

    return actions;
  }

  public GetApprovedActions(
    identityRolesService: IdentityRolesService,
    proposal: ProposalEntity
  ): Array<Enums.ProposalCommandAction> {
    const actions = new Array<Enums.ProposalCommandAction>();

    switch (identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
        if (
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null &&
          !proposal.auxiliary.IsPreviousYearTerminated
        ) {
          actions.push(Enums.ProposalCommandAction.Endorse);
          actions.push(Enums.ProposalCommandAction.Cancel);
        }
        // renewal
        if (
          (proposal.transType === Enums.TransType.NEW ||
            proposal.transType === Enums.TransType.END ||
            proposal.transType === Enums.TransType.REN) &&
          proposal.proposalType === Enums.ProposalType.Policy &&
          proposal.proposalStatus === Enums.ProposalStatus.Accepted &&
          proposal.pendingTransaction == null &&
          !proposal.auxiliary.IsPreviousYearTerminated
        ) {
          // 30 days before & 30 days after for HDB & PTE - CBG
          // 19 days before & 30 days after for MIP - CBG
          // 88 days before & 30 days after for MAR & FIRE - IBG
          if (proposal.auxiliary.allowRenewal) {
            actions.push(Enums.ProposalCommandAction.Renew);
          }
        }
        // reinstate year2 cancellation
        if (
          proposal.auxiliary.IsPreviousYearTerminated &&
          proposal.proposalStatus === Enums.ProposalStatus.Accepted &&
          proposal.pendingTransaction == null &&
          proposal.transType !== Enums.TransType.CAN
        ) {
          actions.push(Enums.ProposalCommandAction.Cancel);
        }
        // refund minimum premium
        if (
          proposal.transType === Enums.TransType.CAN &&
          proposal.pendingTransaction == null &&
          proposal.auxiliary.DisplayCancellationAdjustment
        ) {
          actions.push(Enums.ProposalCommandAction.CancellationAdjustment);
        }
        break;
      case Enums.UserType.Broker:
        // if (this.identityRolesService.role.isMaker &&
        //     proposal.transType !== Enums.TransType.CAN &&
        //     proposal.pendingTransaction == null
        //     && proposal.productId.toString().toUpperCase() !== Const.ProductID.PRODUCT_ID_DBS_MF_IBG
        //     && proposal.productId.toString().toUpperCase() !== Const.ProductID.PRODUCT_ID_DBS_MACH_IBG) {
        //     actions.push(Enums.ProposalCommandAction.Endorse);
        //     actions.push(Enums.ProposalCommandAction.Cancel);
        // }

        // if (this.identityRolesService.role.isMaker &&
        //     proposal.auxiliary.IsPreviousYearTerminated
        //     && proposal.proposalStatus === Enums.ProposalStatus.Accepted && proposal.pendingTransaction == null
        //     && proposal.transType !== Enums.TransType.CAN) {
        //         actions.splice(0, 1);
        //     actions.push(Enums.ProposalCommandAction.Cancel);
        // }

        if (
          identityRolesService.isMaker &&
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null
        ) {
          if (
            proposal.proposalStatus === Enums.ProposalStatus.Accepted &&
            proposal.auxiliary.IsPreviousYearTerminated
          ) {
            actions.push(Enums.ProposalCommandAction.Cancel);
          } else {
            if (
              proposal.productId?.toString().toUpperCase() !==
                Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
              proposal.productId?.toString().toUpperCase() !==
                Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
            ) {
              actions.push(Enums.ProposalCommandAction.Endorse);
              actions.push(Enums.ProposalCommandAction.Cancel);
            }
          }
        }
        if (
          identityRolesService.IsCbsOps &&
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null
        ) {
          actions.push(Enums.ProposalCommandAction.Endorse);
        }

        if (
          identityRolesService.IsCSCBGSG &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG &&
          proposal.productId?.toString().toUpperCase() !==
            Const.ProductID.PRODUCT_ID_DBS_MACH_IBG &&
          proposal.transType !== Enums.TransType.CAN &&
          proposal.pendingTransaction == null
        ) {
          actions.push(Enums.ProposalCommandAction.Endorse);
        }

        break;
    }

    return actions;
  }

  public ProductAllowApproved(proposalEntity: ProposalEntity): boolean {
    // DBSEP-2136
    if (
      proposalEntity.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
      proposalEntity.productId?.toString().toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
    ) {
      return true;
    } else {
      return false;
    }
  }

  public CanSave(
    showSaveAction: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    return showSaveAction && existingLogic; //todo: code changed, for future
  }

  public CanEditQuotation(
    showEditQuotation: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    return showEditQuotation && existingLogic; //todo: code changed, for future
  }

  public CanEdit(
    showEditAction: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    return showEditAction && existingLogic; //todo: code changed, for future
  }

  public CanApprove(
    showApproveAction: boolean,
    existingLogic: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    return showApproveAction && existingLogic; //todo: code changed, for future
  }

  public CanChecked(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireSingaporeProposal
  ) {
    return (
      !proposal.isUnderwriter &&
      !identityRolesService.IsCbsOps &&
      !identityRolesService.IsCSCBGSG
    );
  }

  public CanBindPolicy(
    identityRolesService: IdentityRolesService,
    hideActionsCommandsByIBG: boolean
  ): boolean {
    return (
      (identityRolesService.hasProductTransactRoles &&
        !hideActionsCommandsByIBG) ||
      (identityRolesService.IsCbsOps && hideActionsCommandsByIBG)
    );
  }

  public CanReferToUnderwriter(
    showSubmitAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    //the rest handle by this.actions.submit.visible
    return showSubmitAction; //dummy/temporary, for future; submit can be 'bind' or 'Checked'
  }

  public CanSubmit(
    showSubmitAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    //the rest handle by this.actions.submit.visible
    return showSubmitAction; //dummy/temporary, for future; submit can be 'bind' or 'Checked'
  }
  public CanSubmitForREN(
    showSubmitAction: boolean,
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    //the rest handle by this.actions.submit.visible
    return showSubmitAction; //dummy/temporary, for future; submit can be 'bind' or 'Checked'
  }
}
