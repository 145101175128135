import { SectionTab } from "./../../../../../base/section-tab.model";

class MortgageFireSingaporeTabSchema {
  tabs!: SectionTab[];
  master!: SectionTab;
  locations!: SectionTab;
  businessInterruption!: SectionTab;
  money!: SectionTab;
  premiumSummary!: SectionTab;

  constructor() {
    this.initialize();
  }

  initialize() {
    this.master = new SectionTab("Master", 0);
    this.locations = new SectionTab("Locations", 2);
    this.businessInterruption = new SectionTab("Business Interruption", 3);
    this.money = new SectionTab("Money", 4);
    this.premiumSummary = new SectionTab("Premium Summary", 1);

    this.tabs = [];
    this.tabs.push(this.master);
    this.tabs.push(this.locations);
    this.tabs.push(this.businessInterruption);
    this.tabs.push(this.money);
    this.tabs.push(this.premiumSummary);
  }
}

export const MortgageFireSGTabSchema = new MortgageFireSingaporeTabSchema();
