import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { DocumentService } from "./document/document.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class ClientTimelineService extends ApiService {
  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService,
    public documentService: DocumentService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  getMessage(proposalId: any) {
    var uri = "api/proposal/getMessage";
    var params = {
      proposalId: proposalId,
    };
    var data = {};
    return this.get(uri, params, data);
  }

  saveMultiyearOption(serverModel: any) {
    return this.saveConsent(serverModel);
  }

  saveConsent(newMessage: any) {
    var uri = "api/proposal/saveConsent";
    var params = {};
    var data = {};
    return this.add(uri, params, newMessage);
  }
}
