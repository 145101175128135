<div
  style="
    min-height: 100%;
    position: relative;
    min-height: 100%;
    position: relative;
  "
>
  <div class="">
    <div class="col-md-12" style="padding: 15px 0 25px">
      <div class="col-md-3" style="padding: 20px 0 0 35px">
        <!-- <img
          id="chubbLogo"
          src=""
          onerror="this.src='assets\Chubb-black.png'"
        /> -->
      </div>
      <div class="col-md-6" style="padding: 7px 0 0 9%">
        <div style="font-size: 33px">E-placement℠</div>
      </div>
      <!-- <div class="col-md-3">
                <select kendo-drop-down-list k-data-text-field="'displayText'" k-data-value-field="'languageID'" k-data-source="availableLanguages"
                    k-ng-model="selectedLanguageId" k-on-change="setLanguage(selectedLanguageId)" k-value-primitive="true">
                </select>
            </div> -->
      <div class="col-md-3"></div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
  <div class="col-md-12" style="padding: 15px 0 25px">
    <div class="col-md-3" style="padding: 20px 0 0 35px"></div>
    <div class="col-md-6" style="padding: 10px 0 0 9%">
      <div ng-repeat="option in userTypeOptions.Items">
        <!-- <label>
          <input
            type="radio"
            name="usertype"
            ng-value="option"
            ng-model="$parent.radioModValue"
          />
          {{ option.OrganisationName }}</label
        > -->
        <br /><br />
      </div>
      <br />
      <br />
      <button
        class="btn btn-primary chubbPurple_bg ng-isolate-scope"
        name="submit"
        style="width: 250px"
        ng-click="SubmitLogin()"
      >
        Login
      </button>
    </div>
    <div class="col-md-3"></div>
  </div>
  <div>
    <div class="col-md-12 marginTop15">
      <div class="col-md-3"></div>
      <div class="col-md-6 marginTop30" ng-hide="isBrowserSupported">
        <!--isBrowserSupported-->
        <div class="card-body" align="center">
          <div class="row browserNotSupportedMsgDiv">
            <table class="table table-bordered">
              <tr class="chubbRed_bg chubbWhite">
                <td>Your current internet browser is not supported.</td>
              </tr>
              <tr>
                <td>
                  <p>
                    The following are the recommended minimum internet browser
                    versions that can be used to access the System via Internet
                    :
                  </p>
                  <ul>
                    <li class="marginTop10">
                      Microsoft Internet Explorer Version 11 and above
                    </li>
                    <li>Google Chrome 44.0.2403.155 and above</li>
                    <li>Mozilla Firefox 40.0 and above</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          <div class="row textAlignCenter footerLoginForBrowserDetectionMsg">
            <p data-ng-bind="footerTitle"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- start -: Notification for user - migration of E-Placement URL for Australia & Singapore-->
  <div class="col-md-12 marginTop30 textAlignCenter">
    <div class="col-md-1"></div>
  </div>
  <!-- end -: Notification for user - migration of E-Placement URL for Australia & Singapore-->
  <!--end .row -->

  <!--end .card-body -->
  <div class="row maxWidth" style="font-family: 'Publico'">
    <!--<div class="col-md-12 ">
                <div class="col-md-3"></div>
                <div class="col-md-9 " style="padding: 0 0 0 9.9%;max-width: 540px; ">
                    <div class="chubbLightGray_border padding5">
                        <p>ACE and Chubb are now one.</p>
                        <p class="marginTop15">
                            Effective from 1 May 2016, our URL has changed from https://einsurance.acejerneh.com.my to
                            https://ams.chubb.com.
                        </p>
                        <p>Please update your bookmarks to the latest URL.</p>
                    </div>

                </div>
            </div>-->
    <div class="col-md-12 marginTop30">
      <div class="col-md-3"></div>
      <div
        class="col-md-9"
        style="padding: 0 0 0 9.9%"
        data-ng-bind="footerTitle"
      ></div>
    </div>
  </div>
</div>
