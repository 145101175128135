import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UploadExcelService } from "./upload-excel.service";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FileUploadService extends UploadExcelService {
  SERVER_URL_UPLOADDOCUMENT: string = "api/document/uploadFile";

  constructor(public override httpClient: HttpClient) {
    super(httpClient);
  }

  public save(formData: any) {
    return this.uploadAsResponse(
      formData,
      environment.serviceBase + this.SERVER_URL_UPLOADDOCUMENT
    );
  }
}
