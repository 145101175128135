import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as saveAs from "file-saver";
import { environment } from "../../environments/environment"; //use the new one in src folder, old one mainly for guid.ts only
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class PolicyCustomerLoginService extends ApiService {
  private otpStatus: string | undefined;
  SERVER_URL_GET_OTP: string = "api/publicFacingCustomer/getOtp";
  SERVER_URL_VALIDATE_OTP: string = "api/publicFacingCustomer/validateOtp";
  SERVER_URL_CALCULATE_PREMIUM: string =
    "api/publicFacingCustomer/calculatePremium";
  SERVER_URL_ACCEPT_REJECT_OFFER: string =
    "api/publicFacingCustomer/acceptRejectOffer";
  SERVER_URL_GET_FAQS: string = "api/publicFacingCustomer/getFaqs";

  allPoliciesDownloadEntityList: any;
  premiumDetail: any;
  offerDetail: any;
  isCustomerLogin: boolean = false;
  isCustomerOTP: boolean = false;
  isCustomerOffer: boolean = false;

  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  async getOTP(
    userEmailMobile: string,
    dob: string,
    last4UniqueId: string
  ): Promise<any> {
    const postData = {
      userEmailOrMobile: userEmailMobile,
      dobInDDMMYY: dob,
      last4UniqueId: last4UniqueId,
    };

    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/json",
    });
    const response = await this.httpClient
      .post<any>(environment.serviceBase + this.SERVER_URL_GET_OTP, postData, {
        headers: headers,
      })
      .toPromise();
    if (response) this.otpStatus = response;
    return this.otpStatus;
  }

  async validateOtpWithDds(enc: string, otp: string): Promise<any> {
    const postData = {
      enc: enc,
      otp: otp,
    };

    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/json",
    });
    const response = await this.httpClient
      .post<any>(
        environment.serviceBase + this.SERVER_URL_VALIDATE_OTP,
        postData,
        {
          headers: headers,
        }
      )
      .toPromise();
    if (response) this.otpStatus = response;
    return this.otpStatus;
  }

  async calculatePremium(
    enc: string,
    encProposalId: string,
    encExpiryDate: string
  ): Promise<any> {
    const postData = {
      enc: enc,
      encProposalId: encProposalId,
      transType: 4,
      encExpiryDate: encExpiryDate,
    };

    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/json",
    });
    const response = await this.httpClient
      .post<any>(
        environment.serviceBase + this.SERVER_URL_CALCULATE_PREMIUM,
        postData,
        {
          headers: headers,
        }
      )
      .toPromise();
    if (response) this.premiumDetail = response;
    return this.premiumDetail;
  }

  async acceptReject(
    enc: string,
    encProposalId: string,
    policyNumber: string,
    acceptOrReject: string
  ): Promise<any> {
    const postData = {
      enc: enc,
      encProposalId: encProposalId,
      policyNumber: policyNumber,
      acceptOrReject: acceptOrReject,
    };

    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/json",
    });
    const response = await this.httpClient
      .post<any>(
        environment.serviceBase + this.SERVER_URL_ACCEPT_REJECT_OFFER,
        postData,
        {
          headers: headers,
        }
      )
      .toPromise();
    if (response) this.offerDetail = response;
    return this.offerDetail;
  }

  downloadFaqs = () => {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/pdf",
    });
    const params = {};

    return this.httpClient
      .get(environment.serviceBase + this.SERVER_URL_GET_FAQS, {
        headers: headers,
        params: params,
        responseType: "arraybuffer",
        observe: "response",
      })
      .subscribe(
        (result: any) => {
          const fileName: string = "FAQs.pdf";
          const file = new Blob([result.body], { type: "application/pdf" });
          const url = window.URL.createObjectURL(file);
          saveAs(url, fileName);
        },
        (error: any) => {}
      );
  };
}
