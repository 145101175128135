<kendo-dialog
  title="Occupation List"
  class="modal-title-light-orange"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="850"
  [height]="550"
>
  <div class="container-fluid-custom">
    <div id="searchNewClient" class="row padding5 masterPage">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <form role="form">
              <div class="form-group">
                <label
                  for="clientName_textBox"
                  class="floatLeft padding10"
                  [textContent]="'Search'"
                ></label>
                <div class="floatLeft">
                  <input
                    id="occupation_textBox"
                    type="text"
                    class="k-textbox form-control"
                    [(ngModel)]="occupationKeyword"
                    (input)="search($event)"
                    name="occupationKeyword"
                    #name="ngModel"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <form role="form"></form>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <hr />
                <div id="occupation-search-grid" class="grid-wrapper">
                  <kendo-grid
                    style="cursor: pointer"
                    [kendoGridBinding]="occupationList"
                    [data]="gridView"
                    [pageSize]="pageSize"
                    [skip]="skip"
                    [pageable]="true"
                    [height]="380"
                    [resizable]="true"
                    (pageChange)="pageChange($event)"
                    [selectable]="true"
                    filterable="menu"
                    mode="multiple"
                    kendoGridSelectBy
                    [(selectedKeys)]="selectedRowIndexes"
                    (selectionChange)="gridSelectionChange($event)"
                    (cellClick)="cellClickHandler($event)"
                  >
                    <kendo-grid-column field="key" title="Code " [width]="100">
                    </kendo-grid-column
                    ><kendo-grid-column field="value" title="Occupation">
                    </kendo-grid-column>
                    <kendo-grid-column
                      field="action"
                      title="Action"
                      [width]="100"
                      [filterable]="false"
                    >
                      <ng-template
                        kendoGridCellTemplate
                        let-dataItem
                        let-rowIndex="rowIndex"
                      >
                        <span
                          ng-if="!proposalReadOnly!"
                          class="k-grid-button k-grid-Edit fa fa-plus-square-o"
                          title="Select"
                        >
                          <img id="edit" />
                        </span>
                      </ng-template>
                    </kendo-grid-column>
                    <ng-template
                      kendoPagerTemplate
                      let-totalPages="totalPages"
                      let-currentPage="currentPage"
                    >
                      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                      <kendo-pager-numeric-buttons
                        [buttonCount]="10"
                      ></kendo-pager-numeric-buttons>
                      <kendo-pager-info></kendo-pager-info>
                      <kendo-pager-next-buttons></kendo-pager-next-buttons>
                      <kendo-pager-page-sizes
                        [pageSizes]="pageSizes"
                      ></kendo-pager-page-sizes>
                    </ng-template>
                  </kendo-grid>
                  <div *ngIf="loading" class="k-i-loading"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer-border-top"></div>
</kendo-dialog>
