import * as EnumSG from "../../sme/mortgage-fire/singapore/base/mf-sg-enums";
import { AbstractDocType } from "./abstract-doc-type";

export class DbsDocType implements AbstractDocType {
  public getDocTypeList(
    clientType: EnumSG.ClientType
  ): Array<{ text: string; value: string }> {
    return [
      { text: "Singapore NRIC", value: "1" },
      { text: "Singapore PR", value: "2" },
      { text: "Malaysia IC", value: "3" },
      { text: "Foreign Passport", value: "4" },
    ];
  }

  public getDocTypeCaption(): string {
    return "Document Type";
  }

  public showClientTypeOthers(): boolean {
    return false;
  }
}
