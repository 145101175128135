import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { AbstractGooglePlaces } from "./abstract-google-places";

export class DbsGooglePlaces implements AbstractGooglePlaces {
  public getGooglePlacesOptions(): Options {
    return {
      types: ["address"],
      componentRestrictions: { country: ["sg"] },
    } as unknown as Options;
  }
}
