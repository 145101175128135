<!-- autoDebit -->
<div id="autoDebitTextFileFields" class="reportOption container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group row">
        <div class="col-md-1">
          <label class="mt-2">AD Month (Generated 6th of every month)</label>
        </div>
        <div class="col-md-2">
          <kendo-datepicker
            class="maxWidth"
            format="MMMM  yyyy"
            placeholder=""
            [bottomView]="'year'"
            [(ngModel)]="searchCriteria.month"
            name="searchMonth"
            #name="ngModel"
          >
          </kendo-datepicker>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      (click)="download()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
    </button>
  </div>
</div>
