import { KeyValue } from "@angular/common";
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import {
  GridDataResult,
  PageSizeItem,
  PageChangeEvent,
  SelectionEvent,
  CellClickEvent,
} from "@progress/kendo-angular-grid";
import { IProduct } from "src/app/framework/interface.product";
import { common } from "src/app/framework/utils/common";
import { MasterDataService } from "src/app/services/master-data.service";
import {
  MasterPolicyEntity,
  MasterPolicyService,
} from "src/app/services/master-policy.service";
import { SearchCriteria } from "../base/search-criteria";
import { warningZoneArgs } from "../warning-zone/warning-zone.component";
import { OpenItemStatus } from "./../../../../finance/finance-enums";
import * as Enum from "./../../../../framework/enum.shared";
import * as AppEnums from "src/app/framework/app.enums";
import * as HomeConstant from "./../home-constant";
import { proposalCommon } from "../../base/utils/proposal-common";
import { PolicyListingEntity } from "./PolicyListingEntity";
import { IUser } from "src/app/framework/interface.shared";
import { Router } from "@angular/router";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { ROUTE_PROPOSAL } from "src/app/app.module";
import { BaseListingComponent } from "../../base/base-listing/base-listing.component";

@Component({
  selector: "app-policy-list",
  templateUrl: "./policy-list.component.html",
  styleUrls: ["./policy-list.component.scss"],
})
export class PolicyListComponent
  extends BaseListingComponent
  implements OnInit
{
  @ViewChild("heroForm", { static: false })
  private form!: UntypedFormGroup;

  @ViewChild("toDoStartDateTo", { static: false })
  public toDoStartDateTo!: DatePickerComponent;

  public toDoStartDateTo_min!: Date;
  public toDoStartDateTo_max!: Date;

  @ViewChild("toDoExpiryDateTo", { static: false })
  public toDoExpiryDateTo!: DatePickerComponent;

  public toDoExpiryDateTo_min!: Date;
  public toDoExpiryDateTo_max!: Date;

  @ViewChild("toDoEffectiveDateTo", { static: false })
  public toDoEffectiveDateTo!: DatePickerComponent;

  public toDoEffectiveDateTo_min!: Date;
  public toDoEffectiveDateTo_max!: Date;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  private listing: PolicyListingEntity[] = [];
  private totalListing: number = 0;
  submitted = false;

  currentUser: IUser | undefined;
  model = new SearchCriteria();

  public userProductList!: IProduct[];
  private masterPolicyList!: MasterPolicyEntity[];
  public masterPolicyOptions!: Array<{ text: string; value: string }>;
  public proposalStatusOptions!: Array<{ text: string; value: number }>;
  public userTeamOptions!: Array<{ text: string; value: number }>;
  public isCitiUser: boolean = false;
  public isDBSUser: boolean = false;
  public isChubbUser: boolean = false;
  public defaultUserListItem: KeyValue<string, string> = {
    key: "All",
    value: "",
  };
  public defaultMasterPolicyOptionsItem: { text: string; value: string } = {
    text: "Select master policy no...",
    value: "",
  };
  public loading!: boolean;

  @Output() warningZoneEvent = new EventEmitter<any>();

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public masterPolicyService: MasterPolicyService,
    public masterDataService: MasterDataService
  ) {
    super(router, broadcastService, identityRolesService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.currentUser = this.identityRolesService.getIdentity().currentUser();
    this.fetchMasterPolicyList();
    this.defaultSearchCriteria();
    this.getUserOrganisation();
    this.refresh();
  }

  private getUserOrganisation() {
    //Hide/Show the appropriate search name for Bank Reference No/Collateral Reference No
    switch (this.currentUser?.organisation?.description) {
      case Enum.UserOrganisation.DBS:
        this.isCitiUser = false;
        this.isChubbUser = false;
        this.isDBSUser = true;
        break;
      case Enum.UserOrganisation.Citi:
        this.isCitiUser = true;
        this.isChubbUser = false;
        this.isDBSUser = false;
        break;
      default:
        this.isCitiUser = false;
        this.isChubbUser = true;
        this.isDBSUser = false;
        break;
    }
  }

  /** Default search criteria while page load */
  private defaultSearchCriteria() {
    let products = this.currentUser?.products;
    if (products != undefined) {
      this.userProductList = products;
    }

    // Status dropdownlist options
    const proposalStatusOptions = [
      { text: "Draft", value: 6 },
      { text: "Pending Checker", value: 18 },
      { text: "Referred", value: 3 },
      { text: "Approved by Underwriter", value: 1 },
      { text: "Declined by Underwriter", value: 4 },
    ];
    this.proposalStatusOptions = proposalStatusOptions;

    // user team dropdownlist options
    const userTeamOptions = [
      { text: "CBG-Customer Solutions", value: 4 },
      { text: "Loan Document", value: 1 },
      { text: "Loan Records", value: 2 },
      { text: "Loan Servicing", value: 3 },
      { text: "Maker & Checker", value: 6 },
      { text: "NA", value: 5 },
    ];
    this.userTeamOptions = userTeamOptions;

    // effectiveDateFrom (14 days earlier from today)
    const effectiveDateFrom: Date = new Date(new Date().getFullYear(), 0, 1);
    this.model.effectiveDateFrom = effectiveDateFrom;

    // effectiveDateFrom ( 6 months after effectiveDateFrom)
    const effectiveDateTo: Date = new Date(
      effectiveDateFrom.getFullYear(),
      11,
      31
    );
    this.model.effectiveDateTo = effectiveDateTo;
  }

  private fetchMasterPolicyList = async () => {
    this.masterPolicyOptions = new Array<{ text: string; value: string }>();

    this.masterPolicyService.getList(this.currentUser!).subscribe(
      (result: any) => {
        this.masterPolicyList = result;

        // update list
        this.masterPolicyList.forEach((mp) => {
          const displayText =
            mp.policyType +
            " - " +
            mp.description +
            " - " +
            mp.meridianMasterPolicyNumber +
            " (MSIG: " +
            mp.policyNumber +
            ")";
          this.masterPolicyOptions.push({ text: displayText, value: mp.id });
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  };

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }

  public gridSelectionChange(selection: SelectionEvent) {
    // let selectedData = grid.data.data[selection.index];
    //const selectedData = selection?.selectedRows?[0]?.dataItem;
    const selectedData = selection?.selectedRows;
    if (selectedData != undefined && selectedData.length > 0) {
      var t = selectedData[0].dataItem;
    }
  }

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    const productId = dataItem.productId;
    const proposalId = dataItem.proposalId;

    const route = AppEnums.productsRoute[productId + "|1"];
    const commandAction = AppEnums.proposalCommandAction["Open"];
    if (route !== undefined) {
      this.router.navigate(["/" + ROUTE_PROPOSAL], {
        queryParams: {
          pdid: productId,
          pid: proposalId,
          action: commandAction,
        },
      });
    }
  }

  private refresh(): void {
    this.loading = true;
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "policyInceptionDate");
    formData.append("sort[0][dir]", "desc");

    let array = [
      ["startDateFrom", "gte", this.model.startDateFrom],
      ["startDateTo", "gte", this.model.startDateTo],
      ["expiryDateFrom", "gte", this.model.expiryDateFrom],
      ["expiryDateTo", "gte", this.model.expiryDateTo],
      ["effectiveDateFrom", "gte", this.model.effectiveDateFrom],
      ["effectiveDateTo", "lte", this.model.effectiveDateTo],
      ["quoteNo", "eq", this.model.quoteNo],
      ["policyNo", "eq", this.model.policyNo],
      ["masterPolicyId", "eq", this.model.masterPolicyId],
      ["cin", "eq", this.model.cin],
      ["insuredName", "eq", this.model.insuredName],
      ["productId", "eq", this.model.productId],
      ["proposalStatus", "eq", this.model.proposalStatus],
      ["bankReferenceNo", "eq", this.model.bankReferenceNo],
      ["createdByUserId", "eq", this.model.createdByUserId],
      ["userTeam", "eq", this.model.userTeam],
      ["additionalInsuredName", "eq", this.model.additionalInsuredName],
      ["bcrn", "eq", this.model.bcrn],
      ["riskAddressPostalCode", "eq", this.model.riskAddressPostalCode],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    this.masterPolicyService.searchPolicyListing(querystring).subscribe(
      (result: any) => {
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          this.listing = result.body.d?.results;
          this.totalListing = result.body.d?.__count;
          this.loadItems();
          this.loading = false;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  private loadItems(): void {
    this.gridView = {
      data: this.listing, //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }

  public on_searchStartDateFrom_change(value: Date): void {
    if (this.model.startDateFrom === null) {
      this.model.startDateTo = undefined;
      this.toDoStartDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoStartDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.startDateTo &&
      this.model.startDateFrom &&
      this.model.startDateTo < this.model.startDateFrom
    ) {
      this.model.startDateTo = undefined;
      this.toDoStartDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoStartDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.toDoStartDateTo_min = value;
    this.toDoStartDateTo_max = maxDate;

    // open datepicker
    this.toDoStartDateTo.toggle();
    this.on_searchStartDateFrom_focus();
  }

  public on_searchStartDateFrom_focus(): void {
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
  }

  public on_searchExpiryDateFrom_change(value: Date): void {
    if (this.model.expiryDateFrom === null) {
      this.model.expiryDateTo = undefined;
      this.toDoExpiryDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoExpiryDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }

    if (
      this.model.expiryDateTo &&
      this.model.expiryDateFrom &&
      this.model.expiryDateTo < this.model.expiryDateFrom
    ) {
      this.model.expiryDateTo = undefined;
      this.toDoExpiryDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoExpiryDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.toDoExpiryDateTo_min = value;
    this.toDoExpiryDateTo_max = maxDate;

    // open datepicker
    this.toDoExpiryDateTo.toggle();
    this.on_searchExpiryDateFrom_focus();
  }

  public on_searchExpiryDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
  }

  public on_searchEffectiveDateFrom_change(value: Date): void {
    if (this.model.effectiveDateFrom === null) {
      this.model.effectiveDateTo = undefined;
      this.toDoEffectiveDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoEffectiveDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.effectiveDateTo &&
      this.model.effectiveDateFrom &&
      this.model.effectiveDateTo < this.model.effectiveDateFrom
    ) {
      this.model.effectiveDateTo = undefined;
      this.toDoEffectiveDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoEffectiveDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    // open datepicker
    this.toDoEffectiveDateTo.toggle();
    this.on_searchEffectiveDateFrom_focus();
  }

  public on_searchEffectiveDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
  }

  private validateMandatoryFields(): boolean {
    let isValid: boolean = false;

    // dates
    const dates: boolean =
      this.model.startDateFrom !== null ||
      this.model.startDateTo !== null ||
      this.model.expiryDateFrom !== null ||
      this.model.expiryDateTo !== null ||
      this.model.effectiveDateFrom !== null ||
      this.model.effectiveDateTo !== null;

    isValid = dates; // || etc...

    return isValid;
  }

  private checkDatesRange() {
    if (this.model.startDateFrom !== null && this.model.startDateTo === null) {
      this.model.startDateTo = this.model.startDateFrom;
    }

    if (
      this.model.expiryDateFrom !== null &&
      this.model.expiryDateTo === null
    ) {
      this.model.expiryDateTo = this.model.expiryDateFrom;
    }

    if (
      this.model.effectiveDateFrom !== null &&
      this.model.effectiveDateTo === null
    ) {
      this.model.effectiveDateTo = this.model.effectiveDateFrom;
    }
  }

  public onSubmit(): void {
    if (this.form.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }

    this.checkDatesRange();

    const isValid = this.validateMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      //$('#warningZone').collapse('hide');
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
      // $('#warningZone #message').text('Please select a date range.');
      // $('#warningZone').collapse('show');
      // httpNotifier.response_notifier$.next(new KeyValue('', e));
    }
  }

  public getOpenItemStatusDisplayText(dataItem: any): string {
    const openItemStatusEnum: OpenItemStatus = dataItem.openItemStatusId;

    return openItemStatusEnum === OpenItemStatus.Undefined
      ? "-"
      : proposalCommon.translateOpenItemStatus(openItemStatusEnum);
  }

  public getCinDisplayText(dataItem: any): string {
    if (common.isStringNullOrEmpty(dataItem.cin)) {
      return "-";
    }
    return dataItem.cin;
  }

  public getMsigPolicyNoDisplayText(dataItem: any): string {
    return common.isStringNullOrEmpty(dataItem.MSIGPolicyNo) ||
      dataItem.policyNo == dataItem.MSIGPolicyNo
      ? "-"
      : dataItem.MSIGPolicyNo;
  }

  public getProposalTypeDisplayText(dataItem: any): string {
    return HomeConstant.transactionType[dataItem.proposalTransTypeId];
  }

  public hasTransactionHistories(dataItem: PolicyListingEntity): boolean {
    return dataItem.transactionHistories !== "[]";
  }
}
