<div class="wrapper">
  <kendo-tabstrip style="padding-top: 30px">
    <kendo-tabstrip-tab title="Batch Invoice" [selected]="true">
      <ng-template kendoTabContent>
        <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
          <div class="container-fluid mt-2">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row lbl-align">
                  <div class="col-md-4">
                    <label for="oiPolicyNo">Policy No</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      name="policyNo"
                      #name="ngModel"
                      id="oiPolicyNo"
                      placeholder="Policy No"
                      (input)="on_quoteNumber_keyup($event)"
                      [(ngModel)]="searchCriteria.policyNo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row lbl-align">
                  <div class="col-md-3">
                    <label for="oiEffectiveDateFrom"
                      >Batch Invoice Entry Date</label
                    >
                  </div>
                  <div class="col-md-4">
                    <kendo-datepicker
                      class="maxWidth"
                      id="oiEffectiveDateFrom"
                      format="dd/MM/yyyy"
                      (focus)="on_searchOIFrom_focus()"
                      placeholder=""
                      [(ngModel)]="searchCriteria.effectiveDateFrom"
                      (valueChange)="on_searchEffectiveDateFrom_change($event)"
                      name="EffectiveDateFrom"
                      #name="ngModel"
                    ></kendo-datepicker>
                  </div>
                  <div class="col-md-1 textAlignCenter">
                    <label class="mt-2">to</label>
                  </div>
                  <div class="col-md-4">
                    <kendo-datepicker
                      class="maxWidth"
                      #oiEffectiveDateTo
                      id="oiEffectiveDateTo"
                      format="dd/MM/yyyy"
                      placeholder=""
                      [min]="openItemEntryDate_min"
                      [max]="openItemEntryDate_max"
                      [(ngModel)]="searchCriteria.effectiveDateTo"
                      name="EffectiveDateTo"
                      #name="ngModel"
                    ></kendo-datepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="mt-2" for="oiBatchId">Batch ID</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      id="oiBatchId"
                      placeholder="Batch ID"
                      (keypress)="
                        ($event.charCode >= 48 && $event.charCode < 58)
                      "
                      name="invoiceNo"
                      #name="ngModel"
                      [(ngModel)]="searchCriteria.invoiceNo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-md-3">
                    <label for="oiEffectiveDateFrom"
                      >Policy Effective Date</label
                    >
                  </div>
                  <div class="col-md-4">
                    <kendo-datepicker
                      class="maxWidth"
                      id="oiPolicyEffectiveDateFrom"
                      format="dd/MM/yyyy"
                      placeholder=""
                      [(ngModel)]="searchCriteria.policyeffectiveDateFrom"
                      (valueChange)="
                        on_searchPolicyEffectiveDateFrom_change($event)
                      "
                      name="PolicyEffectiveDateFrom"
                      #name="ngModel"
                    ></kendo-datepicker>
                  </div>
                  <div class="col-md-1 textAlignCenter">
                    <label class="mt-1">to</label>
                  </div>
                  <div class="col-md-4">
                    <kendo-datepicker
                      class="maxWidth"
                      id="oiPolicyEffectiveDateTo"
                      format="dd/MM/yyyy"
                      placeholder=""
                      [(ngModel)]="searchCriteria.policyeffectiveDateTo"
                      name="PolicyEffectiveDateTo"
                      #name="ngModel"
                    ></kendo-datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label class="mt-2" for="reportSegment">Segment</label>
                  </div>
                  <div class="col-md-10">
                    <kendo-multiselect
                      id="reportSegment"
                      placeholder="Select segment..."
                      [autoClose]="false"
                      [kendoDropDownFilter]="{
                        operator: 'contains'
                      }"
                      [data]="segmentList"
                      textField="text"
                      valueField="value"
                      [valuePrimitive]="true"
                      [(ngModel)]="searchCriteria.segments"
                      name="segments"
                    >
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label class="mt-2" for="oiProduct">Product</label>
                  </div>
                  <div class="col-md-10">
                    <kendo-multiselect
                      id="oiProduct"
                      placeholder="Select product..."
                      [autoClose]="false"
                      [kendoDropDownFilter]="{
                        operator: 'contains'
                      }"
                      [data]="userProductList"
                      textField="pd"
                      valueField="pdid"
                      [valuePrimitive]="true"
                      [(ngModel)]="searchCriteria.productIds"
                      name="productIds"
                    >
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label class="mt-2" for="oiStatus">Status</label>
                  </div>
                  <div class="col-md-10">
                    <kendo-multiselect
                      id="oiStatus"
                      placeholder="Select status..."
                      [autoClose]="false"
                      [kendoDropDownFilter]="{
                        operator: 'contains'
                      }"
                      [data]="statusList"
                      textField="text"
                      valueField="value"
                      [valuePrimitive]="true"
                      [(ngModel)]="searchCriteria.statusIds"
                      name="statusIds"
                    >
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
<div class="ml-2">
  <button
    (click)="onSubmit()"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    event="1"
  >
    <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
  </button>
</div>
<div class="ml-2" *ngIf="isSearch">
  <hr />
  <button
    (click)="selectAllPolicy(true)"
    *ngIf="isSelectAll"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    event="1"
  >
    <i class="fa fa-check-square-o ng-scope" aria-hidden="true"></i> Select All
  </button>
  <button
    (click)="selectAllPolicy(false)"
    *ngIf="!isSelectAll"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    event="1"
  >
    <i class="fa fa-square-o ng-scope" aria-hidden="true"></i> Deselect All
  </button>
  <button
    (click)="generateSelectedPolicies()"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn ml-1"
    event="2"
  >
    <i class="fa fa-file-excel-o ng-scope" aria-hidden="true"></i> Batch &
    Download Policies
  </button>
  <button
    (click)="updateInvoiceNumber()"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    event="3"
  >
    <i class="fa fa-floppy-o ng-scope" aria-hidden="true"></i> Update Invoice No
  </button>
  <button
    (click)="downloadAllPolicies()"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn ml-1"
    event="4"
  >
    <i class="fa fa-floppy-o ng-scope" aria-hidden="true"></i> Export All
  </button>
</div>
<br />
<div class="grid-wrapper ml-3" *ngIf="isSearch">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="false"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    mode="multiple"
    kendoGridSelectBy
    (cellClick)="cellClickHandler($event)"
    [selectable]="true"
    [(selectedKeys)]="selectedRowIndexes"
  >
    <!-- (selectionChange)="gridSelectionChange($event)" -->
    <!-- <kendo-grid-checkbox-column field="isChecked" title="" [width]="30">
      <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem>
        <input [kendoGridSelectionCheckbox]="idx" *ngIf="!dataItem.batchId" />
      </ng-template>
    </kendo-grid-checkbox-column> -->
    <kendo-grid-column field="isChecked" title="" [width]="30">
      <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem>
        <input
          type="checkbox"
          *ngIf="!dataItem.batchId"
          [checked]="dataItem.isChecked"
          (click)="SetSelectedItem(dataItem, idx)"
        />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="policyNo" title="Policy No" [width]="140">
    </kendo-grid-column>
    <kendo-grid-column field="amount" title="Total Premium" [width]="90">
    </kendo-grid-column>
    <kendo-grid-column field="amountGst" title="GST Amount" [width]="90">
    </kendo-grid-column>
    <kendo-grid-column field="batchId" title="Batch ID" [width]="110">
    </kendo-grid-column>
    <kendo-grid-column field="statusDisplayText" title="Status" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column
      field="lastUpdatedByUserName"
      title="Last Updated By"
      [width]="130"
    >
    </kendo-grid-column>

    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="10"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
  <app-batch-invoice-details
    *ngIf="redirectOpened"
    [opened]="redirectOpened"
    [openItemId]="openItemId"
    (closeDialogEvent)="returnToBatchInvoiceListing($event)"
  >
  </app-batch-invoice-details>
  <app-update-invoice-number
    *ngIf="redirectUpdateInvoiceOpened"
    [opened]="redirectUpdateInvoiceOpened"
    [openItemId]="openItemId"
    (closeDialogEvent)="returnToBatchInvoiceListing($event)"
  >
  </app-update-invoice-number>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
<br />
<div #appendTo class="append-container"></div>
<br />
