import * as Enums from "./../../../../../../framework/enum.shared";

import { KeyValue } from "./../../../../../../framework/domain-entity/key-value";
import { MortgageFireSingaporeBusinessRulesManager } from "./mf-sg-business-rules.manager";
import { MortgageFireSingaporeFieldValidationManager } from "./mf-sg-field-validation.manager";
import { MortgageFireSingaporeProposal } from "./mf-sg-proposal";
import { QuestionEntity } from "./../../../../base/question-entity.model";
import { pageLifeCycleNotifier } from "./../../../../notifier/page-life-cycle-notifier";
import { questionNotifier } from "./../../../../notifier/question-notifier";
import { Subscription } from "rxjs";

export class MortgageFireSingaporeAnswerChangeManager {
  answerChangedSubscription: Subscription;

  constructor(
    private proposal: MortgageFireSingaporeProposal,
    private fieldValidationManager: MortgageFireSingaporeFieldValidationManager,
    private businessRulesManager: MortgageFireSingaporeBusinessRulesManager
  ) {
    this.answerChangedSubscription =
      questionNotifier.answer_change_notifier$.subscribe(this.answerChanged);

    pageLifeCycleNotifier.page_unload_notifier$.subscribe((event: any) => {
      this.destroy();
    });
  }

  private answerChanged = (event: KeyValue<QuestionEntity>) => {
    const questionName = event.key;
    const questionEntity = event.value;
  };

  public destroy() {
    this.answerChangedSubscription.unsubscribe();
  }
}
