<!-- citiWeeklysummaryInvoice -->
<div id="citiWeeklysummaryInvoice" class="container-fluid reportOption">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="mt-1"> AD Week</label>
        </div>
        <div class="col-md-5">
          <kendo-datepicker
            class="maxWidth"
            id="autoDebitPeriod_date_Week"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="searchCriteria.ADWeek"
            [disabledDates]="disabledDates"
            (valueChange)="on_SummaryAutoDebitWeekChange($event)"
          ></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="mt-1">Transaction Type</label>
        </div>
        <div class="col-md-5">
          <kendo-dropdownlist
            id="citiTransType"
            [data]="transactionTypeOption"
            textField="text"
            valueField="value"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.transactionType"
          >
          </kendo-dropdownlist>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      (click)="download()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
    </button>
  </div>
</div>
