import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { IProduct } from "src/app/framework/interface.product";
import { BroadcastService } from "src/app/services/broadcast.service";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { CbgDailyDownloadSearchCriteria } from "./cbg-daily-download.model";
@Component({
  selector: "app-cbg-daily-download",
  templateUrl: "./cbg-daily-download.component.html",
})
export class CbgDailyDownloadComponent extends BaseComponent implements OnInit {
  @ViewChild("dailyReportDateTo", { static: false })
  public dailyReportDateTo!: DatePickerComponent;
  public dailyReportDate_min!: Date;
  public dailyReportDate_max!: Date;
  @Input() presetReportType!: number;
  @Output() warningZoneEvent = new EventEmitter<any>();
  public searchCriteria = new CbgDailyDownloadSearchCriteria();
  public userTeamOptions!: Array<{ text: string; value: number }>;
  public segmentList!: Array<{ text: string; value: string }>;
  public isCitiProduct: boolean = false;
  public userProductList!: IProduct[];
  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    private presetExportService: PresetExportService,
    private excelTemplateService: ExcelTemplateService
  ) {
    super(router, broadcastService, identityRolesService);
  }
  override ngOnInit(): void {
    this.fetchUserList();
    this.defaultCbgDailyDownloadSearchCriteria();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.searchCriteria = new CbgDailyDownloadSearchCriteria();
    this.defaultCbgDailyDownloadSearchCriteria();
  }
  private defaultCbgDailyDownloadSearchCriteria(): void {
    // today

    const dateFrom: Date = new Date();
    dateFrom.setDate(dateFrom.getDate() - 14);
    this.searchCriteria.dateFrom = dateFrom;

    // dateTo: 6 months after dateFrom
    const dateTo: Date = new Date(dateFrom);
    dateTo.setMonth(dateTo.getMonth() + 6);
    this.searchCriteria.dateTo = dateTo;
    //segments
    this.segmentList = [
      { text: "CBG", value: "CBG" },
      { text: "IBG", value: "IBG" },
      { text: "PB", value: "PB" },
      { text: "TPC", value: "TPC" },
    ];
    // User Team
    this.userTeamOptions = [
      { text: "CBG-Customer Solutions", value: 4 },
      { text: "Loan Document", value: 1 },
      { text: "Loan Records", value: 2 },
      { text: "Loan Servicing", value: 3 },
      { text: "Maker & Checker", value: 6 },
      { text: "NA", value: 5 },
    ];
  }
  private fetchUserList() {
    // get user productsList
    if (this.isCitiProduct) {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return (
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    } else {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return !(
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    }
  }
  public on_dailyReportDateFrom_change(value: any): void {
    if (this.searchCriteria.dateFrom === null) {
      this.searchCriteria.dateTo = undefined;
      this.dailyReportDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.dailyReportDate_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.searchCriteria.dateTo &&
      this.searchCriteria.dateFrom &&
      this.searchCriteria.dateTo < this.searchCriteria.dateFrom
    ) {
      this.searchCriteria.dateTo = undefined;
      this.dailyReportDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.dailyReportDate_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.dailyReportDate_min = value;
    this.dailyReportDate_max = maxDate;

    // open datepicker
    this.dailyReportDateTo.toggle();
  }
  private validateCbgDailyDlMandatoryFields(): boolean {
    let isValid: boolean = false;
    // dates
    const dates: boolean = this.searchCriteria.dateFrom !== null;
    isValid = dates; // || etc...
    return isValid;
  }
  public download(): void {
    if (this.validateCbgDailyDlMandatoryFields()) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.downloadCbgDailyDownloadReport();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private formatDate(date: Date | undefined, dateTo: Date | undefined): string {
    if (date && dateTo) {
      return (
        date.getDate().toString() +
        "/" +
        (date.getMonth() + 1).toString() +
        "/" +
        date.getFullYear().toString() +
        " to " +
        dateTo.getDate().toString() +
        "/" +
        (dateTo.getMonth() + 1).toString() +
        "/" +
        dateTo.getFullYear().toString()
      );
    } else return "";
  }
  private downloadCbgDailyDownloadReport(): void {
    let reportTitle = "";
    switch (this.presetReportType) {
      case 1:
        reportTitle = "Daily NB Download";
        this.presetExportService
          .newBusinessCBGDailyDownload(
            this.searchCriteria.dateFrom,
            this.searchCriteria.dateTo,
            this.searchCriteria.segments,
            this.searchCriteria.userTeams,
            this.searchCriteria.productIds
          )
          .subscribe((response: any) => {
            this.excelTemplateService.downloadDailyCbgDownloadReport(
              dailyCbgDownloadReportColumn,
              "CBG Daily Download",
              reportTitle +
                " from " +
                this.formatDate(
                  this.searchCriteria.dateFrom,
                  this.searchCriteria.dateTo
                ),
              response.body.d.results
            );
          });
        break;
      case 2:
        reportTitle = "Daily EN Download";
        this.presetExportService
          .endorsementCBGDailyDownload(
            this.searchCriteria.dateFrom,
            this.searchCriteria.dateTo,
            this.searchCriteria.segments,
            this.searchCriteria.userTeams,
            this.searchCriteria.productIds
          )
          .subscribe((response: any) => {
            this.excelTemplateService.downloadDailyCbgDownloadReport(
              dailyCbgDownloadReportColumn,
              "CBG Daily Download",
              reportTitle +
                " from " +
                this.formatDate(
                  this.searchCriteria.dateFrom,
                  this.searchCriteria.dateTo
                ),
              response.body.d.results
            );
          });
        break;
      case 3:
        reportTitle = "Daily CA Download";
        this.presetExportService
          .cancellationCBGDailyDownload(
            this.searchCriteria.dateFrom,
            this.searchCriteria.dateTo,
            this.searchCriteria.segments,
            this.searchCriteria.userTeams,
            this.searchCriteria.productIds
          )
          .subscribe((response: any) => {
            this.excelTemplateService.downloadDailyCbgDownloadReport(
              dailyCbgDownloadReportColumn,
              "CBG Daily Download",
              reportTitle +
                " from " +
                this.formatDate(
                  this.searchCriteria.dateFrom,
                  this.searchCriteria.dateTo
                ),
              response.body.d.results
            );
          });
        break;
      default:
        break;
    }
  }
}
const dailyCbgDownloadReportColumn: string[] = [
  "S/N",
  "Financial Institution Branch",
  "Master Policy No",
  "Product Type",
  "Policy No",
  "Contract No",
  "Quote No",
  "Status",
  "Insured Name",
  "NRIC",
  "Situation",
  "City / Suburb",
  "Country",
  "Postal Code",
  "Sum Insured",
  "Gross Premium",
  "GST",
  "Total Premium",
  "Bank Reference",
  "Bound Date",
  "Effective Date",
  "Inception Date",
  "Expiry Date",
  "Maker's ID",
  "Date / Time",
  "Checker's ID",
  "Date / Time",
  "Status",
  "Remarks",
  "Refund Status",
  "Transaction Code Desc",
];
