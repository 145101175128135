import { formatDate } from "@angular/common";
import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { Day } from "@progress/kendo-date-math";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_HOME } from "src/app/app.module";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { Global } from "src/app/shared/global";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { ReconcileListService } from "src/app/services/reconcile-list.service";
import { UploadExcel } from "src/app/components/product/auto-loan-redemption/auto-loan-redemption.entity";
import { dlgModelArgs } from "src/app/components/shared/partials/md-confirmation-message-v2/md-confirmation-message-v2.component";
import { common } from "src/app/framework/utils/common";
import { SearchCriteria } from "src/app/components/accounting/reconcile/models/reconcile-list.model";
import { ReconcilePoliciesDownloadEntity } from "../../home/preset-report/preset-report.model";

@Component({
  selector: "app-base-reconcile-list",
  templateUrl: "./base-reconcile-list.component.html",
  styleUrls: ["./base-reconcile-list.component.scss"],
})
export class BaseReconcileListComponent implements OnInit {
  isSgProduct: boolean = false;
  isHkProduct: boolean = false;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    protected uploadService: UploadExcelService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected excelTemplate: ExcelTemplateService,
    protected reconcileService: ReconcileListService
  ) {}
  public disabledDates: Day[] = [
    Day.Saturday,
    Day.Sunday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
  ];
  isCitiProduct: boolean = false;
  autoDebitPeriod: any;
  isInvoice: boolean = true;
  isPeriod: boolean = true;
  isPolicyNumber: boolean = true;
  isSearch: boolean = false;
  isInvalidSearch: boolean = false;
  searchCriteria = new SearchCriteria();

  //upload excel

  uploadExcel!: UploadExcel;
  excelFilesErrorMessage: string = "";
  hasError: boolean = false;
  isProcessing: boolean = false;

  // Error logs
  errorLogs!: { Title: string; Logs: string[] };

  public redirectOpened = false;
  redirectMessage: any;
  redirectData: any;
  sub: any;

  getCountry(): void {
    //todo
    // this.sub = this.route.queryParams.subscribe((params) => {
    //   this.productId = params["pdid"];

    //   let country = this.productService.getCountryByProductId(this.productId!);
    //   switch (country) {
    //     case "SG":
    //       this.isSgProduct = true;
    //       this.isHkProduct = false;
    //       break;
    //     case "HK":
    this.isSgProduct = false;
    this.isHkProduct = true;
    //       break;
    //     default:
    //   }
    // });
  }

  //#region shared methods
  ngOnInit(): void {
    this.getCountry();

    this.uploadExcel = new UploadExcel();
    this.readProductRadio();
    this.sub = this.route.data.subscribe();

    // Init Error Log
    if (Global.isUndefined(this.errorLogs)) {
      this.errorLogs = { Title: "", Logs: new Array<string>() };
    }

    this.setDbsAutoDebitPeriod();
  }
  ngOnDestroy() {
    //this.sub?.unsubscribe();
  }

  onChange_ProductRadio(): void {
    this.isInvalidSearch = false;
    this.readProductRadio();

    if (this.isCitiProduct) {
      this.setCitiAutoDebitPeriod();
    } else {
      this.setDbsAutoDebitPeriod();
      this.resetInput();
    }
  }
  private setCitiAutoDebitPeriod(): void {
    var d = new Date();
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    var autoDebitDefaultWeek;
    autoDebitDefaultWeek = formatDate(
      new Date(d.setDate(diff)),
      "dd/MM/yyyy",
      this.locale
    ).toString();
    this.searchCriteria.autoDebitPeriodYear =
      autoDebitDefaultWeek.split("/")[2];
    this.searchCriteria.autoDebitPeriodMonth =
      autoDebitDefaultWeek.split("/")[1];
    this.searchCriteria.autoDebitPeriodDate =
      autoDebitDefaultWeek.split("/")[0];
    this.searchCriteria.autoDebitPeriod = autoDebitDefaultWeek;

    this.autoDebitPeriod = new Date(
      parseInt(this.searchCriteria.autoDebitPeriodYear),
      parseInt(this.searchCriteria.autoDebitPeriodMonth) - 1,
      parseInt(this.searchCriteria.autoDebitPeriodDate)
    );

    //  this.autoDebitPeriod = autoDebitDefaultWeek;
  }
  private setDbsAutoDebitPeriod(): void {
    var autoDebitDefaultMonth;
    autoDebitDefaultMonth = formatDate(
      new Date(),
      "MM/yyyy",
      this.locale
    ).toString();
    this.searchCriteria.autoDebitPeriodYear =
      autoDebitDefaultMonth.split("/")[1];
    this.searchCriteria.autoDebitPeriodMonth =
      autoDebitDefaultMonth.split("/")[0];
    this.searchCriteria.autoDebitPeriod = autoDebitDefaultMonth;
    this.autoDebitPeriod = new Date(
      parseInt(this.searchCriteria.autoDebitPeriodYear),
      parseInt(this.searchCriteria.autoDebitPeriodMonth) - 1
    );
    //this.autoDebitPeriod = autoDebitDefaultMonth;
    this.searchCriteria.autoDebitPeriodDate = "";
  }
  private resetInput(): void {
    this.isInvoice = true;
    this.isPeriod = true;
    this.isPolicyNumber = true;
    this.searchCriteria.invoiceNumber = "";
    this.searchCriteria.policyNumber = "";
  }
  private readProductRadio() {
    this.isCitiProduct = this.searchCriteria.productRadio === "citi";
  }
  getDisableInput(e: any): void {
    if (e === 1) {
      this.isPeriod = false;
      this.isInvoice = true;
      this.isPolicyNumber = true;
      this.autoDebitPeriod = null;
      this.searchCriteria.autoDebitPeriodMonth = "";
      this.searchCriteria.autoDebitPeriodYear = "";
    } else if (e === 2) {
      this.isInvoice = false;
      this.isPeriod = true;
      this.isPolicyNumber = true;
      this.searchCriteria.invoiceNumber = "";
    } else if (e === 3) {
      this.isPolicyNumber = false;
      this.isInvoice = true;
      this.isPeriod = true;
      this.searchCriteria.invoiceNumber = "";
      this.autoDebitPeriod = null;
      this.searchCriteria.autoDebitPeriodMonth = "";
      this.searchCriteria.autoDebitPeriodYear = "";
    }
  }
  public on_quoteNumber_keyup(event: any): any {
    let k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    if (
      !(
        (k > 64 && k < 91) ||
        (k > 96 && k < 123) ||
        k === 8 ||
        k === 45 ||
        k === 32 ||
        (k >= 48 && k <= 57)
      )
    ) {
      event.preventDefault();
    }
  }
  //search
  public search(): void {
    let isValid = this.validateMandatoryFields();

    if (isValid) {
      //  $('#warningZone').collapse('hide');
      this.isInvalidSearch = false;
      this.isSearch = true;
      this.searchReconcile();
    } else {
      this.isInvalidSearch = true;
      //  $('#warningZone').collapse('show');
      //httpNotifier.response_notifier$.next(new KeyValue('', e));
    }
  }
  //validate mandatory field
  protected validateMandatoryFields(): boolean {
    this.reformatPeriod();
    let isValid: boolean = false;

    const inoutField: boolean = this.isCitiProduct
      ? this.searchCriteria.autoDebitPeriod !== ""
      : this.searchCriteria.policyNumber !== "" ||
        this.searchCriteria.autoDebitPeriod !== "" ||
        this.searchCriteria.invoiceNumber.length > 0;

    isValid = inoutField; // || etc...

    return isValid;
  }
  private searchReconcile() {
    this.exportSearch();
  }
  protected exportSearch() {
    // const postData = {
    //     autoDebitPeriodDate: this.searchCriteria.autoDebitPeriodDate,
    //     autoDebitPeriodMonth: this.searchCriteria.autoDebitPeriodMonth,
    //     autoDebitPeriodYear: this.searchCriteria.autoDebitPeriodYear,
    //     policyNumber: this.searchCriteria.policyNo,
    //     invoiceNumber: this.searchCriteria.invoiceNo,
    //     productRadio: this.searchCriteria.productRadio
    // };

    //const reconcileDownloadExportManager = new ReconcileDownloadExportManager(this.$q);
    this.reconcileService
      .searchReconcilePolicies(this.searchCriteria)
      .then((response: ReconcilePoliciesDownloadEntity[]) => {
        if (response)
          this.excelTemplate.downloadReconcilePolicies(
            columns,
            "Reconcile",
            "Reconcile Policies",
            response
          );
      }),
      (error: any) => {};
  }
  reformatPeriod() {
    this.searchCriteria.autoDebitPeriod = "";

    if (!common.isUndefinedOrNull(this.autoDebitPeriod)) {
      if (this.isCitiProduct) {
        const period = formatDate(
          this.autoDebitPeriod,
          "dd/MM/yyyy",
          this.locale
        ).toString();
        this.searchCriteria.autoDebitPeriodYear = period.split("/")[2];
        this.searchCriteria.autoDebitPeriodMonth = period.split("/")[1];
        this.searchCriteria.autoDebitPeriodDate = period.split("/")[0];
        this.searchCriteria.autoDebitPeriod = this.autoDebitPeriod;
      } else {
        const period = formatDate(
          this.autoDebitPeriod,
          "MM/yyyy",
          this.locale
        ).toString();
        this.searchCriteria.autoDebitPeriodYear = !common.isUndefinedOrNull(
          period.split("/")[2]
        )
          ? period.split("/")[2]
          : period.split("/")[1];
        this.searchCriteria.autoDebitPeriodMonth = period.split("/")[0];
        this.searchCriteria.autoDebitPeriod = this.autoDebitPeriod;
      }
    }
  }

  excelAndSizeRestrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
    maxFileSize: 5242880, // Check for each file size less than 5MB, 1024 * 1024 * 5
  };

  public resetFiles(): void {
    //todo: currently only allow single file upload
    // Clear uploaded file once, the file is successfuly uploaded.
    this.uploadExcel.files = {};
  }

  public removeEvent(e: RemoveEvent): void {
    this.resetFiles();
  }

  public select(e: SelectEvent): void {
    this.resetFiles();

    const that = this;

    this.hasError = false;

    let totalFileSize = this.getUploadedClaimFilesTotalSize(e.files);
    if (totalFileSize / 1024 / 1024 > 20) {
      this.hasError = true;
      this.excelFilesErrorMessage =
        "Total file size allowed in a claim is 20MB.";
    } else {
      const uploadExcels: File[] = new Array<File>();
      e.files.forEach((file: FileInfo) => {
        //this already check extension & individual file size by kendo control itself
        if (!file.validationErrors && file.rawFile) {
          uploadExcels.push(file.rawFile);
        }
      });
      if (this.uploadExcel != undefined && this.uploadExcel != null) {
        this.uploadExcel.files = uploadExcels;
      }
    }
  }

  getUploadedClaimFilesTotalSize(files: FileInfo[]): number {
    let totalFileSize: number = 0;

    files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        totalFileSize += file.rawFile.size;
      }
    });

    return totalFileSize;
  }

  add(): void {
    this.hasError = false;

    if (this.uploadExcel?.files?.length > 0) {
      this.addExcel();
    } else {
      this.excelFilesErrorMessage = "At least one file needs to be uploaded.";
      this.hasError = true;
    }
  }

  getUploadedExcelFiles(uploadedExcel: UploadExcel): FormData {
    const formData: FormData = new FormData();
    for (let i = 0; i < uploadedExcel.files.length; i++) {
      formData.append("file" + i.toString(), uploadedExcel.files[i]);
    }
    return formData;
  }

  addExcel() {
    this.isProcessing = true;

    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);

    this.uploadService.uploadReconcile(uploadedExcelFormData).subscribe(
      (result: any) => {
        let tempData: any = null;
        //no longer result.data as in angularJs but result.body
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          if (result.body.Status === false) {
            tempData = {
              message: result.body.Message,
              validationMessage: result.body.ValidationMessage,
              status: false,
            };
          } else {
            tempData = {
              message: result.body.Message,
              status: false,
            };
          }

          // Clear uploaded file once, the file is successfuly uploaded.
          //this.uploadExcel.files = {};//maybe no need to clear, just like original

          this.redirectOpened = true;
          this.redirectData = tempData;
          // this.dialog.modalLarge(
          //     'shared/partials/mdSuccessfullFileUpload/successfullFileUpload.html',
          //     'SuccessFullFileUpload', data
          //     , this.confirmationModelForSuccessullExcelUpload, undefined, undefined, undefined, false, 'standardModal');
          this.isProcessing = false;
        }
      },
      (error: any) => {
        console.log(error);
        // Prompt fail
        this.errorLogs.Logs = new Array<string>(); // Clear error logs
        this.errorLogs.Title = "Error";

        const log: string =
          Global.isDefined(error?.data?.ExceptionMessage) &&
          error.data.ExceptionMessage !== ""
            ? error.data.ExceptionMessage
            : "Failed to upload excel file. Please contact the system administrator.";

        this.errorLogs.Logs.push(log);
        this.isProcessing = false;
      }
    );
  }

  public confirmationModelForSuccessullExcelUpload(args: dlgModelArgs) {
    this.redirectOpened = false;
    if (args.status === "return") {
      //todo
      this.router.navigate(["/" + ROUTE_HOME]);
      //this.$state.go('product.home', { reload: true });
    }
  }
  //#endregion
}
const columns: string[] = [
  "Serial Number",
  "Action",
  "Meridian Sequence No",
  "Policy Certificate Number",
  "Total",
  "GST",
  "Status",
  "Invoice No",
  "Saved By",
  "Id(Do not change!)",
  "Status Id(Do not change!)",
];
