<kendo-dialog
  title="{{ titlebarTitle }}"
  class="{{ titlebarCssClass }}"
  (close)="close('cancel')"
  [width]="getWindowSize()"
>
  <div class="container-fluid container-overflow-y">
    <div class="modal-body">
      <div class="clearfix" class="">
        <div class="col-md-12" *ngIf="command == 6">
          <div>
            <label
              [innerHtml]="'Are you sure you wish to approve this submission?'"
            ></label>
          </div>
          <form role="form">
            <div class="form-group">
              <div class="col-md-12 grid-header row">
                <div class="col-md-2">
                  <label [innerHtml]="'SNo'"></label>
                </div>
                <div class="col-md-5">
                  <label [innerHtml]="'Policy No'"></label>
                </div>
                <div class="col-md-5">
                  <label [innerHtml]="'Main Insured Name'"></label>
                </div>
              </div>

              <div
                class="col-md-12"
                *ngFor="let data of model; let idx = index"
              >
                <div class="grid-td row">
                  <div class="col-md-2">
                    {{ idx + 1 }}
                  </div>
                  <div class="col-md-5">
                    <span *ngIf="data.policyNo == '-'">{{ data.quoteNo }}</span>
                    <span *ngIf="data.policyNo != '-'">{{
                      data.policyNo
                    }}</span>
                  </div>
                  <div class="col-md-5">
                    {{ data.insuredName }}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12" *ngIf="command == 9">
          <div>
            <label
              [innerHtml]="'Are you sure you wish to decline this submission?'"
            ></label>
          </div>
          <div>
            <div class="alert alert-warning" [hidden]="!warning_message">
              <p>{{ confirmLabel }}</p>
            </div>
          </div>
          <form role="form">
            <div class="form-group">
              <div class="col-md-12 grid-header row">
                <div class="col-md-1">
                  <label [innerHtml]="'SNo'"></label>
                </div>
                <div class="col-md-4">
                  <label [innerHtml]="'Policy No'"></label>
                </div>
                <div class="col-md-4">
                  <label [innerHtml]="'Main Insured Name'"></label>
                </div>
                <div class="col-md-3">
                  <label
                    class="required"
                    [innerHtml]="'Decline Reason'"
                  ></label>
                </div>
              </div>

              <div
                class="col-md-12"
                *ngFor="let data of model; let idx = index"
              >
                <div class="grid-td">
                  <div class="row" style="margin-top: 2px">
                    <div class="col-md-1">
                      {{ idx + 1 }}
                    </div>
                    <div class="col-md-4">
                      <span *ngIf="data.policyNo == '-'">{{
                        data.quoteNo
                      }}</span>
                      <span *ngIf="data.policyNo != '-'">{{
                        data.policyNo
                      }}</span>
                    </div>
                    <div class="col-md-4">
                      {{ data.insuredName }}
                    </div>
                    <div class="col-md-3">
                      <input
                        id="txtDecline"
                        type="text "
                        value=""
                        maxlength="30"
                        class="k-textbox form-control col-md-12"
                        [(ngModel)]="data.DeclineReason"
                        name="DeclineReason"
                        (blur)="validateDeclineReason(data)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-12" *ngIf="command == 7 || command == 3">
          <!-- will not work if multiple dropdown/select wrap in form -->
          <!-- <form role="form"> -->
          <div class="form-group">
            <div class="col-md-12 grid-header row">
              <div class="col-md-1">
                <label [innerHtml]="'SNo'"></label>
              </div>
              <div class="col-md-2">
                <label [innerHtml]="'Policy Number'"></label>
              </div>
              <div class="col-md-3">
                <label [innerHtml]="'Main Insured Name'"></label>
              </div>
              <div class="col-md-3">
                <label [innerHtml]="'Print Options'"></label>
              </div>
              <div class="col-md-3"></div>
            </div>

            <div class="col-md-12" *ngFor="let data of model; let idx = index">
              <div class="grid-td row">
                <div class="col-md-1">
                  {{ idx + 1 }}
                </div>
                <div class="col-md-2">
                  <span *ngIf="data.policyNo == '-'">{{ data.quoteNo }}</span>
                  <span *ngIf="data.policyNo != '-'">{{ data.policyNo }}</span>
                </div>
                <div class="col-md-3">
                  {{ data.insuredName }}
                </div>
                <div class="col-md-3">
                  <!-- <label class="required" [innerHtml]="'Print Options'"></label> -->
                  <p>
                    <span
                      style="
                        font-size: 12px;
                        font-weight: bold;
                        padding-left: 12px;
                      "
                    >
                      Print Options </span
                    ><span
                      style="color: red; font-size: 12px; font-weight: bold"
                      >* Any value entered will not be updated into the Client
                      Master database.</span
                    >
                  </p>

                  <kendo-dropdownlist
                    id="printType"
                    [data]="printTypeOptions"
                    textField="text"
                    valueField="value"
                    [valuePrimitive]="true"
                    [(ngModel)]="data.printType"
                    name="printType"
                  ></kendo-dropdownlist>
                </div>
                <div class="col-md-3">
                  <label
                    style="color: red"
                    [hidden]="
                      !(
                        data.isInvalidEmailAddress &&
                        data.printCorrespondence != ''
                      )
                    "
                    >{{ data.printEmailValidation }}</label
                  >
                  <div
                    class="form-group"
                    *ngIf="data.printType == 2 || data.printType == 5"
                  >
                    <div>
                      <div class="col-sm-5">
                        <label
                          class="required"
                          *ngIf="data.printType === 2"
                          [innerHtml]="'Email'"
                        >
                        </label>
                        <label
                          class="required"
                          *ngIf="data.printType === 5"
                          [innerHtml]="'MobileNo'"
                        >
                        </label>
                      </div>

                      <div class="col-sm-7">
                        <input
                          id="clientemail"
                          type="text "
                          *ngIf="data.printType == 2"
                          value=""
                          maxlength="30"
                          class="k-textbox form-control col-md-12"
                          [(ngModel)]="data.emailId"
                          name="emailId"
                          (blur)="validateEmail(data)"
                        />

                        <input
                          id="clientmobileNo"
                          type="text "
                          *ngIf="data.printType == 5"
                          value=""
                          class="k-textbox form-control col-md-12"
                          [(ngModel)]="data.mobileNo"
                          name="mobileNo"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-md-2">
                            <input id="passwordMailBindPolicy" type="checkbox"
                            [(ngModel)]="data.isPasswordProtected" />
                        </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
  <!--outside of container-fluid, so that not affected by height & scroll-->
  <div class="modal-footer">
    <kendo-dialog-actions>
      <button
        class="btn chubbLightBlue_bg_btn"
        kendoButton
        (click)="close('yes')"
        themeColor="primary"
      >
        OK
      </button>
      <button class="btn chubbRed_bg_btn" kendoButton (click)="close('no')">
        Cancel
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
