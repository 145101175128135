<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>

  <div *ngIf="!question.readOnly">
    <!--https://stackoverflow.com/questions/39760956/how-to-bind-maxlength-attribute-->
    <div class="form-control" id="address-input">
      <div *ngIf="front">
        <label
          class="form"
          style="margin: 0px; color: black"
          [textContent]="title"
        ></label>
      </div>
      <input
        id="{{ question.key }}_textBox"
        name="{{ question.key }}"
        type="text"
        [(ngModel)]="QuestionAnswerFormatted"
        ngModel
        maxlength="{{ question.maxLength }}"
        placeholder="{{ placeholder }}"
        class="k-textbox"
        [disabled]="question.isDisabled!"
        style="
          flex: 1;
          border: 1px solid white;
          outline: none;
          font-weight: 400;
        "
      />
      <div *ngIf="end">
        <label
          class="form"
          style="margin: 0px; color: black"
          [textContent]="title"
        ></label>
      </div>
      <!-- https://jira.apac.chubb.com/browse/HE-160 : cannot use kendo-textbox, else will break when googlePlace API -->
      <!-- <kendo-textbox
        [(ngModel)]="QuestionAnswerFormatted"
        class="k-textbox"
        style="
          flex: 1;
          border: 1px solid white;
          outline: none;
          font-weight: 400;
        "
      >
        <div *ngIf="front">
          <ng-template kendoTextBoxPrefixTemplate>
            <label
              class="form"
              style="margin: 0px; color: black"
              [textContent]="title"
            ></label>
          </ng-template>
        </div>

        <div *ngIf="end">
          <ng-template kendoTextBoxSuffixTemplate>
            <label
              class="form"
              style="margin: 0px; color: black"
              [textContent]="title"
            ></label>
          </ng-template>
        </div> 
      </kendo-textbox> -->
    </div>
  </div>

  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()" class="wordBreak">
      {{ question.answer }}
    </p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
