import { Injectable } from "@angular/core";
import * as MfEnum from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import * as MfEnumHK from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-enums";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import * as EnumHK from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-enums";
import { AbstractProductFactory } from "src/app/models/new-product/abstract-factory/factory/abstract-product-factory";
import { HaseProductFactory } from "src/app/models/new-product/abstract-factory/factory/hase-product-factory";
import { DbsProductFactory } from "src/app/models/new-product/abstract-factory/factory/dbs-product-factory";
import { GUID } from "src/app/framework/domain-entity/guid";
import * as Const from "src/app/framework/const.shared";

const productFactory = () => {
  return new ProductService();
};

export { productFactory };

@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor() {}

  getProductBase(productType: MfEnum.MortgageFireSgProduct) {
    switch (productType) {
      case EnumSG.MortgageFireSgProduct.CTHDB:
      case EnumSG.MortgageFireSgProduct.CTMFPTE:
      case EnumSG.MortgageFireSgProduct.CTMFCONDO: {
        return EnumSG.ProductBase.Citi;
      }
      case EnumSG.MortgageFireSgProduct.HSWPB:
      case EnumSG.MortgageFireSgProduct.HSCMB: {
        return EnumSG.ProductBase.HASE;
      }
      default: {
        return EnumSG.ProductBase.DBS;
      }
    }
  }

  getProductFactory(productType: MfEnum.MortgageFireSgProduct) {
    switch (productType) {
      case EnumSG.MortgageFireSgProduct.CTHDB:
      case EnumSG.MortgageFireSgProduct.CTMFPTE:
      case EnumSG.MortgageFireSgProduct.CTMFCONDO: {
        return new DbsProductFactory();
      }
      case EnumSG.MortgageFireSgProduct.HSWPB:
      case EnumSG.MortgageFireSgProduct.HSCMB: {
        return new HaseProductFactory();
      }
      default: {
        return new DbsProductFactory();
      }
    }
  }

  getHKProductFactory(productType: MfEnumHK.MortgageFireHKProduct) {
    switch (productType) {
      case EnumHK.MortgageFireHKProduct.HSWPB:
      case EnumHK.MortgageFireHKProduct.HSCMB: {
        return new HaseProductFactory();
      }
      default: {
        return new DbsProductFactory();
      }
    }
  }

  getProductFactoryByProductId(productId: GUID) {
    switch (productId.toString()) {
      case Const.ProductID.PRODUCT_ID_DBS_MORTGAGEINTERESTPLUS:
      case Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSPTE:
      case Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSHDB: {
        return new DbsProductFactory();
      }
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
      case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING: {
        return new HaseProductFactory();
      }
      default: {
        return new DbsProductFactory();
      }
    }
  }

  getCountryByProductId(productId: string) {
    switch (productId.toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_DBS_MORTGAGEINTERESTPLUS:
      case Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSPTE:
      case Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSHDB: {
        return "SG";
      }
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
      case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING: {
        return "HK";
      }
      default: {
        return "SG";
      }
    }
  }
}
