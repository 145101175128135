<div class="row marginLeft15" *ngIf="isDataLoaded">
  <div class="k-i-loading"></div>
</div>
<div *ngIf="!isDataLoaded" class="col-md-12">
  <div class="card-head chubbWhite_bg chubbLightGray_border">
    <div class="tools">
      <div class="btn-group"></div>
    </div>
    <header class="chubbDarkBlue">Profile</header>
  </div>
  <div class="card-body chubbLightGray_border">
    <div style="padding: 10px">
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label class="required" [innerHtml]="'User Login'"></label>
        </div>
        <div class="col-md-3">{{ userDetails.userName }}</div>
      </div>

      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Team'"></label>
        </div>
        <div class="col-md-3">{{ userDetails.userTeam }}</div>
      </div>

      <div class="col-md-12 row" style="padding: inherit">
        <div class="col-md-3">
          <label class="required" [innerHtml]="'Email'"></label>
        </div>
        <div class="col-md-4">{{ userDetails.email }}</div>
      </div>
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Receive Email'"></label>
        </div>
        <div class="col-md-3">
          {{ userDetails.receiveEmail ? "Yes" : "No" }}
        </div>
      </div>
      <div
        *ngIf="userDetails.mobileNumber"
        class="col-md-12 row k-bottom-lightgray"
        style="padding: inherit"
      >
        <div class="col-md-3">
          <label [innerHtml]="'Mobile Number'"></label>
        </div>
        <div class="col-md-3">{{ userDetails.mobileNumber }}</div>
      </div>
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label class="required" [innerHtml]="'User Type'"></label>
        </div>
        <div class="col-md-3">{{ model.userTypeDesc }}</div>
      </div>
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label class="required" [innerHtml]="'Country'"></label>
        </div>
        <div class="col-md-3">{{ userDetails.displayUserCountry }}</div>
      </div>
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label class="required" [innerHtml]="'Organisation'"></label>
        </div>
        <div class="col-md-3">{{ model.organisationName }}</div>
      </div>
      <div
        *ngIf="model.userType != 1"
        class="col-md-12 row k-bottom-lightgray"
        style="padding: inherit"
      >
        <div class="col-md-3">
          <label class="required" [innerHtml]="'Products'"></label>
        </div>
        <div class="col-md-4" style="display: grid">
          <span *ngFor="let product of userDetails.productsByOrganisation">
            {{ product.text }}
          </span>
        </div>
      </div>
      <div
        *ngIf="model.userType != 1"
        class="col-md-12 row k-bottom-lightgray"
        style="padding: inherit"
      >
        <div class="col-md-3">
          <label
            [ngClass]="model.userType != '3' ? 'required' : ''"
            [innerHtml]="'Roles'"
          ></label>
        </div>
        <div class="col-md-4" style="display: grid">
          <span *ngFor="let role of userDetails.roles">
            {{ role.text }}
          </span>
        </div>
      </div>
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Active'"></label>
        </div>
        <div class="col-md-3">{{ userDetails.active ? "Yes" : "No" }}</div>
      </div>
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Disabled'"></label>
        </div>
        <div class="col-md-3">{{ userDetails.disabled ? "Yes" : "No" }}</div>
      </div>
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Suspended'"></label>
        </div>
        <div class="col-md-3">{{ userDetails.suspended ? "Yes" : "No" }}</div>
      </div>
      <div
        [hidden]="model.userType != 1"
        class="col-md-12 row k-bottom-lightgray"
        style="padding: inherit"
      >
        <div class="col-md-3">
          <label [innerHtml]="'Access Group'"></label>
        </div>
        <div class="col-md-3">{{ userDetails.displayAccessGroup }}</div>
      </div>
    </div>
  </div>
</div>
