<header class="plain-header">
  <img class="logo" src="assets\Chubb-turquoise.png" alt="chubb logo" />
</header>
<div class="centered-container">
  <form
    id="requestOtpForm"
    class="form"
    name="forms.formLogin"
    ng-submit="sendForm()"
    method="post"
    style="min-height: 400px"
  >
    <div *ngIf="showDropdown">
      <br />
      <p class="floatLeft">Please select the policy to renew</p>
      <kendo-dropdownlist
        id="reportSegment"
        [kendoDropDownFilter]="{
          operator: 'contains'
        }"
        [data]="proposalIdProductPolicy"
        textField="productPolicy"
        valueField="proposalId"
        [valuePrimitive]="true"
        [(ngModel)]="productPolicySelected"
        name="segments"
        (valueChange)="onProductPolicyChange()"
      >
      </kendo-dropdownlist>
    </div>
    <br />
    <div *ngIf="showDetails">
      <p class="headline">Exclusive Promotion:</p>
      <p class="headline">
        Enjoy 5% discount when you renew on our 3-year Fire Mortgage Plan
      </p>
      <p class="thin-bold-description">
        Product Name : {{ productDescription }}
      </p>
      <p class="thin-bold-description" style="margin-top: -13px">
        Policy Number : {{ policyNumberDescription }}
      </p>
      <p class="description">
        Thank you for expressing interest in our exclusive promotion that allows
        you to enjoy greater savings when you choose to renew your policy on a
        3-year plan.
      </p>
      <div class="input-container">
        <table>
          <thead>
            <tr>
              <th>Existing Plan (1-year)<br />(before GST)</th>
              <th>Promotion Plan (3-year)<br />(before GST)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ premium1Year | ausCurrency }}</td>
              <td>
                {{ premium3Year | ausCurrency }}<br /><i
                  >(5% discount off your 3 years total premium)</i
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="tiny-italic-description">
        Note: Premium is subject to GST at the prevailing rate based on the
        effective date of policy renewal.
      </p>
      <p class="description">
        If you wish to take up this offer and renew your policy on a 3-year
        plan, please click on ‘Yes’. If otherwise, please click on ‘No’ and your
        existing policy will be renewed automatically on the current annual plan
        arrangement.
      </p>
      <div style="width: 100%; display: table; margin-top: 15px">
        <div style="display: table-row">
          <div
            class="description"
            style="width: 50%; display: table-cell; text-align: center"
          >
            <input
              type="radio"
              name="name"
              #name="ngModel"
              value="Yes"
              [(ngModel)]="userChoice.name"
            />
            <label class="lbl marginLeft" for="oiUserChoiceRadio">Yes</label>
          </div>
          <div
            class="description"
            style="display: table-cell; text-align: center"
          >
            <input
              type="radio"
              name="name"
              #name="ngModel"
              value="No"
              [(ngModel)]="userChoice.name"
            />
            <label class="lbl marginLeft" for="oiUserChoiceRadio">No</label>
          </div>
        </div>
      </div>
      <div class="button-container">
        <button
          (click)="onSubmit()"
          event="1"
          [disabled]="IsDisabled"
          (class)="({ removePointer: IsDisabled })"
        >
          Submit
        </button>
        <div class="description">
          For more information relating to this offer, please read our
          <a href="" (click)="downloadFaqs($event)">FAQs</a> or contact our
          Customer Service Representatives at:
          <br />
          <br />
          E-mail : <a href="mailto:dbscs.sg@chubb.com">dbscs.sg@chubb.com</a>
          <br />
          Hotline : +65 6398 8797
          <br />
          <div style="margin-left: 38px">
            (Mondays to Fridays, 9:00am to 5:00pm; excluding Public Holidays)
          </div>
        </div>
      </div>
    </div>
    <!--these msg cannot be part of the div of showDetails-->
    <p id="error" class="error" *ngIf="hasError">{{ msg }}</p>
    <p id="success" class="success" *ngIf="hasSuccess">{{ msg }}</p>
  </form>
</div>
<div id="footerdiv" class="" style="margin-left: 11px">
  <footer style="margin-top: 15px">
    <p>{{ footerTitle }}</p>
  </footer>
</div>
