import { KeyValue } from "./../../../framework/domain-entity/key-value";
import { ProposalEntity } from "./../base/proposal-entity.model";
import { Subject } from "rxjs";

/** To notify proposal action trigger */
export class ApiActionNotifier {
  /** Notify before proposal action trigger */
  before_notifier$: Subject<KeyValue<ProposalEntity>> = new Subject<
    KeyValue<ProposalEntity>
  >();

  /** Notify after proposal action triggered successfully */
  success_notifier$: Subject<KeyValue<ProposalEntity>> = new Subject<
    KeyValue<ProposalEntity>
  >();

  /** Notify after proposal action triggered failure */
  failure_notifier$: Subject<KeyValue<ProposalEntity>> = new Subject<
    KeyValue<ProposalEntity>
  >();
}

export const apiActionNotifier = new ApiActionNotifier();
