import { KeyValue } from "./../../../framework/domain-entity/key-value";
import { Proposal } from "./../base/proposal.model";
import { Subject } from "rxjs";

/** To notify proposal action trigger */
class PageLifeCycleNotifier {
  /** Notify after proposal loaded */
  page_loaded_notifier$: Subject<KeyValue<Proposal>> = new Subject<
    KeyValue<Proposal>
  >();

  /** Notify after proposal saved */
  page_unload_notifier$: Subject<KeyValue<Proposal>> = new Subject<
    KeyValue<Proposal>
  >();

  /** Notify on tab load */
  tab_load_notifier$: Subject<KeyValue<Proposal>> = new Subject<
    KeyValue<Proposal>
  >();

  /** Notify on tab change request notifier
   * Key: tabId
   * Value: questionName. ElementId to auto scroll to
   */
  tab_request_notifier$: Subject<KeyValue<string>> = new Subject<
    KeyValue<string>
  >();
}

export const pageLifeCycleNotifier = new PageLifeCycleNotifier();
