import { Component, Inject } from "@angular/core";
import { BaseClientCreateComponent } from "./base/base-client-create/base-client-create.component";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import { ClientService } from "src/app/services/customer/client.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { ProductService } from "src/app/services/new-product/base/product.service";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { NotifierService } from "src/app/services/notifier.service";
import { DIALOG_DATA } from "../../dialog/dialog-tokens";
import { clientCreateDlgInputArgs } from "./base/base-client-create/client-create-dlg-input-args";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { common } from "src/app/framework/utils/common";
import { ClientAddressEntity } from "src/app/models/customer/base/client-address-entity.model";
import { ClientEntity } from "src/app/models/customer/base/client-entity.model";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import * as Enums from "src/app/framework/enum.shared";

@Component({
  selector: "app-client-create",
  templateUrl: "./client-create.component.html",
  styleUrls: ["./client-create.component.scss"],
})
export class ClientCreateComponent extends BaseClientCreateComponent {
  constructor(
    public override clientService: ClientService,
    public override masterDataService: MasterDataService,
    public override cdkDialogService: CdkDialogService,
    @Inject(DIALOG_DATA) public override inputData: clientCreateDlgInputArgs,
    public override dialogRef: DialogRef,
    public override notifier: NotifierService,
    public override productService: ProductService,
    public proposalService: MfSgProposalService
  ) {
    super(
      clientService,
      masterDataService,
      cdkDialogService,
      inputData,
      dialogRef,
      notifier,
      productService
    );

    if (this.inputData) {
      this.proposal = this.inputData.proposal as MortgageFireSingaporeProposal; //temp pass from outside
      this.isCreate = this.inputData.isNewClient;
      this.labelText = "Create";
      this.h3LabelText = "Client - Create";
      if (!this.isCreate) {
        this.labelText = "Update";
        this.isCreate = false;
        this.h3LabelText = "Change of Client Info";
      }
    }
  }

  override ngOnInit(): void {
    if (!this.isCreate) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId!,
          this.proposalService!.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response: ClientEntity) => {
          this.clientData = response;
          this.setDate(this.clientData.birthDate);
          // check for docType, assign to empty if is 0
          if (common.isDefined(this.clientData.docType)) {
            this.clientData.docType =
              parseInt(this.clientData.docType) === 0
                ? ""
                : this.clientData.docType.toString();
          }

          this.proposalService.proposal.customerMappingAddressID = Number(
            response.address[0].clientAddressID
          );
          this.address = response.address[0];
          this.setClientType(this.clientData.clientType);

          this.getIsStateVisible(this.address.countryISO2);
        });

      // this.updateClientCIN();
    }
    this.dialogOkNotifierSubscription =
      dialogActionNotifier.ok_notifier$.subscribe((event) => {
        this.createClient();
      });

    this.initialize();
    //  this.checkIsNewClient();
  }

  override initialize() {
    this.postBackData = [];
    this.proposal = this.proposalService.proposal;
    this.nationalityList = new Array<any>();
    this.countryList = new Array<any>();
    super.subscribeTabLoadRenderer();
    super.subscribeTabLoadRenderer();
    if (this.isCreate) this.initializeQuestionSchema();
    // $(document).ready(() => {
    this.componentsInitialize();
    // });

    this.productFactory = this.productService.getProductFactory(
      (this.proposal as MortgageFireSingaporeProposal).productType
    );
    this.googlePlacesOptions = this.productFactory
      .googlePlaces()
      .getGooglePlacesOptions(); //init by country here
  }

  override setCinText() {
    this.CINText =
      (this.proposal as MortgageFireSingaporeProposal).productType ===
        EnumSG.MortgageFireSgProduct.CTHDB ||
      (this.proposal as MortgageFireSingaporeProposal).productType ===
        EnumSG.MortgageFireSgProduct.CTMFPTE ||
      (this.proposal as MortgageFireSingaporeProposal).productType ===
        EnumSG.MortgageFireSgProduct.CTMFCONDO
        ? "Citibank CIN"
        : "DBS CIN";
  }

  override createClient = () => {
    this.showErrorMessage = false;

    //const deferred = this.$q.defer();

    if (this.customerTypeCheck()) {
      let personalMandatory: any[];
      let orgMandatory: any[];
      let contactMandatory: any[];
      let allMandatoryField: any[];

      personalMandatory = [
        {
          id: "clientSalutation",
          label: "Salutation",
          qid: this.clientData.salutation,
        },
        {
          id: "clientDocType",
          label: "Document Type",
          qid: this.clientData.docType === "0" ? "" : this.clientData.docType,
        },
        {
          id: "clientSurname",
          label: "Surname",
          qid: this.clientData.lastName,
        },
        {
          id: "clientGivenName",
          label: "Given Name",
          qid: this.clientData.firstName,
        },
        {
          id: "clientDOB",
          label: "Date of Birth",
          qid: this.clientData.birthDate,
        },
        {
          id: "clientNationality",
          label: "Nationality",
          qid: this.clientData.nationality,
        },
      ];

      if (
        this.clientData.docType === "1" ||
        this.clientData.docType === "2" ||
        this.clientData.docType === "3"
      ) {
        personalMandatory.push({
          id: "clientNRICNo",
          label: "NRIC",
          qid: this.clientData.nric,
        });
      } else if (this.clientData.docType === "4") {
        personalMandatory.push({
          id: "clientPassportNo",
          label: "Passport Number",
          qid: this.clientData.passportNo,
        });
      }

      contactMandatory = [
        {
          id: "insuredAddress1",
          label: "Address Line 1",
          qid: this.address.addressLine1,
        },
        {
          id: "insuredAddress2",
          label: "Address Line 2",
          qid: this.address.addressLine2,
        },
        {
          id: "insuredPostalCode",
          label: "PostCode",
          qid: this.address.postCode,
        },
        { id: "insuredCity", label: "City/Suburb", qid: this.address.city },
        {
          id: "insuredCountry",
          label: "Country",
          qid: this.address.countryISO2,
        },
      ];

      orgMandatory = [
        {
          id: "clientBRNo",
          label: "Business Registration Number",
          qid: this.clientData.businessRegistrationNumber,
        },
        {
          id: "clientName",
          label: "Business Name",
          qid: this.clientData.clientName,
        },
      ];
      if (!this.ibgDisabled) {
        orgMandatory.push({
          id: "clientIncorporatedDate",
          label: "Incorporated Date",
          qid: this.clientData.incorporatedDate,
        });
      }

      if (
        (this.proposal as MortgageFireSingaporeProposal).productType ===
          EnumSG.MortgageFireSgProduct.CTHDB ||
        (this.proposal as MortgageFireSingaporeProposal).productType ===
          EnumSG.MortgageFireSgProduct.CTMFPTE ||
        (this.proposal as MortgageFireSingaporeProposal).productType ===
          EnumSG.MortgageFireSgProduct.CTMFCONDO
      ) {
        contactMandatory = [
          {
            id: "insuredAddress1",
            label: "Address Line 1",
            qid: this.address.addressLine1,
          },
          {
            id: "insuredCountry",
            label: "Country",
            qid: this.address.countryISO2,
          },
        ];
      } else {
        contactMandatory = [
          {
            id: "insuredAddress1",
            label: "Address Line 1",
            qid: this.address.addressLine1,
          },
          {
            id: "insuredCountry",
            label: "Country",
            qid: this.address.countryISO2,
          },
          {
            id: "brokerMobileNo",
            label: "Mobile Number",
            qid: this.clientData.mobileNumber,
          },
        ];
      }

      if (this.clientData.clientType === EnumSG.ClientType.Person) {
        allMandatoryField = personalMandatory.concat(contactMandatory);
      } else {
        allMandatoryField = orgMandatory.concat(contactMandatory);
      }

      this.postBackData = [Array];
      let errorMessageIndicator: number = 0;
      const htmlErrorMessage: string =
        '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed" aria-hidden="true">' +
        "</i> <b> @i.label@ </b> is required. <br></div>";

      const clientAddressData: ClientAddressEntity = new ClientAddressEntity();

      clientAddressData.clientAddressID = this.isCreate
        ? ""
        : String(this.proposalService.proposal.customerMappingAddressID);
      clientAddressData.clientID = this.isCreate
        ? ""
        : String(this.proposalService.proposal.customerMappingId);
      clientAddressData.buildingName = this.address.buildingName;
      clientAddressData.unitNo = this.address.unitNo;
      clientAddressData.addressLine1 = this.address.addressLine1;

      clientAddressData.addressLine2 = this.address.addressLine2;
      clientAddressData.city = this.address.city;
      clientAddressData.postCode = this.address.postCode;
      clientAddressData.stateName = this.address.stateName;

      clientAddressData.countryISO2 = this.address.countryISO2;
      clientAddressData.contact1 = "";
      clientAddressData.contact2 = "";

      const clientData: ClientEntity = new ClientEntity();
      clientData.address = [];

      clientData.clientId = this.isCreate
        ? 0
        : this.proposalService.proposal.customerMappingId!;
      clientData.clientCode = this.isCreate ? "0" : this.clientData.clientCode;
      clientData.clientType = this.clientData.clientType;
      clientData.nric = this.clientData.nric;
      clientData.passportNo = this.clientData.passportNo;

      clientData.businessRegistrationNumber =
        this.clientData.businessRegistrationNumber;
      clientData.incorporatedDate = this.clientData.incorporatedDate;
      // clientData.businessName = this.clientData.businessName;
      clientData.personInCharge = this.clientData.personInCharge;
      clientData.firstName = this.clientData.firstName;
      clientData.lastName = this.clientData.lastName;
      clientData.clientName = this.clientData.clientName;
      clientData.salutation = this.clientData.salutation;
      clientData.docType = this.clientData.docType;

      clientData.gender = this.clientData.gender;
      clientData.birthDate = this.clientData.birthDate;
      if (this.clientData.clientType === EnumSG.ClientType.Person) {
        if (
          common.isDefined(this.clientData.birthDate) &&
          this.clientData.birthDate !== ""
        ) {
          const answer = new Date(this.clientData.birthDate);
          const year = answer.getFullYear();
          const month = answer.getMonth() + 1;
          const day = answer.getDate();
          clientData.birthDate = `${year}-${month}-${day} 12:00:00 AM`;
        }
      }

      clientData.occupation = this.clientData.occupation;
      clientData.race = this.clientData.race;
      clientData.maritalStatus = this.clientData.maritalStatus;

      clientData.phoneNoHome = this.clientData.phoneNoHome;
      clientData.phoneNoOffice = this.clientData.phoneNoOffice;
      clientData.mobileNumber = this.clientData.mobileNumber;
      clientData.pagerNo = this.clientData.pagerNo;
      clientData.faxNumber = this.clientData.faxNumber;
      clientData.cinNo = this.clientData.cinNo;
      clientData.emailAddress = this.clientData.emailAddress;
      clientData.nationality =
        this.clientData.clientType === EnumSG.ClientType.Person
          ? this.clientData.nationality
          : "";
      clientData.address.push(clientAddressData);
      clientData.productBase = this.productService.getProductBase(
        (this.proposal as MortgageFireSingaporeProposal).productType
      );

      this.showErrorMessage = true;
      if (allMandatoryField.length > 0) {
        this.errorMsgInHtml = "";
        this.emptyFieldValidation = "";

        for (const i of allMandatoryField) {
          if (common.isUndefinedOrNull(i.qid) || i.qid.length === 0) {
            this.errorMsgInHtml += htmlErrorMessage.replace(
              "@i.label@",
              i.label
            );
            this.emptyFieldValidation += " " + i.label;
            errorMessageIndicator++;
          } else {
            this.emptyFieldValidation.replace(i.label, "");
          }
        }
      }
      if (errorMessageIndicator === 0) {
        this.showErrorMessage = false;
        this.isProcessing = true;

        let clientActionData: any;

        if (
          this.proposalService.proposal.endorsementType ===
            Enums.EndorsementType.ChangeOfClientInfo &&
          this.proposalService.proposal.transType === Enums.TransType.END &&
          !this.isCreate
        ) {
          clientActionData = {
            clientModel: clientData,
            action: "validate",
          };
        } else {
          clientActionData = {
            clientModel: clientData,
            action: "save",
          };
        }

        this.ClientSave(clientActionData);
      } else {
        //angular.element(".modal-body").animate({ scrollTop: 0 }, 200);
        dialogActionNotifier.cancel_notifier$.next({
          key: "client-save-close",
          value: { status: "failed" },
        });
      }
    } else {
      this.showErrorMessage = true;
      this.errorMsgInHtml = "";
      this.errorMsgInHtml;
      '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
        ' aria-hidden="true"></i> Please select the <b> client type</b> to proceed.</div>';
      dialogActionNotifier.cancel_notifier$.next({
        key: "client-save-close",
        value: { status: "failed" },
      });
    }
    //return deferred.promise;
  };
}
