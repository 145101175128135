import { Component, Input, OnInit } from "@angular/core";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";
import { Culture, dateFormatter } from "src/app/shared/date-formatter";
import { Global } from "src/app/shared/global";

@Component({
  selector: "date-picker-v1-question",
  templateUrl: "./date-picker-v1-question.component.html",
  styleUrls: ["./date-picker-v1-question.component.scss"],
})
export class DatePickerV1QuestionComponent implements OnInit {
  @Input() public question!: QuestionEntity;
  @Input() public minDate?: Date;
  @Input() public maxDate?: Date;
  @Input() public onClose?: (args: any) => void;
  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;

  constructor() {}

  ngOnInit(): void {}

  keyDown = function (evt: { preventDefault: () => void }) {
    /* to disable the input for date input*/
    evt.preventDefault();
  };

  displayText = () => {
    return dateFormatter.format(this.question.answer, Culture.enSg);
    // return Global.formatDateToString(value.toISOString());
  };

  checkIsDefined(mode: string, value: Date | number): Date {
    if (mode === "min") {
      // set min date to 1900
      return Global.isDate(value) ? new Date(value) : new Date(1900, 1, 1);
    } /*(mode === 'max')*/ else {
      // set max date to 2999
      return Global.isDate(value) ? new Date(value) : new Date(2999, 12, 31);
    }
  }

  setMinDate() {
    if (this.minDate != undefined) {
      return this.minDate;
    } else {
      return this.checkIsDefined("min", this.question.minValue!);
    }
  }

  setMaxDate() {
    if (this.maxDate != undefined) {
      return this.maxDate;
    } else {
      return this.checkIsDefined("max", this.question.maxValue!);
    }
  }

  public valueUpdated(evt?: any) {
    if (this.onClose) {
      this.onClose(evt);
    }
  }
}
