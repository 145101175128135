<div id="loanRedemptionReportFields" class="container-fluid reportOption">
  <div *ngIf="showReportType" class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="mt-1">Product Type</label>
        </div>
        <div class="col-md-2">
          <input
            type="radio"
            name="productRadio"
            value="dbs"
            [(ngModel)]="productRadioControl"
            (change)="onChange_ProductRadio()"
          />
          <label class="mt-1 ml-1" for="oiProductRadio">DBS</label>
        </div>
        <div class="col-md-3">
          <input
            type="radio"
            name="productRadio"
            value="citi"
            [(ngModel)]="productRadioControl"
            (change)="onChange_ProductRadio()"
          />
          <label class="mt-1 ml-1" for="oiProductRadio">Citi</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row" *ngIf="!isCitiProduct">
        <div class="col-md-3">
          <label class="marginTop5">Segment</label>
        </div>
        <div class="col-md-9">
          <kendo-dropdownlist
            [data]="segmentList"
            textField="text"
            valueField="value"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.segmentDetails"
            (valueChange)="on_segment_change($event)"
            name="segmentDetails"
          >
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="form-group row" *ngIf="isCitiProduct">
        <div class="col-md-3">
          <label class="marginTop5" for="reportProduct">Product</label>
        </div>
        <div class="col-md-9">
          <kendo-multiselect
            id="reportProduct"
            placeholder="Select product..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="userProductList"
            textField="pd"
            valueField="pdid"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.productIds"
            name="productIds"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <!-- Left search section -->

      <div class="form-group row">
        <div class="col-md-4">
          <input
            name="seartchType"
            type="radio"
            value="1"
            checked
            (change)="setRequiredProperties(1)"
          />
          <label class="ml-2" for="recInvoiceNo">MSBR Date</label>
        </div>
        <div class="col-md-6">
          <input
            name="seartchType"
            type="radio"
            value="2"
            (change)="setRequiredProperties(2)"
          />
          <label class="ml-2" for="recPeriod">File Upload Date</label>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-4">
          <kendo-datepicker
            class="maxWidth"
            id="reportDateFrom"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="searchCriteria.effectiveDateFrom"
            (valueChange)="on_FileUploadDateFrom_change($event)"
          ></kendo-datepicker>
        </div>
        <div class="col-md-1 textAlignCenter">
          <label class="marginTop5" for="fileUploadDateTo">to</label>
        </div>
        <div class="col-md-5">
          <kendo-datepicker
            class="maxWidth"
            id="fileUploadDateTo"
            #fileUploadDateTo
            format="dd/MM/yyyy"
            placeholder=""
            [min]="fileUploadDateTo_min"
            [max]="fileUploadDateTo_max"
            [(ngModel)]="searchCriteria.effectiveDateTo"
          ></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      (click)="search()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
    </button>
    <button
      (click)="download()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
    </button>
  </div>
</div>
<br />
<div class="grid-wrapper ml-2">
  <!-- report type 17 -->
  <kendo-grid
    *ngIf="presetReportType === 17"
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column
      *ngIf="
        productRadioControl === 'dbs' && searchCriteria.segmentDetails === 'IBG'
      "
      field="BankRefNo"
      title="Bank Reference Number"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column field="PolicyNo" title="Policy Number" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="PolicyStatus" title="Policy Status" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="RiskAddress" title="Risk Address" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="productRadioControl === 'citi'"
      field="BankRefNo"
      title="Collateral No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="InsuredName" title="Insured Name" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column
      field="MITDDate"
      title="Cancellation/Endorsement Effective Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="RefundPremium"
      title="Refund Premium"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="Remarks" title="Remarks" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="TransactionBoundDate"
      title="Transaction Bound Date/Upload Date"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="MSBRDateReceived"
      [title]="
        productRadioControl === 'dbs'
          ? 'MSBR Date Received'
          : 'Email Date Received'
      "
      [width]="160"
    >
    </kendo-grid-column>
  </kendo-grid>
  <!-- report type 18 -->
  <kendo-grid
    *ngIf="presetReportType === 18"
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column
      *ngIf="
        productRadioControl === 'dbs' && searchCriteria.segmentDetails === 'IBG'
      "
      field="BankRefNo"
      title="Bank Reference Number"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column field="PolicyNo" title="Policy Number" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="productRadioControl === 'citi'"
      field="BankRefNo"
      title="Collateral No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="productRadioControl === 'citi'"
      field="InsuredName"
      title="Borrower/Property owner"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="searchCriteria.segmentDetails === 'CBG'"
      field="RiskAddress"
      title="Risk Address"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="productRadioControl === 'dbs'"
      field="MITDDate"
      [title]="
        searchCriteria.segmentDetails === 'IBG' ? 'MITD Date' : 'Effective Date'
      "
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="productRadioControl === 'dbs'"
      field="InsuranceAction"
      title="Insurance Action"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="productRadioControl === 'citi'"
      field="MITDDate"
      title="Effective Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="Remarks" title="Remarks" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="MSBRDateReceived"
      [title]="
        productRadioControl === 'dbs'
          ? 'MSBR Date Received'
          : 'Email Date Received'
      "
      [width]="160"
    >
    </kendo-grid-column>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
