<kendo-dialog
  title="Help - Address"
  class="modal-title-green"
  (close)="close('cancel')"
  [width]="550"
  [height]="350"
>
  <div class="row margin0">
    <div class="cardNoBorder">
      <div class="col-md-12 padding0">
        <!-- Modal content -->
        <div class="modal-content cardNoBorder">
          <div class="modal-body">
            <div id="feedback_form">
              <div class="clearfix">
                <div class="col-md-12">
                  <p class="fontBold">Street Address Search</p>
                  <ul class="b">
                    <li>
                      This address search capability is powered by Google. It is
                      restricted to addresses in Hong Kong only and as cataloged
                      by Google.
                    </li>
                    <li>Please only input street address.</li>
                    <li>Do not input building name, suite/lot/unit number</li>
                    <li>Example</li>
                    <li style="list-style-type: none">
                      <ul>
                        <li style="list-style-type: circle">
                          <span
                            style="vertical-align: inherit"
                            class="chubbGreen"
                            >123 Queen's Road West
                          </span>
                          <span
                            class="fa fa-check"
                            style="vertical-align: inherit"
                          ></span>
                        </li>
                        <li style="list-style-type: circle">
                          <span style="vertical-align: inherit" class="chubbRed"
                            >Central Plaza 18 Harbour Road Wan Chai
                          </span>
                          <span
                            class="fa fa-close chubbRed"
                            style="vertical-align: inherit"
                          ></span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <hr />
                  <p class="fontBold">Suite/Lot/Unit Number, Building Name</p>
                  <ul class="b">
                    <li>
                      Please input the suite/lot/unit number and building name
                      in the respective named field.
                    </li>
                  </ul>
                  <hr />
                  <p class="fontBold" ng-show="addressNaming">
                    Street Number, Street Name, Postcode, Suburb, State, Country
                  </p>
                  <p class="fontBold" ng-show="!addressNaming">
                    Address No 1, Address No 2, Postcode, City, State, Country
                  </p>
                  <ul class="b">
                    <li>
                      These fields are automatically populated after you have
                      selected an address from the <b>Street Address Search</b>.
                    </li>
                    <li>
                      In the unlikely event that the address search did not
                      return any value, the field will become editable and you
                      can manually input the correct value.
                    </li>
                  </ul>
                  <div *ngIf="showGeoCode">
                    <hr />
                    <p class="fontBold">Geocode</p>
                    <ul class="b">
                      <li>
                        This is automatically populated after you have selected
                        an address from the <b>Street Address Search</b>.
                      </li>
                      <li>
                        If the address provided does not have a valid geocode,
                        the submission will be referred.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>
