<!--height is controlled by css: .modal-body-auto -->
<kendo-dialog
  id="mdConfirmationMessageV4"
  title="Print Confirmation"
  class="modal-title-red"
  (close)="close('cancel')"
  [width]="600"
  ><div class="modal-body-auto">
    <div class="">
      <div class="form-group">
        <p style="margin-bottom: 0">
          <span style="font-size: 12px; font-weight: bold; padding-left: 12px">
            Print Options </span
          ><span style="color: red; font-size: 12px; font-weight: bold">{{
            data.confimationMsg
          }}</span>
        </p>
      </div>
    </div>
    <div class="">
      <div class="col-md-6">
        <form role="form">
          <div class="form-group">
            <div>
              <kendo-dropdownlist
                id="alert alert-warningselectReprintConfirmationBoard"
                [data]="options"
                textField="key"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="selectedPrintType"
                (valueChange)="optionChange($event)"
                name="selectReprintType"
              ></kendo-dropdownlist>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="">
      <div class="col-md-6" *ngIf="data.selectedPrintType.value == 2">
        <form role="form">
          <form role="form ">
            <div class="form-group">
              <label class="required" [textContent]="'Email'"></label>
              <div>
                <input
                  id="printEmail"
                  name="printEmail"
                  (blur)="validateEmail(data.emailId)"
                  type="text "
                  value=""
                  maxlength="100"
                  class="k-textbox form-control col-md-12"
                  [(ngModel)]="data.emailId"
                />
              </div>
              <label class="col-md-12 chubbRed" style="padding-left: 0">{{
                response
              }}</label>
            </div>
          </form>
        </form>
      </div>
      <div class="col-md-6" *ngIf="data.selectedPrintType.value == 5">
        <form role="form">
          <form role="form ">
            <div class="form-group">
              <label class="required" [textContent]="'Mobile No'"></label>
              <div>
                <input
                  id="printmobile"
                  name="printmobile"
                  max="printmobile"
                  (blur)="validateMobile(data.mobileNo)"
                  type="text "
                  value=""
                  maxlength="200"
                  class="k-textbox form-control col-md-12"
                  [(ngModel)]="data.mobileNo"
                />
              </div>
              <label class="col-md-12 chubbRed" style="padding-left: 0">{{
                response
              }}</label>
            </div>
          </form>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbLightBlue_bg_btn"
        kendoButton
        (click)="confirmation(1)"
        themeColor="primary"
      >
        OK
      </button>
      <button class="btn chubbRed_bg_btn" kendoButton (click)="close('no')">
        Cancel
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
