<!-- #renewalDraftDeletionReportFields -->
<form #heroForm="ngForm">
  <div
    id="renewalDraftDeletionReportFields"
    class="container-fluid reportOption"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="form-group row">
          <div class="col-md-1">
            <label class="marginTop5">Expiry Month</label>
          </div>
          <div class="col-md-2">
            <kendo-datepicker
              class="maxWidth"
              format="MMMM  yyyy"
              [bottomView]="'year'"
              placeholder=""
              [(ngModel)]="searchCriteria.month"
              name="month"
            ></kendo-datepicker>
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-1">
            <label class="marginTop5">Segment</label>
          </div>
          <div class="col-md-5">
            <kendo-multiselect
              placeholder="Select segment..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="segmentList"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.segment"
              name="segment"
            ></kendo-multiselect>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-1">
            <label class="marginTop5">Product</label>
          </div>
          <div class="col-md-11">
            <kendo-multiselect
              id="reportProduct"
              placeholder="Select product..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="userProductList"
              textField="pd"
              valueField="pdid"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.productIds"
              name="productIds"
            >
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button
        (click)="search()"
        class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
      >
        <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
      </button>
      <button
        (click)="download()"
        class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
      >
        <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
      </button>
    </div>
  </div>
</form>
<br />
<div class="grid-wrapper ml-2">
  <!-- report type 6 -->
  <kendo-grid
    *ngIf="presetReportType === 6"
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column
      field="policyCertificateNo"
      title="Policy Certificate No"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="masterPolicyNo"
      title="Master Policy No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="policyNo" title="Policy No" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column
      field="effectiveDate"
      title="Effective Date"
      [width]="140"
    >
    </kendo-grid-column>
    <kendo-grid-column field="expiryDate" title="Expiry Date" [width]="140">
    </kendo-grid-column
    ><kendo-grid-column
      field="grossRenewalPremium"
      title="Gross Renewal Premium"
      [width]="170"
    >
    </kendo-grid-column>
    <kendo-grid-column field="gstAmount" title="GST" [width]="70">
    </kendo-grid-column>
    <kendo-grid-column
      field="totalRenewalPremium"
      title="Total Renewal Premium"
      [width]="140"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="freeOrPaidRenewal"
      title="Free/Paid Renewal"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="draftCreationDate"
      title="Draft Creation Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="status" title="Status" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column
      field="draftDeletionDate"
      title="Draft Deletion Date"
      [width]="180"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="transactionType"
      title="Transaction Type"
      [width]="170"
    >
    </kendo-grid-column>
    <kendo-grid-column field="insuredName" title="Insured Name" [width]="180">
    </kendo-grid-column
    ><kendo-grid-column
      field="draftSumInsured"
      title="Draft Sum Insured (Uploaded SI)"
      [width]="180"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="currentSumInsured"
      title="EPL Sum Insured (Current EPL SI)"
      [width]="180"
    >
    </kendo-grid-column>
    <kendo-grid-column field="riskAddress" title="Risk Address" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column
      field="bankReference"
      title="Bank Reference"
      [width]="130"
    >
    </kendo-grid-column>
  </kendo-grid>
  <!-- report type 7 -->
  <kendo-grid
    *ngIf="presetReportType === 7"
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column
      field="MeridianSequenceNo"
      title="Meridian Sequence No"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="PolicyCertificateNo"
      title="Master Policy No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="MasterPolicyNo"
      title="Master Policy No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="PolicyNo" title="Policy No" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column
      field="EffectiveDate"
      title="Effective Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="ExpiryDate" title="Expiry Date" [width]="140">
    </kendo-grid-column
    ><kendo-grid-column
      field="GrossRenewalPremium"
      title="Gross Renewal Premium"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="GST" title="GST" [width]="70">
    </kendo-grid-column>
    <kendo-grid-column
      field="TotalRenewalPremium"
      title="Total Renewal Premium"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="CommissionPerc"
      title="Commission %"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column field="Commission" title="Commission" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="GSTOnCommission"
      title="GST On Commission"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="NoOfFreeYears"
      title="No Of Years - Free Insurance"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="FreePaidRenewal"
      title="Free/Paid Renewal"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="RenewalBoundDate"
      title="Renewal Bound Date"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="RenewalDocsGeneratedDate"
      title="Renewal Docs Generated Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="ADFileMonth" title="AD File Month" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="Status" title="Status" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="SavedBy" title="Saved By" [width]="160">
    </kendo-grid-column>

    <kendo-grid-column field="RMName" title="RM Name" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column field="BUPCCode" title="BUPC Code" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="BankRef" title="Bank Ref" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="InsuredName" title="Insured Name" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column
      field="AdditionalInsureds"
      title="Additional Insureds"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="SumInsured" title="Sum Insured" [width]="140">
    </kendo-grid-column
    ><kendo-grid-column field="RiskAddress" title="Risk Address" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="CorrespondenceAddress"
      title="Correspondence Address"
      [width]="70"
    >
    </kendo-grid-column>
    <kendo-grid-column field="InsuredNRIC" title="Insured NRIC" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="BorrowerCIN" title="Borrower CIN" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column field="Occupation" title="Occupation" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="OccupationRates"
      title="Occupation Rates"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="OccupiedAs" title="Occupied As" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="NatureOfBusiness"
      title="Nature Of Business"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="UsageOfPremises"
      title="Usage Of Premises"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="TypeOfProperty"
      title="Type Of Property"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="TypeOfEquipment"
      title="Type Of Equipment"
      [width]="160"
    >
    </kendo-grid-column>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
