import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CdkDialogService } from "../../../../../../../../services/dialog/cdk-dialog.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { MasterPolicyService } from "src/app/services/master-policy.service";
import { BaseTodoListComponent } from "../../../../../../base/base-todo-list/base-todo-list.component";
import { MfHkReport } from "../mf-hk-todo-list/mf-hk-search-criteria";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { LOCALE_ID, Inject } from "@angular/core";

@Component({
  selector: "app-mf-hk-report-list",
  templateUrl: "./mf-hk-report-list.component.html",
  styleUrls: ["./mf-hk-report-list.component.scss"],
})

export class MfHkReportComponent
  extends BaseTodoListComponent
  implements OnInit
{
  public reportModel = new MfHkReport();
  public excelTemplate = new ExcelTemplateService();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public override masterPolicyService: MasterPolicyService,
    public override masterDataService: MasterDataService,
    public override uploadService: UploadExcelService,
    public override cdkDialogService: CdkDialogService
  ) {
    super(
      router,
      broadcastService,
      identityRolesService,
      masterPolicyService,
      masterDataService,
      uploadService,
      cdkDialogService
    );
  }

  public onExport(): void {
    this.reportModel = {
      reportId: this.reportModel.reportId == undefined && this.defaultReportListItem.value ? this.defaultReportListItem.value : this.reportModel.reportId,
      reportName: this.reportModel.reportName == undefined && this.defaultReportListItem.text ? this.defaultReportListItem.text : this.reportModel.reportName
    };
    this.masterPolicyService
      .exportReport(this.reportModel.reportId)
      .then(
        (result: any) => {
          const base64String = result.fileBytes;
          const decodedByteArray = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
          this.uploadService.generateAnchorAndTriggerDownload(
            decodedByteArray,
            result.filename
          );
        },
        (err: any) => {
          console.log(err);
        }
      );
  }
}