<div *ngIf="tabRequested" id="premiumSummaryTab">
  <div *ngIf="tabShow">
    <div class="row" id="premiumSummaryTabContainer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 card card-mod cardNoBorder ng-scope">
            <kendo-expansionpanel
              id="premium_summary_panel"
              title="Premium Summary"
              [expanded]="true"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-2">
                    <form role="form">
                      <div class="form-group">
                        <percentage-box-v1-question
                          [question]="
                            proposal.qsPremiumSummary.qs_master_brokerCommission
                          "
                        >
                        </percentage-box-v1-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-2">
                    <form role="form">
                      <div class="form-group">
                        <currency-box-v1-question
                          [question]="
                            proposal.qsPremiumSummary.qs_master_commission
                          "
                        >
                        </currency-box-v1-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-3">
                    <form role="form">
                      <div class="form-group">
                        <currency-box-v1-question
                          [question]="
                            proposal.qsPremiumSummary
                              .qs_master_netCommOffsetWithDiscount
                          "
                        >
                        </currency-box-v1-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-2">
                    <form role="form">
                      <div class="form-group">
                        <currency-box-v1-question
                          [question]="
                            proposal.qsPremiumSummary.qs_master_commissionGst
                          "
                        >
                        </currency-box-v1-question>
                      </div>
                    </form>
                  </div>

                  <div class="col-md-2">
                    <form role="form">
                      <div class="form-group">
                        <currency-box-v1-question
                          [question]="
                            proposal.qsPremiumSummary
                              .qs_master_commissionPayable
                          "
                        >
                        </currency-box-v1-question>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      id="IBGPreSumm"
                      class="table-outter"
                      style="padding: 1px"
                    >
                      <!-- <div id="IBGPreSumm" class="table-outter" style="padding:1px; overflow:auto;"></div> -->
                      <table
                        class="table table-bordered table-hover nowrap marginTop10 cursorNormal borderNone"
                      >
                        <thead>
                          <tr>
                            <td colspan="1" class="borderNone"></td>
                            <td
                              class="textAlignCenter chubbGray_bg chubbWhite [textContent]ing"
                              colspan="6"
                              [textContent]="poiText"
                              *ngIf="!proposal.isUnderwriter"
                            ></td>
                            <td
                              class="textAlignCenter chubbGray_bg chubbWhite [textContent]ing"
                              colspan="6"
                              [textContent]="poiText"
                              *ngIf="proposal.isUnderwriter"
                            ></td>
                          </tr>
                          <tr class="chubbGray_bg chubbWhite">
                            <th class="textAlignLeft" style="width: 40%">
                              Coverage
                            </th>
                            <th class="textAlignCenter">Gross Premium</th>
                            <th class="textAlignCenter">
                              Discount % offered by Citi
                            </th>
                            <th class="textAlignCenter">
                              Premium $ Discount by Citi
                            </th>
                            <th class="textAlignCenter">
                              Nett Premium billed to Customer
                            </th>
                            <th class="textAlignCenter">GST on Premium</th>
                            <th class="textAlignCenter fontBold">
                              Premium to be collected from Customer
                            </th>
                          </tr>
                          <tr *ngIf="!(coverages.length > 0)">
                            <td colspan="7">
                              <div style="height: 30px">
                                <div class="margin5 textAlignCenter">
                                  <span class="loadingBarText"
                                    >No data found.</span
                                  >
                                </div>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody class="ng-scope" *ngIf="coverages.length > 0">
                          <ng-container *ngFor="let coverage of coverages">
                            <tr
                              *ngIf="
                                coverage.coverageProductMappingId ===
                                  'bda310ed-701f-4384-b56a-6bf85aac7fad' ||
                                coverage.coverageProductMappingId ===
                                  'ab0bc9f7-3385-473c-938c-c020007fd37a' ||
                                coverage.coverageProductMappingId ===
                                  'e3dd5372-156d-45e7-87ad-0fb30139753a'
                              "
                            >
                              <td class="paddingLeft30">Property Fire</td>
                              <td class="textAlignRight">
                                <kendo-numerictextbox
                                  *ngIf="
                                    proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] &&
                                    isPremiumOverridingAllowed &&
                                    !proposal.IsCancellationAdjustment
                                  "
                                  id="grossPremium_{{
                                    coverage.coverageProductMappingId
                                  }}"
                                  name="grossPremium_{{
                                    coverage.coverageProductMappingId
                                  }}"
                                  [(ngModel)]="
                                    coverage.premiumDetails.grossPremium
                                  "
                                  [min]="minPremium"
                                  [max]="maxPremium"
                                  [format]="'c2'"
                                  [spinners]="false"
                                  [decimals]="2"
                                  class="textAlignCenter width98Per"
                                  [ngClass]="{
                                    chubbLightGreen_bg:
                                      editedDiv[
                                        'rate_' +
                                          coverage.coverageProductMappingId
                                      ]
                                  }"
                                  (blur)="
                                    updatingPremiumDetails(
                                      coverage.coverageProductMappingId,
                                      coverage.premiumDetails.grossPremium,
                                      'grossPremium'
                                    )
                                  "
                                >
                                </kendo-numerictextbox>
                                <span
                                  [textContent]="
                                    premiumSummary.grossPremium
                                      | ausCurrency : 2
                                  "
                                  *ngIf="
                                    (proposal.isUnderwriter &&
                                      (proposal.refreshedProposalEntity
                                        .proposalReadOnly ||
                                        !proposalService.proposal.auxiliary[
                                          'isPremiumManualOverridden'
                                        ] ||
                                        !isPremiumOverridingAllowed ||
                                        proposal.IsCancellationAdjustment)) ||
                                    proposal.isBroker
                                  "
                                ></span>
                                <div
                                  id="resetPremium_{{
                                    coverage.coverageProductMappingId
                                  }}"
                                  style="position: relative"
                                  *ngIf="
                                    proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] && isPremiumOverridingAllowed
                                  "
                                >
                                  <a
                                    (click)="
                                      resetPremiumDetails(
                                        coverage.coverageProductMappingId,
                                        'grossPremium'
                                      )
                                    "
                                    class="close"
                                    style="
                                      position: absolute;
                                      right: 10px;
                                      color: red;
                                      opacity: 0.7;
                                      top: -30px;
                                    "
                                    [ngClass]="{ resetIconIE: resetIconIE }"
                                    title="Reset"
                                    >×</a
                                  >
                                </div>
                              </td>
                              <td class="textAlignRight">
                                <span
                                  [textContent]="
                                    coverage.premiumDetails.discountPercentage
                                      | percentage
                                  "
                                ></span>
                              </td>
                              <td class="textAlignRight">
                                <span
                                  [textContent]="
                                    coverage.premiumDetails.premiumDiscount
                                      | ausCurrency : 2
                                  "
                                ></span>
                              </td>
                              <td class="textAlignRight">
                                <span
                                  [textContent]="
                                    coverage.premiumDetails.netBilledPremium
                                      | ausCurrency : 2
                                  "
                                ></span>
                              </td>
                              <td class="textAlignRight">
                                <kendo-numerictextbox
                                  *ngIf="
                                    proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] &&
                                    isPremiumOverridingAllowed &&
                                    !proposal.IsCancellationAdjustment
                                  "
                                  id="cbg_fireGST"
                                  name="cbg_fireGST"
                                  [(ngModel)]="
                                    coverage.premiumDetails.premiumTax
                                  "
                                  [min]="minPremiumTax"
                                  [max]="maxPremiumTax"
                                  [format]="'c2'"
                                  [spinners]="false"
                                  [decimals]="2"
                                  class="textAlignCenter"
                                  (blur)="
                                    updatingCBGPremium(
                                      coverage.premiumDetails.premiumTax,
                                      'premiumTax'
                                    )
                                  "
                                >
                                </kendo-numerictextbox>

                                <span
                                  class="textAlignCenter"
                                  *ngIf="
                                    (proposal.isUnderwriter &&
                                      (proposal.refreshedProposalEntity
                                        .proposalReadOnly ||
                                        !proposalService.proposal.auxiliary[
                                          'isPremiumManualOverridden'
                                        ] ||
                                        !isPremiumOverridingAllowed ||
                                        proposal.IsCancellationAdjustment)) ||
                                    proposal.isBroker
                                  "
                                  title="currency "
                                  [textContent]="
                                    coverage.premiumDetails.premiumTax
                                      | ausCurrency : 2
                                  "
                                ></span>
                              </td>
                              <td class="textAlignRight">
                                <span
                                  [textContent]="
                                    coverage.premiumDetails.totalPremium
                                      | ausCurrency : 2
                                  "
                                ></span>
                              </td>
                            </tr>
                          </ng-container>
                          <tr class="textAlignLeft totalCellBorder text-nowrap">
                            <td
                              class="textAlignLeft totalCellBorder text-nowrap clearBoth"
                            >
                              <div class="inlineBlock fontBold floatLeft">
                                <span style="vertical-align: sub">Total</span>
                              </div>
                            </td>
                            <td
                              class="textAlignRight fontBold"
                              [textContent]="
                                premiumSummary.grossPremium | ausCurrency
                              "
                            ></td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="
                                  premiumDetails.discountPercentage | percentage
                                "
                              ></span>
                            </td>
                            <td class="textAlignRight">
                              <span
                                [textContent]="
                                  premiumDetails.premiumDiscount
                                    | ausCurrency : 2
                                "
                              ></span>
                            </td>
                            <td class="textAlignRight fontBold">
                              <span
                                [textContent]="
                                  premiumDetails.netBilledPremium
                                    | ausCurrency : 2
                                "
                              ></span>
                            </td>
                            <td
                              class="textAlignRight fontBold"
                              [textContent]="
                                premiumSummary.premiumTax | ausCurrency
                              "
                            ></td>
                            <td
                              class="textAlignRight fontBold"
                              [textContent]="
                                premiumSummary.totalPremium | ausCurrency
                              "
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row floatRight">
                  <div class="col-md-12 fontBold" style="font-size: 11px">
                    <em>all amounts in SGD unless otherwise specified.</em>
                  </div>
                </div>
              </div>
            </kendo-expansionpanel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
