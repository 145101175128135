<div class="row marginLeft15" *ngIf="isDataLoaded">
  <div class="k-i-loading"></div>
</div>
<div *ngIf="!isDataLoaded" class="col-md-12">
  <div class="card-head chubbWhite_bg chubbLightGray_border">
    <div class="tools">
      <div class="btn-group"></div>
    </div>
    <header class="chubbDarkBlue">Additional Information</header>
  </div>
  <div class="card-body chubbLightGray_border">
    <div style="padding: 10px">
      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Display Name'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.displayName }}</div>
        <div class="col-md-3">
          <label [innerHtml]="'Salutation'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.salutation }}</div>
      </div>

      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'First Name'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.firstName }}</div>
        <div class="col-md-3">
          <label class="required" [innerHtml]="'Last Name'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.lastName }}</div>
      </div>

      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Date of Birth'"></label>
        </div>
        <div class="col-md-3">
          <span [hidden]="isDateTimeMin">{{
            personalDetails.dateOfBirth | date : "dd/MM/yyyy"
          }}</span>
        </div>
        <div class="col-md-3">
          <label [innerHtml]="'Gender'"></label>
        </div>
        <div
          *ngIf="personalDetails.displayGender !== 'Undefined'"
          class="col-md-3"
        >
          {{ personalDetails.displayGender }}
        </div>
      </div>

      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Nationality'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.displayNationality }}</div>
        <div class="col-md-3">
          <label [innerHtml]="'Race'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.race }}</div>
      </div>

      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Passport No.'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.passportNo }}</div>
        <div class="col-md-3">
          <label [innerHtml]="'Marital Status'"></label>
        </div>
        <div
          *ngIf="personalDetails.displayMaritalStatus !== 'Undefined'"
          class="col-md-3"
        >
          {{ personalDetails.displayMaritalStatus }}
        </div>
      </div>

      <div
        [hidden]="personalDetails.maritalStatus == 1"
        class="col-md-12 row k-bottom-lightgray"
        style="padding: inherit"
      >
        <div class="col-md-3">
          <label [innerHtml]="'Name of Spouse'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.nameOfSpouse }}</div>
        <div class="col-md-3">
          <label [innerHtml]="'No of Children'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.noOfChildren }}</div>
      </div>

      <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
        <div class="col-md-3">
          <label [innerHtml]="'Education'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.education }}</div>
        <div class="col-md-3">
          <label [innerHtml]="'Hobbies'"></label>
        </div>
        <div class="col-md-3">{{ personalDetails.hobbies }}</div>
      </div>
    </div>
  </div>
</div>
