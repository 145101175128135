<div class="wrapper">
  <kendo-tabstrip style="padding-top: 30px">
    <kendo-tabstrip-tab title="Open Item" [selected]="true">
      <ng-template kendoTabContent>
        <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
          <div class="container-fluid mt-2">
            <!-- Search filters -->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row lbl-align">
                  <div class="col-md-4">
                    <label>Product Type</label>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="radio"
                      name="productRadio"
                      #name="ngModel"
                      value="dbs"
                      [(ngModel)]="searchCriteria.productRadio"
                      (change)="onChange_ProductRadio()"
                    />
                    <label class="lbl" for="oiProductRadio">DBS</label>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="radio"
                      name="productRadio"
                      #name="ngModel"
                      value="citi"
                      [(ngModel)]="searchCriteria.productRadio"
                      (change)="onChange_ProductRadio()"
                    />
                    <label class="lbl" for="oiProductRadio">Citi</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row lbl-align">
                  <div class="col-md-4">
                    <label for="oiPolicyNo">Policy No</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      name="policyNo"
                      #name="ngModel"
                      id="oiPolicyNo"
                      placeholder="Policy No"
                      (input)="on_quoteNumber_keyup($event)"
                      [(ngModel)]="searchCriteria.policyNo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row lbl-align">
                  <div class="col-md-3">
                    <label for="oiEffectiveDateFrom"
                      >Open Item Entry Date</label
                    >
                  </div>
                  <div class="col-md-4">
                    <kendo-datepicker
                      class="maxWidth"
                      id="oiEffectiveDateFrom"
                      format="dd/MM/yyyy"
                      placeholder=""
                      [(ngModel)]="searchCriteria.effectiveDateFrom"
                      (valueChange)="on_searchEffectiveDateFrom_change($event)"
                      name="EffectiveDateFrom"
                      #name="ngModel"
                    ></kendo-datepicker>
                  </div>
                  <div class="col-md-1 textAlignCenter">
                    <label class="mt-2">to</label>
                  </div>
                  <div class="col-md-4">
                    <kendo-datepicker
                      class="maxWidth"
                      #oiEffectiveDateTo
                      id="oiEffectiveDateTo"
                      format="dd/MM/yyyy"
                      placeholder=""
                      [min]="openItemEntryDate_min"
                      [max]="openItemEntryDate_max"
                      [(ngModel)]="searchCriteria.effectiveDateTo"
                      name="EffectiveDateTo"
                      #name="ngModel"
                    ></kendo-datepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="searchCriteria.productRadio == 'dbs'">
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="mt-2" for="oiInvoiceNo">Invoice No</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      id="oiInvoiceNo"
                      placeholder="Invoice No"
                      (input)="on_quoteNumber_keyup($event)"
                      name="invoiceNo"
                      #name="ngModel"
                      [(ngModel)]="searchCriteria.invoiceNo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-md-3">
                    <label for="oiEffectiveDateFrom"
                      >Policy Effective Date</label
                    >
                  </div>
                  <div class="col-md-4">
                    <kendo-datepicker
                      class="maxWidth"
                      id="oiPolicyEffectiveDateFrom"
                      format="dd/MM/yyyy"
                      placeholder=""
                      [(ngModel)]="searchCriteria.policyeffectiveDateFrom"
                      (valueChange)="
                        on_searchPolicyEffectiveDateFrom_change($event)
                      "
                      name="PolicyEffectiveDateFrom"
                      #name="ngModel"
                    ></kendo-datepicker>
                  </div>
                  <div class="col-md-1 textAlignCenter">
                    <label class="mt-1">to</label>
                  </div>
                  <div class="col-md-4">
                    <kendo-datepicker
                      class="maxWidth"
                      id="oiPolicyEffectiveDateTo"
                      #oiPolicyEffectiveDateTo
                      format="dd/MM/yyyy"
                      placeholder=""
                      [(ngModel)]="searchCriteria.policyeffectiveDateTo"
                      name="PolicyEffectiveDateTo"
                      #name="ngModel"
                    ></kendo-datepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="mt-2" for="oiChequeNo">Cheque No</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      id="oiChequeNo"
                      placeholder="Cheque No"
                      name="chequeNo"
                      #name="ngModel"
                      [(ngModel)]="searchCriteria.chequeNo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <div
                    *ngIf="searchCriteria.productRadio == 'dbs'"
                    style="display: contents"
                  >
                    <div class="col-md-3">
                      <label class="mt-1" for="oiPeriod"
                        >Auto Debit Month</label
                      >
                    </div>
                    <div class="col-md-4">
                      <kendo-dateinput
                        class="maxWidth"
                        id="autoDebitPeriod_dateInput"
                        format="MM/yyyy"
                        placeholder="month/year"
                        (valueChange)="on_AutoDebitMonthChange($event)"
                        [(ngModel)]="autoDebitPeriod"
                        name="autoDebitPeriod"
                        #name="ngModel"
                      >
                      </kendo-dateinput>
                    </div>
                  </div>
                  <div
                    *ngIf="searchCriteria.productRadio == 'citi'"
                    style="display: contents"
                  >
                    <div class="col-md-3">
                      <label class="mt-1" for="oiPeriodWeek"
                        >Auto Debit Week</label
                      >
                    </div>
                    <div class="col-md-4">
                      <kendo-datepicker
                        class="maxWidth"
                        id="autoDebitPeriod_dateInput_Week"
                        format="dd/MM/yyyy"
                        placeholder=""
                        [(ngModel)]="autoDebitPeriod"
                        [disabledDates]="disabledDates"
                        (valueChange)="on_AutoDebitWeekChange($event)"
                        name="autoDebitPeriod"
                        #name="ngModel"
                      ></kendo-datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="searchCriteria.productRadio == 'dbs'">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label class="mt-2" for="reportSegment">Segment</label>
                  </div>
                  <div class="col-md-10">
                    <kendo-multiselect
                      id="reportSegment"
                      placeholder="Select segment..."
                      [autoClose]="false"
                      [kendoDropDownFilter]="{
                        operator: 'contains'
                      }"
                      [data]="segmentList"
                      textField="text"
                      valueField="value"
                      [valuePrimitive]="true"
                      [(ngModel)]="searchCriteria.segments"
                      name="segments"
                    >
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label class="mt-2" for="oiProduct">Product</label>
                  </div>
                  <div class="col-md-10">
                    <kendo-multiselect
                      id="oiProduct"
                      placeholder="Select product..."
                      [autoClose]="false"
                      [kendoDropDownFilter]="{
                        operator: 'contains'
                      }"
                      [data]="userProductList"
                      textField="pd"
                      valueField="pdid"
                      [valuePrimitive]="true"
                      [(ngModel)]="searchCriteria.productIds"
                      name="productIds"
                    >
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label class="mt-2" for="oiStatus">Status</label>
                  </div>
                  <div class="col-md-10">
                    <kendo-multiselect
                      id="oiStatus"
                      placeholder="Select status..."
                      [autoClose]="false"
                      [kendoDropDownFilter]="{
                        operator: 'contains'
                      }"
                      [data]="statusList"
                      textField="text"
                      valueField="value"
                      [valuePrimitive]="true"
                      [(ngModel)]="searchCriteria.statusIds"
                      name="statusIds"
                    >
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
<div class="ml-2">
  <button
    (click)="onSubmit()"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    event="1"
  >
    <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
  </button>
  <button
    (click)="export()"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn ml-1"
    event="2"
  >
    <i class="fa fa-download ng-scope" aria-hidden="true"></i> Export
  </button>
</div>
<br />
<div class="grid-wrapper ml-3">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    mode="multiple"
    kendoGridSelectBy
    [(selectedKeys)]="selectedRowIndexes"
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column
      field="actionName"
      title="Action"
      [width]="85"
      [style]="{ color: '#ff4350', 'text-align': 'center' }"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="meridianOpenItemSequenceNo"
      title="Meridian Sequence No"
      [width]="140"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="policyCertificateNumber"
      title="Policy Certificate No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="amount" [title]="getTitle()" [width]="90">
    </kendo-grid-column>
    <kendo-grid-column field="amountGst" title="GST" [width]="90">
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="searchCriteria.productRadio == 'citi'"
      field="premiumToBeCollectedFromCustomer"
      title="Premium to be Collected from Custome"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="searchCriteria.productRadio == 'citi'"
      field="netCommissionOffsetWithDiscount"
      title="Net Comm offset with Discount"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column field="statusDisplayText" title="Status" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="invoiceNo" title="Invoice No" [width]="110">
    </kendo-grid-column>
    <kendo-grid-column
      field="lastUpdatedByUserName"
      title="Saved By"
      [width]="130"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="policyHolder"
      title="Policy Holder Name"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="correspondenceAddress"
      title="Correspondence Address"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="inactiveAddressChanged"
      title="Inactive Address Changed"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="searchCriteria.productRadio == 'citi'"
      field="customerType"
      title="Customer Type"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="searchCriteria.productRadio == 'citi'"
      field="collateralNumber"
      title="Collateral No"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="grossPremium"
      title="Gross Premium (Excl.GST)"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column field="BUPC" title="BUPC" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column
      field="bankReference"
      title="Bank Reference"
      [width]="130"
    >
    </kendo-grid-column>

    <kendo-grid-column field="insuredName" title="Insured Name" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column
      field="effectiveDate"
      title="Effective Date"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column field="expiryDate" title="Expiry Date" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column field="sumInsured" title="Sum Insured" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column field="riskAddress" title="Risk Address" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column field="insuredNRIC" title="Insured NRIC" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column
      field="additionalInsured"
      title="Additional Insured"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="AutoDebitMonth"
      *ngIf="searchCriteria.productRadio != 'citi'"
      title="AD-Month"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="AutoDebitWeek"
      *ngIf="searchCriteria.productRadio == 'citi'"
      title="AD-Week"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="CancellationType"
      title="Cancellation Adjustment"
      [width]="130"
    >
    </kendo-grid-column>

    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="10"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
  <app-open-item-details
    *ngIf="redirectOpened"
    [opened]="redirectOpened"
    [openItemId]="openItemId"
    (closeDialogEvent)="returnToOpenItemListing($event)"
  >
  </app-open-item-details>
  <app-do-manual-pay-form
    *ngIf="doManualPay"
    [model]="openItem"
    (makePayment)="makePaymentHandler($event)"
    (cancel)="cancelPaymentHandler()"
  >
  </app-do-manual-pay-form>
  <app-do-refund-pay-form
    *ngIf="doRefundPay"
    [model]="openItem"
    (doRefund)="doRefundHandler($event)"
    (cancel)="cancelRefundHandler()"
  >
  </app-do-refund-pay-form>
  <app-do-bounce-cheque-form
    *ngIf="doBounceCheque"
    [openItemId]="openItemId"
    (bounceCheque)="bounceChequeHandler($event)"
    (cancel)="cancelBounceChequeHandler()"
  >
  </app-do-bounce-cheque-form>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
<div #appendTo class="append-container"></div>
