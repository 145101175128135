<kendo-dialog
  *ngIf="active"
  [width]="600"
  [height]="500"
  (close)="closeForm()"
  style="z-index: 1000"
>
  <kendo-dialog-titlebar class="modal-title-custom chubbLightOrange_bg">
    Bounce Cheque Payment
  </kendo-dialog-titlebar>
  <div class="container-fluid ml-2">
    <div class="form-group row mt-4">
      <div class="col-md-5">
        <label for="chequeIssuanceBank">Cheque Issuing Bank</label>
      </div>
      <div class="col-md-7">
        <p
          class="form-control-static"
          id="chequeIssuanceBank"
          [textContent]="openItemEntity.chequeIssuanceBank"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label for="chequeNo">Cheque Number</label>
      </div>
      <div class="col-md-7">
        <p
          class="form-control-static"
          id="chequeNo"
          [textContent]="openItemEntity.chequeNumber"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label for="amount">Total Premium</label>
      </div>
      <div class="col-md-7">
        <p
          class="form-control-static"
          id="amount"
          [textContent]="openItemEntity.amount"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label for="amountGst">GST</label>
      </div>
      <div class="col-md-7">
        <p
          class="form-control-static"
          id="amountGst"
          [textContent]="openItemEntity.amountGst"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label for="payerName">Payer Name</label>
      </div>
      <div class="col-md-7">
        <p
          class="form-control-static"
          id="payerName"
          [textContent]="openItemEntity.payerName"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label for="policyCertificateNumber">Policy Certificate Number</label>
      </div>
      <div class="col-md-7">
        <p
          class="form-control-static"
          id="policyCertificateNumber"
          [textContent]="openItemEntity.policyCertificateNumber"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label for="remarks">Payment Remarks</label>
      </div>
      <div class="col-md-7">
        <p
          class="form-control-static"
          id="remarks"
          [textContent]="openItemEntity.remarks"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label>Bounce Cheque Remarks</label>
      </div>
      <div class="col-md-7">
        <textarea
          class="form-control"
          rows="3"
          [(ngModel)]="manualPayment.remarks"
        ></textarea>
      </div>
    </div>
  </div>
  <kendo-dialog-actions>
    <button
      kendoButton
      class="btn chubbWhite chubbLightBlue_bg_btn"
      themeColor="primary"
      (click)="onSave($event)"
    >
      Bounce Cheque
    </button>
    <button
      kendoButton
      class="btn chubbWhite chubbRed_bg_btn"
      (click)="onCancel($event)"
    >
      Return
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
