import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { Global } from "../shared/global";

@Pipe({
  name: "percentNoSymbol",
})
export class PercentNoSymbolPipe extends DecimalPipe implements PipeTransform {
  override transform(value: any, decimal?: any, symbol?: any): any {
    if (Global.isUndefined(value)) {
      value = 0;
    }

    let fractionSize = "1.2-2";
    if (decimal != undefined) {
      fractionSize = "1.2-" + decimal; //todo: check if works
    }
    let formatedByCurrencyPipe = super.transform(value, fractionSize);
    return formatedByCurrencyPipe;
  }
}
