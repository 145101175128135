import * as lodash from 'lodash';

/** https://stackoverflow.com/questions/28150967/typescript-cloning-object */
class Cloner {

    /** Object.create
     * Properties: Yes | Methods: Yes | Deep Copy: Yes
     */
    cloneDeep<T>(obj: any) {
        return lodash.cloneDeep(obj);
    }

    /** Object.create
     * Properties: Yes | Methods: Yes | Deep Copy: Yes
     */
    arrayCloneDeep<T>(arr: any[]) {
        return lodash.map(arr, this.cloneDeep);
    }
}

export const cloner = new Cloner();
