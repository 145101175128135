import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
} from "@angular/core";
import { CldrIntlService, IntlService } from "@progress/kendo-angular-intl";
import { MessageService } from "@progress/kendo-angular-l10n";
import { CustomMessagesService } from "../../services/custom-messages.service";
import { locales } from "src/app/resources/locales";
import { BroadcastService } from "src/app/services/broadcast.service";
import { SessionStorageService } from "src/app/services/storages/session-storage.service";
import { Identity } from "src/app/shared/user/identity";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { UserProfileService } from "src/app/services/user-profile.service";
import { IUser } from "src/app/framework/interface.shared";
import { BaseComponent } from "../shared/base/base.component";
import { Router } from "@angular/router";
import { DialogWrapperService } from "src/app/services/dialog/dialog-wrapper.service";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { MdProfileDetailsComponent } from "../shared/partials/md-menu-header/md-profile-details/md-profile-details.component";
import { AuthorizationService } from "src/app/services/authentication/authorization.service";
import { ROUTE_USER_TYPE_LISTING } from "src/app/app-routing.module";

@Component({
  selector: "app-header-component",
  templateUrl: "./header.commponent.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Output() public toggle = new EventEmitter();
  @Input() public selectedPage?: string;

  inspectCurrentOnlineUserEnable: boolean = false;
  appAdminEnable: boolean = false;
  impersonateUserEnable: boolean = false;
  impersonateUser = "";
  userTypeOptions: any;
  isUserCount: boolean = false;

  isUserLoggedIn!: boolean;
  currentUser: IUser | undefined;
  userInfo: any;
  userDisplayName: string = "";
  userOrganizationName: string = "";

  //#region theme, no use for now
  public customMsgService: CustomMessagesService;

  public selectedLanguage = { locale: "English", localeId: "en-US" };
  public locales = locales;
  public popupSettings = { width: "150" };
  public themes: { href: string; text: string }[] = [
    {
      href: "assets/kendo-theme-default/dist/all.css",
      text: "Default",
    },
    {
      href: "assets/kendo-theme-bootstrap/dist/all.css",
      text: "Bootstrap",
    },
    {
      href: "assets/kendo-theme-material/dist/all.css",
      text: "Material",
    },
  ];
  public selectedTheme = this.themes[1];
  public message: string = "";
  public userTypeName: string | undefined;
  //#endregion

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public identity: Identity,
    public messages: MessageService,
    @Inject(LOCALE_ID) public localeId: string,
    public intlService: IntlService,
    private sessionStorageService: SessionStorageService,
    private userProfileService: UserProfileService,
    private dialogWrapperService: DialogWrapperService,
    private authorizationService: AuthorizationService
  ) {
    super(router, broadcastService, identityRolesService);
    //when log-in, the sessionStorageService might not yet init-ed, hence needs to sub to a broadcast
    this.broadcastService.onAutheticated.subscribe((msg: any) => {
      this.isUserLoggedIn = msg.loginStatus;
      this.userInfo = this.sessionStorageService.get(
        Identity.USER_STORAGE_NAME
      );
      this.userDisplayName = this.userInfo?.displayName;
      this.userOrganizationName = this.userInfo?.organisationName;

      this.currentUser = this.identityRolesService.getIdentity().currentUser(); //when first init
      this.getUserTypeOptions();
    });

    //when refresh
    this.userInfo = this.sessionStorageService.get("authorizedUserData");

    //#region theme, no use for now
    this.localeId = this.selectedLanguage.localeId;
    this.setLocale(this.localeId);
    // //load Bootstrap first
    // this.changeTheme({
    //   href: "assets/kendo-theme-bootstrap/dist/all.css",
    //   text: "Bootstrap",
    // });
    //load default first, which to mimic Banca
    this.changeTheme({
      href: "assets/kendo-theme-default/dist/all.css",
      text: "Default",
    });
    this.customMsgService = this.messages as CustomMessagesService;
    this.customMsgService.language = this.selectedLanguage.localeId;
    //#endregion
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.currentUser = this.identityRolesService.getIdentity().currentUser(); //when refresh

    this.inspectCurrentOnlineUserEnable =
      this.identityRolesService.isSuperAdmin;
    this.appAdminEnable = this.identityRolesService.isSuperAdmin;
    this.impersonateUserEnable = this.identityRolesService.isSuperAdmin;
    this.userDisplayName = this.userInfo?.displayName;
    this.userOrganizationName = this.userInfo?.organisationName;
  }

  getUserTypeOptions() {
    if (this.currentUser) {
      this.userProfileService
        .GetUserRolesByEmail(this.currentUser.emailAddress)
        .subscribe((userTypeList) => {
          var userId = this.sessionStorageService.get("_USRLE");
          if (userId != null) {
            userTypeList.body.Items.forEach((value: any) => {
              if (value.UserId == userId) {
                value.UserName = "user-circle-o";
              } else {
                value.UserName = "random";
              }
            });
          }

          this.userTypeOptions = userTypeList.body;
          this.isUserCount = this.userTypeOptions.Items.length > 1;
          this.userTypeName = "";
          let displayName = "";
          this.userDisplayName = "";

          displayName =
            this.identity.currentUser().organisationShortName + " | ";
          if (
            this.identity.currentUser().organisationShortName != undefined &&
            this.identity
              .currentUser()
              .organisationShortName?.trim()
              .toLowerCase() ==
              this.identity.currentUser().displayName.trim().toLowerCase()
          ) {
            displayName += this.identity.currentUser().userName;
          } else {
            displayName += this.identity.currentUser().displayName;
          }

          if (this.identity.getCurrentUserType() === 1)
            this.userTypeName = "Admin";
          else if (this.identity.getCurrentUserType() === 2)
            this.userTypeName = "Broker";
          else if (this.identity.getCurrentUserType() === 3)
            this.userTypeName = "Underwriter";
          else if (this.identity.getCurrentUserType() === 4)
            this.userTypeName = "Client";
          else if (this.identity.getCurrentUserType() === 5)
            this.userTypeName = "AgentusertypeList.type === 4";
          else if (this.identity.getCurrentUserType() === 6)
            this.userTypeName = "Marketer";

          this.userDisplayName = displayName + " (" + this.userTypeName + ")";
          // if(Identity.currentUser() !=null)
          // {
          //     window.document.title = "Eplacement";
          // }

          //, 1000);
        });
    }
  }

  //#region from old code
  impersonateUserWithDialog(event: any) {
    //todo
    // Dialog.modalLarge(
    //     'core/components/admin/partials/mdImpersonate/impersonateUser.html',
    //     'ImpersonateUserController', {},
    //     undefined,
    //     undefined, false);
  }

  openProfileDetails($event: any) {
    var data = {
      details: this.currentUser,
      message: "",
    };
    this.dialogWrapperService
      .modalLarge(
        "../shared/partials/md-menu-header/md-profile-details/md-profile-details.component.html",
        MdProfileDetailsComponent,
        data, //todo
        undefined,
        undefined,
        null,
        null,
        false,
        "slidingModal"
      )
      .subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          //console.log("close");
        } else {
          this.confirmationProductModalClose(result);
        }
      });
  }

  confirmationProductModalClose = (data: any) => {
    if (data.confirmation) {
    }
  };

  inspectCurrentOnlineUser(event: any) {
    //todo
    // event.preventDefault();
    // Dialog.modalLarge(
    //     'core/components/admin/partials/mdInspectCurrentOnlineUser/currentOnlineUser.html',
    //     'CurrentOnlineUserController', {},
    //     undefined,
    //     undefined, false);
  }

  appAdmin = function (event: any) {
    //todo
    // event.preventDefault();
    // Dialog.modalLarge(
    //     'core/components/admin/partials/mdAppAdmin/appAdmin.html',
    //     'AppAdminController', {},
    //     undefined,
    //     undefined, false);
  };

  SetDefaultUserRoles() {
    var userLoginObj = this.userTypeOptions.Items.filter(
      (e: { UserId: any }) => {
        if (e.UserId === this.currentUser?.id) return e;
        return null;
      }
    );
    if (userLoginObj !== null) {
      this.userProfileService.SetDefaultUserRoles(userLoginObj[0].UserLogin);
    }
  }

  ChangeUserLogin(UserLogin: any, UserId: any) {
    this.sessionStorageService.set("_USRLE", UserId);
    let ssoUserName = "adb2c_roles_management"; // configured in the API
    let loginData = {
      userName: ssoUserName,
      password: UserLogin,
    };

    this.authorizationService.login(loginData).subscribe((response: any) => {
      this.identity.storeAuthData(response);
      // populating the current user
      this.identity.populateCurrentUser(true).subscribe(() => {
        setTimeout(() => {
          if (this.identity.isAuthenticated()) {
            this.router.navigate(["/" + ROUTE_USER_TYPE_LISTING]);
          } else {
            this.message = "Invalid sign on !";
          }
        }, 2000);
      });
    });
  }

  logOut() {
    this.identityRolesService.logOut();
  }
  //#endregion

  //#region theme, no use for now
  public changeTheme(theme: { href: string; text: string }) {
    this.selectedTheme = theme;
    const themeEl: any = document.getElementById("theme");
    themeEl.href = theme.href;
  }

  public changeLanguage(item: any): void {
    this.customMsgService.language = item.localeId;
    this.setLocale(item.localeId);
  }

  public setLocale(locale: any): void {
    (this.intlService as CldrIntlService).localeId = locale;
  }

  public onButtonClick(): void {
    this.toggle.emit();
  }
  //#endregion
}
