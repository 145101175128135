<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [textContent]="question.displayText"
    [ngClass]="{ required: question.required && !question.readOnly }"
  ></label>
  <div *ngIf="!question.readOnly">
    <label class="radio-inline">
      <input
        style="margin-right: 5px"
        name="{{ question.key }}"
        type="radio"
        value="1"
        [(ngModel)]="question.answer"
        [disabled]="question.isDisabled!"
      />
      <span>Yes</span>
    </label>
    <label class="radio-inline">
      <input
        style="margin-right: 5px"
        name="{{ question.key }}"
        type="radio"
        value="2"
        [(ngModel)]="question.answer"
        [disabled]="question.isDisabled!"
      />
      <span>No</span>
    </label>
  </div>

  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()">{{ answerDisplayText(question) }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
