<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>

  <div *ngIf="!question.readOnly">
    <kendo-numerictextbox
      style="opacity: 1"
      id="{{ question.key }}_textBox"
      name="{{ question.key }}"
      type="text"
      [maxlength]="question.maxLength!"
      [(ngModel)]="QuestionAnswerFormatted"
      [disabled]="question.isDisabled!"
      [spinners]="false"
      [format]="'c0'"
      [decimals]="0"
      [min]="0"
      class="textAlignCenter"
    >
    </kendo-numerictextbox>
  </div>

  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()">{{ question.answer | ausCurrency }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
