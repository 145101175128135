import { Component, Input, OnInit } from "@angular/core";
import * as Enum from "./../../../../../../../framework/enum.shared";
import * as EnumHK from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-enums";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { Global } from "src/app/shared/global";
import { Culture, dateFormatter } from "src/app/shared/date-formatter";
import { componentHelper } from "src/app/models/new-product/utils/component.helper";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import {
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { proposalServiceNotifier } from "src/app/models/new-product/notifier/proposal-service-notifier";
import { MfHkBaseComponent } from "../mf-hk-base.component";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { MortgageFireHKTabSchema } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/schemas/mf-hk-tab-schema";
import { MfHkMessageService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-message.service";
import { Subscription } from "rxjs";
import { MortgageFireHongKongFieldValidationManager } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-field-validation.manager";
import { ProductService } from "src/app/services/new-product/base/product.service";
import { common } from "src/app/framework/utils/common";
@Component({
  selector: "mf-hk-premium-summary",
  templateUrl: "./mf-hk-premium-summary.component.html",
  styleUrls: ["./mf-hk-premium-summary.component.scss"],
})
export class MfHkPremiumSummaryComponent
  extends MfHkBaseComponent
  implements OnInit
{
  @Input() proposal!: MortgageFireHongKongProposal; //temp using @Input()

  quote!: Array<any>;
  coverages!: Array<any>;
  premiumDetails: any;
  premiumSummary: any;
  fireGrossPremium!: number;
  fireGST!: number;
  fireServiceLevy!: number;
  fireTotalCharge!: number;
  poiText!: string;
  //isCBGProduct!: boolean;
  isHaseProduct!: boolean;
  containEquipment!: boolean;
  isAccepted!: boolean;
  isCancellation!: boolean;
  underwriterReadOnly: boolean = false;
  isCMBProduct!: boolean;
  isWPBProduct!: boolean;
  isPremiumOverridingAllowed!: boolean;
  minPremium!: number;
  maxPremium!: number;
  minPremiumTax!: number;
  maxPremiumTax!: number;
  isIncomplete: boolean = false;
  isEndorse: boolean = false;
  isReferred!: boolean;
  isUnderwriter: boolean = false;
  isBroker: boolean = false;
  private msg_overridePremium_warning: string =
    "Are you sure you wish to Unlock? " +
    "Once unlocked, user is responsible to manually calculate all open fields. " +
    "There is no option to relock. Click OK to proceed, else please click Cancel.";
  resetIconIE: any;
  hasUpdated!: boolean;
  public editedDiv: any = [];
  subscription: Subscription;
  hideDiscountCode: boolean = true;

  constructor(
    public proposalService: MfHKProposalService,
    public identityRolesService: IdentityRolesService,
    private cdkDialogService: CdkDialogService,
    private mfHkMessageService: MfHkMessageService,
    public productService: ProductService
  ) {
    super();

    this.tabId = MortgageFireHKTabSchema.premiumSummary.index.toString();
    super.subscribeProposalLoadedNotifier(this.proposalSaved);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
    super.subscribeAnswerChangedNotifier(this.answerChanged);

    this.isUnderwriter = this.identityRolesService.isUnderwriter;
    this.isBroker = this.identityRolesService.isBroker;

    // subscribe to home component messages
    this.subscription = this.mfHkMessageService
      .getMessage()
      .subscribe((message) => {
        if (message) {
          this.resetCommission(message);
        }
      });
  }

  override ngOnInit(): void {
    this.initialize();
    componentHelper.notifyPageLoaded(
      "mf-sg-premium-summary.component",
      this.proposal
    );
  }

  /*
    Checked (Yes)	When Gross Premium can be edited, the commission and rate should not be edited
    not checked(No)	Premium Rate and Commission amount Can be edited and Gross premium cannot be edited
  */
  resetCommission(v: boolean) {
    const productFactory = this.productService.getHKProductFactory(
      this.proposal.productType
    );

    const can_toggle_manualUw = productFactory
      .premiumSummary()
      .Can_toggle_manualUw(
        this.identityRolesService,
        this.proposal!,
        this.proposalService
      );

    //edit mode only can update readOnly
    if (can_toggle_manualUw) {
      this.toggle_manualUw();
    }

    //reset if ManualUW changes
    /*  has to use the autoCorrect option to enforce the min-max range;  (especially if not lost focus)
        but this will cause the kendo-numerictextbox default to empty since it's not on min-max range,
        hence use final validation rule at: src\app\models\new-product\sme\mortgage-fire\hong-kong\base\mf-hk-field-validation.manager.ts
        https://github.com/telerik/kendo-angular/issues/1910
    */
    this.minPremium = this.getMinPremium();
    this.maxPremium = this.getMaxPremium();
  }

  toggle_manualUw() {
    const manual_uw = this.compareQuestionAnswer(
      this.proposal!.qsMaster.qs_master_manualUnderwritting!
    );

    if (manual_uw) {
      // this.proposal!.qsPremiumSummary.qs_master_brokerCommission.readOnly =
      //   true;
      this.proposal!.qsPremiumSummary.qs_master_brokerCommission.required =
        false;
      this.proposal!.qsPremiumSummary.qs_master_techRate.readOnly = true;
      this.proposal!.qsPremiumSummary.qs_master_techRate.required = false;
      //user could have choose non-manual-uw, save, then change to manual-uw; hence need to reset answer
      this.proposal!.qsPremiumSummary.qs_master_techRate.clearAnswer();
    } else {
      // this.proposal!.qsPremiumSummary.qs_master_brokerCommission.readOnly =
      //   false;
      this.proposal!.qsPremiumSummary.qs_master_brokerCommission.required =
        false;
      if (
        this.proposal.transType === Enum.TransType.END || //HE-256
        this.proposal.transType === Enum.TransType.CAN
      ) {
        this.proposal!.qsPremiumSummary.qs_master_techRate.readOnly = true;
      } else {
        this.proposal!.qsPremiumSummary.qs_master_techRate.readOnly = false;
      }

      //this.proposal!.qsPremiumSummary.qs_master_techRate.required = false;
    }
  }

  compareQuestionAnswer(ques: QuestionEntity) {
    return (
      ques!.answer == 1 || ques!.answer === "True" || ques!.answer === true
    );
  }

  canAnswerQuestion(ques: QuestionEntity) {
    return (
      this.compareQuestionAnswer(ques) &&
      this.identityRolesService.IsHASEUser &&
      (this.isCMBProduct || (this.isWPBProduct && this.isUnderwriter)) //HE-28
    );
  }

  canEditPremium(ques: QuestionEntity) {
    return (
      this.canAnswerQuestion(ques) &&
      this.proposalService.isFinancialEndorsementOrNbOrCan()
    );
  }
  
  private answerChanged = (kv: KeyValue<QuestionEntity>) => {
    const key = kv.key;
    const question = kv.value;
    switch (key) {
      case this.proposal.qsPremiumSummary.qs_master_manualReferral.key:
        this.qs_master_manualReferToUw_onChange();
        break;
      case this.proposal.qsMaster.qs_machinery_equipment!.key:
        this.proposalSaved(new KeyValue<Proposal>("", this.proposal));
        break;
      case this.proposal.qsPremiumSummary.qs_master_brokerCommission?.key:
      case this.proposal.qsMaster.qs_master_manualUnderwritting?.key:
      case this.proposal.qsMaster.qs_otherinfo_InsuranceCompany?.key:
        this.proposal.checkDiscountEligibility(key);
        break;
      default:
        break;
    }
  };
  
  private proposalSaved = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireHongKongProposal;
    // reset the underwrite read only flag

    this.underwriterReadOnly = false;
    this.isAccepted =
      this.proposal.proposalStatus === Enum.ProposalStatus.Accepted
        ? true
        : false;
    this.isCancellation =
      this.proposal.proposalStatus === Enum.ProposalStatus.Cancelled
        ? true
        : false;
    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;
    this.isReferred =
      this.proposal.proposalStatus === Enum.ProposalStatus.Referred
        ? true
        : false;

    if (this.isAccepted || this.isCancellation) {
      this.underwriterReadOnly = true;
    }

    /** DBS CR
     * To set the Gross Premium and Premium Rate fields under ‘Premium Summary’
     * page of IBG products in ePlacement to be editable for the non-standard cases to be manually keyed in ePlacement.
     */
    this.isPremiumOverridingAllowed =
      this.identityRolesService.IsHASEUser &&
      !this.proposal.refreshedProposalEntity.proposalReadOnly &&
      this.proposal.endorsementType != 20;

    this.mfHkMessageService.sendMessage("true"); //reset Commission editability

    this.quote = this.proposal.refreshedProposalEntity.quotes!;

    if (Global.isDefined(this.quote) && this.quote.length > 0) {
      // CBG only contains fire risk
      this.coverages = this.quote[0].coverages;
      if (this.coverages.length > 0) {
        this.containEquipment = this.checkEquipmentCoverage();
        this.premiumDetails = this.coverages[0].premiumDetails;
        this.premiumSummary = this.quote[0].premiumSummary;
        this.proposal.qsPremiumSummary.qs_master_commission.answer =
          this.premiumSummary.commission;
        this.proposal.qsPremiumSummary.qs_master_commissionGst.answer =
          this.premiumSummary.commissionTax; // commission on gst
        this.fireGrossPremium = this.premiumSummary.premium;
        this.fireGST = this.premiumSummary.premiumTax;
        this.fireTotalCharge = this.premiumSummary.totalPremium;
        this.fireServiceLevy = this.premiumSummary.fireServiceLevy;
      }
    }

    this.getPOIText();

    this.minPremium = this.getMinPremium();
    this.maxPremium = this.getMaxPremium();

    switch (this.proposal.transType) {
      case Enum.TransType.END:
        this.isEndorse = true;
        break;
      default:
    }
  };

  getMinPremium() {
    const productFactory = this.productService.getHKProductFactory(
      this.proposal.productType
    );

    return productFactory.premiumSummary().getMinPremium(this.proposal);
  }

  getMaxPremium() {
    if (
      //this.isCMBProduct && //HE-28
      this.isManualUW()
    ) {
      return MortgageFireHongKongFieldValidationManager.maxPremium;
    } else {
      return 100000000;
    }
  }


  getIndex = (index: number) => {
    return index < 1 ? 0 : index;
  };

  private checkEquipmentCoverage = () => {
    let isEquipmentCov: boolean = false;
    this.coverages.forEach((cov) => {
      if (
        cov.coverageProductMappingId !== "f2e78506-3774-4c7d-92d7-c628d50c31e9"
      ) {
        isEquipmentCov = true;
      }
    });

    return isEquipmentCov;
  };

  //HE-354: self-autoCorrect, not using built-in autoCorrect
  public autoCorrect(data: any): void {
    if (data < this.minPremium) {
      data = this.minPremium;
    }

    if (data > this.maxPremium) {
      data = this.maxPremium;
    }

    return data;
  }

  updatingHasePremium(data: any, field: any) {
    data = Global.isDefined(data) ? data : 0;

    if (Global.isDefined(data)) {
      if (Global.isDefined(this.quote) && this.quote.length > 0) {
        if (Global.isDefined(this.quote[0])) {
          if (Global.isDefined(this.quote[0].premiumSummary)) {
            if (field === "fireGrossPremium") {
              this.premiumSummary.premium = this.autoCorrect(data);

              this.fireGrossPremium = this.totalToDIsplay(
                data,
                "fireGrossPremium",
                this.maxPremium,
                this.minPremium
              );
            }
            // if (field === "fireGST") {
            //   this.premiumSummary.premiumTax = data;
            //   this.fireGST = this.totalToDIsplay(
            //     data,
            //     "fireGST",
            //     this.maxPremiumTax,
            //     this.minPremiumTax
            //   );
            // }
          }
        }
      }
    }
  }

  private totalToDIsplay = (
    data: any,
    field: any,
    maxAmount: any,
    minAmount: any
  ): number => {
    data = Global.isDefined(data) ? data : 0;
    let amount: any = 0;
    if (Global.isDefined(data)) {
      if (data > minAmount) {
        if (data > maxAmount) {
          amount = maxAmount;
        } else {
          amount = data;
        }
      } else {
        amount = minAmount;
      }
    }
    return amount;
  };

  private qs_master_manualReferToUw_onChange() {
    this.proposalService.fieldValidationManager.setManualReferralReasonRequires();
    this.proposalService.businessRulesManager.validateManualReferral();

    // Clear manual refer reason details if select 'No'
    if (
      this.proposal.qsPremiumSummary.qs_master_manualReferral.readAnswerAsNumeric() ===
      Enum.TrueFalseQuestionAnswer.No
    ) {
      this.proposal.qsPremiumSummary.qs_master_manualReferralReason.clearAnswer();
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription?.unsubscribe();

    super.unsubscribeRxjs();
  }

  private initialize() {
    this.proposal = this.proposalService.proposal;
    super.subscribeTabLoadRenderer();
    this.premiumDetailsCommissionValue();

    this.isWPBProduct =
      this.proposal.productType === EnumHK.MortgageFireHKProduct.HSWPB
        ? true
        : false;
    this.isCMBProduct =
      this.proposal.productType === EnumHK.MortgageFireHKProduct.HSCMB
        ? true
        : false;

    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;

    this.hideDiscountCode = 
      this.isWPBProduct === true
        ? false 
        : true;
  }

  premiumDetailsCommissionValue = (): void => {
    if (this.proposal) {
      switch (this.proposal.productType) {
        // case EnumSG.MortgageFireSgProduct.IBGMF:
        // case EnumSG.MortgageFireSgProduct.IBGMAC:
        //   this.proposal.qsPremiumSummary.qs_master_brokerCommission.answer = 25;
        //   break;
        default:
          this.proposal.qsPremiumSummary.qs_master_brokerCommission.answer = 36.4;
          //DBSEP-6364: if not manualUW, show as it is, which is 'unknown'
          if (this.isManualUW()) {
            this.proposal.qsPremiumSummary.qs_master_techRate.answer = 0;
          }
      }
    }
  };

  isManualUW() {
    return (
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === "True" ||
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === true
    );
  }

  getPOIText = () => {
    const poiStartDate = this.dateFormatting(
      this.proposal.qsMaster.qs_master_poi_startDate!.answer
    );
    const poiEndDate = this.dateFormatting(
      this.proposal.qsMaster.qs_master_poi_endDate!.answer
    );

    const poiText = "Period From " + poiStartDate + " To " + poiEndDate;

    this.poiText = poiText;
  };

  dateFormatting = (value: Date) => {
    return dateFormatter.format(value, Culture.enSg);
  };

  public allowPremiumToEdit = (): void => {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: "",
      message: this.msg_overridePremium_warning,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForPremiumOveride(result);
      }
    });
  };

  private confirmationModalCloseForPremiumOveride = (result: any): void => {
    if (result.status == "yes") {
      this.proposalService.proposal.auxiliary["isPremiumManualOverridden"] =
        true;
      this.isEndorse = true;
      if (this.identityRolesService.isOperationUnderwriter) {
        this.proposal.qsMaster.qs_master_manualUnderwritting!.answer = true;
        setTimeout(() => {
          proposalServiceNotifier.saved_notifier$.next(
            new KeyValue(this.proposal.proposalId!.toString(), this.proposal)
          );
        }, 1000);
      }
    }
  };

  updatingPremiumDetails = (
    coverageId: string,
    val: string,
    sectionType: any
  ): void => {
    const premiumData = this.proposal.refreshedProposalEntity.quotes!;
    const value: number = val ? parseFloat(val) : 0;

    if (premiumData.length > 0 && Global.isDefined(value)) {
      premiumData[0].premiumSummary.rate = 0;
      premiumData[0].premiumSummary.sumInsured = 0;
      premiumData[0].premiumSummary.premium = 0;
      premiumData[0].premiumSummary.premiumTax = 0;
      premiumData[0].premiumSummary.totalPremium = 0;
      premiumData[0].coverages?.forEach((coverage) => {
        premiumData[0].premiumSummary.rate =
          premiumData[0].premiumSummary.rate! + coverage!.premiumDetails!.rate!;
        premiumData[0].premiumSummary.sumInsured =
          premiumData[0]!.premiumSummary.sumInsured! +
          coverage.premiumDetails!.sumInsured!;
        premiumData[0].premiumSummary.premium =
          premiumData[0].premiumSummary.premium +
          coverage.premiumDetails!.premium!;
        premiumData[0].premiumSummary.premiumTax =
          premiumData[0].premiumSummary.premiumTax! +
          coverage.premiumDetails!.premiumTax!;
        premiumData[0].premiumSummary.totalPremium =
          premiumData[0].premiumSummary.totalPremium +
          coverage.premiumDetails!.totalPremium;

        if (coverage.coverageProductMappingId === coverageId) {
          let id: string = sectionType + "_" + coverageId;
          switch (sectionType) {
            case "rate":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.rate = parseFloat(value.toFixed(4));
              break;
            case "sumInsured":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.sumInsured = parseFloat(
                value.toFixed(2)
              );
              break;
            case "premium":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.premium = parseFloat(value.toFixed(2));
              coverage.premiumDetails!.premiumOverridden = parseFloat(
                value.toFixed(2)
              );
              break;
            case "premiumTax":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.premiumTax = parseFloat(
                value.toFixed(2)
              );
              break;
            case "totalPremium":
              this.editedDiv[id] = true;
              coverage.premiumDetails!.totalPremium = parseFloat(
                value.toFixed(2)
              );
              break;
            default:
          }
        }
      });
    }
  };

  public resetPremiumDetails = (coverageId: string, sectionType: any): void => {
    const premiumData = this.proposal.refreshedProposalEntity.quotes!;
    if (premiumData.length > 0) {
      premiumData[0].premiumSummary.rate = 0;
      premiumData[0].premiumSummary.sumInsured = 0;
      premiumData[0].premiumSummary.premium = 0;
      premiumData[0].premiumSummary.premiumTax = 0;
      premiumData[0].coverages?.forEach((coverage) => {
        premiumData[0].premiumSummary.sumInsured =
          premiumData[0].premiumSummary.sumInsured! +
          coverage.premiumDetails!.sumInsured!;
        premiumData[0].premiumSummary.premium =
          premiumData[0].premiumSummary.premium +
          coverage.premiumDetails!.premium;
        premiumData[0].premiumSummary.premiumTax =
          premiumData[0].premiumSummary.premiumTax! +
          coverage.premiumDetails!.premiumTax!;
        premiumData[0].premiumSummary.rate =
          premiumData[0].premiumSummary.totalPremium +
          coverage.premiumDetails!.totalPremium;

        if (coverage.coverageProductMappingId === coverageId) {
          let id: string = sectionType + "_" + coverageId;
          switch (sectionType) {
            case "rate":
              premiumData[0].premiumSummary.rate =
                premiumData[0].premiumSummary.rate -
                coverage.premiumDetails!.rate!;
              if (coverage.premiumDetails!.rate !== 0) {
                this.editedDiv[id] = true;
              }
              coverage.premiumDetails!.rate = 0;
              break;
            case "sumInsured":
              premiumData[0].premiumSummary.sumInsured =
                premiumData[0].premiumSummary.sumInsured -
                coverage.premiumDetails!.sumInsured!;
              if (coverage.premiumDetails!.sumInsured !== 0) {
                this.editedDiv[id] = true;
              }
              coverage.premiumDetails!.sumInsured = 0;
              break;
            case "premium":
              premiumData[0].premiumSummary.premium =
                premiumData[0].premiumSummary.premium -
                coverage.premiumDetails!.premium;
              if (coverage.premiumDetails!.premium !== 0) {
                this.editedDiv[id] = true;
              }
              coverage.premiumDetails!.premium = 0;
              break;
            case "premiumTax":
              premiumData[0].premiumSummary.premiumTax =
                premiumData[0].premiumSummary.premiumTax -
                coverage.premiumDetails!.premiumTax!;
              if (coverage.premiumDetails!.premiumTax !== 0) {
                this.editedDiv[id] = true;
              }
              coverage.premiumDetails!.premiumTax = 0;
              break;
            default:
          }
        }
      });
    }
  };
}
