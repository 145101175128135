<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>

  <div *ngIf="!question.readOnly">
    <kendo-dropdownlist
      id="{{ question.key }}_dropdown"
      name="{{ question.key }}"
      [kendoDropDownFilter]="{
        operator: 'contains'
      }"
      [data]="question.optionsValue"
      textField="key"
      valueField="value"
      [valuePrimitive]="true"
      [(ngModel)]="question.answer"
      (valueChange)="valueChange($event)"
    ></kendo-dropdownlist>
  </div>
  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()">{{ answerDisplayText(question) }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
