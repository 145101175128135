import { Component, Inject, OnInit, SimpleChange } from "@angular/core";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import { DIALOG_DATA } from "src/app/components/shared/partials/dialog/dialog-tokens";
import { clientViewDlgModelArgs } from "src/app/components/shared/partials/mdClient/client-view/client-view.component";
import {
  MessageBoard,
  MessageBoardModel,
} from "../message-forum/MessageBoardModel";
import * as Enum from "src/app/framework/enum.shared";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { ProposalEntity } from "../message-forum/message-forum.component";
import { MessageBoardService } from "src/app/services/message-board.service";
import { Global } from "src/app/shared/global";
import { MdValidationMessageComponent } from "../../sme/masterPolicy/md-validation-message/md-validation-message.component";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { FileUploadService } from "src/app/services/file-upload.service";
import {
  FileInfo,
  RemoveEvent,
  SuccessEvent,
  UploadEvent,
} from "@progress/kendo-angular-upload";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-message-board",
  templateUrl: "./message-board.component.html",
  styleUrls: ["./message-board.component.scss"],
})
export class MessageBoardComponent implements OnInit {
  model: MessageBoardModel = new MessageBoardModel();
  data!: any;

  ownerid: any;
  description = "Message Board File";
  fileType = Enum.fileType.MessageBoard;
  documentids = [];
  //DBSEP-3226 - INC21420894 - remove SMS and Email option and include no action required as per the business requirement
  options: KeyValue<string>[] = [
    {
      key: "Mail",
      value: "1",
    },
    {
      key: "No Action Required",
      value: "4",
    },
  ];
  selectedPrintType: any;
  isInvalidEmailAddress = false;
  showPrintOption = false;

  hasFormError = false;
  formErrors = "";
  showNotify = true;
  validationMessage = "";
  public myFiles: Array<FileInfo> = [];

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any,
    public identityRolesService: IdentityRolesService,
    private messageBoardService: MessageBoardService,
    private cdkDialogService: CdkDialogService,
    private fileUploadService: FileUploadService
  ) {
    if (this.inputData) {
      this.model.messageBoard.push(new MessageBoard());
      this.model.messageBoard[0].notifyUser = true;
      this.model.messageBoard[0].notifyOwnerOnly = true;
      this.model.messageBoard[0].isUnderwriterInternalComment = false;
      this.model.proposalId = this.inputData.proposalId;
      this.model.productId = this.inputData.productId;
      this.ownerid = this.inputData.proposalId;

      if (this.inputData.productId == "f64a7d46-18f4-421a-931e-43b7fc198cd4") {
        this.showNotify = false;
      }
      this.data = {
        proposalId: this.inputData.proposalId,
      };
    }
  }

  ngOnInit(): void {
    this.model.printCorrespondence = this.inputData.printCorrespondence;

    this.model.selectedPrintType = this.options.filter((item) => {
      return item.value == this.inputData.preferredDeliveryMethod;
    })[0];

    if (
      this.model.selectedPrintType != null &&
      this.model.selectedPrintType.value === "2"
    ) {
      this.model.printCorrespondence = this.inputData.emailId;
    } else if (
      this.model.selectedPrintType != null &&
      this.model.selectedPrintType.value === "5"
    ) {
      this.model.printCorrespondence = this.inputData.mobileNo;
    } else if (
      this.model.selectedPrintType != null &&
      this.model.selectedPrintType.value === "1"
    ) {
      this.model.printCorrespondence = this.inputData.contactInfo;
    } else if (
      this.model.selectedPrintType != null &&
      this.model.selectedPrintType.value === "4"
    ) {
      this.model.printCorrespondence = "";
    }
    if (this.model.selectedPrintType == null) {
      this.model.selectedPrintType = this.options[1];
    }

    //https://github.com/udos86/ng-dynamic-forms/issues/362
    //overhead for non-primitive
    this.selectedPrintType = this.model.selectedPrintType.value;

    this.model.isPasswordProtected = true;
    if (
      this.identityRolesService.isChecker ||
      this.identityRolesService.isMaker
    ) {
      this.showPrintOption = false;
    }

    setTimeout(function () {
      //todo
      //angular.element('#selectReprintTypeBoard').kendoDropDownList();
    }, 100);
  }

  public complete(): void {
    //console.log(`complete event`);
  }

  public error(e: ErrorEvent): void {
    //console.log(`error event: ${e.filename}`);
  }

  public remove(e: RemoveEvent): void {
    //console.log(`remove event: ${e.files[0].name}`);
    e.preventDefault(); //do our own way
    this.myFiles = [];
  }

  public success(e: SuccessEvent): void {
    //console.log(`success event ${e.files[0].name}`);
  }

  public upload(e: UploadEvent): void {
    //console.log(`upload event ${e.files[0].name}`);
    e.preventDefault(); //do our own way

    if (e.files.length > 0) {
      //from old ui: shared\uiControls\inputs\drFileUpload\FileUploadController.js
      const formData: FormData = new FormData();
      formData.append("description", this.description);
      formData.append("ownerid", this.ownerid);
      formData.append("type", this.fileType.toString());
      for (var i = 0; i < e.files.length; i++) {
        formData.append("file" + i, e.files[i].rawFile!);
      }

      this.fileUploadService.save(formData).subscribe((result: any) => {
        if (result && result.body) {
          if (result.body.StatusMessage == "success") {
            this.validationMessage = "Upload successful";
            this.myFiles = [];
            this.documentids = result.body.docIdList;
            if (result.body.docIdList.length > 0) {
              this.showHidePrintOption();
            }
          } else {
            this.validationMessage = "Upload failed";
            this.documentids = [];
          }
        }
      });
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    //ngOnChanges won't be called for updates on the array
    if (
      changes["documentids"] &&
      changes["documentids"].previousValue !=
        changes["documentids"].currentValue
    ) {
      // documentids prop changed
      if (changes["documentids"].currentValue.length > 0) {
        this.showHidePrintOption();
      }
    }
  }

  showHidePrintOption() {
    this.showPrintOption = true;
    if (
      this.identityRolesService.isChecker ||
      this.identityRolesService.isMaker
    ) {
      this.showPrintOption = false;
    }
  }

  validateEmail(email: string) {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    var valid = false;
    if (email !== "" && reg.test(email)) {
      this.isInvalidEmailAddress = false;
      valid = true;
      this.hasFormError = false;
      this.formErrors = "";
      this.model.printCorrespondence = email;
    } else {
      this.isInvalidEmailAddress = true;
      valid = false;
      this.hasFormError = true;
      this.formErrors = "Invalid Email Address!";
      //todo
      // angular.element('.alertLogin.invalidEmail').fadeIn(500);
      // setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  validateMobile(mobile: string) {
    var reg = /^\+?[0-9]+$/;
    var valid = false;
    if (
      mobile.length == 8 &&
      mobile !== "" &&
      reg.test(mobile) &&
      (mobile.toString()[0] == "9" || mobile.toString()[0] == "8")
    ) {
      this.isInvalidEmailAddress = false;
      valid = true;
      this.model.printCorrespondence = mobile;
      this.hasFormError = false;
      this.formErrors = "";
    } else {
      this.isInvalidEmailAddress = true;
      valid = false;
      this.hasFormError = true;
      this.formErrors = "Invalid Mobile Number!";
      //todo
      // angular.element('.alertLogin.invalidEmail').fadeIn(500);
      // setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  validateInput() {
    if (+this.model.selectedPrintType.value == 2)
      return this.validateEmail(this.data.emailId);
    else if (+this.model.selectedPrintType.value == 5)
      return this.validateMobile(this.data.mobileNo);
    else return true;
  }

  optionChange(event: any) {
    //https://github.com/udos86/ng-dynamic-forms/issues/362
    //overhead for non-primitive
    this.model.selectedPrintType = this.options.filter((item) => {
      return item.value == this.selectedPrintType;
    })[0];

    this.hasFormError = false;
    this.formErrors = "";
    if (+this.model.selectedPrintType.value == 2) {
      this.validateEmail(this.data.emailId);
      this.model.printCorrespondence = this.data.emailId;
    } else if (+this.model.selectedPrintType.value == 5) {
      this.validateMobile(this.data.mobileNo);
      this.model.printCorrespondence = this.data.mobileNo;
    } else if (+this.model.selectedPrintType.value == 1) {
      this.model.printCorrespondence = this.data.contactInfo;
    }
  }

  submit() {
    this.model.messageBoard[0].proposalId = this.data.proposalId;

    var serverModel = cloneDeep(this.model.messageBoard[0]); //UiManager.buildServerModel(this.model.messageBoard);
    serverModel.fileIds = this.documentids;
    if (Global.isUndefined(this.model.messageBoard[0].messageTitle))
      this.model.messageBoard[0].messageTitle = "";
    if (Global.isUndefined(this.model.messageBoard[0].messageContent))
      this.model.messageBoard[0].messageContent = "";

    if (serverModel.fileIds.length > 0) {
      const printType =
        +this.model.selectedPrintType.value == 4
          ? "No Print"
          : this.model.selectedPrintType.key;
      serverModel.messageContent += "\n" + "Print Type: " + printType;
    }

    if (
      (this.model.messageBoard[0].messageTitle != "" &&
        this.model.messageBoard[0].messageContent != "" &&
        this.model.messageBoard[0].folderTypeId == null &&
        this.validateInput()) ||
      (this.model.messageBoard[0].messageTitle != "" &&
        this.model.messageBoard[0].messageContent != "" &&
        this.model.messageBoard[0].folderTypeId != null &&
        this.model.messageBoard[0].documentTypeId != null &&
        this.validateInput())
    ) {
      this.messageBoardService.saveMessage(serverModel).subscribe(
        (model) => {
          var messageId = model.body.data;
          if (serverModel.fileIds.length > 0) {
            this.reprint(messageId);
          }
          this.getMessage(this.data.proposalId);
        },
        (result) => {
          this.hasFormError = true;
          this.formErrors = result.statusText;
        }
      );
    } else {
      var validateData = [];
      var validationMessage = " ";
      if (
        this.model.messageBoard[0].messageTitle == "" &&
        this.model.messageBoard[0].messageContent == ""
      )
        validationMessage = "<b>Please enter Title and Content</b>";
      else if (this.model.messageBoard[0].messageTitle == "")
        validationMessage = "<b>Please enter Title</b>";
      else if (this.model.messageBoard[0].messageContent == "")
        validationMessage = "<b>Please enter Content</b>";
      else if (
        +this.model.selectedPrintType.value == 2 &&
        !this.validateEmail(this.data.emailId)
      )
        validationMessage = "<b>Please enter valid Email</b>";
      else if (
        +this.model.selectedPrintType.value == 5 &&
        !this.validateMobile(this.data.mobileNo)
      )
        validationMessage = "<b>Please enter valid MobileNo</b>";

      validateData.push({
        text: validationMessage,
      });
      const v1DialogRef = this.cdkDialogService.open(
        MdValidationMessageComponent,
        {
          data: validateData,
        }
      );

      v1DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
        }
      });
    }
  }

  reprint(messageId: any) {
    var proposalEntity: ProposalEntity = new ProposalEntity();
    proposalEntity.proposalId = this.model.messageBoard[0].proposalId;
    proposalEntity.messageId = messageId;
    proposalEntity.printType = this.model.selectedPrintType.value;
    proposalEntity.printCorrespondence = this.model.printCorrespondence;
    proposalEntity.isReprint = false;
    proposalEntity.isSummary = false;
    proposalEntity.isManual = true;
    proposalEntity.isPasswordProtected = this.model.isPasswordProtected;

    this.messageBoardService.savePrintOptions(proposalEntity).subscribe(
      (model) => {
        var messageId = model.data;
        this.getMessage(this.data.proposalId);
      },
      (result) => {
        this.hasFormError = true;
        this.formErrors = result.statusText;
      }
    );
  }

  //While closing get the message history
  getMessage(proposalId: any) {
    this.messageBoardService
      .getMessage(proposalId)
      .subscribe((response: any) => {
        if (response) {
          setTimeout(() => {
            if (response.data != null && response.data.length > 0) {
              this.model.messageBoard = response.data;
              this.close("yes", this.model.messageBoard);
            } else {
              this.close("yes", this.model.messageBoard);
            }
          }, 100);
        }
      });
  }

  public close(status: any, messageBoard: any) {
    let savParamters: clientViewDlgModelArgs = {
      status: status,
      data: messageBoard,
    };
    this.dialogRef?.close(savParamters);
  }

  public getMessageAndClose(status: any) {
    if (status !== "yes") {
      this.getMessage(this.data.proposalId);
    }
  }
}
