import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { UserProfileService } from "src/app/services/user-profile.service";
import {
  ApiModel,
  PersonModel,
  SelectedProducts,
  SelectedRoles,
  UserDetailsModel,
  UserProfileModel,
} from "../../user-profile.model";
import * as constants from "../../user-profile.constant";
import { common } from "src/app/framework/utils/common";
import { sessionstorageEnum } from "src/app/framework/app.enums";

@Component({
  selector: "app-create-profile",
  templateUrl: "./create-profile.component.html",
  styleUrls: ["./create-profile.component.scss"],
})
export class CreateProfileComponent implements OnInit {
  @Input() isTab2: boolean = false;
  model = new ApiModel();
  userDetails = new UserDetailsModel();
  userProfile = new UserProfileModel();
  isUserAvailable = "";
  isUserExists = false;
  userLogin!: string;
  title!: string;
  serverErrorMsg!: string;
  public dob_max!: Date;
  @ViewChild("userForm", { static: false })
  private userForm!: UntypedFormGroup;
  public teamsData = new Array<{ text: string; value: number }>();
  public organizationsData = new Array<{ text: string; value: number }>();
  public productData = new Array<SelectedProducts>();
  public roleData = new Array<SelectedRoles>();
  isInvalidEmailAddress = false;
  isUserEmailIDExists = false;
  isEmailSameRoleExistEp2 = false;
  showNoProductsMessage = false;
  isUserEmailIDAvailable = "";
  isUserEmailRoleAlreadyPresent = "";
  userTypeOptions: any = [];
  public userType = new Array<{ text: string; value: number }>();
  public accessControlList = new Array<{ text: string; value: number }>();
  //personal details
  personalDetails = new PersonModel();
  public genderList = new Array<{ text: string; value: number }>();
  public statusList = new Array<{ text: string; value: number }>();
  public nationality = new Array<{ text: string; value: string }>();
  public noOfChilds = new Array<{ text: string; value: string }>();
  public countryData = new Array<{ text: string; value: string }>();

  constructor(
    private userProfileService: UserProfileService,
    public identityRolesService: IdentityRolesService
  ) {}
  ngOnInit(): void {
    this.dob_max = new Date();
    this.userDetails.userType = "";
    this.userDetails.receiveEmail = true;
    this.userDetails.active = true;
    if (this.identityRolesService.isThailandUser) this.title = "System";
    else this.title = "Products";
    this.getUserTeam();
    this.getAccessControlGroupsList();
    //personal details
    this.genderList = [
      { text: "Male", value: 1 },
      { text: "Female", value: 2 },
    ];
    this.statusList = [
      { text: "", value: 0 },
      { text: "Single", value: 1 },
      { text: "Married", value: 2 },
    ];
    this.loadNationality();
    this.loadChildData();
    //this.personalDetails.nationality = "112";//HE-75: Remove Nationality Defaulting to "Malaysia"
    this.setModeToDirectives();
  }

  setModeToDirectives() {
    //this.userProfile.person.mode = mode;
    this.userProfile.marketer = {
      marketerId: "",
      marketerCode: "",
      branchId: "",
      organisationByProducts: "",
      mode: "create",
    };
    //this.userProfile.userDetails.mode = "create";
    this.userProfile.underwriter = {
      organisationByProducts: "",
      meridianCode: "",
      geniusCode: "",
      mode: "create",
    };
  }

  saveData(): UserProfileModel {
    this.userDetails.productsByOrganisation = [];
    this.userDetails.productIds.forEach((pid) => {
      this.userDetails.productsByOrganisation.push(
        this.productData.filter((x) => x.value === pid)[0]
      );
    });
    //
    this.userDetails.roles = [];
    this.userDetails.roleIds.forEach((roleid) => {
      this.userDetails.roles.push(
        this.roleData.filter((x) => x.value == roleid.toString())[0]
      );
    });
    this.userProfile.userDetails = this.userDetails;
    this.userProfile.subUser = this.getSubUserType(
      this.userProfile.userDetails.userType
    );
    this.userProfile.person = this.personalDetails;
    this.userProfile.userType = this.userProfile.userDetails.userType;
    return this.userProfile;
  }

  getSubUserType(userType: number) {
    //subuser info is not required for agent,broker,client as there will not be any extra info for these
    // other than person details
    if (userType == 3) {
      return this.userProfile.underwriter;
    } else {
      //if (userType == 6) {
      return this.userProfile.marketer;
    }
  }

  getUserTeam(): void {
    this.userProfileService.getUserTeam().subscribe((result: any) => {
      result.items.forEach((item: any) => {
        this.teamsData.push({ text: item.o, value: item.i });
      });
    });
  }
  private getAccessControlGroupsList() {
    this.userProfileService.getAccessControlGroups().subscribe((result) => {
      this.accessControlList = result?.items;
      if (
        this.identityRolesService.isAdmin &&
        !this.identityRolesService.showInterfaceMaintenance
      ) {
        //FP007222 Additional Work - Restrict Underwriter For Honkong Admin
        if (!this.identityRolesService.isThailandUser) {
          this.userType = [
            { value: 2, text: "Broker" },
            { value: 3, text: "Underwriter" },
          ];
        } else {
          this.userType = [{ value: 2, text: "Broker" }];
        }
      } else {
        this.userType = [
          { value: 1, text: "System Maintenance" },
          { value: 2, text: "Broker" },
          { value: 3, text: "Underwriter" },
          //,{ value: "5", text: "Agent" }//DBS Admin Screen Enable Changes
          //,{ value: "6", text: "Marketer" }//DBS Admin Screen Enable Changes
        ];
      }
    });
  }
  public onChangeOrg(val?: any): void {
    this.userDetails.productIds = [];
    this.productData = [];
    this.userDetails.roleIds = [];
    this.roleData = [];
    if (this.userDetails.userOrganisation)
      this.getProductsByOrganisationAndCountry();
  }
  private getProductsByOrganisationAndCountry(): void {
    this.userProfileService
      .getProductsByOrganisationAndCountry(
        this.userDetails.userOrganisation,
        this.userDetails.userCountry
      )
      .subscribe((result: any) => {
        if (result.items?.length > 0) {
          this.showNoProductsMessage = false;
          result.items.forEach((item: any) => {
            let selectedProduct: SelectedProducts = {
              text: item.o,
              value: item.i,
            };
            this.productData.push(selectedProduct);
          });
        } else this.showNoProductsMessage = true;
      });
  }
  public onProductSelectionChange(val: any): void {
    this.userDetails.roleIds = [];
    this.roleData = [];
    if (this.userDetails.productIds?.length > 0) {
      this.getRoles();
    }
  }
  private getRoles(): void {
    this.userProfileService
      .getRoles(this.userDetails.productIds, this.userDetails.userType)
      .subscribe((result: any) => {
        result.items.forEach((item: any) => {
          let role: SelectedRoles = {
            text: item.o,
            value: item.i,
          };
          this.roleData.push(role);
        });
      });
  }
  getProductById(pid: any): void {}
  onSubmit(): void {}
  checkUser(): void {
    this.isUserAvailable = "";
    //By default userlogin will not be allowed to update. But Super Admin can update the UserLogin. So below check is for updating the userlogin by superadmin
    if (this.userLogin === this.userDetails.userName) {
      this.isUserAvailable = "";
      this.isUserExists = false;
      return;
    }
    //DBS Admin Screen Enable Changes
    if (!this.userDetails.userName) {
      this.isUserAvailable = "Username Empty";
    } else {
      this.userProfileService
        .checkUserLogin(this.userDetails.userName)
        .subscribe(
          (model: any) => {
            if (model.body.isAvailable == true) {
              this.isUserAvailable = "";
              this.isUserExists = false;
            } else {
              this.isUserAvailable = "Username Not Available";
              this.isUserExists = true;
            }
          },
          function (err: any) {
            console.log(err);
          }
        );
    }
  }
  onKeypressEvent(event: any) {
    if (event.charCode === 32) event.preventDefault();
  }
  //Load country based on the selected usertype
  private loadCountry(): void {
    this.countryData = [];
    //let userCountry = this.identityRolesService.isThailandUser ? "TH" : "SG";
    let userCountry = this.identityRolesService
      .getIdentity()
      .currentUser().CountryISO; //cater HK
    constants.countryData.forEach((item) => {
      if (!this.identityRolesService.showInterfaceMaintenance) {
        if (userCountry === item.value) {
          this.userDetails.displayUserCountry = item.text;
          this.userDetails.userCountry = item.value;
        }
      } else {
        this.countryData.push(item);
      }
    });
  }
  changeOrganizationList(): void {
    //DBS Admin Screen Enable Changes
    this.showNoProductsMessage = false;
    this.productData = [];
    this.userDetails.productIds = [];
    this.roleData = [];
    this.userDetails.roleIds = [];
    this.userDetails.userOrganisation = undefined;
    this.loadOrganization();
  }
  private clearData(): void {
    this.organizationsData = [];
    this.userDetails.userOrganisation = undefined;
    this.userDetails.userCountry = undefined;
    this.productData = [];
    this.userDetails.productIds = [];
    this.roleData = [];
    this.userDetails.roleIds = [];
  }
  public checkEmailIDInADB2C() {
    if (this.IsADB2CCountry()) {
      if (this.validateEmail(this.userDetails.email)) {
        this.isUserEmailIDAvailable = "";
        var B2CACCESS_TOKEN = sessionStorage.getItem(
          sessionstorageEnum.B2CAccessToken
        );
        this.userProfileService
          .CheckEmailIDInADB2C(this.userDetails.email, B2CACCESS_TOKEN)
          .subscribe((result: any) => {
            if (
              common.isDefined(result.body) &&
              common.isDefined(result.status) &&
              result.body.Error === "user not found/active!"
            ) {
              this.isInvalidEmailAddress = true;
              this.isUserEmailIDExists = true;
              this.isUserEmailIDAvailable =
                "EmailID is not registered in ADB2C";
              this.userDetails.isNotifyCreation = false;
            } else {
              this.userDetails.mobileNumber = result.body.mobile;
              if (result.body.mobile && result.body.mobile != "") {
                this.userDetails.isNotifyCreation = true;
              } else {
                this.userDetails.isNotifyCreation = false;
              }
              this.checkUserEmailID();
            }
          });
      }
    } else {
      this.isUserEmailIDExists = false;
      this.isUserEmailIDAvailable = "";
    }
  }
  private validateEmail(email: any): boolean {
    this.isUserEmailRoleAlreadyPresent = "";
    this.isInvalidEmailAddress = false;
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (reg.test(email)) {
      this.isInvalidEmailAddress = false;
      this.isUserEmailIDAvailable = "";
      this.isUserEmailIDExists = false;
      return true;
    } else {
      this.isInvalidEmailAddress = true;
      if (common.isDefined(email) && email.length != 0) {
        this.isUserEmailIDAvailable = "EmailID is not valid";
        this.isUserEmailIDExists = true;
      }
      return false;
    }
  }
  private checkUserEmailID() {
    if (this.IsADB2CCountry()) {
      var userEmailID = this.userDetails.email;
      var userOrganisationGroupID = this.userDetails.userType;
      if (userEmailID == "") {
        this.isUserEmailIDAvailable = "UserEmailID Empty";
      } else {
        if (common.isDefined(userEmailID) && userEmailID == "") {
          this.isUserEmailIDAvailable = "UserEmailID Empty";
        } else {
          if (
            common.isDefined(userOrganisationGroupID) &&
            userOrganisationGroupID != ""
          ) {
            this.validateEmailSameRoleExistEp2(
              userEmailID,
              userOrganisationGroupID
            );
          }
        }
      }
    } else {
      this.isUserEmailIDAvailable = "";
      this.isUserEmailIDExists = false;
    }
  }
  private IsADB2CCountry(): boolean {
    return true; //all Banca using ADB2C
  }
  private validateEmailSameRoleExistEp2(email: any, selectedRole: any) {
    this.isUserEmailRoleAlreadyPresent = "";
    this.userProfileService
      .GetUserRolesByEmail(email)
      .subscribe((usertypeList) => {
        this.userTypeOptions = usertypeList.body;

        if (
          common.isDefined(this.userTypeOptions) &&
          common.isDefined(this.userTypeOptions.Items) &&
          Array.isArray(this.userTypeOptions.Items) &&
          this.userTypeOptions.Items.length > 0
        ) {
          var isEmailSameRoleExistEp2 = this.userTypeOptions.Items.some(
            function (item: any) {
              return item.OrganisationGroupID == selectedRole;
            }
          );
        }
        this.isEmailSameRoleExistEp2 = isEmailSameRoleExistEp2;

        if (isEmailSameRoleExistEp2) {
          this.isUserEmailRoleAlreadyPresent =
            "Email Address is already present with the same User Type. Kindly update the existing user to assign new Role.";
        } else {
          this.isUserEmailRoleAlreadyPresent = "";
        }
      });
  }
  public changeUserType(val: any) {
    this.clearData();
    this.loadCountry();
    if (this.userDetails.email?.length > 0)
      this.validateEmailSameRoleExistEp2(
        this.userDetails.email,
        this.userDetails.userType
      );
    if (
      !this.identityRolesService.showInterfaceMaintenance &&
      this.userDetails.userType
    )
      this.loadOrganization();
  }
  private loadOrganization(): void {
    this.organizationsData = [];
    this.userProfileService
      .getOrganisationByGroupType(this.userDetails.userType)
      .subscribe((result) => {
        result.items.forEach((item: any) => {
          //For User Type "Broker" > Under Organization, keep HASE only
          if (this.userDetails.userType == 2) {
            if (String(item.o).toUpperCase() === "HASE") {
              this.organizationsData.push({ text: item.o, value: item.i });
            } else {
              //do nothing, dun insert
            }
          } else {
            this.organizationsData.push({ text: item.o, value: item.i });
          }
        });

        //HE-75: Keep Organization as a non-default field for both Underwriter and Broker User Type
        // if (this.userDetails.userType == 2 || this.userDetails.userType == 3) {
        //   this.userDetails.userOrganisation = this.organizationsData[0].value;
        //   this.onChangeOrg();
        // }
      });
  }
  //personal details
  //Load country based on the selected usertype
  private loadNationality(): void {
    constants.nationality.forEach((item) => {
      this.nationality.push(item);
    });
  }
  private loadChildData(): void {
    constants.personNoOfChildren.forEach((item) => {
      this.noOfChilds.push(item);
    });
  }

  private isDateTimeMinValue(value: any) {
    return value === "0001-01-01T00:00:00";
  }
  public validateFormData(): boolean {
    this.markFormGroupTouched(this.userForm);
    if (
      this.isUserEmailIDAvailable?.length === 0 &&
      this.isUserEmailRoleAlreadyPresent?.length === 0 &&
      this.isUserAvailable?.length === 0
    )
      return this.userForm.valid;
    else return false;
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((item: any) => {
      item.markAsTouched();
      if (item.controls) {
        this.markFormGroupTouched(item);
      }
    });
  }
  //this is to toggle disabled field based on active field
  public toggleDisabled(): void {
    if (this.userDetails.active) {
      this.userDetails.disabled = !this.userDetails.active;
    }
  }
  public getTitle(): string {
    return this.isTab2 ? "Additional Information" : "Profile";
  }
  public showServerValidation(msg: string): void {
    this.serverErrorMsg = msg;
  }

  protected dispUserCountry(): boolean {
    return (
      !this.identityRolesService.showInterfaceMaintenance &&
      common.isDefined(this.userDetails.displayUserCountry) &&
      this.userDetails.displayUserCountry !== ""
    );
  }
}
