import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IIdentity } from "src/app/framework/interface.shared";
import { common } from "src/app/framework/utils/common";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export class BaseComponent implements OnInit {
  public currentIdentity: IIdentity;

  constructor(
    public router: Router,
    public broadcastService: BroadcastService,
    public identityRolesService: IdentityRolesService
  ) {
    //when log-in, the sessionStorageService might not yet init-ed, hence needs to sub to a broadcast
    this.broadcastService.onAutheticated.subscribe((msg: any) => {
      this.currentIdentity = this.identityRolesService.getIdentity();
    });

    this.currentIdentity = this.identityRolesService.getIdentity();
    //when refresh
    if (common.isUndefinedOrNull(this.currentIdentity.role)) {
      this.identityRolesService.refreshCurrentUser();
    }
  }

  public ngOnInit(): void {}
}
