<style>
    @font-face {
  font-family: 'Publico';
  src: url(6cfb268eccb0db4fab2a92a6ce498729.ttf);
}

@font-face {
  font-family: 'Publico-Bold';
  src: url(dd53b6167ba53be12be444e84a884119.ttf);
}

body {
  /*padding-top: 60px;*/
  background: #FFFFFF;
  /*background: #DFE5E6;*/
  padding-bottom: 20px;
  font-family: 'Publico', 'Georgia';
}

/* Set padding to keep content from hitting the edges */
.body-content {
  /*padding-left: 40px;*/
  /*padding-right: 5px;*/
}

/* Override the default bootstrap behavior where horizontal description lists
   will truncate terms that are too long to fit in the left column
*/
.dl-horizontal dt {
  white-space: normal;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

/* start - additional for ng-Idle modal styles */
.modal-danger .modal-header {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  padding: 9px 15px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-warning .modal-header {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
  padding: 9px 15px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* end */
/*  additional for bootstrap */
.table-hover tbody tr:hover>td {
  cursor: pointer
}

/* end */
/*  start - to fixed bootstrap 3.3.2 modal-backdrop bug */
.modal-backdrop {
  bottom: 0;
  position: fixed;
}

/* end */
.has-error .form-control {
  /*box-shadow: 0 0 5px red;*/
}

/* start - angular file uploader */
.my-drop-zone {
  border: dotted 3px lightgray;
}

.nv-file-over {
  border: dotted 3px green;
}

/* Default class applied to drop zones on over */
.another-file-over-class {
  border: dotted 3px green;
}

/* end */
colgroup col.success {
  background-color: #dff0d8;
}

colgroup col.error {
  background-color: #f2dede;
}

colgroup col.warning {
  background-color: #fcf8e3;
}

colgroup col.info {
  background-color: #d9edf7;
}

h3 {
  background-color: #84BD00;
  font-family: 'Publico', bold;
  color: #ffffff;
  font-size: 15px;
  height: 25px;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-left: 20px;
  top: 0px;
}

th {
  border: thin;
}

h1 {
  background-color: #99cd00;
  font-family: 'Publico', bold;
  color: #ffffff;
  height: 35px;
  font-size: 21px;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  top: 0px;
}

.sectionheaderdiv {
  font-size: 20px;
  height: 55px;
}

.sectiontitle {
  font-family: 'Publico';
  color: #358ccb;
  font-size: 18px;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.tablecontent {
  font-family: 'Publico';
  color: #333333;
  font-size: 14px;
}

#custom-accord .panel-default {
  border-color: white !important;
  background-color: #fff;
  border: 0px none;
}

#custom-accord .panel-heading {
  background-color: white !important;
  border-color: white !important;
  background-image: none !important;
}

#custom-accord .panel-group .panel-heading+.panel-collapse .panel-body {
  border-top: 0px white !important;
}

#custom-accord2 .panel-default {
  border-color: white !important;
  background-color: #fff;
  border: 0px none;
}

#custom-accord2 .panel-heading {
  background-color: white !important;
  border-color: white !important;
  background-image: none !important;
}

#custom-accord2 .panel-group .panel-heading+.panel-collapse .panel-body {
  border-top: 0px white !important;
  margin-top: 0px;
}

#custom-accord3 .panel-default {
  border-color: white !important;
  background-color: #fff;
  border: 0px none;
}

#custom-accord3 .panel-heading {
  background-color: white !important;
  border-color: white !important;
  background-image: none !important;
}

#custom-accord3 .panel-group .panel-heading+.panel-collapse .panel-body {
  border-top: 0px white !important;
  margin-top: 0px;
}

#custom-accord4 .panel-default {
  border-color: white !important;
  background-color: #fff;
  border: 0px none;
}

#custom-accord4 .panel-heading {
  background-color: white !important;
  border-color: white !important;
  background-image: none !important;
}

#custom-accord4 .panel-group .panel-heading+.panel-collapse .panel-body {
  border-top: 0px white !important;
  margin-top: 0px;
}

.clearSection {
  position: inherit;
  padding: 0px;
}

.positionFixed {
  /*position: fixed;*/
  z-index: 1;
}

.loginBoxMargin {
  margin: 0px 0px 0px 0px;
}

.loginText {
  font-family: 'Publico';
  font-size: 14pt;
  color: #84BD00;
}

.loginSubHeader {
  font-family: 'Publico';
  font-size: 17pt;
  color: #84BD00;
}

.loginFooter {
  font-size: 12pt;
}

.loginHeader {
  margin: 10px 10px 0px 10px;
  background-color: #84BD00;
  font-family: 'Publico';
  font-size: 18pt;
  color: #FFFFFF;
  height: 50px;
  padding: 8px;
}

.loginTextBox {
  background-color: #FFFFFF !important;
  border-width: 0px !important;
  border-bottom-width: 1px !important;
  border-radius: 0px !important;
}

.header-ACE {
  background-color: white !important;
}

.text-primary-ACE {
  color: #84BD00;
}

.header-a-color {
  color: white !important;
}

.btn-icon-toggle {
  padding: 0;
  width: 55px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop50 {
  margin-top: 50px;
}

.headerbar-left {
  /*background-color: #84BD00;*/
}

.grid-headerbar {
  background-color: #84BD00;
}

.floatLeft {
  float: left !important;
}

.appName {
  font-family: 'Publico';
  font-size: 25pt;
  color: #333F48;
  position: relative;
  float: left;
}

.appSubtitle {
  /*font-family: gotham-book;*/
  font-size: 20pt;
  position: relative;
  float: left;
  /*top: 17px;*/
  margin-left: 5px;
  opacity: 0.4;
  font-weight: bold;
}

.profile-header {
  font-family: 'Publico';
  font-size: 14px;
  color: #333F48;
}

.user {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.profile-background {
  background-color: #333F48;
}

.ACEgreen {
  color: #84BD00 !important;
}

.gui-controls li.active>a .title {
  color: #84BD00;
}

.gui-icon {
  color: #FFFFFF;
  border: 0px;
}

#menubar:before {
  background: #333F48;
}

.menuText {
  font-family: 'Publico' !important;
  font-weight: normal;
  color: #FFFFFF !important;
  font-size: 14pt !important;
}

.menuSelectedText {
  color: #FFB617 !important;
  font-weight: normal;
}

.paddingTop3 {
  padding-top: 3px;
}

.gui-controls>li>a {
  padding: 9px 0 !important;
}

.gui-icon:hover {
  color: greenyellow !important;
}

.gui-icon-text:hover {
  color: greenyellow !important;
}

.menuFooter {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.menuFooterTitle {
  font-family: 'Publico';
  font-size: 14pt;
  color: #FFFFFF !important;
}

.menuFooterSubTitle {
  font-family: 'Publico';
  font-size: 7pt;
  color: #84BD00 !important;
}

.menuFooterBody {
  font-family: 'Publico';
  font-weight: normal;
  font-size: 8pt;
  color: #000000 !important;
  line-height: 10pt;
  white-space: normal !important;
  width: 230px;
}

.gui-controls a.expanded .gui-icon,
.gui-controls li.active .gui-icon,
.gui-controls li.active .gui-icon:hover {
  color: #84BD00;
}

.style-primary {
  border-color: #AFAFAF !important;
}

.style-Agency-primary {
  border-color: #150F96 !important;
}

.ACElightgrey {
  color: #DFE5E6;
}

.cardHeader {
  padding: 0 30px 0 0;
  font-family: 'Publico';
  font-size: 14pt;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.buttonColor {
  background-color: #84BD00 !important;
  border-color: #84BD00 !important;
}

.buttonText {
  font-family: Publico-Bold;
  font-size: 12px !important;
}

.loadingBarText {
  font-family: 'Publico';
  font-size: 12pt;
  color: #333F48;
}

.bulletinNoCardText {
  font-family: 'Publico';
  font-size: 12pt;
  color: #333F48;
}

.bulletinText1,
.drLocationTableText1 {
  font-family: 'Publico';
  font-size: 11pt;
  color: #004c97;
}

.bulletinText2,
.drLocationTableText2 {
  font-family: 'Publico';
  font-size: 11pt;
  color: #333F48;
}

.panel-heading .cardBackgroundColor h2 {
  margin-top: 0px;
}

.rightFloating {
  float: right;
  padding-right: 5px;
}

.bullentinEditTitle {
  font-family: 'Publico';
  font-size: 18pt;
  color: #333F48;
}

.bullentinEditTitleBorder {
  border-bottom: thin solid #EEEEEE;
}

.bullentinSearchTableTitle {
  font-family: 'Publico';
  font-size: 16px;
}

.drLocationTable {
  font-family: 'Publico';
  font-size: 10pt;
}

.drLocationTableNormalFont {
  font-family: 'Publico';
  font-size: 10pt;
  color: #FFF !important;
}

.bullentinSearchTableBody1 {
  font-family: 'Publico';
  font-size: 12pt;
  color: #004C97 !important;
}

.bullentinSearchTableBody2 {
  font-family: 'Publico';
  font-size: 12pt;
  color: #000000 !important;
}

.bulletinEditUnselectedTab .active {
  font-family: 'Publico';
  font-size: 14px;
  background-color: #FFFFFF;
}

.bulletinEditUnselectedTab,
.agencyProfileUnselectedTab,
.userProfileUnselectedTab {
  font-family: 'Publico';
  font-size: 14px;
  background-color: #6E27C5 !important;
  border: #6E27C5 solid thin;
  color: #000 !important;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
  border: 0px;
  border-bottom: none;
  background-color: transparent !important;
}

.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
  opacity: 1;
}

.nav-tabs.nav-justified>li>a {
  margin-right: 0;
  border-radius: 0px;
}

.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a:focus {
  border: none;
  color: #000 !important;
}

.nav-tabs>li>a {
  /*color: #FFFFFF !important;*/
  text-transform: none;
  border: none;
  border-bottom: none;
  opacity: 1;
  border-radius: 0px;
  margin-right: 0px !important;
  cursor: pointer;
}

.nav-tabs>li.active>a {
  /*color: #FFF !important;*/
  font-family: 'Publico';
  border: none;
  border-radius: 0px;
  font-size: 14px;
}

.nav-tabs {
  border-bottom: none;
}

.minWidth20 {
  min-width: 20%;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #dfe5e6 !important;
  border: solid 1px #ddd;
}

.table>tbody>tr>td.border-grid-color,
.table>thead>tr>th.border-grid-color {
  border: solid 1px #ddd !important;
}

.header-nav .header-nav-brand img {
  max-height: 64px !important;
}

.bannerMarginLeft25 {
  margin-left: -25px;
}

.floatRight {
  float: right !important;
}

.headerbar-left .header-nav {
  margin-left: 0px !important;
}

#main-menu>a {
  color: red !important;
  text-decoration: none;
}

.inputText {
  font-family: 'Publico';
  color: #333F48;
}

@media (max-width: 990px) {
  #headerTitle {
    display: none;
  }
}

.menuBackgroundColor {
  background-color: #4b4e53 !important;
}

.gui-icon-text:hover {
  background-color: #7ACB00 !important;
}

.fontSize14pt {
  font-size: 14pt;
}

.fontSize14px {
  font-size: 14px !important;
}

.fontSize15px {
  font-size: 15px !important;
}

.fontSize12px {
  font-size: 12px;
}

.fontSize16px {
  font-size: 16px;
}

.fontSize19pt {
  font-size: 19pt !important;
}

.card-head header {
  /* padding: 11px 10px !important;*/
  /*display: -webkit-box;*/
  line-height: 9px;
  /*-webkit-line-clamp: 1;
    -webkit-box-orient: vertical;*/
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}

.card-head .tools {
  padding-right: 0px !important;
  float: right;
  min-height: 32px;
}

.cardBorderRound {
  border-radius: 0px !important;
}

.cardNoBorder {
  border: none !important;
  -webkit-box-shadow: none;
}

.cardBodyBorder {
  background-color: #ffffff;
  color: #313534;
  border: #AFAFAF solid thin;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cardBodyBorderTopBottom {
  /*background-color: #ffffff;
    color: #313534;*/
  border-top: #000 solid thin;
  border-bottom: #000 solid thin;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card-mod {
  margin-bottom: 10px !important;
  /*margin-left: -11px;*/
}

.card-body {
  padding: 14px !important;
  ;
}

.card-body-mod {
  padding: 10px !important;
  ;
}

.userProfileCard-body {
  padding: 15px 0px 15px 0px !important;
}

.dropdown-menu {
  border-radius: 0px;
}

.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
  transition: linear opacity 1s;
  }
  .grecaptcha-badge.showgr {
    visibility: visible;
    opacity: 1;
    transition: linear opacity 1s;
    }


.header-nav-profile .dropdown>a:after {
  content: '' !important;
  ;
}

#loading-bar-spinner {
  display: none !important;
}

.form-modifier {
  padding: 0;
  height: 37px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-color: rgba(12, 12, 12, 0.12);
  background: transparent;
  color: #0c0c0c;
  font-size: 16px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group .form-control~label:after,
.form-group .form-control~.form-control-line:after {
  background-color: #84BD00 !important;
}

.loginTitle {
  font-family: 'Publico';
  font-size: 14pt;
}

#base {
  width: 100% !important;
}

#content {
  /*width: 99% !important;
    padding-top: 95px;*/
  /*padding-top: 128px;*/
  padding-top: 0px;
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
  }

  .mrListingSearch {
    width: 88% !important;
    margin-left: -6% !important;
  }
}

@media (min-width: 992px) {
  .container {
    width: 100%;
  }

  #content {
    padding-top: 128px;
  }

  .searchTitleWidth {
    min-width: inherit;
  }

  .carousel .next {
    right: -2px !important;
  }

  .resetIconIE {
    right: 50px !important;
  }
}

@media (max-width: 991px) {
  .form-group.marginBtm0 {
    margin-bottom: 16px !important;
  }

  .carousel .prev {
    margin-left: -60px !important;
  }

  .bulletinLeftPerc {
    left: 94% !important;
  }

  .carousel .next {
    right: -2px !important;
  }
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }

  .navbar-nav>li>a {
    padding-bottom: 10px;
  }

  /*.navbar-nav > li > a {
        padding-top: 10px;
    }

    .navbar-nav > li > a {
        line-height: 20px;
    }*/
}

@media (max-width: 625px) {
  .carousel .prev {
    margin-left: -40px !important;
  }

  .bulletinLeftPerc {
    left: 92% !important;
  }
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding-right: 10px;
  padding-left: 10px;
}

element.style {}

.offcanvas {
  z-index: 1200 !important;
}

.hideScroll {
  /*overflow:hidden;*/
}

.clearBoth {
  clear: both;
}

.panel-primary>.panel-heading,
.panel-footer {
  color: #fff;
  background-color: #84BD00;
  border-color: #84BD00;
}

.cardBackgroundColor {
  color: #fff !important;
  background-color: #84BD00 !important;
  border-color: #84BD00 !important;
}

.canvasWidth {
  width: 1080px;
}

.panel-primary {
  border-color: #84BD00 !important;
}

.ACEred {
  color: #E03632;
}

.ACEredBackgroundColor {
  background-color: #E03632;
}

.ACEblue {
  color: #004C97 !important;
}

.ACEblueBackgroundColor {
  background-color: #004C97 !important;
}

.ACETurquoise {
  color: #05C3DE !important;
}

.ACEgrey {
  color: #333F48 !important;
}

.ACElightgrey {
  color: #8D8D8D !important;
}

.searchBackgroundColor {
  background-color: #e8e8e8 !important;
}

.searchCardContent {
  height: 83px !important;
  cursor: pointer;
  margin-left: 10px;
  padding-left: 10px;
  border-left: #004C97 thick solid
}

.searchUserCardContent {
  height: 150px !important;
  cursor: pointer;
  margin-left: 10px;
  padding-left: 10px;
}

.padding5 {
  padding: 5px;
}

.form-control {
  padding-right: 30px;
}

.form-control+.fa {
  position: absolute;
  right: 0;
  padding: 8px 27px;
}

.center-block {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.input-group .icon-addon .form-control {
  border-radius: 0;
}

.icon-addon {
  position: relative;
  color: #555;
  display: block;
}

.icon-addon:after,
.icon-addon:before {
  display: table;
  content: " ";
}

.icon-addon:after {
  clear: both;
}

.icon-addon.addon-md .glyphicon,
.icon-addon .glyphicon,
.icon-addon.addon-md .fa,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px
}

.icon-addon.addon-lg .form-control {
  line-height: 1.33;
  /*height: 46px;*/
  font-size: 14px;
  padding: 10px 16px 10px 0px;
}

.icon-addon.addon-sm .form-control {
  height: 30px;
  padding: 5px 10px 5px 28px;
  font-size: 12px;
  line-height: 1.5;
}

.icon-addon.addon-lg .fa,
.icon-addon.addon-lg .glyphicon {
  font-size: 18px;
  margin-left: 0;
  left: 200px;
  top: -4px;
}

.icon-addon.addon-md .form-control,
.icon-addon .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}

.icon-addon.addon-sm .fa,
.icon-addon.addon-sm .glyphicon {
  margin-left: 0;
  font-size: 12px;
  left: 5px;
  top: -1px
}

.icon-addon .form-control:focus+.glyphicon,
.icon-addon:hover .glyphicon,
.icon-addon .form-control:focus+.fa,
.icon-addon:hover .fa {
  color: #2580db;
}

.form-group.marginBtm0 {
  margin-bottom: 0px;
}

.ACEwhite {
  color: #FFF;
}

.addAgencyBtn {
  background-color: #84BD00;
  border-color: #84BD00;
}

.marginTop-10 {
  margin-top: -13px;
}

.paddingTop8 {
  padding-top: 8px;
}

.marginRight-10 {
  margin: 0px 0 0px -10px;
}

.marginTop3 {
  margin-top: 3px;
}

.marginTop4 {
  margin-top: 3px;
}

.marginLeft-10 {
  margin-left: -10px;
}

.marginLeft20 {
  margin: 0px 0 0px 20px
}

.marginLeft5 {
  margin: 0px 0 0px 5px
}

.marginLeft25 {
  margin: 0px 0 0px 25px
}

.marginLeft30 {
  margin: 0px 0 0px 30px
}

.marginLeft-5 {
  margin: 0px 0px 0px -5px;
}

.marginLeft-5 {
  margin: 0px 0px 0px -5px;
}

.height100 {
  height: 100px !important;
}

.bullentinCardAlert {
  font-family: 'Publico' !important;
  font-size: 10pt !important;
}

.paddingLeft5 {
  padding-left: 5px !important;
}

.padding19 {
  padding: 19px;
}

.padding10 {
  padding: 10px;
}

.padding1 {
  padding: 1px !important;
}

.fontSize15em {
  font-size: 1.5em !important;
}

.padding6 {
  padding: 6px !important;
}

.bullentinCardTitle {
  font-family: 'Publico-Bold' !important;
  color: #F1352B !important;
  font-size: 12pt !important;
}

.bullentinCardBody {
  font-family: 'Publico' !important;
  color: #333F48 !important;
  font-size: 10pt !important;
  padding-top: 4px;
  display: -webkit-box;
  line-height: 1.8;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bullentinCardDate {
  font-family: 'Publico' !important;
  line-height: 12pt;
  color: #333F48 !important;
  font-size: 10pt !important;
}

.bullentinCardHeader {
  font-family: 'Publico' !important;
  font-weight: bold;
  color: #333F48 !important;
  font-size: 10pt !important;
}

.bullentinCardBorder {
  min-height: 87px;
  max-height: 87px;
}

.paddingLeft15 {
  padding-left: 15px !important;
}

.ACEorange {
  color: #FE5000;
}

/*breadcrumb*/
.breadcrumbBody {
  text-align: center;
}

.progress-meter {
  padding: 0;
}

.breadcrumText {
  font-family: 'Publico' !important;
  font-weight: normal;
  font-size: 10pt !important;
}

ol.progress-meter {
  padding-bottom: 9.5px;
  list-style-type: none;
  counter-reset: flag;
}

ol.progress-meter li {
  display: inline-block;
  text-align: center;
  text-indent: -19px;
  height: 36px;
  width: 22%;
  font-size: 12px;
  border-top-width: 4px;
  border-top-style: solid;
}

ol.progress-meter li:before {
  position: relative;
  float: left;
  text-indent: 0;
  left: -webkit-calc(50% - 9.5px);
  left: -moz-calc(50% - 9.5px);
  left: -ms-calc(50% - 9.5px);
  left: -o-calc(50% - 9.5px);
  left: calc(50% - 9.5px);
}

ol.progress-meter li.done {
  font-size: 12px;
}

.done {
  color: red;
}

ol.progress-meter li.done:before,
ol.progress-meter li.todo:before {
  content: counter(flag);
  counter-increment: flag;
  height: 50px;
  width: 50px;
  line-height: 40.85px;
  top: -57.175px;
  border: none;
  border-radius: 50px;
  font-size: 14pt;
  margin-left: -18px;
}

ol.progress-meter li.done:after {
  height: 50px;
  width: 50px;
  line-height: 21.85px;
  top: -12.175px;
  border: none;
  border-radius: 50px;
}

ol.progress-meter li.todo {
  color: #DFE5E6 !important;
}

ol.progress-meter li.done {
  color: #84BD00 !important;
  border-top-color: #84BD00;
  padding-top: 30px;
}

ol.progress-meter li.done:before {
  color: #000000;
  background-color: #FFFFFF;
  border: #84BD00 solid thick;
}

ol.progress-meter li.todo:after {
  height: 50px;
  width: 50px;
  line-height: 21.85px;
  top: -12.175px;
  border: none;
  border-radius: 50px;
}

ol.progress-meter li.todo {
  color: #DFE5E6;
  border-top-color: #DFE5E6;
  padding-top: 30px;
}

ol.progress-meter li.todo:before {
  color: #000000;
  background-color: #FFFFFF;
  border: #DFE5E6 solid thick;
}

.ACEgreen-background {
  background-color: #7ACB00 !important;
}

.ACEgrey-background {
  background-color: #333F48 !important;
}

.ACEgrey-grid-header-background {
  background-color: #7A8086 !important;
}

.ACElightgrey-background {
  background-color: #DFE5E6 !important;
}

.ACEorange-background {
  /*background-color: #FE5000 !important;*/
}

.ACElightgrey-border {
  background-color: white !important;
  border-width: 1px !important;
  border-color: darkgray !important;
  border: solid;
}

.ACEgray03-background {
  background-color: #EFEFEF !important;
}

.ACEwhite-background {
  background-color: #FFFFFF !important;
}

.searchCardContent:hover {
  background-color: #DFE5E6;
}

.searchUserProfileCard:focus,
.searchUserProfileCard:hover {
  color: #F1352B;
}

.searchUserProfileCard:hover span#userLogin,
.searchUserProfileCard:active span#userLogin,
.searchUserProfileCard:hover div#NagvigateRight,
.searchUserProfileCard:hover div#userAddress,
.searchUserProfileCard:hover div#userEmail,
.searchUserProfileCard:hover div#userAddressText,
.searchUserProfileCard:hover div#userEmailText,
.searchUserProfileCard:hover div#orgNameText {
  color: #F1352B !important;
}

.searchUserProfileCard:hover>div.searchUserCardHeader {
  background-color: #F1352B !important;
}

.bulletinLeftPerc {
  left: 96%;
}

/*carousal*/
.carousel {
  position: relative;
  min-height: 20px;
  height: auto !important;
  height: 20px
}

.carousel .next,
.carousel .prev {
  display: none;
  width: 56px;
  height: 56px;
  position: absolute;
  bottom: 20px;
  margin-top: -28px;
  z-index: 1000;
  cursor: pointer;
}

.carousel .prev {
  margin-left: -80px
}

/*.carousel .next { margin-right:-80px }*/
.carousel li {
  display: none;
}

.carousel li img {
  width: 100%;
  height: auto;
}

.paging {
  position: absolute;
  z-index: 9998;
}

.paging>a {
  display: block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  float: left
}

/*.badge { display:block; width:104px; height:104px; background:url(images/badge.png) 0 0 no-repeat; z-index:9000; position:absolute; top:-3px; left:-3px; }*/
img {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  -o-user-select: none;
  user-select: none;
}

input {
  font-family: 'Publico' !important;
}

.paddingTop5 {
  padding-top: 5px;
}

.paddingBottom5 {
  padding-bottom: 5px;
}

button:focus {
  outline: none !important;
}

button:hover,
.k-grid-button:hover {
  /*background-color: #FFB81C;*/
  color: #FFF;
  background-color: #FF6600;
}

input:focus,
select:focus,
textarea:focus,
input.k-textbox:focus,
input.k-input:focus,
input.loginTextBox:focus {
  /*outline-: solid #84BD00;*/
  outline: none;
  border-left: 2px #F1352B solid !important;
}

.btn:hover {
  /*color: transparent !important;*/
}

.btnHover:hover,
.btnHover:visited,
.btnHover:focus {
  outline: 0 !important;
  background-color: transparent !important;
}

.addbtnHover:hover,
.addbtnHover:visited,
.addbtnHover:focus {
  outline: 0 !important;
  background-color: #84BD00 !important;
}

.btnHoverWhiteBg:hover,
.btnHoverWhiteBg:visited,
.btnHoverWhiteBg:focus,
.btnHoverWhiteBg>span:hover {
  outline: 0 !important;
  background-color: #fff !important;
  /*border-color: #ccc !important;*/
  color: #FFFFFF !important;
}

.noCursor {
  cursor: none !important;
}

table .cursorNormal>td {
  cursor: default !important;
}

.informationText12px {
  font-size: 12px;
}

.k-textbox {
  /*border-width: 0px !important;
    border-bottom-width: 2px !important;
    border-radius: 0px !important;
    border-color: #84BD00 !important;*/
}

.k-textboxBorderRadius {
  border-radius: 6px !important;
}

.padding0 {
  padding: 0px !important;
}

.offcanvas-head {
  padding: 0px 15px;
}

.modal-footer {
  border-top: none;
}

.marginLeft15 {
  margin-left: 15px;
}

.marginRight20 {
  margin-right: 20px;
}

.marginLeftRight20 {
  margin: 0 20px 0 20px;
}

.paddingTop20 {
  padding-top: 20px;
}

.marginLeftRight10 {
  margin: 0px 10px 0px 10px;
}

.searchTable>tbody>tr>td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}

.marginLeft10 {
  margin-left: 10px !important;
}

.searchTable {
  width: 98% !important;
}

a[data-toggle="tab"] {
  color: #FFF;
}

.ACEgray01 {
  background-color: #A5ACB0 !important;
}

.userProfileSearchText1 {
  font-family: 'Publico';
  font-size: 14pt;
  margin-bottom: 10px;
}

.userProfileSearchText2 {
  font-family: 'Publico';
  font-size: 14pt;
  font-weight: bold;
}

.padding15 {
  padding: 15px;
}

.padding8 {
  padding: 8px;
}

.paddingTop25 {
  padding-top: 25px;
}

.marginRight25 {
  margin-right: 25px
}

.width90 {
  width: 90%
}

[class^="tabNav"],
[class*="tabNav"] {
  display: inline-block;
  float: inherit;
}

.textAlignCenter {
  text-align: center;
}

@media (max-width: 1144px) {
  /** cxvinj - below line of code is commented because it hides few of the buttons if the window width is > 1000, in sliding bar.js file the width is handled dynamically */
  /* .canvasWidth {
        width: 880px !important;
    }*/
}

.cardHeadtitle {
  line-height: 20px;
  padding: 5px 5px 0px 10px;
}

@media (min-width: 768px) {
  .searchUserCardContent {
    height: 100px !important;
  }
}

@media (max-width: 767px) {
  .searchUserCardContent {
    height: 95px !important;
  }

  #content {
    padding-top: 64px;
  }

  .minHeight40x {
    min-height: 30px !important;
  }
}

@media (min-width: 911px) {
  .searchUserCardContent {
    height: 80px !important;
  }
}

.minHeight40x {
  min-height: 40px;
}

.lblColorRed {
  color: red;
}

.lblColorBlack {
  color: black;
}

.lblColorGreen {
  color: #84BD00;
}

.borderLeftRight {
  border-left: #DFE5E6 solid thin;
  border-right: #DFE5E6 solid thin;
}

.borderRight {
  border-right: #DFE5E6 solid thin;
}

.zindex999 {
  z-index: 999;
}

.marginLeftTop10px {
  margin: 10px 0 0 10px;
}

.marginLeftTopSlidingWindow {
  margin: 3px 0 0 10px;
}

.cursorPointer {
  cursor: pointer;
}

#bulletinNext:hover *,
#bulletinPrev:hover * {
  background-color: #A5ACB0 !important;
  color: white;
  border-radius: 5px;
}

@media (min-width: 991px) {
  .carousel .prev {
    left: 75px !important;
  }
}

@media (max-width: 769px) {
  .bulletinMarginTop {
    margin-top: 8px !important;
  }
}

@media (max-width: 625px) {
  .carousel .prev {
    left: 35px !important;
  }
}

@media (max-width: 870px) {
  .bulletinLeftPerc {
    left: 93% !important;
  }
}

.paddingLeft3 {
  padding-left: 3px;
}

.card-head {
  /*min-height: 0px !important;*/
  line-height: 30px;
  min-height: 30px;
}

.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

::-webkit-scrollbar {
  width: 15px;
}

/* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track {
  background-color: #DFE5E6;
}

/* the new scrollbar will have a flat appearance with the set background color */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

/* this will style the thumb, ignoring the track */
::-webkit-scrollbar-button {
  background-color: #7A8086;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */
::-webkit-scrollbar-corner {
  background-color: black;
}

/* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
.paddingRight10 {
  padding-right: 10px;
}

.borderNone {
  border: none !important;
}

.tab-content {
  background-color: #fff;
}

.errorBorderRed {
  border: red solid thin !important;
  color: red !important;
}

.paddingBottom15 {
  padding-bottom: 15px;
}

.textDecoUnderline {
  text-decoration: underline;
}

.border-lightgrey {
  border: #DFE5E6 solid thin;
}

.marginRight10 {
  margin-right: 10px !important;
}

.marginRight5 {
  margin-right: 5px !important;
}

.paddingTop15 {
  padding-top: 15px;
}

/*scrollable tabmenu*/
.horizontal-scrollable-tabs {
  min-height: 39px;
}

.horizontal-scrollable-tabs .arrow-right {
  float: right;
}

.horizontal-scrollable-tabs .arrow-left {
  float: left;
}

/* Customize your arrows here */
/*.horizontal-scrollable-tabs .scroller {
    font-size: 18px;
    color: red;
    padding: 7px 10px;
    display: none;
}

.horizontal-scrollable-tabs .scroller.disabled {
    color: gray;
    display: none;
}*/
.horizontal-scrollable-tabs .horizontal-tabs .nav-tabs-horizontal {
  overflow-x: hidden;
  display: -webkit-box;
  display: -moz-box;
}

/* As it has a mobile focus the scrollbar is removed */
.horizontal-scrollable-tabs .horizontal-tabs .nav-tabs-horizontal::-webkit-scrollbar {
  width: 0 !important;
}

/* It's good to add a min-width so that the items have the same width */
.horizontal-scrollable-tabs .horizontal-tabs .nav-tabs-horizontal>li {
  float: none;
  min-width: 39px;
  text-align: center;
}

.nav-tabs-horizontal img {
  height: 30px;
}

.nav-tabs-horizontal {
  border-bottom: 0px;
}

a {
  border: none !important;
}

.selected {
  background-color: #FF6600;
  color: #FFF !important;
}

.campaign-selected {
  background-color: #FF6600;
  color: #FFF !important;
}

ol {
  counter-reset: li;
  /* Initiate a counter */
  margin-left: 0;
  /* Remove the default left margin */
  padding-left: 0;
  /* Remove the default left padding */
}

ol>li {
  position: relative;
  /* Create a positioning context */
  margin: 0 0 6px 2em;
  /* Give each list item a left margin to make room for the numbers */
  padding: 4px 8px;
  /* Add some spacing around the content */
  list-style: none;
  /* Disable the normal item numbering */
}

ol>li:before {
  content: counter(li);
  /* Use the counter as content */
  counter-increment: li;
  /* Increment the counter by 1 */
  /* Position and style the number */
  position: absolute;
  left: -2em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 2em;
  /* Some space between the number and the content in browsers that support
       generated content but not positioning it (Camino 2 is one example) */
  margin-right: 8px;
  padding: 4px;
  text-align: center;
}

li ol,
li ul {
  margin-top: 6px;
}

ol ol li:last-child {
  margin-bottom: 0;
}

.ULnoStyle {
  list-style-type: none;
}

.marginTop10 {
  margin-top: 10px;
}

.whiteColorFont {
  color: #FFFFFF !important;
}

.marginTop5 {
  margin-top: 5px;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.paddingBottom4 {
  padding-bottom: 4px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

#loginBackground {
  background-color: white;
  padding-bottom: 15px;
}

#footerLogin {
  bottom: 0;
  margin-top: 60px;
}

.img-backdrop img {
  float: left;
  margin: 30px;
  min-width: 0;
  min-height: 0;
}

@media (max-height: 700px) and (min-height: 500px) {
  section.section-account .img-backdrop img {
    height: auto;
    -webkit-animation: backdrop-animation 0.8s ease-out;
    -o-animation: backdrop-animation 0.8s ease-out;
    animation: backdrop-animation 0.8s ease-out;
    -webkit-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -moz-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -ms-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -o-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    animation: backdrop-animation 0.8s ease-out 1s ease-in;
  }
}

@media (max-height: 499px) {
  section.section-account .img-backdrop img {
    height: 0;
  }
}

.bulletinPrev {
  border-radius: 5px;
  width: 25px;
  float: left;
  height: 110px;
  margin-top: 10px;
}

.bulletinPrevIcon {
  height: 110px;
  margin-left: -1px;
  font-weight: bolder;
  padding: 40px 0px
}

.bulletinNext {
  border-radius: 5px;
  background-color: black;
  width: 25px;
  float: right;
  height: 110px;
  margin-top: 10px;
}

.bulletinNextIcon {
  height: 110px;
  margin-left: -1px;
  font-weight: bolder;
  padding: 40px 0;
}

.dashboardCardMaxHeight {
  max-height: 360px;
}

.horizontal-tabs>.nav-tabs>li>a {
  color: #ffffff !important;
}

.horizontal-tabs>.nav-tabs>li.active>a {
  background-color: #84BD00 !important;
}

/*.bullentinCardBody  p {*/
/*white-space: nowrap;*/
/*overflow: hidden;*/
/*text-overflow: ellipsis;*/
/*}*/
.bullentinCardBody p {
  display: -webkit-box;
  line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bulletin li {
  max-height: 110px;
}

.bulletin ul {
  max-height: 108px !important;
  min-height: 108px !important;
}

.text-primary-Title {
  color: #4AC2DB !important;
}

.alternativeRowColor tr:nth-child(odd) {
  background-color: #fff !important;
}

.alternativeRowColor tr:nth-child(even) {
  background-color: #E6EAEB !important;
}

.panel-postedDate {
  padding: 9px 15px;
}

.top10AgenciesPanelHeading {
  color: #fff;
  background-color: #84BD00;
  border-color: #84BD00;
}

.ACEPink {
  color: #E6449A !important;
}

.ACEBlue {
  color: #42C4DE !important;
}

.ACEBlueButtonColor {
  background-color: #42C4DE !important;
  border-color: #42C4DE !important;
}

.agencySearchBorder {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 0 0 0;
}

.ACE-theme {
  background-color: #42C4DE;
  border-color: #42C4DE;
  color: #FFF;
}

.buttonRed {
  background-color: #E03632 !important;
  border-color: #E03632 !important;
  ;
  color: #FFF;
  font-weight: bold;
}

.buttonGrey {
  background-color: #15CB00;
  border-color: #15CB00;
  color: #FFF;
  font-weight: bold;
}

.ACE-panel-border {
  border: 2px solid #42C4DE;
  border-top: none;
}

#tblSearchSoaDetails>thead>tr>th {
  background-color: #84BD00;
  color: #FFFFFF;
}

#tblSearchSoaDetailsThai>thead>tr>th {
  background-color: #84BD00;
  color: #FFFFFF;
}

.salesManagementTableAlternateRowColor {
  background-color: #E6EAEB !important;
}

.regionTbl>thead>tr>th {
  /*background-color: #84BD00;
    color: #FFFFFF;*/
}

.regionCentralTbl>thead>tr>th {
  background-color: #84BD00;
  color: #FFFFFF;
}

.allRegionTbl>thead>tr>th {
  background-color: #84BD00;
  color: #FFFFFF;
}

.tbl4>thead>tr>th {
  background-color: #84BD00;
  color: #FFFFFF;
}

.ACEwhite-background-blackfont {
  background-color: white !important;
  color: black !important;
}

.headingIntermediary {
  font-size: 15pt;
  width: 15%;
  color: #84BD00;
  text-align: left;
  margin-left: 5px;
}

.headingCampaign {
  font-size: 15pt;
  width: 25%;
  color: #84BD00;
  text-align: left;
  margin-left: 5px;
}

.headingUserProfile {
  font-size: 15pt;
  width: 9%;
  color: #84BD00;
  text-align: left;
  margin-left: 5px;
}

.floatingLeft {
  margin: 0 12px;
  padding-left: 0;
  list-style: none;
}

.floatingLeft img {
  width: 80%;
}

.floatingLeft div {
  float: left;
  clear: none;
}

.ACEGray {
  color: #A5ACB0;
}

.intermediaryImage {
  background: url(78ea921f03b10b49b164309d5c81812b.png) no-repeat 6px center;
  position: absolute;
  bottom: 0;
  height: 175px;
  left: 0px;
  width: 100%;
  z-index: 1;
  text-indent: -9999px;
}

.commandButton {
  background-color: #a5acb0;
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: 3px;
  padding-top: 4px;
}

.commandButtonAccept {
  background-image: url(7dbf8c3654b80316372e120cf6a04e51.gif);
}

.commandButtonExit {
  background-image: url(5ee55a9a8239b7811c39a6e5a0ef0be4.gif);
}

.commandButtonSave {
  background-image: url(025eb3589e476a1a2cc5fd4a324fc07f.gif);
}

.commandButtonSubmit {
  background-image: url(025eb3589e476a1a2cc5fd4a324fc07f.gif);
}

.commandButtonCalc {
  background-image: url(36e0f42037a8b8157da9f14d01f62953.gif);
}

.commandButtonApprove {
  background-image: url(36e0f42037a8b8157da9f14d01f62953.gif);
}

.commandButtonDecline {
  background-image: url(4aad14e9ce64638745e001efa81cd7a7.gif);
}

.commandButtonDownload {
  background-image: url(7c04cf834c5c71aa4b64ea333bba058c.gif);
}

.commandButtonRedundant {
  background-image: url(19dc4730dd80d94649618c61fbbfa157.gif);
}

.commandButtonOpen {
  background-image: url(36e0f42037a8b8157da9f14d01f62953.gif);
}

.commandButtonEmail {
  background-image: url(36e0f42037a8b8157da9f14d01f62953.gif);
}

.close-image-grid {
  float: left;
  font-size: 25px;
  line-height: 2 !important;
  padding-left: 4px;
}

.border-grid-color {
  border-color: #ddd !important;
}

[required] {
  box-shadow: none;
}

.multicolumnGrid {
  display: block;
  height: 800px;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 500px;
}

.multiColumnGridWidth {
  min-width: 80px;
  border: 1px solid #ddd;
  text-align: right;
  background-color: #84BD00;
}

.fontBold {
  font-family: Publico-Bold;
  font-weight: bold;
}

.required:after {
  color: #E03632;
  content: '\00a0 *';
  display: inline;
}

.requiredFloatRight:after {
  color: #E03632;
  content: '\00a0 * \00a0 ';
  display: inline;
  float: right;
}

/*th, td { white-space: nowrap; }*/
div.dataTables_wrapper {
  width: 975px;
  margin: 0 auto;
}

/*.dataTables_wrapper{*/
/*width:100% !important;*/
/*}*/
.multiColumnDataTable1_wrapper {
  width: 100%
}

/*#multiColumnDataTable1 tr td:nth-child(4), td:nth-child(7) {*/
/*border-right: 2px solid green;*/
/*}*/
/*.multiColumnDataNew tr td:nth-child(4), td:nth-child(2),*/
/*td:nth-child(3), td:nth-child(4), td:nth-child(5),*/
/*td:nth-child(6) , td:nth-child(7){*/
/*border-right: none !important;*/
/*}*/
/*.multiColumnDataNew tr  td:nth-child(6), td:nth-child(11) {*/
/*border-right: 4px solid red !important;*/
/*}*/
/*
.multiColumnDataNew {
    border-right: 4px solid red !important;
}*/
.tabMenuArrows {
  font-size: 25pt !important;
  font-weight: bold !important;
  margin-top: -7px !important;
}

.fontSize20pt {
  font-size: 20pt !important;
  font-weight: bold !important;
}

.fontSize10pt {
  font-size: 10pt;
}

.marginLeft28px {
  margin-left: 28px;
}

.paddingLeft10 {
  padding-left: 10px;
}

.fontSize12pt {
  font-size: 12pt;
}

.text-center {
  text-align: center;
}

.borderRed {
  border: red solid 2px !important;
}

.browserNotSupportedMsgDiv {
  margin-top: 50px;
}

.browserNotSupportedMsgDiv>ul {
  padding: 10px;
}

.browserNotSupportedMsgDiv>ul>li {
  margin-left: 40px;
}

.loginHeaderTitleAlign {
  margin: 40px 145px 0px 145px;
}

.footerLoginForBrowserDetectionMsg {
  bottom: 0;
  margin-top: 60px;
}

/*
.k-list-container{
    min-width:126px !important;
    width: auto!important;
}
.k-list
{
    width:auto !important;
}*/
/*

.search-button{
    pointer-events: all !important;
}
.search-button:hover{
    cursor: pointer;

}*/
.nonItalic {
  font-style: normal !important;
}

.unattended {
  /*background-color: #F5F5F5;*/
  opacity: 0.8;
  font-size: 12px;
}

a.menuBackgroundColor::after {
  display: none !important;
}

.percentage:after {
  color: black;
  content: '\00a0 %';
  display: inline;
}

.footerForPasswordExpiry {
  bottom: 0;
  margin-top: 20px;
}

.loginHeaderTitleAlignForPwdExpiry {
  margin: 20px 145px 0px 145px;
}

.paddingTopForPasswordExpiry {
  padding-top: 5px !important;
}

.multiColumnDataTable1-border {
  border-left: 4px solid #ddd;
}

table.dataTable {
  border-collapse: collapse !important;
}

.boldText {
  font-family: Publico-Bold;
}

.colSpan5Width {
  width: 350px !important;
}

#kendoGridTable tbody tr:hover {
  background: #ddd;
}

.table#tblPremium>tbody>tr>td,
#tblPremiumSummary>tbody>tr>td {
  /*border: none !important;*/
}

.table#tblPremium>tbody>tr>th,
#tblPremiumSummary>tbody>tr>th {
  border: none !important;
}

.headerUnderline {
  text-decoration: underline;
}

.premiumBreakdown {
  border-bottom: black double 4px !important;
  border-left: none !important;
  border-right: none !important;
  width: 130px !important;
  border-top: black solid 1px;
  padding: 5px;
  margin: auto;
}

.searchWidth {
  width: 10% !important;
}

.mandatoryErrorColor {
  bordor-color: red !important;
}

.salesPlanHeader-background {
  background-color: #6E27C5 !important;
}

.salesPlanSubTotal-background {
  background-color: #AFAFAF !important;
}

.salesPlanGrandTotal-background {
  background-color: #6E27C5 !important;
}

.salesPlanGeneral-background {
  background-color: #FFF !important;
}

.salesPlanGeneralLob-background {
  /*background-color: #AFAFAF !important;*/
}

.salesPlanTotal-background {
  background-color: #F1352B !important;
}

.blackColorFont {
  color: black !important;
}

.cell_width {
  /*max-width: 70px !important;*/
}

.tblCurrentAgent>thead>tr>th {
  max-width: 60px;
}

.salesPlanTbl>thead>tr>th {
  background-color: #AFAFAF;
  color: #FFFFFF;
}

.dashBoardDrillDown>thead>tr>th {
  background-color: #84BD00 !important;
  color: #FFFFFF !important;
}

.dashboardTopTenCardMaxHeight,
.dashboardKPICardMaxHeight {
  max-height: 353px;
  min-height: 353px;
}

.topTenPanelScroll {
  overflow: auto;
  height: 80%;
}

.KPIPanelScroll {
  overflow: auto;
  height: 80%;
}

.dashboardHROCardMaxHeight {
  max-height: 360px;
  min-height: 360px;
}

.HROPerformancePanelScroll {
  overflow: auto;
  height: 265px;
}

.headRoomDrillDownHeader-background>thead>tr>th {
  background-color: #2f4f4f !important;
  color: #FFFFFF;
}

.dashboardDDL {
  width: auto;
  height: 25px;
  font-size: 11px
}

.wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  padding: 5px;
  height: 50px;
}

.list {
  position: absolute;
  left: 0px;
  top: 0px;
  min-width: 3000px;
  /*margin-left: 12px;*/
  margin-top: 0px;
}

.list li {
  display: table-cell;
  position: relative;
  text-align: center;
  cursor: grab;
  cursor: -webkit-grab;
  color: #efefef;
  vertical-align: middle;
  border: white solid thin;
}

.scroller {
  text-align: center;
  cursor: pointer;
  display: none;
  padding: 7px;
  padding-top: 11px;
  white-space: no-wrap;
  vertical-align: middle;
  background-color: #fff;
}

.scroller-right {
  float: right;
}

.scroller-left {
  float: left;
}

/*.tabMenu.wrapper > ul > li.active > a {
    color: #FFF !important;
    background-color: #150F96 !important;
}

.tabMenu.wrapper > ul > li.active {
    background-color: #150F96 !important;
    color: #FFF !important;
}

.tabMenu.wrapper > ul > li > a {
    color: #150F96 !important;
}*/
.wrapper>ul>li.active>a {
  color: #000 !important;
  background-color: #FFFFFF !important;
}

.wrapper>ul>li.active {
  background-color: #FFF !important;
  color: #000 !important;
}

.wrapper>ul>li>a {
  color: #FFF !important;
}

.salesPlanTbl>tbody>tr>td {
  text-align: right;
}

.icon-salesPlanEstimationsIcon {
  background: url(8e928cce8cdf3df1ff209d3a20bc6877.png) no-repeat center;
}

.icon-intermediaryIcon {
  background: url(e231cbced5da8b68af5b43d7639db907.png) no-repeat center;
}

.myAMSIcon {
  background: url(aa34d33a3f628b62fb79514189581f5f.png) no-repeat center;
}

.rulesExtensionIcon {
  background: url(f3626e877fa79d431eb73554bda82699.png) no-repeat center;
}

.gui-Customized-icon .fa:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  line-height: 40px;
}

.gui-Customized-icon {
  position: absolute;
  left: 12px;
  top: 0;
  width: 40px;
  height: 40px;
  color: #535858;
  font-size: 18px;
  overflow: hidden;
  border-radius: 999px;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.gui-Customized-icon:hover {
  background-color: rgba(12, 12, 12, 0.07);
  color: #313534;
}

.gui-Customized-icon:hover img {
  left: -5px;
  top: -5px;
  width: 50px;
  height: 50px;
}

.gui-Customized-icon:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0aa89e;
}

.gui-Customized-icon {
  color: rgba(255, 255, 255, 0.65);
}

.gui-controls a.expanded .gui-Customized-icon,
.gui-controls li.active .gui-Customized-icon,
.gui-controls li.active .gui-Customized-icon:hover {
  color: #84BD00;
}

.list li {
  cursor: pointer !important;
}

#myTab>li>a:hover,
.buttonColor:hover {
  background-color: #AFAFAF !important;
  border: #AFAFAF;
  color: #FFF !important;
}

.labelText {
  font-family: Publico;
}

.maxWidth {
  width: 100% !important;
}

.cardBorderGreen {
  border: #84BD00 solid thin;
}

.cardBorderChubbGray {
  border: #AFAFAF solid thin;
}

.cardBorderBottomGreen {
  border-bottom: #84BD00 solid thin;
}

.k-grid-button {
  padding: 0px;
  font-size: 15px;
  /* font-family: inherit; */
  line-height: 1.72em;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #676767;
  border: #ccc solid thin !important;
  background-color: #fff;
  border-radius: 6px;
  margin: 0 .16em;
  min-width: 50px;
}

.policyHeader {
  font-size: 20px;
  font-weight: bold;
}

.policyCard {
  overflow-y: scroll;
  height: inherit;
  overflow-x: hidden;
}

.uppercaseText {
  text-transform: uppercase;
}

div.DTFC_LeftBodyLiner {
  border-right: 1px solid black;
  overflow-x: hidden;
}

div.dataTables_wrapper {
  width: 100%;
  margin: 0 auto;
}

div.ColVis {
  float: left;
}

.header-nav-profile .dropdown>a {
  /*padding: 5px !important;*/
}

.noBackgroundColor {
  background-color: transparent !important;
}

#pushdown-container .pushdown .desc-buttons p.description {
  max-width: 550px;
  min-width: 150px;
  width: auto !important;
}

.modal-header-custom {
  min-height: 16.42857143px;
  height: 55px;
  border-bottom: 2px solid #e5e5e5;
}

.modal-title-custom {
  margin: 0;
  line-height: 1.42857143;
  font-family: 'Publico', bold;
  color: #ffffff;
  font-size: 22px;
  height: 55px;
  vertical-align: middle;
  top: 0px;
  padding-top: 11px;
}

.proposalTypeTitle {
  font-family: 'Publico';
  padding-left: 10px;
  font-size: 20px;
  vertical-align: middle;
  top: 0px;
  margin-top: 20px;
  line-height: 1.5;
}

.margin0 {
  margin: 0px !important;
}

.margin5 {
  margin: 5px !important;
}

.margin10 {
  margin: 10px !important;
}

.ACE-red-button {
  background-color: #E03632;
  border-color: #E03632;
  color: white;
}

.paddingTop10 {
  padding-top: 10px;
}

.fontBlack {
  font-family: Publico;
  color: black;
}

.borderRadius5 {
  border-radius: 5px;
}

.overflowHidden {
  overflow: hidden;
}

.environmentBox {
  position: absolute;
  margin-left: 53%;
  margin-top: 8px;
  border: white solid 3px;
  font-size: 24px;
  color: white;
}

.headRoomDetails>tbody>tr>td {
  text-align: right;
}

.searchAction:hover {
  background-color: #F1352B !important;
  color: white !important;
}

.width30 {
  width: 30px;
}

#cssHeadRoomTable th {
  text-align: center;
  vertical-align: middle;
  table-layout: fixed;
}

#cssHeadRoomTable td {
  text-align: center;
  vertical-align: middle;
}

.LowHeadroomPotentialbackground {
  background-color: #6E27C5;
}

.HighHeadroomPotentialbackground {
  background-color: #6E27C5;
}

.MediunHeadroomPotentialbackground {
  background-color: #6E27C5;
}

.BUHeadroomPotentialbackground {
  background-color: lightblue;
}

.vScroll {
  max-height: 285px;
  overflow-y: auto;
}

/*.vScroll th {position:absolute;margin-top:-21px;}*/
#footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 30px;
  width: 100%;
  background: #999;
}

.RecruitmentPlanTbl>thead>tr>th {
  /*background-color: #84BD00;*/
}

.RecruitmentPlanTbl input {
  width: 100%;
  text-align: center;
}

.dashboardDrillDownSubTotal-background {
  background-color: #F1352B !important;
}

.dashboardDrillDownGrandTotal-background {
  background-color: #FF6600 !important;
}

.chubbPurple_bg {
  background-color: #6E27C5 !important;
}

.chubbPurple {
  color: #6E27C5 !important;
}

.chubbPurple_border_btn {
  border: #6E27C5 solid thin !important;
}

.chubbPurple_border_btn:hover {
  background-color: #6E27C5 !important;
  color: #FFF !important;
}

.chubbGray_bg {
  background-color: #4b4e53 !important;
}

.chubbGray_border {
  border: #4b4e53 solid thin;
}

.chubbLightGray_bg {
  background-color: #AFAFAF !important;
}

.chubbLightGray_border {
  border: #AFAFAF solid thin;
}

.chubbLightGray {
  color: #AFAFAF !important;
}

.chubbYellow_bg {
  background-color: #ffff00 !important;
}

.chubbOrange_bg {
  background-color: #FF6600 !important;
}

.chubbOrange {
  color: #FF6600 !important;
}

.chubbOrange_border_btn {
  border: #FF6600 solid thin;
}

.chubbOrange_border_btn:hover {
  background-color: #FF6600 !important;
  color: #FFF !important;
}

.chubbDarkBlue_bg {
  background-color: #150F96 !important;
}

.chubbDarkBlue {
  color: #150F96 !important;
}

.chubbDarkBlue_border_btn {
  border: #150F96 solid thin;
}

.chubbDarkBlue_border_btn:hover {
  background-color: #150F96 !important;
  color: #FFF !important;
}

.chubbLightOrange_bg {
  background-color: #FFB617 !important;
}

.chubbDarkBlue_border {
  border: #150F96 solid thin;
}

.chubbLightOrange {
  color: #FFB617 !important;
}

.chubbLightBlue_bg {
  background-color: #01C1D6 !important;
}

.chubbLightBlue {
  color: #01C1D6 !important;
}

.chubbLightBlue_border_btn {
  border: #01C1D6 solid thin;
}

.chubbLightBlue_border_btn:hover {
  background-color: #01C1D6 !important;
  color: #FFF !important;
}

.chubbLightOrange_border_btn {
  border: #FFB617 solid thin;
}

.chubbLightOrange_border_btn:hover {
  background-color: #FFB617 !important;
  color: #FFF !important;
}

.chubbRed_bg {
  background-color: #F1352B !important;
}

.chubbRed {
  color: #F1352B !important;
}

.chubbRed_border {
  border: #F1352B solid thin;
}

.chubbRed_border_btn {
  border: #F1352B solid thin;
}

.chubbRed_border_btn:hover {
  background-color: #F1352B !important;
  color: #FFF !important;
}

.chubbGreen_bg {
  background-color: #7ACB00 !important;
}

.chubbGreen {
  color: #7ACB00 !important;
}

.chubbGreen_border {
  border: #7ACB00 solid thin;
}

.chubbGreen_border_btn {
  border: #7ACB00 solid thin !important;
}

.chubbGreen_border_btn:hover {
  background-color: #7ACB00 !important;
  color: #FFF !important;
}

.chubbPink_bg {
  background-color: #FF0198 !important;
}

.chubbPink {
  color: #FF0198 !important;
}

.chubbWhite_bg {
  background-color: #FFF !important;
}

.chubbWhite {
  color: #FFF !important;
}

.chubbBlack {
  color: #000 !important;
}

.chubbWhite_border {
  border: #FFF solid thin;
}

.chubbLightGrayF5F5F5_bg {
  background-color: #F5F5F5
}

.borderBottomRadius {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.menubar-visible #menubar {
  width: 280px;
}

.menuOpenTableRow {
  background-color: #7ACB00 !important;
}

.commandAction:hover {
  z-index: 2 !important;
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
}

.OnTrackYellow {
  background-color: #F1F133 !important;
}

.OnTrackGreen {
  background-color: #008200 !important;
}

.OnTrackLightGreen {
  background-color: #00ff00 !important;
}

.ui-checkbox-off:after {
  background-color: white !important;
}

.ui-checkbox-on:after {
  background-color: green !important;
}

.btn {
  text-transform: none !important;
  border-radius: 0px !important;
  padding: 10px 12px;
  font-size: 15px;
}

#menubar .menubar-scroll-panel {
  padding-top: 41px;
}

#header {
  /*height: 94px;*/
}

@media (min-width: 1200px) {
  .menubar-pin #menubar {
    width: 280px;
  }
}

.menubar-inverse .gui-controls a.expanded .gui-icon,
.menubar-inverse .gui-controls li.active .gui-icon,
.menubar-inverse .gui-controls li.active .gui-icon:hover {
  background-color: transparent;
  border-color: transparent;
  color: #FFF;
}

.menubar-inverse .gui-icon {
  color: #FFF;
}

.menubar-inverse .gui-controls li .title {
  text-shadow: none;
}

#base>.backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

.k-grid-header .k-header {
  background-color: #4b4e53 !important;
  color: #FFFFFF !important;
}

.k-grid-header .k-header .k-link,
.k-grid-header .k-link,
.k-grid-header .k-link:link {
  color: #FFFFFF !important;
}

.k-grid-content tr td {
  border-left-width: 1px !important;
}

.commandAction:hover {
  background-color: #FFB617 !important;
  border-color: #FFB617 !important;
}

.k-grid td {
  padding: 0em .3em 0em .6em !important;
}

.k-grid-header th.k-header {
  padding: .786em .6em .786em 0.6em !important;
}

.newBtn[disabled] {
  background-color: #AFAFAF !important;
  color: #FFFFFF !important;
}

.agencyProfileUnselectedTab.active,
.userProfileUnselectedTab.active,
.bulletinEditUnselectedTab.active {
  background-color: #FFF !important;
  color: #6E27C5;
}

.agencyProfileUnselectedTab.active>a,
.userProfileUnselectedTab.active>a {
  color: #000 !important;
}

.searchCard {
  border-radius: 0px;
  box-shadow: none;
  border: #6E27C5 solid thin;
}

.card {
  margin-bottom: 20px;
}

.card-head-agency {
  padding: 11px 10px !important;
  display: -webkit-box;
  line-height: 1.5;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
}

table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: normal !important;
}

.btn-link {
  text-decoration: underline;
}

table.headRoomDash {
  width: 96.5%;
}

.searchCardTextWrap {
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

/* Tooltip container */
.searchUserProfileCard {
  position: relative;
  /*display: inline-block;*/
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
/*
.searchUserProfileCard .tooltiptext {
    visibility: hidden;
    width: 100%;
    background-color: black;
    color: #fff;
    top: -30px;
    !*right: 50%;*!
    !*margin-left: -50px;*! !* Use half of the width (120/2 = 60), to center the tooltip *!
    padding: 3px ;


    !* Position the tooltip text - see examples below! *!
    position: absolute;
    z-index: 1;
}

!* Show the tooltip text when you mouse over the tooltip container *!
.searchUserProfileCard:hover .tooltiptext {
    visibility: visible;
}
.searchUserProfileCard .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; !* At the bottom of the tooltip *!
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}*/
.highHeadRoomPotential-background {
  background-color: #CCFFCC !important;
}

.mediumHeadRoomPotential-background {
  background-color: #F8F5CC !important;
}

.lowHeadRoomPotential-background {
  background-color: #EBC2EB !important;
}

.hroChartInactiveLink {
  color: black;
  pointer-events: none;
  cursor: default;
}

.hroChartactiveLink {
  color: blue;
  cursor: pointer;
}

/*.hroSegmentationTable td {
    border: 1px solid black !important;
}

.hroPerformaceTrackingTable td {
    border: 1px solid black !important;
}

.hroPerformaceTrackingTable > thead > tr > th {
    border: 1px solid black !important;
    background-color: #F1352B !important;
}*/
/*.hroSegmentationTable td {
    !*border: 1px solid black !important;*!
}*/
.k-autocomplete .k-input {
  border-radius: 0px !important;
}

.model-content {
  border-radius: 0px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0px !important;
  }
}

@media (max-width: 1000px) {
  #menuHeaderText {
    display: none;
  }
}

@media (max-width: 991px) {
  #base {
    padding-left: 0;
    padding-right: 0;
  }

  #menubar {
    top: 130px;
  }
}

@media (max-width: 992px) {
  /*#menubar {
        width: 240px;
        z-index: 1013;
        -webkit-transform: translate(-250px, 0);
        -ms-transform: translate(-250px, 0);
        -o-transform: translate(-250px, 0);
        transform: translate(-250px, 0);
        -webkit-transition: -webkit-transform 0.5s ease-in;
        -moz-transition: -moz-transform 0.5s ease-in;
        -o-transition: -o-transform 0.5s ease-in;
        transition: transform 0.5s ease-in;
    }*/
}

@media (max-width: 992px) {
  .header-fixed #header {
    position: relative;
  }
}

@media (max-width: 768px) {
  #header {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  #menubar {
    top: 130px;
  }

  .header-fixed #header:before {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  #menubar {
    z-index: 1004;
    left: 0;
    bottom: 0;
    width: 64px;
    color: rgba(12, 12, 12, 0.85);
    -webkit-transform: translate(0px, 0);
    -ms-transform: translate(0px, 0);
    -o-transform: translate(0px, 0);
    transform: translate(0px, 0);
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
  }
}

#header {
  /*-webkit-box-shadow: none !important;
    box-shadow: none !important;*/
}

.fontSize25px {
  font-size: 25px !important;
}

.nav-tabs>li {
  /*border-right: white solid thin;*/
}

.k-alt-lightgray {
  background-color: lightgray;
}

.k-bottom-lightgray {
  border-bottom: lightgray solid thin;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: #4b4e53 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: solid thin #4b4e53 !important;
  background-color: #FFF !important;
  color: #4b4e53 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  border: 1px solid #4b4e53;
  background-color: #4b4e53 !important;
  color: #FFF !important;
  background: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #4b4e53 !important;
  background: #FFF !important;
}

.width160px {
  width: 160px !important;
}

.width200px {
  width: 200px !important;
}

.card.style-primary {
  color: #000;
}

.card {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: #AFAFAF solid thin;
}

.borderBottomLightBlue {
  border-bottom: #01C1D6 solid thin;
}

.borderBottomGreen {
  border-bottom: #7ACB00 solid thin;
}

.productSelectionTitle {
  color: #004C97;
  font-size: 18px;
}

.productSelectionTitle:hover {
  color: #FFF;
}

#productListing td {
  width: 5em;
  line-height: 2em;
  background-color: #fff;
}

#productListing tr:hover {
  color: #fff;
  background-color: #150F96;
}

#productListing tr:hover td {
  background-color: transparent;
}

.dashboardDrillDownSubTotal-background {
  background-color: #5F9EA0 !important;
}

.dashboardDrillDownGrandTotal-background {
  background-color: #7F5D0A !important;
}

.k-alt {
  background-color: #AFAFAF;
  border-color: #AFAFAF;
}

.hroSegmentationTable td {
  /*border: 1px solid black !important;*/
}

.hroPerformaceTrackingTable td {
  /*border: 1px solid black !important;*/
}

.dashboardDrillDownGrandTotal-background {
  background-color: #7F5D0A !important;
}

.dashboardDrillDownSubTotal-background {
  background-color: #5F9EA0 !important;
}

.dashboardDrillDownGrandTotal-background {
  background-color: #7F5D0A !important;
}

.k-alt {
  background-color: #AFAFAF;
  border-color: #AFAFAF;
}

.generictemplate-alternativecolor {
  border-bottom: lightgray solid thin;
  margin-left: 0px;
  margin-right: 0px;
  line-height: 35px;
}

/*
.CUSTOM .generictemplate-alternativecolor:nth-child(even){
    background-color:#fff;
         }
.CUSTOM .generictemplate-alternativecolor:nth-child(odd){
    background-color: rgba(221, 221, 221, 0.38);
}
*/
.margin0 {
  margin: 0;
}

.progress-bar-success {
  background-color: #7ACB00;
}

span.readOnlyText {
  margin-top: 1px !important;
  display: inline-block;
}

.headerBorder {
  border-top: black solid thin;
}

.paddingTop2 {
  padding-top: 2px;
}

.btn-box {
  height: 30px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 4px;
  text-transform: none !important;
  border-radius: 0px !important;
  padding: 6px 12px;
  font-size: 15px;
}

.templateContent {
  overflow-y: auto;
  height: inherit;
  overflow-x: hidden;
}

div.dataTables_wrapper {
  width: 98%;
  margin: 25px auto;
}

.table thead {
  opacity: 1;
}

/*input[type="checkbox"] {
    margin: -6px 0 6px;
}*/
.card-head .tools>.btn-group {
  margin-right: 0px;
}

.btn-group {
  display: block;
}

.pageHeader {
  font-family: 'Publico';
  padding-left: 10px;
  font-size: 20px;
  vertical-align: middle;
  top: 0px;
  line-height: 1.5;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 19px;
  border-bottom: 1px solid #111;
}

.custom-event {
  color: #FFFFFF;
  text-shadow: 0 1px 0 #000;
  font-size: 12px;
}

.custom-all-day-event {
  text-align: center;
  text-transform: uppercase
}

#kendoSchedulerEvent .k-header {
  background-color: #4b4e53;
}

.k-scheduler .k-header li {
  border-color: #4b4e53;
}

.k-scheduler .k-scheduler-toolbar .k-state-selected {
  background-color: #7ACB00;
  border-color: #7ACB00;
}

.header-nav-profile .dropdown .profile-info {
  padding: 12px 20px 0 10px;
}

#base {
  padding-left: 0px;
}

.headerbar-lastRowMenu {
  margin-top: 64px;
}

.navbar-nav>li>a {
  padding-top: 10px;
}

@media (max-width: 768px) {
  .headerbar-lastRowMenu {
    margin-top: 0px;
    position: absolute;
    left: 0;
    right: 0;
    /*min-height: 64px;*/
    top: 128px;
    float: none !important;
    background: #ffffff;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  }

  .navbar-nav>li>a {
    padding-top: 20px;
  }

  .newProductBtn {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .navbar-nav>li>a {
    padding-top: 10px;
    line-height: 20px !important;
  }
}

#navLeftMenu>li>a:hover,
.navbar-right>li>a:hover {
  color: black !important;
}

.overFlowAuto {
  overflow: auto;
}

.dropdown-menu>li:hover,
.dropdown-menu>li>a:hover {
  background-color: #150F96 !important;
  color: white;
}

.form-group>label,
.form-group .control-label {
  opacity: 1;
}

.form-group {
  margin-bottom: 10px;
}

.fontWeight100 {
  font-weight: 100;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.k-dropdown.form-control,
.k-input.form-control,
.k-combobox.form-control,
.k-datepicker.form-control {
  border: none;
}

.form-control {
  border-radius: 0px;
}

.paddingTopBottom {
  padding: 7px 0 7px 0;
}

div.back-to-top {
  display: none;
  width: 60px;
  height: 60px;
  /*text-indent: -9999px;*/
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 20px;
  background: #7ACB00 no-repeat center 43%;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 8px 0 0 16px;
  color: white;
  cursor: pointer;
}

div.float-save-btn {
  display: none;
  width: 60px;
  height: 38px;
  /*text-indent: -9999px;*/
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 100px;
  background: #7ACB00 no-repeat center 43%;
  /*-webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;*/
  padding: 8px 0 0 16px;
  color: white;
  cursor: pointer;
}

.paddingTop20 {
  padding-top: 20px;
}

.form-group>value-text>label,
.form-group>currency-text>label,
.form-group>percentage-text>label,
.form-group>numeric-only-text>label,
.form-group>numeric-text>label,
.form-group>postal-only-text>label,
.form-group>radio-yesno-button>label,
.form-group>multi-line-text>label,
.form-group>check-box>label,
.form-group>drop-down>label,
.form-group>value-question>label,
.form-group>value-email-question>label,
.form-group>value-phone-question>label,
.form-group>combo-box-question>label,
.form-group>multi-lines-question>label,
.form-group>radio-button-question>label,
.form-group>drop-down-question>label,
.form-group>numeric-text-box-question>label,
.form-group>currency-question>label,
.form-group>date-range-question>label,
.form-group>numeric-percentage-question>label {
  font-size: 12px;
  margin-bottom: 0;
}

.fontWeight100>label {
  font-weight: 100;
}

.glyphicon {
  top: 0px;
}

.height34px {
  height: 34px;
}

.padding4 {
  padding: 4px;
}

.clearMarginTopBottom {
  margin-top: 0px;
  margin-bottom: 0px;
}

.list-group-item>.customBadge {
  float: right;
}

.customBadge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  /*font-weight: bold;*/
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #F1352B;
  border-radius: 10px;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.tabMenuRightBorder {
  border-right: white solid thin;
}

.textTransformCapitalize {
  text-transform: capitalize;
}

.width200px {
  width: 200px;
}

pre.wrap {
  word-break: keep-all;
  /* webkit */
  word-wrap: break-word;
  white-space: pre;
  white-space: -moz-pre-wrap;
  /* fennec */
  white-space: pre-wrap;
  white-space: pre \9;
  /* IE7+ */
}

.scrollable-tabs {
  width: 100%;
  display: table;
}

.scrollable-tabs>li {
  width: auto !important;
}

/*************************************************************/
#loading-img {
  background: url(59f91ff3616b0e8ab277cb06027e170d.gif) center center no-repeat;
  height: 100%;
  background-size: 7%;
  background-color: inherit;
}

.loading-ellipsis {
  background: url(1e61267ffa7bf5e12d066158064b107c.gif) center center no-repeat;
  height: 20px;
  width: 45px;
}

.overlay {
  background: #AFAFAF;
  opacity: 0.7;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
}

.has-error .k-numeric-wrap {
  border-color: #a94442;
}

#qs_pd_insuredSufferedLoss_table_grid table,
#qs_pd_insuredExpectClaims_table_grid table,
#qs_sar_portable-equipment_table_grid table,
#qs_pd_hist_declined_table_grid table {
  table-layout: inherit !important;
}

#qs_pd_insuredSufferedLoss_table_grid table tr td[width],
#qs_pd_insuredExpectClaims_table_grid table tr td[width],
#qs_sar_portable-equipment_table_grid table tr td[width],
#qs_pd_hist_declined_table_grid table tr td[width] {
  border: 0px !important;
}

.floating-box {
  position: fixed;
  top: auto;
  left: auto;
  right: 2%;
  bottom: 0;
  z-index: 1;
}

.activeStatus {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #7ACB00;
  border: 1px solid #7ACB00;
  border-radius: 25px;
  margin-right: 5px;
}

#productEndorsementClauseTable tbody:nth-child(odd) {
  background: #f5f5f5;
}

#productEndorsementClauseTabletbody:nth-child(even) {
  background: #e5e5e5;
}

#qs_pd_insuredSufferedLoss_table_grid+.fileAttachmentLossQuestion {
  visibility: hidden;
  ;
}

#qs_pd_hist_declined_table_grid+.historyDeclinesQuestion {
  visibility: hidden;
  ;
}

.border-bottom0 {
  border-bottom: 0;
}

.paddingLeft30 {
  padding-left: 30px !important;
}

/*************************************************************/
.buttonMarginIE {
  margin: 0 -14px -6px
}

.horizontalMenuToggle {
  /*width: 200px;
    background-color: salmon;
    overflow: hidden;

    -webkit-transition-duration: 0.8s;*/
}

.dropdown .dropdown-menu {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown.open .dropdown-menu {
  max-height: 1000px;
  opacity: 1;
}

.dropdown-menu>li>a {
  line-height: 2.428571;
}

.k-tabstrip-items {
  background-image: none;
  /*background-color: #6E27C5;*/
  background-color: #AFAFAF;
  width: 100%;
  display: table;
}

.k-tabstrip>.k-tabstrip-items>.k-item {
  text-transform: none;
  width: auto;
  /*display: table-cell;*/
  text-align: center;
}

.k-tabstrip-top>.k-tabstrip-items .k-item.k-state-active {
  background-image: none;
  background-color: #ffffff;
  color: #000;
}

.k-tabstrip>.k-tabstrip-items .k-item.k-state-hover .k-loading,
.k-tabstrip>.k-tabstrip-items .k-item.k-state-selected .k-loading {
  background-image: none;
}

.marginLeft-10 {
  margin-left: -10px;
}

.premiumHeader {
  padding-left: 10px;
  padding-right: 10px !important;
  min-width: 20%;
  padding-top: 2px;
}

.header-nav>li.bizPackProfile {
  position: relative;
  display: block;
  padding: 3px 10px;
  float: left;
  /*background-color: #150F96;*/
  margin-right: 7px;
  margin-top: 13px;
  height: 25px;
  color: #150F96;
}

.header-nav>li.bizPackProfileAlert {
  position: relative;
  display: block;
  padding: 3px 10px;
  float: left;
  background-color: #F1352B;
  margin-right: 7px;
  margin-top: 7px;
  height: 25px;
  color: #FFFFFF;
}

.chubbGray_border_btn {
  border: #AFAFAF solid thin !important;
}

.k-tabstrip-items .k-link {
  padding: .3em .9em;
}

.btn_mod {
  padding: 5px 12px !important;
}

.k-grid-toolbar,
.k-grouping-header {
  padding: 0em;
}

.navbar-brand {
  padding: 10px 15px;
  height: 40px;
}

.navbar-nav>li>a {
  line-height: 20px;
}

.headerIcon22px {
  font-size: 22px;
}

.bulletPoint {
  display: list-item;
  list-style-position: inside;
  list-style-type: circle;
}

.list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.bizpack-list-group-item {
  padding-left: 10px;
}

.bizpack-list-group-item ul {
  margin: 0;
  list-style-type: none;
}

.bizpack-list-group-item ul li {
  padding-bottom: 7px;
}

.bizpack-list-title {
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 3px;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /* IE10+ CSS styles go here */
  .btn-box {
    padding: 7px 12px !important;
  }
}

@supports (-ms-accelerator:true) {

  /* IE Edge 12+ CSS styles go here */
  .btn-box {
    padding: 7px 12px !important;
  }
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
  background-color: #AFAFAF;
  border-color: #337ab7;
}

.fontSize12px {
  font-size: 12px;
}

#bizpackPreSumm.table-outter {
  overflow-x: auto;
}

#bizpackPreSumm table th,
#bizpackPreSumm table td {
  vertical-align: middle;
  padding: 5px 10px;
}

#bizpackPreSumm .table>tbody+tbody {
  /*Overwrites css in tables.less:70*/
  border-top: 0;
}

#bizpackPreSumm table td p {
  margin: 0;
}

.chubbGray {
  color: #AFAFAF !important;
}

.chubbGray_border_btn:hover {
  background-color: #AFAFAF !important;
  color: #FFF !important;
}

/*
table th, table#bizpackPreSumm td {
    min-width: 200px;
}*/
.totalAmountBorder {
  border-top: solid 2px !important;
  border-bottom: double !important;
}

.totalCellBorder {
  border-top: solid 2px #ddd !important;
  border-bottom: solid 2px #ddd !important;
}

.verticalAlignBottom {
  vertical-align: bottom !important;
}

.verticalAlignTop {
  vertical-align: top !important;
}

/*#qs_location_occupation::after,#qs_master_insuredBusinessRegNo::after {
    color: #E03632;
    content: '\00a0 *';
    display: inline;
}*/
.textDecoItalic {
  font-style: italic;
}

.k-numeric-wrap .k-input {
  width: 98%;
}

.width98Per {
  width: 98%;
}

#qs_pd_hist_declined_table_grid .k-grid-content,
#qs_sar_portable-equipment_table_grid .k-grid-content,
#qs_electronicEqp_details_table_grid .k-grid-content,
#qs_machinerybreakdown_details_table_grid .k-grid-content,
#qs_generalProperty_specifiedItems_table_grid .k-grid-content,
#qs_postQuote_ammendSeasonalIncreasePeriods_table_grid .k-grid-content {
  min-height: 100px;
}

.table>tbody>tr>td {
  vertical-align: middle;
}

.k-numerictextbox {
  width: auto;
}

.marginRight25px {
  margin-right: 25px;
}

.k-textbox>input,
.k-numeric-wrap>.k-input,
.k-autocomplete .k-input,
.k-picker-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  margin-bottom: 2px !important;
}

.k-autocomplete.k-state-default .k-input,
.k-combobox .k-state-default .k-input,
.k-picker-wrap.k-state-default .k-input,
.k-numeric-wrap.k-state-default .k-input,
.k-multiselect-wrap,
.k-slider-track,
.k-slider-selection,
.k-progress-status-wrap,
.k-dropdown-wrap .k-state-default .k-input {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

span {
  vertical-align: middle;
}

.k-widget,
.k-widget *,
.k-widget :before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

input.k-textbox {
  height: 34px;
  text-indent: .6em;
}

.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-numeric-wrap .k-input,
.k-picker-wrap .k-input,
.k-selectbox .k-input,
.k-textbox>input {
  text-indent: .8em;
}

.marginTop35 {
  margin-top: 35px;
}

.k-tabstrip-top>.k-tabstrip-items .k-item.k-state-active {
  border-bottom-color: #7ACB00;
}

.k-state-mandatory {
  border-bottom-color: #F1352B !important;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #313534;
}

.right75 {
  right: 75px;
}

.borderColorGrey .k-numeric-wrap {
  border-color: #e0e0e0 !important;
}

.ACElightWhite-background {
  background-color: #fafbfb !important
}

.quickQuote_div {
  overflow: scroll;
  display: block;
}

.quickQuote_div td {
  min-width: 100px;
}

.quickQuote_div td:first-child {
  min-width: 500px;
}

#quickQuote_table>thead>tr>th {
  white-space: nowrap;
}

#quickQuote_table>tbody>tr>td:nth-child(1) {
  width: 400px !important;
}

.limitDeductibleSpacing {
  border: #AFAFAF solid thin;
  margin-top: 3px;
  clear: both;
}

.limitDeductibleScroll {
  white-space: nowrap;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

/*.limitDeductibleLimitSpan {
    white-space: normal;
    display: inline-block
}

.limitDeductibleTable {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}*/
table.limitDeductibleTable,
table.limitDeductibleTable th,
table.limitDeductibleTable td {
  border: 1px solid black;
}

table.limitDeductibleTable,
table.limitDeductibleTable th,
table.limitDeductibleTable td {
  padding: .5em 1em;
}

.limitDeductibleDivRow {
  width: 1350px;
}

.limitDeductibleDivRowBroker {
  width: 1200px;
}

.limitDeductibleDivCoverages {
  width: 350px;
}

.limitDeductibleDivCoveragesBroker {
  width: 708px;
}

.limitDeductibleDivLimitDeductible {
  width: 160px;
  text-align: right;
}

.limitDeductibleDivUL {
  width: 160px;
  text-align: right;
}

.limitDeductibleDivAnnualPremium {
  width: 320px;
  text-align: right;
}

.limitDeductibleDivChargePremium {
  width: 320px;
  text-align: right;
}

.limitDeductibleDivChargePremiumBroker {
  width: 160px;
  text-align: right;
}

.limitOption:hover {
  background-color: #FFB617 !important;
}

.limitOptionActive {
  background-color: #FFB617 !important
}

textarea.k-textbox {
  padding: 2px .3em;
}

.border-box {
  border: 1px solid black;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  display: inline-block;
  float: left;
}

table.endorsementTable,
table.endorsementTable td,
table.endorsementTable th {
  border: none;
  font-family: Georgia;
  font-size: 10.5pt;
}

.endorsementTable td {
  vertical-align: top;
}

#qs_pd_history_sufferedLoss_table_grid_selectPlus td{
  text-overflow: ellipsis;
}

#qs_pd_history_futureClaims_table_grid td {
  white-space: nowrap;
  text-overflow: ellipsis;
}
#qs_pd_history_sufferedLoss_table_grid td:last-child,
#qs_pd_history_sufferedLoss_table_grid_selectPlus td:last-child,
#qs_pd_history_futureClaims_table_grid td:last-child {
  white-space: normal;
}

#awarnessDetails_wnd_title.k-window-title,
#coverageDetails_wnd_title.k-window-title {
  line-height: 1.362em;
}

a.contactUs:hover {
  background-color: white !important;
  color: #FF6600 !important;
  border: #FF6600 solid thin;
}

#proposalGrid.k-grid td {
  white-space: nowrap;
}

.marginTopBottom7 {
  margin: 7px 0 7px 0 !important;
}

.wrapNormal {
  white-space: normal !important;
}

.displayBlock {
  display: block !important;
}

.selectedchkbox {
  width: 16px;
  height: 17px;
  content: url(c60a5048897b71c860a569f7534b666e.png);
  margin-top: 1px;
  margin-right: 5px;
}

.chkbox {
  width: 16px;
  height: 17px;
  content: url(fb24828e2a10766d13ed0c9ac2cc2924.png);
  margin-top: 5px;
  margin-right: 5px;
}

.customModalTable.k-grid td {
  padding: 8px 10px !important;
}

.locationHeader {
  margin-left: 10px;
  /* margin-top: 35px; */
  margin-right: -15px;
  margin-bottom: -11px;
}

.spOverlay {
  background: #AFAFAF;
  opacity: 0.7;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
}

.spCheckboxLabel {
  font-size: 12px;
  font-weight: bold;
  opacity: 1;
  display: inline-block;
  max-width: 100%;
}


.spRadioButtonLabel {
    font-size: 12px;
    font-weight: bold;
    opacity: 1;
    display: inline-block;
    max-width: 100%;
}

.spTableColumnLabel {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
}

.panel-body-sp {
  padding: 25px;
}

.spHr {
  border: none;
  height: 10px;
  width: 100%;
  margin-top: 0;
  border-bottom: 2px solid #dddcda;
  box-shadow: 0 20px 20px -20px #5f5e5d;

}

.spLocationSubtitle {
  font-family: 'Publico';
  font-size: 12pt;
  /* position: relative; */
  float: left;

  margin-left: 5px;
  padding-bottom: 5px;
  opacity: 0.8;
  font-weight: bold;
}

.spCardBodyBorder {
  background-color: #ffffff;
  color: #313534;
  border: #AFAFAF solid thin;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.spSgTabHeaderExtra {
  margin-top: 125px;
}

.spSgTabHeaderDefault {
  margin-top: 95px
}

.spDefaultPointer {
  cursor: default;
}

.sp-list-group-item {
  padding-left: 1px;
}

.sp-list-group-item ul {
  margin: 0;
  list-style-type: none;
}

.sp-list-group-item ul li {
  padding-bottom: 5px;
}

/**Sitcky header**/
.page_holder {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  /* width:200px;
    height:300px;*/
  width: 200px;
  height: 300px;
  overflow: auto;
  z-index: 1;
}

.container2 {
  position: relative;
}

.stuck {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  background: blue;
  margin-right: 18px;
  z-index: 2;
}

.content2 {
  /*height:700px;*/
  height: 700px;
  background: gray;
}

.sufferDetailsNonEditable {
  display: none;
}

.additionalUenDetailsNonEditable {
  display: none;
}

.premiumOverriddenEdit {
  vertical-align: sub;
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
}

#qs_insured_history_additionalUenDetails_table_grid_selectPlus .k-grid-content
{
  min-height: 75px;
}

.cursorAuto {
  cursor: auto !important;
}

.borderBlue {
  border: #01C1D6 solid thin !important;
}

input[type="text"]:disabled {
  background: #dddddd;
  cursor: not-allowed;
}

.marginBottom0 {
  margin-bottom: 0px !important;
}

.verticalAlignMiddle {
  vertical-align: middle !important;
}

.chubbGreen_border_btn:hover span {
  background-color: #7ACB00 !important;
  color: #FFF !important;
}

.showEditDoc:hover,
.showEditDoc:focus {
  background-color: #fff !important;
  color: red !important;
}

.printableDetailsTable .container .row {
  border-bottom: 1px solid #ddd;
}

.printableDetailsTable .container .row:last-child,
.printableDetailsTable .container:last-child .row:last-child {
  border-bottom: none;
}

.verticalAlignTop {
  vertical-align: top !important;
}

.printableDetailsTable tr td {
  padding: 0 !important;
}

.printableDetailsTable .container .row div:last-child {
  border-left: 1px solid #ddd;
}

.printableDetailsTable .container .row div {
  padding-top: 5px;
  padding-bottom: 5px;
  word-wrap: break-word;
}

.printableDetailsTable tr th,
.printableDetailsTable tr td:last-child {
  width: 150px !important;
}

.border-right {
  border-right: 1px solid #ddd;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #84BD00;
}

input:focus+.slider {
  box-shadow: 0 0 1px #84BD00;
}

input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}


.showEditDoc:hover,
.showEditDoc:focus {
  background-color: #fff !important;
  color: red !important;
}

.printableDetailsTable .container .row {
  border-bottom: 1px solid #ddd;
}

.printableDetailsTable .container .row:last-child,
.printableDetailsTable .container:last-child .row:last-child {
  border-bottom: none;
}

.verticalAlignTop {
  vertical-align: top !important;
}

.printableDetailsTable tr td {
  padding: 0 !important;
}

.printableDetailsTable .container .row div:last-child {
  border-left: 1px solid #ddd;
}

.printableDetailsTable .container .row div {
  padding-top: 5px;
  padding-bottom: 5px;
  word-wrap: break-word;
}

.printableDetailsTable tr th,
.printableDetailsTable tr td:last-child {
  width: 150px !important;
}

.border-right {
  border-right: 1px solid #ddd;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #84BD00;
}

input:focus+.slider {
  box-shadow: 0 0 1px #84BD00;
}

input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}

#mrlListingGrid.k-grid td {
  border-color: #cecaca !important;
  padding: .4em .6em !important;
  white-space: nowrap;
}

#mrlListingGrid.k-grid td .k-button {
  padding: .3em .6em;
}

#mrlListingGrid {
  border: none;
}

.mrlListingToolTip .k-callout-w {
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: #337ab7;
}

.mrlListingToolTip .k-i-close {
  background-image: url(http://cdn.kendostatic.com/2013.1.319/styles/Default/sprite.png);
}

.mrlListingToolTip {
  border: 2px solid #337ab7 !important;
}

.anchorInfo {
  color: #337ab7 !important;
}

#failedReasons-container ul {
  list-style: none;
  text-indent: -0.75em;
  vertical-align: baseline;
}

.textDecorationNone,
.textDecorationNone:hover,
.textDecorationNone:focus {
  text-decoration: none;
}

#mrlListingGrid tr:last-child .k-invalid-msg {
  margin: 0 !important;
  margin-left: 150px !important;
  top: 2px;
}

#mrlListingGrid .k-grid-edit-row .k-tooltip {
  color: red !important;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid red;
}

#mrlListingGrid .k-grid-edit-row .k-tooltip .k-callout-n {
  border-top-color: transparent;
  border-bottom-color: red;
  border-right-color: transparent;
}

#mrlListingGrid .k-grid-edit-row:last-child .k-tooltip .k-callout-n {
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: red;
  top: 13px;
  left: -5%;
}

#mrlListingGrid .additionalInfo {
  vertical-align: super;
}

.mrlListingAdditionalInfoToolTip {
  border: 2px solid #7ACB00 !important;
}

.mrlListingAdditionalInfoToolTip .k-callout-w {
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: #7ACB00 !important;
}

.dropdown-border {
  border: 1px solid #ff4350 !important;
  height: auto;
}

.checkbox#agreementCheckBox {
  padding-left: 20px;
  position: relative;
  display: block;
}

.checkbox#agreementCheckBox input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  margin-left: -20px;
}

.checkbox#agreementCheckBox input:checked+label::before {
  border-color: #84BD00;
}

.checkbox#agreementCheckBox input:checked+label::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 13px;
  left: 2px;
  top: 4px;
  margin-left: -20px;
  border: 1px solid #84BD00;
  border-radius: 50%;
  background-color: #84BD00;
}

.checkbox#agreementCheckBox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}

.checkbox#agreementCheckBox label::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 2px;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
}

.checkbox#agreementCheckBox.disabled label {
  color: #cccccc;
}

.checkbox#agreementCheckBox.disabled label::before {
  opacity: .54;
  border-color: #cccccc;
}

.checkbox#agreementCheckBox input:checked+label::before {
  border-color: #84BD00;
  background-color: #84BD00;
}

.checkbox#agreementCheckBox input:checked+label::after {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  color: #fff;
  top: 0;
  left: 1px;
  border-color: transparent;
  background-color: transparent;
}

.checkbox#agreementCheckBox label::before {
  border-radius: 2px;
}

.inner-addon {
    position: relative;
}

/* style glyph */
.inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}

/* align glyph */
.left-addon .glyphicon {
    left: 0px;
}

.right-addon .glyphicon {
    right: 0px;
}

/* add padding  */
.left-addon input {
    padding-left: 30px;
}

.right-addon input {
    padding-right: 30px;
}

#qs_srp_table>tbody>tr>td:nth-child(1) {
    width: 350px !important;
}

.premium-text-bold {
    font-weight: bold;
}

.premium-text-normal {
    font-weight: normal;
    font-size: small;
}


/* For html table */
.divWrapper {
    overflow-x: auto;
    width: 100%;
}

.koreaTableFont12px {
    font-size: 12px;
}

@keyframes highlight {
  0% {
    background: #F1352B;
  }

  100% {
    background: none;
  }
}

.highlight {
  animation: highlight 4s;
}

.loginKorea2FABackdrop {
  background-color: white;
  opacity: 0.5;
}

#myLogoutProgress {
  width: 100%;
  background-color: #ddd;
}

#myLogoutBar {
  width: 1%;
  height: 10px;
  background-color: #4CAF50;
}

</style>
<div id="login-form">
    <div ng-hide="SSOcontainer">
        <h1>Signing on ... Please wait... </h1>
    
        <div class="alert alert-danger" ng-show="message.length > 0">
            <strong ng-bind="message"></strong>
        </div>
    </div>
        <!--#181050- Block if browser is not compatible to SSO AMS - end-->
        <div class="card contain-sm style-transparent" align="center" ng-hide="isBrowserSupported">
            <div class="card-body" align="center">
                <div class="row loginHeader loginHeaderTitleAlign">
                    <div class="col-sm-12 textAlignCenter">
                        E-placement℠
                    </div>
                </div>
                <br>
                <div class="row text-left borderRed browserNotSupportedMsgDiv">
                    <ul>
                        Your current internet browser is not supported. The following are the recommended minimum internet browser versions that can be used to access the System via Internet :
                        <li class="marginTop10">Microsoft Internet Explorer Version 11 and above</li>
                        <li>Google Chrome 44.0.2403.155 and above</li>
                        <li>Mozilla Firefox 40.0 and above</li>
                    </ul>
                </div>
                <div class="row textAlignCenter footerLoginForBrowserDetectionMsg">
                    <p data-ng-bind="footerTitle"></p>
                </div>
            </div>
        </div>
      <!--#181050- Block if browser is not compatible to SSO AMS - end-->
    </div>
    