import { Component, Input, OnInit } from "@angular/core";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";

@Component({
  selector: "text-box-question",
  templateUrl: "./text-box-question.component.html",
  styleUrls: ["./text-box-question.component.scss"],
})
export class TextBoxQuestionComponent implements OnInit {
  @Input() public question!: QuestionEntity;
  @Input() public placeholder?: string;

  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;

  constructor() {}

  ngOnInit(): void {}
}
