<!--height is controlled by css: .modal-body-auto -->
<kendo-dialog
  title="Client Details"
  class="modal-title-light-orange"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="850"
>
  <div class="modal-body-auto">
    <div class="container-fluid" *ngIf="showErrorMessage">
      <div class="row">
        <div class="col-md-12">
          <div id="myModal" class="marginBottom10" role="dialog">
            <div class="">
              <!-- Modal content-->
              <div class="modal-content" style="border-color: #f1352b">
                <div class="modal-header" style="border-bottom-color: #f1352b">
                  <h4 class="modal-title chubbRed">
                    Please enter any of the search criteria
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="searchNewClient" class="padding5 masterPage">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <form role="form">
              <div class="form-group">
                <label
                  for="clientName_textBox"
                  class=""
                  [textContent]="'Mortgagor Name'"
                ></label>
                <div>
                  <input
                    id="clientName_textBox"
                    type="text"
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientName"
                    name="clientName"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form">
              <div class="form-group">
                <label
                  for="custIdentificationNo_textBox"
                  class=""
                  [textContent]="'Mortgagor ID Type'"
                ></label>
                <div>
                  <kendo-dropdownlist
                    [data]="docTypeList"
                    textField="text"
                    valueField="value"
                    [kendoDropDownFilter]="{
                      operator: 'contains'
                    }"
                    [valuePrimitive]="true"
                    [(ngModel)]="custDocType"
                    name="docType"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <form role="form">
              <div class="form-group">
                <label
                  for="clientCode_textBox"
                  class=""
                  [textContent]="'Client Code'"
                ></label>
                <div>
                  <input
                    id="clientCode_textBox"
                    type="text"
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientCode"
                    name="clientCode"
                  />
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-4">
            <form role="form">
              <div class="form-group">
                <label
                  for="mortgagorId_textBox"
                  class=""
                  [textContent]="'Mortgagor ID'"
                ></label>
                <div>
                  <input
                    id="mortgagorId_textBox"
                    type="text"
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientMortgagorId"
                    name="clientMortgagorId"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-4">
            <form role="form"></form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <form role="form">
              <div class="form-group">
                <label
                  for="clientAddress_textBox"
                  class=""
                  [textContent]="'Address'"
                ></label>
                <div>
                  <input
                    id="clientAddress_textBox"
                    type="text"
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientAddress"
                    name="clientAddress"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-4">
            <form role="form">
              <div class="form-group">
                <label
                  for="clientMobile_textBox"
                  class=""
                  [textContent]="'Mobile Number'"
                ></label>
                <div>
                  <input
                    id="clientMobile_textBox"
                    type="text"
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientMobileNumber"
                    name="clientMobileNumber"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-4">
            <form role="form">
              <div class="form-group">
                <label
                  for="clientEmail_textBox"
                  class=""
                  [textContent]="'Email'"
                ></label>
                <div>
                  <input
                    id="clientEmail_textBox"
                    type="text"
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientEmailAddress"
                    name="clientEmailAddress"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <form role="form">
              <button
                (click)="search($event)"
                event="1"
                class="btn chubbWhite chubbGreen_bg_btn confirmation_ok"
                kendoButton
              >
                <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
              </button>
            </form>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <hr />
                <div id="client-search-grid" class="grid-wrapper">
                  <kendo-grid
                    style="cursor: pointer"
                    [data]="gridView"
                    [pageSize]="pageSize"
                    [skip]="skip"
                    [pageable]="true"
                    [height]="gridHeight"
                    [resizable]="true"
                    (pageChange)="pageChange($event)"
                    [selectable]="true"
                    mode="multiple"
                    kendoGridSelectBy
                    [(selectedKeys)]="selectedRowIndexes"
                    (selectionChange)="gridSelectionChange($event)"
                    (cellClick)="cellClickHandler($event)"
                  >
                    <kendo-grid-column
                      field="clientCode"
                      title="Client Code "
                      [width]="150"
                    >
                    </kendo-grid-column
                    ><kendo-grid-column
                      field="clientName"
                      title="Customer Name"
                      [width]="150"
                    >
                    </kendo-grid-column>
                    <kendo-grid-column
                      field="addressDisplayText"
                      title="Address"
                      [width]="350"
                    >
                    </kendo-grid-column>
                    <!-- 
                      there're 2 fields in api, one is cin, another is cinNo
                      ~\Applications\AMS\Chubb.Ams.Web.Extensions\Models\API\Client\ClientModel.cs 
                    -->
                    <kendo-grid-column
                      field="cinNo"
                      title="Mortgagor ID"
                      [width]="140"
                    >
                    </kendo-grid-column>
                    <kendo-grid-column
                      field="clientTypeDisplayText"
                      title="Type"
                      [width]="70"
                    >
                    </kendo-grid-column>
                    <kendo-grid-column
                      field="mobileNumber"
                      title="Mobile Number"
                      [width]="150"
                    >
                    </kendo-grid-column>
                    <kendo-grid-column
                      field="emailAddress"
                      title="Email"
                      [width]="300"
                    >
                    </kendo-grid-column>
                    <ng-template
                      kendoPagerTemplate
                      let-totalPages="totalPages"
                      let-currentPage="currentPage"
                    >
                      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                      <kendo-pager-numeric-buttons
                        [buttonCount]="10"
                      ></kendo-pager-numeric-buttons>
                      <kendo-pager-info></kendo-pager-info>
                      <kendo-pager-next-buttons></kendo-pager-next-buttons>
                      <kendo-pager-page-sizes
                        [pageSizes]="pageSizes"
                      ></kendo-pager-page-sizes>
                    </ng-template>
                  </kendo-grid>
                  <div *ngIf="loading" class="k-i-loading"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        [hidden]="!isEditable"
        (click)="close('yes')"
        themeColor="primary"
      >
        Edit
      </button>
      <button
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel"
        kendoButton
        [disabled]="isDisabled"
        (click)="close('no')"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
