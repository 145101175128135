<form (ngSubmit)="onSubmit()" #heroForm="ngForm">
    <div class="container-fluid">
      <!-- Search filters -->
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">
            <div class="col-md-2">
                <label class="marginTop5" for="reportName"
                  >Report</label
                >
            </div>
            <div class="col-md-8">
                <kendo-dropdownlist
                id="reportName"
                [defaultItem]="defaultReportListItem"
                [kendoDropDownFilter]="{
                    operator: 'contains'
                }"
                [data]="reportList"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="reportModel.reportId"
                name="reportId"
                ></kendo-dropdownlist>
            </div>
          </div>
          </div>
      </div>
      <div class="row" >
        <button
          (click)="onExport()"
          class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn ml-1 marginTop50"
        >
          <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
        </button>
      </div>
    </div>
</form>