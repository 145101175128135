import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "../../partials/dialog/dialog-ref";
import { DIALOG_DATA } from "../../partials/dialog/dialog-tokens";

@Component({
  selector: "mf-contact-us-form",
  templateUrl: "./mf-contact-us-form.component.html",
  styleUrls: ["./mf-contact-us-form.component.scss"],
})
export class MfContactUsFormComponent implements OnInit {
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any
  ) {}

  ngOnInit(): void {}

  bizPackEmailLink = function (event: { preventDefault: () => void }) {
    event.preventDefault();
    var email = "CustomerService.SG@chubb.com";
    var mailto_link = "mailto:" + email;
    var windowPopup1 = window.open(mailto_link.substr(0, 2000), "emailWindow");

    // setTimeout(function () {
    //     if (angular.isDefined(windowPopup1) && windowPopup1 != null) {
    //         windowPopup1.close();
    //     }
    // }, 500);
  };

  public close(status: any) {
    this.dialogRef?.close(null);
  }
}
