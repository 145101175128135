<!-- #IBGrenewalReportFields -->
<form #heroForm="ngForm">
  <div id="IBGrenewalReportFields" class="container-fluid reportOption">
    <div class="row">
      <div class="col-md-6">
        <!-- Left search section -->
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="IBGrenewalPolicyNo">Policy No</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="IBGrenewalPolicyNo"
              placeholder="Policy No"
              [(ngModel)]="searchCriteria.policyNo"
              name="policyNo"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="IBGrenewalCIN">CIN</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="IBGrenewalCIN"
              placeholder="CIN (Customer Identification No)"
              [(ngModel)]="searchCriteria.cin"
              name="cin"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="IBGrenewalInsuredName"
              >Insured Name</label
            >
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="IBGrenewalInsuredName"
              placeholder="Insured Name"
              [(ngModel)]="searchCriteria.insuredName"
              name="insuredName"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5">Segment</label>
          </div>
          <div class="col-md-6">
            <kendo-multiselect
              placeholder="Select segment..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="segmentList"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.segment"
              name="segment"
            >
            </kendo-multiselect>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5">Transaction Type</label>
          </div>
          <div class="col-md-6">
            <kendo-multiselect
              placeholder="Select Transaction Type..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="transactionTypeOption"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.transType"
              name="transType"
            >
            </kendo-multiselect>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="IBGrenewalPolicyStatus"
              >Policy Status</label
            >
          </div>
          <div class="col-md-6">
            <kendo-multiselect
              placeholder="Select status..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="policyStatus"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.policyStatus"
              name="policyStatus"
            >
            </kendo-multiselect>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="IBGrenewalProposalStatus"
              >Proposal Status</label
            >
          </div>
          <div class="col-md-6">
            <kendo-multiselect
              id="IBGrenewalProposalStatus"
              placeholder="Select status..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="proposalStatus"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.proposalStatus"
              name="proposalStatus"
            >
            </kendo-multiselect>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="IBGrenewalMSIGNo"
              >MSIG Policy No</label
            >
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="IBGrenewalMSIGNo"
              placeholder="MSIG Policy No"
              [(ngModel)]="searchCriteria.MSIGNo"
              name="MSIGNo"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- Right search section -->
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="IBGrenewalStartDateFrom"
              >Policy Start Date
            </label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="IBGrenewalStartDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.startDateFrom"
              (valueChange)="on_searchStartDateFrom_change($event)"
              name="startDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="IBGrenewalStartDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="IBGrenewalStartDateTo"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.startDateTo"
              (valueChange)="on_searchStartDateTo_change($event)"
              name="startDateTo"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="IBGrenewalExpiryDateFrom"
              >Policy Expiry Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="IBGrenewalExpiryDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.expiryDateFrom"
              (valueChange)="on_searchExpiryDateFrom_change($event)"
              name="expiryDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="IBGrenewalExpiryDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="IBGrenewalExpiryDateTo"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.expiryDateTo"
              (valueChange)="on_searchExpiryDateTo_change($event)"
              name="expiryDateTo"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="IBGrenewalBoundDateFrom"
              >Bound Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="IBGrenewalBoundDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.boundDateFrom"
              (valueChange)="on_searchBoundStartDateFrom_change($event)"
              name="boundDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="IBGrenewalBoundDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="IBGrenewalBoundDateTo"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.boundDateTo"
              (valueChange)="on_searchBoundDateTo_change($event)"
              name="boundDateTo"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="IBGrenewalEffectiveDateFrom"
              >Effective Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="IBGrenewalEffectiveDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.effectiveDateFrom"
              name="effectiveDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="IBGrenewalEffectiveDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="IBGrenewalEffectiveDateTo"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.effectiveDateTo"
              name="effectiveDateTo"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="IBGrenewalBankRefNo"
              >Bank Reference No</label
            >
          </div>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              id="IBGrenewalBankRefNo"
              placeholder="Bank Reference No"
              [(ngModel)]="searchCriteria.bankReferenceNo"
              name="bankReferenceNo"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="IBGrenewalpolicyProduct "
              >Product</label
            >
          </div>
          <div class="col-md-9">
            <kendo-multiselect
              id="IBGrenewalpolicyProduct"
              placeholder="Select product..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="userProductList"
              textField="pd"
              valueField="pdid"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.productId"
              name="productId"
            >
            </kendo-multiselect>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="IBGrenewalpostalCode"
              >Postal Code</label
            >
          </div>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              id="IBGrenewalpostalCode"
              placeholder="Risk Address Postal Code"
              [(ngModel)]="searchCriteria.postalCode"
              name="postalCode"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button
        (click)="search()"
        class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
      >
        <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
      </button>
      <button
        (click)="download()"
        class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
      >
        <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
      </button>
    </div>
  </div>
</form>
<br />
<div class="grid-wrapper ml-2">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column field="SrNo" title="S/N" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column field="PolicyNo" title="Policy No" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="MSIGPolicyNo"
      title="MSIG Policy No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="RMName" title="RM Name" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="BUPCCode" title="BUPC Code" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column
      field="BankReferenceNo"
      title="Bank Reference No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="BorrowerCIN" title="Borrower CIN" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="NameOfInsured"
      title="Name Of Insured"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="AdditionalInsureds"
      title="Names other than Insured"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="CorrespondenceAddress"
      title="Correspondence Address"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="RiskAddress" title="Risk Address" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="PolicyInceptionDate"
      title="Policy Inception Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="PolicyExpiryDate"
      title="Policy Expiry Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="NoOfFreeYears"
      title="No. of Years - Free Insurance"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column field="SumInsured" title="Sum Insured" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="GrossPremium"
      title="Gross Premium (Excl. GST)"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="GST" title="GST" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="TotalPremium"
      title="Total Premium (Gross Premium + GST)"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="TransactionType"
      title="Transaction Type"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="TransactionDate"
      title="Transaction Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="RenewableMethod"
      title="Renewable Method"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="Occupation" title="Occupation" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column
      field="OccupancyRates"
      title="Occupancy Rates"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="OccupiedAs" title="Occupied As" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="NatureOfBusiness"
      title="Nature Of Business"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="UsageOfPremises"
      title="Usage Of Premises"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="TypeOfProperty"
      title="Type of Property"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="OccupiedAs" title="Occupied As" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="TypeOfEquipment"
      title="Type Of Equipment"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="CommissionPerc"
      title="Commission %"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="Commission" title="Commission" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="GSTOnCommission"
      title="GST On Commission"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="PremiumRates" title="Premium Rates" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="OpenItemStatus"
      title="Open Item Status"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="Remarks" title="Remarks" [width]="160">
    </kendo-grid-column>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
