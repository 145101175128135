export class LstRateEntity {
  ID: any;
  LSTRICode: DataField;
  ReferencePercentage: DataField;
  RICommissionPercentage: DataField;
  EffectiveStartDate: DataField;
  EffectiveEndDate: any;
  SortOrder: any;
  CreatedBY: any;
  Remarks: any;
  IsExternalParty: boolean;
  IsDirty?: boolean;

  constructor(
    id?: any,
    lstRICode?: any,
    referencePercentage?: any,
    riCommissionPercentage?: any,
    effectiveStartDate?: Date,
    effectiveEndDate?: Date,
    sortOrder?: any,
    createdBY?: any,
    remarks?: any,
    isExternalParty?: boolean
  ) {
    this.ID = id;
    this.LSTRICode = new DataField(lstRICode, "LSTRICode", "LSTRICode" + id);
    this.ReferencePercentage = new DataField(
      referencePercentage,
      "referencePercentage",
      "referencePercentage" + sortOrder
    );
    this.RICommissionPercentage = new DataField(
      riCommissionPercentage,
      "riCommissionPercentage",
      "riCommissionPercentage" + sortOrder
    );
    this.EffectiveStartDate = new DataField(
      effectiveStartDate,
      "effectiveStartDate",
      "effectiveStartDate" + sortOrder
    );
    this.EffectiveEndDate = effectiveEndDate;
    this.SortOrder = sortOrder;
    this.CreatedBY = createdBY;
    this.Remarks = remarks;
    this.IsExternalParty = isExternalParty!;

    this.IsDirty = false;
  }
}

export class DataField {
  Value: any;
  Name: any;
  Field: any;
  ValidationField: any;
  OldValue: any;

  IsDirty: boolean;

  // IsRequired: DataCheck;
  // IsValid: DataCheck;

  constructor(value: any, name: any, field: any) {
    this.Value = value;
    this.OldValue = value;
    this.Name = name;
    this.Field = field;
    this.IsDirty = false;
  }

  CheckDirty() {
    if (typeof this.Value == "object" && this.Value instanceof Date) {
      this.IsDirty =
        this.Value.getDate() != this.OldValue.getDate() ||
        this.Value.getMonth() != this.OldValue.getMonth() ||
        this.Value.getFullYear() != this.OldValue.getFullYear();
    } else {
      this.IsDirty = this.Value != this.OldValue;
    }

    return this.IsDirty;
  }

  private addInvalidClass(element: any) {
    // $(element).addClass("invalid");
  }

  private removeInvalidClass(element: any) {
    // $(element).removeClass("invalid");
  }
}

export class DataCheck {
  // CheckType: DataCheckType;
  Regex: any;
}

export enum DataCheckType {
  Required = 1,
  Format = 2,
  Custom = 3,
}
