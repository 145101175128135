import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { BaseReconcileListComponent } from "../../new-product/base/base-reconcile-list/base-reconcile-list.component";

@Component({
  selector: "app-reconcile-list",
  templateUrl: "./reconcile-list.component.html",
  styleUrls: ["./reconcile-list.component.scss"],
})
export class ReconcileListComponent
  extends BaseReconcileListComponent
  implements OnInit {}
