<kendo-dialog [minWidth]="250" [width]="600" [height]="150">
  <div class="container-fluid-custom">
    <div class="modal-header-custom">
      <h3 class="modal-title-custom chubbRed_bg">{{ titleMessage }}</h3>
    </div>
    <div class="modal-body">
      <p>{{ bodyMessage }}</p>
      <div class="progress-bar-container">
        <div
          class="progress-bar progress-bar-striped bg-warning"
          role="progressbar"
          [attr.aria-valuenow]="timeRemaining"
          [style.width]="(timeRemaining / warningTime) * 100 + '%'"
          aria-valuemin="0"
          aria-valuemax="100"
          style="width: 100%"
        >
          {{ timeRemaining }}
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>
