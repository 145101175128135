import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { Subject } from "rxjs";

/** To notify http event trigger */
export class ButtonNotifier {
  /** Notify http request event */
  start_notifier$: Subject<KeyValue<any>> = new Subject<KeyValue<any>>();

  /** Notify http response event */
  end_notifier$: Subject<KeyValue<any>> = new Subject<KeyValue<any>>();
}

export const buttonNotifier = new ButtonNotifier();
