import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SessionStorageService } from "../services/storages/session-storage.service";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private sessionStorageService: SessionStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isTokenAccessRequires(req)) {
      // get auth token from local session storage
      const authData = this.sessionStorageService.get("authorizationData");
      if (authData) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${authData.token}`,
            Session: authData.sessionToken,
          },
        });
      }
    }

    return next.handle(req);
  }

  private isTokenAccessRequires = (config: { url: string }) => {
    /** Check if url is not login request, and it's api consuming request.
     *
     * Because we only need to add token bearer header to api consuming request,
     * other request like html content(partial.html) request is not requires to bear the token data
     */

    return (
      config.url.toLowerCase().indexOf("/token") === -1 &&
      config.url.toLowerCase().indexOf(environment.serviceBase.toLowerCase()) >
        -1
    );
  };
}
