<div
  *ngIf="!question!.readOnly"
  id="qs_fire_building"
  [ngClass]="{ 'st-danger': question!.theme === 1 }"
>
  <kendo-numerictextbox
    id="currency1_numeric"
    type="text "
    [(ngModel)]="QuestionAnswerFormatted"
    [spinners]="false"
    [format]="'c0'"
    [decimals]="0"
    *ngIf="condition"
    [min]="0"
    class="textAlignCenter"
  ></kendo-numerictextbox>
  <label
    id="currency1"
    class="textAlignCenter"
    title="currency"
    [textContent]="question!.answer | ausCurrency"
    *ngIf="!condition"
    style="font-weight: normal"
  ></label>
</div>
<div *ngIf="question!.readOnly">
  <span class="textAlignCenter" id="currency2_numeric" title="currency ">{{
    question!.answer | ausCurrency
  }}</span>
</div>
