import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";

@Component({
  selector: "app-base-listing",
  templateUrl: "./base-listing.component.html",
  styleUrls: ["./base-listing.component.scss"],
})
export class BaseListingComponent extends BaseComponent {
  advancedSearchCaption = "More Info";

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService
  ) {
    super(router, broadcastService, identityRolesService);
  }

  toggleCaption() {
    this.advancedSearchCaption =
      this.advancedSearchCaption === "More Info" ? "Less Info" : "More Info";
  }
}
