export class ProposalEntity {
  DeclineReason!: string;
  command!: number;
  fileIdsJsonString!: string;
  isManual!: boolean;
  isPasswordProtected!: boolean;
  isReprint!: boolean;
  isSummary!: boolean;
  messageId!: number;
  printCorrespondence!: string;
  printType!: number; //better use number for keyValue pair for kendo-dropdownlist
  proposalId!: string;

  contactInfo!: string; //extra
  emailId!: string; //extra
  policyNo!: string; //extra
  insuredName!: string; //extra
  mobileNo!: string; //extra
  quoteNo!: string; //extra
  printEmailValidation!: string; //extra
  isInvalidEmailAddress: boolean = false; //extra
}
