<!-- #cbgDailyDownloadFields -->
<div id="cbgDailyDownloadFields" class="container-fluid reportOption">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-2">
          <label class="mt-1">User Team</label>
        </div>
        <div class="col-md-10">
          <kendo-multiselect
            placeholder="Select user team..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="userTeamOptions"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.userTeams"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-offset-1 col-md-3">
          <label class="mt-1">Transaction Date</label>
        </div>
        <div class="col-md-4">
          <kendo-datepicker
            class="maxWidth"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="searchCriteria.dateFrom"
            (valueChange)="on_dailyReportDateFrom_change($event)"
          ></kendo-datepicker>
        </div>
        <div class="col-md-1 textAlignCenter">
          <label class="mt-1" for="dailyReportDateTo">to</label>
        </div>
        <div class="col-md-4">
          <kendo-datepicker
            id="dailyReportDateTo"
            #dailyReportDateTo
            class="maxWidth"
            [min]="dailyReportDate_min"
            [max]="dailyReportDate_max"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="searchCriteria.dateTo"
          ></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-2">
          <label class="mt-1">Segment</label>
        </div>
        <div class="col-md-10">
          <kendo-multiselect
            placeholder="Select segment..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="segmentList"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.segments"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-offset-3 col-md-3">
          <label class="mt-1" for="reportProduct">Product</label>
        </div>
        <div class="col-md-offset-2 col-md-9">
          <kendo-multiselect
            id="reportProduct"
            placeholder="Select product..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="userProductList"
            textField="pd"
            valueField="pdid"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.productIds"
            name="productIds"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      (click)="download()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
    </button>
  </div>
</div>
