import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { SummaryInvoiceAutoDebitSearchCriteria } from "./summary-invoice-auto-debit.model";
import { SummaryInvoiceAutoDebitEntity } from "../preset-report.model";
import { proposalCommon } from "../../../base/utils/proposal-common";

import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
@Component({
  selector: "app-summary-invoice-auto-debit-report",
  templateUrl: "./summary-invoice-auto-debit.component.html",
  styleUrls: ["./summary-invoice-auto-debit.component.scss"],
})
export class SummaryInvoiceAutoDebitComponent implements OnInit {
  @Output() warningZoneEvent = new EventEmitter<any>();
  public searchCriteria = new SummaryInvoiceAutoDebitSearchCriteria();
  public showReportType: boolean = false;
  public isCitiProduct: boolean = false;
  public productRadioControl!: string;
  public loading!: boolean;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  public listing: SummaryInvoiceAutoDebitEntity[] = [];
  private totalListing: number = 0;
  submitted = false;
  public segmentList!: Array<{ text: string; value: string }>;

  constructor(
    private presetExportService: PresetExportService,
    private excelTemplateService: ExcelTemplateService
  ) {}
  ngOnInit(): void {
    this.segmentList = [
      { text: "CBG", value: "CBG" },
      { text: "IBG", value: "IBG" },
      { text: "PB / TPC", value: "PB,TPC" },
    ];
    this.defaultSummaryInvoiceAutoDebitSearchCriteria();
  }

  private defaultSummaryInvoiceAutoDebitSearchCriteria(): void {
    this.searchCriteria.segmentDetails = "CBG";
    //this.summaryInvoiceAutoDebitGrid.dataSource.data([]);
  }
  private validateSummaryInvoiceAutoDebitMandatoryFields(): boolean {
    let isValid: boolean = false;
    // dates
    const dates: boolean = this.searchCriteria.month !== undefined;
    isValid = dates; // || etc...
    return isValid;
  }

  public download(): void {
    if (this.validateSummaryInvoiceAutoDebitMandatoryFields()) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.downloadSummaryInvoiceAutoDebitReport();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a month.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  public gridSelectionChange(selection: SelectionEvent) {
    // console.log("gridSelectionChanged");
  }
  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    // console.log("cell clicked");
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }
  public sort: SortDescriptor[] = [
    {
      field: "policyCertificateNo",
      dir: "asc",
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }
  private loadData(): void {
    this.gridView = {
      data: orderBy(this.listing, this.sort),
      total: this.listing.length,
    };
  }
  private refresh(): void {
    this.loading = true;
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "policyCertificateNo");
    formData.append("sort[0][dir]", "asc");

    let date = new Date(
      this.searchCriteria.month.getFullYear(),
      this.searchCriteria.month.getMonth(),
      new Date().getDate()
    );

    let array = [
      ["segmentDetails", "eq", this.searchCriteria.segmentDetails],
      ["month", "eq", date],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }
    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    this.presetExportService
      .summaryInvoiceAutoDebitSearch(querystring)
      .subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            this.listing = result.body.d?.results;
            this.totalListing = result.body.d?.__count;
            this.loadItems();
            this.loading = false;
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  private loadItems(): void {
    this.gridView = {
      data: orderBy(this.listing, this.sort), //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }
  public search(): void {
    const isValid = this.validateSummaryInvoiceAutoDebitMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a month.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private formatDate(date: Date): string {
    return (
      proposalCommon.translateMonth(date.getMonth() + 1) +
      " " +
      date.getFullYear().toString()
    );
  }
  private downloadSummaryInvoiceAutoDebitReport() {
    const month: number = this.searchCriteria.month.getMonth() + 1;
    const year: number = this.searchCriteria.month.getFullYear();

    this.presetExportService
      .summaryInvoiceAutoDebit(month, year, this.searchCriteria.segmentDetails)
      .subscribe((response: any) => {
        if (
          response != undefined &&
          response.body != undefined &&
          response.body != null
        ) {
          this.excelTemplateService.downloadsummaryInvoiceAutoDebitReport(
            summaryInvoiceColumn,
            "Summary Invoice Auto Debit",
            "SummaryInvoiceAutoDebit " +
              this.formatDate(this.searchCriteria.month),
            response.body.d?.results,
            this.searchCriteria.segmentDetails
          );
        }
      });
  }
}
const summaryInvoiceColumn: string[] = [
  "SI No",
  "Meridian Sequence No",
  "Policy Certificate No",
  "Master Policy No",
  "Policy No",
  "Entry Date",
  "Gross Premium",
  "GST",
  "Total Premium",
  "Open Item Status",
  "Invoice No",
  "Saved By",
  "BU PC Code",
  "Bank Ref",
  "Insured Name",
  "Effective Date",
  "Expiry Date",
  "Sum Insured",
  "Risk Address",
  "Insured NRIC",
  "Transaction Type",
  "Product",
  "Contract Type",
  "Policy Status",
  "Transaction date",
];
