import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { proposalCommon } from "src/app/components/new-product/base/utils/proposal-common";
import { openItemDisplayStatus } from "src/app/finance/finance-constant";
import { common } from "src/app/framework/utils/common";
import { BatchInvoiceListService } from "src/app/services/batch-invoice-list.service";
import { NotifierService } from "src/app/services/notifier.service";
import { BatchInvoiceEntity } from "../models/batch-invoice-list.model";
@Component({
  selector: "app-batch-invoice-details",
  templateUrl: "./batch-invoice-details.component.html",
})
export class BatchInvoiceDetailsComponent implements OnInit {
  @Input() public opened = false;
  @Input() openItemId!: string;
  @Output() closeDialogEvent = new EventEmitter<any>();
  private listing: BatchInvoiceEntity[] = [];
  public displayPolicyCertificateNo!: string;
  public gridView!: GridDataResult;

  @ViewChild("appendTo", { read: ViewContainerRef, static: false })
  public appendTo!: ViewContainerRef;

  constructor(
    private batchInvoiceListService: BatchInvoiceListService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.getBatchInvoiceHistory();
  }
  private getBatchInvoiceHistory(): void {
    this.batchInvoiceListService
      .getBatchInvoiceHistory(this.openItemId)
      .subscribe(
        (result: BatchInvoiceEntity[]) => {
          if (common.isDefined(result)) {
            this.displayPolicyCertificateNo = result[0].policyCertificateNumber;
            this.listing = result;
            this.loadItems();
            this.notifier.success("History search successfully", this.appendTo);
          }
        },
        (err: any) => {
          this.notifier.error("History search failed", this.appendTo);
        }
      );
  }
  public close(status: any) {
    this.opened = false;
    this.closeDialogEvent.emit();
  }
  private loadItems(): void {
    this.listing.forEach((entity) => {
      entity.statusDisplayText = openItemDisplayStatus[entity.statusId];
      if (entity.autoDebitMonth !== 0) {
        entity.autoDebitMonthDisplayText = proposalCommon.translateMonth(
          entity.autoDebitMonth
        );
      }
      entity.chequeIssuanceBank = !common.isStringNullOrEmpty(
        entity.manualPaymentChequeIssuanceBank
      )
        ? entity.manualPaymentChequeIssuanceBank
        : !common.isStringNullOrEmpty(entity.refundPaymentChequeIssuanceBank)
        ? entity.refundPaymentChequeIssuanceBank
        : "";

      entity.chequeNumber = !common.isStringNullOrEmpty(
        entity.manualPaymentChequeNumber
      )
        ? entity.manualPaymentChequeNumber
        : !common.isStringNullOrEmpty(entity.refundPaymentChequeNumber)
        ? entity.refundPaymentChequeNumber
        : "";
      entity.remarks = !common.isStringNullOrEmpty(entity.manualPaymentRemarks)
        ? entity.manualPaymentRemarks
        : !common.isStringNullOrEmpty(entity.refundPaymentRemarks)
        ? entity.refundPaymentRemarks
        : "";
    });

    this.gridView = {
      data: this.listing, //.slice(this.skip, this.skip + this.pageSize),
      total: this.listing?.length,
    };
  }
}
