import * as Enums from "./../../../framework/enum.shared";

import { ExceptionManager } from "./../../../framework/utils/exception-manager";
import { GUID } from "./../../../framework/domain-entity/guid";
import { KeyValue } from "./../../../framework/domain-entity/key-value";
import { common } from "./../../../framework/utils/common";
import { proposalConst } from "./../const/proposal-const";
import { questionNotifier } from "./../notifier/question-notifier";

export class QuestionEntity {
  // private variable for 'answer' property
  private answerValue: any;
  /** v. Value to the answer */
  get answer(): any {
    return this.answerValue;
  }
  set answer(value: any) {
    this.answerDataTypeGuard(value);
    this.isAnswerDirty(value);

    this.answerValue = value;

    questionNotifier.answer_change_notifier$.next(new KeyValue(this.key, this));
  }

  // private variable for 'answerDisplayText' property
  private answerDisplayTextValue!: string;
  /** answer readonly display text */
  get answerDisplayText(): string {
    if (common.isUndefinedOrNull(this.answer)) {
      return proposalConst.questionUnansweredDisplayText;
    }

    if (!common.isUndefinedOrNull(this.options)) {
      return this.optionsKeyDict?.get(this.answer)?.[0]; //this.optionsKeyDict[this.answer];//todo
    }

    if (common.isDate(this.answer)) {
      return this.answer.toLocaleString();
    }

    if (common.isObject(this.answer) || common.isArray(this.answer)) {
      return common.objectToJson(this.answer);
    }

    return this.answer.toString();
  }

  // private variable for 'answerDisplayText' property
  public optionsValue!: KeyValue<any>[];
  private optionsKeyDict: Map<string, any[]> = new Map<string, any[]>(); // object;
  /** options list to be used by combo-box drop-down-list true-false etc... */
  get options(): KeyValue<any>[] {
    return this.optionsValue;
  }

  set options(value: KeyValue<any>[]) {
    this.optionsValue = []; //undefined;
    this.optionsKeyDict = new Map<string, any[]>(); //{};

    if (!common.isUndefinedOrNull(value) && value.length > 0) {
      this.optionsValue = value;
      value.forEach((kv) => {
        //todo: check if this works
        //https://stackoverflow.com/questions/40358434/typescript-ts7015-element-implicitly-has-an-any-type-because-index-expression
        let y = this.optionsKeyDict?.get(kv.value)?.[0];
        if (y) {
          y = kv.key;
        }
        //this.optionsKeyDict[kv.value] = kv.key;
      });
    }
  }

  answerDataType: Enums.AnswerDataType | undefined;
  /** Original version of the "answer" property while the question instantiated */
  answerOriginalVersion: any;
  /** dv. Default answer */
  defaultAnswer?: string;
  /** flag to indicate if "answer" property value has been changed */
  isDirty: boolean;
  /** k. Question name */
  key: string;
  /** Question name for compare (key.trim().toLowerCase()) */
  keyTrimToLower?: string;
  /** ro. Read only flag */
  readOnly?: boolean = undefined;
  /** t. Label/ Text to display */
  displayText?: string = undefined;
  /** c. Question Control type. Front end take it as data type of the question answer */
  controlType?: Enums.UiControlType = undefined;
  /** i. Question additional info. Usually contains multi select options */
  additionalInfo?: any = undefined;
  /** m. Value text maximum length */
  maxLength?: number = undefined;
  /** mv. Value maximum allowed amount */
  maxValue?: number | Date = undefined;
  /** mnv. Value minimum allowed amount */
  minValue?: number | Date = undefined;
  /*step count - eg, incremental by 1000*/
  step?: number;
  /** decimal number */
  decimals?: number;
  /** r. Flag to determine if answer is mandatory */
  required?: boolean = undefined;
  /** nt. Question number */
  numberText?: string = undefined;
  /** qpid. QuestionPanelId */
  questionPanelId?: GUID = undefined;
  /** sid. Question section number */
  sectionId?: number = undefined;
  /** ig. Flag to determine if this question ignored by front end */
  isIgnore?: boolean = undefined;
  /** flag for hide and show */
  isVisible?: boolean = true;
  /** flag for disabling question */
  isDisabled?: boolean = false;
  /** extra additional attribute */
  attributes?: any;
  /** question field info */
  fieldInfo?: string;
  /** question info / place holder */
  questionInfo?: string;
  /** question located tab's index */
  tabId: number;
  /** theme */
  theme: Enums.QuestionTheme = Enums.QuestionTheme.Normal;

  constructor(
    key: string,
    answer: any,
    answerDataType?: Enums.AnswerDataType,
    options?: KeyValue<any>[]
  ) {
    this.key = key;
    this.keyTrimToLower = this.key.trim().toLowerCase();

    if (common.isDefined(answerDataType)) {
      this.answerDataType = answerDataType;
    } else {
      this.answerDataType = Enums.AnswerDataType.String;
    }

    this.answerValue = answer;
    this.answerOriginalVersion = answer;
    this.options = options !== undefined ? options : [];
    this.isDirty = false; // default isDirty flag to false
    this.tabId = 0;
  }

  /** Method to check if the question has been answered */
  isAnswered(): boolean | undefined {
    // return false if answer is totally undefined
    if (common.isUndefinedOrNull(this.answer)) {
      return false;
    }

    if (common.isString(this.answer)) {
      return this.answer.length > 0;
    } else if (common.isNumber(this.answer)) {
      return true;
    } else if (common.isBoolean(this.answer)) {
      return true;
    } else if (common.isDate(this.answer)) {
      return true;
    } else if (common.isObject(this.answer)) {
      return !common.isEmptyObject(this.answer);
    } else if (common.isArray(this.answer)) {
      return this.answer.length > 0;
    }

    ExceptionManager.error("Answer Data Type not handled");
    return undefined;
  }

  /** Method to read and convert the answer into string.
   * Answer value will be JSON.stringy() if it's an object.
   */
  readAnswerAsString(): string | undefined {
    // return undefined if answer is totally undefined
    if (common.isUndefinedOrNull(this.answer)) {
      return undefined;
    }

    if (common.isString(this.answer)) {
      return this.answer;
    } else if (common.isNumber(this.answer)) {
      return this.answer.toString();
    } else if (common.isBoolean(this.answer)) {
      return this.answer ? "True" : "False";
    } else if (common.isDate(this.answer)) {
      return this.answer.toString();
    } else if (common.isObject(this.answer) || common.isArray(this.answer)) {
      return common.objectToJson(this.answer);
    }

    ExceptionManager.error("Answer Data Type not handled");
    return undefined;
  }

  /** Method to read and convert the answer into back-end Api expected string value */
  readAnswerAsApiValue(): string | undefined {
    // return undefined if answer is totally undefined
    if (common.isUndefinedOrNull(this.answer)) {
      return undefined;
    }

    if (common.isString(this.answer)) {
      return this.answer;
    } else if (common.isNumber(this.answer)) {
      return this.answer.toString();
    } else if (common.isBoolean(this.answer)) {
      return this.answer ? "true" : "false";
    } else if (common.isDate(this.answer)) {
      const year = this.answer.getFullYear();
      const month = this.answer.getMonth() + 1;
      const day = this.answer.getDate();
      return `${year}-${month}-${day} 12:00:00 AM`;
    } else if (common.isObject(this.answer) || common.isArray(this.answer)) {
      return common.objectToJson(this.answer);
    }

    ExceptionManager.error("Answer Data Type not handled");
    return undefined;
  }

  /** Method to read numeric question type value
   * Return "0" if answer undefined or empty
   */
  readAnswerAsNumeric(): number | undefined {
    switch (this.answerDataType) {
      case Enums.AnswerDataType.String:
      case Enums.AnswerDataType.Numeric:
        if (common.isUndefinedOrNull(this.answer)) {
          return 0;
        }

        return parseFloat(this.answer);
      default:
        ExceptionManager.error("Data type is not numeric");
        return undefined;
    }
  }

  /** Method to read the answer */
  readAnswer<T>(): T {
    return <T>this.answer;
  }

  /** Method to clear the answer */
  clearAnswer(): void {
    this.answer = undefined;
  }

  /** Method to reset to default to answer if applicable.
   * ClearAnswer() will be executed if default value not available
   */
  resetToDefaultAnswer(): void {
    if (!common.isUndefinedOrNull(this.defaultAnswer)) {
      this.answerValue = this.defaultAnswer;
    } else {
      this.clearAnswer();
    }
  }

  /** Method to guard data type of answer value to be set */
  private answerDataTypeGuard(value: any) {
    // answer data type guard
    if (!common.isUndefinedOrNull(value)) {
      const invalidDataTypeMsg = "[" + this.key + "] invalid answer data type";
      switch (this.answerDataType) {
        case Enums.AnswerDataType.Numeric:
        case Enums.AnswerDataType.String:
          if (common.isDate(value)) {
            ExceptionManager.invalidArgument(value, invalidDataTypeMsg);
          }
          break;

        case Enums.AnswerDataType.Date:
          if (!common.isDate(value)) {
            ExceptionManager.invalidArgument(value, invalidDataTypeMsg);
          }
          break;

        case Enums.AnswerDataType.Boolean:
          // temporarily fix - to be checked with CM
          if (!common.isBoolean(value)) {
            value = value.toLowerCase() === "true" ? true : false;
          }

          if (!common.isBoolean(value)) {
            ExceptionManager.invalidArgument(value, invalidDataTypeMsg);
          }
          break;

        case Enums.AnswerDataType.Object:
          if (!common.isObject(value)) {
            ExceptionManager.invalidArgument(value, invalidDataTypeMsg);
          }
          break;

        default:
          ExceptionManager.invalidArgument(value, "Data type is not numeric");
          break;
      }
    }
  }

  /** Method to set isDirty flag */
  private isAnswerDirty(value: any) {
    // "string" | "number" | "boolean" | "symbol" | "undefined" | "object" | "function"
    const typeStr = typeof value;

    // check if value is different from "answerOriginalVersion"
    // to set isDirty flag
    switch (typeStr) {
      case "string":
      case "number":
      case "boolean":
      case "undefined":
        if (value !== this.answerOriginalVersion) {
          this.isDirty = true;
        } else {
          this.isDirty = false;
        }
        break;

      case "object":
        // TODO: find a better way to compare object
        // for now, set all object answer type changes to dirty if answer setter invoked
        this.isDirty = true;
        break;

      case "symbol":
      case "function":
      default:
        ExceptionManager.invalidArgument(value, "Data type is not numeric");
        break;
    }
  }
}
