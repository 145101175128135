import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
} from "@progress/kendo-angular-grid";
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { NotifierService } from "src/app/services/notifier.service";
import { PolicyWordingSetupService } from "src/app/services/policy-wording-setup.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { Global } from "src/app/shared/global";
import {
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import {
  MdConfirmationMessageV2Component,
  v2DlgInputArgs,
} from "../../shared/partials/md-confirmation-message-v2/md-confirmation-message-v2.component";
import { dlgModelArgs } from "../../shared/partials/md-confirmation-message-v6/md-confirmation-message-v6.component";
import { PolicyTypeEntity, PolicyWordingEntity } from "./policy-wording.entity";
@Component({
  selector: "app-policy-wording-setup",
  templateUrl: "./policy-wording-setup.component.html",
  styleUrls: ["./policy-wording-setup.component.scss"],
})
export class PolicyWordigSetupComponent implements OnInit {
  constructor(
    private policyWordingSetUpService: PolicyWordingSetupService,
    private uploadService: UploadExcelService,
    private notifier: NotifierService,
    private excelTemplate: ExcelTemplateService,
    private cdkDialogService: CdkDialogService
  ) {}

  @ViewChild("appendTo", { read: ViewContainerRef, static: false })
  public appendTo!: ViewContainerRef;

  @ViewChild("policyForm", { static: false })
  private policyForm!: UntypedFormGroup;

  @ViewChild("effectiveDate", { static: false })
  public effectiveDate!: DatePickerComponent;

  public minEffectiveDate!: Date;
  public maxEffectiveDate = new Date(2999, 12, 31);
  _minTransactionDate: Date = new Date("2017-01-01T00:00:00");
  public productTypeList!: Array<{ text: string; value: string }>;
  public documentVersion!: Array<{ text: string; value: string }>;
  policyWording = new PolicyWordingEntity();
  lstPolicyWording: PolicyWordingEntity[] = [];
  policyType!: PolicyTypeEntity[];
  documentTitleList!: PolicyWordingEntity[];
  isUpdate: boolean = false;
  documentName: any;
  documentChange: boolean = false;

  //effective date validation
  dateValidationMsg: string = "";
  //document title validation
  docTitleValidationMsg: string = "";
  //upload excel

  uploadPdf!: any;
  excelFilesErrorMessage: string = "";
  hasError: boolean = false;
  isProcessing: boolean = false;

  //pagination
  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  public totalListing: number = 0;

  public selectedRowIndexes: number[] = [];
  public loading!: boolean;

  // Error logs
  errorLogs!: { Title: string; Logs: Array<string> };

  ngOnInit(): void {
    // Init Error Log
    if (Global.isUndefined(this.errorLogs)) {
      this.errorLogs = { Title: "", Logs: new Array<string>() };
    }
    this.productTypeList = [
      { text: "CBG", value: "CBG" },
      { text: "IBG", value: "IBG" },
      { text: "HDB", value: "HDB" },
      { text: "PTE", value: "PTE" },
      { text: "CDO", value: "CDO" },
    ];
    this.documentVersion = [
      { text: "New", value: "New" },
      { text: "Existing", value: "Existing" },
    ];
    this.fetchPolicyTypeList();
    this.getDocumentTitleList();
  }
  excelAndSizeRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf"],
    maxFileSize: 5242880, // Check for each file size less than 5MB, 1024 * 1024 * 5
  };
  public resetFiles(): void {
    //todo: currently only allow single file upload
    // Clear uploaded file once, the file is successfuly uploaded.
    this.uploadPdf = {};
  }

  public removeEvent(e: RemoveEvent): void {
    this.resetFiles();
  }

  public select(e: SelectEvent): void {
    this.resetFiles();
    this.hasError = false;

    let totalFileSize = this.getUploadedClaimFilesTotalSize(e.files);
    if (totalFileSize / 1024 / 1024 > 5) {
      this.hasError = true;
      this.excelFilesErrorMessage = "Total file size allowed in a claim is 5.";
    } else {
      const uploadPdf: File[] = new Array<File>();
      e.files.forEach((file: FileInfo) => {
        //this already check extension & individual file size by kendo control itself
        if (!file.validationErrors && file.rawFile) {
          uploadPdf.push(file.rawFile);
        }
      });
      if (this.uploadPdf != undefined && this.uploadPdf != null) {
        this.excelFilesErrorMessage = "";
        this.uploadPdf = uploadPdf;
        this.policyWording.files = this.uploadPdf;
      }
    }
  }
  getUploadedClaimFilesTotalSize(files: FileInfo[]): number {
    let totalFileSize: number = 0;

    files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        totalFileSize += file.rawFile.size;
      }
    });

    return totalFileSize;
  }
  private fetchPolicyTypeList() {
    this.policyWordingSetUpService
      .getPolicyTypeList()
      .subscribe((response: PolicyTypeEntity[]) => {
        if (this.policyWording.productType == undefined) {
          this.policyType = response;
        } else {
          this.policyType = response.filter(
            (x) => x.productType == this.policyWording.productType
          );
        }
      });
  }
  private getDocumentTitleList() {
    this.policyWordingSetUpService
      .getDocumentTitleList()
      .subscribe((response: PolicyWordingEntity[]) => {
        this.documentTitleList = response;
      });
  }
  public onTitleChange(e: any): void {
    if (this.isUpdate) {
      this.policyWording.documentTitle = this.documentTitleList.filter(
        (x) =>
          x.policyWordingDocumentSetupId ==
          this.policyWording.policyWordingDocumentSetupId
      )[0].documentTitle;
      let titleList = this.documentName.split("/policyWording/");
      this.documentName =
        titleList[0] + "/policyWording/" + this.policyWording.documentTitle;
    }
  }
  public downloadDocument(policyWording: PolicyWordingEntity): void {
    this.policyWordingSetUpService.downloadDocument(
      policyWording.documentTitle
    );
  }

  public onSubmit(): void {}
  add(): void {
    let hasError = false;
    this.errorLogs.Logs = [];
    this.dateValidationMsg = "";
    this.docTitleValidationMsg = "";
    this.excelFilesErrorMessage = "";
    if (this.policyWording.productType == null) {
      this.errorLogs.Logs.push("Please select a product type.");
      hasError = true;
    } else if (this.policyWording.policyType == null) {
      this.errorLogs.Logs.push("Please policy type.");
      hasError = true;
    } else if (this.policyWording.startEffectiveDate == null) {
      this.errorLogs.Logs.push("Please enter start effective date.");
      hasError = true;
    } else if (
      !this.isUpdate &&
      new Date(this.policyWording.startEffectiveDate) <=
        new Date(this.minEffectiveDate)
    ) {
      this.errorLogs.Logs.push(
        "StartEffective Date should be greater to latest StartEffective Date of existing policy setup."
      );
      hasError = true;
    } else if (
      this.isUpdate &&
      new Date(this.policyWording.startEffectiveDate) <=
        new Date(this.minEffectiveDate)
    ) {
      this.errorLogs.Logs.push(
        "StartEffective Date should be greater than latest StartEffective Date of existing policy setup."
      );
      hasError = true;
    } else if (this.policyWording.documentVersion == null) {
      this.errorLogs.Logs.push("Please select document version.");
      hasError = true;
    } else if (
      this.policyWording.documentVersion == "New" &&
      (this.policyWording.documentTitle == null ||
        this.policyWording.documentTitle == "")
    ) {
      this.errorLogs.Logs.push("Please enter document title.");
      hasError = true;
    } else if (
      this.policyWording.documentTitle != null &&
      this.policyWording.documentTitle.indexOf(" ") >= 0
    ) {
      this.errorLogs.Logs.push("Space not allowed in document title.");
      hasError = true;
    } else if (
      this.policyWording.documentVersion == "New" &&
      (this.policyWording.documentTitle == null ||
        this.policyWording.documentTitle == "")
    ) {
      this.errorLogs.Logs.push("Please enter document title.");
      hasError = true;
    } else if (
      this.policyWording.documentTitle != null &&
      this.policyWording.documentTitle.indexOf(" ") >= 0
    ) {
      this.errorLogs.Logs.push("Space not allowed in document title.");
      hasError = true;
    } else if (
      this.policyWording.documentVersion != "New" &&
      this.policyWording.policyWordingDocumentSetupId == null
    ) {
      this.errorLogs.Logs.push("Please enter document title.");
      hasError = true;
    } else if (
      this.policyWording.documentVersion == "New" &&
      this.policyWording.files == null
    ) {
      this.excelFilesErrorMessage = "File needs to be uploaded.";
      this.hasError = true;
    } else if (
      this.policyWording.documentVersion == "New" &&
      this.policyWording.files.length == 0
    ) {
      this.excelFilesErrorMessage = "At least one file needs to be uploaded.";
      this.hasError = true;
    } else if (
      this.isUpdate &&
      this.documentChange &&
      this.policyWording.files == null
    ) {
      this.excelFilesErrorMessage = "One File needs to be uploaded.";
      this.hasError = true;
    } else if (
      this.isUpdate &&
      this.documentChange &&
      this.policyWording.files.length == 0
    ) {
      this.excelFilesErrorMessage = "At least one file needs to be uploaded.";
      this.hasError = true;
    } else {
      this.addpdf();
    }
  }
  private addpdf(): void {
    this.isProcessing = true;
    if (this.policyWording.documentVersion != "New") {
      this.policyWording.documentTitle = this.documentTitleList.filter(
        (x) =>
          x.policyWordingDocumentSetupId ==
          this.policyWording.policyWordingDocumentSetupId
      )[0].documentTitle;
    }
    // Populate uploadedExcel model into Form Data for HTTP POST
    let uplodedExcelFormData = this.populateFormData(this.policyWording);
    this.uploadService.uploadPolicyWording(uplodedExcelFormData).subscribe(
      (result: any) => {
        let tempData: any = null;
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          if (result.body.Status === false) {
            if (result.body.ValidationMessage == "Title already Exists") {
              this.isProcessing = false;
              this.ConfirmationErrorPopup(
                result.body.ValidationMessage +
                  "! Please use the correct document title.",
                ""
              );
              return;
            } else {
              tempData = {
                message: result.body.Message,
                validationMessage: result.body.ValidationMessage,
                status: false,
              };
            }
          } else {
            tempData = {
              message: result.body.Message,
              status: true,
            };
          }
          //this.clearValues();
          this.getDocumentTitleList();
          this.GetPolicyWordingSetUpList();
          this.notifier.success(tempData.message, this.appendTo);
          if (this.isUpdate) {
            this.cancel();
          }
          this.isProcessing = false;
        }
      },
      (error: any) => {
        this.notifier.error(
          "Failed to upload pdf file. Please contact the system administrator.",
          this.appendTo
        );
        this.isProcessing = false;
      }
    );
  }
  private populateFormData(policyWording: PolicyWordingEntity): FormData {
    const formData: FormData = new FormData();
    for (let i = 0; i < policyWording.files?.length; i++) {
      formData.append("file" + i.toString(), policyWording.files[i]);
    }
    formData.append("id", policyWording.id);
    formData.append("productType", policyWording.productType);
    formData.append("policyType", policyWording.policyType.toString());
    formData.append("documentTitle", policyWording.documentTitle);
    formData.append("documentVersion", policyWording.documentVersion);
    formData.append(
      "policyWordingDocumentSetupId",
      policyWording.policyWordingDocumentSetupId
    );
    formData.append(
      "startEffectiveDate",
      new Date(policyWording.startEffectiveDate).toUTCString()
    );

    return formData;
  }
  public cancel = () => {
    this.isUpdate = false;
    this.policyForm.reset();
    this.isProcessing = false;
  };
  public updatePolicyHandler(policyWording: PolicyWordingEntity): void {
    //todo: return as promise or observable
    this.ConfirmationPopup(
      "Are you sure you want to update the policy setup?",
      policyWording
    );
  }

  ConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }
  ConfirmationErrorPopup(msg: string, data: any) {
    let v2InputData: v2DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v2DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV2Component,
      {
        data: v2InputData,
      }
    );

    v2DialogRef.afterClosed().subscribe((result: any) => {});
  }

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    // //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    // console.log("cell clicked");
    // //todo: this will re-route to proposal page
    // this.router.navigate(["/" + ROUTE_PROPOSAL], {
    //   queryParams: { id: dataItem.proposalId },
    // });
    if (columnIndex === 7) this.downloadDocument(dataItem);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.loadItems();
  }
  async confirmationModalCloseForActions(args: dlgModelArgs) {
    this.errorLogs.Logs = [];
    if (args.status === "yes") {
      this.documentChange = false;
      this.policyWording.files = this.uploadPdf;
      //const myCopiedArray = Object.assign([], args.data);
      this.policyWording.policyType = [];
      this.policyWording.productType = args.data.productType;
      this.policyWording.policyType[0] = args.data.policyType;
      this.policyWording.documentTitle = args.data.documentTitle;
      this.policyWording.startEffectiveDate = new Date(
        args.data.startEffectiveDate
      );
      this.policyWording.policyWordingDocumentSetupId =
        args.data.policyWordingDocumentSetupId;
      this.documentName = args.data.documentName;
      this.policyWording.documentVersion = "Existing";
      if (this.lstPolicyWording.length > 1) {
        this.minEffectiveDate = new Date(
          this.lstPolicyWording[1].startEffectiveDate
        );
      }
      this.isUpdate = true;
    }
  }
  async confirmationErrorModalCloseForActions(args: dlgModelArgs) {}

  public onPolicyTypeChange(val: any): void {
    this.minEffectiveDate = new Date(1900, 1, 1);

    if (this.policyWording.productType?.length == 1) {
      this.policyWording.productType = this.policyType.filter(
        (x) => x.policyType == this.policyWording.productType
      )[0].productType;
    }
    this.GetPolicyWordingSetUpList();
  }
  public GetPolicyWordingSetUpList(): void {
    let policyType: string = "";
    this.lstPolicyWording = [];
    if (
      !this.isUpdate &&
      Array.isArray(this.policyWording.policyType) &&
      this.policyWording.policyType.length > 1
    ) {
      for (let i = 0; i < this.policyWording.policyType.length; i++) {
        this.callGetPolicyWordingSetUpListdList(
          this.policyWording.policyType[i]
        );
      }
    } else {
      if (Array.isArray(this.policyWording.policyType)) {
        policyType = this.policyWording.policyType[0];
      } else {
        policyType = this.policyWording.policyType;
      }

      this.callGetPolicyWordingSetUpListdList(policyType);
    }
  }
  callGetPolicyWordingSetUpListdList(policyType: any) {
    if (policyType != undefined) {
      this.policyWordingSetUpService
        .getPolicyWordingSetUpList(policyType, this.policyWording.productType)
        .subscribe(
          (result: PolicyWordingEntity[]) => {
            if (result.length > 0) {
              result.forEach((oItem) => {
                this.lstPolicyWording.push(oItem);
                if (this.minEffectiveDate == null) {
                  this.minEffectiveDate = new Date(oItem.startEffectiveDate);
                } else {
                  if (
                    new Date(this.minEffectiveDate) <
                    new Date(oItem.startEffectiveDate)
                  ) {
                    this.minEffectiveDate = new Date(oItem.startEffectiveDate);
                  }
                }
              });
              this.totalListing = this.lstPolicyWording.length;
              this.loadItems();
            } else {
              // this.lstPolicyWording = [];
              this.minEffectiveDate = new Date(this._minTransactionDate);
            }
          },
          (error) => {
            this.AddGroupErrorMessageBySplitting(error);
          }
        );
    }
    this.totalListing = this.lstPolicyWording.length;
    this.loadItems();
  }
  private loadItems(): void {
    this.gridView = {
      data: this.lstPolicyWording.slice(this.skip, this.skip + this.pageSize), //static paging
      //data: this.listing, //.slice(this.skip, this.skip + this.pageSize),//dynamic paging
      total: this.totalListing,
    };
  }
  private AddGroupErrorMessageBySplitting(errorMessage: string) {
    this.errorLogs.Logs = [];
    var errorMessages = errorMessage.split(";").reverse();
    errorMessages.forEach((errormessage) => {
      this.errorLogs.Logs?.push(errormessage);
    });
    this.ConfirmationErrorPopup(errorMessages.toString(), "");
  }
  public onProductTypeChange(e: any): void {
    this.fetchPolicyTypeList();
    this.lstPolicyWording = [];
  }
  public downloadPolicies = (policyWording: PolicyWordingEntity): void => {
    this.policyWordingSetUpService
      .getPolicyListOfWordingSetUp(policyWording.id)
      .subscribe(
        (result: PolicyWordingEntity[]) => {
          this.excelTemplate.downloadPolicies(
            columns,
            policyWording.documentTitle,
            "PolicyList",
            result
          );
        },
        (error) => {
          this.AddGroupErrorMessageBySplitting(error);
        }
      );
  };
}
const columns: string[] = [
  "Policy Certificate No",
  "Product Type",
  "Policy Type",
  "Policy No",
  "Effective Date",
  "PolicyBound Date",
  "Expiry Date",
];
