<div
  style="width: 100%; display: inline-block"
  *ngIf="pageLoaded"
  id="buttonPanelDiv"
>
  <div class="floatLeft" style="width: auto; margin-left: 3px">
    <button
      [disabled]="httpRequesting"
      #elementBtnSave
      (click)="click(elementBtnSave)"
      event="{c: actions.save.command}"
      [attr.data-command-id]="actions.save.command"
      title="Save"
      *ngIf="CanSave()"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-save fa-lg fa-lg-line-height" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Save</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnSendToChecker
      (click)="click(elementBtnSendToChecker)"
      event="{c: actions.sendToChecker.command}"
      [attr.data-command-id]="actions.sendToChecker.command"
      title="Send To Checker"
      [hidden]="!actions.sendToChecker.visible"
      *ngIf="
        this.identityRolesService.hasProductTransactRoles &&
        !hideActionsCommandsByIBG
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i
        class="fa fa-external-link fa-lg fa-lg-line-height"
        aria-hidden="true"
      ></i>
      <span class="fontSize14px">&nbsp;Send To Checker</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnReturnToRevise
      (click)="click(elementBtnReturnToRevise)"
      event="{c: actions.returnForRevise.command}"
      [attr.data-command-id]="actions.returnForRevise.command"
      title="Return To Revise"
      [hidden]="!actions.returnForRevise.visible"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-repeat fa-lg fa-lg-line-height" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Return To Revise</span>
    </button>
    <button
      [disabled]="httpRequesting"
      #elementBtnAcceptPolicy
      (click)="click(elementBtnAcceptPolicy)"
      event="{c: actions.approve.command}"
      [attr.data-command-id]="actions.approve.command"
      [attr.data-additional-id]="3"
      title="Approve"
      [hidden]="!actions.approve.visible"
      *ngIf="
        (this.identityRolesService.hasProductTransactRoles && !isWpbReferred) ||
        (this.identityRolesService.IsCbsOps && hideActionsCommandsByIBG)
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i
        class="fa fa-check-circle-o fa-lg fa-lg-line-height"
        aria-hidden="true"
      ></i>
      <span class="fontSize14px">&nbsp;Quote</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnEditQuotation
      (click)="click(elementBtnEditQuotation)"
      event="{c: actions.EditQuotation.command}"
      [attr.data-command-id]="19"
      title="Edit Quotation"
      *ngIf="CanEditQuotation()"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i
        class="fa fa-check-circle-o fa-lg fa-lg-line-height"
        aria-hidden="true"
      ></i>
      <span class="fontSize14px">&nbsp;Edit Quote</span>
    </button>

    <!-- DBSEP-2136  -->
    <button
      [disabled]="httpRequesting"
      #elementBtnReferToUnderwriter
      (click)="click(elementBtnReferToUnderwriter)"
      event="{c: actions.submit.command}"
      [attr.data-command-id]="actions.submit.command"
      [attr.data-additional-id]="2"
      title="Refer to Underwriter"
      *ngIf="CanReferToUnderwriter()"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i
        class="fa fa-check-circle-o fa-lg fa-lg-line-height"
        aria-hidden="true"
      ></i>
      <span class="fontSize14px">&nbsp;Refer to Underwriter</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnSubmitPolicy
      (click)="click(elementBtnSubmitPolicy)"
      event="{c: actions.submit.command}"
      [attr.data-command-id]="actions.submit.command"
      [attr.data-additional-id]="1"
      title="{{ bindPolicy }}"
      *ngIf="CanSubmit()"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i
        class="fa fa-check-square-o fa-lg fa-lg-line-height"
        aria-hidden="true"
      ></i>
      <span class="fontSize14px" *ngIf="CanChecked()">&nbsp;Checked</span>
      <span class="fontSize14px" *ngIf="CanBindPolicy()"
        >&nbsp;{{ bindPolicy }}</span
      >
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnRenew
      (click)="click(elementBtnRenew)"
      event="{c: actions.renew.command}"
      [attr.data-command-id]="actions.renew.command"
      title="Renew"
      [hidden]="!actions.renew.visible"
      *ngIf="
        !this.identityRolesService.isBroker &&
        this.isTransactionExpiredForRenewal
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i
        class="fa fa-check-circle-o fa-lg fa-lg-line-height"
        aria-hidden="true"
      ></i>
      <span class="fontSize14px">&nbsp;Renew</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnDecline
      (click)="click(elementBtnDecline)"
      event="{c: actions.decline.command}"
      [attr.data-command-id]="actions.decline.command"
      title="Decline"
      [hidden]="!actions.decline.visible"
      *ngIf="
        this.identityRolesService.hasProductTransactRoles &&
        !hideActionsCommandsByIBG
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa-lg-line-height" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Decline</span>
    </button>
    <!-- DBSEP-2136  -->
    <button
      [disabled]="httpRequesting"
      #elementBtnApprove
      (click)="click(elementBtnApprove)"
      event="{c: actions.approve.command}"
      [attr.data-command-id]="actions.approve.command"
      [attr.data-additional-id]="4"
      title="Approve"
      *ngIf="CanApprove()"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i
        class="fa fa-check-circle-o fa-lg fa-lg-line-height"
        aria-hidden="true"
      ></i>
      <span class="fontSize14px">&nbsp;Approve</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnCancellationAdjustment
      (click)="click(elementBtnCancellationAdjustment)"
      event="{c: actions.cancellationAdjustment.command}"
      [attr.data-command-id]="actions.cancellationAdjustment.command"
      title="Cancellation Adjustment"
      *ngIf="false"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i
        class="fa fa-check-circle-o fa-lg fa-lg-line-height"
        aria-hidden="true"
      ></i>
      <span class="fontSize14px">&nbsp;Cancellation Adjustment</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnEdit
      (click)="click(elementBtnEdit)"
      event="{c: actions.endorsement.command, t: 1}"
      [attr.data-command-id]="actions.endorsement.command"
      [attr.data-endorsement-id]="1"
      title="Edit"
      *ngIf="CanEdit() && !this.isTransactionExpired"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa fa-edit" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Edit</span>
    </button>

    <!-- Loan redemption is allowed only one time per policy. If previous proposal returns 'loanRedemption' property true, hide the button -->
    <!-- IsLoanRedeemedValidationMessage - to hide loan redemption button in year 2 when year 1 has raised loan redemption -->
    <button
      [disabled]="httpRequesting"
      #elementBtnLoanRedemption
      (click)="click(elementBtnLoanRedemption)"
      event="{c: actions.endorsement.command, t: 16}"
      [attr.data-command-id]="actions.endorsement.command"
      [attr.data-endorsement-id]="16"
      title="Loan Redemption"
      [hidden]="
        !(
          actions.endorsement.visible &&
          !proposal.loanRedeemed &&
          !proposal.auxiliary['IsLoanRedeemedValidationMessage']
        )
      "
      *ngIf="
        this.identityRolesService.hasProductTransactRoles &&
        !hideActionsCommandsByIBG &&
        !hideAllEndorsementsForRenewedCMBWPB &&
        !this.isTransactionExpired
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa fa-registered" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Loan Redemption</span>
    </button>

    <!--2022-08-16 dagoh0: APACDM-363 - upgrade/downgrade plan type -->
    <button
      [disabled]="httpRequesting"
      #elementBtnChangeOfPlanType
      (click)="click(elementBtnChangeOfPlanType)"
      event="{c: actions.endorsement.command, t: 22}"
      [attr.data-command-id]="actions.endorsement.command"
      [attr.data-endorsement-id]="22"
      title="Change of Plan Type"
      [hidden]="!(actions.endorsement.visible && !isCITIProduct)"
      *ngIf="isUpgradable && !hideActionsCommandsByIBG"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-lg fa-regular fa-sort" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Change of Plan Type</span>
    </button>

    <!-- Change Of POI is allowed only if earlier General Endorsement / Change Of SI was not performed.Else hide the button -->
    <button
      [disabled]="httpRequesting"
      #elementBtnEditPOI
      (click)="click(elementBtnEditPOI)"
      event="{c: actions.endorsement.command, t: 17}"
      [attr.data-command-id]="actions.endorsement.command"
      [attr.data-endorsement-id]="17"
      title="Edit Policy Period"
      [hidden]="true"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa fa fa-calendar" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Edit POI</span>
    </button>

    <!-- Change of Sum Insured -->
    <button
      [disabled]="httpRequesting"
      #elementBtnEditSumIns
      (click)="click(elementBtnEditSumIns)"
      event="{c: actions.endorsement.command, t: 8}"
      [attr.data-command-id]="actions.endorsement.command"
      [attr.data-endorsement-id]="8"
      title="Edit Sum Insured"
      [hidden]="!actions.endorsement.visible"
      *ngIf="
        this.identityRolesService.hasProductTransactRoles &&
        !hideAllEndorsementsForRenewedCMBWPB &&
        !this.isTransactionExpired
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa-sort-amount-desc" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Edit Sum. Ins.</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnEditCorrAdd
      (click)="click(elementBtnEditCorrAdd)"
      event="{c: actions.ChangeOfCorrespondenceAddress.command}"
      [attr.data-command-id]="actions.ChangeOfCorrespondenceAddress.command"
      title="Edit Correspondence Address"
      [hidden]="!showCorrespondencebtn"
      *ngIf="
        this.identityRolesService.hasProductTransactRoles &&
        showCorrespondencebtn
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa-address-card" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Edit Corr. Add</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnEditClientInfo
      (click)="click(elementBtnEditClientInfo)"
      event="{c: actions.endorsement.command, t: 19}"
      [attr.data-command-id]="actions.endorsement.command"
      [attr.data-endorsement-id]="19"
      title="Edit Client Info."
      [hidden]="
        !(
          !this.identityRolesService.isMaker &&
          !this.identityRolesService.isChecker &&
          actions.endorsement.visible
        )
      "
      *ngIf="
        (this.identityRolesService.hasProductTransactRoles ||
          this.identityRolesService.IsCbsOps ||
          hideActionsCommandsForCSCBGSG) &&
        !hideAllEndorsementsForRenewedCMBWPB &&
        !this.isTransactionExpired
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa-address-card" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Edit Client. Info.</span>
    </button>

    <!--no need for Hase-->
    <button
      [disabled]="httpRequesting"
      #elementBtnEditAddIns
      (click)="click(elementBtnEditAddIns)"
      event="{c: actions.endorsement.command, t: 18}"
      [attr.data-command-id]="actions.endorsement.command"
      [attr.data-endorsement-id]="18"
      title="Edit Additional Insured"
      [hidden]="true"
      *ngIf="false"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa-user-circle" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Edit Add. Ins.</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnTerminate
      (click)="click(elementBtnTerminate)"
      event="{c: actions.cancel.command}"
      [attr.data-command-id]="actions.cancel.command"
      title="Terminate"
      [hidden]="!actions.cancel.visible"
      *ngIf="
        this.identityRolesService.hasProductTransactRoles &&
        !hideActionsCommandsByIBG &&
        !hideAllEndorsementsForRenewedCMBWPB &&
        !this.isTransactionExpired
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa-ban" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Terminate</span>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnCreateOrEdit
      (click)="click(elementBtnCreateOrEdit)"
      event="{c: actions.createOrEdit.command}"
      [attr.data-command-id]="actions.createOrEdit.command"
      title="Edit"
      [hidden]="!actions.createOrEdit.visible"
      *ngIf="
        this.identityRolesService.hasProductTransactRoles &&
        !hideActionsCommandsByIBG &&
        !this.isTransactionExpired
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-times fa-lg fa-undo" aria-hidden="true"></i>
      <span class="fontSize14px">&nbsp;Edit</span>
    </button>
  </div>

  <!-- Message board -->
  <div class="floatRight" style="margin-right: 1px">
    <button
      [disabled]="httpRequesting"
      class="marginRight5 floatLeft marginBottom5 btn btn-danger"
      (click)="openCreateNew($event)"
      title="Create New"
      *ngIf="
        this.identityRolesService.hasProductTransactRoles &&
        (!hideActionsCommandsByIBG || !hideActionsCommandsForCSCBGSG)
      "
    >
      <i class="fa fa-plus fa-lg" aria-hidden="true"> </i>
    </button>
    <button
      [disabled]="httpRequesting"
      class="marginRight5 floatLeft marginBottom5 btn btn-info"
      (click)="openMessageForum()"
      title="Message Board"
      *ngIf="showMessageBoard && !isLapsed"
    >
      <i class="fa fa-comments-o fa-lg" aria-hidden="true"></i>
    </button>
    <button
      [disabled]="httpRequesting"
      class="marginRight5 floatLeft marginBottom5 btn btn-info"
      (click)="openContactUs()"
      title="Contact Us"
    >
      <i class="fa fa-phone fa-lg" aria-hidden="true"></i>
    </button>
    <!-- DBSEP-1630 : comment for hiding the copy functionality -->
    <button
      [disabled]="httpRequesting"
      #elementBtnCopy
      (click)="click(elementBtnCopy)"
      event="{c: actions.copy.command}"
      [attr.data-command-id]="actions.copy.command"
      title="Copy"
      [hidden]="!actions.copy.visible"
      *ngIf="
        showMessageBoard &&
        this.identityRolesService.hasProductTransactRoles &&
        !hideActionsCommandsByIBG
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-files-o fa-lg" aria-hidden="true"></i>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnMakeRedundant
      (click)="click(elementBtnMakeRedundant)"
      event="{c: actions.makeRedundant.command}"
      [attr.data-command-id]="actions.makeRedundant.command"
      title="Make Redundant"
      [hidden]="!actions.makeRedundant.visible"
      *ngIf="
        (showMessageBoard &&
          this.identityRolesService.hasProductTransactRoles &&
          !hideActionsCommandsByIBG) ||
        (showMessageBoard && this.identityRolesService.IsCbsOps) ||
        (showMessageBoard && hideActionsCommandsForCSCBGSG)
      "
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
    </button>

    <button
      [disabled]="httpRequesting"
      #elementBtnExit
      (click)="click(elementBtnExit)"
      event="{c: actions.exit.command}"
      [attr.data-command-id]="actions.exit.command"
      title="Exit"
      [hidden]="!actions.exit.visible"
      class="marginRight5 floatLeft marginBottom5 btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-sign-out fa-lg" aria-hidden="true"></i>
    </button>
  </div>
</div>
