import { Component, OnInit, ViewChildren } from "@angular/core";
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { PolicyCustomerLoginService } from "../../../services/policy-customer-login.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-customer-enter-otp",
  templateUrl: "./customer-enter-otp.component.html",
  styleUrls: ["./customer-enter-otp.component.scss"],
})
export class CustomerEnterOtpComponent implements OnInit {
  public userEmailMobile: string | undefined;
  public dob: string = "";
  public last4UniqueId: string = "";
  public hasError: boolean = false;
  public msg: string = "";
  IsDisabled: boolean = false;

  formInput = ["input1", "input2", "input3", "input4", "input5", "input6"];
  @ViewChildren("formRow") rows: any;
  otpForm: FormGroup<any>;
  enc: string = "";
  email: string = "";
  mobile: string = "";
  otpSent: string | undefined;
  otpSentInBold: string | undefined;
  otpResent: string | undefined;
  otpInput:
    | {
        size: number;
        type: string; //"text",
        onDone: (value: any) => void;
        onChange: (value: any) => void;
      }
    | undefined;
  canResendOtp: boolean = false;
  resendOtp: string | undefined;
  timer: any;
  footerTitle: string = "";

  constructor(
    private policyCustomerLoginService: PolicyCustomerLoginService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.otpForm = this.toFormGroup(this.formInput);
    const getYear = new Date().getFullYear();
    this.footerTitle = "© " + getYear + " - " + "Chubb E-placement℠ System";
  }

  toFormGroup = (elements: any) => {
    const group: any = {};
    elements.forEach((key: string | number) => {
      group[key] = new FormControl("", Validators.required);
    });
    return new FormGroup(group);
  };

  resendOtpAndStartTimer = () => {
    this.policyCustomerLoginService
      .getOTP(this.email, this.dob, this.last4UniqueId)
      .then((response) => {
        if (response.status === 1) {
          this.otpResent = "OTP resent Successfully";
          this.hasError = false;
          this.resetOtpString();
          this.startTimer();
        } else if (response.status === 4) {
          this.IsDisabled = true;
          this.killTimer();
          this.hasError = true;
          let lockInMinutes = "30";
          if (
            response.lockInMinutes !== undefined &&
            response.lockInMinutes !== null
          ) {
            lockInMinutes = response.lockInMinutes;
          }
          this.msg =
            "Sorry, this link has been disabled for " +
            lockInMinutes +
            " minutes due to multiple failed verification attempts. Please try again later. ";
        } else {
          this.hasError = true;
          this.msg = "Sorry, the OTP is invalid. Please try again.";
        }
      });
  };

  onSubmit = () => {
    this.otpForm.markAllAsTouched();
    if (this.otpForm.status === "VALID") {
      this.policyCustomerLoginService
        .validateOtpWithDds(this.enc, this.getOtpString())
        .then((response) => {
          if (response.status === 1) {
            this.msg = "OTP is valid";
            this.router.navigate([
              "/customerAcceptOffer",
              this.enc,
              response.proposalIdList,
              response.productList,
              response.policyList,
              response.expiryDateList,
            ]);
            this.policyCustomerLoginService.isCustomerLogin = false;
            this.policyCustomerLoginService.isCustomerOTP = false;
            this.policyCustomerLoginService.isCustomerOffer = true;
          } else if (response.status === 4) {
            this.IsDisabled = true;
            this.killTimer();
            this.hasError = true;
            let lockInMinutes = "30";
            if (
              response.lockInMinutes !== undefined &&
              response.lockInMinutes !== null
            ) {
              lockInMinutes = response.lockInMinutes;
            }
            this.msg =
              "Sorry, this link has been disabled for " +
              lockInMinutes +
              " minutes due to multiple failed verification attempts. Please try again later.";
          } else if (response.status === 5) {
            this.hasError = true;
            this.msg = "Sorry, the OTP is invalid. Please try again.";
          } else if (response.status === 6) {
            this.hasError = true;
            this.msg = "Sorry, the OTP is invalid. Please try again.";
          } else {
            this.hasError = true;
            this.msg = "Invalid enc/otp";
          }
        });
    } else {
      this.msg = "Failed to read Otp value, please restart from beginning.";
      this.hasError = true;
    }
  };

  keyDownEvent(event: any, index: any) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
      this.otpForm.controls["input" + index].setValue("");
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      setTimeout(() => {
        this.rows._results[pos].nativeElement.focus();
      }, 0);
    }
  }

  getOtpString = () => {
    let otp: string =
      this.otpForm.controls["input1"].value +
      this.otpForm.controls["input2"].value +
      this.otpForm.controls["input3"].value +
      this.otpForm.controls["input4"].value +
      this.otpForm.controls["input5"].value +
      this.otpForm.controls["input6"].value;
    return otp;
  };

  resetOtpString = () => {
    this.otpForm.controls["input1"].setValue("");
    this.otpForm.controls["input2"].setValue("");
    this.otpForm.controls["input3"].setValue("");
    this.otpForm.controls["input4"].setValue("");
    this.otpForm.controls["input5"].setValue("");
    this.otpForm.controls["input6"].setValue("");
  };

  ngOnInit(): void {
    this.route.firstChild?.params.subscribe((param: any) => {
      this.enc = param.enc;
      this.dob = param.dob;
      this.email = param.email;
      this.mobile = param.mobile;
      this.last4UniqueId = param.last4UniqueId;
    });

    if (this.email !== "") {
      let maskedEmail = "";
      let emailWithoutDomain = this.email.substring(0, this.email.indexOf("@"));
      let emailWithOnlyDomain = this.email.substring(this.email.indexOf("@"));
      switch (emailWithoutDomain.length) {
        case 1:
          maskedEmail = "x" + emailWithOnlyDomain;
          break;
        case 2:
          maskedEmail =
            emailWithoutDomain.substring(0, 1) + "X" + emailWithOnlyDomain;
          break;
        case 3:
          maskedEmail =
            emailWithoutDomain.substring(0, 1) +
            "X" +
            emailWithoutDomain.substring(2, 3) +
            emailWithOnlyDomain;
          break;
        case 4:
          maskedEmail =
            emailWithoutDomain.substring(0, 1) +
            "X" +
            emailWithoutDomain.substring(3, 5) +
            emailWithOnlyDomain;
          break;
        case 5:
          maskedEmail =
            emailWithoutDomain.substring(0, 1) +
            "X" +
            emailWithoutDomain.substring(4, 7) +
            emailWithOnlyDomain;
          break;
        default:
          maskedEmail =
            emailWithoutDomain.substring(0, 2) +
            "XXXXX" +
            this.email.substring(this.email.indexOf("@") - 3);
          break;
      }
      this.otpSent = "Enter the OTP that has been sent to your email address ";
      this.otpSentInBold = maskedEmail;
    } else {
      this.otpSent = "Enter the OTP that has been sent to your mobile number ";
      this.otpSentInBold =
        "+65XXXXX" + this.mobile.substr(this.mobile.length - 3);
    }
    this.otpResent = "";

    this.otpInput = {
      size: 6,
      type: "password", //"text",
      onDone: function (value) {
        //console.log(value);
      },
      onChange: function (value) {
        //console.log(value);
      },
    };

    this.startTimer();
  }

  convertMinsToHrsMins = (mins: number) => {
    let h: string = Math.floor(mins / 60).toString();
    let m: string = (mins % 60).toString();
    h = parseInt(h) < 10 ? "0" + h : h;
    m = parseInt(m) < 10 ? "0" + m : m;
    return `${h}:${m}`;
  };

  startTimer = () => {
    this.canResendOtp = false;
    var countDown = 5 * 60;
    this.resendOtp =
      "Resend OTP <br>(in " +
      this.convertMinsToHrsMins(countDown) +
      " minutes)";
    this.timer = setInterval(() => {
      this.resendOtp =
        "Resend OTP <br>(in " +
        this.convertMinsToHrsMins(countDown) +
        " minutes)";
      countDown--;
      if (countDown === 0) {
        this.canResendOtp = true;
        this.killTimer();
      }
    }, 1000);
  };

  killTimer = () => {
    if (this.timer !== undefined) {
      clearInterval(this.timer);
      this.timer = undefined;
      this.resendOtp = "Resend OTP\r\n";
    }
  };
}
