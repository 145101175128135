import * as EnumSG from "../../sme/mortgage-fire/singapore/base/mf-sg-enums";
import * as MfEnum from "../../sme/mortgage-fire/singapore/base/mf-sg-enums";
import { AbstractDocType } from "./abstract-doc-type";

export class HaseDocType implements AbstractDocType {
  public getDocTypeList(
    clientType: EnumSG.ClientType
  ): Array<{ text: string; value: string }> {
    switch (clientType) {
      case MfEnum.ClientType.Person: {
        return [
          { text: "Hk Id Card", value: "I" },
          { text: "Passport", value: "P" },
        ];
      }
      case MfEnum.ClientType.Organization: {
        return [
          { text: "Business Registration Document", value: "B" },
          { text: "CI", value: "C" },
        ];
      }
      case MfEnum.ClientType.Others:
      default: {
        return [{ text: "Others", value: "X" }];
      }
    }
  }

  public getDocTypeCaption(): string {
    return "Mortgagor ID Type";
  }

  public showClientTypeOthers(): boolean {
    return true;
  }
}
