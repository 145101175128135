<kendo-dialog
  title="Message Board"
  class="modal-title-light-orange"
  (close)="getMessageAndClose('cancel')"
  [width]="850"
  [height]="450"
>
  <div class="modal-body container-overflow-y">
    <div>
      <label ng-model="messageBoard.response"></label>
      <div class="">
        <form class="" role="form">
          <div class="form-group">
            <div class="fontBold">
              <label for="title">Title</label>
            </div>
            <input
              type="text"
              id="titleText"
              name="titleText"
              [(ngModel)]="model.messageBoard[0].messageTitle"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <div class="fontBold">
              <label for="content">Content</label>
            </div>
            <textarea
              name="contentText"
              [(ngModel)]="model.messageBoard[0].messageContent"
              rows="5"
              cols="40"
              class="form-control"
            ></textarea>
          </div>

          <div class="form-group">
            <div class="col-sm-4">
              <div ng-if="showNotify" class="checkbox">
                <label>
                  <input
                    name="notifyUserText"
                    type="checkbox"
                    value="notifyUser"
                    [(ngModel)]="model.messageBoard[0].notifyUser"
                  />
                  Notify User
                </label>
              </div>
              <div ng-if="showNotify" class="checkbox">
                <label>
                  <input
                    name="notifyOwnerOnlyCheckbox"
                    type="checkbox"
                    value="notifyOwnerOnly"
                    [(ngModel)]="model.messageBoard[0].notifyOwnerOnly"
                  />
                  Notify proposal owner only
                </label>
              </div>

              <div>
                <!--from : shared\uiControls\inputs\drFileUpload\drFileUpload.html-->
                <div style="color: #009900">{{ validationMessage }}</div>
                <kendo-upload
                  name="documentids"
                  [autoUpload]="false"
                  [multiple]="true"
                  [(ngModel)]="myFiles"
                  (complete)="complete()"
                  (remove)="remove($event)"
                  (success)="success($event)"
                  (upload)="upload($event)"
                >
                </kendo-upload>
                <!-- <div
                  dr-file-upload
                  multiple
                  ownerid="ownerid"
                  description="description"
                  type="fileType"
                  documentids="documentids"
                  opts="opts"
                  module=""
                ></div> -->
              </div>
            </div>
          </div>

          <div class="clearfix col-md-12" *ngIf="showPrintOption">
            <div>
              <div class="col-md-6" style="padding-left: 0">
                <form role="form">
                  <div class="form-group">
                    <div class="form-group">
                      <label
                        class="required"
                        [textContent]="'Print Options'"
                      ></label>
                      <div class="col-md-6">
                        <kendo-dropdownlist
                          id="selectReprintTypeBoard"
                          [data]="options"
                          textField="key"
                          valueField="value"
                          [valuePrimitive]="true"
                          [(ngModel)]="selectedPrintType"
                          (valueChange)="optionChange($event)"
                          name="selectReprintType"
                        ></kendo-dropdownlist>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="clearfix col-md-12" *ngIf="showPrintOption">
            <div class="col-md-6" style="padding-left: 0">
              <form role="form">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="required"
                      *ngIf="+model.selectedPrintType.value == 2"
                      [textContent]="'Email'"
                    >
                    </label>
                    <label
                      class="required"
                      *ngIf="+model.selectedPrintType.value == 5"
                      [textContent]="'MobileNo'"
                    >
                    </label>
                    <div>
                      <input
                        id="printEmail"
                        name="printEmail"
                        type="text "
                        (blur)="validateEmail(data.emailId)"
                        *ngIf="+model.selectedPrintType.value == 2"
                        value=""
                        maxlength="100"
                        class="k-textbox form-control"
                        [(ngModel)]="data.emailId"
                      />

                      <input
                        id="clientmobileNo"
                        name="clientmobileNo"
                        type="text "
                        (blur)="validateMobile(data.mobileNo)"
                        *ngIf="+model.selectedPrintType.value == 5"
                        value=""
                        class="k-textbox form-control"
                        [(ngModel)]="data.mobileNo"
                      />
                    </div>
                    <div class="clearfix" ng-if="hasFormError">
                      <label
                        ng-model="response"
                        class="col-md-12 chubbRed"
                        style="padding-left: 0"
                        >{{ formErrors }}</label
                      >
                    </div>
                  </div>
                </form>
              </form>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        (click)="submit()"
      >
        Add Message
      </button>
      <button
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel"
        kendoButton
        (click)="getMessageAndClose('no')"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
