import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DialogWrapperService } from "../dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "../error-handler/error-handler.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
//import { Identity } from "../../extensions/user/identity";
import { Identity } from "src/app/shared/user/identity";
import { ApiService } from "../dal/api.service";
import { identity } from "lodash";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ROUTE_HOME } from "src/app/app.module";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService extends ApiService {
  private urltoken = "token";

  constructor(
    private identity: Identity,
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService,
    private router: Router
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  public GetUrlDetails(data: any, url: string) {
    return this.httpPostCallLogin(
      this.getHeaderForAuthentication(),
      this.constructEndPoint(url),
      data,
      encodeURIComponent(data)
    );
  }

  protected encodeURIComponent(obj: any) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }

  public login(loginData: any): Observable<any> {
    // // broadcast login event
    // AuthenticationEvent.login();
    const data = {
      grant_type: "password",
      username: loginData.userName,
      password: loginData.password,
      client_id: environment.client,
    };

    let body = `grant_type=${"password"}&client_id=${
      environment.client
    }&username=${"" + loginData.userName + ""}&password=${
      "" + loginData.password + ""
    }`;
    let options = {
      headers: this.getHeaderForAuthentication(),
    };

    return this.httpClient.post<any>(
      this.constructEndPoint(this.urltoken),
      body,
      options
    );

    //     $http({
    //           method: 'POST',
    //           url: appSettings.apiBaseUri + "token",
    //           headers: { 'Content-Type': 'multipart/form-data' },
    //           data: { username: loginData.userName, password: loginData.password, grant_type: 'password', client_id: "ams-localhost" },
    //           transformRequest: function (obj) {
    //             var str = [];
    //             for (var p in obj)
    //                 str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    //             return str.join("&");
    //           }
    //         }).success(function (response)
    // {
    //   // populate authentication data to Identity
    //   Identity.storeAuthData(response);

    //   // //response2FA = response;
    //   // // if session token is undefined, go to korea 2FA
    //   // if (angular.isUndefined(response.sessionToken)) {
    //   //   if (!response.isKoreaVestpinTwoFA) {
    //   //     throw new error('Forbidden');
    //   //   }
    //   //   deferred.resolve(response);
    //   // } else {

    //   //   // if user is trying to login as different user
    //   //   if (isDifferentUser) {
    //   //     // logout the current user without clearing _lastLoginUser object
    //   //     logout(true);

    //   //     if (angular.isDefined(fnNotSameAsPreviousUser)) {
    //   //       return fnNotSameAsPreviousUser();
    //   //     }
    //   //   }

    //   //   Debugger.log('logged in succeed')

    //     // populating the current user
    //     this.Identity.populateCurrentUser(true)
    //       .then(function () {

    //         // setup auto refresh token;
    //         TokenRefresher.start();

    //         // broadcast login success event
    //         AuthenticationEvent.loginSuccess();

    //         // ping server to indicate alive session
    //         ping();

    //         // resolve response
    //         deferred.resolve(response);

    //       }, function (err) {
    //         deferred.reject(err);
    //       });
    //     }

    //   }).error(function (err, status) {

    //     Debugger.log('login failed')

    //     // broadcast login failed event
    //     AuthenticationEvent.loginFailed();

    //     deferred.reject(err);
    //   });

    // return deferred.promise;
  }
}
