import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProductService } from "src/app/services/new-product/base/product.service";

@Component({
  selector: "[app-base-proposal]", //render Selector as an Attribute, to prevent <app-home> nested
  templateUrl: "./base-proposal.component.html",
  styleUrls: ["./base-proposal.component.scss"],
})
export class BaseProposalComponent implements OnInit {
  isSgProduct: boolean = false;
  isHkProduct: boolean = false;
  sub: any;
  productId?: string;

  constructor(
    public route: ActivatedRoute,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe((params) => {
      this.productId = params["pdid"];

      let country = this.productService.getCountryByProductId(this.productId!);
      switch (country) {
        case "SG":
          this.isSgProduct = true;
          this.isHkProduct = false;
          break;
        case "HK":
          this.isSgProduct = false;
          this.isHkProduct = true;
          break;
        default:
      }
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
