export const countryData = [
  {
    value: "HK",
    text: "Hong Kong",
  },
  {
    value: "SG",
    text: "Singapore",
  },
];
export const personNoOfChildren = [
  {
    value: "1",
    text: "0",
  },
  {
    value: "2",
    text: "1",
  },
  {
    value: "3",
    text: "2",
  },
  {
    value: "4",
    text: "3",
  },
  {
    value: "5",
    text: "4",
  },
  {
    value: "6",
    text: "5",
  },
  {
    value: "7",
    text: ">5",
  },
];
export const nationality = [
  {
    value: "1",
    text: "Afghans",
  },
  {
    value: "2",
    text: "Albanians",
  },
  {
    value: "3",
    text: "Algerians",
  },
  {
    value: "4",
    text: "Americans",
  },
  {
    value: "5",
    text: "Andorrans",
  },
  {
    value: "6",
    text: "Angolans",
  },
  {
    value: "7",
    text: "Argentines",
  },
  {
    value: "8",
    text: "Armenians",
  },
  {
    value: "9",
    text: "Aromanians",
  },
  {
    value: "0",
    text: "Arubans",
  },
  {
    value: "11",
    text: "Australians",
  },
  {
    value: "12",
    text: "Austrians",
  },
  {
    value: "13",
    text: "Azeris",
  },
  {
    value: "14",
    text: "Bahamians",
  },
  {
    value: "15",
    text: "Bahrainis",
  },
  {
    value: "16",
    text: "Bangladeshis",
  },
  {
    value: "17",
    text: "Barbadians",
  },
  {
    value: "18",
    text: "Belarusians",
  },
  {
    value: "19",
    text: "Belgians",
  },
  {
    value: "20",
    text: "Belizeans",
  },
  {
    value: "21",
    text: "Bermudians",
  },
  {
    value: "22",
    text: "Boers",
  },
  {
    value: "23",
    text: "Bosniaks",
  },
  {
    value: "24",
    text: "Brazilians",
  },
  {
    value: "25",
    text: "Bretons",
  },
  {
    value: "26",
    text: "British",
  },
  {
    value: "27",
    text: "British Virgin Islanders",
  },
  {
    value: "28",
    text: "Bulgarians",
  },
  {
    value: "29",
    text: "Burmeses",
  },
  {
    value: "30",
    text: "Macedonian Bulgarians",
  },
  {
    value: "31",
    text: "Burkinabès",
  },
  {
    value: "32",
    text: "Burundians",
  },
  {
    value: "33",
    text: "Cambodians",
  },
  {
    value: "34",
    text: "Cameroonians",
  },
  {
    value: "35",
    text: "Canadians",
  },
  {
    value: "36",
    text: "Catalans",
  },
  {
    value: "37",
    text: "Cape Verdeans",
  },
  {
    value: "38",
    text: "Chadians",
  },
  {
    value: "39",
    text: "Chileans",
  },
  {
    value: "40",
    text: "Chinese",
  },
  {
    value: "41",
    text: "Colombians",
  },
  {
    value: "42",
    text: "Comorians",
  },
  {
    value: "43",
    text: "Congolese",
  },
  {
    value: "44",
    text: "Croatians",
  },
  {
    value: "45",
    text: "Cubans",
  },
  {
    value: "46",
    text: "Cypriots",
  },
  {
    value: "47",
    text: "Turkish Cypriots",
  },
  {
    value: "48",
    text: "Czechs",
  },
  {
    value: "49",
    text: "Danes",
  },
  {
    value: "50",
    text: "Dominicans (Republic)",
  },
  {
    value: "51",
    text: "Dominicans (Commonwealth)",
  },
  {
    value: "52",
    text: "Dutch",
  },
  {
    value: "53",
    text: "East Timorese",
  },
  {
    value: "54",
    text: "Ecuadorians",
  },
  {
    value: "55",
    text: "Egyptians",
  },
  {
    value: "56",
    text: "Emiratis",
  },
  {
    value: "57",
    text: "English",
  },
  {
    value: "58",
    text: "Eritreans",
  },
  {
    value: "59",
    text: "Estonians",
  },
  {
    value: "60",
    text: "Ethiopians",
  },
  {
    value: "61",
    text: "Faroese",
  },
  {
    value: "62",
    text: "Finns",
  },
  {
    value: "63",
    text: "Finnish Swedish",
  },
  {
    value: "64",
    text: "Fijians",
  },
  {
    value: "65",
    text: "Filipinos",
  },
  {
    value: "66",
    text: "French citizens",
  },
  {
    value: "67",
    text: "Georgians",
  },
  {
    value: "68",
    text: "Germans",
  },
  {
    value: "69",
    text: "Baltic Germans",
  },
  {
    value: "70",
    text: "Ghanaians",
  },
  {
    value: "71",
    text: "Gibraltar",
  },
  {
    value: "72",
    text: "Greeks",
  },
  {
    value: "73",
    text: "Greek Macedonians",
  },
  {
    value: "74",
    text: "Grenadians",
  },
  {
    value: "75",
    text: "Guatemalans",
  },
  {
    value: "76",
    text: "Guianese (French)",
  },
  {
    value: "77",
    text: "Guineans",
  },
  {
    value: "78",
    text: "Guinea-Bissau nationals",
  },
  {
    value: "79",
    text: "Guyanese",
  },
  {
    value: "80",
    text: "Haitians",
  },
  {
    value: "81",
    text: "Hondurans",
  },
  {
    value: "82",
    text: "Hong Kong",
  },
  {
    value: "83",
    text: "Hungarians",
  },
  {
    value: "84",
    text: "Icelanders",
  },
  {
    value: "85",
    text: "Indians",
  },
  {
    value: "86",
    text: "Indonesians",
  },
  {
    value: "87",
    text: "Iranians (Persians)",
  },
  {
    value: "88",
    text: "Arabs",
  },
  {
    value: "89",
    text: "Irish",
  },
  {
    value: "90",
    text: "Israelis",
  },
  {
    value: "91",
    text: "Italians",
  },
  {
    value: "92",
    text: "Ivoirians",
  },
  {
    value: "93",
    text: "Jamaicans",
  },
  {
    value: "94",
    text: "Japanese",
  },
  {
    value: "95",
    text: "Jordanians",
  },
  {
    value: "96",
    text: "Kazakhs",
  },
  {
    value: "97",
    text: "Kenyans",
  },
  {
    value: "98",
    text: "Koreans",
  },
  {
    value: "99",
    text: "Kosovo Albanians",
  },
  {
    value: "100",
    text: "Kurds",
  },
  {
    value: "101",
    text: "Kuwaitis",
  },
  {
    value: "102",
    text: "Lao",
  },
  {
    value: "103",
    text: "Latvians",
  },
  {
    value: "104",
    text: "Lebanese",
  },
  {
    value: "105",
    text: "Liberians",
  },
  {
    value: "106",
    text: "Libyans",
  },
  {
    value: "107",
    text: "Liechtensteiners",
  },
  {
    value: "108",
    text: "Lithuanians",
  },
  {
    value: "109",
    text: "Luxembourgers",
  },
  {
    value: "110",
    text: "Macedonians",
  },
  {
    value: "111",
    text: "Malagasy",
  },
  {
    value: "112",
    text: "Malaysian",
  },
  {
    value: "113",
    text: "Malawians",
  },
  {
    value: "114",
    text: "Maldivians",
  },
  {
    value: "115",
    text: "Malians",
  },
  {
    value: "116",
    text: "Maltese",
  },
  {
    value: "117",
    text: "Manx",
  },
  {
    value: "118",
    text: "Mauritians",
  },
  {
    value: "119",
    text: "Mexicans",
  },
  {
    value: "120",
    text: "Moldovans",
  },
  {
    value: "121",
    text: "Moroccans",
  },
  {
    value: "122",
    text: "Mongolians",
  },
  {
    value: "123",
    text: "Montenegrins",
  },
  {
    value: "124",
    text: "Namibians",
  },
  {
    value: "125",
    text: "Nepalese",
  },
  {
    value: "126",
    text: "New Zealanders",
  },
  {
    value: "127",
    text: "Nicaraguans",
  },
  {
    value: "128",
    text: "Nigeriens",
  },
  {
    value: "129",
    text: "Nigerians",
  },
  {
    value: "130",
    text: "Norwegians",
  },
  {
    value: "131",
    text: "Pakistanis",
  },
  {
    value: "132",
    text: "Palauans",
  },
  {
    value: "133",
    text: "Palestinians",
  },
  {
    value: "134",
    text: "Panamanians",
  },
  {
    value: "135",
    text: "Papua New Guineans",
  },
  {
    value: "136",
    text: "Paraguayans",
  },
  {
    value: "137",
    text: "Peruvians",
  },
  {
    value: "138",
    text: "Poles",
  },
  {
    value: "139",
    text: "Portuguese",
  },
  {
    value: "140",
    text: "Puerto Ricans",
  },
  {
    value: "141",
    text: "Quebecers",
  },
  {
    value: "142",
    text: "Réunionnais",
  },
  {
    value: "143",
    text: "Romanians",
  },
  {
    value: "144",
    text: "Russians",
  },
  {
    value: "145",
    text: "Baltic Russians",
  },
  {
    value: "146",
    text: "Rwandans",
  },
  {
    value: "147",
    text: "Salvadorans",
  },
  {
    value: "148",
    text: "São Tomé and Príncipe",
  },
  {
    value: "149",
    text: "Saudis",
  },
  {
    value: "150",
    text: "Scots",
  },
  {
    value: "151",
    text: "Senegalese",
  },
  {
    value: "152",
    text: "Serbs",
  },
  {
    value: "153",
    text: "Sierra Leoneans",
  },
  {
    value: "154",
    text: "Singaporeans",
  },
  {
    value: "155",
    text: "Slovaks",
  },
  {
    value: "156",
    text: "Slovenes",
  },
  {
    value: "157",
    text: "Somalis",
  },
  {
    value: "158",
    text: "South Africans",
  },
  {
    value: "159",
    text: "Spaniards",
  },
  {
    value: "160",
    text: "Sri Lankans",
  },
  {
    value: "161",
    text: "St Lucians",
  },
  {
    value: "162",
    text: "Sudanese",
  },
  {
    value: "163",
    text: "Surinamese",
  },
  {
    value: "164",
    text: "Swedes",
  },
  {
    value: "165",
    text: "Swiss",
  },
  {
    value: "166",
    text: "Syrians",
  },
  {
    value: "167",
    text: "Taiwanese",
  },
  {
    value: "168",
    text: "Tanzanians",
  },
  {
    value: "169",
    text: "Thais",
  },
  {
    value: "170",
    text: "Tibetans",
  },
  {
    value: "171",
    text: "Tobagonians",
  },
  {
    value: "172",
    text: "Trinidadians",
  },
  {
    value: "173",
    text: "Tunisians",
  },
  {
    value: "174",
    text: "Turks",
  },
  {
    value: "175",
    text: "Tuvaluans",
  },
  {
    value: "176",
    text: "Ugandans",
  },
  {
    value: "177",
    text: "Ukrainians",
  },
  {
    value: "178",
    text: "Uruguayans",
  },
  {
    value: "179",
    text: "Uzbeks",
  },
  {
    value: "180",
    text: "Vanuatuans",
  },
  {
    value: "181",
    text: "Venezuelans",
  },
  {
    value: "182",
    text: "Vietnamese",
  },
  {
    value: "183",
    text: "Welsh",
  },
  {
    value: "184",
    text: "Yemenis",
  },
  {
    value: "185",
    text: "Zambians",
  },
  {
    value: "186",
    text: "Zimbabweans",
  },
];
