import { formatDate } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment"; //use the new one in src folder, old one mainly for guid.ts only
import {
  AllPoliciesDownloadEntity,
  SearchCriteria,
} from "../components/accounting/batch-invoice/models/batch-invoice-list.model";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class BatchInvoiceListService extends ApiService {
  SERVER_URL_BATCH_INVOICE_SEARCH: string = "api/invoice/search";
  SERVER_URL_BATCH_INVOICE_HISTORY = "api/openitem/getOpenItemHistory/";
  SERVER_URL_BATCH_INVOICE_EXPORT = "api/openitem/export";
  SERVER_URL_BATCH_INVOICE_DETAILS = "api/openitem/get/";
  SERVER_URL_BATCH_PROCESSING = "api/invoice/doOpenItemBatchProcessing/";
  SERVER_URL_BATCH_NUMBER_SEARCH = "api/invoice/getOpenItemListByBatchNumber/";
  SERVER_URL_BATCH_NUMBER_UPDATE =
    "api/invoice/updateOpenItemInvoiceNumberByBatchId/";
  SERVER_URL_BATCH_DOWNLOAD_ALL = "api/invoice/downloadAll";

  format: string = "yyyy/MM/dd";
  locale: string = "en-US";
  allPoliciesDownloadEntityList: any;

  headers = new HttpHeaders({
    Accept: "application/octet-stream;",
    "Content-Type": "application/x-www-form-urlencoded",
  });

  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  public searchBatchInvoiceListing(querystring: string) {
    return this.httpClient.post<any>(
      environment.serviceBase + this.SERVER_URL_BATCH_INVOICE_SEARCH,
      querystring,
      {
        headers: this.headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }
  public getBatchInvoiceHistory(openItemId: string) {
    return this.httpClient.get<any>(
      environment.serviceBase +
        this.SERVER_URL_BATCH_INVOICE_HISTORY +
        `${openItemId}`
    );
  }
  public getBatchInvoiceDetails(openItemId: string) {
    return this.httpClient.get<any>(
      environment.serviceBase +
        this.SERVER_URL_BATCH_INVOICE_DETAILS +
        `${openItemId}`
    );
  }
  public exportBatchInvoiceListing(querystring: string) {
    return this.httpClient.post<any>(
      environment.serviceBase + this.SERVER_URL_BATCH_INVOICE_EXPORT,
      querystring,
      {
        headers: this.headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  doBatchAndExportHandler(data: any) {
    return this.httpClient.put<any>(
      environment.serviceBase + this.SERVER_URL_BATCH_PROCESSING,
      data
    );
  }

  doSearchInvoiceNumberHandler(data: any) {
    const params = {
      batchId: data,
    };
    return this.httpClient.get<any>(
      environment.serviceBase + this.SERVER_URL_BATCH_NUMBER_SEARCH,
      { headers: this.headers, params: params }
    );
  }

  doUpdateInvoiceNumberHandler(batchId: any, invoiceNo: any) {
    const params = {
      batchId: batchId,
      invoiceNumber: invoiceNo,
    };
    return this.httpClient.put<any>(
      environment.serviceBase + this.SERVER_URL_BATCH_NUMBER_UPDATE,
      "",
      { headers: this.headers, params: params }
    );
  }

  async doDownloadAllPoliciesHandler(
    data: SearchCriteria
  ): Promise<AllPoliciesDownloadEntity[]> {
    this.allPoliciesDownloadEntityList = [];
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/json",
    });

    let postData: any = data;

    if (data.effectiveDateFrom === undefined) {
      postData.effectiveDateFrom = "2199-12-31";
    }
    if (data.effectiveDateTo === undefined) {
      postData.effectiveDateTo = "2199-12-31";
    }
    const params = {
      effectiveDateFrom: formatDate(
        postData.effectiveDateFrom,
        this.format,
        this.locale
      ),
      effectiveDateTo: formatDate(
        postData.effectiveDateTo,
        this.format,
        this.locale
      ),
      policyNo: postData.policyNo,
      batchId: postData.batchId,
      segments: postData.segments,
      statusIds: postData.statusIds,
      productIds: postData.productIds,
      policyeffectiveDateFrom: formatDate(
        postData.policyeffectiveDateFrom,
        this.format,
        this.locale
      ),
      policyeffectiveDateTo: formatDate(
        postData.policyeffectiveDateTo,
        this.format,
        this.locale
      ),
    };
    const response = await this.httpClient
      .post<any>(
        environment.serviceBase + this.SERVER_URL_BATCH_DOWNLOAD_ALL,
        postData,
        { headers: headers }
      )
      .toPromise();
    if (response) this.allPoliciesDownloadEntityList = response.d.results;
    return this.allPoliciesDownloadEntityList;
  }
}
