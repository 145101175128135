import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MfSgBaseComponent } from "../../mf-sg-base.component";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { Culture, dateFormatter } from "src/app/shared/date-formatter";
import { MortgageFireSGTabSchema } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/schemas/mf-sg-tab-schema";

@Component({
  selector: "ct-sg-premium-period-history",
  templateUrl: "./ct-sg-premium-period-history.component.html",
})
export class CtSgPremiumPeriodHistoryComponent
  extends MfSgBaseComponent
  implements OnInit, OnDestroy
{
  @Input() proposal!: MortgageFireSingaporeProposal; //temp using @Input()
  poiText!: string;
  constructor(
    public proposalService: MfSgProposalService,
    public identityRolesService: IdentityRolesService
  ) {
    super();

    this.tabId = MortgageFireSGTabSchema.premiumSummary.index.toString();
    super.subscribeProposalLoadedNotifier(this.proposalSaved);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
    super.subscribeAnswerChangedNotifier(this.answerChanged);
  }
  ngOnInit(): void {
    this.initialize();
    //adding extra checking : this.pphRecordCount > 0
    //at showPremiumPeriodHistory () of mf-sg-main.component.ts will cause proposalLoaded not trigger
    //thus add this at here
    this.getPOIText();
  }
  private answerChanged = (kv: KeyValue<QuestionEntity>) => {
    const key = kv.key;
    const question = kv.value;
    switch (key) {
      case this.proposal.qsMaster.qs_machinery_equipment!.key:
        this.proposalSaved(new KeyValue<Proposal>("", this.proposal));
        break;
      default:
        break;
    }
  };

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    this.getPOIText();
  };

  private proposalSaved = (event: KeyValue<Proposal>) => {
    this.getPOIText();
  };
  ngOnDestroy(): void {
    super.unsubscribeRxjs();
  }

  private initialize() {
    this.proposal = this.proposalService.proposal;
    super.subscribeTabLoadRenderer();
  }
  getPOIText = () => {
    // const poiStartDate = this.proposal.qsMaster.qs_master_poi_startDate.readAnswerAsString().split(',')[0];
    // const poiEndDate = this.proposal.qsMaster.qs_master_poi_endDate.readAnswerAsString().split(',')[0];

    const poiStartDate = this.dateFormatting(
      this.proposal.qsMaster.qs_master_poi_startDate!.answer
    );
    const poiEndDate = this.dateFormatting(
      this.proposal.qsMaster.qs_master_poi_endDate!.answer
    );

    const poiText = "Period From " + poiStartDate + " To " + poiEndDate;

    this.poiText = poiText;
  };

  dateFormatting = (value: Date) => {
    return dateFormatter.format(value, Culture.enSg);
  };
}
