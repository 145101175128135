import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { proposalCommon } from "src/app/components/new-product/base/utils/proposal-common";
import { openItemDisplayStatus } from "src/app/finance/finance-constant";
import { common } from "src/app/framework/utils/common";
import { OpenItemListService } from "src/app/services/open-item-list.service";
import { OpenItemEntity } from "../models/open-item-list.model";
@Component({
  selector: "app-open-item-details",
  templateUrl: "./open-item-details.component.html",
})
export class OpenItemDetailsComponent implements OnInit {
  @Input() public opened = false;
  @Input() openItemId!: string;
  @Output() closeDialogEvent = new EventEmitter<any>();
  private listing: OpenItemEntity[] = [];
  public displayPolicyCertificateNo!: string;
  public gridView!: GridDataResult;
  constructor(private openItemListService: OpenItemListService) {}
  ngOnInit(): void {
    this.getOpenItemHistory();
  }
  private getOpenItemHistory(): void {
    this.openItemListService.getOpenItemHistory(this.openItemId).subscribe(
      (result: OpenItemEntity[]) => {
        if (common.isDefined(result)) {
          this.displayPolicyCertificateNo = result[0].policyCertificateNumber;
          this.listing = result;
          this.loadItems();
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  public close(status: any): void {
    this.opened = false;
    this.closeDialogEvent.emit();
  }
  private loadItems(): void {
    this.listing.forEach((entity) => {
      entity.statusDisplayText = openItemDisplayStatus[entity.statusId];
      if (entity.autoDebitMonth !== 0) {
        entity.autoDebitMonthDisplayText = proposalCommon.translateMonth(
          entity.autoDebitMonth
        );
      }
      entity.chequeIssuanceBank = !common.isStringNullOrEmpty(
        entity.manualPaymentChequeIssuanceBank
      )
        ? entity.manualPaymentChequeIssuanceBank
        : !common.isStringNullOrEmpty(entity.refundPaymentChequeIssuanceBank)
        ? entity.refundPaymentChequeIssuanceBank
        : "";

      entity.chequeNumber = !common.isStringNullOrEmpty(
        entity.manualPaymentChequeNumber
      )
        ? entity.manualPaymentChequeNumber
        : !common.isStringNullOrEmpty(entity.refundPaymentChequeNumber)
        ? entity.refundPaymentChequeNumber
        : "";
    });

    this.gridView = {
      data: this.listing, //.slice(this.skip, this.skip + this.pageSize),
      total: this.listing?.length,
    };
  }
}
