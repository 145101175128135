<div
  id="myModal"
  style="margin: 0 10px 10px 10px"
  role="dialog"
  *ngIf="pageLoaded && (showFieldValidation || showValidationMessage || showFieldBlankValidation)"
>
  <div class="">
    <!-- Modal content-->
    <div class="modal-content" style="border-color: #afafaf">
      <div
        class="modal-header"
        style="
          border-bottom-color: #afafaf;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      >
        <h4 class="modal-title chubbRed">Please complete the following :</h4>
      </div>
      <div class="modal-body paddingTop5">
        <div *ngFor="let vs of proposal?.validationMessage">
          <div class="row">
            <div class="col-md-6">
              <span>
                <i
                  class="fa fa-exclamation-circle fa-2 chubbRed"
                  aria-hidden="true"
                ></i>
                <span style="white-space: pre-wrap">{{ vs }}</span>
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="showFieldValidation">
          <div
            *ngFor="let es of proposalService?.fieldValidationManager?.echos"
          >
            <div>
              <span class="fontSize16px fontBold">{{ es.title }}</span>
              <div class="row">
                <div class="col-md-6" *ngFor="let e of es.echos">
                  <span (click)="navigate(e.entity)" class="cursorPointer">
                    <i
                      class="fa fa-exclamation-circle fa-2 chubbRed"
                      aria-hidden="true"
                    ></i>
                    <span style="white-space: pre-wrap">{{ e.message }}</span>
                    <i
                      class="fa fa-pencil-square fa-2 chubbRed marginLeft10"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showFieldBlankValidation">
          <div *ngFor="let cs of proposalService?.showFieldBlankValidationEchos">
            <div>
              <div class="row">
                <div class="col-md-12" *ngFor="let e of cs.echos"> 
                  <span (click)="navigate(e.entity)" class="cursorPointer">
                    <i
                      class="fa fa-exclamation-circle fa-2 chubbRed"
                      aria-hidden="true"
                    ></i>
                    <span style="white-space: pre-wrap">{{ e.message }}</span>
                    <i
                      class="fa fa-pencil-square fa-2 chubbRed marginLeft10"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>  
            </div>      
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
