import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { IProduct } from "src/app/framework/interface.product";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { LSTSearchCriteria } from "./ibg-reinsurance-report.model";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { LSTEntity } from "../preset-report.model";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { UntypedFormGroup } from "@angular/forms";
@Component({
  selector: "app-lst-report",
  templateUrl: "./ibg-reinsurance-report.component.html",
  styleUrls: ["./ibg-reinsurance-report.component.scss"],
})
export class LstReportComponent extends BaseComponent implements OnInit {
  @ViewChild("heroForm", { static: false })
  private form!: UntypedFormGroup;
  @ViewChild("boundDateTo", { static: false })
  public boundDateTo!: DatePickerComponent;

  public boundDateTo_min!: Date;
  public boundDateTo_max!: Date;
  @Output() warningZoneEvent = new EventEmitter<any>();
  public searchCriteria = new LSTSearchCriteria();
  public userProductList!: IProduct[];
  public showReportType: boolean = false;
  public isCitiProduct: boolean = false;
  public productRadioControl!: string;
  public loading!: boolean;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  public listing: LSTEntity[] = [];
  private totalListing: number = 0;
  submitted = false;
  public transactionTypeOption!: Array<{ text: string; value: number }>;

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    private presetExportService: PresetExportService,
    private excelTemplateService: ExcelTemplateService
  ) {
    super(router, broadcastService, identityRolesService);
  }
  override ngOnInit(): void {
    this.productRadioControl = "dbs";
    this.transactionTypeOption = [
      { text: "New Business", value: 1 },
      { text: "Endorsement", value: 2 },
      { text: "Cancellation", value: 3 },
      { text: "Renewal", value: 4 },
    ];

    this.fetchUserList();
    this.defaultLSTReportSearchCriteria();
  }
  private readProductRadio() {
    this.isCitiProduct = this.productRadioControl === "citi";
  }
  private fetchUserList() {
    // get user productsList
    if (this.isCitiProduct) {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return (
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    } else {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return !(
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    }
  }
  private defaultLSTReportSearchCriteria(): void {
    const dt = new Date(new Date().getFullYear(), 0, 1);
    const effectiveDateFrom = new Date(
      Date.UTC(
        dt.getFullYear(),
        dt.getMonth(),
        dt.getDate(),
        dt.getHours(),
        dt.getMinutes(),
        dt.getSeconds()
      )
    );

    this.searchCriteria.effectiveDateFrom = effectiveDateFrom;

    const effectiveDateTo: Date = new Date(effectiveDateFrom);
    effectiveDateTo.setFullYear(effectiveDateFrom.getFullYear() + 1);
    this.searchCriteria.effectiveDateTo = effectiveDateTo;
    let isValidField: boolean = false;
    isValidField = this.validateLSTMandatoryFields();
    if (isValidField) {
      this.refresh();
    }
  }
  private validateLSTInputFields(): boolean {
    const pattern1 = "[0-9]";
    const pattern2 = "(?=.*[!@#$%^&*?])";
    if (
      this.searchCriteria.insuredName !== null &&
      this.searchCriteria.insuredName !== "" &&
      (new RegExp(pattern1).test(this.searchCriteria.insuredName) ||
        new RegExp(pattern2).test(this.searchCriteria.insuredName))
    ) {
      return false;
    }

    if (
      this.searchCriteria.policyNo !== null &&
      this.searchCriteria.policyNo !== "" &&
      new RegExp(pattern2).test(this.searchCriteria.policyNo)
    ) {
      return false;
    }

    return true;
  }
  private validateLSTMandatoryFields(): boolean {
    let isValid: boolean = false;
    // dates
    const dates: boolean =
      this.searchCriteria.effectiveDateFrom !== null &&
      this.searchCriteria.effectiveDateTo !== null;
    isValid = dates;
    return isValid;
  }

  public on_boundDateFrom_change(value: Date): void {
    if (this.searchCriteria.boundDateFrom === null) {
      this.searchCriteria.boundDateTo = undefined;
      this.boundDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.boundDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.searchCriteria.boundDateTo &&
      this.searchCriteria.boundDateFrom &&
      this.searchCriteria.boundDateTo < this.searchCriteria.boundDateFrom
    ) {
      this.searchCriteria.boundDateTo = undefined;
      this.boundDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.boundDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.boundDateTo_min = value;
    this.boundDateTo_max = maxDate;

    // open datepicker
    this.boundDateTo.toggle();
  }
  public download(): void {
    if (this.validateLSTMandatoryFields()) {
      if (this.validateLSTInputFields()) {
        let warningParamters: warningZoneArgs = {
          showWarning: false,
          warningText: "",
        };
        this.warningZoneEvent.emit(warningParamters);
        this.downloadLSTReport();
      } else {
        let warningParamters: warningZoneArgs = {
          showWarning: true,
          warningText: "Please provide valid data to search.",
        };
        this.warningZoneEvent.emit(warningParamters);
      }
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  public gridSelectionChange(selection: SelectionEvent) {
    // console.log("gridSelectionChanged");
  }
  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    // console.log("cell clicked");
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }
  public sort: SortDescriptor[] = [
    {
      field: "policyNo",
      dir: "asc",
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }
  private loadData(): void {
    this.gridView = {
      data: orderBy(this.listing, this.sort),
      total: this.listing.length,
    };
  }
  private refresh(): void {
    this.loading = true;
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "policyNo");
    formData.append("sort[0][dir]", "asc");

    let array = [
      ["effectiveDateFrom", "eq", this.searchCriteria.effectiveDateFrom],
      ["effectiveDateTo", "eq", this.searchCriteria.effectiveDateTo],
      ["boundDateFrom", "eq", this.searchCriteria.boundDateFrom],
      ["boundDateTo", "eq", this.searchCriteria.boundDateTo],
      ["startDateFrom", "eq", this.searchCriteria.startDateFrom],
      ["startDateTo", "eq", this.searchCriteria.startDateTo],
      ["expiryDateFrom", "eq", this.searchCriteria.expiryDateFrom],
      ["expiryDateTo", "eq", this.searchCriteria.expiryDateTo],
      ["policyNo", "eq", this.searchCriteria.policyNo],
      ["insuredName", "eq", this.searchCriteria.insuredName],
      ["transType", "eq", this.searchCriteria.transType],
      ["productIds", "eq", this.searchCriteria.productIds],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }
    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    this.presetExportService.lstReportSearch(querystring).subscribe(
      (result: any) => {
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          this.listing = result.body.d?.results;
          this.totalListing = result.body.d?.__count;
          this.loadItems();
          this.loading = false;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  private loadItems(): void {
    this.gridView = {
      data: orderBy(this.listing, this.sort), //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }
  public search(): void {
    if (this.form.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }
    const isValid = this.validateLSTMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a month.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private downloadLSTReport() {
    this.presetExportService
      .lstReport(
        this.searchCriteria.effectiveDateFrom,
        this.searchCriteria.effectiveDateTo,
        this.searchCriteria.boundDateFrom,
        this.searchCriteria.boundDateTo,
        this.searchCriteria.startDateFrom,
        this.searchCriteria.startDateTo,
        this.searchCriteria.expiryDateFrom,
        this.searchCriteria.expiryDateTo,
        this.searchCriteria.policyNo,
        this.searchCriteria.insuredName,
        this.searchCriteria.transType,
        this.searchCriteria.productIds
      )
      .subscribe((response: any) => {
        if (
          response != undefined &&
          response.body != undefined &&
          response.body != null
        ) {
          this.excelTemplateService.downloadlstReport(
            lstReportColumn,
            "LST ",
            "LST_Report_" + this.formatDate(),
            response.body.d?.results
          );
        }
      });
  }
  private formatDate(): string {
    const date = new Date();
    return (
      date.getDate().toString() +
      "_" +
      (date.getMonth() + 1).toString() +
      "_" +
      date.getFullYear().toString()
    );
  }
}
const lstReportColumn: string[] = [
  "Policy No.",
  "Declaration Reference",
  "EPL Policy Number",
  "Currency",
  "Trans Code",
  "Endorsement No",
  "Gross TSI",
  "Gross Prem",
  "R/I - CO / Account",
  "Reference %",
  "Ceded Premium %",
  "Ceded Premium Amount",
  "LST Prem",
  "% ceded to Surplus",
  "Net TSI",
  "Net Premium",
  "Transaction Date",
  "Inception Date",
  "Effective Date",
  "Expiry Date",
  "Occupation (NAICS)",
  "Occupation (SSIC)",
  "Occupancy",
  "Risk Address",
  "Insured Name",
];
