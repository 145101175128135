import { Component, OnInit } from "@angular/core";
import { ConfigurableFormBaseComponent } from "../configurable-form-base/configurable-form-base.component";

@Component({
  selector: "app-configurable-form-add",
  templateUrl: "./configurable-form-add.component.html",
  styleUrls: ["./configurable-form-add.component.scss"],
})
export class ConfigurableFormAddComponent
  extends ConfigurableFormBaseComponent
  implements OnInit {}
