import { Injectable } from "@angular/core";
import { Workbook, Worksheet } from "exceljs";
import * as fs from "file-saver";
import { ExportListEntity } from "../components/new-product/home/export-list/ExportListEntity";
import { IBGBouncedMailPolicyEntity } from "../components/product/bounced-mail-policy/bounced-mail-policy.component";
import {
  IBGLoanRedemptionFailureEntity,
  IBGLoanRedemptionSuccessEntity,
} from "./upload-excel.service";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  constructor() {}

  constructWorkbookFailure(
    data: Array<IBGLoanRedemptionFailureEntity>,
    SegmentType: string,
    reportTitle: string
  ) {
    if (SegmentType === "IBG") {
      //Excel Title, Header, Data
      const headerDescription = [
        "SI No",
        "Bank Reference Number",
        "Policy Number",
        "MITD Date (DD/MM/YYYY)",
        "Insurance Action",
        "Remarks",
        "MSBR Date Received (DD/MM/YYYY)",
      ];

      const jsonTableHeader = [
        "BankRefNo",
        "PolicyNo",
        "MITDDate",
        "InsuranceAction",
        "Remarks",
        "MSBRDateReceived",
      ];

      this.generateExcel(
        "Loan Redemption Report",
        headerDescription,
        data,
        reportTitle,
        ColumnState.GenerateIndexAndUseGivenRows,
        jsonTableHeader
      );
    } else if (SegmentType === "Citi") {
      //Excel Title, Header, Data
      const headerDescription = [
        "SI No",
        "Policy Number",
        "Collateral Number",
        "Borrower/Property Owner",
        "Risk/Property Address",
        "Effective Date (DD/MM/YYYY)",
        "Remarks",
      ];

      const jsonTableHeader = [
        "PolicyNo",
        "BankRefNo",
        "InsuredName",
        "RiskAddress",
        "MITDDate",
        "Remarks",
      ];

      this.generateExcel(
        "Loan Redemption Report",
        headerDescription,
        data,
        reportTitle,
        ColumnState.GenerateIndexAndUseGivenRows,
        jsonTableHeader
      );
    } else {
      //Excel Title, Header, Data
      const headerDescription = [
        "SI No",
        "Policy Number",
        "Risk Address",
        "Effective Date (DD/MM/YYYY)",
        "Insurance Action",
        "Remarks",
        "MSBR Date Received (DD/MM/YYYY)",
      ];

      const jsonTableHeader = [
        "PolicyNo",
        "RiskAddress",
        "MITDDate",
        "InsuranceAction",
        "Remarks",
        "MSBRDateReceived",
      ];

      this.generateExcel(
        "Loan Redemption Report",
        headerDescription,
        data,
        reportTitle,
        ColumnState.GenerateIndexAndUseGivenRows,
        jsonTableHeader
      );
    }
  }

  private constructSummaryExcelRow(
    tempData: any,
    worksheet: Worksheet,
    SegmentType: string
  ): any {
    // push 5 row for every record
    for (let i = 0; i < 5; i++) {
      worksheet.addRow([]);
    }

    var total = 0;
    var successTotal = 0;
    if (SegmentType === "Citi") {
      total =
        tempData.citiExceptionTable.length + tempData.citiSuccessTable.length;
      successTotal = tempData.citiSuccessTable.length;
    } else {
      total =
        tempData.cBGExceptionTable.length +
        tempData.cBGSuccessTable.length +
        tempData.iBGExceptionTable.length +
        tempData.iBGSuccessTable.length;
      successTotal =
        SegmentType === "CBG"
          ? tempData.cBGSuccessTable.length
          : tempData.iBGSuccessTable.length;
    }

    let summaryRow = worksheet.addRow(["SUMMARY", "NO. OF CASES"]);
    summaryRow.font = { bold: true };

    if (SegmentType === "Citi") {
      let summaryHeaderRow = worksheet.addRow([
        "TOTAL NUMBER OF RECORDS IN DSG FILE PROVIDED BY Citibank:",
        total,
      ]);
      summaryHeaderRow.font = { bold: true };

      let summarySuccessRow = worksheet.addRow([
        "SUCCESSFULLY PROCESSED:",
        successTotal,
      ]);
      summarySuccessRow.font = { bold: true };

      let summaryExceptionRow = worksheet.addRow([
        "EXCEPTIONS:",
        tempData.citiExceptionTable.length,
      ]);
      summaryExceptionRow.font = { bold: true };
    } else {
      let summaryHeaderRow = worksheet.addRow([
        "TOTAL NUMBER OF RECORDS IN DSG FILE PROVIDED BY DBS:",
        total,
      ]);
      summaryHeaderRow.font = { bold: true };

      let summarySuccessRow = worksheet.addRow([
        "SUCCESSFULLY PROCESSED:",
        successTotal,
      ]);
      summarySuccessRow.font = { bold: true };

      var exceptionCount =
        SegmentType === "CBG"
          ? tempData.cBGExceptionTable.length
          : tempData.iBGExceptionTable.length;
      var exceptionCountCBG_IBG =
        SegmentType === "CBG"
          ? tempData.iBGExceptionTable.length
          : tempData.cBGExceptionTable.length;

      let summaryExceptionRow = worksheet.addRow([
        "EXCEPTIONS:",
        exceptionCount,
      ]);
      summaryExceptionRow.font = { bold: true };

      var exceptionType =
        (SegmentType === "CBG" ? "IBG" : "CBG") + " EXCEPTIONS:";

      let summaryExceptionTypeRow = worksheet.addRow([
        exceptionType,
        exceptionCountCBG_IBG,
      ]);
      summaryExceptionTypeRow.font = { bold: true };
    }
  }

  constructWorkbookSuccess(
    tempData: any,
    data: Array<IBGLoanRedemptionSuccessEntity>,
    SegmentType: string,
    reportTitle: string
  ) {
    if (SegmentType === "IBG") {
      //Excel Title, Header, Data
      const headerDescription = [
        "SI No",
        "Bank Reference Number",
        "Policy Number",
        "Policy Status",
        "Risk Address",
        "Insured Name",
        "Cancellation/Endorsement Effective Date",
        "Refund Premium",
        "Remarks",
        "Transaction Bound Date/Upload Date",
        "MSBR Date Received",
      ];

      const jsonTableHeader = [
        "BankRefNo",
        "PolicyNo",
        "PolicyStatus",
        "RiskAddress",
        "InsuredName",
        "MITDDate",
        "RefundPremium",
        "Remarks",
        "MSBRDate",
        "MSBRDateReceived",
      ];

      this.generateExcelWithSummary(
        "Loan Redemption Report",
        headerDescription,
        data,
        reportTitle,
        ColumnState.GenerateIndexAndUseGivenRows,
        tempData,
        SegmentType,
        jsonTableHeader
      );
    } else if (SegmentType === "Citi") {
      //Excel Title, Header, Data
      const headerDescription = [
        "SI No",
        "Policy Number",
        "Policy Status",
        "Collateral Number",
        "Risk Address",
        "Insured Name",
        "Cancellation/Endorsement Effective Date",
        "Refund Premium",
        "Remarks",
        "Transaction Bound Date/Upload Date",
        "Email Date Received",
      ];

      const jsonTableHeader = [
        "PolicyNo",
        "PolicyStatus",
        "BankRefNo",
        "RiskAddress",
        "InsuredName",
        "MITDDate",
        "RefundPremium",
        "Remarks",
        "MSBRDateReceived",
        "MSBRDate",
      ];

      this.generateExcelWithSummary(
        "Loan Redemption Report",
        headerDescription,
        data,
        reportTitle,
        ColumnState.GenerateIndexAndUseGivenRows,
        tempData,
        SegmentType,
        jsonTableHeader
      );
    } else {
      //Excel Title, Header, Data
      const headerDescription = [
        "SI No",
        "Policy Number",
        "Policy Status",
        "Risk Address",
        "Insured Name",
        "Cancellation/Endorsement Effective Date",
        "Refund Premium",
        "Remarks",
        "Transaction Bound Date/Upload Date",
        "MSBR Date Received",
      ];

      const jsonTableHeader = [
        "PolicyNo",
        "PolicyStatus",
        "RiskAddress",
        "InsuredName",
        "MITDDate",
        "RefundPremium",
        "Remarks",
        "MSBRDate",
        "MSBRDateReceived",
      ];

      this.generateExcelWithSummary(
        "Loan Redemption Report",
        headerDescription,
        data,
        reportTitle,
        ColumnState.GenerateIndexAndUseGivenRows,
        tempData,
        SegmentType,
        jsonTableHeader
      );
    }
  }

  generateBouncedMailPolicyExcel(
    data: any[] = Array<IBGBouncedMailPolicyEntity>()
  ) {
    //Excel Title, Header, Data
    const headerDescription = [
      "No",
      "Policy Number",
      "Customer ID",
      "Insured Name",
      "DOB(DD/MM/YY)",
      "Mobile Phone",
      "Email",
      "Correspondence Address",
      "System Last endorsement effective date",
      "Channel",
      "Status",
      "Prefered Delivery Method",
      "EPL Download Date",
    ];

    const jsonTableHeader = [
      "PolicyNo",
      "ClientCode",
      "InsuredName",
      "DOB",
      "MobileNo",
      "Email",
      "CorrespondenceAddress",
      "EndorsementEffectiveDate",
      "PolicyType",
      "PolicyStatus",
      "PreferedDeliveryMethod",
      "EplDownloadDate",
    ];

    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Bounced Mail Policy");

    //Blank Row
    worksheet.addRow([]);

    //Add Header Row
    let headerRow = worksheet.addRow(headerDescription);
    headerRow.font = { bold: true };

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    let filteredData = this.generateRowIndexAndGivenColumnsForBouncedMail(
      data,
      jsonTableHeader
    );

    // Add Data and Conditional Formatting
    filteredData.forEach((d: any) => {
      let row = worksheet.addRow(d);
    });

    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((filteredData) => {
      let blob = new Blob([filteredData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Bounced Mail Policy.xlsx");
    });
  }

  public generateExportList(data: any[] = Array<ExportListEntity>()) {
    //Excel Title, Header, Data
    const headerDescription = [
      "S/N",
      "Financial Institution Branch",
      "Master Policy No",
      "Product Type",
      "Policy No",
      "MSIG Policy No",
      "Quote No",
      "Status",
      "Insured Name",
      "CIN",
      "Risk Address",
      "City / Suburb",
      "Country",
      "Postal Code",
      "Sum Insured",
      "Bank Reference",
      "Bound Date",
      "Inception Date",
      "Expiry Date",
      "Maker's ID",
      "Date / Time",
      "Checker's ID",
      "Date / Time",
    ];

    const jsonTableHeader = [
      "serialNo",
      "buPcCode",
      "masterPolicyNo",
      "productType",
      "policyNo",
      "MSIGPolicyNo",
      "quoteNo",
      "status",
      "insuredName",
      "cin",
      "riskAddress",
      "city",
      "country",
      "riskAddressPostCode",
      "sumInsured",
      "bankReference",
      "boundDate",
      "inceptionDate",
      "expiryDate",
      "makerUserName",
      "submittedDateTime",
      "checkerUserName",
      "checkedDateTime",
    ];

    this.generateExcel(
      "Export Report",
      headerDescription,
      data,
      "Export Report.xlsx",
      ColumnState.UseGivenRows,
      jsonTableHeader
    );
  }

  private generateExcel(
    worksheetTitle: string,
    headerDescription: string[],
    data: any[],
    excelFileName: string,
    columnState: ColumnState = ColumnState.AsItIs,
    jsonColumnHeader: string[] = [],
    columnsToSkip: number = 0
  ) {
    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(worksheetTitle);

    //Blank Row
    worksheet.addRow([]);

    //Add Header Row
    let headerRow = worksheet.addRow(headerDescription);
    headerRow.font = { bold: true };

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    let filteredData = [];

    switch (columnState) {
      case ColumnState.AsItIs:
        filteredData = this.generateRowsAsItIs(data);
        break;
      case ColumnState.UseGivenRows:
        filteredData = this.generateRowsWithGivenColumns(
          data,
          jsonColumnHeader
        );
        break;
      case ColumnState.GenerateIndexAndUseGivenRows:
        filteredData = this.generateRowIndexAndGivenColumns(
          data,
          jsonColumnHeader
        );
        break;
      default:
        //
        break;
    }

    // Add Data and Conditional Formatting
    filteredData.forEach((d: any) => {
      let row = worksheet.addRow(d);
    });

    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((filteredData) => {
      let blob = new Blob([filteredData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, excelFileName);
    });
  }

  private generateExcelWithSummary(
    worksheetTitle: string,
    headerDescription: string[],
    data: any[],
    excelFileName: string,
    columnState: ColumnState = ColumnState.AsItIs,
    tempData: any,
    SegmentType: string,
    jsonColumnHeader: string[] = [],
    columnsToSkip: number = 0
  ) {
    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(worksheetTitle);

    //Blank Row
    worksheet.addRow([]);

    //Add Header Row
    let headerRow = worksheet.addRow(headerDescription);
    headerRow.font = { bold: true };

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    let filteredData = [];

    switch (columnState) {
      case ColumnState.AsItIs:
        filteredData = this.generateRowsAsItIs(data);
        break;
      case ColumnState.UseGivenRows:
        filteredData = this.generateRowsWithGivenColumns(
          data,
          jsonColumnHeader
        );
        break;
      case ColumnState.GenerateIndexAndUseGivenRows:
        filteredData = this.generateRowIndexAndGivenColumns(
          data,
          jsonColumnHeader
        );
        break;
      default:
        //
        break;
    }

    // Add Data and Conditional Formatting
    filteredData.forEach((d: any) => {
      let row = worksheet.addRow(d);
    });

    this.constructSummaryExcelRow(tempData, worksheet, SegmentType);

    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((filteredData) => {
      let blob = new Blob([filteredData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, excelFileName);
    });
  }

  private generateRowIndexAndGivenColumns(
    data: any[],
    columns: string[]
  ): any[] {
    //let filteredData = data.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y)], []);
    let filteredData = [];
    let rowId = 1;
    for (let i in data) {
      let temp = [];

      for (let j = 0; j < columns.length; j++) {
        temp.push(data[i][columns[j]]);
      }
      temp.unshift(rowId); //push the rowId to first
      rowId++;
      filteredData.push(temp);
    }

    return filteredData;
  }

  private generateRowsWithGivenColumns(data: any[], columns: string[]): any[] {
    //let filteredData = data.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y)], []);
    let filteredData = [];
    for (let i in data) {
      let temp = [];

      for (let j = 0; j < columns.length; j++) {
        temp.push(data[i][columns[j]]);
      }
      filteredData.push(temp);
    }

    return filteredData;
  }

  private generateRowsAsItIs(data: any[]): any[] {
    //let filteredData = data.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y)], []);
    let filteredData = [];
    for (let i in data) {
      let temp = [];
      let counter = 0;
      for (let j in data[i]) {
        temp.push(data[i][j]);
        counter++;
      }
      filteredData.push(temp);
    }

    return filteredData;
  }

  private generateRowIndexAndGivenColumnsForBouncedMail(
    data: any[],
    columns: string[]
  ): any[] {
    //let filteredData = data.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y)], []);
    let filteredData = [];
    let rowId = 1;
    for (let i in data) {
      for (let j in data[i].Transactions) {
        let temp = [];
        temp.push(rowId); //push the rowId to first
        for (let k = 0; k < columns.length; k++) {
          temp.push(data[i].Transactions[j][columns[k]]);
        }
        rowId++;
        filteredData.push(temp);
      }
    }

    return filteredData;
  }
}

export enum ColumnState {
  AsItIs,
  UseGivenRows,
  GenerateIndexAndUseGivenRows,
}
