<kendo-dialog *ngIf="opened" [width]="900">
  <div class="modal-header-custom">
    <h3 class="modal-title-custom chubbLightOrange_bg">Open Item Details</h3>
  </div>
  <div class="ml-2">
    <label
      style="margin: 10px 0px 10px 0px; font-weight: bold"
      [textContent]="'Policy Certificate Number:'"
    ></label>
    <span class="ml-2" [textContent]="displayPolicyCertificateNo"> </span>
    <kendo-grid
      style="cursor: pointer"
      [data]="gridView"
      mode="multiple"
      [resizable]="true"
      kendoGridSelectBy
    >
      <kendo-grid-column field="dateCreated" title="Date / Time" [width]="140">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.dateCreated | date : "short" }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="statusDisplayText" title="Status" [width]="160">
      </kendo-grid-column>

      <kendo-grid-column field="batchId" title="Batch Id" [width]="90">
      </kendo-grid-column>
      <!-- <kendo-grid-column field="invoiceNo" title="Invoice No" [width]="130">
      </kendo-grid-column> -->
      <kendo-grid-column field="amount" title="Total Premium" [width]="130">
      </kendo-grid-column>
      <kendo-grid-column field="amountGst" title="Levy" [width]="90">
      </kendo-grid-column>
      <kendo-grid-column field="paymentTypeId" title="Paid by" [width]="90">
        <ng-template kendoGridCellTemplate let-dataItem>
          <label>{{ getPaymentTypeDisplayText(dataItem) }}</label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="autoDebitDate"
        title="Payment Month"
        [width]="130"
      >
      </kendo-grid-column>
      <kendo-grid-column field="remarks" title="Remarks" [width]="250">
      </kendo-grid-column>
      <kendo-grid-column
        field="lastUpdatedByUserName"
        title="Action By"
        [width]="110"
      >
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="modal-footer">
    <kendo-dialog-actions>
      <button
        kendoButton
        (click)="close('return')"
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel ng-binding"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
