import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as Enums from "./../../././framework/enum.shared";

import { ExceptionManager } from "./../../framework/utils/exception-manager";

@Injectable({
  providedIn: "root",
})
/** Service to track user current proposal activity */
export class ProposalActivityService {
  proposalId?: string;
  productId?: string;
  action?: string;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.proposalId = params["pid"];
      this.productId = params["pdid"];
      this.action = params["action"];
    });
  }

  readProposalId(): string | null {
    return this.proposalId!; //this.route.snapshot.paramMap.get("pid") ?
  }

  readProductId(): string | null {
    return this.productId!; //this.route.snapshot.paramMap.get("pdid");
  }

  readCommandAction(): Enums.ProposalCommandAction | undefined {
    //const actionId = Number(this.route.snapshot.paramMap.get("action"));
    const actionId = Number(this.action!);
    switch (actionId) {
      case 1:
        return Enums.ProposalCommandAction.CreateOrEdit;

      case 8:
        return Enums.ProposalCommandAction.Open;

      default:
        ExceptionManager.error("unhandled command action");
        return undefined;
    }
  }
}
