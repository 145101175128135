<!-- Kendo Tabs -->
<!-- <div kendo-tab-strip id="tabstrip" ng-show="model.pageLoaded">
  <ul>
    <li
      id="master"
      style="cursor: default"
      ng-show="model.master.visible"
      ng-click="model.click(model.master.index)"
    >
      <span ng-bind="model.master.displayName"></span>
    </li>
    <li
      id="premiumSummary"
      style="cursor: default"
      ng-show="model.premiumSummary.visible"
      ng-click="model.click(model.premiumSummary.index)"
    >
      <span ng-bind="model.premiumSummary.displayName"></span>
    </li>
  </ul>
</div> -->
<kendo-tabstrip
  (tabSelect)="onTabSelect($event)"
  id="tabstrip"
  *ngIf="pageLoaded"
>
  <kendo-tabstrip-tab
    title="{{ master.displayName }}"
    id="master"
    style="cursor: default"
    *ngIf="master.visible"
    [selected]="true"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <!--todo-->
      </div>
    </ng-template></kendo-tabstrip-tab
  >
  <kendo-tabstrip-tab
    title="{{ premiumSummary.displayName }}"
    id="premiumSummary"
    style="cursor: default"
    *ngIf="premiumSummary.visible"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <!--todo-->
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
