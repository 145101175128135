<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9">
        <div class="form-group row">
          <div class="col-md-12">
            <button
              id="btnReturn"
              type="button"
              class="btn btn-primary ld-ext-left chubbDarkBlue_bg chubbDarkBlue_border_btn"
              (click)="returnToListing()"
            >
              <span>Return</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group row" style="float: right">
          <div class="col-md-12">
            <button
              id="btnEditDelete"
              type="button"
              class="btn btn-primary ld-ext-left chubbRed_bg chubbRed_border_btn"
              (click)="delete()"
            >
              <span>Delete</span>
            </button>
            <button
              id="btnEditReset"
              type="button"
              class="btn btn-primary ld-ext-left chubbRed_bg chubbRed_border_btn"
              (click)="reset()"
            >
              <span>Reset</span>
            </button>
            <button
              id="btnEditSave"
              type="button"
              class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
              (click)="save()"
            >
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--Error Log -->
    <kendo-expansionpanel
      *ngIf="errorLogs.Logs.length > 0"
      id="main"
      title="Error"
      [expanded]="true"
    >
      <div class="container-fluid">
        <div class="row">
          <header
            class="fontBold chubbWhite"
            [innerHtml]="errorLogs.Title"
          ></header>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <ul>
                <li *ngFor="let log of errorLogs.Logs" [innerHtml]="log"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </kendo-expansionpanel>
    <!--Attachments -->
    <kendo-expansionpanel title="Edit" [expanded]="true">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="marginTop5" for="selMasterPolicy"
                  >Coverage Type</label
                >
              </div>
              <div class="col-md-6">
                <kendo-multiselect
                  id="selMasterPolicy"
                  placeholder="Select Coverage Type"
                  [autoClose]="false"
                  [kendoDropDownFilter]="{
                    operator: 'contains'
                  }"
                  [data]="coverageTypeOptions"
                  textField="text"
                  valueField="value"
                  [valuePrimitive]="true"
                  [(ngModel)]="model.coverageTypes"
                  name="masterPolicy"
                >
                </kendo-multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-md-3">
                <label class="marginTop5" for="txtTitle">Title</label>
              </div>
              <div class="col-md-9">
                <input
                  type="text"
                  class="form-control"
                  id="txtTitle"
                  placeholder="Title"
                  [(ngModel)]="model.title"
                  name="title"
                  #name="ngModel"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="marginTop5" for="txtCode">Code</label>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="txtCode"
                  placeholder="Code"
                  [(ngModel)]="model.code"
                  name="code"
                  #name="ngModel"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label>
              <input
                name="addDefault"
                type="checkbox"
                value="{{ model.default }}"
                [(ngModel)]="model.default"
                id="addDefault"
              />
              Default
            </label>
          </div>
        </div>
        <div class="row">
          <kendo-editor
            [(value)]="model.editorValue"
            style="height: 370px; width: 100%"
            ><kendo-toolbar #toolbar [overflow]="true">
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button
                  kendoEditorBoldButton
                ></kendo-toolbar-button>
                <kendo-toolbar-button
                  kendoEditorItalicButton
                ></kendo-toolbar-button>
                <kendo-toolbar-button
                  kendoEditorUnderlineButton
                ></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
    </kendo-expansionpanel>
  </div>
</div>
