import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { OpenItemListService } from "src/app/services/open-item-list.service";
import { Identity } from "src/app/shared/user/identity";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { BaseOpenItemListComponent } from "../../new-product/base/base-open-item-list/base-open-item-list.component";
import { Router } from "@angular/router";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";

@Component({
  selector: "app-open-item-list",
  templateUrl: "./open-item-list.component.html",
  styleUrls: ["./open-item-list.component.scss"],
})
export class OpenItemListComponent
  extends BaseOpenItemListComponent
  implements OnInit
{
  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    @Inject(LOCALE_ID) public override locale: string,
    public override openItemListService: OpenItemListService,
    public override identity: Identity,
    public override excelTemplate: ExcelTemplateService,
    public override cdkDialogService: CdkDialogService
  ) {
    super(
      router,
      broadcastService,
      identityRolesService,
      locale,
      openItemListService,
      identity,
      excelTemplate,
      cdkDialogService
    );
  }

  // this.refresh() in ngOnInit() has to be called separately by component,
  // cannot place in BaseOpenItemListComponent, else call twice
  override ngOnInit(): void {
    super.ngOnInit();

    this.defaultSearchCriteria();
    this.readProductRadio();
    this.fetchUserList();
    this.fetchStatusList();
    this.initStatusLists();
    this.setDbsAutoDebitPeriod();
    this.refresh();
  }
}
