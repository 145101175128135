<kendo-dialog
  *ngIf="opened"
  title="{{ message }}"
  class="{{ titlebarCssClass }}"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="dlgWidth"
  [minHeight]="180"
  [maxHeight]="dlgMaxHeight"
>
  <div class="container-fluid-custom">
    <div class="modal-body">
      <h6 class="fontBold">Type of Equipment</h6>
      <span>{{ data.equipmentType.key }}</span>
      <br />
      <br />
      <br />
      <h6 class="fontBold">Additional Info</h6>
      <span>{{ data.additionalInfo }}</span>
    </div>
  </div>
  <div class="modal-footer"></div>
</kendo-dialog>
