<kendo-dialog
  *ngIf="active"
  [width]="600"
  [height]="470"
  (close)="closeForm()"
  style="z-index: 1000"
>
  <kendo-dialog-titlebar class="modal-title-custom chubbLightOrange_bg">
    Manual Payment
  </kendo-dialog-titlebar>
  <div class="container-fluid" style="padding-top: 15px">
    <div *ngIf="invalidRequiredField" id="alertMessage" class="row">
      <div class="col-md-12">
        <div class="alert alert-danger displayBlock">
          <label class="margin0">
            <span>
              <i
                class="fa fa-exclamation-circle marginRight5"
                aria-hidden="true"
              ></i>
              Please fill in the required fields.
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="policyNo">Policy Number</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="policyNo"
          [textContent]="openItemEntity.policyNo"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4" [ngClass]="{ 'st-danger': invalidPayerName }">
        <label class="marginTop5 required" for="payerName">Payer Name</label>
      </div>
      <div [ngClass]="{ 'st-danger': invalidPayerName }" class="col-md-8">
        <input
          type="text"
          class="form-control"
          id="payerName"
          placeholder="Payer Name"
          [(ngModel)]="manualPayment.payerName"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="amount">Total Premium</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="amount"
          [textContent]="openItemEntity.amount"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="amountGst">Levy</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="amountGst"
          [textContent]="openItemEntity.amountGst"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" [ngClass]="'required'">Paid by</label>
      </div>
      <div class="col-md-8">
        <div>
          <label class="radio-inline">
            <input
              type="radio"
              name="paymentType"
              [value]="2"
              [(ngModel)]="manualPayment.paymentType"
              (change)="onChangePaymentType(2)"
            />Customer
          </label>
          <label class="radio-inline">
            <input
              type="radio"
              name="paymentType"
              [value]="1"
              [(ngModel)]="manualPayment.paymentType"
              (change)="onChangePaymentType(1)"
            />HASE Bank
          </label>
        </div>
      </div>
    </div>
    <!-- Common fields -->
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="remarks">Remarks</label>
      </div>
      <div class="col-md-8">
        <textarea
          class="form-control"
          rows="3"
          id="remarks"
          [(ngModel)]="manualPayment.remarks"
          maxlength="250"
        ></textarea>
      </div>
    </div>
  </div>
  <kendo-dialog-actions>
    <button
      kendoButton
      themeColor="primary"
      class="btn chubbWhite chubbLightBlue_bg_btn"
      (click)="onSave($event)"
    >
      Make Payment
    </button>
    <button
      kendoButton
      class="btn chubbWhite chubbRed_bg_btn"
      (click)="onCancel($event)"
    >
      Return
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
