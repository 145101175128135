import { GUID } from "src/app/framework/domain-entity/guid";
import { MfHkConfigAddCriteria } from "../configurable-form-add/mf-hk-config-add-criteria";

export class MfHkConfigEditCriteria extends MfHkConfigAddCriteria {
  constructor() {
    super();
  }

  guid?: GUID;
}
