import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import { DIALOG_DATA } from "src/app/components/shared/partials/dialog/dialog-tokens";

@Component({
  selector: "app-md-validation-message",
  templateUrl: "./md-validation-message.component.html",
  styleUrls: ["./md-validation-message.component.scss"],
})
export class MdValidationMessageComponent implements OnInit {
  message: SimpleMessageEntity[] = [];
  title = "";
  showAddLocationForm: boolean = false;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any
  ) {
    if (this.inputData) {
      if (Array.isArray(inputData)) {
        this.message = inputData;
        this.title = "Please check the field(s) below";
      } else {
        this.message = [{ text: inputData.validationMessages }];
        this.title = inputData.title;
      }
    }
  }

  ngOnInit(): void {}

  addLocation() {
    this.showAddLocationForm = true;
  }

  cancelAddLocationForm() {
    this.showAddLocationForm = false;
  }

  public close(status: any) {
    this.dialogRef?.close(null);
  }
}

export class SimpleMessageEntity {
  text!: string;
}
