import { SearchCriteria } from "src/app/components/new-product/home/base/search-criteria";
/** study materials

https://stackoverflow.com/questions/3635754/sql-select-between-exact-number-of-rows

select * from
    (select Row_Number() over
     (order by userID) as RowIndex, * from users) as Sub
    Where Sub.RowIndex >= 235 and Sub.RowIndex <= 250
 */

export class MfHkSearchCriteria extends SearchCriteria {
  constructor() {
    super();
  }

  interfaceDateFrom?: Date;
  interfaceDateTo?: Date;
  mortgageLoanAccountNumber1?: string;
  insuredPropertyAddress?: string;
  migrated?: string;
}

export class MfHkReport{
  reportId!: string;
  reportName!: string;
}