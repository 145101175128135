import { Component, Inject, ViewChild } from "@angular/core";
import { BaseClientCreateComponent } from "src/app/components/shared/partials/mdClient/client-create/base/base-client-create/base-client-create.component";
import { ClientEntity } from "src/app/models/customer/base/client-entity.model";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import { common } from "src/app/framework/utils/common";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import { Global } from "src/app/shared/global";
import { ClientAddressEntity } from "src/app/models/customer/base/client-address-entity.model";
import * as Enums from "src/app/framework/enum.shared";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import { DIALOG_DATA } from "src/app/components/shared/partials/dialog/dialog-tokens";
import { clientCreateDlgInputArgs } from "src/app/components/shared/partials/mdClient/client-create/base/base-client-create/client-create-dlg-input-args";
import { ClientService } from "src/app/services/customer/client.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { ProductService } from "src/app/services/new-product/base/product.service";
import { NotifierService } from "src/app/services/notifier.service";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { MfHkAddressHelpFormComponent } from "../../mf-hk-address-help-form/mf-hk-address-help-form.component";

@Component({
  selector: "app-mf-hk-client-create",
  templateUrl: "./mf-hk-client-create.component.html",
  styleUrls: ["./mf-hk-client-create.component.scss"],
})
export class MfHkClientCreateComponent extends BaseClientCreateComponent {
  @ViewChild("insuredAddress3", { static: false })
  public insuredAddress3: any;

  @ViewChild("insuredAddress4", { static: false })
  public insuredAddress4: any;

  @ViewChild("clientDistrictCode", { static: false })
  public clientDistrictCode: any;

  addAddress3RO: boolean = true;
  addAddress4RO: boolean = true;
  // addBuildingNameRO: boolean = true;
  // addUnitNoRO: boolean = true;
  // addHkDistrictCodeRO: boolean = true;

  isOthers: boolean = false;
  public defaultItem: { text: string; value: string } = {
    text: "Select item...",
    value: "",
  };

  hkDistrictList!: any[];

  constructor(
    public override clientService: ClientService,
    public override masterDataService: MasterDataService,
    public override cdkDialogService: CdkDialogService,
    @Inject(DIALOG_DATA) public override inputData: clientCreateDlgInputArgs,
    public override dialogRef: DialogRef,
    public override notifier: NotifierService,
    public override productService: ProductService,
    public proposalService: MfHKProposalService
  ) {
    super(
      clientService,
      masterDataService,
      cdkDialogService,
      inputData,
      dialogRef,
      notifier,
      productService
    );

    if (this.inputData) {
      this.proposal = this.inputData.proposal as MortgageFireHongKongProposal; //temp pass from outside
      this.isCreate = this.inputData.isNewClient;
      this.labelText = "Create";
      this.h3LabelText = "Client - Create";
      if (!this.isCreate) {
        this.labelText = "Update";
        this.isCreate = false;
        this.h3LabelText = "Change of Client Info";
      }
    }
  }

  override async ngOnInit(): Promise<void> {
    if (!this.isCreate || this.isChangeOfClientInfo()) {
      //has to wait until fully finish to get latest this.clientData
      var response = await this.getClient_Promise();
      this.updateClientData(response);
    }
    this.dialogOkNotifierSubscription =
      dialogActionNotifier.ok_notifier$.subscribe((event) => {
        this.createClient();
      });

    this.initialize();
    //  this.checkIsNewClient();
  }

  protected isChangeOfClientInfo() {
    //when 'Endorsement - Change Of Client Information', clientData is also needed
    let getClient = false;
    if (this.proposal) {
      let isIncomplete =
        this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete;
      let isChangeOfClientInfo =
        this.proposal.endorsementType ===
        Enums.EndorsementType.ChangeOfClientInfo;
      getClient = isIncomplete && isChangeOfClientInfo;
    }
    return getClient;
  }

  private updateClientData(response: any) {
    if (response) {
      this.clientData = response;
      this.setDate(this.clientData.birthDate);
      // check for docType, assign to empty if is 0
      if (common.isDefined(this.clientData.docType)) {
        this.clientData.docType =
          parseInt(this.clientData.docType) === 0
            ? ""
            : this.clientData.docType.toString();
      }

      this.proposalService.proposal.customerMappingAddressID = Number(
        response.address[0].clientAddressID
      );
      this.address = response.address[0];
      this.setClientType(this.clientData.clientType);

      this.getIsStateVisible(this.address.countryISO2);
    }
  }

  public getClient_Promise() {
    return this.clientService
      .get(
        this.proposalService.proposal.customerMappingId!,
        this.proposalService!.proposal.qsMaster.qs_master_insuredNo!.answer
      )
      .toPromise();
  }

  override initialize() {
    this.postBackData = [];
    this.proposal = this.proposalService.proposal;
    this.nationalityList = new Array<any>();
    this.countryList = new Array<any>();
    super.subscribeTabLoadRenderer();
    super.subscribeTabLoadRenderer();
    if (this.isCreate) this.initializeQuestionSchema();
    // $(document).ready(() => {
    this.componentsInitialize();
    // });

    this.initProductFactory();

    this.masterDataService
      .getHkDistrictCodes()
      .subscribe((responseHkDistrictCodes: any[]) => {
        this.hkDistrictList = responseHkDistrictCodes;
      });
  }

  //better put in after ngAfterViewInit(), not in initialize()
  // https://github.com/skynet2/ngx-google-places-autocomplete/issues/91
  ngAfterViewInit() {
    //maybe after async ngOnInit(), the ngAfterViewInit() is called before initialize()
    //hence the productFactory is not yet init
    this.initProductFactory();

    this.clientInformation_autocomplete.options.componentRestrictions =
      this.googlePlacesOptions.componentRestrictions; //init by country here
  }

  private initProductFactory() {
    if (!this.productFactory) {
      this.productFactory = this.productService.getHKProductFactory(
        (this.proposal as MortgageFireHongKongProposal).productType
      );
    }

    this.googlePlacesOptions = this.productFactory
      .googlePlaces()
      .getGooglePlacesOptions(); //init by country here
  }

  override setClientType(clientType: EnumSG.ClientType) {
    switch (clientType) {
      case EnumSG.ClientType.Person:
        {
          this.isPersonal = true;
          this.isOrganization = false;
          this.isOthers = false;
        }
        break;
      case EnumSG.ClientType.Others:
        {
          this.isPersonal = false;
          this.isOrganization = false;
          this.isOthers = true;
        }
        break;
      default:
        {
          this.isPersonal = false;
          this.isOrganization = true;
          this.isOthers = false;
        }
        break;
    }
  }

  override selectClientType = (e: any): void => {
    this.emptyFieldValidation = "";
    this.showErrorMessage = false;
    if (this.isCreate) {
      this.clientData.cinNo = "";
    }

    this.errorMsgInHtml = "";
    this.clientData.clientType = Number(e.currentTarget.value);

    this.setClientType(this.clientData.clientType);

    if (e.currentTarget.value === "1") {
      // this.isOrganization = false;
      // this.isPersonal = true;
      this.clientData.businessRegistrationNumber = "";
      this.clientData.businessRegistrationNumber2 = "";
      this.clientData.clientName = "";
      this.clientData.personInCharge = "";
    } else if (e.currentTarget.value === "2") {
      // this.isOrganization = true;
      // this.isPersonal = false;
      this.clientData.salutation = "";
      this.clientData.firstName = "";
      this.clientData.lastName = "";
      this.clientData.birthDate = "";
      this.clientData.gender = "";
      this.clientData.maritalStatus = "";
      this.clientData.nric = "";
      this.clientData.passportNo = "";
      this.clientData.occupation = "";
      this.clientData.race = "";
    } else if (e.currentTarget.value === "3") {
      //others
      this.clientData.businessRegistrationNumber = "";
      this.clientData.businessRegistrationNumber2 = "";
      this.clientData.dbsClientReferenceNumber = "";
      this.clientData.clientName = "";
      this.clientData.personInCharge = "";
    }
    this.clientData.docType = ""; //for all docType

    this.componentsInitialize();
  };

  override updateAddressValues = (
    place: { address_components: string | any[] },
    componentForm: { [x: string]: string | number },
    divId: string
  ): void => {
    this.resetClientAddressForm();
    if (Global.isDefined(place.address_components)) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        //if (componentForm[addressType]) {
        //const val = place.address_components[i][componentForm[addressType]];
        switch (addressType) {
          case "street_number":
            let val1 = place.address_components[i].long_name;
            this.address.addressLine3 = val1;
            this.validateReadOnlyAddress(val1, "addAddress1RO");
            break;
          case "route":
            let val2 = place.address_components[i].long_name;
            this.address.addressLine3 += " " + val2;
            this.validateReadOnlyAddress(val2, "addAddress2RO");
            break;
          case "locality":
            let val3 = place.address_components[i].long_name;
            this.address.city = val3;
            this.validateReadOnlyAddress(val3, "addCityRO");
            break;
          case "postal_code":
            let val4 = place.address_components[i].long_name;
            this.address.postCode = val4;
            this.validateReadOnlyAddress(val4, "addPostcodeRO");
            break;
          case "administrative_area_level_1":
            //map to district code
            let val5 = place.address_components[i].short_name;
            let found = this.hkDistrictList.find((i) => i.key === val5);
            if (found) {
              this.address.hkDistrictCode = found.value;
            }
            this.validateReadOnlyAddress(val5, "addHkDistrictCodeRO");
            break;
          case "country":
            let val6 = place.address_components[i].short_name;
            this.address.countryISO2 = val6.trim();
            this.validateReadOnlyAddress(val6, "addCountryRO");
            this.getIsStateVisible(val6);
            break;
          default:
            break;
        }
        // clearing the search input
        //this.clientInformation_autocomplete.nativeElement.value = "";//todo
        //}
        this.inputReadOnlyAddress();
      }
    }
    //this.this.$apply();//todo
  };

  override validateReadOnlyAddress = (value: any, inputId: any) => {
    if (value !== "") {
      const index = this.readOnlyFields.indexOf(inputId);
      switch (inputId) {
        case "addAddress1RO":
          this.addAddress1RO = false;
          break;
        case "addAddress2RO":
          this.addAddress1RO = false; //bundle
          this.addAddress2RO = false;
          this.addAddress3RO = false; //bundle
          this.addAddress4RO = false; //bundle
          break;
        case "addAddress3RO":
          this.addAddress3RO = false;
          break;
        case "addAddress4RO":
          this.addAddress4RO = false;
          break;
        // case "addHkDistrictCodeRO":
        //   this.addHkDistrictCodeRO = false;
        //   break;
        case "addCityRO":
          this.addCityRO = false;
          break;
        case "addPostcodeRO":
          this.addPostcodeRO = false;
          break;
        case "addStateRO":
          this.addStateRO = false;
          break;
        case "addCountryRO":
          this.addCountryRO = false;
          break;
        default:
          break;
      }
      if (index !== -1) {
        this.readOnlyFields.splice(index, 1);
      }
    }
  };

  override setCinText() {
    this.CINText = "CIN";
  }

  atLeastOneCarParkAddressIsFilled(address: ClientAddressEntity) {
    let clientAddressMandatory: any[] = [];
    let contactMandatory: any[] = [
      {
        id: "insuredCountry",
        label: "Country",
        qid: this.address.countryISO2,
      },
      // {
      //   id: "brokerMobileNo",
      //   label: "Mobile Number",
      //   qid: this.clientData.mobileNumber,
      // },
    ];
    let allContactMandatoryField: any[];

    if (
      common.isStringNullOrEmpty(address.addressLine1) &&
      common.isStringNullOrEmpty(address.addressLine2) &&
      common.isStringNullOrEmpty(address.addressLine3) &&
      common.isStringNullOrEmpty(address.addressLine4)
    ) {
      clientAddressMandatory = [
        {
          id: "insuredAddress1",
          label: "Address Line 1",
          qid: this.address.addressLine1,
        },
        // {
        //   id: "insuredAddress2",
        //   label: "Address Line 2",
        //   qid: this.address.addressLine2,
        // },
        // {
        //   id: "insuredAddress3",
        //   label: "Address Line 3",
        //   qid: this.address.addressLine3,
        // },
        // {
        //   id: "insuredAddress4",
        //   label: "Address Line 4",
        //   qid: this.address.addressLine4,
        // },
      ];
    }

    allContactMandatoryField = contactMandatory.concat(clientAddressMandatory);

    return allContactMandatoryField;
  }

  protected override resetClientAddressForm() {
    this.readOnlyFields = [
      "addAddress1RO",
      "addAddress2RO",
      "addAddress3RO",
      "addAddress4RO",
      "addCityRO",
      "addPostcodeRO",
      "addStateRO",
    ];
    this.address.buildingName = "";
    this.address.unitNo = "";
    this.address.addressLine1 = "";
    this.address.addressLine2 = "";
    this.address.addressLine3 = "";
    this.address.addressLine4 = "";
    this.address.city = "";
    this.address.postCode = "";
    this.address.stateName = "";

    this.updateNativeElement(this.insuredAddress_lotunit, "");
    this.updateNativeElement(this.insuredAddress_building, "");
    this.updateNativeElement(this.insuredAddress1, "");
    this.updateNativeElement(this.insuredAddress2, "");
    this.updateNativeElement(this.insuredAddress3, "");
    this.updateNativeElement(this.insuredAddress4, "");
    this.updateNativeElement(this.insuredCity, "");
    this.updateNativeElement(this.insuredPostalCode, "");
    this.updateNativeElement(this.insuredState, "");
    //this.clientInformation_autocomplete.nativeElement.value = "";//todo
  }

  protected override phoneValidation = (inputId: any): void => {
    let inputValue = "";

    switch (inputId) {
      case "brokerPhoneNo": {
        inputValue = this.clientData.phoneNoHome.trim();
        break;
      }
      case "brokerOfficeNo": {
        inputValue = this.clientData.phoneNoOffice.trim();
        break;
      }
      default: {
        inputValue = this.clientData.mobileNumber.trim();
        break;
      }
    }

    let errorText: string = "";

    var phoneno = /^\+?[0-9]+$/;
    if (inputValue.length > 0) {
      if (!inputValue.match(phoneno)) {
        errorText = this.getPhoneValidationText(inputId);
        this.showErrorMessage = true;
        this.errorMsgInHtml = "";
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2  chubbRed"' +
          ' aria-hidden="true"></i> Please enter a valid <b> ' +
          errorText +
          "</b></div>";
        // angular.element('.modal-body').animate({ scrollTop: 0 }, 200);
      } else {
        this.showErrorMessage = false;
        this.errorMsgInHtml = "";
        //   angular
        //     .element("#" + inputId)
        //     .closest(".form-group")
        //     .removeClass("has-error");
      }
    }
  };

  protected override openAddressHelp(event: {
    preventDefault: () => void;
  }): void {
    event.preventDefault();
    const data = {
      // 'proposalModel': this.proposal.proposalModel,
      showGeoCode: false, // determine to hide/show geocode
    };

    const clientCreateDialogRef = this.cdkDialogService.open(
      MfHkAddressHelpFormComponent,
      {
        data: data,
      }
    );

    clientCreateDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }

  override createClient = () => {
    this.showErrorMessage = false;
    //auto assigned, since user could have enter address without googlePlaceApi
    this.address.countryISO2 = "HK";
    //const deferred = this.$q.defer();

    if (this.customerTypeCheck()) {
      let personalMandatory: any[];
      let orgMandatory: any[];
      let otherMandatory: any[];
      let contactMandatory: any[];
      let allMandatoryField: any[];

      personalMandatory = [
        {
          id: "clientDocType",
          label: "Mortgagor ID Type",
          qid: this.clientData.docType === "0" ? "" : this.clientData.docType,
        },
        {
          id: "clientName",
          label: "Mortgagor Name",
          qid: this.clientData.clientName,
        },
      ];

      if (this.clientData.docType === "I") {
        personalMandatory.push({
          id: "clientNRICNo",
          label: "Mortgagor ID (NRIC)",
          qid: this.clientData.nric,
        });
      } else if (this.clientData.docType === "P") {
        personalMandatory.push({
          id: "clientPassportNo",
          label: "Mortgagor ID (Passport Number)",
          qid: this.clientData.passportNo,
        });
      } else if (this.clientData.docType === "X") {
        personalMandatory.push({
          id: "clientDBClientReferenceNumber",
          label: "Mortgagor ID (DB Client Reference Number)",
          qid: this.clientData.dbsClientReferenceNumber,
        });
      }

      contactMandatory = this.atLeastOneCarParkAddressIsFilled(this.address);

      if (
        this.clientData.clientType === EnumSG.ClientType.Person ||
        this.clientData.clientType === EnumSG.ClientType.Others
      ) {
        allMandatoryField = personalMandatory.concat(contactMandatory);
      } else if (this.clientData.docType === "B") {
        orgMandatory = [
          {
            id: "clientBRNo",
            label: "Business Registration Number",
            qid: this.clientData.businessRegistrationNumber,
          },
        ];
        allMandatoryField = orgMandatory.concat(contactMandatory);
      } //if (this.clientData.docType === "C")
      else {
        orgMandatory = [
          {
            id: "clientBRNo2",
            label: "Business Registration Number2",
            qid: this.clientData.businessRegistrationNumber2,
          },
        ];
        allMandatoryField = orgMandatory.concat(contactMandatory);
      }

      this.postBackData = [Array];
      let errorMessageIndicator: number = 0;
      const htmlErrorMessage: string =
        '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed" aria-hidden="true">' +
        "</i> <b> @i.label@ </b> is required. <br></div>";

      const clientAddressData: ClientAddressEntity = new ClientAddressEntity();

      clientAddressData.clientAddressID = this.isCreate
        ? ""
        : String(this.proposalService.proposal.customerMappingAddressID);
      clientAddressData.clientID = this.isCreate
        ? ""
        : String(this.proposalService.proposal.customerMappingId);
      clientAddressData.buildingName = this.address.buildingName;
      clientAddressData.unitNo = this.address.unitNo;
      clientAddressData.addressLine1 = this.address.addressLine1;

      clientAddressData.addressLine2 = this.address.addressLine2;
      clientAddressData.addressLine3 = this.address.addressLine3;
      clientAddressData.addressLine4 = this.address.addressLine4;
      clientAddressData.city = this.address.city;
      clientAddressData.postCode = this.address.postCode;
      clientAddressData.stateName = this.address.stateName;
      clientAddressData.hkDistrictCode = this.address.hkDistrictCode;
      clientAddressData.countryISO2 = "HK"; //this.address.countryISO2;
      clientAddressData.contact1 = "";
      clientAddressData.contact2 = "";

      const clientData: ClientEntity = new ClientEntity();
      clientData.address = [];

      clientData.clientId = this.isCreate
        ? 0
        : this.proposalService.proposal.customerMappingId!;
      clientData.clientCode = this.isCreate ? "0" : this.clientData.clientCode;
      clientData.clientType = this.clientData.clientType;
      clientData.nric = this.clientData.nric;
      clientData.passportNo = this.clientData.passportNo;

      clientData.businessRegistrationNumber =
        this.clientData.docType === "B"
          ? this.clientData.businessRegistrationNumber
          : "";
      clientData.businessRegistrationNumber2 =
        this.clientData.docType === "C"
          ? this.clientData.businessRegistrationNumber2
          : "";
      clientData.dbsClientReferenceNumber =
        this.clientData.dbsClientReferenceNumber;
      clientData.incorporatedDate = this.clientData.incorporatedDate;
      // clientData.businessName = this.clientData.businessName;
      clientData.personInCharge = this.clientData.personInCharge;
      clientData.firstName = this.clientData.firstName;
      clientData.lastName = this.clientData.lastName;
      clientData.clientName = this.clientData.clientName;
      clientData.salutation = this.clientData.salutation;
      clientData.docType = this.clientData.docType;

      clientData.gender = this.clientData.gender;
      clientData.birthDate = this.clientData.birthDate;
      if (this.clientData.clientType === EnumSG.ClientType.Person) {
        if (
          common.isDefined(this.clientData.birthDate) &&
          this.clientData.birthDate !== ""
        ) {
          const answer = new Date(this.clientData.birthDate);
          const year = answer.getFullYear();
          const month = answer.getMonth() + 1;
          const day = answer.getDate();
          clientData.birthDate = `${year}-${month}-${day} 12:00:00 AM`;
        }
      }

      clientData.occupation = this.clientData.occupation;
      clientData.race = this.clientData.race;
      clientData.maritalStatus = this.clientData.maritalStatus;

      clientData.phoneNoHome = this.clientData.phoneNoHome;
      clientData.phoneNoOffice = this.clientData.phoneNoOffice;
      clientData.mobileNumber = this.clientData.mobileNumber;
      clientData.pagerNo = this.clientData.pagerNo;
      clientData.faxNumber = this.clientData.faxNumber;
      clientData.cinNo = this.clientData.cinNo;
      clientData.emailAddress = this.clientData.emailAddress;
      /*
       * exec GetMasterNationalityList -- client's nationality
       * exec GetMasterCountryList -- ClientAddress's countryISO
       */
      clientData.nationality = "";
      clientData.address.push(clientAddressData);
      clientData.productBase = EnumSG.ProductBase.HASE;
      // clientData.productBase = this.productService.getProductBase(
      //   (this.proposal as MortgageFireHongKongProposal).productType
      // );

      this.showErrorMessage = true;
      if (allMandatoryField.length > 0) {
        this.errorMsgInHtml = "";
        this.emptyFieldValidation = "";

        for (const i of allMandatoryField) {
          if (common.isUndefinedOrNull(i.qid) || i.qid.length === 0) {
            this.errorMsgInHtml += htmlErrorMessage.replace(
              "@i.label@",
              i.label
            );
            this.emptyFieldValidation += " " + i.label;
            errorMessageIndicator++;
          } else {
            this.emptyFieldValidation.replace(i.label, "");
          }
        }
      }
      if (errorMessageIndicator === 0) {
        this.showErrorMessage = false;
        this.isProcessing = true;

        let clientActionData: any;

        if (
          this.proposalService.proposal.endorsementType ===
            Enums.EndorsementType.ChangeOfClientInfo &&
          this.proposalService.proposal.transType === Enums.TransType.END &&
          !this.isCreate
        ) {
          clientActionData = {
            clientModel: clientData,
            action: "validate",
          };
        } else {
          clientActionData = {
            clientModel: clientData,
            action: "save",
          };
        }

        this.ClientSave(clientActionData);
      } else {
        //angular.element(".modal-body").animate({ scrollTop: 0 }, 200);
        dialogActionNotifier.cancel_notifier$.next({
          key: "client-save-close",
          value: { status: "failed" },
        });
      }
    } else {
      this.showErrorMessage = true;
      this.errorMsgInHtml = "";
      this.errorMsgInHtml;
      '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
        ' aria-hidden="true"></i> Please select the <b> client type</b> to proceed.</div>';
      dialogActionNotifier.cancel_notifier$.next({
        key: "client-save-close",
        value: { status: "failed" },
      });
    }
    //return deferred.promise;
  };
}
