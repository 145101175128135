import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { common } from "src/app/framework/utils/common";
import { MfSgEquipmentListingEntity } from "../MfSgEquipmentListingEntity";

@Component({
  selector: "app-mf-sg-equipment-listing-edit-form",
  templateUrl: "./mf-sg-equipment-listing-edit-form.html",
  styleUrls: ["./mf-sg-equipment-listing-edit-form.scss"],
})
export class MfSgEquipmentListingEditComponent implements OnInit {
  defaultItem!: string;
  constructor() {}

  ngOnInit(): void {}

  public active = false;
  selectedEquipmentType: any;
  public editForm: UntypedFormGroup = new UntypedFormGroup({
    rowID: new UntypedFormControl(""), //new FormControl("", Validators.required),//id is empty when new
    additionalInfo: new UntypedFormControl("", Validators.required),
    equipmentDescription: new UntypedFormControl("", Validators.required),
    equipmentType: new UntypedFormControl(""),
    sumInsured: new UntypedFormControl(""),
    typeOfFinancing: new UntypedFormControl(""),
  });
  @Input() public equipmentDropdownOptions: any;
  @Input() public isNew: boolean | undefined;

  @Input() public set model(
    equipmentListingEntity: MfSgEquipmentListingEntity | undefined
  ) {
    this.editForm.reset(equipmentListingEntity);

    // toggle the Dialog visibility
    this.active = equipmentListingEntity !== undefined;

    if (
      common.isDefined(equipmentListingEntity) &&
      common.isDefined(equipmentListingEntity!.rowID)
    ) {
      this.defaultItem = equipmentListingEntity?.equipmentType;
    } else {
      this.defaultItem = common.isDefined(this.equipmentDropdownOptions)
        ? this.equipmentDropdownOptions[0]
        : "";
    }
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<MfSgEquipmentListingEntity> = new EventEmitter();

  public onSave(e: PointerEvent): void {
    this.editForm.value.equipmentType = common.isDefined(
      this.selectedEquipmentType
    )
      ? this.selectedEquipmentType
      : this.defaultItem;
    this.save.emit(this.editForm.value);
    this.closeForm(e);
  }

  public onCancel(e: PointerEvent): void {
    this.closeForm(e);
  }

  public closeForm(e: PointerEvent): void {
    this.cancel.emit();

    setTimeout(() => {
      e.preventDefault();
      this.active = false;
    }, 10);
  }

  optionChange(event: any) {
    //https://github.com/udos86/ng-dynamic-forms/issues/362
    //overhead for non-primitive
    this.selectedEquipmentType = event;
  }
}
