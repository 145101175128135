import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import {
  GridDataResult,
  PageSizeItem,
  PageChangeEvent,
  SelectionEvent,
  CellClickEvent,
} from "@progress/kendo-angular-grid";
import { ExportListEntity } from "./ExportListEntity";
import { IProduct } from "src/app/framework/interface.product";
import { MasterDataService } from "src/app/services/master-data.service";
import {
  MasterPolicyEntity,
  MasterPolicyService,
} from "src/app/services/master-policy.service";
import { ExcelService } from "src/app/services/excel.service";
import { SearchCriteria } from "../base/search-criteria";
import { warningZoneArgs } from "../warning-zone/warning-zone.component";
import { IUser } from "src/app/framework/interface.shared";
import { Router } from "@angular/router";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import * as Enum from "./../../../../framework/enum.shared";
import { BaseListingComponent } from "../../base/base-listing/base-listing.component";

@Component({
  selector: "app-export-list",
  templateUrl: "./export-list.component.html",
  styleUrls: ["./export-list.component.scss"],
})
export class ExportListComponent
  extends BaseListingComponent
  implements OnInit
{
  @ViewChild("heroForm", { static: false })
  private form!: UntypedFormGroup;

  @ViewChild("toDoStartDateTo", { static: false })
  public toDoStartDateTo!: DatePickerComponent;

  public toDoStartDateTo_min!: Date;
  public toDoStartDateTo_max!: Date;

  @ViewChild("toDoExpiryDateTo", { static: false })
  public toDoExpiryDateTo!: DatePickerComponent;

  public toDoExpiryDateTo_min!: Date;
  public toDoExpiryDateTo_max!: Date;

  @ViewChild("toDoEffectiveDateTo", { static: false })
  public toDoEffectiveDateTo!: DatePickerComponent;

  public toDoEffectiveDateTo_min!: Date;
  public toDoEffectiveDateTo_max!: Date;

  @ViewChild("boundDateTo", { static: false })
  public boundDateTo!: DatePickerComponent;

  public boundDateTo_min!: Date;
  public boundDateTo_max!: Date;

  @ViewChild("exportSubmmissionDateTo", { static: false })
  public exportSubmmissionDateTo!: DatePickerComponent;

  public submmissionDateTo_min!: Date;
  public submmissionDateTo_max!: Date;

  @ViewChild("approvalDateTo", { static: false })
  public approvalDateTo!: DatePickerComponent;

  public approvalDateTo_min!: Date;
  public approvalDateTo_max!: Date;

  public gridView!: GridDataResult;
  public pageSize = 100;
  public skip = 0;
  public take = 100;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  private listing: ExportListEntity[] = [];
  private totalListing: number = 0;
  submitted = false;

  currentUser: IUser | undefined;
  model = new SearchCriteria();
  public userProductList!: IProduct[];
  private masterPolicyList!: MasterPolicyEntity[];
  public masterPolicyOptions!: Array<{ text: string; value: string }>;
  public proposalStatusOptions!: Array<{ text: string; value: number }>;
  public userTeamOptions!: Array<{ text: string; value: number }>;
  public segmentOptions!: Array<{ text: string; value: string }>;
  public transTypeOptions!: Array<{ text: string; value: number }>;

  public isCitiUser: boolean = false;
  public isDBSUser: boolean = false;
  public isChubbUser: boolean = false;
  public defaultUserListItem: { text: string; value: string } = {
    text: "All",
    value: "",
  };
  public defaultMasterPolicyOptionsItem: { text: string; value: string } = {
    text: "Select master policy no...",
    value: "",
  };
  public defaultTransTypeItem: { text: string; value: string } = {
    text: "All",
    value: "",
  };

  public userList: Array<{ text: string; value: string }> = new Array<{
    text: string;
    value: string;
  }>();
  public checkableRows: number[] = [];
  public selectedRowIndexes: number[] = [];
  public loading!: boolean;

  @Output() warningZoneEvent = new EventEmitter<any>();

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    private masterPolicyService: MasterPolicyService,
    private masterDataService: MasterDataService,
    private excelService: ExcelService
  ) {
    super(router, broadcastService, identityRolesService);
  }

  //#region migrate from old angularjs
  override ngOnInit(): void {
    super.ngOnInit();
    this.currentUser = this.identityRolesService.getIdentity().currentUser();

    this.fetchUserList();
    this.fetchMasterPolicyList();
    this.defaultSearchCriteria();
    //this.refresh();//original is not loaded by default
  }

  public getBcrnByUserOrg(): string {
    var currentUserOrg = this.currentUser?.organisation;
    if (currentUserOrg?.description == Enum.UserOrganisation.DBS) {
      return "Bank Reference No";
    } else if (currentUserOrg?.description == Enum.UserOrganisation.Citi) {
      return "Collateral Reference No";
    } else {
      return "Bank Ref No/Coll Ref No";
    }
  }

  /** Default search criteria while page load */
  private defaultSearchCriteria() {
    let products = this.currentUser?.products;
    if (products != undefined) {
      this.userProductList = products;
    }

    // Status dropdownlist options
    const proposalStatusOptions = [
      { text: "Draft", value: 6 },
      { text: "Pending Checker", value: 18 },
      { text: "Referred", value: 3 },
      { text: "Approved by Underwriter", value: 1 },
      { text: "Declined by Underwriter", value: 4 },
    ];
    this.proposalStatusOptions = proposalStatusOptions;

    // user team dropdownlist options
    const userTeamOptions = [
      { text: "CBG-Customer Solutions", value: 4 },
      { text: "Loan Document", value: 1 },
      { text: "Loan Records", value: 2 },
      { text: "Loan Servicing", value: 3 },
      { text: "Maker & Checker", value: 6 },
      { text: "NA", value: 5 },
    ];
    this.userTeamOptions = userTeamOptions;

    // Transaction
    const transTypeOptions = [
      { text: "  New Business", value: 1 },
      { text: "Endorsement", value: 2 },
      { text: " Cancellation", value: 3 },
      { text: " Renewal", value: 4 },
    ];
    this.transTypeOptions = transTypeOptions;

    // Segment
    const segmentOptions = [
      { text: "CBG", value: "CBG" },
      { text: "IBG", value: "IBG" },
      { text: "PB", value: "PB" },
      { text: "TPC", value: "TPC" },
    ];
    this.segmentOptions = segmentOptions;

    // effectiveDateFrom (14 days earlier from today)
    const effectiveDateFrom: Date = new Date();
    effectiveDateFrom.setDate(effectiveDateFrom.getDate() - 14);
    this.model.effectiveDateFrom = effectiveDateFrom;

    // effectiveDateFrom ( 6 months after effectiveDateFrom)
    const effectiveDateTo: Date = new Date(effectiveDateFrom);
    effectiveDateTo.setMonth(effectiveDateTo.getMonth() + 6);
    this.model.effectiveDateTo = effectiveDateTo;

    if (
      this.identityRolesService.isChecker &&
      !this.identityRolesService.isMaker
    ) {
      this.model.proposalStatus.push(18);
    }
  }

  private fetchUserList = () => {
    if (this.identityRolesService.isUnderwriter) {
      this.masterDataService
        .getUnderwriterMakerUserList()
        .subscribe((response) => {
          // update list
          var keyValueUserList = response;
          keyValueUserList.forEach((mp) => {
            this.userList.push({ text: mp.value, value: mp.key });
          });
        });
    } else {
      this.masterDataService.getMakerUserList().subscribe((response) => {
        // update list
        var keyValueUserList = response;
        keyValueUserList.forEach((mp) => {
          this.userList.push({ text: mp.value, value: mp.key });
        });
      });
    }
  };

  private fetchMasterPolicyList = async () => {
    this.masterPolicyOptions = new Array<{ text: string; value: string }>();

    this.masterPolicyService.getList(this.currentUser!).subscribe(
      (result: any) => {
        this.masterPolicyList = result;

        // update list
        this.masterPolicyList.forEach((mp) => {
          const displayText =
            mp.policyType +
            " - " +
            mp.description +
            " - " +
            mp.meridianMasterPolicyNumber +
            " (MSIG: " +
            mp.policyNumber +
            ")";
          this.masterPolicyOptions.push({ text: displayText, value: mp.id });
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  };
  //#endregion

  //#region generic Grid event
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }

  private clearSelection() {
    // Optionally, clear the selection when paging
    this.checkableRows = [];
    this.selectedRowIndexes = [];
  }

  public gridSelectionChange(selection: SelectionEvent) {}

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {}

  private refresh(exportExcel: boolean = false): void {
    if (!exportExcel) {
      this.clearSelection();
      this.loading = true;
    }

    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();

    if (!exportExcel) {
      formData.append("take", this.take.toString());
      formData.append("skip", this.skip.toString());
      formData.append("page", "1");
      formData.append("pageSize", this.pageSize.toString());
    }
    formData.append("sort[0][field]", "serialNo");
    formData.append("sort[0][dir]", "asc");

    let array = [
      ["startDateFrom", "gte", this.model.startDateFrom],
      ["startDateTo", "lte", this.model.startDateTo],
      ["expiryDateFrom", "gte", this.model.expiryDateFrom],
      ["expiryDateTo", "lte", this.model.expiryDateTo],
      ["effectiveDateFrom", "gte", this.model.effectiveDateFrom],
      ["effectiveDateTo", "lte", this.model.effectiveDateTo],

      ["submissionDateFrom", "gte", this.model.submissionDateFrom],
      ["submissionDateTo", "lte", this.model.submissionDateTo],
      ["approvalDateFrom", "gte", this.model.approvalDateFrom],
      ["approvalDateTo", "lte", this.model.approvalDateTo],

      ["quoteNo", "eq", this.model.quoteNo],
      ["policyNo", "eq", this.model.policyNo],
      ["masterPolicyId", "eq", this.model.masterPolicyId],
      ["cin", "eq", this.model.cin],
      ["insuredName", "eq", this.model.insuredName],
      ["productId", "eq", this.model.productId],
      ["proposalStatus", "eq", this.model.proposalStatus],

      ["transType", "eq", this.model.transType],

      ["bankReferenceNo", "eq", this.model.bankReferenceNo],
      ["createdByUserId", "eq", this.model.createdByUserId],

      ["segment", "eq", this.model.segment],
      ["boundDateFrom", "gte", this.model.boundDateFrom],
      ["boundDateTo", "lte", this.model.boundDateTo],

      ["userTeam", "eq", this.model.userTeam],
      // ["additionalInsuredName", "eq", this.model.additionalInsuredName],
      // ["bcrn", "eq", this.model.bcrn],
      // ["riskAddressPostalCode", "eq", this.model.riskAddressPostalCode],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    this.masterPolicyService
      .searchExportListing(querystring, exportExcel)
      .subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            if (exportExcel) {
              let listing = result.body.d?.results;
              for (let i = 0; i < listing.length; i++) {
                listing[i].status = this.getStatusDisplayText(listing[i]);
              }
              this.excelService.generateExportList(listing);
            } else {
              this.listing = result.body.d?.results;
              this.totalListing = result.body.d?.__count;
              this.loadItems();
              this.loading = false;
            }
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  private loadItems(): void {
    this.gridView = {
      data: this.listing, //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }

  public on_searchStartDateFrom_change(value: Date): void {
    if (this.model.startDateFrom === null) {
      this.model.startDateTo = undefined;
      this.toDoStartDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoStartDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.startDateTo &&
      this.model.startDateFrom &&
      this.model.startDateTo < this.model.startDateFrom
    ) {
      this.model.startDateTo = undefined;
      this.toDoStartDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoStartDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.toDoStartDateTo_min = value;
    this.toDoStartDateTo_max = maxDate;

    // open datepicker
    this.toDoStartDateTo.toggle();
    this.on_searchStartDateFrom_focus();
  }

  public on_searchStartDateFrom_focus(): void {
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
    if (this.boundDateTo.isOpen) {
      this.boundDateTo.toggle();
    }
    if (this.exportSubmmissionDateTo.isOpen) {
      this.exportSubmmissionDateTo.toggle();
    }
    if (this.approvalDateTo.isOpen) {
      this.approvalDateTo.toggle();
    }
  }

  public on_searchExpiryDateFrom_change(value: Date): void {
    if (this.model.expiryDateFrom === null) {
      this.model.expiryDateTo = undefined;
      this.toDoExpiryDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoExpiryDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }

    if (
      this.model.expiryDateTo &&
      this.model.expiryDateFrom &&
      this.model.expiryDateTo < this.model.expiryDateFrom
    ) {
      this.model.expiryDateTo = undefined;
      this.toDoExpiryDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoExpiryDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.toDoExpiryDateTo_min = value;
    this.toDoExpiryDateTo_max = maxDate;

    // open datepicker
    this.toDoExpiryDateTo.toggle();
    this.on_searchExpiryDateFrom_focus();
  }

  public on_searchExpiryDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
    if (this.boundDateTo.isOpen) {
      this.boundDateTo.toggle();
    }
    if (this.exportSubmmissionDateTo.isOpen) {
      this.exportSubmmissionDateTo.toggle();
    }
    if (this.approvalDateTo.isOpen) {
      this.approvalDateTo.toggle();
    }
  }

  public on_searchEffectiveDateFrom_change(value: Date): void {
    if (this.model.effectiveDateFrom === null) {
      this.model.effectiveDateTo = undefined;
      this.toDoEffectiveDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoEffectiveDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.effectiveDateTo &&
      this.model.effectiveDateFrom &&
      this.model.effectiveDateTo < this.model.effectiveDateFrom
    ) {
      this.model.effectiveDateTo = undefined;
      this.toDoEffectiveDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoEffectiveDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    // open datepicker
    this.toDoEffectiveDateTo.toggle();
    this.on_searchEffectiveDateFrom_focus();
  }

  public on_searchEffectiveDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
    if (this.boundDateTo.isOpen) {
      this.boundDateTo.toggle();
    }
    if (this.exportSubmmissionDateTo.isOpen) {
      this.exportSubmmissionDateTo.toggle();
    }
    if (this.approvalDateTo.isOpen) {
      this.approvalDateTo.toggle();
    }
  }

  public on_searchBoundDateFrom_change(value: Date): void {
    if (this.model.boundDateFrom === null) {
      this.model.boundDateTo = undefined;
      this.boundDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.boundDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.boundDateTo &&
      this.model.boundDateFrom &&
      this.model.boundDateTo < this.model.boundDateFrom
    ) {
      this.model.boundDateTo = undefined;
      this.boundDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.boundDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.boundDateTo_min = value;
    this.boundDateTo_max = maxDate;
    // open datepicker
    this.boundDateTo.toggle();
    this.on_searchBoundDateFrom_focus();
  }

  public on_searchBoundDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
    if (this.exportSubmmissionDateTo.isOpen) {
      this.exportSubmmissionDateTo.toggle();
    }
    if (this.approvalDateTo.isOpen) {
      this.approvalDateTo.toggle();
    }
  }

  public on_searchSubmmissionDateFrom_change(value: Date): void {
    if (this.model.submissionDateFrom === null) {
      this.model.submissionDateTo = undefined;
      this.submmissionDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.submmissionDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.submissionDateTo &&
      this.model.submissionDateFrom &&
      this.model.submissionDateTo < this.model.submissionDateFrom
    ) {
      this.model.submissionDateTo = undefined;
      this.submmissionDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.submmissionDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.submmissionDateTo_min = value;
    this.submmissionDateTo_max = maxDate;
    // open datepicker
    this.exportSubmmissionDateTo.toggle();
    this.on_searchSubmmissionDateFrom_focus();
  }

  public on_searchSubmmissionDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
    if (this.boundDateTo.isOpen) {
      this.boundDateTo.toggle();
    }
    if (this.approvalDateTo.isOpen) {
      this.approvalDateTo.toggle();
    }
  }

  public on_searchApprovalDateFrom_change(value: Date): void {
    if (this.model.approvalDateFrom === null) {
      this.model.approvalDateTo = undefined;
      this.approvalDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.approvalDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.approvalDateTo &&
      this.model.approvalDateFrom &&
      this.model.approvalDateTo < this.model.approvalDateFrom
    ) {
      this.model.approvalDateTo = undefined;
      this.approvalDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.approvalDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.approvalDateTo_min = value;
    this.approvalDateTo_max = maxDate;
    // open datepicker
    this.approvalDateTo.toggle();
    this.on_searchApprovalDateFrom_focus();
  }

  public on_searchApprovalDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
    if (this.boundDateTo.isOpen) {
      this.boundDateTo.toggle();
    }
    if (this.exportSubmmissionDateTo.isOpen) {
      this.exportSubmmissionDateTo.toggle();
    }
  }

  private validateMandatoryFields(): boolean {
    let isValid: boolean = false;

    // dates
    const dates: boolean =
      this.model.startDateFrom !== null ||
      this.model.startDateTo !== null ||
      this.model.expiryDateFrom !== null ||
      this.model.expiryDateTo !== null ||
      this.model.effectiveDateFrom !== null ||
      this.model.effectiveDateTo !== null ||
      this.model.submissionDateFrom !== null ||
      this.model.submissionDateTo !== null ||
      this.model.approvalDateFrom !== null ||
      this.model.approvalDateTo !== null ||
      this.model.boundDateFrom !== null ||
      this.model.boundDateTo !== null;

    isValid = dates; // || etc...

    return isValid;
  }

  private checkDatesRange() {
    if (this.model.startDateFrom !== null && this.model.startDateTo === null) {
      this.model.startDateTo = this.model.startDateFrom;
    }

    if (
      this.model.expiryDateFrom !== null &&
      this.model.expiryDateTo === null
    ) {
      this.model.expiryDateTo = this.model.expiryDateFrom;
    }

    if (
      this.model.effectiveDateFrom !== null &&
      this.model.effectiveDateTo === null
    ) {
      this.model.effectiveDateTo = this.model.effectiveDateFrom;
    }

    if (
      this.model.submissionDateFrom !== null &&
      this.model.submissionDateTo === null
    ) {
      this.model.submissionDateTo = this.model.submissionDateFrom;
    }

    if (
      this.model.approvalDateFrom !== null &&
      this.model.approvalDateTo === null
    ) {
      this.model.approvalDateTo = this.model.approvalDateFrom;
    }
  }

  public onSubmit(): void {
    if (this.form.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }

    this.checkDatesRange();

    const isValid = this.validateMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      //$('#warningZone').collapse('hide');
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
      // $('#warningZone #message').text('Please select a date range.');
      // $('#warningZone').collapse('show');
      // httpNotifier.response_notifier$.next(new KeyValue('', e));
    }
  }

  getStatusDisplayText(dataItem: any): string {
    switch (dataItem.status) {
      case 2:
        return "Bound";
      case 3:
        return "Referred";
      case 6:
        return "Draft";
      case 10:
        return "Endorsed";
      case 11:
        return "Renewed";
      case 12:
        return "Cancelled";
      case 18:
        return "P. Checker";
      default:
        return "";
    }
  }

  export(): void {
    //this.submitted = true;
    this.refresh(true);
  }
  //#endregion
}
