<div row padding5 *ngIf="isProcessing">
  <div class="margin5 textAlignCenter">
    <span class="loadingBarText">Please Wait</span>&nbsp;
    <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
  </div>
</div>
<div
  class="container-fluid"
  id="clientPersonalInformation"
  *ngIf="clientViewData?.clientType == '1'"
>
  <div class="row">
    <div class="col-md-12 textDecoUnderline">
      <h4>Personal Information</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form">
        <div class="form-group">
          <div class="form-group">
            <label class="fontSize14px" [textContent]="'Salutation'"></label>
            <div>
              <p *ngIf="clientViewData?.salutation != ''">
                {{ clientViewData?.salutationText }}
              </p>
              <span
                class="unattended"
                *ngIf="clientViewData?.salutation == ''"
                >{{ noAnswerDefaultText }}</span
              >
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Surname'"></label>
          <div>
            <p class="wordBreakAll" *ngIf="clientViewData?.lastName != ''">
              {{ clientViewData?.lastName }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.lastName == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Given Name'"></label>

          <div>
            <p class="wordBreakAll" *ngIf="clientViewData?.firstName != ''">
              {{ clientViewData?.firstName }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.firstName == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Date of Birth'"></label>
          <div>
            <p *ngIf="clientViewData?.birthDateFormat != ''">
              {{ clientViewData?.birthDateFormat }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.birthDateFormat == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form">
        <div class="form-group">
          <div class="form-group">
            <label class="fontSize14px" [textContent]="'Document Type'"></label>
            <div>
              <p *ngIf="clientViewData?.documentTypeText != ''">
                {{ clientViewData?.documentTypeText }}
              </p>
              <span
                class="unattended"
                *ngIf="clientViewData?.documentTypeText == ''"
                >{{ noAnswerDefaultText }}</span
              >
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-3">
      <form role="form ">
        <div class="form-group" id="nric">
          <label class="fontSize14px" [textContent]="'NRIC'"></label>
          <div>
            <p *ngIf="clientViewData?.nric != ''">{{ clientViewData?.nric }}</p>
            <span class="unattended" *ngIf="clientViewData?.nric == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group" id="passportNo">
          <label class="fontSize14px" [textContent]="'Passport Number'"></label>
          <div>
            <p *ngIf="clientViewData?.passportNo != ''">
              {{ clientViewData?.passportNo }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.passportNo == ''"
              >{{ noAnswerDefaultText }}
            </span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group" id="dbsCIN">
          <label class="fontSize14px" [textContent]="'DBS CIN'"></label>
          <div>
            <p *ngIf="clientViewData?.cinNo != ''">
              {{ clientViewData?.cinNo }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.cinNo == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Race'"></label>
          <div>
            <p *ngIf="clientViewData?.race != ''">
              {{ clientViewData?.raceText }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.race == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Nationality'"></label>

          <div>
            <p *ngIf="clientViewData?.nationality != ''">
              {{ clientViewData?.nationalityText }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.nationality == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Gender'"></label>
          <div>
            <p *ngIf="clientViewData?.gender != ''">
              {{ clientViewData?.genderText }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.gender == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Marital Status'"></label>
          <div>
            <p *ngIf="clientViewData?.maritalStatus != ''">
              {{ clientViewData?.maritalStatusText }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.maritalStatus == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Occupation'"></label>
          <div>
            <p *ngIf="clientViewData?.occupation != ''">
              {{ clientViewData?.occupation }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.occupation == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div
  class="container-fluid"
  id="clientOrganizationInformation"
  *ngIf="clientViewData?.clientType == '2'"
>
  <div class="row">
    <div class="col-md-12 textDecoUnderline">
      <h4>Organization Information</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Business Name '"></label>
          <div>
            <p class="wordBreakAll" *ngIf="clientViewData?.clientName != ''">
              {{ clientViewData?.clientName }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.clientName == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label
            class="fontSize14px"
            [textContent]="'Business Registration Number '"
          ></label>
          <div>
            <p *ngIf="clientViewData?.businessRegistrationNumber != ''">
              {{ clientViewData?.businessRegistrationNumber }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.businessRegistrationNumber == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label
            class="fontSize14px"
            [textContent]="'Person in Charge'"
          ></label>
          <div>
            <p *ngIf="clientViewData?.personInCharge != ''">
              {{ clientViewData?.personInCharge }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.personInCharge == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'DBS CIN'"></label>
          <div>
            <p *ngIf="clientViewData?.cinNo != ''">
              {{ clientViewData?.cinNo }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.cinNo == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="container-fluid">
  <hr />
  <div class="row">
    <div class="col-md-12 textDecoUnderline">
      <h4>Contact Information</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label
            class="fontSize14px"
            [textContent]="'Suite/Lot/Unit Number'"
          ></label>
          <div>
            <p *ngIf="clientViewData?.address[0].unitNo != ''">
              {{ clientViewData?.address[0].unitNo }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].unitNo == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Building Name'"></label>
          <div>
            <p *ngIf="clientViewData?.address[0].buildingName != ''">
              {{ clientViewData?.address[0].buildingName }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].buildingName == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Address Line 1'"></label>
          <div>
            <p *ngIf="clientViewData?.address[0].addressLine1 != ''">
              {{ clientViewData?.address[0].addressLine1 }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].addressLine1 == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Address Line 2'"></label>

          <div>
            <p *ngIf="clientViewData?.address[0].addressLine2 != ''">
              {{ clientViewData?.address[0].addressLine2 }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].addressLine2 == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Postcode'"></label>
          <div>
            <p *ngIf="clientViewData?.address.postCode != ''">
              {{ clientViewData?.address[0].postCode }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address.postCode == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'City/Suburb'"></label>
          <div>
            <p *ngIf="clientViewData?.address[0].city != ''">
              {{ clientViewData?.address[0].city }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].city == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'State'"></label>
          <div>
            <p *ngIf="clientViewData?.address[0].stateName != ''">
              {{ clientViewData?.address[0].stateName }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].stateName == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Country'"></label>
          <div>
            <p *ngIf="clientViewData?.address[0].countryISO2 != ''">
              {{ clientViewData?.countryISO2Text }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.address[0].countryISO2 == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form " id="mobilePhoneForm">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Mobile Number'"></label>
          <div>
            <p *ngIf="clientViewData?.mobileNumber != ''">
              {{ clientViewData?.mobileNumber }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.mobileNumber == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form " name="emailForm" novalidate>
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Email Address'"></label>
          <div>
            <p *ngIf="clientViewData?.emailAddress != ''">
              {{ clientViewData?.emailAddress }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.emailAddress == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form " id="housePhoneForm">
        <div class="form-group">
          <label
            class="fontSize14px"
            [textContent]="'Phone Number (Home)'"
          ></label>
          <div>
            <p *ngIf="clientViewData?.phoneNoHome != ''">
              {{ clientViewData?.phoneNoHome }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.phoneNoHome == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <form role="form " id="officePhoneForm">
        <div class="form-group">
          <label
            class="fontSize14px"
            [textContent]="'Phone Number (Office)'"
          ></label>
          <div>
            <p *ngIf="clientViewData?.phoneNoOffice != ''">
              {{ clientViewData?.phoneNoOffice }}
            </p>
            <span
              class="unattended"
              *ngIf="clientViewData?.phoneNoOffice == ''"
              >{{ noAnswerDefaultText }}</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form role="form ">
        <div class="form-group">
          <label class="fontSize14px" [textContent]="'Fax Number'"></label>
          <div>
            <p *ngIf="clientViewData?.faxNumber != ''">
              {{ clientViewData?.faxNumber }}
            </p>
            <span class="unattended" *ngIf="clientViewData?.faxNumber == ''">{{
              noAnswerDefaultText
            }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
