import { GUID } from "./../../../../../../../framework/domain-entity/guid";
import { MortgageFireSGTabSchema } from "./mf-sg-tab-schema";
import { Section } from "./../../../../../base/section.model";

class MortgageFireSingaporeSectionSchema {
  sections!: Section[];
  master!: Section;
  locationInsuredInfo!: Section;
  materialDamage!: Section;
  businessInterruption!: Section;
  money!: Section;
  premiumSummary!: Section;

  constructor() {
    this.initialize();
  }

  initialize() {
    this.master = new Section(
      "Master",
      new GUID("5C4BEC68-94B7-4D7F-B5D6-F7003D1748CF"),
      MortgageFireSGTabSchema.master.index
    );

    this.locationInsuredInfo = new Section(
      "Location Details",
      new GUID("C3651266-87DE-44E5-97C0-8258137906A4"),
      MortgageFireSGTabSchema.locations.index
    );
    this.materialDamage = new Section(
      "Material Damage",
      new GUID("95902C96-166B-4093-8DA2-D784E21628EA"),
      MortgageFireSGTabSchema.locations.index
    );

    this.businessInterruption = new Section(
      "Business Interruption",
      new GUID("2D01A81C-B8E1-4057-8B96-3A66254CFA1A"),
      MortgageFireSGTabSchema.businessInterruption.index
    );

    this.money = new Section(
      "Money",
      new GUID("B4A2ABDF-555A-48DE-8F0A-D68BAD994DE3"),
      MortgageFireSGTabSchema.money.index
    );
    this.premiumSummary = new Section(
      "Premium Summary",
      new GUID("B4A2ABDF-555A-48DE-8F0A-D68BAD994DE3"),
      MortgageFireSGTabSchema.premiumSummary.index
    );

    this.sections = [];
    this.sections.push(this.master);
    this.sections.push(this.locationInsuredInfo);
    this.sections.push(this.materialDamage);
    this.sections.push(this.businessInterruption);
    this.sections.push(this.money);
    this.sections.push(this.premiumSummary);
  }
}

export const MortgageFireSGSectionSchema =
  new MortgageFireSingaporeSectionSchema();
