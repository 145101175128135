import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { IProduct } from "src/app/framework/interface.product";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { BankTransSearchCriteria } from "./bank-trans.model";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { PresetExportService } from "src/app/services/preset-export.service";

@Component({
  selector: "app-bank-trans",
  templateUrl: "./bank-trans.component.html",
  styleUrls: ["./bank-trans.component.scss"],
})
export class BankTransComponent extends BaseComponent implements OnInit {
  @Output() warningZoneEvent = new EventEmitter<any>();

  @ViewChild("reportDateTo", { static: false })
  public reportDateTo!: DatePickerComponent;

  public transDate_min!: Date;
  public transDate_max!: Date;

  public segmentList!: Array<{ text: string; value: string }>;
  public transactionTypeOption!: Array<{ text: string; value: number }>;
  public customerTypes!: Array<{ text: string; value: string }>;
  public isCitiProduct: boolean = false;
  public productRadioControl!: string;
  public searchCriteria = new BankTransSearchCriteria();
  public userProductList!: IProduct[];
  public showReportType: boolean = false;

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    private presetExportService: PresetExportService,
    private excelTemplateService: ExcelTemplateService
  ) {
    super(router, broadcastService, identityRolesService);
  }
  override ngOnInit(): void {
    this.productRadioControl = "dbs";
    if (
      this.identityRolesService.isChecker ||
      this.identityRolesService.isMaker
    ) {
      this.fetchUserList();
    } else {
      this.showReportType = true;
      this.fetchUserList();
    }
    this.readProductRadio();
    this.defaultSearchCriteria();
  }
  private defaultSearchCriteria() {
    // segment dropdownlist options
    const segmentList = [
      { text: "CBG", value: "CBG" },
      { text: "IBG", value: "IBG" },
      { text: "PB", value: "PB" },
      { text: "TPC", value: "TPC" },
    ];
    this.segmentList = segmentList;
    const transactionOption = [
      { text: "New Business", value: 1 },
      { text: "Endorsement", value: 2 },
      { text: "Cancellation", value: 3 },
      { text: "Renewal", value: 4 },
      { text: "Loan Redemption", value: 5 },
    ];

    this.transactionTypeOption = transactionOption;
    const customerTypes = [
      { text: "Citibusiness", value: "Citibusiness" },
      { text: "Commercial", value: "Commercial" },
      { text: "GCB", value: "GCB" },
      { text: "IPB", value: "IPB" },
      { text: "Staff", value: "Staff" },
      { text: "Bridging Loan", value: "Bridging Loan" },
    ];
    this.customerTypes = customerTypes;
    this.defaultBankTransSearchCriteria();
  }
  private readProductRadio(): void {
    this.isCitiProduct = this.productRadioControl === "citi";
  }

  public onChange_ProductRadio(): void {
    this.isCitiProduct = this.productRadioControl === "citi" ? true : false;
    this.searchCriteria.segments = [];
    this.searchCriteria.productIds = [];
    this.searchCriteria.productRadio = this.productRadioControl;
    this.defaultBankTransSearchCriteria();
    this.readProductRadio();
    this.fetchUserList();
  }
  private fetchUserList() {
    // get user productsList
    if (this.isCitiProduct) {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return (
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    } else {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return !(
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    }
  }
  public on_transReportDateFrom_change(value: Date): void {
    if (this.searchCriteria.effectiveDateFrom === null) {
      this.searchCriteria.effectiveDateTo = undefined;
      this.transDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.transDate_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.searchCriteria.effectiveDateTo &&
      this.searchCriteria.effectiveDateFrom &&
      this.searchCriteria.effectiveDateTo <
        this.searchCriteria.effectiveDateFrom
    ) {
      this.searchCriteria.effectiveDateTo = undefined;
      this.transDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.transDate_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.transDate_min = value;
    this.transDate_max = maxDate;

    // open datepicker
    this.reportDateTo.toggle();
    this.on_searchOIFrom_focus();
  }
  private on_searchOIFrom_focus(): void {
    if (this.reportDateTo.isOpen) {
      this.reportDateTo.toggle();
    }
    if (this.reportDateTo.isOpen) {
      this.reportDateTo.toggle();
    }
  }
  private defaultBankTransSearchCriteria(): void {
    //  dateFrom: 14 days before today
    const dateFrom: Date = new Date();
    dateFrom.setDate(dateFrom.getDate() - 14);
    this.searchCriteria.dateFrom = dateFrom;

    // dateTo: 6 months after dateFrom
    const dateTo: Date = new Date(dateFrom);
    dateTo.setMonth(dateTo.getMonth() + 6);
    this.searchCriteria.dateTo = dateTo;

    const dt = new Date(new Date().getFullYear(), 0, 1);
    const effectiveDateFrom = new Date(
      Date.UTC(
        dt.getFullYear(),
        dt.getMonth(),
        dt.getDate(),
        dt.getHours(),
        dt.getMinutes(),
        dt.getSeconds()
      )
    );
    this.searchCriteria.effectiveDateFrom = effectiveDateFrom;

    const effectiveDateTo: Date = new Date(effectiveDateFrom);
    effectiveDateTo.setFullYear(effectiveDateFrom.getFullYear() + 1);
    this.searchCriteria.effectiveDateTo = effectiveDateTo;
  }
  public on_DailyreportDateFrom_change(value: Date): void {}
  public download(): void {
    this.checkDatesRange();
    if (this.validateBankTransMandatoryFields()) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.downloadBankTransReport();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private validateBankTransMandatoryFields(): boolean {
    let isValid: boolean = false;
    // dates
    const dates: boolean =
      this.searchCriteria.dateFrom !== null ||
      this.searchCriteria.dateTo !== null;

    isValid = dates; // || etc...

    return isValid;
  }
  private checkDatesRange(): void {
    if (
      this.searchCriteria.dateFrom !== null &&
      this.searchCriteria.dateTo === null
    ) {
      this.searchCriteria.dateTo = this.searchCriteria.dateFrom;
    }
  }
  private getFileName(): string {
    let reportTitle = "";
    if (this.searchCriteria.dateFrom === this.searchCriteria.dateTo) {
      reportTitle += " on " + this.formatDate(this.searchCriteria.dateFrom);
    } else {
      reportTitle +=
        " from " +
        this.formatDate(this.searchCriteria.dateFrom) +
        " to " +
        this.formatDate(this.searchCriteria.dateTo);
    }
    return reportTitle;
  }
  private formatDate(date: Date): string {
    return (
      date.getDate().toString() +
      "/" +
      (date.getMonth() + 1).toString() +
      "/" +
      date.getFullYear().toString()
    );
  }
  private downloadBankTransReport(): void {
    if (this.productRadioControl === "dbs") {
      this.presetExportService
        .bankTransaction(
          this.searchCriteria.dateFrom,
          this.searchCriteria.dateTo,
          this.searchCriteria.segments,
          this.searchCriteria.productIds
        )
        .subscribe((response: any) => {
          if (response && response.body && response.body.d.results) {
            this.excelTemplateService.downloadBankTransDBSReport(
              bankTransDBSColumn,
              "Bank Trans",
              "Bank Trans DBS" + this.getFileName(),
              response.body.d.results
            );
          }
        });
    } else {
      this.presetExportService
        .bankTransactionCiti(
          this.searchCriteria.dateFrom,
          this.searchCriteria.dateTo,
          this.searchCriteria.productIds,
          this.searchCriteria.productRadio,
          this.searchCriteria.effectiveDateFrom,
          this.searchCriteria.effectiveDateTo,
          this.searchCriteria.transactionType,
          this.searchCriteria.customerType,
          this.searchCriteria.policyStatus
        )
        .subscribe((response: any) => {
          if (response && response.body && response.body.d.results) {
            this.excelTemplateService.downloadBankTransCITIReport(
              bankTransCITYColumn,
              "Bank Trans",
              "Bank Trans Citi" + this.getFileName(),
              response.body.d.results
            );
          }
        });
    }
  }
}
const bankTransDBSColumn: string[] = [
  "S/N",
  "Financial Institution Branch",
  "Master Policy No",
  "Agent No",
  "Agent Name",
  "Contract Type",
  "Contract No",
  "Reference No",
  "Original Inception Date",
  "Contract Commencement Date",
  "Expiry Date",
  "Client No",
  "Client Surname",
  "Client Given Name",
  "Client Identification No",
  "Client Address",
  "Risk No",
  "Location of Risk",
  "Total Sum Insured",
  "Gross Premium" /*new*/,
  "GST Amount",
  "Total Premium" /*Gross Premium -> Total Premium*/,
  "Commission Rate" /*new*/,
  "Commission excl. GST" /*Commission Amount -> Commission excl. GST*/,
  "GST on Commission" /*new*/,
  "Premium Net Off Comm" /*new*/,
  "GST on Premium Net Off Comm" /*new*/,
  "Discount Amount",
  "Net Premium",
  "Transaction Code",
  "Transaction Code Desc",
  "Meridian Sequence No",
  "Policy Certificate No",
  "Quote No",
  "MSIG Number",
  "Insured NRIC",
  "Entry Date",
  "Endorsement Effective Date",
  "Status",
  "Saved By",
  "Cheque Issuance Bank",
  "Cheque No",
  "Payment Mode",
  "Free Insurance",
  "No of Years Free",
  "Machinery Type",
  "Property Type",
  "Remarks",
  "Dwelling/Occupancy",
  "Maker's ID",
  "Makers Date/Time",
  "Checker's ID",
  "Checkers Date/Time",
];
const bankTransCITYColumn: string[] = [
  "S/N",
  "Customer Type",
  "Master Policy Number",
  "Contract Type",
  "Premium Payment",
  "Policy Number",
  "Plan Type",
  "Collateral Number",
  "Client Number",
  "Client Surname",
  "Client Given Name",
  "Client Identification Number",
  "Client Address",
  "Mobile Number",
  "Email Address",
  "Additional Client Name",
  "Additional Client NRIC",
  "Preferred Delivery Mode",
  "Preferred Delivery Details",
  "FRV/OLV",
  "FRV/OLV Value",
  "Code",
  "Inception Date",
  "Effective Date",
  "Expiry Date",
  "Sum Insured",
  "Risk Address",
  "Transaction Type",
  "Property Type",
  "Remarks",
  "Dwelling/Occupancy",
  "Policy Status",
  "Transaction Date",
  "Transacted By",
  "Payment History Status",
  "AD Week",
  "Open Audit Number",
  "Gross Premium",
  "Discount Offered By Citi",
  "Premium Discount By Citi",
  "Nett Premium Billed To Customer",
  "GST on Premium",
  "Premium To Be Collected From Customer",
  "Commission Rate",
  "Commission Amount",
  "Nett Comm Offset With Discount",
  "GST on Commission",
  "Commission Payable",
  "Amt Due to Chubb",
];
