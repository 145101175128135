<div class="header">
  <div class="nav-container" style="padding-left: 0px">
    <div class="col-md-8">
      <!-- <div class="brand-holder"> -->
      <!-- <div class="row"> -->
      <!-- <div class=""> -->
      <!-- <div class=" " style="padding: 0px 0 0 10px; float: left;"> -->
      <!-- <img src="core\components\login\directives\drLogin\img\Chubb-black.png" style="border-width:0px;margin-left: 20px"> -->
      <img
        src="assets\Chubb-black.png"
        style="border-width: 0px; margin-left: 20px"
      />

      <!-- </div> -->
      <!-- </div> -->
    </div>

    <div
      class="col-md-4"
      style="color: #337ab7 !important; display: inline-block"
    >
      <ul class="header-nav header-nav-profile" style="text-align: center">
        <li class="user-dropdown-content" style="float: right">
          <a data-toggle="dropdown" style="min-width: 210px; font-size: 14px">
            <span class="profile-info" style="vertical-align: middle"
              >{{userDisplayName}}</span
            >
          </a>
          <ul class="dropdown-menu cursorPointer" style="z-index: 9999">
            <li>
              <a (click)="openProfileDetails($event)"
                ><i class="fa fa-user"></i> Profile Details</a
              >
            </li>
            <li
              style="border-top: #afafaf solid thin"
              *ngFor="let option of userTypeOptions?.Items;"
              ng-show="isUserCount"
            >
              <a (click)="ChangeUserLogin(option.UserLogin,option.UserId)"
                ><i class="fa fa-{{option.UserName}}"></i>
                {{option.OrganisationName}}</a
              >
            </li>
            <li style="border-top: #afafaf solid thin" *ngIf="isUserCount">
              <a (click)="SetDefaultUserRoles()"
                ><i class="fa fa-user"></i> Set current role as default</a
              >
            </li>
            <li
              style="border-top: #afafaf solid thin"
              *ngIf="identityRolesService.isSuperAdmin"
            >
              <a> <i class="fa fa-eye"></i> Inspect Current Online User </a>
            </li>
            <li
              style="border-top: #afafaf solid thin"
              *ngIf="identityRolesService.isSuperAdmin"
            >
              <a>
                <i class="fa fa-user"></i>
                App admin
              </a>
            </li>
            <li
              style="border-top: #afafaf solid thin"
              *ngIf="identityRolesService.isSuperAdmin"
            >
              <a> <i class="fa fa-user-secret"></i> Impersonate </a>
            </li>
            <li class="chubbRed logOut" style="border-top: #afafaf solid thin">
              <a (click)="logOut()" class="chubbRed"
                ><i class="fa fa-power-off chubbRed"></i> Logout</a
              >
            </li>
          </ul>
        </li>
      </ul>

      <!-- <span class="profile-info" data-ng-bind="userDisplayName"></span> -->
      <!-- <span class="profile-info">{{userDisplayName}} ({{usertypeName}})</span> -->
      <!-- <span class="profile-info"> -->

      <!-- </span> -->
      <!-- <span> {{ this.customMsgService.translate('language') }} </span> -->
      <!-- <kendo-dropdownlist [data]="locales" textField="locale" valueField="localeId" [value]="selectedLanguage"
      (valueChange)="changeLanguage($event)" [popupSettings]="popupSettings"
      [ngClass]="{'material-bg':selectedTheme.text==='Material'}" class="ddl-locale">
    </kendo-dropdownlist> -->

      <!-- <kendo-dropdownlist
      [data]="themes"
      textField="text"
      valueField="href"
      [value]="selectedTheme"
      (valueChange)="changeTheme($event)"
      [popupSettings]="popupSettings"
      [ngClass]="{'material-bg':selectedTheme.text==='Material'}"
      class="ddl-theme"
    >
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <span class="k-icon k-i-palette"></span>
      </ng-template>
    </kendo-dropdownlist> -->
      <!-- </div> -->
    </div>
    <!-- </div> -->
    <!-- <kendo-avatar imageSrc="assets/user.jpg" shape="circle">  </kendo-avatar> -->
  </div>
</div>
