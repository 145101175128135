import { Component, Input, OnInit } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { UserProfileService } from "src/app/services/user-profile.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-md-profile-details",
  templateUrl: "./md-profile-details.component.html",
  styleUrls: ["./md-profile-details.component.scss"],
})
export class MdProfileDetailsComponent implements OnInit {
  @Input() public data: any;
  public isProfileDetailsUpdated: boolean = false;
  public isExternalUser: boolean = false;

  constructor(
    public dialog: DialogRef,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    this.isExternalUser =
      this.data.details.emailAddress.toString().indexOf("@chubb") > -1
        ? false
        : true;
  }

  changePassword = () => {
    let authority = environment.adb2cSettings.passwordChangepolicy.authority;
    let changePasswordPolicy =
      environment.adb2cSettings.passwordChangepolicy.value;
    let changePasswordRedirectConfirmationUri =
      environment.adb2cSettings.passwordChangepolicy.redirectUri;
    let clientId = environment.adb2cSettings.clientId;

    const authorityUrl =
      authority + "/" + changePasswordPolicy + "/oauth2/v2.0";
    const changePasswordUrl =
      authorityUrl +
      "/authorize?client_id=" +
      clientId +
      "&response_type=id_token&redirect_uri=" +
      encodeURIComponent(changePasswordRedirectConfirmationUri) +
      "&response_mode=query&scope=openid";

    this.userProfileService
      .GenerateUserIdToken(this.data.details.emailAddress)
      .subscribe((result: any) => {
        let userIdToken: string = result.body.TokenData.replace('"', "");
        window.location.href =
          changePasswordUrl + "&id_token_hint=" + userIdToken;
      });
  };
}
