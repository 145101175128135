import { Component, OnInit } from "@angular/core";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { ExcelService } from "src/app/services/excel.service";
import { Global } from "src/app/shared/global";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseUploadComponent } from "../../accounting/base-upload/base-upload.component";
import { ExcelTemplateService } from "src/app/services/excel-template.service";

@Component({
  selector: "app-auto-loan-redemption",
  templateUrl: "./auto-loan-redemption.component.html",
  styleUrls: ["./auto-loan-redemption.component.scss"],
})
export class AutoLoanRedemptionComponent
  extends BaseUploadComponent
  implements OnInit
{
  constructor(
    public override uploadService: UploadExcelService,
    public override router: Router,
    public override route: ActivatedRoute,
    public override excelTemplate: ExcelTemplateService,
    private excelService: ExcelService
  ) {
    super(uploadService, router, route, excelTemplate);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    //this would most likely override the base's Subscription, which is fine, for now
    this.sub = this.route.data.subscribe(
      (v) => (this.uploadExcel.segmentDetails = v["type"]) //read from route param, as in app.route.js
    );
  }

  override addExcel() {
    this.isProcessing = true;

    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);
    uploadedExcelFormData.append(
      "selectedSection",
      JSON.parse(JSON.stringify(this.uploadExcel.segmentDetails))
    );

    this.uploadService
      .uploadAutoLoanRedemption(uploadedExcelFormData)
      .subscribe(
        (result: any) => {
          let tempData: any = null;
          //no longer result.data as in angularJs but result.body
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            if (result.body.Status === false) {
              tempData = {
                message: result.body.Message,
                validationMessage: result.body.ValidationMessage,
                status: false,
              };
            } else {
              tempData = {
                message: result.body.Message,
                status: true,
                iBGSuccessTable: result.body.IBGSuccessTable,
                iBGExceptionTable: result.body.IBGExceptionTable,
                cBGExceptionTable: result.body.CBGExceptionTable,
                cBGSuccessTable: result.body.CBGSuccessTable,
                citiExceptionTable: result.body.CitiExceptionTable,
                citiSuccessTable: result.body.CitiSuccessTable,
              };

              let reportTitle: string = "";
              if (tempData.iBGExceptionTable.length > 0) {
                reportTitle = "Loan Redemption Exception Report - IBG";
                this.excelService.constructWorkbookFailure(
                  tempData.iBGExceptionTable,
                  "IBG",
                  reportTitle
                );
              }

              if (tempData.iBGSuccessTable.length > 0) {
                reportTitle = "Loan Redemption Success Report - IBG";
                this.excelService.constructWorkbookSuccess(
                  tempData,
                  tempData.iBGSuccessTable,
                  "IBG",
                  reportTitle
                );
              }

              if (tempData.cBGExceptionTable.length > 0) {
                reportTitle = "Loan Redemption Exception Report - CBG";
                this.excelService.constructWorkbookFailure(
                  tempData.cBGExceptionTable,
                  "CBG",
                  reportTitle
                );
              }

              if (tempData.cBGSuccessTable.length > 0) {
                reportTitle = "Loan Redemption Success Report - CBG";
                this.excelService.constructWorkbookSuccess(
                  tempData,
                  tempData.cBGSuccessTable,
                  "CBG",
                  reportTitle
                );
              }

              if (tempData.citiExceptionTable.length > 0) {
                reportTitle = "Loan Redemption Exception Report - Citi";
                this.excelService.constructWorkbookFailure(
                  tempData.citiExceptionTable,
                  "Citi",
                  reportTitle
                );
              }

              if (
                this.uploadExcel.segmentDetails.toLowerCase() == "citi" &&
                tempData.citiSuccessTable != null
              ) {
                reportTitle = "Loan Redemption Success Report - Citi";
                this.excelService.constructWorkbookSuccess(
                  tempData,
                  tempData.citiSuccessTable,
                  "Citi",
                  reportTitle
                );
              }
            }

            // Clear uploaded file once, the file is successfuly uploaded.
            //this.uploadExcel.files = {};//maybe no need to clear, just like original

            this.redirectOpened = true;
            this.redirectData = tempData;
            this.isProcessing = false;
          }
        },
        (error: any) => {
          console.log(error);
          // Prompt fail
          this.errorLogs.Logs = new Array<string>(); // Clear error logs
          this.errorLogs.Title = "Error";

          const log: string =
            Global.isDefined(error?.data?.ExceptionMessage) &&
            error.data.ExceptionMessage !== ""
              ? error.data.ExceptionMessage
              : "Failed to upload excel file. Please contact the system administrator.";

          this.errorLogs.Logs.push(log);
          this.isProcessing = false;
        }
      );
  }
}

export class IBGBouncedMailPolicyEntity {
  rowIndex!: string;
  Transactions: Array<IColumn> = [];
}

export interface IColumn {
  PolicyNo: string;
  ClientCode: string;
  InsuredName: string;
  DOB: string;
  MobileNo: string;
  Email: string;
  CorrespondenceAddress: string;
  EndorsementEffectiveDate: string;
  PolicyType: string;
  PolicyStatus: string;
  PreferedDeliveryMethod: string;
  EplDownloadDate: string;
}
