import { Component, Input } from "@angular/core";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { Subscription } from "rxjs";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";
import { pageLifeCycleNotifier } from "src/app/models/new-product/notifier/page-life-cycle-notifier";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { MortgageFireSGTabSchema } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/schemas/mf-sg-tab-schema";
import { MfSgBaseComponent } from "../mf-sg-base.component";

export class TabModel {
  constructor(
    public index: number,
    public displayName: string,
    public visible: boolean
  ) {}
}

@Component({
  selector: "mf-sg-tabs",
  templateUrl: "./mf-sg-tabs.component.html",
})
export class MfSgTabsComponent extends MfSgBaseComponent {
  pageLoaded: boolean = false;

  master!: TabModel;
  locations!: TabModel;
  businessInterruption!: TabModel;
  premiumSummary!: TabModel;
  money!: TabModel;
  //     tabStrip: kendo.ui.TabStrip;
  tab_request_notifier_subscriptions!: Subscription;

  @Input() proposal!: MortgageFireSingaporeProposal; //temp using @Input()

  ngOnInit(): void {
    this.initialize();

    this.tab_request_notifier_subscriptions =
      pageLifeCycleNotifier.tab_request_notifier$.subscribe(
        this.tabRequestNotifier
      );
  }

  ngOnDestroy() {
    this.unsubscribeAllRxjs();
  }

  private initialize() {
    this.pageLoaded = true;
    super.subscribePageLoadedNotifier(this.pageLoadedNotifier);
    super.subscribeProposalLoadedNotifier(this.pageLoadedNotifier);

    this.master = new TabModel(
      MortgageFireSGTabSchema.master.index,
      MortgageFireSGTabSchema.master.displayText,
      true
    );

    this.locations = new TabModel(
      MortgageFireSGTabSchema.locations.index,
      MortgageFireSGTabSchema.locations.displayText,
      true
    );

    this.premiumSummary = new TabModel(
      MortgageFireSGTabSchema.premiumSummary.index,
      MortgageFireSGTabSchema.premiumSummary.displayText,
      true
    );

    this.businessInterruption = new TabModel(
      MortgageFireSGTabSchema.businessInterruption.index,
      MortgageFireSGTabSchema.businessInterruption.displayText,
      true
    );

    this.money = new TabModel(
      MortgageFireSGTabSchema.money.index,
      MortgageFireSGTabSchema.money.displayText,
      true
    );
  }

  private tabRequestNotifier = (event: KeyValue<string>) => {
    const tabId = parseInt(event.key, 10);
    this.select(tabId);
    const element: any = document.getElementById(event.value);
    const offset = element.getBoundingClientRect().top - 100;
    window.scrollTo({ top: offset, left: 0, behavior: "smooth" });
  };

  /** Method to be invoke upon page loaded successfully */
  private pageLoadedNotifier = (response: any) => {
    let event = response as KeyValue<MortgageFireSingaporeProposal>;
    this.proposal = event.value;
    this.pageLoaded = true;
    //this.tabStrip = $('#tabstrip').kendoTabStrip().data('kendoTabStrip');//todo: check if needed
    this.autoSelect();
  };

  unsubscribeAllRxjs() {
    super.unsubscribeRxjs();
    this.tab_request_notifier_subscriptions?.unsubscribe();
  }

  autoSelect() {
    switch (this.proposal.transType) {
      default: //this.onTabSelect(this.master.index);
        // default to master tab
        this.click(this.master.index);
        break;
    }
  }

  public onTabSelect(e: SelectEvent) {
    //this.tabStrip.select(tabIndex);//todo: check if needed
    this.click(e.index);
  }

  click(tabIndex: number) {
    pageLifeCycleNotifier.tab_load_notifier$.next(
      new KeyValue<MortgageFireSingaporeProposal>(
        tabIndex.toString(),
        this.proposal
      )
    );
  }

  select(tabIndex: number) {
    // this.tabStrip.selectTab(tabIndex);
    this.click(tabIndex);
  }
}
