<!-- #manualPaidAutoDebitFields -->
<div id="manualPaidAutoDebitFields" class="container-fluid reportOption">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group row">
        <div class="col-md-1">
          <label class="mt-1">Month</label>
        </div>
        <div class="col-md-2">
          <kendo-datepicker
            class="maxWidth"
            id="IBGrenewalStartDateFrom"
            format="MMMM  yyyy"
            [bottomView]="'year'"
            placeholder=""
            [(ngModel)]="searchCriteria.month"
            name="month"
          ></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      (click)="download()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
    </button>
  </div>
</div>
