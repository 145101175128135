import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";
import { OnInit, Input, Component, Output } from "@angular/core";
import { common } from "src/app/framework/utils/common";
import { stream } from "exceljs";

@Component({
  selector: "text-box-v2-question",
  templateUrl: "./text-box-v2-question.component.html",
  styleUrls: ["./text-box-v2-question.component.scss"],
})
export class TextBoxV2QuestionComponent implements OnInit {
  @Input() public question!: QuestionEntity;
  @Input() public placeholder?: string;
  @Input() public title?: string;
  @Input() public front?: boolean;
  @Input() public end?: boolean;
  @Input() public onClose?: (args: any) => void;

  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;

  constructor() {}

  ngOnInit(): void {}

  // public onChildValueChange = (data: any, question: string): void => {
  //   if (
  //     question == "qs_risk_insuredAddress_lotunit" &&
  //     data.toUpperCase().trim().startsWith("FLAT") &&
  //     !common.isStringNullOrEmpty(data)
  //   )
  //     this.question.answer = this.question.answer.slice(4).trim();

  //   if (
  //     question == "qs_risk_insuredAddress_building" &&
  //     data.toUpperCase().trim().startsWith("BLOCK") &&
  //     !common.isStringNullOrEmpty(data)
  //   )
  //     this.question.answer = this.question.answer.slice(5).trim();

  //   if (
  //     question == "qs_risk_insuredAddress1" &&
  //     data.toUpperCase().trim().endsWith("/F") &&
  //     !common.isStringNullOrEmpty(data)
  //   )
  //     this.question.answer = this.question.answer.slice(0, -2).trim();
  // };

  //https://stackoverflow.com/questions/40341648/angular-2-how-to-bind-input-to-model-property-with-getter-and-setter-methods
  // Getter method
  public get QuestionAnswerFormatted(): string {
    if (this.question.key == "qs_risk_insuredAddress_lotunit") {
      if (this.question.answer === undefined || this.question.answer === "Flat ") {
        this.question.answer = "";
      }
      //DO NOT replace directly, answer could be null, hence init above first
      return this.question.answer.replace(/^Flat /, "").replace(/^Flat/, "");
    }

    if (this.question.key == "qs_risk_insuredAddress_building") {
      if (this.question.answer === undefined || this.question.answer === "Block ") {
        this.question.answer = "";
      }
      //DO NOT replace directly, answer could be null, hence init above first
      return this.question.answer.replace(/^Block /, "").replace(/^Block/, "");
    }

    if (this.question.key == "qs_risk_insuredAddress1") {
      if (this.question.answer === undefined || this.question.answer === " /F") {
        this.question.answer = "";
      }
      //DO NOT replace directly, answer could be null, hence init above first
      return this.question.answer.replace(/ \/F$/, "").replace(/\/F$/, "");
    }

    return this.question.answer;
  }

  // Setter method
  public set QuestionAnswerFormatted(val: string) {
    // strip out all the currency information
    if (this.question.key == "qs_risk_insuredAddress_lotunit") {
      this.question.answer = "Flat " + val.trim();
      return;
    }

    if (this.question.key == "qs_risk_insuredAddress_building") {
      this.question.answer = "Block " + val.trim();
      return;
    }

    if (this.question.key == "qs_risk_insuredAddress1") {
      this.question.answer = val.trim() + " /F";
      return;
    }

    this.question.answer = val.trim();
  }
}
