import { ArgumentInvalidException } from './../domain-entity/exceptions/argument-invalid-exception';
import { ArgumentNullException } from './../domain-entity/exceptions/argument-null-exception';
import { Exception } from './../domain-entity/exceptions/exception';
import { NotImplementedException } from './../domain-entity/exceptions/not-implemented-exception';
import { UnauthorizedException } from './../domain-entity/exceptions/unauthorized-exception';
import { common } from './common';

export class ExceptionManager {
    static argumentNullGuard(argument: any, argumentName: string) {
        if (common.isUndefinedOrNull(argument)) {
            throw new ArgumentNullException(argument, argumentName);
        }
    }

    static incomplete(message?: string) {
        throw new NotImplementedException(message);
    }

    static error(message: string, header?: string) {
        throw new Exception(message, header);
    }

    static invalidArgument(argument: any, argumentName: string, message?: string) {
        throw new ArgumentInvalidException(argument, argumentName, message);
    }

    static unauthorized() {
        throw new UnauthorizedException();
    }
}
