import * as Enums from "./../../framework/enum.shared";
import * as InterfaceProduct from "./../../framework/interface.product";
import { ExceptionManager } from "./../../framework/utils/exception-manager";
import { GUID } from "./../../framework/domain-entity/guid";
import { KeyValue } from "./../../framework/domain-entity/key-value";
import { Injectable, ViewContainerRef } from "@angular/core";
import { ApiService } from "../dal/api.service";
import { Proposal } from "./../../models/new-product/base/proposal.model";
import { ProposalEntity } from "./../../models/new-product/base/proposal-entity.model";
import { ProposalEntityMapper } from "./../../models/new-product/mapper/proposal-entity.mapper";
import { Observable, Subject } from "rxjs";
import { common } from "src/app/framework/utils/common";
import { apiActionNotifier } from "./../../models/new-product/notifier/api-action-notifier";
import { apiMapperNotifier } from "./../../models/new-product/notifier/api-mapper-notifier";
import { ProposalEditableHelperService } from "./../../models/new-product/base/utils/proposal-editable-helper.service";
import { IdentityRolesService } from "../identity-roles.service";
import { environment } from "src/environments/environment";
import { NotifierService } from "../notifier.service";
import * as Const from "src/app/framework/const.shared";
import { httpNotifier } from "src/app/interceptor/http-notifier";


@Injectable({
  providedIn: "root",
})
export class ProposalService {
  loadedProposalEntity!: ProposalEntity;
  currentProposal!: Proposal | undefined;

  private proposalEntityMapper: ProposalEntityMapper;
  private proposalActionUri: string =
    environment.services.epProposal + "action";

  constructor(
    private apiService: ApiService,
    private identityRolesService: IdentityRolesService,
    private proposalEditableHelperService: ProposalEditableHelperService,
    private notifier: NotifierService
  ) {
    this.proposalEntityMapper = new ProposalEntityMapper();
  }

  //#region migrate from old angularjs
  create(productId: GUID): Observable<ProposalEntity> {
    // refresh the [loadedProposalEntity] & [currentProposal] field because we are trying to load another new proposal
    this.loadedProposalEntity = new ProposalEntity(
      new GUID(common.DEFAULT_GUID)
    );
    this.currentProposal = undefined;
    this.loadedProposalEntity.productId = productId;

    return this.action(
      Enums.ProposalCommandAction.CreateOrEdit,
      this.loadedProposalEntity
    );
  }

  safeGuardCommand(
    command: Enums.ProposalCommandAction
  ): Enums.ProposalCommandAction {
    if (
      (this.identityRolesService.getIdentity().role.isClaimant ||
        this.identityRolesService.getIdentity().role.isProductReadOnly) &&
      !this.identityRolesService.getIdentity().role.IsCbsOps &&
      !this.identityRolesService.getIdentity().role.IsCSCBGSG
    ) {
      // Open only
      return Enums.ProposalCommandAction.Open;
    }

    return command;
  }

  edit(proposalId: GUID): Observable<ProposalEntity> {
    // refresh the [loadedProposalEntity] & [currentProposal] field because we are trying to load another new proposal
    this.loadedProposalEntity = new ProposalEntity(proposalId);
    this.currentProposal = undefined;
    this.loadedProposalEntity.productId = common.DEFAULT_GUID_OBJ;

    return this.action(
      Enums.ProposalCommandAction.CreateOrEdit,
      this.loadedProposalEntity
    );
  }

  action(
    commandAction: Enums.ProposalCommandAction,
    proposalEntity: ProposalEntity,
    isNotificationRequired: boolean = true
  ): Observable<ProposalEntity> {
    var command = this.safeGuardCommand(commandAction);

    /** Current login user is transaction read only user
     * It could be [Claims] or whatever read only user type
     */
    if (
      this.identityRolesService.getIdentity().isProposalTransactionReadOnly ===
      true
    ) {
      // read only user is only allowed to be View
      if (command !== Enums.ProposalCommandAction.Open) {
        ExceptionManager.error("Current user is in read only roles");
      }
    }

    const subject = new Subject<ProposalEntity>();

    apiMapperNotifier.before_entity_to_api_map_notifier$.next({
      proposalEntity: proposalEntity,
      proposalApiModel: undefined,
    });

    const apiModel = this.proposalEntityMapper.map(proposalEntity);

    apiMapperNotifier.after_entity_to_api_map_notifier$.next({
      proposalEntity: proposalEntity,
      proposalApiModel: apiModel,
    });

    const postData: InterfaceProduct.IWebServicePostData = {
      jd: JSON.stringify(apiModel),
      c: command,
    };

    //todo
    // const sessionId = Application.angular_$state().params['sid'];
    // if (sessionId != null) {
    //     postData.sid = sessionId;
    // }

    // const readonly = Application.angular_$state().params['ro'];
    // if (readonly != null) {
    //     postData.ro = readonly;
    // }
    httpNotifier.request_notifier$.next(new KeyValue("", ""));
    apiActionNotifier.before_notifier$.next(
      new KeyValue(proposalEntity.proposalId!.toString(), proposalEntity)
    );

    this.apiService
      .update2<InterfaceProduct.IProposalApiModel>(
        this.proposalActionUri,
        //{},
        postData
      )
      .subscribe(
        (result: any) => {
          if (result) {
            const entity = this.proposalEntityMapper.reverseMap(result);

            this.editable(command, entity);

            apiMapperNotifier.after_api_to_entity_map_notifier$.next({
              proposalEntity: entity,
              proposalApiModel: result,
            });

            this.loadedProposalEntity = entity;

            subject.next(entity);
            subject.complete();

            apiActionNotifier.success_notifier$.next(
              new KeyValue(entity.proposalId!.toString(), entity)
            );
            httpNotifier.response_notifier$.next(new KeyValue("", ""));   
            if (isNotificationRequired) {
              this.notify(postData.c!, entity);
            }
          }
        },
        (rejection) => {
          subject.error(rejection);
          httpNotifier.response_error_notifier$.next(new KeyValue("", ""));
          apiActionNotifier.failure_notifier$.next(
            new KeyValue(proposalEntity.proposalId!.toString(), rejection)
          );

          this.notifier.error("Error Occurred!", this.getVCR());
        }
      );

    return subject;
  }

  editable(
    command: Enums.ProposalCommandAction,
    proposalEntity: ProposalEntity
  ) {
    // [Open] or isReadOnly() is none editable
    let readOnly =
      command === Enums.ProposalCommandAction.Open ||
      this.proposalEditableHelperService.isReadOnly(proposalEntity);

    // Renewal check : it should be view only for checker and maker
    if (
      command === Enums.ProposalCommandAction.CreateOrEdit &&
      proposalEntity.transType === Enums.TransType.REN &&
      this.identityRolesService.getIdentity().currentUser().userType ===
        Enums.UserType.Broker
    ) {
      readOnly = true;
    }

    proposalEntity.proposalReadOnly = readOnly;

    if (readOnly) {
      // set all questions to read only
      proposalEntity.questions?.forEach((q) => (q.readOnly = true));
    }
  }

  notify(command: Enums.ProposalCommandAction, entity: ProposalEntity) {
    switch (command) {
      case Enums.ProposalCommandAction.Save:
        this.notifier.success("Saved", this.getVCR());
        break;
      case Enums.ProposalCommandAction.SendToChecker:
        this.notifier.success("Sent", this.getVCR());
        break;
      case Enums.ProposalCommandAction.ReturnForRevise:
        this.notifier.success("Returned", this.getVCR());
        break;
      case Enums.ProposalCommandAction.Submit:
        switch (entity.proposalStatus) {
          case Enums.ProposalStatus.Referred:
            this.notifier.success("Referred", this.getVCR());
            break;
          case Enums.ProposalStatus.Accepted:
            this.notifier.success("Policy Bound Successfully", this.getVCR());
            break;
        }
        break;
      case Enums.ProposalCommandAction.Copy:
        this.notifier.success("Editing Copied Proposal", this.getVCR());
        break;
      case Enums.ProposalCommandAction.Decline:
        this.notifier.success("Declined", this.getVCR());
        break;
      case Enums.ProposalCommandAction.EditDeclinedProposal:
        this.notifier.success("Editing Declined Proposal", this.getVCR());
        break;
      case Enums.ProposalCommandAction.Accept:
        this.notifier.success("Policy Bound Successfully", this.getVCR());
        break;
    }
  }
  //#endregion

  serviceViewContainerRef!: ViewContainerRef;

  getVCR(): ViewContainerRef {
    return this.serviceViewContainerRef;
  }

  setVCR(value: ViewContainerRef) {
    this.serviceViewContainerRef = value;
  }
}
