<div ng-if="tabRequested" id="masterTab">
  <div *ngIf="tabShow">
    <div class="container-fluid-customized">
      <div class="row" *ngIf="isEndorsement || isCancel">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <div class="card-head chubbLightGray_bg">
            <header
              class="fontBold chubbWhite"
              id="client_panel_end"
              *ngIf="isEndorsement"
            >
              Endorsement
            </header>
            <header
              class="fontBold chubbWhite"
              id="client_panel_can"
              *ngIf="isCancel"
            >
              Cancellation
            </header>
          </div>
          <div class="card-body card-body-mod cardBodyBorder cardNoBorder">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <date-picker-v1-question
                        [question]="proposal!.qsMaster.qs_master_effectiveDate!"
                        answer="proposal!.qsMaster.qs_master_effectiveDate.answer"
                        [minDate]="qs_master_effectiveDate_datePicker_minDate"
                        [maxDate]="qs_master_effectiveDate_datePicker_maxDate"
                      >
                      </date-picker-v1-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-area-question
                        [question]="
                          proposal!.qsMaster
                            .qs_master_endorse_endorsementReason!
                        "
                      >
                      </text-area-question>
                    </div>

                    <div class="form-group" *ngIf="editMSIGPolicy">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_replacePolicyNo!
                        "
                      >
                      </text-box-question>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <form role="form "></form>
                      </div>
                    </div>
                  </form>
                  <form role="form " class="form-inline">
                    <div
                      class="form-group"
                      *ngIf="
                        proposal!.qsMaster.qs_master_waiveMinimumPremium!
                          .isVisible
                      "
                    >
                      <div class="checkbox form-inline">
                        <label>
                          <input
                            #qs_master_waiveMinimumPremium_checkbox
                            name="{{
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .key
                            }}"
                            type="checkbox"
                            id="{{
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .key
                            }}_checkbox"
                            (click)="
                              updateModelAnswer(
                                proposal!.qsMaster
                                  .qs_master_waiveMinimumPremium!
                              )
                            "
                            [checked]="
                              compareQuestionAnswer(
                                proposal!.qsMaster
                                  .qs_master_waiveMinimumPremium!
                              )
                            "
                            [disabled]="
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .isDisabled!
                            "
                          />
                          {{
                            proposal!.qsMaster.qs_master_waiveMinimumPremium!
                              .displayText
                          }}
                        </label>
                      </div>
                    </div>
                  </form>
                  <form role="form">
                    <div class="form-group">
                      <div
                        *ngIf="
                          isIBGProduct &&
                          proposal!.IsCancellationAdjustment &&
                          proposalService.proposal!.auxiliary[
                            'isPremiumManualOverridden'
                          ]
                        "
                      >
                        <label
                          [textContent]="
                            proposal!.qsMaster.qs_master_manualUnderwritting!
                              .displayText
                          "
                        ></label>
                        <div>
                          <div
                            class=""
                            *ngIf="
                              proposalService.proposal!.auxiliary[
                                'isPremiumManualOverridden'
                              ]
                            "
                            style="padding-left: 5px"
                          >
                            Yes
                          </div>
                          <div
                            class=""
                            *ngIf="
                              !proposalService.proposal!.auxiliary[
                                'isPremiumManualOverridden'
                              ]
                            "
                            style="padding-left: 5px"
                          >
                            No
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <form role="form " class="">
                    <div
                      class="form-group"
                      *ngIf="
                        proposal!.qsMaster
                          .qs_master_cancellation_adjustmentAmount!.isVisible
                      "
                    >
                      <div class=" ">
                        <label
                          class=""
                          for="cancellation_adjustmentAmount_{{
                            proposal!.qsMaster
                              .qs_master_cancellation_adjustmentAmount!.key
                          }}"
                          [ngClass]="{
                            required:
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .required &&
                              !proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly
                          }"
                          [textContent]="
                            proposal!.qsMaster
                              .qs_master_cancellation_adjustmentAmount!
                              .displayText
                          "
                        ></label>
                        <div
                          class=""
                          *ngIf="
                            !proposal!.qsMaster
                              .qs_master_cancellation_adjustmentAmount!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            [(ngModel)]="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.answer
                            "
                            class="textAlignLeft form-inline"
                            style="width: 100px"
                            *ngIf="
                              !proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly
                            "
                            [format]="'c2'"
                            [spinners]="false"
                            id="cancellation_adjustmentAmount_{{
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.key
                            }}"
                            name="cancellation_adjustmentAmount_{{
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.key
                            }}"
                            [decimals]="2"
                          >
                          </kendo-numerictextbox>
                        </div>
                        <div>
                          <span
                            [textContent]="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.answer
                                | ausCurrency
                            "
                            *ngIf="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly &&
                              proposal!.qsMaster.qs_master_cancellation_adjustmentAmount!.isAnswered()
                            "
                          >
                          </span>
                          <span
                            class="unattended"
                            *ngIf="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly &&
                              !proposal!.qsMaster.qs_master_cancellation_adjustmentAmount!.isAnswered()
                            "
                            >{{ noAnswerDefaultText }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form " class="">
                    <div
                      class="form-group"
                      *ngIf="
                        proposal!.qsMaster
                          .qs_master_cancellation_maximumAdjustmentAmount!
                          .isVisible
                      "
                    >
                      <div class="">
                        <label
                          for="{{
                            proposal!.qsMaster
                              .qs_master_cancellation_maximumAdjustmentAmount!
                              .key
                          }}"
                          [textContent]="
                            proposal!.qsMaster
                              .qs_master_cancellation_maximumAdjustmentAmount!
                              .displayText
                          "
                        >
                        </label>
                        <div>
                          <span
                            attr.for="{{
                              proposal!.qsMaster
                                .qs_master_cancellation_maximumAdjustmentAmount!
                                .key
                            }}"
                            >{{
                              proposal!.qsMaster
                                .qs_master_cancellation_maximumAdjustmentAmount!
                                .answer | ausCurrency
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <kendo-expansionpanel
            id="broker_panel"
            title="Relationship Manager Information"
            [expanded]="true"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_rmStaffReference!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_rmSalesChannel!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_rmMortgageeCode!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_rmBranchCode!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row" *ngIf="isCMBProduct">
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_rmSalesStaffCode!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </kendo-expansionpanel>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <kendo-expansionpanel
            id="broker_panel"
            title="Client Information"
            [expanded]="true"
          >
            <div class="container-fluid">
              <div class="row" *ngIf="showClientSearch()">
                <div class="col-md-3">
                  <form role="form">
                    <div
                      class="btn-box chubbWhite chubbLightOrange_bg"
                      (click)="clientSearch()"
                    >
                      Client Search
                    </div>
                  </form>
                </div>
                <div class="col-md-3">
                  <form role="form">
                    <div
                      class="btn-box chubbWhite chubbLightOrange_bg"
                      (click)="clientCreate()"
                    >
                      Create New Client
                    </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3" id="qs_master_insuredNo">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredNo!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-4">
                  <form role="form ">
                    <div
                      class="form-group"
                      id="qs_master_insuredName"
                      [ngClass]="{
                        'st-danger':
                          proposal!.qsMaster.qs_master_insuredName!.theme === 1
                      }"
                    >
                      <label
                        [ngClass]="{
                          required:
                            proposal!.qsMaster.qs_master_insuredName!
                              .required &&
                            !proposal!.qsMaster.qs_master_insuredName!.readOnly
                        }"
                        [textContent]="
                          proposal!.qsMaster.qs_master_insuredName!.displayText
                        "
                        class="fontSize14px"
                      ></label>
                      <span
                        class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                        (click)="viewClientDetails()"
                        *ngIf="
                          proposal!.qsMaster.qs_master_insuredName!.isAnswered() &&
                          !isIBGProduct
                        "
                        style="padding-left: 5px; padding-right: 5px"
                        >View Details</span
                      >
                      <span
                        class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                        (click)="GotoUpdateClientInfo()"
                        *ngIf="!isIBGProduct && isChangeOfClientInfo"
                        style="padding-left: 5px; padding-right: 5px"
                      >
                        Update Client
                      </span>
                      <div>
                        <p
                          class="wordBreakAll"
                          *ngIf="
                            proposal!.qsMaster.qs_master_insuredName!.isAnswered()
                          "
                        >
                          {{ proposal!.qsMaster.qs_master_insuredName!.answer }}
                        </p>

                        <span
                          class="unattended"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_insuredName!.isAnswered()
                          "
                          >Unknown</span
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <form role="form ">
                    <div class="form-group">
                      <div
                        id="{{
                          proposal!.qsMaster.qs_master_preferredDeliveryMethod!
                            .key
                        }}"
                        [ngClass]="{
                          'st-danger':
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.theme === 1
                        }"
                      >
                        <label
                          for="{{
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.key
                          }}"
                          [ngClass]="{
                            required:
                              proposal!.qsMaster
                                .qs_master_preferredDeliveryMethod!.required &&
                              (isChangeOfClientInfo ||
                                (!isEndorsement && !isCancel))
                          }"
                          >Contact Info./Preferred Delivery Method:</label
                        >
                        <span> {{ preferredDeliveryText }}</span>
                        <span
                          class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                          (click)="updatePreferredDelivery()"
                          *ngIf="
                            isChangeOfClientInfo ||
                            isRenewalDraft ||
                            showClientSearch()
                          "
                          style="padding-left: 5px; padding-right: 5px"
                          >Update</span
                        >
                        <div class="radio clearMarginTopBottom">
                          <div class="row">
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 5 &&
                                    (isChangeOfClientInfo ||
                                      (!isEndorsement && !isCancel))
                                }"
                                >Telephone Number</label
                              >
                            </div>
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 2 &&
                                    (isChangeOfClientInfo ||
                                      (!isEndorsement && !isCancel))
                                }"
                                >Email Address</label
                              >
                            </div>
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 1 &&
                                    (isChangeOfClientInfo ||
                                      (!isEndorsement && !isCancel))
                                }"
                                >Correspondence Address</label
                              >

                              <span
                                class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                                (click)="updatePreferredDelivery()"
                                [hidden]="!isChangeOfCorrespondenceAddressType"
                                style="padding-left: 5px; padding-right: 5px"
                                >Update</span
                              >
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="radio clearMarginTopBottom">
                            <div class="row">
                              <div class="col-md-4">
                                <p
                                  *ngIf="
                                    proposal!.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.isAnswered()
                                  "
                                  class="wordBreak"
                                >
                                  {{
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod_MobileNo!
                                      .answer
                                  }}
                                </p>
                              </div>
                              <div class="col-md-4">
                                <p
                                  *ngIf="
                                    proposal!.qsMaster.qs_master_preferredDeliveryMethod_Email!.isAnswered()
                                  "
                                  class="wordBreak"
                                >
                                  {{
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod_Email!
                                      .answer
                                  }}
                                </p>
                              </div>
                              <div class="col-md-4">
                                <p
                                  *ngIf="
                                    proposal!.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.isAnswered()
                                  "
                                  class="wordBreak"
                                >
                                  {{ contactInformationPreferredDelivery }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- </div> -->
          </kendo-expansionpanel>

          <!--not need anymore for Hase-->
          <div class="" *ngIf="false">
            <div class="col-md-12">
              <div
                id="qs_fire_interestedParties_table_grid"
                style="margin-bottom: 20px"
              >
                <kendo-grid
                  [data]="gridFireInterestedParties"
                  [pageSize]="10"
                  [pageable]="true"
                  [sortable]="true"
                  (edit)="editHandler($event)"
                  (cancel)="cancelHandler($event)"
                  (save)="saveHandler($event)"
                  (remove)="removeHandler($event)"
                  (add)="addHandler($event)"
                  [navigable]="true"
                  [height]="280"
                >
                  <ng-template
                    kendoGridToolbarTemplate
                    *ngIf="addButtonVisiiblity"
                  >
                    <button kendoGridAddCommand type="button">
                      {{ labelTextInterestedParty }}
                    </button>
                  </ng-template>
                  <kendo-grid-column field="Name" title="Name" [width]="350">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                      <kendo-textbox
                        [(ngModel)]="dataItem.Name"
                        name="Name"
                        required
                      >
                      </kendo-textbox>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    field="NatureOfInterest"
                    title="Nature Of Interest"
                    [width]="250"
                    [editable]="false"
                  ></kendo-grid-column>
                  <kendo-grid-command-column
                    title="Action"
                    [hidden]="hideInterestedPartiesActionCol"
                    [width]="220"
                  >
                    <ng-template kendoGridCellTemplate let-isNew="isNew">
                      <button
                        kendoGridEditCommand
                        type="button"
                        [primary]="true"
                      >
                        Edit
                      </button>
                      <button kendoGridRemoveCommand type="button">
                        Remove
                      </button>
                      <button
                        kendoGridSaveCommand
                        type="button"
                        class="k-button-icontext"
                      >
                        {{ isNew ? "Add" : "Update" }}
                      </button>
                      <button kendoGridCancelCommand type="button">
                        {{ isNew ? "Discard changes" : "Cancel" }}
                      </button>
                    </ng-template>
                  </kendo-grid-command-column>
                </kendo-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 card card-mod cardNoBorder ng-scope">
        <kendo-expansionpanel
          id="broker_panel"
          [title]="
            ibgMACDisabled ? 'Fire Risk Details' : 'Machinery Risk Details'
          "
          [expanded]="true"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="
                        proposal!.qsMaster.qs_master_masterPolicyNumber!
                      "
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <div
                      id="div_isMigrated"
                      *ngIf="proposal!.refreshedProposalEntity.isMigrated"
                    >
                      <label [textContent]="''"></label>
                      <div>
                        <input
                          name="checkboxIsMigrated"
                          type="checkbox"
                          id="chk_isMigrated"
                          [disabled]="
                            proposal!.refreshedProposalEntity.proposalStatus! ==
                            2
                          "
                          [checked]="
                            proposal!.refreshedProposalEntity.isMigrated
                          "
                        /><label
                          style="display: inline; padding: 10px"
                          [textContent]="'  Migrated'"
                        ></label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      for="{{ proposal!.qsMaster.qs_fire_freeInsurance!.key }}"
                      [ngClass]="{
                        required:
                          proposal!.qsMaster.qs_fire_freeInsurance!.required &&
                          !proposal!.qsMaster.qs_fire_freeInsurance!.readOnly
                      }"
                      [textContent]="
                        proposal!.qsMaster.qs_fire_freeInsurance!.displayText
                      "
                    ></label>

                    <div
                      *ngIf="
                        !proposal!.qsMaster.qs_fire_freeInsurance!.readOnly
                      "
                    >
                      <div class="radio clearMarginTopBottom">
                        <input
                          name="{{
                            proposal!.qsMaster.qs_fire_freeInsurance!.key
                          }} "
                          #qs_otherinfo_AutoIssue_checkbox
                          type="checkbox"
                          id="{{
                            proposal!.qsMaster.qs_fire_freeInsurance!.key
                          }}_checkbox"
                          [checked]="
                            compareQuestionAnswer(
                              proposal!.qsMaster.qs_fire_freeInsurance!
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div> 
              <div class="col-md-3" *ngIf="!isNewBusiness && isPlanTypeExists">
                <form role="form ">
                  <div class="form-group">
                    <radio-button-v1-question
                      [question]="proposal!.qsMaster.qs_master_planType!"
                    >
                    </radio-button-v1-question>
                  </div>
                </form>
              </div>

              <div class="col-md-3" *ngIf="isIBGProduct">
                <form role="form ">
                  <div class="form-group">
                    <yes-no-radio-question
                      [question]="
                        proposal!.qsMaster.qs_fire_freeInsuranceFirstYear!
                      "
                    >
                    </yes-no-radio-question>
                  </div>
                </form>
              </div>-->

              <div
                class="col-md-3"
                *ngIf="
                  proposal!.qsMaster.qs_fire_freeInsuranceNoYear!.isVisible ||
                  !isFreeInsApplicable
                "
              >
                <form role="form ">
                  <div class="form-group">
                    <numeric-box-question
                      [question]="
                        proposal!.qsMaster.qs_fire_freeInsuranceNoYear!
                      "
                    >
                    </numeric-box-question>
                  </div>
                </form>
              </div>
            </div>
            <!--17/10/2018 Chirag : Added Free Insurance Start/End Date -->
            <div
              class="row"
              *ngIf="
                !isIBGProduct &&
                proposal!.qsMaster.qs_master_FreeIns_startDate!.isVisible
              "
            >
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="
                        proposal!.qsMaster.qs_master_FreeIns_startDate!
                      "
                      answer="proposal!.qsMaster.qs_master_FreeIns_startDate"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_FreeIns_endDate!"
                      answer="proposal!.qsMaster.qs_master_FreeIns_endDate"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
            </div>

            <!--Chirag: 28-12-2018 Free Insurance Start/End Date For CBG Prodcut-->
            <div
              class="row"
              *ngIf="
                !isFreeInsApplicable &&
                !proposal!.qsMaster.qs_master_FreeIns_startDate!.isVisible
              "
            >
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="fontSize14px"
                      [textContent]="'Free Insurance Start Date'"
                    ></label>
                  </div>
                  <div class="form-group">
                    <label class="fontSize10px" [textContent]="'N/A'"></label>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="fontSize14px"
                      [textContent]="'Free Insurance End Date'"
                    ></label>
                  </div>
                  <div class="form-group">
                    <label class="fontSize10px" [textContent]="'N/A'"></label>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_poi_startDate!"
                      answer="proposal!.qsMaster.qs_master_poi_startDate.answer "
                      [minDate]="qs_master_poi_startDate_datePicker_minDate"
                      [maxDate]="qs_master_poi_startDate_datePicker_maxDate"
                      [onClose]="refreshEchoPanel"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_poi_endDate!"
                      answer="proposal!.qsMaster.qs_master_poi_endDate.answer "
                      [onClose]="refreshEchoPanel"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      attr.for="{{
                        proposal!.qsMaster.qs_master_insuredName!.key
                      }} "
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Risk Address'"
                    ></label>
                    <!-- requested by qa not to merge with label above, to prevent extra underline-->
                    <label id="dummy" class="required"></label>
                  </div>
                </form>
              </div>
            </div>

            <div class="row" *ngIf="isIBGProduct">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <div>
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_risk_uploadRiskAddress!
                        "
                      >
                      </text-box-question>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12" *ngIf="canEditProposal()">
                <form role="form "></form>
              </div>

              <div class="col-md-12" *ngIf="showClientSearch()">
                <form role="form ">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <label
                          class="floatLeft marginRight10 fontSize14px"
                          [textContent]="
                            'Street Address Search – Please enter only the street address and suburb, do not include building name or suite/lot/unit/shop number or level                                      '
                          "
                        ></label>
                        <div
                          style="font-size: 12px"
                          class="cursorPointer floatLeft chubbWhite"
                        >
                          <span
                            class="chubbGreen_bg"
                            style="
                              padding: 0px 5px 1px 5px;
                              vertical-align: super;
                            "
                            (click)="openAddressHelp($event)"
                            >Help</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <input
                          id="riskInformation_autocomplete"
                          ngx-google-places-autocomplete
                          [options]="googlePlacesOptions"
                          #riskInformation_autocomplete="ngx-places"
                          (onAddressChange)="
                            updateAddressValues(
                              $event,
                              {},
                              'riskInformation_autocomplete'
                            )
                          "
                          [disabled]="isRenewalDraft && isIBGProduct"
                          placeholder="Enter your address "
                          type="text "
                          class="k-textbox maxWidth"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-v2-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress_lotunit!
                          "
                          title="Flat"
                          [front]="true"
                        >
                        </text-box-v2-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-v2-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress_building!
                          "
                          title="Block"
                          [front]="true"
                        >
                        </text-box-v2-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-v2-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress1!
                          "
                          title=" /F"
                          [end]="true"
                        >
                        </text-box-v2-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress2!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress3!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <drop-down-list-filter-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredDistCode!
                          "
                        >
                        </drop-down-list-filter-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="proposal!.qsMaster.qs_risk_insuredCountry!.isVisible"
              >
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_risk_insuredCountry!"
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" *ngIf="showClientSearch()">
                <form role="form ">
                  <div class=" ">
                    <div
                      class="btn-box chubbWhite chubbRed_bg"
                      (click)="
                        resetAddressForm(true, 'riskInformation_autocomplete')
                      "
                      style="margin-bottom: 0px"
                    >
                      Reset
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      attr.for="{{
                        proposal!.qsMaster.qs_master_insuredName!.key
                      }} "
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Car Park Address'"
                    ></label>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12" *ngIf="canEditProposal()">
                <form role="form "></form>
              </div>

              <div class="col-md-12" *ngIf="showClientSearch()">
                <form role="form ">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <label
                          class="floatLeft marginRight10 fontSize14px"
                          [textContent]="
                            'Street Address Search – Please enter only the street address and suburb, do not include building name or suite/lot/unit/shop number or level                                      '
                          "
                        ></label>
                        <div
                          style="font-size: 12px"
                          class="cursorPointer floatLeft chubbWhite"
                        >
                          <span
                            class="chubbGreen_bg"
                            style="
                              padding: 0px 5px 1px 5px;
                              vertical-align: super;
                            "
                            (click)="openAddressHelp($event)"
                            >Help</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <input
                          id="riskCarParkInformation_autocomplete"
                          ngx-google-places-autocomplete
                          [options]="googlePlacesOptions"
                          #riskCarParkInformation_autocomplete="ngx-places"
                          (onAddressChange)="
                            updateCarAddressValues(
                              $event,
                              {},
                              'riskCarParkInformation_autocomplete'
                            )
                          "
                          [disabled]="isRenewalDraft && isIBGProduct"
                          placeholder="Enter your address "
                          type="text "
                          class="k-textbox maxWidth"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_carParkAddress1!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <!-- <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_carParkAddress2!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div> -->
                </div>
              </div>

              <!-- <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_carParkAddress3!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_carParkAddress4!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div> -->

              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_risk_carParkCountry!"
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="" *ngIf="showClientSearch()">
                <form role="form ">
                  <div class="col-md-6">
                    <div class=" ">
                      <div
                        class="btn-box chubbWhite chubbRed_bg"
                        (click)="
                          resetCarAddressForm(
                            true,
                            'riskInformation_autocomplete'
                          )
                        "
                        style="margin-bottom: 0px"
                      >
                        Reset
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <hr />

              <div class="col-md-12">
                <div class="row">
                  <div
                    class="col-md-6"
                    id="{{
                      proposal!.qsMaster.qs_master_manualUnderwritting!.key
                    }}"
                  >
                    <form role="form ">
                      <div
                        class="form-group"
                        id="displayManualUW"
                        *ngIf="displayManualUnderwriting"
                      >
                        <div>
                          <label
                            for="{{
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .key
                            }}"
                            [textContent]="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .displayText
                            "
                          ></label>
                          <div
                            *ngIf="
                              !proposal!.qsMaster.qs_master_manualUnderwritting!
                                .readOnly
                            "
                          >
                            <div
                              class="radio clearMarginTopBottom clearfix"
                              *ngIf="isIncomplete || isReferred"
                            >
                              <input
                                *ngIf="
                                  !proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.readOnly
                                "
                                name="{{
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.key
                                }} "
                                #qs_master_manualUnderwritting_checkbox
                                type="checkbox"
                                value="{{
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer
                                }}"
                                id="{{
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.key
                                }}_checkbox"
                                [disabled]="
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.isDisabled!
                                "
                                (click)="
                                  updateModelAnswer(
                                    proposal!.qsMaster
                                      .qs_master_manualUnderwritting!
                                  );
                                  qs_master_manualUnderwritting_onChange(
                                    proposal!.qsMaster
                                      .qs_master_manualUnderwritting!.answer!
                                  )
                                "
                                [checked]="
                                  compareQuestionAnswer(
                                    proposal!.qsMaster
                                      .qs_master_manualUnderwritting!
                                  )
                                "
                              />
                            </div>
                            <div *ngIf="!isIncomplete && !isReferred">
                              <div
                                class=""
                                *ngIf="
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer ===
                                    'True' ||
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer ===
                                    true
                                "
                                style="padding-left: 5px"
                              >
                                Yes
                              </div>
                              <div
                                class=""
                                *ngIf="
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer !==
                                    'True' &&
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer !==
                                    true
                                "
                                style="padding-left: 5px"
                              >
                                No
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .readOnly
                            "
                          >
                            <div
                              class=""
                              *ngIf="
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer ===
                                  'True' ||
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer ===
                                  true
                              "
                              style="padding-left: 5px"
                            >
                              Yes
                            </div>
                            <div
                              class=""
                              *ngIf="
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer !==
                                  'True' &&
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer !==
                                  true
                              "
                              style="padding-left: 5px"
                            >
                              No
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-group"
                        id="hideManualUW"
                        *ngIf="!displayManualUnderwriting"
                      >
                        <div>
                          <label
                            for="{{
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .key
                            }}"
                            [textContent]="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .displayText
                            "
                          ></label>
                          <div
                            class=""
                            *ngIf="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer === 'True' ||
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer === true
                            "
                            style="padding-left: 5px"
                          >
                            Yes
                          </div>
                          <div
                            class=""
                            *ngIf="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer !== 'True' &&
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer !== true
                            "
                            style="padding-left: 5px"
                          >
                            No
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group" *ngIf="enableOccupiedAs">
                        <drop-down-list-filter-question
                          [question]="proposal!.qsMaster.qs_fire_occupied!"
                          [onValueChange]="qs_fire_occupied_onValueChange"
                        >
                        </drop-down-list-filter-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6" *ngIf="isWPBProduct">
                    <form role="form">
                      <div class="form-group">
                        <text-area-question
                          [question]="
                            proposal!.qsMaster.qs_fire_grossFloorArea!
                          "
                        >
                        </text-area-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-3" *ngIf="isCMBProduct">
                    <form role="form">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_risk_occupancy!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-3" *ngIf="isCMBProduct">
                    <form role="form">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_risk_construction!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_fire_loanAmount!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-3">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_fire_replacement!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-3">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_fire_currency!"
                          [placeholder]="'Eg: HKD'"
                          answer="HKD"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-3" *ngIf="isCMBProduct">
                    <form role="form">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_fire_grossFloorArea!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div
                    class="col-md-6"
                    [hidden]="
                      !(
                        proposal.qsMaster.qs_fire_mcst!.isVisible &&
                        ibgMACDisabled
                      )
                    "
                  >
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_fire_mcst!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-12" *ngIf="isCMBProduct">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <div class="">
                          <radio-button-v1-question
                            [question]="
                              proposal!.qsMaster.qs_fire_coverageValueType!
                            "
                            (change)="
                              qs_fire_coverageValueType_OnChange(
                                proposal!.qsMaster.qs_fire_coverageValueType!
                              )
                            "
                          >
                          </radio-button-v1-question>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="table table-striped table-bordered table-condensed marginTop10"
                    >
                      <thead>
                        <tr class="chubbGray_bg chubbWhite">
                          <th style="width: 25px">No</th>
                          <th>Item Coverage Description</th>
                          <th class="textAlignCenter" style="width: 250px">
                            Sum Insured
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            {{ msg_building }}
                          </td>
                          <td
                            class="textAlignCenter"
                            [ngClass]="{
                              chubbGreen_bg: isBuildingSIModified
                            }"
                          >
                            <currency-box-conditional-question
                              [question]="proposal!.qsMaster.qs_fire_building!"
                              [condition]="currency1HDBDisabled"
                            ></currency-box-conditional-question>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </kendo-expansionpanel>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 card card-mod cardNoBorder ng-scope">
        <kendo-expansionpanel
          id="broker_panel"
          title="Other Information"
          [expanded]="true"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      attr.for="{{
                        proposal!.qsMaster.qs_master_insuredName!.key
                      }} "
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Other Information'"
                    ></label>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_MortgLoanAcctNum1!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_MortgLoanAcctNum2!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_MortgLoanAcctNum3!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_master_premiumPayment!"
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form " *ngIf="isWPBProduct">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_otherinfo_VersionDate!"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
                <form role="form " *ngIf="isCMBProduct">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_InsuranceCompany!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_MortgLoanRecEntryDate!
                      "
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_RepayAcctType!
                      "
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_otherinfo_RepayAcct!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_RepayAcctName!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <checkbox-question
                      [question]="proposal!.qsMaster.qs_otherinfo_AutoIssue!"
                    >
                    </checkbox-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_PreGeneratePolicyNo!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_InsuranceOption!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_CurrentLoanValue!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_ProptyReinstaCost!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_ProptyReinstaCostDate!
                      "
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6" *ngIf="isWPBProduct">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_otherinfo_InsuranceCompany!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </kendo-expansionpanel>
      </div>
    </div>
  </div>
</div>
