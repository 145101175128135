import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Day } from "@progress/kendo-date-math";
import { PresetExportService } from "src/app/services/preset-export.service";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { CitiWeeklyInvoiceSearchCriteria } from "./citi-weekly-invoice.model";

@Component({
  selector: "app-citi-weekly-invoice",
  templateUrl: "./citi-weekly-invoice.component.html",
})
export class CitiWeeklyInvoiceComponent implements OnInit {
  @Output() warningZoneEvent = new EventEmitter<any>();
  public transactionTypeOption!: Array<{ text: string; value: number }>;
  public disabledDates: Day[] = [
    Day.Saturday,
    Day.Sunday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
  ];
  public searchCriteria = new CitiWeeklyInvoiceSearchCriteria();
  constructor(private presetExportService: PresetExportService) {}
  ngOnInit(): void {
    this.defaultSearchCriteria();
  }
  private defaultSearchCriteria(): void {
    // default to first option
    this.transactionTypeOption = [
      { text: "New Business", value: 1 },
      { text: "Endorsement", value: 2 },
      { text: "Cancellation", value: 3 },
      { text: "Renewal", value: 4 },
      { text: "Loan Redemption", value: 5 },
    ];
  }
  private validateCitiMonthlyMandatoryFields(): boolean {
    let isValid: boolean = false;
    if (
      this.searchCriteria.transactionType !== null &&
      this.searchCriteria.transactionType !== 0 &&
      this.searchCriteria.ADWeek !== null
    ) {
      isValid = true;
    }
    return isValid;
  }

  public on_SummaryAutoDebitWeekChange(val: any): void {}
  public download(): void {
    if (this.validateCitiMonthlyMandatoryFields()) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.downloadCitiWeeklyInvoiceReport();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select both AD Week & Transaction Type",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private downloadCitiWeeklyInvoiceReport() {
    this.presetExportService.citiWeeklyInvoiceReport(
      this.searchCriteria.ADWeek,
      this.searchCriteria.transactionType
    );
  }
}
