import * as EnumHK from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-enums";
import * as Enums from "./../../../../../../../framework/enum.shared";
import * as InterfaceProduct from "src/app/framework/interface.product";
import { Component } from "@angular/core";
import { GUID } from "src/app/framework/domain-entity/guid";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { ProposalAction } from "./../../../../../../../models/new-product/base/proposal-action.model";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { common } from "src/app/framework/utils/common";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { ProposalAvailableActionHelperService } from "src/app/services/new-product/base/utils/proposal-available-action-helper.service";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ClientEntity } from "src/app/models/customer/base/client-entity.model";
import { Echo } from "src/app/models/new-product/base/echo.model";
import { ClientService } from "src/app/services/customer/client.service";
import { environment } from "src/environments/environment";
import { buttonNotifier } from "src/app/components/shared/notifier/button-notifier";
import { Router } from "@angular/router";
import { ROUTE_HOME } from "src/app/app.module";
import {
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { MdProductSelectionDialogComponent } from "src/app/components/shared/partials/md-product-selection-dialog/md-product-selection-dialog.component";
import { MfContactUsFormComponent } from "src/app/components/shared/products/mf-contact-us-form/mf-contact-us-form.component";
import { MessageForumComponent } from "src/app/components/product/messageBoard/message-forum/message-forum.component";
import { MdConfirmationMessageWithReasonComponent } from "src/app/components/shared/partials/md-confirmation-message-with-reason/md-confirmation-message-with-reason.component";
import { MdConfirmationMessageV2Component } from "src/app/components/shared/partials/md-confirmation-message-v2/md-confirmation-message-v2.component";
import { MdConfirmationMessageV4Component } from "src/app/components/shared/partials/md-confirmation-message-v4/md-confirmation-message-v4.component";
import { AbstractProductFactory } from "src/app/models/new-product/abstract-factory/factory/abstract-product-factory";
import { ProductService } from "src/app/services/new-product/base/product.service";
import { MfHkBaseComponent } from "../mf-hk-base.component";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { httpNotifier } from "src/app/interceptor/http-notifier";
import { Subscription } from "rxjs";
import { cloneDeep } from "lodash";

@Component({
  selector: "mf-hk-button-panel",
  templateUrl: "./mf-hk-button-panel.component.html",
  styleUrls: ["./mf-hk-button-panel.component.scss"],
})
export class MfHkButtonPanelComponent extends MfHkBaseComponent {
  pageLoaded: boolean = false;
  proposal!: MortgageFireHongKongProposal;
  actions!: ProposalAction;
  showMessageBoard: boolean = false;
  private _defaultGuid: GUID = new GUID("00000000-0000-0000-0000-000000000000");
  isIncomplete: boolean = false;
  isReferred: boolean = false;
  isGeneralOrSIEndorsed: boolean = false;
  isWpbProduct: boolean = false;
  isCITIProduct: boolean = false;
  hideActionsCommandsByIBG: boolean = false;
  isLapsed: boolean = false;
  isRefUnderwriter: boolean = false;
  isWpbReferred: boolean = false; //added to check Wpb referred case
  showCorrespondencebtn: boolean = false;
  // added as a part of Renewal Assessments
  showRenewalAssessment: boolean = false;
  renewalAssessmentMessage: string = "";
  showRenewalFailure: boolean = false;
  hideAllEndorsementsForRenewedCMBWPB: boolean = false;
  hideActionsCommandsForCSCBGSG: boolean = false;
  hideActionsCommandsForDBSUsers: boolean = false;
  isTransactionExpired: boolean = false;
  isTransactionExpiredForRenewal: boolean = false;
  //2022-08-22 dagoh0: APACDM-363 - flag added for CBG product change of plan type
  isUpgradable: boolean = false;
  private msg_exitWarning: string =
    "Are you sure you wish to return to the proposal listing screen? Your changes will not be saved.";
  private msg_sendToCheckerWarning: string =
    "Are you sure you wish to submit the proposal for the checker to review?";
  private msg_approveWarning: string =
    "Are you sure you want to approve this submission?";
  private msg_IBG_approveWarning: string =
    "Referral transaction will be approved with this action, Do you wish to proceed?";
  private msg_declineWarning: string =
    "Are you sure you wish to decline this submission?";
  private msg_IBG_declineWarning: string =
    "Referral transaction will be declined with this action, Do you wish to proceed?";
  private msg_renewWarning: string =
    "Are you sure you wish to renew this submission?";
  private msg_redundantWarning: string =
    "Please confirm if you wish to delete / abandon the current transaction. Click OK to proceed, else please click Cancel.";
  private msg_returnToReviseWarning: string =
    "Are you sure you wish to return this submission to maker for revise?" +
    " If yes, please provide reason.";
  private msg_checkedWarning: string =
    "Are you sure you wish to mark this submission as checked?";
  private msg_ibgRefUnderwriterWarning: string =
    "Transaction will be referred to UW for review and aprroval , Do you wish to proceed?";
  private msg_bindPolicyWarning: string =
    "Are you sure you wish to bind this Policy?";
  private msg_referPolicyWarning: string =
    "Are you sure you wish to refer this Policy?";
  private msg_bindCBGPolicyWarning: string =
    "Are you sure you wish to bind this Policy with Gross Premium or GST 0 value?";
  private msg_endorseWarning: string =
    "Are you sure you wish to Endorse this Policy?";
  private msg_loanRedemptionWarning: string =
    "Are you sure you wish to Fully Redeem this Policy?";
  private msg_cancelWarning: string =
    "Are you sure you wish to cancel this Policy?";
  private msg_copyWarning: string =
    "Are you sure you wish to Copy this Proposal?";
  private msg_editWarning: string =
    "Are you sure you wish to Edit this Policy?";
  private msg_cancellationAdjustmentWarning: string =
    "Are you sure you wish to perform Cancellation Adjustment?";
  private msg_changeOfPOIWarning: string =
    "Are you sure you wish to raise Change Of POI endorsement for this Policy?";
  private msg_changeOfSIWarning: string =
    "Are you sure you wish to raise Change Of Sum Insured endorsement for this Policy?";
  private msg_changeOfCorrAddressWarning: string =
    "Are you sure you wish to raise Change Of Correspondence Address for this Policy?";
  private msg_changeOfClientInfo: string =
    "Are you sure you wish to raise Change Of Client Information for this Policy?";
  private msg_changeOfAdditionalInsuredWarning: string =
    "Are you sure you wish to raise Change Of Additional Insured Details endorsement for this Policy?";
  private clickedButtonId!: number;
  private msg_warningMessageForLST: string =
    "Cannot Bind Policy. <br> Sum Insured is greater than the allowed limit.";
  private msg_preferenceDelivery: string =
    "Are you sure you wish to raise Change Of Preference Delivery for this Policy?";
  //2022-08-24 dagoh0: APACDM-363 - Change of Plan Type Message
  private msg_changeOfPlanType: string =
    "Are you sure you wish to change the policy's plan type?";
  private msg_editapprovedquotation: string =
    "Are you sure you wish to edit the quotation?";
  productFactory!: AbstractProductFactory;

  protected bindPolicy: string = "Bind Policy";
  //HES-64- To Prevent multiple Button click issue
  public httpRequesting: boolean = false;
  private request_notifier_subscription: Subscription = new Subscription();
  private request_error_notifier_subscription: Subscription =
    new Subscription();
  private response_notifier_subscription: Subscription = new Subscription();
  private response_error_notifier_subscription: Subscription =
    new Subscription();
  private start_notifier_subscription: Subscription = new Subscription();
  private end_notifier_subscription: Subscription = new Subscription();

  constructor(
    private proposalService: MfHKProposalService,
    public identityRolesService: IdentityRolesService,
    private clientService: ClientService,
    private proposalAvailableActionHelper: ProposalAvailableActionHelperService,
    private router: Router,
    private cdkDialogService: CdkDialogService,
    public productService: ProductService
  ) {
    super();
  }

  ngOnDestroy() {
    super.unsubscribeRxjs();
  }

  override ngOnInit(): void {
    this.initialize();
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
    super.subscribePageLoadedNotifier((event) => {
      this.proposal = event.value as MortgageFireHongKongProposal;
      this.isWpbProduct =
        this.proposal.productType === EnumHK.MortgageFireHKProduct.HSWPB
          ? true
          : false;
      this.isCITIProduct = false;
      // this.proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB ||
      // this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
      // this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
      //   ? true
      //   : false;
      /*message board check*/
      this.showMessageBoard = this.proposal!.proposalId!.isDefault
        ? false
        : true;
      this.isIncomplete =
        this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete
          ? true
          : false;
      this.isReferred =
        this.proposal.proposalStatus === Enums.ProposalStatus.Referred
          ? true
          : false;
      this.isWpbReferred =
        this.proposal.proposalStatus === Enums.ProposalStatus.Referred
          ? true
          : false;
      this.pageLoaded = true;
      this.showCorrespondencebtn = this.getcorrespondenceflag();
      super.subscribeAnswerChangedNotifier(this.answerChanged);
      //2022-08-22 dagoh0: APACDM-363 - determine to show upgrade or downgrade plan type button for CBG product only
      //There will be scenario that CBG product qs_master_planType might be null or empty
      this.isUpgradable = this.CanUpgrade();
    });
    //HES-64- To Prevent multiple Button click issue
    this.request_notifier_subscription =
      httpNotifier.request_notifier$.subscribe(this.onHttpRequestStart);
    this.request_error_notifier_subscription =
      httpNotifier.response_error_notifier$.subscribe(this.onHttpRequestEnd);
    this.response_notifier_subscription =
      httpNotifier.response_notifier$.subscribe(this.onHttpRequestEnd);
    this.response_error_notifier_subscription =
      httpNotifier.response_error_notifier$.subscribe(this.onHttpRequestEnd);
  }
  //HES-64- To Prevent multiple Button click issue
  public onHttpRequestStart = (e: KeyValue<any>) => {
    this.httpRequesting = true;
  };

  public onHttpRequestEnd = (e: KeyValue<any>) => {
    setTimeout(() => {
      this.httpRequesting = false;
    }, 50);
  };

  //#region abstract factory
  CanChecked() {
    return false;
    // return this.productFactory
    //   .buttonPanel()
    //   .CanChecked(this.identityRolesService, this.proposal);
  }

  CanBindPolicy() {
    return this.productFactory
      .buttonPanel()
      .CanBindPolicy(this.identityRolesService, this.hideActionsCommandsByIBG);
  }

  CanEditQuotation() {
    const existingLogic =
      (this.identityRolesService.hasProductTransactRoles &&
        !this.hideActionsCommandsByIBG) ||
      (this.identityRolesService.IsCbsOps && this.hideActionsCommandsByIBG);

    return this.productFactory
      .buttonPanel()
      .CanEditQuotation(
        this.actions.EditQuotation.visible,
        existingLogic,
        this.identityRolesService,
        this.proposal,
        this.proposalService
      );
  }

  CanSave() {
    const existingLogic =
      (this.identityRolesService.hasProductTransactRoles &&
        !this.hideActionsCommandsByIBG) ||
      this.identityRolesService.IsCbsOps ||
      this.hideActionsCommandsForCSCBGSG;

    return this.productFactory
      .buttonPanel()
      .CanSave(
        this.actions.save.visible,
        existingLogic,
        this.identityRolesService,
        this.proposal,
        this.proposalService
      );
  }

  CanApprove() {
    let isIBGProduct = false; // this is migrated from existing; if removed might break CMB
    let isIBGReferred =
      this.proposal.proposalStatus === Enums.ProposalStatus.Referred
        ? true
        : false;
    const existingLogic =
      isIBGProduct &&
      !this.identityRolesService.isOperationUnderwriter &&
      this.proposal.isUnderwriter &&
      isIBGReferred;

    return this.productFactory
      .buttonPanel()
      .CanApprove(
        this.actions.approve.visible,
        existingLogic,
        this.identityRolesService,
        this.proposal,
        this.proposalService
      );
  }

  CanEdit() {
    const existingLogic =
      (this.proposal.proposalStatus != Enums.ProposalStatus.Renewed &&
        this.identityRolesService.hasProductTransactRoles &&
        !this.hideActionsCommandsByIBG) ||
      (this.identityRolesService.IsCbsOps && this.hideActionsCommandsByIBG);

    return this.productFactory
      .buttonPanel()
      .CanEdit(
        this.actions.endorsement.visible,
        existingLogic,
        this.identityRolesService,
        this.proposal,
        this.proposalService
      );
  }

  CanReferToUnderwriter() {
    return this.productFactory
      .buttonPanel()
      .CanReferToUnderwriter(
        this.actions.submit.visible,
        this.identityRolesService,
        this.proposal,
        this.proposalService
      );
  }

  CanSubmit() {
    return this.productFactory
      .buttonPanel()
      .CanSubmit(
        this.actions.submit.visible,
        this.identityRolesService,
        this.proposal,
        this.proposalService
      );
  }
  //#endregion

  //#region migrate from old angularjs
  monthDiff(
    d1: { getFullYear: () => number; getMonth: () => number },
    d2: Date
  ) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
  }

  getcorrespondenceflag = (): boolean => {
    const currentPOI = new Date(new Date().setHours(0, 0, 0, 0));
    var effectivedate =
      this.proposal!.qsMaster.qs_master_effectiveDate!.answer! != null
        ? this.proposal!.qsMaster.qs_master_effectiveDate!.answer!
        : currentPOI!;
    var currentDate = new Date();
    var month = this.monthDiff(effectivedate, currentDate);
    if (
      this.identityRolesService.isOperationUnderwriter &&
      this.proposal.openItems != undefined &&
      this.proposal.openItems.length > 0 &&
      month >= -6 &&
      month <= 6
    ) {
      if (
        this.proposal.openItems[0].Status == 4 ||
        this.proposal.openItems[0].Status == 13
      ) {
        if (
          this.proposal.transType == 3 &&
          this.proposal.proposalStatus == Enums.ProposalStatus.Accepted &&
          this.proposal.pendingTransaction == null
        ) {
          return true;
        }
      }
    }
    return false;
  };

  private answerChanged = (kv: KeyValue<QuestionEntity>) => {
    const key = kv.key;
    const question = kv.value;
    switch (key) {
      case this.proposal!.qsMaster.qs_master_effectiveDate!.key:
        this.qs_master_effectiveDate_onChange();
        break;
    }
  };

  initialize() {
    this.actions = new ProposalAction();
    this.hideAllButton();
  }

  private qs_master_effectiveDate_onChange = (): void => {
    if (common.isDefined(this.proposal.proposalStatus)) {
      if (
        (this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete ||
          this.proposal.proposalStatus === Enums.ProposalStatus.Referred) &&
        ((this.proposal.transType === Enums.TransType.END &&
          this.proposal.endorsementType ===
            Enums.EndorsementType.LoanRedemption) ||
          this.proposal.transType === Enums.TransType.CAN)
      ) {
        const actionsAllowed =
          this.proposalAvailableActionHelper.getAvailableActions(
            this.proposalService.proposalEntity
          );
        this.hideAllButton();
        this.setButtonVisibility(actionsAllowed);
      }
    }
  };

  private hideAllButton() {
    this.actions.save.visible = false;
    this.actions.submit.visible = false;
    this.actions.sendToChecker.visible = false;
    this.actions.returnForRevise.visible = false;
    this.actions.recalculate.visible = false;
    this.actions.approve.visible = false;
    this.actions.accept.visible = false;
    this.actions.renew.visible = false;
    this.actions.decline.visible = false;
    this.actions.makeRedundant.visible = false;
    this.actions.unsuccessful.visible = false;
    this.actions.endorsement.visible = false;
    this.actions.cancel.visible = false;
    this.actions.createOrEdit.visible = false;
    this.actions.cancellationAdjustment.visible = false;
    this.actions.EditQuotation.visible = false;
  }

  private showBindPolicyButtonForBroker() {
    if (
      this.proposal.proposalId &&
      this.proposal.proposalId.toString() !==
        "00000000-0000-0000-0000-000000000000"
    ) {
      if (this.proposal.transType === Enums.TransType.NEW) {
        return true;
      } else {
        var answer =
          this.proposal.qsMaster.qs_master_endorse_endorsementReason!.answer;
        if (
          answer != undefined &&
          answer != null &&
          answer !== "" &&
          (this.clickedButtonId == 2 || this.clickedButtonId == 6)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  private showBindPolicyButtonForBrokerRen() {
    if (this.clickedButtonId == 2) {
      if (this.proposal.transType === Enums.TransType.REN) {
        return true;
      }
    }
    return false;
  }

  private showBindPolicyButtonForApproved() {
    var answer =
      this.proposal.qsMaster.qs_master_endorse_endorsementReason!.answer;
    if (
      answer != undefined &&
      answer != null &&
      answer !== "" &&
      (this.proposal.transType === Enums.TransType.END ||
        this.proposal.transType === Enums.TransType.CAN) &&
      (this.proposal.proposalStatus === Enums.ProposalStatus.Approved ||
        this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private setButtonVisibility(actionsAllowed: Enums.ProposalCommandAction[]) {
    const poiStartDate = new Date(
      this.proposal!.qsMaster.qs_master_poi_startDate!.readAnswerAsString()!
    );
    const currentPOI = new Date(new Date().setHours(0, 0, 0, 0));

    const endorsementEffectiveDate = new Date(
      this.proposal!.qsMaster.qs_master_effectiveDate!.answer! != null
        ? this.proposal!.qsMaster.qs_master_effectiveDate!.readAnswerAsString()!
        : currentPOI.toString()!
    );
    let expectedMinimumLoanRedemptionDate = endorsementEffectiveDate;
    const minimumLoanRedemptionDate = new Date(
      currentPOI.setDate(currentPOI.getDate() - 14)
    );
    this.showCorrespondencebtn = this.getcorrespondenceflag();
    if (endorsementEffectiveDate < minimumLoanRedemptionDate) {
      expectedMinimumLoanRedemptionDate = minimumLoanRedemptionDate;
    }
    actionsAllowed.forEach((action) => {
      switch (action) {
        case Enums.ProposalCommandAction.Save:
          this.actions.save.visible = true;
          // if the proposal is Cancellation Adjustment
          if (this.proposal.IsCancellationAdjustment) {
            this.actions.save.visible = this.proposal.isUnderwriter;
          }
          break;
        case Enums.ProposalCommandAction.Submit:
          if (this.showBindPolicyButtonForBroker()) {
            this.actions.submit.visible = true;
          }
          if (this.showBindPolicyButtonForBrokerRen()) {
            this.actions.submit.visible = true;
          }
          if (this.showBindPolicyButtonForApproved()) {
            this.actions.submit.visible = true;
          }
          if (this.proposal.IsCancellationAdjustment) {
            this.actions.submit.visible = this.proposal.isUnderwriter;
          }
          break;
        case Enums.ProposalCommandAction.SendToChecker:
          this.actions.sendToChecker.visible = true;
          if (this.proposal.IsCancellationAdjustment) {
            this.actions.sendToChecker.visible = this.proposal.isUnderwriter;
          }
          break;
        case Enums.ProposalCommandAction.ReturnForRevise:
          this.actions.returnForRevise.visible = true;
          if (this.proposal.IsCancellationAdjustment) {
            this.actions.returnForRevise.visible = this.proposal.isUnderwriter;
          }
          break;
        case Enums.ProposalCommandAction.Approve:
          if (this.proposal.transType === Enums.TransType.NEW) {
            this.actions.approve.visible = true;
          }
          if (this.proposal.IsCancellationAdjustment) {
            this.actions.approve.visible = this.proposal.isUnderwriter;
          }
          break;
        case Enums.ProposalCommandAction.Accept:
          this.actions.accept.visible = true;
          if (this.proposal.IsCancellationAdjustment) {
            this.actions.accept.visible = this.proposal.isUnderwriter;
          }
          break;
        case Enums.ProposalCommandAction.Renew:
          this.actions.renew.visible = true;
          break;
        case Enums.ProposalCommandAction.Decline:
          this.actions.decline.visible = true;
          break;
        case Enums.ProposalCommandAction.MakeRedundant:
          this.actions.makeRedundant.visible = true;
          break;
        case Enums.ProposalCommandAction.Unsuccessful:
          this.actions.unsuccessful.visible = true;
          break;
        case Enums.ProposalCommandAction.Exit:
          this.actions.exit.visible = true;
          break;
        case Enums.ProposalCommandAction.Endorse:
          this.actions.endorsement.visible = true;
          break;
        case Enums.ProposalCommandAction.Cancel:
          this.actions.cancel.visible = true;
          break;
        case Enums.ProposalCommandAction.Copy:
          this.actions.copy.visible = true;
          break;
        case Enums.ProposalCommandAction.EditDeclinedProposal:
          this.actions.createOrEdit.visible = true;
          break;
        case Enums.ProposalCommandAction.CancellationAdjustment:
          this.actions.cancellationAdjustment.visible =
            this.proposal.isUnderwriter;
          break;
        case Enums.ProposalCommandAction.EditQuotation:
          this.actions.EditQuotation.visible = true;
          break;
        default:
      }
    });
  }

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireHongKongProposal;

    this.productFactory = this.productService.getHKProductFactory(
      this.proposal.productType
    );

    this.isWpbProduct =
      this.proposal.productType === EnumHK.MortgageFireHKProduct.HSWPB
        ? true
        : false;
    this.isCITIProduct = false;
    const actionsAllowed =
      this.proposalAvailableActionHelper.getAvailableActions(
        this.proposalService.proposalEntity
      );
    this.hideAllButton();
    this.setButtonVisibility(actionsAllowed);

    //HE-28
    if (this.isWpbProduct && this.identityRolesService.isUnderwriter) {
      this.setSubmitButtonVisibilityForWpb();
    }

    if (this.identityRolesService.IsCSCBGSG && !this.isCITIProduct) {
      this.setBttnVisibilityForCSCBGSG();
    }
    this.pageLoaded = true;
    this.showMessageBoard = this.proposal!.proposalId!.isDefault ? false : true;
    this.hideActionsCommandsByIBG =
      (false && this.proposalService.proposal.isBroker) ||
      this.proposal.proposalStatus === Enums.ProposalStatus.Lapsed
        ? true
        : false;
    this.checkEndorsementHistory();
    this.hideAllEndorsementsForRenewedCMBWPB =
      this.proposal.proposalStatus == Enums.ProposalStatus.Renewed
        ? true
        : false;
    this.isLapsed =
      this.proposal.proposalStatus === Enums.ProposalStatus.Lapsed
        ? true
        : false;
    //CS CBG SG only limit to CBG and endorsementType = ChangeOfClientInfo
    this.hideActionsCommandsForCSCBGSG = this.identityRolesService.IsCSCBGSG
      ? true
      : false;
    //Hide change of POI from DBS users
    this.hideActionsCommandsForDBSUsers = this.identityRolesService.isBroker
      ? true
      : false;
    if (this.hideActionsCommandsForDBSUsers) {
      this.setBttnVisibilityForDBSUsers();
    }
    //2022-08-22 dagoh0: APACDM-363 - determine to show upgrade or downgrade plan type button for CBG product only
    this.isUpgradable = this.CanUpgrade();

    this.isTransactionExpired = this.CheckIfExpired();
    this.isTransactionExpiredForRenewal = this.CheckIfExpiredForRenewal();

    this.setBindPolicyWording();

    super.subscribeAnswerChangedNotifier(this.answerChanged);
  };

  private setBindPolicyWording() {
    if (
      this.proposal.transType === Enums.TransType.END ||
      this.proposal.transType === Enums.TransType.CAN
    ) {
      this.bindPolicy = "Confirm";
    } else {
      this.bindPolicy = "Bind Policy";
    }
  }

  private CanUpgrade(): boolean {
    let isUpgradable = false;
    //follow DataMember in : ~\Baseline\Chubb.ePolicy.Framework\Library\Common\Entities\ePolicy\Model\AuxiliaryModel.cs
    //small letter 'a', not capital 'A'
    if (common.isDefined(this.proposal.auxiliary)) {
      isUpgradable = this.proposal.auxiliary["allowUpgrade"];
    } else {
      console.log("this.proposal.auxiliary['allowUpgrade'] is not Defined");
    }
    return isUpgradable;
  }

  public CheckIfExpired() {
    const expirydate = this.proposal.qsMaster.qs_master_poi_endDate?.answer;
    const currentDate = new Date().setHours(0, 0, 0, 0);

    if (currentDate > expirydate) return true;
    else return false;
  }

  public CheckIfExpiredForRenewal() {
    if (
      (this.proposal.transType === Enums.TransType.NEW ||
        this.proposal.transType === Enums.TransType.END ||
        this.proposal.transType === Enums.TransType.REN) &&
      this.proposal.proposalStatus === Enums.ProposalStatus.Accepted &&
      !this.proposal.auxiliary["IsPreviousYearTerminated"] &&
      this.proposal.auxiliary["allowRenewal"]
    )
      return true;
    else return false;
  }

  private setCancellationAdjustmentReferralRule() {
    this.isRefUnderwriter = true;
    this.actions.submit.visible = false;
    this.proposal.referralMessageRequires = true;
    this.proposal.auxiliary["DisplayCancellationAdjustment"] = false;
    this.proposalService.businessRulesManager.validateOperationUnderwriterCancellationAdj(
      false
    );
  }
  private MakeRedundantRuleReferralRule() {
    this.isRefUnderwriter = true;
    this.actions.submit.visible = false;
    this.proposal.referralMessageRequires = true;
    this.actions.save.visible = false;
    this.proposalService.businessRulesManager.validateOperationUnderwriterMakeRedundant();
  }

  //HE-28
  // isRefUnderwriter - Flag to identify whether this is referral case for operator Underwriter user or not.
  private setSubmitButtonVisibilityForWpb() {
    // if (this.identityRolesService.isOperationUnderwriter) {
    //   if (
    //     common.isDefined(this.proposalService.businessRulesManager.echos) &&
    //     this.proposalService.businessRulesManager.echos.length > 0
    //   ) {
    //     this.isRefUnderwriter = true;
    //     //this.actions.submit.visible = !this.isRefUnderwriter;
    //     this.proposal.referralMessageRequires = true;
    //   } else {
    //     this.isRefUnderwriter = false;
    //     //this.actions.submit.visible = !this.isRefUnderwriter;
    //   }
    // }
    switch (this.proposal.proposalStatus) {
      case Enums.ProposalStatus.Accepted:
      case Enums.ProposalStatus.Declined:
      case Enums.ProposalStatus.Renewed:
      case Enums.ProposalStatus.Endorsed:
        //if (this.identityRolesService.isOperationUnderwriter) {
        //this.actions.submit.visible = false;
        this.isRefUnderwriter = false;
        this.proposal.referralMessageRequires = false;
        this.isWpbReferred = false;
        //}
        break;
      case Enums.ProposalStatus.Referred:
        this.isRefUnderwriter = false;
        this.isWpbReferred = true;
        //actions related, do at API or AbstractButtonPanel
        // this.actions.accept.visible = false;
        // this.actions.approve.visible = true;
        // this.actions.submit.visible = false;
        // this.actions.save.visible = false;
        break;
      case Enums.ProposalStatus.Cancelled:
      case Enums.ProposalStatus.Lapsed:
        this.isWpbReferred = false;
        //this.actions.submit.visible = false;
        break;
      case Enums.ProposalStatus.Approved:
        this.isRefUnderwriter = false;
        // this.actions.submit.visible = false;
        // if (
        //   this.proposal.transType === Enums.TransType.REN &&
        //   this.proposal.endorsementType === Enums.EndorsementType.MakeRedundant
        // ) {
        //   this.actions.accept.visible = false;
        // } else {
        //   this.actions.accept.visible = true;
        // }
        break;
      case Enums.ProposalStatus.Incomplete:
        this.isWpbReferred = false;
        break;
    }
  }

  //Only Show button if is incomplete and endorsementType = ChangeOfClientInfo
  private setBttnVisibilityForCSCBGSG() {
    if (
      this.identityRolesService.IsCSCBGSG &&
      this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete &&
      this.proposal.endorsementType === Enums.EndorsementType.ChangeOfClientInfo
    ) {
      this.actions.submit.visible = true;
      this.actions.save.visible = true;
      this.actions.makeRedundant.visible = true;
    } else {
      this.actions.submit.visible = false;
      this.actions.save.visible = false;
      this.actions.makeRedundant.visible = false;
    }
  }

  private setBttnVisibilityForDBSUsers() {
    if (
      this.hideActionsCommandsForDBSUsers &&
      this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete &&
      this.proposal.endorsementType === Enums.EndorsementType.ChangeOfPOI
    ) {
      this.actions.submit.visible = false;
      this.actions.save.visible = false;
      this.actions.makeRedundant.visible = false;
      this.actions.sendToChecker.visible = false;
    }
  }

  private checkEndorsementHistory = () => {
    /** Check the endorsementHistoryModel if any General/ Change Of SI Endorsement has been performed for the proposal
     *  If yes, Hide the change of POI button
     */
    // reset the flag
    this.isGeneralOrSIEndorsed = false;
    if (
      common.isDefined(this.proposal.refreshedProposalEntity.endorsementHistory)
    ) {
      const endorsementHistoryDetails =
        this.proposal!.refreshedProposalEntity.endorsementHistory!.filter(
          function (obj) {
            return (
              obj.endorsementType === "GeneralEndorsement" ||
              obj.endorsementType === "ChangeOfSumInsured"
            );
          }
        );
      if (endorsementHistoryDetails.length > 0) {
        this.isGeneralOrSIEndorsed = true;
      }
    }
  };

  private proposalSaved = (event: KeyValue<Proposal>) => {
    const actionsAllowed =
      this.proposalAvailableActionHelper.getAvailableActions(
        this.proposalService.proposalEntity
      );
    this.isWpbProduct =
      this.proposal.productType === EnumHK.MortgageFireHKProduct.HSWPB
        ? true
        : false;
    this.isCITIProduct = false;
    // this.proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB ||
    // this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
    // this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
    //   ? true
    //   : false;
    this.hideAllButton();
    this.setButtonVisibility(actionsAllowed);
    //  CbsOps has access only to the ChangeOfClientInfo & ChangeOfCorrespondenceAddress
    if (this.identityRolesService.IsCbsOps) {
      const actionsAllowedforops =
        this.proposalAvailableActionHelper.getAvailableActionsforCbsOps(
          this.proposalService.proposalEntity
        );
      this.setButtonVisibility(actionsAllowedforops);
    }
    //CS CBG SG user has access only to ChangeOfClientInfo
    if (this.identityRolesService.IsCSCBGSG) {
      const actionsAllowedForCSCBGSG =
        this.proposalAvailableActionHelper.getAvailableActionforCSCBGSG(
          this.proposalService.proposalEntity
        );
      this.setButtonVisibility(actionsAllowedForCSCBGSG);
    }
    //HE-28
    if (this.isWpbProduct && this.identityRolesService.isUnderwriter) {
      this.setSubmitButtonVisibilityForWpb();
    }
    this.showMessageBoard = this.proposal!.proposalId!.isDefault ? false : true;
    this.hideActionsCommandsByIBG =
      (false && this.proposalService.proposal.isBroker) ||
      this.proposal.proposalStatus === Enums.ProposalStatus.Lapsed
        ? true
        : false;
    this.checkEndorsementHistory();
    this.isLapsed =
      this.proposal.proposalStatus === Enums.ProposalStatus.Lapsed
        ? true
        : false;
    //2022-08-22 dagoh0: APACDM-363 - determine to show upgrade or downgrade plan type button for CBG product only
    this.isUpgradable = this.CanUpgrade();
    this.isTransactionExpired = this.CheckIfExpired();
    this.isTransactionExpiredForRenewal = this.CheckIfExpiredForRenewal();
    this.setBindPolicyWording();
  };

  //     /** adding Endorsement type optional parameter
  //      * EndorsementType 1: General Endorsement
  //      * EndorsementType 16: Loan Redemption
  //      * EndorsementType 17: Change Of POI
  //     */
  click(el: any) {
    let command = Number(el.getAttribute("data-command-id"));
    let endorsementType = Number(el.getAttribute("data-endorsement-id"));
    let additionalButtonType = Number(el.getAttribute("data-additional-id")); //differentiate bind & refer-to-uw, & quote & approve
    // this.clickedButtonId = parseInt(event.key, 10);//todo: need to implement ButtonDefaultComponent for this
    this.clickedButtonId = command; //todo: temp
    // const command = event.value.c;
    //const endorsementType = event.value.t;
    switch (+command) {
      case Enums.ProposalCommandAction.Save:
      case Enums.ProposalCommandAction.Recalculate:
        this.proposalService.action(command, this.clickedButtonId);
        break;
      case Enums.ProposalCommandAction.Exit:
      case Enums.ProposalCommandAction.SendToChecker:
      case Enums.ProposalCommandAction.ReturnForRevise:
      case Enums.ProposalCommandAction.Submit:
      case Enums.ProposalCommandAction.Approve:
      case Enums.ProposalCommandAction.Renew:
      case Enums.ProposalCommandAction.Decline:
      case Enums.ProposalCommandAction.MakeRedundant:
      case Enums.ProposalCommandAction.Accept:
      case Enums.ProposalCommandAction.Endorse:
      case Enums.ProposalCommandAction.Cancel:
      case Enums.ProposalCommandAction.Cancel:
      case Enums.ProposalCommandAction.Copy:
      case Enums.ProposalCommandAction.EditDeclinedProposal:
      case Enums.ProposalCommandAction.CancellationAdjustment:
      case Enums.ProposalCommandAction.ChangeOfCorrespondenceAddress:
      case Enums.ProposalCommandAction.EditQuotation:
        this.actionsConfirmationModal(
          command,
          endorsementType,
          additionalButtonType
        );
        break;

      default:
    }
  }

  getSubmitButNotReferMsg() {
    let msg: string = "";
    //DBSEP-2136
    if (this.proposal.transType) {
      if (this.proposal.transType === Enums.TransType.CAN) {
        msg = this.msg_cancelWarning;
      } else if (
        this.proposal.transType === Enums.TransType.NEW ||
        this.proposal.transType === Enums.TransType.REN
      ) {
        msg = this.msg_bindPolicyWarning;
      } else {
        msg = this.msg_endorseWarning;
      }
    } else {
      msg = this.msg_endorseWarning;
    }

    return msg;
  }

  getMessage(
    command: Enums.ProposalCommandAction,
    endorsementType: any,
    additionalButtonType: any
  ): string {
    let msg: string = "";
    switch (+command) {
      case Enums.ProposalCommandAction.Exit:
        msg = this.msg_exitWarning;
        break;
      case Enums.ProposalCommandAction.SendToChecker:
        msg = this.msg_sendToCheckerWarning;
        break;
      case Enums.ProposalCommandAction.ReturnForRevise:
        msg = this.msg_returnToReviseWarning;
        break;
      case Enums.ProposalCommandAction.Submit:
        if (additionalButtonType === 2) {
          msg = this.msg_referPolicyWarning; //HE-170: as long as refer-button clicked, show this msg
        } else {
          msg = this.getSubmitButNotReferMsg();
        }

        break;
      case Enums.ProposalCommandAction.Approve:
        msg = this.isWpbProduct
          ? this.msg_IBG_approveWarning
          : this.msg_approveWarning;
        if (
          this.identityRolesService.isUnderwriter &&
          !this.identityRolesService.isOperationUnderwriter &&
          this.isWpbProduct &&
          this.proposal.transType === Enums.TransType.REN &&
          this.proposal.proposalStatus === Enums.ProposalStatus.Referred
        ) {
          if (
            this.proposalService.proposal.endorsementType ===
            Enums.EndorsementType.MakeRedundant
          ) {
            command = Enums.ProposalCommandAction.MakeRedundant;
          }
        }
        break;
      case Enums.ProposalCommandAction.Renew:
        msg = this.msg_renewWarning;
        break;
      case Enums.ProposalCommandAction.Decline:
        msg = this.isWpbProduct
          ? this.msg_IBG_declineWarning
          : this.msg_declineWarning;
        break;
      case Enums.ProposalCommandAction.MakeRedundant:
        msg = this.msg_redundantWarning;
        break;
      case Enums.ProposalCommandAction.Accept:
        msg = this.msg_bindPolicyWarning;
        break;
      case Enums.ProposalCommandAction.Endorse:
        if (endorsementType === Enums.EndorsementType.LoanRedemption) {
          msg = this.msg_loanRedemptionWarning;
        } else if (endorsementType === Enums.EndorsementType.ChangeOfPOI) {
          msg = this.msg_changeOfPOIWarning;
        } else if (
          endorsementType === Enums.EndorsementType.ChangeOfSumInsured
        ) {
          msg = this.msg_changeOfSIWarning;
        } else if (
          endorsementType ===
          Enums.EndorsementType.ChangeOfCorrespondenceAddress
        ) {
          msg = this.msg_changeOfCorrAddressWarning;
        } else if (
          endorsementType === Enums.EndorsementType.ChangeOfAdditionalInsured
        ) {
          msg = this.msg_changeOfAdditionalInsuredWarning;
        } else if (
          endorsementType === Enums.EndorsementType.ChangeOfClientInfo
        ) {
          msg = this.msg_changeOfClientInfo;
          //2022-08-24 dagoh0: APACDM-363 - Handle Change of Plan Type confirmation message
        } else if (endorsementType === Enums.EndorsementType.ChangeOfPlanType) {
          msg = this.msg_changeOfPlanType;
        } else {
          msg = this.msg_endorseWarning;
        }
        break;
      case Enums.ProposalCommandAction.Cancel:
        msg = this.msg_cancelWarning;
        break;
      case Enums.ProposalCommandAction.Copy:
        msg = this.msg_copyWarning;
        break;
      case Enums.ProposalCommandAction.EditDeclinedProposal:
        msg = this.msg_editWarning;
        break;
      case Enums.ProposalCommandAction.CancellationAdjustment:
        msg = this.msg_cancellationAdjustmentWarning;
        break;
      case Enums.ProposalCommandAction.ChangeOfCorrespondenceAddress:
        msg = this.msg_changeOfCorrAddressWarning;
        break;
      case Enums.ProposalCommandAction.EditQuotation:
        msg = this.msg_editapprovedquotation;
        break;
      default:
    }

    return msg;
  }

  actionsConfirmationModal = (
    command: Enums.ProposalCommandAction,
    endorsementType: any,
    additionalButtonType: any
  ): void => {
    let msg: string = this.getMessage(
      command,
      endorsementType,
      additionalButtonType
    );

    const data: InterfaceProduct.IDialogMessage = {
      message: msg,
      tabClicked: command,
    };
    if (common.isDefined(endorsementType)) {
      data.checkedStatus = endorsementType; // using checkedStatus to know the general and loan redemption endorsement type
    }
    if (
      command === Enums.ProposalCommandAction.ReturnForRevise ||
      command === Enums.ProposalCommandAction.Decline
    ) {
      const v1DialogRef = this.cdkDialogService.open(
        MdConfirmationMessageWithReasonComponent,
        {
          data: data,
        }
      );

      v1DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
          this.confirmationModalCloseForActions(result);
        }
      });
    } else {
      if (
        command === Enums.ProposalCommandAction.Submit ||
        command === Enums.ProposalCommandAction.Accept ||
        command === Enums.ProposalCommandAction.Approve
      ) {
        data.productId = this.proposal.productId;

        this.proposal.addtionalEndorsementType = additionalButtonType; //differentiate quote & approve
        this.isWpbProduct =
          this.proposal.productType === EnumHK.MortgageFireHKProduct.HSWPB;
        // CBG General Endorsement doesn't create Document, hence the print option poppup will not show for any role.

        //todo: http://bitbucket.apac.chubb.com/projects/DBSEP/repos/chubb-ep2-ui/commits/d4df8fb8c0d0d3e4ea4f6c4a191d8a0b7fcce1d3
        if (false /*this.isIBGProduct*/) {
          // if (
          //   additionalButtonType !== 2 &&
          //   this.proposal.transType == Enums.TransType.END &&
          //   this.proposal.endorsementType ==
          //     Enums.EndorsementType.GeneralEndorsement
          // ) {
          this.showMdConfirmationMessageV1(data);
        } else {
          var isValid = true;
          if (
            additionalButtonType === 2 &&
            this.proposal.productType === EnumHK.MortgageFireHKProduct.HSWPB
          ) {
            isValid = this.proposalService.ReferValidate(
              data.tabClicked,
              this.clickedButtonId
            );

            if (isValid) {
              this.doRefer(data.tabClicked, msg);
            }
          } else if (
            additionalButtonType === 4 &&
            this.proposal.productType === EnumHK.MortgageFireHKProduct.HSWPB
          ) {
            //if WPB+approve, also skip PrintOptionPopup
            this.showMdConfirmationMessageV1(data);
          } else {
            this.PrintOptionPopup(data);
          }
        }
      } else {
        this.showMdConfirmationMessageV1(data);
      }
    }
  };

  private showMdConfirmationMessageV1(data: InterfaceProduct.IDialogMessage) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: data.message,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  confirmationModalCloseForLST = (data: any): void => {
    if (data.status === "yes") {
    }
  };

  private PrintOptionPopup(data: InterfaceProduct.IDialogMessage) {
    if (this.identityRolesService.isChecker) {
      // 'REF' is prefix of all referral policy validation fail message key
      var referralPolicy =
        this.proposalService.businessRulesManager.echos.length > 0
          ? this.proposalService.businessRulesManager.echos[0].echos.filter(
              function (item) {
                return item.key!.indexOf("REF") == 0;
              }
            )
          : new Array<Echo<QuestionEntity>>();
      // when checker is checking non referral policy,
      // we need to set print type 1 (print to mail) autometically,
      // even though the print popup is not showing.
      // and need to set default Password Protected is true for CBG and false for IBG.
      if (referralPolicy.length == 0) {
        // Default Print Type is Send to Mail
        var option = [
          {
            label: "Email",
            value: "2",
          },
          {
            label: "SMS",
            value: "5",
          },
          {
            label: "Mail",
            value: "1",
          },
        ];
        data.selectedPrintType = option.filter(
          (x) =>
            x.value ==
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer
        )[0];
        if (data.selectedPrintType.value == 1) {
          data.printCorrespondence =
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer;
        } else if (data.selectedPrintType.value == 2) {
          data.printCorrespondence =
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.answer;
        } else if (data.selectedPrintType.value == 5) {
          data.printCorrespondence =
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer;
        }
        // Checking IBG Product
        if (false /*this.isIBGProduct */) {
          data.isPasswordProtected = false;
        } else {
          data.isPasswordProtected = true;
        }
      }
      this.showMdConfirmationMessageV1(data);
    } else {
      if (this.proposal!.customerMappingId! > 0 && !this.isRefUnderwriter) {
        this.clientService
          .get(
            this.proposal.customerMappingId!,
            this.proposal.qsMaster.qs_master_insuredNo!.answer
          )
          .subscribe((response: ClientEntity) => {
            const clientData = response;
            if (clientData) {
              data.printCorrespondence = clientData.emailAddress;
            }
            this.showPrintOptionDialog(data);
          });
      } else {
        if (!this.isRefUnderwriter) {
          this.showPrintOptionDialog(data);
        }
      }
    }
  }

  showPrintOptionDialog = (data: any): void => {
    data.preferredDeliveryMethod =
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer;
    data.emailId =
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.answer;
    data.mobileNo =
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer;
    data.contactInfo =
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer;
    data.confimationMsg = this.isWpbProduct
      ? "*  Any value entered will not be updated into the Client Master database."
      : "*  Any value entered will not be updated into the Client Master database.";

    const v4DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV4Component,
      {
        data: data,
      }
    );

    v4DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationPrintOptionSelection(result);
      }
    });
  };

  doRefer(command: any, msg: string) {
    this.proposalService.referAction(command, this.clickedButtonId, msg);
  }

  confirmationPrintOptionSelection = (result: any): void => {
    if (result.status === "yes") {
      let data = result.data;
      this.showMdConfirmationMessageV1(data);
    }
  };

  cbgManualUnderwritingMessage = (): any => {
    let message: any = this.msg_bindPolicyWarning;
    const isIBGProduct = false;
    // this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMF ||
    // this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMAC
    //   ? true
    //   : false;
    if (!isIBGProduct) {
      if (
        this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === true ||
        this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === "True"
      ) {
        if (
          this.proposal.transType === Enums.TransType.END &&
          this.proposal.endorsementType ===
            Enums.EndorsementType.ChangeOfSumInsured
        ) {
          const quote = this.proposal.refreshedProposalEntity.quotes;
          if (common.isDefined(quote) && quote && quote.length > 0) {
            if (common.isDefined(quote[0].premiumSummary)) {
              if (
                quote[0].premiumSummary.premium === 0 ||
                quote[0].premiumSummary.premiumTax === 0
              ) {
                message = this.msg_bindCBGPolicyWarning;
              }
            }
          }
        }
      }
    }
    return message;
  };

  confirmationModalCloseForActions = (response: any): void => {
    if (response.status === "yes") {
      let data = response.data;
      switch (data.tabClicked) {
        case Enums.ProposalCommandAction.Exit:
          this.router.navigate(["/" + ROUTE_HOME]);
          break;
        case Enums.ProposalCommandAction.SendToChecker:
        case Enums.ProposalCommandAction.ReturnForRevise:
        case Enums.ProposalCommandAction.Approve:
        case Enums.ProposalCommandAction.Renew:
        case Enums.ProposalCommandAction.Decline:
        case Enums.ProposalCommandAction.Submit:
        case Enums.ProposalCommandAction.MakeRedundant:
        case Enums.ProposalCommandAction.Accept:
        case Enums.ProposalCommandAction.Endorse:
        case Enums.ProposalCommandAction.Cancel:
        case Enums.ProposalCommandAction.Copy:
        case Enums.ProposalCommandAction.EditDeclinedProposal:
        case Enums.ProposalCommandAction.CancellationAdjustment:
        case Enums.ProposalCommandAction.ChangeOfCorrespondenceAddress:
        case Enums.ProposalCommandAction.EditQuotation:
          if (
            data.tabClicked == Enums.ProposalCommandAction.Submit ||
            data.tabClicked == Enums.ProposalCommandAction.Approve ||
            data.tabClicked == Enums.ProposalCommandAction.Accept
          ) {
            if (data.selectedPrintType != null) {
              this.proposalService.proposal.printType =
                data.selectedPrintType.value;
              this.proposalService.proposal.printCorrespondence =
                data.printCorrespondence;
              this.proposalService.proposal.isPasswordProtected =
                data.isPasswordProtected;
              this.proposalService.proposal.isReprint = false;
              this.proposalService.proposal.isSummary = false;
              this.proposalService.proposal.isManual = false;
            }
          }
          if (
            data.tabClicked === Enums.ProposalCommandAction.Renew &&
            this.proposal.proposalStatus === Enums.ProposalStatus.Accepted
          ) {
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.required =
              false;
            //Reset Clauses added flag for renewal cases
            this.proposal.qsMaster.qs_master_clausesflag!.answer = "false";
          } else {
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.required =
              true;
          }
          if (data.tabClicked === Enums.ProposalCommandAction.Copy) {
            this.proposal.qsMaster.qs_master_clausesflag!.answer = "false";
          }
          if (
            data.tabClicked === Enums.ProposalCommandAction.ReturnForRevise ||
            data.tabClicked === Enums.ProposalCommandAction.Decline
          ) {
            // assign the [return to revise reason] / [Decline reason] to aux actionReason property
            this.proposalService.proposal.auxiliary = {
              actionReason: data.message,
            };
          }
          if (data.tabClicked === Enums.ProposalCommandAction.Endorse) {
            // set the endorsementType
            /**  EndorsementType 1: General Endorsement
                              * EndorsementType 16: Loan Redemption
                              EndorsementType 17: Change Of POI
                              EndorsementType 8: Change Of SI
                          */
            // Handling only for Endorsement Reason Question because it will reset for every endorsement raised
            this.proposal.qsMaster.qs_master_endorse_endorsementReason!.answer =
              "";
            switch (data.checkedStatus) {
              case Enums.EndorsementType.LoanRedemption:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.LoanRedemption;
                break;
              case Enums.EndorsementType.ChangeOfPOI:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.ChangeOfPOI;
                break;
              case Enums.EndorsementType.ChangeOfSumInsured:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.ChangeOfSumInsured;
                break;
              case Enums.EndorsementType.ChangeOfCorrespondenceAddress:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.ChangeOfCorrespondenceAddress;
                break;
              case Enums.EndorsementType.ChangeOfAdditionalInsured:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.ChangeOfAdditionalInsured;
                break;
              case Enums.EndorsementType.ChangeOfClientInfo:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.ChangeOfClientInfo;
                break;
              case Enums.EndorsementType.GstAdjustment:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.GstAdjustment;
                break;
              //2022-08-23 dagoh0: APACDM-363 - Assign Endorsement Type
              case Enums.EndorsementType.ChangeOfPlanType:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.ChangeOfPlanType;
                break;
              default:
                this.proposalService.proposal.endorsementType =
                  Enums.EndorsementType.GeneralEndorsement;
                break;
            }
          }
          if (data.tabClicked === Enums.ProposalCommandAction.Cancel) {
            // Handling only for Endorsement Reason Question because it will reset for cancellation raised
            this.proposal.qsMaster.qs_master_endorse_endorsementReason!.answer =
              "";
          }
          if (
            data.tabClicked ===
            Enums.ProposalCommandAction.CancellationAdjustment
          ) {
            this.proposalService.proposal.endorsementType =
              Enums.EndorsementType.CancellationAdjustment;
            if (
              this.identityRolesService.isOperationUnderwriter &&
              false /*this.isIBGProduct*/
            ) {
              this.setCancellationAdjustmentReferralRule();
            }
          }
          if (
            data.tabClicked ===
            Enums.ProposalCommandAction.ChangeOfCorrespondenceAddress
          ) {
            this.proposalService.proposal.endorsementType =
              Enums.EndorsementType.ChangeOfCorrespondenceAddress;
          }
          if (data.tabClicked === Enums.ProposalCommandAction.MakeRedundant) {
            if (
              (this.proposalService.proposal.endorsementType =
                Enums.EndorsementType.ChangeOfClientInfo)
            ) {
              this.clientService.clientDictionary = {};
            }
            if (
              this.identityRolesService.isOperationUnderwriter &&
              false /*this.isIBGProduct*/ &&
              this.proposal.transType === Enums.TransType.REN &&
              this.proposal.proposalStatus === Enums.ProposalStatus.Incomplete
            ) {
              this.proposalService.proposal.endorsementType =
                Enums.EndorsementType.MakeRedundant;
              // this.proposal.auxiliary['IsMakeRedundant']="true";
              this.MakeRedundantRuleReferralRule();
              break;
            }
          }
          if (data.tabClicked === Enums.ProposalCommandAction.Approve) {
            this.isWpbReferred = false;
            this.actions.submit.visible = false;
          }
          // notify button start event
          buttonNotifier.start_notifier$.next(
            new KeyValue<string>(this.clickedButtonId.toString(), "start")
          );
          this.proposalService.action(data.tabClicked, this.clickedButtonId);
          break;
        default:
          // do nothing since all the command actions should be handled.
          break;
      }
    }
  };

  openMessageForum(event?: any, UnderwriterInternalComment?: any): void {
    const data = {
      proposalId: this.proposal.proposalId!.toString(),
      policyNumber: this.proposal.policyNumber,
      isUnderwriterInternalComment: UnderwriterInternalComment,
      productId: this.proposal.productId.toString(),
      printCorrespondence: "",
      preferredDeliveryMethod:
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer,
      emailId:
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.answer,
      mobileNo:
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!
          .answer,
      contactInfo:
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!
          .answer,
      transType: this.proposal.transType,
      proposalStatus: this.proposal.proposalStatus,
      loanRedeemed: this.proposal.loanRedeemed,
      policyExpiryDate: this.proposal.qsMaster.qs_master_poi_endDate!.answer,
      proposalType: this.proposal.productType,
      pendingTransaction: this.proposal.pendingTransaction,
      isPreviousYearTerminated:
        this.proposal.auxiliary["IsPreviousYearTerminated"],
      allowRenewal: this.proposal.auxiliary["allowRenewal"],
      confimationMsg: this.isWpbReferred
        ? "*  Any value entered will not be updated into the Client Master database."
        : "*  Any value entered will not be updated into the Client Master database.",
    };
    if (this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer == 5) {
      data.printCorrespondence =
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer;
    }
    if (this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer == 2) {
      data.printCorrespondence =
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.answer;
    }
    if (this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer == 1) {
      data.printCorrespondence =
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer;
    }
    if (this.proposal.customerMappingId! > 0) {
      this.clientService
        .get(
          this.proposal.customerMappingId!,
          this.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response: ClientEntity) => {
          var clientData = response;
          if (clientData) {
            data.printCorrespondence = clientData.emailAddress;
          }
          this.showMessageForum(data);
        });
    } else {
      this.showMessageForum(data);
    }
  }

  showMessageForum = (data?: any): void => {
    const v1DialogRef = this.cdkDialogService.open(MessageForumComponent, {
      data: data,
    });

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  };

  openContactUs(event?: any, UnderwriterInternalComment?: any): void {
    const data = {
      proposalId: this.proposal.proposalId,
      isUnderwriterInternalComment: UnderwriterInternalComment,
      productId: this.proposal.productId,
    };
    const v1DialogRef = this.cdkDialogService.open(MfContactUsFormComponent, {
      data: data,
    });

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }

  openCreateNew(event?: any, UnderwriterInternalComment?: any): void {
    let productSelectionInputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: null,
      message: "",
    };

    const productSelectionDialogRef = this.cdkDialogService.open(
      MdProductSelectionDialogComponent,
      {
        data: productSelectionInputData,
      }
    );

    productSelectionDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });

    event.preventDefault(); //do not goes further
  }
  //     delete() {
  //     }
  //     messageBoard() {
  //     }
  // }
  //#endregion
}
