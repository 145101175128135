<header class="plain-header">
  <img class="logo" src="assets\Chubb-turquoise.png" alt="chubb logo" />
</header>
<div class="centered-container">
  <form
    id="requestOtpForm"
    class="form"
    name="forms.formLogin"
    ng-submit="sendForm()"
    method="post"
  >
    <p class="headline">Enter One time Password (OTP)</p>
    <p class="description">
      {{ otpSent }}<b> {{ otpSentInBold }} </b>:
    </p>
    <div class="container-fluid">
      <form (ngSubmit)="onSubmit()" [formGroup]="otpForm">
        <div class="row">
          <div class="form-group" *ngFor="let input of formInput; index as i">
            <input
              #formRow
              type="password"
              formControlName="{{ input }}"
              class="otpRow"
              maxlength="1"
              (keydown)="keyDownEvent($event, i)"
            />
          </div>
        </div>
      </form>
    </div>
    <p id="error" class="error" *ngIf="hasError">{{ msg }}</p>
    <div class="button-container">
      <button
        (click)="onSubmit()"
        event="1"
        [disabled]="IsDisabled"
        [class]="{ removePointer: IsDisabled }"
      >
        Submit
      </button>
    </div>
  </form>
  <div style="width: 100%; display: table; margin-top: 15px">
    <div style="display: table-row">
      <div class="tiny-description" style="width: 50%; display: table-cell">
        If you did not receive your OTP or if your OTP has expired, please click
        here to request for a new OTP.
      </div>
      <div class="description button-container" style="display: table-cell">
        <button
          (click)="resendOtpAndStartTimer()"
          event="1"
          [disabled]="!canResendOtp || IsDisabled"
          [class]="{ removePointer: !canResendOtp || IsDisabled }"
          style="display: block; width: 100%"
          [innerHtml]="resendOtp"
        ></button>
      </div>
    </div>
  </div>
  <p class="description">{{ otpResent }}</p>
  <p class="enquiries-description">
    For any enquiries, please contact us at
    <span style="color: #01c1d6">dbscs.sg@chubb.com</span>.
  </p>
</div>
<div id="footerdiv" class="" style="margin-left: 11px">
  <footer style="margin-top: 15px">
    <p>{{ footerTitle }}</p>
  </footer>
</div>
