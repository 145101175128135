import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OpenItemListingEntity } from "src/app/components/accounting/open-item/OpenItemListingEntity";
import {
  ManualPaymentEntity,
  RefundPaymentEntity,
} from "src/app/components/accounting/open-item/models/open-item-list.model";
import { BaseOpenItemListComponent } from "src/app/components/new-product/base/base-open-item-list/base-open-item-list.component";
import { dlgModelArgs } from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { openItemDisplayStatus } from "src/app/finance/finance-constant";
import { OpenItemStatus } from "src/app/finance/finance-enums";
import { BroadcastService } from "src/app/services/broadcast.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { OpenItemListService } from "src/app/services/open-item-list.service";
import { Identity } from "src/app/shared/user/identity";

@Component({
  selector: "mf-hk-open-item-list",
  templateUrl: "./mf-hk-open-item-list.component.html",
  styleUrls: ["./mf-hk-open-item-list.component.scss"],
})
export class MfHkOpenItemListComponent
  extends BaseOpenItemListComponent
  implements OnInit
{
  isPaymentMonthSelected: boolean = false;
  selectedStatusIds: number[] = [];

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    @Inject(LOCALE_ID) public override locale: string,
    public override openItemListService: OpenItemListService,
    public override identity: Identity,
    public override excelTemplate: ExcelTemplateService,
    public override cdkDialogService: CdkDialogService
  ) {
    super(
      router,
      broadcastService,
      identityRolesService,
      locale,
      openItemListService,
      identity,
      excelTemplate,
      cdkDialogService
    );

    this.searchCriteria.productRadio = "hase"; //default to hase
  }

  // this.refresh() in ngOnInit() has to be called separately by component,
  // cannot place in BaseOpenItemListComponent, else call twice
  override ngOnInit(): void {
    super.ngOnInit();

    this.defaultSearchCriteria();
    this.readProductRadio();
    this.fetchUserList();
    this.fetchStatusList();
    this.initStatusLists();
    this.setDbsAutoDebitPeriod();
    this.refresh();
  }

  public override onChange_ProductRadio(): void {
    this.resetFormValues();
    this.readProductRadio();
    this.fetchUserList();
    this.fetchStatusList();

    this.setDbsAutoDebitPeriod();
    this.refresh();
  }

  //DBSEP-7267
  protected override on_AutoDebitMonthChange(val: any): void {
    var timestamp = Date.parse(val);

    if (isNaN(timestamp) == false) {
      this.selectedStatusIds = [24]; //Filter the list by the Status "Sent for Collection"
      this.isPaymentMonthSelected = true;
    } else {
      this.selectedStatusIds = [];
      this.isPaymentMonthSelected = false;
    }
  }

  protected override getQueryString(): string {
    //convert number array to string array needed by query
    this.searchCriteria.statusIds = this.selectedStatusIds.map(String);
    return super.getQueryString();
  }

  protected override setDbsAutoDebitPeriod(): void {
    //do nothing, no need default to current month
  }

  protected override initStatusLists(): void {
    // PendingPayment = 23
    this.payableStatusList = [23];

    // PendingRefund = 25
    this.refundableStatusList = [25];
    this.refundableReviewStatusList = [];
  }

  protected override fetchUserList() {
    // get user productsList
    this.userProductList = this.identity
      .currentUser()
      .products?.filter(function (e) {
        let ucvalue = e.pdid.toString().toUpperCase();
        return (
          ucvalue == "3840D5AA-7FB6-416A-9E3E-9D346562E648" ||
          ucvalue == "AAEA26B4-812F-4FA1-AC43-7177EA129917"
        );
      });
  }

  protected override fetchStatusList() {
    // get statusList
    this.statusList = [];

    for (const key in openItemDisplayStatus) {
      if (openItemDisplayStatus.hasOwnProperty(key)) {
        const value = openItemDisplayStatus[key];

        if (
          this.searchCriteria.productRadio != null &&
          [6, 9, 10, 23, 24, 25].includes(Number(key))
        ) {
          this.statusList.push({ text: value, value: Number(key) });
        }
      }
    }
    // sort statusList by asc
    this.statusList.sort((x, y) => {
      return x.text > y.text ? 1 : x.text < y.text ? -1 : 0;
    });
  }

  public makeHasePaymentHandler(
    manualPaymentEntity: ManualPaymentEntity
  ): void {
    this.doManualPay = false;
    this.openItemListService.doHaseManualPay(manualPaymentEntity).subscribe(
      (response: any) => {
        this.refresh();
        this.openStatusPopup("Payment made Succesfully");
      },
      (err: any) => {
        this.refresh();
        this.openStatusPopup("Payment failed");
      }
    );
  }

  protected override getActionName(entity: OpenItemListingEntity): string {
    let isPendingPayment: boolean =
      this.payableStatusList.indexOf(entity.statusId) !== -1;

    let isPendingRefund: boolean =
      this.refundableStatusList.indexOf(entity.statusId) !== -1;

    let isPendingRefundReview: boolean =
      this.refundableReviewStatusList.indexOf(entity.statusId) !== -1;
    // // Manual Paid (6) & Payment by cheque (2)
    // let isBounceableCheque: boolean =
    //   entity.statusId === 6 && entity.paymentTypeId === 2;

    if (isPendingPayment) return "PAY";
    else if (isPendingRefund) return "REFUND";
    else if (isPendingRefundReview) return "REFUND IN REVIEW";
    //else if (isBounceableCheque) return "BOUNCE CHEQUE";//not needed in Hase
    else return "";
  }

  protected override onRefundClick(datas: any): void {
    this.refundOpenItemID = datas.openItemId;
    if (datas.statusId == OpenItemStatus.PendingRefund) {
      //when Hase's refund, directly to new 'refund in review' popup directly, no need confimation dlg
      this.doRefundPay = true;
      // this.ConfirmationPopup(
      //   "Are you sure you want to Change the status to Pending Refund?",
      //   ""
      // );
    }
  }

  public override doRefundHandler(
    refundPaymentEntity: RefundPaymentEntity
  ): void {
    this.doRefundPay = false;

    //this.openItemListService.doRefund(refundPaymentEntity).subscribe(
    this.openItemListService.doHaseRefund(refundPaymentEntity).subscribe(
      (response: any) => {
        this.refresh();
        this.openStatusPopup("Payment refunded successfully");
      },
      (err: any) => {
        this.refresh();
        this.openStatusPopup("Payment refunded failed");
      }
    );
  }

  override async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      const postData = { openItemId: this.refundOpenItemID };
      this.openItemListService.doHaseRefund(postData).subscribe(
        (response: any) => {
          // refresh grid
          this.refresh();
          this.openStatusPopup("Status Updated Succesfully");
        },
        (err: any) => {
          this.refresh();
          this.openStatusPopup("Status Updated Failed");
        }
      );
    }
  }

  public override export(): void {
    this.openItemListService
      .exportOpenItemListing(this.getQueryString())
      .subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            this.excelTemplate.downloadHaseOpenItemPolicies(
              haseColumns,
              "Open Item Report",
              "Open Item Report",
              result.body.d?.results
            );
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }
}

const haseColumns: string[] = [
  "Product Type", //"Meridian Sequence No",
  "Policy Certificate No",
  //"Master Policy No",
  "Policy No",
  "Entry Date",
  "Total Premium",
  "Levy",
  "Status",
  //"Invoice No",
  "Saved By",
  "policy Holder Name",
  "Correspondence Address",
  //"Inactive Address Changed",
  "Gross Premium (Excl.Levy)",
  //"BUPC",
  //"Bank Reference",
  "Mortgagor Name", //"Insured Name",
  "Effective Date",
  "INS Expiry Date",
  "Sum Insured",
  "Risk Address",
  //"Insured NRIC",
  //"Additional Insured",
  "Payment Month",
  //"Cancellation Adjustment",
];
