import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ClausesDeductiblesService } from "src/app/services/clauses-deductibles.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { Global } from "src/app/shared/global";
import { MdInformationMessageComponent } from "../md-information-message/md-information-message.component";
import { MfHkConfigAddCriteria } from "../configurable-form-add/mf-hk-config-add-criteria";
import { MfHkEndorsementListSearchCriteria } from "src/app/components/new-product/sme/mortgage-fire/hong-kong/components/endorsement/endorsement-list/mf-hk-end-list-search-criteria";

@Component({
  selector: "app-configurable-form-base",
  templateUrl: "./configurable-form-base.component.html",
  styleUrls: ["./configurable-form-base.component.scss"],
})
export class ConfigurableFormBaseComponent implements OnInit {
  errorLogs!: { Title: string; Logs: string[] };

  model = new MfHkConfigAddCriteria();

  @Input()
  public addSearchCriteria = new MfHkEndorsementListSearchCriteria();

  @Input()
  public coverageTypeOptions: Array<{ text: string; value: string }> = [];

  @Output() returnListing: EventEmitter<string> = new EventEmitter();

  constructor(
    public clausesDeductiblesService: ClausesDeductiblesService,
    public cdkDialogService: CdkDialogService
  ) {
    // Init Error Log
    if (Global.isUndefined(this.errorLogs)) {
      this.errorLogs = { Title: "", Logs: new Array<string>() };
    }
  }

  ngOnInit(): void {
    //this is the time @Input() addSearchCriteria get initialized; not in constructor()
    this.model.typeId = this.addSearchCriteria.typeId;

    this.setDefaultCoverageType();
  }

  setDefaultCoverageType() {
    //if perils
    if (this.coverageTypeOptions.length === 1) {
      this.model.coverageTypes.push(this.coverageTypeOptions[0].value);
    }
  }

  returnToListing() {
    this.returnListing.emit("returnToListing");
  }

  reset() {
    this.errorLogs.Logs = new Array<string>(); // Clear error logs
    this.model = new MfHkConfigAddCriteria();
    this.model.typeId = this.addSearchCriteria.typeId;
  }

  protected modelToArray(): (
    | (string | Date | undefined)[]
    | (string | string[])[]
    | (string | number[])[]
    | (string | number)[]
  )[] {
    let array = [
      ["coverageTypes", "eq", this.model.coverageTypes.toString()],
      ["code", "eq", this.model.code!],
      ["title", "eq", this.model.title!],
      ["description", "eq", this.model.editorValue],
      ["default", "eq", this.boolToString(this.model.default)],
      ["typeId", "eq", this.model.typeId],
    ];

    return array;
  }

  private stringBoolToString(boolToCompare: any) {
    if (
      boolToCompare == true ||
      String(boolToCompare).toLowerCase() === "true"
    ) {
      return true;
    }

    return false;
  }

  protected boolToString(boolToCompare: any) {
    return this.stringBoolToString(boolToCompare).toApiValue();
  }

  protected setFormData(): FormData {
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    let array = this.modelToArray();

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    return formData;
  }

  pushLog(log: string) {
    this.errorLogs.Logs = new Array<string>(); // Clear error logs
    this.errorLogs.Title = "Error";

    this.errorLogs.Logs.push(log);
  }

  handleReturnResult(result: any) {
    const returnCode = result.body.d?.results as string;
    if (returnCode === "1") {
      const v2DialogRef = this.cdkDialogService.open(
        MdInformationMessageComponent,
        {
          data: {
            message: "Data created. Redirect to listing page",
          },
        }
      );

      v2DialogRef.afterClosed().subscribe((result: any) => {
        this.returnListing.emit("returnToListing");
      });
    } else {
      const log: string =
        "The code provided is already in use. Please change the code.";
      this.pushLog(log);
    }
  }

  save() {
    if (
      this.model.coverageTypes.length === 0 ||
      this.model.code === undefined ||
      this.model.code.trim() === "" ||
      this.model.title === undefined ||
      this.model.title.trim() === ""
    ) {
      const log: string = "Coverage Type, Title, and Code are mandatory.";
      this.pushLog(log);
    } else {
      const formData: FormData = this.setFormData();

      //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
      //IT HAS TO BE IN SINGLE CHUNK OF STRING
      var querystring: string = "";
      for (var pair of formData.entries()) {
        querystring +=
          encodeURIComponent(pair[0]) +
          "=" +
          encodeURIComponent(pair[1] as string) +
          "&";
      }
      this.clausesDeductiblesService
        .createHaseEndorsementClause(querystring)
        .subscribe(
          (result: any) => {
            if (
              result != undefined &&
              result.body != undefined &&
              result.body != null
            ) {
              this.handleReturnResult(result);
            }
          },
          (err: any) => {
            console.log(err);

            this.cdkDialogService.open(MdInformationMessageComponent, {
              data: {
                message: "An error has occurred. Please contact admin",
              },
            });
          }
        );
    }
  }
}
