import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";
import { httpNotifier } from "src/app/interceptor/http-notifier";
import { buttonNotifier } from "../notifier/button-notifier";

@Component({
  selector: "[button-default]", //render Selector as an Attribute
  templateUrl: "./button-default.component.html",
  styleUrls: ["./button-default.component.scss"],
})
export class ButtonDefaultComponent implements OnInit {
  public httpRequesting: boolean = false;
  public running: boolean = false;
  private id!: number;

  private request_notifier_subscription: Subscription = new Subscription();
  private request_error_notifier_subscription: Subscription =
    new Subscription();
  private response_notifier_subscription: Subscription = new Subscription();
  private response_error_notifier_subscription: Subscription =
    new Subscription();
  private start_notifier_subscription: Subscription = new Subscription();
  private end_notifier_subscription: Subscription = new Subscription();

  onClick!: (e: any) => void;
  event: any;
  customButtonStartNotification: boolean = false;

  constructor() {
    this.id = common.generateUniqueId();

    // do nothing
    this.request_notifier_subscription =
      httpNotifier.request_notifier$.subscribe(this.onHttpRequestStart);
    this.request_error_notifier_subscription =
      httpNotifier.response_error_notifier$.subscribe(this.onHttpRequestEnd);
    this.response_notifier_subscription =
      httpNotifier.response_notifier$.subscribe(this.onHttpRequestEnd);
    this.response_error_notifier_subscription =
      httpNotifier.response_error_notifier$.subscribe(this.onHttpRequestEnd);

    this.start_notifier_subscription = buttonNotifier.start_notifier$.subscribe(
      this.onButtonStart
    );
    this.end_notifier_subscription = buttonNotifier.end_notifier$.subscribe(
      this.onButtonEnd
    );
  }

  ngOnInit(): void {}

  public $onDestroy = () => {
    this.request_notifier_subscription.unsubscribe();
    this.request_error_notifier_subscription.unsubscribe();
    this.response_notifier_subscription.unsubscribe();
    this.response_error_notifier_subscription.unsubscribe();
    this.start_notifier_subscription.unsubscribe();
    this.end_notifier_subscription.unsubscribe();
  };

  public onHttpRequestStart = (e: KeyValue<any>) => {
    this.httpRequesting = true;
  };

  public onHttpRequestEnd = (e: KeyValue<any>) => {
    setTimeout(() => {
      this.httpRequesting = false;
      buttonNotifier.end_notifier$.next(
        new KeyValue<string>(this.id.toString(), "end")
      );
    }, 50);
  };

  public onButtonStart = (e: KeyValue<any>) => {
    const eId = e.key.toNumber();
    if (eId === this.id) {
      this.running = true;
    }
  };

  public onButtonEnd = (e: KeyValue<any>) => {
    const eId = e.key.toNumber();
    if (eId === this.id) {
      this.running = false;
    }
  };

  public click() {
    try {
      if (!this.customButtonStartNotification) {
        buttonNotifier.start_notifier$.next(
          new KeyValue<string>(this.id.toString(), "start")
        );
      }

      if (common.isDefined(this.onClick)) {
        this.onClick(new KeyValue(this.id.toString(), this.event));
      }
    } catch (error) {
      // just notified end if any error occurred
      this.httpRequesting = false;
      buttonNotifier.end_notifier$.next(
        new KeyValue<string>(this.id.toString(), "end")
      );

      throw error;
    }
  }
}
