export class CbgDailyDownloadSearchCriteria {
  constructor() {
    //this.dateFrom = null;
    //this.dateTo  = null;
    this.segments = [];
    this.userTeams = [];
    this.productIds = [];
  }

  dateFrom?: Date;
  dateTo?: Date;
  segments: Array<string>;
  userTeams: Array<number>;
  productIds: Array<string>;
}
