<kendo-dialog
  [title]="h3LabelText"
  class="modal-title-light-orange"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="850"
  [height]="440"
>
  <div class="container-fluid-custom container-overflow-y">
    <div #modal_body row padding5 *ngIf="isProcessing">
      <div class="margin5 textAlignCenter">
        <span class="loadingBarText">Please Wait</span>&nbsp;
        <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
      </div>
    </div>
    <div class="container-fluid" *ngIf="showErrorMessage">
      <div class="row">
        <div class="col-md-12">
          <div id="myModal" class="marginBottom10" role="dialog">
            <div class="">
              <!-- Modal content-->
              <div class="modal-content" style="border-color: #afafaf">
                <div
                  class="modal-header"
                  style="
                    border-bottom-color: #afafaf;
                    padding-top: 5px;
                    padding-bottom: 5px;
                  "
                >
                  <h4 class="modal-title chubbRed">
                    Please complete the following :
                  </h4>
                </div>
                <div class="modal-body paddingTop5">
                  <div class="row">
                    <div
                      style="display: contents"
                      id="errorMessage"
                      [innerHtml]="errorMsgInHtml"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="createNewClient" class="masterPage" *ngIf="!isProcessing">
      <div class="container-fluid">
        <div class="row" *ngIf="isCreate">
          <div class="col-md-12">
            <form role="form">
              <label
                [textContent]="'Please select client type:'"
                class="fontSize12px"
              ></label>
              <div>
                <label>
                  <input
                    name="clientType"
                    type="radio"
                    value="1"
                    [(ngModel)]="changedVal"
                    (click)="selectClientType($event)"
                  />
                  <span class="fontSize12px" style="vertical-align: text-top"
                    >Individual</span
                  >
                </label>
                <label class="marginLeft10">
                  <input
                    name="clientType"
                    type="radio"
                    value="2"
                    [(ngModel)]="changedVal"
                    (click)="selectClientType($event)"
                  />
                  <span class="fontSize12px" style="vertical-align: text-top"
                    >Organization</span
                  >
                </label>
                <label class="marginLeft10" *ngIf="showClientTypeOthers">
                  <input
                    name="clientType"
                    type="radio"
                    value="3"
                    [(ngModel)]="changedVal"
                    (click)="selectClientType($event)"
                  />
                  <span class="fontSize12px" style="vertical-align: text-top"
                    >Others</span
                  >
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="clientPersonalInformation">
        <div class="row">
          <div class="col-md-12 textDecoUnderline">
            <h4>Personal Information</h4>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div
                class="form-group"
                [ngClass]="{
                  'st-danger': isCreate && isEmpty('Mortgagor Name')
                }"
              >
                <label
                  [ngClass]="{ required: isCreate }"
                  [textContent]="'Mortgagor Name'"
                ></label>
                <div>
                  <input
                    id="clientFullName"
                    type="text "
                    value=""
                    maxlength="230"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.clientName"
                    name="clientName"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <div class="form-group">
              <div
                class="form-group"
                [ngClass]="{ 'st-danger': isEmpty('Document Type') }"
              >
                <label
                  class="required"
                  [textContent]="documentTypeLabel"
                ></label>
                <div>
                  <kendo-dropdownlist
                    [defaultItem]="defaultItem"
                    [data]="docTypeList"
                    textField="text"
                    valueField="value"
                    [kendoDropDownFilter]="{
                      operator: 'contains'
                    }"
                    [valuePrimitive]="true"
                    [(ngModel)]="clientData.docType"
                    (valueChange)="documentTypeChange($event)"
                    name="docType"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <form
              *ngIf="clientData.docType == 'I'"
              role="form"
              [ngClass]="{
                'st-danger': isEmpty('NRIC')
              }"
            >
              <div class="form-group" id="nric">
                <label class="required" [textContent]="'Mortgagor ID'"></label>
                <div>
                  <input
                    id="clientNRICNo"
                    type="text "
                    value=""
                    maxlength="200"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.nric"
                    (blur)="nricPassportValidation($event, 'clientNRICNo')"
                    name="nric"
                  />
                </div>
              </div>
            </form>
            <form *ngIf="clientData.docType == 'P'" role="form">
              <div
                class="form-group"
                id="passportNo"
                [ngClass]="{
                  'st-danger':
                    clientData.docType == 'P' && isEmpty('Passport Number')
                }"
              >
                <label
                  [ngClass]="{ required: clientData.docType == 'P' }"
                  [textContent]="'Mortgagor ID'"
                ></label>
                <div>
                  <input
                    id="clientPassportNo"
                    type="text "
                    value=""
                    maxlength="200"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.passportNo"
                    (blur)="nricPassportValidation($event, 'clientPassportNo')"
                    name="passportNo"
                  />
                </div>
              </div>
            </form>
            <form *ngIf="clientData.docType == 'B'" role="form ">
              <div
                class="form-group"
                id="businessRegistrationNumber"
                [ngClass]="{
                  'st-danger': isEmpty('Business Registration Number')
                }"
              >
                <label class="required" [textContent]="'Mortgagor ID'"></label>
                <div>
                  <input
                    id="clientBRNo"
                    type="text "
                    value=""
                    maxlength="200"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.businessRegistrationNumber"
                    (blur)="businessRegistrationNumberToCIN($event)"
                    name="businessRegistrationNumber"
                  />
                </div>
              </div>
            </form>
            <form *ngIf="clientData.docType == 'C'" role="form ">
              <div
                class="form-group"
                id="businessRegistrationNumber2"
                [ngClass]="{
                  'st-danger': isEmpty('Business Registration Number2')
                }"
              >
                <label class="required" [textContent]="'Mortgagor ID'"></label>
                <div>
                  <input
                    id="clientBRNo2"
                    type="text "
                    value=""
                    maxlength="200"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.businessRegistrationNumber2"
                    (blur)="businessRegistrationNumberToCIN($event)"
                    name="businessRegistrationNumber2"
                  />
                </div>
              </div>
            </form>
            <form
              *ngIf="clientData.docType == 'X'"
              role="form"
              [ngClass]="{
                'st-danger': isEmpty('DBClientReferenceNumber')
              }"
            >
              <div class="form-group" id="DBClientReferenceNumber">
                <label class="required" [textContent]="'Mortgagor ID'"></label>
                <div>
                  <input
                    id="clientDBClientReferenceNumber"
                    type="text "
                    value=""
                    maxlength="200"
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.dbsClientReferenceNumber"
                    (blur)="dbsClientReferenceNumberToCIN($event)"
                    name="DBClientReferenceNumber"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <hr />
        <div class="row">
          <div class="col-md-12 textDecoUnderline">
            <h4>Contact Information</h4>
          </div>
          <div class="col-md-12">
            <form role="form ">
              <div
                class="form-group"
                *ngIf="!this.isCreate || this.isChangeOfClientInfo()"
              >
                <label
                  style="color: red; font-weight: normal"
                  class="floatLeft marginRight10"
                  >🛈 If you update these Contact Information, it won’t be
                  updated to the policy. Please update policy data by using the
                  <b>Update</b> feature.</label
                >
              </div>
              <div class="form-group">
                <label
                  class="floatLeft marginRight10"
                  [textContent]="
                    'Street Address Search – Please enter only the street address and suburb, do not include building name or suite/lot/unit/shop number or level                                      '
                  "
                ></label>
                <div
                  style="font-size: 12px"
                  class="cursorPointer floatLeft chubbWhite"
                >
                  <span
                    class="chubbGreen_bg"
                    style="padding: 0px 5px 1px 5px; vertical-align: super"
                    (click)="openAddressHelp($event)"
                    >Help</span
                  >
                </div>
                <div>
                  <div>
                    <input
                      id="clientInformation_autocomplete"
                      ngx-google-places-autocomplete
                      [options]="googlePlacesOptions"
                      #clientInformation_autocomplete="ngx-places"
                      (onAddressChange)="
                        updateAddressValues(
                          $event,
                          {},
                          'clientInformation_autocomplete'
                        )
                      "
                      placeholder="Enter your address "
                      type="text "
                      class="k-textbox maxWidth form-control"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label
                  class="required"
                  [textContent]="'Address Line 1'"
                ></label>

                <div>
                  <input
                    id="insuredAddress1"
                    #insuredAddress1
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    maxlength="100"
                    [(ngModel)]="address.addressLine1"
                    name="addressLine1"
                  />
                </div>
                <!-- <div *ngIf="addAddress1RO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.addressLine1)"
                  ></span>
                </div> -->
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class="" [textContent]="'Address Line 2'"></label>
                <div>
                  <input
                    id="insuredAddress2"
                    #insuredAddress2
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="address.addressLine2"
                    name="addressLine2"
                  />
                </div>
                <!-- <div *ngIf="addAddress2RO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.addressLine2)"
                  ></span>
                </div> -->
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class="" [textContent]="'Address Line 3'"></label>
                <div>
                  <input
                    id="insuredAddress3"
                    #insuredAddress3
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="address.addressLine3"
                    name="addressLine3"
                  />
                </div>
                <!-- <div *ngIf="addAddress3RO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.addressLine3)"
                  ></span>
                </div> -->
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class="" [textContent]="'Address Line 4'"></label>
                <div>
                  <input
                    id="insuredAddress4"
                    #insuredAddress4
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="address.addressLine4"
                    name="addressLine4"
                  />
                </div>
                <!-- <div *ngIf="addAddress4RO">
                  <span
                    class="unattended"
                    [textContent]="addressDataIfUnknown(address.addressLine4)"
                  ></span>
                </div> -->
              </div>
            </form>
          </div>
          <div class="col-md-12 floatLeft">
            <form role="form ">
              <div class="form-group">
                <div
                  class="btn-box chubbWhite chubbRed_bg"
                  (click)="resetClientAddressForm()"
                >
                  Reset
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form " id="mobilePhoneForm">
              <div class="form-group">
                <label [textContent]="'Mobile Number'"></label>
                <div>
                  <input
                    id="brokerMobileNo"
                    name="brokerMobileNo"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    (blur)="phoneValidation('brokerMobileNo')"
                    [(ngModel)]="clientData.mobileNumber"
                    name="mobileNumber"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form " name="emailForm" novalidate>
              <div class="form-group">
                <label class=" " [textContent]="'Email Address'"></label>
                <div>
                  <input
                    id="brokerEmail"
                    type="text"
                    name="brokerEmail"
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.emailAddress"
                    (blur)="emailValidation($event, 'brokerEmail')"
                    name="emailAddress"
                  />
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-6">
            <form role="form " id="housePhoneForm">
              <div class="form-group">
                <label class=" " [textContent]="'Phone Number (Home)'"></label>
                <div>
                  <input
                    id="brokerPhoneNo"
                    name="brokerPhoneNo"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    (blur)="phoneValidation('brokerPhoneNo')"
                    [(ngModel)]="clientData.phoneNoHome"
                    name="phoneNoHome"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form " id="officePhoneForm">
              <div class="form-group">
                <label
                  class=" "
                  [textContent]="'Phone Number (Office)'"
                ></label>
                <div>
                  <input
                    id="brokerOfficeNo"
                    name="brokerOfficeNo"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    (blur)="phoneValidation('brokerOfficeNo')"
                    [(ngModel)]="clientData.phoneNoOffice"
                    name="phoneNoOffice"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <form role="form ">
              <div class="form-group">
                <label class=" " [textContent]="'Fax Number'"></label>
                <div>
                  <input
                    id="brokerFaxNo"
                    name="brokerFaxNo"
                    type="text "
                    value=""
                    class="k-textbox form-control"
                    [(ngModel)]="clientData.faxNumber"
                    name="faxNumber"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- <div>
                <button ng-click="createClient() ">Save</button>
            </div> -->
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        (click)="createClient()"
        themeColor="primary"
      >
        {{ labelText }}
      </button>
      <button
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel"
        kendoButton
        (click)="close('no')"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
<!-- <script>
    $(document).ready(function () {
        var bodyResize = function () {

            var windowWidth = $(window).width();

            if (windowWidth < 1022) {
                $('#content').css('padding-top', '16px');
                // console.log(windowWidth + '20');
            }
            if (windowWidth > 977) {
                $('#content').css('padding-top', '80px');
            }
        };

        bodyResize();

        $(window).resize(function () {
            bodyResize();
        });

        // Listen for orientation changes
        window.addEventListener("orientationchange ", function () {
            // Announce the new orientation number
            bodyResize();
        }, false);

    });

</script> -->
