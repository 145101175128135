import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  OpenItemEntity,
  ManualPaymentEntity,
} from "../models/open-item-list.model";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { OpenItemListService } from "src/app/services/open-item-list.service";
import { common } from "src/app/framework/utils/common";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";

@Component({
  selector: "app-do-bounce-cheque-form",
  templateUrl: "./do-bounce-cheque.component.html",
  styleUrls: ["./do-bounce-cheque.component.scss"],
})
export class DoBounceChequeComponent implements OnInit {
  public manualPayment = new ManualPaymentEntity();
  //public openItemId!:string;
  public openItemEntity = new OpenItemEntity();
  public totalPremium: any;
  public active = false;
  public opened: boolean = false;

  @Input() openItemId!: string;
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() bounceCheque: EventEmitter<ManualPaymentEntity> =
    new EventEmitter();

  constructor(
    private openItemListService: OpenItemListService,
    private cdkDialogService: CdkDialogService
  ) {
    this.active = true;
  }

  ngOnInit(): void {
    this.getOpenItemDetails();
  }
  private getOpenItemDetails() {
    this.openItemListService
      .getOpenItemDetails(this.openItemId)
      .subscribe((response: OpenItemEntity) => {
        let openItem: OpenItemEntity = response;

        let chequeIssuanceBank = !common.isStringNullOrEmpty(
          openItem.manualPaymentChequeIssuanceBank
        )
          ? openItem.manualPaymentChequeIssuanceBank
          : !common.isStringNullOrEmpty(
              openItem.refundPaymentChequeIssuanceBank
            )
          ? openItem.refundPaymentChequeIssuanceBank
          : "";

        let chequeNo = !common.isStringNullOrEmpty(
          openItem.manualPaymentChequeNumber
        )
          ? openItem.manualPaymentChequeNumber
          : !common.isStringNullOrEmpty(openItem.refundPaymentChequeNumber)
          ? openItem.refundPaymentChequeNumber
          : "";

        let paymentRemarks = common.isStringNullOrEmpty(openItem.remarks)
          ? "-"
          : openItem.remarks;

        this.openItemEntity = openItem;
        this.openItemEntity.chequeIssuanceBank = chequeIssuanceBank;
        this.openItemEntity.chequeNumber = chequeNo;
        this.openItemEntity.remarks = paymentRemarks;
      });
  }
  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  ConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  public onSave(e: PointerEvent): void {
    //this.closeForm();
    this.ConfirmationPopup("Are you sure you want to bounce this cheque?", "");
    //e.preventDefault();
  }
  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      this.bounceCheque.emit(this.manualPayment);
    }
  }
}
