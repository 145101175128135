import { MortgageFireHongKongProposal } from "../../sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { AbstractPremiumSummary } from "./abstract-premium-summary";
import * as Enum from "src/app/framework/enum.shared";
import * as Const from "./../../../../framework/const.shared";
import { common } from "./../../../../framework/utils/common";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MortgageFireHongKongFieldValidationManager } from "../../sme/mortgage-fire/hong-kong/base/mf-hk-field-validation.manager";

export class HasePremiumSummary implements AbstractPremiumSummary {
  public Can_toggle_manualUw(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    switch (proposal.productId?.toString().toUpperCase()) {
      case Const.ProductID.PRODUCT_ID_HASE_WEALTHPERSONALBANKING:
        return this.CanToggleManualUwByWpb(
          identityRolesService,
          proposal,
          proposalService
        );
      case Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING:
        return this.CanToggleManualUwByCmb(proposal, proposalService);
      default:
        return false;
    }
  }

  private CanToggleManualUwByWpb(
    identityRolesService: IdentityRolesService,
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    const isUnderwriter =
      identityRolesService.getIdentity().currentUser().userType ==
      Enum.UserType.Underwriter;

    const manual_uw = proposalService.compareQuestionAnswer(
      proposal!.qsMaster.qs_master_manualUnderwritting!
    );

    const canEditCommissionStatuses =
      proposal.proposalStatus === Enum.ProposalStatus.Incomplete ||
      proposal.proposalStatus === Enum.ProposalStatus.Referred ||
      proposal.proposalStatus === Enum.ProposalStatus.Approved ||
      proposal.proposalStatus === Enum.ProposalStatus.Declined;

    return isUnderwriter && canEditCommissionStatuses && manual_uw;
  }

  private CanToggleManualUwByCmb(
    proposal: MortgageFireHongKongProposal,
    proposalService: MfHKProposalService
  ): boolean {
    //do it outside
    // const manual_uw = proposalService.compareQuestionAnswer(
    //   proposal!.qsMaster.qs_master_manualUnderwritting!
    // );

    const isIncomplete =
      proposal.proposalStatus === Enum.ProposalStatus.Incomplete;
    return isIncomplete; // && manual_uw;
  }

  public getMinPremium(proposal: MortgageFireHongKongProposal): number {
    const isCMBProduct =
      proposal.productId?.toString().toUpperCase() ===
      Const.ProductID.PRODUCT_ID_HASE_COMMERCIALBANKING;

    if (isCMBProduct) {
      return this.getCmbMinPremium(proposal);
    } else {
      return this.getWpbMinPremium(proposal);
    }
  }

  private getWpbMinPremium(proposal: MortgageFireHongKongProposal): number {
    return -1000000000;
  }

  private getCmbMinPremium(proposal: MortgageFireHongKongProposal): number {
    if (
      proposal.transType === Enum.TransType.NEW && //DBSEP-7012
      this.isManualUW(proposal)
    ) {
      return MortgageFireHongKongFieldValidationManager.minPremium;
    } else {
      return -1000000000;
    }
  }

  isManualUW(proposal: MortgageFireHongKongProposal) {
    return (
      proposal.qsMaster.qs_master_manualUnderwritting!.answer === "True" ||
      proposal.qsMaster.qs_master_manualUnderwritting!.answer === true
    );
  }
}
