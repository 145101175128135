<div *ngIf="tabRequested" id="premiumSummaryTab">
  <div *ngIf="tabShow">
    <div class="row" id="premiumSummaryTabContainer">
      <div class="container-fluid">
        <kendo-expansionpanel
          id="premium_summary_panel"
          title="Premium Summary"
          [expanded]="true"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <form role="form">
                  <div class="form-group">
                    <percentage-box-v1-question
                      [question]="
                        proposal!.qsPremiumSummary.qs_master_techRate!
                      "
                      [noAnsCustomDefaultText]="'Default'"
                      [numberOfDecimalPlace]="6"
                    ></percentage-box-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form">
                  <div class="form-group">
                    <percentage-box-v1-question
                      [question]="
                        proposal!.qsPremiumSummary.qs_master_brokerCommission!
                      "
                    ></percentage-box-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form">
                  <div class="form-group">
                    <currency-box-v1-question
                      [question]="
                        proposal!.qsPremiumSummary.qs_master_commission
                      "
                    >
                    </currency-box-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3" *ngIf="!hideDiscountCode">
                <form role="form">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_master_discountCode!">
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <!-- <div class="col-md-3">
                <form role="form">
                  <div class="form-group">
                    <currency-box-v1-question
                      [question]="
                        proposal!.qsPremiumSummary.qs_master_commissionGst
                      "
                    >
                    </currency-box-v1-question>
                  </div>
                </form>
              </div> -->
            </div>
            <div class="row">
              <div class="col-md-12">
                <div id="CBGPreSumm" class="table-outter" style="padding: 1px">
                  <table
                    class="table table-bordered table-hover nowrap marginTop10 cursorNormal borderNone"
                  >
                    <thead>
                      <tr>
                        <td colspan="1" class="borderNone"></td>
                        <td
                          class="textAlignCenter chubbGray_bg chubbWhite [textContent]ing"
                          colspan="3"
                          [textContent]="poiText"
                        ></td>
                      </tr>
                      <tr class="chubbGray_bg chubbWhite">
                        <th class="textAlignLeft" style="width: 40%">
                          Coverage
                        </th>
                        <th class="textAlignCenter">Gross Premium</th>
                        <th class="textAlignCenter">Premium Levy</th>
                        <th class="textAlignCenter fontBold">Total Charge</th>
                      </tr>
                      <tr *ngIf="!(coverages.length > 0)">
                        <td colspan="4">
                          <div style="height: 30px">
                            <div class="margin5 textAlignCenter">
                              <span class="loadingBarText">No data found.</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody class="ng-scope" *ngIf="coverages.length > 0">
                      <tr
                        *ngIf="
                          (isIncomplete || isReferred) &&
                          !proposal!.IsCancellationAdjustment
                        "
                      >
                        <td>Property Fire</td>
                        <td class="textAlignRight">
                          <div
                            id="ifCase"
                            *ngIf="
                              canEditPremium(
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!
                              )
                            "
                          >
                            <!--has to use the autoCorrect option to enforce the min-max range; 
                              but this will cause the kendo-numerictextbox default to empty since it's not on min-max range,
                              hence use validation rule at: src\app\models\new-product\sme\mortgage-fire\hong-kong\base\mf-hk-field-validation.manager.ts
                            https://github.com/telerik/kendo-angular/issues/1910-->
                            <kendo-numerictextbox
                              id="cbg_fireGrossPremium"
                              name="cbg_fireGrossPremium"
                              [(ngModel)]="fireGrossPremium"
                              [min]="minPremium"
                              [max]="maxPremium"
                              [format]="'c2'"
                              [spinners]="false"
                              [decimals]="2"
                              class="textAlignCenter"
                              (blur)="
                                updatingHasePremium(
                                  fireGrossPremium,
                                  'fireGrossPremium'
                                )
                              "
                            >
                            </kendo-numerictextbox>
                          </div>
                          <div
                            id="elseCase"
                            *ngIf="
                              !canEditPremium(
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!
                              )
                            "
                          >
                            <span
                              class="textAlignCenter"
                              title="currency "
                              [textContent]="fireGrossPremium | ausCurrency : 2"
                            ></span>
                          </div>
                        </td>

                        <td class="textAlignRight">
                          <div>
                            <span
                              class="textAlignCenter"
                              title="currency "
                              [textContent]="fireServiceLevy | ausCurrency : 2"
                            ></span>
                          </div>
                        </td>
                        <td
                          class="textAlignRight"
                          [textContent]="fireTotalCharge | ausCurrency : 2"
                        ></td>
                      </tr>
                      <tr
                        *ngIf="
                          (!isIncomplete && !isReferred) ||
                          proposal!.IsCancellationAdjustment
                        "
                      >
                        <td>Property Fire</td>
                        <td
                          class="textAlignRight"
                          [textContent]="fireGrossPremium | ausCurrency : 2"
                        ></td>

                        <td
                          class="textAlignRight"
                          [textContent]="fireServiceLevy | ausCurrency : 2"
                        ></td>
                        <td
                          class="textAlignRight"
                          [textContent]="fireTotalCharge | ausCurrency : 2"
                        ></td>
                      </tr>
                      <tr>
                        <td
                          class="textAlignLeft fontBold totalCellBorder text-nowrap"
                          [textContent]="'Total'"
                        ></td>
                        <td
                          class="textAlignRight fontBold totalAmountBorder text-nowrap"
                          [textContent]="fireGrossPremium | ausCurrency : 2"
                        ></td>
                        <td
                          class="textAlignRight fontBold totalAmountBorder text-nowrap"
                          [textContent]="fireServiceLevy | ausCurrency : 2"
                        ></td>
                        <td
                          class="textAlignRight fontBold totalAmountBorder text-nowrap"
                          [textContent]="fireTotalCharge | ausCurrency : 2"
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div class="col-md-12" *ngIf="!isCBGProduct">
                <div id="IBGPreSumm" class="table-outter" style="padding: 1px">
                  <table
                    class="table table-bordered table-hover nowrap marginTop10 cursorNormal borderNone"
                  >
                    <thead>
                      <tr>
                        <td colspan="1" class="borderNone"></td>
                        <td
                          class="textAlignCenter chubbGray_bg chubbWhite ng-binding"
                          colspan="4"
                          [textContent]="poiText"
                          *ngIf="!proposal!.isUnderwriter"
                        ></td>
                        <td
                          class="textAlignCenter chubbGray_bg chubbWhite ng-binding"
                          colspan="5"
                          [textContent]="poiText"
                          *ngIf="proposal!.isUnderwriter"
                        ></td>
                      </tr>
                      <tr class="chubbGray_bg chubbWhite">
                        <th class="textAlignLeft" style="width: 40%">
                          Coverage
                        </th>
                        <th
                          class="textAlignCenter"
                          *ngIf="proposal!.isUnderwriter"
                        >
                          Premium Rate
                        </th>
                        <th class="textAlignCenter">Sum Insured</th>
                        <th class="textAlignCenter">Gross Premium</th>
                        <th class="textAlignCenter">GST</th>
                        <th class="textAlignCenter fontBold">Total Charge</th>
                      </tr>
                      <tr *ngIf="!(coverages.length > 0)">
                        <td colspan="6">
                          <div style="height: 30px">
                            <div class="margin5 textAlignCenter">
                              <span class="loadingBarText">No data found.</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody class="ng-scope" *ngIf="coverages.length > 0">
                      <ng-container *ngFor="let coverage of coverages">
                        <tr
                          *ngIf="
                            coverage.coverageProductMappingId ===
                            'f2e78506-3774-4c7d-92d7-c628d50c31e9'
                          "
                        >
                          <td
                            class="fontBold"
                            [textContent]="coverage.coverageName"
                          ></td>
                          <td
                            class="textAlignCenter"
                            *ngIf="proposal!.isUnderwriter"
                          >
                            <kendo-numerictextbox
                              *ngIf="
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] &&
                                isPremiumOverridingAllowed &&
                                !proposal!.IsCancellationAdjustment
                              "
                              id="rate_{{ coverage.coverageProductMappingId }}"
                              name="rate_{{
                                coverage.coverageProductMappingId
                              }}"
                              [(ngModel)]="coverage.premiumDetails.rate"
                              [min]="0"
                              [format]="'###.0000 \\%'"
                              [spinners]="false"
                              [decimals]="4"
                              class="textAlignCenter width98Per"
                              [ngClass]="{
                                chubbLightGreen_bg:
                                  editedDiv[
                                    'rate_' + coverage.coverageProductMappingId
                                  ]
                              }"
                              (blur)="
                                updatingPremiumDetails(
                                  coverage.coverageProductMappingId,
                                  coverage.premiumDetails.rate,
                                  'rate'
                                )
                              "
                            >
                            </kendo-numerictextbox>
                            <span
                              [textContent]="
                                coverage.premiumDetails.rate | percentage : 4
                              "
                              *ngIf="
                                (proposal!.isUnderwriter &&
                                  (proposal!.refreshedProposalEntity
                                    .proposalReadOnly ||
                                    !proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] ||
                                    proposal.IsCancellationAdjustment)) ||
                                proposal!.isBroker
                              "
                            ></span>
                            <div
                              id="resetRate_{{
                                coverage.coverageProductMappingId
                              }}"
                              style="position: relative"
                              *ngIf="
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] && isPremiumOverridingAllowed
                              "
                            >
                              <a
                                (click)="
                                  resetPremiumDetails(
                                    coverage.coverageProductMappingId,
                                    'rate'
                                  )
                                "
                                class="close"
                                style="
                                  position: absolute;
                                  right: 32px;
                                  color: red;
                                  opacity: 0.7;
                                  top: -30px;
                                  z-index: 1000;
                                "
                                [ngClass]="{ resetIconIE: resetIconIE }"
                                title="Reset"
                                >×</a
                              >
                            </div>
                          </td>
                          <td class="textAlignRight">
                            <kendo-numerictextbox
                              *ngIf="
                                !isEndorse &&
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] &&
                                isPremiumOverridingAllowed &&
                                !proposal!.IsCancellationAdjustment
                              "
                              id="coverage.premiumDetails.sumInsured"
                              name="coverage.premiumDetails.sumInsured"
                              [(ngModel)]="coverage.premiumDetails.sumInsured"
                              [min]="0"
                              [format]="'c2'"
                              [spinners]="false"
                              [decimals]="4"
                              class="textAlignCenter width98Per"
                              [ngClass]="{
                                chubbLightGreen_bg:
                                  editedDiv[
                                    'sumInsured_' +
                                      coverage.coverageProductMappingId
                                  ]
                              }"
                              (blur)="
                                updatingPremiumDetails(
                                  coverage.coverageProductMappingId,
                                  coverage.premiumDetails.sumInsured,
                                  'sumInsured'
                                )
                              "
                            ></kendo-numerictextbox>
                            <span
                              [textContent]="
                                coverage.premiumDetails.sumInsured
                                  | ausCurrency : 2
                              "
                              *ngIf="
                                isEndorse ||
                                (proposal!.isUnderwriter &&
                                  (proposal!.refreshedProposalEntity
                                    .proposalReadOnly ||
                                    !proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] ||
                                    proposal.IsCancellationAdjustment)) ||
                                proposal!.isBroker
                              "
                            ></span>
                            <div
                              id="resetSumInsured_{{
                                coverage.coverageProductMappingId
                              }}"
                              style="position: relative"
                              *ngIf="
                                !isEndorse &&
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] &&
                                isPremiumOverridingAllowed
                              "
                            >
                              <a
                                (click)="
                                  resetPremiumDetails(
                                    coverage.coverageProductMappingId,
                                    'sumInsured'
                                  )
                                "
                                class="close"
                                style="
                                  position: absolute;
                                  right: 10px;
                                  color: red;
                                  opacity: 0.7;
                                  top: -30px;
                                  z-index: 1000;
                                "
                                [ngClass]="{ resetIconIE: resetIconIE }"
                                title="Reset"
                                >×</a
                              >
                            </div>
                          </td>
                          <td class="textAlignRight">
                            <kendo-numerictextbox
                              *ngIf="
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] &&
                                isPremiumOverridingAllowed &&
                                !proposal!.IsCancellationAdjustment
                              "
                              id="premium_{{
                                coverage.coverageProductMappingId
                              }}"
                              name="premium_{{
                                coverage.coverageProductMappingId
                              }}"
                              [(ngModel)]="coverage.premiumDetails.premium"
                              [min]="minPremium"
                              [max]="maxPremium"
                              [format]="'c2'"
                              [spinners]="false"
                              [decimals]="4"
                              class="textAlignCenter width98Per"
                              [ngClass]="{
                                chubbLightGreen_bg:
                                  editedDiv[
                                    'premium_' +
                                      coverage.coverageProductMappingId
                                  ]
                              }"
                              (blur)="
                                updatingPremiumDetails(
                                  coverage.coverageProductMappingId,
                                  coverage.premiumDetails.premium,
                                  'premium'
                                )
                              "
                            ></kendo-numerictextbox>
                            <span
                              [textContent]="
                                coverage.premiumDetails.premium
                                  | ausCurrency : 2
                              "
                              *ngIf="
                                (proposal!.isUnderwriter &&
                                  (proposal!.refreshedProposalEntity
                                    .proposalReadOnly ||
                                    !proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] ||
                                    proposal.IsCancellationAdjustment)) ||
                                proposal!.isBroker
                              "
                            ></span>
                            <div
                              id="resetPremium_{{
                                coverage.coverageProductMappingId
                              }}"
                              style="position: relative"
                              *ngIf="
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] && isPremiumOverridingAllowed
                              "
                            >
                              <a
                                (click)="
                                  resetPremiumDetails(
                                    coverage.coverageProductMappingId,
                                    'premium'
                                  )
                                "
                                class="close"
                                style="
                                  position: absolute;
                                  right: 10px;
                                  color: red;
                                  opacity: 0.7;
                                  top: -30px;
                                  z-index: 1000;
                                "
                                [ngClass]="{ resetIconIE: resetIconIE }"
                                title="Reset"
                                >×</a
                              >
                            </div>
                          </td>
                          <td class="textAlignRight">
                            <span
                              [textContent]="
                                coverage.premiumDetails.premiumTax
                                  | ausCurrency : 2
                              "
                            ></span>
                          </td>
                          <td class="textAlignRight">
                            <span
                              [textContent]="
                                coverage.premiumDetails.totalPremium
                                  | ausCurrency : 2
                              "
                            ></span>
                          </td></tr
                      ></ng-container>
                      <tr *ngIf="containEquipment">
                        <td
                          class="fontBold"
                          colspan="6 "
                          [textContent]="'Machinery Equipment(s)'"
                        ></td>
                      </tr>
                      <ng-container *ngFor="let coverage of coverages">
                        <tr
                          *ngIf="
                            coverage.coverageProductMappingId !==
                            'f2e78506-3774-4c7d-92d7-c628d50c31e9'
                          "
                        >
                          <td
                            class="paddingLeft30"
                            [textContent]="coverage.coverageName"
                          ></td>
                          <td
                            class="textAlignCenter"
                            *ngIf="proposal!.isUnderwriter"
                          >
                            <kendo-numerictextbox
                              *ngIf="
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] &&
                                isPremiumOverridingAllowed &&
                                !proposal!.IsCancellationAdjustment
                              "
                              id="rate_{{ coverage.coverageProductMappingId }}"
                              name="rate_{{
                                coverage.coverageProductMappingId
                              }}"
                              [(ngModel)]="coverage.premiumDetails.rate"
                              [min]="0"
                              [format]="'###.0000 \\%'"
                              [spinners]="false"
                              [decimals]="4"
                              class="textAlignCenter width98Per"
                              [ngClass]="{
                                chubbLightGreen_bg:
                                  editedDiv[
                                    'rate_' + coverage.coverageProductMappingId
                                  ]
                              }"
                              (blur)="
                                updatingPremiumDetails(
                                  coverage.coverageProductMappingId,
                                  coverage.premiumDetails.rate,
                                  'rate'
                                )
                              "
                            >
                            </kendo-numerictextbox>
                            <span
                              [textContent]="
                                coverage.premiumDetails.rate | percentage : 4
                              "
                              *ngIf="
                                (proposal!.isUnderwriter &&
                                  (proposal!.refreshedProposalEntity
                                    .proposalReadOnly ||
                                    !proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] ||
                                    proposal.IsCancellationAdjustment)) ||
                                proposal!.isBroker
                              "
                            ></span>
                            <div
                              id="resetRate_{{
                                coverage.coverageProductMappingId
                              }}"
                              style="position: relative"
                              *ngIf="
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] && isPremiumOverridingAllowed
                              "
                            >
                              <a
                                (click)="
                                  resetPremiumDetails(
                                    coverage.coverageProductMappingId,
                                    'rate'
                                  )
                                "
                                class="close"
                                style="
                                  position: absolute;
                                  right: 32px;
                                  color: red;
                                  opacity: 0.7;
                                  top: -30px;
                                  z-index: 1000;
                                "
                                [ngClass]="{ resetIconIE: resetIconIE }"
                                title="Reset"
                                >×</a
                              >
                            </div>
                          </td>
                          <td class="textAlignRight">
                            <kendo-numerictextbox
                              *ngIf="
                                !isEndorse &&
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] &&
                                isPremiumOverridingAllowed &&
                                !proposal!.IsCancellationAdjustment
                              "
                              id="coverage.premiumDetails.sumInsured"
                              name="coverage.premiumDetails.sumInsured"
                              [(ngModel)]="coverage.premiumDetails.sumInsured"
                              [min]="0"
                              [format]="'c2'"
                              [spinners]="false"
                              [decimals]="4"
                              class="textAlignCenter width98Per"
                              [ngClass]="{
                                chubbLightGreen_bg:
                                  editedDiv[
                                    'sumInsured_' +
                                      coverage.coverageProductMappingId
                                  ]
                              }"
                              (blur)="
                                updatingPremiumDetails(
                                  coverage.coverageProductMappingId,
                                  coverage.premiumDetails.sumInsured,
                                  'sumInsured'
                                )
                              "
                            ></kendo-numerictextbox>
                            <span
                              [textContent]="
                                coverage.premiumDetails.sumInsured
                                  | ausCurrency : 2
                              "
                              *ngIf="
                                isEndorse ||
                                (proposal!.isUnderwriter &&
                                  (proposal!.refreshedProposalEntity
                                    .proposalReadOnly ||
                                    !proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] ||
                                    proposal.IsCancellationAdjustment)) ||
                                proposal!.isBroker
                              "
                            ></span>
                            <div
                              id="resetSumInsured_{{
                                coverage.coverageProductMappingId
                              }}"
                              style="position: relative"
                              *ngIf="
                                !isEndorse &&
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] &&
                                isPremiumOverridingAllowed
                              "
                            >
                              <a
                                (click)="
                                  resetPremiumDetails(
                                    coverage.coverageProductMappingId,
                                    'sumInsured'
                                  )
                                "
                                class="close"
                                style="
                                  position: absolute;
                                  right: 10px;
                                  color: red;
                                  opacity: 0.7;
                                  top: -30px;
                                  z-index: 1000;
                                "
                                [ngClass]="{ resetIconIE: resetIconIE }"
                                title="Reset"
                                >×</a
                              >
                            </div>
                          </td>
                          <td class="textAlignRight">
                            <kendo-numerictextbox
                              *ngIf="
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] &&
                                isPremiumOverridingAllowed &&
                                !proposal!.IsCancellationAdjustment
                              "
                              id="premium_{{
                                coverage.coverageProductMappingId
                              }}"
                              name="premium_{{
                                coverage.coverageProductMappingId
                              }}"
                              [(ngModel)]="coverage.premiumDetails.premium"
                              [min]="minPremium"
                              [max]="maxPremium"
                              [format]="'c2'"
                              [spinners]="false"
                              [decimals]="4"
                              class="textAlignCenter width98Per"
                              [ngClass]="{
                                chubbLightGreen_bg:
                                  editedDiv[
                                    'premium_' +
                                      coverage.coverageProductMappingId
                                  ]
                              }"
                              (blur)="
                                updatingPremiumDetails(
                                  coverage.coverageProductMappingId,
                                  coverage.premiumDetails.premium,
                                  'premium'
                                )
                              "
                            ></kendo-numerictextbox>
                            <span
                              [textContent]="
                                coverage.premiumDetails.premium
                                  | ausCurrency : 2
                              "
                              *ngIf="
                                (proposal!.isUnderwriter &&
                                  (proposal!.refreshedProposalEntity
                                    .proposalReadOnly ||
                                    !proposalService.proposal.auxiliary[
                                      'isPremiumManualOverridden'
                                    ] ||
                                    proposal.IsCancellationAdjustment)) ||
                                proposal!.isBroker
                              "
                            ></span>
                            <div
                              id="resetPremium_{{
                                coverage.coverageProductMappingId
                              }}"
                              style="position: relative"
                              *ngIf="
                                proposalService.proposal.auxiliary[
                                  'isPremiumManualOverridden'
                                ] && isPremiumOverridingAllowed
                              "
                            >
                              <a
                                (click)="
                                  resetPremiumDetails(
                                    coverage.coverageProductMappingId,
                                    'premium'
                                  )
                                "
                                class="close"
                                style="
                                  position: absolute;
                                  right: 10px;
                                  color: red;
                                  opacity: 0.7;
                                  top: -30px;
                                  z-index: 1000;
                                "
                                [ngClass]="{ resetIconIE: resetIconIE }"
                                title="Reset"
                                >×</a
                              >
                            </div>
                          </td>
                          <td class="textAlignRight">
                            <span
                              [textContent]="
                                coverage.premiumDetails.premiumTax
                                  | ausCurrency : 2
                              "
                            ></span>
                          </td>
                          <td class="textAlignRight">
                            <span
                              [textContent]="
                                coverage.premiumDetails.totalPremium
                                  | ausCurrency : 2
                              "
                            ></span>
                          </td>
                        </tr>
                      </ng-container>
                      <tr class="textAlignLeft totalCellBorder text-nowrap">
                        <td
                          class="textAlignLeft totalCellBorder text-nowrap clearBoth"
                        >
                          <div class="inlineBlock fontBold floatLeft">
                            <span style="vertical-align: sub">Total</span>
                            <span
                              class="fa chubbGreen marginRight5"
                              [ngClass]="{
                                'fa-lock':
                                  isPremiumOverridingAllowed &&
                                  !proposalService.proposal.auxiliary[
                                    'isPremiumManualOverridden'
                                  ],
                                'fa-unlock': isPremiumOverridingAllowed
                              }"
                              style="
                                vertical-align: sub;
                                margin-bottom: 0;
                                margin-left: 10px;
                              "
                            ></span>
                          </div>
                          <div
                            class="inlineBlock floatRight"
                            *ngIf="
                              isPremiumOverridingAllowed &&
                              !proposalService.proposal.auxiliary[
                                'isPremiumManualOverridden'
                              ]
                            "
                          >
                            <button
                              class="chubbGreen_border_btn chubbWhite_bg chubbGreen btn-box marginBottom0"
                              (click)="allowPremiumToEdit()"
                              style="cursor: pointer"
                            >
                              <span
                                class="fa fa-unlock chubbGreen marginRight5"
                                style="
                                  vertical-align: baseline;
                                  margin-bottom: 0;
                                "
                              ></span
                              >UnLock Premium
                            </button>
                          </div>
                          <div
                            class="inlineBlock floatRight"
                            *ngIf="
                              !isPremiumOverridingAllowed &&
                              proposalService.proposal.auxiliary[
                                'isPremiumManualOverridden'
                              ]
                            "
                          >
                            <span
                              class="fa fa-unlock chubbGreen marginRight5"
                              style="vertical-align: baseline; margin-bottom: 0"
                            ></span
                            >UnLocked Premium
                          </div>
                        </td>
                        <td
                          class="textAlignRight fontBold"
                          *ngIf="!proposal!.isUnderwriter"
                        ></td>
                        <td
                          class="textAlignRight fontBold"
                          colspan="2 "
                          *ngIf="proposal!.isUnderwriter"
                        ></td>
                        <td
                          class="textAlignRight fontBold"
                          [textContent]="premiumSummary.premium | ausCurrency"
                        ></td>
                        <td
                          class="textAlignRight fontBold"
                          [textContent]="
                            premiumSummary.premiumTax | ausCurrency
                          "
                        ></td>
                        <td
                          class="textAlignRight fontBold"
                          [textContent]="
                            premiumSummary.totalPremium | ausCurrency
                          "
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
            </div>
            <div class="row floatRight">
              <div class="col-md-12 fontBold" style="font-size: 11px">
                <em>all amounts in HKD unless otherwise specified.</em>
              </div>
            </div>
          </div>
        </kendo-expansionpanel>
      </div>
    </div>
  </div>
</div>
