import { Component, OnInit } from "@angular/core";
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { dlgModelArgs } from "../../shared/partials/md-successful-file-upload/md-successful-file-upload.component";
import { Global } from "src/app/shared/global";
import { UploadExcel } from "../auto-loan-redemption/auto-loan-redemption.entity";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_HOME } from "src/app/app.module";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-multi-year-auto-debit-upload",
  templateUrl: "./multi-year-auto-debit-upload.component.html",
  styleUrls: ["./multi-year-auto-debit-upload.component.scss"],
})
export class MultiYearAutoDebitUploadComponent implements OnInit {
  uploadExcel!: UploadExcel;
  excelFilesErrorMessage: string = "";
  hasError: boolean = false;
  isProcessing: boolean = false;

  // Error logs
  errorLogs!: { Title: string; Logs: string[] };

  public redirectOpened = false;
  redirectMessage: any;
  redirectData: any;
  sub: any;

  constructor(
    private uploadService: UploadExcelService,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.uploadExcel = new UploadExcel();

    this.sub = this.route.data.subscribe(
      (v) => (this.uploadExcel.segmentDetails = v["type"]) //read from route param, as in app.route.js
    );

    // Init Error Log
    if (Global.isUndefined(this.errorLogs)) {
      this.errorLogs = { Title: "", Logs: new Array<string>() };
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  excelAndSizeRestrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
    maxFileSize: 5242880, // Check for each file size less than 5MB, 1024 * 1024 * 5
  };

  public resetFiles(): void {
    //todo: currently only allow single file upload
    // Clear uploaded file once, the file is successfuly uploaded.
    this.uploadExcel.files = {};
  }

  public removeEvent(e: RemoveEvent): void {
    this.resetFiles();
  }

  public select(e: SelectEvent): void {
    this.resetFiles();

    this.hasError = false;

    let totalFileSize = this.getUploadedClaimFilesTotalSize(e.files);
    if (totalFileSize / 1024 / 1024 > 20) {
      this.hasError = true;
      this.excelFilesErrorMessage =
        "Total file size allowed in a claim is 20MB.";
    } else {
      const uploadExcels: File[] = new Array<File>();
      e.files.forEach((file: FileInfo) => {
        //this already check extension & individual file size by kendo control itself
        if (!file.validationErrors && file.rawFile) {
          uploadExcels.push(file.rawFile);
        }
      });
      if (this.uploadExcel != undefined && this.uploadExcel != null) {
        this.uploadExcel.files = uploadExcels;
      }
    }
  }

  getUploadedClaimFilesTotalSize(files: FileInfo[]): number {
    let totalFileSize: number = 0;

    files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        totalFileSize += file.rawFile.size;
      }
    });

    return totalFileSize;
  }

  add(): void {
    this.hasError = false;

    if (this.uploadExcel?.files?.length > 0) {
      this.addExcel();
    } else {
      this.excelFilesErrorMessage = "At least one file needs to be uploaded.";
      this.hasError = true;
    }
  }

  getUploadedExcelFiles(uploadedExcel: UploadExcel): FormData {
    const formData: FormData = new FormData();
    for (let i = 0; i < uploadedExcel.files.length; i++) {
      formData.append("file" + i.toString(), uploadedExcel.files[i]);
    }
    return formData;
  }

  addExcel() {
    this.isProcessing = true;

    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);
    uploadedExcelFormData.append(
      "selectedSection",
      JSON.parse(JSON.stringify(this.uploadExcel.segmentDetails))
    );
    this.uploadService
      .uploadMultiYearAutoDebit(uploadedExcelFormData)
      .subscribe(
        (result: any) => {
          let tempData: any = null;
          //no longer result.data as in angularJs but result.body
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            if (result.status === 200) {
              tempData = {
                message: "Upload successful.",
                status: true,
              };
              this.multiyearAutoDebitSummaryReport("Annual");
              this.multiyearAutoDebitSummaryReport("Multiyear");
            } else {
              let message: string = "";
              switch (result.status) {
                case 401:
                  message = "Unauthorized document download";
                  break;
                default:
                  message =
                    "Document download response with unexpected status: " +
                    result.status.toString();
                  break;
              }

              tempData = {
                message: message,
                validationMessage: result.body.ValidationMessage,
                status: false,
              };
            }

            // Clear uploaded file once, the file is successfuly uploaded.
            //this.uploadExcel.files = {};//maybe no need to clear, just like original
            this.redirectOpened = true;
            this.redirectData = tempData;
            this.isProcessing = false;
          }
        },
        (error: any) => {
          let tempData: any = {
            message:
              "Failed to upload excel file. Please contact the system administrator.",
            validationMessage: undefined,
            status: false,
          };
          this.redirectOpened = true;
          this.redirectData = tempData;
          this.isProcessing = false;
        }
      );
  }

  async multiyearAutoDebitSummaryReport(reportType: string) {
    var url = "api/product/home/MultiyearAutoDebit/generateADSummaryReport";
    let tempData: any = null;
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const params = {
      reportType: reportType,
    };

    return this.httpClient
      .get(environment.serviceBase + url, {
        headers: headers,
        params: params,
        responseType: "arraybuffer",
        observe: "response",
      })
      .subscribe(
        (result: any) => {
          const fileName: string = result.headers.get("x-filename");
          this.uploadService.generateAnchorAndTriggerDownload(
            result.body,
            fileName
          );
        },
        (error: any) => {
          let message: string = "";
          switch (error.status) {
            case 401:
              message = "Unauthorized document download";
              break;
            default:
              message =
                "Document download response with unexpected status: " +
                error.status.toString();
              break;
          }
          tempData = {
            message: message,
            validationMessage: undefined,
            status: false,
          };
          this.redirectOpened = true;
          this.redirectData = tempData;
          this.isProcessing = false;
        }
      );
  }

  public confirmationModelForSuccessullExcelUpload(args: dlgModelArgs) {
    this.redirectOpened = false;
    if (args.status === "return") {
      //todo
      this.router.navigate(["/" + ROUTE_HOME]);
      //this.$state.go('product.home', { reload: true });
    }
  }
}
