import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "../../partials/dialog/dialog-ref";
import { DIALOG_DATA } from "../../partials/dialog/dialog-tokens";
import { v1DlgInputArgs } from "../../partials/md-confirmation-message-v1/md-confirmation-message-v1.component";

@Component({
  selector: "address-help-form",
  templateUrl: "./address-help-form.component.html",
  styleUrls: ["./address-help-form.component.scss"],
})
export class AddressHelpFormComponent implements OnInit {
  showGeoCode = true;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any
  ) {
    if (this.inputData) {
      this.showGeoCode = this.inputData.showGeoCode;
    }
  }

  ngOnInit(): void {}

  public close(status: any) {
    this.dialogRef?.close(null);
  }
}
