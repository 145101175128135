import { ConfigurableMode } from "src/app/framework/enum.shared";

export class MfHkConfigAddCriteria {
  constructor() {
    this.coverageTypes = new Array<string>();
    this.code = "";
    this.title = "";
    this.editorValue = "";
  }

  coverageTypes: Array<string>;
  code?: string;
  title?: string;
  default: boolean = false;
  editorValue: string;
  typeId: ConfigurableMode = ConfigurableMode.Clauses;
}
