import { Component, OnInit, Input } from "@angular/core";
import { common } from "src/app/framework/utils/common";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { UserProfileService } from "src/app/services/user-profile.service";
import { ApiModel, UserDetailsModel } from "../../user-profile.model";
import * as constants from "../../user-profile.constant";

@Component({
  selector: "app-view-user-profile",
  templateUrl: "./view-user-profile.component.html",
  styleUrls: ["./view-user-profile.component.scss"],
})
export class ViewUserProfileComponent implements OnInit {
  isDataLoaded: boolean = false;
  model = new ApiModel();
  userDetails = new UserDetailsModel();
  title!: string;
  @Input() accessControlList!: any;
  @Input() data!: any;
  @Input() teamsData!: any;
  constructor(
    private userProfileService: UserProfileService,
    public identityRolesService: IdentityRolesService
  ) {}
  ngOnInit(): void {
    if (this.identityRolesService.isThailandUser) this.title = "System";
    else this.title = "Products";
    this.getUserProfile();
  }
  public getUserProfile(): void {
    if (common.isDefined(this.data?.userId) && this.data?.userId != "") {
      this.isDataLoaded = true;
      this.userProfileService
        .searchUserProfileByUserId(this.data.userId)
        .subscribe(
          (response) => {
            if (response && response.body) {
              this.model = response.body;
              this.userDetails = this.model.userProfile.userDetails;
              if (this.model.userType == 1)
                this.model.userTypeDesc = "System Maintenance";
              this.loadCountry();
              this.loadTeams();
              this.loadAccessControlGroup();
              this.isDataLoaded = false;
            }
          },
          (err: any) => {
            this.isDataLoaded = false;
          }
        );
    }
  }
  //Load country based on the selected usertype
  private loadCountry(): void {
    constants.countryData.forEach((item) => {
      if (this.userDetails.userCountry === item.value) {
        this.userDetails.displayUserCountry = item.text;
        this.userDetails.userCountry = item.value;
      }
    });
  }
  private loadTeams(): void {
    this.teamsData.forEach((item: any) => {
      if (this.userDetails.teamId === item.value) {
        this.userDetails.userTeam = item.text;
      }
    });
  }
  private loadAccessControlGroup(): void {
    this.accessControlList.forEach((item: any) => {
      if (this.userDetails.accessGroupId === item.value) {
        this.userDetails.displayAccessGroup = item.text;
      }
    });
  }
}
