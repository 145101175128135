import { Component, OnInit } from "@angular/core";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { common } from "src/app/framework/utils/common";
import { DialogWrapperService } from "src/app/services/dialog/dialog-wrapper.service";
import { UserProfileService } from "src/app/services/user-profile.service";
import { UserProfileList } from "../user-profile.model";
import { ViewUserComponent } from "../view-user/view-user.component";
import { NotifierService } from "src/app/services/notifier.service";
import { UserSuccessComponent } from "../user-success/user-success.component";

@Component({
  selector: "app-search-user",
  templateUrl: "./search-user.component.html",
  styleUrls: ["./search-user.component.scss"],
})
export class SearchUserComponent implements OnInit {
  userListModel = new UserProfileList();
  constructor(
    private userProfile: UserProfileService,
    private dialogWrapperService: DialogWrapperService
  ) {}
  ngOnInit(): void {}
  userName = "";
  userSearchText = "";
  userListLoaded = false;
  contentLoaded = false;
  organisationListLoaded = false;
  productListLoaded = false;
  hasResult = false;

  showSearchTextMsg = false;
  showNoResultsMsg = false;
  searchText = "";
  getUserProfileDetails(userId: any, organisationId: any, mode: any) {
    var data = {
      userId: userId,
      organisationId: organisationId,
      mode: mode,
    };
    this.dialogWrapperService
      .modalLarge(
        "view-user.component.html",
        ViewUserComponent,
        data,
        undefined,
        undefined,
        null,
        null,
        false,
        "slidingModal"
      )
      .subscribe((result: any) => {
        if (result instanceof DialogCloseResult) {
        } else {
          this.confirmationProductModalClose(result);
        }
      });
  }

  confirmationProductModalClose = (data: any) => {
    if (data === "success") {
      this.dialogWrapperService
        .modalSmall(
          "user-success.component.html",
          UserSuccessComponent,
          "", //todo
          undefined,
          undefined,
          null,
          null,
          false,
          ""
        )
        .subscribe((result: any) => {});
    }
  };

  clearContent(): void {
    this.userListModel.Items = [];
    this.hasResult = false;
    this.showNoResultsMsg = false;
    this.showSearchTextMsg = false;
  }

  //method which will be used for searching the user based on the searchText and organisation name
  searchUser(): void {
    this.userListLoaded = true;
    this.userProfile.searchUserList(this.searchText).subscribe(
      (model: any) => {
        if (common.isObject(model) && model.body.Items.length > 0) {
          this.userListModel = model.body;
          this.showNoResultsMsg = false;
        } else if (common.isObject(model) && model.body.Items.length == 0) {
          //When no records
          this.userListModel.Items = []; //setting the scope to empty if no records
          this.showNoResultsMsg = true;
        }
        this.hasResult = true;
        this.userListLoaded = false;
      },
      (err: any) => {
        this.userListLoaded = false;
        this.showNoResultsMsg = false;
      }
    );
  }
}
