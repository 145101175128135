import { GUID } from "./../../../../../../framework/domain-entity/guid";

class MortgageFireSingaporeSettings {
  hdbProductId = new GUID("2396885E-49F6-43F6-A314-47D727AB888F");
  tpeProductId = new GUID("6FAF6BEE-F5FC-4DC2-9F75-37FE2D61611D");
  mipProductId = new GUID("565E9075-9F73-45C0-BA5C-A78AC05BA837");
  ibgMFProductId = new GUID("06CB09D3-6FE0-4695-92A8-A61DFB627C61");
  ibgMacProductId = new GUID("8533FA05-F190-4A29-9613-3DB7F693DD32");
  citiHdbProductId = new GUID("A8CDAC65-3EC4-4138-974A-D18C3EF8536C");
  citiMFPTEProductId = new GUID("1F7EE805-C8CE-416C-AF29-292E920878F8");
  citiMFCondoProductID = new GUID("B8A348CA-491C-48DA-988A-FCC52F7FC5C5");

  workspace: string = "library/module/new-product/sme/mortgage-fire/singapore";

  MAR_ConstructionMachineryType = "5372b784-9f92-4591-adfe-0ddffc1baa7f";
  MAR_CranesMachineryType = "9b25f776-4983-4014-bc04-1c9029e99911";
  MAR_IndustrialMachineryType = "abd448d1-7fa5-4e7c-bacf-0afb4c581805";

  ARC_ConstructionMachineryType = "3ca94a88-7792-4c71-9594-61d29f339317";
  ARC_MedicalMachineryType = "53d4868c-9b5a-4dd3-ac74-1cf4b95ff67d";
  ARC_OfficeMachineryType = "ef3e4f53-7dbc-46f3-8fa2-1f5017e2ee0d";
  ARC_IndustrialMachineryType = "ed4c42ef-dc3b-4959-808f-0be6c4946727";
}

export const mortgageFireSingaporeSettings: MortgageFireSingaporeSettings =
  new MortgageFireSingaporeSettings();
