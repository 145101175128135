import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "../dialog/dialog-ref";
import { DIALOG_DATA } from "../dialog/dialog-tokens";
import {
  dlgModelArgs,
  v1DlgInputArgs,
} from "../md-confirmation-message-v1/md-confirmation-message-v1.component";

@Component({
  selector: "md-information-message",
  templateUrl: "./md-information-message.component.html",
  styleUrls: ["./md-information-message.component.scss"],
})
export class MdInformationMessageComponent implements OnInit {
  public data: any;
  isProcessing = false;
  model = {};

  okText = "OK";
  isDisabled = false;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: v1DlgInputArgs
  ) {}

  ngOnInit(): void {
    if (this.inputData) {
      this.data = this.inputData;
    }
  }

  public close(status: any) {
    let savParamters: dlgModelArgs = {
      status: status,
      data: this.data,
    };

    this.dialogRef?.close(savParamters);
  }
}
