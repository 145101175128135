<div id="bankTransFields" class="reportOption container-fluid">
  <div *ngIf="showReportType" class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="mt-1">Product Type</label>
        </div>
        <div class="col-md-2">
          <input
            type="radio"
            name="productRadio"
            value="dbs"
            [(ngModel)]="productRadioControl"
            (change)="onChange_ProductRadio()"
          />
          <label class="mt-1 ml-1" for="oiProductRadio">DBS</label>
        </div>
        <div class="col-md-3">
          <input
            type="radio"
            name="productRadio"
            value="citi"
            [(ngModel)]="productRadioControl"
            (change)="onChange_ProductRadio()"
          />
          <label class="mt-1 ml-1" for="oiProductRadio">Citi</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row" *ngIf="!isCitiProduct">
        <div class="col-md-3">
          <label class="mt-1">Segment</label>
        </div>
        <div class="col-md-9">
          <kendo-multiselect
            id="reportSegment"
            placeholder="Select segment..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="segmentList"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.segments"
            name="segments"
          >
          </kendo-multiselect>
        </div>
      </div>
      <div class="form-group row" *ngIf="isCitiProduct">
        <div class="col-md-3">
          <label class="mt-1">Transaction Type</label>
        </div>
        <div class="col-md-9">
          <kendo-multiselect
            id="reportSegment"
            placeholder="Select Transaction Type..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="transactionTypeOption"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.transactionType"
            name="segments"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-offset-1 col-md-3">
          <label class="mt-1" for="reportDateFrom">Transaction Date</label>
        </div>
        <div class="col-md-4">
          <kendo-datepicker
            class="maxWidth"
            id="reportDateFrom"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="searchCriteria.dateFrom"
            (valueChange)="on_transReportDateFrom_change($event)"
          ></kendo-datepicker>
        </div>
        <div class="col-md-1 textAlignCenter">
          <label class="mt-1" for="reportDateTo">to</label>
        </div>
        <div class="col-md-4">
          <kendo-datepicker
            class="maxWidth"
            id="reportDateTo"
            #reportDateTo
            format="dd/MM/yyyy"
            placeholder=""
            [min]="transDate_min"
            [max]="transDate_max"
            [(ngModel)]="searchCriteria.dateTo"
          ></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-md-3">
          <label class="mt-1" for="reportProduct">Product</label>
        </div>
        <div class="col-md-9">
          <kendo-multiselect
            id="reportProduct"
            placeholder="Select product..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="userProductList"
            textField="pd"
            valueField="pdid"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.productIds"
            name="productIds"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row" *ngIf="isCitiProduct">
        <div class="col-md-offset-1 col-md-3">
          <label class="mt-1" for="reportTransDateFrom">Effective Date</label>
        </div>
        <div class="col-md-4">
          <kendo-datepicker
            class="maxWidth"
            id="reportTransDateFrom"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="searchCriteria.effectiveDateFrom"
            (valueChange)="on_DailyreportDateFrom_change($event)"
            name="reportTransDateFrom"
            #name="ngModel"
          ></kendo-datepicker>
        </div>
        <div class="col-md-1 textAlignCenter">
          <label class="mt-1" for="reportTransDateTo">to</label>
        </div>
        <div class="col-md-4">
          <kendo-datepicker
            class="maxWidth"
            id="reportTransDateTo"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="searchCriteria.effectiveDateTo"
            name="reportTransDateTo"
            #name="ngModel"
          ></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row" *ngIf="isCitiProduct">
        <div class="col-md-3">
          <label class="mt-1">Customer Type</label>
        </div>
        <div class="col-md-9">
          <kendo-multiselect
            id="reportSegment"
            placeholder="Select Customer Type..."
            [autoClose]="false"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="customerTypes"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.customerType"
            name="segments"
          >
          </kendo-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      (click)="download()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
    </button>
  </div>
</div>
