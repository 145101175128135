<!-- <div class="menu-container"> -->
<!-- <kendo-menu [items]="items" (select)="onSelect($event)"></kendo-menu> -->
<ul class="ulheader" [hidden]="hideMenus">
  <li class="noHover menulist">E-placement℠</li>
  <li
    class="menulist-no-padding"
    *ngIf="identityRolesService.hasProductReadWriteRoles"
  >
    <a routerLink="product/home">Home <i class="fa fa-home"></i></a>
  </li>
  <li
    class="menulist-no-padding"
    *ngIf="identityRolesService.hasProductTransactRoles"
  >
    <a href="#" (click)="btnNew_Onclick($event)">New</a>
  </li>
  <li
    class="dropdown menulist-no-padding"
    *ngIf="identityRolesService.isFinanceAccounting"
  >
    <a class="dropbtn">Accounting<span class="caret"></span></a>
    <div class="dropdown-content collapse show" id="accountingMenuRoot">
      <a
        routerLink="openItem"
        data-toggle="collapse"
        data-target="#accountingMenuRoot"
        *ngIf="isSgProduct || isHkProduct"
        >Open Item</a
      >
      <a
        routerLink="batchInvoice"
        data-toggle="collapse"
        data-target="#accountingMenuRoot"
        *ngIf="isSgProduct"
        >Batch Invoice</a
      >
      <a
        routerLink="reconcile"
        data-toggle="collapse"
        data-target="#accountingMenuRoot"
        *ngIf="isSgProduct || isHkProduct"
        >Reconcile</a
      >
      <a
        routerLink="reviewRefundUpload"
        data-toggle="collapse"
        data-target="#accountingMenuRoot"
        *ngIf="isSgProduct"
        >Bulk Refund in Review Upload</a
      >
      <a
        routerLink="refundUpload"
        data-toggle="collapse"
        data-target="#accountingMenuRoot"
        *ngIf="isSgProduct"
        >Bulk Refund Upload</a
      >
      <a
        routerLink="dbsAccountingGSTAdjustmentUpload"
        data-toggle="collapse"
        data-target="#accountingMenuRoot"
        *ngIf="isSgProduct"
        >GST Adjustment Upload</a
      >
    </div>
  </li>

  <li
    class="dropdown menulist-no-padding"
    *ngIf="
      identityRolesService.isUnderwriter && identityRolesService.isIBGProductTab
    "
  >
    <a class="dropbtn">Product<span class="caret"></span></a>
    <div class="dropdown-content collapse show" id="productMenuRoot">
      <a
        routerLink="uploadExcelCreate"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        >IBG (SG) - Upload</a
      >
      <a
        routerLink="rmscreen"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        >RM Screen</a
      >
      <a
        routerLink="lstRate"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        >LST Rate</a
      >
      <a
        routerLink="autoLoanRedemption?type=CBG"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        >CBG Loan Redemption - Upload</a
      >
      <a
        routerLink="autoLoanRedemption?type=IBG"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        >IBG Loan Redemption - Upload</a
      >
      <a
        routerLink="autoLoanRedemption?type=Citi"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        >Citi Loan Redemption - Upload</a
      >
      <a
        routerLink="bouncedMailPolicy"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        *ngIf="identityRolesService.isOperationUnderwriter"
        >Bounced Mail Policy</a
      >
      <a
        routerLink="policyWordingSetUp"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        *ngIf="identityRolesService.isOperationUnderwriter"
        >Policy Wording SetUp</a
      >
      <a
        routerLink="multiYearAutoDebit"
        data-toggle="collapse"
        data-target="#productMenuRoot"
        >Multiyear AutoDebit - Upload</a
      >
    </div>
  </li>
  <li class="menulist-no-padding" *ngIf="identityRolesService.isUnderwriter">
    <a routerLink="configurables">Clauses and Deductibles</a>
  </li>
  <!-- Admin -->
  <li
    class="dropdown menulist-no-padding"
    *ngIf="identityRolesService.showAdminTab"
  >
    <a class="dropbtn">Admin<span class="caret"></span></a>
    <div class="dropdown-content collapse show" id="adminMenuRoot">
      <a
        routerLink="userProfileList"
        data-toggle="collapse"
        data-target="#adminMenuRoot"
        (click)="loadUserProfile()"
        >User Profile</a
      >
    </div>
  </li>
</ul>
<router-outlet></router-outlet>
<!-- </div> -->
<ngx-idle-timeout
  *ngIf="idleTimer"
  [allowedIdleTimeInSeconds]="allowedIdleTimeInSeconds"
  [titleMessage]="titleMessage"
  [bodyMessage]="bodyMessage"
  [warningTimeInSeconds]="warningTimeInSeconds"
></ngx-idle-timeout>
