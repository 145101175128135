<!--height is controlled by css: .modal-body-auto -->
<kendo-dialog
  title="Product List"
  [actions]="returnActions"
  (action)="onAction($event)"
  [minWidth]="250"
  [width]="dlgWidth"
  id="mdProductSelectionDialog"
>
  <div class="container-fluid-custom">
    <div class="modal-body-auto textAlignCenter">
      <div style="padding-bottom: 5px">Please select a product to proceed</div>
      <table
        class="table table-bordered marginTop10"
        cellspacing="0 "
        id="productListing "
        style="border-spacing: 0"
      >
        <tbody *ngIf="isBroker">
          <tr
            (click)="goTo(item.pdid)"
            *ngFor="let item of productListing"
            style="cursor: pointer"
            [hidden]=" (currentUser?.userType == EnumsForHtml.UserType.Underwriter &amp;&amp; !item.uwNewPolicyEnabled) || (
              item.pdid === '06cb09d3-6fe0-4695-92a8-a61dfb627c61' ||
              item.pdid === '8533fa05-f190-4a29-9613-3db7f693dd32' ||
              item.pdid === 'cc290dc8-a0be-4818-9557-c2b725a3f4f8' ||
              item.pdid === '944439aa-4c28-4a69-999e-0731de0c795a')
            "
          >
            <td
              class="productSelectionTitle"
              data-ng-bind="item.pd"
              style="cursor: pointer"
            >
              {{ item.pd }}
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="isUnderwriter">
          <tr
            (click)="goTo(item.pdid)"
            *ngFor="let item of productListing"
            style="cursor: pointer"
            [hidden]="currentUser?.userType == EnumsForHtml.UserType.Underwriter &amp;&amp; !item.uwNewPolicyEnabled"
          >
            <td
              class="productSelectionTitle"
              data-ng-bind="item.pd"
              style="cursor: pointer"
            >
              {{ item.pd }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</kendo-dialog>
