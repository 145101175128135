import { KeyValue } from "src/app/framework/domain-entity/key-value";

export class MessageBoardModel {
  // constructor(public name: string, public natureOfInterest: string) {
  //   this.Name = name;
  //   this.NatureOfInterest = natureOfInterest;
  // }

  proposalId: any;
  isUnderwriterInternalComment: any;
  productId: any;
  printCorrespondence: any;
  createdByUserId: any;

  selectedPrintType!: KeyValue<string>;
  isPasswordProtected: any;
  messageBoard: MessageBoard[] = [];
  selectedUnderwriter: any;
  statusText: any;
}

export class MessageBoard {
  PolicySummaryVersion: any;
  fileIds: any;
  files: any;
  folderTypeId: any;
  isUnderwriterInternalComment: any;
  logDateTime: any;
  messageContent: any;
  messageTitle: any;
  name: any;
  notifyOwnerOnly: any;
  notifyUser: any;
  proposalId: any;
  rowIndex: any;
  selected: any;
  underWriterUserId: any;
  userType: any;
  documentTypeId: any;
  productId: any;
  sendEmail?: boolean;
}
