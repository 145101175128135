<kendo-dialog
  *ngIf="active"
  [width]="600"
  [height]="450"
  (close)="closeForm()"
  style="z-index: 1000"
>
  <kendo-dialog-titlebar class="modal-title-custom chubbLightOrange_bg">
    Refund Payment
  </kendo-dialog-titlebar>
  <div class="container-fluid" style="padding-top: 15px">
    <div *ngIf="invalidRequiredField" id="alertMessage" class="row">
      <div class="col-md-12">
        <div class="alert alert-danger displayBlock">
          <label class="margin0">
            <span>
              <i
                class="fa fa-exclamation-circle marginRight5"
                aria-hidden="true"
              ></i>
              Please fill in the required fields.
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="policyNo">Policy Number</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="policyNo"
          [textContent]="openItemEntity.policyNo"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="amount">Total Premium</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="amount"
          [textContent]="openItemEntity.amount"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="amountGst">GST</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="amountGst"
          [textContent]="openItemEntity.amountGst"
        ></p>
      </div>
    </div>
    <!-- Refund by Cheque fields -->
    <div *ngIf="refundType === 1">
      <div class="form-group row">
        <div class="col-md-4">
          <label class="marginTop5" for="refundType">Refund Payment Type</label>
        </div>
        <div class="col-md-8">
          <p
            class="form-control-static"
            id="refundType"
            [textContent]="refundTypeDisplayText"
          ></p>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidPayerName }">
          <label class="marginTop5 required" for="payerName">Payer Name</label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidPayerName }">
          <input
            type="text"
            class="form-control"
            id="payerName"
            placeholder="Payer Name"
            [(ngModel)]="refundPayment.payerName"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidCIB }">
          <label class="marginTop5 required" for="chequeIssuanceBank"
            >Cheque Issuing Bank</label
          >
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidCIB }">
          <input
            type="text"
            class="form-control"
            id="chequeIssuanceBank"
            placeholder="Cheque Issuing Bank"
            [(ngModel)]="refundPayment.chequeIssuanceBank"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidCN }">
          <label class="marginTop5 required" for="chequeNo"
            >Cheque Number</label
          >
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidCN }">
          <input
            type="text"
            class="form-control"
            id="chequeNo"
            placeholder="Cheque Number"
            [(ngModel)]="refundPayment.chequeNo"
          />
        </div>
      </div>
    </div>
    <!-- Common fields -->
    <div class="form-group row">
      <div
        class="col-md-4"
        [ngClass]="{ 'st-danger': refundType === 2 && invalidRemark }"
      >
        <!-- Is required for OnlineTransfer refundType -->
        <label
          class="marginTop5"
          [ngClass]="refundType === 2 ? 'required' : ''"
          for="remarks"
          >Remarks</label
        >
      </div>
      <div
        class="col-md-8"
        [ngClass]="{ 'st-danger': refundType === 2 && invalidRemark }"
      >
        <textarea
          class="form-control"
          rows="3"
          id="remarks"
          [(ngModel)]="refundPayment.remarks"
        ></textarea>
      </div>
    </div>
  </div>
  <kendo-dialog-actions>
    <button
      kendoButton
      class="btn chubbWhite chubbLightBlue_bg_btn"
      themeColor="primary"
      (click)="onSave($event)"
    >
      Make Refund
    </button>
    <button
      kendoButton
      class="btn chubbWhite chubbRed_bg_btn"
      (click)="onCancel($event)"
    >
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
