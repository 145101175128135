import * as Enums from './../../enum.shared';

import { common } from './../../utils/common';

/** Common Exception object to be thrown with error message while system is unable to proceed */
export class Exception {

    public type?: Enums.ErrorExceptionType = Enums.ErrorExceptionType.ApplicationError;

    constructor(public message: string,
        public header?: string,
        type?: Enums.ErrorExceptionType) {

        if (common.isDefined(type)) {
            this.type = type;
        }
    }
}
