import { ApiMapperNotifierModel } from "./api-mapper-notifier.model";
import { Subject } from "rxjs";

/** To notify upon proposal action api end point invoke */
class ApiMapperNotifier {
  /** Notify before ProposalEntity mapped to IProposalApiModel */
  before_entity_to_api_map_notifier$: Subject<ApiMapperNotifierModel> =
    new Subject<ApiMapperNotifierModel>();

  /** Notify after ProposalEntity mapped to IProposalApiModel */
  after_entity_to_api_map_notifier$: Subject<ApiMapperNotifierModel> =
    new Subject<ApiMapperNotifierModel>();

  /** Notify after IProposalApiModel mapped to ProposalEntity */
  after_api_to_entity_map_notifier$: Subject<ApiMapperNotifierModel> =
    new Subject<ApiMapperNotifierModel>();
}

export const apiMapperNotifier = new ApiMapperNotifier();
