import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "percentage",
})
export class PercentagePipe extends DecimalPipe implements PipeTransform {
  override transform(value: any, decimal?: any, symbol?: any): any {
    /* https://angular.io/api/common/PercentPipe
      
    {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.

    minIntegerDigits: The minimum number of integer digits before the decimal point. Default is 1.
    minFractionDigits: The minimum number of digits after the decimal point. Default is 0.
    maxFractionDigits: The maximum number of digits after the decimal point. Default is 0.
    Optional. Default is undefined.

    */
    let fractionSize = "0.0-0"; //"1.2-2";
    if (decimal) {
      fractionSize = "1.2-" + decimal; //todo: check if works
    }
    let formatedByCurrencyPipe = super.transform(value, fractionSize);
    return formatedByCurrencyPipe + " %";
  }
}
