import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { IProduct } from "src/app/framework/interface.product";
import { BroadcastService } from "src/app/services/broadcast.service";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { CitiMonthlyReportSearchCriteria } from "./citi-monthly-report.model";
import { proposalCommon } from "./../../../base/utils/proposal-common";

@Component({
  selector: "app-citi-monthly-report",
  templateUrl: "./citi-monthly-report.component.html",
})
export class CitiMonthlyReportComponent
  extends BaseComponent
  implements OnInit
{
  @Output() warningZoneEvent = new EventEmitter<any>();
  @ViewChild("reportCitiMonthlyDateTo", { static: false })
  public reportCitiMonthlyDateTo!: DatePickerComponent;
  public reportCitiMonthlyDate_min!: Date;
  public reportCitiMonthlyDate_max!: Date;
  public searchCriteria = new CitiMonthlyReportSearchCriteria();
  public transactionTypeOption!: Array<{ text: string; value: number }>;
  public customerTypes!: Array<{ text: string; value: string }>;
  public userProductList!: IProduct[];
  public isCitiProduct: boolean = true;
  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    private presetExportService: PresetExportService,
    private excelTemplateService: ExcelTemplateService
  ) {
    super(router, broadcastService, identityRolesService);
  }

  override ngOnInit(): void {
    this.defaultCitiMonthlyReportSearchCriteria();
    this.fetchUserList();
  }
  private defaultCitiMonthlyReportSearchCriteria(): void {
    this.transactionTypeOption = [
      { text: "New Business", value: 1 },
      { text: "Active", value: 2 },
      { text: "Cancellation", value: 3 },
    ];
    this.customerTypes = [
      { text: "Citibusiness", value: "Citibusiness" },
      { text: "Commercial", value: "Commercial" },
      { text: "GCB", value: "GCB" },
      { text: "IPB", value: "IPB" },
      { text: "Staff", value: "Staff" },
      { text: "Bridging Loan", value: "Bridging Loan" },
    ];

    //  dateFrom: 14 days before today
    const dateFrom: Date = new Date();
    dateFrom.setDate(dateFrom.getDate() - 14);
    this.searchCriteria.dateFrom = dateFrom;

    // dateTo: 6 months after dateFrom
    const dateTo: Date = new Date(dateFrom);
    dateTo.setMonth(dateTo.getMonth() + 6);
    this.searchCriteria.dateTo = dateTo;

    /*2022-08-18 dagoh0: APACDM-1660 - Default a transaction Type */
    this.searchCriteria.transactionType = 1;
  }
  private fetchUserList() {
    // get user productsList
    if (this.isCitiProduct) {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return (
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    } else {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return !(
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    }
  }
  public on_citiMonthlyReportDateFrom_change(val: any): void {
    if (this.searchCriteria.dateFrom === null) {
      this.searchCriteria.dateTo = undefined;
      this.reportCitiMonthlyDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.reportCitiMonthlyDate_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }

    if (
      this.searchCriteria.dateTo &&
      this.searchCriteria.dateFrom &&
      this.searchCriteria.dateTo < this.searchCriteria.dateFrom
    ) {
      this.searchCriteria.dateTo = undefined;
      this.reportCitiMonthlyDate_min = new Date(1900, 1, 1, 0, 0, 0);
      this.reportCitiMonthlyDate_max = new Date(2099, 12, 31, 0, 0, 0);
    }

    // open datepicker
    this.reportCitiMonthlyDateTo.toggle();
  }
  private validateCitiMonthlyMandatoryFields(): boolean {
    let isValid: boolean = false;

    /*2022-08-18 dagoh0: APACDM-1660 - change to validated selected filter */
    const filterSelected: boolean =
      this.searchCriteria.dateFrom !== null ||
      this.searchCriteria.dateTo !== null ||
      this.searchCriteria.transactionType !== null;

    isValid = filterSelected; // || etc...

    return isValid;
  }
  public download(): void {
    if (this.validateCitiMonthlyMandatoryFields()) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.downloadCitiMonthlyReport();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range & Transaction Type.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private formatDate(date?: Date): string {
    if (date)
      return (
          proposalCommon.translateMonth(date.getMonth() + 1) +
        "_" +
        date.getFullYear().toString().substring(-2)
      );
    else return "";
  }
  private downloadCitiMonthlyReport() {
    this.presetExportService
      .monthlyReportCiti(
        this.searchCriteria.dateFrom,
        this.searchCriteria.dateTo,
        this.searchCriteria.productIds,
        this.searchCriteria.transactionType.toString(),
        this.searchCriteria.customerType,
        this.searchCriteria.policyStatus
      )
      .subscribe((response: any) => {
        if (response && response.body && response.body.d.results) {
          switch (this.searchCriteria.transactionType.toString()) {
            case "1":
              this.excelTemplateService.downloadCitiMonthlyNewBusinessReport(
                citiNewBusinessColumn,
                "Bank Trans Citi",
                "citi" +
                  " Monthly " +
                  "New Business" +
                  " - " +
                  this.formatDate(this.searchCriteria.dateFrom),
                response.body.d.results
              );
              break;
            case "2":
              this.excelTemplateService.downloadCitiMonthlyActiveReport(
                citiActiveColumn,
                "Bank Trans Citi",
                "citi" +
                  " Monthly " +
                  "Active" +
                  " - " +
                  this.formatDate(this.searchCriteria.dateFrom),
                response.body.d.results
              );
              break;
            case "3":
              this.excelTemplateService.downloadCitiMonthlyCancellationReport(
                citiCancellationColumn,
                "Bank Trans Citi",
                "citi" +
                  " Monthly " +
                  "Cancellation" +
                  " - " +
                  this.formatDate(this.searchCriteria.dateFrom),
                response.body.d.results
              );
              break;
          }
        }
      });
  }
}
const citiNewBusinessColumn: string[] = [
  "Product Name",
  "Customer Type",
  "Client Name",
  "Policy Number",

  "Policy Effective Date",
  "Policy Expiry Date",
  "Policy Bound Date",
  "Collateral Number",

  "Master Policy Number",
  "Issued by",
  "Occupied as",
  "Fire Replacement Value",
  "Transaction Desciption",
  "Plan Type",
  "Premium Payment",

  "Risk insured Address building",
  "Risk insured Address lotunit",
  "Risk insured Address 1",
  "Risk insured Address 2",
  "Risk insured Address City",
  "Risk insured Address Country",
  "Risk insured Address PostalCode",

  "Base Premium",
  "GST",
  "Total Premium",
  "Sum Insured",
  "Discount",
];
const citiActiveColumn: string[] = [
  "Product Name",
  "Customer Type",
  "Client Name",
  "Policy Number",

  "Policy Effective Date",
  "Policy Expiry Date",
  "Policy Bound Date",
  "Policy Status",
  "Collateral Number",

  "Risk insured Address building",
  "Risk insured Address lotunit",
  "Risk insured Address 1",
  "Risk insured Address 2",
  "Risk insured Address City",
  "Risk insured Address Country",
  "Risk insured Address PostalCode",

  "Base Premium",
  "GST",
  "Total Premium",
  "Sum Insured",
  "Discount",
];
const citiCancellationColumn: string[] = [
  "Product Name",
  "Customer Type",
  "Client Name",
  "Policy Number",

  "Policy Effective Date",
  "Policy Expiry Date",
  "Policy Bound Date",
  "Cancellation Effective Date",
  "Collateral Number",

  "Master Policy Number",
  "Issued by",
  "Occupied as",
  "Fire Replacement Value",
  "Remark",
  "Transaction Desciption",
  "Plan Type",
  "Premium Payment",

  "Risk insured Address building",
  "Risk insured Address lotunit",
  "Risk insured Address 1",
  "Risk insured Address 2",
  "Risk insured Address City",
  "Risk insured Address Country",
  "Risk insured Address PostalCode",

  "Base Premium",
  "GST",
  "Total Premium",
  "Sum Insured",
  "Discount",
];
