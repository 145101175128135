import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
//import { environment } from "../environments/environment";
import { environment } from "src/environments/environment"; //use the new one in src folder, old one mainly for guid.ts only
import { RmScreenListingEntity } from "../components/product/rm-screen/RmScreenListingEntity";
import { common } from "../framework/utils/common";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class RmScreenService extends ApiService {
  private rmScreenListingData!: RmScreenListingEntity[];

  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  async getRmdetails(): Promise<RmScreenListingEntity[]> {
    this.rmScreenListingData = [];
    const uri = environment.serviceBase + "api/RMDetails/getRMDetails";

    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/json", //"application/x-www-form-urlencoded",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //   Session: "d5990a3b-ad1d-4aea-93e5-9b0da35da17e", //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });

    if (
      common.isDefined(this.rmScreenListingData) &&
      this.rmScreenListingData.length > 0
    ) {
      //todo
    } else {
      this.rmScreenListingData = [];

      const response = await this.httpClient
        .get<any>(uri, { headers: headers })
        .toPromise();

      this.rmScreenListingData = response;
    }

    return this.rmScreenListingData;
  }

  save(data: any[], isNewRm: boolean) {
    const url = "api/RMDetails/saveRMDetails/";
    const postData = JSON.stringify(data[0]); //has to be first item, if json array will not work

    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url),
      postData
    );
  }

  deleteRM(data: string) {
    const url = "api/RMDetails/removeRMDetails/";

    const postData = { id: data };

    //it's a delete call, not post
    return this.httpDeleteCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url),
      postData
    );
  }
}
