import * as Enums from "../../../../../../../framework/enum.shared";

import { GUID } from "./../../../../../../../framework/domain-entity/guid";
import { MortgageFireSGSectionSchema } from "./../schemas/mf-sg-section-schema";
import { MortgageFireSGTabSchema } from "./../schemas/mf-sg-tab-schema";
import { ProposalEntity } from "./../../../../../base/proposal-entity.model";
import { QuestionEntity } from "./../../../../../base/question-entity.model";
import { QuestionSection } from "./../../../../../base/question-section";
import { common } from "./../../../../../../../framework/utils/common";
import { MasterDataService } from "../../../../../../../services/master-data.service";

export class MortgageFireSingaporeClientCreateQuestionSection extends QuestionSection {
  qs_master_clientSalutation!: QuestionEntity;
  qs_master_clientSurname!: QuestionEntity;
  qs_master_clientGivenName!: QuestionEntity;
  qs_master_clientNRICPassportNo!: QuestionEntity;
  qs_master_clientDOB!: QuestionEntity;

  qs_master_clientOccupation!: QuestionEntity;
  qs_master_clientCategory!: QuestionEntity;
  qs_master_clientRace!: QuestionEntity;
  qs_master_clientNationality!: QuestionEntity;
  qs_master_clientGender!: QuestionEntity;

  qs_master_clientMaritalStatus!: QuestionEntity;

  qs_master_insuredAddress_building!: QuestionEntity;
  qs_master_insuredAddress_lotunit!: QuestionEntity;
  qs_master_insuredAddress1!: QuestionEntity;
  qs_master_insuredAddress2!: QuestionEntity;
  qs_master_insuredPostalCode!: QuestionEntity;
  qs_master_insuredCity!: QuestionEntity;
  qs_master_insuredState!: QuestionEntity;
  qs_master_insuredCountry!: QuestionEntity;

  qs_master_brokerEmail!: QuestionEntity;
  qs_master_brokerPhoneNo!: QuestionEntity;
  qs_master_brokerMobileNo!: QuestionEntity;
  qs_master_brokerOfficeNo!: QuestionEntity;
  qs_master_brokerFaxNo!: QuestionEntity;
  qs_master_brokerPagerNo!: QuestionEntity;

  constructor(public override masterDataService: MasterDataService) {
    super(MortgageFireSGTabSchema.master.index, masterDataService);
  }

  initializeQuestionSchema() {
    this.qs_master_clientSalutation = new QuestionEntity(
      "qs_master_clientSalutation",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientSalutation.displayText = "Salutation";
    this.qs_master_clientSalutation.options = [
      { key: "Capt", value: "1" },
      { key: "Dr", value: "2" },
      { key: "Madam", value: "3" },
      { key: "Miss", value: "4" },
      { key: "Mr", value: "5" },
      { key: "Mrs", value: "6" },
      { key: "Ms", value: "7" },
      { key: "Prof", value: "8" },
      { key: "Sir", value: "9" },
      { key: "Tan Sri", value: "10" },
    ];
    this.qs_master_clientSalutation.required = true;
    this.qs_master_clientSalutation.tabId = this.tabId;
    this.qs_master_clientSalutation.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientSurname = new QuestionEntity(
      "qs_master_clientSurname",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientSurname.displayText = "Surname";
    this.qs_master_clientSurname.required = true;
    this.qs_master_clientSurname.tabId = this.tabId;
    this.qs_master_clientSurname.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientGivenName = new QuestionEntity(
      "qs_master_clientGivenName",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientGivenName.displayText = "Given Name";
    this.qs_master_clientGivenName.required = true;
    this.qs_master_clientGivenName.tabId = this.tabId;
    this.qs_master_clientGivenName.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientNRICPassportNo = new QuestionEntity(
      "qs_master_clientNRICPassportNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientNRICPassportNo.displayText = "NRIC / Passport Number";
    this.qs_master_clientNRICPassportNo.required = true;
    this.qs_master_clientNRICPassportNo.tabId = this.tabId;
    this.qs_master_clientNRICPassportNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientDOB = new QuestionEntity(
      "qs_master_clientSalutation",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientDOB.displayText = "Birth Date";
    this.qs_master_clientDOB.required = true;
    this.qs_master_clientDOB.tabId = this.tabId;
    this.qs_master_clientDOB.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientOccupation = new QuestionEntity(
      "qs_master_clientOccupation",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientOccupation.displayText = "Occupation";
    this.qs_master_clientOccupation.options = [
      { key: "Accounting Director", value: "1" },
      { key: "Administrator Database", value: "2" },
      { key: "Advisory Sale Specialist", value: "3" },
      { key: "Journalist", value: "4" },
      { key: "Legal Consultant", value: "5" },
      { key: "Management Engineer", value: "6" },
      { key: "Safety Manager", value: "7" },
      { key: "Script Editor", value: "8" },
      { key: "Trade Manager", value: "9" },
    ];
    this.qs_master_clientOccupation.required = true;
    this.qs_master_clientOccupation.tabId = this.tabId;
    this.qs_master_clientOccupation.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientCategory = new QuestionEntity(
      "qs_master_clientCategory",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientCategory.displayText = "Category";
    this.qs_master_clientCategory.options = [{ key: "Others", value: "1" }];
    this.qs_master_clientSalutation.required = true;
    this.qs_master_clientSalutation.tabId = this.tabId;
    this.qs_master_clientSalutation.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientRace = new QuestionEntity(
      "qs_master_clientRace",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientRace.displayText = "Race";
    this.qs_master_clientRace.options = [
      { key: "Chinese", value: "1" },
      { key: "Indian", value: "2" },
      { key: "Malay", value: "3" },
      { key: "Others", value: "4" },
    ];
    this.qs_master_clientRace.required = true;
    this.qs_master_clientRace.tabId = this.tabId;
    this.qs_master_clientRace.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientNationality = new QuestionEntity(
      "qs_master_clientNationality",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientNationality.displayText = "Nationality";
    this.qs_master_clientNationality.options = [
      { key: "Hong Kong", value: "1" },
      { key: "Singaporean", value: "2" },
    ];
    this.qs_master_clientNationality.required = true;
    this.qs_master_clientNationality.tabId = this.tabId;
    this.qs_master_clientNationality.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientGender = new QuestionEntity(
      "qs_master_clientGender",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientGender.displayText = "Gender";
    this.qs_master_clientGender.options = [
      { key: "Male", value: "1" },
      { key: "Female", value: "2" },
    ];
    this.qs_master_clientGender.required = true;
    this.qs_master_clientGender.tabId = this.tabId;
    this.qs_master_clientGender.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_clientMaritalStatus = new QuestionEntity(
      "qs_master_clientMaritalStatus",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_clientMaritalStatus.displayText = "Marital Status";
    this.qs_master_clientMaritalStatus.options = [
      { key: "Single", value: "1" },
      { key: "Married", value: "2" },
      { key: "Widowed", value: "3" },
      { key: "Divorced", value: "4" },
      { key: "Separated", value: "5" },
    ];
    this.qs_master_clientMaritalStatus.required = true;
    this.qs_master_clientMaritalStatus.tabId = this.tabId;
    this.qs_master_clientMaritalStatus.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_insuredAddress_building = new QuestionEntity(
      "qs_master_insuredAddress_building",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress_building.displayText = "Building Name";
    this.qs_master_insuredAddress_building.required = false;
    this.qs_master_insuredAddress_building.tabId = this.tabId;
    this.qs_master_insuredAddress_building.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredAddress_lotunit = new QuestionEntity(
      "qs_master_insuredAddress_lotunit",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress_lotunit.displayText = "Suite/Lot/Unit Number";
    this.qs_master_insuredAddress_lotunit.required = false;
    this.qs_master_insuredAddress_lotunit.tabId = this.tabId;
    this.qs_master_insuredAddress_lotunit.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredAddress1 = new QuestionEntity(
      "qs_master_insuredAddress1",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress1.displayText = "Address No 1";
    this.qs_master_insuredAddress1.readOnly = true;
    this.qs_master_insuredAddress1.required = true;
    this.qs_master_insuredAddress1.tabId = this.tabId;
    this.qs_master_insuredAddress1.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredAddress2 = new QuestionEntity(
      "qs_master_insuredAddress2",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredAddress2.displayText = "Address No 2";
    this.qs_master_insuredAddress2.readOnly = true;
    this.qs_master_insuredAddress2.required = false;
    this.qs_master_insuredAddress2.tabId = this.tabId;
    this.qs_master_insuredAddress2.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredPostalCode = new QuestionEntity(
      "qs_master_insuredPostalCode",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredPostalCode.displayText = "PostCode";
    this.qs_master_insuredPostalCode.readOnly = true;
    this.qs_master_insuredPostalCode.required = false;
    this.qs_master_insuredPostalCode.tabId = this.tabId;
    this.qs_master_insuredPostalCode.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredCity = new QuestionEntity(
      "qs_master_insuredCity",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredCity.displayText = "City/Suburb";
    this.qs_master_insuredCity.readOnly = true;
    this.qs_master_insuredCity.required = false;
    this.qs_master_insuredCity.tabId = this.tabId;
    this.qs_master_insuredCity.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredState = new QuestionEntity(
      "qs_master_insuredState",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredState.displayText = "State";
    this.qs_master_insuredState.readOnly = true;
    this.qs_master_insuredState.required = false;
    this.qs_master_insuredState.tabId = this.tabId;
    this.qs_master_insuredState.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_insuredCountry = new QuestionEntity(
      "qs_master_insuredCountry",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_insuredCountry.displayText = "Country";
    this.qs_master_insuredCountry.readOnly = true;
    this.qs_master_insuredCountry.answer = "SG";
    this.qs_master_insuredCountry.required = true;
    this.qs_master_insuredCountry.tabId = this.tabId;
    this.qs_master_insuredCountry.questionPanelId =
      MortgageFireSGSectionSchema.locationInsuredInfo.sectionId;

    this.qs_master_brokerEmail = new QuestionEntity(
      "qs_pd_brokerEmail",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerEmail.displayText = "Email Address";
    this.qs_master_brokerEmail.required = true;
    this.qs_master_brokerEmail.tabId = this.tabId;
    this.qs_master_brokerEmail.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_brokerPhoneNo = new QuestionEntity(
      "qs_master_brokerPhoneNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerPhoneNo.displayText = "Phone Number (Home)";
    this.qs_master_brokerPhoneNo.required = false;
    this.qs_master_brokerPhoneNo.tabId = this.tabId;
    this.qs_master_brokerPhoneNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_brokerMobileNo = new QuestionEntity(
      "qs_master_brokerMobileNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerMobileNo.displayText = "Mobile Number";
    this.qs_master_brokerMobileNo.required = false;
    this.qs_master_brokerMobileNo.tabId = this.tabId;
    this.qs_master_brokerMobileNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_brokerOfficeNo = new QuestionEntity(
      "qs_master_brokerOfficeNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerOfficeNo.displayText = "Phone Number (Office)";
    this.qs_master_brokerOfficeNo.required = true;
    this.qs_master_brokerOfficeNo.tabId = this.tabId;
    this.qs_master_brokerOfficeNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_brokerFaxNo = new QuestionEntity(
      "qs_master_brokerFaxNo",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerFaxNo.displayText = "Fax Number";
    this.qs_master_brokerFaxNo.required = false;
    this.qs_master_brokerFaxNo.tabId = this.tabId;
    this.qs_master_brokerFaxNo.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;

    this.qs_master_brokerEmail = new QuestionEntity(
      "qs_master_brokerEmail",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_brokerEmail.displayText = "Pager Number";
    this.qs_master_brokerEmail.required = false;
    this.qs_master_brokerEmail.tabId = this.tabId;
    this.qs_master_brokerEmail.questionPanelId =
      MortgageFireSGSectionSchema.master.sectionId;
  }

  refreshQuestionsArray() {
    this.questions = [];
    this.questions.push(this.qs_master_clientSalutation);
  }
}
