<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [textContent]="question.displayText"
    [ngClass]="{ required: question.required && !question.readOnly }"
  ></label>
  <div *ngIf="!question.readOnly">
    <input
      name="{{ question!.key }} "
      #qs_checkbox
      type="checkbox"
      value="{{ question!.answer }}"
      id="{{ question!.key }}_checkbox"
      [disabled]="question!.isDisabled!"
      (click)="qs_onChange(question!.answer!)"
      [checked]="compareQuestionAnswer(question!)"
    />
  </div>

  <div *ngIf="question.readOnly">
    <!-- <p *ngIf="question.isAnswered()">{{ answerDisplayText(question) }}</p>
      <span class="unattended" *ngIf="!question.isAnswered()">{{
        noAnswerDefaultText
      }}</span> -->
    <div
      class=""
      *ngIf="question!.answer === 'True' || question!.answer === true"
      style="padding-left: 5px"
    >
      Yes
    </div>
    <div
      class=""
      *ngIf="question!.answer !== 'True' && question!.answer !== true"
      style="padding-left: 5px"
    >
      No
    </div>
  </div>
</div>
