<div id="{{ question.key }}" ng-class="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>

  <div *ngIf="!question.readOnly">
    <div class="radio clearMarginTopBottom clearfix">
      <div
        style="padding-right: 20px"
        *ngFor="let ai of question.optionsValue"
        class="floatLeft marginLeftRight20 paddingTopBottom"
      >
        <input
          *ngIf="!question.readOnly"
          name="{{ question.key }}"
          type="radio"
          value="{{ ai.value }}"
          [(ngModel)]="question.answer"
          (change)="onChange(question.answer)"
        />
        <label
          style="padding-left: 10px"
          [textContent]="ai.key"
          [hidden]="question.readOnly"
        ></label>
      </div>
    </div>
  </div>
  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()">{{ answerDisplayText(question) }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">
      {{ noAnswerDefaultText }}
    </span>
  </div>
</div>
