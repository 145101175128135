<kendo-dialog
  title="{{ title }}"
  class="modal-title-red"
  (close)="close('cancel')"
  [width]="600"
  [height]="180"
>
  <div class="modal-body">
    <div *ngFor="let item of message">
      <i class="fa fa-exclamation-circle chubbRed"> </i>
      <span [innerHtml]="item.text"></span>
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel"
        kendoButton
        (click)="close('no')"
      >
        Return
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
