<form (ngSubmit)="onSubmit()" #heroForm="ngForm">
  <div class="container-fluid">
    <!-- Search filters -->
    <div class="row">
      <div class="col-md-6">
        <!-- Left search section -->
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoQuoteNo">Quote No</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoQuoteNo"
              placeholder="Quote No"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.quoteNo"
              name="quoteNo"
              #name="ngModel"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoPolicyNo">Policy No</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoPolicyNo"
              placeholder="Policy No"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.policyNo"
              name="policyNo"
              #name="ngModel"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoCIN">CIN</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoCIN"
              placeholder="CIN (Customer Identification No)"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.cin"
              name="cin"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- Right search section -->
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoStartDateFrom"
              >Policy Start Date
            </label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoStartDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              (focus)="on_searchStartDateFrom_focus()"
              (valueChange)="on_searchStartDateFrom_change($event)"
              placeholder=""
              [(ngModel)]="model.startDateFrom"
              name="startDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoStartDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoStartDateTo
              id="toDoStartDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              [min]="toDoStartDateTo_min"
              [max]="toDoStartDateTo_max"
              [(ngModel)]="model.startDateTo"
              name="startDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoExpiryDateFrom"
              >Policy Expiry Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoExpiryDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              (focus)="on_searchExpiryDateFrom_focus()"
              (valueChange)="on_searchExpiryDateFrom_change($event)"
              placeholder=""
              [(ngModel)]="model.expiryDateFrom"
              name="expiryDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoExpiryDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoExpiryDateTo
              id="toDoExpiryDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              [min]="toDoExpiryDateTo_min"
              [max]="toDoExpiryDateTo_max"
              [(ngModel)]="model.expiryDateTo"
              name="expiryDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoEffectiveDateFrom"
              >Effective Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoEffectiveDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              (focus)="on_searchEffectiveDateFrom_focus()"
              (valueChange)="on_searchEffectiveDateFrom_change($event)"
              [(ngModel)]="model.effectiveDateFrom"
              name="effectiveDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoEffectiveDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoEffectiveDateTo
              id="toDoEffectiveDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              [(ngModel)]="model.effectiveDateTo"
              name="effectiveDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoInsuredName">Insured Name</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoInsuredName"
              placeholder="Insured Name"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.insuredName"
              name="insuredName"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoUserTeam">User Team</label>
          </div>
          <div class="col-md-9">
            <kendo-multiselect
              id="toDoUserTeam"
              placeholder="Select user team..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="userTeamOptions"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="model.userTeam"
              name="userTeam"
            >
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoAdditionalInsuredName"
              >Add Ins Name</label
            >
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoAdditionalInsuredName"
              placeholder="Additional Insured Name"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.additionalInsuredName"
              name="additionalInsuredName"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoRiskAddressPostalCode"
              >Risk Add Postcode</label
            >
          </div>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              id="toDoRiskAddressPostalCode"
              placeholder="Risk Address Postal Code"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.riskAddressPostalCode"
              name="riskAddressPostalCode"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="todoAdvancedSearch" class="main-container collapse">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="marginTop5" for="toDoCreatedBy">Created By</label>
            </div>
            <div class="col-md-6">
              <kendo-dropdownlist
                id="toDoCreatedBy"
                [defaultItem]="defaultUserListItem"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="userList"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.createdByUserId"
                name="createdByUserId"
              ></kendo-dropdownlist>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="marginTop5" for="toDoBCRN" *ngIf="isDBSUser"
                >Bank Reference No</label
              >
              <label class="marginTop5" for="toDoBCRN" *ngIf="isCitiUser"
                >Collateral Reference No</label
              >
              <label class="marginTop5" for="toDoBCRN" *ngIf="isChubbUser"
                >Bank Ref No/Coll. Ref No</label
              >
            </div>
            <div class="col-md-9">
              <input
                type="text"
                class="form-control"
                id="toDoBCRN"
                *ngIf="isDBSUser"
                placeholder="Bank Reference No"
                ng-keypress="model.on_quoteNumber_keyup($event)"
                [(ngModel)]="model.bcrn"
                name="bcrn"
                #name="ngModel"
              />
              <input
                type="text"
                class="form-control"
                id="toDoBCRN"
                *ngIf="isCitiUser"
                placeholder="Collateral Reference No"
                ng-keypress="model.on_quoteNumber_keyup($event)"
                [(ngModel)]="model.bcrn"
                name="bcrn"
                #name="ngModel"
              />
              <input
                type="text"
                class="form-control"
                id="toDoBCRN"
                *ngIf="isChubbUser"
                placeholder="Bank Reference No/Collateral Reference No"
                ng-keypress="model.on_quoteNumber_keyup($event)"
                [(ngModel)]="model.bcrn"
                name="bcrn"
                #name="ngModel"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-md-2">
              <label class="marginTop5" for="toDoMasterPolicyNo"
                >Master Policy No</label
              >
            </div>
            <div class="col-md-10">
              <kendo-dropdownlist
                id="toDoMasterPolicyNo"
                [defaultItem]="defaultMasterPolicyOptionsItem"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="masterPolicyOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.masterPolicyId"
                name="masterPolicyId"
              ></kendo-dropdownlist>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-md-2">
              <label class="marginTop5" for="toDoStatus">Proposal Status</label>
            </div>
            <div class="col-md-10">
              <kendo-multiselect
                id="toDoStatus"
                placeholder="Select status..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="proposalStatusOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.proposalStatus"
                name="proposalStatus"
              >
              </kendo-multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-md-2">
              <label class="marginTop5" for="toDoProduct">Product</label>
            </div>
            <div class="col-md-10">
              <kendo-multiselect
                id="toDoProduct"
                placeholder="Select product..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="userProductList"
                textField="pd"
                valueField="pdid"
                [valuePrimitive]="true"
                [(ngModel)]="model.productId"
                name="productId"
              >
              </kendo-multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--HTML5 button element. Remember the reason is this button has a default behavior of submit, as stated in the W3 specification-->
  <button
    id="todoAdvancedToggler"
    type="button"
    class="btn btn-primary ld-ext-left chubbDarkBlue_bg chubbDarkBlue_border_btn"
    data-toggle="collapse"
    data-target="#todoAdvancedSearch"
    (click)="toggleCaption()"
  >
    <span>{{ advancedSearchCaption }}</span>
    <i
      class="fa fa-caret-down paddingLeft5 verticalAlignSub"
      aria-hidden="true"
    ></i>
  </button>
  <button
    type="submit"
    class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    [disabled]="!heroForm.form.valid"
  >
    <i class="fa fa-search ng-scope" aria-hidden="true"></i>
    Search
  </button>
</form>

<br />
<button
  on-click="ActionSelect()"
  *ngIf="ButtonShow('All')"
  class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
>
  <i
    class="fa fa-check-circle-o fa-lg fa-lg-line-height"
    aria-hidden="true"
  ></i>
  Select All
</button>
<button
  on-click="ActionApprove()"
  *ngIf="ButtonShow(6)"
  class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
>
  Approve All
</button>
<button
  on-click="ActionBind()"
  *ngIf="ButtonShow(7)"
  class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
>
  Bind All Policies
</button>
<button
  on-click="ActionDecline()"
  *ngIf="ButtonShow(9)"
  class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
>
  Decline All
</button>
<br />
<br />
<div class="grid-wrapper">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    [(selectedKeys)]="selectedRowIndexes"
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <!-- <kendo-grid-checkbox-column
      field="canApproveIBGForUW"
      title=""
      [width]="30"
    >
      <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem>
        <input
          [kendoGridSelectionCheckbox]="idx"
          [style.display]="dataItem.canApproveIBGForUW ? 'block' : 'none'"
        />
      </ng-template>
    </kendo-grid-checkbox-column> -->
    <kendo-grid-column field="checkboxChecked" title="" [width]="30">
      <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem>
        <input
          type="checkbox"
          [style.display]="dataItem.canApproveIBGForUW ? 'block' : 'none'"
          [checked]="dataItem.checkboxChecked"
          (click)="SetSelectedItem(dataItem, idx)"
        />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="createdDate" title="Created Date" [width]="130">
    </kendo-grid-column
    ><kendo-grid-column
      field="createdByUserName"
      title="Created By"
      [width]="180"
    >
    </kendo-grid-column>
    <kendo-grid-column field="quoteNo" title="Quote No" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="policyNo" title="Policy No" [width]="170">
    </kendo-grid-column>
    <kendo-grid-column
      field="msIGPolicyNo"
      title="MSIG Policy No"
      [width]="170"
    >
    </kendo-grid-column
    ><kendo-grid-column field="insuredName" title="Insured Name" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column
      field="additionalInsuredName"
      title="Add Ins Name"
      [width]="150"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="riskAddressPostalCode"
      title="Risk Add Postcode"
      [width]="150"
    >
    </kendo-grid-column>
    <kendo-grid-column field="cin" title="CIN" [width]="110">
    </kendo-grid-column
    ><kendo-grid-column field="productCode" title="Product" [width]="100">
    </kendo-grid-column>
    <kendo-grid-column
      field="proposalStatusDisplayText"
      title="Status"
      [width]="110"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="proposalTypeDisplayText"
      title="Type"
      [width]="80"
    >
    </kendo-grid-column>
    <kendo-grid-column field="sumInsured" title="Sum Insured" [width]="120">
    </kendo-grid-column
    ><kendo-grid-column field="premium" title="Premium" [width]="110">
    </kendo-grid-column>
    <kendo-grid-column
      field="usagePremises"
      title="Usage Of Premises"
      [width]="110"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="occupancyRate"
      title="Occupancy Rate"
      [width]="150"
    >
    </kendo-grid-column>
    <kendo-grid-column field="bcrn" [title]="getBcrnByUserOrg()" [width]="170">
    </kendo-grid-column>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="10"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
