import { Component, Input, OnInit } from "@angular/core";
import { ConfigurableFormBaseComponent } from "../configurable-form-base/configurable-form-base.component";
import { MfHkConfigEditCriteria } from "./mf-hk-config-edit-criteria";
import { MdInformationMessageComponent } from "../md-information-message/md-information-message.component";

@Component({
  selector: "app-configurable-form-edit",
  templateUrl: "./configurable-form-edit.component.html",
  styleUrls: ["./configurable-form-edit.component.scss"],
})
export class ConfigurableFormEditComponent extends ConfigurableFormBaseComponent {
  @Input()
  public editModel: MfHkConfigEditCriteria = new MfHkConfigEditCriteria();

  override ngOnInit(): void {
    super.ngOnInit();

    this.copyObjectWithoutReferences();
  }

  override reset() {
    this.errorLogs.Logs = new Array<string>(); // Clear error logs
    this.copyObjectWithoutReferences();
  }

  copyObjectWithoutReferences() {
    //this.model = this.editModel;
    this.model = JSON.parse(JSON.stringify(this.editModel)); //copy object without references, so that can be reset later
  }

  override modelToArray(): (
    | (string | Date | undefined)[]
    | (string | string[])[]
    | (string | number[])[]
    | (string | number)[]
  )[] {
    let array = [
      ["coverageTypes", "eq", this.model.coverageTypes.toString()],
      ["code", "eq", this.model.code!],
      ["title", "eq", this.model.title!],
      ["description", "eq", this.model.editorValue],
      ["default", "eq", this.boolToString(this.model.default)],
      ["typeId", "eq", this.model.typeId],
      ["guid", "eq", this.editModel.guid!.toString()],
    ];

    return array;
  }

  override save() {
    if (
      this.model.coverageTypes.length === 0 ||
      this.model.code === undefined ||
      this.model.code.trim() === "" ||
      this.model.title === undefined ||
      this.model.title.trim() === ""
    ) {
      const log: string = "Coverage Type, Title, and Code are mandatory.";
      this.pushLog(log);
    } else {
      const formData: FormData = this.setFormData();

      //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
      //IT HAS TO BE IN SINGLE CHUNK OF STRING
      var querystring: string = "";
      for (var pair of formData.entries()) {
        querystring +=
          encodeURIComponent(pair[0]) +
          "=" +
          encodeURIComponent(pair[1] as string) +
          "&";
      }
      this.clausesDeductiblesService
        .updateHaseEndorsementClause(querystring)
        .subscribe(
          (result: any) => {
            if (
              result != undefined &&
              result.body != undefined &&
              result.body != null
            ) {
              this.handleReturnResult(result);
            }
          },
          (err: any) => {
            console.log(err);

            this.cdkDialogService.open(MdInformationMessageComponent, {
              data: {
                message: "An error has occurred. Please contact admin",
              },
            });
          }
        );
    }
  }

  delete() {
    if (
      this.model.coverageTypes.length === 0 ||
      this.model.title === undefined ||
      this.model.title.trim() === ""
    ) {
      this.errorLogs.Logs = new Array<string>(); // Clear error logs
      this.errorLogs.Title = "Error";
      const log: string = "Master Policy number or title is needed.";
      this.errorLogs.Logs.push(log);
    } else {
      const formData: FormData = this.setFormData();

      //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
      //IT HAS TO BE IN SINGLE CHUNK OF STRING
      var querystring: string = "";
      for (var pair of formData.entries()) {
        querystring +=
          encodeURIComponent(pair[0]) +
          "=" +
          encodeURIComponent(pair[1] as string) +
          "&";
      }
      this.clausesDeductiblesService
        .deleteHaseEndorsementClause(querystring)
        .subscribe(
          (result: any) => {
            if (
              result != undefined &&
              result.status != undefined &&
              result.status != null &&
              result.status === 200 &&
              result.statusText === "OK"
            ) {
              const v2DialogRef = this.cdkDialogService.open(
                MdInformationMessageComponent,
                {
                  data: {
                    message: "Data deleted. Redirect to listing page",
                  },
                }
              );

              v2DialogRef.afterClosed().subscribe((result: any) => {
                this.returnListing.emit("returnToListing");
              });
            }
          },
          (err: any) => {
            console.log(err);

            this.cdkDialogService.open(MdInformationMessageComponent, {
              data: {
                message: "An error has occurred. Please contact admin",
              },
            });
          }
        );
    }
  }
}
