<div class="wrapper">
  <kendo-tabstrip style="padding-top: 30px">
    <kendo-tabstrip-tab title="Reconcile" [selected]="true">
      <ng-template kendoTabContent>
        <div class="container-fluid mt-2">
          <div *ngIf="isInvalidSearch" class="alert alert-warning" role="alert">
            <span>
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </span>
            <strong class="ml-1">Please enter at least ONE search info.</strong>
          </div>
        </div>
        <div class="container-fluid">
          <!-- Search filters -->
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="form-group row lbl-align">
                <div class="col-md-4">
                  <label>Product Type</label>
                </div>
                <div class="col-md-2">
                  <input
                    type="radio"
                    name="productRadio"
                    value="wpb"
                    [(ngModel)]="searchCriteria.productRadio"
                    (change)="onChange_ProductRadio()"
                  />
                  <label class="lbl" for="recProductRadio"
                    >&nbsp; &nbsp; WPB</label
                  >
                </div>
                <div class="col-md-2">
                  <input
                    type="radio"
                    name="productRadio"
                    value="cmb"
                    [(ngModel)]="searchCriteria.productRadio"
                    (change)="onChange_ProductRadio()"
                  />
                  <label class="lbl" for="recProductRadio"
                    >&nbsp; &nbsp; CMB</label
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- Left search section -->
              <div class="form-group row lbl-align">
                <div class="col-md-4">
                  <input
                    name="reconcile"
                    type="radio"
                    value="2"
                    (click)="getDisableInput(2)"
                  />
                  <label [ngClass]="'lbl'" for="recPeriod"
                    >&nbsp;Payment Month</label
                  >
                </div>
                <div class="col-md-6">
                  <div>
                    <kendo-dateinput
                      class="date-input"
                      id="autoDebitPeriod_dateInput"
                      format="MM/yyyy"
                      [disabled]="isInvoice"
                      placeholder="month/year"
                      [(ngModel)]="autoDebitPeriod"
                      #name="ngModel"
                    ></kendo-dateinput>
                  </div>
                </div>
              </div>
              <div class="form-group row lbl-align">
                <div class="col-md-4">
                  <input
                    name="reconcile"
                    type="radio"
                    value="3"
                    (click)="getDisableInput(3)"
                  />
                  <label class="lbl" for="recPolicyNo"
                    >&nbsp;Policy Number</label
                  >
                </div>
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    [disabled]="isPolicyNumber"
                    id="oiPolicyNumber"
                    placeholder="Policy No"
                    [(ngModel)]="searchCriteria.policyNumber"
                  />
                </div>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
                  (click)="search()"
                  [disabled]="isProcessing"
                >
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <i
                    *ngIf="isProcessing"
                    class="fa fa-circle-o-notch fa-spin fa-fw"
                    aria-hidden="true"
                  ></i>
                  Search
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label
                  id="excelFilesErrorMessage"
                  style="color: red"
                  *ngIf="hasError"
                >
                  {{ excelFilesErrorMessage }}
                </label>
                <kendo-fileselect
                  [restrictions]="excelAndSizeRestrictions"
                  (select)="select($event)"
                  (remove)="removeEvent($event)"
                >
                </kendo-fileselect>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn ml-0"
                  (click)="add()"
                  [disabled]="isProcessing"
                >
                  <i
                    *ngIf="isProcessing"
                    class="fa fa-circle-o-notch fa-spin fa-fw"
                    aria-hidden="true"
                  ></i>
                  <span class="fa fa-upload fa-1"></span>
                  Upload Excel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row padding10"></div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
<app-md-successful-file-upload
  *ngIf="redirectOpened"
  [opened]="redirectOpened"
  [message]="redirectMessage"
  [data]="redirectData"
  (closeDialogEvent)="confirmationModelForSuccessullExcelUpload($event)"
></app-md-successful-file-upload>
