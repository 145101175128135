import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MfHkTabchangeService {
  private emitSource = new BehaviorSubject<number>(0);
  emit$ = this.emitSource.asObservable();

  emitNumber(value: number) {
    this.emitSource.next(value);
  }

  clearMessages() {
    this.emitSource.next(0);
  }
}
