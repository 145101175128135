import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GUID } from "../framework/domain-entity/guid";
import { Global } from "../shared/global";
import { IUser } from "../framework/interface.shared";
import { common } from "../framework/utils/common";
import { environment } from "src/environments/environment";
import { ApiService } from "./dal/api.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export class MasterPolicyEntity {
  id!: string;
  masterPolicyNumber!: string;
  policyNumber!: string;
  description!: string;
  commissionRate!: number;
  noOfFreeInsuranceYear!: number;
  policyType!: string;
  typeDescription!: string;
  productType!: string;
  homeContentSumInsuredAmount!: number;
  productName!: string;
  isMI!: boolean;
  active!: boolean;
  productId!: string;
  country!: string;
  meridianMasterPolicyNumber!: string;
  paymentType!: number;
  endEffectiveDate!: Date;
  startEffectiveDate!: Date;
}

@Injectable({
  providedIn: "root",
})
export class MasterPolicyService extends ApiService {
  private url =
    "api/product/policy/getMasterPolicies?v=" + environment.jsonVersion;
  private masterPolicyList!: MasterPolicyEntity[];
  private masterPolicyData!: MasterPolicyEntity[];
  private masterPolicyDictionary!: { [key: string]: MasterPolicyEntity };
  private productMasterPolicyListDictionary!: {
    [key: string]: MasterPolicyEntity[];
  };
  private productListing: any;
  private userMasterPolicyData!: MasterPolicyEntity[];
  //private Identity: Identity;

  SERVER_URL_TODOSEARCH: string = "api/product/home/todo/search";
  SERVER_URL_HASE_TODOSEARCH: string = "api/product/home/todo/haseSearch";
  SERVER_URL_POLICYSEARCH: string = "api/product/home/policy/search";
  SERVER_URL_HASE_POLICYSEARCH: string = "api/product/home/policy/haseSearch";
  SERVER_URL_EXPORTSEARCH: string = "api/product/home/export/search";
  SERVER_URL_EXPORTEXPORT: string = "api/product/home/export/export";
  SERVER_URL_GETPROPOSALDATESUBMITTED: string =
    "api/proposal/getProposalDateSubmitted";
  SERVER_URL_HASE_REPORT: string = "api/product/home/report/export";

  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  getbyMasterPolicyId(masterPolicyId: GUID) {
    if (common.isDefined(this.masterPolicyDictionary)) {
      return this.masterPolicyDictionary[masterPolicyId.toString()];
    } else {
      return;
    }
  }

  getProposalDateSubmitted(proposalId: string): Observable<any> {
    return this.add<any>(
      this.SERVER_URL_GETPROPOSALDATESUBMITTED,
      {},
      { proposalId: proposalId }
    ).pipe(
      map(
        (response: any) => {
          //return response.result;
          return response.body.result; //it's in body.result in new angular
        },
        (error: any) => {
          return error;
        }
      )
    );
  }

  getListByProduct(
    productId: GUID,
    submittedDate: string | number | Date,
    notAppendPolicy?: boolean
  ): Observable<MasterPolicyEntity[]> {
    if (common.isDefined(this.productMasterPolicyListDictionary)) {
      return of(this.productMasterPolicyListDictionary[productId.toString()]);
    } else {
      this.productMasterPolicyListDictionary = {};
      this.masterPolicyData = [];
      return this.getApiData<any>(this.url).pipe(
        map(
          (response: any) => {
            const masterPolicyList = response.d;
            // filtering the master policy data based on the dates
            if (Global.isDefined(masterPolicyList)) {
              masterPolicyList.forEach(
                (list: {
                  startEffectiveDate: string | number | Date;
                  endEffectiveDate: string | number | Date;
                  active: any;
                  commissionRate: any;
                  country: any;
                  description: any;
                  homeContentSumInsuredAmount: any;
                  id: any;
                  isMI: any;
                  masterPolicyNumber: any;
                  meridianMasterPolicyNumber: any;
                  noOfFreeInsuranceYear: any;
                  paymentType: any;
                  policyNumber: any;
                  policyType: any;
                  productId: any;
                  productName: any;
                  productType: any;
                  typeDescription: any;
                }) => {
                  if (
                    new Date(submittedDate) >
                      new Date(list.startEffectiveDate) &&
                    new Date(list.endEffectiveDate) >= new Date(submittedDate)
                  ) {
                    this.masterPolicyData.push({
                      active: list.active,
                      commissionRate: list.commissionRate,
                      country: list.country,
                      description: list.description,
                      endEffectiveDate: new Date(list.endEffectiveDate),
                      homeContentSumInsuredAmount:
                        list.homeContentSumInsuredAmount,
                      id: list.id,
                      isMI: list.isMI,
                      masterPolicyNumber: list.masterPolicyNumber,
                      meridianMasterPolicyNumber:
                        list.meridianMasterPolicyNumber,
                      noOfFreeInsuranceYear: list.noOfFreeInsuranceYear,
                      paymentType: list.paymentType,
                      policyNumber: list.policyNumber,
                      policyType: list.policyType,
                      productId: list.productId,
                      productName: list.productName,
                      productType: list.productType,
                      startEffectiveDate: new Date(list.startEffectiveDate),
                      typeDescription: list.typeDescription,
                    });
                  }
                }
              );
            }

            this.masterPolicyData.forEach((mp) => {
              let displayText =
                mp.policyType +
                " - " +
                mp.description +
                " - " +
                mp.meridianMasterPolicyNumber;
              if (!common.isDefined(notAppendPolicy)) {
                displayText += " (MSIG: " + mp.policyNumber + ")";
              }
              mp.description = displayText;
            });

            this.masterPolicyData.forEach((mp: MasterPolicyEntity) => {
              const productIdText = mp.productId.trim().toUpperCase();
              if (
                common.isUndefinedOrNull(
                  this.productMasterPolicyListDictionary[productIdText]
                )
              ) {
                this.productMasterPolicyListDictionary[productIdText] = [];
              }

              // Only include active master policy
              if (mp.active) {
                this.productMasterPolicyListDictionary[productIdText].push(mp);
              }
            });

            return this.productMasterPolicyListDictionary[productId.toString()];
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  }

  public searchTodoPolicyListing(querystring: string) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase + this.SERVER_URL_TODOSEARCH,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public searchHaseTodoPolicyListing(querystring: string) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase + this.SERVER_URL_HASE_TODOSEARCH,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public searchPolicyListing(querystring: string) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase + this.SERVER_URL_POLICYSEARCH,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public searchHasePolicyListing(querystring: string) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase + this.SERVER_URL_HASE_POLICYSEARCH,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public searchExportListing(
    querystring: string,
    exportExcel: boolean = false
  ) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase +
        (!exportExcel
          ? this.SERVER_URL_EXPORTSEARCH
          : this.SERVER_URL_EXPORTEXPORT),
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }

  getList(
    currentUser: IUser,
    productId: GUID = new GUID(),
    active: boolean = true
  ): Observable<MasterPolicyEntity[]> {
    if (
      common.isDefined(this.masterPolicyData) &&
      this.masterPolicyData.length > 0
    ) {
      this.masterPolicyDictionary = {};
      this.userMasterPolicyData = [];
      this.productListing =
        currentUser?.products !== undefined ? currentUser.products : [];

      this.masterPolicyData.forEach((mp: MasterPolicyEntity) => {
        this.productListing?.forEach((product: { pdid: string }) => {
          if (
            product.pdid.toUpperCase() ===
            mp.productId.toString().trim().toUpperCase()
          ) {
            this.masterPolicyDictionary[
              mp.id.toString().trim().replace(" ", "")
            ] = mp;
            this.userMasterPolicyData.push(mp);
          }
        });
      });

      return of(this.userMasterPolicyData);
    } else {
      this.masterPolicyDictionary = {};
      this.masterPolicyData = [];
      this.userMasterPolicyData = [];
      return this.getApiData<any>(this.url).pipe(
        map(
          (response: any) => {
            this.masterPolicyList = response.d;
            if (Global.isDefined(this.masterPolicyList)) {
              this.masterPolicyList.forEach((list) => {
                if (
                  new Date() < new Date(list.endEffectiveDate) &&
                  new Date(list.startEffectiveDate) < new Date()
                ) {
                  this.masterPolicyData.push({
                    active: list.active,
                    commissionRate: list.commissionRate,
                    country: list.country,
                    description: list.description,
                    endEffectiveDate: list.endEffectiveDate,
                    homeContentSumInsuredAmount:
                      list.homeContentSumInsuredAmount,
                    id: list.id,
                    isMI: list.isMI,
                    masterPolicyNumber: list.masterPolicyNumber,
                    meridianMasterPolicyNumber: list.meridianMasterPolicyNumber,
                    noOfFreeInsuranceYear: list.noOfFreeInsuranceYear,
                    paymentType: list.paymentType,
                    policyNumber: list.policyNumber,
                    policyType: list.policyType,
                    productId: list.productId,
                    productName: list.productName,
                    productType: list.productType,
                    startEffectiveDate: list.startEffectiveDate,
                    typeDescription: list.typeDescription,
                  });
                }
              });
            }
            this.productListing =
              currentUser?.products !== undefined ? currentUser.products : [];

            if (
              common.isDefined(this.userMasterPolicyData) &&
              this.userMasterPolicyData.length == 0
            ) {
              this.masterPolicyData.forEach((mp: MasterPolicyEntity) => {
                this.productListing?.forEach((product: { pdid: string }) => {
                  if (
                    product.pdid.toUpperCase() ===
                    mp.productId.toString().trim().toUpperCase()
                  ) {
                    this.masterPolicyDictionary[
                      mp.id.toString().trim().replace(" ", "")
                    ] = mp;
                    this.userMasterPolicyData.push(mp);
                  }
                });
              });
            }

            return this.userMasterPolicyData;
          },
          (error: any) => {
            return error;
          }
        )
      );
    }
  }

  public async exportReport(reportId: string
  ): Promise<any> {
    const uri = environment.serviceBase + this.SERVER_URL_HASE_REPORT;
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/json", 
    });
    const response = await this.httpClient
      .post<any>(uri,reportId, { headers: headers })
      .toPromise();
      if (response)
        return response.d;
  }
}
