import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  RefundPaymentEntity,
  OpenItemEntity,
} from "../models/open-item-list.model";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { RefundPaymentType } from "src/app/framework/enum.shared";
import { OpenItemStatus } from "src/app/finance/finance-enums";
import { proposalCommon } from "src/app/components/new-product/base/utils/proposal-common";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { common } from "src/app/framework/utils/common";
import { OpenItemListService } from "src/app/services/open-item-list.service";

@Component({
  selector: "app-do-refund-pay-form",
  templateUrl: "./do-refund.component.html",
  styleUrls: ["./do-refund.component.scss"],
})
export class DoRefundComponent implements OnInit {
  public refundPayment = new RefundPaymentEntity();
  public openItemId!: string;
  public openItemEntity = new OpenItemEntity();
  public totalPremium: any;
  public active = false;
  public opened: boolean = false;

  refundType!: RefundPaymentType;
  refundTypeDisplayText!: string;

  @Input() public set model(openItemEntity: any) {
    this.refundPayment.openItemId = openItemEntity.openItemId;
  }
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() doRefund: EventEmitter<RefundPaymentEntity> = new EventEmitter();
  //validation
  invalidRequiredField = false;
  invalidPayerName = false;
  invalidCIB = false;
  invalidCN = false;
  invalidRemark = false;

  constructor(
    private cdkDialogService: CdkDialogService,
    private openItemListService: OpenItemListService
  ) {}

  ngOnInit(): void {
    this.getOpenItemDetails();
  }
  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }
  private getOpenItemDetails() {
    this.openItemListService
      .getOpenItem(this.refundPayment.openItemId)
      .subscribe((response: OpenItemEntity) => {
        const openItem: OpenItemEntity = response;
        this.active = openItem !== undefined;
        this.openItemEntity = openItem;
        this.refundType =
          openItem.statusId === OpenItemStatus.PendingRefundToBankInReview
            ? RefundPaymentType.OnlineTransfer
            : openItem.statusId ===
              OpenItemStatus.PendingRefundToIndividualInReview
            ? RefundPaymentType.Cheque
            : RefundPaymentType.Undefined;
        const refundTypeDisplayText: string =
          proposalCommon.translateRefundPaymentType(this.refundType);
        this.refundTypeDisplayText = refundTypeDisplayText;
        this.refundPayment.refundPaymentType = this.refundType;
      });
  }
  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  ConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  public onSave(e: PointerEvent): void {
    this.invalidRequiredField = false;
    const isValid: boolean = this.validateMandatoryFields();
    if (isValid) {
      this.ConfirmationPopup(
        "Are you sure you want to refund this payment?",
        ""
      );
    } else {
      this.invalidRequiredField = true;
      e.preventDefault();
    }
  }
  private validateMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields
    if (common.isStringNullOrEmpty(this.refundPayment.payerName)) {
      hasError = true;
      this.invalidPayerName = true;
    } else {
      this.invalidPayerName = false;
    }
    if (common.isStringNullOrEmpty(this.refundPayment.chequeIssuanceBank)) {
      hasError = true;
      this.invalidCIB = true;
    } else {
      this.invalidCIB = false;
    }
    if (common.isStringNullOrEmpty(this.refundPayment.chequeNo)) {
      hasError = true;
      this.invalidCN = true;
    } else {
      this.invalidCN = false;
    }
    if (this.refundType === 2) {
      if (common.isStringNullOrEmpty(this.refundPayment.remarks)) {
        hasError = true;
        this.invalidRemark = true;
      } else {
        this.invalidRemark = false;
      }
    }

    return !hasError;
  }
  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      this.doRefund.emit(this.refundPayment);
    }
  }
}
