<div class="wrapper">
  <kendo-tabstrip (tabSelect)="onTabSelect($event)" style="padding-top: 30px">
    <kendo-tabstrip-tab title="LST Rate" [selected]="true">
      <ng-template kendoTabContent>
        <!--Error Log -->
        <div
          class="col-md-12 card card-mod cardNoBorder data-ng-scope"
          *ngIf="errorLogCheck()"
        >
          <div class="card-head chubbRed_bg">
            <div class="tools">
              <div class="btn-group">
                <a class="btn btn_mod btn-collapse">
                  <i class="fa chubbWhite"></i>
                </a>
              </div>
            </div>
            <header
              class="fontBold chubbWhite"
              data-ng-bind="errorLogs.Title"
            ></header>
          </div>
          <div class="card-body card-body-mod cardBodyBorder">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <ul>
                      <li *ngFor="let log of errorLogs?.Logs">{{ log }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="col-md-12">
          <table class="table table-bordered table-responsive">
            <thead>
              <tr class="headerRow">
                <th class="text-center col-md-1">Row</th>
                <th class="text-center col-md-2">LST RI Code</th>
                <th class="text-center col-md-2">Rate Percentage %</th>
                <th class="text-center col-md-2">Commission Percentage %</th>
                <th class="text-center col-md-2">Start Effective Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let lstRate of lstRates">
                <td class="textAlignCenter">
                  {{ lstRate.SortOrder }}
                </td>
                <td class="textAlignCenter">
                  <kendo-textbox
                    id="oiLSTRICode"
                    placeholder="Batch ID"
                    name="LSTRICode"
                    #name="ngModel"
                    [(ngModel)]="lstRate.LSTRICode.Value"
                    (keyup)="validate()"
                    [maxlength]="10"
                    required
                  ></kendo-textbox>
                </td>
                <td class="textAlignCenter">
                  <kendo-numerictextbox
                    name="ReferencePercentage"
                    #name="ngModel"
                    [(ngModel)]="lstRate.ReferencePercentage.Value"
                    [min]="0"
                    [max]="100"
                    [format]="'###.00 \\\%'"
                    [step]="0.01"
                    [decimals]="2"
                    (keyup)="validate()"
                    (valueChange)="on_oiFrom_change()"
                    required
                  >
                  </kendo-numerictextbox>
                </td>
                <td class="textAlignCenter">
                  <kendo-numerictextbox
                    name="RICommissionPercentage"
                    #name="ngModel"
                    [(ngModel)]="lstRate.RICommissionPercentage.Value"
                    [min]="0"
                    [max]="100"
                    [format]="'###.00 \\\%'"
                    [step]="0.01"
                    [decimals]="2"
                    (keyup)="validate()"
                    (valueChange)="on_oiFrom_change()"
                    required
                  >
                  </kendo-numerictextbox>
                </td>
                <td class="textAlignCenter">
                  <kendo-datepicker
                    id="oiEffectiveDateFrom"
                    format="dd/MM/yyyy"
                    placeholder=""
                    [(ngModel)]="lstRate.EffectiveStartDate.Value"
                    [min]="lstRate.EffectiveStartDate.OldValue"
                    name="EffectiveDateFrom"
                    #name="ngModel"
                    (valueChange)="on_oiFrom_change()"
                    required
                  ></kendo-datepicker>
                </td>
                <!-- (focus)="on_searchOIFrom_focus()" 
                (valueChange)="on_searchEffectiveDateFrom_change($event)" -->
              </tr>
              <tr>
                <td class="text-right" colspan="5">
                  <button
                    (click)="onConfirmUpdate()"
                    [disabled]="!isDirty"
                    class="btn btn-primary ld-ext-left chubbWhite chubbLightBlue_bg_btn"
                    event="1"
                  >
                    Update
                  </button>
                  <button
                    (click)="onCancel()"
                    [disabled]="!isDirty"
                    class="btn btn-primary ld-ext-left chubbWhite chubbRed_bg_bt"
                    event="1"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
