import {
  Component,
  Input,
  OnInit,
  LOCALE_ID,
  Inject,
  ViewChild,
} from "@angular/core";
import { formatDate } from "@angular/common";
import {
  ApiModel,
  PersonModel,
  UserProfileModel,
} from "../../user-profile.model";
import * as constants from "../../user-profile.constant";
import { common } from "src/app/framework/utils/common";
import { UserProfileService } from "src/app/services/user-profile.service";
import { FormGroup, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-edit-personal-details",
  templateUrl: "./edit-personal-details.component.html",
  styleUrls: ["./edit-personal-details.component.scss"],
})
export class EditPersonalDetailsComponent implements OnInit {
  isDataLoaded: boolean = false;
  userDetails = new ApiModel();
  @Input() data!: any;
  personalDetails = new PersonModel();
  public genderList = new Array<{ text: string; value: number }>();
  public statusList = new Array<{ text: string; value: number }>();
  public nationality = new Array<{ text: string; value: string }>();
  public noOfChilds = new Array<{ text: string; value: string }>();
  @ViewChild("userForm", { static: false })
  private userForm!: UntypedFormGroup;
  dob!: Date | undefined;
  public dob_max!: Date;
  serverErrorMsg!: string;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private userProfileService: UserProfileService
  ) {}
  ngOnInit(): void {
    this.dob_max = new Date();
    this.genderList = [
      { text: "Male", value: 1 },
      { text: "Female", value: 2 },
    ];
    this.statusList = [
      { text: "", value: 0 },
      { text: "Single", value: 1 },
      { text: "Married", value: 2 },
    ];
    this.getUserProfile();
    this.loadNationality();
    this.loadChildData();
  }
  public getUserProfile(): void {
    if (common.isDefined(this.data?.userId) && this.data?.userId != "") {
      this.isDataLoaded = true;
      this.userProfileService
        .searchUserProfileByUserId(this.data.userId)
        .subscribe((response) => {
          if (response && response.body) {
            this.userDetails = response.body;
            this.personalDetails = this.userDetails.userProfile.person;
            this.setDate();
            this.isDataLoaded = false;
          }
        });
    }
  }
  setDate(): void {
    if (this.isDateTimeMinValue(this.personalDetails.dateOfBirth))
      this.dob = undefined;
    else {
      if (this.personalDetails.dateOfBirth) {
        let date = formatDate(
          this.personalDetails.dateOfBirth,
          "dd/MM/yyyy",
          "en-US"
        );
        let year = date.split("/")[2];
        let month = date.split("/")[1];
        let day = date.split("/")[0];
        this.dob = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      }
    }
  }
  //save data
  saveData(): UserProfileModel {
    if (this.dob) {
      const answer = new Date(this.dob);
      const year = answer.getFullYear();
      const month = answer.getMonth() + 1;
      const day = answer.getDate();
      this.personalDetails!.dateOfBirth = `${year}-${month}-${day} 12:00:00 AM`;
    }
    this.userDetails.userProfile.subUser = { mode: "edit" };
    return this.userDetails.userProfile;
  }
  //Load country based on the selected usertype
  private loadNationality(): void {
    constants.nationality.forEach((item) => {
      this.nationality.push(item);
    });
  }
  private loadChildData(): void {
    constants.personNoOfChildren.forEach((item) => {
      this.noOfChilds.push(item);
    });
  }
  isDateTimeMinValue(value: any) {
    return formatDate(value, "yyyy", this.locale) === "0001";
  }
  onSubmit(): void {}
  public validateFormData(): boolean {
    return this.userForm.valid;
  }
  public showServerValidation(msg: string): void {
    this.serverErrorMsg = msg;
  }
}
