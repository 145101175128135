<!-- #LSTReportFields -->
<form #heroForm="ngForm">
  <div id="LSTReportFields" class="container-fluid reportOption">
    <div class="row">
      <div class="col-md-6">
        <!-- Left search section -->
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="LSTPolicyNo">Policy No</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="LSTPolicyNo"
              placeholder="Policy No"
              [(ngModel)]="searchCriteria.policyNo"
              name="policyNo"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="LSTInsuredName">Insured Name</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="LSTInsuredName"
              placeholder="Insured Name"
              [(ngModel)]="searchCriteria.insuredName"
              name="insuredName"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5">Transaction Type</label>
          </div>
          <div class="col-md-6">
            <kendo-multiselect
              placeholder="Select Transaction Type..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="transactionTypeOption"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.transType"
              name="transactionType"
            >
            </kendo-multiselect>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="LSTPolicyProduct">Product</label>
          </div>
          <div class="col-md-6">
            <kendo-multiselect
              placeholder="Select product..."
              [autoClose]="false"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [data]="userProductList"
              textField="pd"
              valueField="pdid"
              [valuePrimitive]="true"
              [(ngModel)]="searchCriteria.productIds"
              name="productIds"
            >
            </kendo-multiselect>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- Right search section -->
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="LSTEffectiveDateFrom"
              >Effective Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="LSTEffectiveDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.effectiveDateFrom"
              name="effectiveDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="LSTEffectiveDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="LSTEffectiveDateTo"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.effectiveDateTo"
              name="effectiveDateTo"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="LSTBoundDateFrom">Bound Date</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="LSTBoundDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.boundDateFrom"
              name="boundDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="LSTBoundDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="LSTBoundDateTo"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.boundDateTo"
              name="boundDateTo"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="LSTStartDateFrom"
              >Policy Start Date
            </label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="LSTStartDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.startDateFrom"
              name="startDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="LSTStartDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="LSTStartDateTo"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.startDateTo"
              name="startDateTo"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="LSTExpiryDateFrom"
              >Policy Expiry Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="LSTExpiryDateFrom"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.expiryDateFrom"
              name="expiryDateFrom"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="LSTExpiryDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              class="maxWidth"
              id="LSTExpiryDateTo"
              format="dd/MM/yyyy"
              placeholder=""
              [(ngModel)]="searchCriteria.expiryDateTo"
              name="expiryDateTo"
            ></kendo-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <button
        (click)="search()"
        class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
      >
        <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
      </button>
      <button
        (click)="download()"
        class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
      >
        <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
      </button>
    </div>
  </div>
</form>
<br />
<div class="grid-wrapper ml-2">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column
      field="EPLPolicyNumber"
      title="EPL Policy Number"
      [width]="200"
    >
    </kendo-grid-column
    ><kendo-grid-column field="Currency" title="Currency" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="TransCode" title="Trans Code" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="EndorsementCount"
      title="Endorsement No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="GrossTSI" title="Gross TSI" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column field="GrossPrem" title="Gross Prem" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="RICOAccount"
      title="R/I - CO / Account"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="Reference" title="Reference" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="CededPremiumPercentage"
      title="Ceded Premium %"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="CededPremiumAmount"
      title="Ceded Premium Amount"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="LSTPrem" title="LST Prem" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="PercentageCededToSurplus"
      title="% ceded to Surplus"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="NetPremium" title="Net Premium" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="TransactionDate"
      title="Transaction Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="InceptionDate"
      title="Inception Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="EffectiveDate"
      title="Effective Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="ExpiryDate" title="Expiry Date" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="NAICSOccupation"
      title="Occupation (NAICS)"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="SSICOccupation"
      title="Occupation (SSIC)"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="Occupancy" title="Occupancy" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="RiskAddress" title="Risk Address" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="InsuredName" title="Insured Name" [width]="160">
    </kendo-grid-column>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
