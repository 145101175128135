/** study materials

https://stackoverflow.com/questions/3635754/sql-select-between-exact-number-of-rows

select * from
    (select Row_Number() over
     (order by userID) as RowIndex, * from users) as Sub
    Where Sub.RowIndex >= 235 and Sub.RowIndex <= 250
 */

import { ConfigurableMode } from "src/app/framework/enum.shared";

export class MfHkEndorsementListSearchCriteria {
  constructor() {
    this.coverageTypes = new Array<string>();
    this.title = "";
    this.code = "";
  }

  coverageTypes: Array<string>;
  code?: string;
  title?: string;
  typeId: ConfigurableMode = ConfigurableMode.Clauses;
}
