<div class="col-md-12 margin-bottom-xxl">
  <div class="breadcrumbBody">
    <ul class="floatingLeft">
      <div>
        <img src="assets/User.png" />
      </div>
      <div class="headingUserProfile brand-holder">User Data Profile</div>
      <div>
        <img src="assets/{{ additionalInformationImage }}" />
      </div>
      <div
        class="headingUserProfile brand-holder"
        [ngClass]="isTab2 ? 'ACEgreen' : 'ACEGray'"
      >
        Additional Information
      </div>
    </ul>
  </div>
</div>
<div class="wrapper">
  <app-create-profile [isTab2]="isTab2"></app-create-profile>
</div>
<div style="padding: 10px" class="col-md-12">
  <div>
    <button
      value="Cancel"
      class="btn chubbLightBlue chubbLightBlue_border_btn chubbWhite_bg floatLeft"
      (click)="cancel()"
    >
      Cancel
    </button>
  </div>
  <div *ngIf="!isTab2" style="float: right">
    <button
      value="Next"
      class="btn chubbLightBlue chubbLightBlue_border_btn chubbWhite_bg"
      (click)="next()"
    >
      Next
    </button>
  </div>
  <div *ngIf="isTab2" style="float: right">
    <button
      value="Back"
      class="btn chubbLightBlue chubbLightBlue_border_btn chubbWhite_bg"
      (click)="back()"
    >
      Back
    </button>
    <button
      value="Save"
      class="btn chubbRed chubbRed_border_btn chubbWhite_bg"
      (click)="saveAllUserProfile()"
    >
      Save
    </button>
  </div>
</div>
