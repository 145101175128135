<div class="wrapper" id="main">
  <mf-sg-button-panel></mf-sg-button-panel>
  <mf-sg-echo-field-validation-panel></mf-sg-echo-field-validation-panel>
  <mf-sg-referral-validation-echo-panel></mf-sg-referral-validation-echo-panel>
  <mf-sg-pending-transaction-panel></mf-sg-pending-transaction-panel>
  <mf-sg-tabs id="mf-sg-tabs" [proposal]="this.proposal"> </mf-sg-tabs>
  <div class="row">
    <div class="col-md-12">
      <span
        class="floatRight chubbLightOrange_bg chubbBlack fontBold"
        style="padding: 5px 10px"
        >{{ productName }}</span
      >
      <span
        class="floatRight chubbRed_bg chubbWhite fontBold"
        *ngIf="proposal?.loanRedeemed"
        style="padding: 5px 10px"
        >Loan Redeemed</span
      >
    </div>
  </div>
  <mf-sg-header [proposal]="this.proposal"></mf-sg-header>
  <mf-sg-master [proposal]="this.proposal" *ngIf="isDbsProduct"></mf-sg-master>
  <mf-sg-premium-summary
    [proposal]="this.proposal"
    *ngIf="isDbsProduct"
  ></mf-sg-premium-summary>
  <ct-sg-master [proposal]="this.proposal" *ngIf="isCitiProduct"></ct-sg-master>
  <ct-sg-premium-summary
    [proposal]="this.proposal"
    *ngIf="isCitiProduct"
  ></ct-sg-premium-summary>
  <ct-sg-premium-period-history
    [proposal]="this.proposal"
    *ngIf="showPremiumPeriodHistory()"
  ></ct-sg-premium-period-history>
</div>
<div
  class="back-to-top fa fa-3x fa-angle-double-up"
  (click)="scrollToTop()"
  style="display: block"
></div>
