export class CitiMonthlyReportSearchCriteria {
  constructor() {
    //this.dateFrom = null;
    //this.dateTo = null;
    this.segments = [];
    this.productIds = [];
    this.customerType = [];
    //this.transactionType = [];
    this.policyStatus = [];
  }
  dateFrom?: Date;
  dateTo?: Date;
  segments: Array<string>;
  productIds: Array<string>;
  customerType: Array<string>;
  transactionType!: number;
  policyStatus: Array<string>;
}
