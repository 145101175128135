import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MfHkEndorsementListSearchCriteria } from "src/app/components/new-product/sme/mortgage-fire/hong-kong/components/endorsement/endorsement-list/mf-hk-end-list-search-criteria";

@Component({
  selector: "app-configurable-filter",
  templateUrl: "./configurable-filter.component.html",
  styleUrls: ["./configurable-filter.component.scss"],
})
export class ConfigurableFilterComponent implements OnInit {
  @Input()
  model = new MfHkEndorsementListSearchCriteria();
  @Input()
  coverageTypeOptions!: Array<{ text: string; value: string }>;
  @Input()
  searchPlaceholder: string = "Search Clauses and Warranties";

  @Output() searchClickEvent = new EventEmitter<any>();
  @Output() newClickEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  addNew() {
    this.newClickEvent.emit("test");
  }
}
