import { Component, OnInit } from "@angular/core";
import { BaseConfigurablesListComponent } from "src/app/components/new-product/base/base-configurables-list/base-configurables-list.component";
import { MfHkConfigEditCriteria } from "src/app/components/shared/partials/configurable-form-edit/mf-hk-config-edit-criteria";
import { ConfigurableMode } from "src/app/framework/enum.shared";

@Component({
  selector: "mf-hk-deductible-list",
  templateUrl: "./deductible-list.component.html",
  styleUrls: ["./deductible-list.component.scss"],
})
export class DeductibleListComponent
  extends BaseConfigurablesListComponent
  implements OnInit
{
  public coverageTypeOptions: Array<{ text: string; value: string }> = [
    { text: "Fire", value: "84B90097-F4C7-4AAA-9B99-71FE8CD7355E" },
    {
      text: "Property All Risk",
      value: "4AE939D5-8FAF-4AF5-8B4F-8822ED23B817",
    },
  ];

  override ngOnInit(): void {
    this.model.typeId = ConfigurableMode.Deductibles; //do before super.ngOnInit() for loading list

    super.ngOnInit();
  }

  addNewDeductible(val: any) {
    this.editModel = new MfHkConfigEditCriteria(); //reset

    this.isAdd = true;
  }
}
