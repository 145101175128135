import { Component, OnInit, ViewChild } from "@angular/core";
import { CellClickEvent } from "@progress/kendo-angular-grid";
import { BaseConfigurablesListComponent } from "src/app/components/new-product/base/base-configurables-list/base-configurables-list.component";
import { ConfigListingEntity } from "src/app/components/new-product/base/base-configurables-list/config-listing-entity";
import { ConfigurableFilterComponent } from "src/app/components/shared/partials/configurable-filter/configurable-filter.component";
import { MfHkConfigEditCriteria } from "src/app/components/shared/partials/configurable-form-edit/mf-hk-config-edit-criteria";

@Component({
  selector: "mf-hk-endorsement-list",
  templateUrl: "./endorsement-list.component.html",
  styleUrls: ["./endorsement-list.component.scss"],
})
export class EndorsementListComponent
  extends BaseConfigurablesListComponent
  implements OnInit
{
  @ViewChild("childConfigurableFilter", { static: false })
  childConfigurableFilter!: ConfigurableFilterComponent;

  public coverageTypeOptions: Array<{ text: string; value: string }> = [
    { text: "Fire", value: "84B90097-F4C7-4AAA-9B99-71FE8CD7355E" },
    {
      text: "Property All Risk",
      value: "4AE939D5-8FAF-4AF5-8B4F-8822ED23B817",
    },
  ];

  override ngOnInit(): void {
    super.ngOnInit();
  }

  addNewClausesAndWarranties(val: any) {
    this.editModel = new MfHkConfigEditCriteria(); //reset

    this.isAdd = true;
  }
}
