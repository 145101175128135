<kendo-tabstrip
  (tabSelect)="onTabSelect($event)"
  id="tabstrip"
  *ngIf="pageLoaded"
>
  <kendo-tabstrip-tab
    title="{{ master.displayName }}"
    id="master"
    style="cursor: default"
    *ngIf="master.visible"
    [selected]="true"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <!--todo-->
      </div>
    </ng-template></kendo-tabstrip-tab
  >

  <kendo-tabstrip-tab
    title="{{ premiumSummary.displayName }}"
    id="premiumSummary"
    style="cursor: default"
    *ngIf="premiumSummary.visible"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <!--todo-->
      </div>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="{{ clausesAndDeductibles.displayName }}"
    id="clausesAndDeductibles"
    style="cursor: default"
    *ngIf="clausesAndDeductibles.visible"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <!--todo-->
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
