class ReportHelper {
  getTaxInvoiceRow(
    referenceNumber: string,
    forAdMonth: string,
    reportExtractedDate: string,
    totalGrossPremium: number,
    totalGST: number,
    totalPremium: number,
    reportName: string,
    segment: string
  ): Array<{}> {
    // Sheet 3 - Tax Invoice
    const rows3: any = [];

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "CHUBB®", bold: true, textAlign: "left", fontSize: 20 },
        { value: "", bold: true, textAlign: "left" },
        {
          value: "Chubb Insurance Singapore Limited",
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
          fontSize: 9,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        {
          value: "Co Regn. No.: 199702449H",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 9,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        {
          value: "GST Registration no.: 199702449H",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 9,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        {
          value: "138 Market Street",
          bold: false,
          fontFamily: "Georgia",
          textAlign: "left",
          fontSize: 9,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        {
          value: "#11-01 CapitaGreen",
          bold: false,
          fontFamily: "Georgia",
          textAlign: "left",
          fontSize: 9,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        {
          value: "Singapore 048946",
          bold: false,
          fontFamily: "Georgia",
          textAlign: "left",
          fontSize: 9,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "Summary of Tax Invoice",
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
          fontSize: 18,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
      height: 25,
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        {
          value: "Reference Number:-",
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left", fontSize: 11 },
        {
          value: referenceNumber,
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
          fontSize: 11,
        },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "DBS Bank Ltd",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          color: "#ff0000",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        {
          value: "Date:-",
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left" },
        {
          value: reportExtractedDate,
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
        },
      ],
    });

    var address1 = "";
    if (segment != "CBG") {
      address1 = "IBG Ops - Corporate Loans";
    } else {
      address1 = "Credit Operations";
    }

    rows3.push({
      cells: [
        {
          value: address1,
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          color: "#ff0000",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "2 Changi Business Park Crescent",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          color: "#ff0000",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    var address2 = "";
    if (segment != "CBG") {
      address2 = "#04-06 DBS Asia Hub (Lobby B)";
    } else {
      address2 = "#07-03 DBS Asia Hub (Lobby A)";
    }

    rows3.push({
      cells: [
        {
          value: address2,
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          color: "#ff0000",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "Singapore 486029",
          bold: false,
          fontFamily: "Georgia",
          textAlign: "left",
          color: "#ff0000",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    var attendTo = "";
    if (segment != "CBG") {
      attendTo = "Attn : Zuraida";
    } else {
      attendTo = "Attn : Melcia Chen";
    }

    rows3.push({
      cells: [
        {
          value: attendTo,
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          color: "#ff0000",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    var description = "";
    description =
      reportName == "AD"
        ? "Description of Payment – Auto Debit"
        : reportName == "FI"
        ? "Description of Payment – Free Insurance"
        : "";
    rows3.push({
      cells: [
        {
          value: description,
          fontFamily: "Georgia",
          verticalAlign: "center",
          bold: true,
          textAlign: "left",
          fontSize: 11,
          borderTop: { color: "#000000", size: 3 },
          borderLeft: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: true,
          textAlign: "left",
          borderTop: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderTop: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: true,
          textAlign: "left",
          borderTop: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: true,
          textAlign: "left",
          borderTop: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: true,
          textAlign: "left",
          borderRight: { color: "#000000", size: 3 },
          borderTop: { color: "#000000", size: 3 },
        },
      ],
      height: 50,
    });

    rows3.push({
      cells: [
        {
          value: "For the month of:-",
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
          fontSize: 11,
          borderLeft: { color: "#000000", size: 3 },
        },
        {
          value: forAdMonth,
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
          underline: true,
        },
        {
          value: "(schedule as per attached)",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        {
          value: "",
          bold: true,
          textAlign: "left",
          borderRight: { color: "#000000", size: 3 },
        },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderLeft: { color: "#000000", size: 3 },
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderRight: { color: "#000000", size: 3 },
        },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderLeft: { color: "#000000", size: 3 },
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        {
          value: "Premium:-",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "S$",
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: totalGrossPremium,
          fontFamily: "Georgia",
          bold: true,
          textAlign: "right",
          fontSize: 11,
          borderRight: { color: "#000000", size: 3 },
        },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderLeft: { color: "#000000", size: 3 },
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        {
          value: "GST:-",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "S$",
          fontFamily: "Georgia",
          bold: true,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: totalGST,
          fontFamily: "Georgia",
          bold: true,
          textAlign: "right",
          fontSize: 11,
          borderRight: { color: "#000000", size: 3 },
        },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderLeft: { color: "#000000", size: 3 },
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        {
          value: "Amount Due:-",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "S$",
          bold: true,
          fontFamily: "Georgia",
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: totalPremium,
          fontFamily: "Georgia",
          bold: true,
          textAlign: "right",
          fontSize: 11,
          borderRight: { color: "#000000", size: 3 },
          borderTop: { color: "#000000", size: 3 },
          borderBottom: { color: "#000000", size: 3 },
        },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderLeft: { color: "#000000", size: 3 },
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        {
          value: "",
          bold: true,
          textAlign: "left",
          borderRight: { color: "#000000", size: 3 },
        },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderBottom: { color: "#000000", size: 3 },
          borderLeft: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderBottom: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderBottom: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: false,
          textAlign: "left",
          borderBottom: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: true,
          textAlign: "left",
          borderBottom: { color: "#000000", size: 3 },
        },
        {
          value: "",
          bold: true,
          textAlign: "left",
          borderBottom: { color: "#000000", size: 3 },
          borderRight: { color: "#000000", size: 3 },
        },
      ],
    });
    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "Note:-",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value:
            "Payment should be arranged to the following bank account, quoting the reference number:-",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "BENEFICIARY'S NAME:",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "CHUBB INSURANCE SINGAPORE LTD - SIF CD",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "BANK'S NAME:",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "DBS Bank Ltd",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "BANK'S ADDRESS:",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value:
            "12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "COUNTRY:",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "SINGAPORE",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "BANK CODE:",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "7171",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "BRANCH CODE:",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "003",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "SGD ACCOUNT NO.:",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "003-952178-2",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    rows3.push({
      cells: [
        {
          value: "SWIFT CODE:",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        {
          value: "DBSSSGSG",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
        { value: "", bold: true, textAlign: "left" },
      ],
    });

    rows3.push({
      cells: [
        {
          value: "No official receipt will be issued",
          fontFamily: "Georgia",
          bold: false,
          textAlign: "left",
          fontSize: 11,
        },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
        { value: "", bold: false, textAlign: "left" },
      ],
    });
    return rows3;
  }
}

export const reportHelper: ReportHelper = new ReportHelper();
