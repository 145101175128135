import * as Enums from "../../../../../../../framework/enum.shared";

import { GUID } from "./../../../../../../../framework/domain-entity/guid";
import { MortgageFireHKSectionSchema } from "../schemas/mf-hk-section-schema";
import { MortgageFireHKTabSchema } from "./../schemas/mf-hk-tab-schema";
import { ProposalEntity } from "./../../../../../base/proposal-entity.model";
import { QuestionEntity } from "./../../../../../base/question-entity.model";
import { QuestionSection } from "./../../../../../base/question-section";
import { common } from "./../../../../../../../framework/utils/common";
import { MasterDataService } from "src/app/services/master-data.service";

export class MortgageFireHongKongPremiumSummaryQuestionSection extends QuestionSection {
  qs_master_techRate!: QuestionEntity;
  qs_master_brokerCommission!: QuestionEntity;
  qs_master_brokerCommissionRate!: QuestionEntity;
  qs_master_commission!: QuestionEntity;
  qs_master_commissionGst!: QuestionEntity;
  qs_master_commissionPayable!: QuestionEntity;

  // Premium summary
  qs_master_manualReferral!: QuestionEntity;
  qs_master_manualReferralReason!: QuestionEntity;

  //Citibank
  qs_master_netCommOffsetWithDiscount!: QuestionEntity;

  constructor(public override masterDataService: MasterDataService) {
    super(MortgageFireHKTabSchema.premiumSummary.index, masterDataService);
  }

  initializeQuestionSchema() {
    this.qs_master_techRate = new QuestionEntity(
      "qs_master_techRate",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_techRate.displayText = "Tech Rate";
    this.qs_master_techRate.readOnly = true;
    this.qs_master_techRate.required = false;
    this.qs_master_techRate.tabId = this.tabId;
    this.qs_master_techRate.questionPanelId =
      MortgageFireHKSectionSchema.premiumSummary.sectionId;

    this.qs_master_brokerCommission = new QuestionEntity(
      "qs_master_brokerCommission",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_brokerCommission.displayText = "Commission";
    this.qs_master_brokerCommission.readOnly = true;
    this.qs_master_brokerCommission.required = true;
    this.qs_master_brokerCommission.tabId = this.tabId;
    this.qs_master_brokerCommission.questionPanelId =
      MortgageFireHKSectionSchema.premiumSummary.sectionId;

    this.qs_master_brokerCommissionRate = new QuestionEntity(
      "qs_master_brokerCommissionRate",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_brokerCommissionRate.displayText = "Commission Rate";
    this.qs_master_brokerCommissionRate.readOnly = true;
    this.qs_master_brokerCommissionRate.required = true;
    this.qs_master_brokerCommissionRate.tabId = this.tabId;
    this.qs_master_brokerCommissionRate.questionPanelId =
      MortgageFireHKSectionSchema.premiumSummary.sectionId;

    this.qs_master_commission = new QuestionEntity(
      "qs_master_commission",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_commission.displayText = "Commission Amount";
    this.qs_master_commission.readOnly = true;
    this.qs_master_commission.required = false;
    this.qs_master_commission.tabId = this.tabId;
    this.qs_master_commission.questionPanelId =
      MortgageFireHKSectionSchema.premiumSummary.sectionId;

    this.qs_master_commissionGst = new QuestionEntity(
      "qs_master_commissionGst",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_commissionGst.displayText = "GST on Commission";
    this.qs_master_commissionGst.readOnly = true;
    this.qs_master_commissionGst.required = true;
    this.qs_master_commissionGst.tabId = this.tabId;
    this.qs_master_commissionGst.questionPanelId =
      MortgageFireHKSectionSchema.premiumSummary.sectionId;

    this.qs_master_commissionPayable = new QuestionEntity(
      "qs_master_commissionPayable ",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_commissionPayable.displayText = "Commission Payable";
    this.qs_master_commissionPayable.readOnly = true;
    this.qs_master_commissionPayable.required = true;
    this.qs_master_commissionPayable.tabId = this.tabId;
    this.qs_master_commissionPayable.questionPanelId =
      MortgageFireHKSectionSchema.premiumSummary.sectionId;

    this.qs_master_manualReferral = new QuestionEntity(
      "qs_master_manualReferral",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_manualReferral.displayText =
      "Would you like to manually refer to Underwriter?";
    this.qs_master_manualReferral.required = true;
    this.qs_master_manualReferral.tabId = this.tabId;
    this.qs_master_manualReferral.questionPanelId =
      MortgageFireHKSectionSchema.master.sectionId;
    this.qs_master_manualReferral.defaultAnswer = "2";
    this.qs_master_manualReferral.answer = "2";

    this.qs_master_manualReferralReason = new QuestionEntity(
      "qs_master_manualReferralReason",
      undefined,
      Enums.AnswerDataType.String
    );
    this.qs_master_manualReferralReason.displayText =
      "Refer to underwriter reason";
    this.qs_master_manualReferralReason.required = false;
    this.qs_master_manualReferralReason.tabId = this.tabId;
    this.qs_master_manualReferralReason.isVisible = false;
    this.qs_master_manualReferralReason.questionPanelId =
      MortgageFireHKSectionSchema.master.sectionId;

    this.qs_master_netCommOffsetWithDiscount = new QuestionEntity(
      "qs_master_netCommOffsetWithDiscount",
      undefined,
      Enums.AnswerDataType.Numeric
    );
    this.qs_master_netCommOffsetWithDiscount.displayText =
      "Nett Comm Offset with Discount";
    this.qs_master_netCommOffsetWithDiscount.readOnly = true;
    this.qs_master_netCommOffsetWithDiscount.required = true;
    this.qs_master_netCommOffsetWithDiscount.tabId = this.tabId;
    this.qs_master_netCommOffsetWithDiscount.questionPanelId =
      MortgageFireHKSectionSchema.premiumSummary.sectionId;
  }

  refreshQuestionsArray() {
    this.questions = [];
    this.questions.push(this.qs_master_techRate);
    this.questions.push(this.qs_master_brokerCommission);
    this.questions.push(this.qs_master_brokerCommissionRate);
    this.questions.push(this.qs_master_commission);
    this.questions.push(this.qs_master_manualReferral);
    this.questions.push(this.qs_master_manualReferralReason);
    this.questions.push(this.qs_master_commissionGst);
    this.questions.push(this.qs_master_netCommOffsetWithDiscount);
    this.questions.push(this.qs_master_commissionPayable);
  }
}
