import * as Enums from './../../enum.shared';

import { Exception } from './exception';

/** Common Exception object to be thrown for un-authorized access exception */
export class UnauthorizedException extends Exception {

    constructor() {
        super('');
        this.message = '401 Unauthorized';
    }
}
