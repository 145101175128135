<kendo-dialog
  title="Confirmation"
  class="modal-title-red"
  (close)="close('cancel')"
  [width]="900"
  [minHeight]="340"
  ><div class="modal-body">
    <div>
      <div class="alert alert-warning" *ngIf="warning_message">
        <p style="margin-bottom: 0">Please provide {{ confirmLabel }}</p>
      </div>
    </div>
    <div [textContent]="data.message"></div>
    <div class="form-group" style="margin-top: 20px">
      <label class="required" [textContent]="confirmLabel"></label>
      <textarea
        id="confirmationReason"
        class="form-control"
        [(ngModel)]="confirmationReason"
        style="height: 120px"
      ></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <kendo-dialog-actions>
      <button
        class="btn chubbLightBlue_bg_btn"
        kendoButton
        (click)="confirmation(1)"
        themeColor="primary"
      >
        OK
      </button>
      <button class="btn chubbRed_bg_btn" kendoButton (click)="close('no')">
        Cancel
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
