export const proposalStatus: { [key: number]: string } = {
    1: 'Approved',
    2: 'Bound',
    3: 'Referred',
    6: 'Draft',
    10: 'Endorsed',
    11: 'Renewed',
    12: 'Cancelled',
    18: 'P. Checker',
    4:  'Declined',
    9:  'Lapsed',     
};

export const transactionType: { [key: number]: string } = {
    1: 'NB',
    2: 'EN',
    3: 'CA',
    4: 'RN'
};
