import { Component, Inject, OnInit } from "@angular/core";
import {
  FileInfo,
  RemoveEvent,
  SuccessEvent,
  UploadEvent,
} from "@progress/kendo-angular-upload";
import { DialogRef } from "../dialog/dialog-ref";
import { DIALOG_DATA } from "../dialog/dialog-tokens";
import {
  dlgModelArgs,
  v1DlgInputArgs,
} from "../md-confirmation-message-v1/md-confirmation-message-v1.component";
import * as Enum from "src/app/framework/enum.shared";
import { FileUploadService } from "src/app/services/file-upload.service";
import {
  MessageBoard,
  MessageBoardModel,
} from "src/app/components/product/messageBoard/message-forum/MessageBoardModel";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MessageBoardService } from "src/app/services/message-board.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { common } from "src/app/framework/utils/common";

@Component({
  selector: "app-md-confirmation-message-with-reason-attachment",
  templateUrl:
    "./md-confirmation-message-with-reason-attachment.component.html",
  styleUrls: [
    "./md-confirmation-message-with-reason-attachment.component.scss",
  ],
})
export class MdConfirmationMessageWithReasonAttachmentComponent
  implements OnInit
{
  public data: any;
  model: MessageBoardModel = new MessageBoardModel();

  ownerId: any;
  description = "Message Board File";
  fileType = Enum.fileType.MessageBoard;
  documentIds = [];
  //DBSEP-3226 - INC21420894 - remove SMS and Email option and include no action required as per the business requirement
  options: KeyValue<string>[] = [
    {
      key: "Email",
      value: "2",
    },
    {
      key: "SMS",
      value: "5",
    },
    {
      key: "Mail",
      value: "1",
    },
    {
      key: "No Action Required",
      value: "4",
    },
  ];
  selectedPrintType: any;
  isInvalidEmailAddress = false;

  hasFormError = false;
  formErrors = "";
  showNotify = true;
  validationMessage = "";
  public myFiles: Array<FileInfo> = [];
  confirmLabel!: string;
  warning_message!: boolean;
  confirmationReason!: string;
  validationReasonMessage: string = "Please enter Reason";
  showReasonMessage!: boolean;
  isIBG!: boolean;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any,
    public identityRolesService: IdentityRolesService,
    private messageBoardService: MessageBoardService,
    private cdkDialogService: CdkDialogService,
    private fileUploadService: FileUploadService
  ) {
    if (this.inputData) {
      this.model.messageBoard.push(new MessageBoard());
      this.model.messageBoard[0].notifyUser = true;
      this.model.messageBoard[0].notifyOwnerOnly = true;
      this.model.messageBoard[0].isUnderwriterInternalComment = false;
      this.model.proposalId = this.inputData.proposalId;
      this.model.productId = this.inputData.productId;
      this.ownerId = this.inputData.proposalId;
      this.warning_message = false;
      this.model.messageBoard[0].messageTitle =
        "Reason for Referred to Underwriter:";
      if (this.inputData.tabClicked == 9) {
        this.confirmLabel = "Reason:";
      } else {
        this.confirmLabel = "Reason:";
      }
      this.description = "Message Board File";
      this.fileType = Enum.fileType.MessageBoard;
      this.documentIds = [];
      this.data = {
        proposalId: this.inputData.proposalId,
        tabClicked: this.inputData.tabClicked,
      };
    }
  }

  ngOnInit(): void {
    this.model.printCorrespondence = this.inputData.printCorrespondence;

    this.model.selectedPrintType = this.options.filter((item) => {
      return item.value == this.inputData.preferredDeliveryMethod;
    })[0];

    if (
      this.model.selectedPrintType != null &&
      this.model.selectedPrintType.value === "2"
    ) {
      this.data.printCorrespondence = this.inputData.emailId;
    } else if (
      this.model.selectedPrintType != null &&
      this.model.selectedPrintType.value === "5"
    ) {
      this.data.printCorrespondence = this.inputData.mobileNo;
    } else if (
      this.model.selectedPrintType != null &&
      this.model.selectedPrintType.value === "1"
    ) {
      this.data.printCorrespondence = this.inputData.contactInfo;
    } else if (
      this.model.selectedPrintType != null &&
      this.model.selectedPrintType.value === "4"
    ) {
      this.data.printCorrespondence = "";
    }
    if (this.model.selectedPrintType == null) {
      this.data.selectedPrintType = this.options[1];
    }

    //https://github.com/udos86/ng-dynamic-forms/issues/362
    //overhead for non-primitive
    this.data.selectedPrintType = this.model.selectedPrintType.value;

    if (
      this.inputData.productId == "06CB09D3-6FE0-4695-92A8-A61DFB627C61" ||
      this.inputData.productId == "8533FA05-F190-4A29-9613-3DB7F693DD32"
    ) {
      this.isIBG = true;
      this.data.isPasswordProtected = false;
    } else {
      this.data.isPasswordProtected = true;
    }
  }

  confirmation(flag: any) {
    this.data.confirmation = flag;

    if (this.data.confirmation) {
      if (
        common.isDefined(this.confirmationReason) &&
        this.confirmationReason !== ""
      ) {
        this.data.message = this.confirmationReason;
        this.model.messageBoard[0].messageContent = this.confirmationReason;
        this.submit();
        this.close("yes");
      } else {
        this.warning_message = true;
      }
    } else {
      this.close("no");
    }
  }

  private submit = () => {
    this.model.messageBoard[0].proposalId = this.inputData.proposalId;
    this.model.messageBoard[0].notifyUser = false;

    // let serverModel: Array<serverModel> = this.model.messageBoard[0];
    this.model.messageBoard[0].fileIds = this.documentIds;

    if (!common.isDefined(this.model.messageBoard[0].messageTitle)) {
      this.model.messageBoard[0].messageTitle = "";
    }
    if (!common.isDefined(this.model.messageBoard[0].messageContent)) {
      this.model.messageBoard[0].messageContent = "";
    }

    this.messageBoardService.saveMessage(this.model.messageBoard[0]).subscribe(
      (result) => {
        //success
      },
      (result) => {
        this.hasFormError = true;
        this.formErrors = result.statusText;
      }
    );
  };

  public close(status: any) {
    let savParameters: dlgModelArgs = {
      status: status,
      data: this.data,
    };

    this.dialogRef?.close(savParameters);
  }

  public complete(): void {
    //console.log(`complete event`);
  }

  public error(e: ErrorEvent): void {
    //console.log(`error event: ${e.filename}`);
  }

  public remove(e: RemoveEvent): void {
    //console.log(`remove event: ${e.files[0].name}`);
    e.preventDefault(); //do our own way
    this.myFiles = [];
  }

  public success(e: SuccessEvent): void {
    //console.log(`success event ${e.files[0].name}`);
  }
  public upload(e: UploadEvent): void {
    //console.log(`upload event ${e.files[0].name}`);
    e.preventDefault(); //do our own way

    if (e.files.length > 0) {
      //from old ui: shared\uiControls\inputs\drFileUpload\FileUploadController.js
      const formData: FormData = new FormData();
      formData.append("description", this.description);
      formData.append("ownerid", this.ownerId);
      formData.append("type", this.fileType.toString());
      for (var i = 0; i < e.files.length; i++) {
        formData.append("file" + i, e.files[i].rawFile!);
      }
      this.fileUploadService.save(formData).subscribe((result: any) => {
        if (result && result.body) {
          if (result.body.StatusMessage == "success") {
            this.validationMessage = "Upload successful";
            this.myFiles = [];
            this.documentIds = result.body.docIdList;
          } else {
            this.validationMessage = "Upload failed";
            this.documentIds = [];
          }
        }
      });
    }
  }
}
