import * as Enum from "src/app/framework/enum.shared";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import { MortgageFireSGTabSchema } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/schemas/mf-sg-tab-schema";
import { Component, Input, ViewChild } from "@angular/core";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { componentHelper } from "src/app/models/new-product/utils/component.helper";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import {
  MasterPolicyEntity,
  MasterPolicyService,
} from "src/app/services/master-policy.service";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { MfSgBaseComponent } from "../../mf-sg-base.component";
import { Culture, dateFormatter } from "src/app/shared/date-formatter";
import { Global } from "src/app/shared/global";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { ClientViewComponent } from "src/app/components/shared/partials/mdClient/client-view/client-view.component";
import {
  ClientSearchComponent,
  clientSearchDlgInputArgs,
} from "src/app/components/shared/partials/mdClient/client-search/client-search.component";
import { ClientService } from "src/app/services/customer/client.service";
import { ClientEntity } from "src/app/models/customer/base/client-entity.model";
import {
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { NotifierService } from "src/app/services/notifier.service";
import { cloneDeep } from "lodash";
import { AddressHelpFormComponent } from "src/app/components/shared/products/address-help-form/address-help-form.component";
import * as InterfaceProduct from "src/app/framework/interface.product";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";
import { MdPreferredDeliveryMethodComponent } from "src/app/components/shared/partials/md-preferred-delivery-method/md-preferred-delivery-method.component";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { MdInformationMessageComponent } from "src/app/components/shared/partials/md-information-message/md-information-message.component";
import { ClientCreateComponent } from "src/app/components/shared/partials/mdClient/client-create/client-create.component";
import { clientCreateDlgInputArgs } from "src/app/components/shared/partials/mdClient/client-create/base/base-client-create/client-create-dlg-input-args";
import { FireInterestedParties } from "../FireInterestedParties";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import {
  GridComponent,
  AddEvent,
  GridDataResult,
  RemoveEvent,
  SaveEvent,
  CancelEvent,
  EditEvent,
} from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
  OccupationSearchComponent,
  occupationSearchDlgInputArgs,
} from "src/app/components/shared/partials/md-occupation/occupation-search/occupation-search.component";
import {
  MdConfirmationMessageV5Component,
  v5DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v5/md-confirmation-message-v5.component";
@Component({
  selector: "ct-sg-master",
  templateUrl: "./ct-sg-master.component.html",
  styleUrls: ["./ct-sg-master.component.scss"],
})
export class CtSgMasterComponent extends MfSgBaseComponent {
  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;
  @Input() public proposal!: MortgageFireSingaporeProposal;
  originalInceptionDate: string = "";
  masterPolicyList!: MasterPolicyEntity[];
  masterPolicyOptions!: Array<{ text: string; value: string }>;
  currency1HDBDisabled: boolean = true;
  hideDiscountCode: boolean = true;
  currency1HDBValue!: number;
  productId!: string;
  contactInformation: string = "";
  contactInformationPreferredDelivery: string = "";
  ibgMFDisabled: boolean = true;
  ibgMACDisabled: boolean = true;
  ///Chirag: 28-12-2018 Free Insurance Change For CBG Prodcut
  isFreeInsApplicable: boolean = true;
  NumOfFreeYears: number = 0;
  isEndorsement!: boolean;
  isUnderwriter!: boolean;
  isCancel!: boolean;
  /// DBSEP - 2050 : Automation Of LST
  isAutoLst: boolean = true; /*Need To Set From Auxilary Flag */
  isLstApplicable!: boolean;
  isRenewal!: boolean;
  isIBGProduct!: boolean;
  isCitiProduct!: boolean;
  isNewBusiness!: boolean;
  isGeneralEndorsement!: boolean;
  isChangeOfCorrespondenceAddressType!: boolean;
  isIncomplete!: boolean;
  isRenewalDraft: boolean = false;
  isReferred!: boolean;
  isChangeOfClientInfo!: boolean;
  isSameAsAddressTicked: boolean = false;
  isBuildingSIModified: boolean = false;
  hasMSIGPolicy: boolean = false;
  editMSIGPolicy: boolean = false;
  houseHoldContentsAmount!: MasterPolicyEntity;
  enableOccupiedAs: boolean = true;
  // Equipment Data
  equipmentData!: any[];
  equipmentDataLength!: number;
  currentSelectedEquipmentGridOptions: any;
  wnd: any;
  window: any;
  detailsTemplate: any;
  onFirstRun: boolean = true;
  nationalityList!: any[];

  // Interested party
  claimData!: any[];
  claimDataLength!: number;
  currentSelectedInterestedGridOptions: any;

  // Additional Insured Details
  otherInsuredData!: any[];
  otherInsuredDataLength!: number;
  readOnlyAddressField!: any[];
  currentSelectedInsuredGridOptions: any;
  msg_deleteWarning: string = "Are you sure you wish to delete the item?";
  msg_masterPolicyChangeWarning: string =
    "Please confirm if you wish to change the Master Policy Number." +
    " All the data in the Equipment Listing will be lost. Click OK to proceed, else please click Cancel?";
  countryList!: any[];
  labelText: string = "Additional Insured";
  defaultNOI: string = "";
  editableNOI: boolean = true;
  showFreeInsuranceNoYear: boolean = false;
  interestedPartyGridLength = 30;

  isEditable: boolean = false;
  isNewClient: boolean = true;
  refundDisplayText!: string;
  preferredDeliveryText!: string;
  isMasterStateVisible: boolean = false;
  countryExcludeState: any = ["SG", "HK"];
  category!: string;
  masterPolicyNumber: string = "";
  suppressNotification: boolean = false;
  isChecker: boolean = false;
  isMaker: boolean = false;
  displayManualUnderwriting: boolean = false;
  msg_manualUnderwritting_warning: string =
    "Are you sure you wish to perform manual underwritting? " +
    "Once checked, there is no option to uncheck. Click OK to proceed, else please click Cancel.";
  firstOnLoad: boolean = true;
  selectCoverageType!: string;
  isCustomDiscount: boolean = false;
  clientDictionary!: ClientEntity;
  lstDetailsTemplate: any = [
    {
      rowID: 0,
      account: "8TPQZA",
      reference: "XOL",
      percent1: 0,
      amount1: 0,
      percent2: 0,
      amount2: 0,
    },
    {
      rowID: 1,
      account: "8TPQUJ",
      reference: "80% of LST",
      percent1: 0,
      amount1: 0,
      percent2: 0,
      amount2: 0,
    },
    {
      rowID: 2,
      account: "8TPQUK",
      reference: "15% of LST",
      percent1: 0,
      amount1: 0,
      percent2: 0,
      amount2: 0,
    },
    {
      rowID: 3,
      account: "8TPPPV",
      reference: "5% of LST",
      percent1: 0,
      amount1: 0,
      percent2: 0,
      amount2: 0,
    },
  ];
  lstDefaultValue: any;
  @ViewChild("qs_risk_sameAsAddress_checkbox", { static: false })
  public qs_risk_sameAsAddress_checkbox: any;

  @ViewChild("qs_master_manualUnderwritting", { static: false })
  public qs_master_manualUnderwritting_checkbox: any;

  @ViewChild("riskInformation_autocomplete")
  riskInformation_autocomplete!: GooglePlaceDirective;

  @ViewChild("policyInformation_autocomplete", { static: false })
  public policyInformation_autocomplete!: GooglePlaceDirective;

  @ViewChild("qs_master_waiveMinimumPremium_checkbox", { static: false })
  public qs_master_waiveMinimumPremium_checkbox: any;

  //https://github.com/skynet2/ngx-google-places-autocomplete/issues/91
  options = {
    types: ["address"],
    componentRestrictions: { country: ["sg"] },
  } as unknown as Options;
  public sort: SortDescriptor[] = [
    {
      field: "PartyID",
      dir: "desc",
    },
  ];
  hideInterestedPartiesActionCol: boolean = false;
  addButtonVisiiblity: boolean = false;
  labelTextInterestedParty: string = "Add Additional Insured";
  private editedRowIndex!: number | undefined;
  private editedProduct!: FireInterestedParties | undefined;

  public gridFireInterestedParties!: GridDataResult;
  public objFireInterestedParties!: FireInterestedParties[];

  qs_master_effectiveDate_datePicker_minDate?: Date;
  qs_master_effectiveDate_datePicker_maxDate?: Date;

  qs_master_poi_startDate_datePicker_minDate?: Date;
  qs_master_poi_startDate_datePicker_maxDate?: Date;
  constructor(
    public identityRolesService: IdentityRolesService, // private dialogActionNotifierService: DialogActionNotifierService,
    // private $scope: any, // private Enums: any, // private dialog: Dialog.IDialog,
    public proposalService: MfSgProposalService, // private $q: ng.IQService, // private api: Api,
    private masterPolicyService: MasterPolicyService,
    private masterDataService: MasterDataService, // private clientService: ClientService, // private _identity: Identity, private $timeout: ng.ITimeoutService
    private cdkDialogService: CdkDialogService,
    private clientService: ClientService,
    private notifier: NotifierService
  ) {
    super();

    this.isChecker = identityRolesService.isChecker ? true : false;
    this.isMaker = identityRolesService.isMaker ? true : false;
    this.tabId = MortgageFireSGTabSchema.master.index.toString();
    // // this.googleSearchAutoCompletePolicyInformation =
    // //     new GoogleAddressAutoCompleteManager('policyInformation_autocomplete', 'sg', this.updateAddressValues);
    // this.googleSearchAutoCompleteRiskInformation =
    //     new GoogleAddressAutoCompleteManager('riskInformation_autocomplete', 'sg', this.updateAddressValues);

    super.subscribeAnswerChangedNotifier(this.answerChanged);
    //reset
    this.gridFireInterestedParties = {
      data: [],
      total: 0,
    };
    // const scope = $scope;
    // $scope.$on('$destroy', () => {

    //     $('#masterTab').empty();
    //     scope.$destroy();
    // });
  }
  ngOnInit(): void {
    this.initialize();
    componentHelper.notifyPageLoaded("ct-sg-master.component", this.proposal);

    this.masterDataService.getCountryList().subscribe((response: any[]) => {
      this.countryList = response;
    });
    // fetchMasterPolicyList is being call to for the equipment type listing usage
    this.fetchMasterPolicyList();

    super.subscribeProposalLoadedNotifier(this.proposalSaved);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
    this.firstOnLoad = false;
  }
  ngOnDestroy() {
    this.unsubscribeRxjs();
  }
  private initialize() {
    this.nationalityList = new Array<any>();
    this.proposal = this.proposalService.proposal;

    this.defaultNOI = "Additional Insured";
    this.editableNOI = false;

    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;
    if (
      common.isDefined(this.proposal.originalInceptionDate) &&
      this.proposal.originalInceptionDate !== null
    ) {
      this.originalInceptionDate = dateFormatter.format(
        this.proposal.originalInceptionDate,
        Culture.enSg
      );
    } else {
      this.originalInceptionDate = dateFormatter.format(
        this.proposal?.qsMaster?.qs_master_poi_startDate?.answer,
        Culture.enSg
      );
      this.proposal.originalInceptionDate =
        this.proposal?.qsMaster?.qs_master_poi_startDate?.answer;
    }
    if (
      this.proposal.qsMaster?.qs_master_manualUnderwritting?.answer === "True"
    ) {
      //  angular.element('#qs_master_manualUnderwritting_checkbox').prop('checked', true);
      this.manualUnderwritting();
    }
    super.subscribeTabLoadRenderer();

    this.fireRiskDetailsOccupiedAsValue();
    this.qs_fire_replacement_questionUpdate();

    this.getDisplayManualUnderwriting();

    //disable fire replacement value
    if (
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
    ) {
      if (this.proposal.qsMaster.qs_fire_replacement) {
        this.proposal.qsMaster.qs_fire_replacement.required = false;
        this.proposal.qsMaster.qs_fire_replacement.isDisabled = true;
      }
    }
  }
  manualUnderwritting = (): void => {
    switch (this.proposal.productType) {
      case EnumSG.MortgageFireSgProduct.CTMFPTE:
      case EnumSG.MortgageFireSgProduct.CTMFCONDO:
        if (this.proposal.qsMaster.qs_fire_replacement)
          this.proposal.qsMaster.qs_fire_replacement.isDisabled = false;
        break;
    }
    this.currency1HDBDisabled = true;
    this.hideDiscountCode = true;
    if (this.proposal.qsMaster.qs_master_discountCode)
      this.proposal.qsMaster.qs_master_discountCode!.answer = "0";
    if (this.proposal.qsMaster.qs_master_customDiscountCode)
      this.proposal.qsMaster.qs_master_customDiscountCode!.answer = "0";
  };

  fireRiskDetailsOccupiedAsValue = (): void => {
    switch (this.proposal.productType) {
      case EnumSG.MortgageFireSgProduct.CTHDB:
        this.currency1HDBDisabled = false;
        this.hideDiscountCode = false;
        break;
      case EnumSG.MortgageFireSgProduct.CTMFPTE:
        this.currency1HDBDisabled = true;
        this.hideDiscountCode = false;
        break;
      case EnumSG.MortgageFireSgProduct.CTMFCONDO:
        this.currency1HDBDisabled = true;
        this.hideDiscountCode = false;
        if (this.proposal.qsMaster.qs_fire_mcst)
          this.proposal.qsMaster.qs_fire_mcst.isVisible = true;
        break;
      default:
        break;
    }
  };
  qs_fire_replacement_questionUpdate = (): void => {
    switch (this.proposal.productType) {
      case EnumSG.MortgageFireSgProduct.CTHDB:
        this.proposal.qsMaster.qs_fire_replacement!.isVisible = false;
        this.proposal.qsMaster.qs_fire_replacement!.required = false;
        this.proposal.qsMaster.qs_fire_outstandingLoanValue!.isVisible = false;
        this.proposal.qsMaster.qs_fire_outstandingLoanValue!.required = false;
        break;
      default:
        break;
    }
  };
  toShowQuestion(question: any) {
    let flag: boolean = false;
    if (this.proposal.proposalStatus != Enum.ProposalStatus.Incomplete) {
      switch (question) {
        case "qs_master_preferredDeliveryMethod_Email":
          flag =
            this.proposal?.qsMaster.qs_master_preferredDeliveryMethod_Email
              ?.answer != undefined
              ? true
              : false;
          if (this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email)
            flag =
              this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email.readOnly =
                true;
          break;
        case "qs_master_preferredDeliveryMethod_MobileNo":
          flag =
            this.proposal?.qsMaster.qs_master_preferredDeliveryMethod_MobileNo
              ?.answer != undefined
              ? true
              : false;
          if (
            this.proposal?.qsMaster.qs_master_preferredDeliveryMethod_MobileNo
              ?.readOnly
          )
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo.readOnly =
              true;
          break;
        case "qs_master_preferredDeliveryMethod":
          flag =
            this.proposal?.qsMaster.qs_master_preferredDeliveryMethod?.answer !=
              undefined &&
            !this.proposal.qsMaster.qs_master_preferredDeliveryMethod.readOnly
              ? false
              : true;
          break;
        case "qs_master_preferredDeliveryMethod_CorrAddress":
          flag =
            this.proposal?.qsMaster
              .qs_master_preferredDeliveryMethod_CorrAddress?.answer !=
            undefined
              ? false
              : true;
          break;
      }
    }
    if (this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete) {
      switch (question) {
        case "qs_master_preferredDeliveryMethod_Email":
          flag = true;
          if (this.isGeneralEndorsement)
            if (this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email)
              this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email.readOnly =
                false;
          break;
        case "qs_master_preferredDeliveryMethod_MobileNo":
          flag = true;
          if (this.isGeneralEndorsement)
            if (
              this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo
            )
              this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo.readOnly =
                false;
          break;
        case "qs_master_preferredDeliveryMethod":
          if (
            this.isCancel ||
            (this.isEndorsement &&
              this.proposal.endorsementType ===
                Enum.EndorsementType.LoanRedemption)
          ) {
            flag = true;
          }
      }
    }
    return flag;
  }
  private getDisplayManualUnderwriting = () => {
    if (
      this.identityRolesService.isMaker ||
      this.identityRolesService.isUnderwriter
    ) {
      this.displayManualUnderwriting = true;
    } else {
      this.displayManualUnderwriting = false;
    }
  };
  fetchMasterPolicyList = () => {
    this.masterPolicyService
      .getList(this.identityRolesService.getIdentity().currentUser())
      .subscribe((response) => {
        // update list
        this.masterPolicyList = response;

        this.masterPolicyOptions = new Array<{ text: string; value: string }>();

        this.masterPolicyList.forEach((mp) => {
          const displayText =
            mp.policyType +
            " - " +
            mp.description +
            " - " +
            mp.meridianMasterPolicyNumber +
            " (MSIG: " +
            mp.policyNumber +
            ")";
          this.masterPolicyOptions.push({ text: displayText, value: mp.id });
        });
      });
  };
  compareQuestionAnswer(ques: QuestionEntity) {
    return (
      ques!.answer == 1 || ques!.answer === "True" || ques!.answer === true
    );
  }
  updateModelAnswer(ques: QuestionEntity) {
    if (ques!.answer === "True") {
      ques.answer = false;
    } else if (ques!.answer === "False") {
      ques.answer = true;
    } else {
      ques.answer = !ques.answer;
    }
  }
  private answerChanged = (kv: KeyValue<QuestionEntity>) => {
    const key = kv!.key;
    const question = kv.value;

    switch (key) {
      //// 12/10/2018 : Chirag Added Code For FreeInsurance Start/ End Date
      case this.proposal!.qsMaster.qs_fire_freeInsuranceNoYear!.key:
        if (this.isIBGProduct) this.setFreeInsuranceDate();
        else if (!this.isIBGProduct && this.proposal.proposalStatus === 6)
          this.setFreeInsuranceDateForCBG();
        break;
      /// 12/10/2018 : Chirag Added Method For FreeInsurance Start/End Date
      case this.proposal!.qsMaster.qs_master_poi_startDate!.key:
        this.qs_master_poi_startDate_onChange();
        this.setFreeInsurance_OnPOIDate_Endorsement();
        break;
      case this.proposal!.qsMaster.qs_fire_occupied!.key:
        this.qs_fire_occupied_onChange(
          this.proposal!.qsMaster.qs_fire_occupied!.answer
        );
        break;
      case this.proposal!.qsMaster.qs_fire_replacement!.key:
        this.qs_fire_FSVSumInsured(
          this.proposal!.qsMaster.qs_fire_replacement!.answer
        );
        break;
      case this.proposal!.qsMaster.qs_master_insuredAddress_building!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredAddress_building!,
          this.proposal.qsMaster.qs_risk_insuredAddress_building!
        );
        break;
      case this.proposal!.qsMaster.qs_master_insuredAddress_lotunit!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredAddress_lotunit!,
          this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!
        );
        break;
      case this.proposal!.qsMaster.qs_master_insuredAddress1!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredAddress1!,
          this.proposal.qsMaster.qs_risk_insuredAddress1!
        );
        break;
      case this.proposal!.qsMaster.qs_master_insuredAddress2!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredAddress2!,
          this.proposal.qsMaster.qs_risk_insuredAddress2!
        );
        break;
      case this.proposal!.qsMaster.qs_master_insuredPostalCode!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredPostalCode!,
          this.proposal.qsMaster.qs_risk_insuredPostalCode!
        );
        break;
      case this.proposal!.qsMaster.qs_master_insuredCity!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredCity!,
          this.proposal.qsMaster.qs_risk_insuredCity!
        );
        break;
      case this.proposal!.qsMaster.qs_master_insuredState!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredState!,
          this.proposal.qsMaster.qs_risk_insuredState!
        );
        break;
      case this.proposal!.qsMaster.qs_master_masterPolicyNumber!.key:
        break;
      case this.proposal!.qsMaster.qs_master_occupancyRate!.key:
      case this.proposal!.qsMaster.qs_machinery_equipment!.key:
        // reset the aux if underwriter changes it
        // this.resetAuxiliaryModel();
        break;
      case this.proposal!.qsMaster.qs_risk_sameAsAddress!.key:
        this.qs_risk_sameAsAddress_onChange(
          this.proposal!.qsMaster.qs_risk_sameAsAddress!.answer
        );
        break;
      case this.proposal.qsMaster.qs_fire_building!.key:
        this.qs_master_qs_fire_building(
          this.proposal!.qsMaster.qs_fire_building!.answer
        );
        break;
      case this.proposal!.qsMaster.qs_master_effectiveDate!.key:
        this.qs_master_effectiveDates_onChange(
          this.proposal!.qsMaster.qs_master_effectiveDate!.answer
        );
        break;

      case this.proposal!.qsMaster.qs_fire_freeInsuranceFirstYear!.key:
        this.qs_fire_freeInsuranceFirstYear_onChange(
          this.proposal!.qsMaster.qs_fire_freeInsuranceFirstYear!.answer
        );
        break;
      case this.proposal!.qsMaster.qs_master_lst!.key:
        this.qs_master_lst_onChange(
          this.proposal!.qsMaster.qs_master_lst!.answer
        );
        break;
      case this.proposal!.qsMaster.qs_master_preferredDeliveryMethod!.key:
        this.qs_master_preferredDeliveryMethodChange(
          this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer
        );
        break;
      case this.proposal!.qsMaster.qs_master_planType!.key:
        this.qs_master_planType_onChange(
          this.proposal.qsMaster.qs_master_planType!.answer
        );
        break;
      case this.proposal.qsMaster.qs_master_premiumPayment!.key:
        this.qs_master_premiumPayment_OnChange(
          this.proposal.qsMaster.qs_master_premiumPayment!.answer
        );
        break;
      case this.proposal.qsMaster.qs_master_customerType!.key:
        this.qs_master_customerType_OnChange(
          this.proposal.qsMaster.qs_master_customerType!.answer
        );
        break;
      case this.proposal.qsMaster.qs_master_discountCode!.key:
        this.qs_master_discountCode_OnChange(
          this.proposal.qsMaster.qs_master_discountCode!.answer
        );
        break;
      case this.proposal.qsMaster.qs_fire_outstandingLoanValue!.key:
        this.qs_fire_outstandingLoanValue_OnChange(
          this.proposal.qsMaster.qs_fire_outstandingLoanValue!.answer
        );
        break;
      case this.proposal.qsMaster.qs_master_customDiscountCode!.key:
        this.qs_master_customDiscountCode_OnChange(
          this.proposal.qsMaster.qs_master_customDiscountCode!.answer
        );
        break;
      default:
        break;
    }
  };

  private getMasterPolicyDetails = (): MasterPolicyEntity => {
    const masterPolicyNumber =
      this.proposal!.qsMaster.qs_master_masterPolicyNumber!.readAnswerAsString();
    let found: boolean = false;
    let masterPolicy = <MasterPolicyEntity>{};
    this.masterPolicyList.every((mp) => {
      if (mp.id === masterPolicyNumber) {
        found = true;
        masterPolicy = mp;
        return false;
      }
      return true;
    });
    return masterPolicy;
  };

  private setExpiryDateForCiti = () => {
    let annualExpiryDate = cloneDeep(
      this.proposal!.qsMaster.qs_master_poi_startDate!.answer
    );
    const planType = this.proposal!.qsMaster.qs_master_planType!.answer - 1;
    if (planType >= 0) {
      if (annualExpiryDate.getMonth() >= 9) {
        annualExpiryDate = new Date(
          annualExpiryDate.getFullYear() + (planType + 1),
          11,
          31
        );
      } else {
        annualExpiryDate = new Date(
          annualExpiryDate.getFullYear() + planType,
          11,
          31
        );
      }
    } else {
      annualExpiryDate = new Date(
        annualExpiryDate.getFullYear() + 1,
        annualExpiryDate.getMonth(),
        annualExpiryDate.getDate() - 1
      );
    }
    return annualExpiryDate;
  };
  updateNativeElement(ele: any, value: boolean) {
    if (ele !== undefined) {
      ele.nativeElement.checked = value;
    }
  }
  private proposalSaved = (event: KeyValue<Proposal>) => {
    if (!this.isIBGProduct) {
      this.getContactInformation();
    }
    if (
      this.isIBGProduct &&
      this.proposal!.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!
        .answer == null
    ) {
      this.updateContactInformation();
    }
    let checkCorrespondingAddressCbg =
      this.proposal!.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.isAnswered() &&
      this.proposal!.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer.includes(
        "|"
      );

    if (checkCorrespondingAddressCbg) {
      this.correspondanceDeliveryAddress(null);
    }

    this.getDisplayManualUnderwriting();
    this.proposal = event.value as MortgageFireSingaporeProposal;
    // validate business rules after proposal is saved successfully
    this.proposalService.businessRulesManager.validate();
    this.qs_risk_sameAsAddress_onChange(
      this.proposal!.qsMaster.qs_risk_sameAsAddress!.answer
    );

    if (
      common.isDefined(this.proposal.originalInceptionDate) &&
      this.proposal.originalInceptionDate !== null
    ) {
      this.originalInceptionDate = dateFormatter.format(
        new Date(this.proposal.originalInceptionDate),
        Culture.enSg
      );
    } else {
      this.originalInceptionDate = dateFormatter.format(
        new Date(this.proposal!.qsMaster.qs_master_poi_startDate!.answer),
        Culture.enSg
      );
      this.proposal.originalInceptionDate =
        this.proposal!.qsMaster.qs_master_poi_startDate!.answer;
    }

    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;
    this.isReferred =
      this.proposal.proposalStatus === Enum.ProposalStatus.Referred
        ? true
        : false;
    this.isNewBusiness = this.proposal.transType === Enum.TransType.NEW;
    this.isEndorsement = this.proposal.transType === Enum.TransType.END;
    this.isRenewal = this.proposal.transType === Enum.TransType.REN;
    this.isUnderwriter = this.proposal.isUnderwriter;
    this.isCancel = this.proposal.transType === Enum.TransType.CAN;
    this.isIBGProduct =
      this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMF ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMAC
        ? true
        : false;

    this.isCitiProduct =
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
        ? true
        : false;
    this.isGeneralEndorsement =
      this.proposal.transType === Enum.TransType.END &&
      this.proposal.endorsementType === Enum.EndorsementType.GeneralEndorsement;
    // console.log('ee' + this.proposal.qsMaster.qs_master_manualUnderwritting!.answer)
    // New question when copy should refer to default value
    const refreshQuestion = this.proposal.refreshedProposalEntity.questions;
    // tslint:disable-next-line:no-unused-expression

    // Raja - Disable Inception Date picker in Renewal screen
    if (this.isRenewal) {
      this.proposal!.qsMaster.qs_master_poi_startDate!.readOnly = true;
    }

    this.isRenewalDraft =
      (this.isIncomplete && this.isRenewal) || this.isReferred;

    // Allow To Edit MSIG policy for IBG prodcut
    const replacingPolicyNo =
      this.proposal!.qsMaster.qs_master_replacePolicyNo!.readAnswerAsString();
    this.hasMSIGPolicy =
      common.isDefined(replacingPolicyNo) && replacingPolicyNo!.trim() !== "";
    this.editMSIGPolicy =
      this.hasMSIGPolicy &&
      this.isIncomplete &&
      this.proposal.endorsementType === Enum.EndorsementType.GeneralEndorsement;
    if (this.hasMSIGPolicy) {
      this.proposal!.qsMaster.qs_master_replacePolicyNo!.displayText =
        "Original Policy Number";
    } else {
      this.proposal!.qsMaster.qs_master_replacePolicyNo!.displayText =
        "Replacing Policy No";
    }

    if (
      this.proposal!.qsMaster.qs_master_manualUnderwritting!.answer ===
        "True" ||
      this.proposal!.qsMaster.qs_master_manualUnderwritting!.answer === true
    ) {
      this.updateNativeElement(
        this.qs_master_manualUnderwritting_checkbox,
        true
      );
      this.proposal!.qsMaster.qs_master_manualUnderwritting!.isDisabled = true;
      this.proposal!.qsMaster.qs_master_manualUnderwritting!.answer = true;
      this.manualUnderwritting();
    } else {
      this.updateNativeElement(
        this.qs_master_manualUnderwritting_checkbox,
        false
      );
      this.proposal!.qsMaster.qs_master_manualUnderwritting!.isDisabled = false;
      switch (this.proposal.productType) {
        case EnumSG.MortgageFireSgProduct.CTHDB:
          this.currency1HDBDisabled = false;
          this.hideDiscountCode = false;
          break;
        case EnumSG.MortgageFireSgProduct.CTMFPTE:
        case EnumSG.MortgageFireSgProduct.CTMFCONDO:
          this.currency1HDBDisabled = true;
          this.hideDiscountCode = false;
          break;
      }
    }
    switch (this.proposal.productType) {
      case EnumSG.MortgageFireSgProduct.CTHDB:
      case EnumSG.MortgageFireSgProduct.CTMFPTE:
      case EnumSG.MortgageFireSgProduct.CTMFCONDO:
        this.isChangeOfCorrespondenceAddressType =
          this.proposal.endorsementType ===
            Enum.EndorsementType.ChangeOfCorrespondenceAddress &&
          (this.isIncomplete ||
            (this.isReferred &&
              this.proposalService.proposal.isUnderwriter &&
              !this.identityRolesService.isOperationUnderwriter &&
              !this.identityRolesService.isClaimant &&
              !this.identityRolesService.isProductReadOnly));

        this.isChangeOfClientInfo =
          this.proposal.endorsementType ===
            Enum.EndorsementType.ChangeOfClientInfo &&
          (this.isIncomplete ||
            (this.isReferred &&
              this.proposalService.proposal.isUnderwriter &&
              !this.identityRolesService.isOperationUnderwriter &&
              !this.identityRolesService.isClaimant &&
              !this.identityRolesService.isProductReadOnly) ||
            this.identityRolesService.IsCSCBGSG);

        break;
      default:
      // do nothing
    }

    if (this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer > 0) {
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer =
        this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer;
    } else {
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer = 0;
    }

    if (
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete &&
      this.proposal.transType === Enum.TransType.NEW &&
      this.proposal!.customerMappingId! > 0 &&
      (this.identityRolesService.isMaker ||
        this.identityRolesService.isUnderwriter)
    ) {
      this.isEditable = true;
    } else {
      this.isEditable = false;
    }
    if (this.isEndorsement || this.isCancel) {
      // set display text for refund
      this.qs_master_refundDisplayText(
        this.proposal.qsMaster.qs_master_refund!
      );
    }
    if (
      (this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer ==
        null ||
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer ==
          "") &&
      this.proposal.proposalStatus == Enum.ProposalStatus.Incomplete
    )
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer = "2";
    this.qs_master_preferredDeliveryText(
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!
    );

    this.assignDefaultValueForHouseholdContentsCiti();

    //Assign defaulted value for Plan Type
    if (this.proposal.qsMaster.qs_master_planType!.answer == null) {
      this.proposal.qsMaster.qs_master_planType!.answer =
        this.proposal.qsMaster.qs_master_planType!.defaultAnswer;
    }

    //Assign defaulted value for customer Type
    if (this.proposal.qsMaster.qs_master_customerType!.answer == null) {
      this.proposal.qsMaster.qs_master_customerType!.answer =
        this.proposal.qsMaster.qs_master_customerType!.defaultAnswer;
    }

    if (this.proposal.qsMaster.qs_master_premiumPayment!.answer == null) {
      //Assign Defaulted Value for Payment Mode
      this.proposal.qsMaster.qs_master_premiumPayment!.answer =
        this.proposal.qsMaster.qs_master_premiumPayment!.defaultAnswer;
    }

    //Assign first item as selection if the list is only have 1 item
    if (
      Object!.keys(this.proposal.qsMaster.qs_master_masterPolicyNumber!.options)
        .length === 1 &&
      !this.proposal.qsMaster.qs_master_masterPolicyNumber!.isAnswered()
    ) {
      this.proposal.qsMaster.qs_master_masterPolicyNumber!.answer =
        this.proposal.qsMaster.qs_master_masterPolicyNumber!.options[0].value.toString();
    }

    //For Citi Private/Condo only
    if (
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
    ) {
      this.proposal.qsMaster.qs_fire_replacement!.displayText = "";
      this.proposal.qsMaster.qs_fire_coverageValueType!.required = true;
      if (this.isEndorsement && this.isIncomplete) {
        // need to be editable in sum-insured, but not in edit
        this.proposal.qsMaster.qs_fire_coverageValueType!.readOnly = false;
        this.proposal.qsMaster.qs_fire_replacement!.required = false; //auto-calculate/populate by cell in the table
      }

      this.proposal.qsMaster.qs_fire_building!.answer =
        this.proposal.qsMaster.qs_fire_building!.answer;
      this.proposal.qsMaster.qs_fire_replacement!.answer =
        this.proposal.qsMaster.qs_fire_replacement!.answer;

      if (
        this.proposal.qsMaster.qs_master_manualUnderwritting!.answer ===
          "True" ||
        this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === true
      ) {
        this.proposal.qsMaster.qs_fire_replacement!.isDisabled = false;
      } else {
        this.proposal.qsMaster.qs_fire_replacement!.isDisabled = true;
      }
    }

    //Assign the value to selectCoverageType so it can be use to do comparison during onChange
    this.selectCoverageType =
      this.proposal.qsMaster.qs_fire_coverageValueType!.answer;

    const component = this;
    //todo
    // const timeout = Application.angular_$timeout();
    setTimeout(() => {
      //         // initializing the google auto completer search
      //         this.googleSearchAutoCompleteRiskInformation.InitializeAddressAutoCompleteElement();
      // Other Insured
      component.otherInsuredData =
        component.proposal.qsMaster.qs_master_additionalInsured!.answer === ""
          ? []
          : component.processTableDataOtherInsured(
              component.proposal.qsMaster.qs_master_additionalInsured!.answer
            );
      component.otherInsuredDataLength = component.otherInsuredData.length;
      // Interested Parties
      component.claimData =
        component.proposal.qsMaster.qs_fire_interestedParties!.answer === ""
          ? []
          : component.processTableDataInteresedParty(
              component.proposal.qsMaster.qs_fire_interestedParties!.answer
            );
      component.claimDataLength = component.claimData.length;
      // Equipment
      component.equipmentData =
        component.proposal.qsMaster.qs_machinery_equipment!.answer === ""
          ? []
          : component.processTableDataEquipment(
              component.proposal.qsMaster.qs_machinery_equipment!.answer
            );
      component.equipmentDataLength = component.equipmentData.length;

      //   const dataSource = new kendo.data.DataSource({
      //     transport: {
      //       read: component.claimsData_OnRead,
      //       update: component.claimData_OnUpdate,
      //       destroy: component.claimData_OnDestroy,
      //       create: component.claimsData_OnCreate,
      //       // cache: false,
      //       parameterMap: (options, operation) => {
      //         if (operation !== "read" && options.models) {
      //           return { models: kendo.stringify(options.models) };
      //         }
      //       },
      //     },
      //     batch: true,
      //     pageSize: 5,
      //     schema: {
      //       model: {
      //         id: "PartyID",
      //         fields: {
      //           PartyID: { editable: false },
      //           Name: { validation: { required: true } },
      //           NatureOfInterest: {
      //             type: "text",
      //             editable: component.editableNOI,
      //             defaultValue: component.defaultNOI,
      //             validation: {
      //               required: true,
      //               NatureOfInterestCheckLength: function (input) {
      //                 if (
      //                   input.val() !== "" &&
      //                   input.attr("data-maxlength") > 10
      //                 ) {
      //                   input.attr(
      //                     "data-NatureOfInterestCheckLength-msg",
      //                     "Max Length Reached"
      //                   );
      //                   return false;
      //                 }
      //                 return true;
      //               },
      //             },
      //           },
      //         },
      //       },
      //     },
      //   });

      //   const dataSourceEquipment = new kendo.data.DataSource({
      //     transport: {
      //       read: component.equipmentData_OnRead,
      //       update: component.equipmentData_OnUpdate,
      //       destroy: component.equipmentData_OnDestroy,
      //       create: component.equipmentData_OnCreate,

      //       parameterMap: (options, operation) => {
      //         if (operation !== "read" && options.models) {
      //           return { models: kendo.stringify(options.models) };
      //         }
      //       },
      //     },
      //     batch: true,
      //     pageSize: 5,
      //     schema: {
      //       model: {
      //         id: "rowID",
      //         fields: {
      //           rowID: { editable: false },
      //           equipmentType: {
      //             validation: { required: true },
      //             defaultValue: {
      //               key: "",
      //               value: "",
      //             },
      //           },
      //           typeOfFinancing: {
      //             type: "text",
      //           },
      //           equipmentDescription: {
      //             type: "text",
      //             validation: { required: true },
      //           },

      //           sumInsured: {
      //             type: "number",
      //             defaultValue: 0,
      //             validation: {
      //               required: true,
      //               sumInsuredCheckLength: function (input) {
      //                 if (input.attr("data-bind") === "value:sumInsured") {
      //                   input.attr(
      //                     "data-sumInsuredCheckLength-msg",
      //                     "Sum Insured is required"
      //                   );
      //                   return input.val() !== "";
      //                 }
      //                 return true;
      //               },
      //             },
      //           },
      //           additionalInfo: {
      //             defaultValue: "",
      //             type: "text",
      //             validation: {
      //               required: true,
      //               textAreaLengthcheck: function (input) {
      //                 if (input.attr("data-bind") === "value:additionalInfo") {
      //                   input.attr(
      //                     "data-textAreaLengthcheck-msg",
      //                     "Addtional Info is required"
      //                   );
      //                   return input.val() !== "";
      //                 }
      //                 return true;
      //               },
      //             },
      //           },
      //         },
      //       },
      //     },
      //   });

      //   const dataSourceOtherInsured = new kendo.data.DataSource({
      //     transport: {
      //       read: component.otherInsuredData_OnRead,
      //       update: component.otherInsuredData_OnUpdate,
      //       destroy: component.otherInsuredData_OnDestroy,
      //       create: component.otherInsuredData_OnCreate,

      //       parameterMap: (options, operation) => {
      //         if (operation !== "read" && options.models) {
      //           return { models: kendo.stringify(options.models) };
      //         }
      //       },
      //     },
      //     batch: true,
      //     pageSize: 5,
      //     schema: {
      //       model: {
      //         id: "otherInsuredID",
      //         fields: {
      //           otherInsuredID: { editable: false },
      //           otherInsuredName: { validation: { required: true } },
      //           cin: { validation: { required: true } },
      //           otherBRNo: { validation: { required: true } },
      //         },
      //       },
      //     },
      //   });

      //   // fixed the typescript declaration bugs
      //   const qs_master_interestedParty_table_grid: any = angular.element(
      //     "#qs_ct_additional_insured_table_grid"
      //   );
      //   const showAction: boolean = component.proposal.qsMaster
      //     .qs_fire_interestedParties.readOnly
      //     ? true
      //     : false;

      //   const hideInterestedPartiesActionCol: boolean =
      //     component.proposal.qsMaster.qs_fire_interestedParties.readOnly;
      //   let labelTextInterestedParty = "Add Additional Insured";
      //   // Destroy the kendo Grid if defined and recreate everytime
      //   if (
      //     common.isDefined(qs_master_interestedParty_table_grid.data("kendoGrid"))
      //   ) {
      //     const interestedPartyGrid =
      //       qs_master_interestedParty_table_grid.data("kendoGrid");
      //     interestedPartyGrid.destroy();
      //     angular.element("#qs_ct_additional_insured_table_grid").empty();
      //   }

      //   qs_master_interestedParty_table_grid.kendoGrid({
      //     dataSource: dataSource,
      //     pageable: true,
      //     height: 280,
      //     toolbar: [
      //       {
      //         text: labelTextInterestedParty,
      //         name: "create",
      //       },
      //     ],
      //     dataBound: () => {
      //       const grid1 = angular
      //         .element("#qs_ct_additional_insured_table_grid")
      //         .data("kendoGrid");
      //       if (
      //         common.isDefined(grid1) &&
      //         !this.proposal.qsMaster.qs_fire_interestedParties.readOnly
      //       ) {
      //         if (
      //           grid1.dataSource.data().length >= this.interestedPartyGridLength
      //         ) {
      //           angular
      //             .element("#qs_ct_additional_insured_table_grid .k-grid-add")
      //             .hide();
      //         } else {
      //           angular
      //             .element("#qs_ct_additional_insured_table_grid .k-grid-add")
      //             .show();
      //         }
      //       } else {
      //         angular
      //           .element("#qs_ct_additional_insured_table_grid .k-grid-add")
      //           .hide();
      //       }
      //       angular
      //         .element(
      //           "#qs_ct_additional_insured_table_grid .k-grid-myDelete span"
      //         )
      //         .addClass("k-icon k-delete");
      //     },
      //     columns: [
      //       {
      //         field: "Name",
      //         title: "Name",
      //         width: "350px",
      //       },
      //       {
      //         field: "NatureOfInterest",
      //         title: "Nature of Interest",
      //         width: "250px",
      //       },
      //       {
      //         field: "NRIC",
      //         title: "NRIC",
      //         width: "250px",
      //       },
      //       {
      //         hidden: hideInterestedPartiesActionCol,
      //         field: "command",
      //         command: [
      //           "edit",
      //           {
      //             name: "myDelete",
      //             text: "Delete",
      //             click: function (e) {
      //               e.preventDefault();
      //               // assign the selected row
      //               component.currentSelectedInterestedGridOptions = $(
      //                 e.currentTarget
      //               ).closest("tr");

      //               const data = {
      //                 message: component.msg_deleteWarning,
      //                 tableName: "qs_ct_additional_insured_table_grid",
      //               };

      //               component._dialog = Dialog.DialogModal.Instance.modalMedium(
      //                 "shared/partials/mdConfirmationMessageV1/sharedConfirmationMessageV1.html",
      //                 "SharedConfirmationMessageV1Controller",
      //                 data,
      //                 component.confirmationModalCloseForDeleteAction,
      //                 undefined,
      //                 undefined,
      //                 undefined,
      //                 false,
      //                 "standardModal"
      //               );
      //             },
      //           },
      //         ],
      //         title: "Action",
      //         width: "200px",
      //         attributes: { class: "textAlignCenter" },
      //         headerAttributes: { style: "text-align:center !important" },
      //       },
      //     ],
      //     edit: component.onGridEditing,
      //     editable: { mode: "inline", confirmation: false },
      //   });

      //   component.wnd = $("#equipmentDetails")
      //     .kendoWindow({
      //       title: "Equipment Details",
      //       modal: true,
      //       visible: false,
      //       resizable: false,
      //       width: 550,
      //     })
      //     .data("kendoWindow");

      //   const equipTemplateHtml = $("#equipmentTemplate").html();
      //   component.detailsTemplate = Global.isDefined(equipTemplateHtml)
      //     ? kendo.template(equipTemplateHtml)
      //     : "";

      //   const qs_master_equipment_table_grid: any = angular.element(
      //     "#qs_machinery_equipment_table_grid"
      //   );
      //   const hideEquipmentActionCol: boolean =
      //     component.proposal.qsMaster.qs_machinery_equipment.readOnly;

      //   if (common.isDefined(qs_master_equipment_table_grid.data("kendoGrid"))) {
      //     const equipmentGrid = qs_master_equipment_table_grid.data("kendoGrid");
      //     equipmentGrid.destroy();
      //     angular.element("#qs_machinery_equipment_table_grid").empty();
      //     angular.element("#qs_machinery_equipment_table_grid").off("click");
      //   }

      //   let toolbar: any = [
      //     {
      //       text: "Add New Equipment",
      //       name: "create",
      //     },
      //   ];

      //   if (!component.isEndorsement) {
      //     toolbar = component.proposal.qsMaster.qs_machinery_equipment.readOnly
      //       ? ""
      //       : toolbar;
      //   } else {
      //     if (
      //       this.proposal.refreshedProposalEntity.proposalReadOnly &&
      //       component.proposal.qsMaster.qs_machinery_equipment.readOnly
      //     ) {
      //       toolbar = "";
      //     }
      //   }

      //   const showEquipAction: boolean = component.proposal.qsMaster
      //     .qs_machinery_equipment.readOnly
      //     ? true
      //     : false;
      //   timeout(() => {
      //     if (showEquipAction) {
      //       $(
      //         "#qs_machinery_equipment_table_grid .k-grid-edit,#qs_machinery_equipment_table_grid .k-grid-myDelete"
      //       ).hide();
      //     }
      //     if (!this.isIBGProduct) {
      //       /// 30/09/2019 : INC20585048 DBS EPL - FREE INSURANCE DISPLAY DISCREPANCIES and Occcupation Filter issue
      //       this.filterOccupied(
      //         this.proposal.qsMaster.qs_master_masterPolicyNumber!.answer
      //       );
      //     }
      //   }, 500);

      //   qs_master_equipment_table_grid.kendoGrid({
      //     dataSource: dataSourceEquipment,
      //     pageable: true,
      //     height: 280,
      //     draggable: false,
      //     toolbar: toolbar,
      //     dataBound: () => {
      //       const grid1 = angular
      //         .element("#qs_machinery_equipment_table_grid")
      //         .data("kendoGrid");
      //       if (
      //         common.isDefined(grid1) &&
      //         this.proposal.qsMaster.qs_machinery_equipment.readOnly
      //       ) {
      //         angular
      //           .element("#qs_machinery_equipment_table_grid .k-grid-add")
      //           .hide();
      //       }
      //       $(
      //         "#qs_machinery_equipment_table_grid .k-grid-myDelete span"
      //       ).addClass("k-icon k-delete");
      //     },
      //     columns: [
      //       {
      //         field: "equipmentType",
      //         title: "Type of Equipment",
      //         width: "250px",
      //         editor: component.equipmentTypeSectionDropdown,
      //         template: "#=equipmentType!.key#",
      //       },
      //       {
      //         field: "typeOfFinancing",
      //         title: "Serial Number",
      //         width: "150px",
      //       },
      //       {
      //         field: "equipmentDescription",
      //         title: "Description",
      //         width: "250px",
      //       },

      //       {
      //         field: "sumInsured",
      //         title: "Sum Insured",
      //         width: "140px",
      //         editor: component.equipmentSumInsuredEditor,
      //         template: "$ #=sumInsured#",
      //       },
      //       {
      //         field: "additionalInfo",
      //         title: "Additional Info",
      //         editor: component.equipmentAddtionalInfoTextArea,
      //         template: "#=additionalInfo#",
      //         width: "250px",
      //       },
      //       {
      //         // hidden: hideEquipmentActionCol,
      //         field: "command",
      //         command: [
      //           "edit",
      //           {
      //             name: "myDelete",
      //             text: "Delete",
      //             click: function (e) {
      //               e.preventDefault();
      //               // assign the selected row
      //               component.currentSelectedEquipmentGridOptions = $(
      //                 e.currentTarget
      //               ).closest("tr");

      //               const data = {
      //                 message: component.msg_deleteWarning,
      //                 tableName: "qs_machinery_equipment_table_grid",
      //               };

      //               component._dialog = Dialog.DialogModal.Instance.modalMedium(
      //                 "shared/partials/mdConfirmationMessageV1/sharedConfirmationMessageV1.html",
      //                 "SharedConfirmationMessageV1Controller",
      //                 data,
      //                 component.confirmationModalCloseForDeleteAction,
      //                 undefined,
      //                 undefined,
      //                 undefined,
      //                 false,
      //                 "standardModal"
      //               );
      //             },
      //           },
      //           {
      //             text: "Addt. Info",
      //             click: component.equipListShowDetails,
      //             name: "add-info",
      //           },
      //         ],
      //         title: "Action",
      //         width: "300px",
      //         attributes: { class: "textAlignCenter" },
      //         headerAttributes: { style: "text-align:center !important" },
      //       },
      //     ],
      //     edit: component.onGridEditing,
      //     selectable: true,
      //     editable: {
      //       mode: "popup",
      //       confirmation: false,
      //       draggable: false,
      //       window: {
      //         // title: "My Custom Title",
      //         // animation: false,
      //         width: "700px",
      //         height: "500px",
      //       },
      //     },
      //   });

      //   angular
      //     .element("#qs_machinery_equipment_table_grid")
      //     .on("click", ".k-grid-add", (e) => {
      //       e.preventDefault();
      //       if (
      //         !this.proposal.qsMaster.qs_master_masterPolicyNumber.isAnswered()
      //       ) {
      //         if (
      //           common.isDefined(
      //             $("#qs_machinery_equipment_table_grid").data("kendoGrid")
      //           )
      //         ) {
      //           const equipmentGrid = angular
      //             .element("#qs_machinery_equipment_table_grid")
      //             .data("kendoGrid");
      //           equipmentGrid.removeRow($(e.currentTarget).closest("tr"));
      //         }
      //         const notification = {
      //           message: "Please choose a master Policy to add Equipment.",
      //         };
      //         this._dialog = Dialog.DialogModal.Instance.modalMedium(
      //           "shared/partials/mdInformationMessage/informationMessageController.html",
      //           "InformationMessageController",
      //           notification,
      //           "",
      //           undefined,
      //           undefined,
      //           undefined,
      //           false,
      //           "standardModal"
      //         );
      //       } else {
      //         component.fetchEquipmentDropdownList();
      //       }
      //     });

      //   angular
      //     .element("#qs_machinery_equipment_table_grid")
      //     .on("click", ".k-grid-edit", (e) => {
      //       timeout(() => {
      //         component.setEquipmentDropdownList();
      //       }, 10);
      //     });

      //   const qs_master_otherInsured_table_grid: any = angular.element(
      //     "#qs_master_otherInsured_table_grid"
      //   );
      //   const hideOtherInsuredNameActionCol: boolean =
      //     component.proposal.qsMaster.qs_master_otherInsuredName.readOnly;

      //   if (
      //     common.isDefined(qs_master_otherInsured_table_grid.data("kendoGrid"))
      //   ) {
      //     const otherInsuredGrid =
      //       qs_master_otherInsured_table_grid.data("kendoGrid");
      //     otherInsuredGrid.destroy();
      //     angular.element("#qs_master_otherInsured_table_grid").empty();
      //   }

      //   qs_master_otherInsured_table_grid.kendoGrid({
      //     dataSource: dataSourceOtherInsured,
      //     pageable: true,
      //     height: 280,
      //     toolbar: [
      //       {
      //         text: "Add Additional Insured",
      //         name: "create",
      //       },
      //     ],
      //     dataBound: () => {
      //       const grid1 = angular
      //         .element("#qs_master_otherInsured_table_grid")
      //         .data("kendoGrid");
      //       if (
      //         common.isDefined(grid1) &&
      //         !this.proposal.qsMaster.qs_master_otherInsuredName.readOnly
      //       ) {
      //         if (
      //           grid1.dataSource.data().length >= this.interestedPartyGridLength
      //         ) {
      //           angular
      //             .element("#qs_master_otherInsured_table_grid .k-grid-add")
      //             .hide();
      //         } else {
      //           angular
      //             .element("#qs_master_otherInsured_table_grid .k-grid-add")
      //             .show();
      //         }
      //       } else {
      //         angular
      //           .element("#qs_master_otherInsured_table_grid .k-grid-add")
      //           .hide();
      //       }
      //       $(
      //         "#qs_master_otherInsured_table_grid .k-grid-myDelete span"
      //       ).addClass("k-icon k-delete");
      //     },
      //     columns: [
      //       {
      //         field: "otherInsuredName",
      //         title: "Insured Name",
      //         width: "350px",
      //       },
      //       {
      //         field: "cin",
      //         title: "CIN",
      //         width: "325px",
      //       },
      //       {
      //         field: "otherBRNo",
      //         title: "Business Registration Number",
      //         width: "275px",
      //       },
      //       {
      //         hidden: hideOtherInsuredNameActionCol,
      //         field: "command",
      //         command: [
      //           "edit",
      //           {
      //             name: "myDelete",
      //             text: "Delete",
      //             click: function (e) {
      //               e.preventDefault();
      //               // assign the selected row
      //               component.currentSelectedInsuredGridOptions = $(
      //                 e.currentTarget
      //               ).closest("tr");

      //               const data = {
      //                 message: component.msg_deleteWarning,
      //                 tableName: "qs_master_otherInsured_table_grid",
      //               };

      //               component._dialog = Dialog.DialogModal.Instance.modalMedium(
      //                 "shared/partials/mdConfirmationMessageV1/sharedConfirmationMessageV1.html",
      //                 "SharedConfirmationMessageV1Controller",
      //                 data,
      //                 component.confirmationModalCloseForDeleteAction,
      //                 undefined,
      //                 undefined,
      //                 undefined,
      //                 false,
      //                 "standardModal"
      //               );
      //             },
      //           },
      //         ],
      //         title: "Action",
      //         width: "200px",
      //         attributes: { class: "textAlignCenter" },
      //         headerAttributes: { style: "text-align:center !important" },
      //       },
      //     ],
      //     edit: component.onGridEditing,
      //     editable: { mode: "inline", confirmation: false },
      //   });

      let annualExpiryDate = cloneDeep(
        component.proposal.qsMaster.qs_master_poi_startDate!.answer
      );
      let minInceptionDate = new Date();
      const planTypeYear =
        this.proposal.qsMaster.qs_master_planType != null &&
        this.proposal.qsMaster.qs_master_planType.answer != null
          ? +this.proposal.qsMaster.qs_master_planType.answer
          : 0;
      if (planTypeYear > 0) {
        annualExpiryDate.setYear(annualExpiryDate.getFullYear() + planTypeYear);
        annualExpiryDate.setDate(annualExpiryDate.getDate() - 1);
      } else {
        annualExpiryDate.setYear(annualExpiryDate.getFullYear() + 1);
        annualExpiryDate.setDate(annualExpiryDate.getDate() - 1);
      }
      minInceptionDate.setDate(minInceptionDate.getDate() - 30);
      if (component.proposal.isBroker) {
        const timeDiff = Math.abs(
          new Date().getTime() -
            this.proposal.qsMaster.qs_master_poi_startDate!.answer.getTime()
        );
        const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
        this.qs_master_poi_startDate_datePicker_minDate = minInceptionDate;
        this.qs_master_poi_startDate_datePicker_maxDate = annualExpiryDate;
      } else {
        this.qs_master_poi_startDate_datePicker_minDate = new Date(1900, 1, 1);
        this.qs_master_poi_startDate_datePicker_maxDate = new Date(
          2999,
          12,
          31
        );
      }
      if (
        component.proposal.proposalStatus != Enum.ProposalStatus.Accepted &&
        this.proposal.qsMaster.qs_master_planType!.answer == null
      ) {
        component.proposal.qsMaster.qs_master_poi_endDate!.answer =
          annualExpiryDate;
      }
      component.qs_master_effectiveDate_onChange();
      if (
        (component.isEndorsement &&
          component.proposal.endorsementType !==
            Enum.EndorsementType.ChangeOfPOI) ||
        component.isCancel
      ) {
        component.proposal.qsMaster.qs_master_poi_startDate!.readOnly = true;
      }

      if (this.isIBGProduct) {
        setTimeout(() => {
          //       // empty country value to search for all
          //       this.googleSearchAutoCompletePolicyInformation =
          //         new GoogleAddressAutoCompleteManager(
          //           "policyInformation_autocomplete",
          //           "",
          //           this.updateAddressValues
          //         );
          this.options = {
            types: ["address"],
            componentRestrictions: { country: [] }, //no country restriction
          } as unknown as Options;
          //       this.googleSearchAutoCompletePolicyInformation.InitializeAddressAutoCompleteElement();
          if (this.proposal.isBroker) {
            this.proposal.qsMaster.qs_master_insuredNo!.readOnly = true;
            this.proposal.qsMaster.qs_risk_insuredAddress_building!.readOnly =
              true;
            this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly =
              true;
            if (
              this.identityRolesService.IsCbsOps &&
              this.proposal.transType === Enum.TransType.END &&
              this.proposal.endorsementType ===
                Enum.EndorsementType.GeneralEndorsement &&
              this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
            ) {
              this.proposal.qsMaster.qs_master_insuredNo!.readOnly = false;
            }
          }
          this.qs_master_lst_onChange(
            this.proposal.qsMaster.qs_master_lst!.answer
          );
        }, 200);
      }
      if (
        this.proposal.qsMaster.qs_master_waiveMinimumPremium!.answer === "True"
      ) {
        if (common.isDefined(this.qs_master_waiveMinimumPremium_checkbox)) {
          this.qs_master_waiveMinimumPremium_checkbox.nativeElement.checked =
            true;
        }
      }
    }, 200);
    this.bindGrid(this.proposal.qsMaster.qs_fire_interestedParties!);
  };
  private bindGrid(qs_fire_interestedParties: QuestionEntity) {
    let isIBGProduct =
      this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMF ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMAC
        ? true
        : false;
    if (!isIBGProduct) {
      this.labelTextInterestedParty = "Add Additional Insured";
    }

    this.hideInterestedPartiesActionCol =
      this.proposal!.qsMaster.qs_fire_interestedParties!.readOnly!;

    if (qs_fire_interestedParties.answer) {
      const obj: FireInterestedParties[] = JSON.parse(
        qs_fire_interestedParties.answer
      );
      this.gridFireInterestedParties = {
        data: obj,
        total: obj.length,
      };
    }

    if (
      qs_fire_interestedParties.readOnly !== undefined &&
      !qs_fire_interestedParties.readOnly
    ) {
      if (
        this.gridFireInterestedParties?.total >= this.interestedPartyGridLength
      ) {
        //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').hide();
        this.addButtonVisiiblity = false;
      } else {
        //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').show();
        this.addButtonVisiiblity = true;
      }
    } else {
      //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').hide();
      this.addButtonVisiiblity = false;
    }
    //angular.element('#qs_fire_interestedParties_table_grid .k-grid-myDelete span').addClass('k-icon k-delete');//todo
  }
  public addHandler(args: AddEvent): void {
    args.sender.addRow(new FireInterestedParties("", this.defaultNOI, ""));
  }
  public editHandler(args: EditEvent): void {
    // close the previously edited item
    this.closeEditor(args.sender);
    // edit the row
    args.sender.editRow(args.rowIndex);
  }

  public cancelHandler(args: CancelEvent): void {
    // call the helper method-
    this.closeEditor(args.sender, args.rowIndex);
  }
  private getPartyId(): number {
    let parties: any = this.gridFireInterestedParties!.data!.map((item) => {
      return item.PartyID;
    });
    return Math.max(...parties) + 1;
  }
  public saveHandler(args: SaveEvent): void {
    // update the data source
    if (args.isNew) {
      const oldData: any = args.sender.data;
      let PartyId = oldData.data.length > 0 ? this.getPartyId() : 1;
      let NRIC = args.dataItem.NRIC;
      let NatureOfInterest = args.dataItem.NatureOfInterest;
      let Name = args.dataItem.Name;
      const obj: any = {
        PartyID: PartyId,
        Name: Name,
        NatureOfInterest: NatureOfInterest,
        NRIC: NRIC,
      };
      oldData.data.push(obj);
      this.gridFireInterestedParties = {
        data: orderBy(oldData.data, this.sort),
        total: oldData.data.length,
      };
    }
    this.proposal!.qsMaster.qs_fire_interestedParties!.answer = JSON.stringify(
      this.gridFireInterestedParties.data
    );
    // close the editor, that is, revert the row back into view mode
    args.sender.closeRow(args.rowIndex);
  }

  private confirmationRemoveAdditionalInsured(
    data: any,
    args: RemoveEvent
  ): void {
    // remove the current dataItem from the current data source
    if (data.status === "yes") {
      this.gridFireInterestedParties.data.splice(args.rowIndex, 1);
      this.proposal!.qsMaster.qs_fire_interestedParties!.answer =
        JSON.stringify(this.gridFireInterestedParties.data);
    }
  }
  public removeHandler(args: RemoveEvent): void {
    const dataMsg = {
      message: this.msg_deleteWarning,
    };

    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: {},
      message: dataMsg.message,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationRemoveAdditionalInsured(result, args);
      }
    });
  }

  private closeEditor(
    grid: GridComponent,
    rowIndex = this.editedRowIndex
  ): void {
    // close the editor
    grid.closeRow(rowIndex);
    // revert the data item to original state
  }

  private qs_master_effectiveDates_onChange = (data: any) => {
    this.proposalService.businessRulesManager.validateCancellationBackdating();
    this.proposalService.businessRulesManager.validateLoanRedemptionBackdating();
  };

  private qs_master_effectiveDate_onChange = (): void => {
    /** Endorsement Restrictions for Effective Date
     * -------------------------------------------------------------------
     *
     * 1. General Endorsement
     * 2. Change Of Correspondence Address
     * 3. Change of Sum Insured
     *    Uw and Broker :
     *   -------------------------
     *   Min : Previous Endorsement Effective Date
     *   Max: Policy End date
     * -----------------------------------------
     * 4. Loan Redemption:
     *    Min : Policy Start Date
     *    Max: Policy End Date
     * --------------------------------------------
     * 5. Change Of POI
     *    UW:
     *    ----------
     *    Min : No Restriction
     *    Max : No Restriction
     *    Broker:
     *    ---------
     *    Min : 1 month from Policy Start Date
     *    Max : No Restriction
     * -----------------------------------------
     * 6. Change Of Sum Insured:
     *    Min : Policy Start Date
     *    Max: Policy End Date
     * --------------------------------------------
     */
    let minEffectiveDate =
      common.isDefined(this.proposal.minimumEffectiveDate) &&
      this.proposal.minimumEffectiveDate !== null
        ? new Date(this.proposal.minimumEffectiveDate)
        : new Date(this.proposal.qsMaster.qs_master_poi_startDate!.answer);
    if (
      this.proposal.minimumEffectiveDate < this.proposal.originalInceptionDate
    ) {
      minEffectiveDate = this.proposal.originalInceptionDate;
    }
    const policyStartDate = new Date(
      this.proposal!.qsMaster.qs_master_poi_startDate!.readAnswerAsString()!
    );
    const currentPOI = new Date(new Date().setHours(0, 0, 0, 0));
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const timeDiff = Math.abs(
      policyStartDate.getTime() - currentDate.getTime()
    );
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let minEffectiveDateLoanRedemption = policyStartDate;
    if (
      policyStartDate < currentDate &&
      diffDays > EnumSG.BackdatedRules.MaximumAllowedLoanRedemption
    ) {
      // minEffectiveDateLoanRedemption = new Date(currentPOI.setDate(currentPOI.getDate() - 14));
      minEffectiveDateLoanRedemption = minEffectiveDate;
    }
    if (this.isEndorsement) {
      this.qs_master_effectiveDate_datePicker_minDate = new Date(1900, 1, 1);
      this.qs_master_effectiveDate_datePicker_maxDate = new Date(2999, 12, 31);

      switch (this.proposal.endorsementType) {
        case Enum.EndorsementType.GeneralEndorsement:
          {
            if (this.isIBGProduct) {
              this.qs_master_effectiveDate_datePicker_minDate =
                minEffectiveDate;
              this.qs_master_effectiveDate_datePicker_maxDate =
                this.proposal.qsMaster.qs_master_poi_endDate!.answer;
            } else {
              this.qs_master_effectiveDate_datePicker_minDate = new Date(
                this.proposal.qsMaster.qs_master_poi_startDate!.answer
              );
              this.qs_master_effectiveDate_datePicker_maxDate = new Date(
                this.proposal.qsMaster.qs_master_poi_endDate!.answer
              );
            }
          }
          break;
        case Enum.EndorsementType.ChangeOfCorrespondenceAddress:
        case Enum.EndorsementType.ChangeOfClientInfo:
        case Enum.EndorsementType.ChangeOfAdditionalInsured:
          {
            this.qs_master_effectiveDate_datePicker_minDate = new Date(
              this.proposal.qsMaster.qs_master_poi_startDate!.answer
            );
            this.qs_master_effectiveDate_datePicker_maxDate = new Date(
              this.proposal.qsMaster.qs_master_poi_endDate!.answer
            );
          }
          break;
        case Enum.EndorsementType.LoanRedemption:
          {
            this.qs_master_effectiveDate_datePicker_minDate =
              minEffectiveDateLoanRedemption;
            this.qs_master_effectiveDate_datePicker_maxDate = new Date(
              this.proposal.qsMaster.qs_master_poi_endDate!.answer
            );
          }
          break;
        case Enum.EndorsementType.ChangeOfPOI:
          if (this.proposal.isUnderwriter) {
            {
              this.qs_master_poi_startDate_datePicker_minDate = new Date(
                1900,
                1,
                1
              );
              this.qs_master_poi_startDate_datePicker_maxDate = new Date(
                2999,
                12,
                31
              );
            }
          }
          break;
        case Enum.EndorsementType.ChangeOfSumInsured:
          {
            this.qs_master_effectiveDate_datePicker_minDate = minEffectiveDate;
            this.qs_master_effectiveDate_datePicker_maxDate = new Date(
              this.proposal.qsMaster.qs_master_poi_endDate!.answer
            );
          }
          break;
        default:
          break;
      }
    } else if (this.isCancel) {
      switch (this.proposal.productType) {
        case EnumSG.MortgageFireSgProduct.MIP:
        case EnumSG.MortgageFireSgProduct.IBGMF:
          let MIPEffectiveDate = minEffectiveDate;
          if (this.proposal.loanRedeemed) {
            if (minEffectiveDate < minEffectiveDateLoanRedemption) {
              MIPEffectiveDate = minEffectiveDateLoanRedemption;
            }
          }
          this.qs_master_effectiveDate_datePicker_minDate = MIPEffectiveDate;
          this.qs_master_effectiveDate_datePicker_maxDate = new Date(
            this.proposal.qsMaster.qs_master_poi_endDate!.answer
          );
          break;
        default:
          this.qs_master_effectiveDate_datePicker_minDate = minEffectiveDate;
          this.qs_master_effectiveDate_datePicker_maxDate = new Date(
            this.proposal.qsMaster.qs_master_poi_endDate!.answer
          );
          break;
      }
    }
  };

  //   private confirmationModalCloseForDeleteAction = (data: any): void => {
  //     if (data.confirmation) {
  //       let options = "";
  //       switch (data.tableName) {
  //         case "qs_machinery_equipment_table_grid":
  //           options = this.currentSelectedEquipmentGridOptions;
  //           $("#qs_machinery_equipment_table_grid")
  //             .data("kendoGrid")
  //             .removeRow(options);
  //           break;
  //         case "qs_ct_additional_insured_table_grid":
  //           options = this.currentSelectedInterestedGridOptions;
  //           $("#qs_ct_additional_insured_table_grid")
  //             .data("kendoGrid")
  //             .removeRow(options);
  //           break;
  //         case "qs_master_otherInsured_table_grid":
  //           options = this.currentSelectedInsuredGridOptions;
  //           $("#qs_master_otherInsured_table_grid")
  //             .data("kendoGrid")
  //             .removeRow(options);
  //           break;
  //         default:
  //           break;
  //       }
  //     } else {
  //       return;
  //     }
  //   };

  public $postLink = () => {};

  //   private resetAuxiliaryModel = () => {
  //     // reset the aux if underwriter changes it
  //     if (this.isUnderwriter && this.isIBGProduct) {
  //       const data = {
  //         message: "Premium Details will be refreshed after saved.",
  //       };

  //       this._dialog = Dialog.DialogModal.Instance.modalLarge(
  //         "shared/partials/mdInformationMessage/informationMessageController.html",
  //         "InformationMessageController",
  //         data,
  //         "",
  //         undefined,
  //         undefined,
  //         undefined,
  //         false,
  //         "standardModal"
  //       );

  //       this.proposal.auxiliary = {};
  //     }
  //   };

  public qs_master_insuredIndustry_onChange = () => {
    let postData: occupationSearchDlgInputArgs = {
      isEditable: this.isEditable,
      occupationList: this.proposal.qsMaster.qs_master_insuredIndustry!.options, //temp pass to inside ClientSearchComponent
    };

    const occupationSearchDialogRef = this.cdkDialogService.open(
      OccupationSearchComponent,
      {
        data: postData,
      }
    );

    occupationSearchDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForOccupation(result);
      }
    });
  };

  private occupationModalClose = (data: KeyValue<string>): void => {
    if (common.isDefined(data)) {
      this.proposal.qsMaster.qs_master_insuredIndustry!.answer = data!.key;
      this.proposal.qsMaster.qs_master_insuredIndustry_desc!.answer =
        data.value;
    }
  };

  //   private equipmentAddtionalInfoTextArea(container, options) {
  //     $(
  //       '<textarea data-text-field="' +
  //         options.field +
  //         '" name = "' +
  //         options.field +
  //         '" data-value-field="' +
  //         options.field +
  //         '" data-bind="value:' +
  //         options.field +
  //         '" style="width:400px;height:210px"/>'
  //     ).appendTo(container);
  //     $('<span class="k-invalid-msg" data-for="additionalInfo"></span>').appendTo(
  //       container
  //     );
  //   }

  //   equipListShowDetails = (e): void => {
  //     e.preventDefault();
  //     const component = this;
  //     const equipGrid = $("#qs_machinery_equipment_table_grid").data("kendoGrid");
  //     const dataItem = equipGrid.dataItem($(e.currentTarget).closest("tr"));
  //     component.wnd.content(component.detailsTemplate(dataItem));
  //     component.wnd.center().open();
  //   };

  //   private equipmentTypeSectionDropdown(container, options) {
  //     $(
  //       '<input required name="' +
  //         options.field +
  //         '" id="equipmentDropdownList"/>'
  //     )
  //       .appendTo(container)
  //       .kendoDropDownList({
  //         autoBind: false,
  //         dataTextField: "key",
  //         dataValueField: "value",
  //       });
  //   }

  //   private equipmentSumInsuredEditor(container, options) {
  //     $(
  //       '<input data-text-field="' +
  //         options.field +
  //         '" name = "' +
  //         options.field +
  //         '" data-value-field="' +
  //         options.field +
  //         '" data-bind="value:' +
  //         options.field +
  //         '"/>'
  //     )
  //       .appendTo(container)
  //       .kendoNumericTextBox({
  //         min: 0,
  //         spinners: false,
  //         format: "c0",
  //         decimals: 0,
  //       });
  //     $('<span class="k-invalid-msg" data-for="sumInsured"></span>').appendTo(
  //       container
  //     );
  //   }
  //   private fetchEquipmentDropdownList = (): void => {
  //     if (
  //       common.isDefined(
  //         angular.element("#equipmentDropdownList").data("kendoDropDownList")
  //       )
  //     ) {
  //       if (
  //         angular
  //           .element("#equipmentDropdownList")
  //           .data("kendoDropDownList")
  //           .dataSource.data().length <= 0
  //       ) {
  //         angular
  //           .element("#equipmentDropdownList")
  //           .data("kendoDropDownList")
  //           .dataSource.pushCreate(this.fetchEquipmentOptions());
  //         angular
  //           .element("#equipmentDropdownList")
  //           .data("kendoDropDownList")
  //           .refresh();
  //       }
  //       angular
  //         .element("#equipmentDropdownList")
  //         .data("kendoDropDownList")
  //         .select(0);
  //     }
  //   };

  //   fetchEquipmentOptions = (): KeyValue<any>[] => {
  //     const selectedMasterPolicyDetails = this.getMasterPolicyDetails();
  //     const component = this;
  //     const equipmentDropdownOptions =
  //       component.proposal.qsMaster.qs_machinery_equipment.options.filter(
  //         function (obj) {
  //           switch (selectedMasterPolicyDetails.policyType) {
  //             case "ARC":
  //               return (
  //                 obj.value ===
  //                   ConstSG.mortgageFireSingaporeSettings
  //                     .ARC_MedicalMachineryType ||
  //                 obj.value ===
  //                   ConstSG.mortgageFireSingaporeSettings
  //                     .ARC_OfficeMachineryType ||
  //                 obj.value ===
  //                   ConstSG.mortgageFireSingaporeSettings
  //                     .ARC_ConstructionMachineryType ||
  //                 obj.value ===
  //                   ConstSG.mortgageFireSingaporeSettings
  //                     .ARC_IndustrialMachineryType
  //               );
  //             case "MAR":
  //               return (
  //                 obj.value ===
  //                   ConstSG.mortgageFireSingaporeSettings
  //                     .MAR_ConstructionMachineryType ||
  //                 obj.value ===
  //                   ConstSG.mortgageFireSingaporeSettings
  //                     .MAR_CranesMachineryType ||
  //                 obj.value ===
  //                   ConstSG.mortgageFireSingaporeSettings
  //                     .MAR_IndustrialMachineryType
  //               );
  //           }
  //         }
  //       );
  //     return equipmentDropdownOptions;
  //   };

  //   private setEquipmentDropdownList = (): void => {
  //     const component = this;
  //     if (
  //       common.isDefined(
  //         angular.element("#equipmentDropdownList").data("kendoDropDownList")
  //       )
  //     ) {
  //       if (
  //         angular
  //           .element("#equipmentDropdownList")
  //           .data("kendoDropDownList")
  //           .dataSource.data().length <= 0
  //       ) {
  //         angular
  //           .element("#equipmentDropdownList")
  //           .data("kendoDropDownList")
  //           .dataSource.pushCreate(this.fetchEquipmentOptions());
  //         angular
  //           .element("#equipmentDropdownList")
  //           .data("kendoDropDownList")
  //           .refresh();
  //         // angular.element('#equipmentDropdownList').data('kendoDropDownList').select(0);
  //       }
  //     }
  //   };
  verifyStateCountry(answer: string) {
    const elementPos = this.countryExcludeState
      .map(function (x: any) {
        return x;
      })
      .indexOf(answer);
    if (elementPos > -1) {
      return false;
    }
    return true;
  }

  processTableDataInteresedParty(answer: string) {
    let jsonParsed: any = [];
    if (common.isDefined(answer)) {
      jsonParsed = JSON.parse(answer);
      /*add id for table's use*/
      jsonParsed.forEach((item: { PartyID: any }, index: any) => {
        item.PartyID = index;
      });
    }
    return jsonParsed;
  }

  processTableDataOtherInsured(answer: string) {
    let jsonParsed: any = [];
    if (common.isDefined(answer)) {
      jsonParsed = JSON.parse(answer);
      /*add id for table's use*/
      jsonParsed.forEach((item: { otherInsuredID: any }, index: any) => {
        item.otherInsuredID = index;
      });
    }
    return jsonParsed;
  }

  processTableDataEquipment(answer: string) {
    let jsonParsed: any = [];
    if (common.isDefined(answer)) {
      jsonParsed = JSON.parse(answer);
      /*add id for table's use*/
      jsonParsed.forEach(
        (item: { rowID: any; additionalInfo: string }, index: any) => {
          item.rowID = index;
          //  addtional info is added later, so need to check on the value to prevent showing undefined
          if (!Global.isDefined(item.additionalInfo)) {
            item.additionalInfo = "";
          }
        }
      );
    }
    return jsonParsed;
  }

  //   onDataBound = () => {
  //     const gridTable: any = $("#qs_master_claimedBefore_table_grid").data(
  //       "kendoGrid"
  //     );
  //   };

  //   claimsData_OnRead = (options): void => {
  //     options.success(this.claimData);
  //   };

  //   claimsData_OnCreate = (options): void => {
  //     if (options.data && options.data.models) {
  //       this.claimDataLength = this.claimDataLength + 1;
  //       /*adding the id using the initial length*/
  //       options.data.models[0].PartyID = this.claimDataLength;
  //       this.claimData.push(options.data.models[0]);
  //       options.success(options.data.models[0]);
  //       /*TODO - need to bind to the qas*/
  //       this.proposal.qsMaster.qs_fire_interestedParties!.answer = JSON.stringify(
  //         this.claimData
  //       );
  //     }
  //   };

  //   claimData_OnDestroy = (options): void => {
  //     if (options.data && options.data.models) {
  //       for (let i = 0; i < this.claimData.length; i++) {
  //         const updatedData: any = options.data.models[0];
  //         if (this.claimData[i].PartyID === updatedData.PartyID) {
  //           this.claimData.splice(i, 1);
  //           break;
  //         }
  //       }
  //       /*set the answer to empty if length < 1*/
  //       this.proposal.qsMaster.qs_fire_interestedParties!.answer =
  //         this.claimData.length > 0 ? JSON.stringify(this.claimData) : "";
  //       options.success(this.claimData);
  //     }
  //   };

  //   claimData_OnUpdate = (options): void => {
  //     if (options.data && options.data.models) {
  //       for (let i = 0; i < this.claimData.length; i++) {
  //         const updatedData: any = options.data.models[0];
  //         if (this.claimData[i].PartyID === updatedData.PartyID) {
  //           this.claimData[i].Name = updatedData.Name;
  //           this.claimData[i].NatureOfInterest = updatedData.NatureOfInterest;
  //           this.claimData[i].NRIC = updatedData.NRIC;
  //         }
  //       }

  //       this.proposal.qsMaster.qs_fire_interestedParties!.answer = JSON.stringify(
  //         this.claimData
  //       );
  //       options.success(options.data.models[0]);
  //     }
  //   };

  //   equipmentData_OnRead = (options): void => {
  //     options.success(this.equipmentData);
  //   };

  //   equipmentData_OnCreate = (options): void => {
  //     if (options.data && options.data.models) {
  //       this.equipmentDataLength = this.equipmentDataLength + 1;
  //       /*adding the id using the initial length*/
  //       options.data.models[0].rowID = this.equipmentDataLength;
  //       options.data.models[0].equipmentType =
  //         options.data.models[0].equipmentType!.key !== ""
  //           ? options.data.models[0].equipmentType
  //           : this.fetchEquipmentOptions()[0];
  //       this.equipmentData.push(options.data.models[0]);
  //       options.success(options.data.models[0]);
  //       /*TODO - need to bind to the qas*/
  //       this.proposal.qsMaster.qs_machinery_equipment!.answer = JSON.stringify(
  //         this.equipmentData
  //       );
  //     }
  //   };

  //   equipmentData_OnDestroy = (options): void => {
  //     if (options.data && options.data.models) {
  //       for (let i = 0; i < this.equipmentData.length; i++) {
  //         const updatedData: any = options.data.models[0];
  //         if (this.equipmentData[i].rowID === updatedData.rowID) {
  //           this.equipmentData.splice(i, 1);
  //           break;
  //         }
  //       }
  //       /*set the answer to empty if length < 1*/
  //       this.proposal.qsMaster.qs_machinery_equipment!.answer =
  //         this.equipmentData.length > 0 ? JSON.stringify(this.equipmentData) : "";
  //       options.success(this.equipmentData);
  //     }
  //   };

  //   equipmentData_OnUpdate = (options): void => {
  //     if (options.data && options.data.models) {
  //       for (let i = 0; i < this.equipmentData.length; i++) {
  //         const updatedData: any = options.data.models[0];
  //         if (this.equipmentData[i].rowID === updatedData.rowID) {
  //           this.equipmentData[i].equipmentType = updatedData.equipmentType;
  //           this.equipmentData[i].typeOfFinancing = updatedData.typeOfFinancing;
  //           this.equipmentData[i].equipmentDescription =
  //             updatedData.equipmentDescription;
  //           this.equipmentData[i].additionalInfo = updatedData.additionalInfo;
  //           this.equipmentData[i].sumInsured = updatedData.sumInsured;
  //         }
  //       }
  //       options.success(options.data.models[0]);
  //       this.proposal.qsMaster.qs_machinery_equipment!.answer = JSON.stringify(
  //         this.equipmentData
  //       );
  //     }
  //   };

  //   otherInsuredData_OnRead = (options): void => {
  //     options.success(this.otherInsuredData);
  //   };

  //   otherInsuredData_OnCreate = (options): void => {
  //     if (options.data && options.data.models) {
  //       this.otherInsuredDataLength = this.otherInsuredDataLength + 1;
  //       /*adding the id using the initial length*/
  //       options.data.models[0].otherInsuredID = this.otherInsuredDataLength;
  //       this.otherInsuredData.push(options.data.models[0]);
  //       options.success(options.data.models[0]);
  //       /*TODO - need to bind to the qas*/
  //       this.proposal.qsMaster.qs_master_additionalInsured!.answer =
  //         JSON.stringify(this.otherInsuredData);
  //     }
  //   };

  //   otherInsuredData_OnDestroy = (options): void => {
  //     if (options.data && options.data.models) {
  //       for (let i = 0; i < this.otherInsuredData.length; i++) {
  //         const updatedData: any = options.data.models[0];
  //         if (
  //           this.otherInsuredData[i].otherInsuredID === updatedData.otherInsuredID
  //         ) {
  //           this.otherInsuredData.splice(i, 1);
  //           break;
  //         }
  //       }
  //       options.success(this.otherInsuredData);
  //       /*set the answer to empty if length < 1*/
  //       this.proposal.qsMaster.qs_master_additionalInsured!.answer =
  //         this.otherInsuredData.length > 0
  //           ? JSON.stringify(this.otherInsuredData)
  //           : "";
  //     }
  //   };

  //   otherInsuredData_OnUpdate = (options): void => {
  //     if (options.data && options.data.models) {
  //       for (let i = 0; i < this.otherInsuredData.length; i++) {
  //         const updatedData: any = options.data.models[0];
  //         if (
  //           this.otherInsuredData[i].otherInsuredID === updatedData.otherInsuredID
  //         ) {
  //           this.otherInsuredData[i].otherInsuredName =
  //             updatedData.otherInsuredName;
  //           this.otherInsuredData[i].cin = updatedData.cin;
  //           this.otherInsuredData[i].otherBRNo = updatedData.otherBRNo;
  //         }
  //       }
  //       options.success(options.data.models[0]);
  //       this.proposal.qsMaster.qs_master_additionalInsured!.answer =
  //         JSON.stringify(this.otherInsuredData);
  //     }
  //   };

  //   clientCreate = () => {
  //     const postData = { isNewClient: true };
  //     this._dialog = this.dialog.modalLarge(
  //       "shared/partials/mdClient/create/clientCreate.html",
  //       "ClientCreateController",
  //       postData,
  //       this.confirmationModalCloseForActions,
  //       undefined,
  //       undefined,
  //       undefined,
  //       false,
  //       "standardModal"
  //     );
  //   };
  updatePreferredDelivery = () => {
    if (this.proposalService.proposal.customerMappingId! > 0) {
      const postData = { isNewClient: true };
      const preferredDeliveryMethodDialogRef = this.cdkDialogService.open(
        MdPreferredDeliveryMethodComponent,
        {
          data: postData,
        }
      );

      preferredDeliveryMethodDialogRef
        .afterClosed()
        .subscribe((result: any) => {
          // Subscription runs after the dialog closes
          if (result) {
            this.confirmationModalCloseForDeliveryMehodActions(result);
          }
        });
    }
  };
  clientCreate = () => {
    const postData: clientCreateDlgInputArgs = {
      isNewClient: true,
      proposal: this.proposal,
    };
    const clientCreateDialogRef = this.cdkDialogService.open(
      ClientCreateComponent,
      {
        data: postData,
      }
    );
    clientCreateDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  };
  clientSearch = () => {
    let postData: clientSearchDlgInputArgs = {
      isEditable: this.isEditable,
      proposal: this.proposal, //temp pass to inside ClientSearchComponent
    };

    const clientSearchDialogRef = this.cdkDialogService.open(
      ClientSearchComponent,
      {
        data: postData,
      }
    );

    clientSearchDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
    // this._dialog = this.dialog.modalLarge(
    //     'shared/partials/mdClient/search/clientSearch.html',
    //     'ClientSearchController', ''
    //     , this.confirmationModalCloseForActions, undefined, undefined, undefined, false, 'standardModal');
  };

  viewClientDetails = () => {
    const postData = { isEditable: this.isEditable };

    const v1DialogRef = this.cdkDialogService.open(ClientViewComponent, {
      data: postData,
    });

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.UpdateClientInfo(result);
      }
    });
  };
  openAddressHelp(event: { preventDefault: () => void }): void {
    event.preventDefault();
    const data = {
      // 'proposalModel': this.proposal.proposalModel,
      showGeoCode: false, // determine to hide/show geocode
    };

    const clientSearchDialogRef = this.cdkDialogService.open(
      AddressHelpFormComponent,
      {
        data: data,
      }
    );

    clientSearchDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }
  correspondanceDeliveryAddress(data: any) {
    let contactInfo = "";
    if (
      this.proposal.proposalStatus == Enum.ProposalStatus.Incomplete &&
      data != null &&
      data.address != undefined
    ) {
      if (data["address"][0]["countryISO2"] === "SG") {
        contactInfo =
          this.getAddressFormat(data["address"][0]["addressLine1"]) + "|";
        if (this.getAddressDefined(data["address"][0]["addressLine2"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["addressLine2"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["unitNo"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["unitNo"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["buildingName"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["buildingName"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["countryISO2"])) {
          contactInfo =
            contactInfo +
            this.addressCountryIfUnknown(data["address"][0]["countryISO2"]) +
            " |";
        }
        if (this.getAddressDefined(data["address"][0]["postCode"])) {
          contactInfo =
            contactInfo + this.getAddressFormat(data["address"][0]["postCode"]);
        }
      } else {
        contactInfo = this.getAddressFormat(data["address"][0]["unitNo"]) + "|";
        if (this.getAddressDefined(data["address"][0]["buildingName"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["buildingName"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["addressLine1"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["addressLine1"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["addressLine2"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["addressLine2"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["postCode"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["postCode"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["city"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["city"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["stateName"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["stateName"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["countryISO2"])) {
          contactInfo =
            contactInfo +
            this.addressCountryIfUnknown(data["address"][0]["countryISO2"]) +
            "|";
        }
      }
    } else {
      contactInfo =
        this.proposal!.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!
          .answer;
    }
    var dataAddress = contactInfo.split("|");
    var address = "";
    if (contactInfo.includes("|")) {
      for (let x in dataAddress) {
        if (dataAddress[x] != " " || dataAddress[x] != "") {
          address = address + " " + dataAddress[x];
        }
      }
    } else {
      address = contactInfo;
    }

    this.contactInformationPreferredDelivery = address;
    this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer =
      contactInfo;
  }

  confirmationModalCloseForDeliveryMehodActions = (response: any) => {
    if (response.status === "yes") {
      let data = response.data;
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer =
        data.mobileNumber;
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.answer =
        data.emailAddress;
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer =
        data.preferredDeliveryMethod;
      this.correspondanceDeliveryAddress(data);
    }
  };
  confirmationModalCloseForOccupation = (response: any): void => {
    if (response.status === "yes" && common.isDefined(response.data)) {
      this.proposal.qsMaster.qs_master_insuredIndustry!.answer =
        response.data.key;
      this.proposal.qsMaster.qs_master_insuredIndustry_desc!.answer =
        response.data.value;
    }
  };
  confirmationModalCloseForActions = (response: any): void => {
    if (response.status === "yes") {
      let data = response.data;
      if (this.isChangeOfClientInfo && !data.isNewClient) {
        this.confirmationModalCloseForChangeOfClientInfoController(data);
        this.confirmationModalCloseForChangeOfCorrespondenceAddress(data);
      } else {
        // map to customer mapping id
        this.proposalService.proposal.customerMappingId = data.clientId;
        this.proposalService.proposal.customerMappingAddressID =
          data.clientAddressId;
        // this.ClientService.search();
        if (common.isDefined(data.clientId)) {
          this.clientService
            .get(data.clientId, data.clientCode)
            .subscribe((response: any) => {
              if (!this.isChangeOfClientInfo) this.isEditable = true;
              if (response["clientType"] === 1) {
                this.proposal.qsMaster.qs_master_insuredName!.answer =
                  response["lastName"] + " " + response["firstName"];
                this.proposal.qsMaster.qs_master_clientName!.answer =
                  response["lastName"] + "-" + response["firstName"];
              } else {
                this.proposal.qsMaster.qs_master_insuredName!.answer =
                  response["clientName"];
                this.proposal.qsMaster.qs_master_clientName!.answer =
                  response["clientName"];
              }

              this.proposal.qsMaster.qs_master_insuredNo!.answer =
                response["clientCode"];
              this.proposal.qsMaster.qs_master_mobileNumber!.answer =
                response["mobileNumber"];
              this.proposal.qsMaster.qs_master_emailID!.answer =
                response["emailAddress"];
              // update the values to the master level
              this.proposal.qsMaster.qs_master_insuredAddress_building!.answer =
                response["address"][0]["buildingName"];
              this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer =
                response["address"][0]["unitNo"];
              this.proposal.qsMaster.qs_master_insuredAddress1!.answer =
                response["address"][0]["addressLine1"];
              this.proposal.qsMaster.qs_master_insuredAddress2!.answer =
                response["address"][0]["addressLine2"];
              this.proposal.qsMaster.qs_master_insuredPostalCode!.answer =
                response["address"][0]["postCode"];
              this.proposal.qsMaster.qs_master_insuredCity!.answer =
                response["address"][0]["city"];
              this.proposal.qsMaster.qs_master_insuredState!.answer =
                response["address"][0]["stateName"];
              this.proposal.qsMaster.qs_master_insuredCountry!.answer =
                response["address"][0]["countryISO2"];

              let contactInfo = "";
              if (response["address"][0]["countryISO2"] === "SG") {
                contactInfo =
                  response["address"][0]["addressLine1"] +
                  " " +
                  this.getAddressFormat(
                    response["address"][0]["addressLine2"]
                  ) +
                  this.getAddressFormat(response["address"][0]["unitNo"]) +
                  this.getAddressFormat(
                    response["address"][0]["buildingName"]
                  ) +
                  this.addressCountryIfUnknown(
                    response["address"][0]["countryISO2"]
                  ) +
                  " " +
                  this.getAddressFormat(response["address"][0]["postCode"]);
              } else {
                contactInfo =
                  this.getAddressFormat(response["address"][0]["unitNo"]) +
                  this.getAddressFormat(
                    response["address"][0]["buildingName"]
                  ) +
                  response["address"][0]["addressLine1"] +
                  " " +
                  this.getAddressFormat(
                    response["address"][0]["addressLine2"]
                  ) +
                  this.getAddressFormat(response["address"][0]["postCode"]) +
                  this.getAddressFormat(response["address"][0]["city"]) +
                  this.getAddressFormat(response["address"][0]["stateName"]) +
                  this.addressCountryIfUnknown(
                    response["address"][0]["countryISO2"]
                  );
              }
              this.contactInformation = contactInfo;
              if (
                this.proposal.proposalStatus ==
                  Enum.ProposalStatus.Incomplete &&
                (this.proposal.transType === Enum.TransType.NEW ||
                  this.proposal.transType === Enum.TransType.REN)
              ) {
                this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer =
                  response["mobileNumber"];

                this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.answer =
                  response["emailAddress"];
                this.correspondanceDeliveryAddress(response);
                if (
                  this.proposal.qsMaster
                    .qs_master_preferredDeliveryMethod_MobileNo!.answer == "0"
                ) {
                  this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer =
                    "";
                } else if (
                  this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.isAnswered()
                ) {
                  this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer =
                    "2";
                } else if (
                  this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.isAnswered()
                ) {
                  this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer =
                    "5";
                } else {
                  this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer =
                    "1";
                }
              }
            });
        }

        this.copyInsuredAddress(data.clientId, data.clientCode);
      }
    } else {
      if (common.isDefined(this.clientDictionary)) {
        this.mapClientdata(
          this.clientDictionary,
          this.clientService.clientDictionary[
            this.proposalService.proposal.customerMappingId!
          ]
        );
      }
    }
  };

  //   getCityState = (answer: string) => {
  //     if (answer["countryISO2"] === "SG") {
  //       return " ";
  //     } else {
  //       if (answer["countryISO2"] === "HK") {
  //         return " " + answer["city"] + " ";
  //       } else {
  //         return " " + answer["city"] + " " + answer["stateName"] + " ";
  //       }
  //     }
  //   };

  qs_master_planType_onChange = (data: any): void => {
    this.proposal.qsMaster.qs_master_poi_endDate!.answer =
      this.setExpiryDateForCiti();

    if (common.isDefined(this.proposal.qsMaster.qs_master_planType)) {
      if (this.proposal.qsMaster.qs_master_planType!.answer !== "3") {
        this.proposal.qsMaster.qs_master_discountCode!.options = [
          { key: "NA", value: "0" },
          { key: "Custom", value: "6" },
          { key: "10%", value: "1" },
          { key: "15%", value: "2" },
          { key: "20%", value: "3" },
        ];
      } else {
        this.proposal.qsMaster.qs_master_discountCode!.options = [
          { key: "NA", value: "0" },
          { key: "Custom", value: "6" },
          { key: "10%", value: "1" },
          { key: "15%", value: "2" },
          { key: "20%", value: "3" },
          { key: "CTG_50%", value: "4" },
          { key: "CPC_66.66%", value: "5" },
        ];
      }
    }

    if (
      common.isDefined(this.proposal.qsMaster.qs_master_discountCode!.answer)
    ) {
      if (
        !this.proposal.qsMaster.qs_master_discountCode!.options.find(
          (item) =>
            item.value === this.proposal.qsMaster.qs_master_discountCode!.answer
        )
      ) {
        this.proposal.qsMaster.qs_master_discountCode!.answer = null;
      }
    }
  };

  qs_master_poi_startDate_onChange = (): void => {
    /// 12/10/2018 : Chirag Added Code For FreeInsurance Start/End Date
    this.setFreeInsuranceDate();
    let annualExpiryDate = cloneDeep(
      this.proposal.qsMaster.qs_master_poi_startDate!.answer
    );
    const minInceptionDate = new Date();

    annualExpiryDate = this.setExpiryDateForCiti();
    this.proposal.qsMaster.qs_master_poi_endDate!.answer = annualExpiryDate;

    minInceptionDate.setDate(minInceptionDate.getDate() - 30);
    if (this.proposal.isBroker) {
      const timeDiff = Math.abs(
        new Date().getTime() -
          this.proposal.qsMaster.qs_master_poi_startDate!.answer.getTime()
      );
      const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
      this.qs_master_poi_startDate_datePicker_minDate = minInceptionDate;
      this.qs_master_poi_startDate_datePicker_maxDate = annualExpiryDate;
    } else {
      this.qs_master_poi_startDate_datePicker_minDate = new Date(1900, 1, 1);
      this.qs_master_poi_startDate_datePicker_maxDate = new Date(2999, 12, 31);
    }
    this.proposal.qsMaster.qs_master_poi_endDate!.answer = annualExpiryDate;
    // Update the effective Date whenever start date is modified
    this.proposal.qsMaster.qs_master_effectiveDate!.answer =
      this.proposal.qsMaster.qs_master_poi_startDate!.answer;
    this.qs_master_effectiveDate_onChange();
    switch (this.proposal.proposalStatus) {
      case Enum.ProposalStatus.Incomplete:
        break;
      case Enum.ProposalStatus.PendingChecker:
        if (!this.proposal.isUnderwriter) {
          this.proposalService.businessRulesManager.validatePOIBackDated();
        }
        break;
      case Enum.ProposalStatus.Referred:
        this.proposalService.businessRulesManager.validatePOIBackDated();
        break;
    }
  };

  //   cardClick = (e: any): void => {
  //     window["_amsTemplate"]._handleCardCollapse(e);
  //   };

  readOnlyQSFireOccupied = (): void => {
    this.proposal.qsMaster.qs_fire_occupied!.readOnly = true;
    // this.proposal.qsMaster.qs_fire_occupied!.answer = this.proposal.qsMaster.qs_fire_occupied.options[0]!.key; // Default to Dwelling
  };

  private qs_fire_sumInsured_ibg() {
    this.proposalService.businessRulesManager.validateIBGSI();
  }

  qs_fire_occupied_onChange = (data: any): void => {
    switch (this.proposal.productType) {
      case EnumSG.MortgageFireSgProduct.CTHDB:
        this.proposal.qsMaster.qs_fire_replacement!.readOnly = true;
        this.proposal.qsMaster.qs_fire_outstandingLoanValue!.readOnly = true;
        switch (data) {
          case "2":
            this.proposal.qsMaster.qs_fire_replacement!.answer = "120000"; // HDB 3 Rooms
            break;
          case "3":
            this.proposal.qsMaster.qs_fire_replacement!.answer = "156000"; // HDB 3 Rooms with terrace
            break;
          case "4":
            this.proposal.qsMaster.qs_fire_replacement!.answer = "168000"; // HDB 4 Rooms
            break;
          case "5":
            this.proposal.qsMaster.qs_fire_replacement!.answer = "216000"; // HDB 5 Rooms
            break;
          case "6":
            this.proposal.qsMaster.qs_fire_replacement!.answer = "240000"; // HDB 6 Rooms & EA
            break;
          case "7":
            this.proposal.qsMaster.qs_fire_replacement!.answer = "80000"; // HDB studio
            break;
          case "8":
            this.proposal.qsMaster.qs_fire_replacement!.answer = "80000"; // HDB  2 room
            break;
          default: // Non HDB Dwelling
            this.proposal.qsMaster.qs_fire_replacement!.readOnly = false;
            this.proposal.qsMaster.qs_fire_outstandingLoanValue!.readOnly =
              false;
            break;
        }
        break;
      default:
    }
  };

  qs_fire_FSVSumInsured = (data: any): void => {
    switch (this.proposal.productType) {
      case EnumSG.MortgageFireSgProduct.CTHDB:
        if (
          this.proposal.qsMaster.qs_master_manualUnderwritting!.answer ===
            "True" ||
          this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === true
        ) {
          this.proposal.qsMaster.qs_fire_building!.answer =
            this.proposal.qsMaster.qs_fire_building!.answer;
        } else {
          this.proposal.qsMaster.qs_fire_building!.answer =
            this.proposal.qsMaster.qs_fire_replacement!.answer;
        }

        break;
      default:
        break;
    }
  };

  /// 12/10/2018 : Chirag Added Method For FreeInsurance Start/End Date
  setFreeInsuranceDate = (): void => {
    if (!this.isIBGProduct) {
      this.NumOfFreeYears = 0;
      let dtOriginaInceptionDate = "";

      if (
        this.proposal.transType === Enum.TransType.NEW &&
        common.isDefined(this.houseHoldContentsAmount)
      ) {
        /// 08/10/2019 :INC20585048 CTS team added code block For FreeInsurance Start/End Date & Remaining Free Year Issue
        if (
          Number(
            cloneDeep(
              this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer
            )
          ) > 0 &&
          this.proposal.proposalStatus !== 6
        ) {
          this.NumOfFreeYears = Number(
            cloneDeep(
              this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer
            )
          );
        } else {
          this.NumOfFreeYears = Number(
            this.houseHoldContentsAmount?.noOfFreeInsuranceYear
          );
        }
      } else if (
        Number(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer) > 0
      ) {
        this.NumOfFreeYears = Number(
          cloneDeep(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer)
        );
      }

      this.proposal.qsMaster.qs_master_FreeIns_startDate!.isVisible =
        this.NumOfFreeYears > 0 ? true : false;
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.isVisible =
        this.NumOfFreeYears > 0 ? true : false;

      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer =
        this.NumOfFreeYears;

      const FreeInsDate = cloneDeep(
        this.proposal.qsMaster.qs_master_poi_startDate!.answer
      );

      this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer = new Date(
        FreeInsDate
      );
      const FreeInsExpiryDate = cloneDeep(
        this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer
      );

      FreeInsExpiryDate.setYear(
        FreeInsExpiryDate.getFullYear() + this.NumOfFreeYears
      );
      FreeInsExpiryDate.setDate(FreeInsExpiryDate.getDate() - 1);
      this.proposal.qsMaster.qs_master_FreeIns_endDate!.answer =
        FreeInsExpiryDate;

      dtOriginaInceptionDate = this.proposal.originalInceptionDate;
      const OriginalDate = new Date(dtOriginaInceptionDate);

      if (
        Number(
          cloneDeep(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer)
        ) == 0 &&
        common.isDefined(this.houseHoldContentsAmount) &&
        Number(this.houseHoldContentsAmount?.noOfFreeInsuranceYear) > 0 &&
        Number(OriginalDate.getFullYear()) != Number(FreeInsDate.getFullYear())
      ) {
        this.isFreeInsApplicable = false;
      }
    } else {
      let FreeYears = 0;
      let dtOriginaInceptionDate = "";

      FreeYears = Number(
        cloneDeep(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer)
      );

      this.proposal.qsMaster.qs_master_FreeIns_startDate!.isVisible =
        FreeYears > 0 ? true : false;
      dtOriginaInceptionDate =
        this.proposal.qsMaster.qs_master_poi_startDate!.answer;

      this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer = new Date(
        dtOriginaInceptionDate
      );
      const FreeInsExpiryDate = cloneDeep(
        this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer
      );

      FreeInsExpiryDate.setYear(FreeInsExpiryDate.getFullYear() + FreeYears);
      FreeInsExpiryDate.setDate(FreeInsExpiryDate.getDate() - 1);
      this.proposal.qsMaster.qs_master_FreeIns_endDate!.answer =
        FreeInsExpiryDate;
    }
  };
  setFreeInsuranceDateForCBG = (): void => {
    this.NumOfFreeYears = 0;
    this.NumOfFreeYears = Number(
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer
    );
    if (this.NumOfFreeYears > 0) {
      const FreeInsExpiryDate =
        this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer;
      FreeInsExpiryDate.setYear(
        FreeInsExpiryDate.getFullYear() + this.NumOfFreeYears
      );
      FreeInsExpiryDate.setDate(FreeInsExpiryDate.getDate() - 1);
      this.proposal.qsMaster.qs_master_FreeIns_endDate!.answer =
        FreeInsExpiryDate;
    } else {
      this.proposal.qsMaster.qs_master_FreeIns_startDate!.isVisible =
        this.NumOfFreeYears > 0 ? true : false;
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.isVisible =
        this.NumOfFreeYears > 0 ? true : false;
    }
  };

  /// 15/11/2018 : Chirag Method For FreeInsurance Start/End Date
  setFreeInsurance_OnPOIDate_Endorsement = (): void => {
    ///Chirag: 28-12-2018 Free Insurance Change For CBG Prodcut
    if (this.isEndorsement && this.proposal.transType !== Enum.TransType.REN) {
      this.setFreeInsuranceDate();
    }
  };
  /// 30/09/2019 : INC20585048 DBS EPL - FREE INSURANCE DISPLAY DISCREPANCIES and Occcupation Filter issue
  //   filterOccupied = (data: any): void => {
  //     const dropdownFireOccupied = $("#qs_fire_occupied_dropdown").data(
  //       "kendoDropDownList"
  //     );
  //     if (common.isDefined(data) && data != null) {
  //       this.houseHoldContentsAmount = this.masterPolicyService.get(
  //         data.trim().replace(" ", "")
  //       );

  //       if (
  //         Global.isDefined(this.houseHoldContentsAmount) &&
  //         this.houseHoldContentsAmount.policyType === "DHO"
  //       ) {
  //         if (Global.isDefined(dropdownFireOccupied)) {
  //           dropdownFireOccupied.dataSource.filter([
  //             {
  //               field: "value",
  //               operator: "neq",
  //               value: 7,
  //             },
  //             {
  //               field: "value",
  //               operator: "neq",
  //               value: 8,
  //             },
  //           ]);
  //           if (
  //             dropdownFireOccupied.value() === "7" ||
  //             dropdownFireOccupied.value() === "8"
  //           ) {
  //             dropdownFireOccupied.text("");
  //             dropdownFireOccupied.value("");
  //             this.proposal.qsMaster.qs_fire_occupied.clearAnswer();
  //           }
  //         }
  //       } else {
  //         if (Global.isDefined(dropdownFireOccupied)) {
  //           dropdownFireOccupied.dataSource.filter({});
  //         }
  //       }
  //     }
  //   };

  qs_master_premiumPayment_OnChange = (data: any): void => {
    if (common.isDefined(data) && data != null) {
    }
  };

  qs_master_customerType_OnChange = (data: any): void => {
    if (common.isDefined(data) && data != null) {
    }
  };

  qs_master_discountCode_OnChange = (data: any): void => {
    if (common.isDefined(data) && data != null) {
      if (this.proposal.qsMaster.qs_master_discountCode!.answer === "6") {
        this.isCustomDiscount = true;
        this.proposal.qsMaster.qs_master_customDiscountCode!.required = true;
      } else {
        this.isCustomDiscount = false;
        this.proposal.qsMaster.qs_master_customDiscountCode!.required = false;
        this.proposal.qsMaster.qs_master_customDiscountCode!.answer = 0;
      }
    }
  };

  qs_fire_outstandingLoanValue_OnChange = (data: any): void => {};
  qs_master_customDiscountCode_OnChange = (data: any): void => {
    if (common.isDefined(data) && data != null) {
      if (data < 0 || data > 100) {
        this.proposal.qsMaster.qs_master_customDiscountCode!.answer = "";
      }
    }
  };

  public qs_fire_coverageValueType_OnChange = (): void => {
    if (
      this.selectCoverageType !==
      this.proposal.qsMaster.qs_fire_coverageValueType!.answer
    ) {
      this.proposal.qsMaster.qs_fire_building!.clearAnswer();
      this.proposal.qsMaster.qs_fire_replacement!.clearAnswer();

      this.selectCoverageType =
        this.proposal.qsMaster.qs_fire_coverageValueType!.answer;
    }
  };

  assignDefaultValueForHouseholdContentsCiti = (): void => {
    //Display citi default home content
    this.proposal.qsMaster.qs_fire_householdContents!.answer =
      this.proposal.qsMaster.qs_fire_householdContents!.defaultAnswer;
  };

  //   confirmationModalCloseForMasterPolicyNumberChange = (data: any): void => {
  //     if (data.confirmation) {
  //       if (
  //         common.isDefined(
  //           $("#qs_machinery_equipment_table_grid").data("kendoGrid")
  //         )
  //       ) {
  //         angular
  //           .element("#qs_machinery_equipment_table_grid")
  //           .data("kendoGrid")
  //           .dataSource.data([]);
  //         angular
  //           .element("#qs_machinery_equipment_table_grid")
  //           .data("kendoGrid")
  //           .refresh();
  //         this.equipmentData = [];
  //         this.equipmentDataLength = this.equipmentData.length;
  //         this.suppressNotification = true;

  //         this.proposal.qsMaster.qs_machinery_equipment.clearAnswer();
  //         this.suppressNotification = false;
  //         this.masterPolicyNumber =
  //           this.proposal.qsMaster.qs_master_masterPolicyNumber!.answer;
  //       }
  //     } else {
  //       // set the flag to true to prevent calling the notifier upon change of the answer
  //       this.suppressNotification = true;
  //       this.proposal.qsMaster.qs_master_masterPolicyNumber!.answer =
  //         this.masterPolicyNumber;
  //       // reset the flag back to false to call the notifier for the next change event
  //       this.suppressNotification = false;
  //     }
  //   };

  qs_risk_sameAsAddress_onChange = (data: any): void => {
    if (
      common.isDefined(data) &&
      data !== null &&
      !this.proposal.refreshedProposalEntity.proposalReadOnly &&
      this.proposal.transType === Enum.TransType.NEW
    ) {
      //in json: it's using "True"/"False" for all the questions
      const answer =
        data === "1" || data === true || data === "True" ? true : false;
      this.isSameAsAddressTicked = answer;
      this.proposal.qsMaster.qs_risk_insuredAddress_building!.readOnly = answer;
      this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly = answer;
    } else {
      this.proposal.qsMaster.qs_risk_insuredAddress_building!.readOnly = true;
      this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly = true;
    }
  };

  public qs_master_manualUnderwritting_onChange = (data: any): void => {
    //  if (common.isDefined(data) && data !== null && data !== 'False' && this.isIncomplete) {
    if (data === true) {
      const dataMsg = {
        message: this.msg_manualUnderwritting_warning,
      };

      let v1InputData: v1DlgInputArgs = {
        opened: true,
        dialogWidth: 600,
        data: {},
        message: dataMsg.message,
      };

      const v1DialogRef = this.cdkDialogService.open(
        MdConfirmationMessageV1Component,
        {
          data: v1InputData,
        }
      );

      v1DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
          this.confirmationModalCloseForManualUnderwritting(result);
        }
      });
    } else {
      this.updateNativeElement(
        this.qs_master_manualUnderwritting_checkbox,
        false
      );
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer = "False";
    }
  };

  private confirmationModalCloseForManualUnderwritting = (data: any): void => {
    if (data.status === "yes") {
      this.proposal.qsMaster.qs_master_manualUnderwritting!.isDisabled = true;
      if (this.proposal.isUnderwriter) {
        this.proposal.auxiliary["isPremiumManualOverridden"] = true;
      }

      this.manualUnderwritting();
      this.proposalService.businessRulesManager.validateManualUnderwritting();
    } else {
      this.updateNativeElement(
        this.qs_master_manualUnderwritting_checkbox,
        false
      );
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer = "False";
    }
  };

  updateAddressValues = (
    place: { address_components: string | any[] },
    componentForm: { [x: string]: string | number },
    divId: string
  ): void => {
    this.resetAddressForm(false, divId);
    if (Global.isDefined(place.address_components)) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        //if (componentForm[addressType]) {
        //const val = place.address_components[i][componentForm[addressType]];
        if (divId === "riskInformation_autocomplete") {
          switch (addressType) {
            case "street_number":
              let val1 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredAddress1!,
                val1,
                true
              );
              break;
            case "route":
              let val2 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredAddress2!,
                val2,
                true
              );
              break;
            case "locality":
              let val3 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredCity!,
                val3
              );
              break;
            case "postal_code":
              let val4 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredPostalCode!,
                val4,
                true
              );
              break;
            case "administrative_area_level_1":
              let val5 = place.address_components[i].short_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredState!,
                val5
              );
              break;
            case "country":
              let val6 = place.address_components[i].short_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredCountry!,
                val6
              );
              break;
            default:
              break;
          }
          // clearing the search input
          //this.riskInformation_autocomplete.nativeElement.value = "";//todo
        } else if (divId === "policyInformation_autocomplete") {
          switch (addressType) {
            case "street_number":
              let val7 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredAddress1!,
                val7,
                true
              );
              break;
            case "route":
              let val8 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredAddress2!,
                val8,
                true
              );
              break;
            case "locality":
              let val9 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredCity!,
                val9,
                true
              );
              break;
            case "postal_code":
              let val10 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredPostalCode!,
                val10,
                true
              );
              break;
            case "administrative_area_level_1":
              let val11 = place.address_components[i].short_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredState!,
                val11,
                true
              );
              break;
            case "country":
              let val12 = place.address_components[i].short_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredCountry!,
                val12,
                true
              );
              this.isMasterStateVisible = this.verifyStateCountry(val12);
              break;
            default:
              break;
          }
          if (this.isIBGProduct) {
            this.updateContactInformation();
          }

          // clearing the search input
          //this.policyInformation_autocomplete.nativeElement.value = "";
        }
        //}
      }

      if (divId === "policyInformation_autocomplete") {
        this.copyInsuredAddress("", "");
      }
      this.checkIfAddressReadOnlyField();
    }
    //this.$scope.$apply();//todo
  };

  resetAddressForm = (fromResetButton: boolean, divId: string) => {
    if (divId === "riskInformation_autocomplete") {
      this.readOnlyAddressField = [
        "qs_risk_insuredAddress1",
        "qs_risk_insuredAddress2",
        "qs_risk_insuredCity",
        "qs_risk_insuredPostalCode",
      ];
      this.proposal.qsMaster.qs_risk_insuredAddress1!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredAddress2!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredPostalCode!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredCity!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredState!.clearAnswer();

      this.proposal.qsMaster.qs_risk_insuredAddress1!.required = true;
      this.proposal.qsMaster.qs_risk_insuredPostalCode!.required = true;
      this.proposal.qsMaster.qs_risk_insuredCity!.required = true;

      this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = true;
      this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = true;
      this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = true;
      this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = true;
      this.proposal.qsMaster.qs_risk_insuredState!.readOnly = true;

      if (fromResetButton) {
        this.proposal.qsMaster.qs_risk_insuredAddress_building!.clearAnswer();
        this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.clearAnswer();
        // clearing the search input
        //this.riskInformation_autocomplete.nativeElement.value = "";
      }
    } else if (divId === "policyInformation_autocomplete") {
      this.readOnlyAddressField = [
        "qs_master_insuredAddress1",
        "qs_master_insuredAddress2",
        "qs_master_insuredCity",
        "qs_master_insuredPostalCode",
        "qs_master_insuredState",
      ];
      this.proposal.qsMaster.qs_master_insuredAddress1!.clearAnswer();
      this.proposal.qsMaster.qs_master_insuredAddress2!.clearAnswer();
      this.proposal.qsMaster.qs_master_insuredPostalCode!.clearAnswer();
      this.proposal.qsMaster.qs_master_insuredCity!.clearAnswer();
      this.proposal.qsMaster.qs_master_insuredState!.clearAnswer();

      if (this.isIBGProduct) {
        this.proposal.qsMaster.qs_master_insuredAddress1!.required = true;
      }
      this.proposal.qsMaster.qs_master_insuredAddress1!.readOnly = true;
      this.proposal.qsMaster.qs_master_insuredAddress2!.readOnly = true;

      this.copyInsuredAddress("", "");

      if (fromResetButton) {
        this.proposal.qsMaster.qs_master_insuredAddress_building!.clearAnswer();
        this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.clearAnswer();
        // clearing the search input
        //this.policyInformation_autocomplete.nativeElement.value = "";
      }
    }
  };
  checkIfAddressReadOnlyField = () => {
    const toCopy: boolean =
      this.qs_risk_sameAsAddress_checkbox.nativeElement.checked;
    for (let i = 0; i < this.readOnlyAddressField.length; i++) {
      const inputFieldId = this.readOnlyAddressField[i];
      switch (inputFieldId) {
        case "qs_master_insuredAddress1":
          this.proposal.qsMaster.qs_master_insuredAddress1!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = false;
          }
          break;
        case "qs_master_insuredAddress2":
          this.proposal.qsMaster.qs_master_insuredAddress2!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = false;
          }
          break;
        case "qs_master_insuredCity":
          this.proposal.qsMaster.qs_master_insuredCity!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = false;
          }
          break;
        case "qs_master_insuredPostalCode":
          this.proposal.qsMaster.qs_master_insuredPostalCode!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = false;
          }
          break;
        case "qs_master_insuredState":
          this.proposal.qsMaster.qs_master_insuredState!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredState!.readOnly = false;
          }
          break;
        case "qs_risk_insuredAddress1":
          this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = false;
          break;
        case "qs_risk_insuredAddress2":
          this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = false;
          break;
        case "qs_risk_insuredCity":
          this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = false;
          break;
        case "qs_risk_insuredPostalCode":
          this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = false;
          break;
        case "qs_risk_insuredState":
          this.proposal.qsMaster.qs_risk_insuredState!.readOnly = false;
          break;
        default:
          break;
      }
    }
  };

  /** editable flag - open for risk address */
  updateAddressAns = (
    model: QuestionEntity,
    val: any,
    isEditableAddress?: boolean
  ) => {
    if (val !== "") {
      model.answer = val;
      model.readOnly = isEditableAddress ? false : true;
      const index = this.readOnlyAddressField.indexOf(model.key);
      if (index !== -1) {
        this.readOnlyAddressField.splice(index, 1);
      }
    } else {
      model.readOnly = false;
    }
  };

  /** editable flag - open for risk address */
  //   updateAddressAns = (
  //     model: QuestionEntity,
  //     val: any,
  //     isEditableAddress?: boolean
  //   ) => {
  //     if (val !== "") {
  //       model!.answer = val;
  //       model.readOnly = isEditableAddress ? false : true;
  //       const index = this.readOnlyAddressField.indexOf(model!.key);
  //       if (index !== -1) {
  //         this.readOnlyAddressField.splice(index, 1);
  //       }
  //     } else {
  //       model.readOnly = false;
  //     }
  //   };
  copyMasterAddress_onChange = (
    inputKey: QuestionEntity,
    copyToKey: QuestionEntity
  ): void => {
    // Copy address from client information to risk only for IBG product
    /** Ignore copying address during endorsement for IBG */
    if (
      this.isIBGProduct &&
      !this.isGeneralEndorsement &&
      common.isDefined(this.qs_risk_sameAsAddress_checkbox)
    ) {
      const toCopy: boolean =
        this.qs_risk_sameAsAddress_checkbox.nativeElement.checked;
      if (toCopy) {
        copyToKey.answer = inputKey.answer;
      }
    }
    if (this.isIBGProduct) {
      this.updateContactInformation();
    }
  };
  clearClientCreateInformation = () => {
    this.proposal.qsClientCreate.qs_master_clientSalutation.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientSurname.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientGivenName.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientNRICPassportNo.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientDOB.clearAnswer();

    this.proposal.qsClientCreate.qs_master_clientOccupation.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientCategory.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientRace.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientNationality.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientGender.clearAnswer();

    this.proposal.qsClientCreate.qs_master_clientMaritalStatus.clearAnswer();

    this.proposal.qsClientCreate.qs_master_insuredAddress1.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredAddress2.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredPostalCode.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredCity.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredState.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredCountry.clearAnswer();
  };

  copyInsuredAddress = (clientID?: string, clientNo?: string) => {
    const toCopy: boolean =
      this.qs_risk_sameAsAddress_checkbox.nativeElement.checked; // IBG product copy address from the master insured details
    if (this.isIBGProduct) {
      if (
        this.proposal.qsMaster.qs_master_insuredCountry!.answer.toLowerCase() ===
        "sg"
      ) {
        /** Ignore copying address during endorsement for IBG */
        if (toCopy && !this.isGeneralEndorsement) {
          this.proposal.qsMaster.qs_risk_insuredAddress_building!.answer =
            this.proposal.qsMaster.qs_master_insuredAddress_building!.answer;
          this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.answer =
            this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer;
          this.proposal.qsMaster.qs_risk_insuredAddress1!.answer =
            this.proposal.qsMaster.qs_master_insuredAddress1!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredAddress1!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredAddress2!.answer =
            this.proposal.qsMaster.qs_master_insuredAddress2!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredAddress2!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredPostalCode!.answer =
            this.proposal.qsMaster.qs_master_insuredPostalCode!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredPostalCode!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredCity!.answer =
            this.proposal.qsMaster.qs_master_insuredCity!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredCity!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredState!.answer =
            this.proposal.qsMaster.qs_master_insuredState!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredState!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredState!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredCountry!.answer =
            this.proposal.qsMaster.qs_master_insuredCountry!.answer;
        }
      } else {
        if (toCopy) {
          this.qs_risk_sameAsAddress_checkbox.nativeElement.checked = false;
          this.proposal.qsMaster.qs_risk_sameAsAddress!.answer = false;
          this.isSameAsAddressTicked = false;

          const data = {
            message: "Only Singapore address can be accepted for Risk.",
          };
          const v1DialogRef = this.cdkDialogService.open(
            MdInformationMessageComponent,
            {
              data: data,
            }
          );

          v1DialogRef.afterClosed().subscribe((result: any) => {
            // Subscription runs after the dialog closes
            if (result) {
            }
          });
        }
      }
    } else {
      // if there's customer mapping id
      if (this.proposalService.proposal.customerMappingId! > 0) {
        // copy to the policy panel
        if (toCopy && this.proposal.transType === Enum.TransType.NEW) {
          this.clientService
            .get(this.proposalService.proposal.customerMappingId!, clientNo)
            .subscribe(
              (response) => {
                if (
                  response["address"][0]["countryISO2"].toLowerCase() === "sg"
                ) {
                  this.proposal.qsMaster.qs_risk_insuredAddress_building!.answer =
                    response["address"][0]["buildingName"];
                  this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.answer =
                    response["address"][0]["unitNo"];
                  this.proposal.qsMaster.qs_risk_insuredAddress1!.answer =
                    response["address"][0]["addressLine1"];
                  this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly =
                    true;
                  this.proposal.qsMaster.qs_risk_insuredAddress2!.answer =
                    response["address"][0]["addressLine2"];
                  this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly =
                    true;
                  this.proposal.qsMaster.qs_risk_insuredPostalCode!.answer =
                    response["address"][0]["postCode"];
                  this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly =
                    true;
                  this.proposal.qsMaster.qs_risk_insuredCity!.answer =
                    response["address"][0]["city"];
                  this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = true;
                  this.proposal.qsMaster.qs_risk_insuredState!.answer =
                    response["address"][0]["stateName"];
                  this.proposal.qsMaster.qs_risk_insuredState!.readOnly = true;
                  this.proposal.qsMaster.qs_risk_insuredCountry!.answer =
                    response["address"][0]["countryISO2"];
                } else {
                  if (toCopy) {
                    this.qs_risk_sameAsAddress_checkbox.nativeElement.checked =
                      false;
                    this.proposal.qsMaster.qs_risk_sameAsAddress!.answer =
                      false;
                    this.isSameAsAddressTicked = false;
                    const data = {
                      message:
                        "Only Singapore address can be accepted for Risk.",
                    };
                    const v1DialogRef = this.cdkDialogService.open(
                      MdInformationMessageComponent,
                      {
                        data: data,
                      }
                    );

                    v1DialogRef.afterClosed().subscribe((result: any) => {
                      // Subscription runs after the dialog closes
                      if (result) {
                      }
                    });
                  }
                }
              },
              (rejection) => {
                this.notifier.error(
                  "Address is failing to retrieve.",
                  this.baseNotifierAppendTo
                );
              }
            );
        }
      }
    }
  };

  getContactInformation = () => {
    if (
      this.proposal.transType === (Enum.TransType.NEW || Enum.TransType.REN)
    ) {
      // if there's customer mapping id
      if (this.proposalService.proposal.customerMappingId! > 0) {
        if (
          this.proposal.proposalId!.toString() !==
          "00000000-0000-0000-0000-000000000000"
        ) {
          this.clientService
            .get(
              this.proposalService.proposal.customerMappingId!,
              this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
            )
            .subscribe(
              (response) => {
                const clientInfo = response;

                this.proposal.qsMaster.qs_master_insuredAddress_building!.answer =
                  clientInfo["address"][0]["buildingName"];
                this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer =
                  clientInfo["address"][0]["unitNo"];
                this.proposal.qsMaster.qs_master_insuredAddress1!.answer =
                  clientInfo["address"][0]["addressLine1"];
                this.proposal.qsMaster.qs_master_insuredAddress2!.answer =
                  clientInfo["address"][0]["addressLine2"];
                this.proposal.qsMaster.qs_master_insuredPostalCode!.answer =
                  clientInfo["address"][0]["postCode"];
                this.proposal.qsMaster.qs_master_insuredCity!.answer =
                  clientInfo["address"][0]["city"];
                this.proposal.qsMaster.qs_master_insuredState!.answer =
                  clientInfo["address"][0]["stateName"];
                this.proposal.qsMaster.qs_master_insuredCountry!.answer =
                  clientInfo["address"][0]["countryISO2"];
                //  this.updateContactInformation();
              },
              (rejection) => {
                this.notifier.error(
                  "Contact Information is failing to retrieve.",
                  this.baseNotifierAppendTo
                );
              }
            );
        }
      }
    }
  };

  updateContactInformation = () => {
    let contactInfo = "";
    if (this.proposal.qsMaster.qs_master_insuredCountry!.answer === "SG") {
      contactInfo =
        this.proposal.qsMaster.qs_master_insuredAddress1!.answer +
        " " +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress2!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress_building!.answer
        ) +
        this.addressCountryIfUnknown(
          this.proposal.qsMaster.qs_master_insuredCountry!.answer
        ) +
        " " +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredPostalCode!.answer
        );
    } else {
      contactInfo =
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress_building!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress1!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress2!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredPostalCode!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredCity!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredState!.answer
        ) +
        this.addressCountryIfUnknown(
          this.proposal.qsMaster.qs_master_insuredCountry!.answer
        );
    }

    this.contactInformation = contactInfo;
    this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer =
      contactInfo;
  };

  getAddressFormat = (input: any) => {
    if (!common.isUndefinedOrNull(input)) {
      return input + " ";
    } else {
      return "";
    }
  };

  getAddressDefined = (input: any) => {
    if (!common.isUndefinedOrNull(input)) {
      return true;
    } else {
      return false;
    }
  };

  /* getAddressCityState = () => {
    if (this.proposal.qsMaster.qs_master_insuredCountry!.answer === 'SG') {
        return ' ';
    } else {
        if (this.proposal.qsMaster.qs_master_insuredCountry!.answer === 'HK') {
            if (!common.isUndefinedOrNull(this.proposal.qsMaster.qs_master_insuredCity!.answer)) {
                return ' ' + this.proposal.qsMaster.qs_master_insuredCity!.answer + ' ';
            } else {
                return ' ';
            }
        } else {
            if (!common.isUndefinedOrNull(this.proposal.qsMaster.qs_master_insuredCity!.answer)) {
                if (!common.isUndefinedOrNull(this.proposal.qsMaster.qs_master_insuredState!.answer)) {
                    return ' ' + this.proposal.qsMaster.qs_master_insuredCity!.answer + ' '
                        + this.proposal.qsMaster.qs_master_insuredState!.answer + ' ';
                } else {
                    return ' ' + this.proposal.qsMaster.qs_master_insuredCity!.answer + ' ';
                }
            } else {
                if (!common.isUndefinedOrNull(this.proposal.qsMaster.qs_master_insuredState!.answer)) {
                    return ' ' + this.proposal.qsMaster.qs_master_insuredState!.answer + ' ';
                } else {
                    return ' ';
                }
            }
        }
    }
} */
  addressCountryIfUnknown = (inputId: string) => {
    let displayText: string = "Unknown";
    if (inputId.length > 0) {
      this.countryList.forEach((i) => {
        if (i.value === inputId) {
          displayText = i!.key;
        }
      });
    }

    return displayText;
  };

  showChangeOfCorrespondenceAddressModal = (command: number) => {
    const data: InterfaceProduct.IDialogMessage = {
      message: "Change of Correspondence Address",
      tabClicked: command,
    };
    //todo
    // this._dialog = this.dialog.modalLarge(
    //     'shared/partials/mdChangeOfCorrespondenceAddress/changeOfCorrespondenceAddress.html',
    //     'ChangeOfCorrespondenceAddressController', data
    //     , this.confirmationModalCloseForChangeOfCorrespondenceAddress, undefined, undefined, undefined, false, 'standardModal');
  };

  GotoUpdateClientInfo() {
    this.FindExistingPoliciesForClient(null);
  }

  UpdateClientInfo = (response: any) => {
    // DBSEP-2063: add this block to popup the confirmation box with list of policy and with button of update and new client
    // Update button of confirmation box will allow to update the client  and newclient button will open new popup of new client screen
    if (response.status == "yes") {
      this.updateClientData();
      let command = response.data;
      if (!this.isChecker && !this.isMaker) {
        this.FindExistingPoliciesForClient(command);
      } else {
        this.GoToClientPage(false);
      }
    }
  };

  mapClientdata(sourcedata: ClientEntity, destinationdata: ClientEntity) {
    if (Array.isArray(sourcedata.address)) {
      //need deep-copy, which copy-by-value, not copy-by-reference
      destinationdata.address = JSON.parse(JSON.stringify(sourcedata.address));
    } else {
      destinationdata.address = sourcedata.address;
    }
    destinationdata.clientId = sourcedata.clientId;
    destinationdata.clientCode = sourcedata.clientCode;
    destinationdata.clientType = sourcedata.clientType;
    destinationdata.nric = sourcedata.nric;
    destinationdata.passportNo = sourcedata.passportNo;
    destinationdata.businessRegistrationNumber =
      sourcedata.businessRegistrationNumber;
    destinationdata.dbsClientReferenceNumber =
      sourcedata.dbsClientReferenceNumber;
    destinationdata.incorporatedDate = sourcedata.incorporatedDate;
    destinationdata.personInCharge = sourcedata.personInCharge;
    destinationdata.firstName = sourcedata.firstName;
    destinationdata.lastName = sourcedata.lastName;
    destinationdata.clientName = sourcedata.clientName;
    destinationdata.salutation = sourcedata.salutation;
    destinationdata.gender = sourcedata.gender;
    destinationdata.birthDate = sourcedata.birthDate;
    destinationdata.occupation = sourcedata.occupation;
    destinationdata.nationality = sourcedata.nationality;
    destinationdata.race = sourcedata.race;
    destinationdata.maritalStatus = sourcedata.maritalStatus;
    destinationdata.phoneNoHome = sourcedata.phoneNoHome;
    destinationdata.phoneNoOffice = sourcedata.phoneNoOffice;
    destinationdata.mobileNumber = sourcedata.mobileNumber;
    destinationdata.pagerNo = sourcedata.pagerNo;
    destinationdata.faxNumber = sourcedata.faxNumber;
    destinationdata.emailAddress = sourcedata.emailAddress;
    destinationdata.docType = sourcedata.docType;
    destinationdata.cinNo = sourcedata.cinNo;
  }

  // DBSEP-2063: It will check the confirmation from user and redirect to client edit or create page.
  PolicyconfirmationModalCloseForActions = (response: any): void => {
    if (response.status === "yes") {
      if (this.proposalService.proposal.customerMappingId != null) {
        this.updateClientData();
        this.GoToClientPage(false);
      } else {
        this.GoToClientPage(true);
      }
    }
  };

  updateClientData() {
    if (this.proposalService.proposal.customerMappingId != null) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId,
          this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response) => {
          if (
            common.isDefined(
              this.clientService.clientDictionary[
                this.proposalService.proposal.customerMappingId!
              ]
            )
          ) {
            this.clientDictionary = new ClientEntity();
            this.mapClientdata(
              this.clientService.clientDictionary[
                this.proposalService.proposal.customerMappingId!
              ],
              this.clientDictionary
            );
          }
        });
    }
  }
  // DBSEP-2063:  It will check the mapping of policy with clientId. If policy exists then a new popup will be open otherwise
  //it will directally go to client update page.
  FindExistingPoliciesForClient = (command: any) => {
    this.clientService
      .FindExistingPoliciesForClient(
        this.proposalService.proposal.customerMappingId!,
        this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
      )
      .subscribe(
        (response: any) => {
          let data: InterfaceProduct.IDialogMessage = {
            message: response?.body?.message,
            tabClicked: command,
          };
          if (response?.body?.message == "") {
            this.GoToClientPage(false);
          } else {
            this.showMdConfirmationMessageV5(data);
          }
        },
        (rejection: any) => {
          this.notifier.error("Failed to save.", this.baseNotifierAppendTo);
          dialogActionNotifier.cancel_notifier$.next({
            key: "client-save-close",
            value: { status: "failed" },
          });
        }
      );
  };
  private showMdConfirmationMessageV5(data: InterfaceProduct.IDialogMessage) {
    let v1InputData: v5DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: data.message,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV5Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.PolicyconfirmationModalCloseForActions(result);
      }
    });
  }

  // DBSEP-2063:  It will transfer on client create or client edit page as per the flag.
  // DBSEP-2063:  It will transfer on client create or client edit page as per the flag.
  GoToClientPage(data: any) {
    const postData: clientCreateDlgInputArgs = {
      isNewClient: data,
      proposal: this.proposal,
    };
    const clientCreateDialogRef = this.cdkDialogService.open(
      ClientCreateComponent,
      {
        data: postData,
      }
    );
    clientCreateDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  confirmationModalCloseForChangeOfClientInfoController = (data: any): void => {
    if (Global.isDefined(data.clientData)) {
      this.proposalService.proposal.qsMaster.qs_master_mobileNumber!.answer =
        data.clientData.mobileNumber;
      this.proposalService.proposal.qsMaster.qs_master_emailID!.answer =
        data.clientData.emailAddress;

      this.proposalService.proposal.qsMaster.qs_master_salutation!.answer =
        data.clientData.salutation;
      if (data.clientData.clientType === 1) {
        this.proposalService.proposal.qsMaster.qs_master_insuredName!.answer =
          data.clientData.lastName + " " + data.clientData.firstName;
        this.proposal.qsMaster.qs_master_clientName!.answer =
          data.clientData.lastName + "-" + data.clientData.firstName;
      } else {
        this.proposalService.proposal.qsMaster.qs_master_insuredName!.answer =
          data.clientData.clientName;
        this.proposal.qsMaster.qs_master_clientName!.answer =
          data.clientData.clientName;
      }

      this.proposalService.proposal.qsMaster.qs_master_passport!.answer =
        data.clientData.passportNo;
      this.proposalService.proposal.qsMaster.qs_master_nric!.answer =
        data.clientData.nric;
      this.proposalService.proposal.qsMaster.qs_master_occupation!.answer =
        data.clientData.occupation;
      this.proposalService.proposal.qsMaster.qs_master_birthDate!.answer =
        data.clientData.birthDate;
      this.proposalService.proposal.qsMaster.qs_master_race!.answer =
        data.clientData.race;
      this.proposalService.proposal.qsMaster.qs_master_nationality!.answer =
        data.clientData.nationality;
      this.proposalService.proposal.qsMaster.qs_master_gender!.answer =
        data.clientData.gender;
      this.proposalService.proposal.qsMaster.qs_master_maritalStatus!.answer =
        data.clientData.maritalStatus;
      this.proposalService.proposal.qsMaster.qs_master_phoneNoHome!.answer =
        data.clientData.phoneNoHome;
      this.proposalService.proposal.qsMaster.qs_master_phoneNoOffice!.answer =
        data.clientData.phoneNoOffice;
      this.proposalService.proposal.qsMaster.qs_master_faxNumber!.answer =
        data.clientData.faxNumber;
      this.proposalService.proposal.qsMaster.qs_master_cin!.answer =
        data.clientData.cinNo;
    }
  };

  confirmationModalCloseForChangeOfCorrespondenceAddress = (
    data: any
  ): void => {
    if (Global.isDefined(data.clientAddressData)) {
      this.proposal.qsMaster.qs_master_insuredAddress1!.answer =
        data.clientAddressData.addressLine1;
      this.proposal.qsMaster.qs_master_insuredAddress2!.answer =
        data.clientAddressData.addressLine2;
      this.proposal.qsMaster.qs_master_insuredAddress_building!.answer =
        data.clientAddressData.buildingName;
      this.proposal.qsMaster.qs_master_insuredCity!.answer =
        data.clientAddressData.city;
      this.proposal.qsMaster.qs_master_insuredState!.answer =
        data.clientAddressData.stateName;
      this.proposal.qsMaster.qs_master_insuredCountry!.answer =
        data.clientAddressData.countryISO2;
      this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer =
        data.clientAddressData.unitNo;
      this.proposal.qsMaster.qs_master_insuredPostalCode!.answer =
        data.clientAddressData.postCode;
    }
  };
  //todo
  //   openAddressHelp(event): void {
  //     event.preventDefault();
  //     const data = {
  //       // 'proposalModel': this.proposal.proposalModel,
  //       showGeoCode: false, // determine to hide/show geocode
  //     };
  //     this._dialog = Dialog.DialogModal.Instance.modalMedium(
  //       "products/addressHelpForm/directives/drAddressHelpForm/drAddressHelpForm.html",
  //       "AddressHelpFormController",
  //       data,
  //       undefined,
  //       undefined,
  //       undefined,
  //       undefined,
  //       true,
  //       "standardModal"
  //     );
  //   }

  qs_master_qs_fire_building = (data: any): void => {
    var firebuilding = this.proposal.qsMaster.qs_fire_building!.answer;

    switch (this.proposal.productType) {
      case EnumSG.MortgageFireSgProduct.CTHDB:
        if (this.proposal.qsMaster.qs_fire_building!.isAnswered()) {
          var firereplacement =
            this.proposal.qsMaster.qs_fire_replacement!.answer;
          if (firebuilding.indexOf("$") > -1) {
            this.proposal.qsMaster.qs_fire_building!.answer =
              firebuilding.substring(1);
          }

          if (firereplacement.indexOf("$") > -1) {
            this.proposal.qsMaster.qs_fire_replacement!.answer =
              firereplacement.substring(1);
          }
          const index = Math.round(
            this.proposal.qsMaster.qs_fire_replacement!.answer
          );
          const answer = Math.round(
            this.proposal.qsMaster.qs_fire_building!.readAnswerAsNumeric()!
          );
          if (answer === index) {
            this.isBuildingSIModified = false;
          } else {
            this.isBuildingSIModified = true;
          }
        }
        break;
      case EnumSG.MortgageFireSgProduct.CTMFPTE:
      case EnumSG.MortgageFireSgProduct.CTMFCONDO:
        if (this.isGeneralEndorsement) {
          this.proposal.qsMaster.qs_fire_coverageValueType!.readOnly = true;
        }

        if (
          common.isDefined(
            this.proposal.qsMaster.qs_master_manualUnderwritting!.answer
          )
        ) {
          if (
            this.proposal.qsMaster.qs_master_manualUnderwritting!.answer ===
              "false" ||
            this.proposal.qsMaster.qs_master_manualUnderwritting!.answer ===
              "False"
          ) {
            var coverageType =
              this.proposal.qsMaster.qs_fire_coverageValueType!.answer;

            if (this.proposal.qsMaster.qs_fire_building!.isAnswered()) {
              if (
                this.proposal.qsMaster.qs_fire_building!.readAnswerAsNumeric() !=
                0
              ) {
                switch (coverageType) {
                  case "1": //Fire replacement Value
                    const firereplacementvalue =
                      (this.proposal.qsMaster.qs_fire_building!.readAnswerAsNumeric()! /
                        120) *
                      100;
                    this.proposal.qsMaster.qs_fire_replacement!.answer =
                      parseInt(firereplacementvalue.toString(), 0);

                    break;

                  case "2": //Outstanding Loan Value
                    this.proposal.qsMaster.qs_fire_replacement!.answer =
                      this.proposal.qsMaster.qs_fire_building!.answer;
                    break;
                }
              }
            } else {
              this.proposal.qsMaster.qs_fire_replacement!.clearAnswer();
            }
          }
        }
        break;
      default:
      // do nothing
    }
  };

  qs_master_lst_onChange = (data: any): void => {
    if (data === "1") {
      // yes
      if (
        !Global.isDefined(
          this.proposal.qsMaster.qs_master_lstDetails!.answer
        ) ||
        this.proposal.qsMaster
          .qs_master_lstDetails!.answer.toString()
          .trim() === ""
      ) {
        this.lstDefaultValue = this.lstDetailsTemplate;
        this.proposal.qsMaster.qs_master_lstDetails!.answer =
          this.lstDetailsTemplate;
      } else {
        if (this.proposal.qsMaster.qs_master_lstDetails!.answer) {
          this.lstDefaultValue = JSON.parse(
            this.proposal.qsMaster.qs_master_lstDetails!.answer
          );
        } else {
          // skip the parsing
          this.lstDefaultValue =
            this.proposal.qsMaster.qs_master_lstDetails!.answer;
        }
      }
      /// DBSEP - 2050 : Automation Of LST
      if (this.isCancel && this.isIncomplete && !this.isAutoLst) {
        this.proposal.qsMaster.qs_master_lstDetails!.readOnly = false;
      }
    } else if (data === "2") {
      // no
      this.proposal.qsMaster.qs_master_lstDetails!.clearAnswer();
      this.lstDefaultValue = "";
    }
  };
  qs_master_preferredDeliveryMethodChange = (data: any): void => {
    this.proposalService.fieldValidationManager.setEmailMobileRequires(
      data,
      this.isIBGProduct
    );
    this.qs_master_preferredDeliveryText(
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!
    );
  };

  qs_fire_freeInsuranceFirstYear_onChange = (data: any): void => {
    this.proposalService.fieldValidationManager.setNoOfFreeInsuranceYearsRequires();
  };
  qs_master_refundDisplayText = (question: QuestionEntity) => {
    switch (question!.answer) {
      case "1":
        this.refundDisplayText = "Default";
        break;

      case "2":
        this.refundDisplayText = "Bank";
        break;
      case "3":
        this.refundDisplayText = "Policy Holder";
        break;
      case "4":
        this.refundDisplayText = "No Refund";
        break;
      default:
        this.refundDisplayText = "Default";
        break;
    }
  };

  qs_master_preferredDeliveryText = (question: QuestionEntity) => {
    switch (question!.answer) {
      case "2":
        this.preferredDeliveryText = "Email";
        break;

      case "5":
        this.preferredDeliveryText = "SMS";
        break;
      case "1":
        this.preferredDeliveryText = "No Mobile/Email or Opt Out";
        break;

      default:
        this.preferredDeliveryText = "";
        break;
    }
  };

  //   updateLSTDetails = (
  //     evt,
  //     rowNumber: string,
  //     param: any,
  //     id: string,
  //     decimals: number
  //   ) => {
  //     // update the value to correct decimals
  //     if (Global.isDefined(rowNumber) && Global.isDefined(param)) {
  //       let val: any = "";
  //       let convertDecimals: string = "";
  //       let currentVal: number;
  //       if (
  //         navigator.userAgent.search("Firefox") <= -1 &&
  //         evt !== undefined &&
  //         $(event) &&
  //         $(event.currentTarget)
  //       ) {
  //         currentVal = parseFloat($(event.currentTarget).val());
  //         convertDecimals = currentVal.toFixed(decimals);
  //       } else {
  //         currentVal = parseFloat($("#" + id).val());
  //         convertDecimals = currentVal.toFixed(decimals);
  //       }
  //       val = param.includes("amount")
  //         ? convertDecimals
  //         : parseFloat(convertDecimals) >= 0
  //         ? convertDecimals
  //         : 0;
  //       this.lstDefaultValue[rowNumber][param] = val;
  //     }
  //     this.proposal.qsMaster.qs_master_lstDetails!.answer = JSON.stringify(
  //       this.lstDefaultValue
  //     );
  //   };
}
