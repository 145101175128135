import { Component, OnInit } from "@angular/core";
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { Global } from "src/app/shared/global";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_HOME } from "src/app/app.module";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { UploadExcel } from "src/app/components/product/auto-loan-redemption/auto-loan-redemption.entity";
import { dlgModelArgs } from "src/app/components/shared/partials/md-successful-file-upload/md-successful-file-upload.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-base-upload",
  templateUrl: "./base-upload.component.html",
  styleUrls: ["./base-upload.component.scss"],
})
export class BaseUploadComponent implements OnInit {
  uploadExcel!: UploadExcel;
  excelFilesErrorMessage: string = "";
  hasError: boolean = false;
  isProcessing: boolean = false;

  // Error logs
  errorLogs!: { Title: string; Logs: string[] };

  public redirectOpened = false;
  redirectMessage: any;
  redirectData: any;
  sub?: Subscription;

  constructor(
    protected uploadService: UploadExcelService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected excelTemplate: ExcelTemplateService
  ) {}

  ngOnInit(): void {
    this.uploadExcel = new UploadExcel();

    //https://stackoverflow.com/questions/36814995/multiple-subscriptions-to-observable
    //might need to change to Subject/BehaviorSubject if needed in the future
    this.sub = this.route.data.subscribe();

    // Init Error Log
    if (Global.isUndefined(this.errorLogs)) {
      this.errorLogs = { Title: "", Logs: new Array<string>() };
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  excelAndSizeRestrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
    maxFileSize: 5242880, // Check for each file size less than 5MB, 1024 * 1024 * 5
  };

  public resetFiles(): void {
    //todo: currently only allow single file upload
    // Clear uploaded file once, the file is successfuly uploaded.
    this.uploadExcel.files = {};
  }

  public removeEvent(e: RemoveEvent): void {
    this.resetFiles();
  }

  public select(e: SelectEvent): void {
    this.resetFiles();

    const that = this;

    this.hasError = false;

    let totalFileSize = this.getUploadedFilesTotalSize(e.files);
    if (totalFileSize / 1024 / 1024 > 20) {
      this.hasError = true;
      this.excelFilesErrorMessage =
        "Total file size allowed in a file is 20MB.";
    } else {
      const uploadExcels: File[] = new Array<File>();
      e.files.forEach((file: FileInfo) => {
        //this already check extension & individual file size by kendo control itself
        if (!file.validationErrors && file.rawFile) {
          uploadExcels.push(file.rawFile);
        }
      });
      if (this.uploadExcel != undefined && this.uploadExcel != null) {
        this.uploadExcel.files = uploadExcels;
      }
    }
  }

  getUploadedFilesTotalSize(files: FileInfo[]): number {
    let totalFileSize: number = 0;

    files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        totalFileSize += file.rawFile.size;
      }
    });

    return totalFileSize;
  }

  add(): void {
    this.hasError = false;

    if (this.uploadExcel?.files?.length > 0) {
      this.addExcel();
    } else {
      this.excelFilesErrorMessage = "At least one file needs to be uploaded.";
      this.hasError = true;
    }
  }

  getUploadedExcelFiles(uploadedExcel: UploadExcel): FormData {
    const formData: FormData = new FormData();
    for (let i = 0; i < uploadedExcel.files.length; i++) {
      formData.append("file" + i.toString(), uploadedExcel.files[i]);
    }
    return formData;
  }

  addExcel() {
    //virtual, overide
  }

  public confirmationModelForSuccessullExcelUpload(args: dlgModelArgs) {
    this.redirectOpened = false;
    if (args.status === "return") {
      this.router.navigate(["/" + ROUTE_HOME]);
    }
  }
}
