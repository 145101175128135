import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UrlService {
  constructor() {}

  GetFullUrlWithSubDirectory(originalLastSegment: string) {
    const path = window.location.pathname.toLocaleLowerCase();
    /*
    const lastSegment = path.slice(path.lastIndexOf("/"), path.length); //some env is sub-folder
    let iisSubDirectory = path.replace(lastSegment, "");
    */
    const iisSubDirectory = path.slice(0, path.lastIndexOf("/"));

    let fullUrlWithSubDirectory = window.location.origin + iisSubDirectory;

    return fullUrlWithSubDirectory + "/" + originalLastSegment;
  }
}
