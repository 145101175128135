<div class="wrapper" id="admin">
  <kendo-tabstrip
    (tabSelect)="onTabSelect($event)"
    #tabstrip
    style="padding-top: 30px"
    [tabAlignment]="alignment"
    class="havlved-tab"
  >
    <kendo-tabstrip-tab title="Deductibles" [selected]="true">
      <ng-template kendoTabContent>
        <div class="content">
          <mf-hk-deductible-list></mf-hk-deductible-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Extra Perils">
      <ng-template kendoTabContent>
        <div class="content">
          <mf-hk-extra-peril-list></mf-hk-extra-peril-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Clauses and Warranties">
      <ng-template kendoTabContent>
        <div class="content">
          <mf-hk-endorsement-list></mf-hk-endorsement-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Additional Clauses/Exclusions ">
      <ng-template kendoTabContent>
        <div class="content">
          <mf-hk-add-clauses-excl-list></mf-hk-add-clauses-excl-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
