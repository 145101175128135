<div *ngIf="!(isAdd || isEdit)">
  <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
    <app-configurable-filter
      #childConfigurableFilter
      *ngIf="true"
      [model]="model"
      [coverageTypeOptions]="coverageTypeOptions"
      [searchPlaceholder]="'Search Additional Clauses / Exclusions'"
      (newClickEvent)="addNewAdditionalClausesOrExclusions($event)"
    ></app-configurable-filter>
  </form>
  <br />
  <div class="grid-wrapper">
    <kendo-grid
      #grid
      style="cursor: pointer"
      [data]="gridView"
      [pageSize]="pageSize"
      [skip]="skip"
      [pageable]="true"
      [height]="400"
      [resizable]="true"
      (pageChange)="pageChange($event)"
      [selectable]="true"
      (selectionChange)="gridSelectionChange($event)"
      (cellClick)="cellClickHandler($event)"
    >
      <kendo-grid-column field="code" title="Code" [width]="100">
      </kendo-grid-column>
      <kendo-grid-column field="title" title="Title" [width]="350">
      </kendo-grid-column>
      <kendo-grid-column field="isDefault" title="Default" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <label>{{ getDefaultDisplayText(dataItem) }}</label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="action" title="Action" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span
            class="k-grid-button k-grid-Edit fa fa-pencil-square-o"
            title="Edit"
          >
            <img id="edit" />
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="id" title="Id" [hidden]="true">
      </kendo-grid-column>
      <kendo-grid-column
        field="coverageTypeId"
        title="Coverage Type"
        [hidden]="true"
      >
      </kendo-grid-column>
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="10"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes
          [pageSizes]="pageSizes"
        ></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
    <div *ngIf="loading" class="k-i-loading"></div>
  </div>
</div>
<app-configurable-form-add
  *ngIf="isAdd"
  [addSearchCriteria]="model"
  [coverageTypeOptions]="coverageTypeOptions"
  (returnListing)="returnListingHandler($event)"
></app-configurable-form-add>
<app-configurable-form-edit
  *ngIf="isEdit"
  [editModel]="editModel"
  [coverageTypeOptions]="coverageTypeOptions"
  (returnListing)="returnListingHandler($event)"
></app-configurable-form-edit>
