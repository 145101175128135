import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { IProduct } from "src/app/framework/interface.product";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { RenewalDraftDeletionSearchCriteria } from "./renewal-report.model";
import { proposalCommon } from "./../../../base/utils/proposal-common";

import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
  RenewalDraftDeletionEntity,
  RenewalEntity,
} from "../preset-report.model";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { UntypedFormGroup } from "@angular/forms";
@Component({
  selector: "app-renewal-report",
  templateUrl: "./renewal-report.component.html",
  styleUrls: ["./renewal-report.component.scss"],
})
export class RenewalReportComponent extends BaseComponent implements OnInit {
  @ViewChild("heroForm", { static: false })
  private form!: UntypedFormGroup;
  @ViewChild("fileUploadDateTo", { static: false })
  public fileUploadDateTo!: DatePickerComponent;

  public fileUploadDateTo_min!: Date;
  public fileUploadDateTo_max!: Date;
  @Input() presetReportType!: number;
  @Output() warningZoneEvent = new EventEmitter<any>();
  public searchCriteria = new RenewalDraftDeletionSearchCriteria();
  public userProductList!: IProduct[];
  public showReportType: boolean = false;
  public isCitiProduct: boolean = false;
  public productRadioControl!: string;
  public loading!: boolean;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  public listing: RenewalDraftDeletionEntity[] = [];
  public renewalListing: RenewalEntity[] = [];
  private totalListing: number = 0;
  submitted = false;
  isMSBRSearch = false;
  public segmentList!: Array<{ text: string; value: string }>;

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    private presetExportService: PresetExportService,
    private excelTemplateService: ExcelTemplateService
  ) {
    super(router, broadcastService, identityRolesService);
  }
  override ngOnInit(): void {
    this.productRadioControl = "dbs";
    //segments
    this.segmentList = [
      { text: "CBG", value: "CBG" },
      { text: "IBG", value: "IBG" },
      { text: "PB", value: "PB" },
      { text: "TPC", value: "TPC" },
    ];
    this.productRadioControl = "dbs";
    this.fetchUserList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.searchCriteria = new RenewalDraftDeletionSearchCriteria();
    if (this.gridView && this.gridView.data) {
      this.gridView.data = [];
      this.gridView.total = 0;
    }
  }
  private readProductRadio() {
    this.isCitiProduct = this.productRadioControl === "citi";
  }

  private fetchUserList() {
    // get user productsList
    if (this.isCitiProduct) {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return (
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    } else {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return !(
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    }
  }
  private validateRenewalDraftDeletionReportMandatoryFields(): boolean {
    let isValid: boolean = false;
    // dates
    const dates: boolean = this.searchCriteria.month !== undefined;
    isValid = dates; // || etc...
    return isValid;
  }

  public download(): void {
    if (this.validateRenewalDraftDeletionReportMandatoryFields()) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      if (this.presetReportType === 6) {
        this.downloadRenewalDraftDeletionReport();
      } else {
        this.downloadRenewalReport();
      }
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  public gridSelectionChange(selection: SelectionEvent) {
    // console.log("gridSelectionChanged");
  }
  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    // console.log("cell clicked");
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }
  public sort: SortDescriptor[] = [
    {
      field: "policyCertificateNo",
      dir: "asc",
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }
  private loadData(): void {
    if (this.presetReportType === 6) {
      this.gridView = {
        data: orderBy(this.listing, this.sort),
        total: this.listing.length,
      };
    } else {
      this.gridView = {
        data: orderBy(this.renewalListing, this.sort),
        total: this.renewalListing.length,
      };
    }
  }
  private refresh(): void {
    this.loading = true;
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "policyCertificateNo");
    formData.append("sort[0][dir]", "asc");

    let array = [
      ["productId", "eq", this.searchCriteria.productIds],
      ["segment", "eq", this.searchCriteria.segment],
      ["month", "eq", this.searchCriteria.month],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }
    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }
    if (this.presetReportType == 6) {
      this.presetExportService.renewalDraftReportSearch(querystring).subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            this.listing = result.body.d?.results;
            this.totalListing = result.body.d?.__count;
            this.loadItems();
            this.loading = false;
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
    } else {
      this.presetExportService.renewalReportSearch(querystring).subscribe(
        (result: any) => {
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            this.renewalListing = result.body.d?.results;
            this.totalListing = result.body.d?.__count;
            this.loadItems();
            this.loading = false;
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }

  private loadItems(): void {
    if (this.presetReportType === 6) {
      this.gridView = {
        data: orderBy(this.listing, this.sort), //.slice(this.skip, this.skip + this.pageSize),
        total: this.totalListing,
      };
    } else {
      this.gridView = {
        data: orderBy(this.renewalListing, this.sort), //.slice(this.skip, this.skip + this.pageSize),
        total: this.totalListing,
      };
    }
  }
  public search(): void {
    if (this.form?.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }
    const isValid = this.validateRenewalDraftDeletionReportMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private formatDate(date: Date): string {
    return (
      proposalCommon.translateMonth(date.getMonth() + 1) +
      " " +
      date.getFullYear().toString()
    );
  }
  private downloadRenewalDraftDeletionReport(): void {
    const month: number = this.searchCriteria.month.getMonth() + 1;
    const year: number = this.searchCriteria.month.getFullYear();

    this.presetExportService
      .renewalDraftDeletionReport(
        month,
        year,
        this.searchCriteria.segment,
        this.searchCriteria.productIds
      )
      .subscribe((response: any) => {
        if (
          response != undefined &&
          response.body != undefined &&
          response.body != null
        ) {
          this.excelTemplateService.downloadRenewalDraftReport(
            renewalDraftColumn,
            "Renewal Draft Deletion",
            "Renewal Draft Deletion in " +
              this.formatDate(this.searchCriteria.month),
            response.body.d?.results
          );
        }
      });
  }
  private downloadRenewalReport(): void {
    const month: number = this.searchCriteria.month.getMonth() + 1;
    const year: number = this.searchCriteria.month.getFullYear();
    this.presetExportService
      .renewalReport(
        month,
        year,
        this.searchCriteria.segment,
        this.searchCriteria.productIds
      )
      .subscribe((response: any) => {
        if (
          response != undefined &&
          response.body != undefined &&
          response.body != null
        ) {
          this.excelTemplateService.downloadRenewalReport(
            renewalColumn,
            "Renewal",
            "Renewal" + this.formatDate(this.searchCriteria.month),
            response.body.d?.results
          );
        }
      });
  }
}
const renewalDraftColumn: string[] = [
  "Policy Certificate No",
  "Master Policy No",
  "Policy No",
  "Effective Date",
  "Expiry Date",
  "Gross Renewal Premium",
  "GST",
  "Total Renewal Premium",
  "Free/Paid Renewal",
  "Draft Creation Date",
  "Status",
  "Draft Deletion Date",
  "Transaction Type",
  "Insured Name",
  "Draft Sum Insured (Uploaded SI)",
  "EPL Sum Insured (Current EPL SI)",
  "Risk Address",
  "Bank Reference",
];
const renewalColumn: string[] = [
  "Meridian Sequence No",
  "Policy Certificate No",
  "Master Policy No",
  "PolicyNo",
  "Effective Date",
  "Expiry Date",
  "Gross Renewal Premium",
  "GST",
  "Total Renewal Premium",
  "Commission %",
  "Commission",
  "GST On Commission",
  "No Of Years - Free Insurance",
  "Free/Paid Renewal",
  "Renewal Bound Date",
  "Renewal Docs Generated Date",
  "AD File Month",
  "Status",
  "Saved By",
  "RM Name",
  "BUPC Code",
  "Bank Ref",
  "Insured Name",
  "Additional Insureds",
  "Sum Insured",
  "Risk Address",
  "Correspondence Address",
  "Insured NRIC",
  "Borrower CIN",
  "Occupation",
  "Occupation Rates",
  "Occupied As",
  "Nature Of Business",
  "Usage Of Premises",
  "Type Of Property",
  "Type Of Equipment",
];
