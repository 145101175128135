<kendo-dialog
  title="Underwriter Internal Comment"
  class="modal-title-light-orange"
  (close)="getMessageAndClose('cancel')"
  [width]="850"
  [height]="450"
  *ngIf="!model.messageBoard[0].sendEmail"
>
  <div class="card cardNoBorder">
    <div class="">
      <div class="card-body fontBlack">
        <!--<div ng-show="true">-->
        <label ng-model="messageBoard.response"></label>
        <!--// </div>-->
        <div class="form">
          <form class="" role="form">
            <div class="form-group">
              <button
                type="submit"
                *ngIf="!model.messageBoard[0].sendEmail"
                (click)="submit()"
                class="btn-box chubbRed chubbRed_border_btn chubbWhite_bg"
              >
                Add Message
              </button>
              <button
                type="submit"
                *ngIf="model.messageBoard[0].sendEmail"
                (click)="addMessageSendEmail()"
                class="btn-box chubbLightBlue_border_btn chubbLightBlue chubbWhite_bg"
              >
                Add Message and Send Email
              </button>
            </div>

            <div class="form-group">
              <label ng-model="model.statusText"></label>
              <div class="fontBold"><label for="title">Title</label></div>
              <input
                type="text"
                id="titleText"
                name="titleText"
                [(ngModel)]="model.messageBoard[0].messageTitle"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <div class="fontBold"><label for="content">Content</label></div>
              <textarea
                name="contentText"
                [(ngModel)]="model.messageBoard[0].messageContent"
                rows="5"
                cols="40"
                class="form-control"
              ></textarea>
            </div>

            <div class="form-group">
              <div class="fontBold"><label for="title">Folder Type</label></div>
              <select
                kendo-drop-down-list
                id="messageBoardFolderType"
                name="messageBoardFolderType"
                [(ngModel)]="model.messageBoard[0].folderTypeId"
                class="form-control"
                controlType="kendoDropDownList"
                k-value-primitive="true"
                k-data-source="folderTypes"
                k-data-text-field="'name'"
                k-data-value-field="'id'"
              ></select>
            </div>

            <div class="form-group" *ngIf="model.messageBoard[0].sendEmail">
              <div class="fontBold">
                <label for="content">Mail comment to</label>
              </div>
              <select
                name="selectedUnderwriter"
                kendo-drop-down-list
                [(ngModel)]="model.selectedUnderwriter"
                k-data-source="underWriterList"
                k-data-text-field="'text'"
                k-data-value-field="'value'"
                style="width: 40%"
                placeholder="please select"
              ></select>
            </div>

            <div class="form-group">
              <div class="checkbox">
                <label>
                  <input
                    name="sendEmail"
                    type="checkbox"
                    value="sendEmail"
                    [(ngModel)]="model.messageBoard[0].sendEmail"
                    (click)="chckBoxOnChange()"
                  />
                  Send Email
                </label>
              </div>
              <div>
                <div class="col-sm-4">
                  <div
                    dr-file-upload
                    multiple
                    ownerid="ownerid"
                    description="description"
                    type="fileType"
                    documentids="model.messageBoard.fileIds"
                    opts="opts"
                    module=""
                  ></div>
                </div>
              </div>
            </div>

            <br />
            <br />
            <br />
          </form>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div data-ng-bind="errorMessage"></div>
      </div>
    </div>
  </div>
</kendo-dialog>
