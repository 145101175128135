import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment"; //use the new one in src folder, old one mainly for guid.ts only
import {
  ReconcilePoliciesDownloadEntity,
  SearchCriteria,
} from "../components/accounting/reconcile/models/reconcile-list.model";
import { common } from "../framework/utils/common";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class ReconcileListService extends ApiService {
  private reconcilePoliciesDownloadEntityList!: ReconcilePoliciesDownloadEntity[];
  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }
  async searchReconcilePolicies(
    data: SearchCriteria
  ): Promise<any> {
    const uri = environment.serviceBase + "api/reconcile/export";
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/json",
    });
    const response = await this.httpClient
      .post<any>(uri, data, { headers: headers })
      .toPromise();
    if (response)
    return response.d;
  }
}
