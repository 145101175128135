export class ClientAddressEntity {
  clientAddressID!: string;
  clientID!: string;
  buildingName!: string;
  unitNo!: string;
  addressLine1!: string;

  addressLine2!: string;
  addressLine3!: string;
  addressLine4!: string;
  addressLine5!: string;
  city!: string;

  postCode!: string;
  stateName!: string;
  countryISO2!: string;
  contact1!: string;
  contact2!: string;

  hkDistrictCode?: string;
}
