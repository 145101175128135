import * as EnumSG from "./mf-hk-enums";
import * as Enums from "./../../../../../../framework/enum.shared";
import * as lodash from "lodash";

import { Echo } from "./../../../../base/echo.model";
import { Echos } from "./../../../../base/echos.model";
import { Global } from "./../../../../../../shared/global";
import { KeyValue } from "./../../../../../../framework/domain-entity/key-value";
import { MortgageFireHKTabSchema } from "./schemas/mf-hk-tab-schema";
import { MortgageFireHongKongBusinessRuleSchema } from "./schemas/mf-hk-business-rule-schema";
import { MortgageFireHongKongProposal } from "./mf-hk-proposal";
import { ProductID } from "./../../../../../../framework/const.shared";
import { ProposalStatus } from "./../../../../../../framework/enum.shared";
import { QuestionEntity } from "./../../../../base/question-entity.model";
import { Rule } from "./../../../../base/rule.model";
import { common } from "./../../../../../../framework/utils/common";
import { instanceFactory } from "../../../../../../framework/utils/instance-factory";
import { proposalHelper } from "./../../../../utils/proposal.helper";
import * as Interface from "./../../../../../../framework/interface.shared";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { environment } from "src/environments/environment";

export class MortgageFireHongKongBusinessRulesManager {
  echos!: Echos<QuestionEntity>[];
  ruleSchema: MortgageFireHongKongBusinessRuleSchema;
  underwriterMessages: Rule<QuestionEntity | undefined>[];
  constructor(
    private proposal: MortgageFireHongKongProposal,
    public identityRolesService: IdentityRolesService
  ) {
    this.ruleSchema = new MortgageFireHongKongBusinessRuleSchema(this.proposal);
    this.underwriterMessages = [];
    this.validate(); //to do
  }

  /** Method for validate proposal mandatory questions */
  validate(): Echos<QuestionEntity>[] {
    this.echos = [];

    switch (this.proposal.proposalStatus) {
      case ProposalStatus.Incomplete:
      case ProposalStatus.PendingChecker:
        if (!this.proposal.isUnderwriter) {
          this.proposal.referralMessageRequires = true;
          /** this.validateManualReferral(); */
          /** this.validateChangeOfSIEndorsement(); */
          /** this.validateChangeOfAddressEndorsement(); */
          /** this.validateCancellation(); */
          //this.validatePOIBackDated(); //HE-227 : no need for Hase
          //this.validateCancellationBackdating();
          //this.validateLoanRedemptionBackdating();
          //this.validateManualUnderwritting();//HE-255: no need for Hase
        } else if (
          this.proposal.isUnderwriter &&
          //this.identityRolesService.getIdentity().role.isOperationUnderwriter &&
          (this.proposal.transType === Enums.TransType.NEW ||
            (this.proposal.transType === Enums.TransType.END &&
              this.proposal.endorsementType ===
                Enums.EndorsementType.GeneralEndorsement))
        ) {
          this.proposal.referralMessageRequires = true;
        } else {
          this.proposal.referralMessageRequires = false;
        }
        break;
      case ProposalStatus.Referred:
        this.proposal.referralMessageRequires = true;
        /** this.validateManualReferral(); */
        /** this.validateChangeOfSIEndorsement(); */
        /** this.validateChangeOfAddressEndorsement(); */
        /** this.validateCancellation(); */
        //this.validatePOIBackDated();//HE-227 : no need for Hase
        //this.validateCancellationBackdating();//HE-257
        this.validateLoanRedemptionBackdating();

        //this.validateManualUnderwritting();//HE-257, Scenario 3
        break;
      case ProposalStatus.Accepted:
        this.proposal.referralMessageRequires = true;
        /** this.validateIsPreviousYearLoanRedeemed(); */
        /** [checked with kevin and got feedback no need to show two warning message and to remove the loan redemption warning message] */
        this.validateIsRenewalYearPendingCheckerValidationMessage();
        break;
      case ProposalStatus.Renewed:
        this.proposal.referralMessageRequires = true;
        this.validateIsPreviousYearPendingCheckerValidationMessage();
        break;
      default:
        this.proposal.referralMessageRequires = false;
      // do nothing
    }

    //this.validateMaxSI();
    return this.echos;
  }

  //this one is un-obstructive, hence do in the ~src\app\models\new-product\sme\mortgage-fire\hong-kong\base\mf-hk-field-validation.manager.ts
  validateMaxSI() {
    if (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB) {
      const rule = this.ruleSchema.pwbSI;
      if (
        this.proposal.transType === Enums.TransType.NEW ||
        (this.proposal.transType === Enums.TransType.END &&
          this.proposal.endorsementType ===
            Enums.EndorsementType.GeneralEndorsement) ||
        this.proposal.transType === Enums.TransType.REN
      ) {
        if (
          this.proposal.qsMaster.qs_fire_building!.readAnswerAsNumeric()! >=
          200000000
        ) {
          this.add(rule);
        } else {
          this.remove(rule);
        }
      }
    } else if (
      this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB
    ) {
      const rule = this.ruleSchema.cmbSI;
      if (
        this.proposal.transType === Enums.TransType.NEW ||
        (this.proposal.transType === Enums.TransType.END &&
          this.proposal.endorsementType ===
            Enums.EndorsementType.GeneralEndorsement) ||
        this.proposal.transType === Enums.TransType.REN
      ) {
        if (
          this.proposal.qsMaster.qs_fire_building!.readAnswerAsNumeric()! >=
          100000000
        ) {
          this.add(rule);
        } else {
          this.remove(rule);
        }
      }
    }
  }

  /** This rule is no longer required. Just holding for Future Use */
  // validateChangeOfSIEndorsement() {
  //     const rule = this.ruleSchema.changeOfSIEndorsementType;
  //     if (this.proposal.endorsementType === Enums.EndorsementType.ChangeOfSumInsured) {
  //         this.add(rule);
  //     } else {
  //         this.remove(rule);
  //     }
  // }

  SetIBGReferredToUnderwriterReferRule() {
    switch (this.proposal.proposalStatus) {
      case ProposalStatus.Incomplete:
        if (
          this.identityRolesService.getIdentity().role.isOperationUnderwriter
        ) {
          this.proposal.referralMessageRequires = true;
          //this.validatePOIBackDated();//HE-227 : no need for Hase
          //this.validateCancellationBackdating();//HE-257
          this.validateManualUnderwritting();
          if (
            this.proposal.transType === Enums.TransType.NEW ||
            (this.proposal.transType === Enums.TransType.END &&
              this.proposal.endorsementType ===
                Enums.EndorsementType.GeneralEndorsement) ||
            this.proposal.transType === Enums.TransType.REN
          ) {
          }
          this.validateOperationUnderwriterCancellationAdj(true);
        }
        break;
      case ProposalStatus.Referred:
        this.validateOperationUnderwriterCancellationAdj(true);
        this.validateOperationUnderwriterMakeRedundant();
        break;
    }
  }

  validateChangeOfAddressEndorsement() {
    const rule = this.ruleSchema.changeOfdAddressEndorsementType;
    if (
      this.proposal.endorsementType ===
      Enums.EndorsementType.ChangeOfCorrespondenceAddress
    ) {
      this.add(rule);
    } else {
      this.remove(rule);
    }
  }

  validateCancellation() {
    const rule = this.ruleSchema.cancellationRefer;
    if (
      this.proposal.transType === Enums.TransType.CAN &&
      (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB ||
        this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB)
    ) {
      const currentDate = new Date();
      const cancellationEffectiveDate = new Date(
        this.proposal.qsMaster.qs_master_effectiveDate!.answer
      );
      const policyStartDate = new Date(
        this.proposal.qsMaster.qs_master_poi_startDate!.answer
      );
      /** const diffDates = Math.abs(cancellationEffectiveDate.getTime() - policyStartDate.getTime()); */
      /** const diffDaysOfPolicy = Math.floor(diffDates / (1000 * 3600 * 24)); */
      const timeDiff = Math.abs(
        policyStartDate.getTime() - currentDate.getTime()
      );
      const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
      if (diffDays > 30) {
        this.add(rule);
      } else {
        this.remove(rule);
      }
    } else {
      this.remove(rule);
    }
  }

  validateLoanRedemptionBackdating() {
    const rule = this.ruleSchema.loanRedemptionReferBackdated;
    if (
      this.proposal.endorsementType === Enums.EndorsementType.LoanRedemption
    ) {
      const startDate = new Date(
        new Date(
          this.proposal.qsMaster.qs_master_poi_startDate!.readAnswerAsString()!
        ).setHours(0, 0, 0, 0)
      );
      const currentDate = new Date();
      const cancellationEffectiveDate = new Date(
        this.proposal.qsMaster.qs_master_effectiveDate!.answer
      );
      const timeDiff = Math.abs(
        cancellationEffectiveDate.getTime() - currentDate.getTime()
      );
      const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
      if (cancellationEffectiveDate >= startDate) {
        if (EnumSG.BackdatedRules.MaximumAllowedLoanRedemption > diffDays) {
          this.add(rule);
        } else {
          this.remove(rule);
        }
      } else {
        this.remove(rule);
      }
    }
  }

  isFinancialEndorsementOrNbOrCan() {
    //refer: src\app\components\new-product\sme\mortgage-fire\hong-kong\components\master\hase\mf-hk-master\mf-hk-master.component.ts
    //hide_qs_master_refund_onAllNonFinEn()
    if (this.proposal.transType == Enums.TransType.END) {
      if (
        this.proposal.endorsementType ===
          Enums.EndorsementType.LoanRedemption ||
        this.proposal.endorsementType ===
          Enums.EndorsementType.GeneralEndorsement ||
        this.proposal.endorsementType ===
          Enums.EndorsementType.ChangeOfClientInfo
      ) {
        return false;
      }

      return true;
    }

    return true; //diff here
  }

  isOnlyFinancialEndorsement() {
    //refer: src\app\components\new-product\sme\mortgage-fire\hong-kong\components\master\hase\mf-hk-master\mf-hk-master.component.ts
    //hide_qs_master_refund_onAllNonFinEn()
    if (this.proposal.transType == Enums.TransType.END) {
      if (
        this.proposal.endorsementType ===
          Enums.EndorsementType.LoanRedemption ||
        this.proposal.endorsementType ===
          Enums.EndorsementType.GeneralEndorsement ||
        this.proposal.endorsementType ===
          Enums.EndorsementType.ChangeOfClientInfo
      ) {
        return false;
      }

      return true;
    }

    return this.proposal.transType == Enums.TransType.CAN; //diff here
  }

  compareQuestionAnswer(ques: QuestionEntity) {
    return (
      ques!.answer == 1 || ques!.answer === "True" || ques!.answer === true
    );
  }

  //DBSEP-2136
  validateOperationUnderwriterCancellationAdj(flag: boolean) {
    const rule = this.ruleSchema.cancellationRefer;
    if (flag) {
      if (this.proposal.IsCancellationAdjustment) {
        this.add(rule);
      }
    } else {
      this.add(rule);
    }
  }

  validateOperationUnderwriterMakeRedundant() {
    const rule = this.ruleSchema.makeRedundantRefer;
  }

  validateCancellationBackdating() {
    let rule = this.ruleSchema.cancellationReferBackdated;
    const startDate = new Date(
      new Date(
        this.proposal.qsMaster.qs_master_poi_startDate!.readAnswerAsString()!
      ).setHours(0, 0, 0, 0)
    );
    const currentDate = new Date();
    const cancellationEffectiveDate = new Date(
      this.proposal.qsMaster.qs_master_effectiveDate!.answer
    );
    var timeDiff = Math.abs(
      cancellationEffectiveDate.getTime() - currentDate.getTime()
    );
    var diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
    var isCBG =
      this.proposal.transType === Enums.TransType.CAN &&
      (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB ||
        this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB);

    if (isCBG) {
      if (cancellationEffectiveDate >= startDate) {
        if (EnumSG.BackdatedRules.MaximumAllowedBackdate > diffDays) {
          this.add(rule);
          if (
            this.proposal.endorsementType ===
            Enums.EndorsementType.ChangeOfCorrespondenceAddress
          ) {
            this.remove(rule);
          }
        } else {
          this.remove(rule);
        }
      } else {
        this.remove(rule);
      }
    }
  }

  validateManualReferral() {
    const rule = this.ruleSchema.manualRefer;
    if (
      this.proposal.qsPremiumSummary.qs_master_manualReferral.readAnswerAsNumeric() ===
      Enums.TrueFalseQuestionAnswer.Yes
    ) {
      this.add(rule);
    } else {
      this.remove(rule);
    }
  }

  // Cater for reinstatement loan redeemed scenario (message popup) for year 1
  validateIsPreviousYearLoanRedeemed() {
    if (
      Global.isDefined(
        this.proposal.auxiliary["IsLoanRedeemedValidationMessage"]
      ) &&
      (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB ||
        this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB)
    ) {
      const rule = this.ruleSchema.loanRedemptionReinstatementMessage;
      if (
        this.proposal.auxiliary["IsLoanRedeemedValidationMessage"] === "true" ||
        this.proposal.auxiliary["IsLoanRedeemedValidationMessage"] === true
      ) {
        this.add(rule);
      } else {
        this.remove(rule);
      }
    }
  }

  // Cater for year 2 pending checker message when there is pending checker transaction in year 1
  validateIsRenewalYearPendingCheckerValidationMessage() {
    if (
      Global.isDefined(
        this.proposal.auxiliary["IsRenewalYearPendingCheckerValidationMessage"]
      ) &&
      (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB ||
        this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB)
    ) {
      const rule = this.ruleSchema.renewalYearPendingCheckerMessage;
      if (
        this.proposal.auxiliary[
          "IsRenewalYearPendingCheckerValidationMessage"
        ] === "true" ||
        this.proposal.auxiliary[
          "IsRenewalYearPendingCheckerValidationMessage"
        ] === true
      ) {
        this.add(rule);
      } else {
        this.remove(rule);
      }
    }
  }

  // Cater for year 1 pending checker message when there is pending checker transaction in year 2
  validateIsPreviousYearPendingCheckerValidationMessage() {
    if (
      Global.isDefined(
        this.proposal.auxiliary["IsPreviousYearPendingCheckerValidationMessage"]
      ) &&
      (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB ||
        this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB)
    ) {
      const rule = this.ruleSchema.previousYearPendingCheckerMessage;
      if (
        this.proposal.auxiliary[
          "IsPreviousYearPendingCheckerValidationMessage"
        ] === "true" ||
        this.proposal.auxiliary[
          "IsPreviousYearPendingCheckerValidationMessage"
        ] === true
      ) {
        this.add(rule);
      } else {
        this.remove(rule);
      }
    }
  }

  validateManualUnderwritting() {
    const rule = this.ruleSchema.manualUnderwritingRefer;
    var isCBG =
      (this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === true ||
        this.proposal.qsMaster.qs_master_manualUnderwritting!.answer ===
          "True") &&
      (this.proposal.proposalStatus == Enums.ProposalStatus.Incomplete ||
        this.proposal.proposalStatus == Enums.ProposalStatus.Referred ||
        this.proposal.proposalStatus == Enums.ProposalStatus.PendingChecker);

    var isIBG =
      this.proposal.auxiliary["isPremiumManualOverridden"] &&
      (this.proposal.proposalStatus == Enums.ProposalStatus.Incomplete ||
        this.proposal.proposalStatus == Enums.ProposalStatus.Referred);

    if (isCBG || isIBG) {
      this.add(rule);
    } else {
      this.remove(rule);
    }
    if (
      (this.identityRolesService.IsCbsOps ||
        this.identityRolesService.IsCSCBGSG) &&
      this.proposal.proposalStatus == Enums.ProposalStatus.Incomplete
    ) {
      this.remove(rule);
    }

    var isCBGOpsuser =
      this.proposal.proposalStatus == Enums.ProposalStatus.Incomplete &&
      this.identityRolesService.getIdentity().role.isOperationUnderwriter &&
      (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB ||
        this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB);
    if (isCBGOpsuser) {
      this.remove(rule);
    }
  }

  validatePOIBackDated() {
    const rule = this.ruleSchema.backDatedPOI;
    const startDateOfPOI = new Date(
      this.proposal.qsMaster.qs_master_poi_startDate!.readAnswerAsString()!
    );
    const startDate = new Date(
      new Date(
        this.proposal.qsMaster.qs_master_poi_startDate!.readAnswerAsString()!
      ).setHours(0, 0, 0, 0)
    );
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const timeDiff = Math.abs(startDateOfPOI.getTime() - currentDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    var isCBGOpsuser =
      this.proposal.proposalStatus == Enums.ProposalStatus.Incomplete &&
      this.identityRolesService.getIdentity().role.isOperationUnderwriter &&
      (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB ||
        this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB);
    if (isCBGOpsuser) {
      this.remove(rule);
      return;
    }

    switch (this.proposal.transType) {
      case Enums.TransType.NEW:
        if (startDate < currentDate) {
          if (diffDays > EnumSG.BackdatedRules.MaximumAllowedBackdate) {
            this.add(rule);
          } else {
            this.remove(rule);
          }
        } else {
          this.remove(rule);
        }
        break;
      case Enums.TransType.END:
        if (
          this.proposal.endorsementType === Enums.EndorsementType.ChangeOfPOI &&
          startDate < currentDate
        ) {
          if (diffDays > EnumSG.BackdatedRules.MaximumAllowedBackdate) {
            this.add(rule);
          } else {
            this.remove(rule);
          }
        } else {
          this.remove(rule);
        }
        break;
      case Enums.TransType.REN:
        break;
      default:
        // do nothing
        break;
    }
  }

  private add(message: Rule<QuestionEntity | undefined>) {
    this.pushUnderwriterMessage(message);
    this.pushEchos(message);
  }

  private remove(message: Rule<QuestionEntity | undefined>) {
    this.removeUnderwriterMessage(message);
    this.removeEchos(message);
  }

  private pushUnderwriterMessage(message: Rule<QuestionEntity | undefined>) {
    const found = lodash.find(
      this.underwriterMessages,
      (um: Rule<QuestionEntity>) => {
        return um.id === message.id;
      }
    );

    if (common.isUndefinedOrNull(found)) {
      this.underwriterMessages.push(message);
    }
  }

  private removeUnderwriterMessage(message: Rule<QuestionEntity | undefined>) {
    lodash.remove(
      this.underwriterMessages,
      (um: Rule<QuestionEntity | undefined>) => {
        return um.id === message.id;
      }
    );
  }

  private pushEchos(message: Rule<QuestionEntity | undefined>) {
    let echosFound = lodash.find(this.echos, (e: Echos<QuestionEntity>) => {
      const tabId = parseInt(e.info, 10);
      return message.entity!.tabId === tabId;
    });

    if (common.isUndefinedOrNull(echosFound)) {
      echosFound = new Echos<QuestionEntity>("", []);
      echosFound.info = message.entity!.tabId;
      proposalHelper.convertTitleToTabIntoEchos(
        echosFound,
        MortgageFireHKTabSchema.tabs
      );
      this.echos.push(echosFound);
    }

    const echoFound = lodash.find(
      echosFound!.echos,
      (e: Echo<QuestionEntity>) => {
        return e.key === message.id;
      }
    );

    if (common.isUndefinedOrNull(echoFound)) {
      const echo = new Echo<QuestionEntity>(message.message);
      echo.key = message.id;
      echo.entity = message.entity;
      echo.info = message.entity!.tabId;
      echo.title = message.entity!.key;
      echosFound!.echos.push(echo);
    }
  }

  private removeEchos(message: Rule<QuestionEntity | undefined>) {
    const echosFound = lodash.find(this.echos, (e: Echos<QuestionEntity>) => {
      const tabId = parseInt(e.info, 10);
      return message.entity!.tabId === tabId;
    });

    if (common.isDefined(echosFound)) {
      lodash.remove(echosFound!.echos, (e: Echo<QuestionEntity>) => {
        return e.key === message.id;
      });

      // section remove if no child available
      if (echosFound!.echos.length === 0) {
        lodash.remove(this.echos, (e: Echos<QuestionEntity>) => {
          const tabId = parseInt(e.info, 10);
          return message.entity!.tabId === tabId;
        });
      }
    }
  }
}
