import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PolicyCustomerLoginService } from "../../../services/policy-customer-login.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-customer-login",
  templateUrl: "./customer-login.component.html",
  styleUrls: ["./customer-login.component.scss"],
})
export class CustomerLoginComponent implements OnInit {
  public footerTitle: string = "";
  public userEmailMobile: string | undefined;
  public dob: string | undefined;
  public last4UniqueId: string | undefined;
  public customerLoginForm: UntypedFormGroup = new UntypedFormGroup({
    userEmailMobile: new UntypedFormControl("", Validators.required),
    dob: new UntypedFormControl("", Validators.required),
    last4UniqueId: new UntypedFormControl("", Validators.required),
  });
  public hasError: boolean = false;
  public msg: string = "";
  IsDisabled: boolean = false;
  currentMsgToParent = "";
  msgFromChild1: any = [];

  constructor(
    private policyCustomerLoginService: PolicyCustomerLoginService,
    private router: Router
  ) {
    const getYear = new Date().getFullYear();
    this.footerTitle = "© " + getYear + " - " + "Chubb E-placement℠ System";
  }

  onSubmit = () => {
    this.customerLoginForm.markAllAsTouched();
    if (this.customerLoginForm.status === "VALID") {
      // get otp
      this.policyCustomerLoginService
        .getOTP(
          this.customerLoginForm.controls["userEmailMobile"].value,
          this.customerLoginForm.controls["dob"].value,
          this.customerLoginForm.controls["last4UniqueId"].value
        )
        .then((response) => {
          if (response.status === 1) {
            this.msg = "OTP sent Successfully";
            this.router.navigate([
              "/customerEnterOtp",
              response.enc,
              response.email,
              response.mobile,
              this.customerLoginForm.controls["dob"].value,
              this.customerLoginForm.controls["last4UniqueId"].value,
            ]);
            this.policyCustomerLoginService.isCustomerLogin = false;
            this.policyCustomerLoginService.isCustomerOTP = true;
            this.policyCustomerLoginService.isCustomerOffer = false;
          } else {
            if (response.status === 2) {
              this.hasError = true;
              this.msg =
                "Based on the details provided, you are not eligible for this promotion. Please check if you've entered your details correctly. Thank you";
            } else if (response.status === 4) {
              this.IsDisabled = true;
              this.hasError = true;
              let lockInMinutes = "30";
              if (
                response.lockInMinutes !== undefined &&
                response.lockInMinutes !== null
              ) {
                lockInMinutes = response.lockInMinutes;
              }
              this.msg =
                "Sorry, this link has been disabled for " +
                lockInMinutes +
                " minutes due to multiple failed verification attempts. Please try again later. ";
            } else {
              this.msg = "Error, please check with administrator.";
            }
            this.hasError = true;
          }
        });
    }
  };

  ngOnInit(): void {}
}
