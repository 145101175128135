import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { DocumentService } from "./document/document.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class MessageBoardService extends ApiService {
  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService,
    public documentService: DocumentService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  getMessage(proposalId: any) {
    var uri = "api/proposal/getMessage";
    var params = {
      proposalId: proposalId,
    };
    var data = {};
    return this.get(uri, params, data);
  }

  getInternalUnderwriterComment(proposalId: any) {
    var uri = "api/proposal/getInternalUnderwriterComment";
    var params = {
      proposalId: proposalId,
    };
    var data = {};
    return this.get(uri, params, data);
  }

  saveMessage(serverModel: any) {
    return this.save(serverModel).pipe(
      map(
        (data: any) => {
          return data;
        },
        (error: any) => {
          return error;
        }
      )
    );
  }

  saveEmail = function (serverModel: any) {
    // var deferred = $q.defer();
    // saveAndSendEmail(serverModel)
    //     .then(function (data) {
    //         deferred.resolve(data);
    //     }, function (err) {
    //         deferred.reject(err);
    //     })
    // return deferred.promise;
  };

  save(newMessage: any) {
    var uri = "api/proposal/saveMessage";
    var params = {};
    var data = {};
    return this.add(uri, params, newMessage);
  }

  getFile(params: any) {
    this.documentService.downloadDocument(params);
  }

  upload(newMessage: any) {
    var uri = "api/document/upload";
    var params = {};
    var data = {};
    return this.add(uri, params, newMessage);
  }

  saveAndSendEmail(newMessage: any) {
    var uri = "api/proposal/sendEmailSaveMessage";
    var params = {};
    var data = {};
    return this.add(uri, params, newMessage);
  }

  getMessageBoardCategory(callback: (arg0: any[]) => void) {
    var uri = "products/messageBoard/data/messageBoardCategory.json?v=1";
    return this.get(uri, {}, {}, false, true).subscribe(function (result: any) {
      var model = result;
      var folderType: Array<{ id: any; name: any }> = new Array<{
        id: any;
        name: any;
      }>();
      model.folderType.forEach(function (type: { id: any; name: any }) {
        folderType.push({
          id: type.id,
          name: type.name,
        });
      });
      callback(folderType);
    });
  }

  savePrintOptions(proposalEntity: any): Observable<any> {
    var uri = "api/proposal/SendPrintOptions";
    var fileModelsJson = "";
    if (
      proposalEntity.fileModels != undefined &&
      proposalEntity.fileModels.length > 0
    ) {
      fileModelsJson = JSON.stringify(proposalEntity.fileModels);
    } else {
      fileModelsJson = "";
    }
    const postData = {
      proposalId: proposalEntity.proposalId.toString(),
      printType: proposalEntity.printType,
      messageId:
        proposalEntity.messageId == undefined ? 0 : proposalEntity.messageId,
      printCorrespondence: proposalEntity.printCorrespondence,
      isReprint: proposalEntity.isReprint,
      isSummary: proposalEntity.isSummary,
      isManual: proposalEntity.isManual,
      isPasswordProtected: proposalEntity.isPasswordProtected,
      fileIdsJsonString: fileModelsJson,
    };

    return this.add(uri, {}, postData).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error: any) => {
          return error;
        }
      )
    );
  }
}
