import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DialogRef } from "../dialog/dialog-ref";
import { DIALOG_DATA } from "../dialog/dialog-tokens";

@Component({
  selector: "app-md-confirmation-message-v2",
  templateUrl: "./md-confirmation-message-v2.component.html",
  styleUrls: ["./md-confirmation-message-v2.component.scss"],
})
export class MdConfirmationMessageV2Component implements OnInit {
  @Input() public opened = false;
  @Input() public data: any;
  @Input() public message: any;

  @Output() closeDialogEvent = new EventEmitter<any>();

  public titlebarTitle = "Confirmation";
  public titlebarCssClass = "modal-title-red";
  public dlgWidth: number = 600;

  public okText!: boolean;
  public isDisabled = false;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: v2DlgInputArgs
  ) {}

  ngOnInit(): void {
    if (this.inputData) {
      this.opened = this.inputData.opened;
      this.dlgWidth = this.inputData.dialogWidth;
      this.data = this.inputData.data;
      this.message = this.inputData.message;
    }

    this.okText = this.data?.okText != undefined ? this.data.okText : "OK";
  }

  public close(status: any) {
    this.opened = false;

    let savParamters: dlgModelArgs = {
      status: status,
      data: this.data,
    };
    this.closeDialogEvent.emit(savParamters);
    this.dialogRef?.close(savParamters);
  }

  public open() {
    this.opened = true;
  }
}

export interface v2DlgInputArgs {
  opened: boolean;
  dialogWidth: number;
  data: any;
  message: any;
}

export interface dlgModelArgs {
  status: string;
  data: any; //ProposalEntity[]
}
