import { AbstractGooglePlaces } from "../google-places/abstract-google-places";
import { DbsGooglePlaces } from "../google-places/dbs-google-places";
import { AbstractDocType } from "../doc-type/abstract-doc-type";
import { DbsDocType } from "../doc-type/dbs-doc-type";
import { AbstractProductFactory } from "./abstract-product-factory";
import { AbstractButtonPanel } from "../button-panel/abstract-button-panel";
import { DbsButtonPanel } from "../button-panel/dbs-button-panel";
import { AbstractProposal } from "../proposal/abstract-proposal";
import { DbsProposal } from "../proposal/dbs-proposal";
import { AbstractMaster } from "../master/abstract-master";
import { DbsMaster } from "../master/dbs-master";
import { AbstractPremiumSummary } from "../premium-summary/abstract-premium-summary";
import { DbsPremiumSummary } from "../premium-summary/dbs-premium-summary";

export class DbsProductFactory implements AbstractProductFactory {
  docTypeList(): AbstractDocType {
    return new DbsDocType();
  }

  googlePlaces(): AbstractGooglePlaces {
    return new DbsGooglePlaces();
  }

  buttonPanel(): AbstractButtonPanel {
    return new DbsButtonPanel();
  }

  master(): AbstractMaster {
    return new DbsMaster();
  }

  premiumSummary(): AbstractPremiumSummary {
    return new DbsPremiumSummary();
  }

  proposal(): AbstractProposal {
    return new DbsProposal();
  }
}
