import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  ApiModel,
  SelectedProducts,
  SelectedRoles,
  UserDetailsModel,
  UserProfileModel,
} from "../../user-profile.model";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { UserProfileService } from "src/app/services/user-profile.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { common } from "src/app/framework/utils/common";
import * as constants from "../../user-profile.constant";

@Component({
  selector: "app-edit-user-profile",
  templateUrl: "./edit-user-profile.component.html",
  styleUrls: ["./edit-user-profile.component.scss"],
})
export class EditUserProfileComponent implements OnInit {
  isDataLoaded: boolean = false;
  @Input() data!: any;
  @Input() accessControlList!: any;
  @Input() teamsData!: any;
  model = new ApiModel();
  userDetails = new UserDetailsModel();
  isUserAvailable = "";
  isUserExists = false;
  userLogin!: string;
  title!: string;
  @ViewChild("userForm", { static: false })
  private userForm!: UntypedFormGroup;
  public productData = new Array<SelectedProducts>();
  public roleData = new Array<SelectedRoles>();
  constructor(
    private userProfileService: UserProfileService,
    public identityRolesService: IdentityRolesService
  ) {}
  ngOnInit(): void {
    if (this.identityRolesService.isThailandUser) this.title = "System";
    else this.title = "Products";
    this.getUserProfile();
  }
  public getUserProfile(): void {
    if (common.isDefined(this.data?.userId) && this.data?.userId != "") {
      this.isDataLoaded = true;
      this.userProfileService
        .searchUserProfileByUserId(this.data.userId)
        .subscribe((response) => {
          if (response && response.body) {
            this.model = response.body;
            this.userDetails = this.model.userProfile.userDetails;
            this.userLogin = this.userDetails.userName;
            this.userDetails.userTeam = this.userDetails.teamId;
            this.loadCountry();
            if (this.model.userType != 1)
              this.getProductsByOrganisationAndCountry();
            else this.model.userTypeDesc = "System Maintenance";
            this.isDataLoaded = false;
          }
        });
    }
  }
  saveData(): UserProfileModel {
    this.userDetails.productsByOrganisation = [];
    this.userDetails.productIds?.forEach((pid) => {
      this.userDetails.productsByOrganisation.push(
        this.productData.filter((x) => x.value === pid)[0]
      );
    });
    //
    this.userDetails.roles = [];
    this.userDetails.roleIds?.forEach((roleid) => {
      this.userDetails.roles.push(
        this.roleData.filter((x) => x.value == roleid.toString())[0]
      );
    });
    this.model.userProfile.subUser = { mode: "edit" };
    return this.model.userProfile;
  }
  getProductsByOrganisationAndCountry(): void {
    this.userProfileService
      .getProductsByOrganisationAndCountry(
        this.userDetails.userOrganisation,
        this.userDetails.userCountry
      )
      .subscribe((result: any) => {
        result.items.forEach((item: any) => {
          let selectedProduct: SelectedProducts = {
            text: item.o,
            value: item.i,
          };
          this.productData.push(selectedProduct);
        });
        this.setSelectedProduct();
      });
  }
  getRoles(): void {
    this.userProfileService
      .getRoles(this.userDetails.productIds, this.model.userType)
      .subscribe((result: any) => {
        result.items.forEach((item: any) => {
          let role: SelectedRoles = {
            text: item.o,
            value: item.i,
          };
          this.roleData.push(role);
        });
        this.setSelectedroles();
      });
  }
  setSelectedProduct(): void {
    this.userDetails.productIds = [];
    this.userDetails.productsByOrganisation.forEach((item) => {
      this.userDetails.productIds.push(item.value);
    });
    this.getRoles();
  }
  setSelectedroles(): void {
    this.userDetails.roleIds = [];
    this.userDetails.roles.forEach((item) => {
      this.userDetails.roleIds.push(parseInt(item.value));
    });
  }
  getProductById(pid: any): void {}
  onSubmit(): void {}
  checkUser(): void {
    this.isUserAvailable = "";
    //By default userlogin will not be allowed to update. But Super Admin can update the UserLogin. So below check is for updating the userlogin by superadmin
    if (this.userLogin === this.userDetails.userName) {
      this.isUserAvailable = "";
      this.isUserExists = false;
      return;
    }
    //DBS Admin Screen Enable Changes
    if (!this.userDetails.userName) {
      this.isUserAvailable = "Username Empty";
    } else {
      this.userProfileService
        .checkUserLogin(this.userDetails.userName)
        .subscribe(
          (model: any) => {
            if (model.body.isAvailable == true) {
              this.isUserAvailable = "";
              this.isUserExists = false;
            } else {
              this.isUserAvailable = "Username Not Available";
              this.isUserExists = true;
            }
          },
          function (err: any) {
            console.log(err);
          }
        );
    }
  }
  onKeypressEvent(event: any) {
    if (event.charCode === 32) event.preventDefault();
  }
  //Load country based on the selected usertype
  private loadCountry(): void {
    constants.countryData.forEach((item) => {
      if (this.userDetails.userCountry === item.value) {
        this.userDetails.displayUserCountry = item.text;
        this.userDetails.userCountry = item.value;
      }
    });
  }
  public validateFormData(): boolean {
    return this.userForm.valid;
  }
  //this is to toggle disabled field based on active field
  public toggleDisabled(): void {
    if (this.userDetails.active) {
      this.userDetails.disabled = !this.userDetails.active;
    }
  }
}
