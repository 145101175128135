import * as EnumHK from "./mf-hk-enums";
import * as Enums from "./../../../../../../framework/enum.shared";
import * as MfEnum from "./../../hong-kong/base/mf-hk-enums";
import {
  ProductID,
  UserRoles,
} from "./../../../../../../framework/const.shared";
import { TransType, UserType } from "./../../../../../../framework/enum.shared";

import { ExceptionManager } from "./../../../../../../framework/utils/exception-manager";
import { GUID } from "./../../../../../../framework/domain-entity/guid";
import { Global } from "../../../../../../shared/global";
import { Identity } from "../../../../../../shared/user/identity";
import { MortgageFireHongKongClientCreateQuestionSection } from "./questions/mf-hk-client-create-question-section";
import { MortgageFireHongKongMasterQuestionSection } from "./questions/mf-hk-master-question-section";
import { MortgageFireHongKongPremiumSummaryQuestionSection } from "./questions/mf-hk-premium-summary-question-section";
import { MortgageFireHongKongProposalLocation } from "./mf-hk-proposal-location";
import { Proposal } from "../../../../base/proposal.model";
import { ProposalEntity } from "./../../../../base/proposal-entity.model";
import { ProposalPackage } from "./../../../../base/proposal-package-model";
import { QuestionEntity } from "./../../../../base/question-entity.model";
import { common } from "./../../../../../../framework/utils/common";
import { mortgageFireHongKongHelper } from "../utils/mf-hk-helper";
import { proposalEntityFactory } from "./../../../../factory/proposal-entity.factory";
import { proposalHelper } from "./../../../../utils/proposal.helper";
import { IdentityRolesService } from "../../../../../../services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { ClausesandDeductibles } from "src/app/models/new-product/base/Clauses-Deductibles.model";

export class MortgageFireHongKongProposal extends ProposalPackage<MortgageFireHongKongProposalLocation> {
  /** cmwanx: 2017-11-7
   * Mortgage Fire was originally designed to cater multiple locations.
   *
   * Later JK decided it will always be single location.
   * Although front end is now only capturing for single location,
   * but [Proposal] class still remains its multi location design to extends from [ProposalPackage]
   */

  qsMaster!: MortgageFireHongKongMasterQuestionSection;
  qsPremiumSummary!: MortgageFireHongKongPremiumSummaryQuestionSection;
  qsClientCreate!: MortgageFireHongKongClientCreateQuestionSection;
  productType: MfEnum.MortgageFireHKProduct;

  messageId!: number;
  printType?: string;
  printCorrespondence?: string;
  isReprint?: boolean;
  isSummary?: boolean;
  isManual?: boolean;
  isPasswordProtected?: boolean;
  IsCancellationAdjustment!: boolean;

  /** ProposalEntity that last used for refreshing questions */
  refreshedProposalEntity!: ProposalEntity;
  // TODO: other sections question implementation

  /**
   * productID
   * proposalEntity <= will be passing in while proposalApi retrieved object from back end
   */
  constructor(
    productId: GUID,
    public override identityRolesService: IdentityRolesService,
    public masterDataService: MasterDataService
  ) {
    super(productId, identityRolesService);

    this.productType = mortgageFireHongKongHelper.readProductType(
      this.productId
    )!;

    this.initialize();
  }

  initialize() {
    // initialize sections
    this.qsMaster = new MortgageFireHongKongMasterQuestionSection(
      this.masterDataService
    );
    this.qsPremiumSummary =
      new MortgageFireHongKongPremiumSummaryQuestionSection(
        this.masterDataService
      );
    this.qsClientCreate = new MortgageFireHongKongClientCreateQuestionSection(
      this.masterDataService
    );

    this.children = [];
  }

  refreshQuestionsReadOnly(proposalEntity: ProposalEntity) {
    const questions = this.extractQuestionArray();

    questions.forEach((question) => {
      const q = proposalEntity.getQuestion(question.key);
      if (q && common.isDefined(q)) {
        if (
          this.transType === Enums.TransType.CAN ||
          this.endorsementType === Enums.EndorsementType.LoanRedemption ||
          this.endorsementType === Enums.EndorsementType.ChangeOfPOI ||
          this.endorsementType === Enums.EndorsementType.ChangeOfSumInsured ||
          this.endorsementType ===
            Enums.EndorsementType.ChangeOfCorrespondenceAddress ||
          this.endorsementType === Enums.EndorsementType.ChangeOfClientInfo ||
          this.endorsementType ===
            Enums.EndorsementType.ChangeOfAdditionalInsured ||
          this.endorsementType === Enums.EndorsementType.GstAdjustment ||
          this.endorsementType === Enums.EndorsementType.ChangeOfPlanType
        ) {
          question.readOnly = true;
        } else {
          question.readOnly = q.readOnly;
        }
      }
    });
  }

  refreshQuestionsReadOnlyForcbsOps(proposalEntity: ProposalEntity) {
    const isIBGProducts = false;
    // this.productType === EnumSG.MortgageFireSgProduct.IBGMAC ||
    // this.productType === EnumSG.MortgageFireSgProduct.IBGMF;

    const isIBGOPS =
      this.identityRolesService.IsCbsOps &&
      isIBGProducts &&
      this.transType === Enums.TransType.END &&
      this.endorsementType === Enums.EndorsementType.GeneralEndorsement &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete;

    const isCBGOPS =
      this.identityRolesService.IsCbsOps &&
      this.transType === Enums.TransType.END &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete;

    if (isIBGOPS || isCBGOPS) {
      const questions = this.extractQuestionArray();
      questions.forEach((question) => {
        const q = proposalEntity.getQuestion(question.key);
        if (common.isDefined(q)) {
          question.readOnly = true;
        }
      });
    }
  }

  refreshCustomQuestionsReadOnly = (proposalEntity: ProposalEntity) => {
    if (this.productType === EnumHK.MortgageFireHKProduct.HSCMB) {
      this.qsPremiumSummary.qs_master_commission.readOnly = true; //CMB only, always
    }

    if (this.productType === EnumHK.MortgageFireHKProduct.HSWPB) {
      this.qsPremiumSummary.qs_master_techRate.readOnly = true; //WPB only, always
    }

    //DBSEP-7022
    this.qsMaster.qs_fire_currency!.readOnly = true;
    this.qsMaster.qs_fire_currency!.answer = "HKD";

    let isNB = this.whenNewBussiness();
    if (!isNB) {
      this.qsMaster.qs_master_endorse_endorsementReason!.required = true; //as long as not NB, remarks is needed
      this.qsMaster.qs_fire_coverageValueType!.readOnly = true; //as long as not NB, it cannot be edited
      this.qsMaster.qs_master_manualUnderwritting!.readOnly = true; //as long as not NB, it cannot be edited

      this.qsMaster.qs_risk_insuredAddress1!.readOnly = true; //when END, readonly
      this.qsMaster.qs_risk_insuredAddress2!.readOnly = true;
      this.qsMaster.qs_risk_insuredAddress3!.readOnly = true; //when GeneralEdit, readonly
      this.qsMaster.qs_risk_insuredDistCode!.readOnly = true; //when GeneralEdit, readonly
      this.qsMaster.qs_risk_carParkAddress1!.readOnly = true; //when GeneralEdit, readonly
      this.qsMaster.qs_risk_carParkAddress2!.readOnly = true; //when GeneralEdit, readonly
      this.qsMaster.qs_risk_carParkAddress3!.readOnly = true; //when GeneralEdit, readonly
      this.qsMaster.qs_risk_carParkAddress4!.readOnly = true; //when GeneralEdit, readonly
      this.qsMaster.qs_fire_grossFloorArea!.readOnly = true; //readonly for the rest, when GeneralEdit do in its func
      //this.qsMaster.qs_fire_currency!.readOnly = true; //readonly for the rest, when GeneralEdit do in its func //DBSEP-7022
      this.qsMaster.qs_fire_loanAmount!.readOnly = true; //readonly for the rest, when GeneralEdit do in its func

      //reset when newly bound from in-complete to accepted; add other proposalStatus if needed
      if (this.productType === EnumHK.MortgageFireHKProduct.HSCMB) {
        this.qsMaster.qs_risk_occupancy!.readOnly = true; //readonly for the rest, when GeneralEdit do in its func
        this.qsMaster.qs_risk_construction!.readOnly = true; //readonly for the rest, when GeneralEdit do in its func
      }
    }

    this.qsMaster.qs_risk_insuredPostalCode!.readOnly = true;
    this.qsMaster.qs_risk_insuredCity!.readOnly = true;
    this.qsMaster.qs_risk_insuredCountry!.readOnly = true;
    //this.qsMaster.qs_master_poi_endDate!.readOnly = false;
    // this.qsMaster.qs_master_insuredNo.readOnly = true;
    this.qsMaster.qs_fire_householdContents!.readOnly = true;
    this.qsMaster.qs_master_insuredAddress!.readOnly = true;
    this.qsMaster.qs_risk_uploadRiskAddress!.readOnly = true;
    this.qsMaster.qs_master_insuredCountry!.readOnly = true;
    this.qsPremiumSummary.qs_master_brokerCommission.required = false;
    this.qsMaster.qs_master_insuredAddress1!.readOnly = true;
    this.qsMaster.qs_master_insuredAddress2!.readOnly = true;
    this.qsMaster.qs_master_insuredPostalCode!.readOnly = true;
    this.qsMaster.qs_master_insuredCity!.readOnly = true;
    this.qsMaster.qs_master_insuredState!.readOnly = true;
    this.qsMaster.qs_master_insuredCountry!.readOnly = true;
    if (this.transType === Enums.TransType.END) {
      this.qsMaster.qs_master_planType!.readOnly = true;
      this.qsMaster.qs_master_premiumPayment!.readOnly = true; //when GeneralEdit, editable
    }
    //HE-2236: Set Discount ReadOnly
    this.checkDiscountEligibility('');

    this.qsMaster.qs_otherinfo_VersionDate!.readOnly = true; //always, regardless of proposalStatus;
    this.qsMaster.qs_otherinfo_AutoIssue!.readOnly = true; //always, regardless of proposalStatus;
    this.qsMaster.qs_master_masterPolicyNumber!.readOnly = true; //always, regardless of proposalStatus; read from propertyType 'qs_fire_occupied'

    //if any of the field aside country is selected then can bind
    //this.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly = true;
    this.qsMaster.qs_risk_insuredAddress_building!.required = false;
    this.qsMaster.qs_risk_insuredAddress1!.required = false;
    this.qsMaster.qs_risk_insuredAddress2!.required = false;
    this.qsMaster.qs_risk_insuredAddress3!.required = false;

    switch (this.productType) {
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        this.qsMaster.qs_fire_notes!.required = false;
        // HDB don't have Fire Replacement Value Question. So setting min value as 0.
        this.qsMaster.qs_fire_replacement!.minValue = 0;
        this.qsMaster.qs_fire_building!.minValue = 1;
        this.qsMaster.qs_master_insuredNo!.readOnly = true;
        break;
      default:
        break;
    }

    // assign value for CBG product only
    switch (this.productType) {
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        if (this.endorsementType != Enums.EndorsementType.ChangeOfPlanType) {
          this.qsMaster.qs_master_planType!.readOnly = true;
        }

        break;
      default:
        break;
    }

    if (
      (this.transType === Enums.TransType.CAN ||
        this.endorsementType === Enums.EndorsementType.LoanRedemption ||
        this.endorsementType === Enums.EndorsementType.ChangeOfPOI ||
        this.endorsementType ===
          Enums.EndorsementType.ChangeOfCorrespondenceAddress ||
        this.endorsementType === Enums.EndorsementType.ChangeOfClientInfo ||
        this.endorsementType === Enums.EndorsementType.ChangeOfSumInsured ||
        this.endorsementType ===
          Enums.EndorsementType.ChangeOfAdditionalInsured ||
        this.endorsementType === Enums.EndorsementType.GstAdjustment) &&
      ((this.proposalStatus === Enums.ProposalStatus.Incomplete &&
        //(this.identityRolesService.isMaker || this.isUnderwriter)//change for Hase
        this.identityRolesService.getIdentity().role.IsHASEUser) ||
        (this.proposalStatus === Enums.ProposalStatus.Referred &&
          !this.identityRolesService.getIdentity().role.isOperationUnderwriter))
    ) {
      this.qsMaster.qs_master_effectiveDate!.readOnly = false;
      this.qsMaster.qs_master_endorse_endorsementReason!.readOnly = false;
      this.qsMaster.qs_master_cancellation_adjustmentAmount!.readOnly = false;
      this.qsMaster.qs_fire_occupied!.readOnly = true;
      this.qsMaster.qs_master_refund!.readOnly = false;
      if (
        this.transType === Enums.TransType.CAN &&
        ((this.proposalStatus === Enums.ProposalStatus.Incomplete &&
          (this.identityRolesService.isMaker || this.isUnderwriter)) ||
          this.proposalStatus === Enums.ProposalStatus.Referred)
      ) {
        this.qsMaster.qs_master_endorse_endorsementReason!.required = true;
        this.qsMaster.qs_master_cancellation_masterpiece!.readOnly = false;
      }
      if (
        this.endorsementType === Enums.EndorsementType.ChangeOfPOI &&
        ((this.proposalStatus === Enums.ProposalStatus.Incomplete &&
          (this.identityRolesService.isMaker || this.isUnderwriter)) ||
          this.proposalStatus === Enums.ProposalStatus.Referred)
      ) {
        this.qsMaster.qs_master_effectiveDate!.readOnly = true;
        this.qsMaster.qs_master_poi_startDate!.readOnly = false;
      }

      if (
        this.proposalStatus === Enums.ProposalStatus.Referred &&
        this.isBroker
      ) {
        this.qsMaster.qs_master_effectiveDate!.readOnly = true;
        this.qsMaster.qs_master_endorse_endorsementReason!.readOnly = true;
        this.qsMaster.qs_master_cancellation_masterpiece!.readOnly = true;
        this.qsMaster.qs_master_poi_startDate!.readOnly = true;
        this.qsMaster.qs_master_refund!.readOnly = true;
        this.qsMaster.qs_fire_interestedParties!.readOnly = true;
      }
      if (this.endorsementType === Enums.EndorsementType.LoanRedemption  &&
        ((this.proposalStatus === Enums.ProposalStatus.Incomplete &&
          this.identityRolesService.getIdentity().role.IsHASEUser) ||
          (this.proposalStatus === Enums.ProposalStatus.Referred &&
            !this.identityRolesService.getIdentity().role.isOperationUnderwriter))
      ) {
        this.qsMaster.qs_otherinfo_MortgLoanAcctNum2!.readOnly = false;
        this.qsMaster.qs_otherinfo_MortgLoanAcctNum3!.readOnly = false;
      }
    }

    if (
      this.refreshedProposalEntity.proposalReadOnly ||
      this.transType === Enums.TransType.CAN ||
      this.endorsementType === Enums.EndorsementType.LoanRedemption
    ) {
      this.qsMaster.qs_master_insuredAddress_building!.readOnly = true;
      this.qsMaster.qs_master_insuredAddress_lotunit!.readOnly = true;
    }

    this.qsMaster.qs_master_poi_endDate!.readOnly = true; //always, regarding any proposal status

    this.whenGeneralEndorsement();
    this.whenChangeOfSumInsured();
    this.whenChangeOfPlanType();
    this.whenRenewal();

    // If User role is isClaimant or ProductReadOnly, all the questions should be readOnly
    if (
      this.identityRolesService.isClaimant ||
      this.identityRolesService.isProductReadOnly
    ) {
      const questions = this.extractQuestionArray();
      questions.forEach((question) => {
        const q = proposalEntity.getQuestion(question.key);
        if (common.isDefined(q)) {
          question.readOnly = true;
        }
      });
    }

    this.whenChangeOfCorrespondenceAddress();
    this.whenChangeOfClientInfo();

    if (
      this.identityRolesService.isOperationUnderwriter &&
      this.transType === Enums.TransType.CAN &&
      this.endorsementType ===
        Enums.EndorsementType.ChangeOfCorrespondenceAddress &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete
    ) {
      this.qsMaster.qs_master_insuredAddress_lotunit!.readOnly = false;
      this.qsMaster.qs_master_insuredAddress_building!.readOnly = false;
      this.qsMaster.qs_master_endorse_endorsementReason!.readOnly = false;
      this.qsMaster.qs_master_effectiveDate!.readOnly = true;
      this.qsMaster.qs_master_refund!.readOnly = true;
    }

    let isCanAdj = this.whenCancellationAdjustment();
    if (!isCanAdj) {
      // Visibility Check
      this.qsMaster.qs_master_cancellation_adjustmentAmount!.isVisible = false;
      this.qsMaster.qs_master_cancellation_maximumAdjustmentAmount!.isVisible =
        false;

      this.qsMaster.qs_master_cancellation_adjustmentAmount!.required = false;
      this.qsMaster.qs_master_cancellation_maximumAdjustmentAmount!.required =
        false;
    }

    // Waive Minimum Premium Checkbox
    // Visibility Check
    if (
      this.transType === Enums.TransType.CAN &&
      this.auxiliary["DisplayMinimumPremiumWaived"]
    ) {
      this.qsMaster.qs_master_waiveMinimumPremium!.isVisible = true;

      // Disable Check
      if (this.proposalStatus === Enums.ProposalStatus.Incomplete) {
        if (!this.isUnderwriter) {
          this.qsMaster.qs_master_waiveMinimumPremium!.isDisabled = true;
          this.qsMaster.qs_master_waiveMinimumPremium!.isVisible = false;
        } else {
          this.qsMaster.qs_master_waiveMinimumPremium!.isDisabled =
            this.auxiliary["FirstCancellationWaiveMinimumPremium"];
          this.qsMaster.qs_master_waiveMinimumPremium!.isVisible = true;
        }
      } else {
        /* for accepted scenario */
        if (!this.isUnderwriter) {
          this.qsMaster.qs_master_waiveMinimumPremium!.isVisible = false;
        } else {
          this.qsMaster.qs_master_waiveMinimumPremium!.isVisible = true;
        }

        this.qsMaster.qs_master_waiveMinimumPremium!.isDisabled = true;
      }
    } else {
      // Visibility Check
      this.qsMaster.qs_master_waiveMinimumPremium!.isVisible = false;
    }

    this.refreshCustomQuestionsReadOnlyForDeliveryMethod(proposalEntity);
  };

  //#region proposal status
  whenNewBussiness(): boolean {
    if (
      this.transType === Enums.TransType.NEW &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete
    ) {
      this.qsMaster.qs_risk_insuredAddress1!.readOnly = false; //only NB can edit
      this.qsMaster.qs_risk_insuredAddress2!.readOnly = false;

      //this.qsMaster.qs_risk_carParkAddress1!.required = true; //default carParkAddress1 to required //no more
      this.qsPremiumSummary.qs_master_techRate.readOnly = false;

      if (this.productType === EnumHK.MortgageFireHKProduct.HSWPB)
        this.qsPremiumSummary.qs_master_techRate.readOnly = true;

      const isCMBProducts =
        this.productType === EnumHK.MortgageFireHKProduct.HSCMB;
      const isWPBroducts =
        this.productType === EnumHK.MortgageFireHKProduct.HSWPB;
      //HE-28: 4- Commission: by default, is 40%, should be editable in both Manual and Non-Manual Underwriting.
      if (isCMBProducts || (isWPBroducts && this.isUnderwriter)) {
        this.qsPremiumSummary.qs_master_brokerCommission.readOnly = false;
      }

      //check if not defined, then only assign default
      if (
        common.isUndefinedOrNull(this.qsMaster.qs_risk_construction!.answer) ||
        this.qsMaster.qs_risk_construction!.answer === ""
      ) {
        this.qsMaster.qs_risk_construction!.answer = "Class I"; //default
      }
      return true;
    } else if (
      this.transType === Enums.TransType.NEW &&
      this.proposalStatus === Enums.ProposalStatus.Referred
    ) {
      const isWPBroducts =
        this.productType === EnumHK.MortgageFireHKProduct.HSWPB; //HE-28
      if (isWPBroducts && this.isUnderwriter) {
        //HE-28: 4- Commission: by default, is 40%, should be editable in both Manual and Non-Manual Underwriting.
        this.qsPremiumSummary.qs_master_brokerCommission.readOnly = false;

        this.qsMaster.qs_risk_carParkAddress1!.readOnly = false;
        this.qsMaster.qs_fire_grossFloorArea!.readOnly = false;
        this.qsMaster.qs_fire_loanAmount!.readOnly = false;
      }
      return true;
    }

    return false;
  }

  whenGeneralEndorsement() {
    if (
      this.endorsementType === Enums.EndorsementType.GeneralEndorsement &&
      this.transType === Enums.TransType.END &&
      ((this.proposalStatus === Enums.ProposalStatus.Incomplete &&
        this.identityRolesService.getIdentity().role.IsHASEUser) ||
        (this.proposalStatus === Enums.ProposalStatus.Referred &&
          !this.identityRolesService.getIdentity().role.IsHASEUser))
    ) {
      this.qsMaster.qs_master_premiumPayment!.readOnly = false; //when GeneralEdit, editable
      this.qsMaster.qs_fire_interestedParties!.readOnly = false; //when GeneralEdit, editable
      if (this.productType === EnumHK.MortgageFireHKProduct.HSCMB) {
        //this.qsPremiumSummary.qs_master_commission.readOnly = false; //CMB only when GeneralEdit, editable
      }
      //this.qsMaster.qs_master_endorse_endorsementReason!.required = false;
      this.qsMaster.qs_risk_insuredAddress_building!.readOnly = true;
      this.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly = true;
      this.qsMaster.qs_fire_occupied!.readOnly = true;
      this.qsMaster.qs_fire_replacement!.readOnly = true;
      this.qsMaster.qs_fire_building!.readOnly = true;
      this.qsMaster.qs_master_manualUnderwritting!.readOnly = true;

      this.qsMaster.qs_fire_loanAmount!.readOnly = false; //all product when GeneralEdit, editable
      this.qsMaster.qs_fire_replacement!.readOnly = false; //all product when GeneralEdit, editable
      //this.qsMaster.qs_fire_currency!.readOnly = false; //all product when GeneralEdit, editable //DBSEP-7022
      this.qsMaster.qs_fire_grossFloorArea!.readOnly = false; //all product when GeneralEdit, editable
      this.qsMaster.qs_risk_occupancy!.readOnly = false; //all product when GeneralEdit, editable
      this.qsMaster.qs_risk_construction!.readOnly = false; //all product when GeneralEdit, editable
    }
  }

  whenChangeOfSumInsured() {
    if (
      this.endorsementType === Enums.EndorsementType.ChangeOfSumInsured &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete &&
      this.identityRolesService.IsHASEUser
    ) {
      switch (this.productType) {
        case EnumHK.MortgageFireHKProduct.HSCMB:
        case EnumHK.MortgageFireHKProduct.HSWPB:
          this.qsMaster.qs_fire_occupied!.readOnly = true;
          this.qsMaster.qs_fire_occupied!.required = true;
          this.qsMaster.qs_fire_building!.readOnly = false; //follow EnumSG.MortgageFireSgProduct.PTE when END
          this.qsMaster.qs_fire_building!.required = true;
          break;
        default:
          break;
      }
    } else if (
      this.endorsementType === Enums.EndorsementType.ChangeOfSumInsured &&
      this.proposalStatus === Enums.ProposalStatus.Referred
    ) {
      const isWPBroducts =
        this.productType === EnumHK.MortgageFireHKProduct.HSWPB; //HE-28
      if (isWPBroducts) {
        if (this.isBroker) {
          this.qsMaster.qs_fire_occupied!.readOnly = true;
          this.qsMaster.qs_fire_replacement!.readOnly = true;
          this.qsMaster.qs_fire_building!.readOnly = true;
          this.qsMaster.qs_master_manualUnderwritting!.readOnly = true;
        } else if (this.isUnderwriter) {
          this.qsMaster.qs_fire_building!.readOnly = false; //HE-28
          this.qsMaster.qs_fire_building!.required = true;
        }
      }
    }
  }

  whenRenewal() {
    if (
      this.transType === Enums.TransType.REN &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete &&
      this.identityRolesService.IsHASEUser
    ) {
      switch (this.productType) {
        case EnumHK.MortgageFireHKProduct.HSCMB:
        case EnumHK.MortgageFireHKProduct.HSWPB:
          this.qsMaster.qs_master_poi_startDate!.readOnly = true;
          this.qsMaster.qs_master_manualUnderwritting!.readOnly = false;
          this.qsMaster.qs_fire_occupied!.readOnly = true;
          this.qsMaster.qs_fire_occupied!.required = true;
          this.qsMaster.qs_fire_building!.readOnly = false; //follow EnumSG.MortgageFireSgProduct.PTE when END
          this.qsMaster.qs_fire_building!.required = true;
          this.qsMaster.qs_risk_insuredAddress_building!.readOnly = true;
          this.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly = true;

          this.qsMaster.qs_fire_loanAmount!.readOnly = false; //all product when GeneralEdit, editable
          this.qsMaster.qs_fire_replacement!.readOnly = false; //all product when GeneralEdit, editable
          this.qsMaster.qs_fire_grossFloorArea!.readOnly = false; //all product when GeneralEdit, editable
          this.qsMaster.qs_risk_occupancy!.readOnly = false; //all product when GeneralEdit, editable
          this.qsMaster.qs_risk_construction!.readOnly = false; //all product when GeneralEdit, editable

          break;
        default:
          break;
      }
      if (this.productType == EnumHK.MortgageFireHKProduct.HSCMB) {
        //Broker commission should be editable for renewal
        this.qsPremiumSummary.qs_master_brokerCommission.readOnly = false;
      }
    } else if (this.proposalStatus === Enums.ProposalStatus.Referred) {
      const isWPBroducts =
        this.productType === EnumHK.MortgageFireHKProduct.HSWPB;
      if (isWPBroducts) {
        if (this.isBroker) {
          this.qsMaster.qs_fire_occupied!.readOnly = true;
          this.qsMaster.qs_fire_replacement!.readOnly = true;
          this.qsMaster.qs_fire_building!.readOnly = true;
          this.qsMaster.qs_master_manualUnderwritting!.readOnly = true;
        } else if (this.isUnderwriter) {
          this.qsMaster.qs_fire_building!.readOnly = false;
          this.qsMaster.qs_fire_building!.required = true;
        }
      }
    }
  }

  whenChangeOfPlanType() {
    //2022-08-25 dagoh0: APACDM-363 - Handle fields required for change of plan type
    if (
      this.endorsementType === Enums.EndorsementType.ChangeOfPlanType &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete &&
      (this.isUnderwriter ||
        this.identityRolesService.getIdentity().role.IsCbsOps ||
        this.identityRolesService.getIdentity().role.isOperationUnderwriter)
    ) {
      switch (this.productType) {
        case EnumHK.MortgageFireHKProduct.HSCMB:
        case EnumHK.MortgageFireHKProduct.HSWPB:
          // case EnumSG.MortgageFireSgProduct.HDB:
          // case EnumSG.MortgageFireSgProduct.PTE:
          // case EnumSG.MortgageFireSgProduct.MIP:
          this.qsMaster.qs_fire_occupied!.readOnly = true;
          this.qsMaster.qs_master_endorse_endorsementReason!.readOnly = false;
          this.qsMaster.qs_master_refund!.readOnly = false;
          this.qsMaster.qs_master_planType!.readOnly = false;
          break;
        default:
          break;
      }
    }
  }

  whenChangeOfCorrespondenceAddress() {
    // Enable Client Address and info endorsement for CBSOPS user
    const isChangeOfAddressOrClientInfo =
      this.endorsementType ===
        Enums.EndorsementType.ChangeOfCorrespondenceAddress ||
      this.endorsementType === Enums.EndorsementType.ChangeOfClientInfo;

    if (
      this.identityRolesService.IsCbsOps &&
      this.transType === Enums.TransType.END &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete &&
      isChangeOfAddressOrClientInfo
    ) {
      this.qsMaster.qs_master_effectiveDate!.readOnly = false;
      this.qsMaster.qs_master_endorse_endorsementReason!.readOnly = false;
      this.qsMaster.qs_master_endorse_endorsementReason!.required = true;
      this.qsMaster.qs_master_refund!.readOnly = false;
    }
  }

  whenChangeOfClientInfo() {
    //Enabled client info endorsement for CS CBS SG User
    if (
      this.identityRolesService.IsCSCBGSG &&
      this.endorsementType === Enums.EndorsementType.ChangeOfClientInfo &&
      this.transType === Enums.TransType.END &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete
    ) {
      this.qsMaster.qs_master_effectiveDate!.readOnly = true;
      this.qsMaster.qs_master_endorse_endorsementReason!.readOnly = false;
      this.qsMaster.qs_master_endorse_endorsementReason!.required = true;
      this.qsMaster.qs_master_refund!.readOnly = false;
    }
  }

  whenCancellationAdjustment(): boolean {
    // Cancellation adjustmentAmount and maximum adjust amount
    // Visibility Check
    if (
      this.transType === Enums.TransType.CAN &&
      this.endorsementType === Enums.EndorsementType.CancellationAdjustment
    ) {
      this.qsMaster.qs_master_cancellation_adjustmentAmount!.isVisible = true;
      this.qsMaster.qs_master_cancellation_maximumAdjustmentAmount!.isVisible =
        true;

      // Readonly Check
      if (
        this.proposalStatus === Enums.ProposalStatus.Incomplete &&
        this.isUnderwriter
      ) {
        // can edit only after incomplete and underwriter
        this.qsMaster.qs_master_cancellation_adjustmentAmount!.readOnly = false;
        this.qsMaster.qs_master_cancellation_maximumAdjustmentAmount!.readOnly =
          false;

        this.qsMaster.qs_master_cancellation_adjustmentAmount!.required = true;
      } else {
        this.qsMaster.qs_master_cancellation_adjustmentAmount!.readOnly = true;
        this.qsMaster.qs_master_cancellation_maximumAdjustmentAmount!.readOnly =
          true;

        this.qsMaster.qs_master_cancellation_adjustmentAmount!.required = false;
      }

      // Updating Min and Max value of adjustment amount depend on Maximum Adjustment amount
      var maximumAdjustmentAmount =
        this.qsMaster.qs_master_cancellation_maximumAdjustmentAmount;
      maximumAdjustmentAmount!.answer =
        maximumAdjustmentAmount!.answer === undefined ||
        isNaN(maximumAdjustmentAmount!.answer)
          ? 0
          : parseFloat(maximumAdjustmentAmount!.answer);

      var adjustmentAmount =
        this.qsMaster.qs_master_cancellation_adjustmentAmount;
      adjustmentAmount!.answer =
        adjustmentAmount!.answer === undefined ||
        isNaN(adjustmentAmount!.answer)
          ? 0
          : parseFloat(adjustmentAmount!.answer);

      return true;
    }

    return false;
  }
  //#endregion

  refreshCustomQuestionsReadOnlyForDeliveryMethod(
    proposalEntity: ProposalEntity
  ) {
    if (
      this.transType === Enums.TransType.END &&
      this.endorsementType === Enums.EndorsementType.GeneralEndorsement &&
      this.proposalStatus === Enums.ProposalStatus.Incomplete
    ) {
      switch (this.productType) {
        // case EnumSG.MortgageFireSgProduct.HDB:
        // case EnumSG.MortgageFireSgProduct.MIP:
        // case EnumSG.MortgageFireSgProduct.PTE:
        // case EnumSG.MortgageFireSgProduct.CTHDB:
        // case EnumSG.MortgageFireSgProduct.CTMFPTE:
        // case EnumSG.MortgageFireSgProduct.CTMFCONDO:
        case EnumHK.MortgageFireHKProduct.HSCMB:
        case EnumHK.MortgageFireHKProduct.HSWPB:
          this.qsMaster.qs_master_preferredDeliveryMethod!.readOnly = true;
          break;
        // case EnumSG.MortgageFireSgProduct.IBGMF:
        // case EnumSG.MortgageFireSgProduct.IBGMAC:
        //   // this.qsMaster.qs_master_preferredDeliveryMethod.readOnly = true;
        //   // this.qsMaster.qs_master_mobileNumber.readOnly=true;
        //   // this.qsMaster.qs_master_emailID.readOnly=true;
        //   break;
      }
    }
  }
  refreshPotentiallyChangedQuestions(proposalEntity: ProposalEntity) {
    ExceptionManager.argumentNullGuard(proposalEntity, "proposalEntity");
    ExceptionManager.argumentNullGuard(
      proposalEntity.questions,
      "proposalEntity.questions"
    );

    this.qsMaster.questions.forEach((question) => {
      const q = proposalEntity.getQuestion(question.key);
      if (q && common.isDefined(q)) {
        switch (question.key) {
          case "qs_master_effectiveDate":
            this.qsMaster.qs_master_effectiveDate!.answer = q.answer;
            break;
          case "qs_master_endorse_endorsementReason":
            this.qsMaster.qs_master_endorse_endorsementReason!.answer =
              q.answer;
            break;
          case "qs_master_refund":
            this.qsMaster.qs_master_refund!.answer = q.answer;
            break;
          case "qs_fire_occupied":
            this.qsMaster.qs_fire_occupied!.answer = q.answer;
            break;
          case "qs_master_manualUnderwritting":
            this.qsMaster.qs_master_manualUnderwritting!.answer = q.answer;
            break;
          case "qs_master_insuredCountry":
            if (
              this.qsMaster.qs_master_insuredCountry!.answer === null ||
              this.qsMaster.qs_master_insuredCountry!.answer === ""
            ) {
              this.qsMaster.qs_master_insuredCountry!.answer = "SG";
            }
            break;
          case "qs_fire_freeInsuranceFirstYear":
            // // IBG
            // if (
            //   this.productType === EnumSG.MortgageFireSgProduct.IBGMF ||
            //   this.productType === EnumSG.MortgageFireSgProduct.IBGMAC
            // ) {
            //   this.qsMaster.qs_fire_freeInsuranceFirstYear!.answer = q.answer;
            // }

            break;
          case "qs_fire_freeInsuranceNoYear":
            ////Chirag: 28-12-2018 Free Year Change For CBG Prodcut
            // IBG
            // if (this.productType === EnumSG.MortgageFireSgProduct.IBGMF
            //   || this.productType === EnumSG.MortgageFireSgProduct.IBGMAC) {
            this.qsMaster.qs_fire_freeInsuranceNoYear!.answer = q.answer;
            //}
            break;
          case "qs_master_masterPolicyNumber":
            // // PTE and MIP
            // if (
            //   this.productType === EnumSG.MortgageFireSgProduct.PTE ||
            //   this.productType === EnumSG.MortgageFireSgProduct.MIP
            // ) {
            //   this.qsMaster.qs_master_masterPolicyNumber!.answer = q.answer;
            // }

            break;
          case "qs_master_poi_startDate":
            if (this.transType === Enums.TransType.REN) {
              this.qsMaster.qs_master_poi_startDate!.answer = q.answer;
            }
            //HE-1811 : Refresh dates for copied policy
            if (
              this.transType === Enums.TransType.NEW &&
              this.proposalStatus === Enums.ProposalStatus.Incomplete
            ) {
              this.qsMaster.qs_master_poi_startDate!.answer = q.answer;
            }
            break;
          case "qs_master_poi_endDate":
            if (this.transType === Enums.TransType.REN) {
              this.qsMaster.qs_master_poi_endDate!.answer = q.answer;
            }
            //HE-1811 : Refresh dates for copied policy
            if (
              this.transType === Enums.TransType.NEW &&
              this.proposalStatus === Enums.ProposalStatus.Incomplete
            ) {
              this.qsMaster.qs_master_poi_endDate!.answer = q.answer;
            }
            break;
          case "qs_master_cancellation_maximumAdjustmentAmount":
            this.qsMaster.qs_master_cancellation_maximumAdjustmentAmount!.answer =
              q.answer;
            break;
          case "qs_master_lstDetails":
            this.qsMaster.qs_master_lstDetails!.answer = q.answer;
            break;
          case "qs_master_lst":
            this.qsMaster.qs_master_lst!.answer = q.answer;
            break;
          case "qs_master_planType":
            this.qsMaster.qs_master_planType!.answer = q.answer;
            break;
          case "qs_master_discountCode":
            this.qsMaster.qs_master_discountCode!.answer = q.answer;
            break;
          case "qs_master_customDiscountCode":
            this.qsMaster.qs_master_customDiscountCode!.answer = q.answer;
            break;
          default:
        }
      }
    });
    this.qsPremiumSummary.questions.forEach((question) => {
      const q = proposalEntity.getQuestion(question.key);
      if (common.isDefined(q)) {
        switch (question.key) {
          case "qs_master_brokerCommission":
            this.qsPremiumSummary.qs_master_brokerCommission.answer = q!.answer;
            break;
          case "qs_master_techRate":
            this.qsPremiumSummary.qs_master_techRate.answer = q!.answer;
            break;
          default:
        }
      }
    });
  }

  refreshQuestions(proposalEntity: ProposalEntity) {
    ExceptionManager.argumentNullGuard(proposalEntity, "proposalEntity");
    ExceptionManager.argumentNullGuard(
      proposalEntity.questions,
      "proposalEntity.questions"
    );

    this.refreshedProposalEntity = proposalEntity;
    this.proposalId = proposalEntity.proposalId;
    this.qsMaster.refreshQuestions(proposalEntity);
    this.qsPremiumSummary.refreshQuestions(proposalEntity);

    // it is always one children
    if (
      common.isDefined(proposalEntity.children) &&
      /* While creating new proposal, back end is returning an children array with zero item, therefore, check for child length */
      proposalEntity.children &&
      proposalEntity.children!.length! > 0 &&
      common.isDefined(this.children) &&
      this.children.length > 0
    ) {
      this.children[0].refreshQuestions(proposalEntity.children[0]);
    }
  }

  refreshPremiumSummary(proposalEntity: ProposalEntity) {
    ExceptionManager.argumentNullGuard(proposalEntity, "proposalEntity");

    /** Refresh the proposalId and entity to pass back to back-end */
    this.refreshedProposalEntity = proposalEntity;
    this.proposalId = proposalEntity.proposalId;

    this.IsCancellationAdjustment =
      this.endorsementType == Enums.EndorsementType.CancellationAdjustment;

    // TODO: refresh premium summary
  }

  refreshValidationMessage(proposalEntity: ProposalEntity) {
    ExceptionManager.argumentNullGuard(proposalEntity, "proposalEntity");

    /** Refresh the proposalId and entity to pass back to back-end */
    this.validationMessage = proposalEntity.validationsMessages;
    this.proposalId = proposalEntity.proposalId;
  }

  extractQuestionArray(): QuestionEntity[] {
    const questions: QuestionEntity[] = [];

    this.qsMaster.questions.forEach((q) => {
      if (common.isDefined(this.refreshedProposalEntity.getQuestion(q.key))) {
        questions.push(q);
      }
    });

    this.qsPremiumSummary.questions.forEach((q) => {
      if (common.isDefined(this.refreshedProposalEntity.getQuestion(q.key))) {
        questions.push(q);
      }
    });

    return questions;
  }

  createOneChild(): MortgageFireHongKongProposalLocation {
    const newChild = this.createEmptyLocation();
    this.children.push(newChild);

    return newChild;
  }

  getChild(proposalId: GUID): MortgageFireHongKongProposalLocation {
    const found = proposalHelper.searchChildren(this.children, proposalId);
    return found as MortgageFireHongKongProposalLocation;
  }

  private createEmptyLocation(): MortgageFireHongKongProposalLocation {
    const location = new MortgageFireHongKongProposalLocation(
      this.productId,
      this.identityRolesService
    );
    return location;
  }

  checkDiscountEligibility(key: any){
    if(this.productType === EnumHK.MortgageFireHKProduct.HSWPB)
    {
      //HE-2236: Copy Function
      if(key == 'Copy')
      {
        this.setDiscountCodeValue('');  
      }
      else
      {
        if(this.qsMaster.qs_master_discountCode != null){
          if(!this.isUnderwriter || this.transType !== Enums.TransType.NEW)
          {
            this.setDiscountCodeEnablement(true);
          }
          //HE-2236: Exclusion case
          if(common.isUndefinedOrNull(this.qsPremiumSummary.qs_master_brokerCommission?.answer) || this.qsPremiumSummary.qs_master_brokerCommission?.answer == 'null' || this.qsPremiumSummary.qs_master_brokerCommission?.answer <= 0 || this.qsPremiumSummary.qs_master_techRate?.answer != 0)
          {
            this.setDiscountCodeEnablement(true);
            if(this.transType === Enums.TransType.NEW && this.proposalStatus === Enums.ProposalStatus.Incomplete)
              this.setDiscountCodeValue('1');
          }
          else if(this.transType === Enums.TransType.NEW && this.proposalStatus === Enums.ProposalStatus.Incomplete){
            if((this.qsMaster.qs_master_discountCode.readOnly == true && this.isUnderwriter) || common.isUndefinedOrNull(this.qsMaster.qs_master_discountCode?.answer) || key == this.qsMaster.qs_otherinfo_InsuranceCompany?.key)
            {
              this.setDiscountCodeValue('');       
            }
            if(this.isUnderwriter)
              this.setDiscountCodeEnablement(false);    
          }
        }
        //HE-2344: Show N/A for all historical policies
        if((this.transType !== Enums.TransType.NEW || this.proposalStatus != Enums.ProposalStatus.Incomplete) && common.isUndefinedOrNull(this.qsMaster.qs_master_discountCode?.answer)){
          this.setDiscountCodeValue('1');
          this.setDiscountCodeEnablement(true);
        }
      }
    }
  }

  setDiscountCodeValue(result: any){
    if(this.qsMaster.qs_master_discountCode != null)
      this.qsMaster.qs_master_discountCode.answer = result == "1" ? "1" : (String(this.qsMaster.qs_otherinfo_InsuranceCompany?.answer).toUpperCase() == "CB" && this.qsPremiumSummary.qs_master_brokerCommission?.answer > 0 && (this.qsMaster.qs_master_manualUnderwritting?.answer == false || this.qsMaster.qs_master_manualUnderwritting?.answer == 'False') && (this.qsPremiumSummary.qs_master_techRate?.answer == 0 || common.isStringNullOrEmpty(this.qsPremiumSummary.qs_master_techRate?.answer))) ? "4" : "1";  
  }

  setDiscountCodeEnablement(result: boolean){
    if(this.qsMaster.qs_master_discountCode != null)
      this.qsMaster.qs_master_discountCode.readOnly = result;  
  }
}
