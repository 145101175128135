import * as Enums from "./../../../framework/enum.shared";

export class ProposalActionModel {
  visible: boolean = true;
  disabled: boolean = false;

  constructor(
    public command: Enums.ProposalCommandAction,
    public displayText: string
  ) {}
}

export class ProposalAction {
  save: ProposalActionModel;
  sendToChecker: ProposalActionModel;
  returnForRevise: ProposalActionModel;
  recalculate: ProposalActionModel;
  submit: ProposalActionModel;
  approve: ProposalActionModel;
  accept: ProposalActionModel;
  renew: ProposalActionModel;
  decline: ProposalActionModel;
  makeRedundant: ProposalActionModel;
  unsuccessful: ProposalActionModel;
  exit: ProposalActionModel;
  endorsement: ProposalActionModel;
  cancel: ProposalActionModel;
  copy: ProposalActionModel;
  createOrEdit: ProposalActionModel;
  cancellationAdjustment: ProposalActionModel;
  ChangeOfCorrespondenceAddress: ProposalActionModel;
  EditQuotation: ProposalActionModel;

  constructor() {
    this.save = new ProposalActionModel(
      Enums.ProposalCommandAction.Save,
      "Save"
    );
    this.sendToChecker = new ProposalActionModel(
      Enums.ProposalCommandAction.SendToChecker,
      "Send To Checker"
    );
    this.returnForRevise = new ProposalActionModel(
      Enums.ProposalCommandAction.ReturnForRevise,
      "Return For Revise"
    );
    this.recalculate = new ProposalActionModel(
      Enums.ProposalCommandAction.Recalculate,
      "Refresh Premium Summary"
    );
    this.submit = new ProposalActionModel(
      Enums.ProposalCommandAction.Submit,
      "Get Quote"
    );
    this.approve = new ProposalActionModel(
      Enums.ProposalCommandAction.Approve,
      "Approve"
    );
    this.accept = new ProposalActionModel(
      Enums.ProposalCommandAction.Accept,
      "Bind Policy"
    );
    this.renew = new ProposalActionModel(
      Enums.ProposalCommandAction.Renew,
      "Renew"
    );
    this.decline = new ProposalActionModel(
      Enums.ProposalCommandAction.Decline,
      "Decline Quote"
    );
    this.makeRedundant = new ProposalActionModel(
      Enums.ProposalCommandAction.MakeRedundant,
      "Make Redundant"
    );
    this.unsuccessful = new ProposalActionModel(
      Enums.ProposalCommandAction.Unsuccessful,
      "Unsuccessful"
    );
    this.exit = new ProposalActionModel(
      Enums.ProposalCommandAction.Exit,
      "Exit"
    );
    this.endorsement = new ProposalActionModel(
      Enums.ProposalCommandAction.Endorse,
      "Modify"
    );
    this.cancel = new ProposalActionModel(
      Enums.ProposalCommandAction.Cancel,
      "Terminate"
    );
    this.copy = new ProposalActionModel(
      Enums.ProposalCommandAction.Copy,
      "Copy"
    );
    this.createOrEdit = new ProposalActionModel(
      Enums.ProposalCommandAction.EditDeclinedProposal,
      "Edit"
    );
    this.cancellationAdjustment = new ProposalActionModel(
      Enums.ProposalCommandAction.CancellationAdjustment,
      "Refund MinimumPremium"
    );
    this.ChangeOfCorrespondenceAddress = new ProposalActionModel(
      Enums.ProposalCommandAction.ChangeOfCorrespondenceAddress,
      "Cancellation Addr. Update"
    );
      this.EditQuotation = new ProposalActionModel(
      Enums.ProposalCommandAction.EditQuotation,
       "Edit Quotation"
       );
  }
}
