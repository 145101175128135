export class BankTransSearchCriteria {

    constructor() {
        // this.dateFrom = null;
        // this.dateTo = null;
        // this.effectiveDateFrom = null;
        // this.effectiveDateTo = null;
        this.segments = [];
        this.productIds = [];
        this.customerType = [];
        this.transactionType = [];
        this.policyStatus = [];
        this.productRadio = '';
    }
    productRadio: string;
    dateFrom!: Date;
    dateTo!: Date;
    effectiveDateFrom?: Date;
    effectiveDateTo?: Date;
    segments: Array<string>;
    productIds: Array<string>;
    customerType: Array<string>;
    transactionType: Array<string>;
    policyStatus: Array<string>;
}
