import { Component, OnInit } from "@angular/core";
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { ExcelService } from "src/app/services/excel.service";
import { dlgModelArgs } from "../../shared/partials/md-successful-file-upload/md-successful-file-upload.component";
import { Global } from "src/app/shared/global";
import { UploadExcel } from "../../product/auto-loan-redemption/auto-loan-redemption.entity";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_HOME } from "src/app/app.module";
import { ExcelTemplateService } from "src/app/services/excel-template.service";

@Component({
  selector: "app-review-refund-upload",
  templateUrl: "./review-refund-upload.component.html",
  styleUrls: ["./review-refund-upload.component.scss"],
})
export class ReviewRefundUploadComponent implements OnInit {
  uploadExcel!: UploadExcel;
  excelFilesErrorMessage: string = "";
  hasError: boolean = false;
  isProcessing: boolean = false;

  // Error logs
  errorLogs!: { Title: string; Logs: string[] };

  public redirectOpened = false;
  redirectMessage: any;
  redirectData: any;
  sub: any;

  constructor(
    private excelService: ExcelService,
    private uploadService: UploadExcelService,
    private router: Router,
    private route: ActivatedRoute,
    private excelTemplate: ExcelTemplateService
  ) {}

  ngOnInit(): void {
    this.uploadExcel = new UploadExcel();

    this.sub = this.route.data.subscribe();

    // Init Error Log
    if (Global.isUndefined(this.errorLogs)) {
      this.errorLogs = { Title: "", Logs: new Array<string>() };
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  downloadTemplate(): void {
    this.excelTemplate.downloadTemplate(
      columns,
      "Refund",
      "ReviewRefundUploadTemplate"
    );
  }

  excelAndSizeRestrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
    maxFileSize: 5242880, // Check for each file size less than 5MB, 1024 * 1024 * 5
  };

  public resetFiles(): void {
    //todo: currently only allow single file upload
    // Clear uploaded file once, the file is successfuly uploaded.
    this.uploadExcel.files = {};
  }

  public removeEvent(e: RemoveEvent): void {
    this.resetFiles();
  }

  public select(e: SelectEvent): void {
    this.resetFiles();

    const that = this;

    this.hasError = false;

    let totalFileSize = this.getUploadedClaimFilesTotalSize(e.files);
    if (totalFileSize / 1024 / 1024 > 20) {
      this.hasError = true;
      this.excelFilesErrorMessage =
        "Total file size allowed in a claim is 20MB.";
    } else {
      const uploadExcels: File[] = new Array<File>();
      e.files.forEach((file: FileInfo) => {
        //this already check extension & individual file size by kendo control itself
        if (!file.validationErrors && file.rawFile) {
          uploadExcels.push(file.rawFile);
        }
      });
      if (this.uploadExcel != undefined && this.uploadExcel != null) {
        this.uploadExcel.files = uploadExcels;
      }
    }
  }

  getUploadedClaimFilesTotalSize(files: FileInfo[]): number {
    let totalFileSize: number = 0;

    files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        totalFileSize += file.rawFile.size;
      }
    });

    return totalFileSize;
  }

  add(): void {
    this.hasError = false;

    if (this.uploadExcel?.files?.length > 0) {
      this.addExcel();
    } else {
      this.excelFilesErrorMessage = "At least one file needs to be uploaded.";
      this.hasError = true;
    }
  }

  getUploadedExcelFiles(uploadedExcel: UploadExcel): FormData {
    const formData: FormData = new FormData();
    for (let i = 0; i < uploadedExcel.files.length; i++) {
      formData.append("file" + i.toString(), uploadedExcel.files[i]);
    }
    return formData;
  }

  addExcel() {
    this.isProcessing = true;

    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);

    this.uploadService.uploadReviewRefund(uploadedExcelFormData).subscribe(
      (result: any) => {
        let tempData: any = null;
        //no longer result.data as in angularJs but result.body
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          if (result.body.Status === false) {
            tempData = {
              message: result.body.Message,
              validationMessage: result.body.ValidationMessage,
              status: false,
            };
          } else {
            tempData = {
              message: result.body.Message,
              status: true,
            };
          }

          // Clear uploaded file once, the file is successfuly uploaded.
          //this.uploadExcel.files = {};//maybe no need to clear, just like original

          this.redirectOpened = true;
          this.redirectData = tempData;
          // this.dialog.modalLarge(
          //     'shared/partials/mdSuccessfullFileUpload/successfullFileUpload.html',
          //     'SuccessFullFileUpload', data
          //     , this.confirmationModelForSuccessullExcelUpload, undefined, undefined, undefined, false, 'standardModal');
          this.isProcessing = false;
        }
      },
      (error: any) => {
        console.log(error);
        // Prompt fail
        this.errorLogs.Logs = new Array<string>(); // Clear error logs
        this.errorLogs.Title = "Error";

        const log: string =
          Global.isDefined(error?.data?.ExceptionMessage) &&
          error.data.ExceptionMessage !== ""
            ? error.data.ExceptionMessage
            : "Failed to upload excel file. Please contact the system administrator.";

        this.errorLogs.Logs.push(log);
        this.isProcessing = false;
      }
    );
  }

  public confirmationModelForSuccessullExcelUpload(args: dlgModelArgs) {
    this.redirectOpened = false;
    if (args.status === "return") {
      //todo
      this.router.navigate(["/" + ROUTE_HOME]);
      //this.$state.go('product.home', { reload: true });
    }
  }
}
const columns: string[] = ["Policy Certificate Number", "Refund Premium"];
