<div class="wrapper">
  <kendo-tabstrip style="padding-top: 30px">
    <kendo-tabstrip-tab title="Policy Wording SetUp" [selected]="true">
      <ng-template kendoTabContent>
        <!--Error Log -->
        <div id="main">
          <kendo-expansionpanel
            *ngIf="errorLogs.Logs.length > 0"
            name="error_panel"
            [expanded]="true"
          >
            <div class="container-fluid">
              <div class="row">
                <header
                  class="fontBold chubbWhite"
                  [innerHtml]="errorLogs.Title"
                ></header>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <ul>
                      <li
                        *ngFor="let log of errorLogs.Logs"
                        [innerHtml]="log"
                      ></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </kendo-expansionpanel>
        </div>
        <form (ngSubmit)="onSubmit()" #policyForm="ngForm">
          <div class="container-fluid mt-2">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row lbl-align">
                  <div class="col-md-2">
                    <label for="productType">Product Type</label>
                  </div>
                  <div class="col-md-9">
                    <kendo-formfield orientation="horizontal">
                      <kendo-dropdownlist
                        [data]="productTypeList"
                        [disabled]="isUpdate"
                        textField="text"
                        valueField="value"
                        [kendoDropDownFilter]="{
                          operator: 'contains'
                        }"
                        [valuePrimitive]="true"
                        [(ngModel)]="policyWording.productType"
                        (valueChange)="onProductTypeChange($event)"
                        name="productType"
                        required
                      >
                      </kendo-dropdownlist>
                      <kendo-formhint></kendo-formhint>
                    </kendo-formfield>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row lbl-align">
                  <div class="col-md-2">
                    <label for="policyType">Policy Type</label>
                  </div>
                  <div class="col-md-9">
                    <kendo-formfield orientation="horizontal">
                      <kendo-multiselect
                        id="policyType"
                        placeholder="Select Policy Type..."
                        [autoClose]="false"
                        [disabled]="isUpdate"
                        [kendoDropDownFilter]="{
                          operator: 'contains'
                        }"
                        [data]="policyType"
                        textField="policyType"
                        valueField="policyType"
                        [valuePrimitive]="true"
                        [(ngModel)]="policyWording.policyType"
                        (valueChange)="onPolicyTypeChange($event)"
                        name="policyType"
                        required
                      >
                      </kendo-multiselect>
                      <kendo-formhint></kendo-formhint>
                    </kendo-formfield>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row lbl-align">
                  <div class="col-md-4">
                    <label class="mt-1" for="effectiveDate"
                      >Effective Date</label
                    >
                  </div>
                  <div class="col-md-6">
                    <kendo-formfield orientation="horizontal">
                      <kendo-datepicker
                        class="maxWidth"
                        id="effectiveDate"
                        format="dd/MM/yyyy"
                        [min]="minEffectiveDate"
                        [max]="maxEffectiveDate"
                        placeholder=""
                        [(ngModel)]="policyWording.startEffectiveDate"
                        name="effectiveDate"
                        #name="ngModel"
                        required
                      >
                      </kendo-datepicker>
                      <kendo-formhint></kendo-formhint>
                    </kendo-formfield>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group row lbl-align">
                  <div class="col-md-6">
                    <label class="mt-1" for="documentVersion"
                      >Document Version</label
                    >
                  </div>
                  <div class="col-md-6">
                    <kendo-formfield orientation="horizontal">
                      <kendo-dropdownlist
                        [data]="documentVersion"
                        [disabled]="isUpdate"
                        textField="text"
                        valueField="value"
                        [kendoDropDownFilter]="{
                          operator: 'contains'
                        }"
                        [valuePrimitive]="true"
                        [(ngModel)]="policyWording.documentVersion"
                        name="documentVersion"
                        required
                      >
                      </kendo-dropdownlist>
                      <kendo-formhint></kendo-formhint>
                    </kendo-formfield>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="policyWording.documentVersion == 'Existing'"
            >
              <div class="col-md-12">
                <div class="form-group row lbl-align">
                  <div class="col-md-2 mt-2">
                    <label for="documentTitleddl">Document Title</label>
                  </div>
                  <div class="col-md-9">
                    <kendo-formfield orientation="horizontal">
                      <kendo-dropdownlist
                        id="documentTitleddl"
                        aria-placeholder="Select Title..."
                        [data]="documentTitleList"
                        textField="documentTitle"
                        valueField="policyWordingDocumentSetupId"
                        [kendoDropDownFilter]="{
                          operator: 'contains'
                        }"
                        [valuePrimitive]="true"
                        [(ngModel)]="policyWording.policyWordingDocumentSetupId"
                        name="documentTitleddl"
                        (valueChange)="onTitleChange($event)"
                        required
                      >
                      </kendo-dropdownlist>
                      <kendo-formhint></kendo-formhint>
                    </kendo-formfield>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="policyWording.documentVersion == 'Existing' && isUpdate"
            >
              <div class="col-md-12">
                <div class="form-group row">
                  <div class="col-md-2"></div>
                  <div class="col-md-9">
                    <span
                      *ngIf="isUpdate"
                      class="marginRight20 floatLeft"
                      (click)="downloadDocument(policyWording)"
                    >
                      <span class="cursorPointer">{{ documentName }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6"
                *ngIf="policyWording.documentVersion == 'New'"
              >
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="mt-1" for="documentTitle"
                      >Document Title</label
                    >
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <kendo-formfield orientation="horizontal">
                        <input
                          id="documentTitle"
                          name="documentTitle"
                          placeholder="Standard Format:Title_DDMMYYYY"
                          type="text"
                          maxlength="15"
                          [(ngModel)]="policyWording.documentTitle"
                          class="k-textbox form-control"
                          required
                        />
                        <kendo-formhint></kendo-formhint>
                      </kendo-formfield>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="policyWording.documentVersion == 'Existing' && isUpdate"
              >
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="mt-2" for="Document Change"
                      >Document Change</label
                    >
                  </div>
                  <div class="col-md-6 mt-2">
                    <input
                      name="documentChange"
                      type="checkbox"
                      value="{{ documentChange }}"
                      [(ngModel)]="documentChange"
                      id="documentChange"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-md-5"
                *ngIf="
                  policyWording.documentVersion == 'New' ||
                  (documentChange &&
                    policyWording.documentVersion == 'Existing' &&
                    isUpdate)
                "
              >
                <div class="form-group row">
                  <div class="col-md-6">
                    <label class="mt-2" for="transactionDate">Document</label>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        id="excelFilesErrorMessage"
                        style="color: red"
                        *ngIf="hasError"
                      >
                        {{ excelFilesErrorMessage }}
                      </label>
                      <kendo-fileselect
                        [restrictions]="excelAndSizeRestrictions"
                        id="pdf"
                        name="pdf"
                        (select)="select($event)"
                        (remove)="removeEvent($event)"
                        required
                      >
                      </kendo-fileselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row ml-2">
            <div class="col-md-4" class="text-right">
              <button
                *ngIf="!isUpdate"
                style="width: 70px"
                type="button"
                class="btn chubbWhite chubbGreen_bg"
                (click)="add()"
                [disabled]="isProcessing"
              >
                <i
                  *ngIf="isProcessing"
                  class="fa fa-circle-o-notch fa-spin fa-fw"
                  aria-hidden="true"
                ></i>
                Save
              </button>
              <button
                *ngIf="isUpdate"
                style="width: 70px"
                type="button"
                class="btn chubbWhite chubbGreen_bg"
                (click)="add()"
                [disabled]="isProcessing"
              >
                <i
                  *ngIf="isProcessing"
                  class="fa fa-circle-o-notch fa-spin fa-fw"
                  aria-hidden="true"
                ></i>
                Update
              </button>

              <button
                style="width: 70px"
                type="button"
                class="btn chubbWhite chubbRed_bg_btn"
                (click)="cancel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
<div *ngIf="!isUpdate" class="grid-wrapper mt-2 ml-3">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    mode="multiple"
    kendoGridSelectBy
    [(selectedKeys)]="selectedRowIndexes"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column field="productType" title="Product Name" [width]="90">
    </kendo-grid-column>
    <kendo-grid-column field="policyType" title="Policy Type" [width]="90">
    </kendo-grid-column>
    <kendo-grid-column
      field="policyTypeDesc"
      title="Policy Type Description"
      [width]="110"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="startEffectiveDate"
      title="Start Effective Date (MM/dd/yyyy)"
      [width]="110"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.startEffectiveDate | date : "MM/dd/yyyy" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="endEffectiveDate"
      title="End Effective Date (MM/dd/yyyy)"
      [width]="110"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.endEffectiveDate | date : "MM/dd/yyyy" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedBy" title="Updated By" [width]="90">
    </kendo-grid-column>
    <kendo-grid-column field="updatedDate" title="Updated Date" [width]="90">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.updatedDate | date : "MM/dd/yyyy" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="documentName" title="Document" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="fa fa-file-pdf-o cursorPointer"></span>
        {{ dataItem.documentName }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Action" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          class="btn chubbWhite chubbLightBlue_bg_btn"
          *ngIf="dataItem.endEffectiveDate == '2199-12-31T00:00:00'"
          (click)="updatePolicyHandler(dataItem)"
        >
          Update
        </button>
        <!-- <button (click)="downloadDocument(dataItem)">
            <span class="fa fa-file-pdf-o cursorPointer"></span>
          </button> -->
        <span class="ml-2"
          ><i
            class="fa fa-file-excel-o ng-scope"
            (click)="downloadPolicies(dataItem)"
            aria-hidden="true"
          ></i>
        </span>
      </ng-template>
    </kendo-grid-command-column>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="10"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
    </ng-template>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
<div #appendTo class="append-container"></div>
