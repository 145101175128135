import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared/base/base.component";
import { Router } from "@angular/router";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { IUser } from "src/app/framework/interface.shared";
import { UserProfileService } from "src/app/services/user-profile.service";
import { ROUTE_HOME } from "src/app/app.module";
import { AuthorizationService } from "src/app/services/authentication/authorization.service";
import { Identity } from "src/app/shared/user/identity";
import * as Enums from "./../../../framework/enum.shared";

@Component({
  selector: "app-user-role-selection",
  templateUrl: "./user-role-selection.component.html",
  styleUrls: ["./user-role-selection.component.scss"],
})
export class UserRoleSelectionComponent
  extends BaseComponent
  implements OnInit
{
  currentUser: IUser | undefined;
  userTypeOptions: any;

  loginData = {
    userName: "",
    password: "",
  };

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    private userProfileService: UserProfileService,
    private authorizationService: AuthorizationService,
    private identity: Identity
  ) {
    super(router, broadcastService, identityRolesService);

    //when log-in, the sessionStorageService might not yet init-ed, hence needs to sub to a broadcast
    this.broadcastService.onAutheticated.subscribe((msg: any) => {
      this.currentUser = this.identityRolesService.getIdentity().currentUser(); //when first init
      this.getUserTypeOptions();
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  getUserTypeOptions() {
    if (this.currentUser) {
      this.userProfileService
        .GetUserRolesByEmail(this.currentUser.emailAddress)
        .subscribe((resp) => {
          this.userTypeOptions = resp.body;
        });
    }
  }

  OpenUserRoleSelected(defaultUserLogin: { UserLogin: any }) {
    if (defaultUserLogin) {
      this.userProfileService
        .SetDefaultUserRoles(defaultUserLogin.UserLogin)
        .subscribe((resp) => {
          this.loginData.userName = "adb2c_roles_management";
          this.loginData.password = defaultUserLogin.UserLogin; //can ignore this for adb2c
          this.proceedLogin();
        });
    }
  }

  proceedLogin() {
    this.authorizationService.login(this.loginData).subscribe(async (resp) => {
      this.identity.storeAuthData(resp);
      // populating the current user
      //has to wait until fully finish to get latest this.identity.currentUser()
      await this.identity.populateCurrentUser_Promise(true);
      this.redirectPage();
    });
  }

  redirectPage() {
    //check admin first in new angular, since some users could have multiple roles
    if (this.identity.currentUser().userType == Enums.UserType.Admin) {
      this.router.navigate(["/userProfileList"]);
    } else if (
      this.identityRolesService.hasProductReadWriteRoles ||
      this.identityRolesService.hasProductTransactRoles
    ) {
      this.router.navigate(["/" + ROUTE_HOME]);
    } else if (this.identityRolesService.isFinanceAccounting) {
      this.router.navigate(["/openItem"]);
    }
  }
}
