<!-- summaryInvoiceAutoDebitFields -->
<div id="summaryInvoiceAutoDebitFields" class="container-fluid reportOption">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group row">
        <div class="col-md-2">
          <label class="marginTop5">
            AD Month (Generated 6th of every month)</label
          >
        </div>
        <div class="col-md-2">
          <kendo-datepicker
            class="maxWidth"
            format="MMMM  yyyy"
            [bottomView]="'year'"
            placeholder=""
            [(ngModel)]="searchCriteria.month"
          ></kendo-datepicker>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-1">
          <label class="marginTop5">Segment</label>
        </div>
        <div class="col-md-5">
          <kendo-dropdownlist
            [data]="segmentList"
            textField="text"
            valueField="value"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [valuePrimitive]="true"
            [(ngModel)]="searchCriteria.segmentDetails"
          >
          </kendo-dropdownlist>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      (click)="search()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-search ng-scope" aria-hidden="true"></i> Search
    </button>
    <button
      (click)="download()"
      class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    >
      <i class="fa fa-download ng-scope" aria-hidden="true"></i> Download
    </button>
  </div>
</div>
<br />
<div class="grid-wrapper ml-2">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
      info: true,
      pageSizes: true
    }"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column
      field="MeridianSequenceNo"
      title="Meridian Sequence No"
      [width]="160"
    >
    </kendo-grid-column
    ><kendo-grid-column
      field="PolicyCertificateNo"
      title="Master Policy No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="MasterPolicyNo"
      title="Master Policy No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="PolicyNo" title="Policy No" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column field="EntryDate" title="Entry Date" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="GrossPremium" title="Gross Premium" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="GST" title="GST" [width]="70">
    </kendo-grid-column>
    <kendo-grid-column field="TotalPremium" title="Total Premium" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="Status" title="Open Item Status" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column field="InvoiceNo" title="Invoice No" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="SavedBy" title="Saved By" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="BUPCCode" title="BUPC Code" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="BankRef" title="Bank Ref" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="InsuredName" title="Insured Name" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column
      field="EffectiveDate"
      title="Effective Date"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="ExpiryDate" title="Expiry Date" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="SumInsured" title="Sum Insured" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="RiskAddress" title="Risk Address" [width]="160">
    </kendo-grid-column>

    <kendo-grid-column field="InsuredNRIC" title="Insured NRIC" [width]="160">
    </kendo-grid-column
    ><kendo-grid-column
      field="TransactionType"
      title="Transaction Type"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="Product" title="Product" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="ContractType" title="Contract Type" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column field="PolicyStatus" title="Policy Status" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column
      field="TransactionDate"
      title="Transaction Date"
      [width]="140"
    >
    </kendo-grid-column>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
