import * as Enum from "src/app/framework/enum.shared";
import { Component, Input, OnInit, AfterViewInit } from "@angular/core";
import { componentHelper } from "src/app/models/new-product/utils/component.helper";
import { MfHkBaseComponent } from "../mf-hk-base.component";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { AllListingEntity } from "src/app/components/new-product/base/base-configurables-list/config-listing-entity";
import { ClausesDeductiblesService } from "src/app/services/clauses-deductibles.service";
import { MortgageFireHKTabSchema } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/schemas/mf-hk-tab-schema";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHkEndorsementListSearchCriteria } from "../endorsement/endorsement-list/mf-hk-end-list-search-criteria";
import { CoverageTypeService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-coverage.service";
import { MfHkTabchangeService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-tabchange.service";
import { ConfigurableMode } from "src/app/framework/enum.shared";
import { ClausesandDeductibles } from "src/app/models/new-product/base/Clauses-Deductibles.model";
import { ProposalEntity } from "src/app/models/new-product/base/proposal-entity.model";
import * as EnumHK from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-enums";
import { common } from "src/app/framework/utils/common";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-mf-hk-clauses-and-deductibles",
  templateUrl: "./mf-hk-clauses-and-deductibles.component.html",
  styleUrls: ["./mf-hk-clauses-and-deductibles.component.scss"],
})
export class MfHkClausesAndDeductiblesComponent
  extends MfHkBaseComponent
  implements OnInit
{
  @Input() proposal!: MortgageFireHongKongProposal;
  model = new MfHkEndorsementListSearchCriteria();

  resultDeductible: ClausesandDeductibles[] = [];
  nonDefaultDeductibles: ClausesandDeductibles[] = [];

  resultExtraPerils: ClausesandDeductibles[] = [];
  nonDefaultExtraPerils: ClausesandDeductibles[] = [];

  resultClauses: ClausesandDeductibles[] = [];
  nonDefaultClauses: ClausesandDeductibles[] = [];

  resultAdditionalClauses: ClausesandDeductibles[] = [];
  nonDefaultAdditionalClauses: ClausesandDeductibles[] = [];

  masterListData: ClausesandDeductibles[] = [];

  appendDeductibles: number = 1;
  appendExtraPerils: number = 1;
  appendClauses: number = 1;
  appendAdditionalClauses: number = 1;

  isIncomplete!: boolean;
  isNewBusiness!: boolean;
  isEndorsement!: boolean;
  isCancellation!: boolean;
  isUnderwriter!: boolean;

  proposalEntity!: ProposalEntity;
  coverageType: string = "";
  subscription?: Subscription;
  tabSubscription?: Subscription;

  protected listing: AllListingEntity[] = [];
  protected totalListing: number = 0;
  public gridView!: GridDataResult;
  public loading!: boolean;
  public pageSize = 10;
  public skip = 0;
  public take = 10;

  constructor(
    public proposalService: MfHKProposalService,
    public clausesDeductiblesService: ClausesDeductiblesService,
    public coverageTypeService: CoverageTypeService,
    private mfHkTabchangeService: MfHkTabchangeService
  ) {
    super();

    this.tabId = MortgageFireHKTabSchema.clausesAndDeductibles.index.toString();

    this.subscription = this.coverageTypeService.value$.subscribe((option) => {
      if (option) {
        this.coverageType = option;
        this.refreshData();
      }
    });

    this.tabSubscription = this.mfHkTabchangeService.emit$.subscribe(
      (number) => {
        if (number == 2) {
          this.resetOnlyDropdown();
          if (
            this.checkDropDown() &&
            common.isDefined(this.proposal.clausesDeductibles) &&
            this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
          ) {
            this.fetchDropdown();
          }
          if (
            this.checkDropDown() &&
            common.isDefined(
              this.proposal.qsMaster.qs_fire_coverageValueType?.answer
            ) &&
            !common.isDefined(this.proposal.clausesDeductibles) &&
            this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
          ) {
            this.fetchDropdown();
          }
        }
      }
    );

    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
  }

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireHongKongProposal;
    this.isAnswered();

    //If this policy or draft is existing then load default C&D Automatiocally
    this.checkExistingPolicy();

    //This function inserts default data for each C&D item
    this.insertDefaultItemsForRenewal();
  };

  private proposalSaved = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireHongKongProposal;
    this.saveDeductibles();
    this.isAnswered();
    this.checkExistingPolicy();
    //This function inserts default data for each C&D item
    this.insertDefaultItemsForRenewal();
    this.sortBasedOnCode();
    this.resetOnlyDropdown();
    if (
      this.checkDropDown() &&
      common.isDefined(this.proposal.clausesDeductibles) &&
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
    ) {
      this.loadOnlyDropdown();
    }
  };

  override ngOnInit(): void {
    this.model.typeId = ConfigurableMode.Deductibles;

    this.initialize();
    componentHelper.notifyPageLoaded(
      "mf-hk-clauses-deductibles.component",
      this.proposal
    );
  }

  private initialize() {
    this.proposal = this.proposalService.proposal;

    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;
    this.isNewBusiness = this.proposal.transType === Enum.TransType.NEW;
    this.isEndorsement = this.proposal.transType === Enum.TransType.END;

    super.subscribeTabLoadRenderer();
  }

  protected isreadonly() {
    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;

    this.isUnderwriter = this.proposal.isUnderwriter;

    const allowEdit = this.isIncomplete && this.isUnderwriter;

    this.isEndorsement =
      this.proposal.transType === Enum.TransType.END ? true : false;

    this.isCancellation =
      this.proposal.transType === Enum.TransType.CAN ? true : false;

    if (this.isEndorsement) {
      let acceptedEndorsementFlag =
        (this.proposal.endorsementType ===
          Enum.EndorsementType.GeneralEndorsement ||
        this.proposal.endorsementType ===
          Enum.EndorsementType.ChangeOfSumInsured
          ? true
          : false) && allowEdit;

      return acceptedEndorsementFlag;
    }
    if (this.isCancellation) {
      return false;
    } else {
      return allowEdit;
    }
  }

  protected showExtraPerils() {
    var flag = false;

    if (
      common.isDefined(
        this.proposal.qsMaster.qs_fire_coverageValueType?.answer
      ) &&
      this.proposal.qsMaster.qs_fire_coverageValueType?.answer == "1"
    ) {
      return (flag = true);
    }
    if (
      !common.isDefined(
        this.proposal.qsMaster.qs_fire_coverageValueType?.answer
      )
    ) {
      return (flag = true);
    } else return (flag = false);
  }

  protected isAnswered() {
    if (common.isDefined(this.proposal.clausesDeductibles)) {
      this.resultDeductible = this.proposal.clausesDeductibles.filter(
        (item) => item.ClauseDeductibleType === 1
      );
      this.resultExtraPerils = this.proposal.clausesDeductibles.filter(
        (item) => item.ClauseDeductibleType === 2
      );
      this.resultClauses = this.proposal.clausesDeductibles.filter(
        (item) => item.ClauseDeductibleType === 3
      );
      this.resultAdditionalClauses = this.proposal.clausesDeductibles.filter(
        (item) => item.ClauseDeductibleType === 4
      );
    }
    return true;
  }

  public refreshData(): void {
    this.proposal = this.proposalService.proposal;
    if (
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete &&
      common.isDefined(this.proposal.clausesDeductibles) &&
      this.proposal.clausesDeductibles.length > 0
    ) {
      this.reset();
      this.loadAtRefresh();
    } else if (
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete &&
      this.proposal.transType === Enum.TransType.END &&
      common.isDefined(this.proposal.clausesDeductibles) &&
      this.proposal.clausesDeductibles.length > 0
    ) {
      this.resetOnlyDropdown();
      this.loadOnlyDropdown();
    } else {
      this.load();
    }
  }

  protected modelToArray(): (
    | (string | Date | undefined)[]
    | (string | string[])[]
    | (string | number[])[]
    | (string | number)[]
  )[] {
    let array = [
      ["coverageTypes", "eq", this.model.coverageTypes],
      ["title", "eq", this.model.title],
      ["typeId", "eq", this.model.typeId],
    ];

    return array;
  }

  public load() {
    const formData: FormData = this.setFormData();
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }
    this.clausesDeductiblesService.getAllHaseList(querystring).subscribe(
      (result: any) => {
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          this.listing = result.body.d?.results;
          this.totalListing = result.body.d?.__count;
          if (
            this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete &&
            this.proposal.transType === Enum.TransType.END &&
            common.isDefined(this.proposal.clausesDeductibles) &&
            this.proposal.clausesDeductibles.length > 0
          ) {
            this.loadOnlyDropdown();
          }
          if (
            common.isDefined(this.proposal.clausesDeductibles) &&
            this.checkDropDown() &&
            this.proposal.clausesDeductibles.length > 0
          ) {
            this.loadOnlyDropdown();
          }
          if (
            !common.isDefined(this.proposal.clausesDeductibles) ||
            this.proposal.clausesDeductibles.length == 0
          ) {
            this.loadItems();
          }
          this.saveDeductibles();
          this.loading = false;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  public loadOnlyDropdown(): void {
    this.listing.forEach((entity, index) => {});

    this.listing.forEach((item) => {
      const list: ClausesandDeductibles = {
        ClauseDeductibleType: item.clausesAndDeductiblesType.toNumber(),
        ClausesDeductiblesID: item.id.toString(),
        ClausesDeductiblesCode: item.coverageCode,
        Title: item.title,
        AdditionalText: item.additionalText,
        Description: item.description,
      };
      if (
        item.coverageType ==
        this.proposal.qsMaster.qs_fire_coverageValueType?.answer
      ) {
        this.masterListData?.push(list);
        if (
          item.clausesAndDeductiblesType == "1" &&
          !this.isItemInMasterList(list, this.resultDeductible)
        ) {
          this.nonDefaultDeductibles?.push(list);
        }
        if (
          item.clausesAndDeductiblesType == "2" &&
          !this.isItemInMasterList(list, this.resultExtraPerils)
        )
          this.nonDefaultExtraPerils?.push(list);
        if (
          item.clausesAndDeductiblesType == "3" &&
          !this.isItemInMasterList(list, this.resultClauses)
        )
          this.nonDefaultClauses?.push(list);
        if (
          item.clausesAndDeductiblesType == "4" &&
          !this.isItemInMasterList(list, this.resultAdditionalClauses)
        )
          this.nonDefaultAdditionalClauses?.push(list);
      }
    });
  }

  public loadItems(): void {
    this.listing.forEach((entity, index) => {});

    this.listing.forEach((item) => {
      const list: ClausesandDeductibles = {
        ClauseDeductibleType: item.clausesAndDeductiblesType.toNumber(),
        ClausesDeductiblesID: item.id.toString(),
        ClausesDeductiblesCode: item.coverageCode,
        Title: item.title,
        AdditionalText: item.additionalText,
        Description: item.description,
      };
      if (this.coverageType == item.coverageType) {
        this.masterListData.push(list);
        if (
          item.isDefault === "True" &&
          item.clausesAndDeductiblesType == "1" &&
          this.appendDeductibles == 1
        ) {
          this.resultDeductible?.push(list);
        }
        if (
          item.isDefault === "False" &&
          item.clausesAndDeductiblesType == "1"
        ) {
          this.nonDefaultDeductibles?.push(list);
        }

        if (
          item.isDefault === "True" &&
          item.clausesAndDeductiblesType == "2" &&
          this.coverageType == "1" &&
          this.appendExtraPerils == 1
        )
          this.resultExtraPerils?.push(list);
        if (
          item.isDefault === "False" &&
          item.clausesAndDeductiblesType == "2" &&
          this.coverageType == "1"
        )
          this.nonDefaultExtraPerils?.push(list);

        if (
          item.isDefault === "True" &&
          item.clausesAndDeductiblesType == "3" &&
          this.appendClauses == 1
        )
          this.resultClauses?.push(list);
        if (item.isDefault === "False" && item.clausesAndDeductiblesType == "3")
          this.nonDefaultClauses?.push(list);

        if (
          item.isDefault === "True" &&
          item.clausesAndDeductiblesType == "4" &&
          this.appendAdditionalClauses == 1
        )
          this.resultAdditionalClauses?.push(list);
        if (item.isDefault === "False" && item.clausesAndDeductiblesType == "4")
          this.nonDefaultAdditionalClauses?.push(list);
      }
    });
  }

  public loadAtRefresh() {
    const formData: FormData = this.setFormData();
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }
    this.clausesDeductiblesService.getAllHaseList(querystring).subscribe(
      (result: any) => {
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          this.listing = result.body.d?.results;
          this.totalListing = result.body.d?.__count;

          this.loadItems();
          this.saveDeductibles();
          this.loading = false;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  public fetchDropdown() {
    const formData: FormData = this.setFormData();
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }
    this.clausesDeductiblesService.getAllHaseList(querystring).subscribe(
      (result: any) => {
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          this.listing = result.body.d?.results;
          this.totalListing = result.body.d?.__count;

          this.loadOnlyDropdown();
          this.saveDeductibles();
          this.loading = false;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  checkExistingPolicy() {
    if (
      //Check if the last submitted date is before clauses and deductibles release date
      //Add default C&D to only historical policies
      this.proposal.lastSubmittedDate <=
        environment.ClausesAndDeductiblesReleaseDate &&
      (!common.isDefined(this.proposal.clausesDeductibles) ||
        this.proposal.clausesDeductibles.length == 0) &&
      common.isDefined(
        this.proposal.qsMaster.qs_fire_coverageValueType?.answer
      ) &&
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete &&
      //Check if Clauses and deductibles added, if added don't fill default else load default
      //Applicable to RN and EN cases if the previous historical transaction has Empty C&D
      (!common.isDefined(
        this.proposal.qsMaster.qs_master_clausesflag?.answer
      ) ||
        this.proposal.qsMaster.qs_master_clausesflag?.answer != "true")
    ) {
      this.coverageType =
        this.proposal.qsMaster.qs_fire_coverageValueType?.answer;
      this.load();
      this.proposal.qsMaster.qs_master_clausesflag!.answer = "true";
    }

    //Add default C&D when copy function
    if (
      this.proposal.copyProcessFor == Enum.copyProcessFor.NormalCopy &&
      (!common.isDefined(this.proposal.clausesDeductibles) ||
        this.proposal.clausesDeductibles.length == 0) &&
      common.isDefined(
        this.proposal.qsMaster.qs_fire_coverageValueType?.answer
      ) &&
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete &&
      (!common.isDefined(
        this.proposal.qsMaster.qs_master_clausesflag?.answer
      ) ||
        this.proposal.qsMaster.qs_master_clausesflag?.answer != "true")
    ) {
      this.coverageType =
        this.proposal.qsMaster.qs_fire_coverageValueType?.answer;
      this.load();
      this.proposal.qsMaster.qs_master_clausesflag!.answer = "true";
    }
  }

  protected setFormData(): FormData {
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "code");
    formData.append("sort[0][dir]", "asc");

    let array = this.modelToArray();

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    return formData;
  }

  public isItemInMasterList(
    item: ClausesandDeductibles,
    masterList: ClausesandDeductibles[]
  ) {
    return masterList.some(
      (masterItem) =>
        masterItem.ClausesDeductiblesID === item.ClausesDeductiblesID
    );
  }

  public updateList(data: {
    item: ClausesandDeductibles;
    type: number;
    action: string;
  }): void {
    if (data.action == "add") {
      if (data.type == 1) this.resultDeductible.push(data.item);
      else if (data.type == 2) this.resultExtraPerils.push(data.item);
      else if (data.type == 3) this.resultClauses.push(data.item);
      else if (data.type == 4) this.resultAdditionalClauses.push(data.item);
    } else {
      if (data.type == 1)
        this.resultDeductible = this.resultDeductible.filter(
          (item) => item.ClausesDeductiblesID !== data.item.ClausesDeductiblesID
        );
      else if (data.type == 2)
        this.resultExtraPerils = this.resultExtraPerils.filter(
          (item) => item.ClausesDeductiblesID !== data.item.ClausesDeductiblesID
        );
      else if (data.type == 3)
        this.resultClauses = this.resultClauses.filter(
          (item) => item.ClausesDeductiblesID !== data.item.ClausesDeductiblesID
        );
      else if (data.type == 4)
        this.resultAdditionalClauses = this.resultAdditionalClauses.filter(
          (item) => item.ClausesDeductiblesID !== data.item.ClausesDeductiblesID
        );
    }

    this.saveDeductibles();
  }

  public insertDefaultItemsForRenewal() {
    if (
      (this.proposal.transType === Enum.TransType.REN ||
        //HE-1811: Adding items to a single empty C&D when copy function
        (this.proposal.copyProcessFor === Enum.copyProcessFor.NormalCopy &&
          (!common.isDefined(
            this.proposal.qsMaster.qs_master_clausesflag?.answer
          ) ||
            this.proposal.qsMaster.qs_master_clausesflag?.answer != "true"))) &&
      this.proposal.clausesDeductibles.length != 0
    ) {
      this.coverageType =
        this.proposal.qsMaster.qs_fire_coverageValueType?.answer;

      this.appendDeductibles = 0;
      this.appendExtraPerils = 0;
      this.appendClauses = 0;
      this.appendAdditionalClauses = 0;

      this.resultDeductible = this.proposal.clausesDeductibles.filter(
        (item) => item.ClauseDeductibleType == 1
      );
      if (this.resultDeductible.length == 0) this.appendDeductibles = 1;

      if (this.coverageType == "1") {
        this.resultExtraPerils = this.proposal.clausesDeductibles.filter(
          (item) => item.ClauseDeductibleType == 2
        );
        if (this.resultExtraPerils.length == 0) this.appendExtraPerils = 1;
      }

      this.resultClauses = this.proposal.clausesDeductibles.filter(
        (item) => item.ClauseDeductibleType == 3
      );
      if (this.resultClauses.length == 0) this.appendClauses = 1;

      this.resultAdditionalClauses = this.proposal.clausesDeductibles.filter(
        (item) => item.ClauseDeductibleType == 4
      );
      if (this.resultAdditionalClauses.length == 0)
        this.appendAdditionalClauses = 1;

      this.loadAtRefresh();
    }
  }

  public saveDeductibles() {
    if (this.proposal.productType === EnumHK.MortgageFireHKProduct.HSCMB)
      this.proposal.clausesDeductibles = [
        ...this.resultDeductible,
        ...this.resultClauses,
        ...this.resultExtraPerils,
        ...this.resultAdditionalClauses,
      ];
  }
  public sortBasedOnCode() {
    if (common.isDefined(this.proposal.clausesDeductibles)) {
      this.resultDeductible = this.proposal.clausesDeductibles
        .filter((item) => item.ClauseDeductibleType === 1)
        .sort((a, b) =>
          a.ClausesDeductiblesCode!.localeCompare(b.ClausesDeductiblesCode!)
        );
      this.resultExtraPerils = this.proposal.clausesDeductibles
        .filter((item) => item.ClauseDeductibleType === 2)
        .sort((a, b) =>
          a.ClausesDeductiblesCode!.localeCompare(b.ClausesDeductiblesCode!)
        );
      this.resultClauses = this.proposal.clausesDeductibles
        .filter((item) => item.ClauseDeductibleType === 3)
        .sort((a, b) =>
          a.ClausesDeductiblesCode!.localeCompare(b.ClausesDeductiblesCode!)
        );
      this.resultAdditionalClauses = this.proposal.clausesDeductibles
        .filter((item) => item.ClauseDeductibleType === 4)
        .sort((a, b) =>
          a.ClausesDeductiblesCode!.localeCompare(b.ClausesDeductiblesCode!)
        );
      this.saveDeductibles();
    }
  }

  public checkDropDown() {
    if (
      this.nonDefaultDeductibles.length == 0 &&
      this.nonDefaultExtraPerils.length == 0 &&
      this.nonDefaultClauses.length == 0 &&
      this.nonDefaultAdditionalClauses.length == 0
    )
      return true;

    return false;
  }

  protected resetOnlyDropdown() {
    this.nonDefaultDeductibles = [];
    this.nonDefaultClauses = [];
    this.nonDefaultExtraPerils = [];
    this.nonDefaultAdditionalClauses = [];
  }

  protected reset() {
    this.resultDeductible = [];
    this.nonDefaultDeductibles = [];
    this.resultClauses = [];
    this.nonDefaultClauses = [];
    this.resultExtraPerils = [];
    this.nonDefaultExtraPerils = [];
    this.resultAdditionalClauses = [];
    this.nonDefaultAdditionalClauses = [];
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription?.unsubscribe();
    this.coverageTypeService.clearSelectedValue();

    this.tabSubscription?.unsubscribe();
    this.mfHkTabchangeService.clearMessages();

    super.unsubscribeRxjs();
  }
}
