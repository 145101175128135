import { GUID } from "./../../../framework/domain-entity/guid";
import { Proposal } from "./proposal.model";

export abstract class ProposalPackage<T extends Proposal> extends Proposal {
  children!: T[];

  abstract createOneChild(): T;

  abstract getChild(proposalId: GUID): T;
}
