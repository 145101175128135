import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { Global } from "src/app/shared/global";
import { BaseUploadComponent } from "../base-upload/base-upload.component";

@Component({
  selector: "app-gst-adjustment",
  templateUrl: "./gst-adjustment.component.html",
  styleUrls: ["./gst-adjustment.component.scss"],
})
export class GstAdjustmentComponent extends BaseUploadComponent {
  constructor(
    public override uploadService: UploadExcelService,
    public override router: Router,
    public override route: ActivatedRoute,
    public override excelTemplate: ExcelTemplateService
  ) {
    super(uploadService, router, route, excelTemplate);
  }

  downloadTemplate(): void {
    this.excelTemplate.downloadTemplate(
      columns,
      "GSTAdjustment",
      "GSTAdjustmentTemplate"
    );
  }

  override addExcel() {
    this.isProcessing = true;
    this.errorLogs.Logs = []; //reset errorLogs

    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);

    this.uploadService.uploadGSTAdjustment(uploadedExcelFormData).subscribe(
      (result: any) => {
        let tempData: any = null;
        //no longer result.data as in angularJs but result.body
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          if (result.status === 200) {
            tempData = {
              message: "Upload successful.",
              status: true,
            };
            this.uploadService
              .generateGSTAdjustmentReport("GSTAdjustmentReport")
              .subscribe(
                (result: any) => {
                  const fileName: string = result.headers.get("x-filename");
                  this.uploadService.generateAnchorAndTriggerDownload(
                    result.body,
                    fileName
                  );
                },
                (error: any) => {
                  let message: string = "";
                  switch (error?.status) {
                    case 401:
                      message = "Unauthorized document download";
                      break;
                    default:
                      message =
                        "Document download response with unexpected status: " +
                        error?.status?.toString();
                      break;
                  }
                  tempData = {
                    message: message,
                    validationMessage: undefined,
                    status: false,
                  };

                  this.redirectOpened = true;
                  this.redirectData = tempData;
                  this.isProcessing = false;
                }
              );
          } else {
            let message: string = "";
            switch (result.status) {
              case 401:
                message = "Unauthorized document download";
                break;
              default:
                message =
                  "Document download response with unexpected status: " +
                  result.status.toString();
                break;
            }

            tempData = {
              message: message,
              validationMessage: result.body.ValidationMessage,
              status: false,
            };
          }

          // Clear uploaded file once, the file is successfuly uploaded.
          //this.uploadExcel.files = {};//maybe no need to clear, just like original
          this.redirectOpened = true;
          this.redirectData = tempData;
          this.isProcessing = false;
        }
      },
      (error: any) => {
        console.log(error);
        this.errorLogs.Logs = new Array<string>();
        this.errorLogs.Title = "Error";

        const log: string =
          Global.isDefined(error?.data?.ExceptionMessage) &&
          error.data.ExceptionMessage !== ""
            ? error.data.ExceptionMessage
            : "Failed to upload excel file. Please contact the system administrator.";

        this.errorLogs.Logs.push(log);
        this.isProcessing = false;
      }
    );
  }
}

const columns: string[] = [
  "Policy Number",
  "EPL (column manually created) Policy number + Reference",
  "Reference",
  "Open Item Audit Number",
];
