/** Common function for data type conversion  */

interface Object {
  toNumber(): number;
  toBoolean(): boolean;
  /** Covert value to API recognized string value */
  toApiValue(): string;
  /** Check if is Date data type */
  isDate(): boolean;
}

interface String {
  toNumber(): number;
  toBoolean(): boolean;
  /** Covert value to API recognized string value */
  toApiValue(): string;
  endsWith(suffix: string): boolean;
}

interface Number {
  /** Covert value to API recognized string value */
  toApiValue(): string;
  toNumber(): number;
}

interface Boolean {
  /** Covert value to API recognized string value */
  toApiValue(): string;
}

interface Date {
  /** Covert value to API recognized string value */
  toApiValue(): string;
}

if (typeof String.prototype.endsWith !== "function") {
  String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
  };
}

String.prototype.toNumber = function () {
  if (typeof this === "undefined" || this === null) {
    return 0;
  }

  let value = (<string>this).replace(/[^0-9\.-]+/g, "");

  if (value.length === 0) return 0;

  return parseFloat(value);
};

// Number.prototype.toNumber = function () {
//   return this;
// };

String.prototype.toBoolean = function () {
  if (typeof this === "undefined" || this === null) {
    return false;
  }

  let value = (<string>this).trim().toLowerCase();

  if (value.length === 0) return false;
  if (value === "1") return true;
  if (value === "0") return false;
  if (value === "true") return true;
  if (value === "false") return false;

  throw "value not convertible to boolean";
};

Boolean.prototype.toApiValue = function (): string {
  return this ? "true" : "false";
};

Number.prototype.toApiValue = function (): string {
  return this.toString();
};

Date.prototype.toApiValue = function (): string {
  let date = <Date>this;
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  return `${year}-${month}-${day} 12:00:00 AM`;
};

//todo: this might cause issue to node_modules/@progress/kendo-date-math/dist/npm/tz/zoned-date.d.ts
/** Native type prototype extends */
// interface Date {
//   serverUiControlDateFormat(): string;
//   utcToLocal(): Date;
//   ToLocalDateText(): number;
//   toSystemDateFormat(): string;
// }

// Date.prototype.serverUiControlDateFormat = function () {
//   var str =
//     this.getFullYear() +
//     "-" +
//     (this.getMonth() + 1) +
//     "-" +
//     this.getDate() +
//     " 12:00:00 AM";
//   return str;
// };

// Date.prototype.utcToLocal = function () {
//   var newDate = new Date(this.getTime() + this.getTimezoneOffset() * 60 * 1000);

//   var offset = this.getTimezoneOffset() / 60;
//   var hours = this.getHours();

//   newDate.setHours(hours - offset);

//   return newDate;
// };

// Date.prototype.ToLocalDateText = function () {
//   var str = Date.UTC(
//     this.getFullYear(),
//     this.getMonth(),
//     this.getDate(),
//     this.getHours(),
//     this.getMinutes(),
//     this.getSeconds(),
//     this.getMilliseconds()
//   );
//   return str;
// };

// Date.prototype.toSystemDateFormat = function () {
//   return (
//     this.getFullYear() + "-" + (this.getMonth() + 1) + "-" + this.getDate()
//   );
// };

if (typeof String.prototype.trim !== "function") {
  String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, "");
  };
}

/** cmwanx: temporary put angular bootstrap here.
 * because this is the last file of ams script bundler load
 */
//angular.bootstrap(document.documentElement, ['app']);
