import { Component, Input } from "@angular/core";
import { Proposal } from "../../../../../../../../models/new-product/base/proposal.model";
import * as Enum from "../../../../../../../../framework/enum.shared";
import { KeyValue } from "../../../../../../../../framework/domain-entity/key-value";
import { common } from "../../../../../../../../../app/framework/utils/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_PROPOSAL } from "../../../../../../../../../app/app.module";
import { MfHkBaseComponent } from "../../mf-hk-base.component";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";

export class TabModel {
  constructor(
    public index: number,
    public displayName: string,
    public visible: boolean
  ) {}
}

@Component({
  selector: "mf-hk-pending-transaction-panel",
  templateUrl: "./mf-hk-pending-transaction-panel.component.html",
  styleUrls: ["./mf-hk-pending-transaction-panel.component.scss"],
})
export class MfHkPendingTransactionPanelComponent extends MfHkBaseComponent {
  proposal!: MortgageFireHongKongProposal;
  // private $state: angular.ui.IStateService;
  proposalPendingStatus: string = "";
  productId!: String;

  constructor(
    public proposalService: MfHKProposalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.route.queryParams.subscribe((params) => {
      this.productId = params["pdid"];
    });
  }

  ngOnDestroy = () => {
    super.unsubscribeRxjs();
  };

  override ngOnInit = () => {
    this.initialize();
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalSavedNotifier(this.proposalLoaded);
  };

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireHongKongProposal;
    if (
      common.isDefined(this.proposal.pendingTransaction) &&
      this.proposal.pendingTransaction != null
    ) {
      switch (this.proposal.pendingTransaction.transType) {
        case Enum.TransType.END:
          this.proposalPendingStatus = "Endorsement";
          break;
        case Enum.TransType.CAN:
          this.proposalPendingStatus = "Cancellation";
          break;
        case Enum.TransType.REN:
          this.proposalPendingStatus = "Renewal";
          break;
        default:
      }
    }
  };

  initialize() {}

  viewPendingTransaction = () => {
    const proposalId = this.proposalService.proposal.pendingTransaction?.pid;
    let currentUrl = this.router.url;
    if (currentUrl.startsWith("/" + ROUTE_PROPOSAL)) {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
        this.router.navigate(["/" + ROUTE_PROPOSAL], {
          queryParams: {
            pdid: this.productId,
            pid: proposalId,
            mpid: proposalId,
            action: 1,
            type: 1,
          },
        })
      );
    }else{
      this.router.navigate(["/" + ROUTE_PROPOSAL], {
        queryParams: {
          pdid: this.productId,
          pid: proposalId,
          mpid: proposalId,
          action: 1,
          type: 1,
        },
      });
    }
  }
}