<kendo-dialog *ngIf="opened" [width]="900">
    <div class="modal-header-custom">
        <h3
          class="modal-title-custom chubbLightOrange_bg">
          Open Item Details
        </h3>
      </div>
      <div class="ml-2">
        <label style="margin: 10px 0px 10px 0px;">Policy Certificate Number:</label>
            <span class="ml-2" >
                {{displayPolicyCertificateNo}}
            </span>
        
        <kendo-grid style="cursor: pointer"
                [data]="gridView"
                mode="multiple"
                [resizable]="true"
                kendoGridSelectBy>


        <kendo-grid-column field="startEffectiveDate"
                           title="Date / Time"
                           [width]="140">
            <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.startEffectiveDate | date :'short'}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="statusDisplayText" title="Status" [width]="160">
        </kendo-grid-column>

        <kendo-grid-column field="batchId"
                           title="Batch Id"
                           [width]="90">
        </kendo-grid-column>
        <kendo-grid-column  
                           field="invoiceNo"
                           title="Invoice No" [width]="130">
        </kendo-grid-column>
        <kendo-grid-column 
                           field="amount"
                           title="Total Premium"
                           [width]="130">
        </kendo-grid-column>
        <kendo-grid-column field="amountGst"
                           title="GST"
                           [width]="90">
        </kendo-grid-column>
        <kendo-grid-column field="chequeIssuanceBank"
                           title="Cheque Issuance Bank"
                           [width]="110">
        </kendo-grid-column>
        <kendo-grid-column field="chequeNumber" title="Cheque No" [width]="110">
        </kendo-grid-column>
        <kendo-grid-column field="autoDebitMonthDisplayText" title="Auto Debit Month" [width]="130">
        </kendo-grid-column>
        <kendo-grid-column field="remarks"
                           title="Remarks"
                           [width]="110">
        </kendo-grid-column>
        <kendo-grid-column field="lastUpdatedByUserName"
                           title="Action By"
                           [width]="110">
        </kendo-grid-column>
        
    </kendo-grid>
      </div>
    <div class="modal-footer">
        <kendo-dialog-actions>
          <button
            kendoButton
            (click)="close('return')"
            class="btn chubbWhite chubbRed_bg_btn confirmation_cancel ng-binding"
          >
            Return
          </button>
        </kendo-dialog-actions>
      </div>
</kendo-dialog>