import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { DialogWrapperService } from "src/app/services/dialog/dialog-wrapper.service";
import { UserProfileService } from "src/app/services/user-profile.service";
import { UserProfileModel } from "../user-profile.model";
import { CreateProfileComponent } from "./create-profile/create-profile.component";
import { UserSuccessComponent } from "../user-success/user-success.component";

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent implements OnInit {
  @Output() changeTab: EventEmitter<undefined> = new EventEmitter();
  @ViewChild(CreateProfileComponent)
  createProfileData!: CreateProfileComponent;
  additionalInformationImage = "additional_grey.png";
  isTab2 = false;
  constructor(
    private userProfileService: UserProfileService,
    private dialogWrapperService: DialogWrapperService
  ) {}
  ngOnInit(): void {}
  cancel(): void {
    this.changeTab.emit();
  }
  next(): void {
    if (this.createProfileData?.validateFormData()) {
      this.additionalInformationImage = "additional.png";
      this.isTab2 = true;
    }
  }
  back(): void {
    this.additionalInformationImage = "additional_grey.png";
    this.isTab2 = false;
  }
  saveAllUserProfile(): void {
    if (this.createProfileData?.validateFormData()) {
      let model: UserProfileModel = this.createProfileData?.saveData();
      this.userProfileService.saveUserDetails(model).subscribe((response) => {
        if (response && response.body) {
          if (response.body.status === "success") {
            this.openPopup();
          } else
            this.createProfileData.showServerValidation(
              response.body.invalidDataErrorList
            );
        }
      });
    }
  }
  private openPopup() {
    this.dialogWrapperService
      .modalSmall(
        "user-success.component.html",
        UserSuccessComponent,
        "",
        undefined,
        undefined,
        null,
        null,
        false,
        ""
      )
      .subscribe((result: any) => {});
  }
}
