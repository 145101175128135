<kendo-dialog *ngIf="opened" (close)="close('return')" [width]="900">
  <div class="modal-header-custom">
    <h3
      class="modal-title-custom"
      [ngClass]="{ chubbGreen_bg: isSuccess, chubbRed_bg: !isSuccess }"
    >
      Information
    </h3>
  </div>
  <div class="modal-body">
    <div [innerHtml]="data.message"></div>
    <div [hidden]="isSuccess" *ngFor="let item of validationMessage">
      <h4>
        Row Number : <b>{{ item.value.RowNumber }}</b>
      </h4>
      <ul style="padding-left: 25px">
        <li
          *ngFor="let message of item.value.ValidationMessage"
          class="lblColorRed"
        >
          {{ message }}
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <kendo-dialog-actions>
      <button
        kendoButton
        (click)="close('remain')"
        class="btn chubbGreen chubbGreen_border_btn chubbWhite_bg"
      >
        Remain in the same page.
      </button>
      <button
        kendoButton
        [hidden]="!isSuccess"
        (click)="close('return')"
        class="btn chubbDarkBlue chubbDarkBlue_border_btn chubbWhite_bg"
      >
        Return to proposal listing.
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
