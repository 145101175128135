<kendo-dialog *ngIf="active" [width]="650" [height]="350" (close)="closeForm()">
  <kendo-dialog-titlebar>
    {{ isNew ? "Add" : "Edit" }}
  </kendo-dialog-titlebar>

  <form
    novalidate
    class="k-form"
    style="padding-left: 100px; padding-top: 20px"
    [formGroup]="editForm"
    class="k-form k-form-horizontal"
  >
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="rMName" text="RM Name"></kendo-label>
      <kendo-textbox formControlName="rMName" #rMName required></kendo-textbox>

      <kendo-formhint></kendo-formhint>
      <kendo-formerror>RM Name is required.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="teamName" text="Team Name"></kendo-label>
      <kendo-textbox
        formControlName="teamName"
        #teamName
        required
      ></kendo-textbox>

      <kendo-formhint></kendo-formhint>
      <kendo-formerror>Team Name is required.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="pcCode" text="PC Code"></kendo-label>
      <kendo-textbox
        formControlName="pcCode"
        #pcCode
        required
        [maxlength]="4"
      ></kendo-textbox>

      <kendo-formhint></kendo-formhint>
      <kendo-formerror>PC Code is required.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="officeNumber" text="Office Number"></kendo-label>
      <kendo-textbox
        formControlName="officeNumber"
        #officeNumber
      ></kendo-textbox>
    </kendo-formfield>
  </form>

  <kendo-dialog-actions>
    <button
      kendoButton
      themeColor="primary"
      [disabled]="!editForm.valid"
      (click)="onSave($event)"
    >
      Update
    </button>
    <button kendoButton (click)="onCancel($event)">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
