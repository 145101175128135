import { Observable } from "rxjs";
import { Component, OnInit, Inject, Input, SimpleChanges } from "@angular/core";
import { NgForm } from "@angular/forms";

import {
  GridDataResult,
  CancelEvent,
  EditEvent,
  GridComponent,
  RemoveEvent,
  SaveEvent,
  AddEvent,
} from "@progress/kendo-angular-grid";
import { State, process } from "@progress/kendo-data-query";

import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { common } from "src/app/framework/utils/common";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { MfSgBaseComponent } from "../../mf-sg-base.component";

@Component({
  selector: "mf-sg-qs-fire-interested-parties",
  templateUrl: "./mf-sg-qs-fire-interested-parties.component.html",
  styleUrls: ["./mf-sg-qs-fire-interested-parties.component.scss"],
})
export class MfSgQsFireInterestedPartiesComponent
  extends MfSgBaseComponent
  implements OnInit
{
  /*@Input() */ qs_fire_interestedParties!: QuestionEntity; //cannot use input, since it's old data; Input is not updated when parent changes
  interestedPartyGridLength = 30;
  hideInterestedPartiesActionCol: boolean = false;
  addButtonVisiiblity: boolean = false;
  labelTextInterestedParty: string = "Add New Interested party";

  public gridView!: GridDataResult;

  public view!: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };

  //private editService: EditService;
  private editedRowIndex?: number;
  //private editedProduct?: Product;

  constructor() {
    super();
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
  }

  public ngOnInit(): void {
    // this.view = this.editService.pipe(
    //   map((data) => process(data, this.gridState))
    // );
    // this.editService.read();
  }

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    //get latest mapped
    var updatedProposal = event.value as MortgageFireSingaporeProposal;
    this.qs_fire_interestedParties =
      updatedProposal!.qsMaster.qs_fire_interestedParties!;

    let isIBGProduct =
      updatedProposal.productType === EnumSG.MortgageFireSgProduct.IBGMF ||
      updatedProposal.productType === EnumSG.MortgageFireSgProduct.IBGMAC
        ? true
        : false;
    if (!isIBGProduct) {
      this.labelTextInterestedParty = "Add Additional Insured";
    }

    this.hideInterestedPartiesActionCol =
      updatedProposal!.qsMaster.qs_fire_interestedParties!.readOnly!;

    if (this.qs_fire_interestedParties.answer) {
      const obj = JSON.parse(this.qs_fire_interestedParties.answer);
      this.gridView = {
        data: obj,
        total: obj.length,
      };
    }

    if (
      this.qs_fire_interestedParties.readOnly !== undefined &&
      !this.qs_fire_interestedParties.readOnly
    ) {
      if (this.gridView?.total >= this.interestedPartyGridLength) {
        //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').hide();
        this.addButtonVisiiblity = false;
      } else {
        //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').show();
        this.addButtonVisiiblity = true;
      }
    } else {
      //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').hide();
      this.addButtonVisiiblity = false;
    }
    //angular.element('#qs_fire_interestedParties_table_grid .k-grid-myDelete span').addClass('k-icon k-delete');//todo
  };

  public onStateChange(state: State): void {
    this.gridState = state;

    //this.editService.read();
  }

  public addHandler(args: AddEvent, formInstance: NgForm): void {
    formInstance.reset();
    // close the previously edited item
    this.closeEditor(args.sender);
    // open a new item editor
    //args.sender.addRow(new Product());
  }

  public editHandler(args: EditEvent): void {
    // close the previously edited item
    this.closeEditor(args.sender);
    // track the most recently edited row
    // it will be used in `closeEditor` for closing the previously edited row
    this.editedRowIndex = args.rowIndex;
    // clone the current - `[(ngModel)]` will modify the original item
    // use this copy to revert changes
    //this.editedProduct = Object.assign({}, args.dataItem);
    // edit the row
    args.sender.editRow(args.rowIndex);
  }

  public cancelHandler(args: CancelEvent): void {
    // call the helper method
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler(args: SaveEvent): void {
    // update the data source
    //this.editService.save(args.dataItem, args.isNew);

    // close the editor, that is, revert the row back into view mode
    args.sender.closeRow(args.rowIndex);

    this.editedRowIndex = undefined;
    //this.editedProduct = undefined;
  }

  public removeHandler(args: RemoveEvent): void {
    // remove the current dataItem from the current data source
    // in this example, the dataItem is `editService`
    //this.editService.remove(args.dataItem);
  }

  private closeEditor(
    grid: GridComponent,
    rowIndex = this.editedRowIndex
  ): void {
    // close the editor
    grid.closeRow(rowIndex);
    // revert the data item to original state
    //this.editService.resetItem(this.editedProduct!);
    // reset the helpers
    this.editedRowIndex = undefined;
    //this.editedProduct = undefined;
  }
}
