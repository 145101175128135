import { ExceptionManager } from "./../../../framework/utils/exception-manager";
import { GUID } from "./../../../framework/domain-entity/guid";
import { ProposalEntity } from "./../base/proposal-entity.model";
import { QuestionEntity } from "./../base/question-entity.model";

class ProposalEntityFactory {
  /** Method to build a new ProposalEntity instance */
  build(
    questions: QuestionEntity[],
    productId?: GUID,
    proposalId?: GUID
  ): ProposalEntity {
    ExceptionManager.argumentNullGuard(questions, "questions");

    const entity = new ProposalEntity(proposalId, productId);
    entity.questions = questions;
    entity.refreshQuestionDictionary();

    return entity;
  }
}

export const proposalEntityFactory = new ProposalEntityFactory();
