import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewContainerRef,
  ViewChild,
} from "@angular/core";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { openItemDisplayStatus } from "src/app/finance/finance-constant";
import { BatchInvoiceListService } from "src/app/services/batch-invoice-list.service";
import { UpdateBatchInvoiceEntity } from "../models/batch-invoice-list.model";
import { NotifierService } from "src/app/services/notifier.service";

@Component({
  selector: "app-update-invoice-number",
  templateUrl: "./update-invoice-number.component.html",
})
export class UpdateInvoiceNumberComponent implements OnInit {
  @Input() public opened = false;
  @Input() openItemId!: string;
  @Output() closeDialogEvent = new EventEmitter<any>();
  private batchListing: UpdateBatchInvoiceEntity[] = [];
  public gridView!: GridDataResult;
  public updateSuccess: boolean = false;
  public isBatchIdError: boolean = false;
  public isInvoiceNoError: boolean = false;
  public enableUpdate: boolean = false;
  public enableGrid: boolean = false;
  public batchId!: number;
  public invoiceNo!: string;

  @ViewChild("appendTo", { read: ViewContainerRef, static: false })
  public appendTo!: ViewContainerRef;

  constructor(
    private batchInvoiceListService: BatchInvoiceListService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {}

  public close(status: any) {
    this.opened = false;
    this.closeDialogEvent.emit();
  }

  public onPolicySearch = () => {
    if (this.batchId > 0) {
      this.isBatchIdError = false;

      this.batchInvoiceListService
        .doSearchInvoiceNumberHandler(this.batchId)
        .subscribe(
          (response) => {
            this.batchListing = response.d.results;
            if (this.batchListing !== undefined) {
              this.enableGrid = true;
              if (this.batchListing.length > 0) {
                this.enableUpdate = true;
                this.batchListing.forEach((entity) => {
                  entity.statusDisplayText = this.translateStatus(
                    entity.status
                  );
                });
              } else {
                this.enableUpdate = false;
              }

              this.gridView = {
                data: this.batchListing,
                total: this.batchListing?.length,
              };
              this.notifier.success("Search successfully", this.appendTo);
            }
          },
          (err: any) => {
            this.notifier.error("Search failed", this.appendTo);
          }
        );
    } else {
      this.isBatchIdError = true;
      this.notifier.error("Status failed", this.appendTo);
    }
  };

  translateStatus = (e: any): string => {
    return openItemDisplayStatus[e];
  };

  public updateInvoiceNo = () => {
    this.isBatchIdError = false;
    this.isInvoiceNoError = false;

    if (this.batchId === null || this.batchId === 0) {
      this.isBatchIdError = true;
      return;
    }
    if (this.invoiceNo === "" || this.invoiceNo === undefined) {
      this.isInvoiceNoError = true;
      return;
    }
    if (!this.isBatchIdError && !this.isInvoiceNoError) {
      this.batchInvoiceListService
        .doUpdateInvoiceNumberHandler(this.batchId, this.invoiceNo)
        .subscribe(
          (response) => {
            this.updateSuccess = true;
            this.notifier.success("Update successfully", this.appendTo);
            setTimeout(() => {
              this.close("return");
            }, 1500);
          },
          (err: any) => {
            this.notifier.error("Update failed", this.appendTo);
          }
        );
    }
  };
}
