<div class="wrapper" id="main">
  <mf-hk-button-panel></mf-hk-button-panel>
  <mf-hk-field-validation-echo-panel></mf-hk-field-validation-echo-panel>
  <mf-hk-referral-validation-echo-panel></mf-hk-referral-validation-echo-panel>
  <mf-hk-pending-transaction-panel></mf-hk-pending-transaction-panel>
  <mf-hk-tabs id="mf-sg-tabs" [proposal]="this.proposal"> </mf-hk-tabs>
  <div class="row">
    <div class="col-md-12">
      <span
        class="floatRight chubbLightOrange_bg chubbBlack fontBold"
        style="padding: 5px 10px"
        >{{ productName }}</span
      >
      <span
        class="floatRight chubbRed_bg chubbWhite fontBold"
        *ngIf="proposal?.loanRedeemed"
        style="padding: 5px 10px"
        >Loan Redeemed</span
      >
    </div>
  </div>
  <mf-hk-header [proposal]="this.proposal"></mf-hk-header>
  <mf-hk-master [proposal]="this.proposal" *ngIf="isHaseProduct"></mf-hk-master>
  <mf-hk-premium-summary
    [proposal]="this.proposal"
    *ngIf="isHaseProduct"
  ></mf-hk-premium-summary>
  <app-mf-hk-clauses-and-deductibles
    [proposal]="this.proposal"
    *ngIf="isHaseProduct"
  ></app-mf-hk-clauses-and-deductibles>
</div>
<div
  class="back-to-top fa fa-3x fa-angle-double-up"
  (click)="scrollToTop()"
  style="display: block"
></div>
