import * as Enums from "./../../../../../../framework/enum.shared";

import { KeyValue } from "./../../../../../../framework/domain-entity/key-value";
import { MortgageFireHongKongBusinessRulesManager } from "./mf-hk-business-rules.manager";
import { MortgageFireHongKongFieldValidationManager } from "./mf-hk-field-validation.manager";
import { MortgageFireHongKongProposal } from "./mf-hk-proposal";
import { QuestionEntity } from "./../../../../base/question-entity.model";
import { pageLifeCycleNotifier } from "./../../../../notifier/page-life-cycle-notifier";
import { questionNotifier } from "./../../../../notifier/question-notifier";
import { Subscription } from "rxjs";

export class MortgageFireHongKongAnswerChangeManager {
  answerChangedSubscription: Subscription;

  constructor(
    private proposal: MortgageFireHongKongProposal,
    private fieldValidationManager: MortgageFireHongKongFieldValidationManager,
    private businessRulesManager: MortgageFireHongKongBusinessRulesManager
  ) {
    this.answerChangedSubscription =
      questionNotifier.answer_change_notifier$.subscribe(this.answerChanged);

    pageLifeCycleNotifier.page_unload_notifier$.subscribe((event: any) => {
      this.destroy();
    });
  }

  private answerChanged = (event: KeyValue<QuestionEntity>) => {
    const questionName = event.key;
    const questionEntity = event.value;
  };

  public destroy() {
    this.answerChangedSubscription.unsubscribe();
  }
}
