<div class="wrapper">
  <div style="padding-top: 30px">
    <!--Error Log -->
    <kendo-expansionpanel *ngIf="errorLogs.Logs.length > 0" [expanded]="false">
      <div class="container-fluid">
        <div class="row">
          <header
            class="fontBold chubbWhite"
            [innerHtml]="errorLogs.Title"
          ></header>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <ul>
                <li *ngFor="let log of errorLogs.Logs" [innerHtml]="log"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </kendo-expansionpanel>
    <!--Attachments -->
    <kendo-expansionpanel
      title="Multiyear downgrade Attachments"
      [expanded]="true"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label
                id="excelFilesErrorMessage"
                style="color: red"
                *ngIf="hasError"
                >{{ excelFilesErrorMessage }}</label
              >
              <kendo-fileselect
                [restrictions]="excelAndSizeRestrictions"
                (select)="select($event)"
                (remove)="removeEvent($event)"
              >
              </kendo-fileselect>
            </div>
          </div>
        </div>
        <div class="row">
          <button
            type="button"
            class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
            (click)="add()"
            [disabled]="isProcessing"
          >
            <i
              *ngIf="isProcessing"
              class="fa fa-circle-o-notch fa-spin fa-fw"
              aria-hidden="true"
            ></i>
            Upload Excel
          </button>
        </div>
      </div>
    </kendo-expansionpanel>
  </div>
</div>
<app-md-successful-file-upload
  *ngIf="redirectOpened"
  [opened]="redirectOpened"
  [message]="redirectMessage"
  [data]="redirectData"
  (closeDialogEvent)="confirmationModelForSuccessullExcelUpload($event)"
></app-md-successful-file-upload>
