<!-- [hidden] hide the rendered data, that is, it always kept the rendered data and show or hide on the basis of that directives. 
  However, *ngIf can only render data whenever the condition is true, 
  causing the this.broadcastService in <app-header-component> not loaded properly  -->
<app-header-component [hidden]="!showHeader()"></app-header-component>
<!-- <app-horiz-menu *ngIf="isLoggedIn()"></app-horiz-menu> -->
<!--render Selector as an Attribute, to prevent <app-home> nested-->
<div
  app-horiz-menu
  class="menu-container"
  *ngIf="showHorizMenu()"
  class="no-scroll-x"
></div>
<app-customer-login
  *ngIf="policyCustomerLoginService.isCustomerLogin && !isLoggedIn()"
></app-customer-login>
<app-customer-enter-otp
  *ngIf="policyCustomerLoginService.isCustomerOTP && !isLoggedIn()"
></app-customer-enter-otp>
<app-customer-accept-offer
  *ngIf="policyCustomerLoginService.isCustomerOffer && !isLoggedIn()"
></app-customer-accept-offer>
<app-footer *ngIf="isLoggedIn()"></app-footer>
<!-- this is for DialogWrapperService -->
<div kendoDialogContainer></div>
