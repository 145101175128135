import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ComboBoxComponent } from "@progress/kendo-angular-dropdowns";
import { common } from "src/app/framework/utils/common";
import { ClausesandDeductibles } from "src/app/models/new-product/base/Clauses-Deductibles.model";

@Component({
  selector: "app-clauses-deductibles",
  templateUrl: "./clauses-deductibles.component.html",
  styleUrls: ["./clauses-deductibles.component.scss"],
})
export class ClausesDeductiblesComponent implements OnInit {
  @ViewChild("comboBox") comboBox!: ComboBoxComponent;
  @Input() resultList: ClausesandDeductibles[] = [];
  @Input() nonDefaultList: ClausesandDeductibles[] = [];
  @Input() masterList: ClausesandDeductibles[] = [];
  @Input() isreadonly?: boolean;
  @Input() isAnswered?: boolean;

  @Output() resultListDataChange: EventEmitter<{
    item: ClausesandDeductibles;
    type: number;
    action: string;
  }> = new EventEmitter<{
    item: ClausesandDeductibles;
    type: number;
    action: string;
  }>();

  selectNonDefault: string = "";
  selectedDeductible: ClausesandDeductibles = {};
  newValue: ClausesandDeductibles = {};
  showAddMore: boolean = true;
  addMoreExpanded: boolean = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onDropdownchange(event: any): void {
    if (this.selectedDeductible.Title != "") {
      this.nonDefaultList = this.nonDefaultList.filter(
        (item) =>
          item.ClausesDeductiblesID !==
          this.selectedDeductible.ClausesDeductiblesID
      );

      const DeductiblesType = this.selectedDeductible.ClauseDeductibleType ?? 0;

      this.resultListDataChange.emit({
        item: this.selectedDeductible,
        type: DeductiblesType,
        action: "add",
      });

      this.comboBox.clearValue();
    }
  }

  protected removeItem(selectedValue: ClausesandDeductibles): void {
    this.resultList = this.resultList.filter(
      (item) => item.ClausesDeductiblesID !== selectedValue.ClausesDeductiblesID
    );

    const Value =
      this.masterList.find(
        (item) =>
          item.ClausesDeductiblesID == selectedValue.ClausesDeductiblesID
      ) ?? {};

    if (common.isDefined(Value.ClausesDeductiblesID)) {
      this.nonDefaultList.push(selectedValue);

      const DeductiblesType = selectedValue.ClauseDeductibleType ?? 0;
      this.resultListDataChange.emit({
        item: selectedValue,
        type: DeductiblesType,
        action: "remove",
      });
    } else {
      const DeductiblesType = selectedValue.ClauseDeductibleType ?? 0;
      this.resultListDataChange.emit({
        item: selectedValue,
        type: DeductiblesType,
        action: "remove",
      });
    }
  }

  public openDropdown() {
    this.comboBox.toggle();
  }

  toggleAddMore(): void {
    this.addMoreExpanded = !this.addMoreExpanded;
    this.showAddMore = !this.addMoreExpanded;
  }
}
