<div *ngIf="hasQuoteNumber()">
  <div>
    <div class="container-fluid-customized">
      <div class="row">
        <div class="col-md-12 card-mod cardNoBorder ng-scope">
          <div class="card-body card-body-mod">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-2">
                  <form role="form">
                    <div class="form-group">
                      <label
                        class="fontSize14px"
                        [textContent]="'Policy Number:'"
                      ></label>
                      <div>
                        <p *ngIf="proposal!.policyNumber !== ''">
                          {{ proposal!.policyNumber }}
                        </p>
                        <span
                          class="unattended"
                          *ngIf="proposal!.policyNumber === ''"
                          >Unknown</span
                        >
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-2">
                  <form role="form">
                    <div class="form-group fontSize14px">
                      <label
                        class="fontSize14px"
                        [textContent]="'Quote Number:'"
                      ></label>
                      <div>
                        <p *ngIf="proposal!.quoteNumber !== ''">
                          {{ proposal!.quoteNumber }}
                        </p>
                        <span
                          class="unattended"
                          *ngIf="proposal!.quoteNumber === ''"
                          >Unknown</span
                        >
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-2" *ngIf="displayChecker">
                  <form role="form">
                    <div class="form-group fontSize14px">
                      <label
                        class="fontSize14px"
                        [textContent]="'Checker Name:'"
                      ></label>

                      <div>
                        <p>{{ proposal!.checkerUserName }}</p>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-2" *ngIf="displayMaker">
                  <form role="form">
                    <div class="form-group fontSize14px">
                      <label
                        class="fontSize14px"
                        [textContent]="'Maker Name:'"
                      ></label>
                      <div>
                        <p>{{ proposal!.makerUserName }}</p>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-2" *ngIf="displayOriginalPolicyNo">
                  <form role="form">
                    <div class="form-group fontSize14px">
                      <label
                        class="fontSize14px"
                        [textContent]="'Original Policy Number:'"
                      ></label>
                      <div>
                        <p>
                          {{
                            proposal!.qsMaster.qs_master_replacePolicyNo!.answer
                          }}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-md-2">
                  <form role="form">
                    <div class="form-group">
                      <label
                        class="fontSize14px"
                        [textContent]="'Status:'"
                      ></label>
                      <div>
                        <p *ngIf="proposal!.proposalStatus !== undefined">
                          <span id="proposalStatusText" class="chubbRed">{{
                            proposalStatusText
                          }}</span>
                        </p>
                        <span
                          class="unattended"
                          style="padding: 2px 10px"
                          *ngIf="proposal.proposalStatus === undefined"
                          >Unknown</span
                        >
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  *ngIf="isDisplay"
                  [ngClass]="{
                    'col-md-3': proposal.documents.length <= 0,
                    'col-md-2': proposal.documents.length > 0
                  }"
                >
                  <form role="form">
                    <div class="form-group">
                      <label
                        class="fontSize14px"
                        [textContent]="'Transaction Type:'"
                      ></label>
                      <div>
                        <p *ngIf="proposal!.transType !== undefined">
                          {{ transTypeText }}
                        </p>
                        <span
                          class="unattended"
                          *ngIf="proposal!.transType === undefined"
                          >Unknown</span
                        >
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  *ngIf="proposal.documents.length !== 0"
                  [ngClass]="{
                    'col-md-4': !displayOpenItem,
                    'col-md-2': displayOpenItem
                  }"
                >
                  <form role="form" id="header-doc">
                    <div class="form-group">
                      <label
                        class="fontSize14px"
                        [textContent]="'Documents:'"
                      ></label>
                      <div *ngFor="let document of proposal.documents">
                        <span (click)="downloadDocument(document.key)">
                          <span
                            class="fa fa-file-pdf-o cursorPointer marginRight5"
                          ></span>
                          <span class="cursorPointer">{{
                            document.value
                          }}</span>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-3" *ngIf="displayOpenItem">
                  <form role="form" id="paymentHistory">
                    <div class="form-group">
                      <label
                        class="fontSize14px"
                        [textContent]="'Payment History:'"
                      ></label>
                      <span
                        class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                        (click)="showDetails()"
                        style="padding-left: 5px; padding-right: 5px"
                        >History Details</span
                      >
                      <div>
                        <p>{{ transOpenItemStatus }}</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mf-hk-open-item-details
  *ngIf="openItemDetailsOpened"
  [opened]="openItemDetailsOpened"
  [openItemId]="openItemId"
  (closeDialogEvent)="confirmationModalCloseForActions($event)"
>
</mf-hk-open-item-details>
