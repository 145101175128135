<div row padding5 *ngIf="isProcessing">
  <div class="margin5 textAlignCenter">
    <span class="loadingBarText">Please Wait</span>&nbsp;
    <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
  </div>
</div>
<div class="container-fluid">
  <!--this nativeElement is better visisble always, hence not putting it under id="errorMessage" -->
  <div #modal_body class="row">
    <div
      id="myModal"
      class="marginBottom10 col-md-12"
      *ngIf="showErrorMessage"
      role="dialog"
    >
      <div class="">
        <!-- Modal content-->
        <div class="modal-content" style="border-color: #afafaf">
          <div
            class="modal-header"
            style="
              border-bottom-color: #afafaf;
              padding-top: 5px;
              padding-bottom: 5px;
            "
          >
            <h4 class="modal-title chubbRed">
              Please complete all MANDATORY fields :
            </h4>
          </div>
          <div class="modal-body paddingTop5">
            <div class="row">
              <div id="errorMessage" [innerHtml]="errorMsgInHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="createNewClient" class="masterPage" *ngIf="!isProcessing">
  <div class="container-fluid" id="clientPersonalInformation">
    <div class="row" *ngIf="!isChangeOfCorrespondenceAddressType">
      <div class="col-md-3"></div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-10"></div>
          <div class="col-md-2">
            <span
              [attr.disabled]="isChangeOfCorrespondenceAddressType"
              class="cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
              (click)="updateAll()"
              style="
                width: 80px;
                padding-left: 5px;
                padding-right: 5px;
                display: block;
              "
              >Refresh All</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isChangeOfCorrespondenceAddressType">
      <div class="col-md-3">
        <input
          name="{{
            proposalService.proposal.qsMaster.qs_master_preferredDeliveryMethod!
              .key
          }}"
          type="radio"
          value="5"
          [(ngModel)]="preferredDeliveryMethod"
          [disabled]="
            proposalService.proposal.qsMaster.qs_master_preferredDeliveryMethod!
              .isDisabled || isChangeOfCorrespondenceAddressType
          "
        />
        <span>SMS</span>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-10">
            <label
              [ngClass]="{ required: preferredDeliveryMethod == 5 }"
              [textContent]="'Mobile Number'"
            ></label>
            <input
              id="brokerMobileNo"
              name="brokerMobileNo"
              type="text "
              class="k-textbox form-control"
              [disabled]="isChangeOfCorrespondenceAddressType"
              ng-blur="phoneValidation('brokerMobileNo')"
              [(ngModel)]="clientData.mobileNumber"
            />
          </div>
          <div class="col-md-1">
            <label [textContent]=""></label>
            <span
              [attr.disabled]="isChangeOfCorrespondenceAddressType"
              class="cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
              (click)="updateClientMobile()"
              style="width: 70px; padding-left: 5px; padding-right: 5px"
              >Refresh</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!isChangeOfCorrespondenceAddressType">
      <div class="col-md-3">
        <div class="form-group">
          <input
            name="{{
              proposalService.proposal.qsMaster
                .qs_master_preferredDeliveryMethod!.key
            }}"
            type="radio"
            value="2"
            [(ngModel)]="preferredDeliveryMethod"
            [disabled]="
              proposalService.proposal.qsMaster
                .qs_master_preferredDeliveryMethod!.isDisabled ||
              isChangeOfCorrespondenceAddressType
            "
          />
          <span>Email</span>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-10">
            <label
              [ngClass]="{ required: preferredDeliveryMethod == 2 }"
              [textContent]="'Email'"
            ></label>
            <input
              id="brokerEmail"
              type="text"
              name="brokerEmail"
              value=""
              [disabled]="isChangeOfCorrespondenceAddressType"
              class="k-textbox form-control"
              [(ngModel)]="clientData.emailAddress"
              ng-blur="emailValidation('brokerEmail')"
            />
          </div>

          <div class="col-md-1">
            <label [textContent]=""></label>
            <span
              class="cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
              (click)="updateClientEmail()"
              [attr.disabled]="isChangeOfCorrespondenceAddressType"
              style="width: 70px; padding-left: 5px; padding-right: 5px"
              >Refresh</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <hr />
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <input
            name="{{
              proposalService.proposal.qsMaster
                .qs_master_preferredDeliveryMethod!.key
            }}"
            type="radio"
            value="1"
            [(ngModel)]="preferredDeliveryMethod"
            *ngIf="!isChangeOfCorrespondenceAddressType"
            [disabled]="
              proposalService.proposal.qsMaster
                .qs_master_preferredDeliveryMethod!.isDisabled!
            "
          />
          <span>No Mobile/Email or Opt Out</span>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-12">
            <label
              class="floatLeft marginRight10"
              [textContent]="
                'Street Address Search – Please enter only the street address and suburb, do not include building name or suite/lot/unit/shop number or level '
              "
            ></label>
            <div
              style="font-size: 12px"
              class="cursorPointer floatLeft chubbWhite"
            >
              <span
                class="chubbGreen_bg"
                style="padding: 0px 5px 1px 5px; vertical-align: super"
                (click)="openAddressHelp($event)"
                >Help</span
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10">
            <!-- <input
              id="clientInformation_autocomplete"
              #clientInformation_autocomplete
              placeholder="Enter your address"
              type="text "
              class="k-textbox"
              style="width: 100%"
            /> -->
            <input
              id="clientInformation_autocomplete"
              ngx-google-places-autocomplete
              [options]="options"
              #clientInformation_autocomplete="ngx-places"
              (onAddressChange)="
                updateAddressValues(
                  $event,
                  {},
                  'clientInformation_autocomplete'
                )
              "
              placeholder="Enter your address "
              type="text "
              style="width: 100%"
            />
          </div>
          <div class="col-md-2">
            <span
              class="cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
              (click)="updateClientAddress()"
              style="width: 70px; padding-left: 5px; padding-right: 5px"
              >Refresh</span
            >
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4">
              <form role="form ">
                <div class="form-group">
                  <label
                    class=" "
                    [textContent]="'Suite/Lot/Unit Number'"
                  ></label>
                  <div>
                    <input
                      id="insuredAddress_lotunit"
                      #insuredAddress_lotunit
                      type="text "
                      value=""
                      class="k-textbox form-control"
                      [(ngModel)]="address.unitNo"
                      name="insuredAddress_lotunit"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <form role="form ">
                <div class="form-group">
                  <label class=" " [textContent]="'Building Name'"></label>
                  <div>
                    <input
                      id="insuredAddress_building"
                      #insuredAddress_building
                      type="text "
                      value=""
                      class="k-textbox form-control"
                      [(ngModel)]="address.buildingName"
                      name="insuredAddress_building"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4">
              <form role="form ">
                <div class="form-group">
                  <label
                    [ngClass]="{ required: preferredDeliveryMethod == 1 }"
                    [textContent]="'Address Line 1'"
                  ></label>
                  <div *ngIf="!addAddress1RO">
                    <input
                      id="insuredAddress1"
                      #insuredAddress1
                      type="text "
                      value=""
                      class="k-textbox form-control"
                      maxlength="100"
                      [(ngModel)]="address.addressLine1"
                      name="insuredAddress1"
                    />
                  </div>
                  <div *ngIf="addAddress1RO">
                    <span
                      class="unattended"
                      [textContent]="addressDataIfUnknown(address.addressLine1)"
                    ></span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <form role="form ">
                <div class="form-group">
                  <label class="" [textContent]="'Address Line 2'"></label>

                  <div *ngIf="!addAddress2RO">
                    <input
                      id="insuredAddress2"
                      #insuredAddress2
                      type="text "
                      value=""
                      class="k-textbox form-control"
                      maxlength="100"
                      [(ngModel)]="address.addressLine2"
                      name="insuredAddress2"
                    />
                  </div>
                  <div *ngIf="addAddress2RO">
                    <span
                      class="unattended"
                      [textContent]="addressDataIfUnknown(address.addressLine2)"
                    ></span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4">
              <form role="form ">
                <div class="form-group">
                  <label class="" [textContent]="'Postcode'"></label>

                  <div *ngIf="!addPostcodeRO">
                    <input
                      id="insuredPostalCode"
                      #insuredPostalCode
                      type="text "
                      value=""
                      class="k-textbox form-control"
                      maxlength="6"
                      [(ngModel)]="address.postCode"
                      name="insuredPostalCode"
                    />
                  </div>
                  <div *ngIf="addPostcodeRO">
                    <span
                      class="unattended"
                      [textContent]="addressDataIfUnknown(address.postCode)"
                    ></span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <form role="form ">
                <div class="form-group">
                  <label class=" " [textContent]="'City/Suburb'"></label>
                  <div *ngIf="!addCityRO">
                    <input
                      id="insuredCity"
                      #insuredCity
                      type="text "
                      value=""
                      class="k-textbox form-control"
                      [(ngModel)]="address.city"
                      name="insuredCity"
                    />
                  </div>
                  <div *ngIf="addCityRO">
                    <span
                      class="unattended"
                      [textContent]="addressDataIfUnknown(address.city)"
                    ></span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4">
              <form role="form ">
                <div class="form-group">
                  <label class=" " [textContent]="'State'"></label>
                  <div *ngIf="!addStateRO">
                    <input
                      id="insuredState"
                      #insuredState
                      type="text "
                      value=""
                      class="k-textbox form-control"
                      [(ngModel)]="address.stateName"
                      name="insuredState"
                    />
                  </div>
                  <div *ngIf="addStateRO">
                    <span
                      class="unattended"
                      [textContent]="addressDataIfUnknown(address.stateName)"
                    ></span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <form role="form ">
                <div class="form-group">
                  <label
                    [ngClass]="{ required: preferredDeliveryMethod == 1 }"
                    [textContent]="'Country'"
                    ng-value=""
                  ></label>
                  <div *ngIf="!addCountryRO">
                    <kendo-dropdownlist
                      id="clientCountry"
                      class="maxWidth"
                      [kendoDropDownFilter]="{
                        operator: 'contains'
                      }"
                      [data]="countryList"
                      textField="key"
                      valueField="value"
                      [valuePrimitive]="true"
                      [(ngModel)]="address.countryISO2"
                      name="createdByUserId"
                    ></kendo-dropdownlist>
                  </div>

                  <div *ngIf="addCountryRO">
                    <span
                      class="unattended"
                      [textContent]="
                        addressCountryIfUnknown(address.countryISO2)
                      "
                    ></span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-2 floatLeft">
              <form role="form ">
                <div class="form-group">
                  <div
                    class="btn-box chubbWhite chubbRed_bg"
                    (click)="resetClientAddressForm()"
                  >
                    Reset
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
