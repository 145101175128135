import { Component, OnInit, ViewChild } from "@angular/core";
import {
  SelectEvent,
  TabAlignment,
  TabStripComponent,
} from "@progress/kendo-angular-layout";

@Component({
  selector: "app-configurables",
  templateUrl: "./configurables.component.html",
  styleUrls: ["./configurables.component.scss"],
})
export class ConfigurablesComponent implements OnInit {
  @ViewChild("tabstrip") public tabstrip!: TabStripComponent;

  public alignment: TabAlignment = "justify";

  constructor() {}

  ngOnInit(): void {}

  onTabSelect(e: SelectEvent) {}

  selectFirstTab(val: any): void {
    this.tabstrip.selectTab(0);
  }
}
