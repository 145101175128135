<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group row">
        <div class="col-md-1">
          <label class="mt-2" for="reportType">Report</label>
        </div>
        <div class="col-md-5">
          <kendo-dropdownlist
            [data]="reportOptions"
            textField="text"
            valueField="value"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [valuePrimitive]="true"
            [(ngModel)]="presetReportType"
            (valueChange)="on_reportType_change($event)"
            name="presetReportType"
          >
          </kendo-dropdownlist>
        </div>
      </div>
    </div>
  </div>
</div>
<app-auto-debit
  *ngIf="
    presetReportType === 13 ||
    presetReportType === 14 ||
    presetReportType === 15
  "
  (warningZoneEvent)="passWarningZoneEvent($event)"
  [presetReportType]="presetReportType"
>
</app-auto-debit>
<app-bank-trans
  *ngIf="presetReportType === 4"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-bank-trans>
<app-citi-monthly-report
  *ngIf="presetReportType === 20"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-citi-monthly-report>
<app-citi-weekly-invoice
  *ngIf="presetReportType === 19"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-citi-weekly-invoice>
<app-cbg-daily-download
  *ngIf="
    presetReportType === 1 || presetReportType === 2 || presetReportType === 3
  "
  (warningZoneEvent)="passWarningZoneEvent($event)"
  [presetReportType]="presetReportType"
>
</app-cbg-daily-download>
<app-print-document-report
  *ngIf="presetReportType === 11"
  (warningZoneEvent)="passWarningZoneEvent($event)"
></app-print-document-report>
<app-print-datapost-report
  *ngIf="presetReportType === 12"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-print-datapost-report>
<app-lst-report
  *ngIf="presetReportType === 16"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-lst-report>
<app-ibg-renewal-report
  *ngIf="presetReportType === 8"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-ibg-renewal-report>
<app-loan-redemption-report
  [presetReportType]="presetReportType"
  *ngIf="presetReportType === 17 || presetReportType === 18"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-loan-redemption-report>
<app-manual-paid-auto-debit
  *ngIf="presetReportType === 5"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-manual-paid-auto-debit>
<app-renewal-report
  *ngIf="presetReportType === 6 || presetReportType === 7"
  [presetReportType]="presetReportType"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-renewal-report>
<app-summary-invoice-auto-debit-report
  *ngIf="presetReportType === 9"
  (warningZoneEvent)="passWarningZoneEvent($event)"
>
</app-summary-invoice-auto-debit-report>
