import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { UserProfileService } from "src/app/services/user-profile.service";
import { ApiModel, PersonModel, UserProfileModel } from "../user-profile.model";
import { EditPersonalDetailsComponent } from "./edit-personal-details/edit-personal-details.component";
import { EditUserProfileComponent } from "./edit-user-profile/edit-user-profile.component";
@Component({
  selector: "app-view-user",
  templateUrl: "./view-user.component.html",
  styleUrls: ["./view-user.component.scss"],
})
export class ViewUserComponent implements OnInit {
  @Input() public data: any;
  @ViewChild(EditUserProfileComponent)
  editUserProfileData!: EditUserProfileComponent;
  @ViewChild(EditPersonalDetailsComponent)
  editPersonalData!: EditPersonalDetailsComponent;
  personalDetails!: PersonModel;
  userDetails?: ApiModel;
  tabIndex = 0;
  title = "View User Details";
  public accessControlList = new Array<{ text: string; value: number }>();
  public teamsData = new Array<{ text: string; value: number }>();
  constructor(
    public dialog: DialogRef,
    private userProfileService: UserProfileService
  ) {}
  ngOnInit(): void {
    this.getUserTeam();
    this.getAccessControlGroupsList();
  }
  onTabSelect(e: SelectEvent) {
    this.tabIndex = e.index;
  }
  edit(): void {
    this.data.mode = "edit";
    this.title = "Edit User Details";
  }
  resetEdit(): void {
    this.data.mode = "view";
    this.title = "View User Details";
  }
  saveEdit(): void {
    if (this.tabIndex === 0) {
      if (this.editUserProfileData?.validateFormData()) {
        let model: UserProfileModel = this.editUserProfileData?.saveData();
        this.userProfileService.saveUserDetails(model).subscribe((response) => {
          if (response && response.body) {
            if (response.body.status === "success") {
              this.dialog.close(response.body.status);
            }
          }
        });
      }
    } else {
      if (this.editPersonalData?.validateFormData()) {
        let model: UserProfileModel = this.editPersonalData?.saveData();
        this.userProfileService.saveUserDetails(model).subscribe((response) => {
          if (response && response.body) {
            if (response.body.status === "success") {
              this.dialog.close(response.body.status);
            } else
              this.editPersonalData.showServerValidation(
                response.body.invalidDataErrorList
              );
          }
        });
      }
    }
  }
  private getAccessControlGroupsList() {
    this.userProfileService.getAccessControlGroups().subscribe((result) => {
      this.accessControlList = result?.items;
    });
  }
  private getUserTeam(): void {
    this.userProfileService.getUserTeam().subscribe((result: any) => {
      result.items.forEach((item: any) => {
        this.teamsData.push({ text: item.o, value: item.i });
      });
    });
  }
}
