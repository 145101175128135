// import { Application } from '../../application';
// import * as Enum from '../../framework/enum.shared';
//import * as Interface from "../../extensions/framework/interface.shared";
import * as Interface from "../../framework/interface.shared";
// import { Global } from '../global';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
//import { common } from "src/app/core/extensions/framework/utils/common";
import { common } from "src/app/framework/utils/common";
import { ErrorHandlerService } from "../error-handler/error-handler.service";
import { DialogWrapperService } from "../dialog/dialog-wrapper.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

/*

 http://www.restapitutorial.com/lessons/httpmethods.html

 HTTP Verb	Entire Collection (e.g. /customers)	Specific Item (e.g. /customers/{id})
 GET	200 (OK), list of customers. Use pagination, sorting and filtering to navigate big lists.	200 (OK),
 single customer. 404 (Not Found), if ID not found or invalid.
 PUT	404 (Not Found), unless you want to update/replace every resource in the entire collection.
 200 (OK) or 204 (No Content). 404 (Not Found), if ID not found or invalid.
 POST	201 (Created), 'Location' header with link to /customers/{id} containing new ID.	404 (Not Found).
 DELETE	404 (Not Found), unless you want to delete the whole collection—not often desirable.	200 (OK).
 404 (Not Found), if ID not found or invalid.

 * */
//const angular: ng.IAngularStatic = Application.angular;

@Injectable({
  providedIn: "root",
})
export class ApiService implements Interface.IApi {
  constructor(
    // private $q: ng.IQService,
    protected httpClient: HttpClient,
    // private $resource: ng.resource.IResourceService, // private $timeout: ng.ITimeoutService, // private Navigator: any, // private appSettings: Interface.IAppSettings, // private Debugger: any,
    protected errorHandlerService: ErrorHandlerService,
    // private AuthenticationEvent: any,
    //private localStorageService: LocalStorageService,
    protected dialog: DialogWrapperService
  ) {}

  // private static _instance: Interface.IApi;
  // private dialog: any;
  // private modalInstance: any;

  // static get instance(): Interface.IApi {
  //   if (Global.isUndefined(this._instance)) {
  //     if (Application.mode === Enum.ApplicationMode.browser) {
  //       this._instance = Application.angularInjectionObject<Interface.IApi>('Api');
  //     } else {
  //       throw new Error('not browser mode Api mock not implemented yet');
  //     }
  //   }

  //   return this._instance;
  // }

  private errorHandling(error: any) {
    //todo
    //Global.closeProcessingOverlay();
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    switch (error.status) {
      case 403:
        //todo: trigger dialog service
        // Application.angular_Identity().clearAuthData();
        // const State = Application.angular_$state();
        // this.dialog = Application.angularInjectionObject<any>('Dialog');
        //todo: new dlg template
        this.dialog.modalMedium(
          "shared/partials/mdSharedLogoutMessage/SharedLogoutMessage.html",
          "SharedLogoutMessageController",
          errorMessage,
          this.modalLogout_OnClose,
          undefined,
          "",
          "",
          undefined,
          "standardModal"
        );
        break;
      default:
        throw error;
    }

    return throwError(() => {
      return errorMessage;
    });
  }

  // tslint:disable-next-line: variable-name
  private modalLogout_OnClose = () => {
    // do nothing
  };

  private responseHandling<T>(response: any): T {
    // handling for web service end point consume
    if (environment.responseType.toLowerCase() === "webserviceresultdata") {
      if (response.s !== 200) {
        // not OK http status
        this.errorHandlerService.handle(response.e.m, true, response.e.t);
        return response as T;
      } else {
        return JSON.parse(response.d) as T;
      }
    } else {
      return response as T;
    }
  }

  // private httpResponseHandling<T>(response: ng.IHttpPromiseCallbackArg<T>, serviceUri: string): T {
  //   const httpHeader = response.headers();

  //   switch (response.status) {
  //     case 200:
  //       return response.data as T;

  //     default:
  //       console.log(httpHeader);
  //       throw new Error('http response status: ' + response.data.toString());
  //   }
  // }

  /** Concantenate server uri with end point */
  protected constructEndPoint(serviceUri: string): string {
    return environment.serviceBase + serviceUri; //this.appSettings.apiBaseUri + serviceUri;
  }

  /** Amend paramters before process */
  private processParams(parameters: any, cache: boolean = false) {
    if (common.isDefined(parameters)) {
      if (!cache) {
        // add a busting parameters to prevent $http cache issue
        parameters["cachekill"] = new Date().getTime();
      }
    } else {
      if (!cache) {
        parameters = {
          cachekill: new Date().getTime(),
        };
      }
    }
  }

  getApiData<T>(uri: string, cache: boolean = true): Observable<T> {
    let serviceUri = this.constructEndPoint(uri);
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
    });

    return this.httpGetCall(headers, serviceUri, { cache: cache }).pipe(
      catchError(this.errorHandling)
    );
  }

  /** Method for get object request by posting query string as input. GET method */
  get<T>(
    serviceUri: string,
    parameters: any,
    postData: any,
    isServiceApiEndPoint: boolean = true,
    cache: boolean = false
  ): Observable<T> {
    this.processParams(parameters, cache);
    if (isServiceApiEndPoint) {
      serviceUri = this.constructEndPoint(serviceUri);
    }

    return this.httpGetCall(
      //TODO: sosola
      //this.getHeaderAsJson(),
      this.getHeaderAsEncodedFormUrl(),

      serviceUri,
      parameters
    ).pipe(catchError(this.errorHandling));
  }

  // /** Method for add new item request by posting object as input. POST method */
  add<T>(serviceUri: string, parameters: any, postData: any): Observable<T> {
    return this.search(serviceUri, parameters, postData);
  }

  /** Method for search request by posting object as input. POST method */
  search(serviceUri: string, parameters: any, postData: any) {
    serviceUri = this.constructEndPoint(serviceUri);
    //https://www.freakyjolly.com/angular-promises-example-with-http-handler/
    return this.httpPostCall(this.getHeaderAsJson(), serviceUri, postData);

    // .toPromise();

    // return promise.then(
    //   (response: any) => {
    //     // Success callback
    //     return this.responseHandling<T>(response);
    //   },
    //   (error: any) => {
    //     // Error callback
    //     return this.errorHandling(error, serviceUri);
    //   }
    // );
  }

  protected getHeaderAsEncodedFormUrl(): HttpHeaders {
    return new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
  }

  protected getHeaderAsJson(): HttpHeaders {
    return new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      "Content-Type": "application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
  }

  protected getHeaderForAuthentication(): HttpHeaders {
    return new HttpHeaders({
      // Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/x-www-form-urlencoded",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
  }

  protected httpPostCall(
    headers: HttpHeaders,
    serviceUri: string,
    postData: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(
        /*AppSettings.appSettings.apiBaseUri*/
        serviceUri,
        postData,
        {
          headers: headers,
          reportProgress: true,
          //observe: "events",
          observe: "response",
        }
      )
      .pipe(catchError(this.errorHandling));
  }

  protected httpPostCallLogin(
    headers: HttpHeaders,
    serviceUri: string,
    postData: any,
    transformRequest: string
  ) {
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      serviceUri,
      transformRequest,
      {
        headers: headers,
        reportProgress: true,
        //observe: "events",
        observe: "response",
      }
    );
  }

  protected httpGetCall(
    headers: HttpHeaders,
    serviceUri: string,
    params?: any
  ) {
    return this.httpClient
      .get<any>(serviceUri, {
        headers: headers, //,

        params: params,
      })
      .pipe(catchError(this.errorHandling));
  }
  protected httpDownloadCall(
    headers: HttpHeaders,
    serviceUri: string,
    params: any
  ) {
    return this.httpClient
      .get(serviceUri, {
        headers: headers, //,
        params: params,
        observe: "response",
        responseType: "blob",
      })
      .pipe(catchError(this.errorHandling));
  }

  update2<T>(serviceUri: string, body: any) {
    const headers: HttpHeaders = this.getHeaderAsJson();
    serviceUri = this.constructEndPoint(serviceUri);
    return this.httpClient
      .put<any>(serviceUri, body, { headers })
      .pipe(catchError(this.errorHandling));
  }

  protected httpPutCall(headers: HttpHeaders, serviceUri: string, params: any) {
    return this.httpClient.put<any>(serviceUri, {
      headers: headers,
      params: params,
    });
  }

  // /** Method for update data request by posting object as input. PUT method */
  update<T>(serviceUri: string, parameters: any, postData: any): Observable<T> {
    serviceUri = this.constructEndPoint(serviceUri);
    return this.httpPutCall(
      //soso: commented: this.getHeaderAsJson(),
      this.getHeaderAsEncodedFormUrl(),
      serviceUri,
      postData
    ).pipe(catchError(this.errorHandling));

    // return promise.then(
    //   (response: any) => {
    //     // Success callback
    //     return this.responseHandling<T>(response);
    //   },
    //   (error: any) => {
    //     // Error callback
    //     return this.errorHandling(error, serviceUri);
    //   }
    // );
  }

  protected httpDeleteCall(
    headers: HttpHeaders,
    serviceUri: string,
    params: any
  ) {
    return this.httpClient.delete<any>(serviceUri, {
      headers: headers,
      params: params,
    });
  }

  /** Method for delete data request by posting object as input. PUT method */
  delete<T>(serviceUri: string, parameters: any, postData: any): Observable<T> {
    serviceUri = this.constructEndPoint(serviceUri);

    return this.httpDeleteCall(
      this.getHeaderAsJson(),
      serviceUri,
      postData
    ).pipe(catchError(this.errorHandling));

    // return promise.then(
    //   (response: any) => {
    //     // Success callback
    //     return this.responseHandling<T>(response);
    //   },
    //   (error: any) => {
    //     // Error callback
    //     return this.errorHandling(error, serviceUri);
    //   }
    // );
  }

  // /** Method for get object request by posting query string as input, return in Array. GET method */
  // query<T>(serviceUri: string, parameters: any, postData: any):
  //   angular.IPromise<angular.resource.IResourceArray<angular.resource.IResource<T>>> {

  //   serviceUri = this.constructEndPoint(serviceUri);
  //   const endpoint = this.$resource(serviceUri, parameters);

  //   return endpoint.query(postData).$promise
  //     .then((responseArray: angular.resource.IResourceArray<angular.resource.IResource<T>>) => {
  //       return responseArray;
  //     }, (error) => {
  //       return this.errorHandling(error, serviceUri);
  //     });
  // }

  // /** Method for get object request by posting query string as input.
  //  * Authorization token will be read from storage again.
  //  * $http GET method
  //  */
  // // CMWANX: This funciton currently only used in Proposal.getProductbyUser()
  // getWithAuth<T>(serviceUri: string, parameters: any, postData: any): angular.IPromise<T> {
  //   serviceUri = this.constructEndPoint(serviceUri);
  //   const authData: Interface.IAuthenticationData = this.localStorageService.get(Identity.AUTH_STORAGE_NAME);

  //   const config = {
  //     method: 'Get',
  //     url: serviceUri,
  //     headers: {
  //       Accept: 'application/octet-stream;',
  //       Authorization: 'Bearer ' + authData.token,
  //     },
  //     params: parameters
  //   };

  //   return this.$http(config)
  //     .then((response: ng.IHttpPromiseCallbackArg<T>) => {
  //       return this.httpResponseHandling<T>(response, serviceUri);
  //     }, (error) => {
  //       return this.errorHandling(error, serviceUri);
  //     });
  // }

  //todo
  /** Method for get Blob request by posting query string as input.
   * Authorization token will be read from storage again.
   * Anchor element will be auto generated.
   * $http GET method
   */
  download(
    serviceUri: string,
    parameters?: any,
    postData?: any,
    isServiceApiEndPoint: boolean = true,
    cache: boolean = false
  ): Observable<any> {
    //const deferred = this.$q.defer<any>();
    //serviceUri = this.constructEndPoint(serviceUri);
    //const authData: Interface.IAuthenticationData = this.localStorageService.get(Identity.AUTH_STORAGE_NAME);

    //dun access token here, all done by interceptor
    // const config = {
    //   method: 'Get',
    //   url: serviceUri,
    //   responseType: 'arraybuffer',
    //   headers: {
    //     Accept: 'application/octet-stream;',
    //     Authorization: 'Bearer ' + authData.token,
    //   },
    //   params: parameters
    // };

    // this.$http(config)
    //   .then((response: ng.IHttpPromiseCallbackArg<Blob>) => {
    //     const httpHeader = response.headers();

    //     switch (response.status) {
    //       case 200: // Success
    //         deferred.resolve({
    //           data: response.data,
    //           headerFileName: httpHeader['x-filename']
    //         });
    //         break;

    //       case 401: // Unauthorized
    //         console.log(response.data);
    //         throw new Error('Unauthorized document download');

    //       default:
    //         console.log(response.data);
    //         throw new Error('Document download response with unexpected status: ' + response.status.toString());;
    //     }
    //   }, (error) => {
    //     deferred.resolve(error);
    //     throw new Error('document download error');
    //   });

    // return deferred.promise;
    //return Promise.reject(new Error());
    this.processParams(parameters, cache);
    if (isServiceApiEndPoint) {
      serviceUri = this.constructEndPoint(serviceUri);
    }

    return this.httpDownloadCall(
      //TODO: sosola
      this.getHeaderAsJson(),
      serviceUri,
      parameters
    );
  }

  //todo
  // getExternal<T>(serviceUri: string, parameters: any, postData: any, isServiceApiEndPoint: boolean = true): angular.IPromise<T> {

  //   if (isServiceApiEndPoint) {
  //     serviceUri = this.constructEndPoint(serviceUri);
  //   }
  //   const endpoint = this.$resource(serviceUri);

  //   // return this.$http({
  //   //     method : 'GET',
  //   //     url : serviceUri,
  //   //     transformResponse : function(data) {
  //   //         return $.parseXML(data);
  //   //     }
  //   // });

  //   return endpoint.get().$promise
  //     .then((response) => {
  //       return this.responseHandling<T>(response);
  //     }, (error) => {
  //       return this.errorHandling(error, serviceUri);
  //     });
  // }

  //sosola
  //Get broker list
  /** Method for get object request by posting query string as input. GET method */
  getBrokerList<T>(
    serviceUri: string,
    parameters: any,
    postData: any,
    isServiceApiEndPoint: boolean = true,
    cache: boolean = false
  ): Observable<T> {
    this.processParams(parameters, cache);
    if (isServiceApiEndPoint) {
      serviceUri = this.constructEndPoint(serviceUri);
    }

    return this.httpGetCall(
      //TODO: sosola
      //this.getHeaderAsJson(),
      this.getHeaderAsEncodedFormUrl(),

      serviceUri,
      parameters
    );
  }
}
