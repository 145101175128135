export class FireInterestedParties {
  constructor(
    public name?: string,
    public natureOfInterest?: string,
    public nric?: string
  ) {
    this.Name = name;
    this.NatureOfInterest = natureOfInterest;
    this.NRIC = nric;
  }

  PartyID!: number;
  Name?: string;
  NatureOfInterest?: string;
  NRIC?: string;
}
