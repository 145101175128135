import { Component, OnInit, ViewChild } from "@angular/core";
import { SelectEvent, TabStripComponent } from "@progress/kendo-angular-layout";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  @ViewChild("tabstrip") public tabstrip!: TabStripComponent;
  ngOnInit(): void {}
  onTabSelect(e: SelectEvent) {}
  selectFirstTab(val: any): void {
    this.tabstrip.selectTab(0);
  }
}
