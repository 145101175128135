//import { Application } from './../../application';
import { KeyValue } from "./../../../framework/domain-entity/key-value";
import { pageLifeCycleNotifier } from "./../notifier/page-life-cycle-notifier";

class ComponentHelper {
  notifyPageLoaded(pageName: string, eventArgs: any) {
    //todo
    // $(document).ready(() => {
    //     Application.angular_$timeout()(() => {
    //         pageLifeCycleNotifier
    //             .page_loaded_notifier$.next(new KeyValue(pageName, eventArgs));
    //     }, 10);
    // });
  }

  notifyPageUnload(pageName: string, eventArgs: any) {
    //todo
    // $(document).ready(() => {
    //     Application.angular_$timeout()(() => {
    //         pageLifeCycleNotifier
    //             .page_unload_notifier$.next(new KeyValue(pageName, eventArgs));
    //     }, 10);
    // });
  }
}

export const componentHelper = new ComponentHelper();
