<kendo-grid
  style="cursor: pointer"
  [data]="gridViewDetail"
  [pageSize]="pageSize"
  [skip]="skip"
  [pageable]="true"
  [height]="height"
  [resizable]="true"
  (pageChange)="pageChange($event)"
  [selectable]="true"
  (cellClick)="cellClickHandler($event)"
>
  <kendo-grid-column
    field="effectiveDate"
    title="Previous Trans. Effective Date"
    [width]="130"
  >
  </kendo-grid-column
  ><kendo-grid-column
    field="policyBoundDate"
    title="Previous Approved Date"
    [width]="180"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="proposalTypeDisplayText"
    title="Previous Trans. Type"
    [width]="160"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <label>{{ getProposalTypeDisplayText(dataItem) }}</label>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="endorsementTypeDisplayText"
    title="Previous Endorsement Type"
    [width]="170"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <label>{{ getEndorsementTypeDisplayText(dataItem) }}</label>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="premium" title="Premium" [width]="170">
  </kendo-grid-column>
</kendo-grid>
