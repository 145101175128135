import { Component, Input, OnInit } from "@angular/core";
import { common } from "src/app/framework/utils/common";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";
import { Culture, dateFormatter } from "src/app/shared/date-formatter";

@Component({
  selector: "date-input-question",
  templateUrl: "./date-input-question.component.html",
  styleUrls: ["./date-input-question.component.scss"],
})
export class DateInputQuestionComponent implements OnInit {
  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;
  @Input() public question!: QuestionEntity;
  minDate: any;
  maxDate: any;
  constructor() {}

  ngOnInit(): void {}

  displayText = () => {
    return dateFormatter.format(this.question.answer, Culture.enSg);
  };

  checkIsDefined = (mode: string, value: any) => {
    if (mode === "min") {
      // set min date to 1900
      this.minDate = common.isDate(value) ? value : new Date(1900, 0, 1);
      return this.minDate;
    } else if (mode === "max") {
      // set max date to 2999
      this.maxDate = common.isDate(value) ? value : new Date(2999, 11, 31);
      return this.maxDate;
    }

    return null;
  };

  public onChange(): void {
    if (this.question.answer > this.maxDate) {
      this.question.answer = new Date(this.maxDate);
    } else if (this.question.answer < this.minDate) {
      this.question.answer = new Date(this.minDate);
    }
  }
}
