<kendo-dialog
  *ngIf="opened"
  title="{{ titlebarTitle }}"
  class="{{ titlebarCssClass }}"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="dlgWidth"
  [minHeight]="180"
  [maxHeight]="dlgMaxHeight"
>
  <div class="container-fluid-custom">
    <div class="modal-body">
      <div [innerHtml]="message"></div>
    </div>
  </div>
  <div class="modal-footer">
    <kendo-dialog-actions>
      <button
        class="btn chubbLightBlue_bg_btn"
        kendoButton
        (click)="close('yes')"
        themeColor="primary"
      >
        OK
      </button>
      <button class="btn chubbRed_bg_btn" kendoButton (click)="close('no')">
        Cancel
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
