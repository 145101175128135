import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MasterDataService } from "src/app/services/master-data.service";
import { MasterPolicyService } from "src/app/services/master-policy.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { CdkDialogService } from "./../../../../services/dialog/cdk-dialog.service";
import { BaseTodoListComponent } from "../../base/base-todo-list/base-todo-list.component";

@Component({
  selector: "app-todo-list",
  templateUrl: "./todo-list.component.html",
  styleUrls: ["./todo-list.component.scss"],
})
export class TodoListComponent extends BaseTodoListComponent implements OnInit {
  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public override masterPolicyService: MasterPolicyService,
    public override masterDataService: MasterDataService,
    public override uploadService: UploadExcelService,
    public override cdkDialogService: CdkDialogService
  ) {
    super(
      router,
      broadcastService,
      identityRolesService,
      masterPolicyService,
      masterDataService,
      uploadService,
      cdkDialogService
    );
  }
}
