export class IBGRenewalReportSearchCriteria {
  constructor() {
    //  this.policyNo = null;
    //  this.cin      = null;
    //  this.insuredName = null;
    //  this.startDateFrom = null;
    //  this.startDateTo = null;
    //  this.expiryDateFrom  = null;
    //  this.expiryDateTo  = null;
    //  this.boundDateFrom = null;
    //  this.effectiveDateFrom = null;
    //  this.effectiveDateTo   = null;
    //  this.boundDateTo    = null;
    //  this.bankReferenceNo = null;
    this.segment = [];
    this.transType = [];
    this.policyStatus = [];
    this.proposalStatus = [];
    this.productId = [];
    //  this.postalCode = null;
    //  this.MSIGNo = null;
  }

  policyNo!: string;
  cin!: string;
  insuredName!: string;
  startDateFrom?: Date;
  startDateTo?: Date;
  expiryDateFrom?: Date;
  expiryDateTo?: Date;
  boundDateFrom?: Date;
  boundDateTo?: Date;
  effectiveDateFrom?: Date;
  effectiveDateTo?: Date;
  bankReferenceNo!: string;
  segment: Array<string>;
  transType: Array<string>;
  policyStatus: Array<string>;
  proposalStatus: Array<string>;
  productId: Array<string>;
  postalCode!: string;
  MSIGNo!: string;
}
