<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>
  <div kendoTooltip title='Date format is "DD/MM/YYYY"' style="display: inline">
    &nbsp; 🛈
  </div>
  <div *ngIf="!question.readOnly">
    <!--cannot use class="form-control" -->
    <kendo-datepicker
      id="{{ question.key }}_datePicker"
      name="{{ question.key }}"
      format="dd/MM/yyyy"
      style="width: 200px"
      ng-keydown="keyDown($event)"
      (valueChange)="valueUpdated($event)"
      placeholder="DD/MM/YYYY"
      [(ngModel)]="question.answer"
      [min]="setMinDate()"
      [max]="setMaxDate()"
    ></kendo-datepicker>
  </div>

  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()">{{ displayText() }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
