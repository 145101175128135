export enum Culture {
    enSg
}

class DateFormatter {
    format(value: Date, culture: Culture): string {
        const mm = value.getMonth() + 1; // getMonth() is zero-based
        const dd = value.getDate();
        const yyyy = value.getFullYear();

        switch (culture) {
            case Culture.enSg:
                return dd + '/' + mm + '/' + yyyy;

            default:
                throw Error('date culture unhandled');
        }
    }
}

export const dateFormatter = new DateFormatter();
