<kendo-dialog
  *ngIf="active"
  [width]="650"
  [height]="350"
  (close)="closeForm($event)"
>
  <kendo-dialog-titlebar> {{ isNew ? "Add" : "Edit" }} </kendo-dialog-titlebar>
  <form
    novalidate
    class="k-form"
    style="padding-left: 100px; padding-top: 20px"
    [formGroup]="editForm"
    class="k-form k-form-horizontal"
  >
    <kendo-formfield orientation="horizontal">
      <kendo-label
        [for]="equipmentDropdownOptions"
        text="Type of Equipment"
      ></kendo-label>
      <div style="width: 350px">
        <kendo-dropdownlist
          [data]="equipmentDropdownOptions"
          [value]="defaultItem"
          textField="key"
          valueField="value"
          [(ngModel)]="selectedEquipmentType"
          (selectionChange)="optionChange($event)"
          name="selectedEquipmentType"
          [valuePrimitive]="false"
        ></kendo-dropdownlist>
      </div>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="typeOfFinancing" text="Serial Number"></kendo-label>
      <div style="width: 350px">
        <kendo-textbox
          formControlName="typeOfFinancing"
          #typeOfFinancing
          [maxlength]="50"
        ></kendo-textbox>
      </div>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label
        [for]="equipmentDescription"
        text="Description"
      ></kendo-label>
      <div style="width: 350px">
        <kendo-textbox
          formControlName="equipmentDescription"
          #equipmentDescription
          required
          [maxlength]="50"
        ></kendo-textbox>
      </div>

      <kendo-formhint></kendo-formhint>
      <kendo-formerror>Description is required.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="sumInsured" text="Sum Insured"></kendo-label>
      <kendo-numerictextbox
        formControlName="sumInsured"
        #sumInsured
        [maxlength]="10"
        format="c0"
        [spinners]="false"
      ></kendo-numerictextbox>
    </kendo-formfield>
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="additionalInfo" text="Additional Info"></kendo-label>
      <kendo-textarea
        formControlName="additionalInfo"
        #additionalInfo
        required
        [rows]="5"
        [style.width.px]="380"
      ></kendo-textarea>
      <kendo-formhint></kendo-formhint>
      <kendo-formerror>Additional Info is required.</kendo-formerror>
    </kendo-formfield>
  </form>

  <kendo-dialog-actions>
    <button
      kendoButton
      themeColor="primary"
      [disabled]="!editForm.valid"
      (click)="onSave($event)"
    >
      Update
    </button>
    <button kendoButton (click)="onCancel($event)">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
