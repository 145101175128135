import * as Enums from './../../enum.shared';

import { Exception } from './exception';

/** Common Exception object to be thrown for argument null exception */
export class ArgumentNullException extends Exception {

    constructor(argument: any, argumentName: string) {
        super('');
        this.type = Enums.ErrorExceptionType.ArgumentException;
        this.message = `Invalid null or empty argument [${argumentName}] of type [${typeof argument}] of value [${argument}]`;
    }
}
