import { KeyValue } from "./../framework/domain-entity/key-value";
import { Subject } from "rxjs";

/** To notify http event trigger */
export class HttpNotifier {
  /** Notify http request event */
  request_notifier$: Subject<KeyValue<any>> = new Subject<KeyValue<any>>();

  /** Notify http response event */
  response_notifier$: Subject<KeyValue<any>> = new Subject<KeyValue<any>>();

  /** Notify http request error event */
  request_error_notifier$: Subject<KeyValue<any>> = new Subject<
    KeyValue<any>
  >();

  /** Notify http response error event */
  response_error_notifier$: Subject<KeyValue<any>> = new Subject<
    KeyValue<any>
  >();
}

export const httpNotifier = new HttpNotifier();
