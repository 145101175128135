<kendo-dialog
  title="Information"
  class="modal-title-red"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="600"
  [minHeight]="180"
>
  <div class="container-fluid-custom">
    <div class="modal-body">
      <div [innerHtml]="data.message"></div>
    </div>
  </div>
  <div class="modal-footer">
    <kendo-dialog-actions>
      <button
        class="btn chubbLightBlue_bg_btn"
        kendoButton
        (click)="close('yes')"
        themeColor="primary"
        [disabled]="isDisabled"
      >
        {{ okText }}
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
