import * as Enums from "./../../../../../../framework/enum.shared";
import * as lodash from "lodash";

import { Echo } from "./../../../../base/echo.model";
import * as EnumSG from "./../base/mf-hk-enums";
import { Echos } from "./../../../../base/echos.model";
import { ICoverage } from "./../../../../../../framework/interface.product";
import { KeyValue } from "./../../../../../../framework/domain-entity/key-value";
import { MortgageFireHKTabSchema } from "./schemas/mf-hk-tab-schema";
import { MortgageFireHongKongBusinessRuleSchema } from "./schemas/mf-hk-business-rule-schema";
import { MortgageFireHongKongProposal } from "./mf-hk-proposal";
import { MortgageFireHongKongQuestionEntityValidator } from "./validator/mf-hk-question-entity.validator";
import { Premium } from "./../../../../base/premium.model";
import { QuestionEntity } from "./../../../../base/question-entity.model";
import { common } from "./../../../../../../framework/utils/common";
import { proposalHelper } from "./../../../../utils/proposal.helper";
import { environment } from "src/environments/environment";

export class MortgageFireHongKongFieldValidationManager {
  echos!: Echos<QuestionEntity>[];
  ruleSchema: MortgageFireHongKongBusinessRuleSchema;
  underwriterMessages!: MortgageFireHongKongBusinessRuleSchema[];

  static readonly minPremium: number = 0;
  static readonly maxPremium: number = 5000000;

  constructor(private proposal: MortgageFireHongKongProposal) {
    this.ruleSchema = new MortgageFireHongKongBusinessRuleSchema(this.proposal);
    this.initializeMandatoryFields();
    // this.initializeVisibilityFields();
  }

  /** Method for setting up all questions [required] flag
   * This is the function we should call upon proposal page load to initialize custom mandatory fields
   */
  initializeMandatoryFields() {
    this.setManualReferralReasonRequires();
    this.setNoOfFreeInsuranceYearsRequires();
  }

  /** Method for setting up all questions [visibility] flag
   * This is the function we should call upon proposal page load to initialize custom mandatory fields
   */
  initializeVisibilityFields() {
    this.showReplacingPolicyNumberOnRenew();
  }

  setManualReferralReasonRequires() {
    this.proposal.qsMaster.qs_master_masterPolicyNumber!.required = true;
    this.proposal.qsPremiumSummary.qs_master_manualReferralReason.required =
      false;
    this.proposal.qsPremiumSummary.qs_master_manualReferralReason.isVisible =
      false;
    if (
      this.proposal.qsPremiumSummary.qs_master_manualReferral.readAnswerAsNumeric() ===
      Enums.TrueFalseQuestionAnswer.Yes
    ) {
      this.proposal.qsPremiumSummary.qs_master_manualReferralReason.required =
        true;
      this.proposal.qsPremiumSummary.qs_master_manualReferralReason.isVisible =
        true;
    }
  }
  setEmailMobileRequires(data: string, isIBGProduct: any) {
    if (data === "2") {
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email.required =
          true;
      }
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo.required =
          false;
      }
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress.required =
          false;
      }
    } else if (data === "1") {
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress.required =
          true;
      }
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo.required =
          false;
      }
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email.required =
          false;
      }
    } else if (data === "5") {
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email.required =
          false;
      }
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo.required =
          true;
      }

      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress.required =
          false;
      }
    } else {
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email.required =
          false;
      }
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo.required =
          false;
      }
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress !=
        undefined
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress.required =
          false;
      }
    }
  }
  setNoOfFreeInsuranceYearsRequires() {
    switch (this.proposal.productType) {
      case EnumSG.MortgageFireHKProduct.HSCMB:
      case EnumSG.MortgageFireHKProduct.HSWPB:
        //this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.answer =  this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.answer;
        //  this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.isVisible = false;
        //  this.proposal.qsMaster.qs_master_FreeIns_startDate.isVisible = false;
        //  if(Number(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.answer)>0)
        //  {
        //     this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.readOnly = true;
        //     this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.isVisible = true;
        //     this.proposal.qsMaster.qs_master_FreeIns_startDate.isVisible = true;

        //     this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.answer = this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.answer;
        // }
        break;
    }
    /** INC1590772 - Set answer to Zero for qs_fire_freeInsuranceNoYear if qs_fire_freeInsuranceFirstYear is No */
    if (
      this.proposal.qsMaster.qs_fire_freeInsuranceFirstYear!.readAnswerAsNumeric() ===
      Enums.TrueFalseQuestionAnswer.No
    ) {
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.required = false;
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.isVisible = false;
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer = 0;
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.minValue = 0;
    }
    /** INC1651796 - Disable the controls during endorsement for IBG */
    this.proposal.qsMaster.qs_fire_freeInsuranceFirstYear!.isDisabled =
      this.proposal.transType === Enums.TransType.NEW ? false : true;
    ////DBSEP-252 : Allow Edit Free Year For IBG Product
    ///this.proposal.qsMaster.qs_fire_freeInsuranceNoYear.isDisabled = (this.proposal.transType === Enums.TransType.NEW) ? false : true;
  }

  showReplacingPolicyNumberOnRenew = () => {
    this.proposal.qsMaster.qs_master_replacePolicyNo!.isVisible =
      this.proposal.transType === Enums.TransType.REN ? true : false;
  };

  /** Method for validate proposal mandatory questions */
  validate(): Echos<QuestionEntity>[] {
    this.echos = [];

    const questions = this.proposal.extractQuestionArray();
    const pValidator = new MortgageFireHongKongQuestionEntityValidator(
      questions,
      MortgageFireHKTabSchema.tabs
    );

    this.atLeastOneRiskInsuredAddressIsFilled(); //reset required fileds before validateMandatory

    const pValidationEchos = pValidator.validate();
    this.echos = pValidationEchos;

    //must do after assignment above, so that won't be overrided
    this.concateEchos(this.validateMaxSI());
    this.concateEchos(this.validateMinMaxPremiumIfNb());
    this.concateEchos(this.validateMaxPremiumIfEnd());
    this.concateEchosIfNotExist(this.validate_InsuredNo(), "Client No");
    this.concateEchosIfNotExist(this.validate_InsuredName(), "Mortgagor Name");

    if (
      common.isDefined(this.proposal.children) &&
      this.proposal.children.length > 0
    ) {
      this.proposal.children.forEach((location) => {
        const locQuestions = location.extractQuestionArray();
        const locValidator = new MortgageFireHongKongQuestionEntityValidator(
          locQuestions,
          MortgageFireHKTabSchema.tabs
        );
        const locValidationEchos = locValidator.validate();
        this.echos = lodash.concat(this.echos, locValidationEchos);
      });
    }

    // TODO: implement custom validations if any

    return this.echos;
  }

  /** Method for validate proposal mandatory questions [Client Name] [Client No] for Save */
  validateSave(): Echos<QuestionEntity>[] {
    this.echos = [];

    let questions = this.proposal.extractQuestionArray();
    questions = questions.filter(function (q) {
      return (
        q.key === "qs_master_insuredName" || q.key === "qs_master_insuredNo"
      );
    });
    const pValidator = new MortgageFireHongKongQuestionEntityValidator(
      questions,
      MortgageFireHKTabSchema.tabs
    );
    const pValidationEchos = pValidator.validate();
    this.echos = pValidationEchos;

    if (
      common.isDefined(this.proposal.children) &&
      this.proposal.children.length > 0
    ) {
      this.proposal.children.forEach((location) => {
        const locQuestions = location.extractQuestionArray();
        const locValidator = new MortgageFireHongKongQuestionEntityValidator(
          locQuestions,
          MortgageFireHKTabSchema.tabs
        );
        const locValidationEchos = locValidator.validate();
        this.echos = lodash.concat(this.echos, locValidationEchos);
      });
    }

    return this.echos;
  }
  validateDeliverMethod(): Echos<QuestionEntity>[] {
    const questions = this.proposal
      .extractQuestionArray()
      .filter(
        (x) =>
          x.key === "qs_master_preferredDeliveryMethod_MobileNo" ||
          x.key === "qs_master_preferredDeliveryMethod_Email" ||
          x.key === "qs_master_preferredDeliveryMethod_CorrAddress"
      );
    const echos: Echo<QuestionEntity>[] = [];
    const results: Echos<QuestionEntity>[] = [];
    const requiredCount = questions.filter((x) => x.required).length;
    if (requiredCount >= 1) {
      var q = questions.filter(
        (x) => x.key === "qs_master_preferredDeliveryMethod_MobileNo"
      )[0];
      const mobileAnswer = q.readAnswerAsString();
      if (!common.isDefined(mobileAnswer) && q.required) {
        q.theme = Enums.QuestionTheme.Danger;
        echos.push(
          proposalHelper.buildQuestionEntityEcho(
            "Mobile Number is not correct.",
            q
          )
        );
      } else if (common.isDefined(mobileAnswer) && q.required) {
        if (!proposalHelper.phoneHkValidation(mobileAnswer!)) {
          q.theme = Enums.QuestionTheme.Danger;
          echos.push(
            proposalHelper.buildQuestionEntityEcho(
              "Mobile Number is not correct.",
              q
            )
          );
        } else {
          questions[0].theme = Enums.QuestionTheme.Normal;
        }
      } else if (
        common.isDefined(mobileAnswer) &&
        !q.required &&
        mobileAnswer !== null &&
        mobileAnswer !== ""
      ) {
        if (!proposalHelper.phoneHkValidation(mobileAnswer!)) {
          q.theme = Enums.QuestionTheme.Danger;
          echos.push(
            proposalHelper.buildQuestionEntityEcho(
              "Mobile Number is not correct.",
              q
            )
          );
        } else {
          questions[0].theme = Enums.QuestionTheme.Normal;
        }
      }
      q = questions.filter(
        (x) => x.key === "qs_master_preferredDeliveryMethod_Email"
      )[0];
      const emailAnswer = q.readAnswerAsString();
      if (!common.isDefined(emailAnswer) && q.required) {
        q.theme = Enums.QuestionTheme.Danger;
        echos.push(
          proposalHelper.buildQuestionEntityEcho("Email Id is not correct.", q)
        );
      } else if (common.isDefined(emailAnswer) && q.required) {
        if (!proposalHelper.emailValidation(emailAnswer!)) {
          q.theme = Enums.QuestionTheme.Danger;
          echos.push(
            proposalHelper.buildQuestionEntityEcho(
              "Email Id is not correct.",
              q
            )
          );
        } else {
          questions[0].theme = Enums.QuestionTheme.Normal;
        }
      } else if (
        common.isDefined(emailAnswer) &&
        !q.required &&
        emailAnswer !== null &&
        emailAnswer !== ""
      ) {
        if (!proposalHelper.emailValidation(emailAnswer!)) {
          q.theme = Enums.QuestionTheme.Danger;
          echos.push(
            proposalHelper.buildQuestionEntityEcho(
              "Email Id is not correct.",
              q
            )
          );
        } else {
          questions[0].theme = Enums.QuestionTheme.Normal;
        }
      }
    }

    const resultEchos = proposalHelper.convertToEchos(echos);
    resultEchos.forEach((e) => {
      const echosToAdd = proposalHelper.convertTitleToTabIntoEchos(
        e,
        MortgageFireHKTabSchema.tabs
      );
      results.push(echosToAdd);
    });

    if (this.echos.length > 0) {
      if (results.length > 0) {
        this.echos[0].echos.push(results[0].echos[0]);
      }
    } else {
      this.echos = results;
    }
    return this.echos;
  }

  //#region hase
  validateMaxSI() {
    if (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB) {
      const rule = this.ruleSchema.pwbSI;
      if (
        this.proposal.transType === Enums.TransType.NEW ||
        (this.proposal.transType === Enums.TransType.END &&
          this.proposal.endorsementType ===
            Enums.EndorsementType.ChangeOfSumInsured) ||
        this.proposal.transType === Enums.TransType.REN
      ) {
        if (
          this.proposal.qsMaster.qs_fire_building!.readAnswerAsNumeric()! >
          environment.wpbMaxSiLimit
        ) {
          return proposalHelper.buildQuestionEntityEcho(
            "Insurance amount cannot exceed HKD" +
              environment.wpbMaxSiLimit.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
            this.proposal.qsMaster.qs_fire_building!
          );
        } else {
          return null;
        }
      }
    } else if (
      this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB
    ) {
      const rule = this.ruleSchema.cmbSI;
      if (
        this.proposal.transType === Enums.TransType.NEW ||
        (this.proposal.transType === Enums.TransType.END &&
          this.proposal.endorsementType ===
            Enums.EndorsementType.ChangeOfSumInsured) ||
        this.proposal.transType === Enums.TransType.REN
      ) {
        if (
          this.proposal.qsMaster.qs_fire_building!.readAnswerAsNumeric()! >
          environment.cmbMaxSiLimit
        ) {
          return proposalHelper.buildQuestionEntityEcho(
            "Insurance amount cannot exceed HKD" +
              environment.cmbMaxSiLimit.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
            this.proposal.qsMaster.qs_fire_building!
          );
        } else {
          return null;
        }
      }
    }

    return null;
  }

  validateMinMaxPremiumIfNb() {
    if (this.proposal.productType === EnumSG.MortgageFireHKProduct.HSCMB) {
      return this.validateCmbMinMaxPremiumIfNb();
    } else if (
      this.proposal.productType === EnumSG.MortgageFireHKProduct.HSWPB
    ) {
      return this.validateWpbMaxPremiumIfNb();
    }

    return null;
  }

  validateWpbMaxPremiumIfNb() {
    if (
      this.proposal.transType === Enums.TransType.NEW && //DBSEP-7012
      this.isManualUW()
    ) {
      const premiumData = this.proposal.auxiliary["qt"];
      if (common.isDefined(premiumData) && premiumData.length > 0) {
        var premium = premiumData[0].premiumSummary?.premium;
        if (common.isDefined(premium)) {
          if (premium > MortgageFireHongKongFieldValidationManager.maxPremium) {
            return proposalHelper.buildQuestionEntityEcho(
              "Premium cannot be exceeded HKD 5,000,000.00",
              this.proposal!.qsPremiumSummary.qs_master_techRate!
            );
          }
        }
      }
    }

    return null;
  }

  validateCmbMinMaxPremiumIfNb() {
    if (this.proposal.transType === Enums.TransType.NEW && this.isManualUW()) {
      //DBSEP-7012
      const premiumData = this.proposal.auxiliary["qt"];
      if (common.isDefined(premiumData) && premiumData.length > 0) {
        var premium = premiumData[0].premiumSummary?.premium;
        if (common.isDefined(premium)) {
          if (premium > MortgageFireHongKongFieldValidationManager.maxPremium) {
            return proposalHelper.buildQuestionEntityEcho(
              "Premium cannot exceed HKD 5,000,000.00",
              this.proposal!.qsPremiumSummary.qs_master_techRate!
            );
          }
        }
      }
    }

    return null;
  }

  validateMaxPremiumIfEnd() {
    if (this.proposal.transType === Enums.TransType.END && this.isManualUW()) {
      //DBSEP-7245
      const premiumData = this.proposal.auxiliary["qt"];
      if (common.isDefined(premiumData) && premiumData.length > 0) {
        var premium = premiumData[0].premiumSummary?.premium;
        if (common.isDefined(premium)) {
          //only validate when > 0, when END
          if (premium > MortgageFireHongKongFieldValidationManager.maxPremium) {
            return proposalHelper.buildQuestionEntityEcho(
              "Premium cannot exceed HKD 5,000,000.00",
              this.proposal!.qsPremiumSummary.qs_master_techRate!
            );
          }
        }
      }
    }

    return null;
  }

  isManualUW() {
    return (
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === "True" ||
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === true
    );
  }

  validate_InsuredNo() {
    if (
      this.proposal.qsMaster.qs_master_insuredNo!.readAnswerAsString() === ""
    ) {
      return proposalHelper.buildQuestionEntityEcho(
        "Client No",
        this.proposal.qsMaster.qs_master_insuredNo!
      );
    } else {
      return null;
    }
  }

  validate_InsuredName() {
    if (
      this.proposal.qsMaster.qs_master_insuredName!.readAnswerAsString() === ""
    ) {
      return proposalHelper.buildQuestionEntityEcho(
        "Mortgagor Name",
        this.proposal.qsMaster.qs_master_insuredName!
      );
    } else {
      return null;
    }
  }

  convertToEchos(echos: Echo<QuestionEntity> | null) {
    const echoArray: Array<Echo<QuestionEntity>> =
      Array<Echo<QuestionEntity>>();
    const pEchos = echos;
    if (pEchos) {
      echoArray.push(pEchos);
    }
    return proposalHelper.convertToEchos(echoArray);
  }

  concateEchos(echos: Echo<QuestionEntity> | null) {
    const results = this.convertToEchos(echos);
    this.echos = lodash.concat(this.echos, results);
  }

  concateEchosIfNotExist(echos: Echo<QuestionEntity> | null, str: string) {
    const results = this.convertToEchos(echos);
    if (!this.checkEchosExist(str)) {
      this.echos = lodash.concat(this.echos, results);
    }
  }

  checkEchosExist(str: string) {
    if (this.echos && this.echos.length > 0 && this.echos[0].echos) {
      return this.echos[0].echos.filter((x) => x.message === str);
    }

    return null; //also concat
  }
  //#endregion

  //#region at least one field is filled
  atLeastOneRiskInsuredAddressIsFilled() {
    let questions = this.proposal.extractQuestionArray();

    let qs_risk_insuredAddress_lotunit = questions.filter(
      (x) => x.key === "qs_risk_insuredAddress_lotunit"
    );

    let qs_risk_insuredAddress_building = questions.filter(
      (x) => x.key === "qs_risk_insuredAddress_building"
    );

    let qs_risk_insuredAddress1 = questions.filter(
      (x) => x.key === "qs_risk_insuredAddress1"
    );

    let qs_risk_insuredAddress2 = questions.filter(
      (x) => x.key === "qs_risk_insuredAddress2"
    );

    let qs_risk_insuredAddress3 = questions.filter(
      (x) => x.key === "qs_risk_insuredAddress3"
    );

    if (
      qs_risk_insuredAddress_lotunit.length > 0 &&
      qs_risk_insuredAddress_building.length > 0 &&
      qs_risk_insuredAddress1.length > 0 &&
      qs_risk_insuredAddress2.length > 0 &&
      qs_risk_insuredAddress3.length > 0
    )
      if (
        common.isStringNullOrEmpty(qs_risk_insuredAddress_lotunit[0].answer) &&
        common.isStringNullOrEmpty(qs_risk_insuredAddress_building[0].answer) &&
        common.isStringNullOrEmpty(qs_risk_insuredAddress1[0].answer) &&
        common.isStringNullOrEmpty(qs_risk_insuredAddress2[0].answer) &&
        common.isStringNullOrEmpty(qs_risk_insuredAddress3[0].answer)
      ) {
        qs_risk_insuredAddress_lotunit[0].required = true;
      } else {
        //need to reset in case, re-fill
        qs_risk_insuredAddress_lotunit[0].required = false;
      }
  }

  inverseQuestionRequired(
    quesToCheck: QuestionEntity,
    ques1ToInverse: QuestionEntity,
    ques2ToInverse: QuestionEntity,
    ques3ToInverse: QuestionEntity
  ) {
    if (!common.isStringNullOrEmpty(quesToCheck!.answer)) {
      ques1ToInverse!.required = false;
      ques2ToInverse!.required = false;
      ques3ToInverse!.required = false;
    }
  }
  //#endregion

  /** Method for validate atleast one of the equipment SI must be greater than zero */
  validateEquipmentsSI(): Echos<QuestionEntity>[] {
    let totalSI = 0;
    const questions = this.proposal.extractQuestionArray();
    const echos: Echo<QuestionEntity>[] = [];
    const results: Echos<QuestionEntity>[] = [];

    questions.forEach(function (q) {
      switch (q.key) {
        case "qs_machinery_equipment":
          const answer = q.readAnswerAsString();
          let equipmentAnswer: any[] = [];
          if (common.isDefined(answer) && answer !== null && answer !== "") {
            equipmentAnswer = JSON.parse(answer!);
            if (equipmentAnswer.length > 0) {
              equipmentAnswer.forEach((ep) => {
                totalSI = totalSI + ep.sumInsured;
              });
            }
          }
          if (totalSI <= 0) {
            q.theme = Enums.QuestionTheme.Danger;
            echos.push(
              proposalHelper.buildQuestionEntityEcho(
                "The total Sum Insured of the Equipments must be greater than Zero",
                q
              )
            );
          } else {
            questions[0].theme = Enums.QuestionTheme.Normal;
          }
          break;
      }
    });

    const resultEchos = proposalHelper.convertToEchos(echos);
    resultEchos.forEach((e) => {
      const echosToAdd = proposalHelper.convertTitleToTabIntoEchos(
        e,
        MortgageFireHKTabSchema.tabs
      );
      results.push(echosToAdd);
    });

    if (this.echos.length > 0) {
      if (results.length > 0) {
        this.echos[0].echos.push(results[0].echos[0]);
      }
    } else {
      this.echos = results;
    }
    return this.echos;
  }
  validateCBGTotalChargeAgainstGrossAndGST = (): Echos<QuestionEntity>[] => {
    const questions = this.proposal.extractQuestionArray();
    const echos: Echo<QuestionEntity>[] = [];
    const results: Echos<QuestionEntity>[] = [];
    const premiumData = this.proposal.auxiliary["qt"];
    const proposalTransType = this.proposal.transType;
    questions.forEach(function (q) {
      switch (q.key) {
        case "qs_master_brokerCommission":
          if (premiumData.length > 0) {
            const counter = [];
            if (common.isDefined(premiumData[0].premiumSummary)) {
              if (
                (Math.sign(premiumData[0].premiumSummary.premium) >= 0 &&
                  Math.sign(premiumData[0].premiumSummary.premiumTax) >= 0) ||
                (Math.sign(premiumData[0].premiumSummary.premium) <= 0 &&
                  Math.sign(premiumData[0].premiumSummary.premiumTax) <= 0)
              ) {
                if (
                  //todo
                  true
                  // $("#cbg_fireGrossPremium")
                  //   .siblings()
                  //   .hasClass("chubbLightRed_bg")
                ) {
                  //todo
                  // $("#cbg_fireGrossPremium")
                  //   .siblings()
                  //   .removeClass("chubbLightRed_bg");
                }
                //todo
                // if ($("#cbg_fireGST").siblings().hasClass("chubbLightRed_bg")) {
                //   $("#cbg_fireGST").siblings().removeClass("chubbLightRed_bg");
                // }
              } else {
                //todo
                // $("#cbg_fireGrossPremium")
                //   .siblings()
                //   .addClass("chubbLightRed_bg");
                // $("#cbg_fireGST").siblings().addClass("chubbLightRed_bg");
                const str =
                  "The Gross Premium or GST is incorrect for Property Fire";
                echos.push(proposalHelper.buildQuestionEntityEcho(str, q));
              }
            }
          }
          break;

        case "qs_master_manualReferral":
          if (premiumData.length > 0) {
            if (common.isDefined(premiumData[0].premiumSummary)) {
              const coverage = premiumData[0].premiumSummary;
              if (
                coverage.premium < 0 &&
                Math.abs(coverage.premium) > coverage.accumulatedBasePremium
              ) {
                //todo
                // $("#cbg_fireGrossPremium")
                //   .siblings()
                //   .addClass("chubbLightRed_bg");
                const str =
                  "The Gross Premium cannot be refunded more than accumulated Base Premium $" +
                  coverage.accumulatedBasePremium;
                echos.push(proposalHelper.buildQuestionEntityEcho(str, q));
              } else {
                if (
                  //todo
                  true
                  // $("#cbg_fireGrossPremium")
                  //   .siblings()
                  //   .hasClass("chubbLightRed_bg")
                ) {
                  // $("#cbg_fireGrossPremium")
                  //   .siblings()
                  //   .removeClass("chubbLightRed_bg");
                }
              }

              if (
                coverage.premiumTax < 0 &&
                Math.abs(coverage.premiumTax) > coverage.accumulatedPremiumGst
              ) {
                //$("#cbg_fireGST").siblings().addClass("chubbLightRed_bg");//todo
                const str =
                  "The GST cannot be refunded more than accumulated Premium GST $" +
                  coverage.accumulatedPremiumGst;
                echos.push(proposalHelper.buildQuestionEntityEcho(str, q));
              } else {
                //todo
                // if ($("#cbg_fireGST").siblings().hasClass("chubbLightRed_bg")) {
                //   $("#cbg_fireGST").siblings().removeClass("chubbLightRed_bg");
                // }
              }
            }
          }
          break;

        case "qs_master_manualReferralReason":
          if (
            premiumData.length > 0 &&
            proposalTransType === Enums.TransType.NEW
          ) {
            if (premiumData[0].premiumSummary.premium <= 0) {
              echos.push(
                proposalHelper.buildQuestionEntityEcho(
                  "The total Gross Premium must be greater than $0.",
                  q
                )
              );
            }
          }
          break;
      }
    });

    const resultEchos = proposalHelper.convertToEchos(echos);
    resultEchos.forEach((e) => {
      const echosToAdd = proposalHelper.convertTitleToTabIntoEchos(
        e,
        MortgageFireHKTabSchema.tabs
      );
      results.push(echosToAdd);
    });

    if (this.echos.length > 0) {
      if (results.length > 0) {
        this.echos[0].echos.push(results[0].echos[0]);
      }
    } else {
      this.echos = results;
    }
    return this.echos;
  };
  /** Method for validate the total Charge cannot be less than sum of Gross Premium and GST */
  validateTotalChargeAgainstGrossAndGST = (): Echos<QuestionEntity>[] => {
    const totalSI = 0;
    const questions = this.proposal.extractQuestionArray();
    const echos: Echo<QuestionEntity>[] = [];
    const results: Echos<QuestionEntity>[] = [];
    var t = this.proposal.auxiliary["qt"];
    const premiumData = t;
    const proposalTransType = this.proposal.transType;
    questions.forEach(function (q) {
      switch (q.key) {
        case "qs_master_brokerCommission":
          if (premiumData.length > 0) {
            const counter: ICoverage[] = [];
            premiumData[0].coverages.forEach(
              (coverage: {
                premiumDetails: { premium: number; premiumTax: number };
                coverageProductMappingId: string;
                coverageName: ICoverage;
              }) => {
                if (
                  (Math.sign(coverage.premiumDetails.premium) >= 0 &&
                    Math.sign(coverage.premiumDetails.premiumTax) >= 0) ||
                  (Math.sign(coverage.premiumDetails.premium) <= 0 &&
                    Math.sign(coverage.premiumDetails.premiumTax) <= 0)
                ) {
                  //todo
                  //   if (
                  //     $("#premium_" + coverage.coverageProductMappingId)
                  //       .siblings()
                  //       .hasClass("chubbLightRed_bg")
                  //   ) {
                  //     $("#premium_" + coverage.coverageProductMappingId)
                  //       .siblings()
                  //       .removeClass("chubbLightRed_bg");
                  //   }
                  //   if (
                  //     $("#premiumTax_" + coverage.coverageProductMappingId)
                  //       .siblings()
                  //       .hasClass("chubbLightRed_bg")
                  //   ) {
                  //     $("#premiumTax_" + coverage.coverageProductMappingId)
                  //       .siblings()
                  //       .removeClass("chubbLightRed_bg");
                  //   }
                  // } else {
                  //   $("#premium_" + coverage.coverageProductMappingId)
                  //     .siblings()
                  //     .addClass("chubbLightRed_bg");
                  //   $("#premiumTax_" + coverage.coverageProductMappingId)
                  //     .siblings()
                  //     .addClass("chubbLightRed_bg");
                  //   counter.push(coverage.coverageName);
                }
              }
            );
            if (counter.length > 0) {
              let str = "The Gross Premium or GST is incorrect for ";
              for (let i = 0; i < counter.length; i++) {
                str = str + "\n" + counter[i] + " , ";
              }
              str = str.slice(0, -3);
              echos.push(proposalHelper.buildQuestionEntityEcho(str, q));
            }
          }
          break;

        case "qs_master_manualReferral":
          if (premiumData.length > 0) {
            const counter: ICoverage[] = [];
            premiumData[0].coverages.forEach((coverage: ICoverage) => {
              if (
                coverage.premiumDetails!.premium < 0 &&
                Math.abs(coverage.premiumDetails!.premium) >
                  coverage.premiumDetails!.accumulatedBasePremium!
              ) {
                //todo
                // $("#premium_" + coverage.coverageProductMappingId)
                //   .siblings()
                //   .addClass("chubbLightRed_bg");
                counter.push(coverage);
              }
            });
            if (counter.length > 0) {
              let str =
                "The Gross Premium cannot be refunded more than accumulated Base Premium of ";
              for (let i = 0; i < counter.length; i++) {
                str =
                  str +
                  "\n$" +
                  counter[i].premiumDetails!.accumulatedBasePremium +
                  " for " +
                  counter[i].coverageName +
                  " , ";
              }
              str = str.slice(0, -3);
              echos.push(proposalHelper.buildQuestionEntityEcho(str, q));
            }
          }
          break;

        case "qs_master_manualReferralReason":
          if (
            premiumData.length > 0 &&
            proposalTransType === Enums.TransType.NEW
          ) {
            const counter = [];
            if (premiumData[0].premiumSummary.premium <= 0) {
              echos.push(
                proposalHelper.buildQuestionEntityEcho(
                  "The total Gross Premium must be greater than $0.",
                  q
                )
              );
            }
          }
          break;
      }
    });

    const resultEchos = proposalHelper.convertToEchos(echos);
    resultEchos.forEach((e) => {
      const echosToAdd = proposalHelper.convertTitleToTabIntoEchos(
        e,
        MortgageFireHKTabSchema.tabs
      );
      results.push(echosToAdd);
    });

    if (this.echos.length > 0) {
      if (results.length > 0) {
        this.echos[0].echos.push(results[0].echos[0]);
      }
    } else {
      this.echos = results;
    }
    return this.echos;
  };

  /*2022-08-29 dagoh0: APACDM-363 - validate previous selected Plan Type against new selected Plan Type*/
  //validateCBGSelectedPlanType = (previousPlanType: string): Echos<QuestionEntity>[] => {
  //    const results: Echos<QuestionEntity>[] = [];
  //    const echos: Echo<QuestionEntity>[] = [];
  //
  //
  //    const isCBGProduct = (this.proposal.productType !== EnumSG.MortgageFireSgProduct.IBGMF &&
  //        this.proposal.productType !== EnumSG.MortgageFireSgProduct.IBGMAC  &&
  //        this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTHDB &&
  //        this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTMFCONDO &&
  //        this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTMFPTE);
  //
  //    const questions = this.proposal.extractQuestionArray();
  //    if (isCBGProduct){
  //
  //        const q = questions.filter(x=>x.key==='qs_master_planType')[0];
  //        let planTypeAnswer = q.readAnswerAsString();
  //
  //        if (planTypeAnswer === previousPlanType)
  //        {
  //            console.log(planTypeAnswer + '::' + previousPlanType)
  //            echos.push(proposalHelper.
  //                buildQuestionEntityEcho('Endorsing Plan Type that are same as existing policy\'s plan type are not allowed.', q));
  //        }
  //    }
  //
  //
  //    const resultEchos = proposalHelper.convertToEchos(echos);
  //    resultEchos.forEach(e => {
  //        const echosToAdd = proposalHelper.convertTitleToTabIntoEchos(e, MortgageFireHKTabSchema.tabs);
  //        results.push(echosToAdd);
  //    });
  //
  //    if (this.echos.length > 0) {
  //        if (results.length > 0) {
  //            this.echos[0].echos.push(results[0].echos[0]);
  //        }
  //    } else {
  //        this.echos = results;
  //    }
  //    return this.echos;
  //}
}
