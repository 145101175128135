export class RenewalDraftDeletionSearchCriteria {
  constructor() {
    //this.month = null;
    this.segment = [];
    this.productIds = [];
  }

  month!: Date;
  segment: Array<string>;
  productIds: Array<string>;
}
