import { DatePipe } from "@angular/common";
import { Component, 
    OnInit,
    OnChanges,
    SimpleChanges,
    Input,
    Output,
    EventEmitter} from "@angular/core";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { AutoDebitExtractionSearchCriteria } from "./auto-debit.model";
@Component({
    selector : 'app-auto-debit',
    templateUrl : './auto-debit.component.html'
})
export class AutoDebitComponent implements OnInit,OnChanges{
    @Input() presetReportType!:number
    @Output() warningZoneEvent = new EventEmitter<any>();
    searchCriteria = new AutoDebitExtractionSearchCriteria;
    constructor(
        private presetExportService : PresetExportService,
        private excelTemplateService : ExcelTemplateService
    ){}
    ngOnInit(): void {
        
    }
   ngOnChanges(changes: SimpleChanges): void {
    this.searchCriteria = new AutoDebitExtractionSearchCriteria;
   }
    public download():void{
        switch (this.presetReportType) {           
            case 13: 
                if(this.validateMonthFields()){
                    this.handleWarnings(false);
                    this.presetExportService.downloadDocument(this.searchCriteria.month.getMonth() + 1,this.searchCriteria.month.getFullYear());
                }
                else{
                this.handleWarnings(true);
                }
                break;
            case 14: 
                if(this.validateMonthFields()){
                    this.handleWarnings(false);
                    this.downloadAutoDebitExtration();
                }
                else{
                    this.handleWarnings(true);
                }
                break;
            case 15: 
                if(this.validateMonthFields()){
                    this.handleWarnings(false);
                    this.downloadAutoUpdateExtration()                
                }
                else{
                    this.handleWarnings(true);
                }
                break;
    }
        
    }
    private validateMonthFields(): boolean {
    let isValid: boolean = false;
    // dates
    let dates: boolean = this.searchCriteria.month !== undefined;
    isValid = dates; // || etc...
    return isValid;
    }
    private handleWarnings(isShowWarnings:boolean):void{
        if(isShowWarnings){
            let warningParamters: warningZoneArgs = {
                showWarning: true,
                warningText: "Please select a month.",
            }
            this.warningZoneEvent.emit(warningParamters);
        }
        else{
        let warningParamters: warningZoneArgs = {
            showWarning: false,
            warningText: "",
        };
        this.warningZoneEvent.emit(warningParamters);
    }
    }
    private downloadAutoDebitExtration() {
        this.searchCriteria.segmentDetails = "CBG,IBG,PB,TPC";
        this.presetExportService.summaryInvoiceAutoDebit(
            this.searchCriteria.month.getMonth() + 1,
            this.searchCriteria.month.getFullYear(),
            this.searchCriteria.segmentDetails
            ).subscribe((response:any)=>{
                if(response && response.body && response.body.d.results){
                this.excelTemplateService.downloadautoDebitExtractionReport(
                    autoDebitColumn,
                    'Summary Invoice Auto Debit',
                    'AD_'+ new DatePipe('en-US').transform(this.searchCriteria.month, 'MMMMyyyy'),
                    response.body.d.results
                );
                }
            });
    }

    private downloadAutoUpdateExtration() {
        this.searchCriteria.segmentDetails = "CBG,IBG";
        this.presetExportService.autoUpdateDownload(
            this.searchCriteria.month.getMonth() + 1,
            this.searchCriteria.month.getFullYear(),
            this.searchCriteria.segmentDetails
            ).subscribe((response:any)=>{
                if(response && response.body && response.body.d.results){
                this.excelTemplateService.downloadautoUpdateExtractionReport(
                    autoUpdateColumn,
                    'Auto Update Report',
                    'AU_'+ new DatePipe('en-US').transform(this.searchCriteria.month, 'MMMMyyyy'),
                    response.body.d.results
                );
                }
            });
    }
}
const autoDebitColumn:string[]=[
    'SI No',
    'Meridian Sequence No',
    'Policy Certificate No',
    'Master Policy No',
    'Policy No',
    'Entry Date',
    'Gross Premium',
    'GST',
    'Total Premium',
    'Open Item Status',
    'Invoice No',
    'Saved By',
    'BU PC Code',
    'Bank Ref',
    'Insured Name',
    'Effective Date',
    'Expiry Date',
    'Sum Insured',
    'Risk Address',
    'Insured NRIC',
    'Transaction Type',
    'Product',
    'Contract Type',
    'Policy Status',
    'Transaction date',           
];
const autoUpdateColumn:string[]=[
    'SI No',
    'PolicyNumber',
    'BatchId',
    'Financial Institution Branch/BU PC Code',
    'Bank Reference Number',
    'MeridianMasterPolicyNumber',
    'PolicyType',
    'Main Insured Name',
    'Risk Address',
    'Policy Inception/Start Date',
    'Expiry Date',
    'Total Sum Insured',
    'Gross Premium excl. GST',
    'GST on Gross Premium',
    'Total Premium',
    'Transaction Code'          
];